// Import react
import React, { useState } from 'react'

// Import components
import { Button, Form, Modal } from 'react-bootstrap'
// Import libs/other
import 'react-dates/initialize'

import { editAllergy } from '../../api/api-lib'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'
import { onError } from '../../libs/errorLib'
import { useFormFields } from '../../libs/hooksLib'
import { notification } from '../../libs/notificationLib'
import DeleteAllergy from '../Buttons/DeleteAllergy'
import LoaderButton from '../Buttons/LoaderButton'

import 'react-dates/lib/css/_datepicker.css'

function isNumericString(string) {
  if (typeof string !== 'string') return false
  return !isNaN(string) && !isNaN(parseFloat(string))
}

const EditAllergy = (props) => {
  const [show, setShow] = useState(true)
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [fields, handleFieldChange] = useFormFields({
    AllergyName: props.allergy.AllergyName,
    AllergyType: props.allergy.AllergyType,
    AllergyStatus: props.allergy.AllergyStatus,
    Reaction: props.allergy.Reaction ? props.allergy.Reaction : '',
    Severity: props.allergy.Severity ? props.allergy.Severity : '',
    Onset: props.allergy.Onset ? props.allergy.Onset : null,
    Comments: props.allergy.Comments ? props.allergy.Comments : '',
  })

  function validateForm() {
    const newErrors = []
    if (!fields.AllergyName) newErrors.push('AllergyName')
    if (!fields.AllergyType) newErrors.push('AllergyType')
    if (!fields.AllergyStatus) newErrors.push('AllergyStatus')
    // if there is an Onset provided, make sure it is four characters and all numbers (a valid year)
    if (fields.Onset) {
      const isValidYear =
        fields.Onset.length === 4 && isNumericString(fields.Onset)
      if (!isValidYear) newErrors.push('Onset')
    }
    setErrors(newErrors)
    return !newErrors.length
  }

  function hideModal() {
    setShow(false)
    props.handleShow()
  }

  function handleRadioLabelClick(key, value) {
    const newFields = { ...fields }
    newFields[key] = value
    handleFieldChange(newFields, false)
  }

  async function handleEditAllergy(event) {
    event.preventDefault()
    setIsLoading(true)
    const isValid = validateForm()
    if (!isValid) {
      setIsLoading(false)
      return
    }
    try {
      fields.PatientId = props.patient.PatientId
      fields.AllergyId = props.allergy.AllergyId
      await editAllergy(
        props.allergy.AllergyId,
        props.patient.PatientId,
        fields
      )
      props.handleApiChange([
        patientDataTypes.ProviderSidePatientData,
        props.patient.PatientId,
      ])
      props.handleAllergyDataChange()
      notification(
        'You have successfully edited an allergy. Please wait a moment to see your changes in the allergies list.',
        'success'
      )
    } catch (error) {
      setIsLoading(false)
      onError(
        error,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    } finally {
      setIsLoading(false)
      hideModal()
    }
  }

  return (
    <Modal size="lg" show={show} onHide={hideModal} centered>
      <Modal.Header>
        <h2 className="info">Edit Allergy</h2>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group>
            <Form.Text className="text-muted">
              <span className="danger">*</span> Indicates required field
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="AllergyName">
            <Form.Label>
              Allergen Name&nbsp;
              <span className="danger">*</span>
            </Form.Label>
            <Form.Control
              bsPrefix="form-input"
              onChange={(evt) => {
                handleFieldChange(evt)
                setErrors(errors.filter((err) => err !== 'AllergyName'))
              }}
              type="text"
              value={fields.AllergyName}
            />
            {errors.includes('AllergyName') && (
              <small className="text-danger">
                Allergen Name is a required field.
              </small>
            )}
          </Form.Group>

          <Form.Group controlId="AllergyType">
            <Form.Label>
              Allergen Type&nbsp;
              <span className="danger">*</span>
            </Form.Label>
            <br />
            <Form.Check
              inline
              bsPrefix="form-check"
              checked={fields.AllergyType === 'Drug'}
              value="Drug"
              onChange={(evt) => {
                handleFieldChange(evt)
                setErrors(errors.filter((err) => err !== 'AllergyType'))
              }}
              type="radio"
              className="allergy-radio"
            />
            <span
              className="allergy-radio-label"
              id="newDrugAllergy"
              onClick={() => handleRadioLabelClick('AllergyType', 'Drug')}
            >
              Drug
            </span>
            <Form.Check
              inline
              bsPrefix="form-check"
              checked={fields.AllergyType === 'Food'}
              value="Food"
              onChange={(evt) => {
                handleFieldChange(evt)
                setErrors(errors.filter((err) => err !== 'AllergyType'))
              }}
              type="radio"
              className="allergy-radio"
            />
            <span
              className="allergy-radio-label"
              id="newFoodAllergy"
              onClick={() => handleRadioLabelClick('AllergyType', 'Food')}
            >
              Food
            </span>
            <Form.Check
              inline
              bsPrefix="form-check"
              checked={fields.AllergyType === 'Environment'}
              value="Environment"
              onChange={(evt) => {
                handleFieldChange(evt)
                setErrors(errors.filter((err) => err !== 'AllergyType'))
              }}
              type="radio"
              className="allergy-radio"
            />
            <span
              className="allergy-radio-label"
              id="newEnvironmentAllergy"
              onClick={() =>
                handleRadioLabelClick('AllergyType', 'Environment')
              }
            >
              Environment
            </span>
            <Form.Check
              inline
              bsPrefix="form-check"
              checked={fields.AllergyType === 'Other'}
              value="Other"
              onChange={(evt) => {
                handleFieldChange(evt)
                setErrors(errors.filter((err) => err !== 'AllergyType'))
              }}
              type="radio"
              className="allergy-radio"
            />
            <span
              className="allergy-radio-label"
              id="newOtherAllergy"
              onClick={() => handleRadioLabelClick('AllergyType', 'Other')}
            >
              Other
            </span>
            {errors.includes('AllergyType') && (
              <small className="text-danger">
                Allergen Type is a required field.
              </small>
            )}
          </Form.Group>

          <Form.Group controlId="AllergyStatus">
            <Form.Label>
              Status&nbsp;
              <span className="danger">*</span>
            </Form.Label>
            <br />
            <Form.Check
              inline
              bsPrefix="form-check"
              checked={fields.AllergyStatus === 'Active'}
              value="Active"
              onChange={(evt) => {
                handleFieldChange(evt)
                setErrors(errors.filter((err) => err !== 'AllergyStatus'))
              }}
              type="radio"
              className="allergy-radio"
            />
            <span
              className="allergy-radio-label"
              id="newActiveAllergy"
              onClick={() => handleRadioLabelClick('AllergyStatus', 'Active')}
            >
              Active
            </span>
            <Form.Check
              inline
              bsPrefix="form-check"
              checked={fields.AllergyStatus === 'Inactive'}
              value="Inactive"
              onChange={(evt) => {
                handleFieldChange(evt)
                setErrors(errors.filter((err) => err !== 'AllergyStatus'))
              }}
              type="radio"
              className="allergy-radio"
            />
            <span
              className="allergy-radio-label"
              id="newInactiveAllergy"
              onClick={() => handleRadioLabelClick('AllergyStatus', 'Inactive')}
            >
              Inactive
            </span>
            {errors.includes('AllergyStatus') && (
              <small className="text-danger">Status is a required field.</small>
            )}
          </Form.Group>

          <Form.Group controlId="Reaction">
            <Form.Label>Reaction&nbsp;</Form.Label>
            <Form.Control
              bsPrefix="form-input"
              onChange={handleFieldChange}
              type="text"
              as="textarea"
              className="text-area"
              value={fields.Reaction}
            />
          </Form.Group>

          <Form.Group controlId="Severity">
            <Form.Label>Severity&nbsp;</Form.Label>
            <br />
            <Form.Check
              inline
              bsPrefix="form-check"
              checked={fields.Severity === 'Mild'}
              value="Mild"
              onChange={handleFieldChange}
              type="radio"
              className="allergy-radio"
            />
            <span
              className="allergy-radio-label"
              id="newMildAllergy"
              onClick={() => handleRadioLabelClick('Severity', 'Mild')}
            >
              Mild
            </span>
            <Form.Check
              inline
              bsPrefix="form-check"
              checked={fields.Severity === 'Moderate'}
              value="Moderate"
              onChange={handleFieldChange}
              type="radio"
              className="allergy-radio"
            />
            <span
              className="allergy-radio-label"
              id="newModerateAllergy"
              onClick={() => handleRadioLabelClick('Severity', 'Moderate')}
            >
              Moderate
            </span>
            <Form.Check
              inline
              bsPrefix="form-check"
              checked={fields.Severity === 'Severe'}
              value="Severe"
              onChange={handleFieldChange}
              type="radio"
              className="allergy-radio"
            />
            <span
              className="allergy-radio-label"
              id="newSevereAllergy"
              onClick={() => handleRadioLabelClick('Severity', 'Severe')}
            >
              Severe
            </span>
            <Form.Check
              inline
              bsPrefix="form-check"
              checked={fields.Severity === 'Fatal'}
              value="Fatal"
              onChange={handleFieldChange}
              type="radio"
              className="allergy-radio"
            />
            <span
              className="allergy-radio-label"
              id="newFatalAllergy"
              onClick={() => handleRadioLabelClick('Severity', 'Fatal')}
            >
              Fatal
            </span>
            <Form.Check
              inline
              bsPrefix="form-check"
              checked={fields.Severity === 'Unknown'}
              value="Unknown"
              onChange={handleFieldChange}
              type="radio"
              className="allergy-radio"
            />
            <span
              className="allergy-radio-label"
              id="newUnknownAllergy"
              onClick={() => handleRadioLabelClick('Severity', 'Unknown')}
            >
              Unknown
            </span>
          </Form.Group>

          <Form.Group controlId="Onset">
            <Form.Label>Onset Year&nbsp;</Form.Label>
            <Form.Control
              bsPrefix="form-input"
              onChange={(evt) => {
                handleFieldChange(evt)
                setErrors(errors.filter((err) => err !== 'Onset'))
              }}
              type="text"
              placeholder="YYYY"
              value={fields.Onset}
            />
            {errors.includes('Onset') && (
              <small className="text-danger">
                Onset must be a four digit year.
              </small>
            )}
          </Form.Group>

          <Form.Group controlId="Comments">
            <Form.Label>Comment&nbsp;</Form.Label>
            <Form.Control
              bsPrefix="form-input"
              onChange={handleFieldChange}
              type="text"
              as="textarea"
              className="text-area"
              value={fields.Comments}
            />
          </Form.Group>
          <LoaderButton
            id="saveEditAllergy"
            onClick={handleEditAllergy}
            isLoading={isLoading}
            className="button-label button-block center"
            type="submit"
            textInside="Updating allergy"
          >
            <div className="button-label">
              <i className="fa fa-floppy-o" aria-hidden="true" />
              &nbsp;Save changes
            </div>
          </LoaderButton>
          <DeleteAllergy
            handleClose={hideModal}
            allergyId={props.allergy.AllergyId}
            handleApiChange={props.handleApiChange}
            patientId={props.patient.PatientId}
          />
          <Button
            id="cancelEditAllergy"
            bsPrefix="button-close"
            onClick={hideModal}
          >
            <div className="button-label">
              <i className="fa fa-times" />
              &nbsp; Cancel
            </div>
          </Button>
        </Modal.Body>
      </Form>
    </Modal>
  )
}

export default EditAllergy
