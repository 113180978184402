import React, { useEffect, useState } from 'react'

import { Form, Input, Modal } from 'antd'

import { useCreateNoteTemplate } from '../../v2/notes/hooks/templates/useCreateNoteTemplate'
import { useUpdateNoteTemplate } from '../../v2/notes/hooks/templates/useUpdateNoteTemplate'
import { generateNotification } from '../BaseComponents'
import { NotificationType } from '../BaseComponents/Notification'

const MAX_NAME_LENGTH = 150

interface NoteTemplateCreateDialogProps {
  mode: 'create'
  visible: boolean
  onTemplateCreated: (templateId: string) => void
  onCancel: () => void
}

interface NoteTemplateEditDialogProps {
  mode: 'edit'
  visible: boolean
  onTemplateUpdated: () => void
  onCancel: () => void
  templateId: string
  initialName: string
  initialDescription?: string
}

export const NoteTemplateCreateOrEditDialog = (
  props: NoteTemplateCreateDialogProps | NoteTemplateEditDialogProps
) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [isCreatingOrUpdating, setIsCreatingOrUpdating] = useState(false)

  const clearFields = () => {
    setName('')
    setDescription('')
  }

  useEffect(() => {
    if (props.mode === 'edit') {
      setName(props.initialName)
      setDescription(props.initialDescription ?? '')
    } else {
      clearFields()
    }
  }, [props.visible, props.mode])

  const { createNoteTemplate } = useCreateNoteTemplate()
  const { updateNoteTemplate } = useUpdateNoteTemplate()

  const handleOk = async () => {
    if (props.mode === 'create') {
      setIsCreatingOrUpdating(true)
      try {
        const newTemplate = await createNoteTemplate({
          name,
          description,
        })
        props.onTemplateCreated(newTemplate.uuid)
      } catch {
        generateNotification(
          `Unable to create note template. Try again.`,
          NotificationType.ERROR
        )
      } finally {
        setIsCreatingOrUpdating(false)
      }
    } else {
      setIsCreatingOrUpdating(true)
      try {
        await updateNoteTemplate({
          templateId: props.templateId,
          body: {
            name,
            description,
          },
        })
        props.onTemplateUpdated()
        generateNotification(
          `Note template details updated.`,
          NotificationType.SUCCESS
        )
      } catch {
        generateNotification(
          `Unable to update note template. Try again.`,
          NotificationType.ERROR
        )
      } finally {
        setIsCreatingOrUpdating(false)
      }
    }
  }

  const handleCancel = () => {
    props.onCancel()
  }

  return (
    <Modal
      title={
        props.mode === 'create'
          ? 'New note template'
          : 'Edit note template name'
      }
      visible={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={props.mode === 'create' ? 'Next' : 'Save'}
      okButtonProps={{ disabled: !name.trim(), loading: isCreatingOrUpdating }}
    >
      <Form layout="vertical">
        <Form.Item label="Template name" required>
          <Input
            data-testid={'NoteTemplateCreateOrEditDialog--Input--Name'}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
            placeholder='Example "TMS eval"'
            maxLength={MAX_NAME_LENGTH}
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input
            data-testid={'NoteTemplateCreateOrEditDialog--Input--Description'}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
