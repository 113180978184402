import React from 'react'

import moment from 'moment'
import { Accordion, Card, Col, Container, Form, Row } from 'react-bootstrap'

import './../../PatientProfile.scss'

export default function SubstanceToDisplay(props) {
  return (
    <Container>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={props.Substance}
        id="treatmentsHeader"
        bsPrefix="patient-card"
      >
        <Container>
          <Row>
            {props.SubstanceText && (
              <Col bsPrefix="psych">{props.SubstanceText}</Col>
            )}
          </Row>
        </Container>
      </Accordion.Toggle>

      <Accordion.Collapse
        style={{ marginTop: '6px', marginBottom: '8px' }}
        eventKey={props.Substance}
      >
        <Container>
          <Col>
            {props.CurrentPastUse === 'Current' && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">
                  {props.CurrentPastUse + ' use'}:{' '}
                </span>
                {props.DateRange[0].startDate
                  ? moment(new Date(props.DateRange[0].startDate)).format(
                      props.DateRange[0].dateFormat
                    )
                  : ' - '}
              </Row>
            )}
            {props.CurrentPastUse === 'Past' && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">
                  {props.CurrentPastUse + ' use'}:{' '}
                </span>
                {props.DateRange.map((d, index) => {
                  return (
                    <span key={index}>
                      {index !== 0 && ', '}
                      {(d.startDate
                        ? moment(new Date(d.startDate)).format(d.dateFormat)
                        : ' - ') +
                        (d.endDate
                          ? ' up to ' +
                            moment(new Date(d.endDate)).format(d.dateFormat)
                          : '')}
                    </span>
                  )
                })}
              </Row>
            )}
            {props.Frequency && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">Frequency: </span>
                {props.Frequency}
              </Row>
            )}
            {props.Quantity && props.Substance !== 'Alcohol' && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">Quantity: </span>
                {props.Quantity}
              </Row>
            )}
            {props.Route && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">Route: </span>
                {props.Route}
              </Row>
            )}
            {props.Notes && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">Notes: </span>
                {props.Notes}
              </Row>
            )}
            {props.Substance === 'Alcohol' && (
              <>
                {props.DrinksPerWeek && (
                  <Row bsPrefix="psych-sub">
                    <span className="subtopic">How many drinks a week? </span>
                    {props.DrinksPerWeek}
                  </Row>
                )}
                {props.BingeDrink && (
                  <Row bsPrefix="psych-sub">
                    <span className="subtopic">How many binges per week? </span>
                    {props.BingeDrink}
                  </Row>
                )}
              </>
            )}
            {props.CAGE.state && (
              <>
                <Row bsPrefix="psych-sub">
                  <Form.Check
                    className="psych-checkbox"
                    type="checkbox"
                    checked={JSON.parse(props.CAGE.state)}
                  />
                  <span className="subtopic">CAGE questions:</span>
                </Row>
                {props.CAGE.CuttingDown && (
                  <Row bsPrefix="psych-sub">
                    <Form.Check
                      className="psych-checkbox indent"
                      type="checkbox"
                      checked={JSON.parse(props.CAGE.CuttingDown)}
                    />
                    <span className="subtopic">
                      Tried cutting down or quitting
                    </span>
                  </Row>
                )}
                {props.CAGE.AnnoyedCriticism && (
                  <Row bsPrefix="psych-sub">
                    <Form.Check
                      className="psych-checkbox indent"
                      type="checkbox"
                      checked={JSON.parse(props.CAGE.AnnoyedCriticism)}
                    />
                    <span className="subtopic">
                      Felt annoyed by others criticizing use
                    </span>
                  </Row>
                )}
                {props.CAGE.UseGuilt && (
                  <Row bsPrefix="psych-sub">
                    <Form.Check
                      className="psych-checkbox indent"
                      type="checkbox"
                      checked={JSON.parse(props.CAGE.UseGuilt)}
                    />
                    <span className="subtopic">
                      Felt bad or guilty about use
                    </span>
                  </Row>
                )}
                {props.CAGE.MorningUse && (
                  <Row bsPrefix="psych-sub">
                    <Form.Check
                      className="psych-checkbox indent"
                      type="checkbox"
                      checked={JSON.parse(props.CAGE.MorningUse)}
                    />
                    <span className="subtopic">
                      Used first thing in the morning
                    </span>
                  </Row>
                )}
              </>
            )}
            {props.AdditionalNotes && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">Additional Notes: </span>
                {props.AdditionalNotes}
              </Row>
            )}
          </Col>
        </Container>
      </Accordion.Collapse>
    </Container>
  )
}
