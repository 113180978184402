import React from 'react'

import { CardProps, FormInstance, Row } from 'antd'

import { Button, Card } from '../BaseComponents'
import { ModalProps } from '../BaseComponents/Modal'
import { GenericTableProps } from '../BaseComponents/Table'
import { ModalDisplay } from './types'

import './SettingsTableDisplay.scss'

interface CustomModalProps extends ModalProps {
  form: FormInstance
  mode: ModalDisplay
  onSubmit: (data: any) => void
  toggleCancel: () => void
}

interface CustomTableProps extends GenericTableProps {
  onDelete: (data: any) => void
  onEdit: (data: any) => void
}

interface SettingsTableDisplayProps {
  buttonText?: string
  dataId?: string
  modal: React.FC<CustomModalProps> | any
  props?: CardProps
  show?: boolean
  table: React.FC<CustomTableProps> | any
  title?: string
  toggleShow?: () => void
}

const SettingsTableDisplay: React.FC<SettingsTableDisplayProps> = ({
  modal,
  buttonText = '+ Add new element',
  dataId = 'settings-display-table-card',
  table,
  title = '',
  props = {},
  show,
  toggleShow,
}) => {
  const button = (
    <Button id="display-list-button" onClick={toggleShow} type="primary">
      {buttonText}
    </Button>
  )

  return (
    <Card data-id={dataId} id="display-list-card" bordered={false} {...props}>
      <div className="headerWrapper headerWrapper_row">
        <span className="headerWrapper_title">{title}</span>
        {button}
      </div>
      <Row id="display-list-table">{table}</Row>
      {show && modal}
    </Card>
  )
}

export default SettingsTableDisplay
