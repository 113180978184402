import { PracticeDataType } from '../../../../hooks/usePatientProfile/shared-types'
import { NoteDetailFromApi } from '../../../../v2/notes/types'
import { APPOINTMENT } from '../field-constants'
import { toAddressInputValue, toDateInputValue } from '../mappers'
import {
  AddressInput,
  ClaimForm,
  ClaimFormProvider,
  ClaimLocation,
  ClaimProvider,
  InlineFieldProps,
  Option,
} from '../types'
import {
  getNewBillingValuesOnProviderChange,
  getNewProviderValuesOnProviderChange,
  getNewValuesOnNestedFieldChange,
} from '../utils'

export const getPopulatedFields = (
  noteOptions: Option[],
  locationOptions: Option[],
  posOptions: Option[]
): InlineFieldProps[] =>
  APPOINTMENT.map((field) => {
    const newField = { ...field }
    // attach options for location and provider dropdowns
    if (field.name === 'note.noteId') {
      newField.options = noteOptions
    } else if (field.name === 'note.locationId') {
      newField.options = locationOptions
    } else if (field.name === 'note.posCode') {
      newField.options = posOptions
    }
    return newField
  })

const getNewValuesOnNoteChange = (
  values: ClaimForm,
  newNoteId: string | null,
  notesById: { [index: string]: NoteDetailFromApi },
  locationsByLocationId: { [index: string]: ClaimLocation },
  {
    ProviderPracticeBillingNPI,
  }: Pick<PracticeDataType, 'ProviderPracticeBillingNPI'>,
  providersById: { [index: string]: ClaimProvider }
): ClaimForm => {
  const selectedNote = newNoteId ? notesById[newNoteId] : null
  const selectedLocationId = selectedNote?.locationId ?? null
  const selectedLocation = selectedLocationId
    ? locationsByLocationId[selectedLocationId]
    : null
  const newNote = {
    ...values.note,
    noteId: newNoteId,
    startDate: toDateInputValue(selectedNote?.appointmentDate),
    endDate: toDateInputValue(selectedNote?.appointmentDate),
    locationId: selectedLocationId,
    address: toAddressInputValue(selectedLocation),
    address2: selectedLocation?.AddressLine2 || null, // AddressLine2 can be empty string
    posCode: selectedLocation?.Pos || null, // Pos can be empty string
    practiceNpi: selectedLocation?.BillingNPI || null, // BillingNPI can be empty string
  }
  // only apply NPI fallback if (1) a location is selected (2) practice NPI exists
  if (!newNote.practiceNpi && selectedLocation && ProviderPracticeBillingNPI) {
    newNote.practiceNpi = ProviderPracticeBillingNPI
  }

  const selectedNoteRenderingProviderId = selectedNote?.renderingProviderId
  let newRenderingProvider: ClaimFormProvider | undefined = undefined
  if (
    selectedNoteRenderingProviderId &&
    selectedNoteRenderingProviderId !== values.renderingProvider.providerId
  ) {
    newRenderingProvider = getNewProviderValuesOnProviderChange(
      values.renderingProvider,
      selectedNoteRenderingProviderId,
      providersById
    )
  }
  let newBilling: ClaimForm['billing'] | undefined = undefined
  if (
    selectedNoteRenderingProviderId &&
    selectedNoteRenderingProviderId !== values.billing.providerId &&
    values.billing.type === 'Provider'
  ) {
    newBilling = getNewBillingValuesOnProviderChange(
      values.billing,
      selectedNoteRenderingProviderId,
      providersById
    )
  }
  return {
    ...values,
    note: newNote,
    ...(newRenderingProvider
      ? { renderingProvider: newRenderingProvider }
      : null),
    ...(newBilling ? { billing: newBilling } : null),
  }
}

const getNewValuesOnLocationChange = (
  values: ClaimForm,
  newLocationId: string | null,
  locationsByLocationId: { [index: string]: ClaimLocation },
  {
    ProviderPracticeBillingNPI,
  }: Pick<PracticeDataType, 'ProviderPracticeBillingNPI'>
) => {
  const selectedLocation = newLocationId
    ? locationsByLocationId[newLocationId]
    : null
  const newNote = {
    ...values.note,
    locationId: newLocationId,
    address: toAddressInputValue(selectedLocation),
    address2: selectedLocation?.AddressLine2 || null, // AddressLine2 can be empty string
    posCode: selectedLocation?.Pos || null, // Pos can be empty string
    practiceNpi: selectedLocation?.BillingNPI || null, // BillingNPI can be empty string
  }
  // only apply NPI fallback if (1) a location is selected (2) practice NPI exists
  if (!newNote.practiceNpi && selectedLocation && ProviderPracticeBillingNPI) {
    newNote.practiceNpi = ProviderPracticeBillingNPI
  }

  return {
    ...values,
    note: newNote,
  }
}

const getNewValuesOnAddressChange = (
  values: ClaimForm,
  newAddress: AddressInput
): ClaimForm => {
  return {
    ...values,
    note: {
      ...values.note,
      address: newAddress,
      address2: newAddress?.Address2 ?? null,
    },
  }
}

export const getNewValues = (
  values: ClaimForm,
  fieldName: string,
  fieldValue: any,
  notesById: { [index: string]: NoteDetailFromApi },
  locationsByLocationId: { [index: string]: ClaimLocation },
  practiceData: Omit<PracticeDataType, 'CreatedAt'>,
  providersById: { [index: string]: ClaimProvider }
): ClaimForm => {
  switch (fieldName) {
    case 'note.noteId':
      return getNewValuesOnNoteChange(
        values,
        fieldValue,
        notesById,
        locationsByLocationId,
        practiceData,
        providersById
      )
    case 'note.locationId':
      return getNewValuesOnLocationChange(
        values,
        fieldValue,
        locationsByLocationId,
        practiceData
      )
    case 'note.address':
      return getNewValuesOnAddressChange(values, fieldValue)
    default:
      return getNewValuesOnNestedFieldChange(values, fieldName, fieldValue)
  }
}
