// Import react
import { useEffect, useRef, useState } from 'react'

// Import components/containers
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from 'react-bootstrap'

// Import libs/others
import {
  createNoKnownAllergies,
  deleteAllergy,
  editPatientReportedAllergy,
  getRCopiaSSOUrl,
  syncRCopiaAllergies,
} from '../../api/api-lib'
import EditAllergyButton from '../../components/Buttons/EditAllergyButton'
import LoaderButton from '../../components/Buttons/LoaderButton'
import NewAllergy from '../../components/Forms/NewAllergy'
import { onError } from '../../libs/errorLib'
import { useFormFields } from '../../libs/hooksLib'
import { notification } from '../../libs/notificationLib'
import { DateWarningIcon } from '../../stories/BaseComponents'

// Import styles
import './PatientProfile.scss'

export default function Allergies(props) {
  const [drugAllergies, setDrugAllergies] = useState([])
  const [foodAllergies, setFoodAllergies] = useState([])
  const [environmentAllergies, setEnvironmentAllergies] = useState([])
  const [otherAllergies, setOtherAllergies] = useState([])

  const [freeTextAllergyData, setFreeTextAllergyData] = useFormFields({
    PatientReportedAllergies: '',
  })
  const [patientReportedAllergiesCopy, setPatientReportedAllergiesCopy] =
    useState('')
  const [patientReportedAllergiesId, setPatientReportedAllergiesId] =
    useState(null)
  const [isPatientReportedData, setIsPatientReportedData] = useState(false)

  // an array of the db items that hold 'Patient has no known <AllergenType> allergies' data
  const [previousNoKnownAllergies, setPreviousNoKnownAllergies] = useState([])
  // an array that holds which allergen group checkboxes are currently checked for 'Patient has no known <AllergenType> allergies' so any differences
  // between this list and the previous can be updated in the db on save
  const [newNoKnownAllergies, setNewNoKnownAllergies] = useState([])

  const [showNewAllergyModal, setShowNewAllergyModal] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [allergyDataChange, setAllergyDataChange] = useState(0)
  const [isLoadingRCopia, setIsLoadingRCopia] = useState(false)
  const [RCopiaUrl, setRCopiaUrl] = useState('')
  const [showRCopia, setShowRCopia] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    if (ref?.current && props.openAllergies) {
      ref.current.click()
      document.getElementById('patient-profile-allergies')?.scrollIntoView(true)
    }
  }, [ref, props.openAllergies])

  const closeNewAllergyModal = () => {
    setShowNewAllergyModal(false)
  }

  function handleLabelClick(evt) {
    // if provider is checking a new box by clicking a label, make sure the new list updates, otherwise remove the checked allergen type
    const allergyType = evt.target.id
    if (newNoKnownAllergies.includes(allergyType))
      setNewNoKnownAllergies(
        newNoKnownAllergies.filter((allergy) => allergy !== allergyType)
      )
    else setNewNoKnownAllergies([...newNoKnownAllergies, allergyType])
  }

  const handleCheckboxClick = (evt, allergyType) => {
    // if provider is checking a new box, make sure the new list updates, otherwise remove the checked allergen type
    evt.target.checked
      ? setNewNoKnownAllergies([...newNoKnownAllergies, allergyType])
      : setNewNoKnownAllergies(
          newNoKnownAllergies.filter((allergy) => allergy !== allergyType)
        )
  }

  const cancelEditing = () => {
    // revert free text and checked allergies display back to original data if the provider cancels and doesn't save
    setIsEditing(false)
    setNewNoKnownAllergies(
      previousNoKnownAllergies.map((allergy) => allergy.AllergyType)
    )
    setFreeTextAllergyData(
      {
        PatientReportedAllergies: patientReportedAllergiesCopy,
      },
      false
    )
    if (props.ClinicalNoteDisplay) {
      props.handleEditingAllergies(false)
    }
  }

  async function handleEditAllergies() {
    if (props.ClinicalNoteDisplay) {
      props.handleEditingAllergies(true)
    }
    // if this patient is not onboarded for erx, allow provider to edit record within Osmind,
    // otherwise, bring up patients RCopia profile for provider to make edits there
    if (!props.Patient.PatientRCopiaId) {
      setIsEditing(true)
    } else {
      setIsLoadingRCopia(true)
      notification(
        `Allergies should be updated within the eRx module. Please wait while we pull up ${props.Patient.PatientName}'s profile.`,
        'success'
      )
      const ssoUrl = await getRCopiaSSOUrl(props.Patient.PatientRCopiaId)
      setRCopiaUrl(ssoUrl)
      setShowRCopia(true)
      setIsLoadingRCopia(false)
    }
  }

  useEffect(() => {
    // if the provider was in editing mode, but then onboarded the patient for eRx without closing editing mode, this will turn
    // editing off after onboarding for eRx so that no further allergies can be added in Osmind, they'll have to be added in RCopia
    if (props.Patient.PatientRCopiaId) setIsEditing(false)
    // sort the allergies by allergen type, as well as store allergen meta data
    function sortAllergies(allergies) {
      const drugAllergies = []
      const foodAllergies = []
      const environmentAllergies = []
      const otherAllergies = []
      let patientReportedAllergies = ''

      for (const allergy of allergies) {
        if (allergy.AllergyType === 'Drug') drugAllergies.push(allergy)
        else if (allergy.AllergyType === 'Food') foodAllergies.push(allergy)
        else if (allergy.AllergyType === 'Environment')
          environmentAllergies.push(allergy)
        else if (allergy.AllergyType === 'Other') otherAllergies.push(allergy)
        else if (allergy.AllergyType === 'Latex') otherAllergies.push(allergy)
        else if (allergy.PatientReported) {
          patientReportedAllergies += allergy.Allergies
          setPatientReportedAllergiesId(allergy.AllergyId)
        }
      }
      setDrugAllergies(drugAllergies)
      setFoodAllergies(foodAllergies)
      setEnvironmentAllergies(environmentAllergies)
      setOtherAllergies(otherAllergies)
      setFreeTextAllergyData(
        {
          PatientReportedAllergies: patientReportedAllergies,
        },
        false
      )
      setPatientReportedAllergiesCopy(patientReportedAllergies)
      // these two states are kept so that the free text sections disappear after a provider saves if they empty it
      setIsPatientReportedData(!!patientReportedAllergies.length)
      const noKnownListOnLoad = allergies.filter(
        (allergy) => allergy.NoKnownAllergies
      )
      // keep a list of the previous no known allergies as full objects so we can use their AllergyId's to delete the data if need be
      setPreviousNoKnownAllergies(noKnownListOnLoad)
      // keep a list of only AllergenType's for new list so we can easily check whether a checkbox should be checked or not by default when editing
      setNewNoKnownAllergies(
        noKnownListOnLoad.map((allergy) => allergy.AllergyType)
      )
    }
    sortAllergies(props.Allergies)
  }, [props.Allergies, allergyDataChange])

  // makes sure that when allergies are added or updated, the display updates in real time and we don't leave editing mode unless provider presses save
  function handleAllergyDataChange() {
    setIsEditing(true)
    setAllergyDataChange(allergyDataChange + 1)
    if (props.ClinicalNoteDisplay) {
      props.handleEditingAllergies(false)
    }
  }

  function handleChangeNoNavigation() {
    props.handleApiChange()
  }

  async function handleSubmit() {
    setIsEditing(false)
    try {
      let noKnownAllergiesAdded = newNoKnownAllergies
      // Iterate through the previously stored no known allergies data and if an allergy type is no longer included in the new list,
      // remove that 'Patient has no known <AllergenType> allergies' piece of data,
      // if there are remaning new no known allergies that were not in the original list, create new db items for them
      for (const previous of previousNoKnownAllergies) {
        if (!newNoKnownAllergies.includes(previous.AllergyType)) {
          await deleteAllergy(previous.AllergyId, props.Patient.PatientId)
        }
        noKnownAllergiesAdded = noKnownAllergiesAdded.filter(
          (allergy) => allergy !== previous.AllergyType
        )
      }
      if (noKnownAllergiesAdded.length)
        await createNoKnownAllergies(
          noKnownAllergiesAdded,
          props.Patient.PatientId
        )
      // update the free text allergy fields in the db
      if (
        freeTextAllergyData?.PatientReportedAllergies !==
        patientReportedAllergiesCopy
      ) {
        await editPatientReportedAllergy(
          freeTextAllergyData.PatientReportedAllergies,
          props.Patient.PatientId,
          patientReportedAllergiesId
        )
      }
      handleChangeNoNavigation()
      notification(
        "You have successfully updated the patient's allergies. Please wait a moment for the allergies list to update.",
        'success'
      )
      if (props.ClinicalNoteDisplay) {
        props.handleEditingAllergies(false)
      }
    } catch (error) {
      onError(
        error,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
  }

  async function handleHideRCopia() {
    try {
      setShowRCopia(false)
      notification(
        'Please wait a moment to see the allergy changes made in the eRx module reflected in Osmind.',
        'success',
        { duration: 5 }
      )
      const data = {
        PatientRCopiaId: props.Patient.PatientRCopiaId,
        PatientId: props.Patient.PatientId,
        LastAllergyUpdateTimestamp:
          props.Patient.LastAllergyUpdateTimestamp || '',
      }
      await syncRCopiaAllergies(data)
      handleChangeNoNavigation()
      if (props.ClinicalNoteDisplay) {
        props.handleEditingAllergies(false)
      }
    } catch (error) {
      onError(
        error,
        500,
        'There was an internal error pulling updates from the eRx module into Osmind. Please inform your administrator.'
      )
    }
  }

  function allergenTypeDisplay(allergenType, typeAllergy) {
    return allergenType.map((allergy, index) => {
      if (allergy.NoKnownAllergies) {
        return (
          <div
            key={`${allergy}-${index}`}
            data-testid={`${typeAllergy}-line-${index}`}
          >
            {/* if editing, show the ability to check/uncheckmark that a patient has no known allergies, otherwise show the phrase patient has no known allergies */}
            {isEditing ? (
              <Container bsPrefix="allergies-list">
                <Form.Check
                  className="allergy-checkbox"
                  type="checkbox"
                  checked={newNoKnownAllergies.includes(allergy.AllergyType)}
                  onChange={(evt) =>
                    handleCheckboxClick(evt, allergy.AllergyType)
                  }
                />
                <span
                  id={allergy.AllergyType}
                  onClick={(evt) => handleLabelClick(evt)}
                >
                  Patient has no known {allergy.AllergyType.toLowerCase()}{' '}
                  allergies
                </span>
              </Container>
            ) : (
              <Container key={allergy.AllergyId} bsPrefix="allergies-list">
                Patient has no known {allergy.AllergyType.toLowerCase()}{' '}
                allergies
              </Container>
            )}
          </div>
        )
      }

      const shouldShowWarning =
        props.warningAllergiesIds?.length &&
        props.warningAllergiesIds.includes(allergy.AllergyId)

      return (
        <Container
          bsPrefix="allergies-list"
          key={allergy.AllergyId + index}
          data-testid={`${typeAllergy}-line-${index}`}
        >
          <Row key={allergy.AllergyId}>
            <Col
              bsPrefix="allergy"
              key={allergy.AllergyId + allergy.AllergyName}
            >
              <span id={'name' + typeAllergy + index} className="allergy-name">
                {shouldShowWarning ? (
                  <DateWarningIcon iconPaddingRight="5px" />
                ) : null}
                {allergy.AllergyName}
              </span>
              {isEditing && (
                <EditAllergyButton
                  tagName={typeAllergy + index}
                  allergy={allergenType[index]}
                  patient={props.Patient}
                  handleAllergyDataChange={handleAllergyDataChange}
                  handleApiChange={handleChangeNoNavigation}
                />
              )}
            </Col>
          </Row>
          {allergy.AllergyStatus && (
            <Row>
              <Col
                bsPrefix="allergy-sub"
                key={allergy.AllergyName + allergy.AllergyStatus}
              >
                <span className="subtopic">Status: </span>
                <span id={'status' + typeAllergy + index}>
                  {allergy.AllergyStatus}
                </span>
              </Col>
            </Row>
          )}
          {allergy.Reaction && (
            <Row>
              <Col
                bsPrefix="allergy-sub"
                key={allergy.AllergyName + allergy.Reaction}
              >
                <span className="subtopic">Reaction: </span>
                <span id={'reaction' + typeAllergy + index}>
                  {allergy.Reaction}
                </span>
              </Col>
            </Row>
          )}
          {allergy.Severity && (
            <Row>
              <Col
                bsPrefix="allergy-sub"
                key={allergy.AllergyName + allergy.Severity}
              >
                <span className="subtopic">Severity: </span>
                <span id={'severity' + typeAllergy + index}>
                  {allergy.Severity}
                </span>
              </Col>
            </Row>
          )}
          {allergy.Onset && (
            <Row>
              <Col
                bsPrefix="allergy-sub"
                key={allergy.AllergyName + allergy.Onset}
              >
                <span className="subtopic">Onset: </span>
                <span id={'onset' + typeAllergy + index}>{allergy.Onset}</span>
              </Col>
            </Row>
          )}
          {allergy.Comments && (
            <Row>
              <Col
                bsPrefix="allergy-sub"
                key={allergy.AllergyName + allergy.Comments}
              >
                <span className="subtopic">Comment: </span>
                <span id={'comments' + typeAllergy + index}>
                  {allergy.Comments}
                </span>
              </Col>
            </Row>
          )}
        </Container>
      )
    })
  }
  const allergiesDisplay = (
    <>
      {isPatientReportedData && (
        <>
          <b>Patient reported allergies</b>
          <Container bsPrefix="allergies-list">
            <Row>
              <Col>
                {!isEditing ? (
                  freeTextAllergyData.PatientReportedAllergies
                ) : (
                  <Form.Group controlId="PatientReportedAllergies">
                    <Form.Control
                      className="text-area"
                      as="textarea"
                      bsPrefix="form-input"
                      onChange={setFreeTextAllergyData}
                      type="text"
                      value={freeTextAllergyData.PatientReportedAllergies}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}

      <b>Drug</b>
      {drugAllergies.length ? (
        allergenTypeDisplay(drugAllergies, 'DrugAllergy')
      ) : (
        <>
          {isEditing ? (
            <Container bsPrefix="allergies-list">
              <Form.Check
                id="allergyDrugCheckbox"
                className="allergy-checkbox"
                type="checkbox"
                checked={newNoKnownAllergies.includes('Drug')}
                onChange={(evt) => handleCheckboxClick(evt, 'Drug')}
              />
              <span id="Drug" onClick={(evt) => handleLabelClick(evt)}>
                Patient has no known drug allergies
              </span>
            </Container>
          ) : (
            <Container bsPrefix="allergies-list text-muted">
              No drug allergies recorded
            </Container>
          )}
        </>
      )}

      <b>Food</b>
      {foodAllergies.length ? (
        allergenTypeDisplay(foodAllergies, 'FoodAllergy')
      ) : (
        <>
          {isEditing ? (
            <Container bsPrefix="allergies-list">
              <Form.Check
                id="allergyFoodCheckbox"
                className="allergy-checkbox"
                type="checkbox"
                checked={newNoKnownAllergies.includes('Food')}
                onChange={(evt) => handleCheckboxClick(evt, 'Food')}
              />
              <span id="Food" onClick={(evt) => handleLabelClick(evt)}>
                Patient has no known food allergies
              </span>
            </Container>
          ) : (
            <Container bsPrefix="allergies-list text-muted">
              No food allergies recorded
            </Container>
          )}
        </>
      )}

      <b>Environment</b>
      {environmentAllergies.length ? (
        allergenTypeDisplay(environmentAllergies, 'EnvironmentAllergy')
      ) : (
        <>
          {isEditing ? (
            <Container bsPrefix="allergies-list">
              <Form.Check
                id="allergyEnvironmentCheckbox"
                className="allergy-checkbox"
                type="checkbox"
                checked={newNoKnownAllergies.includes('Environment')}
                onChange={(evt) => handleCheckboxClick(evt, 'Environment')}
              />
              <span id="Environment" onClick={(evt) => handleLabelClick(evt)}>
                Patient has no known environment allergies
              </span>
            </Container>
          ) : (
            <Container bsPrefix="allergies-list text-muted">
              No environment allergies recorded
            </Container>
          )}
        </>
      )}

      <b>Other</b>
      {otherAllergies.length ? (
        allergenTypeDisplay(otherAllergies, 'OtherAllergy')
      ) : (
        <>
          {isEditing ? (
            <Container bsPrefix="allergies-list">
              <Form.Check
                id="allergyLatexCheckbox"
                className="allergy-checkbox"
                type="checkbox"
                checked={newNoKnownAllergies.includes('Latex')}
                onChange={(evt) => handleCheckboxClick(evt, 'Latex')}
              />
              <span id="Latex" onClick={(evt) => handleLabelClick(evt)}>
                Patient has no known latex allergies
              </span>
            </Container>
          ) : (
            <Container bsPrefix="allergies-list text-muted">
              No other allergies recorded
            </Container>
          )}
        </>
      )}
    </>
  )

  const savedTable = (
    <>
      <Container>
        {props.ClinicalNoteDisplay ? (
          <>{allergiesDisplay}</>
        ) : (
          <>
            {!props.NoAccordion && props.Patient.PatientRCopiaId && (
              <div
                className="center"
                style={{
                  fontWeight: 'bold',
                  color: '#A9A9A9',
                  fontSize: 14,
                  marginTop: 5,
                }}
              >
                If allergy updates have recently been made within the eRx
                module, please refresh the page to see them reflected here.
              </div>
            )}
            <Row>
              <Col>
                <Row className="border-bottom indent">
                  <Col className="bottom-spacing">
                    <Row>
                      <Col className="text-area">{allergiesDisplay}</Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        <br />
        {/* EDIT BUTTON */}
        <Row>
          <Col id="editAllergiesPatientProfile">
            {(!props.ClinicalNoteDisplay || props.isEditingNote) && (
              <LoaderButton
                bsPrefix="button-block icon-button"
                onClick={handleEditAllergies}
                isLoading={isLoadingRCopia}
              >
                <i className="fa fa-pencil-square-o" aria-hidden="true" />
                &nbsp; Edit allergies
              </LoaderButton>
            )}
            <br />
          </Col>
        </Row>
      </Container>
      <Modal
        size="xl"
        show={showRCopia}
        onHide={async () => await handleHideRCopia()}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <iframe
            src={RCopiaUrl}
            style={{ height: 975, width: '100%', marginTop: 25 }}
          />
        </Modal.Body>
      </Modal>
    </>
  )

  const editableTable = (
    <Form
      as={Container}
      noValidate
      onSubmit={handleSubmit}
      style={{ padding: '16px' }}
    >
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} controlId="Allergies">
          <Form.Label className="patient topic">
            {!props.NoAccordion && <>Allergies&nbsp;</>}
            <Button
              id="addNewAllergyButton"
              bsPrefix="button-add"
              onClick={() => setShowNewAllergyModal(true)}
            >
              <i className="fa fa-plus" aria-hidden="true">
                {' '}
              </i>
            </Button>
          </Form.Label>
          <Form.Group as={Col} controlId="EditAllergies">
            {allergiesDisplay}
          </Form.Group>
        </Form.Group>
        <Modal
          size="lg"
          show={showNewAllergyModal}
          onHide={() => setShowNewAllergyModal(true)}
          centered
        >
          <Modal.Header>
            <h2 className="info">New Allergy</h2>
          </Modal.Header>
          <NewAllergy
            patient={props.Patient}
            handleAllergyDataChange={handleAllergyDataChange}
            handleApiChange={handleChangeNoNavigation}
            handleClose={closeNewAllergyModal}
          />
        </Modal>
      </Form.Row>
      <Form.Row className="justify-contents-center">
        <Col id="saveNewAllergies" sm={12} md={5} lg={{ span: 4, offset: 2 }}>
          <Button
            bsPrefix="button-block icon-button"
            onClick={() => {
              setIsEditing(false)
              handleSubmit()
            }}
          >
            <i className="fa fa-floppy-o" aria-hidden="true" />
            &nbsp; Save allergies
          </Button>
        </Col>
        <Col id="cancelNewAllergies" sm={12} md={5}>
          <Button bsPrefix="button-close icon-button" onClick={cancelEditing}>
            <i className="fa fa-times" aria-hidden="true" />
            &nbsp; Cancel
          </Button>
        </Col>
      </Form.Row>
    </Form>
  )

  const allergiesAccordion = (
    <Accordion defaultActiveKey="0">
      <Accordion.Toggle
        bsPrefix="accordion-header very-large accordion-toggle"
        as={Card.Header}
        eventKey="1"
        ref={ref}
      >
        <div style={{ cursor: 'pointer' }}>Allergies</div>
      </Accordion.Toggle>

      {!isEditing ? (
        <Accordion.Collapse eventKey="1">{savedTable}</Accordion.Collapse>
      ) : (
        <Accordion.Collapse eventKey="1">{editableTable}</Accordion.Collapse>
      )}
    </Accordion>
  )

  const allergiesDiv = (
    <>{!isEditing ? <>{savedTable}</> : <>{editableTable}</>}</>
  )

  return (
    <>{props.NoAccordion ? <>{allergiesDiv}</> : <>{allergiesAccordion}</>}</>
  )
}
