import React, { useState } from 'react'

import moment from 'moment'
import { Button, Col, Form, Row } from 'react-bootstrap'

import { saveNewAddendum } from '../../../../../api/api-lib'
import { onError } from '../../../../../libs/errorLib'
import { notification } from '../../../../../libs/notificationLib'
import Sentry from '../../../../../libs/sentry'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function Addendums(props) {
  const [newAddendum, setNewAddendum] = useState({
    Content: '',
    Date: '',
    CreatedBy: '',
  })
  const [toggleAddendum, setToggleAddendum] = useState(false)
  const [loading, setLoading] = useState(false)

  async function handleNewAddendum(addendum) {
    if (addendum.Content === '') {
      notification(
        'To create a new Adendum you must include some content.',
        'failure'
      )
      setLoading(false)
      return
    }
    try {
      const auxAdden = props.fields.Addendums.concat(addendum)
      const data = {
        Addendums: auxAdden,
        PatientId: props.PatientId,
        NoteId: props.NoteId,
      }
      await saveNewAddendum(data)
      props.handleApiChange?.()
      notification(
        `You have successfully created a new addendum for ${props.patient.PatientName}. Please wait a moment to see your changes.`,
        'success'
      )
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
      Sentry.captureException(e)
    } finally {
      props.handleClose()
    }
  }

  return (
    <div>
      <br />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Addendums
      </div>
      <br />
      {(props.fields?.Addendums ?? []).map((addendum, index) => (
        <div key={index}>
          <Form.Group
            as={Col}
            style={{
              textAlign: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Form.Label style={{ fontWeight: 'bold' }}>Addendum:</Form.Label>
            <Form.Control
              bsPrefix="form-input"
              value={addendum.Content}
              as="textarea"
              rows="2"
              disabled
            />
          </Form.Group>
          <Form.Group
            as={Col}
            style={{ textAlign: 'center', marginLeft: 'auto' }}
          >
            <Form.Text
              className="center"
              style={{ fontWeight: 'bold', fontSize: '15px' }}
            >
              Added on {moment(addendum.Date).format('LLLL')} by{' '}
              {addendum.CreatedBy}
            </Form.Text>
          </Form.Group>
        </div>
      ))}
      {toggleAddendum ? (
        <div>
          <Form.Group
            as={Col}
            style={{
              textAlign: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Addendum content:
            </Form.Label>
            <Form.Control
              bsPrefix="form-input"
              value={newAddendum.Content}
              as="textarea"
              rows="2"
              onChange={(e) =>
                setNewAddendum({ ...newAddendum, Content: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group
            as={Row}
            style={{ textAlign: 'center', marginLeft: 'auto' }}
          >
            <Button
              variant="outline-primary"
              disabled={loading}
              style={{ marginLeft: 'auto', marginRight: '7px' }}
              onClick={() => {
                setLoading(true)
                handleNewAddendum(newAddendum)
              }}
            >
              + Save
            </Button>
            <Button
              variant="outline-danger"
              style={{ marginRight: 'auto', marginLeft: '7px' }}
              onClick={() => {
                setToggleAddendum(false)
                setLoading(false)
                setNewAddendum({ ...newAddendum, Content: '' })
              }}
            >
              Cancel
            </Button>
          </Form.Group>
        </div>
      ) : (
        <Form.Group as={Row}>
          <Button
            variant="outline-primary"
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            onClick={() => {
              setToggleAddendum(true)
            }}
          >
            + Add Addendum
          </Button>
        </Form.Group>
      )}
    </div>
  )
}
