import { Card, Text } from '../../../../../stories/BaseComponents'
import { Addendum } from '../../../types'
import { NoteDateTimeDisplay } from '../../NoteDateTimeDisplay'

import styles from './Addendums.module.scss'

type AddendumsProps = {
  addendums: Addendum[]
  timezone: string | null
}

export const Addendums = ({ addendums, timezone }: AddendumsProps) => {
  return (
    <div>
      <Text className={styles.headerTitle}>Addendums</Text>
      {addendums.map((addendum) => {
        return (
          <Card
            key={`addendum-${addendum.id}`}
            className={styles.signatureCard}
            bodyStyle={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Text className={styles.title}>
              {`Addendum by ${addendum.providerName}`}
            </Text>
            <Text className={styles.text}>
              <NoteDateTimeDisplay
                timestamp={addendum.createdAt}
                timezone={timezone}
              />
            </Text>
            <Text className={styles.comments}>{addendum.comments}</Text>
          </Card>
        )
      })}
    </div>
  )
}
