import { useContext } from 'react'

import { ChannelProvider } from '@sendbird/uikit-react/Channel/context'

import { usePatientDemographics } from '../../hooks/usePatientInfo'
import useQueryString from '../../hooks/useQueryString'
import { useFeatureFlags } from '../../libs/featureFlags'
import { InfoPage, Skeleton, Text } from '../../stories/BaseComponents'
import { StandardSkeletonRows } from '../../stories/BaseComponents/Skeleton'
import { SendbirdConnectionHandlerContext } from '../../v2/messaging/SendbirdConnectionHandlerProvider'
import { UnreadMessageCountsContext } from '../../v2/messaging/UnreadMessageCounts'
import { Messaging } from '../Other/Messaging'
import { PatientHeader } from './PatientHeader'

import styles from '../_shared.module.scss'
import patientMessagesStyles from './PatientMessages.module.scss'

const ErrorView = (
  <InfoPage
    status="warning"
    title="Sorry there was a problem loading this page"
    details="Oops something went wrong. Please contact your Osmind representative if this issue persists."
    redirectButtonText="Return to Patient List"
    redirectLink="/"
  />
)

export type PatientDemographicsData = {
  PatientId: string
  PatientName: string
  ChannelUrl: string
  ClinicId: string
}

export type PatientMessageProps = {
  healthGorillaUserName: string
  providerId: string
}

export default function PatientMessages({
  healthGorillaUserName,
  providerId,
}: PatientMessageProps) {
  const { enableSendbirdConnectionManagement } = useFeatureFlags()

  const query = useQueryString()
  const patientId = query.get('patientId')
  const clinicId = query.get('providerId')
  const { isLoading: patientMessageIsLoading, isError: patientMessageIsError } =
    useContext(UnreadMessageCountsContext)

  /**
   * Technically, we could get this value directly from useInactiveWindowTab hook
   * - However, there's benefit in using the same value exposed by SendbirdConnectionHandlerProvider
   *     since that's where we're registering the Sendbird state connect/disconnect callbacks
   */
  const { isWindowTabActive } = useContext(SendbirdConnectionHandlerContext)

  const {
    isLoading: patientDataIsLoading,
    isError: patientDataIsError,
    data: patient,
  } = usePatientDemographics(patientId ?? '')

  if (!patientId || !clinicId) {
    return ErrorView
  }

  if (patientDataIsError || patientMessageIsError) {
    return ErrorView
  }

  return (
    <div className={styles.scroll}>
      <PatientHeader
        providerId={clinicId}
        patientId={patientId}
        healthGorillaUserName={healthGorillaUserName}
      />
      {enableSendbirdConnectionManagement && !isWindowTabActive && (
        <div
          data-testid="PatientMessages--InactiveMessage--Container"
          className={patientMessagesStyles.inactiveMessageContainer}
        >
          <div className={patientMessagesStyles.inactiveMessageInnerContainer}>
            <Text
              header="h4"
              className={patientMessagesStyles.inactiveMessageText}
            >
              Messaging is paused
            </Text>
            <Text className={patientMessagesStyles.inactiveMessageText}>
              While this window is not active, the connection to messaging is
              paused.
            </Text>
            <Text className={patientMessagesStyles.inactiveMessageText}>
              The connection to messages will automatically resume when this
              window is focused.
            </Text>
          </div>
        </div>
      )}
      <div className="patientMessagesContainer">
        {patientDataIsLoading || patientMessageIsLoading || !patient ? (
          <Skeleton paragraph={{ rows: StandardSkeletonRows.fullPage }} />
        ) : (
          <ChannelProvider
            channelUrl={(patient as PatientDemographicsData).ChannelUrl}
          >
            <Messaging patient={patient} providerId={providerId} />
          </ChannelProvider>
        )}
      </div>
    </div>
  )
}
