import { useEffect, useMemo, useState } from 'react'

import { format, isValid } from 'date-fns'
import printjs from 'print-js'

import { IntakeForm, getCompletedIntakeForm } from '../../api/intakeForms'
import PatientIntakeResponse from '../PatientIntake/PatientIntakeResponses'
import { bigSampleForm } from '../PatientIntake/mocks/sample-form'

import './IntakeFormPrint.scss'

export default function IntakeFormDownloadButton({
  formId = '',
  publicId = 'N/A',
  storybook = false,
}) {
  const [completedForm, setCompletedForm] = useState<IntakeForm | null>(null)
  useEffect(() => {
    if (storybook) return setCompletedForm(bigSampleForm as IntakeForm)

    if (!formId) return
    ;(async () => {
      try {
        const response = await getCompletedIntakeForm(formId)
        setCompletedForm(response)
      } catch (e) {
        console.error(e)
      }
    })()
  }, [])

  const table = useMemo(() => {
    if (!completedForm) return null
    return (
      <PatientIntakeResponse
        completedForm={completedForm}
        id={`intake-response-${formId}`}
        isPrint
      />
    )
  }, [completedForm, publicId, formId])

  const handleIntakeFormDownload = async () => {
    const element = document.getElementById(`intake-response-${formId}`)
    if (!element || !completedForm) return
    element.style.display = 'block'
    const { completedAt } = completedForm
    let date = completedAt ? new Date(completedAt) : 'N/A'
    if (isValid(date)) date = format(new Date(date), 'P')

    const formatted = (completedForm?.sections ?? []).map((section) => {
      const sectionUpperCased = section[0].toUpperCase() + section.substring(1)
      return sectionUpperCased.replace(/_/g, ' ')
    })
    const sections = formatted.join(', ') ?? 'N/A'
    const header = `<h2>Intake Form</h2><p><b>Date:</b> ${date}</p><p><b>Form sections:</b> ${sections}</p><p><b>Public ID:</b> ${publicId}</p>`
    const headerStyle =
      'font-family: "Montserrat", "Helvetica Neue", "Helvetica", sans-serif; font-size: 14px; font-weight: 400;'
    printjs({
      css: 'intakeFormPrint.scss',
      header,
      headerStyle,
      printable: `intake-response-${formId}`,
      targetStyles: '*',
      type: 'html',
    })
    element.style.display = 'none'
  }

  return (
    <>
      <i
        aria-role="button"
        aria-label="Download intake form button"
        role="button"
        className="fa fa-arrow-circle-o-down"
        id="intake-response-download-button"
        onClick={handleIntakeFormDownload}
      />
      <div id="intake-response" style={{ display: 'none' }}>
        {table}
      </div>
    </>
  )
}
