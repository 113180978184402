import dayjs, { Dayjs } from 'dayjs'

export const TimeDiffUnit = {
  Hours: 'hours',
  Minutes: 'minutes',
  Seconds: 'seconds',
} as const

export type TimeDiffUnit = typeof TimeDiffUnit[keyof typeof TimeDiffUnit]

/**
 * @param value the value
 * @returns `value` as `Number` if it's a string, else `0`
 */
const toNumberOrZero = (value?: string | null): number => {
  if (typeof value === 'string') {
    return value.trim().length ? Number(value) : Number.NaN
  }
  return 0
}

/**
 * Returns a date set to the provided `time` or `Number.NaN` if `time` isn't
 * valid.
 *
 * Support time formats are:
 * - `HH` (ex: '12')
 * - `HH:mm` (ex: '12:00')
 * - `HH:mm:ss` (ex: '12:00:00')
 *
 * @param dt the date to set time for
 * @param time the `time` to set to
 * @returns a new date with `time`, otherwise `Number.NaN` if time isn't valid
 */
const withTime = (dt: Dayjs, time: string): Dayjs => {
  const [startHours, startMins, startSecs] = time.split(':')
  return dt
    .set('hours', toNumberOrZero(startHours))
    .set('minutes', toNumberOrZero(startMins))
    .set('seconds', toNumberOrZero(startSecs))
}

/**
 * Calculates the difference in `unit` between `start` and `end` times.
 *
 * Support time formats are:
 * - `HH` (ex: '12')
 * - `HH:mm` (ex: '12:00')
 * - `HH:mm:ss` (ex: '12:00:00')
 *
 * @example
 * timeDiff('12:00', '13:00', 'hours') // 1
 * timeDiff('12:00', '13:00', 'minutes') // 60
 * timeDiff('12:00:00', '13:00:00', 'seconds') // 3600
 * timeDiff('13:00:00', '12:00:00', 'seconds') // -3600
 *
 * @param start the start time
 * @param end the end time
 * @param unit the time unit for the diff
 * @returns the difference in `unit` or `Number.NaN` if invalid time provided
 */
export const timeDiff = (
  start: string,
  end: string,
  unit: TimeDiffUnit
): number => {
  const baseDate = dayjs().startOf('day')
  const startDt = withTime(baseDate, start)
  const endDt = withTime(baseDate, end)
  return endDt.diff(startDt, unit)
}
