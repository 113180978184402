import React from 'react'

import {
  Collapse as AntDCollapse,
  CollapseProps as AntDCollapseProps,
} from 'antd'

import { TestId } from '../../shared-types'

type CollapseProps = AntDCollapseProps & TestId

const Collapse: React.FC<CollapseProps> = (props) => <AntDCollapse {...props} />
export default Collapse
