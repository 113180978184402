import React from 'react'

import { Accordion, Form } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'
import TreatmentsToDisplay from './PsychContent/TreatmentsToDisplay'

import './../PatientProfile.scss'

export default function TreatmentsSaved(props) {
  const hasNoRelevantHistory = props.patientData.NoPreviousTreatments

  const noRecordedInfo =
    !props.patientData.NoPreviousTreatments &&
    props.patientData.Treatments.length === 0

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Treatments"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }
  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">Treatments:</Form.Row>
      <Form.Group className="border-bottom indent">
        {props.patientData.Treatments.length !== 0 &&
          props.patientData.Treatments.map((treatment, index) => {
            return (
              <Accordion className="table-container" key={index}>
                <TreatmentsToDisplay
                  type="active"
                  index={index}
                  TreatmentType={treatment.treatmentType}
                  TherapyType={treatment.therapyType}
                  NumberSessions={treatment.numberSessions}
                  StartDate={treatment.startDate}
                  EndDate={treatment.endDate}
                  ReasonForTreatment={treatment.reasonForTreatment}
                  TreatmentEfficacy={treatment.treatmentEfficacy}
                  NameOfClinician={treatment.nameOfClinician}
                  AdditionalNotes={treatment.additionalNotes}
                  DateFormat={treatment.dateFormat}
                />
              </Accordion>
            )
          })}
      </Form.Group>
    </Form.Group>
  )
}
