// Import react
import React from 'react'

// Import components
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const InfoTooltip = (props) => {
  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip bsPrefix="tooltip-x">{props.information}</Tooltip>}
      >
        <div
          style={{ cursor: 'pointer', fontWeight: 560, ...props.style }}
          className="info very-large"
        >
          {props.label}
        </div>
      </OverlayTrigger>
    </div>
  )
}

export default InfoTooltip
