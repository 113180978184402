import React from 'react'

import { Col as AntDCol, ColProps as AntDColProps } from 'antd'

import { TestId } from '../../shared-types'

type ColProps = AntDColProps & TestId

const Col: React.FC<ColProps> = (props) => <AntDCol {...props} />
export default Col
