import React, { useEffect, useState } from 'react'

import { Action, Location } from 'history'
import { Prompt, useHistory } from 'react-router-dom'

import { Modal, Spinner, Text } from '../../../../../stories/BaseComponents'
import { clinicalNotesBaseUrl } from '../../utils'

import styles from './GuardModals.module.scss'

export interface AutosaveLoaderPromptProps {
  shouldWaitForAutosave: boolean
}

/**
 * This component is intended to be a single-use loading modal that is triggered to block
 * navigation and further interaction. Once the provided shouldWaitForAutosave param is true,
 * it will allow navigation to proceed.
 *
 * @params {boolean} shouldWaitForAutosave - The condition that will block nav-away when true
 */
const AutosaveLoaderPrompt: React.FC<AutosaveLoaderPromptProps> = ({
  shouldWaitForAutosave,
}) => {
  const history = useHistory()
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [routeDestination, setRouteDestination] = useState<Location | null>(
    null
  )

  /** This callback returns true to proceed with nav-away, false to block nav-away */
  const triggerPrompt = (nextLocation: Location, action: Action) => {
    // Proceed if the autosave had triggered a note creation which redirects `newAutosave` => `${noteId}` route
    if (action === Action.Replace) return true

    if (!routeDestination) {
      // only store the next location once when we trigger the modal
      setShowLoadingModal(true)
      setRouteDestination(nextLocation)
    }

    return !shouldWaitForAutosave
  }

  useEffect(() => {
    // Listens for browser back clicks and will redirect to the notes list if attempting to go back to another note
    const unlistenHistory = history.listen(
      (nextLocation: Location, action: Action) => {
        if (
          action === Action.Pop &&
          !shouldWaitForAutosave &&
          nextLocation.pathname.includes(clinicalNotesBaseUrl)
        ) {
          history.push(`${clinicalNotesBaseUrl}${nextLocation.search ?? ''}`)
        }
      }
    )
    return unlistenHistory
  }, [shouldWaitForAutosave, history])

  useEffect(() => {
    if (!shouldWaitForAutosave && routeDestination) {
      // Proceed with navigation to the blocked location if autosave is done
      setShowLoadingModal(false)
      history.push(
        `${routeDestination.pathname}${routeDestination.search ?? ''}`
      )
    }
  }, [shouldWaitForAutosave, routeDestination])

  return (
    <>
      <Prompt when={shouldWaitForAutosave} message={triggerPrompt} />
      <Modal
        className={styles.loadingModalContainer}
        visible={showLoadingModal}
        zIndex={9999}
        closable={false}
        footer={null}
      >
        <Text
          data-testid="autosave-loading-text"
          className={styles.loadingModalText}
        >
          Waiting for saving to complete
        </Text>
        <Spinner className={styles.loadingModalSpinner} />
      </Modal>
    </>
  )
}
export default AutosaveLoaderPrompt
