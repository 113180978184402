/*global google*/
import React, { useEffect, useState } from 'react'

import { Form, Input, Select } from 'antd'
import { usePlacesWidget } from 'react-google-autocomplete'

import { globalConfig } from '../../config/config'
import { usaStatesData } from '../../containers/Provider/UsaStates'
import { rulesConstructor } from '../../libs/utils'
import { TestId } from '../../shared-types'

export interface Address {
  Address1: string
  Address2: string
  City: string
  State: string
  Zipcode: string
}

type AddressAutocompleteProps = {
  id?: string
  value: Address
  onChange: (values: Address) => void
} & TestId

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  value,
  onChange,
  testId,
  id: groupFormFieldName = '',
}) => {
  const initAddress = {
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    Zipcode: '',
  }

  const config = globalConfig.get()
  const [address, setAddress] = useState(initAddress)
  const [form] = Form.useForm()

  const { Option } = Select
  const usaStatesOptions = [
    <Option value="" key="none selected">
      {' '}
    </Option>,
    ...usaStatesData.map(({ name, abbreviation }) => (
      <Option value={abbreviation} key={abbreviation}>
        {name}
      </Option>
    )),
  ]

  useEffect(() => {
    if (value !== null) {
      setAddress(value)
      form.setFieldsValue(value)
    }
  }, [value])

  const isValidUnitedStatesZipcode = (data: string): boolean => {
    return /^\d{5}(-\d{4})?$/.test(data)
  }

  const update = (value: Address) => {
    setAddress(value)
    onChange(value)
  }

  const fillInAddress = (place: google.maps.places.PlaceResult) => {
    const values = Object.assign({}, initAddress)
    let address1 = ''
    let postcode = ''
    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    if (Object.keys(place).length !== 0) {
      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const componentType = component.types[0]
        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`
            break
          }
          case 'route': {
            address1 += component.short_name
            values.Address1 = address1
            break
          }
          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`
            values.Zipcode = postcode
            break
          }
          case 'locality':
            values.City = component.long_name
            break
          case 'administrative_area_level_1': {
            values.State = component.long_name
            break
          }
        }
      }
    }
    setAddress(values)
    onChange(values)
  }

  const { ref: antRef } = usePlacesWidget({
    apiKey: config.GOOGLE_API_KEY,
    options: {
      componentRestrictions: { country: 'us' },
      types: ['address'],
    },
    onPlaceSelected: (place: google.maps.places.PlaceResult) => {
      fillInAddress(place)
    },
  })

  return (
    <div className="Home" data-testid={testId}>
      <Form.Item
        id="Address"
        name={[groupFormFieldName || '', 'Address1']}
        label="Address 1"
        rules={[rulesConstructor({ message: 'Please input address line 1' })]}
      >
        <Input
          ref={(c) => {
            //@ts-ignore
            if (c) antRef.current = c.input
          }}
          value={address?.Address1 ?? ''}
          onChange={(e) => update({ ...address, Address1: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        id="Address2"
        name={[groupFormFieldName || '', 'Address2']}
        label="Address 2"
        rules={[
          rulesConstructor({
            type: 'string',
            whitespace: true,
            required: false,
          }),
        ]}
      >
        <Input
          value={address?.Address2}
          onChange={(e) => update({ ...address, Address2: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        id="City"
        name={[groupFormFieldName || '', 'City']}
        label="City"
        rules={[
          rulesConstructor({
            message: 'Please input city',
            type: 'string',
            whitespace: true,
          }),
        ]}
      >
        <Input
          value={address?.City}
          onChange={(e) => update({ ...address, City: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        id="State"
        name={[groupFormFieldName || '', 'State']}
        label="State"
        rules={[
          rulesConstructor({
            required: true,
            message: 'Please input state',
          }),
        ]}
        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
      >
        <Select
          onChange={(e: string) => update({ ...address, State: e })}
          value={address?.State}
        >
          {usaStatesOptions}
        </Select>
      </Form.Item>
      <Form.Item
        id="Zipcode"
        name={[groupFormFieldName || '', 'Zipcode']}
        label="Zip code"
        rules={[
          rulesConstructor({
            required: true,
            message: 'Please input zipcode',
          }),
          () => ({
            validator(_, value) {
              if (value && !isValidUnitedStatesZipcode(value)) {
                return Promise.reject(
                  new Error('Zip Code formats: 00000 or 00000-0000')
                )
              }
              return Promise.resolve()
            },
          }),
        ]}
        style={{
          display: 'inline-block',
          width: 'calc(50% - 8px)',
          margin: '0 8px',
        }}
      >
        <Input
          value={address?.Zipcode}
          onChange={(e) => update({ ...address, Zipcode: e.target.value })}
        />
      </Form.Item>
    </div>
  )
}
export default AddressAutocomplete
