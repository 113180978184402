import { format, isBefore, isEqual, isValid } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import _ from 'lodash'

import { AppointmentType } from '../../components/Scheduling/AppointmentSettings'
import { RoomItem } from '../../containers/Authentication/Locations'
import { Teammate } from '../../shared-types'
import { MultiSelectOptions } from '../BaseComponents/Form'
import { Timezone } from './constants'

export const formatProviderName = (
  provider: Teammate,
  isDeactivated = false
): string => {
  const {
    firstName = '',
    lastName = '',
    name = '',
    credential,
    email,
  } = provider
  let formattedName = `${firstName} ${lastName}`.trim()
  if (!firstName && !lastName) {
    formattedName = name
  }
  if (credential && formattedName) {
    formattedName += `, ${credential}`
  }

  if (!formattedName) {
    formattedName = email
  }

  if (isDeactivated) {
    formattedName += ' (Deactivated)'
  }
  return formattedName
}

export function generateProviderOptions(
  allTeam: Teammate[],
  selectedTeammate: string[] = []
): MultiSelectOptions[] {
  const filterOutDeactivated = (user: Teammate) => {
    // filter out users that have been deactivated (UNLESS they are currently selected)
    const isUserDeactivatedAndSelected =
      user.isDeactivated && selectedTeammate.includes(user.cognitoId)
    const isUserActive = !user.isDeactivated && user.isDeactivated !== null
    return isUserActive || isUserDeactivatedAndSelected
  }

  const formatTeammatesIntoOptions = (user: Teammate) => {
    const { cognitoId: value, email, isDeactivated } = user
    const formattedName = formatProviderName(user, isDeactivated) || email
    return {
      name: formattedName,
      label: formattedName,
      value,
    }
  }
  // Normalize the active providers list for react-select-search
  return allTeam.filter(filterOutDeactivated).map(formatTeammatesIntoOptions)
}

export const sortCategories = (
  categories: RoomItem[] | Teammate[] | AppointmentType[]
): RoomItem[] | Teammate[] | AppointmentType[] => {
  const newCategories = _.cloneDeep(categories)

  newCategories.sort((category, nextCategory) => {
    const createdAt = category.createdAt
    const createdAtToCompare = nextCategory.createdAt

    // If we are missing a value from either category or next, then we want to use
    // default values
    if (createdAt && !createdAtToCompare) return 1
    if (!createdAt && createdAtToCompare) return -1
    if (!createdAt || !createdAtToCompare) return 0

    const date = new Date(createdAt)
    const dateToCompare = new Date(createdAtToCompare)
    if (isValid(date) && !isValid(dateToCompare)) return 1
    if (!isValid(date) && isValid(dateToCompare)) return -1
    if (!isValid(date) && !isValid(dateToCompare)) return 0

    const doDatesMatch = isEqual(date, dateToCompare)
    if (doDatesMatch) return 0
    const isDateEarlier = isBefore(date, dateToCompare)
    return isDateEarlier ? -1 : 1
  })

  return categories
}

export const HOUR_PARSE_FORMAT = 'h:mmaaa'
const MINUTE_STEP = 15
export const formatTimeInUTCWithRounding = (date: Date) => {
  const coeff = 1000 * 60 * MINUTE_STEP
  const day = Math.round(date.getTime() / coeff) * coeff

  const hour = format(day, HOUR_PARSE_FORMAT)
  return { day: new Date(day), hour }
}

export const formatTime = (
  date: Date,
  timezone: string,
  shouldRoundToMinuteStep: boolean
) => {
  if (shouldRoundToMinuteStep) {
    const coeff = 1000 * 60 * MINUTE_STEP
    const day = utcToZonedTime(
      new Date(Math.round(date.getTime() / coeff) * coeff),
      timezone
    )
    const hour = format(day, HOUR_PARSE_FORMAT)
    return { day, hour }
  }
  const day = utcToZonedTime(date.getTime(), timezone)
  const hour = format(day, HOUR_PARSE_FORMAT)
  return { day, hour }
}

export const convertOffsetToTimezomeAbbreviation = (
  offset: string,
  selectedTimezone: Timezone
) => {
  if (!offset.includes('GMT')) {
    return offset
  }

  const ianaToAbbrevation: Record<Timezone, Record<string, string>> = {
    'America/New_York': {
      'GMT-4': 'EDT',
      'GMT-5': 'EST',
    },
    'America/Chicago': {
      'GMT-5': 'CDT',
      'GMT-6': 'CST',
    },
    'America/Denver': {
      'GMT-6': 'MDT',
      'GMT-7': 'MST',
    },
    'America/Phoenix': {
      'GMT-7': 'MST',
    },
    'America/Los_Angeles': {
      'GMT-7': 'PDT',
      'GMT-8': 'PST',
    },
    'America/Anchorage': {
      'GMT-8': 'AKDT',
      'GMT-9': 'AKST',
    },
    'Pacific/Honolulu': {
      'GMT-9': 'HDT',
      'GMT-10': 'HST',
    },
  }

  return ianaToAbbrevation[selectedTimezone][offset]
}

export const convertHexToRGBA = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
