import React from 'react'

import { Switch as AntDSwitch, SwitchProps as AntDSwitchProps } from 'antd'
import classNames from 'classnames'

import { TestId } from '../../shared-types'
import Text from './Text'

import styles from './_shared.module.scss'

type SwitchProps = {
  label?: string
  onToggle: () => void
  leftLabel?: boolean
} & AntDSwitchProps &
  TestId

const Label = ({ label, disabled }: { label: string; disabled?: boolean }) => {
  return (
    <Text style={{ cursor: !disabled ? 'pointer' : undefined }}>{label}</Text>
  )
}

export const Switch: React.FC<SwitchProps> = ({
  className,
  label,
  leftLabel,
  testId,
  size = 'small',
  checked,
  disabled,
  onToggle,
  ...rest
}) => {
  function handleClick() {
    if (disabled) return
    // CC-2025: PatientSettings.js (and others?) provides onChange instead of
    //   onToggle, which causes this to fail because undefined.
    if (onToggle) onToggle()
  }

  return (
    <div className={classNames(styles.switch, className)} onClick={handleClick}>
      {leftLabel && label && <Label label={label} disabled={disabled} />}
      <AntDSwitch
        data-testid={testId}
        checked={checked}
        size={size}
        disabled={disabled}
        {...rest}
      />
      {!leftLabel && label && <Label label={label} disabled={disabled} />}
    </div>
  )
}
