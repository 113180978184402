import { useCallback, useEffect, useState } from 'react'

import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { submitDeletePatientDocument } from '../../api/api-lib'
import { getClinicalNotesUrl } from '../../containers/Patient/ClinicalNotes/utils'
import { useProfileUrlParams } from '../../hooks/useProfileUrlParams'
import { getS3FilePrivate, getS3ImagePrivate } from '../../libs/awsLib'
import { downloadPDFToBrowser } from '../../libs/file-handling'
import { notification } from '../../libs/notificationLib'
import { formatDate, parseDateOrNull, useQuery } from '../../libs/utils'
import { PatientDocumentType } from '../../stories/Invoice/constants'
import ConfirmationModal from './ConfirmationModal'
import PDFModal from './PDFModal'

// FileCard
//    input: title, file, file upload date, key (a string: title + filename + date)
export default function FileCard({
  handleApiChange,
  fileContainer: file = {},
  isIntakeForm = false,
  openDocument = '',
  patientId = '',
  providerId = '',
  uploadSuperbill,
}: {
  handleApiChange: () => void
  fileContainer: any
  patientId: string
  providerId: string
  isIntakeForm?: boolean
  openDocument?: string
  uploadSuperbill?: (documentReference: string) => void
}) {
  const history = useHistory()
  const query = useQuery()
  const {
    title,
    documentReference,
    fileUrl: uri,
    name,
    noteDate,
    status,
    documentType,
  } = file
  let dateTimeUploaded = file.dateTimeUploaded
  // TODO: fix this logic on the backend for insurance cards
  if (documentType === 'INSURANCE') {
    let newDate
    if (!dateTimeUploaded) {
      newDate = parseDateOrNull(
        name?.split?.('#')?.[0],
        true,
        'yyyy-MM-dd_hhmm_aa'
      )
    }
    dateTimeUploaded = formatDate({
      shouldLocalize: true,
      value: newDate || dateTimeUploaded,
      format: 'MMM dd, yyyy hh:mm a',
    })
  }
  // fileContainer: same as file/props.fileContainer
  // but contains the actual file data
  const [fileContainer, setFileContainer] = useState(file)
  const { urlParams } = useProfileUrlParams(providerId)
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const isInsuranceCard = documentType === 'INSURANCE'
  const isSuperBill = documentType === PatientDocumentType.SUPERBILL
  const isPDFType = name?.toLowerCase().match('.pdf')
  const isValidImageType = ['.tiff', '.jpg', '.jpeg', '.png'].some((fileType) =>
    name?.toLowerCase().match(fileType)
  )

  const downloadFile = useCallback(async () => {
    if (isInsuranceCard) {
      file.file = { uri }
    } else if (isValidImageType) {
      const img = await getS3ImagePrivate(documentReference, providerId, {
        ResponseCacheControl: 'max-age=0',
      })
      file.uri = img
    } else {
      const result = await getS3FilePrivate(documentReference, providerId, {
        ResponseCacheControl: 'max-age=0',
      })
      file.file = { data: result }
    }
    setFileContainer(file)
  }, [file, providerId, isValidImageType])

  const handleShow = async () => {
    setIsLoading(true) // critical for larger files to show something is loading
    try {
      if (fileContainer.file === undefined) await downloadFile()
      setShow(true)
    } catch (e) {
      setShow(false)
      console.error('error downloading document', e)
      notification(
        'Encountered an error while viewing document. Please contact support if the issue persists.',
        'error'
      )
    } finally {
      setIsLoading(false)
    }
  }

  // needed only to prevent delete
  // todo: make filcontainer hook obsoletexp
  // and only need to depend on file prop
  useEffect(() => setFileContainer(file), [file])

  // have pdf open on page load if being told to do so from navigation url
  useEffect(() => {
    if (`${documentType}#${openDocument}` === documentReference) {
      handleShow()
      // remove this documentReference from query so that we do not show it again on page refresh
      query.delete('documentReference')
      history.replace({ search: query.toString() })
    }
  }, [])

  async function handleDownload() {
    if (uri) return window.open(uri, '_blank')

    setIsLoading(true)
    try {
      const data = await getS3FilePrivate(documentReference, providerId, {
        ResponseCacheControl: 'max-age=0',
      })
      if (!data) throw new Error()
      // we need to set file extensions for superbills, intake forms,
      // or files that do not have a name property
      const fileTitle =
        isIntakeForm || !name || !!documentType ? `${title}.pdf` : name
      downloadPDFToBrowser(data, fileTitle)
    } catch (e) {
      console.error('error downloading document', e)
      notification(
        'Encountered an error while downloading document. Please contact support if the issue persists.',
        'error'
      )
    } finally {
      setIsLoading(false)
    }
  }

  function handleViewNote() {
    // slice off 'SUPERBILL#' from DocumentReference string so the rest is the NoteId
    const noteId = documentReference.substr(10)
    history.push(getClinicalNotesUrl({ urlParams, noteId }))
  }

  // This is triggered in the confirmation modal
  async function handleDelete() {
    setShowDeleteModal(false)
    setFileContainer(null)
    const requestData = {
      PatientId: patientId,
      DocumentReference: documentReference,
    }
    await submitDeletePatientDocument(requestData)
    handleApiChange()
    notification(
      'You have successfully deleted a file. Please wait a moment for the list to update.',
      'success'
    )
  }

  const renderDeleteConfirmationModal = () => {
    return (
      <ConfirmationModal
        id="deleteConfirmationModal"
        title="Are you sure?"
        okText={'Delete'}
        body={
          <>
            <p>
              You are about to permanently delete the following document:{' '}
              <span className="font-weight-bold">{title || 'Untitled'}</span>
            </p>
            <p>
              <span className="danger">WARNING: </span>
              Deleting this file could violate your state's laws regarding
              retention of medical records. Only delete this file if you have a
              legitimate reason for doing so (duplicate files, wrong files
              uploaded, etc.), or you are sure that this will not violate the
              laws of your state. This action cannot be undone.
            </p>
            <p>
              Are you really sure you want to delete{' '}
              <span className="font-weight-bold">{title || 'Untitled'}</span>?
            </p>
          </>
        }
        size="lg"
        show={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
      />
    )
  }

  const card = (
    <>
      {fileContainer && (
        <Container
          bsPrefix="patient-card"
          key={`${title}-${name}-${dateTimeUploaded}`}
          id={`fileCard-${title}`}
        >
          <Row className="justify-contents-center">
            <Col
              sm={12}
              md={3}
              className="info emphasis"
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
            >
              {/* title is only displayed when present */}
              {title ?? 'Untitled'}
            </Col>
            <Col
              sm={12}
              md={2}
              className="emphasis-light"
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
            >
              {name}
            </Col>
            <Col
              sm={12}
              md={3}
              className="emphasis-light"
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
            >
              {dateTimeUploaded}
            </Col>
            <Col
              sm={12}
              md={2}
              className="emphasis-light"
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
            >
              {noteDate ? noteDate : status}
            </Col>
            <Col sm={12} md={2}>
              <Row style={{ width: '140px' }}>
                {!isLoading ? (
                  <>
                    <Col>
                      {isSuperBill && (
                        <div
                          className="view-note-from-superbill"
                          style={{ position: 'absolute', right: '30px' }}
                        >
                          <Button
                            style={{ width: '90px' }}
                            onClick={handleViewNote}
                            variant="outline-secondary"
                            size="sm"
                          >
                            View Note
                          </Button>
                        </div>
                      )}
                      <Button
                        id={'viewFile' + title}
                        variant="link"
                        style={{ padding: '0px' }}
                        onClick={handleShow}
                        disabled={
                          !documentType && !(isValidImageType || isPDFType)
                        }
                      >
                        <i
                          className="very-large fa fa-eye"
                          aria-hidden="true"
                        />
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        data-testid="download-file-button"
                        id={'downloadFile' + title}
                        variant="link"
                        style={{ padding: '0px' }}
                        onClick={handleDownload}
                      >
                        <i
                          className="very-large fa fa-arrow-circle-o-down"
                          aria-hidden="true"
                        />
                      </Button>
                    </Col>
                    {isSuperBill && uploadSuperbill && (
                      <Col style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-top">
                              Replace Superbill with a different document
                            </Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <Button
                              style={{ display: 'contents' }}
                              bsPrefix="button-block"
                              onClick={() => uploadSuperbill(documentReference)}
                            >
                              <i
                                className="very-large fa fa-undo"
                                aria-hidden="true"
                              />
                            </Button>
                          </span>
                        </OverlayTrigger>
                      </Col>
                    )}
                    {!isInsuranceCard && (
                      <Col>
                        <Button
                          id={`deleteFile-${title}`}
                          variant="link"
                          style={{ color: '#bc422a', padding: '0px' }}
                          onClick={() => setShowDeleteModal(true)}
                        >
                          <i
                            className="very-large fa fa-trash-o"
                            aria-hidden="true"
                          />
                        </Button>
                      </Col>
                    )}
                  </>
                ) : (
                  <Row>
                    {' '}
                    <div>
                      <Spinner
                        animation="border"
                        variant="info"
                        className="loader-button-spinner"
                      />
                      &nbsp; Loading...
                    </div>{' '}
                  </Row>
                )}
              </Row>
            </Col>
          </Row>
          <PDFModal
            fileContainer={file}
            show={show}
            attachment={null}
            handleShow={setShow}
          />
        </Container>
      )}
    </>
  )

  const intakeCard = (
    <>
      <Button
        style={{ display: 'contents' }}
        bsPrefix="button-block"
        onClick={handleShow}
      >
        <i className="very-large fa fa-eye ml-3" aria-hidden="true" />
      </Button>
      <Button
        data-testid="download-file-button"
        style={{ display: 'contents' }}
        bsPrefix="button-block"
        onClick={handleDownload}
      >
        <i
          className="very-large fa fa-arrow-circle-o-down ml-3"
          aria-hidden="true"
        />
      </Button>
      <PDFModal
        fileContainer={file}
        show={show}
        attachment={null}
        handleShow={setShow}
      />
    </>
  )

  // return just view and download button if it's an intake form, otherwise show all
  // document fields
  return (
    <>
      {renderDeleteConfirmationModal()}
      {isIntakeForm ? intakeCard : card}
    </>
  )
}
