import React, { CSSProperties, useEffect } from 'react'

import { SpringConfig, animated, useTransition } from '@react-spring/web'

type Props = {
  isLoading: boolean
  springConfig?: SpringConfig
  skeletonComponent: React.ReactNode
  loadedComponent: React.ReactNode
  skeletonWrapperClassName?: string
  loadedWrapperClassName?: string
  skeletonWrapperStyle?: CSSProperties
  loadedWrapperStyle?: CSSProperties
}

const SkeletonLoadingTransition = ({
  isLoading,
  springConfig,
  skeletonComponent,
  loadedComponent,
  skeletonWrapperClassName = '',
  loadedWrapperClassName = '',
  skeletonWrapperStyle = {},
  loadedWrapperStyle = {},
}: Props) => {
  const [transitions, api] = useTransition(isLoading, () => ({
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 170,
      friction: 20,
      ...springConfig,
    },
  }))

  useEffect(() => {
    api.start()
  }, [isLoading])

  return transitions((springs, isLoading) =>
    isLoading ? (
      <animated.div
        style={{
          width: '100%',
          position: 'absolute',
          ...skeletonWrapperStyle,
          ...springs,
        }}
        className={skeletonWrapperClassName}
      >
        {skeletonComponent}
      </animated.div>
    ) : (
      <animated.div
        className={loadedWrapperClassName}
        style={{
          ...loadedWrapperStyle,
          ...springs,
        }}
      >
        {loadedComponent}
      </animated.div>
    )
  )
}

export default SkeletonLoadingTransition
