import React, { useState } from 'react'

import { Skeleton } from 'antd'
import cx from 'classnames'

import SkeletonLoadingTransition from '../../components/Animation/SkeletonLoadingTransition'
import { isValidUrl } from '../../libs/utils'
import { Avatar, Tooltip } from '../BaseComponents'
import { AntDPopover as Popover } from '../BaseComponents/Popover'
import ProfilePhotoUpload from './ProfilePhotoUpload'

import styles from './ProfilePhoto.module.scss'

interface ProfilePhotoProps {
  loading?: boolean
  providerId: string
  patientName?: string
  patientFirstName?: string
  patientLastName?: string
  imageUrl?: string
  setImageUrl: (newValue: string) => void
  isImageLoading?: boolean
  collapsed?: boolean
  setIsImageLoading: (newValue: boolean) => void
  displayName?: string
}

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({
  loading = false,
  imageUrl = '',
  setImageUrl,
  providerId,
  patientName,
  patientFirstName,
  patientLastName,
  isImageLoading = false,
  collapsed,
  setIsImageLoading,
  displayName,
}) => {
  const urlIsValid = isValidUrl(imageUrl)
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)

  const togglePopover = () => {
    if (!collapsed) setIsPopoverVisible(!isPopoverVisible)
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setIsPopoverVisible(true)
    }

    if (event.key === 'Escape') {
      setIsPopoverVisible(false)
    }
  }

  const imageUploadComponent = (
    <ProfilePhotoUpload
      hasImage={urlIsValid}
      isPopoverVisible={isPopoverVisible}
      setIsPopoverVisible={setIsPopoverVisible}
      setIsImageLoading={setIsImageLoading}
      imageUrl={imageUrl}
      setImageUrl={setImageUrl}
      providerId={providerId}
      patientName={patientName}
    />
  )

  const skeleton = (
    <div data-testid="profile-photo-loading">
      <Skeleton.Avatar shape="square" size={collapsed ? 48 : 75} active />
    </div>
  )

  const loaded = (
    <Popover
      data-testid="avatar-popover"
      placement="bottomLeft"
      content={imageUploadComponent}
      title={urlIsValid ? 'Edit profile photo' : 'Add profile photo'}
      trigger={!collapsed ? 'click' : ''}
      visible={isPopoverVisible}
      onVisibleChange={setIsPopoverVisible}
    >
      <div
        className={cx(
          styles.profilePhotoContainer,
          collapsed && styles.collapsed
        )}
        tabIndex={0}
        onKeyDown={handleOnKeyDown}
      >
        <Avatar
          className={styles.profilePhotoAvatar}
          size={75}
          alt="user-avatar"
          shape="square"
          src={urlIsValid ? imageUrl : null}
          data-testid="profile-photo"
          onClick={togglePopover}
        >
          {!urlIsValid && (
            <span
              className={cx(
                styles.profilePhotoInitials,
                collapsed && styles.collapsed
              )}
              data-testid="profile-photo-user-initials"
            >
              {patientFirstName?.slice(0, 1).toUpperCase()}
              {patientLastName?.slice(0, 1).toUpperCase()}
            </span>
          )}
        </Avatar>
      </div>
    </Popover>
  )

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  return (
    <div style={{ position: 'relative', flex: '0 0 75px', height: 75 }}>
      <SkeletonLoadingTransition
        isLoading={loading || isImageLoading}
        skeletonComponent={skeleton}
        skeletonWrapperStyle={{ top: 0 }}
        loadedComponent={
          collapsed ? (
            <Tooltip
              overlayClassName={cx(isSafari && styles.collapsedTooltip)}
              color="#000000"
              placement={'right'}
              title={displayName}
            >
              {loaded}
            </Tooltip>
          ) : (
            loaded
          )
        }
      />
    </div>
  )
}

export default ProfilePhoto
