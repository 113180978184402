import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Form, Modal } from 'antd'
import _ from 'lodash'

import { createRoom, updateRoom } from '../../api/api-lib-typed'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import { Input, Modal as OsmindModal } from '../../stories/BaseComponents'
import { RoomItem, RoomTableItem } from './Locations'

import './Locations.scss'

interface Props {
  show: boolean
  getRooms: () => void
  onHide: () => void
  editRoomItem: RoomTableItem | undefined
  locationId: string
  rooms: RoomItem[] | undefined
}

interface FormValues {
  roomName: string
}

enum Mode {
  EDIT = 'isEdit',
  ADD = 'isAdd',
}

const RoomModal: React.FC<Props> = ({
  show,
  onHide,
  editRoomItem,
  getRooms,
  locationId,
  rooms,
}) => {
  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState<boolean>(false)
  const mode = useMemo(
    () => (editRoomItem ? Mode.EDIT : Mode.ADD),
    [editRoomItem]
  )

  const roomIdRef = React.useRef(editRoomItem?.RoomId)

  useEffect(() => {
    roomIdRef.current = editRoomItem?.RoomId
  }, [editRoomItem])

  const initialValues: FormValues = {
    roomName: editRoomItem?.RoomName ?? '',
  }

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [show])

  const checkFormChanged = () => {
    const values = {
      ...initialValues,
      ...form.getFieldsValue(),
    }
    if (_.isEqual(values, initialValues)) return false
    return true
  }

  const createNewRoom = (values: FormValues) => {
    const item = {
      name: values.roomName,
      locationId: locationId,
    }

    return createRoom(item)
  }

  const editRoom = async (values: FormValues) => {
    const roomId = roomIdRef.current
    if (roomId) {
      return updateRoom(roomId, {
        name: values.roomName,
      })
    }
  }

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        setLoading(true)
        if (mode === Mode.ADD) {
          await createNewRoom(values)
          notification('Successfully added a room.', 'success')
        } else {
          await editRoom(values)
          notification('Successfully edited a room.', 'success')
        }
        onHide()
        getRooms()
      } catch (e) {
        console.error(e)
        onError(new Error('Unable to add a room'))
        onHide()
      }
      setLoading(false)
    },
    [mode]
  )

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values)
      })
      .catch((info) => {
        console.log('Validate Failed: ', info)
      })
  }

  const handleClose = () => {
    if (!checkFormChanged()) {
      setLoading(false)
      onHide()
    } else {
      Modal.confirm({
        title: 'Unsaved changes',
        content:
          'Are you sure you want to close this window? Any unsaved changes will be lost.',
        okText: 'Ok',
        cancelText: 'Cancel',
        cancelButtonProps: {
          type: 'default',
        },
        okButtonProps: {
          type: 'primary',
          loading: isLoading,
        },
        onOk: () => {
          setLoading(false)
          onHide()
        },
      })
    }
  }

  return (
    <OsmindModal
      confirmLoading={isLoading}
      closable={true}
      title="Room/resource"
      visible={show}
      onOk={handleOk}
      onCancel={handleClose}
    >
      <Form
        form={form}
        onFinish={handleOk}
        scrollToFirstError
        layout="vertical"
      >
        <Form.Item
          className="top-ant-form-item"
          name="roomName"
          label="Room/resource name"
          rules={[
            {
              required: true,
              message: 'Please input a room/resource name!',
              type: 'string',
              whitespace: true,
            },
            () => ({
              validator(_, value) {
                if (!rooms) {
                  return Promise.resolve()
                }
                const roomAlreadyExists = rooms.find(
                  (r) => r.roomName === value
                )
                if (roomAlreadyExists) {
                  return Promise.reject(
                    new Error(
                      'This room/resource already exists within this location.'
                    )
                  )
                } else {
                  return Promise.resolve()
                }
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </OsmindModal>
  )
}

export default RoomModal
