import { Link } from 'react-router-dom'

import styles from './EmptyFilter.module.scss'

export type EmptyFilterProps = {
  bodyText: string
  linkText: string
  linkUrl: string
}

export const EmptyFilter = ({
  bodyText,
  linkText,
  linkUrl,
}: EmptyFilterProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.text}>{bodyText}</p>
      <Link className={styles.link} to={linkUrl}>
        {linkText}
      </Link>
    </div>
  )
}
