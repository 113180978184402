import React from 'react'

import { ModalBasic } from '../../../../stories/BaseComponents'

type DeleteClaimModalProps = {
  onDeleteClaim: () => void
  onCancel: () => void
  visible: boolean
  isDeleteClaimLoading: boolean
}

export const testIds = {
  content: 'delete-claim-modal-content',
  cancelButton: 'delete-claim-modal-cancel-button',
  confirmButton: 'delete-claim-modal-confirm-button',
}

const DeleteClaimModal: React.FC<DeleteClaimModalProps> = ({
  onDeleteClaim,
  onCancel,
  visible,
  isDeleteClaimLoading,
}) => (
  <ModalBasic
    visible={visible}
    title={'Delete claim draft'}
    content={
      'Are you sure you want to delete this claim draft? This action cannot be undone.'
    }
    onCancel={onCancel}
    onConfirm={onDeleteClaim}
    cancelButtonProps={{
      disabled: isDeleteClaimLoading,
      testId: testIds.cancelButton,
    }}
    confirmButtonLabel={'Delete'}
    confirmButtonProps={{
      danger: true,
      loading: isDeleteClaimLoading,
      disabled: isDeleteClaimLoading,
      testId: testIds.confirmButton,
    }}
    contentTestId={testIds.content}
  />
)

export default DeleteClaimModal
