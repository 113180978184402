import React from 'react'

import { blue } from '@ant-design/colors'
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { ColumnType } from 'antd/lib/table'
import { Link } from 'react-router-dom'

import { getNotesByDateRange } from '../../../api/notes'
import { getClinicalNotesUrl } from '../../../containers/Patient/ClinicalNotes/utils'
import { QUERY_PARAMS } from '../../../libs/constants'
import { SearchBox, Tag, Tooltip } from '../../BaseComponents'
import { NotesReportData } from '../../BaseComponents/tableData.types'
import {
  METRICS_HEADER_TOTAL_NOTES,
  METRICS_HEADER_UNSIGNED_NOTES,
} from '../constants'
// Replace with structured map of provider list
import { GraphBatchingConfigs, TreatmentListEnum } from '../types'
import SpravatoNoteDaysToSignCell from './SpravatoNoteDaysToSignCell'

import themeStyles from '../../../_theme.module.scss'

export const defaultStringSorter = (a: string, b: string) => {
  return a.localeCompare(b)
}

export const defaultDateSorter = (a: Date, b: Date) => {
  return a.valueOf() - b.valueOf()
}

export const caseInsensitiveFilter = (
  value: string | number | boolean,
  record: string
): boolean => {
  return record
    .toString()
    .toLowerCase()
    .includes(value.toString().toLowerCase())
}

const PATIENT_COLUMN: ColumnType<NotesReportData> = {
  title: 'Patient name',
  dataIndex: ['patientName', 'publicId'],
  key: 'patientName',
  render: (_text, record) => (
    <Link
      style={{ color: blue.primary }}
      to={`/patient/clinical-notes?patientId=${record.patientId}&providerId=${record.providerId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {record.patientName}
    </Link>
  ),
  sorter: (a, b) => defaultStringSorter(a.patientName, b.patientName),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? blue.primary : undefined }} />
  ),
  filterDropdown: (args) => (
    <SearchBox
      dataIndex="patientName"
      placeholder="Search Patient Name"
      {...args}
    />
  ),
  onFilter: (value, record: NotesReportData) =>
    caseInsensitiveFilter(value, record.patientName),
}

const TYPE_COLUMN: ColumnType<NotesReportData> = {
  title: 'Type',
  dataIndex: 'type',
  key: 'type',
}

const TITLE_COLUMN: ColumnType<NotesReportData> = {
  title: 'Title',
  dataIndex: 'title',
  key: 'title',
  render: (_text, record) => (
    <Link
      style={{ color: blue.primary }}
      to={getClinicalNotesUrl({
        noteId: record.noteId,
        // TODO: add a helper for constructing URLs with params
        // https://osmind.atlassian.net/browse/PRAC-2117
        urlParams: `${QUERY_PARAMS.patientId}=${record.patientId}&${QUERY_PARAMS.providerId}=${record.providerId}`,
      })}
      target="_blank"
      rel="noopener noreferrer"
    >
      {record.title}
    </Link>
  ),
}

const DATE_COLUMN: ColumnType<NotesReportData> = {
  title: 'Date',
  dataIndex: 'date',
  key: 'date',
  sorter: (a, b) => defaultDateSorter(a.date, b.date),
  render: (date) => date.toLocaleDateString(),
}

const DAYS_TO_SIGN_COLUMN: ColumnType<NotesReportData> = {
  title: (
    <span>
      Days to sign
      <Tooltip
        title="Spravato REMS forms must be submitted within 7 days of the appointment. Forms are automatically submitted when notes are signed."
        // Place on bottom to avoid overlap with table column tooltips.
        placement={'bottom'}
      >
        <ExclamationCircleOutlined
          style={{ color: themeStyles.gray9, padding: '0 5px' }}
        />
      </Tooltip>
    </span>
  ),
  dataIndex: 'date',
  key: 'days',
  sorter: (a, b) => defaultDateSorter(a.date, b.date),
  render: (date) => <SpravatoNoteDaysToSignCell date={date} />,
}

const SIGNATURE_COLUMN: ColumnType<NotesReportData> = {
  title: 'Signature',
  dataIndex: 'signatures',
  key: 'signatures',
  render: (tag) => (
    <Tag color={tag?.length > 0 ? 'green' : 'red'} key={tag}>
      {tag?.length > 0 ? 'Signed' : 'Unsigned'}
    </Tag>
  ),
}

export const NOTES_COLUMNS: ColumnsType<NotesReportData> = [
  PATIENT_COLUMN,
  {
    ...TYPE_COLUMN,
    sorter: (a, b) => defaultStringSorter(a.type, b.type),
    filters: Object.values(TreatmentListEnum).map((treatment) => ({
      text: treatment,
      value: treatment,
    })),
    onFilter: (value, record) =>
      caseInsensitiveFilter(value, record.type.toString()),
  },
  TITLE_COLUMN,
  {
    ...DATE_COLUMN,
    // Default newest -> oldest.
    defaultSortOrder: 'descend',
  },
  {
    ...SIGNATURE_COLUMN,
    filters: [
      { text: 'Signed', value: 'Signed' },
      { text: 'Unsigned', value: 'Unsigned' },
    ],
    onFilter: (value, record) =>
      record.signatures?.length > 0 ? value === 'Signed' : value === 'Unsigned',
  },
]

export const SPRAVATO_NOTES_COLUMNS: ColumnsType<NotesReportData> = [
  PATIENT_COLUMN,
  TYPE_COLUMN,
  TITLE_COLUMN,
  {
    ...DATE_COLUMN,
    // Default oldest -> newest.
    defaultSortOrder: 'ascend',
  },
  DAYS_TO_SIGN_COLUMN,
  SIGNATURE_COLUMN,
]

export default {
  fetch: getNotesByDateRange,
  header: {
    description: 'Explore your practice’s notes.',
    link: {
      url: 'https://support.osmind.org/en/articles/5665898-reports',
      text: 'Learn more.',
    },
    metrics: [
      { title: METRICS_HEADER_TOTAL_NOTES, dataSource: 0 },
      { title: METRICS_HEADER_UNSIGNED_NOTES, dataSource: 0 },
    ],
    title: 'Notes',
  },
  graph: {
    dataBatchingKeys: [],
  },
  graphFilterConfigs: [],
  dataBatching: {
    options: [],
    fetchKeyAlias: {},
  } as GraphBatchingConfigs,
  table: {
    columns: NOTES_COLUMNS,
    csvHeaders: [
      { label: 'Patient name', key: 'patientName' },
      { label: 'Type', key: 'type' },
      { label: 'Title', key: 'title' },
      { label: 'Date', key: 'date' },
      { label: 'Signature', key: 'csvSigned' },
    ],
  },
}
