import React from 'react'

import { Container, Form, Row } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'

import './../PatientProfile.scss'

export default function NeurocognitiveSaved(props) {
  const hasNoRelevantHistory =
    props.patientData.NoOtherConditions === true &&
    !props.patientData.OtherConditionsAdditionalNotes

  const noRecordedInfo =
    props.patientData.NoOtherConditions === false &&
    !props.patientData.OtherConditionsAdditionalNotes

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Other conditions"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">Other conditions:</Form.Row>
      <Form.Group className="border-bottom indent">
        {props.patientData.OtherConditionsAdditionalNotes && (
          <Container bsPrefix="psych">
            <Form.Row className="patient-dark">Additional Notes:</Form.Row>
            <Row bsPrefix="psych-sub">
              {props.patientData.OtherConditionsAdditionalNotes}
            </Row>
          </Container>
        )}
      </Form.Group>
    </Form.Group>
  )
}
