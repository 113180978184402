import { useState } from 'react'

import { notification } from '../../../../libs/notificationLib'
import {
  Button,
  Modal,
  TextAreaInput,
} from '../../../../stories/BaseComponents'
import { Note } from '../../types'

import styles from './AddendumModal.module.scss'

export type AddendumModalProps = {
  providerName: string
  isAddAddendumModalVisible: boolean
  setIsModalVisible: (isVisible: boolean) => void
  saveAddendum: (addendumText: string) => Promise<Partial<Note>>
  isSaving: boolean
}

export const AddendumModal = ({
  isAddAddendumModalVisible,
  saveAddendum,
  setIsModalVisible,
  isSaving,
  providerName,
}: AddendumModalProps) => {
  const [addendumText, setAddendumText] = useState('')
  const handleAddendumTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAddendumText(e.target.value)
  }
  const handleAddAddendumClick = async () => {
    try {
      await saveAddendum(addendumText)
      setAddendumText('')
      setIsModalVisible(false)
      notification('Addendum added', 'success')
    } catch (e) {
      notification('Unable to Add addendum', 'error')
      console.error('Unable to Add addendum: ', e)
    }
  }

  const handleCancelClick = () => {
    setAddendumText('')
    setIsModalVisible(false)
  }

  return (
    <Modal
      visible={isAddAddendumModalVisible}
      zIndex={5000}
      centered
      mask
      title="Create addendum"
      width={600}
      onCancel={handleCancelClick}
      footer={[
        <Button
          style={{ position: 'relative', float: 'left', marginLeft: '8px' }}
          key="cancel"
          onClick={handleCancelClick}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={isSaving || !addendumText}
          onClick={handleAddAddendumClick}
          style={{ marginRight: '8px' }}
        >
          Add addendum
        </Button>,
      ]}
    >
      <div className={styles.contentContainer}>
        <div className={styles.createdByContainer}>
          <p className={styles.createdByLabel}>Created by</p>
          <p className={styles.createdByValue}>{providerName}</p>
        </div>
        <div className={styles.addendumTextInputContainer}>
          <p className={styles.addendumContentLabel}>
            Addendum content <span className={styles.required}>*</span>
          </p>
          <TextAreaInput
            value={addendumText}
            allowClear
            placeholder="Add addendum content here"
            rows={5}
            testId="addendum-textarea"
            onChange={handleAddendumTextChange}
          />
        </div>
      </div>
    </Modal>
  )
}
