import React, { useMemo, useState } from 'react'

import { Form, FormInstance, Typography } from 'antd'
import Radio from 'antd/lib/radio'

import {
  educationLevelDropDownValues,
  employmentStatusDropDownValues,
  relationshipStatusDropDownValues,
  substanceUseDropdownValues,
} from '../../../shared/Demographics'
import { Divider, Select } from '../../BaseComponents'
import { required } from '../validation-rules'
import { Header, mapCodesetToListView } from './helpers'
import { SocialHistoryQuestionKeys } from './question-keys'

const { Title } = Typography

interface Props {
  form: FormInstance
  reviewMode?: boolean
}

const SocialHistory = ({ reviewMode }: Props) => {
  const [hasMilitaryExp, setHasMilitaryExp] = useState(false)
  const substanceUse = useMemo(() => {
    return substanceUseDropdownValues.map(mapCodesetToListView)
  }, [])
  const educationLevel = useMemo(() => {
    return educationLevelDropDownValues.map(mapCodesetToListView)
  }, [])
  const employmentStatus = useMemo(() => {
    return employmentStatusDropDownValues.map(mapCodesetToListView)
  }, [])
  const relationshipStatus = useMemo(() => {
    return relationshipStatusDropDownValues.map(mapCodesetToListView)
  }, [])

  return (
    <>
      <Title level={2}>Social History</Title>
      <Divider />
      <Header title="Alcohol & substance use" />
      <Form.Item
        label="Do you drink alcohol?"
        name={SocialHistoryQuestionKeys.IS_USING_ALCOHOL}
      >
        <Radio.Group buttonStyle="solid" disabled={reviewMode} size="large">
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={SocialHistoryQuestionKeys.SUBSTANCE_USE_HISTORY}
        label="Please select any of the following substances that you have previously used without the supervision of a medical professional"
      >
        <Select
          size="large"
          options={substanceUse}
          placeholder="Select all that apply"
          showSearch
          allowClear
          mode="multiple"
          wrapOptions
        />
      </Form.Item>
      <Divider />
      <Header title="Background information" />
      <Form.Item
        label="Highest education level"
        name={SocialHistoryQuestionKeys.HIGHEST_EDUCATION_LEVEL}
      >
        <Select
          disabled={reviewMode}
          options={educationLevel}
          placeholder="Select one"
          size="large"
          showSearch
          allowClear
        />
      </Form.Item>
      <Form.Item
        label="Employment status"
        name={SocialHistoryQuestionKeys.EMPLOYMENT_STATUS}
      >
        <Select
          disabled={reviewMode}
          size="large"
          options={employmentStatus}
          placeholder="Select all that apply"
          showSearch
          allowClear
          mode="multiple"
        />
      </Form.Item>
      <Form.Item
        label="Marital or relational status"
        name={SocialHistoryQuestionKeys.CURRENT_RELATIONSHIP_STATUS}
      >
        <Select
          disabled={reviewMode}
          size="large"
          options={relationshipStatus}
          placeholder="Select all that apply"
          showSearch
          allowClear
          mode="multiple"
        />
      </Form.Item>
      <Form.Item
        label="Have you served in the military?"
        name={SocialHistoryQuestionKeys.HAS_SERVED_IN_MILITARY}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          onChange={(event) => setHasMilitaryExp(event.target.value)}
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {hasMilitaryExp && (
        <Form.Item
          label="Do you have combat experience?"
          name={SocialHistoryQuestionKeys.HAS_COMBAT_EXPERIENCE}
          rules={hasMilitaryExp ? [required('Please select an option.')] : []}
        >
          <Radio.Group buttonStyle="solid" disabled={reviewMode} size="large">
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>
        </Form.Item>
      )}
      <Divider />
    </>
  )
}

export default SocialHistory
