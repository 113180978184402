import React, { useCallback, useMemo } from 'react'

import { FormInstance, Row } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { difference, intersection, union, without } from 'lodash'

import { prefixedCodesetCategoryRegex } from '../../../../libs/regex'
import { PatientDemographicObject } from '../../../../shared/Demographics'
import * as MedicalConditions from '../../../../shared/MedicalConditions'
import { Checkbox, CheckboxGroup, MultiSelect } from '../../../BaseComponents'
import CheckboxesWithForm from '../CheckboxesWithForm'
import { MappedCodeSetOptions, mapCodesetToListView } from '../helpers'
import { MedHistoryNonPsychQuestionKeys } from '../question-keys'

import styles from '../_shared.module.scss'

enum MedicalConditionTypes {
  CONSTITUTIONAL = 'con.',
}

const ConditionsField = MedHistoryNonPsychQuestionKeys.CONDITIONS

function SubForm({
  form,
  options,
  reviewMode,
}: {
  form: FormInstance
  options: MedicalConditionsCustomCodesetMappingType[]
  reviewMode: boolean
}) {
  // @ts-ignore
  const subOptions = options.map(({ value }) => value)
  // @ts-ignore
  const items = options.map(({ label, value }) => ({
    label,
    value,
  }))
  const initialSelected: string[] = useMemo(
    () => intersection(subOptions, form.getFieldValue(ConditionsField)),
    [form]
  )

  const handleChange = useCallback(
    (newValuesForSection: string[]) => {
      // overly complicated way of making sure that the value for
      // 'CONDITIONS' is an array of strings, composed of also the
      // sub options selected under medical conditions
      const oldValuesFromForm = form.getFieldValue(ConditionsField)

      // checks if options for this section are already selected for in the form
      const overlap = intersection(subOptions, oldValuesFromForm)
      // if so, and there are more selected in the previous state than in the
      // newValuesForSection, that means an option has been removed
      const isRemoving = overlap.length > newValuesForSection.length
      if (isRemoving) {
        const removedValue = difference(overlap, newValuesForSection)
        const newValuesForForm = without(oldValuesFromForm, ...removedValue)
        form.setFields([{ name: ConditionsField, value: newValuesForForm }])
        return
      }
      const value = union(newValuesForSection, oldValuesFromForm)
      form.setFields([{ name: ConditionsField, value }])
    },
    [form]
  )

  return (
    <>
      {`What type(s) of conditions?`}
      <MultiSelect
        disabled={reviewMode}
        defaultValue={initialSelected}
        handleChange={handleChange}
        options={items}
        showSearch
        allowClear
        size="large"
      />
    </>
  )
}

interface MedicalConditionsCustomCodesetMappingType
  extends MappedCodeSetOptions {
  questionKey: string
}

const MedicalConditionsCustomCodesetMapping = (
  codeset: PatientDemographicObject[]
): MedicalConditionsCustomCodesetMappingType[] => {
  return codeset.map(mapCodesetToListView).map(({ label, ...rest }) => {
    const cleanedValue = label?.replace(prefixedCodesetCategoryRegex, '')
    return {
      ...rest,
      questionKey: cleanedValue,
      label: cleanedValue,
      value: cleanedValue,
    }
  })
}

type MedicalConditionsToDisplayType = (
  | MedicalConditionsCustomCodesetMappingType[]
  | MedicalConditionsCustomCodesetMappingType
)[]

const ConditionsList = ({
  form,
  reviewMode = false,
}: {
  form: FormInstance
  reviewMode: boolean
}) => {
  const conditions = useMemo<MedicalConditionsToDisplayType>(() => {
    const transformedMedicalConditions: MedicalConditionsCustomCodesetMappingType[][] =
      Object.values(MedicalConditions).map((conditions) => {
        return MedicalConditionsCustomCodesetMapping(conditions)
      })

    let medicalConditionsToDisplay: MedicalConditionsToDisplayType = []
    transformedMedicalConditions.forEach((conditions) => {
      // We are flattening constitutional because it does not provide the correct presentation for patients
      const conditionsShouldBeFlatCheckboxes = conditions.some((condition) => {
        return condition.title.includes(MedicalConditionTypes.CONSTITUTIONAL)
      })

      if (conditionsShouldBeFlatCheckboxes) {
        medicalConditionsToDisplay = [
          ...medicalConditionsToDisplay,
          ...conditions,
        ]
      } else {
        medicalConditionsToDisplay.push(conditions)
      }
    })

    return medicalConditionsToDisplay.sort((a, b) => {
      return (
        ((a as MedicalConditionsCustomCodesetMappingType)?.label ??
          (a as MedicalConditionsCustomCodesetMappingType[])[0]?.label) ||
        ''
      ).localeCompare(
        (b as MedicalConditionsCustomCodesetMappingType)?.label ??
          (b as MedicalConditionsCustomCodesetMappingType[])[0]?.label
      ) // sort based on the title of category
    })
  }, [])

  const onSubFormClose = useCallback(
    (options: any) => {
      // anything checked on the subform needs to be erased to make sure that
      // no diagnoses are mistakenly checked + submitted bc they weren't visible
      // @ts-ignore
      const listToRemove = options.map(({ label }) => label)
      const name = MedHistoryNonPsychQuestionKeys.CONDITIONS
      const oldValues = form.getFieldValue(name)
      const newValues = without(oldValues, ...listToRemove)
      form.setFields([{ name, value: newValues }])
    },
    [form]
  )

  const handleBasicChecboxChange = useCallback(
    (event: CheckboxChangeEvent) => {
      const oldValuesFromForm = form.getFieldValue(ConditionsField) ?? []
      const isChecked = event.target.checked
      const newValue = event.target.value
      const newValuesForForm = isChecked
        ? union(oldValuesFromForm, [newValue])
        : without(oldValuesFromForm, newValue)
      form.setFields([{ name: ConditionsField, value: newValuesForForm }])
    },
    [form]
  )

  return (
    <CheckboxGroup disabled={reviewMode}>
      {conditions.map((conditionList, key) =>
        Array.isArray(conditionList) ? (
          <div key={key}>
            <CheckboxesWithForm
              onChange={handleBasicChecboxChange}
              disabled={reviewMode}
              options={conditionList}
              showFirst
              onSubFormClose={() => onSubFormClose(conditionList.slice(1))}
              subForm={() => (
                <SubForm
                  reviewMode={reviewMode}
                  form={form}
                  options={conditionList.slice(1)}
                />
              )}
            />
          </div>
        ) : (
          <Row key={conditionList.key} className={styles.checkboxPadding}>
            <Checkbox
              disabled={reviewMode}
              value={conditionList.value}
              onChange={handleBasicChecboxChange}
            >
              {conditionList.label}
            </Checkbox>
          </Row>
        )
      )}
    </CheckboxGroup>
  )
}

export default ConditionsList
