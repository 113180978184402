// Import react
import React, { Component } from 'react'

// Import components
import { Col, Container, Row } from 'react-bootstrap'

// Import libs/other
import Answers from '../../libs/questionnairesLib/questionnaireConverter'

class ScoreCard extends Component {
  // (scores, type, clinicalNotes)
  constructor(props) {
    super(props)

    this.showModal = this.showModal.bind(this)

    this.state = {
      showModal: false,
    }
  }

  showModal() {
    if (this.state.showModal) {
      this.setState({ showModal: false })
    } else {
      this.setState({ showModal: true })
    }
  }

  render() {
    const [submission] = this.props.submissions
    return (
      <div
        id={this.props.type}
        style={{ cursor: 'pointer' }}
        onClick={this.showModal}
      >
        <Container bsPrefix="one-time-card">
          <Row>
            <Col>
              <h4 className="danger" id={`${this.props.type}-score-type`}>
                {this.props.survey.Name} Score
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="info" id={`${this.props.type}-score`}>
                {submission.Score}
              </h2>
            </Col>
          </Row>

          {this.state.showModal ? (
            <Answers
              loggedInProviderId={this.props.loggedInProviderId}
              handleApiChange={this.props.handleApiChange}
              date={submission.Date}
              type={this.props.type}
              submission={submission}
              onClose={() => this.setState({ targetSubmission: null })}
            />
          ) : (
            <></>
          )}
        </Container>
      </div>
    )
  }
}

export default ScoreCard
