import React from 'react'

import 'moment-timezone'
import { Col, Form } from 'react-bootstrap'
import 'react-dates/initialize'

import { AutoSizingTextArea } from '../../../../../../stories/BaseComponents/TextAreaInput'

import 'react-dates/lib/css/_datepicker.css'

interface AddAssessmentPlanProps {
  disabled: boolean
  fields: any
  handleFieldChange: (obj: any) => void
}

export default function SpravatoAssessmentPlan(props: AddAssessmentPlanProps) {
  return (
    <>
      <Form.Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label className="font-weight-bold">Medication</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.MedicationName}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                MedicationName: event.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label className="font-weight-bold">
            Psych testing / medical / labs / medical care
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.PsychCare}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                PsychCare: event.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label className="font-weight-bold">Psychotherapy</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.PsychotherapyNotes}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                PsychotherapyNotes: event.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label className="font-weight-bold">
            Other (sleep, hygiene, lifestyle, social/environment, safety)
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.OtherSafety}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                OtherSafety: event.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label className="font-weight-bold">Risk Level</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.RiskLevel}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                RiskLevel: event.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label className="font-weight-bold">Follow Up</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.FollowUpNotes}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                FollowUpNotes: event.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
