import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SendOutlined,
} from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'

import {
  putPatientSettings,
  updateProviderSurveySettings,
  updateSurveySettings,
} from '../../api/api-lib'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import {
  Button,
  Dropdown,
  InputNumber,
  Modal,
  Option,
  PageHeader,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  toggleConfirmModal,
} from '../../stories/BaseComponents'
import SurveyEditModal from '../../stories/SurveySettings/SurveyEditModal'
import SurveySettingsTable from '../../stories/SurveySettings/SurveySettingsTable'
import { patientDataTypes } from './patient-data-types'

import styles from '../_shared.module.scss'
import './PatientSettings.scss'

const PatientSettings = ({
  surveyData,
  patientSettingsInfo,
  defaultSurveySettings,
  isClinic = false,
  showModalRef,
}) => {
  const queryClient = useQueryClient()
  const [initialFieldObj, setInitialFieldObj] = useState({})
  const [selectedSurveys, setSelectedSurveys] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [currentSurveySettings, setCurrentSurveySettings] = useState({})
  const [currentAddSurveySettings, setCurrentAddSurveySettings] = useState({})
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const allSurveys = useMemo(
    () =>
      (surveyData ?? [])
        .filter((survey) => survey.IsPatientReported)
        .map((survey) => ({
          name: survey.Name,
          description: survey.Description,
          fieldName: survey.ScoreTypeEnum,
          isRecurring: survey.IsRecurring,
          defaultSendInterval: survey.DefaultSendInterval ?? 7,
          scoreGuide: survey.ScoreGuide,
          lowerScoreRange: survey.ThresholdGuide?.RangeMin,
          upperScoreRange: survey.ThresholdGuide?.RangeMax,
          recommendedThreshold: survey.ThresholdGuide?.RecommendedThreshold,
          comparisonOperator: survey.ThresholdGuide?.ComparisonOperator,
        })),
    [surveyData]
  )

  const showModal = useCallback(() => {
    setIsModalVisible(() => true)
  }, [])

  const closeModal = () => {
    setIsModalVisible(() => false)
  }

  useEffect(() => {
    if (isClinic) {
      showModalRef.current.showModal = showModal
    }
  }, [])

  useEffect(() => {
    const fields = {}
    for (const { fieldName, lowerScoreRange } of allSurveys) {
      const surveySettings = isClinic
        ? defaultSurveySettings
        : patientSettingsInfo?.surveySettings

      const {
        HasEnabled,
        Interval,
        IsActive,
        HasNotificationEnabled,
        Threshold,
        LastRequestedAt,
      } =
        surveySettings?.find(
          ({ ScoreTypeEnum }) => ScoreTypeEnum === fieldName
        ) ?? {}

      fields[fieldName] = {
        IsActive: !surveySettings ? false : IsActive,
        HasEnabled: !surveySettings ? false : !!HasEnabled,
        Interval:
          !Interval || !surveySettings ? 7 : Interval <= 0 ? 1 : Interval,
        HasNotificationEnabled: !surveySettings
          ? false
          : HasNotificationEnabled,
        Threshold:
          !surveySettings || !Threshold || !HasNotificationEnabled
            ? lowerScoreRange
            : Threshold,
        LastRequestedAt: !surveySettings ? null : LastRequestedAt,
      }
    }

    setInitialFieldObj((prev) => ({
      ...prev,
      ...fields,
      PatientId: patientSettingsInfo?.PatientId,
    }))
  }, [isClinic, defaultSurveySettings, patientSettingsInfo])

  const addSurveyToDB = async (data) => {
    try {
      if (!isClinic) {
        await putPatientSettings({
          PatientId: initialFieldObj?.PatientId,
          surveysToAdd: data,
          addSurvey: true,
        })
        queryClient.invalidateQueries([
          patientDataTypes.PatientSettings,
          initialFieldObj?.PatientId,
        ])
        notification(
          'You have successfully updated the patient settings for this patient.',
          'quick'
        )
      } else {
        await updateProviderSurveySettings({
          ProviderId: initialFieldObj?.ProviderId,
          surveysToAdd: data,
          addSurvey: true,
        })
        notification(
          'You have successfully updated the new patient default survey settings.',
          'quick'
        )
      }
    } catch (e) {
      console.error(e)
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
  }

  const updateFieldObjOnAdd = async (surveys, type) => {
    const survey = isClinic || type === 'add' ? [surveys] : surveys
    const newSurveys = {}
    survey.forEach((survey) => {
      const referencedSurvey = allSurveys.find(
        ({ fieldName }) => fieldName === survey
      )
      newSurveys[survey] = {
        ScoreTypeEnum: survey,
        IsActive: true,
        HasEnabled: false,
        Interval: referencedSurvey?.defaultSendInterval ?? 7,
        HasNotificationEnabled: false,
        Threshold: referencedSurvey?.lowerScoreRange ?? 0,
      }

      if (!isClinic) {
        newSurveys[survey] = { ...newSurveys[survey] }
      }
    })
    await addSurveyToDB(Object.values(newSurveys))
    setInitialFieldObj((prev) => ({ ...prev, ...newSurveys }))
    setSelectedSurveys([])
  }

  const updateFieldObj = (ScoreTypeEnum, Field, Value) =>
    setInitialFieldObj((prev) => ({
      ...prev,
      [ScoreTypeEnum]: { ...prev[ScoreTypeEnum], [Field]: Value },
    }))

  const handleChange = async (ScoreTypeEnum, Field, Value) => {
    updateFieldObj(ScoreTypeEnum, Field, Value)
    if (Field === 'IsActive' && Value == false) {
      updateFieldObj(ScoreTypeEnum, 'HasEnabled', false)
    }
    try {
      if (!isClinic) {
        const payload = {
          PatientId: initialFieldObj?.PatientId,
          ScoreTypeEnum,
          Field,
          Value,
        }
        if (Field === 'LastRequestedAt') {
          payload.IsProviderRequested = true
        }
        await putPatientSettings(payload)
        queryClient.invalidateQueries([
          patientDataTypes.PatientSettings,
          initialFieldObj?.PatientId,
        ])
        if (Field === 'LastRequestedAt') {
          notification('Survey request successfully sent.', 'success')
        } else {
          notification(
            'You have successfully updated the patient settings for this patient.',
            'success'
          )
        }
      } else {
        await updateProviderSurveySettings({
          ProviderId: initialFieldObj?.ProviderId,
          ScoreTypeEnum,
          Field,
          Value,
        })
        notification(
          'You have successfully updated the new patient default survey settings.',
          'success'
        )
      }
    } catch (e) {
      console.error(e)
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
  }

  // for Updating Number Inputs
  const onBlurUpdate = ({
    ScoreTypeEnum,
    Field,
    Value,
    Name,
    LowerScoreRange = Number.MIN_SAFE_INTEGER,
    UpperScoreRange = Number.MAX_SAFE_INTEGER,
  }) => {
    const receivedValue = parseInt(Value)

    if (
      LowerScoreRange <= receivedValue &&
      receivedValue <= UpperScoreRange &&
      !isNaN(receivedValue)
    ) {
      handleChange(ScoreTypeEnum, Field, receivedValue)
    } else {
      switch (Field) {
        case 'Threshold':
          notification(
            `Threshold value for ${Name} must be between ${LowerScoreRange} and ${UpperScoreRange} (inclusive).`,
            'error'
          )
          break
        case 'Interval':
          notification(
            `Interval value for ${Name} must be greater than 0.`,
            'error'
          )
          break
      }
    }
  }

  const SendRequestButton = ({ fieldName }) => (
    <Button
      id={'sendOneTimeRequest' + fieldName}
      type="link"
      size="middle"
      icon={<SendOutlined />}
      onClick={() => handleChange(fieldName, 'LastRequestedAt', new Date())}
      block
    >
      Request
    </Button>
  )

  // Add extra columns based on if provider or patient settings
  const patientOnlyColumns = isClinic
    ? []
    : [
        {
          title: () => (
            <>
              Last Requested&nbsp;
              <Tooltip
                style={{ fontSize: 16 }}
                title="Last when the survey was requested to the patient."
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          ),
          dataIndex: 'LastRequestedAt',
          key: 1,
          render: (text, { fieldName }) => (
            <Space size="middle">
              <div>
                {initialFieldObj[fieldName].LastRequestedAt
                  ? moment(initialFieldObj[fieldName].LastRequestedAt).format(
                      'MMM DD, YYYY HH:mm'
                    )
                  : 'Never'}
              </div>
            </Space>
          ),
        },
        {
          title: () => (
            <>
              Request&nbsp;
              <Tooltip
                style={{ fontSize: 16 }}
                title="The patient will receive a one-time-only survey request the next time they use the Osmind app. If the survey is activated, it will resume the usual behavior afterwards"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          ),
          dataIndex: 'ActionSend',
          key: 'ActionSend',
          width: '10%',
          align: 'center',
          render: (text, { fieldName }) => (
            <SendRequestButton fieldName={fieldName} />
          ),
        },
      ]

  const settingsColumns = [
    {
      title: () => (
        <>
          Survey Name&nbsp;
          <Tooltip
            style={{ fontSize: 16 }}
            title="The diagnostic survey that is to be administered."
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      // width: settingsColumnsWidth('name', isClinic),
      dataIndex: 'name',
      key: 'name',
      render: (text, { name, lowerScoreRange, upperScoreRange }) => (
        <>
          {name}&nbsp;
          <Tooltip
            style={{ fontSize: 16 }}
            title={`Interval must be greater than 0. Threshold must be between ${lowerScoreRange} and ${upperScoreRange} (inclusive).`}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
    },
    {
      title: () => (
        <>
          Survey Type&nbsp;
          <Tooltip style={{ fontSize: 16 }} title="Type of the survey">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'Type',
      key: 'Type',
      render: (text, { type }) => (
        <Space size="middle">
          <span>{type}</span>
        </Space>
      ),
    },
    {
      title: () => (
        <>
          Send&nbsp;
          <Tooltip
            style={{ fontSize: 16 }}
            title="ON: By default, new patients will get this survey at the specified send interval"
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'HasEnabled',
      key: 'HasEnabled',
      render: (text, { fieldName, isRecurring }) => (
        <Switch
          disabled={!isRecurring}
          value={initialFieldObj[fieldName]?.HasEnabled}
          checked={initialFieldObj[fieldName]?.HasEnabled}
          checkedChildren="ON"
          unCheckedChildren="OFF"
          onChange={(value) => handleChange(fieldName, 'HasEnabled', value)}
        />
      ),
    },
    {
      title: () => (
        <>
          Send Interval (Days)&nbsp;
          <Tooltip
            style={{ fontSize: 16 }}
            title="Interval at which the patient is prompted to complete the survey (e.g. 1 = daily, 2 = every two days, etc.). If a survey has a recommended send interval, it will be automatically set when you add the survey. If not, it will default to 7 days."
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'Interval',
      key: 'Interval',
      render: (text, { name, fieldName, isRecurring }) => (
        <Space size="middle">
          {isRecurring && (
            <InputNumber
              min={1}
              value={initialFieldObj[fieldName].Interval}
              onChange={(value) => updateFieldObj(fieldName, 'Interval', value)}
              onBlur={(e) =>
                onBlurUpdate({
                  ScoreTypeEnum: fieldName,
                  Field: 'Interval',
                  Value: parseInt(e.currentTarget.value),
                  Name: name,
                  LowerScoreRange: 1,
                })
              }
              onPressEnter={(e) =>
                onBlurUpdate({
                  ScoreTypeEnum: fieldName,
                  Field: 'Interval',
                  Value: parseInt(e.target.value),
                  Name: name,
                  LowerScoreRange: 1,
                })
              }
              onStep={(value) =>
                onBlurUpdate({
                  ScoreTypeEnum: fieldName,
                  Field: 'Interval',
                  Value: parseInt(value),
                  Name: name,
                  LowerScoreRange: 1,
                })
              }
            />
          )}
        </Space>
      ),
    },
    {
      title: () => (
        <>
          Threshold Notification&nbsp;
          <Tooltip
            style={{ fontSize: 16 }}
            title="Get notified when the threshold is met"
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'Notification',
      key: 'Notification',
      render: (text, { fieldName }) => (
        <Space size="middle">
          <Switch
            checked={initialFieldObj[fieldName].HasNotificationEnabled}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            onChange={(value) =>
              handleChange(fieldName, 'HasNotificationEnabled', value)
            }
          />
        </Space>
      ),
    },
    {
      title: () => (
        <>
          Threshold Level&nbsp;
          <Tooltip
            style={{ fontSize: 16 }}
            title="The survey score at which you wish to receive an email notification if the patient reaches or exceeds the score, or in the case of the Mood Score and Katz Index survey, reaches or drops below the score"
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'Threshold',
      key: 'Threshold',
      render: (text, { name, fieldName, lowerScoreRange, upperScoreRange }) => (
        <InputNumber
          min={lowerScoreRange}
          max={upperScoreRange}
          value={initialFieldObj[fieldName].Threshold}
          disabled={!initialFieldObj[fieldName].HasNotificationEnabled}
          onChange={(value) => updateFieldObj(fieldName, 'Threshold', value)}
          onBlur={(e) =>
            onBlurUpdate({
              ScoreTypeEnum: fieldName,
              Field: 'Threshold',
              Value: parseInt(e.currentTarget.value),
              Name: name,
              LowerScoreRange: lowerScoreRange,
              UpperScoreRange: upperScoreRange,
            })
          }
          onPressEnter={(e) =>
            onBlurUpdate({
              ScoreTypeEnum: fieldName,
              Field: 'Threshold',
              Value: parseInt(e.target.value),
              Name: name,
              LowerScoreRange: lowerScoreRange,
              UpperScoreRange: upperScoreRange,
            })
          }
          onStep={(value) =>
            onBlurUpdate({
              ScoreTypeEnum: fieldName,
              Field: 'Threshold',
              Value: parseInt(value),
              Name: name,
              LowerScoreRange: lowerScoreRange,
              UpperScoreRange: upperScoreRange,
            })
          }
        />
      ),
    },
    ...patientOnlyColumns,
    {
      title: () => (
        <>
          {isClinic ? (
            <>Actions&nbsp;</>
          ) : (
            <Button
              size="middle"
              icon={<PlusOutlined />}
              type="primary"
              onClick={showModal}
            >
              Add Survey
            </Button>
          )}
        </>
      ),
      dataIndex: 'Action',
      key: 'Action',
      align: 'center',
      render: (text, { fieldName }) => (
        <Button
          id={'remove' + fieldName}
          icon={<DeleteOutlined />}
          type="link"
          onClick={() => handleChange(fieldName, 'IsActive', false)}
        >
          Remove
        </Button>
      ),
    },
  ]

  const data = allSurveys
    ?.filter(({ fieldName }) => initialFieldObj?.[fieldName]?.IsActive)
    .sort((a, b) => String(a.name).localeCompare(String(b.name)))
    .map(
      ({
        fieldName,
        name,
        comparisonOperator,
        lowerScoreRange,
        upperScoreRange,
        isRecurring,
        description,
        scoreGuide,
        recommendedThreshold,
      }) => ({
        key: fieldName,
        fieldName,
        name,
        lowerScoreRange,
        upperScoreRange,
        description,
        isActive: initialFieldObj[fieldName].IsActive,
        sendingEnabled: initialFieldObj[fieldName].HasEnabled,
        alertingEnabled: initialFieldObj[fieldName].HasNotificationEnabled,
        sendInterval: initialFieldObj[fieldName].Interval,
        alertThreshold: initialFieldObj[fieldName].Threshold,
        comparisonOperator,
        recommendedThreshold,
        isRecurring,
        type: isRecurring ? 'Recurring' : 'One time',
        scoreGuide,
      })
    )

  const handleOnEdit = (survey) => {
    setCurrentSurveySettings(survey)
    setIsEditModalVisible(true)
  }

  const handleOnDelete = (survey) => {
    setCurrentSurveySettings(survey)
    setIsDeleteModalVisible(true)
  }

  const handleConfirmDelete = () => {
    handleChange(currentSurveySettings.fieldName, 'IsActive', false)
    setIsDeleteModalVisible(false)
  }

  const handleDeleteModalCancel = useCallback(() => {
    setIsDeleteModalVisible(false)
  }, [])

  const onAddSurvey = (value) => {
    let survey = allSurveys.find((k) => k.fieldName === value.key)
    survey = {
      key: survey.fieldName,
      isActive: true,
      sendInterval: survey?.defaultSendInterval ?? 0,
      sendingEnabled: false,
      alertingEnabled: false,
      hasNotificationEnabled: false,
      alertThreshold: survey?.lowerScoreRange ?? 0,
      comparisonOperator: survey?.comparisonOperator,
      ...survey,
    }
    setCurrentAddSurveySettings(survey)
    setIsAddModalVisible(true)
  }

  const handleSurveyModalCancel = useCallback(() => {
    setIsEditModalVisible(false)
    setCurrentSurveySettings({})
  }, [])

  const handleAddSurveyModalCancel = useCallback(() => {
    setIsAddModalVisible(false)
    setCurrentSurveySettings({})
  }, [])

  const showConfirm = (type) => {
    toggleConfirmModal({
      title: 'Unsaved changes',
      centered: true,
      content:
        'Are you sure you want to close this window? Any unsaved changes will be lost.',
      onOk() {
        type === 'edit'
          ? handleSurveyModalCancel()
          : handleAddSurveyModalCancel()
      },
    })
  }

  const onSubmitSurveySettings = async (values, type) => {
    try {
      if (type === 'add') {
        await updateFieldObjOnAdd(values.scoreTypeEnum, 'add')
        await updateSurveySettings(values)
      } else {
        await updateSurveySettings(values)
      }
      const surveyValues = {
        ...initialFieldObj,
        [values.scoreTypeEnum]: {
          HasEnabled: values.sendingEnabled,
          HasNotificationEnabled: values.alertingEnabled,
          Interval: values.sendInterval,
          IsActive: true,
          Threshold: values.alertThreshold,
        },
      }
      setInitialFieldObj(surveyValues)
      if (type === 'add') {
        handleAddSurveyModalCancel()
      } else {
        handleSurveyModalCancel()
      }
      notification(
        'You have successfully updated the new patient default survey settings.',
        'success'
      )
      setCurrentSurveySettings({})
    } catch (e) {
      setCurrentSurveySettings({})
      console.error(e)
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
  }

  const generateSurveyItems = () => {
    return allSurveys
      ?.filter(({ fieldName }) => !initialFieldObj?.[fieldName]?.IsActive)
      .sort((a, b) => String(a.name).localeCompare(String(b.name)))
      .map(({ fieldName, name }) => ({ key: fieldName, text: name }))
  }

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        {isClinic && (
          <>
            <PageHeader
              title="Surveys"
              description={
                'Select and configure the questionnaires and surveys that are available to be sent to new patients at your practice. You may override these settings patients from within their chart. '
              }
              link={{
                url: 'https://support.osmind.org/en/articles/5561824-survey-library',
                text: 'Learn more about surveys.',
              }}
              extra={
                <Dropdown
                  items={generateSurveyItems()}
                  trigger={['click']}
                  onAddClick={onAddSurvey}
                >
                  <Button size="middle" icon={<PlusOutlined />} type="primary">
                    Add Survey
                  </Button>
                </Dropdown>
              }
            />
            <SurveySettingsTable
              dataSource={data}
              onEditClick={handleOnEdit}
              onDeleteClick={handleOnDelete}
            />
            <SurveyEditModal
              currentSurveySettings={
                isEditModalVisible
                  ? currentSurveySettings
                  : currentAddSurveySettings
              }
              visible={isEditModalVisible || isAddModalVisible}
              title={
                isEditModalVisible
                  ? currentSurveySettings?.name
                  : currentAddSurveySettings?.name
              }
              onSubmit={onSubmitSurveySettings}
              handleCancel={showConfirm}
              type={isEditModalVisible ? 'edit' : 'add'}
            />
            <Modal
              visible={isDeleteModalVisible}
              title="Delete survey"
              onCancel={handleDeleteModalCancel}
              onOk={handleConfirmDelete}
              okText="Delete"
            >
              <p> Are you sure you want to delete this survey?</p>
            </Modal>
          </>
        )}
        {!isClinic && (
          <>
            <Table
              columns={settingsColumns}
              dataSource={data}
              pagination={false}
            />
            <Modal
              title="Add survey"
              bodyStyle={{ overflow: 'unset' }}
              visible={isModalVisible}
              onOk={async () => {
                await updateFieldObjOnAdd(selectedSurveys, 'edit')
                closeModal()
              }}
              onCancel={() => {
                setSelectedSurveys([])
                closeModal()
              }}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                value={selectedSurveys}
                placeholder="Please select"
                onChange={(value) => setSelectedSurveys(value)}
              >
                {allSurveys
                  ?.filter(
                    ({ fieldName }) => !initialFieldObj?.[fieldName]?.IsActive
                  )
                  .sort((a, b) => String(a.name).localeCompare(String(b.name)))
                  .map(({ fieldName, name }) => (
                    <Option key={fieldName} value={fieldName}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Modal>
          </>
        )}
      </div>
    </div>
  )
}

export default PatientSettings
