import React from 'react'

import { Col, Form } from 'react-bootstrap'

export default function Anxiety(props) {
  if (props.patientData.NoPsychotic) {
    return (
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Psychotic:
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="NoPsychotic"
            label="No relevant history in this section"
            checked={JSON.parse(props.patientData.NoPsychotic)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NoPsychotic: !props.patientData.NoPsychotic,
              })
            }}
          />
        </Form.Group>
      </Form.Row>
    )
  }

  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Psychotic:
          </Form.Label>
        </Form.Group>
        {props.patientData.Schizophrenia.state === false &&
        props.patientData.PsychoticOther.state === false &&
        props.patientData.PsychoticAdditionalNotes === '' ? (
          <Form.Group as={Col} sm={12} md={12} lg={12}>
            <Form.Check
              type="checkbox"
              id="NoPsychotic"
              label="No relevant history in this section"
              checked={JSON.parse(props.patientData.NoPsychotic)}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoPsychotic: !props.patientData.NoPsychotic,
                })
              }}
            />
          </Form.Group>
        ) : (
          ''
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Diagnoses
          </Form.Label>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.Schizophrenia.state ? 2 : 12}
          lg={props.patientData.Schizophrenia.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="Schizophrenia"
            label="Schizophrenia"
            disabled={props.patientData.NoPsychotic}
            checked={JSON.parse(props.patientData.Schizophrenia.state)}
            onChange={() => {
              props.setPatientData({
                ...props.patientData,
                Schizophrenia: {
                  ...props.patientData.Schizophrenia,
                  state: !props.patientData.Schizophrenia.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Schizophrenia.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    Schizophrenia: {
                      ...props.patientData.Schizophrenia,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.Schizophrenia.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.PsychoticOther.state ? 2 : 12}
          lg={props.patientData.PsychoticOther.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="PsychoticOther"
            label="Other"
            disabled={props.patientData.NoPsychotic}
            checked={JSON.parse(props.patientData.PsychoticOther.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                PsychoticOther: {
                  ...props.patientData.PsychoticOther,
                  state: !props.patientData.PsychoticOther.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.PsychoticOther.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    PsychoticOther: {
                      ...props.patientData.PsychoticOther,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.PsychoticOther.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Symptoms
          </Form.Label>
        </Form.Group>
        <Form.Group
          style={{ marginTop: '0px' }}
          as={Col}
          sm={12}
          md={12}
          lg={12}
        >
          <Form.Check
            type="checkbox"
            id="AuditoryHallucinations"
            label="Auditory hallucinations"
            disabled={props.patientData.NoPsychotic}
            checked={JSON.parse(
              props.patientData.PsychoticSymptoms.AuditoryHallucinations
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                PsychoticSymptoms: {
                  ...props.patientData.PsychoticSymptoms,
                  AuditoryHallucinations:
                    !props.patientData.PsychoticSymptoms.AuditoryHallucinations,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="VisualHallucinations"
            label="Visual hallucinations"
            disabled={props.patientData.NoPsychotic}
            checked={JSON.parse(
              props.patientData.PsychoticSymptoms.VisualHallucinations
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                PsychoticSymptoms: {
                  ...props.patientData.PsychoticSymptoms,
                  VisualHallucinations:
                    !props.patientData.PsychoticSymptoms.VisualHallucinations,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="SuspiciousThoughts"
            label="Strong suspicious thoughts"
            disabled={props.patientData.NoPsychotic}
            checked={JSON.parse(
              props.patientData.PsychoticSymptoms.SuspiciousThoughts
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                PsychoticSymptoms: {
                  ...props.patientData.PsychoticSymptoms,
                  SuspiciousThoughts:
                    !props.patientData.PsychoticSymptoms.SuspiciousThoughts,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="Paranoia"
            label="Strong feelings of paranoia"
            disabled={props.patientData.NoPsychotic}
            checked={JSON.parse(props.patientData.PsychoticSymptoms.Paranoia)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                PsychoticSymptoms: {
                  ...props.patientData.PsychoticSymptoms,
                  Paranoia: !props.patientData.PsychoticSymptoms.Paranoia,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="IdeasReference"
            label="Ideas of reference"
            disabled={props.patientData.NoPsychotic}
            checked={JSON.parse(
              props.patientData.PsychoticSymptoms.IdeasReference
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                PsychoticSymptoms: {
                  ...props.patientData.PsychoticSymptoms,
                  IdeasReference:
                    !props.patientData.PsychoticSymptoms.IdeasReference,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="ThoughtBroadcasting"
            label="Thought broadcasting"
            disabled={props.patientData.NoPsychotic}
            checked={JSON.parse(
              props.patientData.PsychoticSymptoms.ThoughtBroadcasting
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                PsychoticSymptoms: {
                  ...props.patientData.PsychoticSymptoms,
                  ThoughtBroadcasting:
                    !props.patientData.PsychoticSymptoms.ThoughtBroadcasting,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="ThoughtInsertion"
            label="Thought insertion"
            disabled={props.patientData.NoPsychotic}
            checked={JSON.parse(
              props.patientData.PsychoticSymptoms.ThoughtInsertion
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                PsychoticSymptoms: {
                  ...props.patientData.PsychoticSymptoms,
                  ThoughtInsertion:
                    !props.patientData.PsychoticSymptoms.ThoughtInsertion,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="BeliefsReality"
            label="Beliefs out of touch with reality"
            disabled={props.patientData.NoPsychotic}
            checked={JSON.parse(
              props.patientData.PsychoticSymptoms.BeliefsReality
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                PsychoticSymptoms: {
                  ...props.patientData.PsychoticSymptoms,
                  BeliefsReality:
                    !props.patientData.PsychoticSymptoms.BeliefsReality,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="OtherPsychoticSymptoms"
            label="Other"
            disabled={props.patientData.NoPsychotic}
            checked={JSON.parse(props.patientData.PsychoticSymptoms.Other)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                PsychoticSymptoms: {
                  ...props.patientData.PsychoticSymptoms,
                  Other: !props.patientData.PsychoticSymptoms.Other,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.PsychoticSymptoms.Other && (
          <Form.Group
            className="indent"
            style={{ marginTop: '0px' }}
            as={Col}
            sm={12}
            md={12}
            lg={12}
          >
            <Form.Control
              as="textarea"
              rows={1}
              bsPrefix="form-input"
              onChange={(e) => {
                props.setPatientData({
                  ...props.patientData,
                  PsychoticSymptoms: {
                    ...props.patientData.PsychoticSymptoms,
                    OtherText: e.target.value,
                  },
                })
              }}
              value={props.patientData.PsychoticSymptoms.OtherText}
            />
          </Form.Group>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional notes
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            bsPrefix="form-input"
            disabled={props.patientData.NoPsychotic}
            onChange={(e) => {
              props.setPatientData({
                ...props.patientData,
                PsychoticAdditionalNotes: e.target.value,
              })
            }}
            value={props.patientData.PsychoticAdditionalNotes}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
