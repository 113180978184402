import React from 'react'

import { Accordion, Form } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'
import HospitalizationsToDisplay from './PsychContent/HospitalizationsToDisplay'

import './../PatientProfile.scss'

export default function HospitalizationSaved(props) {
  const hasNoRelevantHistory = props.patientData.NoPreviousHospitalizations

  const noRecordedInfo =
    !props.patientData.NoPreviousHospitalizations &&
    props.patientData.PreviousHospitalizations.length === 0

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Hospitalizations for mental health reasons"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">
        Hospitalizations for mental health reasons:
      </Form.Row>
      <Form.Group className="border-bottom indent">
        {props.patientData.PreviousHospitalizations.length !== 0 &&
          props.patientData.PreviousHospitalizations.map((H, index) => {
            return (
              <Accordion className="table-container" key={index}>
                <HospitalizationsToDisplay
                  type="active"
                  index={index}
                  ReasonForHospitalization={H.reasonForHospitalization}
                  LenghtOfHospitalization={H.lenghtOfHospitalization}
                  StartDate={H.startDate}
                  EndDate={H.endDate}
                  HospitalName={H.hospitalName}
                  AdditionalNotes={H.additionalNotes}
                  DateFormat={H.dateFormat}
                />
              </Accordion>
            )
          })}
      </Form.Group>
    </Form.Group>
  )
}
