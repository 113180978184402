import { datadogRum } from '@datadog/browser-rum'
import { QueryClient } from '@tanstack/react-query'
import { Auth, Cache } from 'aws-amplify'
import { useHistory } from 'react-router-dom'

import { OnLogoutCallback } from '../../../stories/Navigation/types'

// refactor this to the other use cases, when the need arises
export const LOCAL_STORAGE_SIDEBAR_ITEM_SUFFIX = '_collapsedSidebar'
export const logoutFailureMessage = 'Log out failure'

/**
 * Clears localStorage
 * - Preserves items matching `localStorageSidebarItemSuffix` (`_collapsedSidebar`)
 */
export const clearLocalStorage = () => {
  const preservedLocalStorageItems: { [key: string]: string } = Object.keys(
    window.localStorage
  )
    .filter((lsKey) => lsKey.includes(LOCAL_STORAGE_SIDEBAR_ITEM_SUFFIX))
    .reduce((agg: { [key: string]: string }, currLsKey) => {
      agg[currLsKey] = window.localStorage[currLsKey]
      return agg
    }, {})

  window.localStorage.clear()

  // restore the *_collapsedSidebar items
  Object.keys(preservedLocalStorageItems).forEach((lsKey) =>
    localStorage.setItem(lsKey, preservedLocalStorageItems[lsKey])
  )
}

export const logout = async ({
  queryClient,
  history,
  onLogoutCallback,
}: {
  queryClient: QueryClient
  history: ReturnType<typeof useHistory> // is there a cleaner way to do this?
  onLogoutCallback: OnLogoutCallback
}) => {
  try {
    // most important
    await Auth.signOut()

    // affects visual rendering of the page
    onLogoutCallback()

    // cleaning up cache
    Cache.clear()
    queryClient.clear()
    clearLocalStorage()

    // change route
    history.push('/login')
  } catch (err) {
    // sync action
    datadogRum.addAction(logoutFailureMessage, {
      // this is expected to NOT contain any sensitive information
      // if that assumption is wrong, then we should limit what's sent to DD
      error: err,
    })
  }
}
