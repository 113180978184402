import {
  FieldStyling,
  GridLayout,
  TemplateFields,
} from '../../../components/Forms/FormMachine/types'

export const styling = {
  editColClassName: 'col-bottom-edit',
  colClassName: 'bottom-line',
  editLabelClassName: 'field-label-edit',
  editValueClassName: 'field-value-edit',
  labelClassName: 'col-squished field-label',
  valueClassName: 'field-value',
} as FieldStyling

export const gridLayout = [
  { xs: 6, sm: 6, md: 6, lg: 3, xl: 4 },
  { xs: 6, sm: 6, md: 6, lg: 4, xl: 3 },
  { xs: 6, sm: 6, md: 6, lg: 2, xl: 1 },
  { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
] as GridLayout

export const template = [
  {
    columnSizes: gridLayout[0],
    fieldType: 'unit',
    id: 'height',
    label: 'Height',
    units: ['in', 'cm'],
  },
  {
    columnSizes: gridLayout[0],
    fieldType: 'unit',
    id: 'weight',
    label: 'Weight',
    units: ['lb', 'kg'],
  },
  {
    columnSizes: gridLayout[2],
    customStyling: styling,
    fieldType: 'custom',
    id: 'bmi',
    label: 'BMI',
  },
  {
    columnSizes: gridLayout[1],
    fieldType: 'unit',
    id: 'hip',
    label: 'Hip',
    units: ['in', 'cm'],
  },
  {
    columnSizes: gridLayout[3],
    fieldType: 'textarea',
    id: 'additionalNotes',
    label: 'Additional notes',
    placeholder: 'Any additional notes',
  },
] as TemplateFields
