import { useMemo, useState } from 'react'

import { DownOutlined, RightOutlined, UpOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { format } from 'date-fns'
import { sumBy } from 'lodash'

import {
  centsToUsdString,
  getDisplayNameForPaymentMethodType,
} from '../../../libs/billing'
import { PAYMENT_METHOD_TYPES } from '../../../libs/constants'
import { uppercaseFirstLetter } from '../../../libs/utils'
import { PaymentMethod, PaymentStatus } from '../../../shared-types'
import { HoverIcon, Text } from '../../BaseComponents'
import { Row } from './Row'

import styles from './TransactionCollapse.module.scss'

export enum TransactionType {
  REFUND = 'REFUND',
  PAYMENT = 'PAYMENT',
}

export type Transaction = {
  status: PaymentStatus
  createdAt: string | Date
  amountCents: number
  paymentMethod: PaymentMethod
}

export const totalTransactions = (entries: Transaction[]) =>
  sumBy(entries, (entry) => entry.amountCents)

const PaymentOrRefundLabel = ({
  transaction,
  type,
}: {
  transaction: Transaction
  type: TransactionType
}) => {
  const paymentMethodString = useMemo(() => {
    if (
      transaction.paymentMethod.type === PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD
    ) {
      return `ending in ${transaction.paymentMethod.lastFour}`
    }

    return `via ${getDisplayNameForPaymentMethodType(
      transaction.paymentMethod.type
    )}`
  }, [transaction, type])

  return (
    <Text>
      {uppercaseFirstLetter(type)} on{' '}
      {format(new Date(transaction.createdAt), 'MMMM d, yyyy')}{' '}
      {paymentMethodString}
    </Text>
  )
}

export const TransactionCollapse = ({
  transactions,
  type,
  fromClaim,
}: {
  transactions: Transaction[]
  type: TransactionType
  fromClaim?: boolean
}) => {
  const [showDetails, setShowDetails] = useState(false)

  if (transactions.length === 0) {
    return null
  }

  const totalAmountCents = totalTransactions(transactions)
  if (totalAmountCents === 0) {
    return null
  }

  const isRefund = type === TransactionType.REFUND

  let totalAmount = centsToUsdString(totalAmountCents)

  if (isRefund) {
    totalAmount = `-${totalAmount}`
  }

  return (
    <div
      className={fromClaim ? styles.verticalSpacerFill : styles.verticalSpacer}
    >
      <Row
        testId={isRefund ? 'refund' : 'payment'}
        rowClassName={cx(styles.infoRow, {
          [styles.refundInvoice]: isRefund && !fromClaim,
        })}
        label={
          <div className={fromClaim ? styles.flexStart : styles.flex}>
            {uppercaseFirstLetter(type)}s ({transactions.length})
            <HoverIcon
              className={styles.collapseIcon}
              onClick={() => setShowDetails(!showDetails)}
              testId={`${
                showDetails ? 'collapse' : 'expand'
              }-${type.toLowerCase()}s`}
              icon={
                showDetails ? (
                  fromClaim ? (
                    <UpOutlined />
                  ) : (
                    <DownOutlined />
                  )
                ) : fromClaim ? (
                  <DownOutlined />
                ) : (
                  <RightOutlined />
                )
              }
            />
          </div>
        }
        value={totalAmount}
      />
      {showDetails && (
        <>
          {transactions.map((transaction) => {
            let entryAmount = centsToUsdString(transaction.amountCents)

            if (isRefund) {
              entryAmount = `-${entryAmount}`
            }
            return (
              <Row
                rowClassName={cx(styles.infoRow, styles.paymentOrRefundRow)}
                label={
                  <PaymentOrRefundLabel transaction={transaction} type={type} />
                }
                rowStyle={fromClaim ? { display: 'flex' } : {}}
                value={entryAmount}
                testId={isRefund ? 'refund-details' : 'payment-details'}
              />
            )
          })}
        </>
      )}
    </div>
  )
}
