import { Editor } from '@tiptap/react'

import { determineInsertLocation } from '../utils'

export const qaTemplate = (editor?: Editor) => ({
  text: 'QA Template',
  key: 'QA',
  onClick: () => {
    if (!editor) return

    const endPosition = determineInsertLocation(editor)
    editor
      .chain()
      .insertContentAt(endPosition, [
        {
          type: 'heading',
          attrs: { level: 1 },
          content: [{ type: 'text', text: 'Heading 1' }],
        },
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: 'Toffee marzipan bear claw jujubes soufflé. Pie ice cream gummies powder chocolate jujubes. Apple pie topping gummi bears chocolate bar gingerbread gummi bears. Shortbread chocolate cake oat cake lemon drops cookie muffin dessert jelly-o donut. Tart toffee gummies jujubes jelly-o jujubes brownie jelly-o pie. Macaroon liquorice liquorice croissant gummi bears. Tootsie roll bear claw tootsie roll pastry brownie chocolate bar tart macaroon jelly-o. Sugar plum wafer dragée muffin soufflé. Pastry gingerbread dragée marshmallow chocolate wafer fruitcake. Tart powder cake fruitcake muffin sesame snaps bonbon cupcake. Lollipop candy canes gummi bears cake jelly-o topping topping wafer soufflé. Pudding chocolate cake marzipan jelly beans jujubes gummies ice cream. Cupcake jelly pudding tiramisu biscuit bonbon chocolate shortbread. Biscuit chupa chups shortbread jelly-o cake biscuit.',
            },
          ],
        },
        {
          type: 'heading',
          attrs: { level: 2 },
          content: [{ type: 'text', text: 'Heading 2' }],
        },
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: 'Cotton candy powder jelly wafer bear claw cake cheesecake. Cupcake sesame snaps marzipan candy canes bonbon muffin dessert icing. Fruitcake toffee powder dessert cake bear claw cake bear claw. Marzipan lemon drops lemon drops bonbon chocolate bar candy jelly-o icing. Cake cheesecake brownie jelly beans lemon drops. Fruitcake dessert cake jelly beans carrot cake pie donut marshmallow. Gingerbread sugar plum bear claw cheesecake croissant cupcake. Dragée shortbread brownie soufflé cookie wafer carrot cake dragée lemon drops. Sweet roll gummi bears jujubes topping jelly beans macaroon cheesecake sweet roll chocolate. Tootsie roll jelly beans sesame snaps tootsie roll halvah chocolate cake. Candy jelly-o brownie icing donut shortbread gummi bears ice cream fruitcake. Marzipan dragée dessert dragée macaroon liquorice shortbread soufflé sweet roll.',
            },
          ],
        },
        {
          type: 'heading',
          attrs: { level: 3 },
          content: [{ type: 'text', text: 'Heading 3' }],
        },
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: 'Danish cupcake tiramisu gummies pastry tiramisu. Candy canes jujubes oat cake candy canes cake ice cream jelly beans muffin. Dragée icing oat cake gummi bears oat cake cake gummies macaroon cupcake. Chocolate bar chocolate pie brownie sugar plum bonbon oat cake muffin. Halvah croissant bear claw pastry shortbread cookie cotton candy tootsie roll gingerbread. Cheesecake halvah candy canes wafer soufflé jelly beans chocolate cake. Toffee pie lollipop jujubes apple pie jelly-o apple pie. Tootsie roll icing fruitcake jujubes jelly beans macaroon biscuit toffee liquorice. Gummi bears soufflé topping biscuit cupcake danish chupa chups. Marshmallow gingerbread sweet roll marzipan sweet. Chocolate muffin apple pie biscuit candy chupa chups. Apple pie soufflé icing gummies biscuit. Tiramisu chocolate bar biscuit sugar plum pudding cake tootsie roll sugar plum cupcake.',
            },
          ],
        },
        {
          type: 'heading',
          attrs: {
            level: 3,
          },
          content: [
            {
              text: 'Mixed Styles',
              type: 'text',
            },
          ],
        },
        {
          type: 'paragraph',
          content: [
            {
              text: 'Tiramisu candy ice cream jelly wafer caramels. Dessert bear claw cake toffee fruitcake pudding apple pie tiramisu. Danish tart ',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              text: 'sesame snaps chocolate pastry dragée tootsie roll icing. Danish tootsie roll candy cheesecake powder ice cream. Sugar plum cookie chocolate ',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
                {
                  type: 'italic',
                },
              ],
            },
            {
              text: 'bar dessert jelly topping. Muffin oat cake gummies dessert toffee. Marshmallow muffin oat cake dessert macaroon. Bonbon toffee jelly-o gingerbread bonbon. Muffin sweet roll gummies liquorice soufflé sweet roll donut sweet roll. Donut jelly cake ',
              type: 'text',
              marks: [
                {
                  type: 'italic',
                },
              ],
            },
            {
              text: 'fruitcake gummi bears topping ',
              type: 'text',
              marks: [
                {
                  type: 'strike',
                },
              ],
            },
            {
              text: 'cake. Chocolate pudding gingerbread chocolate ca',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
                {
                  type: 'strike',
                },
              ],
            },
            {
              text: 'ramels shortbread. Croissant bear claw macaroon ',
              type: 'text',
              marks: [
                {
                  type: 'strike',
                },
              ],
            },
            {
              text: ' sesame snaps marzipan cotton candy pie. Bear claw tiramisu carrot cake candy canes marzipan cake. Chocolate liquorice pastry chocolate croissant topping.',
              type: 'text',
            },
          ],
        },
        {
          type: 'bulletList',
          content: [
            {
              type: 'listItem',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      text: 'This is an unordered list',
                      type: 'text',
                    },
                  ],
                },
                {
                  type: 'bulletList',
                  content: [
                    {
                      type: 'listItem',
                      content: [
                        {
                          type: 'paragraph',
                          content: [
                            {
                              text: 'This is a sub-item',
                              type: 'text',
                            },
                          ],
                        },
                        {
                          type: 'bulletList',
                          content: [
                            {
                              type: 'listItem',
                              content: [
                                {
                                  type: 'paragraph',
                                  content: [
                                    {
                                      text: 'This is a long sub-sub-item. Apple pie brownie pie jelly beans jelly beans pastry chocolate bar cake muffin. Gingerbread sesame snaps biscuit jelly beans icing chocolate chocolate cake cake biscuit. Pastry chocolate bar jelly-o danish apple pie jujubes toffee wafer. Biscuit oat cake tiramisu liquorice marzipan.',
                                      type: 'text',
                                    },
                                  ],
                                },
                                {
                                  type: 'bulletList',
                                  content: [
                                    {
                                      type: 'listItem',
                                      content: [
                                        {
                                          type: 'paragraph',
                                          content: [
                                            {
                                              text: 'And a sub-sub-sub-item.',
                                              type: 'text',
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'listItem',
                      content: [
                        {
                          type: 'paragraph',
                          content: [
                            {
                              text: 'This is another sub-item.',
                              type: 'text',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'orderedList',
          attrs: {
            start: 1,
          },
          content: [
            {
              type: 'listItem',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      text: 'This is an ordered list',
                      type: 'text',
                    },
                  ],
                },
                {
                  type: 'orderedList',
                  attrs: {
                    start: 1,
                  },
                  content: [
                    {
                      type: 'listItem',
                      content: [
                        {
                          type: 'paragraph',
                          content: [
                            {
                              text: 'This is a sub-item',
                              type: 'text',
                            },
                          ],
                        },
                        {
                          type: 'orderedList',
                          attrs: {
                            start: 1,
                          },
                          content: [
                            {
                              type: 'listItem',
                              content: [
                                {
                                  type: 'paragraph',
                                  content: [
                                    {
                                      text: 'This is a long sub-sub-item. Apple pie brownie pie jelly beans jelly beans pastry chocolate bar cake muffin. Gingerbread sesame snaps biscuit jelly beans icing chocolate chocolate cake cake biscuit. Pastry chocolate bar jelly-o danish apple pie jujubes toffee wafer. Biscuit oat cake tiramisu liquorice marzipan.',
                                      type: 'text',
                                    },
                                  ],
                                },
                                {
                                  type: 'orderedList',
                                  attrs: {
                                    start: 1,
                                  },
                                  content: [
                                    {
                                      type: 'listItem',
                                      content: [
                                        {
                                          type: 'paragraph',
                                          content: [
                                            {
                                              text: 'And a sub-sub-sub-item.',
                                              type: 'text',
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              type: 'listItem',
                              content: [
                                {
                                  type: 'paragraph',
                                  content: [
                                    {
                                      text: 'This is another sub-item.',
                                      type: 'text',
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ])
      .run()
  },
})
