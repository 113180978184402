import React, { useEffect, useState } from 'react'

import _isEqual from 'lodash/isEqual'
import { Col, Form, Row, Table } from 'react-bootstrap'

import {
  submitEditFamilyHistoryRequest,
  submitNewFamilyHistoryRequest,
} from '../../../api/api-lib'
import GenericAccordion from '../../../components/Accordions/GenericAccordion'
import PatientProfileButtons from '../../../components/Buttons/PatientProfileButtons'
import FormMachine from '../../../components/Forms/FormMachine/FormMachine'
import { onError } from '../../../libs/errorLib'
import { useFormFields } from '../../../libs/hooksLib'
import { notification } from '../../../libs/notificationLib'
import { patientDataTypes } from '../patient-data-types'
import {
  additionalInfoTemplate,
  commentTemplate,
  conditionsTemplate,
  familyInfoTemplate,
  styling,
} from './template'
import { FamilyHistoryResponse, FamilyMember } from './types'

import '../PatientProfile.scss'

interface FamilyHistoryResponseProps {
  handleApiChange: (queryName: string[]) => void
  response: FamilyHistoryResponse
  patientId: string
}

interface Person {
  label: string
  id: FamilyMember
}

const familyMedicalConditions: Person[] = [
  { label: 'Mother', id: FamilyMember.Mother },
  { label: 'Grandmother (maternal)', id: FamilyMember.GrandmotherMaternal },
  { label: 'Grandfather (maternal)', id: FamilyMember.GrandfatherMaternal },
  { label: 'Aunt(s) (maternal)', id: FamilyMember.AuntMaternal },
  { label: 'Uncle(s) (maternal)', id: FamilyMember.UncleMaternal },
  { label: 'Cousin(s) (maternal)', id: FamilyMember.CousinMaternal },
  { label: 'Father', id: FamilyMember.Father },
  { label: 'Grandmother (paternal)', id: FamilyMember.GrandmotherPaternal },
  { label: 'Grandfather (paternal)', id: FamilyMember.GrandfatherPaternal },
  { label: 'Aunt(s) (paternal)', id: FamilyMember.AuntPaternal },
  { label: 'Uncle(s) (paternal)', id: FamilyMember.UnclePaternal },
  { label: 'Cousin(s) (paternal)', id: FamilyMember.CousinPaternal },
  { label: 'Sister(s)', id: FamilyMember.Sister },
  { label: 'Brother(s)', id: FamilyMember.Brother },
  { label: 'Daughter(s)', id: FamilyMember.Daughter },
  { label: 'Son(s)', id: FamilyMember.Son },
]

export default function FamilyHistory(props: FamilyHistoryResponseProps) {
  const [isDisabled, _setIsDisabled] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [familyMembers, setFamilyMembers] = useState(
    new Set(props.response.familyMembers) || new Set()
  )
  const [hasNoFamilyMedicalConditions, setHasNoFamilyMedicalConditions] =
    useState(props.response.noFamilyMedicalConditions || false)
  const [noFamilyPsychConditions, setNoFamilyPsychConditions] = useState(
    props.response.noFamilyPsychConditions || false
  )
  // Handle deepy copy
  const [storedFields, handleFieldChange] = useFormFields(
    JSON.parse(JSON.stringify(props.response))
  )
  const medicalNotesFields = {
    motherMedicalNotes: props.response.motherMedicalNotes || '',
    grandmotherMaternalMedicalNotes:
      props.response.grandmotherMaternalMedicalNotes || '',
    grandfatherMaternalMedicalNotes:
      props.response.grandfatherMaternalMedicalNotes || '',
    auntMaternalMedicalNotes: props.response.auntMaternalMedicalNotes || '',
    uncleMaternalMedicalNotes: props.response.uncleMaternalMedicalNotes || '',
    cousinMaternalMedicalNotes: props.response.cousinMaternalMedicalNotes || '',
    fatherMedicalNotes: props.response.fatherMedicalNotes || '',
    grandmotherPaternalMedicalNotes:
      props.response.grandmotherPaternalMedicalNotes || '',
    grandfatherPaternalMedicalNotes:
      props.response.grandfatherPaternalMedicalNotes || '',
    auntPaternalMedicalNotes: props.response.auntPaternalMedicalNotes || '',
    unclePaternalMedicalNotes: props.response.unclePaternalMedicalNotes || '',
    sisterMedicalNotes: props.response.sisterMedicalNotes || '',
    brotherMedicalNotes: props.response.brotherMedicalNotes || '',
    daughterMedicalNotes: props.response.daughterMedicalNotes || '',
    sonMedicalNotes: props.response.sonMedicalNotes || '',
    cousinPaternalMedicalNotes: props.response.cousinPaternalMedicalNotes || '',
  }
  const [medicalNotes, setMedicalNotes] = useState(medicalNotesFields)
  const [shouldNoPsychShow, setShouldNoPsychShow] = useState(true)

  const revert = () => {
    setMedicalNotes(medicalNotesFields)
    setFamilyMembers(new Set(props.response.familyMembers) || new Set())
    setHasNoFamilyMedicalConditions(
      props.response.noFamilyMedicalConditions || false
    )
    setNoFamilyPsychConditions(props.response.noFamilyPsychConditions || false)
  }

  const cancelEditing = () => {
    handleFieldChange(false, JSON.parse(JSON.stringify(props.response)))
    revert()
    setIsEditing(false)
  }

  const switchToEdit = () => {
    setIsEditing(true)
  }

  function handleCheckChange(
    id: string,
    key: number,
    checked = false,
    idx: number
  ) {
    const field = conditionsTemplate[idx].find((field) => field.id === id)
    if (typeof field !== 'undefined') {
      const type = field.fieldType
      const event = { target: {} }
      if (typeof field.options !== 'undefined') {
        if (type === 'checkbox') {
          const options = field.options
          let selectedOptions = storedFields[id] || []
          if (!checked) {
            selectedOptions.push(options[key])
          } else {
            selectedOptions = selectedOptions.filter(
              (choice: string) => choice !== options[key]
            )
          }
          event.target = {
            id: id,
            type: 'checkbox',
            checked: selectedOptions,
          }
        }
      }
      handleFieldChange(event)
    }
  }

  function handleFamilyMembersChange(event: React.BaseSyntheticEvent) {
    event.persist()
    const { id } = event.target
    const newSet = new Set(familyMembers)
    if (newSet.has(id)) {
      newSet.delete(id)
    } else {
      newSet.add(id)
    }
    setFamilyMembers(newSet)
  }

  function handleCommentChange(
    idx: FamilyMember,
    event: React.BaseSyntheticEvent
  ) {
    const newMedicalNotes = { ...medicalNotes }
    // @ts-ignore
    newMedicalNotes[`${idx}MedicalNotes`] = event?.target.value
    setMedicalNotes(newMedicalNotes)
  }

  const handleNoPsychChange = () => {
    const newNoFamilyPsychConditions = !noFamilyPsychConditions
    setNoFamilyPsychConditions(newNoFamilyPsychConditions)
  }

  const handleNoMedicalChange = () => {
    const newHasNoFamilyMedicalConditions = !hasNoFamilyMedicalConditions
    setHasNoFamilyMedicalConditions(newHasNoFamilyMedicalConditions)
  }

  function getFamilyMemberLabel(person: any) {
    const item = familyMedicalConditions.find((item) => item.id === person)
    return item?.label
  }

  // Checks that condition or comment for any family member is empty or undefined
  const noChecks = [
    'familyMembers',
    'additionalNotes',
    'familyMemberNotes',
    'noFamilyPsychConditions',
    'noFamilyMedicalConditions',
    'createdAt',
  ]
  function validateNoPsychHistory(): boolean {
    const results = Object.keys(storedFields).filter((key) => {
      if (noChecks.includes(key) || key.includes('MedicalNotes')) {
        return false
      }
      if (typeof storedFields[key] === 'string') {
        return storedFields[key] !== ''
      }
      if (Array.isArray(storedFields[key])) {
        return storedFields[key].length > 0
      }
      return false
    })
    return results.length === 0
  }

  async function handleSubmit(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    try {
      setIsLoading(true)
      const data = {
        patientId: props.patientId,
        motherPsychNotes: storedFields.motherPsychNotes || '',
        grandmotherMaternalPsychNotes:
          storedFields.grandmotherMaternalPsychNotes || '',
        grandfatherMaternalPsychNotes:
          storedFields.grandfatherMaternalPsychNotes || '',
        auntMaternalPsychNotes: storedFields.auntMaternalPsychNotes || '',
        uncleMaternalPsychNotes: storedFields.uncleMaternalPsychNotes || '',
        cousinMaternalPsychNotes: storedFields.cousinMaternalPsychNotes || '',
        fatherPsychNotes: storedFields.fatherPsychNotes || '',
        grandmotherPaternalPsychNotes:
          storedFields.grandmotherPaternalPsychNotes || '',
        grandfatherPaternalPsychNotes:
          storedFields.grandfatherPaternalPsychNotes || '',
        auntPaternalPsychNotes: storedFields.auntPaternalPsychNotes || '',
        unclePaternalPsychNotes: storedFields.unclePaternalPsychNotes || '',
        cousinPaternalPsychNotes: storedFields.cousinPaternalPsychNotes || '',
        sisterPsychNotes: storedFields.sisterPsychNotes || '',
        brotherPsychNotes: storedFields.brotherPsychNotes || '',
        daughterPsychNotes: storedFields.daughterPsychNotes || '',
        sonPsychNotes: storedFields.sonPsychNotes || '',
        motherConditions: storedFields.motherConditions || [],
        grandmotherMaternalConditions:
          storedFields.gandmotherMaternalConditions || [],
        grandfatherMaternalConditions:
          storedFields.grandfatherMaternalConditions || [],
        auntMaternalConditions: storedFields.auntMaternalConditions || [],
        uncleMaternalConditions: storedFields.uncleMaternalConditions || [],
        cousinMaternalConditions: storedFields.cousinMaternalConditions || [],
        fatherConditions: storedFields.fatherConditions || [],
        grandmotherPaternalConditions:
          storedFields.grandmotherPaternalConditions || [],
        grandfatherPaternalConditions:
          storedFields.grandfatherPaternalConditions || [],
        auntPaternalConditions: storedFields.auntPaternalConditions || [],
        unclePaternalConditions: storedFields.unclePaternalConditions || [],
        cousinPaternalConditions: storedFields.cousinPaternalConditions || [],
        brotherConditions: storedFields.brotherConditions || [],
        daughterConditions: storedFields.daughterConditions || [],
        sonConditions: storedFields.sonConditions || [],
        sisterConditions: storedFields.sisterConditions || [],
        motherMedicalNotes: medicalNotes.motherMedicalNotes || '',
        grandmotherMaternalMedicalNotes:
          medicalNotes.grandmotherMaternalMedicalNotes || '',
        grandfatherMaternalMedicalNotes:
          medicalNotes.grandfatherMaternalMedicalNotes || '',
        auntMaternalMedicalNotes: medicalNotes.auntMaternalMedicalNotes || '',
        uncleMaternalMedicalNotes: medicalNotes.uncleMaternalMedicalNotes || '',
        cousinMaternalMedicalNotes:
          medicalNotes.cousinMaternalMedicalNotes || '',
        fatherMedicalNotes: medicalNotes.fatherMedicalNotes || '',
        grandmotherPaternalMedicalNotes:
          medicalNotes.grandmotherPaternalMedicalNotes || '',
        grandfatherPaternalMedicalNotes:
          medicalNotes.grandfatherPaternalMedicalNotes || '',
        auntPaternalMedicalNotes: medicalNotes.auntPaternalMedicalNotes || '',
        unclePaternalMedicalNotes: medicalNotes.unclePaternalMedicalNotes || '',
        cousinPaternalMedicalNotes:
          medicalNotes.cousinPaternalMedicalNotes || '',
        sisterMedicalNotes: medicalNotes.sisterMedicalNotes || '',
        brotherMedicalNotes: medicalNotes.brotherMedicalNotes || '',
        daughterMedicalNotes: medicalNotes.daughterMedicalNotes || '',
        sonMedicalNotes: medicalNotes.sonMedicalNotes || '',
        familyMembers: Array.from(familyMembers) || [],
        additionalNotes: storedFields.additionalNotes || '',
        familyMemberNotes: storedFields.familyMemberNotes || '',
        noFamilyPsychConditions: noFamilyPsychConditions || false,
        noFamilyMedicalConditions: hasNoFamilyMedicalConditions || false,
      }
      if (props.response?.createdAt) {
        await submitEditFamilyHistoryRequest(data)
      } else {
        await submitNewFamilyHistoryRequest(data)
      }
      props.handleApiChange([
        patientDataTypes.ProviderSidePatientData,
        props.patientId,
      ])
      notification(
        'You have successfully updated the Family History subsection on the patient.',
        'success'
      )
      setIsLoading(false)
      setIsEditing(false)
    } catch (error) {
      console.error(
        'Error submitting information on Family History subsection of Patient Profile',
        error
      )
      onError(
        error,
        500,
        "Failed to update the patient's additional information. Please inform your administrator."
      )
      setIsLoading(false)
    }
  }
  const showNoFamilyMedicalHistory = (
    <>
      {hasNoFamilyMedicalConditions && (
        <Form.Group as={Col} xs={12} sm={12} lg={12} xl={12}>
          <Row className="form-group form-label indent psych">
            No family medical history
          </Row>
        </Form.Group>
      )}
    </>
  )

  const showNoFamilyPsychHistory = (
    <>
      {!isEditing && noFamilyPsychConditions && (
        <Form.Group as={Col} xs={12} sm={12} lg={12} xl={12}>
          <Row className="form-group form-label indent psych">
            No family psychiatric history
          </Row>
        </Form.Group>
      )}
    </>
  )

  const hasPsychConditionsAndNotes = (person: string) => {
    return (
      (storedFields[`${person}Conditions`] &&
        storedFields[`${person}Conditions`]?.length !== 0) ||
      (storedFields[`${person}PsychNotes`] &&
        storedFields[`${person}PsychNotes`] !== '')
    )
  }

  useEffect(() => {
    if (validateNoPsychHistory()) {
      setShouldNoPsychShow(true)
    } else {
      setShouldNoPsychShow(false)
      setNoFamilyPsychConditions(false)
    }
  }, [storedFields, medicalNotes, familyMembers])

  return (
    <GenericAccordion title="Family History">
      <FormMachine
        storedFields={storedFields}
        template={familyInfoTemplate}
        handleFieldChange={handleFieldChange}
        isEditing={isEditing}
        styling={styling}
      />
      <div
        className={`col-squished no-margin field-label ${
          isEditing ? 'form-label' : 'patient'
        } `}
      >
        Family history of medical conditions
      </div>
      {isEditing ? (
        <>
          <Form.Row style={{ margin: 8 }}>
            {familyMembers.size === 0 && (
              <Form.Group as={Col} xs={12} sm={6} lg={12} xl={12}>
                <Form.Check
                  checked={hasNoFamilyMedicalConditions}
                  label="No family medical history"
                  type="checkbox"
                  id="hasNoFamilyMedicalConditions"
                  onChange={handleNoMedicalChange}
                />
              </Form.Group>
            )}
            {!hasNoFamilyMedicalConditions && (
              <>
                {familyMedicalConditions.map((person, index) => {
                  return (
                    <React.Fragment key={`medical-conditions-edit-${index}`}>
                      <Form.Group as={Col} xs={12} sm={6} lg={6} xl={6}>
                        <Form.Check
                          checked={familyMembers.has(person.id)}
                          inline
                          label={person.label}
                          type="checkbox"
                          key={`medical-condition-check-${index}`}
                          id={person.id}
                          onChange={handleFamilyMembersChange}
                        />
                        {familyMembers.has(person.id) && (
                          <Form.Control
                            bsPrefix="form-input"
                            // @ts-ignore
                            value={medicalNotes[`${person.id}MedicalNotes`]}
                            onChange={(event) => {
                              handleCommentChange(person.id, event)
                            }}
                            placeholder="Medical conditions for this family member"
                            as="textarea"
                            rows={1}
                          />
                        )}
                      </Form.Group>
                    </React.Fragment>
                  )
                })}
              </>
            )}
          </Form.Row>
        </>
      ) : (
        <>
          {showNoFamilyMedicalHistory}
          {Array.from(familyMembers).map((person: any, index: number) => {
            return (
              <React.Fragment key={`medical-conditions-${index}`}>
                <Form.Group as={Col} xs={12} sm={12} lg={12} xl={12}>
                  <Row className="form-group form-label indent psych">
                    {getFamilyMemberLabel(person)}
                  </Row>
                  <Row style={{ paddingLeft: 15 }} className="indent">
                    {
                      // @ts-ignore
                      medicalNotes[`${person}MedicalNotes`]
                    }
                  </Row>
                </Form.Group>
              </React.Fragment>
            )
          })}
        </>
      )}
      <div
        className={`col-squished no-margin field-label ${
          isEditing ? 'form-label' : 'patient'
        } `}
      >
        Family psychiatric history details
      </div>
      {shouldNoPsychShow && isEditing && (
        <Form.Group as={Col} xs={12} sm={12} lg={12} xl={12}>
          <Form.Check
            style={{ margin: 2 }}
            checked={noFamilyPsychConditions}
            label="No family psychiatric history"
            type="checkbox"
            id="noPsychiatricCondition"
            onChange={handleNoPsychChange}
          />
        </Form.Group>
      )}
      {showNoFamilyPsychHistory}
      {!noFamilyPsychConditions && (
        <Row style={{ margin: 2 }}>
          <Table className="remove-padding" responsive>
            <thead>
              <tr>
                <th className="table-header" />
                <th
                  style={{ paddingLeft: '18px' }}
                  className={`${!isEditing && 'patient'}`}
                >
                  Conditions
                </th>
                <th
                  style={{ paddingLeft: '30px' }}
                  className={`${!isEditing && 'patient'}`}
                >
                  Comments
                </th>
              </tr>
            </thead>
            <tbody>
              {familyMedicalConditions.map((person, idx) => {
                return (
                  <React.Fragment key={`medical-psych-conditions-${idx}`}>
                    {!isEditing && hasPsychConditionsAndNotes(person.id) && (
                      <tr key={`medical-condition-${idx}`}>
                        <td className="align-middle font-weight-bold">
                          {person.label}
                        </td>
                        <td>
                          <FormMachine
                            storedFields={storedFields}
                            template={conditionsTemplate[idx]}
                            handleCheckChange={(id, key, checked) => {
                              handleCheckChange(id, key, checked, idx)
                            }}
                            handleFieldChange={handleFieldChange}
                            isEditing={isEditing}
                            styling={styling}
                          />
                        </td>
                        <td>
                          <FormMachine
                            storedFields={storedFields}
                            template={commentTemplate[idx]}
                            handleFieldChange={handleFieldChange}
                            isEditing={isEditing}
                            styling={styling}
                          />
                        </td>
                      </tr>
                    )}
                    {isEditing && (
                      <tr key={`medical-psych-conditions-edit-${idx}`}>
                        <td className="align-middle font-weight-bold">
                          {person.label}
                        </td>
                        <td style={{ minWidth: '600px' }}>
                          <FormMachine
                            storedFields={storedFields}
                            template={conditionsTemplate[idx]}
                            handleCheckChange={(id, key, checked) => {
                              handleCheckChange(id, key, checked, idx)
                            }}
                            handleFieldChange={handleFieldChange}
                            isEditing={isEditing}
                            styling={styling}
                          />
                        </td>
                        <td
                          className="align-middle"
                          style={{ minWidth: '380px' }}
                        >
                          <FormMachine
                            storedFields={storedFields}
                            template={commentTemplate[idx]}
                            handleFieldChange={handleFieldChange}
                            isEditing={isEditing}
                            styling={styling}
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                )
              })}
            </tbody>
          </Table>
        </Row>
      )}
      <FormMachine
        storedFields={storedFields}
        template={additionalInfoTemplate}
        handleFieldChange={handleFieldChange}
        isEditing={isEditing}
        styling={styling}
      />
      <PatientProfileButtons
        cancelEditing={cancelEditing}
        isDisabled={isDisabled}
        isEditing={isEditing}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        switchToEdit={switchToEdit}
      />
    </GenericAccordion>
  )
}
