export enum PATIENT_TABLE {
  PATIENT_NAME = 'PATIENT_NAME',
  MOOD_SCORE = 'MOOD_SCORE',
  PHQ_SCORE = 'PHQ_SCORE',
  MOOD_DATE = 'MOOD_DATE',
  PHQ_DATE = 'PHQ_DATE',
}

export const mockPatientTable = [
  {
    key: '1',
    publicId: 'testPublicId1',
    patientId: 'testPatientId1',
    name: 'Active TestName',
    isSuicidal: true,
    unreadMessageCount: 2,
    needReview: true,
    moodScore: 7,
    moodScoreDate: new Date('Wed Jan 19 2022'),
    phqScore: 10,
    phqScoreDate: new Date('Wed Feb 25 2002'),
    tags: [],
    isActive: true,
  },
  {
    key: '2',
    publicId: 'testPublicId2',
    patientId: 'testPatientId2',
    name: 'Inactive TestName',
    isSuicidal: true,
    unreadMessageCount: 0,
    needReview: false,
    moodScore: 8,
    moodScoreDate: new Date('Wed Jan 19 2022'),
    phqScore: 11,
    phqScoreDate: new Date('Fri Jan 11 2012'),
    tags: [],
    isActive: false,
  },
]
