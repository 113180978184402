import React from 'react'

import { Node } from '@tiptap/core'
import {
  NodeViewRendererProps,
  ReactNodeViewRenderer,
  mergeAttributes,
} from '@tiptap/react'

import { errorNotification } from '../../../../../libs/notificationLib'

/**
 * When adding a new attribute to a custom tag,
 * e.g. <template-block id="123" type="MEDICATIONS"></template-block>,
 * where type is the new attribute in this example,
 * add the type to this union type. This will register
 * the new attribute with the node and allow it to be
 * accessed when parsing the tag.
 */
type AddedAttribute = 'id' | 'type'

type CoreNodeProps = {
  BlockComponent: (props: NodeViewRendererProps) => React.ReactNode
  name: string
  tag: string
  addedAttributes?: AddedAttribute[]
}

type AddedAttributes = {
  [key in AddedAttribute]: {
    default: string | null
    renderHTML: (
      attributes: Record<AddedAttribute, string>
    ) => Record<string, string>
  }
}

export const CoreNode = ({
  BlockComponent,
  name,
  tag,
  addedAttributes = ['id'],
}: CoreNodeProps) =>
  Node.create({
    name,
    group: 'block',
    draggable: true,
    selectable: false,
    addNodeView() {
      return ReactNodeViewRenderer(BlockComponent)
    },

    addOptions() {
      return {
        removeBlock: () => {
          errorNotification(
            'DEVELOPER: Careful! You probably do not actually want to be modifying removeBlock.'
          )
        },
      }
    },

    addAttributes(): Partial<AddedAttributes> {
      // Iterate over the required attributes for the node,
      // and register their functionality with the node
      // Example:
      // addedAttributes = ['id']
      // returns:
      // {
      //   id: {
      //     default: null,
      //     renderHTML: (attrs) => {
      //       return { id: attrs.id }
      //     }
      //   }
      // }
      const attributes: Partial<AddedAttributes> = addedAttributes.reduce(
        (acc, current) => {
          return {
            ...acc,
            [current]: {
              default: null,
              renderHTML: (attrs: Record<AddedAttribute, string>) => {
                return {
                  // i.e.
                  // if current attribute is id, then return { id: attrs.id }
                  [current]: attrs[current],
                }
              },
            },
          }
        },
        {}
      )
      return attributes
    },

    parseHTML() {
      return [{ tag }]
    },

    renderHTML({
      HTMLAttributes,
    }: {
      HTMLAttributes: Record<AddedAttribute, string | null>
    }) {
      const addedAttributes: Partial<
        Record<AddedAttribute, string | undefined>
      > = {}

      // This prevents the tag from adding null values to HTMLAttributes
      // if the attribute is not present in the added tags.
      for (const key in HTMLAttributes) {
        const currentAttribute = HTMLAttributes[key as AddedAttribute]
        if (currentAttribute) {
          addedAttributes[key as AddedAttribute] = currentAttribute
        }
      }

      return [tag, mergeAttributes(addedAttributes)]
    },
  })
