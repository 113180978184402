import React, { useEffect, useState } from 'react'

import 'moment-timezone'
import { Button, Col, Form, Row } from 'react-bootstrap'
import 'react-dates/initialize'

import { convertTime12to24 } from '../../../../../shared/Helpers/utils'
import CustomTimePicker from '../../../../../stories/BaseComponents/CustomTimePicker'
import { generateSpravatoClassName } from '../NoteType/Spravato/Spravato'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function ShowMedicationSpravato(props) {
  const [name, setName] = useState(props.Name || '')
  const [dose, setDose] = useState(props.Dose || '')
  const [time, setTime] = useState(props.Time || '')
  const [comments, setComments] = useState(props.Comments || '')

  useEffect(() => {
    setName(props.Name || '')
    setDose(props.Dose || '')
    setTime(props.Time || '')
    setComments(props.Comments || '')
  }, [props.Name, props.Dose, props.Time, props.Comments])

  useEffect(() => {
    const fields = {
      Name: name,
      Dose: dose,
      Time: time,
      Comments: comments,
    }
    props.saveEditAdditionalMedication(props.index, fields)
  }, [name, dose, time, comments])

  return (
    <>
      <tr>
        <td>
          <Form.Control
            bsPrefix={
              name === '' ? generateSpravatoClassName(true) : 'form-input'
            }
            style={{ alignSelf: 'center', textAlign: 'center' }}
            value={name}
            as="textarea"
            rows="1"
            disabled={props.disabled}
            onChange={(e) => setName(e.target.value)}
          />
        </td>
        <td>
          <Form.Control
            bsPrefix={
              dose === '' ? generateSpravatoClassName(true) : 'form-input'
            }
            style={{ alignSelf: 'center', textAlign: 'center' }}
            value={dose}
            as="textarea"
            rows="1"
            disabled={props.disabled}
            onChange={(e) => setDose(e.target.value)}
          />
        </td>
        <td>
          <CustomTimePicker
            value={time}
            onChange={(value) => {
              setTime(convertTime12to24(value))
            }}
            disabled={props.disabled}
          />
        </td>
        <td>
          <Form.Control
            bsPrefix="form-input"
            style={{ alignSelf: 'center', textAlign: 'center' }}
            value={comments}
            as="textarea"
            rows="1"
            disabled={props.disabled}
            onChange={(e) => setComments(e.target.value)}
          />
        </td>
        <td className="center">
          <Row>
            <Col>
              {!props.disabled && props.medicationType?.length !== 1 && (
                <Button
                  bsPrefix="center plain-button info button-label"
                  onClick={() => {
                    props.deleteAM(props.index)
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true" />
                  &nbsp;Delete
                </Button>
              )}
            </Col>
          </Row>
        </td>
      </tr>
    </>
  )
}
