export enum Credentials {
  RD = 'RD',
  PHS = 'PHS',
  PAC = 'PA-C',
  PA = 'PA',
  ND = 'ND',
  MSN = 'MSN',
  MSED = 'MSEd',
  MS = 'MS',
  MPS = 'MPS',
  MPH = 'MPH',
  MOT = 'MOT',
  MHDL = 'MHDL',
  MED = 'MEd',
  MDIV = 'MDiv',
  MD = 'MD',
  MBCHB = 'MBChB',
  MBBS = 'MBBS',
  EDM = 'EdM',
  EDD = 'EdD',
  DRPH = 'DrPH',
  DO = 'DO',
  DNP = 'DNP',
  DND = 'DND',
  DMIN = 'DMin',
  DMD = 'DMD',
  DDS = 'DDS',
  DDIV = 'DDiv',
  DC = 'DC',
  CNM = 'CNM',
  CMPC = 'CMPC',
  BSN = 'BSN',
  APRN = 'APRN',
  ADN = 'ADN',
  PHD = 'PhD',
  PSYD = 'PsyD',
  MSOP = 'MSOP',
  MSSEP = 'MSSEP',
  MASEP = 'MASEP',
  MSSP = 'MSSP',
  LCSP = 'LCSP',
  DSW = 'DSW',
  MSSW = 'MSSW',
  MSS = 'MSS',
  MASS = 'MASS',
  MSW = 'MSW',
  LCSW = 'LCSW',
  PSYAD = 'PsyaD',
  MA = 'MA',
  MAHS = 'MAHS',
  MAMFC = 'MAMFC',
  MAOP = 'MAOP',
  MC = 'MC',
  MCOUN = 'M.Coun',
  LCAT = 'LCAT',
  MFT = 'MFT',
  LMFT = 'LMFT',
  MHC = 'MHC',
  LMHC = 'LMHC',
  BA = 'BA',
  BS = 'BS',
  AA = 'AA',
  AS = 'AS',
}
