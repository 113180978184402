import { blue } from '@ant-design/colors'
import { SearchOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'

import { getSurveysByDateRange } from '../../../api/surveys'
import SearchBox from '../../BaseComponents/SearchBox'
// Replace with structured map of provider list
import { SurveysReportData } from '../../BaseComponents/tableData.types'
import { METRICS_HEADER_TOTAL_SURVEYS } from '../constants'
import { GraphBatchingConfigs, GraphFilterConfigs } from '../types'

export default {
  fetch: getSurveysByDateRange,
  header: {
    description: 'Explore your practice’s surveys.',
    link: {
      url: 'https://support.osmind.org/en/articles/5665898-reports',
      text: 'Learn more.',
    },
    metrics: [{ title: METRICS_HEADER_TOTAL_SURVEYS, dataSource: 0 }],
    title: 'Surveys',
  },
  graph: {},
  graphFilterConfigs: [] as GraphFilterConfigs[],
  dataBatching: {
    options: [],
    fetchKeyAlias: {},
  } as GraphBatchingConfigs,
  table: {
    columns: [
      {
        title: 'Patient name',
        dataIndex: ['patientName', 'patientId'],
        key: 'patientName',
        render: (_text, record) => (
          <Link
            style={{ color: blue.primary }}
            to={`/patient/progress?patientId=${record.patientId}&providerId=${record.providerId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record.patientName}
          </Link>
        ),
        sorter: (a, b) => a.patientName.localeCompare(b.patientName),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{ color: filtered ? blue.primary : undefined }}
          />
        ),
        filterDropdown: (args) => (
          <SearchBox
            dataIndex="patientName"
            placeholder="Search Patient Name"
            {...args}
          />
        ),
        onFilter: (value, record: SurveysReportData) =>
          record.patientName
            ? record.patientName
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            : false,
      },
      {
        title: 'Survey type',
        dataIndex: 'type',
        key: 'type',
        sorter: (a, b) => a.type.localeCompare(b.type),
        filterDropdown: (args) => (
          <SearchBox
            dataIndex="type"
            placeholder="Search Survey Type"
            {...args}
          />
        ),
        onFilter: (value, record) =>
          record.type
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase()),
      },
      {
        title: 'Survey score',
        dataIndex: 'score',
        key: 'score',
      },
      {
        title: 'Date completed',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => a.date.valueOf() - b.date.valueOf(),
        render: (date) => date.toLocaleDateString(),
      },
    ] as ColumnsType<SurveysReportData>,
    csvHeaders: [
      { label: 'Patient name', key: 'patientName' },
      { label: 'Survey type', key: 'type' },
      { label: 'Survey score', key: 'score' },
      { label: 'Date completed', key: 'date' },
    ],
  },
}
