import { Editor } from '@tiptap/react'

import { determineInsertLocation } from '../utils'

export const SOAPTemplate = (editor: Editor) => ({
  text: 'SOAP Template',
  key: 'SOAP',
  onClick: () => {
    const newlineNode = {
      type: 'paragraph',
      content: [],
    }

    const endPosition = determineInsertLocation(editor)
    editor
      .chain()
      .insertContentAt(endPosition, [
        {
          type: 'heading',
          attrs: { level: 1 },
          content: [{ type: 'text', text: 'Subjective' }],
        },
        {
          type: 'heading',
          attrs: { level: 3 },
          content: [{ type: 'text', text: 'Chief complaint:' }],
        },
        newlineNode,
        {
          type: 'heading',
          attrs: { level: 3 },
          content: [{ type: 'text', text: 'History of present illness:' }],
        },
        newlineNode,
        {
          type: 'heading',
          attrs: { level: 1 },
          content: [{ type: 'text', text: 'Objective' }],
        },
        newlineNode,
        newlineNode,
        {
          type: 'heading',
          attrs: { level: 1 },
          content: [{ type: 'text', text: 'Assessment' }],
        },
        newlineNode,
        newlineNode,
        {
          type: 'heading',
          attrs: { level: 1 },
          content: [{ type: 'text', text: 'Plan' }],
        },
        newlineNode,
        newlineNode,
      ])
      .run()
  },
})
