import React from 'react'

import { format, getMinutes } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

interface TimeSlotWrapperProps {
  value: Date
  timezone: string
}

export default function TimeSlotWrapper(props: TimeSlotWrapperProps) {
  const value = utcToZonedTime(props.value, props.timezone)
  let time = ''
  if (!value || getMinutes(value)) return <React.Fragment />
  time = format(value, 'haaa')
  return <span>{time}</span>
}
