import React from 'react'

import {
  Checkbox as AntDCheckbox,
  CheckboxProps as AntDCheckboxProps,
} from 'antd'
import { CheckboxGroupProps } from 'antd/lib/checkbox'

import { TestId } from '../../shared-types'

type CheckboxProps = AntDCheckboxProps & TestId

const Checkbox: React.FC<CheckboxProps> = ({ children, testId, ...props }) => {
  return (
    <AntDCheckbox data-testid={testId} {...props}>
      {children}
    </AntDCheckbox>
  )
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  children,
  ...props
}) => {
  return <AntDCheckbox.Group {...props}>{children}</AntDCheckbox.Group>
}

export { CheckboxGroup }
export default Checkbox
