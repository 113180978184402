import React from 'react'

import { Button, Spinner } from 'react-bootstrap'

import './LoaderButton.scss'

// isDisabled and isLoading currently set to any to avoid errors
// involving numbers given as props (ie. !example.length for isDisabled)
// TODO: change isDisabld and isLoading to booleans + change relevant instances
// of LoaderButton throughout repo
interface LoaderButtonProps {
  bsPrefix?: string
  children?: React.ReactNode | null
  className?: string
  containerClassName?: string
  isDisabled?: any
  isLoading?: any
  loadingText?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  style?: object
  textInside?: string
  type?: string
  [key: string]: any
  variant?: string
}

export default function LoaderButton({
  bsPrefix = '',
  className = '',
  children = null,
  containerClassName = '',
  isDisabled = false,
  isLoading = false,
  loadingText = '',
  style = {},
  textInside = '',
  type = 'submit',
  variant = '',
  ...props
}: LoaderButtonProps) {
  return (
    <div className={containerClassName}>
      <Button
        style={style}
        type={type}
        bsPrefix={variant ? bsPrefix : `button-block center ${bsPrefix}`}
        variant={variant}
        className={`loader-button ${className}`}
        disabled={isDisabled || isLoading}
        {...props}
      >
        {isLoading ? (
          <div>
            <Spinner
              animation="border"
              variant="danger"
              className="loader-button-spinner"
            />
            &nbsp;&nbsp;{loadingText || textInside}
          </div>
        ) : (
          <>{children}</>
        )}
      </Button>
    </div>
  )
}
