import React from 'react'

import { ModalBasic } from '../../../../stories/BaseComponents'
import { ClaimFormStatus } from '../types'
import { isFormBusy, isFormDeleting, isFormSaving } from '../utils'

type UnsavedChangesModalProps = {
  onSave: () => void
  onDiscard: () => void
  onCancel: () => void
  visible: boolean
  formStatus: ClaimFormStatus
}

export const testIds = {
  content: 'unsaved-changes-modal-content',
  cancelButton: 'unsaved-changes-modal-cancel-button',
  confirmButton: 'unsaved-changes-modal-confirm-button',
  secondaryActionButton: 'unsaved-changes-modal-secondary-action-button',
}

const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
  onSave,
  onDiscard,
  onCancel,
  visible,
  formStatus,
}) => {
  const isDisabled = isFormBusy(formStatus)

  return (
    <ModalBasic
      visible={visible}
      title={'Unsaved changes'}
      content={'Would you like to save your work before leaving?'}
      onCancel={onCancel}
      onConfirm={onSave}
      onSecondaryAction={onDiscard}
      cancelButtonProps={{
        disabled: isDisabled,
        testId: testIds.cancelButton,
      }}
      confirmButtonLabel={'Save'}
      confirmButtonProps={{
        loading: isFormSaving(formStatus),
        disabled: isDisabled,
        testId: testIds.confirmButton,
      }}
      hasSecondaryAction
      secondaryActionButtonLabel={`Don't save`}
      secondaryActionButtonProps={{
        loading: isFormDeleting(formStatus),
        disabled: isDisabled,
        testId: testIds.secondaryActionButton,
      }}
      contentTestId={testIds.content}
    />
  )
}

export default UnsavedChangesModal
