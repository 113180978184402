import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { SpinProps as AntDSpinProps, Spin } from 'antd'

interface SpinProps extends AntDSpinProps {
  fontSize?: number
}

const Spinner: React.FC<SpinProps> = (props) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: props.fontSize || 40 }} spin />
  )
  return <Spin indicator={antIcon} {...props} />
}

export default Spinner
