// Import react
import React from 'react'

// Import libs/other
import Moment from 'moment'
// Import components
import {
  Accordion,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap'

// Import styles
import './JournalAccordion.scss'

const JournalAccordion = (props) => {
  // show sideffect icon, obly show preview of text. try long journal entries.
  // sort the journal entries by date. API Side?
  let noteSnippet = props.note.NoteText.substring(0, 120)
  if (noteSnippet.length > 119) {
    noteSnippet = noteSnippet + '...'
  }
  const adverseEffectPill = props.note.IsSideEffect ? (
    <OverlayTrigger
      key="right"
      placement="right"
      overlay={<Tooltip bsPrefix="tooltip-x">Adverse effects reported</Tooltip>}
    >
      <i className="fa fa-exclamation-triangle danger" aria-hidden="true" />
    </OverlayTrigger>
  ) : (
    <></>
  )

  return (
    <Container>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={props.index.toString()}
        id={'header' + props.index}
        bsPrefix="patient-card"
      >
        <Container>
          <Row>
            <Col id={'journalToogleDate' + (props.index + 1)} className="info">
              {Moment(new Date(props.note.CreatedOn))
                .format('dddd, LL')
                .toString()}{' '}
              &nbsp; {adverseEffectPill}
            </Col>
          </Row>
          <Row>
            <Col
              id={'journalToogleText' + (props.index + 1)}
              className="emphasis-light"
            >
              {noteSnippet}
            </Col>
          </Row>
        </Container>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={props.index.toString()}>
        <Container className="indent">
          <Row className="marginal">
            <Col md={12} lg={1} className="emphasis-light">
              Date:
            </Col>
            <Col id={'journalCollapseDate' + (props.index + 1)} md={12} lg={11}>
              {Moment(new Date(props.note.CreatedOn))
                .format('MMMM DD, YYYY')
                .toString()}
            </Col>
          </Row>
          <Row className="marginal">
            <Col md={12} lg={1} className="emphasis-light">
              Entry:
            </Col>
            <Col id={'journalCollapseText' + (props.index + 1)} md={12} lg={11}>
              {props.note.NoteText}
            </Col>
          </Row>
        </Container>
      </Accordion.Collapse>
    </Container>
  )
}

export default JournalAccordion
