import { API } from 'aws-amplify'

// import { API } from 'aws-amplify'
type Permission = 'READ' | 'WRITE'

export enum Titles {
  SUPERADMIN = 'Super-Admin',
  ADMIN = 'Admin',
  PROVIDER = 'Provider',
  CONTRIBUTOR = 'Contributor',
  BILLER = 'Biller',
}

export type UserPermission = {
  type: String
  id: String
  issuedAt: String
  role: Roles
}

export type Roles = {
  adminUsers?: Permission
  nonAdminUsers?: Permission
  notes?: Permission
  renderServices?: Permission
  erx?: Permission
  reports?: Permission
  zoom?: Permission
  canSeeAllPatients?: Permission
  labsServices?: Permission
}

export type grantRoleResponse = {}

interface updateRolesData {
  roleIds: number[]
  providerId: string
}

type getPermissionCookie = () => Promise<UserPermission>

type grantRole = (createRoleObj: {
  roleIds: number[]
  providerId: string
}) => Promise<[]>

type revokeRole = (revokeRoleObj: {
  roleIds: number[]
  providerId: string
}) => Promise<[]>

type updateRoles = (updateRoles: {
  providerId: string
  roleIds: number[]
}) => Promise<void>

export const getPermissionCookie: getPermissionCookie = async () => {
  return API.get('security', '/roles/getPermissionCookie', {})
}

export const grantRole: grantRole = async (data) => {
  return API.post('security', '/roles/grant', { body: data })
}

export const revokeRole: revokeRole = async (data) => {
  return API.del('security', '/roles/revoke', { body: data })
}

export function getRoles() {
  return API.get('security', '/roles/list', {})
}

export function getLoggedinUserRoles() {
  return API.get('security', '/roles/userRoles', {})
}

export const updateUserRoles: updateRoles = async (data: updateRolesData) => {
  return API.post('security', '/roles/updateRoles', { body: data })
}
