import { QueryKey } from '@tanstack/react-query'
import { API } from 'aws-amplify'
import { Moment } from 'moment'

import {
  Appointment,
  AppointmentStatus,
  DateISO8601,
  GetAppointmentsQueryStringParameters,
  PatientSidePanelEvents,
  Room,
  ScheduledEvent,
} from '../shared-types'
import { UserCalendarAccount } from '../stories/Scheduling/types'

// billing
export async function deletePaymentMethod(
  paymentMethodUuid: string,
  patientId: string
) {
  return await API.del('billing', '/billing/payment-method', {
    body: { uuid: paymentMethodUuid, patientId },
  })
}

export async function updatePaymentMethod(paymentMethodData: {
  cardholderName?: string | null
  cardNickname?: string | null
  isDefault?: boolean | null
  patientId: string
  uuid: string
  zipCode?: string | null
}) {
  return await API.put('billing', '/billing/payment-methods', {
    body: paymentMethodData,
  })
}

// core
export function getEmailTemplate(): Promise<string> {
  return API.get('core-api', '/provider/email-template', {})
}

export function updateEmailTemplate(emailTemplate: string) {
  return API.post('core-api', '/provider/email-template', {
    body: { emailTemplate },
  })
}

// scheduling
export const getPatientSidePanelEvents = (
  patientId: string,
  date: Date
): Promise<PatientSidePanelEvents> => {
  return API.get(
    'scheduling',
    `/scheduling/patient/events?patientId=${patientId}&date=${date.toISOString()}`,
    {}
  )
}

export type GetAppointmentsParameters = {
  endDate: DateISO8601
  includeDetails: boolean
  patientId?: string
  startDate: DateISO8601
  statuses?: AppointmentStatus[]
}

export const getAppointments = <T extends boolean>({
  includeDetails,
  statuses,
  endDate,
  startDate,
  patientId,
}: GetAppointmentsParameters): Promise<{
  appointments: Appointment<T>[]
  canSeeAllPatients: boolean
}> => {
  const queryStringParameters: GetAppointmentsQueryStringParameters = {
    endDate,
    startDate,
    includeDetails: includeDetails.toString(),
  }
  if (statuses && statuses.length > 0) {
    queryStringParameters.statuses = statuses.toString()
  }

  if (patientId) {
    queryStringParameters.patientId = patientId
  }
  return API.get('scheduling', '/scheduling/appointments', {
    queryStringParameters,
  })
}

export type ChangeAppointmentRequestStatusBody = {
  appointmentStatus: AppointmentStatus
  sendCancellationMessage?: boolean
  cancellationReason?: string
}

export const changeAppointmentRequestStatus = (
  appointmentId: string,
  {
    appointmentStatus,
    cancellationReason,
    sendCancellationMessage,
  }: ChangeAppointmentRequestStatusBody
): Promise<void> => {
  return API.post(
    'scheduling',
    `/scheduling/change-appointment-status/${appointmentId}`,
    {
      body: {
        appointmentStatus,
        cancellationReason,
        sendCancellationMessage,
      },
    }
  )
}

export type DayOfWeek = 'SUN' | 'MON' | 'TUE' | 'WED' | 'THUR' | 'FRI' | 'SAT'

export type AvailabilitySettingsResponse = {
  [providerId: string]: {
    providerAvailabilityDayId: string
    dayOfWeek: DayOfWeek
    isUnavailable: boolean
    providerAvailabilityTimeslots: {
      startTimeHour: number
      startTimeMinute: number
      durationInMinutes: number
    }[]
  }[]
}

export const getAvailabilitySettings =
  async (): Promise<AvailabilitySettingsResponse> => {
    return API.get('scheduling', `/scheduling/get-providers-availability/`, {})
  }

export type UpdateProviderAvailabilitySettingsBody = {
  providerAvailabilityDays: {
    providerAvailabilityDayId: string
    isUnavailable: boolean
    dayOfWeek: DayOfWeek
    providerAvailabilityTimeslots: {
      startTimeHour: number
      startTimeMinute: number
      durationInMinutes: number
    }[]
  }[]
}

export const updateAvailabilitySettings = async ({
  providerAvailabilityDays,
}: UpdateProviderAvailabilitySettingsBody): Promise<void> => {
  return API.post('scheduling', '/scheduling/updateProviderAvailability', {
    body: {
      providerAvailabilityDays,
    },
  })
}

export function getScheduledEvents({
  queryKey,
}: {
  queryKey: QueryKey
}): Promise<{
  accountsWithErrors: string[]
  events: ScheduledEvent[]
}> {
  const [_, start, end, consolidateGoogleEvents = false] = queryKey as [
    string,
    string,
    boolean,
    boolean
  ]
  return API.get(
    'scheduling',
    `/scheduling?start=${start}&end=${end}&consolidateGoogleEvents=${consolidateGoogleEvents}`,
    {}
  )
}

export async function getIntegratedCalendarsForSecondaryProviders(): Promise<
  UserCalendarAccount[]
> {
  return await API.get('scheduling', '/calendar/integrated/secondary', {})
}

export function getRooms(): Promise<Room[]> {
  return API.get('scheduling', '/rooms', {})
}

export function createRoom(data: {
  name: string
  locationId: string
}): Promise<{ roomId: string }> {
  return API.post('scheduling', '/rooms', { body: data })
}

export type UpdateRoomRequestBody = {
  name: string
}

export function updateRoom(
  roomId: string,
  body: UpdateRoomRequestBody
): Promise<Room> {
  return API.patch('scheduling', `/rooms/${roomId}`, {
    body,
  })
}

export function deleteRoom(roomId: string): Promise<{ roomId: string }> {
  return API.del('scheduling', `/rooms/${roomId}`, {})
}

// ----------------------------------------------
// ----------------------------------------------
// ---------------- Diagnoses -------------------
// ----------------------------------------------
// ----------------------------------------------

export type Diagnosis = {
  PatientId: string
  // Diagnosis description
  Diagnosis: string
  DiagnosisCode: string
  DiagnosisDate: Moment
  DiagnosisOnsetDate: Moment | null
  DiagnosisNoteContent: string
  DiagnosisStatus: string
  DiagnosisOnsetDateFormat: string
}
// We should address this with better generic typing,
// holding of on this until the work to port BE types to FE is complete
export type FullDiagnosis = {
  PatientId: string
  // Diagnosis description
  Diagnosis: string
  DiagnosisCode: string
  DiagnosisDate: string
  DiagnosisOnsetDate: string
  DiagnosisNoteContent: string
  DiagnosisStatus: string
  DiagnosisOnsetDateFormat: string
  CreatedAt: DateISO8601 | null
  CreatedBy: string
  CreatedOn: DateISO8601 | null
  DiagnosisCodeIsCustom: boolean
  DiagnosisId: string
  DiagnosisImpact: {
    activities: boolean
    finances: boolean
    housing: boolean
    legalMatters: boolean
    recreational: boolean
    relationships: boolean
    school: boolean
    selfEsteem: boolean
    sexual: boolean
    work: boolean
  }
  IsUploaded: null | boolean
  IsUpdatedInRCopia: null | boolean
  IsUploadedToRCopia: null | boolean
  UpdatedBy?: null | string
  UpdatedOn?: DateISO8601 | null
  UploadedToRCopia: null | boolean
  WasUpdatedInRCopia: null | boolean
  isPatientReported: null | boolean
}

export function getDiagnosis({
  diagnosisId,
  patientId,
}: {
  diagnosisId: string
  patientId: string
}): Promise<FullDiagnosis> {
  return API.get(
    'patient-profile',
    `/patients/v2/${patientId}/diagnosis/${diagnosisId}`,
    {}
  )
}

export function createNewDiagnosis(
  patientId: string,
  data: Diagnosis
): Promise<{
  DiagnosisId: string
}> {
  return API.post('patient-profile', `/patients/${patientId}/diagnoses`, {
    body: data,
  })
}

export function createRCopiaDiagnosis(
  data: Diagnosis & {
    PatientRCopiaId: string
    DiagnosisId: string
  }
) {
  return API.post('erx', '/sendDiagnosis', { body: data })
}

export function editDiagnosis(
  diagnosisId: string,
  patientId: string,
  data: Diagnosis
) {
  return API.put(
    'patient-profile',
    `/patients/${patientId}/diagnoses/${diagnosisId}`,
    { body: data }
  )
}

export function editRCopiaDiagnosis(
  data: Diagnosis & { PatientRCopiaId: string }
) {
  return API.post('erx', '/editDiagnosis', {
    body: { ...data, UpdateEnum: 'edit' },
  })
}

export function deleteDiagnosis(diagnosisId: string, patientId: string) {
  return API.del(
    'patient-profile',
    `/patients/${patientId}/diagnoses/${diagnosisId}`,
    {}
  )
}

export function deleteRCopiaDiagnosis(data: {
  PatientId: string
  DiagnosisId: string
  PatientRCopiaId: string
  Diagnosis: Diagnosis
  DiagnosisCode: string
}) {
  return API.post('erx', '/editDiagnosis', {
    body: { ...data, UpdateEnum: 'delete' },
  })
}
