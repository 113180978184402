import React, { useEffect, useState } from 'react'

import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import { Accordion, Card } from 'react-bootstrap'

import {
  submitEditOtherNotesRequest,
  submitNewOtherNotesRequest,
} from '../../../api/api-lib'
import { onError } from '../../../libs/errorLib'
import { useFormFields } from '../../../libs/hooksLib'
import { notification } from '../../../libs/notificationLib'
import { DateISO8601 } from '../../../shared-types'
import { patientDataTypes } from '../patient-data-types'
import EditNotes from './Tables/EditNotes'
import SavedNotes from './Tables/SavedNotes'

import '../PatientProfile.scss'

export interface OtherNotesFields {
  additionalNotes?: string
  clinicalTrialNotes?: string
  createdAt?: DateISO8601
}

interface OtherNotesProps {
  handleApiChange: (queryName: string[]) => void
  otherNotes: OtherNotesFields
  patientId: string
}

export default function OtherNotes(props: OtherNotesProps) {
  const [copy, setCopy] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentData, handleFieldChange] = useFormFields({
    additionalNotes: props.otherNotes.additionalNotes || '',
    clinicalTrialNotes: props.otherNotes.clinicalTrialNotes || '',
  })

  function copyCurrentData() {
    setCopy(_cloneDeep(currentData))
  }

  function switchToEdit() {
    copyCurrentData()
    setIsEditing(true)
  }

  function switchToSave() {
    setIsEditing(false)
  }

  function checkIfSaveButtonShouldBeDisabled() {
    const haveNotesNotChanged = _isEqual(currentData, copy)
    setIsDisabled(haveNotesNotChanged)
  }

  function revert() {
    handleFieldChange(_cloneDeep(copy), false)
    switchToSave()
  }

  useEffect(() => {
    copyCurrentData()
  }, [])

  useEffect(() => {
    checkIfSaveButtonShouldBeDisabled()
  }, [currentData])

  async function handleSubmit(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    try {
      setIsLoading(true)
      const data = {
        ...currentData,
        patientId: props.patientId,
      }

      if (props.otherNotes?.createdAt) {
        await submitEditOtherNotesRequest(data)
      } else {
        await submitNewOtherNotesRequest(data)
      }
      props.handleApiChange([
        patientDataTypes.ProviderSidePatientData,
        props.patientId,
      ])

      notification(
        'You have successfully updated the Other subsection on the patient.',
        'success'
      )
      copyCurrentData()
      setIsLoading(false)
      setIsDisabled(true)
      switchToSave()
    } catch (error) {
      console.error(
        'Error submitting information on Other subsection of Patient Profile',
        error
      )
      onError(
        error,
        500,
        "Failed to update the patient's additional information. Please inform your administrator."
      )
    }
  }

  return (
    <Accordion defaultActiveKey="1">
      <Accordion.Toggle
        as={Card.Header}
        bsPrefix="accordion-header very-large accordion-toggle"
        eventKey="0"
      >
        <div style={{ cursor: 'pointer' }}>Other</div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        {isEditing ? (
          <EditNotes
            currentData={currentData}
            handleFieldChange={handleFieldChange}
            handleSubmit={handleSubmit}
            isDisabled={isDisabled}
            isLoading={isLoading}
            revert={revert}
          />
        ) : (
          <SavedNotes currentData={currentData} switchToEdit={switchToEdit} />
        )}
      </Accordion.Collapse>
    </Accordion>
  )
}
