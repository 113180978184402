import React from 'react'

import { Accordion, Card, Container } from 'react-bootstrap'

interface AccordionProps {
  children?: React.ReactNode | null
  collapseClassName?: string
  containerClassName?: string
  key?: string
  title: string
  toggleClassName?: string
  testId?: string
}

export default function GenericAccordion(props: AccordionProps) {
  return (
    <Accordion
      data-testid={props.testId ?? ''}
      className={props.containerClassName}
      defaultActiveKey="1"
      key={props.key || ''}
    >
      <Accordion.Toggle
        as={Card.Header}
        bsPrefix={
          props.toggleClassName ||
          'accordion-header very-large accordion-toggle'
        }
        eventKey="0"
      >
        <div style={{ cursor: 'pointer' }}>{props.title}</div>
      </Accordion.Toggle>
      <Accordion.Collapse className={props.collapseClassName} eventKey="0">
        <Container>{props.children}</Container>
      </Accordion.Collapse>
    </Accordion>
  )
}
