import { useQuery } from '@tanstack/react-query'

import { getAmdConnection } from '../../api/api-lib'
import { useFeatureFlags } from '../../libs/featureFlags'
import { Card } from '../BaseComponents'
import AmdForm from './AmdForm'
import Providers from './Providers'

import styles from '../../containers/_shared.module.scss'
import './_amd.scss'

const Main = () => {
  const { advancedmd } = useFeatureFlags()
  const { data: amd, isInitialLoading } = useQuery(
    ['advancedmd'],
    getAmdConnection,
    {
      enabled: advancedmd,
    }
  )

  if (!advancedmd) return null

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        <div className="advancedmd">
          <Card bordered={false}>
            <div className="headerWrapper">
              <span className="headerWrapper_title">
                AdvancedMD Integration
              </span>
              <span className="headerWrapper_description">
                Connect to AdvancedMD by entering your practice's AMD login
                details.
              </span>
            </div>

            <AmdForm amd={amd} isLoading={isInitialLoading} />
            <Providers isAmdConnected={!!amd?.username} />
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Main
