import React from 'react'

import { Col, Form, Row } from 'react-bootstrap'

import { generateComponent } from '../FormMachine'
import { TemplateField } from '../types'
import { DropdownEditProps } from './types'

export default function DropdownEditField(props: DropdownEditProps) {
  const { handleFieldChange, storedFields, styling, templateField, value } =
    props

  const {
    columnSizes,
    conditionalOption = '',
    conditionalComponent = null,
    id,
    label,
    options = [],
    size = 1,
  } = templateField

  const {
    colBsPrefix = '',
    colClassName = '',
    colStyle = {},
    editLabelBsPrefix = '',
    editLabelClassName = '',
    editLabelStyle = {},
  } = styling

  let conditionalSection = null

  if (value === conditionalOption && conditionalComponent) {
    conditionalSection = generateComponent(
      {
        handleFieldChange,
        isEditing: true,
        storedFields,
        styling,
      },
      conditionalComponent as TemplateField
    )
  }

  return (
    <Col
      bsPrefix={colBsPrefix}
      className={colClassName}
      style={colStyle}
      {...columnSizes}
    >
      <Row>
        <Col>
          <Form.Group controlId={id}>
            <Form.Label
              bsPrefix={editLabelBsPrefix}
              className={editLabelClassName}
              style={editLabelStyle}
            >
              {label}
            </Form.Label>
            <Form.Control
              as="select"
              onChange={handleFieldChange}
              htmlSize={size}
              defaultValue={value}
            >
              {/*
                  'Select...' is the first option and disappears upon value change
                  in order to avoid misleading the user that the first option presented is saved
              */}
              {!value && <option> Select... </option>}
              {options.map((option, index) => {
                return <option key={'dropdown-' + index}> {option} </option>
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        {conditionalSection}
      </Row>
    </Col>
  )
}
