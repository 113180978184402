import React, { useEffect, useRef, useState } from 'react'

import { LeftOutlined } from '@ant-design/icons'
import { Affix, Button } from 'antd'
import { useHistory } from 'react-router-dom'

import { Title } from '../BaseComponents'

import styles from './NavigatorHeaderDetail.module.scss'

interface NavigatorHeaderDetailProps {
  title: string
  backButtonText: string
  container: HTMLDivElement | null
  updatePosition?: boolean
  setUpdatePosition?: (state: boolean) => void
  scrollTop?: number
}

const NavigatorHeaderDetail: React.FC<NavigatorHeaderDetailProps> = ({
  title,
  backButtonText,
  container,
  updatePosition = false,
  setUpdatePosition,
  scrollTop,
}) => {
  const [fixed, setFixed] = useState(false)
  const affixRef: React.Ref<any> = useRef(null)

  const history = useHistory()

  const handleClick = () => {
    const searchParams = new URLSearchParams(location.search)

    history.replace({
      search: location.search.replace(
        searchParams
          .toString()
          .slice(searchParams.toString().indexOf('profilePanel')),
        `profilePanel=overview`
      ),
    })
  }

  useEffect(() => {
    if (updatePosition) {
      affixRef?.current?.lazyUpdatePosition()
      setUpdatePosition && setUpdatePosition(false)
    }
  }, [updatePosition])

  useEffect(() => {
    if (scrollTop && scrollTop > 0) {
      setFixed(true)
    } else {
      setFixed(false)
    }
  }, [scrollTop])

  return (
    <Affix
      className={`${styles.antAffixOverride}`}
      ref={affixRef}
      onChange={(e) => setFixed(Boolean(e))}
      target={() => container}
    >
      <div className={`${styles.container} ${fixed && styles.shadow}`}>
        <Button
          className={styles.backButton}
          icon={<LeftOutlined />}
          type="link"
          onClick={handleClick}
        >
          {backButtonText}
        </Button>
        <Title level={4} className={styles.title}>
          {title}
        </Title>
      </div>
    </Affix>
  )
}

export default NavigatorHeaderDetail
