import React from 'react'

import { Button, Col, Form } from 'react-bootstrap'

import ShowTreatments from './Tables/ShowTreatments'

export default function Treatments(props) {
  function showSavedTreatments() {
    if (props.patientData.Treatments.length !== 0) {
      return (
        <>
          {props.patientData.Treatments.map((treatment, index) => {
            return (
              <ShowTreatments
                key={index}
                index={index}
                deleteTreatment={props.deleteTreatment}
                TreatmentType={treatment.treatmentType}
                TherapyType={treatment.therapyType}
                NumberSessions={treatment.numberSessions}
                StartDate={treatment.startDate}
                EndDate={treatment.endDate}
                ReasonForTreatment={treatment.reasonForTreatment}
                TreatmentEfficacy={treatment.treatmentEfficacy}
                NameOfClinician={treatment.nameOfClinician}
                AdditionalNotes={treatment.additionalNotes}
                DateFormat={treatment.dateFormat}
                disabled={props.disabled}
                saveTreatment={props.saveTreatment}
              />
            )
          })}
        </>
      )
    }
  }

  return (
    <>
      <Form.Row className="border-bottom" sm={12} md={12} lg={12}>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Treatments:
          </Form.Label>
          {props.patientData.Treatments.length === 0 && (
            <Form.Check
              type="checkbox"
              id="noTreatmentCheckbox"
              label="No relevant previous treatments"
              checked={JSON.parse(props.patientData.NoPreviousTreatments)}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoPreviousTreatments: !props.patientData.NoPreviousTreatments,
                })
              }}
            />
          )}
          {showSavedTreatments()}
        </Form.Group>
        {props.patientData.NoPreviousTreatments ? (
          ''
        ) : (
          <Form.Group
            as={Col}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ marginLeft: 'auto' }}
          >
            <Button
              bsPrefix="plain-button center info button-label"
              onClick={() => {
                props.saveNewTreatment()
              }}
            >
              + Add New Treatment
            </Button>
          </Form.Group>
        )}
      </Form.Row>
    </>
  )
}
