import { useCallback, useEffect, useMemo, useState } from 'react'

import { Select as AntDSelect } from 'antd'

import { useIcd10CodeData } from '../../../hooks/queries/useIcd10CodeData'
import { Select } from '../../../stories/BaseComponents'

import styles from './IcdInput.module.scss'

interface IcdInputProps {
  value?: string
  onSelectIcdCode: (code: string, description: string) => void
  type: 'Add' | 'Edit'
}

export const IcdInput = ({ value, onSelectIcdCode, type }: IcdInputProps) => {
  const [selectedValue, setSelectedValue] = useState(value || null)
  const [searchValue, setSearchValue] = useState(value || '')
  const { icd10Codes, isLoading } = useIcd10CodeData({
    searchValue,
  })

  // If parent component updates the selected ICD code,
  // keep it in sync here
  useEffect(() => {
    setSelectedValue(value || null)
    setSearchValue(value || '')
  }, [value])

  const searchOptions = useMemo(() => {
    return icd10Codes.map((opt) => {
      return {
        value: opt.value.code,
        label: opt.name,
      }
    })
  }, [icd10Codes])

  const handleSelect = useCallback(
    (value: unknown) => {
      const foundCode = icd10Codes.find((opt) => opt.value.code === value)

      // Type guard to satisfy TypeScript, this
      // should never not be found given icd10Codes
      // are the source of truth
      /* istanbul ignore next */
      if (!foundCode) {
        return
      }
      const { code, description } = foundCode.value
      setSelectedValue(value as string)
      setSearchValue(value as string)
      onSelectIcdCode(code, description)
    },
    [icd10Codes]
  )

  const handleSearch = (e: string) => {
    setSearchValue(e)
  }

  return (
    <Select
      loading={isLoading}
      showSearch
      className={styles.container}
      value={selectedValue}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder="Search for diagnosis"
      testId="icd-input"
      disabled={type === 'Edit'}
    >
      {searchOptions.map((opt) => (
        <AntDSelect.Option key={opt.value} value={opt.value}>
          {opt.label}
        </AntDSelect.Option>
      ))}
    </Select>
  )
}
