import React from 'react'

import { Container, Form, Row } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'

import './../PatientProfile.scss'

export default function ObsessiveCompulsiveSaved(props) {
  const hasNoRelevantHistory =
    props.patientData.OCD.state === false &&
    props.patientData.OtherObsessiveCompulsive.state === false &&
    props.patientData.UnwantedThoughts.state === false &&
    !props.patientData.ObsessiveCompulsiveAdditionalNotes &&
    props.patientData.NoObsessiveCompulsive === true

  const noRecordedInfo =
    props.patientData.OCD.state === false &&
    props.patientData.OtherObsessiveCompulsive.state === false &&
    props.patientData.UnwantedThoughts.state === false &&
    !props.patientData.ObsessiveCompulsiveAdditionalNotes &&
    props.patientData.NoObsessiveCompulsive === false

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Obsessive-compulsive"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">
        Obsessive-compulsive:
      </Form.Row>
      <Form.Group className="indent">
        {props.patientData.OCD.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.OCD.state)}
            />
            <span id="OCDShow">OCD</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.OCD.Notes}
          </Container>
        )}
        {props.patientData.OtherObsessiveCompulsive.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.OtherObsessiveCompulsive.state
              )}
            />
            <span id="OtherObsessiveCompulsiveShow">Other</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.OtherObsessiveCompulsive.Notes}
          </Container>
        )}
        {props.patientData.UnwantedThoughts.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.UnwantedThoughts.state)}
            />
            <span id="UnwantedThoughtsShow">
              Unwanted thoughts or behaviors
            </span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.UnwantedThoughts.Notes}
          </Container>
        )}
      </Form.Group>
      <Form.Group className="border-bottom indent">
        {props.patientData.ObsessiveCompulsiveAdditionalNotes && (
          <Container bsPrefix="psych">
            <Form.Row className="patient-dark">Additional Notes:</Form.Row>
            <Row bsPrefix="psych-sub">
              {props.patientData.ObsessiveCompulsiveAdditionalNotes}
            </Row>
          </Container>
        )}
      </Form.Group>
    </Form.Group>
  )
}
