import React, { useEffect, useRef, useState } from 'react'

import CalendarOutlined from '@ant-design/icons/CalendarOutlined'
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled'
import { Col, InputNumber, Row, Tooltip } from 'antd'
import { format, getDaysInMonth, isValid, parse } from 'date-fns'
import { useHistory } from 'react-router-dom'

import InlineEditBase from '../InlineEditFields/InlineEditBase'

import './InlineDateInput.scss'

// Types
interface InputFieldProps {
  id: string
  placeholder: string
  value: string
  type: 'month' | 'day' | 'year'
  refInput: React.RefObject<HTMLInputElement>
  min?: string
  max?: string
  onChange: (value: string, type: 'month' | 'day' | 'year') => void
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

interface DateInputProps {
  value?: string
  required?: boolean
  testId?: string
  onChange: (formattedDate: string) => void
  onClose?: () => void
}

interface DateFields {
  month: string
  day: string
  year: string
}

//Constants
const DEFAULT_FORMAT = 'MM/dd/yyyy'

// Helper functions

/**
 * Converts a date string to a desired format using the provided date format
 * If the dateFormat is an array, it will try each format until a valid date is parsed
 * If the date cannot be parsed, it will return an empty string
 * The desired format is used to format the date if it is parsed successfully
 * @param date - The date string to convert
 * @param dateFormat - The format or array of formats to use to parse the date
 * @param desiredFormat - The format to use to format the date
 * @returns The date string in the desired format or an empty string if the date cannot be parsed
 */
const convertDateToDesiredFormat = (
  date: string,
  dateFormat: string | string[],
  desiredFormat: string
): string => {
  let parsedDate: Date | null = null

  if (Array.isArray(dateFormat)) {
    // Try each format in the array until a valid date is parsed
    for (const format of dateFormat) {
      parsedDate = parse(date, format, new Date())
      if (isValid(parsedDate)) {
        break
      }
    }
  } else {
    // Parse the date using the single format string
    parsedDate = parse(date, dateFormat, new Date())
  }

  // If a valid date is parsed, format it to the desired format, otherwise return an empty string
  return parsedDate && isValid(parsedDate)
    ? format(parsedDate, desiredFormat)
    : ''
}

const padToTwoDigits = (num: number) => (num < 10 ? `0${num}` : `${num}`)

const normalizeDate = (day: string, month: string, year: string): string => {
  if (!day || !month || !year) return ''
  return `${padToTwoDigits(Number(month))}/${padToTwoDigits(
    Number(day)
  )}/${year}`
}

const isValidDate = (month: string, day: string, year: string): boolean => {
  if (!month || !day || !year) return false

  const date = parse(`${month}/${day}/${year}`, DEFAULT_FORMAT, new Date())

  return (
    isValid(date) &&
    date.getFullYear() === Number(year) &&
    date.getMonth() === Number(month) - 1 &&
    date.getDate() === Number(day)
  )
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  placeholder,
  value,
  min,
  max,
  onChange,
  onKeyDown,
  type,
  refInput,
}) => {
  const handleInput = (e: string) => onChange(e, type)

  const formatPadZero = (value: string | undefined) => {
    if (!value) return ''
    return padToTwoDigits(parseInt(value))
  }

  const selectAllText = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.currentTarget.select()
  }

  const minMaxDefaults = {
    month: { min: '1', max: '12', length: 2 },
    day: { min: '1', max: '31', length: 2 },
    year: { min: '1900', max: new Date().getFullYear().toString(), length: 4 },
  }

  //Since we are adding a 0 on the format function we need to remove it when the user types a number
  const onLeandingZeroKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      type !== 'year' &&
      e.key.match(/[0-9]/) &&
      e.currentTarget.value.length === 2 &&
      e.currentTarget.value[0] === '0'
    ) {
      handleInput(e.currentTarget.value.slice(1) + e.key)
    }
  }

  return (
    <InputNumber
      id={id}
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
      name={id}
      parser={(value) => value?.replace(/^0+/, '') ?? ''}
      stringMode
      onKeyDown={(e) => {
        onLeandingZeroKeyDown(e)
        onKeyDown(e)
      }}
      placeholder={placeholder}
      bordered={false}
      maxLength={minMaxDefaults[type].length}
      value={value}
      onClick={selectAllText}
      ref={refInput}
      min={min ?? minMaxDefaults[type].min}
      max={max ?? minMaxDefaults[type].max}
      formatter={(value) => formatPadZero(value)}
      onInput={(e: any) => {
        handleInput(e)
      }}
      controls={false}
    />
  )
}

const DateInput: React.FC<DateInputProps> = ({
  value,
  required,
  testId,
  onChange,
  onClose,
}) => {
  const [date, setDate] = useState<DateFields>({ month: '', day: '', year: '' })
  const [inputError, setInputError] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const [focusedField, setFocusedField] =
    useState<React.RefObject<HTMLInputElement> | null>(null)
  const timeoutRef = useRef()
  const refs = {
    month: useRef<HTMLInputElement>(null),
    day: useRef<HTMLInputElement>(null),
    year: useRef<HTMLInputElement>(null),
  }
  const placeHolders: { [key: string]: string } = {
    month: 'MM',
    day: 'DD',
    year: 'YYYY',
  }

  const isEmptyDate = () => {
    return !date.month && !date.day && !date.year
  }

  const clearDate = () => {
    setDate((prev) => ({ ...prev, month: '', day: '', year: '' }))
    refs.month.current?.focus()
  }

  // Change focus to the next or previous field based on the direction
  const setFocusToField = (
    field: keyof DateFields,
    direction: 'next' | 'previous'
  ) => {
    const fieldOrder = ['month', 'day', 'year']
    const currentIndex = fieldOrder.indexOf(field)
    const nextIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1
    if (nextIndex >= 0 && nextIndex < fieldOrder.length) {
      const nextField = fieldOrder[nextIndex] as keyof DateFields
      setFocusedField(refs[nextField])
    }
  }

  // Effect to auto switch to the next field of the date
  useEffect(() => {
    // Get the focused field from the refs object
    const activeRefType = Object.keys(refs).find(
      (key) => refs[key as keyof DateFields].current === document.activeElement
    ) as keyof DateFields | undefined

    const value = date[activeRefType as keyof DateFields]

    //This delay is to allow the user to type the second digit before moving to the next field
    const delay = setTimeout(() => {
      if (activeRefType === 'month' && Number(value) >= 2) {
        setFocusToField('month', 'next')
      }

      if (activeRefType === 'day' && Number(value) > 3) {
        setFocusToField('day', 'next')
      }
    }, 0) // Add a delay of 500 milliseconds

    return () => clearTimeout(delay) // Clear the timeout on cleanup
  }, [date])

  useEffect(() => {
    if (focusedField) {
      const element = focusedField.current
      if (element) {
        element.focus()
        element.setSelectionRange(element.value.length, element.value.length)
        element.select()
      }
    }
  }, [focusedField])

  useEffect(() => {
    if (!value) {
      refs.month.current?.focus()
    } else {
      refs.year.current?.focus()
    }
  }, [])

  // Clear the blur timeout if the component unmounts
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    const parts = value?.split('/') || []
    setDate({
      month: parts[0] || '',
      day: parts[1] || '',
      year: parts[2] || '',
    })
  }, [value])

  const handleInputChange = (value: string, type: keyof DateFields) => {
    setDate((prev) => ({ ...prev, [type]: value }))
  }

  /**
   * Since the onBlur event is triggered when we change focus to another element inside the container,
   * we need to delay the check to allow the active element to change before checking the active element
   * If the active element is not inside the container, we check if the date is valid and set the error if it is not
   * @param event - The blur event
   */
  const handleBlur = (event: any) => {
    event.preventDefault()
    const currentTarget = event.currentTarget

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    setTimeout(() => {
      // Delay the check to allow blur event to finish before checking the active element
      if (!currentTarget.contains(document.activeElement)) {
        if (isEmptyDate() && value !== '') {
          if (!required) {
            onChange(normalizeDate(date.day, date.month, date.year))
          } else {
            setInputError(true)
          }
        } else if (isValidDate(date.month, date.day, date.year)) {
          onChange(normalizeDate(date.day, date.month, date.year))
        } else {
          setInputError(true) // Set error if the date is not valid
        }
      }
    }, 0)
  }

  const calculateMaxMonthDays = () => {
    if (!date.month) return 31
    return getDaysInMonth(new Date(Number(date.year), Number(date.month) - 1))
  }

  const moveOnKeyboardArrow = (
    e: React.KeyboardEvent<HTMLInputElement>,
    field: keyof DateFields
  ) => {
    if (e.key === 'ArrowRight') {
      e.preventDefault() // Prevent moving the cursor
      setFocusToField(field, 'next')
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault() // Prevent moving the cursor
      setFocusToField(field, 'previous')
    }
  }

  const handleBackspace = (field: keyof DateFields) => {
    setFocusToField(field, 'previous')
  }

  const handleSeparatorKeyDown = (field: keyof DateFields) => {
    setFocusToField(field, 'next')
  }

  const blurCurrentField = (field: keyof DateFields) => {
    if (field === 'year') {
      ;(document.activeElement as HTMLElement)?.blur()
      if (onClose) {
        onClose()
      }
    } else {
      setFocusToField(field, 'next')
    }
  }

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    field: keyof DateFields
  ) => {
    if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
      moveOnKeyboardArrow(e, field)
    } else if (e.key === 'Backspace' && e.currentTarget.value.length === 0) {
      handleBackspace(field)
    } else if (['/', '-', '.', ' '].includes(e.key)) {
      e.preventDefault() // Prevent the key from being registered in the input
      handleSeparatorKeyDown(field)
    } else if (e.key === 'Enter') {
      e.preventDefault()
      blurCurrentField(field)
    }
    //Disable letters
    else if (e.key.match(/^[a-zA-Z]$/)) {
      // Only match single alphabet characters
      e.preventDefault() // Prevent alphabetic characters
    }
  }

  return (
    <form
      className={`dateInput ant-picker ${inputError ? 'error' : ''}`}
      data-testid={`inline-date-input-form-${testId}`}
      style={{ width: '100%' }}
    >
      <Row gutter={0} onBlur={handleBlur} ref={containerRef}>
        {['month', 'day', 'year'].map((field, index) => (
          <React.Fragment key={field}>
            <Col>
              <InputField
                id={`${field}Input`}
                placeholder={placeHolders[field]}
                value={date[field as keyof DateFields]}
                onChange={handleInputChange}
                max={
                  field === 'day'
                    ? calculateMaxMonthDays().toString()
                    : undefined
                }
                onKeyDown={(e) => onKeyDown(e, field as keyof DateFields)}
                type={field as 'month' | 'day' | 'year'}
                refInput={refs[field as keyof DateFields]}
              />
            </Col>
            {index < 2 && <span>/</span>}
          </React.Fragment>
        ))}
      </Row>
      {inputError || !isEmptyDate() ? (
        <Tooltip title="Click to clear the date">
          <CloseCircleFilled
            data-testid={`date-input-clear-date-${testId}`}
            onClick={clearDate}
            className="clearIcon"
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      ) : (
        <CalendarOutlined data-testid={`date-input-calendar-${testId}`} />
      )}
    </form>
  )
}
const InlineDateInput: React.FC<{
  onSave: (value: string) => void
  value: string
  required?: boolean
  placeholder?: string
  format?: string | string[]
  padded?: boolean
  testId?: string
  autoFocus?: boolean
}> = ({
  onSave,
  value: initialValue,
  required = true,
  placeholder = 'Select date',
  format = DEFAULT_FORMAT,
  padded = true,
  testId,
  autoFocus = false,
  ...props
}) => {
  const history = useHistory()

  return (
    <InlineEditBase
      {...props}
      toggleActive={autoFocus}
      activeComponent={({ handleChange, toggleBlur }) => (
        <DateInput
          onChange={handleChange}
          value={convertDateToDesiredFormat(
            initialValue,
            format,
            DEFAULT_FORMAT
          )}
          required={required}
          testId={testId}
          onClose={toggleBlur}
        />
      )}
      padded={padded}
      onActive={() => {
        //If it comes from a search, we remove the search params to avoid the field to be focused again
        const searchParams = new URLSearchParams(location.search)
        if (searchParams.toString().indexOf('field') !== -1) {
          history.push({
            search: location.search.replace(
              searchParams
                .toString()
                .slice(searchParams.toString().indexOf('field') - 1),
              ''
            ),
          })
        }
      }}
      onSave={(value) => {
        const parsedDate = convertDateToDesiredFormat(
          value,
          DEFAULT_FORMAT,
          Array.isArray(format) ? format[0] : format
        )
        onSave(parsedDate)
      }}
      showActions={false}
      allowClear={true}
      autoSave={true}
      placeholder={placeholder}
      value={initialValue}
      testId={testId}
    />
  )
}

export default InlineDateInput
