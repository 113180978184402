/* A hook for managing tags and udpating tags */
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { createTag, getTags } from '../../api/tags'
import { Tag } from '../../stories/Patients/_types'

export enum QueryKeys {
  TAGS = 'TAGS',
  PATIENT_TAGS = 'PATIENT_TAGS',
}

type UseTagsQuery = UseQueryResult<Tag[]>
type CreateTagMutationResult = UseMutationResult<
  Tag,
  unknown,
  { label: string }
>

export const useTagsData = (): UseTagsQuery => {
  return useQuery<Tag[]>([QueryKeys.TAGS], {
    queryFn: getTags,
    retry: false,
  })
}

export const createTagMutation = (): CreateTagMutationResult => {
  const queryClient = useQueryClient()

  return useMutation(createTag, {
    onSuccess: (newTag) => {
      queryClient.setQueryData([QueryKeys.TAGS], (currentTags: any) =>
        currentTags ? [...(currentTags as Tag[]), newTag] : [newTag]
      )
    },
    onError: (_err, _newTag) => {
      queryClient.refetchQueries([QueryKeys.TAGS])
    },
  })
}
