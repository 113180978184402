interface CheckboxField {
  hasText?: boolean
  nested?: boolean
  options?: Record<string, string>
  description?: string
}

export enum Findings {
  UncontrolledHTN = 'History of uncontrolled HTN',
  CardiacArrhythmia = 'History of cardiac arrhythmia ',
  Cystitis = 'History of cystitis',
  DrugDependenceOrAbuse = 'History of drug dependence or abuse',
  Aneurysm = 'History of blood vessel aneurysm',
  IntraCerebralHemorrhage = 'History of intracerebral hemorrhage',
  AVMalformation = 'A/V malformation',
  DailyUseOfBenzodiazepines = 'Daily use of benzodiazepines',
  DailyUseOfStimulants = 'Daily use of stimulants',
  Pregnant = '(Female) Pregnant ',
  NotUsingBirthControl = '(Female) Not using birth control',
}

export const Section: Record<Findings, CheckboxField> = {
  [Findings.UncontrolledHTN]: { hasText: true },
  [Findings.CardiacArrhythmia]: { hasText: true },
  [Findings.Cystitis]: { hasText: true },
  [Findings.DrugDependenceOrAbuse]: { hasText: true },
  [Findings.Aneurysm]: { hasText: true },
  [Findings.IntraCerebralHemorrhage]: { hasText: true },
  [Findings.AVMalformation]: { hasText: true },
  [Findings.DailyUseOfBenzodiazepines]: { hasText: true },
  [Findings.DailyUseOfStimulants]: {
    nested: true,
    options: { Yes: 'Yes', No: 'No' },
    description: 'Client advised to hold stimulants prior to ketamine dosing',
  },
  [Findings.Pregnant]: { hasText: true },
  [Findings.NotUsingBirthControl]: { hasText: true },
}
