import { createContext, useContext } from 'react'

import useClinicalNoteFields from '../../../hooks/useClinicalNoteFields'

export const ClinicalNoteContext = createContext<
  ReturnType<typeof useClinicalNoteFields>
>({} as ReturnType<typeof useClinicalNoteFields>)

export function useClinicalNoteContext() {
  return useContext(ClinicalNoteContext)
}
