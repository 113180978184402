import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { SingleDatePicker } from 'react-dates'

import {
  getRCopiaSSOUrl,
  registerPatientForErx,
  submitErxDemographics,
} from '../../api/api-lib'
import { usaStatesData } from '../../containers/Provider/UsaStates'
import { onError } from '../../libs/errorLib'
import { useFormFields } from '../../libs/hooksLib'
import { notification } from '../../libs/notificationLib'
import { formatDate } from '../../libs/utils'
import { convertToAbbrState } from '../../shared/Helpers/usStateInformation'
import LoaderButton from './LoaderButton'

const EPrescribeButton = (props) => {
  const [showOnboardModal, setShowOnboardModal] = useState(false)
  const [validated, setValidated] = useState(false)
  const [focused, setFocused] = useState(false)
  const [defaultFormValues, setDefaultFormValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [rCopiaUrl, setRCopiaUrl] = useState('')
  const [iFrameDisplay, setIFrameDisplay] = useState('none')
  const [showCloseButton, setShowCloseButton] = useState(false)
  const [wasJustOnboarded, setWasJustOnboarded] = useState(false)
  const [isOpeningFirstRCopia, setIsOpeningFirstRCopia] = useState(false)
  const [showOnboardingMessage, setShowOnboardingMessage] = useState(false)

  // function to pre populate form with information that is available from db
  // for some fields there are several property options so check all potentials
  function fillInAvailableData() {
    const dob = formatDate({ value: props.patient.DateOfBirth })
    let momentDate = null
    const isValidMomentDate = dob && moment(dob).isValid()
    if (isValidMomentDate) momentDate = moment(dob)
    // using moment bc SingleDatePicker is dependent to moment still
    const formFields = {
      PatientFirstName: props.patient.firstName,
      PatientLastName: props.patient.lastName || '',
      // use patient's DOB if it exists, otherwise leave blank - have to specify or null or else SingleDatePicker defaults to using today's date
      DateOfBirth: momentDate,
      PatientGender: 'Select gender',
      PatientLine1: props.patient.AddressLine1 || '',
      PatientLine2: props.patient.AddressLine2 || '',
      PatientCity: props.patient.City || '',
      PatientState: props.patient.State || '',
      PatientZipCode: props.patient.ZipCode || '',
      PatientPhoneNumber: props.patient.PhoneNumber || '',
      PatientHomePhoneNumber: props.patient.homePhone || '',
    }
    // DrFirst expects gender as 'M, F or U' - try taking first character of PatientGender and capitalizing it,
    // otherwise leave blank for provider to select
    let firstCapital = ''
    if (props.patient.legalSex) {
      firstCapital = props.patient.legalSex[0].toUpperCase()
    } else if (props.patient.PrimaryInsuredGender) {
      firstCapital = props.patient.PrimaryInsuredGender[0].toUpperCase()
    }
    if (firstCapital === 'M' || firstCapital === 'F' || firstCapital === 'U') {
      formFields.PatientGender = firstCapital
    }

    // return formFields so it's used as initial state of useFormFields and also save in state
    // so that we have it to reset the form on close
    setDefaultFormValues(formFields)
    return formFields
  }

  const [fields, handleFieldChange] = useFormFields(() => fillInAvailableData())

  // update the auto fill of eRx onboard modal fields whenever the patient info changes
  useEffect(() => {
    const fields = fillInAvailableData()
    handleFieldChange(fields, false)
  }, [props.patient])

  function handleClose() {
    setShowOnboardModal(false)
    handleFieldChange(defaultFormValues, false)
    setIsLoading(false)
  }

  function checkValidFields(event) {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
  }

  function validateForm() {
    // all required fields need to be filled out
    // state should be two uppercase characters and zip code should be 5 numbers
    return (
      fields.PatientFirstName.length > 0 &&
      fields.PatientLastName.length > 0 &&
      fields.DateOfBirth !== null &&
      fields.PatientGender !== 'Select gender' &&
      fields.PatientLine1.length > 0 &&
      fields.PatientCity.length > 0 &&
      (usaStatesData.find(
        (state) =>
          state.name.toLowerCase() === fields.PatientState.toLowerCase()
      ) !== undefined ||
        usaStatesData.find(
          (state) =>
            state.abbreviation.toLowerCase() ===
            fields.PatientState.toLowerCase()
        ) !== undefined) &&
      fields.PatientZipCode.length === 5 &&
      /^[0-9]{10}$/g.test(fields.PatientPhoneNumber) &&
      (fields.PatientHomePhoneNumber
        ? /^[0-9]{10}$/g.test(fields.PatientHomePhoneNumber)
        : true)
    )
  }

  // update patient's demographics for Osmind app
  async function updateDemographics(dateOfBirth) {
    let legalSex

    const data = {
      UpdateFromErxOnboard: true,
      PatientId: props.patient.PatientId,
      firstName: fields.PatientFirstName,
      lastName: fields.PatientLastName,
      legalSex: legalSex,
      addressLine1: fields.PatientLine1,
      addressLine2: fields.PatientLine2,
      city: fields.PatientCity,
      state: fields.PatientState,
      zipCode: fields.PatientZipCode,
      phoneNumber: fields.PatientPhoneNumber,
      homePhoneNumber: fields.PatientHomePhoneNumber,
      dateOfBirth,
    }
    try {
      // TODO: I think this is actually not working anymore...
      await submitErxDemographics(data)
      notification(
        "You have successfully updated the patient's Osmind info.",
        'success'
      )
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
  }

  // hits the eRx api to run send_patient method and add them to e-prescribing
  async function registerNewErxPatient(dateOfBirth) {
    const data = fields
    data.PatientState = convertToAbbrState(data.PatientState)
    data.patientPublicId = props.patient.PublicId
    data.DateOfBirth = dateOfBirth
    const patientRCopiaId = await registerPatientForErx(data)
    notification(
      `You have successfully onboarded ${fields.PatientFirstName} ${fields.PatientLastName} for e-prescribing. Please wait while we open their portal.`,
      'success'
    )
    const ssoUrl = await getRCopiaSSOUrl(patientRCopiaId)
    setRCopiaUrl(ssoUrl)
    setIFrameDisplay(true)
    setShowCloseButton(true)
  }

  async function openPatientPortal() {
    // if we've already gotten an rCopia URL and signed in, continuing using that one
    if (!rCopiaUrl) {
      setIsOpeningFirstRCopia(true)
      try {
        const patientRCopiaId = props.patient.PatientRCopiaId
        const ssoUrl = await getRCopiaSSOUrl(patientRCopiaId)
        setRCopiaUrl(ssoUrl)
        setIFrameDisplay(true)
        setShowCloseButton(true)
      } catch (e) {
        onError(
          e,
          500,
          'There was an internal error processing your request. Please inform your administrator.'
        )
      }
    } else {
      setIFrameDisplay(true)
      setShowCloseButton(true)
    }
    setIsOpeningFirstRCopia(false)
  }

  function closeEPrescribing() {
    setShowCloseButton(false)
    setIFrameDisplay('none')
  }

  // updates patient's demographics in the osmind app, registers them with eRx and then opens their RCopia portal in embedded iFrame
  async function handleOnboardPatient(event) {
    event.preventDefault()
    checkValidFields(event)
    setShowOnboardingMessage(true)
    setIsLoading(true)
    setShowOnboardModal(false)
    try {
      const submitDate = fields.DateOfBirth?.isValid()
        ? fields.DateOfBirth.toDate()
        : null
      await updateDemographics(submitDate)
      await registerNewErxPatient(submitDate)
      setWasJustOnboarded(true)
      // handle api change so that we recognize that this patient now has an RCopia id so the provider doesn't need to refresh
      // to be directed to RCopia when wanting to edit allergies or medications
      props.handleApiChange()
    } catch (e) {
      setShowOnboardingMessage(false)
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
    handleClose()
  }

  return (
    <>
      {/* if patient has been registered with DrFirst/RCopia they will have a RCopiaId property, otherwise they need to be onboarded.
      or if they were just onboarded, switch to showing the open/close button instead */}
      {props.patient.PatientRCopiaId || wasJustOnboarded ? (
        <>
          {showCloseButton ? (
            <Button
              bsPrefix="button-block-thin button-label"
              style={{
                width: '40%',
                textAlign: 'center',
                marginTop: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              onClick={closeEPrescribing}
            >
              Close e-prescribing
            </Button>
          ) : (
            <Button
              bsPrefix="button-block-thin button-label"
              style={{
                width: '40%',
                textAlign: 'center',
                marginTop: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              onClick={openPatientPortal}
            >
              {/* if we're opening RCopia for the first time on this page visit, set a loading indicator since it can take a little time to get
              an SSO URL from the backend */}
              {isOpeningFirstRCopia ? (
                <div>
                  <Spinner animation="border" variant="danger" />
                  &nbsp;&nbsp;Opening e-prescribing
                </div>
              ) : (
                <div>Open e-prescribing</div>
              )}
            </Button>
          )}
        </>
      ) : (
        <Button
          bsPrefix="button-block-thin button-label"
          style={{
            width: '40%',
            textAlign: 'center',
            marginTop: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          onClick={() => setShowOnboardModal(true)}
        >
          Onboard patient for eRx
        </Button>
      )}
      <iframe
        src={rCopiaUrl}
        style={{
          height: 975,
          width: '100%',
          marginTop: 25,
          display: iFrameDisplay,
        }}
      />
      <Modal size="lg" show={showOnboardModal} onHide={handleClose} centered>
        <Form
          onChange={(e) => checkValidFields(e)}
          validated={validated}
          onSubmit={handleOnboardPatient}
          autoComplete="off"
        >
          <Modal.Header closeButton>
            <Modal.Title dialogclassname="modal-header">
              <Form.Row>
                Set up e-prescribing for&nbsp;
                <div className="modal-header-blue">
                  {props.patient.PatientName}
                </div>
              </Form.Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Text className="text-muted">
              Please review and complete the required fields to onboard this
              patient for e-prescribing. Please note, submission of this form
              will update this patient&apos;s information in their Osmind
              profile as well.
            </Form.Text>
            <br />
            <Form.Text className="text-muted">
              <span className="danger">*</span> Indicates required field
            </Form.Text>
            <br />
            <Form.Group controlId="PatientFirstName">
              <Form.Label className="form-label">
                Patient First Name: <span className="danger">*</span>
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                onChange={handleFieldChange}
                placeholder="Jane"
                required
                type="text"
                value={fields.PatientFirstName}
                autoComplete="nope"
              />
            </Form.Group>

            <Form.Group controlId="PatientLastName">
              <Form.Label className="form-label">
                Patient Last Name: <span className="danger">*</span>
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                onChange={handleFieldChange}
                placeholder="Doe"
                required
                type="text"
                value={fields.PatientLastName}
                autoComplete="nope"
              />
            </Form.Group>
            <Form.Group controlId="PatientPhoneNumber">
              <Form.Label className="form-label">
                Cell Phone Number: <span className="danger">*</span>
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                onChange={handleFieldChange}
                required
                placeholder="1112223333"
                type="text"
                value={fields.PatientPhoneNumber}
                autoComplete="nope"
              />
              <Form.Text className="text-muted">
                Use 10-digit format, e.g. '1112223333', avoid using (), -, or
                empty spaces
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="PatientHomePhoneNumber">
              <Form.Label className="form-label">Home Phone Number:</Form.Label>
              <Form.Control
                bsPrefix="form-input"
                onChange={handleFieldChange}
                placeholder="1112223333"
                type="text"
                value={fields.PatientHomePhoneNumber}
                autoComplete="nope"
              />
              <Form.Text className="text-muted">
                Use 10-digit format, e.g. '1112223333', avoid using (), -, or
                empty spaces
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="DateOfBirth">
              <Form.Label className="form-label">
                Patient Date of Birth: <span className="danger">*</span>
              </Form.Label>
              <SingleDatePicker
                date={fields.DateOfBirth}
                onDateChange={(date) => {
                  if (!date || !date.isValid()) return
                  handleFieldChange(
                    {
                      ...fields,
                      DateOfBirth: date,
                    },
                    false
                  )
                }}
                id="NoteDate"
                focused={focused}
                placeholder="MM/DD/YYYY"
                onFocusChange={(focused) => setFocused(!!focused)}
                numberOfMonths={1}
                displayFormat="MM/DD/YYYY"
                onClose={() => setFocused(false)}
                isOutsideRange={() => false}
              />
            </Form.Group>
            <Form.Group controlId="PatientGender">
              <Form.Label className="form-label">
                Patient Legal Sex: <span className="danger">*</span>
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                onChange={handleFieldChange}
                required
                as="select"
                value={fields.PatientGender}
                autoComplete="off"
              >
                <option>Select gender</option>
                <option>M</option>
                <option>F</option>
                <option>Unknown</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <h4>Patient Address</h4>
            </Form.Group>
            <Form.Group controlId="PatientLine1">
              <Form.Label className="form-label">
                Address Line 1: <span className="danger">*</span>
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                onChange={handleFieldChange}
                placeholder="143 Rainbow Road"
                required
                type="text"
                value={fields.PatientLine1}
                autoComplete="nope"
              />
            </Form.Group>
            <Form.Group controlId="PatientLine2">
              <Form.Label className="form-label">Address Line 2:</Form.Label>
              <Form.Control
                bsPrefix="form-input"
                onChange={handleFieldChange}
                type="text"
                value={fields.PatientLine2}
                autoComplete="nope"
              />
            </Form.Group>
            <Form.Group>
              <Form.Row>
                <Form.Group
                  controlId="PatientCity"
                  style={{ marginRight: '10px' }}
                >
                  <Form.Label className="form-label">
                    City: <span className="danger">*</span>
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    onChange={handleFieldChange}
                    required
                    placeholder="San Francisco"
                    type="text"
                    value={fields.PatientCity}
                    autoComplete="nope"
                  />
                </Form.Group>
                <Form.Group
                  controlId="PatientState"
                  style={{ marginRight: '10px' }}
                >
                  <Form.Label className="form-label">
                    State: <span className="danger">*</span>
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    onChange={handleFieldChange}
                    required
                    placeholder="CA"
                    type="text"
                    value={fields.PatientState}
                    autoComplete="nope"
                  />
                  <Form.Text className="text-muted">
                    Use abbreviated format, e.g. 'CA'
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="PatientZipCode">
                  <Form.Label className="form-label">
                    Zip Code: <span className="danger">*</span>
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    onChange={handleFieldChange}
                    required
                    placeholder="94122"
                    type="number"
                    value={fields.PatientZipCode}
                    autoComplete="nope"
                  />
                  <Form.Text className="text-muted">
                    Use 5-digit format, e.g. '94122'
                  </Form.Text>
                </Form.Group>
              </Form.Row>
            </Form.Group>
            <Form.Group controlId="SubmitButton">
              <LoaderButton
                isLoading={isLoading}
                isDisabled={!validateForm()}
                className="button-block center"
                type="submit"
                textInside="Onboarding patient"
              >
                <i className="fa fa-user-plus" aria-hidden="true" />
                &nbsp;Onboard Patient
              </LoaderButton>
            </Form.Group>

            <Button bsPrefix="button-block center" onClick={handleClose}>
              <i className="fa fa-times" />
              &nbsp; Close
            </Button>
          </Modal.Body>
        </Form>
      </Modal>
      <Modal size="lg" show={showOnboardingMessage} centered>
        <Modal.Header>
          <Modal.Title dialogclassname="modal-header">
            <Form.Row>
              Creating an eRx module for&nbsp;
              <div className="modal-header-blue">
                {props.patient.PatientName}
              </div>
            </Form.Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontSize: 18, marginLeft: 10 }}>
            {props.patient.PatientName}'s previously recorded allergies,
            diagnoses and active medications are being uploaded into their eRx
            module. Please be aware that diagnoses created with invalid ICD10
            codes or missing descriptions will not be able to be uploaded. If
            there are any allergies or medications shown with exclamation mark
            icons next to them, they must be corrected by finding a match within
            the eRx system. When ready, press continue to review{' '}
            {props.patient.PatientName}'s information within their eRx module
            and confirm it is correct.
          </div>
          <LoaderButton
            isLoading={isLoading}
            className="button-block center"
            type="submit"
            textInside="Onboarding patient"
            style={{ marginTop: 20 }}
            onClick={() => setShowOnboardingMessage(false)}
          >
            Continue
          </LoaderButton>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EPrescribeButton
