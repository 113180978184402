import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const SubstanceUseDisorderCodes: any = [
  {
    '724724008': {
      title: 'Substance Use Disorder',
      description:
        'Psychoactive substance dependence with current use (disorder)',
      snomedcode: 724724008,
      icdcode: 'F1920',
      icddescription: 'Other psychoactive substance dependence, uncomplicated',
    },
  },
  {
    '7200002': {
      title: 'sud.Alcohol Use Disorder (Alcoholism)',
      description: 'Alcoholism (disorder)',
      snomedcode: 7200002,
      icdcode: 'F1020',
      icddescription: 'Alcohol dependence, uncomplicated',
    },
  },
  {
    '723933008': {
      title: 'sud.Cannabis Use Disorder',
      description: 'Cannabis dependence with current use (disorder)',
      snomedcode: 723933008,
      icdcode: 'F1220',
      icddescription: 'Cannabis dependence, uncomplicated',
    },
  },
  {
    '724653003': {
      title: 'sud.Opioid Use Disorder',
      description: 'Opioid dependence with current use (disorder)',
      snomedcode: 724653003,
      icdcode: 'F1120',
      icddescription: 'Opioid dependence, uncomplicated',
    },
  },
  {
    '312098001': {
      title: 'sud.Sedative, Hypnotic and Anxiolytic Use Disorder',
      description:
        'Sedative, hypnotic AND/OR anxiolytic-related disorder (disorder)',
      snomedcode: 312098001,
      icdcode: 'F1399',
      icddescription:
        'Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder',
    },
  },
  {
    '442406005': {
      title: 'sud.Stimulant Use Disorder',
      description: 'Stimulant dependence (disorder)',
      snomedcode: 442406005,
      icdcode: 'F1520',
      icddescription: 'Other stimulant dependence, uncomplicated',
    },
  },
]

export const SubstanceUseDisorderDropDownValues =
  constructPatientDemographicObject(
    SubstanceUseDisorderCodes,
    CodeSystemOIDs.OSMINDCustomCodeSystem,
    Categories.PYSCH_CONDITIONS
  )
