import { RefObject } from 'react'

import { Day } from 'date-fns'
import { invert } from 'lodash'
import { Event as CalendarEvent } from 'react-big-calendar'

import { Roles } from '../src/api/permissions'
import { HellosignTemplate } from './components/Accordions/SharePatientProgress/attachmentLib'
import { FamilyHistoryResponse } from './containers/Patient/FamilyHistory/types'
import { MedicalSurgicalResponse } from './containers/Patient/MedicalSurgicalHistory/types'
import { PAYMENT_METHOD_TYPES } from './libs/constants'
import { Diagnosis as InvoiceDiagnosis } from './stories/Invoice/Diagnoses'
import { BillingIdentifiers, Provider } from './stories/types'

export interface File {
  dateTimeUploaded: Date
  title: string
  documentReference: string
  name: string
  noteDate?: Date
  status?: string
  documentType: string
}

/**
 * Top-level app cache object linked to useProviderSidePatientData query
 */
export interface ProviderSidePatientData {
  // TODO: Need to build these out
  MedicalSurgicalNotes: MedicalSurgicalResponse
  OtherNotes: { [key: string]: unknown }
  PatientReportedMedications: { [key: string]: unknown }
  PhysicalNotes: PhysicalNotesResponse
  PsychiatricHistory: { [key: string]: unknown }
  SignatureRequests: Array<SignatureRequest>
  SocialHistoryNotes: { [key: string]: unknown }
  FamilyHistory: FamilyHistoryResponse
  Immunizations: ImmunizationsResponse
  DevelopmentalHistory: DevelopmentalHistoryResponse
  clinicalNotes: ClinicalNote[]
  insurance: File[]
}

export interface SurveySetting {
  CreatedAt: DateISO8601
  HasEnabled: boolean
  HasNotificationEnabled: boolean
  Interval: number
  IsActive: boolean
  IsProviderRequested?: boolean
  LastRequestedAt?: DateISO8601
  LastSubmittedAt?: DateISO8601
  NextExpirationDate?: DateISO8601
  NotificationsLeftForCycle?: number
  PatientId: string
  ProviderId: string
  QuestionnaireId: number
  ScoreTypeEnum: string
  Threshold: number
  UpdatedAt: DateISO8601
}

/**
 * This type is not representative with what the BE returns
 */
export interface SurveySubmissionAnswer {
  CreatedAt: DateISO8601
  Id: number
  QuestionnaireOptionId: number
  QuestionnaireStep: {
    CreatedAt: DateISO8601
    Description: string
    Id: number
    Properties: {
      max: {
        score: number
        title: string
      }
      min: {
        score: number
        title: string
      }
    } | null
    QuestionnaireId: number
    Title: string
    Type: string
    UpdatedAt: DateISO8601
  }
  QuestionnaireStepId: number
  QuestionnaireStepOption: {
    CreatedAt: DateISO8601
    Id: number
    QuestionnaireStepId: number
    Score: number
    Title: string
    UpdatedAt: DateISO8601
  }
  QuestionnaireSubmissionId: number
  Score: number
  UpdatedAt: DateISO8601
}

export interface SurveySubmission {
  Answers: Array<SurveySubmissionAnswer>
  CreatedByProvider: string
  Date: DateISO8601
  Deleted: boolean
  Id: number
  Questionnaire: {
    CreatedAt: DateISO8601
    DefaultSendInterval: number
    Description: string
    Id: number
    Instruction: string
    IsLatest: boolean
    IsPatientReported: boolean
    IsRecurring: boolean
    Name: string
    ScoreGuide: Array<string>
    ScoreTypeEnum: string
    UpdatedAt: DateISO8601
    Version: number
  }
  Score: number
}

export interface SurveyData {
  CreatedAt: DateISO8601
  DefaultSendInterval: number
  Description: string
  Id: number
  Instruction: string
  IsLatest: boolean
  IsPatientReported: boolean
  IsRecurring: boolean
  Name: string
  ScoreGuide: Array<string>
  ScoreTypeEnum: string
  Steps: Array<{
    CreatedAt: DateISO8601
    Description: string
    Id: number
    Options: Array<{
      CreatedAt: string
      Id: number
      QuestionnaireStepId: number
      Score: number
      Title: string
      UpdatedAt: DateISO8601
    }>
    Properties: {
      max: {
        score: number
        title: string
      }
      min: {
        score: number
        title: string
      }
    }
    QuestionnaireId: number
    Title: string
    Type: string
    UpdatedAt: DateISO8601
  }>
  ThresholdGuide: {
    ComparisonOperator: string
    Id: number
    QuestionnaireId: number
    RangeMax: number
    RangeMin: number
    RecommendedThreshold: number
  }
  UpdatedAt: DateISO8601
  Version: number
}

export interface PatientSurveySubmissions {
  [key: string]: Array<SurveySubmission>
}

export interface PatientSettingsData {
  surveySettings: Array<SurveySetting>
  surveySubmissions: PatientSurveySubmissions
  questionnaires: Array<Questionnaire>
}

/**
 * Top-level app cache object linked to useProviderDetails query
 */
export interface ProviderHomePageData {
  // TODO: Need to build these out
  appSettings: { [key: string]: unknown }
  clinicData: ClinicDataType
  emailData: Array<{
    EmailTemplate: string
    [key: string]: unknown
  }>
  hellosign: Array<{ [key: string]: unknown }>
  hellosignTemplates: HellosignTemplate[]
  lastErxNotificationCall: boolean
  providerEmail: string
  providerId: string
  loggedInProviderId: string
  providerName: string
  surveyData: Array<Questionnaire>
  surveySettings: Array<{ [key: string]: unknown }>
  healthGorillaUserName?: string
}

export interface ClinicDataType {
  ProviderPhone: string
  ProviderEmail: string
  PracticeName: string
  ProviderPracticeAddress: string
  ProviderPracticeAddressExtn: string
  ProviderPracticeCity: string
  ProviderPracticeState: string
  ProviderPracticeZipcode: string
}

/**
 * The essential cognito user info that the app uses
 */
export interface ProviderAccountInfo {
  id: string
  username: string
  attributes: {
    email: string
    email_verified: boolean
    sub: string
    main_provider_id?: string
  }
}

/** Condensed version (only necessary data) of the raw object Cognito keeps in storage for authenticated sessions. */
export interface CognitoUserInfoFromStorage {
  attributes: {
    'custom:main_provider_id'?: string
    email: string // "testing@osmind.org"
    email_verified: boolean
    sub: string // "0520ceb5-fd72-4fc0-9068-a526b45f62ed"
  }
  username: string
}

export interface Patient {
  id: string
  name: string
  publicId: string
}

export interface PascalPatient {
  PatientFirstName: string
  PatientMiddleName?: string
  PatientLastName: string
  DateOfBirth?: Date | null
  PhoneNumber: string
  AddressLine1: string
  AddressLine2?: string
  City: string
  State: string
  ZipCode: string
  firstName?: string
  lastName?: string
}

export type YYYYMMDDDateString = string // YYYY-MM-DD, consider Template Literal Types
export type DateISO8601 = string // 'YYYY-MM-DDTHH:MM:SS.SSSZ'

export enum EventStatus {
  CREATED = 'CREATED',
  CONFIRMED = 'CONFIRMED',
}

export enum ScheduledEventType {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export type SendBirdMessages = Map<
  string,
  { count: number; markedUnread: boolean }
>

export interface PhysicalNotesResponse {
  height: number | ''
  heightUnit: string
  weight: number | ''
  weightUnit: string
  hip: number | ''
  hipUnit: string
  additionalNotes: string
  createdAt?: DateISO8601
}

export interface DevelopmentalHistoryResponse {
  developmentalHistoryNotes: string
  createdAt?: DateISO8601
}

export interface ImmunizationsResponse {
  immunizations: Array<{
    name: string
    date: DateISO8601
    notes: string
  }>
  additionalNotes: string
  createdAt?: string
}

export interface ImmunizationRequest extends ImmunizationsResponse {
  patientId: string
}

/**
 * Incomplete ClinicalNote schema
 */
export interface ClinicalNote {
  CreatedOn: string
  CreatedOnDate: string
  ComprehensiveMedicalROS: Record<string, unknown>
  ForcedEndEditIsComplete: boolean
  invoice?: Invoice
  IsBeingEditedBy: string
  IsTreatmentConcurrentPsychotherapy?: boolean
  KetamineSpecificROS: Record<string, unknown>
  MSE: Record<string, unknown>
  NoteId: string
  NoteType: NoteTypes
  PatientId: string
  PhysicalExam: Record<string, unknown>
  ReceivedTreatment?: boolean
  Signatures: Array<unknown>
  Title: string
  Rooms?: string
  updatedAt?: string
  lastBackupDate?: string
  RenderingProvider?: string
  EditedOnDateBy: NoteV1AuditLog
  isValidSpravato: boolean | null
  // Need to build this out to align with the ClincalNote schema

  // notes v2
  version?: number
}

export type NoteV1AuditLog = Array<{
  Date: string
  EditBy: string
}>

interface QuestionnaireStep {
  Id: number
  Type: string
  Title: string
  Description: string
  Options: {
    Title: string
    Score: number
    [key: string]: unknown
  }[]
  Properties?: unknown
  Answers?: Array<{ [key: string]: unknown }>
  QuestionnaireId: number
  surveySubmissions?: { [surveyType: string]: Array<unknown> }
}

/**
 * This type is not representative of API response
 */
export interface Questionnaire {
  Id: number
  Name: string
  IsLatest: boolean
  IsPatientReported: boolean
  IsRecurring: boolean
  ScoreTypeEnum: string
  Steps: QuestionnaireStep[]
  Description: string
  Instruction: string
  ScoreGuide: string
  Version: number
  DefaultSendInterval: number
  CreatedAt: Date
  UpdatedAt: Date
}

export interface ScheduledEventCommonProperties {
  IsAllDayEvent: boolean
  OsmindEventId: string
  googleId: string
  EventName: string
  EventType: ScheduledEventType
  Location: string
  Room?: string
  OsmindPatientId: string
  OsmindProviderId?: string
  Instructions: string
  AppointmentType: string
  AppointmentTypeId: string | ''
  Facility: string
  Providers: string
  EventProviders: string[]
  InternalNotes: string
  ColorHex: string
  Status: string
  Description?: string
  IsGoogleEvent?: boolean
  GoogleCalendar?: string
  GoogleCalendarId?: string
  GoogleCalendarAccountName?: string
  masterEventId?: string
  recurrence?: string
  eventId?: string
  eventDetailsId?: string
}

export type ScheduledEventTypeAllDay = Omit<
  ScheduledEventCommonProperties,
  'IsAllDayEvent'
> & {
  IsAllDayEvent: true
  StartDate: YYYYMMDDDateString
  EndDate: YYYYMMDDDateString
}
export type ScheduledEventTypeDateTime = Omit<
  ScheduledEventCommonProperties,
  'IsAllDayEvent'
> & {
  IsAllDayEvent: false
  StartTime: DateISO8601
  EndTime: DateISO8601
}

export type ScheduledEvent =
  | ScheduledEventTypeAllDay
  | ScheduledEventTypeDateTime

export interface Recurrence {
  interval: number
  frequency: 'days' | 'weeks' | 'months' | 'years'
  date?: number
  on?: {
    day: Day
    ordinal?: number
  }
  daysOfWeek?: Day[]
  end: {
    never?: boolean
    on?: Date
    after?: number
  }
}

export interface Event extends CalendarEvent {
  showTimespan?: boolean
  resource?: ScheduledEvent
  resourceId?: string
  eventId?: string
}

export interface BillingCode {
  id?: number
  code: string
  version: string
  ownerId: string
  description: string
  shortDescription?: string
  amount?: string // actually a float
}

export interface BillingTemplateCode {
  uuid: string
  templateId: string
  modifiers: string[]
  unit: number
  unitChargeAmountCents: number
  discountAmountCents: number
  deleted: boolean
  billingCode: BillingCode
  billingCodeId: number
}

export interface BillingTemplate {
  name: string
  uuid: string
  providerId: string
  billingTemplateCodes?: BillingTemplateCode[]
  id: number
}

export interface BillingTemplateCodeTableItem {
  key: string
  codeId: string
  modifiers: string[]
  modifiersDisplay: string
  unit: number
  unitChargeAmountCents: number
  unitChargeDisplay: string
  discountAmountCents: number
  discountDisplay: string
  codeAndDescription: string
  billingCodeValue: string
  billingCodeCode: string
  total: string
  billingCodeId: number
}

export interface BillingTemplateTableItem {
  key: string
  templateId: string
  templateName: string
}

interface BillingAddress {
  street: string
  city: string
  zipcode: string
  state: string
}

export interface BillingProfile {
  profileId: string
  profileName: string
  clinicName: string
  providerName: string
  billingNPI: string
  providerEin: string
  address: BillingAddress
  phoneNumber: string
}

/** This should be the same as the TreatmentListEnum (src/stories/Reports/types/index.ts) */
export enum NoteTypes {
  EVALUATION_NOTE = 'Evaluation Note',
  IM_KETAMINE = 'IM Ketamine',
  IV_KETAMINE = 'IV Ketamine',
  KAP = 'KAP',
  MEDICAL_SOAP = 'Medical SOAP',
  MEMO = 'Memo',
  PSYCHOTHERAPY_NOTE = 'Psychotherapy Note',
  CLINICAL_NOTE = 'Clinical Note',
  SPRAVATO = 'Spravato',
  THERAPY_SOAP = 'Therapy SOAP',
  NOTE_V2 = 'Note v2',
}

type UserFacingNoteTypesV1 = {
  [key in Exclude<NoteTypes, NoteTypes.NOTE_V2>]: string
}
/** The note types as they are represented in sentence casing on the UI */
export const UserFacingNoteTypesV1: UserFacingNoteTypesV1 = {
  [NoteTypes.EVALUATION_NOTE]: 'Evaluation note',
  [NoteTypes.IM_KETAMINE]: NoteTypes.IM_KETAMINE,
  [NoteTypes.IV_KETAMINE]: NoteTypes.IV_KETAMINE,
  [NoteTypes.KAP]: NoteTypes.KAP,
  [NoteTypes.MEDICAL_SOAP]: NoteTypes.MEDICAL_SOAP,
  [NoteTypes.MEMO]: NoteTypes.MEMO,
  [NoteTypes.PSYCHOTHERAPY_NOTE]: 'Psychotherapy note',
  [NoteTypes.CLINICAL_NOTE]: 'Simple clinical note',
  [NoteTypes.SPRAVATO]: NoteTypes.SPRAVATO,
  [NoteTypes.THERAPY_SOAP]: NoteTypes.THERAPY_SOAP,
}

/** The excluded Note V2 note type  */
export const UserFacingNoteTypesV2: { [NoteTypes.NOTE_V2]: string } = {
  [NoteTypes.NOTE_V2]: 'Blank note',
}

export const UserFacingNoteTypes: {
  [key in NoteTypes]: string
} = {
  ...UserFacingNoteTypesV1,
  ...UserFacingNoteTypesV2,
}

export const ReverseUserFacingNoteTypes = invert(UserFacingNoteTypes) as {
  [val: string]: NoteTypes
}

export enum LegacyNoteTypes {
  IV_KETAMINE_INFUSION = 'IV Ketamine Infusion',
}

export enum DemographicsRequestOrigin {
  SIDE_PANEL = 'side_panel',
  OLD_PROFILE = 'old_profile',
}

export interface BillingCodes {
  [NoteTypes.CLINICAL_NOTE]: BillingCode[]
  [NoteTypes.IV_KETAMINE]: BillingCode[]
  [NoteTypes.IM_KETAMINE]: BillingCode[]
  [NoteTypes.SPRAVATO]: BillingCode[]
  [NoteTypes.KAP]: BillingCode[]
  [NoteTypes.MEMO]: BillingCode[]
  [NoteTypes.MEDICAL_SOAP]: BillingCode[]
  [NoteTypes.THERAPY_SOAP]: BillingCode[]
  [NoteTypes.PSYCHOTHERAPY_NOTE]: BillingCode[]
}

export type RoleTitles = {
  label: string
  value: number
}

export interface Teammate {
  name?: string
  email: string
  phone?: string
  erxUserId?: string
  billingNPI?: string
  amdProfileId?: string
  lastActive?: string
  cognitoId: string
  isDeactivated: boolean
  isPrimaryUser: boolean
  credential: string
  createdAt?: string
  roleTitles?: RoleTitles[]
  roles?: Roles
  signatureFont?: string
  firstName?: string
  lastName?: string
  useEINorSSN?: 'EIN' | 'SSN'
  ein?: string
  ssn?: string
  ssnFirstFive?: string
  ssnLastFour?: string
}

export enum BillingCodesTypes {
  CPT_V2020 = 'CPT_V2020',
  CPT_V2021 = 'CPT_V2021',
  CPT_V2022 = 'CPT_V2022',
  CPT_V2023 = 'CPT_V2023',
  CUSTOM = 'CUSTOM',
  HCPCS_V2020 = 'HCPCS_V2020',
  CPT_V2024 = 'CPT_V2024',
}

export type ExternalService = 'STRIPE'

export type BillableEntityLineItem = {
  uuid?: string
  invoiceUuid?: string
  invoice?: Invoice
  noteId?: string
  billingCodeId?: number
  billingCodeCode?: string
  billingCodeVersion?: BillingCodesTypes
  billingCodeOwner?: string
  billingCode: BillingCode
  units: number
  unitChargeAmountCents: number
  totalAdjustmentAmountCents: number
  dxPointers?: number[]
  modifiers?: string[]
  isTaxable?: boolean
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
}

export type Practice = {
  name: string
  phoneNumber?: string
  primaryProviderId?: string
  providers?: Provider[]
  timezone?: string
  isManualInNetworkClaimsEnabled: boolean
} & BillingIdentifiers

export type LineItem = BillableEntityLineItem & {
  id?: number
  billingTemplateId?: number
  displayName?: string
  billingTemplateCode?: {
    billingTemplate?: {
      name: string
    }
  }
  billingTemplateCodeId?: number
}

export enum TypeLocation {
  PHYSICAL = 'Physical',
  OTHER = 'Other',
}

export type RoomLocation = {
  CreatedAt: string
  CreatedBy: string
  Deleted: string
  LocationId: string
  ProviderId: string
  RoomId: string
  RoomName: string
  UpdatedAt: string
  UpdatedBy: string
}

export type Location = {
  uuid?: string
  Uuid?: string
  id: number
  Id?: number
  LocationId?: string
  LocationName?: string
  name: string
  Name?: string
  type?: TypeLocation
  Type?: TypeLocation
  addressLine1: string
  AddressLine1?: string
  addressLine2?: string
  AddressLine2?: string
  city: string
  City?: string
  state: string
  State?: string
  zipcode: string
  Zipcode?: string
  phoneNumber: string
  PhoneNumber?: string
  pos?: string
  Pos?: string
  Rooms?: RoomLocation[]
  Deleted?: boolean
  billingNPI?: string
  BillingNPI?: string
  Ein?: string
} & BillingIdentifiers

export type Room = {
  createdAt: string
  createdBy: string
  deleted: boolean
  locationId: string
  providerId: string
  roomId: string
  roomName: string
  updatedAt: string
  updatedBy: string
}

export type PlaceOfServiceVersion = 'SEPT_2021' | 'CUSTOM'

export type PlaceOfServiceCode = {
  code: string
  version: PlaceOfServiceVersion
  name?: string
}

export type FeeSchedule = {
  id: number
  practiceId: number
  practice: Practice
  osmindMarkupPercent: number
  isDefault: boolean
  externalService: ExternalService
  paymentAttempts: PaymentAttempt[]
}

export enum PaymentStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  ACTIVE_DISPUTE = 'ACTIVE_DISPUTE',
  DISPUTE_LOST = 'DISPUTE_LOST',
  DISPUTE_WON = 'DISPUTE_WON',
  CANCELLED = 'CANCELLED',
}

export type RefundAttempt = {
  uuid: string
  totalAmountCents: number
  status: PaymentStatus
  reasonCode: ReasonCode
  actingUserId: string
  memo: string
  errorCode?: string | null
  errorMessage?: string | null
  createdAt: string
  updatedAt?: string
  amountCentsUnapplied: number
  externalServiceId?: string | null
  externalService?: ExternalService | null
  practiceId: number
  practice: Practice
  patientId: string
  patient: PascalPatient | Patient
  paymentAttemptUuid: string
  paymentAttempt: PaymentAttempt
  billableEntityPayments: BillableEntityPayment[]
  lastAttributedAt?: Date | string | null
}

export type PaymentUserType = 'PROVIDER'
export type ReasonCode = 'OSMIND_ISSUED_REFUND' | 'PROVIDER_ISSUED_REFUND'

export type PaymentAttempt = {
  uuid: string
  paymentMethodUuid: string
  paymentMethod: PaymentMethod
  practiceId: number
  practice: Practice
  totalAmountCents: number
  amountCentsUnapplied: number
  feeScheduleId?: number | null
  feeSchedule?: FeeSchedule | null
  status: PaymentStatus
  actingUserId: string
  actingUserType: PaymentUserType
  errorCode?: string | null
  errorMessage?: string | null
  createdAt: Date | string
  updatedAt?: Date | string | null
  deletedAt?: Date | string | null
  memo?: string
  patientId: string
  patient: PascalPatient
  externalServiceId?: string | null
  externalService?: ExternalService | null
  externalServiceFeeAmountCents?: number | null
  lastAttributedAt?: Date | string | null
  settledAt?: Date | string | null
  lastRefundedAt?: Date | string | null

  refundAttempts: RefundAttempt[]
  billableEntityPayments: BillableEntityPayment[]
}

export type Superbill = {
  id: number
  clinicalNoteId: string
  locationId?: number
  invoice: Invoice
  diagnoses: InvoiceDiagnosis[]
  appointmentDate: string
  pos?: PlaceOfServiceCode
  patientPaymentAmountCents: number
  selectedEIN: string
  selectedNPI: string
  pdfS3Location?: string
  amdVisitId?: string
  includeSignature?: boolean
  sharedAt?: string | null
  sharedBy?: string | null
}

export enum InNetworkClaimStatus {
  SUBMISSION_REQUIRED = 'SUBMISSION_REQUIRED',
  SUBMITTED = 'SUBMITTED',
}

export const StatusCategory = {
  DRAFT: 'DRAFT',
  NO_ACTION: 'NO_ACTION',
  INFO: 'INFO',
  WARNING: 'WARNING',
  ACTION_REQUIRED: 'ACTION_REQUIRED',
  DONE: 'DONE',
} as const

export type StatusCategoryValue =
  | 'DRAFT'
  | 'NO_ACTION'
  | 'INFO'
  | 'WARNING'
  | 'ACTION_REQUIRED'
  | 'DONE'

export type ClaimStatusUpdate = {
  displayMessage: string
  statusCategory: StatusCategoryValue
  categoryCode?: string
  categoryCodeValue?: string
  statusCode?: string
  statusCodeValue?: string
  entityIdentifierCode?: string
  entityIdentifierCodeValue?: string
  transactionTime?: string
}

export interface InsuranceClaim {
  uuid: string
  claimStatus?: CLAIM_STATUS
  patientControlNumber: string
  submittedAt: string | null
  billableEntity: BillableEntity
  InsuranceClaimManualTransaction: InsuranceClaimManualTransaction
  isERApatientResponsibilityActive: boolean
  isERAPaymentActive: boolean
  insurancePaidAmountCents: number
  totalPatientOutstanding: number
  totalClaimOutstanding: number
  patientPaidAmountCents: number
  writeOffAmountCents: number
  BillableEntityPayment: BillableEntityPayment[]
  InsurancePayment: [InsurancePayment]
  patientResponsibilityAmountCents: number
  isMissingSubmission?: boolean
  claimStatusUpdate: ClaimStatusUpdate
}

export type InsurancePayment = {
  amountCents: number
  claimStatusCode: string
  createdAt: string
  insuranceClaimId: string
  insuranceTransactionId: string
  patientControlNumber: string
  patientResponsibilityAmountCents: number
  updatedAt: string
  uuid: string
}

export type InsuranceClaimManualTransaction = {
  uuid: string
  createdAt: string
  insuranceClaimId: string
  patientResponsibilityAmountCents: number
  patientResponsibilityAmountCentsUpdatedAt: string
  paymentAmountCents: number
  paymentAmountCentsUpdatedAt: string
  updatedAt: string
  writeOffAmountCents: number
  writeOffAmountCentsUpdatedAt: string
}

export interface BillableEntity {
  totalAdjustmentAmountCents: number
  totalAmountCents: number | null
  amountCentsDue: number
}

export enum CLAIM_STATUS {
  DRAFT = 'DRAFT',
  RESUBMITTED = 'RESUBMITTED',
  SETTLED = 'SETTLED',
  REJECTED = 'REJECTED',
  ERROR = 'ERROR',
  INFO_REQUEST = 'INFO_REQUEST',
  FINALIZED = 'FINALIZED',
  DENIED = 'DENIED',
  REVISED = 'REVISED',
  FORWARDED = 'FORWARDED',
  SUBMITTED = 'SUBMITTED',
  MANUALLY_ADJUDICATED = 'MANUALLY_ADJUDICATED',
  MANUALLY_SUBMITTED = 'MANUALLY_SUBMITTED',
  ACCEPTED = 'ACCEPTED',
  ADJUDICATED = 'ADJUDICATED',
  CANCELED = 'CANCELED',
  CHANGE_ERROR = 'CHANGE_ERROR',
}

export enum CLAIM_STATUS_DISPLAY {
  DRAFT = 'Draft',
  RESUBMITTED = 'Resubmitted',
  SETTLED = 'Settled',
  REJECTED = 'Rejected',
  ERROR = 'Error',
  INFO_REQUEST = 'Info request',
  FINALIZED = 'Finalized',
  DENIED = 'Denied',
  REVISED = 'Revised',
  FORWARDED = 'Forwarded',
  SUBMITTED = 'Submitted',
  MANUALLY_SUBMITTED = 'Manually submitted',
  ACCEPTED = 'Accepted',
  CANCELED = 'Canceled', // Don't need to specifically call out a manual adjudication for the user
  MANUALLY_ADJUDICATED = 'Adjudicated',
  CHANGE_ERROR = 'Submission error',
  ADJUDICATED = 'Adjudicated',
  UNKNOWN = 'Unknown',
}

export interface Invoice {
  uuid: string
  invoiceNumber: number
  practiceId: number
  practice: Practice
  patientId: string
  patient: PascalPatient
  totalAmountCents: number
  totalAdjustmentAmountCents: number
  amountCentsDue: number
  createdAt: string
  deletedAt?: string | null
  updatedAt?: string | null

  notes?: string
  providerId?: string
  provider?: Provider

  appointmentDate?: string
  /**
   * @deprecated use clinicalNoteIdV2 instead
   */
  clinicalNoteId?: string
  clinicalNoteIdV2?: string
  clinicalNote?: ClinicalNote

  location?: Location
  locationId?: number

  pos?: PlaceOfServiceCode
  posCode?: string
  posVersion?: PlaceOfServiceVersion
  hiddenAt?: string | null

  billableEntityLineItems: LineItem[]
  billableEntityPayments?: BillableEntityPayment[]
  superbill?: Superbill

  inNetworkClaimStatus?: InNetworkClaimStatus

  // I don't know if this is still in use
  billingTemplate?: {
    id?: number
  }
}

export interface PaymentTransaction extends PaymentAttempt {
  billableEntityPayments: BillableEntityPayment[]
  refundAttempts: RefundAttempt[]
  paymentMethod: PaymentMethod
}

export interface RefundTransaction extends RefundAttempt {
  settledAt?: Date | string | null
  billableEntityPayments: BillableEntityPayment[]
}

export interface Transaction {
  payments: PaymentTransaction[]
  refunds: RefundTransaction[]
}

export interface ClinicSummaryData {
  totalOutstandingInvoicesAmountCents: number
  totalCollectedPaymentAmountCents: number
  totalOutstandingCredits: number
  patientsWithBalance: number
  averageInvoiceAge: number
}

export interface BillableEntityPayment {
  uuid: string
  invoiceUuid?: string | null
  invoice?: Invoice | null
  paymentAttemptUuid?: string | null
  paymentAttempt?: PaymentAttempt | null
  refundAttemptUuid?: string | null
  refundAttempt?: RefundAttempt | null
  amountCents: number
  invoiceNumber?: number
  createdAt: string
  updatedAt?: string | null
  deletedAt?: string | null
  insuranceClaimUuid?: string | null
  insuranceClaim?: InsuranceClaim | null
}

export interface PaymentMethod {
  uuid: string
  externalServiceId?: string
  externalService?: ExternalService
  patientId: string
  patient: PascalPatient
  lastUsedAt?: string | null
  lastFour?: string | null
  cardIssuer?: string | null
  expMonth?: number | null
  expYear?: number | null
  isDefault?: boolean
  nickname?: string
  type: PAYMENT_METHOD_TYPES
  createdAt: string
  updatedAt?: string | null
  deletedAt?: string | null
  zipCode?: string
  cardholderName?: string
  paymentAttempts: PaymentAttempt[]
}

export type TestId = {
  testId?: string
}

export type Ref = {
  ref?: RefObject<any>
}

export interface SignatureRequest {
  CompletedAt: DateISO8601 | null
  ImportedAt: DateISO8601 | null
  PatientId: string
  RequestReference: string | null
  SentAt: DateISO8601
  SignatureStatus: string
  SubmissionId: string | null
  TemplateId: string | null
  TemplateName: string | null
  UpdatedAt: DateISO8601 | null
}

export interface BillableEntityPaymentRequest {
  amountCents: number
  invoiceUuid: string
  paymentAttemptUuid: string
}

export interface Diagnosis {
  CreatedAt: DateISO8601 | null
  CreatedBy: string
  CreatedOn: DateISO8601 | null
  Diagnosis: string
  DiagnosisCode: string
  DiagnosisCodeIsCustom: boolean
  DiagnosisDate: DateISO8601 | null
  DiagnosisId: string
  DiagnosisNoteContent: string
  DiagnosisOnsetDate: DateISO8601 | null
  DiagnosisOnsetDateFormat: string // MM/DD/YYYY
  DiagnosisStatus: 'Active' | 'Resolved' | 'Inactive'
  IsUpdatedInRCopia: null | boolean
  IsUploadedToRCopia: null | boolean
  PatientId: string
  UpdatedBy: null | string
  UpdatedOn: DateISO8601 | null
  UploadedToRCopia: null | boolean
  WasUpdatedInRCopia: null | boolean
  isPatientReported: null | boolean
}

export interface DiagnosisHistory extends Diagnosis {
  DiagnosisHistoryId: number
  DiagnosisChangeAction: String
  DiagnosisHistoryCreatedAt: DateISO8601
}

export interface NoteDiagnosis {
  diagnosis: DiagnosisHistory
  diagnosisBlockId: number
  diagnosisId: string
  excludedAt: any
  id: number
  noteId: number
  order: number
}

export interface DiagnosisContentBlock {
  diagnosisBlockId: number
  diagnoses: NoteDiagnosis[]
  differential: any
  noteId: number
}

export enum MedicationOrigin {
  OSMIND = 'OSMIND',
  DR_FIRST = 'DR_FIRST',
  UNKNOWN = 'UNKNOWN',
}

export interface ActiveMedication {
  ActiveMedicationId: string
  AdditionalInstructions: string
  Administration: string
  AdministrationIntervalInDays: string
  AdministrationOther: string
  AdministrationResponsesToDate: number
  Comments: string
  CreatedOn: string
  DisallowMedicationAdherence: boolean
  Dosage: string
  Dose?: string | null
  DoseTiming?: string | null
  DoseUnit?: string | null
  DosesRemainingEstimate?: string | null
  DosesRemainingLastUpdate: any
  DosesRemainingLastUpdateDate: string
  EditedOn?: string | null
  FillDate?: string | null
  Form?: string | null
  Frecuency: string
  IsRetired: boolean
  LastAdministrationDate?: string | null
  LastPatientEstimateOfDosesRemaining?: string | null
  MedicationName: string
  MentalPsychiatricConditions: boolean
  PatientId: string
  PatientPerspectiveEfficacy: string
  PrescribedByProvider: boolean
  ProviderStartDate?: string | null
  ReasonDiscontinued?: string | null
  ReasonForUse: string
  Schedule?: string | null
  SideEffects: string
  StartDate?: string | null
  StopDate?: string | null
  startDate?: string | null
  origin: MedicationOrigin
  originDate?: string | null
  updatedAt?: Date
  isDeleted?: boolean
  quantity?: number | null
  refills?: number | null
  other?: string | null
}

export type ActiveMedicationHistory = Omit<ActiveMedication, 'updatedAt'> & {
  changeEvent: 'UPDATE' | 'CREATE' | 'BACKFILL' | 'DELETE'
  historyCreatedAt: Date
}

export interface Allergy {
  AllergyId: string
  PatientId: string
  Allergies: string | null
  AllergyName: string
  AllergyStatus: 'Active' | 'Inactive' | null
  AllergyType: 'Drug' | 'Environment' | 'Food' | 'Latex' | 'Other' | null
  Comments: string | null
  CreatedAt: DateISO8601
  CreatedBy: string
  UpdatedAt: DateISO8601
  UpdatedBy: string
  NoKnownAllergies: null | boolean
  Onset: string | null
  PatientReported: null | boolean
  Reaction: string | null
  Severity: string | null
  DeletedAt: DateISO8601
  DeletedBy: string
}

export interface AllergyHistory extends Allergy {
  AllergyHistoryId: number
  AllergyChangeAction: String
  AllergyHistoryCreatedAt: DateISO8601
}

export interface Document {
  DocumentId: string
  DocumentReference?: string
  PatientId: string
  Status?: string
  Title?: string
  Name?: string
  NoteDate?: string
  Superbill?: boolean
  SpravatoREMS?: boolean
  DateSubmitedToSpravato?: string
  DateTimeUploaded: string
  docType?: DocumentType
}

export interface PatientReportedMedications {
  PK: string
  SK: string
  CurrentMedications?: string
  PastMedications?: string
  CreatedBy: string
  CreatedAt: DateISO8601
  UpdatedAt?: DateISO8601
  UpdatedBy?: string
  DismissCurrentMedications?: boolean
  DismissPastMedications?: boolean
}

export interface ActiveMedicationResponse {
  ActiveMedicationId: string
  AdministeredLate: boolean
  HasAdverseEvent: boolean
  MedicationName: string
  MedicationResponseId: string
  PatientId: string
  ResponseDate: string
  Administration: string | null
  Dosage: string | null
  AdministrationDate: string | null
  AdministrationDueDate: string | null
  AdverseEventText: string | null
  DidAdminister: boolean | null
  DidRespond: boolean | null
  DosageRemainingEstimate: string | null
}

export interface PastMedication {
  PatientId: string
  PastMedicationId: string
  MedicationName: string
}

export interface PatientMedications {
  activeMeds: ActiveMedication[]
  medicationLogs: ActiveMedicationResponse[]
  pastMeds: PastMedication[]
  PatientReportedMedications?: PatientReportedMedications
}

export interface JournalNote {
  NoteId: string
  PatientId: string
  NoteText: string
  IsRetired: boolean
  IsSideEffect: boolean
  CreatedOn: string
}

export type SidePanelEvent = {
  eventId: string
  eventDetailsId: number
  eventName: string
  startTime: string
  endTime: string
  eventProviders: string[]
  roomName: string
  locationName: string
  practiceTimeZone: string
}

export type PatientSidePanelEvents = {
  todayAppointment?: SidePanelEvent[]
  nextAppointment?: SidePanelEvent
  lastAppointment?: SidePanelEvent
}

export type AppointmentStatus =
  | 'PENDING'
  | 'NO_SHOW'
  | 'DECLINED'
  | 'ACCEPTED'
  | 'FULFILLED'
  | 'CANCELLED'

export interface GetAppointmentsQueryStringParameters {
  endDate: string
  includeDetails: string
  patientId?: string
  startDate: string
  statuses?: string
}

interface AppointmentNotification {
  status: AppointmentStatus
  providerIsOnCareTeam: boolean
}

export interface AppointmentList {
  patient: {
    CareTeam: {
      Providers: {
        ProviderId: string
      }
    }[]
    firstName: string | null
    lastName: string | null
    PatientEmail: string
    PhoneNumber: string | null
    SuicidalThoughts: boolean | null
    PatientId: string
  }
  appointmentType: {
    name: string
    durationInMinutes: number
    id: string
    eventProviders: string[]
    room: {
      roomName: string
      location: {
        name: string
      }
    } | null
  }
  practice: {
    providers: {
      ProviderId: string
      ProviderFirstName: string | null
      ProviderLastName: string | null
      ProviderCredential: string | null
      ProviderSuffix: string | null
    }[]
  }
  providers: {
    ProviderId: string
    ProviderFirstName: string | null
    ProviderLastName: string | null
    ProviderCredential: string | null
    ProviderSuffix: string | null
  }[]
  requestedStartTime: Date
  status: AppointmentStatus
  id: string
  providerIsOnCareTeam: boolean
}

export type Appointment<IsNotification extends true | false> =
  IsNotification extends true ? AppointmentNotification : AppointmentList

export interface PaymentsSummary {
  createdAt: string
  patientName: string
  primaryProvider: string
  actingUser: string
  cardInfo: string | null
  amount: string
  stripeFee: string | null
  netAmount: string
  refundedAmount: string
  status: string
  errorCode: string | null | undefined
  errorMessage: string | null | undefined
}
