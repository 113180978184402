import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { CalendarQueries } from '.'
import { updateCalendarTimezone } from '../../api/api-lib'

type timeZoneParams = {
  timezone: string
}

type optionsType<TParams> =
  | Omit<UseMutationOptions<Response, any, TParams, unknown>, 'mutationFn'>
  | undefined

export const useTimezoneMutation = (
  options: optionsType<timeZoneParams> = {}
) => {
  const queryClient = useQueryClient()

  return useMutation<Response, optionsType<timeZoneParams>, timeZoneParams>(
    updateCalendarTimezone,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options.onSuccess && options.onSuccess(data, variables, context)
        queryClient.invalidateQueries([CalendarQueries.TIMEZONE])
      },
    }
  )
}
