import React from 'react'

import { ModalBasic } from '../BaseComponents'
import { getLocaleDateDisplay, getLocaleTimeDisplay } from './utils'

export type ResendSuperbillModalProps = {
  visible: boolean
  onCancel: () => void
  onSend: () => void
  isSending: boolean
  sharedAt?: string | null
}
const ResendSuperbillModal: React.FC<ResendSuperbillModalProps> = ({
  visible,
  onCancel,
  onSend,
  isSending,
  sharedAt,
}) => {
  return (
    <ModalBasic
      visible={visible}
      title={'Superbill already shared'}
      onCancel={onCancel}
      cancelButtonProps={{
        disabled: isSending,
        testId: 'cancel-resend-superbill-button',
      }}
      onConfirm={onSend}
      confirmButtonLabel={'Resend superbill'}
      confirmButtonProps={{
        loading: isSending,
        disabled: isSending,
        testId: 'resend-superbill-button',
      }}
      contentTestId={'resend-superbill-to-patient-modal'}
    >
      <div style={{ marginBottom: -16 }}>
        <p>
          {sharedAt
            ? `This superbill was last shared with the patient on ${getLocaleDateDisplay(
                sharedAt
              )} at ${getLocaleTimeDisplay(sharedAt)}.`
            : `This superbill has been previously shared with the patient.`}
        </p>
        <p> Do you want to send it again?</p>
      </div>
    </ModalBasic>
  )
}

export default ResendSuperbillModal
