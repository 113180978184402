import { Suspense as ReactSuspense, SuspenseProps } from 'react'

import Spinner from '../Spinner'

import styles from './index.module.scss'

function FullPageLoader() {
  return (
    <div className={styles.wrapper}>
      <Spinner />
    </div>
  )
}

type Props = Omit<SuspenseProps, 'fallback'>

export default function PageSuspense({ children, ...restProps }: Props) {
  return (
    <ReactSuspense {...restProps} fallback={<FullPageLoader />}>
      {children}
    </ReactSuspense>
  )
}
