import React from 'react'

import { FilterValue } from 'antd/es/table/interface'

import { useHasPermissions } from '../../hooks/useHasPermissions'
import { TestId } from '../../shared-types'
import { Tag } from './Tag'

export interface FilterObject {
  readonly key: string
  readonly value: string[]
}

type FilterTagsProps = {
  activeFilters: FilterObject[]
  closable?: boolean
} & TestId

type UpdateFilterFunction = (
  filters: Record<string, FilterValue | null>
) => FilterObject[]

export const updateFilters: UpdateFilterFunction = (
  filters: Record<string, FilterValue | null>
) =>
  Object.keys(filters)
    .filter((filterKey) => filters[filterKey])
    .map((filterKey) => ({
      key: filterKey,
      value: filters[filterKey] as string[],
    }))

const FilterTags: React.FC<FilterTagsProps> = ({
  activeFilters,
  closable,
  testId,
}) => {
  const seeOnlyOwnPatientsSetting = !useHasPermissions({
    canSeeAllPatients: 'READ',
  })
  const removeFilter = (key: string) => {
    // remove the Filter
    console.log(key)
  }

  return (
    <div data-testid={testId}>
      {seeOnlyOwnPatientsSetting || activeFilters.length ? (
        <span>
          <span>Filtered by: </span>
        </span>
      ) : null}
      {seeOnlyOwnPatientsSetting ? (
        <span>
          <Tag key="patientsOnly">Your Patients Only</Tag>
        </span>
      ) : null}
      {activeFilters.length ? (
        <span>
          {activeFilters.map((tag) => (
            <Tag
              data-testid={testId ? `${testId}-${tag.value}` : undefined}
              key={tag.key}
              closable={closable}
              onClose={() => removeFilter(tag.key)}
            >
              {tag.value}
            </Tag>
          ))}{' '}
        </span>
      ) : null}
    </div>
  )
}

export default FilterTags
