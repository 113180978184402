import React from 'react'

import { isBefore } from 'date-fns'
import moment from 'moment'
import 'moment-timezone'
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
import 'react-dates/initialize'

import { AutoSizingTextArea } from '../../../../stories/BaseComponents/TextAreaInput'
import AddVitals from './Sections/AddVitals'
import AssessmentPreparations from './Sections/AssessmentPreparations'
import Discharge from './Sections/Discharge'
import FacilityStaff from './Sections/FacilityStaff'
import KAPSideEffects from './Sections/KAPSideEffects'
import ShowEntriesKAP from './Tables/ShowEntriesKAP'
import ShowSavedAM from './Tables/ShowSavedAM'
import ShowSavedVS from './Tables/ShowSavedVS'

import '../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function KAP(props) {
  const isBeforeNewDoseCalculation = isBefore(
    new Date(props.createdOnDate),
    new Date('2023-04-25')
  )
  function handleShowEntriesKAP() {
    if (props.fields.SavedEntries && props.fields.SavedEntries.length !== 0) {
      return (
        <>
          {props.fields.SavedEntries.map((entry, index) => {
            return (
              <ShowEntriesKAP
                key={index}
                index={index}
                Time={entry.Time}
                Dose={entry.Dose}
                TotalDose={entry.TotalDose}
                Location={entry.Location}
                Notes={entry.Notes}
                Route={entry.Route}
                RouteOther={entry.RouteOther}
                MedicationType={entry.MedicationType}
                MedicationTypeOther={entry.MedicationTypeOther}
                PatientWeight={props.fields.PatientWeight}
                KetamineUnits={props.fields.KetamineUnits}
                saveEditEntries={props.saveEditEntries}
                deleteEntries={props.deleteEntries}
                disabled={props.disabled}
                isBeforeNewDoseCalculation={isBeforeNewDoseCalculation}
              />
            )
          })}
        </>
      )
    }
  }

  function handleShowAdditionalMedication() {
    if (props.fields.SavedAdditionalMedications.length !== 0) {
      return (
        <>
          {props.fields.SavedAdditionalMedications.map((addMed, index) => {
            return (
              <ShowSavedAM
                key={index}
                index={index}
                deleteAM={props.deleteAM}
                AdditionalMedicationName={addMed.AdditionalMedicationName}
                AdditionalMedicationOther={addMed.AdditionalMedicationOther}
                AdditionalMedicationAdministrationRoute={
                  addMed.AdditionalMedicationAdministrationRoute
                }
                AdditionalMedicationDosage={addMed.AdditionalMedicationDosage}
                AdditionalMedicationOtherAdministrationRoute={
                  addMed.AdditionalMedicationOtherAdministrationRoute
                }
                AdditionalMedicationUnits={addMed.AdditionalMedicationUnits}
                AdditionalMedicationUnitsOther={
                  addMed.AdditionalMedicationUnitsOther
                }
                AdditionalMedicationTime={addMed.AdditionalMedicationTime}
                saveEditAdditionalMedication={
                  props.saveEditAdditionalMedication
                }
                disabled={props.disabled}
              />
            )
          })}
        </>
      )
    }
  }

  function handleShowSavedVitalsIMKetamine() {
    if (
      props.fields.SavedVitalSigns &&
      props.fields.SavedVitalSigns.length !== 0
    ) {
      return (
        <>
          {props.fields.SavedVitalSigns.map((vitals, index) => {
            return (
              <ShowSavedVS
                key={index}
                index={index}
                deleteVitals={props.deleteVitals}
                VitalSignsType={vitals.VitalSignsType}
                Time={vitals.Time}
                HR={vitals.HR}
                BP={vitals.BP}
                BpArm={vitals.BpArm}
                RR={vitals.RR}
                SPO2={vitals.SPO2}
                Comments={vitals.Comments}
                EKG={vitals.EKG}
                RASSscore={vitals.RASSscore}
                saveEditVitalSigns={props.saveEditVitalSigns}
                disabled={props.disabled}
                discharge
              />
            )
          })}
        </>
      )
    }
  }

  function saveNewAdditionalMedicationForIMKetamine() {
    props.setAddAdditionalMedication(false)

    const newAditionalMedication = [
      {
        AdditionalMedicationName: '',
        AdditionalMedicationOther: '',
        AdditionalMedicationAdministrationRoute: 'PO',
        AdditionalMedicationOtherAdministrationRoute: '',
        AdditionalMedicationDosage: '',
        AdditionalMedicationUnits: '',
        AdditionalMedicationUnitsOther: '',
        AdditionalMedicationTime: moment().format('HH:mm'),
      },
    ]

    props.handleFieldChange({
      ...props.fields,
      SavedAdditionalMedications:
        props.fields.SavedAdditionalMedications.concat(newAditionalMedication),
    })
  }

  return (
    <div style={{ position: 'relative' }}>
      {/* KAP Template */}
      <FacilityStaff
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        disabled={props.disabled}
      />
      <br />
      <AssessmentPreparations
        KAP
        fields={props.fields}
        detail={props.detail}
        handleFieldChange={props.handleFieldChange}
        pregnantStatus={props.pregnantStatus}
        disabled={props.disabled}
      />
      <br />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Ketamine-assisted psychotherapy
      </div>
      <br />
      <div
        className="center info"
        style={{ fontWeight: 'bold', paddingBottom: '1em' }}
      >
        Ketamine Administration
      </div>
      <Table responsive>
        {props.fields.SavedEntries.length !== 0 || !props.detail ? (
          <thead>
            <tr>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '136px',
                }}
              >
                Time
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '170px',
                }}
              >
                Route
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '150px',
                }}
              >
                {`Dose (${isBeforeNewDoseCalculation ? 'mg' : 'mg/kg'})`}
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '150px',
                }}
              >
                {`Total (${isBeforeNewDoseCalculation ? 'mg/kg' : 'mg'})`}
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '150px',
                }}
              >
                Location
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '200px',
                }}
              >
                Medication type
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '300px',
                }}
              >
                Notes
              </th>
              {!props.disabled && (
                <th
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    minWidth: '140px',
                  }}
                >
                  &nbsp;
                </th>
              )}
            </tr>
          </thead>
        ) : (
          <></>
        )}
        <tbody>{handleShowEntriesKAP()}</tbody>
      </Table>

      <Row>
        {props.disabled ? (
          props.fields.SavedEntries.length === 0 ? (
            <></>
          ) : (
            <>
              <Form.Group
                as={Col}
                sm={12}
                md={6}
                lg={4}
                xl={5}
                controlId="PatientWeight"
              >
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Patient Weight:{' '}
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  value={props.fields.PatientWeight}
                  onChange={props.handleFieldChange}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                sm={12}
                md={6}
                lg={4}
                xl={5}
                controlId="KetamineUnits"
              >
                <Form.Label style={{ fontWeight: 'bold' }}>Units: </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  as="select"
                  onChange={props.handleFieldChange}
                  value={props.fields.KetamineUnits}
                >
                  <option key={1} name="lbs" value="lbs">
                    lbs
                  </option>
                  <option key={0} name="kg" value="kg">
                    kg
                  </option>
                </Form.Control>
              </Form.Group>
            </>
          )
        ) : props.fields.SavedEntries.length > 0 ? (
          <>
            <Form.Group
              as={Col}
              sm={12}
              md={6}
              lg={4}
              xl={5}
              controlId="PatientWeight"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>
                Patient Weight:{' '}
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                type="number"
                value={props.fields.PatientWeight}
                disabled={props.disabled}
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    PatientWeight: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group
              as={Col}
              sm={12}
              md={6}
              lg={4}
              xl={5}
              controlId="KetamineUnits"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>Units: </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                as="select"
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    KetamineUnits: e.target.value,
                  })
                }
                disabled={props.disabled}
                value={props.fields.KetamineUnits}
              >
                <option key={1} name="lbs" value="lbs">
                  lbs
                </option>
                <option key={0} name="kg" value="kg">
                  kg
                </option>
              </Form.Control>
            </Form.Group>
            <Col xs={12} xl={12} className="center">
              <Button
                bsPrefix="plain-button info button-label"
                onClick={() => {
                  props.saveNewEntriesKAP()
                }}
              >
                + Add New Entry
              </Button>
            </Col>
          </>
        ) : (
          <Col xs={12} xl={12} className="center">
            <Button
              bsPrefix="plain-button info button-label"
              onClick={() => {
                props.saveNewEntriesKAP()
              }}
            >
              + Add New Entry
            </Button>
          </Col>
        )}
      </Row>
      <Form.Row>
        <Col className="center">
          <Form.Text
            style={{
              fontWeight: 'bold',
              color: '#A9A9A9',
              paddingBottom: '35px',
            }}
            className="hideDuringPrint"
          >
            Small screens may need to scroll horizontally for a full view of
            medication and vitals.
          </Form.Text>
        </Col>
      </Form.Row>
      <hr />
      <div
        className="center info"
        style={{ fontWeight: 'bold', paddingBottom: '1em' }}
      >
        Additional Medications
      </div>
      <Table responsive>
        {props.fields.SavedAdditionalMedications.length !== 0 ||
        !props.detail ? (
          <thead>
            <tr>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '280px',
                }}
              >
                Additional Medication Name
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '112px',
                }}
              >
                Total Dose
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '150px',
                }}
              >
                Units
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '180px',
                }}
              >
                Route
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '128px',
                }}
              >
                Time
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '140px',
                }}
              >
                &nbsp;
              </th>
            </tr>
          </thead>
        ) : (
          <></>
        )}
        <tbody>{handleShowAdditionalMedication()}</tbody>
      </Table>

      {!props.disabled && (
        <Form.Row>
          <Col className="center">
            <Button
              bsPrefix="plain-button info button-label"
              onClick={() => {
                saveNewAdditionalMedicationForIMKetamine()
              }}
            >
              + Add Med
            </Button>
          </Col>
        </Form.Row>
      )}

      <Form.Row>
        <Col className="center">
          <Form.Text
            style={{
              fontWeight: 'bold',
              color: '#A9A9A9',
              paddingBottom: '35px',
            }}
            className="hideDuringPrint"
          >
            Small screens may need to scroll horizontally for a full view of
            medication and vitals.
          </Form.Text>
        </Col>
      </Form.Row>
      <hr />
      <div
        className="center info"
        style={{ fontWeight: 'bold', paddingBottom: '1em' }}
      >
        Vitals
      </div>
      <Table responsive>
        {props.fields.SavedVitalSigns.length !== 0 || !props.detail ? (
          <thead>
            <tr>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '140px',
                }}
              >
                Time
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '100px',
                }}
              >
                HR
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '100px',
                }}
              >
                BP
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '100px',
                }}
              >
                SpO2
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '100px',
                }}
              >
                EKG
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '100px',
                }}
              >
                RR
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '100px',
                }}
              >
                RASS
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '336px',
                }}
              >
                Comments
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '140px',
                }}
              >
                &nbsp;
              </th>
            </tr>
          </thead>
        ) : (
          <></>
        )}
        <tbody>{handleShowSavedVitalsIMKetamine()}</tbody>
      </Table>
      <AddVitals
        shortPublicId={props.patient.ShortPublicId}
        disabled={props.disabled}
        setAddVitals={props.setAddVitals}
        savedVitalSigns={props.fields.SavedVitalSigns}
        bPPlacement={props.fields.BpPlacement}
        handleSaveVitals={props.saveNewVitals}
        handleSaveCaretakerVitals={props.saveNewCaretakerVitals}
        handleFieldChange={(value) => {
          props.handleFieldChange({ ...props.fields, BpPlacement: value })
        }}
      />
      <Form.Row>
        <Col className="center">
          <Form.Text
            style={{
              fontWeight: 'bold',
              color: '#A9A9A9',
              paddingBottom: '20px',
            }}
            className="hideDuringPrint"
          >
            Small screens may need to scroll horizontally for a full view of
            medication and vitals.
          </Form.Text>
        </Col>
      </Form.Row>
      <hr />
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          controlId="PsychotherapyNotes"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Psychotherapy notes
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.PsychotherapyNotes}
            as={AutoSizingTextArea}
            minRows={3}
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PsychotherapyNotes: e.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>

      <KAPSideEffects
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        disabled={props.disabled}
      />

      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          controlId="PatientResponseToExperience"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Patient narrative response to experience
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.PatientResponseToExperience}
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={2}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientResponseToExperience: e.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          controlId="TreatmentNotes"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional notes
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.TreatmentNotes}
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={2}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                TreatmentNotes: e.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <br />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Assessment and Plan
      </div>
      <Form.Row style={{ marginTop: '20px' }}>
        <Form.Group as={Col} xs={12} sm={12} lg={12} controlId="TreatmentPlan">
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.TreatmentPlan}
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={3}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                TreatmentPlan: e.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <br />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Discharge
      </div>
      <br />
      <Discharge
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        detail={props.detail}
        template="KAP"
        saveNewDischargeVitals={props.saveNewDischargeVitals}
        addDischargeVitals={props.addDischargeVitals}
        setAddDischargeVitals={props.setAddDischargeVitals}
        saveEditDischargeVitalSigns={props.saveEditDischargeVitalSigns}
        deleteVitals={props.deleteDischarge}
        disabled={props.disabled}
      />
    </div>
  )
}
