import React, { useEffect, useState } from 'react'

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Nav,
  Row,
  Tab,
} from 'react-bootstrap'

import { capitalize, isEmptyObject } from '../../../../../../libs/utils'
import { useClinicalNoteContext } from '../../../ClinicalNoteContext'
import { FieldType, Normal, Section, SectionFieldMap } from './constants'

export default function MentalStatusExam(props: any) {
  const { updateType, fields, setFields, findMostRecentEntry } =
    useClinicalNoteContext()
  const [section, setSection] = useState<keyof typeof Section>('Appearance')

  const state = fields.MSE as any

  const setState = (
    section: keyof typeof Section,
    key: string,
    value?: string | boolean | Record<string, string>
  ) => {
    return setFields({
      ...fields,
      MSE: {
        ...state,
        [section]: {
          ...state[section],
          [key]: value,
        },
      },
    })
  }

  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    setExpanded((isExpanded) => isExpanded || !isEmptyObject(state))
  }, [updateType, state])

  const mostRecentEntry = findMostRecentEntry('MSE')

  const renderGroup = (
    key: string,
    value: any,
    { options, nested, hasText, color }: any
  ) => {
    const renderHeader = () => {
      if (nested) {
        const checked = value !== undefined
        return (
          <Form.Row key={`${key}-header`}>
            <Form.Check
              id={`checkbox-${key}`}
              type="checkbox"
              checked={checked}
              label={key}
              disabled={props.disabled}
              onChange={(event) =>
                setState(
                  section,
                  key,
                  event.currentTarget.checked ? {} : undefined
                )
              }
            />
          </Form.Row>
        )
      }
      return (
        <div
          className="semiTitle"
          style={{ fontWeight: 700, marginBottom: '5px', marginLeft: -5 }}
        >
          {key}
        </div>
      )
    }

    return (
      <Form.Group key={`${key}-group`} as={Col} xs={12} sm={12} lg={4} xl={4}>
        {renderHeader()}
        {(!nested || value !== undefined) &&
          Object.entries(options).map(([option, label]: any, index) => {
            const checked = value?.[option] !== undefined

            return (
              <Form.Row
                key={`${key}-${index}`}
                style={{
                  fontSize: 14,
                  marginLeft: 8,
                  paddingBottom: '5px',
                }}
              >
                <Form.Check
                  className={color?.[option] === 'green' ? 'green' : ''}
                  id={`checkbox-${section}-${key}-${option}`}
                  type="checkbox"
                  checked={checked}
                  label={label}
                  disabled={props.disabled}
                  onChange={(event) =>
                    setState(section, key, {
                      ...value,
                      [option]: event.currentTarget.checked ? '' : undefined,
                    })
                  }
                />
                {checked && hasText && (
                  <Form.Control
                    bsPrefix="form-input"
                    placeholder="Additional notes"
                    style={{ margin: '5px 15px' }}
                    value={value?.[option]}
                    disabled={props.disabled}
                    onChange={(event) =>
                      setState(section, key, {
                        ...value,
                        [option]: event.target.value,
                      })
                    }
                  />
                )}
              </Form.Row>
            )
          })}
      </Form.Group>
    )
  }

  const renderCheckbox = (
    key: string,
    value: string | undefined,
    { hasText, color }: any
  ) => {
    const checked = value !== undefined
    return (
      <Form.Group key={key} as={Col} xs={12} sm={12} lg={4} xl={4}>
        <Form.Row key={key}>
          <Form.Check
            className={color === 'green' ? 'green' : ''}
            id={`checkbox-${key}`}
            type="checkbox"
            checked={checked}
            label={capitalize(key)}
            disabled={props.disabled}
            onChange={(event) =>
              setState(
                section,
                key,
                event.currentTarget.checked ? '' : undefined
              )
            }
          />
          {checked && hasText && (
            <Form.Control
              bsPrefix="form-input"
              placeholder="Additional notes"
              style={{ margin: '5px 15px' }}
              value={value}
              disabled={props.disabled}
              onChange={(event) => setState(section, key, event.target.value)}
            />
          )}
        </Form.Row>
      </Form.Group>
    )
  }

  const renderText = (
    key: string,
    value: string | undefined,
    { description }: any
  ) => {
    const text = value ?? ''
    return (
      <Form.Group
        key={key}
        as={Col}
        xs={12}
        sm={12}
        lg={4}
        xl={4}
        style={{ marginLeft: -5 }}
      >
        <Form.Label style={{ margin: 0, fontWeight: 400 }}> Notes</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          key={`${section}-${key}`}
          style={{ fontSize: '14px' }}
          bsPrefix="form-input"
          value={text}
          disabled={props.disabled}
          onChange={(event) => setState(section, key, event.target.value)}
        />
        <Form.Text className="text-muted" style={{ marginLeft: '5px' }}>
          {description}
        </Form.Text>
      </Form.Group>
    )
  }

  return !expanded ? (
    <Row style={{ justifyContent: 'center' }}>
      <span>
        <Button
          bsPrefix="center plain-button info button-label"
          onClick={() => setExpanded(true)}
        >
          + Mental Status Exam
        </Button>
      </span>
    </Row>
  ) : (
    <Accordion defaultActiveKey="0">
      <Accordion.Toggle
        bsPrefix="accordion-header very-large accordion-toggle"
        as={Card.Header}
        eventKey="0"
      >
        <div
          className="alert-clinical-notes"
          style={{ cursor: 'pointer', marginBottom: 16, display: 'flex' }}
        >
          <span style={{ width: 'calc(100% - 150px)', paddingLeft: '90px' }}>
            Mental Status Exam
          </span>
          {!props.disabled ? (
            <Button
              className="modal-save-button"
              style={{ width: '150px' }}
              onClick={() => setFields({ ...fields, MSE: Normal })}
            >
              Mark all sections with "normal" status
            </Button>
          ) : null}
          {mostRecentEntry && !props.disabled ? (
            <Button
              className="modal-save-button"
              style={{ width: '150px' }}
              onClick={() =>
                setFields({ ...fields, MSE: mostRecentEntry.value })
              }
            >
              Copy most recent -{' '}
              {new Date(mostRecentEntry.CreatedOn).toLocaleDateString()}
            </Button>
          ) : null}
          {!props.disabled && (
            <Button
              className="modal-save-button"
              style={{ width: '120px' }}
              onClick={() => {
                setFields({
                  ...fields,
                  MSE: {},
                })
                return setExpanded(false)
              }}
            >
              <FontAwesomeIcon
                icon={faTrash}
                style={{ marginLeft: '5px', marginRight: '5px', fontSize: 16 }}
              />
              Remove
            </Button>
          )}
        </div>
      </Accordion.Toggle>
      <Tab.Container
        activeKey={section}
        onSelect={(s) => s && setSection(s as keyof typeof Section)}
      >
        <Row>
          <Col
            sm={3}
            style={{
              maxHeight: 400,
              overflowY: 'scroll',
              borderRight: '1px solid #eee',
            }}
          >
            <Nav
              variant="pills"
              className="flex-column"
              style={{ flexWrap: 'nowrap' }}
            >
              {Object.entries(Section).map(([key, label]) => (
                <Nav.Item key={key} style={{ margin: 0 }}>
                  <Nav.Link eventKey={key}>{label}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content
              style={{
                display: 'flex',
                flexFlow: 'column wrap',
                paddingTop: 16,
                maxHeight: 400,
                overflowX: 'scroll',
              }}
            >
              {Object.entries(SectionFieldMap[Section[section]]).map(
                ([key, config]: [string, any]) => {
                  const value = state?.[section]?.[key]

                  if (config.type === FieldType.GROUP) {
                    return renderGroup(key, value, config)
                  }
                  if (config.type === FieldType.CHECKBOX) {
                    return renderCheckbox(key, value, config)
                  }
                  if (config.type === FieldType.TEXT) {
                    return renderText(key, value, config)
                  }
                  return null
                }
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Accordion>
  )
}
