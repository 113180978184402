import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useFeatureFlags } from '../../../libs/featureFlags'
import { notification } from '../../../libs/notificationLib'
import { createNote } from '../api'
import { Note } from '../types'
import { notePatientQueryKey } from './useNotes'

type useCreateNoteProps = {
  onSuccess: (note: Note, options: Parameters<typeof createNote>[0]) => void
  onError?: (error: Error) => void
}

export const useCreateNote = ({ onSuccess, onError }: useCreateNoteProps) => {
  const { hasEnabledCustomNotes } = useFeatureFlags()
  const defaultOnError = (error: Error) => {
    console.error(error)
    notification(
      'There was a problem creating the note. Please contact your Osmind administrator.',
      'error'
    )
  }

  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError } = useMutation(createNote, {
    onSuccess: (note: Note, options) => {
      // invalidate notes list query to force a refresh

      queryClient.invalidateQueries(notePatientQueryKey(note.patientId))
      onSuccess(note, options)
    },
    onError: onError ? onError : defaultOnError,
  })

  const callCreate = ({
    patientId,
    fromLast = false,
    saltNoteId,
    templateId,
  }: {
    patientId: string
    fromLast?: boolean
    saltNoteId?: string
    templateId?: string
  }) => {
    if (!hasEnabledCustomNotes) {
      console.warn(
        'Beta notes are disabled, the user should not have been able to trigger creating a note.'
      )
      return
    }

    mutate({ patientId, fromLast, saltNoteId, templateId })
  }

  const createNewNote = (patientId: string) => {
    return callCreate({
      patientId: patientId,
      fromLast: false,
      saltNoteId: undefined,
    })
  }

  const createNewFromLast = (patientId: string) => {
    return callCreate({
      patientId: patientId,
      fromLast: true,
      saltNoteId: undefined,
    })
  }
  const createNewFromId = (patientId: string, sourceNoteId: string) => {
    return callCreate({
      patientId: patientId,
      fromLast: false,
      saltNoteId: sourceNoteId,
    })
  }

  const createNewNoteFromTemplateId = ({
    patientId,
    templateId,
  }: {
    patientId: string
    templateId: string
  }) => {
    return callCreate({
      patientId: patientId,
      fromLast: false,
      templateId,
    })
  }

  return {
    createNewNoteFromTemplateId,
    createNewNote: createNewNote,
    createNewFromLast,
    createNewFromId,
    isLoading,
    isSuccess,
    isError,
  }
}
