import React, { useEffect, useState } from 'react'

import moment from 'moment'
import 'moment-timezone'
import { ButtonGroup, Col, Form, ToggleButton } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-dates/initialize'

import './DatePicker.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dates/lib/css/_datepicker.css'

const dateFormats = [{ format: 'MM/YYYY' }, { format: 'YYYY' }]

export default function ShowSuicideAttempts(props) {
  const [startDate, setStartDate] = useState(props.StartDate || '')
  const [endDate, setEndDate] = useState(props.EndDate || '')

  const [diagnosisOnsetDateFormat, setDiagnosisOnsetDateFormat] = useState(
    props.DateFormat ? props.DateFormat : 'MM/YYYY'
  )
  const [diagnosisOnsetDate, setDiagnosisOnsetDate] = useState(null)

  useEffect(() => {
    const fields = {
      startDate: startDate,
      endDate: endDate,
      dateFormat: diagnosisOnsetDateFormat,
    }
    props.saveDateRange(fields, props.index)
  }, [startDate, endDate, diagnosisOnsetDateFormat])

  function handleDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        setDiagnosisOnsetDate(date)
        let newOnsetDate = diagnosisOnsetDate
        newOnsetDate = date.toDate()
        setStartDate(newOnsetDate)
      } else {
        const newOnsetDate = moment(date, diagnosisOnsetDateFormat)
        setDiagnosisOnsetDate(newOnsetDate)
        setStartDate(date)
      }
    } else {
      setStartDate('')
    }
  }
  function handleEndDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        setDiagnosisOnsetDate(date)
        let newOnsetDate = diagnosisOnsetDate
        newOnsetDate = date.toDate()
        setEndDate(newOnsetDate)
      } else {
        const newOnsetDate = moment(date, diagnosisOnsetDateFormat)
        setDiagnosisOnsetDate(newOnsetDate)
        setEndDate(date)
      }
    } else {
      setEndDate('')
    }
  }

  return (
    <Form.Row>
      <Form.Group
        bsPrefix="center"
        as={Col}
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
      >
        {diagnosisOnsetDateFormat === 'MM/YYYY' && (
          <DatePicker
            className="date-input"
            selected={startDate === '' ? '' : new Date(startDate)}
            placeholderText={diagnosisOnsetDateFormat}
            onChange={(date) => handleDate(date)}
            maxDate={new Date()}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
        )}
        {diagnosisOnsetDateFormat === 'YYYY' && (
          <DatePicker
            className="date-input"
            selected={startDate === '' ? '' : new Date(startDate)}
            placeholderText={diagnosisOnsetDateFormat}
            onChange={(date) => handleDate(date)}
            maxDate={new Date()}
            dateFormat="yyyy"
            showYearPicker
          />
        )}
      </Form.Group>
      {props.PastUse && (
        <Form.Group
          bsPrefix="center"
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
        >
          {diagnosisOnsetDateFormat === 'MM/YYYY' && (
            <DatePicker
              className="date-input"
              selected={endDate === '' ? '' : new Date(endDate)}
              placeholderText={diagnosisOnsetDateFormat}
              onChange={(date) => handleEndDate(date)}
              maxDate={new Date()}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          )}
          {diagnosisOnsetDateFormat === 'YYYY' && (
            <DatePicker
              className="date-input"
              selected={endDate === '' ? '' : new Date(endDate)}
              placeholderText={diagnosisOnsetDateFormat}
              onChange={(date) => handleEndDate(date)}
              maxDate={new Date()}
              dateFormat="yyyy"
              showYearPicker
            />
          )}
        </Form.Group>
      )}
      <Form.Group
        bsPrefix="center"
        as={Col}
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
      >
        <ButtonGroup toggle size="sm">
          {dateFormats.map((btn, idx) => (
            <ToggleButton
              className="date-format-selector"
              variant="primary"
              key={idx}
              type="radio"
              name="radio"
              value={btn.format}
              style={{ fontSize: '12px' }}
              checked={diagnosisOnsetDateFormat === btn.format}
              onChange={(e) =>
                setDiagnosisOnsetDateFormat(e.currentTarget.value)
              }
            >
              {btn.format}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </Form.Group>
      {props.CurrentUse && (
        <Form.Group
          bsPrefix="center"
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
        />
      )}
    </Form.Row>
  )
}
