import React from 'react'

import { Button, Radio, Space } from '../BaseComponents'
import Modal, { ModalProps as BaseModalProps } from '../BaseComponents/Modal'
import { Mode } from './EventModal/EventModal'

import './Scheduling.scss'

export enum ConfirmationTypes {
  NOTIFY = 'notify',
  DISCARD = 'discard',
  DELETE = 'delete',
  RECURRING = 'recurring',
}

interface ModalProps extends BaseModalProps {
  allEventsAction?: boolean
  isThisEventUpdateVisible?: boolean
  isThisAndFollowingEventUpdateVisible?: boolean
  confirmationType: ConfirmationTypes
  onDontSend?: () => void
  onSend?: () => void
  mode?: string
  onRadioChange?: (value: ChangeEventActionType) => void
  action?: string
}

export enum ChangeEventActionType {
  THIS_EVENT = 'THIS_EVENT',
  THIS_AND_FOLLOWING_EVENT = 'THIS_AND_FOLLOWING_EVENT',
  ALL_EVENTS = 'ALL_EVENT',
}

const ConfirmationModal: React.FC<ModalProps> = ({
  allEventsAction = true,
  isThisEventUpdateVisible = true,
  isThisAndFollowingEventUpdateVisible = true,
  confirmationType,
  ...props
}) => {
  const modalProps = {
    [ConfirmationTypes.NOTIFY]: {
      title: 'Notify patient?',
      children:
        props.mode === Mode.CREATE ? (
          <>Would you like to notify the patient about this event?</>
        ) : (
          <>
            Would you like to notify the patient about the update to this event?
          </>
        ),
      footer: (
        <>
          <Button onClick={props.onCancel}>Cancel</Button>
          <Button onClick={props.onDontSend}>Don't send</Button>
          <Button onClick={props.onSend} type="primary">
            Send
          </Button>
        </>
      ),
    },
    [ConfirmationTypes.DISCARD]: {
      title: 'Discard changes?',
      okText: 'Discard',
      children: <>Any changes you have made to this event will be lost.</>,
    },
    [ConfirmationTypes.DELETE]: {
      title: 'Delete event?',
      okText: 'Delete event',
      children: <>This event will be removed from the calendar.</>,
    },
    [ConfirmationTypes.RECURRING]: {
      title: `${props.action} recurring event?`,
      children: (
        <Radio.Group
          onChange={({ target: { value } }) =>
            props.onRadioChange && props.onRadioChange(value)
          }
          defaultValue={
            isThisEventUpdateVisible
              ? ChangeEventActionType.THIS_EVENT
              : ChangeEventActionType.THIS_AND_FOLLOWING_EVENT
          }
        >
          <Space direction="vertical">
            {isThisEventUpdateVisible && (
              <Radio
                value={ChangeEventActionType.THIS_EVENT}
                key={`radio-group-this-event`}
              >
                {'This event'}
              </Radio>
            )}
            {isThisAndFollowingEventUpdateVisible && (
              <Radio
                value={ChangeEventActionType.THIS_AND_FOLLOWING_EVENT}
                key={`radio-group-this-and-following-event`}
              >
                {'This and following events'}
              </Radio>
            )}
            {allEventsAction && (
              <Radio
                value={ChangeEventActionType.ALL_EVENTS}
                key={`radio-group-all-events`}
              >
                {'All events'}
              </Radio>
            )}
          </Space>
        </Radio.Group>
      ),
      okText: `${props.action} event`,
    },
  }

  return (
    <Modal
      className="scheduling-confirmation-modal"
      closable={false}
      visible={true}
      {...modalProps[confirmationType]}
      {...props}
    />
  )
}

export default ConfirmationModal
