import React from 'react'

import { Alert as AntDAlert, AlertProps as AntDAlertProps } from 'antd'

import { TestId } from '../../shared-types'

type AlertProps = AntDAlertProps &
  TestId & { parentClassname?: string; parentStyle?: React.CSSProperties }

const Alert: React.FC<AlertProps> = ({
  testId,
  parentClassname,
  parentStyle,
  ...props
}) => {
  return (
    <div data-testid={testId} className={parentClassname} style={parentStyle}>
      <AntDAlert {...props} />
    </div>
  )
}

export default Alert
