import { format, utcToZonedTime } from 'date-fns-tz'

export const patientName = (patient: {
  firstName: string
  lastName: string
  middleName: string
  firstNameToUse?: string
  lastNameToUse?: string
  middleNameToUse?: string
}) => {
  const {
    firstName,
    firstNameToUse,
    middleName,
    middleNameToUse,
    lastName,
    lastNameToUse,
  } = patient
  const first = firstNameToUse || firstName
  const middle = middleNameToUse || middleName
  const initial = middle ? `${middle[0]}.` : ''
  const last = lastNameToUse || lastName
  return [first, initial, last].filter(Boolean).join(' ')
}

export const isDateNonStandard = (date: string | Date | number) => {
  const MM_YYYY = /(\d{1,2})\/\d{2,4}/
  return date.toString().match(MM_YYYY)
}

export const getNameInitials = (name: string) => {
  return name
    .toUpperCase()
    .split(' ')
    .map((n) => n[0])
    .join('')
}

/**
 * Returns the abbreviation of the IANA timezone code (e.g. America/Los_Angeles -> PDT).
 * The 'time' is required, because the timezone abbreviation may change depending on the time of year.
 * For example, PST vs PDT depending on whether it is daylight savings time or not.
 *
 * @param time The ISO 8601 timestamp.
 * @param timezone The desired timezone. If not specified, the local browser timezone will be used.
 */
export const getTimezoneAbbreviation = ({
  time,
  timezone,
}: {
  time: string
  timezone?: string | null
}): string => {
  // Guard against 'empty string' timezone; It can make the DateTimeFormat go crazy.
  const hasTimezone = !!timezone && timezone.length > 0

  const zonedTime = hasTimezone
    ? utcToZonedTime(new Date(time), timezone)
    : new Date(time)
  const shortTime = new Intl.DateTimeFormat('en-US', {
    timeZone: hasTimezone
      ? timezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: 'short',
  })
    .format(zonedTime)
    .split(' ')
    .pop()
  return shortTime ?? ''
}

/**
 * Builds a standardized representation of a note's "time" for consistent rendering.
 * If a timezone is provided, the time will be localized to the timezone and timezone abbreviation
 * displayed. If no timezone is provided, the time will be localized to the browser's local timezone.
 *
 * @param time The UTC ISO-8601 timestamp.
 * @param timezone An optional timezone of the note.
 */
export const getNoteTimeDisplay = ({
  time,
  timezone,
}: {
  time: string
  timezone: string | null
}): string => {
  const zonedTime = timezone
    ? // This returns the desired time for the specified timezone, but since it's a Date object,
      // the timezone of the Date object will be local browser time.
      utcToZonedTime(time, timezone)
    : // We don't have any timezone to work with; Just use local browser timezone.
      new Date(time)
  const tzAbbreviation = getTimezoneAbbreviation({ time, timezone })
  return `${format(zonedTime, 'hh:mm a')} ${tzAbbreviation}`
}

/**
 * Helper method to build a standardized date display of the note appointment date.
 */
export const getNoteAppointmentDateDisplay = (
  appointmentDate: string
): string => {
  return format(new Date(appointmentDate), 'MM/dd/yyyy')
}
