import React from 'react'

import { Col, Form } from 'react-bootstrap'

export default function FeedingEating(props) {
  if (props.patientData.NoEatingDisorder) {
    return (
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Feeding and eating:
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="NoEatingDisorder"
            label="No relevant history in this section"
            checked={JSON.parse(props.patientData.NoEatingDisorder)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NoEatingDisorder: !props.patientData.NoEatingDisorder,
              })
            }}
          />
        </Form.Group>
      </Form.Row>
    )
  }
  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Feeding and eating:
          </Form.Label>
        </Form.Group>
        {props.patientData.Anorexia.state === false &&
        props.patientData.Bulimia.state === false &&
        props.patientData.BingeEatingDisorder.state === false &&
        props.patientData.OtherEating.state === false &&
        props.patientData.EatingAdditionalNotes === '' ? (
          <Form.Group as={Col} sm={12} md={12} lg={12}>
            <Form.Check
              type="checkbox"
              id="NoEatingDisorder"
              label="No relevant history in this section"
              checked={JSON.parse(props.patientData.NoEatingDisorder)}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoEatingDisorder: !props.patientData.NoEatingDisorder,
                })
              }}
            />
          </Form.Group>
        ) : (
          ''
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Diagnoses
          </Form.Label>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.Anorexia.state ? 2 : 12}
          lg={props.patientData.Anorexia.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="Anorexia"
            label="Anorexia nervosa"
            disabled={props.patientData.NoEatingDisorder}
            checked={JSON.parse(props.patientData.Anorexia.state)}
            onChange={() => {
              props.setPatientData({
                ...props.patientData,
                Anorexia: {
                  ...props.patientData.Anorexia,
                  state: !props.patientData.Anorexia.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Anorexia.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    Anorexia: {
                      ...props.patientData.Anorexia,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.Anorexia.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.Bulimia.state ? 2 : 12}
          lg={props.patientData.Bulimia.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="Bulimia"
            label="Bulimia nervosa"
            disabled={props.patientData.NoEatingDisorder}
            checked={JSON.parse(props.patientData.Bulimia.state)}
            onChange={() => {
              props.setPatientData({
                ...props.patientData,
                Bulimia: {
                  ...props.patientData.Bulimia,
                  state: !props.patientData.Bulimia.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Bulimia.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    Bulimia: {
                      ...props.patientData.Bulimia,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.Bulimia.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.BingeEatingDisorder.state ? 2 : 12}
          lg={props.patientData.BingeEatingDisorder.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="BingeEatingDisorder"
            label="Binge-eating disorder"
            disabled={props.patientData.NoEatingDisorder}
            checked={JSON.parse(props.patientData.BingeEatingDisorder.state)}
            onChange={() => {
              props.setPatientData({
                ...props.patientData,
                BingeEatingDisorder: {
                  ...props.patientData.BingeEatingDisorder,
                  state: !props.patientData.BingeEatingDisorder.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.BingeEatingDisorder.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    BingeEatingDisorder: {
                      ...props.patientData.BingeEatingDisorder,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.BingeEatingDisorder.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.OtherEating.state ? 2 : 12}
          lg={props.patientData.OtherEating.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="OtherEating"
            label="Other"
            disabled={props.patientData.NoEatingDisorder}
            checked={JSON.parse(props.patientData.OtherEating.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                OtherEating: {
                  ...props.patientData.OtherEating,
                  state: !props.patientData.OtherEating.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OtherEating.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    OtherEating: {
                      ...props.patientData.OtherEating,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.OtherEating.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Symptoms
          </Form.Label>
        </Form.Group>
        <Form.Group
          style={{ marginTop: '0px' }}
          as={Col}
          sm={12}
          md={12}
          lg={12}
        >
          <Form.Check
            type="checkbox"
            id="RestrictingFood"
            label="Restricting food to lose weight"
            disabled={props.patientData.NoEatingDisorder}
            checked={JSON.parse(
              props.patientData.EatingSymptoms.RestrictingFood
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                EatingSymptoms: {
                  ...props.patientData.EatingSymptoms,
                  RestrictingFood:
                    !props.patientData.EatingSymptoms.RestrictingFood,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="Purging"
            label="Purging"
            disabled={props.patientData.NoEatingDisorder}
            checked={JSON.parse(props.patientData.EatingSymptoms.Purging)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                EatingSymptoms: {
                  ...props.patientData.EatingSymptoms,
                  Purging: !props.patientData.EatingSymptoms.Purging,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="BodyImage"
            label="Problems with body image"
            disabled={props.patientData.NoEatingDisorder}
            checked={JSON.parse(props.patientData.EatingSymptoms.BodyImage)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                EatingSymptoms: {
                  ...props.patientData.EatingSymptoms,
                  BodyImage: !props.patientData.EatingSymptoms.BodyImage,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="ExcessiveEating"
            label="Excessive eating"
            disabled={props.patientData.NoEatingDisorder}
            checked={JSON.parse(
              props.patientData.EatingSymptoms.ExcessiveEating
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                EatingSymptoms: {
                  ...props.patientData.EatingSymptoms,
                  ExcessiveEating:
                    !props.patientData.EatingSymptoms.ExcessiveEating,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="OtherEatingSymptoms"
            label="Other"
            disabled={props.patientData.NoEatingDisorder}
            checked={JSON.parse(props.patientData.EatingSymptoms.Other)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                EatingSymptoms: {
                  ...props.patientData.EatingSymptoms,
                  Other: !props.patientData.EatingSymptoms.Other,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.EatingSymptoms.Other && (
          <Form.Group
            className="indent"
            style={{ marginTop: '0px' }}
            as={Col}
            sm={12}
            md={12}
            lg={12}
          >
            <Form.Control
              as="textarea"
              rows={1}
              bsPrefix="form-input"
              onChange={(e) => {
                props.setPatientData({
                  ...props.patientData,
                  EatingSymptoms: {
                    ...props.patientData.EatingSymptoms,
                    OtherText: e.target.value,
                  },
                })
              }}
              value={props.patientData.EatingSymptoms.OtherText}
            />
          </Form.Group>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional notes
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            bsPrefix="form-input"
            disabled={props.patientData.NoEatingDisorder}
            onChange={(e) => {
              props.setPatientData({
                ...props.patientData,
                EatingAdditionalNotes: e.target.value,
              })
            }}
            value={props.patientData.EatingAdditionalNotes}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
