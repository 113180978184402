import { format } from 'date-fns'
import moment from 'moment'

import { PaymentsSummaryResponse } from '../../../hooks/useBillingInfo'
import { removeDangerousCSVCharacters } from '../../BaseComponents/CSVExportButton'

export const getDefaultStartMonthDate = () => {
  const currentDateTimezoneAdjusted = new Date()
  return {
    moment: moment().startOf('month'),
    js: new Date(
      currentDateTimezoneAdjusted.getFullYear(),
      currentDateTimezoneAdjusted.getMonth(),
      1
    ),
  }
}

export const validatePaymentsForCSV = (
  startDate?: Date | null,
  endDate?: Date | null,
  paymentsSummaryData?: PaymentsSummaryResponse
) => {
  const { payments = [] } = paymentsSummaryData || {}
  if (!startDate || !endDate) {
    return []
  }
  return removeDangerousCSVCharacters(payments)
}

export const getFilename = (
  startDate?: Date | null,
  endDate?: Date | null,
  paymentsSummaryData?: PaymentsSummaryResponse
) => {
  const { payments = [], practice } = paymentsSummaryData || {}
  if (payments?.length === 0 || !practice || !startDate || !endDate) {
    return ''
  }
  return `${practice.name} transactions ${format(
    startDate,
    'yyyy-M-d'
  )} to ${format(endDate, 'yyyy-M-d')}`
}
