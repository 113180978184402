import React from 'react'

import { Avatar } from 'antd'

import './_shared.scss'

interface TableAvatarProps {
  id: string
  icon: React.ReactChild
  heading: string
  subheading?: string
  description?: string
}

export default function TableAvatar({
  id,
  icon,
  heading = '',
  subheading = '',
  description = '',
}: TableAvatarProps) {
  const avatarStyle = {
    backgroundColor: '#BAE7FF',
    color: '#1890FF',
  }

  return (
    <div className="table-avatar-item">
      <Avatar
        key={id}
        style={avatarStyle}
        shape="square"
        size={40}
        icon={icon}
      />
      <div className="text-region">
        <span>
          <b>{heading}</b> {subheading}
        </span>
        <span className="description-text">{description}</span>
      </div>
    </div>
  )
}
