import { useMemo, useState } from 'react'

import { FixedType } from 'rc-table/lib/interface'

import { RefundModal } from '../../containers/Patient/Billing/RefundModal'
import { usePaymentMethods } from '../../hooks/useBillingInfo'
import {
  calculateRefundAmountCents,
  convertCentsToDollars,
  filterSuccessful,
  formatNumberStringToUsdString,
  getDisplayNameForPaymentMethodType,
} from '../../libs/billing'
import { REFUND_MODAL_MODE } from '../../libs/constants'
import { getCreatedAtContent, handlePaymentsSort } from '../../libs/utils'
import {
  BillableEntityPayment,
  InsuranceClaim,
  Invoice,
  PaymentAttempt,
  PaymentMethod,
} from '../../shared-types'
import { Card, Table } from '../BaseComponents'
import { PaymentTableItem } from '../BaseComponents/tableData.types'

import styles from './BillableEntityPayments.module.scss'

export type BillableEntityPaymentsProps = {
  isLoading: boolean
  payments?: PaymentAttempt[]
  invoiceNumber?: number
  invoiceUuid?: string
  insuranceClaimUuid?: string
  insuranceClaim?: InsuranceClaim
  patientId: string
  invoice?: Invoice
  patientControlNumber?: string
}

export const BillableEntityPayments = ({
  isLoading,
  payments,
  invoiceNumber,
  invoiceUuid,
  insuranceClaimUuid,
  insuranceClaim,
  patientId,
  invoice,
  patientControlNumber,
}: BillableEntityPaymentsProps) => {
  const { data: paymentMethodData } = usePaymentMethods(patientId)
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)
  const [paymentToRefund, setPaymentToRefund] = useState<PaymentAttempt | null>(
    null
  )

  const bePaymentMatchesEntity = (bePayment: BillableEntityPayment) => {
    if (invoiceUuid) {
      return (
        bePayment.invoiceUuid === invoiceUuid ||
        bePayment.invoiceUuid === invoice?.uuid
      )
    } else if (insuranceClaimUuid) {
      return (
        bePayment.insuranceClaimUuid === insuranceClaimUuid ||
        bePayment.insuranceClaimUuid === insuranceClaim?.uuid
      )
    } else {
      return false
    }
  }

  const getPaymentAmountForThisEntity = (payment: PaymentAttempt) => {
    const paymentAmount = payment.billableEntityPayments.reduce(
      (acc, bePayment) => {
        if (bePaymentMatchesEntity(bePayment)) {
          return acc + bePayment.amountCents
        }
        return acc
      },
      0
    )

    const refundAmount = calculateRefundAmountCents(
      payment,
      invoiceUuid,
      insuranceClaimUuid
    )

    return paymentAmount - refundAmount
  }

  const getPaymentMethodContent = (payment: PaymentAttempt) => {
    const paymentMethod = paymentMethodData?.find(
      (paymentMethod: PaymentMethod) =>
        paymentMethod.uuid === payment.paymentMethodUuid
    )
    return paymentMethod
      ? getDisplayNameForPaymentMethodType(paymentMethod.type)
      : null
  }

  const getPaymentMethodForPayment = (payment: PaymentAttempt | null) => {
    if (!payment) return
    return paymentMethodData?.find(
      (pm: PaymentMethod) => pm.uuid === payment.paymentMethodUuid
    )
  }

  const getRefundActionsContent = (payment: PaymentAttempt, index: number) => {
    return (
      <a
        data-testid={`refund-action-link-${index}`}
        className={styles.paymentActionLink}
        onClick={() => {
          setIsRefundModalOpen(true)
          setPaymentToRefund(payment)
        }}
      >
        Refund
      </a>
    )
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a: PaymentTableItem, b: PaymentTableItem) =>
        new Date(a.payment.createdAt).getTime() -
        new Date(b.payment.createdAt).getTime(),
      render: (_text: any, record: PaymentTableItem) => (
        <span>{getCreatedAtContent(record.payment.createdAt.toString())}</span>
      ),
      fixed: 'left' as FixedType,
      width: 200,
    },
    {
      title: 'Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      width: 250,
      render: (_text: string, record: PaymentTableItem) => {
        return getPaymentMethodContent(record.payment)
      },
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      key: 'memo',
      width: 250,
      render: (memo: string, paymentRecord: PaymentTableItem) => {
        const sortedRefunds = paymentRecord.payment.refundAttempts.sort(
          (a, b) => {
            return a.createdAt < b.createdAt ? 1 : -1
          }
        )
        return paymentRecord.payment.refundAttempts.length
          ? sortedRefunds[0].memo
          : paymentRecord.payment.memo
      },
    },
    {
      title: 'Payment amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      sorter: (a: PaymentTableItem, b: PaymentTableItem) => {
        return a.payment.totalAmountCents - b.payment.totalAmountCents
      },
      render: (_text: string, record: PaymentTableItem) => {
        return (
          <span>
            {formatNumberStringToUsdString(
              convertCentsToDollars(
                getPaymentAmountForThisEntity(record.payment)
              )
            )}
          </span>
        )
      },
      width: 250,
      align: 'right' as any,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right' as FixedType,
      width: 100,
      render: (_text: string, record: PaymentTableItem, index: number) => {
        return getRefundActionsContent(record.payment, index)
      },
    },
  ]

  const sortedPayments = useMemo(
    () => Array.from(payments || []).sort(handlePaymentsSort),
    [payments]
  )

  const formattedTransactionsAsDataSource = useMemo(() => {
    const transactionsAsDataSource: any = []
    sortedPayments.filter(filterSuccessful).forEach((payment, idx) => {
      const paymentAmount = getPaymentAmountForThisEntity(payment)
      const refundAmountCents = calculateRefundAmountCents(payment)
      if (
        paymentAmount === 0 ||
        refundAmountCents >= payment.totalAmountCents
      ) {
        return
      }
      const paymentData = {
        key: idx.toString(),
        payment,
      }
      transactionsAsDataSource.push(paymentData)
    })

    return transactionsAsDataSource
  }, [sortedPayments])

  const titleText = invoiceUuid
    ? `All payments for invoice #${invoiceNumber}`
    : `All payments for claim #${patientControlNumber}`

  return (
    <>
      <RefundModal
        isOpen={isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
        mode={
          invoiceUuid
            ? REFUND_MODAL_MODE.INVOICE
            : REFUND_MODAL_MODE.INSURANCE_CLAIM
        }
        invoices={invoice ? [invoice] : []}
        insuranceClaims={insuranceClaim ? [insuranceClaim] : []}
        payment={paymentToRefund}
        paymentMethod={getPaymentMethodForPayment(paymentToRefund)}
        patientId={patientId}
      />
      <Card loading={isLoading}>
        <div className={styles.sectionHeader}>{titleText}</div>
        <div>
          <Table
            scroll={{ x: 1050 }}
            showSorterTooltip={false}
            addClassNames={[
              'no-padding',
              'no-table-tools-section',
              styles.tableBorder,
            ]}
            columns={columns}
            dataSource={formattedTransactionsAsDataSource}
            pagination={false}
          />
        </div>
      </Card>
    </>
  )
}
