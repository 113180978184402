import React, { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Switch, Table } from 'antd'
import moment from 'moment'

import { getAmdConnection, getTeammateData } from '../../api/api-lib'
import { getLoggedinUserRoles, getRoles } from '../../api/permissions'
import { onError } from '../../libs/errorLib'
import { useFeatureFlags } from '../../libs/featureFlags'
import { RoleTitles, Teammate } from '../../shared-types'
import { Card, Space } from '../../stories/BaseComponents'
import NewTeammateModal from './NewTeammateModal'
import ProviderActions from './ProviderActions'
import TeamManagementDetailsPanel from './TeamManagementDetailsPanel'
import TeamRolesModal from './TeamRolesModal'
import {
  fakeActiveTeammate,
  fakeDeavtivatedTeammate,
} from './TeamSettingsStoriesConstants'

import sharedStyles from '../../containers/_shared.module.scss'
import styles from './TeamSettings.module.scss'

interface Props {
  masterProviderId: string
  loggedInUserCognitoId: string
  storybook?: boolean
}

export interface TeammateActionInfo {
  email: string
  cognitoId: string
}

const TeamSettings: React.FC<Props> = ({
  masterProviderId,
  loggedInUserCognitoId,
  storybook = false,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isShowDeactivate, setShowDeactivate] = useState<boolean>(false)
  const [users, setUsers] = useState<Teammate[]>([])
  const [activeUsers, setActiveUsers] = useState<Teammate[]>([])
  const [deactivatedUsers, setDeactivatedUsers] = useState<Teammate[]>([])
  const [refresh, setRefresh] = useState<boolean>(false)
  const [selectedTeammate, setSelectedTeammate] = useState<Teammate>()
  const [allRoles, setAllRoles] = useState<RoleTitles[]>([])
  const [canSeeAllPatientsRoleId, setcanSeeAllPatientsRoleId] =
    useState<number>()
  const [detailsSliderOpen, setDetailsSliderOpen] = useState<boolean>(false)
  const [dynamicText, setDynamicText] = useState<string>('')

  const [teammateForAction, setTeammateForAction] = useState<
    TeammateActionInfo | undefined
  >()
  const { advancedmd } = useFeatureFlags()
  let amdSettings = { username: false }

  const query = useQuery(['amd-settings'], () => getAmdConnection(), {
    enabled: advancedmd,
  })

  if (!storybook) {
    amdSettings = query.data
  }

  const showAmd = advancedmd && amdSettings && !!amdSettings.username

  async function downloadTeammateData() {
    try {
      setLoading(true)
      setUsers([])
      const teammateData = await getTeammateData()
      setUsers(teammateData)
      setActiveUsers(
        teammateData.length !== 0
          ? teammateData.filter((user: Teammate) => !user.isDeactivated)
          : []
      )
      setDeactivatedUsers(
        teammateData.length !== 0
          ? teammateData.filter((user: Teammate) => user.isDeactivated)
          : []
      )
      setLoading(false)
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
  }

  useEffect(() => {
    if (storybook) {
      setUsers(fakeActiveTeammate.concat(fakeDeavtivatedTeammate))
      setActiveUsers(fakeActiveTeammate)
      setDeactivatedUsers(fakeDeavtivatedTeammate)
      return
    }
    async function getCurrentUserRoles() {
      try {
        let cRoles: RoleTitles[] = await getLoggedinUserRoles()
        cRoles = cRoles.filter((role) => role.label !== 'View all patients')

        const rolesStringArray = cRoles.map((cR) => {
          if (cR.label === 'Super-Admin') {
            return 'Super admin'
          }
          return cR.label
        })

        if (!rolesStringArray.length) return
        const initialString =
          rolesStringArray[0] === 'Admin' ? 'You are an' : 'You are a'
        if (rolesStringArray.length > 1) {
          const lastItem = rolesStringArray.slice(-1)
          const allButLastItem = rolesStringArray.slice(0, -1)
          setDynamicText(
            `${initialString} ${allButLastItem.join(', ')} and ${
              lastItem[0]
            } on this account. `
          )
        } else {
          setDynamicText(
            `${initialString} ${rolesStringArray[0]} on this account. `
          )
        }
      } catch (e) {
        onError(
          e,
          500,
          'There was an internal error processing your request. Please inform your administrator.'
        )
      }
    }

    downloadTeammateData()
    getCurrentUserRoles()
  }, [refresh])

  useEffect(() => {
    async function getMainRoles() {
      const rolesFound = await getRoles()
      const rolesOptions = []
      for (const r of rolesFound) {
        if (r.title === 'View all patients') {
          setcanSeeAllPatientsRoleId(r.roleId)
        } else {
          rolesOptions.push({
            value: r.roleId,
            label: r.title,
          })
        }
      }
      setAllRoles(rolesOptions)
    }
    getMainRoles()
  }, [])

  const refreshTeammates = () => {
    setRefresh(!refresh)
  }

  const hideDetails = () => {
    setDetailsSliderOpen(false)
    setSelectedTeammate(undefined)
  }

  function showDetails(record: Teammate) {
    // If details panel is already open, then close it
    if (selectedTeammate?.cognitoId === record.cognitoId && detailsSliderOpen) {
      hideDetails()
      return
    }
    setSelectedTeammate(record)
    setDetailsSliderOpen(true)
  }

  function refreshSelectedTeamate(providerId: string) {
    refreshTeammates()
    if (detailsSliderOpen) {
      const selectedUser = users.find((user) => user.cognitoId === providerId)
      setSelectedTeammate(selectedUser)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => (
        <Space size="middle">
          {!record.isDeactivated ? (
            <span>{record.name}</span>
          ) : (
            <span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>{record.name}</span>
          )}
        </Space>
      ),
      onCell: (record: any) => {
        return {
          onClick: () => {
            showDetails(record)
          },
        }
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: any, record: any) => (
        <Space size="middle">
          {!record.isDeactivated ? (
            <span>{record.email}</span>
          ) : (
            <span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>{record.email}</span>
          )}
        </Space>
      ),
      onCell: (record: any) => {
        return {
          onClick: () => {
            showDetails(record)
          },
        }
      },
    },
    {
      title: 'Role ',
      dataIndex: 'roleTitles',
      key: 'roleTitles',
      render: (text: any, record: Teammate) => {
        return (
          <Space size="middle">
            <TeamRolesModal
              masterProviderId={masterProviderId}
              loggedInUserCognitoId={loggedInUserCognitoId}
              teammates={users}
              record={record}
              allRoles={allRoles}
              refreshSelectedTeamate={refreshSelectedTeamate}
              storybook={storybook}
              refresh={refresh}
            />
          </Space>
        )
      },
      onCell: () => {
        return {
          onClick: () => {
            setDetailsSliderOpen(false)
          },
        }
      },
    },
    {
      title: 'Last Active',
      dataIndex: 'lastActive',
      key: 'lastActive',
      render: (text: any, record: any) => {
        const style = record.isDeactivated
          ? { color: 'rgba(0, 0, 0, 0.25)' }
          : {}
        const lastActiveText =
          record.lastActive === 'never_logged_in'
            ? 'Never logged in'
            : moment(record.lastActive).format('MMM DD, YYYY h:mma')

        return (
          <Space size="middle">
            <span style={style}>{lastActiveText}</span>
          </Space>
        )
      },
      onCell: (record: any) => {
        return {
          onClick: () => {
            showDetails(record)
          },
        }
      },
    },
    {
      key: 'resendInvite',
      render: (text: any, record: any) => {
        return (
          record.lastActive === 'never_logged_in' && (
            <ProviderActions
              storyBook={false}
              masterProviderId={masterProviderId}
              provider={record}
              refresh={refresh}
              isResendInviteAction={true}
              setRefresh={setRefresh}
              teammateForAction={teammateForAction}
              setTeammate={setTeammateForAction}
              closeSlider={hideDetails}
            />
          )
        )
      },
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, record: any) => {
        return (
          <ProviderActions
            storyBook={false}
            masterProviderId={masterProviderId}
            provider={record}
            isResendInviteAction={false}
            refresh={refresh}
            setRefresh={setRefresh}
            teammateForAction={teammateForAction}
            setTeammate={setTeammateForAction}
            closeSlider={hideDetails}
          />
        )
      },
    },
  ]

  const data = React.useMemo(() => {
    const activeList = activeUsers.map((user, index) => {
      return {
        key: `${index}`,
        name: user.name,
        email: user.email,
        phone: user.phone,
        billingNPI: user.billingNPI,
        erxUserId: user.erxUserId,
        lastActive: user.lastActive,
        cognitoId: user.cognitoId,
        isPrimaryUser: user.isPrimaryUser,
        isDeactivated: user.isDeactivated,
        credential: user.credential,
        amdProfileId: user.amdProfileId,
        roleTitles: user.roleTitles,
        roles: user.roles,
      }
    })

    if (isShowDeactivate) {
      const deactiveList = deactivatedUsers.map((user, index) => {
        return {
          key: `${index}`,
          name: user.name,
          email: user.email,
          phone: user.phone,
          billingNPI: user.billingNPI,
          erxUserId: user.erxUserId,
          lastActive: user.lastActive,
          cognitoId: user.cognitoId,
          isPrimaryUser: user.isPrimaryUser,
          isDeactivated: user.isDeactivated,
          credential: user.credential,
          amdProfileId: user.amdProfileId,
          roleTitles: user.roleTitles,
          roles: user.roles,
        }
      })

      activeList.push(...deactiveList)
    }

    return activeList
  }, [activeUsers, deactivatedUsers, isShowDeactivate])

  return (
    <div className={sharedStyles.scroll}>
      <div className={sharedStyles.spacedContainer}>
        <Card bordered={false}>
          <div className="headerWrapper headerWrapper_row">
            <span className="headerWrapper_title">Team management</span>
            <div className={styles.tableHeaderContainer_right}>
              <span className="activate-switch-title">Show deactivated: </span>
              <Switch
                className="activate-switch-switch"
                checked={isShowDeactivate}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                onChange={() => setShowDeactivate(!isShowDeactivate)}
              />
              <NewTeammateModal
                masterProviderId={masterProviderId}
                users={users}
                refresh={refreshTeammates}
                storybook={storybook}
              />
            </div>
          </div>
          <div id={styles.supportArticleContainer}>
            <span>{dynamicText}</span>
            <a
              href="https://support.osmind.org/en/articles/6446843-user-roles-permissions"
              target="_blank"
            >
              Read the roles and permissions support article.
            </a>
          </div>
          <div id={styles.tableSliderContainer}>
            <div id={styles.tableContainer}>
              <Table
                size="large"
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={isLoading}
                rowClassName={(record) =>
                  record.cognitoId === selectedTeammate?.cognitoId
                    ? `${styles.tableRowLightBlue}`
                    : styles.tableRowWhite
                }
              />
            </div>
            {detailsSliderOpen && selectedTeammate && (
              <div id={styles.detailsSlider}>
                <TeamManagementDetailsPanel
                  storyBook={storybook}
                  teammate={selectedTeammate as Teammate}
                  closeSlider={hideDetails}
                  advancemdEnabledOnClinic={showAmd}
                  setTeammate={setTeammateForAction}
                  canSeeAllPatientsRoleId={canSeeAllPatientsRoleId}
                  refreshTeammates={downloadTeammateData}
                />
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  )
}

export default TeamSettings
