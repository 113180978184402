import {
  submitNewGeneralHistoryRequest,
  updatePatientDemographicsErx,
  updatePatientEmail,
} from '../../api/api-lib'
import { updatePatientDemographics } from '../../api/patientInfo'
import { RCopia } from '../../containers/Patient/General/types'
import { onError } from '../../libs/errorLib'
import { DemographicsRequestOrigin } from '../../shared-types'
import { UpdateSendbirdUserFn } from '../../v2/messaging/UnreadMessageCounts/types'
import { DemographicsContactInfo, PatientInfo } from './shared-types'
import { validateDemographics } from './validators'

/**
 * 1. Updates patient profile on Osmind
 * 2. Updates cognito account (if email is changed)
 * 2. Updates ERX (if patient is activated)
 * 3. Updates SendBird channel
 *
 * Should move logic to backend
 */
export const updatePatientDemographicsHandler = async (
  patientId: string,
  formData: DemographicsContactInfo,
  newValueId: string,
  updateSendbirdUser: UpdateSendbirdUserFn,
  newValueLabel?: string,
  patientInfo?: PatientInfo
) => {
  await validateDemographics(newValueId, formData)

  const updateData = {
    patientId,
    firstName: formData.firstName,
    middleName: formData.middleName,
    lastName: formData.lastName,
    firstNameToUse: formData.firstNameToUse,
    middleNameToUse: formData.middleNameToUse,
    lastNameToUse: formData.lastNameToUse,
    formerFirstName: formData.formerFirstName,
    formerMiddleName: formData.formerMiddleName,
    formerLastName: formData.formerLastName,
    birthSex: formData.birthSex,
    legalSex: formData.legalSex,
    pronouns: formData.pronouns,
    genderIdentity:
      formData.genderIdentity === '' ? null : formData.genderIdentity,
    race: formData.race,
    raceSubcategory: formData.raceSubcategory,
    ethnicity: formData.ethnicity === '' ? null : formData.ethnicity,
    specificEthnicity: formData.specificEthnicity,
    notes: formData.notes,
    dateOfBirth: formData.DateOfBirth,
    summary: formData.summary,
    updateOrigin: DemographicsRequestOrigin.SIDE_PANEL,
  }

  // Create PatientInfo record if one doesn't exist yet (legacy)
  if (!patientInfo) {
    await submitNewGeneralHistoryRequest(updateData)
    return formData
  }

  if (
    process.env.REACT_APP_STAGE !== 'local' &&
    (patientInfo.firstName !== formData.firstName ||
      patientInfo.middleName !== formData.middleName ||
      patientInfo.lastName !== formData.lastName)
  ) {
    // Updates user's name on Sendbird
    const newPatientName = `${formData.firstName ?? ''}${
      !formData.middleName ? '' : ` ${formData.middleName}`
    } ${formData.lastName ?? ''}`
    updateSendbirdUser({
      patientId,
      patientName: newPatientName,
      channelUrl: patientInfo.ChannelUrl ?? '',
    })
  }

  // Update patient demographics on serverless
  try {
    if (patientInfo.email !== formData.email) {
      await updatePatientEmail(
        patientInfo.CognitoId,
        patientInfo.email,
        formData.email
      )
    }
    await updatePatientDemographics(updateData)
  } catch (error: any) {
    if (
      error.response?.data?.error ===
      'An account with the given email already exists'
    ) {
      throw new Error(
        'An account with that email address already exists. Please use a different email address.'
      )
    } else {
      throw new Error(
        `There was a problem updating ${newValueLabel}. Please try again or contact support if this continues to happen.`
      )
    }
  }
  const hasChangedErxInfo =
    newValueId.trim() === 'firstName' ||
    newValueId.trim() === 'middleName' ||
    newValueId.trim() === 'lastName' ||
    newValueId.trim() === 'DateOfBirth' ||
    newValueId.trim() === 'legalSex'
  // if this patient has been onboarded with RCopia, update their RCopia patient demographics as well
  // with any potential updates made to their name, DOB and gender. Eventually we will incorpate address here
  // as well once we are storing the address as Line, City, State, Zip in our database
  if (patientInfo.PatientRCopiaId && hasChangedErxInfo) {
    try {
      const updateRCopiaData = {
        patientPublicId: patientInfo.PublicId,
        PatientFirstName: formData.firstName,
        PatientMiddleName: formData.middleName,
        PatientLastName: formData.lastName,
        DateOfBirth: formData.DateOfBirth,
        // Note: ERX does not support option "X" from the new
        // patient profile. It will automatically default to
        // "Unknown" in ERX portal
        PatientGender: formData.legalSex,
      } as RCopia
      await updatePatientDemographicsErx(updateRCopiaData)
    } catch (e) {
      onError(
        e,
        500,
        `There was a problem updating ${newValueLabel}. Please try again or contact support if this continues to happen.`
      )
    }
  }

  return formData
}
