import { useParams } from 'react-router-dom'

import { useAllergySnapshot } from '../../../../../hooks/useAllergyHistory'
import { generateCoreIdDisplayName } from '../../../../../hooks/usePatientProfile/utils'
import { useProfileUrlParams } from '../../../../../hooks/useProfileUrlParams'
import { useNote } from '../../../hooks'
import { AllergiesBlockTable } from '../BlockTypeRenderer/types/allergies/AllergiesBlockTable'

export const ActiveAllergiesBlock = ({ isSigned }: { isSigned?: boolean }) => {
  const { noteId } = useParams() as { noteId: string }
  const { patientId } = useProfileUrlParams() as { patientId: string }
  const { note, isLoading: isNoteLoading } = useNote(noteId)
  const { data: allergies, isLoading } = useAllergySnapshot({
    patientId,
    timestamp: note?.firstSignedAt,
    options: {
      status: 'Active',
    },
    enabled: !!note,
  })

  const patientName = note?.patient && generateCoreIdDisplayName(note?.patient)

  return (
    <AllergiesBlockTable
      blockData={allergies}
      isLoading={isLoading || isNoteLoading}
      patientName={patientName}
      isSigned={isSigned ?? false}
    />
  )
}
