import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { endOfDay } from 'date-fns'

import { getNotesCount } from '../../api/notes'
import { useFeatureFlags } from '../../libs/featureFlags'
import { NoteTypes } from '../../shared-types'
import { getReportInitialDateRange } from '../../stories/Reports/ReportPage'

export const useUnsignedSpravatoNotifications = () => {
  const { unsignedSpravatoNotificationsRefetchInterval: refetchInterval } =
    useFeatureFlags()

  const {
    data: notesResponse,
    isLoading,
    isError,
  } = useQuery(
    ['notesByDateRangeList'],
    () => {
      // Start date for notifications defaults to the same range
      // used by the default view of the reports.
      const { startDate } = getReportInitialDateRange()
      // End date for notifications is always 'today'. The report initial
      // range goes to the end of the month, but for unsigned notes we never want
      // to notify for any 'future' notes.
      const endDate = endOfDay(new Date())
      return getNotesCount({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        noteType: NoteTypes.SPRAVATO,
        signed: false,
      })
    },
    {
      refetchInterval,
      // Allow disabling the API query via our feature flag.
      enabled: refetchInterval > 0,
    }
  )

  const totalUnreadSpravatoNotes: number = useMemo(() => {
    if (
      isLoading ||
      isError ||
      !notesResponse ||
      // Return 'no notifications' if the feature flag is disabled. This
      // prevents any cached values from being re-used if the feature flag
      // is turned off in the middle of someone's session.
      refetchInterval === 0
    ) {
      return 0
    }

    return notesResponse.count
  }, [notesResponse, isLoading, isError, refetchInterval])

  return {
    totalUnreadSpravatoNotes,
  }
}
