import { useMemo } from 'react'

import { Addendum, Signature } from '../../types'
import { NoteDateTimeDisplay } from '../NoteDateTimeDisplay'
import { Typography } from './../../../../stories/BaseComponents'
import { Row } from './CoreData/Row'

import styles from './SignatureHeader.module.scss'

export type SignatureHeaderProps = {
  signatures: Signature[]
  addendums: Addendum[]
  scrollToAddendums?: () => void
  scrollToSignatures?: () => void
  printMode?: boolean
  timezone: string | null
}

export const SignatureHeader = ({
  signatures,
  addendums,
  scrollToSignatures,
  scrollToAddendums,
  printMode = false,
  timezone,
}: SignatureHeaderProps) => {
  const { Text, Link } = Typography
  // Memoize the rendering logic for signatures to prevent unnecessary re-renders
  const renderedSignatures = useMemo(() => {
    if (!Array.isArray(signatures) || signatures.length === 0) {
      return null
    }

    if (printMode) {
      return signatures.map((signature, i) => {
        return (
          <div key={`signature-content-${i}`} className={styles.signature}>
            <Text>{signature.providerName}</Text>
            <br />
            <Text>
              <NoteDateTimeDisplay
                timestamp={signature.signedAt}
                timezone={timezone}
              />
            </Text>
          </div>
        )
      })
    }

    return signatures.map((signature, i) => {
      return (
        <div key={`signature-content-${i}`} className={styles.signature}>
          <Link onClick={scrollToSignatures}>{signature.providerName}</Link>
          <br />
          <Text className={styles.text}>
            <NoteDateTimeDisplay
              timestamp={signature.signedAt}
              timezone={timezone}
            />
          </Text>
        </div>
      )
    })
  }, [signatures])

  if (!signatures || signatures.length === 0) {
    return <></>
  }

  const buildTestId = (testIdLabel: string) =>
    // Prepend 'print-' onto the testId so we can uniquely target elements in tests.
    printMode ? `print-${testIdLabel}` : testIdLabel

  return (
    <div className={styles.signaturesHeader}>
      <div className={styles.tableWrapper}>
        <Row
          label="Electronically signed by"
          content={
            <div className={styles.nonEditableField}>{renderedSignatures}</div>
          }
          testIdPrefix={buildTestId('signed')}
        />
        {addendums.length > 0 && (
          <Row
            label="Addendums added"
            content={
              <div className={styles.nonEditableField}>
                <Link onClick={scrollToAddendums}>
                  {addendums.length}{' '}
                  {addendums.length === 1 ? 'addendum' : 'addendums'}
                </Link>
              </div>
            }
            testIdPrefix={buildTestId('addendum')}
          />
        )}
      </div>
    </div>
  )
}
