import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import {
  createInternalRefund,
  getPaymentLockStatus,
  refundStripePayment,
  setPaymentLockStatus,
} from '../../../../api/api-lib'
import { QueryKeys as BillingQueryKeys } from '../../../../hooks/useBillingInfo'
import { QueryKeys as InsuranceClaimsQueryKeys } from '../../../../hooks/useInsuranceClaims'
import { QueryKeys as PatientProfileQueryKeys } from '../../../../hooks/usePatientProfile'
import {
  calculateMaxRefundAmountFromPayment,
  calculateRefundAmountCents,
  convertCentsToDollars,
  convertCentsToDollarsNumber,
  formatNumberStringToUsdString,
} from '../../../../libs/billing'
import {
  EXTERNAL_SERVICES,
  REFUND_MODAL_MODE,
} from '../../../../libs/constants'
import { notification } from '../../../../libs/notificationLib'
import {
  BillableEntityPayment,
  InsuranceClaim,
  Invoice,
  PaymentAttempt,
  PaymentMethod,
  PaymentStatus,
} from '../../../../shared-types'
import { paymentDataLockedModal } from '../../../../shared/Payments/PaymentLockModal'
import {
  Alert,
  Divider,
  Input,
  Modal,
  Text,
} from '../../../../stories/BaseComponents'
import { USDInput } from '../../../../stories/BaseComponents/EditableInputs'
import { getPaymentMethodDisplayContent } from '../Billing'

import classes from './RefundModal.module.scss'

export interface RefundModalProps {
  isOpen: boolean
  onClose(): void
  paymentMethod: PaymentMethod | null | undefined
  payment: PaymentAttempt | null
  patientId: string
  mode: REFUND_MODAL_MODE
  invoices: Array<Invoice>
  insuranceClaims: Array<InsuranceClaim>
}

export interface BillableEntityPaymentData {
  invoiceUuid: string
  amountCents: number
}

const MINIMUM_REFUND_AMOUNT_CENTS = 100

const getUsdInputError = (
  refundAmountCents: number,
  maxRefundAmount: number
) => {
  if (refundAmountCents > maxRefundAmount) {
    return `Refund amount cannot exceed
    ${formatNumberStringToUsdString(convertCentsToDollars(maxRefundAmount))}.`
  }

  if (refundAmountCents < MINIMUM_REFUND_AMOUNT_CENTS) {
    return `Refund amount cannot be less than
    ${formatNumberStringToUsdString(
      convertCentsToDollars(MINIMUM_REFUND_AMOUNT_CENTS)
    )}.`
  }

  return ''
}

export const RefundModal = ({
  isOpen,
  onClose,
  payment,
  paymentMethod,
  patientId,
  mode,
  invoices,
  insuranceClaims,
}: RefundModalProps) => {
  const queryClient = useQueryClient()
  const refreshPaymentData = () => {
    queryClient.invalidateQueries([BillingQueryKeys.INVOICES])
    queryClient.invalidateQueries([BillingQueryKeys.TRANSACTIONS])
    queryClient.invalidateQueries([
      PatientProfileQueryKeys.PATIENT_INSURANCE_PAYMENTS,
    ])
    queryClient.invalidateQueries([InsuranceClaimsQueryKeys.INSURANCE_CLAIMS])
  }
  const maxRefundAmount: number = useMemo(() => {
    if (!payment || !invoices) return 0
    let maxRefundAmountCents = 0
    let relevantBillableEntityPayments: Array<BillableEntityPayment> = []
    let invoice: Invoice | null = null
    let insuranceClaim: InsuranceClaim | null = null
    let claimRefundAmountCents = 0
    let invoiceRefundAmountCents = 0
    switch (mode) {
      case REFUND_MODAL_MODE.INVOICE:
        invoice = invoices[0]

        // in theory this should not happen. we should only reach this block if at least one billableEntityPayment exists
        // this check prevents a total bork and will block the refund as it is $0
        if (!payment.billableEntityPayments) return 0
        if (!invoice) return 0

        // find the invoice payments. this will include refunds and payments applied to the invoice
        relevantBillableEntityPayments = payment.billableEntityPayments.filter(
          (ip: BillableEntityPayment) => ip.invoiceUuid === invoice?.uuid
        )
        relevantBillableEntityPayments.forEach((ip: BillableEntityPayment) => {
          maxRefundAmountCents += ip.amountCents
        })

        // Subtract total refunds from initial payment amount
        invoiceRefundAmountCents = calculateRefundAmountCents(
          payment,
          invoice.uuid,
          null
        )
        maxRefundAmountCents -= invoiceRefundAmountCents
        break
      case REFUND_MODAL_MODE.INSURANCE_CLAIM:
        insuranceClaim = insuranceClaims[0]

        // in theory this should not happen. we should only reach this block if at least one billableEntityPayment exists
        // this check prevents a total bork and will block the refund as it is $0
        if (!payment.billableEntityPayments) return 0
        if (!insuranceClaim) return 0

        // find the insurance claim payments. this will include refunds and payments applied to the invoice
        relevantBillableEntityPayments = payment.billableEntityPayments.filter(
          (icp: BillableEntityPayment) =>
            icp.insuranceClaimUuid === insuranceClaim?.uuid
        )
        relevantBillableEntityPayments.forEach((icp: BillableEntityPayment) => {
          maxRefundAmountCents += icp.amountCents
        })

        // Subtract total refunds from initial payment amount
        claimRefundAmountCents = calculateRefundAmountCents(
          payment,
          null,
          insuranceClaim.uuid
        )
        maxRefundAmountCents -= claimRefundAmountCents
        break
      case REFUND_MODAL_MODE.PAYMENT:
        maxRefundAmountCents = payment.totalAmountCents

        if (!payment.refundAttempts) return maxRefundAmountCents

        payment.refundAttempts.forEach((refund) => {
          if (refund.status === PaymentStatus.SUCCESSFUL) {
            maxRefundAmountCents -= refund.totalAmountCents
          }
        })
        break
    }

    return maxRefundAmountCents
  }, [payment, invoices, insuranceClaims, mode])

  const [refundAmountCents, setRefundAmountCents] = useState<number>(
    payment ? payment.totalAmountCents : 0
  )
  const [memoValue, setMemoValue] = useState('')
  const [isEditingRefundAmount, setIsEditingRefundAmount] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  useEffect(() => {
    setRefundAmountCents(maxRefundAmount)
  }, [payment, invoices, insuranceClaims])

  const getAttributedEntityContent = () => {
    if (invoices.length + insuranceClaims.length === 0) return 'None'

    let content = ''
    invoices.forEach((invoice: Invoice, idx: number) => {
      content += `#${invoice.invoiceNumber}`
      if (idx < invoices.length - 1) {
        content += ', '
      }
    })

    if (insuranceClaims.length === 0) return content

    if (invoices.length > 0) {
      content += ', '
    }

    insuranceClaims.forEach((claim: InsuranceClaim, idx: number) => {
      content += `#${claim.patientControlNumber}`
      if (idx < insuranceClaims.length - 1) {
        content += ', '
      }
    })

    return content
  }

  const handleRefundValueChange = (value: number | string | null) => {
    if (!value) {
      setRefundAmountCents(0)
      return
    }
    // TODO: Refactor this once value is only a number or only a string
    let refValue = 0
    if (typeof value === 'string') {
      refValue = Math.round(parseFloat(value) * 100)
    } else {
      refValue = value * 100
    }
    setRefundAmountCents(refValue)
  }

  const handleRefundValueBlur = () => {
    setIsEditingRefundAmount(false)
  }

  const handleMemoValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMemoValue(e.target.value)
  }

  const handleClose = () => {
    onClose()
    setMemoValue('')
    setIsSubmitting(false)
  }

  const handleRefundSubmit = async () => {
    setIsSubmitting(true)
    const lockStatusResponse = await getPaymentLockStatus(patientId)
    if (lockStatusResponse.isLocked) {
      paymentDataLockedModal()
      setIsSubmitting(false)
      return
    }

    await setPaymentLockStatus(
      patientId,
      true,
      'RefundModal handleRefundSubmit start'
    )
    const invoiceUuid = invoices.length === 1 ? invoices[0].uuid : null
    const insuranceClaimUuid =
      insuranceClaims.length === 1 ? insuranceClaims[0].uuid : null
    const data = {
      paymentAttemptUuid: payment?.uuid,
      totalAmountCents: refundAmountCents,
      patientId,
      memo: memoValue,
      refundPath: mode.toLowerCase(),
      invoiceUuid,
      insuranceClaimUuid,
    }

    try {
      if (paymentMethod?.externalService === EXTERNAL_SERVICES.STRIPE) {
        await refundStripePayment(data)
      } else {
        await createInternalRefund(data)
      }
      refreshPaymentData()
      if (insuranceClaimUuid) {
        queryClient.invalidateQueries([
          PatientProfileQueryKeys.GET_CLAIM_INFO + insuranceClaimUuid,
        ])
      }
      notification(
        `Successfully refunded $${convertCentsToDollars(refundAmountCents)}.`
      )
      await setPaymentLockStatus(
        patientId,
        false,
        'RefundModal handleRefundSubmit success'
      )
      handleClose()
      setIsConfirmOpen(false)
    } catch (e) {
      console.log('Error', e)
      await setPaymentLockStatus(
        patientId,
        false,
        'RefundModal handleRefundSubmit failure'
      )
      notification(
        'Something went wrong. Refund could not be performed. Please contact Osmind.'
      )
      setIsSubmitting(false)
      setIsConfirmOpen(false)
    }
  }

  const refundExceedsMaxAmount = refundAmountCents > maxRefundAmount
  const refundBelowMinimum = refundAmountCents < MINIMUM_REFUND_AMOUNT_CENTS

  const isSubmitDisabled =
    refundBelowMinimum ||
    refundExceedsMaxAmount ||
    isEditingRefundAmount ||
    isSubmitting

  const usdInputError = getUsdInputError(refundAmountCents, maxRefundAmount)

  const getPartialRefundAlertContent = () => {
    if (invoices.length + insuranceClaims.length <= 1) return null

    const description = `Partial refunds are not enabled for payments made across multiple invoices and/or claims. Make a refund at the invoice or claim level for a partial refund.`
    return (
      <Alert
        className={classes.alertMessage}
        description={description}
        type="info"
        showIcon
      />
    )
  }

  const getEntityName = () => {
    switch (mode) {
      case REFUND_MODAL_MODE.INVOICE:
        return 'Invoice'
      case REFUND_MODAL_MODE.INSURANCE_CLAIM:
        return 'Claim'
      case REFUND_MODAL_MODE.PAYMENT:
        if (insuranceClaims.length > 0 && invoices.length > 0)
          return 'Invoice or claim'
        if (insuranceClaims.length > 0) return 'Claim'
        return 'Invoice'
    }
  }

  return (
    <>
      <Modal
        visible={isConfirmOpen}
        cancelText="Cancel"
        okText="Refund Payment"
        onOk={handleRefundSubmit}
        isOkayDisabled={isSubmitDisabled}
        onCancel={() => setIsConfirmOpen(false)}
        destroyOnClose={true}
        cancelButtonProps={{ disabled: isSubmitting }}
        closable={!isSubmitting}
        maskClosable={!isSubmitting}
        okButtonProps={{ className: 'refund-confirm-submit-button' }}
      >
        <div className={classes.confirmModalHeader}>Refund payment?</div>
        <div className={classes.confirmModalContent}>
          If you refund this payment, the associated invoice(s) and/or claim(s)
          will be marked unpaid. Are you sure you want to refund this payment?
        </div>
      </Modal>
      <Modal
        visible={isOpen}
        okText="Refund"
        onCancel={handleClose}
        onOk={() => setIsConfirmOpen(true)}
        isOkayDisabled={isSubmitDisabled}
        destroyOnClose={true}
        cancelButtonProps={{
          disabled: isSubmitting,
          className: 'refund-cancel-button',
        }}
        okButtonProps={{ className: 'refund-submit-button' }}
        closable={!isSubmitting}
        maskClosable={!isSubmitting}
      >
        {!payment || !paymentMethod ? (
          <div>
            Cannot render refund. No payment or payment method provided.
          </div>
        ) : (
          <>
            <Text className={classes.title}>Refund</Text>
            <Divider />
            {getPartialRefundAlertContent()}
            <div className={classes.container}>
              <div className={classes.leftColumn}>
                <Text data-testid="billable-entity-name">
                  {getEntityName()}:
                </Text>
                <Text>Paid by:</Text>
                <div className={classes.inputText}>
                  <Text>Refund Amount:</Text>
                </div>
                <div className={classes.inputText}>
                  <Text>Memo:</Text>
                </div>
              </div>
              <div className={classes.rightColumn}>
                <Text data-testid="billable-entity-identifier">
                  {getAttributedEntityContent()}
                </Text>
                <div
                  className={classes.refundContainer}
                  data-testid="payment-method-content"
                >
                  {getPaymentMethodDisplayContent(paymentMethod)}
                </div>
                {invoices.length + insuranceClaims.length > 1 ? (
                  <span
                    className={classes.alignCenter}
                    data-testid="max-refund-amount"
                  >
                    $
                    {convertCentsToDollars(
                      calculateMaxRefundAmountFromPayment(payment)
                    )}
                  </span>
                ) : (
                  <div className={classes.refundInputContainer}>
                    <USDInput
                      onChange={handleRefundValueChange}
                      onBlur={handleRefundValueBlur}
                      value={convertCentsToDollarsNumber(refundAmountCents)}
                      onFocus={() => setIsEditingRefundAmount(true)}
                      error={usdInputError}
                      testId="refund-amount-input"
                    />
                  </div>
                )}
                <Input
                  value={memoValue}
                  onChange={handleMemoValueChange}
                  data-testid="refund-memo"
                />
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
