import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const nonConformingGender: PatientDemographicObject[] = [
  {
    'Non-Binary': {
      title: 'Non-Binary/Genderqueer/Gender Non-Conforming',
      description: '',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
  {
    Pangender: {
      title: 'Pangender',
      description: '',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
  {
    Agender: {
      title: 'Agender',
      description: '',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
]

export const nonConformingGenderDropDownValues =
  constructPatientDemographicObject(
    nonConformingGender,
    CodeSystemOIDs.OSMINDCustomCodeSystem,
    Categories.NON_CONFORMING_GENDER_SUB_GENDER_IDENTITY
  )
