import React, { ChangeEvent } from 'react'

import { Col, Form } from 'react-bootstrap'

import { formatPhoneNumber } from '../../../../shared/Helpers/utils'
import { DefaultEditFieldProps } from '../DefaultFields/types'

export default function PhoneEditField(props: DefaultEditFieldProps) {
  const { templateField, handleFieldChange, styling, value } = props

  const {
    columnSizes,
    fieldType = 'text',
    id,
    label,
    placeholder = '',
  } = templateField

  const {
    editColBsPrefix = '',
    editColClassName = '',
    editColStyle = {},
    editValueBsPrefix = '',
    editValueClassName = '',
    editValueStyle = {},
    editLabelBsPrefix = '',
    editLabelClassName = '',
    editLabelStyle = {},
  } = styling

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    let cleanValue = e.target.value
    cleanValue = formatPhoneNumber(cleanValue)
    cleanValue = cleanValue.replace(/\D+/g, '')
    e.target.value = cleanValue
    handleFieldChange(e)
  }

  return (
    <Form.Group
      as={Col}
      bsPrefix={editColBsPrefix}
      className={editColClassName}
      controlId={id}
      style={editColStyle}
      {...columnSizes}
    >
      <Form.Label
        bsPrefix={editLabelBsPrefix}
        className={editLabelClassName}
        style={editLabelStyle}
      >
        {label}
      </Form.Label>
      <Form.Control
        bsPrefix={editValueBsPrefix}
        className={editValueClassName}
        onChange={onChange}
        placeholder={placeholder}
        style={editValueStyle}
        type={fieldType}
        value={formatPhoneNumber(value.toString())}
      />
    </Form.Group>
  )
}
