import { UseQueryResult, useQuery } from '@tanstack/react-query'
import type { AWSError } from 'aws-sdk'

import {
  getBillingPatientList,
  getClinicBillingSummaryData,
  getInvoice,
  getInvoiceByNoteAndPatient,
  getInvoices,
  getNewInvoiceFormData,
  getPaymentMethods,
  getPaymentTransactions,
  getPaymentsSummary,
  getStripeAccountStatus,
  getStripePublishableKey,
} from '../../api/api-lib'
import { getRooms } from '../../api/api-lib-typed'
import {
  ClinicalNote,
  Invoice,
  Patient,
  PaymentsSummary,
  Practice,
  Room,
} from '../../shared-types'

export enum QueryKeys {
  INVOICES = 'INVOICES',
  PAYMENT_METHODS = 'PAYMENT_METHODS',
  TRANSACTIONS = 'TRANSACTIONS',
  INVOICE = 'INVOICE',
  NEW_INVOICE_FORM = 'NEW_INVOICE_FORM',
  STRIPE_PUBLIC_KEY = 'STRIPE_PUBLIC_KEY',
  STRIPE_ACCOUNT_STATUS = 'STRIPE_ACCOUNT_STATUS',
  CLINIC_SUMMARY = 'CLINIC_SUMMARY',
  PATIENT_LIST = 'PATIENT_LIST',
  ROOMS = 'ROOMS',
  PAYMENTS_SUMMARY = 'PAYMENTS_SUMMARY',
}

export type PatientBillingSummaryData = {
  patientName: string
  patientId: string
  lastVisitDate: string
  lastPaymentDate: string
  availableCreditAmountCents: number
  totalOutstandingAmountCents: number
  isProviderAllowedToView: boolean
}

export type PatientBillingSummary = {
  totalPatients: number
  patients: PatientBillingSummaryData[]
}

export const useBillingPatientList = (
  pageNumber: number,
  searchString: string,
  pageSize: number
) => {
  return useQuery<PatientBillingSummary, AWSError>(
    [QueryKeys.PATIENT_LIST, pageNumber, searchString, pageSize],
    {
      queryFn: async () =>
        await getBillingPatientList(pageNumber, searchString, pageSize),
      retry: false,
      keepPreviousData: false,
    }
  )
}

export type ClinicBillingSummary = {
  totalOutstandingInvoicesAmountCents: number
  totalCollectedPaymentAmountCents: number
  totalOutstandingCredits: number
  totalOutstandingClaimsAmountCents: number
  totalAppliedToClaimsAmountCents: number
  patientsWithBalance: number
  averageInvoiceAge: number
}

export interface PaymentsSummaryResponse {
  payments: PaymentsSummary[]
  practice: {
    providers: {
      ProviderId: string
      ProviderFirstName: string | null
      ProviderLastName: string | null
    }[]
    primaryProviderId: string
    name: string
    timezone: string | null
  }
}

export const useClinicSummaryData = () => {
  return useQuery<ClinicBillingSummary, AWSError>([QueryKeys.CLINIC_SUMMARY], {
    queryFn: async () => await getClinicBillingSummaryData(),
    retry: false,
  })
}
export const usePaymentsSummaryData = (start: Date, end: Date) => {
  return useQuery<PaymentsSummaryResponse, AWSError>(
    [QueryKeys.PAYMENTS_SUMMARY, start, end],
    {
      queryFn: async () =>
        await getPaymentsSummary(start.toISOString(), end.toISOString()),
      retry: false,
    }
  )
}

export const useInvoices = (patientId?: string): UseQueryResult<Invoice[]> => {
  return useQuery<any[]>([QueryKeys.INVOICES, patientId], {
    queryFn: async () => {
      const { invoices } = await getInvoices(patientId)
      return invoices
    },
    retry: false,
    enabled: !!patientId,
  })
}

export const usePaymentMethods = (
  patientId: string,
  enabled = true
): UseQueryResult<any> => {
  return useQuery<any[]>([QueryKeys.PAYMENT_METHODS, patientId], {
    queryFn: async () => {
      const { paymentMethods } = await getPaymentMethods(patientId)
      return paymentMethods
    },
    retry: false,
    enabled,
  })
}

export interface transactionParams {
  patientId?: string
  invoiceUuid?: string | null
  insuranceClaimUuid?: string | null
}

export const useTransactions = ({
  patientId,
  invoiceUuid,
  insuranceClaimUuid,
}: transactionParams): UseQueryResult<any> => {
  return useQuery<UseQueryResult[]>(
    [QueryKeys.TRANSACTIONS, patientId, invoiceUuid ?? insuranceClaimUuid],
    {
      queryFn: async () =>
        await getPaymentTransactions({
          patientId,
          invoiceUuid,
          insuranceClaimUuid,
        }),
      retry: false,
    }
  )
}

export type UseInvoiceResult = {
  invoice: Invoice
  notesData: ClinicalNote[]
  patient: Patient
  practice: Practice
}

export const useInvoice = ({
  invoiceUuid,
  patientId,
  noteId,
  v2NotesEnabled,
  onSuccess,
}: {
  invoiceUuid?: string
  patientId?: string
  noteId?: string
  v2NotesEnabled?: boolean
  onSuccess?: (data: Invoice) => void
}): UseQueryResult<UseInvoiceResult> => {
  return useQuery<UseInvoiceResult>(
    [QueryKeys.INVOICE, invoiceUuid, noteId, patientId],
    {
      queryFn: async ({ queryKey }) =>
        queryKey[1]
          ? await getInvoice(queryKey[1], { v2Notes: v2NotesEnabled })
          : await getInvoiceByNoteAndPatient(queryKey[2], queryKey[3], {
              v2Notes: v2NotesEnabled,
            }),
      retry: false,
      enabled: !!invoiceUuid || (!!patientId && !!noteId),
      onSuccess: (data: { invoice: Invoice }) => {
        if (onSuccess) {
          onSuccess(data.invoice)
        }
      },
    }
  )
}

export const useNewInvoiceFormData = (
  patientId: string | undefined,
  v2NotesEnabled?: boolean
): UseQueryResult<any> => {
  return useQuery<any[]>([QueryKeys.NEW_INVOICE_FORM, patientId], {
    queryFn: () =>
      getNewInvoiceFormData(patientId, { v2Notes: v2NotesEnabled }),
    retry: false,
    enabled: !!patientId,
  })
}

export const useStripePublicKey = (): UseQueryResult<any> => {
  return useQuery<any[]>([QueryKeys.STRIPE_PUBLIC_KEY], {
    queryFn: async () => await getStripePublishableKey(),
    retry: false,
  })
}

export const useStripeAccountStatus = (): UseQueryResult<any> => {
  return useQuery<any[]>([QueryKeys.STRIPE_ACCOUNT_STATUS], {
    queryFn: async () => await getStripeAccountStatus(),
    retry: false,
  })
}

export const useRooms = (): UseQueryResult<Room[]> => {
  return useQuery<Room[]>([QueryKeys.ROOMS], {
    queryFn: async () => getRooms(),
    retry: false,
  })
}
