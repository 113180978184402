import React from 'react'

import AssesmentProblems from './Sections/AssesmentProblems'
import FacilityStaff from './Sections/FacilityStaff'
import Objective from './Sections/Objective'
import SOAPPlan from './Sections/SOAPPlan'
import Subjective from './Sections/Subjective'

import '../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function MedicalSOAP(props) {
  return (
    <div style={{ position: 'relative' }}>
      {/* Medical SOAP Ketamine Template */}
      <FacilityStaff
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        disabled={props.disabled}
      />
      <hr />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Subjective
      </div>
      <Subjective
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        activeMeds={props.activeMeds}
        previousNotes={props.previousNotes}
        disabled={props.disabled}
        detail={props.detail}
        saveNewMedicationProgress={props.saveNewMedicationProgress}
        saveEditMedProgress={props.saveEditMedProgress}
        deleteMedProgress={props.deleteMedProgress}
        Medical
      />
      <br />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Objective
      </div>
      <Objective
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        shortPublicId={props.patient.ShortPublicId}
        saveNewVitals={props.saveNewVitals}
        saveNewCaretakerVitals={props.saveNewCaretakerVitals}
        saveEditVitalSigns={props.saveEditVitalSigns}
        setAddVitals={props.setAddVitals}
        deleteVitals={props.deleteVitals}
        disabled={props.disabled}
        detail={props.detail}
        Medical
      />

      <br />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Assessment/problem list
      </div>
      <AssesmentProblems
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        Diagnosis={props.Diagnosis}
        previousNotes={props.previousNotes}
        disabled={props.disabled}
        Medical
      />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Plan
      </div>
      <SOAPPlan
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        disabled={props.disabled}
      />
    </div>
  )
}
