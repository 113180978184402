import { ReactNode, useEffect, useState } from 'react'

import { DatePicker, Divider, Form, Input, Select, Typography } from 'antd'
import { RuleObject } from 'antd/lib/form'
import moment from 'moment'
import { Col, ColProps, Row } from 'react-bootstrap'

import { capitalize } from '../../../libs/utils'
import { Button } from '../../BaseComponents'
import {
  HGGenderValues,
  HGRelationshipValues,
  getHGGenderValue,
} from '../_types'

import './LabsNewOrderForm.scss'
import styles from './LabsNewOrderMissingFields.module.scss'

const { Title, Text } = Typography
interface MissingFieldsFormProps {
  isLoading: boolean
  patientData: any
  savePatientInfo: (newPatientInfo: any) => void
}

interface LabsNewOrderMissingFieldsInput {
  label: string
  placeHolder: string
  type: 'string' | 'date' | 'select' | 'switch'
  rules?: RuleObject[]
  valueInDemographics: string
  colProps: ColProps
  inputProps?: any
  canForceUpdate?: boolean
  canShowInput?: (values: any) => boolean
}

interface LabsNewOrderMissingFieldsForm {
  title?: string
  subtitle?: string
  addSeparator?: boolean
  canShowForm?: (values: any) => boolean
  inputs: LabsNewOrderMissingFieldsInput[]
}

const baseColProps = { xs: 6 }

const patientInfo: LabsNewOrderMissingFieldsForm = {
  title: 'Patient information',
  inputs: [
    {
      label: 'Patient’s date of birth',
      placeHolder: 'Select Date',
      type: 'date',
      rules: [
        {
          required: true,
          message: "Please input patient's date of birth",
          type: 'object',
        },
      ],
      valueInDemographics: 'DateOfBirth',
      colProps: baseColProps,
    },
    {
      label: 'Patient’s legal sex',
      placeHolder: 'Select',
      type: 'select',
      rules: [
        {
          required: true,
          message: "Please input patient's legal sex",
          type: 'string',
        },
      ],
      valueInDemographics: 'legalSex',
      colProps: baseColProps,
      inputProps: {
        options: HGGenderValues.map((genderValue) => {
          return {
            value: genderValue.value,
            label: genderValue.label,
          }
        }),
      },
    },
    {
      label: 'Bill this lab to insurance?',
      placeHolder: 'Select',
      type: 'select',
      rules: [
        {
          required: true,
          message: 'Please select if lab will be billed to insurance?',
          type: 'string',
        },
      ],
      valueInDemographics: 'BillToInsurance',
      colProps: baseColProps,
      inputProps: {
        allowClear: true,
        options: [
          {
            value: 'yes',
            label: 'Yes',
          },
          {
            value: 'no',
            label: 'No',
          },
        ],
      },
      canForceUpdate: true,
    },
    {
      label: 'Relationship to insurance subscriber',
      placeHolder: 'Select',
      type: 'select',
      rules: [
        {
          required: true,
          message:
            "Please input patient's relationship to insurance subscriber",
          type: 'string',
        },
      ],
      valueInDemographics: 'primaryInsuredRelationship',
      colProps: baseColProps,
      inputProps: {
        options: HGRelationshipValues.map((relationshipValue) => {
          return {
            value: relationshipValue,
            label: capitalize(relationshipValue),
          }
        }),
      },
      canForceUpdate: true,
      canShowInput: (values: any) => {
        return values.BillToInsurance === 'yes' ? true : false
      },
    },
  ],
}
const patientAddress: LabsNewOrderMissingFieldsForm = {
  subtitle: 'Patient address',
  canShowForm: (values: any) => {
    return (
      values.primaryInsuredRelationship === HGRelationshipValues[0] &&
      values.BillToInsurance === 'yes'
    )
  },
  inputs: [
    {
      label: 'Address line 1',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: 'Please input patient address',
          type: 'string',
        },
      ],
      valueInDemographics: 'addressLine1',
      colProps: baseColProps,
    },
    {
      label: 'Address line 2',
      placeHolder: '',
      type: 'string',
      valueInDemographics: 'addressLine2',
      colProps: baseColProps,
    },
    {
      label: 'City',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: 'Please input patient city',
          type: 'string',
        },
      ],
      valueInDemographics: 'city',
      colProps: baseColProps,
    },
    {
      label: 'State',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: 'Please input patient state',
          type: 'string',
        },
      ],
      valueInDemographics: 'state',
      colProps: baseColProps,
    },
    {
      label: 'Zip code',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: 'Please input patient zip code',
          type: 'string',
        },
      ],
      valueInDemographics: 'ZipCode',
      colProps: baseColProps,
    },
  ],
}
const primaryInsuranceInfo: LabsNewOrderMissingFieldsForm = {
  subtitle: 'Insurance information',
  title: 'Insurance',
  addSeparator: true,
  canShowForm: (values: any) => {
    return values.primaryInsuredRelationship && values.BillToInsurance === 'yes'
  },
  inputs: [
    {
      label: 'Name of insurance company',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: "Please input insurance's company name",
          type: 'string',
        },
      ],
      valueInDemographics: 'insuranceName',
      colProps: baseColProps,
    },
    {
      label: 'Member ID number',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: 'Please input member ID number',
          type: 'string',
        },
      ],
      valueInDemographics: 'insuranceMemberId',
      colProps: baseColProps,
    },
    {
      label: 'Group ID number',
      placeHolder: '',
      type: 'string',
      valueInDemographics: 'insuranceGroupId',
      colProps: baseColProps,
    },
  ],
}
const primaryInsuranceAddress: LabsNewOrderMissingFieldsForm = {
  subtitle: 'Insurance company address',
  canShowForm: (values: any) => {
    return values.primaryInsuredRelationship && values.BillToInsurance === 'yes'
  },
  inputs: [
    {
      label: 'Address line 1',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: "Please input insurance's Address line 1",
          type: 'string',
        },
      ],
      valueInDemographics: 'insuranceCompanyAddress1',
      colProps: baseColProps,
    },
    {
      label: 'Address line 2',
      placeHolder: '',
      type: 'string',
      valueInDemographics: 'insuranceCompanyAddress2',
      colProps: baseColProps,
    },
    {
      label: 'City',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: "Please input insurance's city",
          type: 'string',
        },
      ],
      valueInDemographics: 'insuranceCompanyCity',
      colProps: baseColProps,
    },
    {
      label: 'State',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: "Please input insurance's state",
          type: 'string',
        },
      ],
      valueInDemographics: 'insuranceCompanyState',
      colProps: baseColProps,
    },
    {
      label: 'Zip code',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: "Please input insurance's zip code",
          type: 'string',
        },
      ],
      valueInDemographics: 'insuranceCompanyZipCode',
      colProps: baseColProps,
    },
  ],
}

const primaryInsuredInfo: LabsNewOrderMissingFieldsForm = {
  title: 'Insurance subscriber',
  subtitle: 'Subscriber information',
  addSeparator: true,
  canShowForm: (values: any) => {
    return (
      values.primaryInsuredRelationship &&
      values.primaryInsuredRelationship !== HGRelationshipValues[0] &&
      values.BillToInsurance === 'yes'
    )
  },
  inputs: [
    {
      label: 'First name',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: 'Please input subscriber first name',
          type: 'string',
        },
      ],
      valueInDemographics: 'primaryInsuredFirstName',
      colProps: baseColProps,
    },
    {
      label: 'Last name',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: 'Please input subscriber last name',
          type: 'string',
        },
      ],
      valueInDemographics: 'primaryInsuredLastName',
      colProps: baseColProps,
    },
    {
      label: 'Date of birth',
      placeHolder: 'Select Date',
      type: 'date',
      rules: [
        {
          required: true,
          message: 'Please input subscriber date of birth',
          type: 'object',
        },
      ],
      valueInDemographics: 'primaryInsuredDateOfBirth',
      colProps: baseColProps,
    },
    {
      label: 'Legal sex',
      placeHolder: 'Select',
      type: 'select',
      rules: [
        {
          required: true,
          message: 'Please input subscriber legal sex',
          type: 'string',
        },
      ],
      valueInDemographics: 'primaryInsuredLegalSex',
      colProps: baseColProps,
      inputProps: {
        options: HGGenderValues.map((genderValue) => {
          return {
            value: genderValue.value,
            label: genderValue.label,
          }
        }),
      },
    },
    {
      label: 'Phone number',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: 'Please input subscriber phone number',
          type: 'string',
        },
      ],
      valueInDemographics: 'primaryInsuredPhone',
      colProps: baseColProps,
    },
  ],
}

const primaryInsuredAddress: LabsNewOrderMissingFieldsForm = {
  subtitle: 'Subscriber address',
  canShowForm: (values: any) => {
    return (
      values.primaryInsuredRelationship &&
      values.primaryInsuredRelationship !== HGRelationshipValues[0] &&
      values.BillToInsurance === 'yes'
    )
  },
  inputs: [
    {
      label: 'Address line 1',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: 'Please input insured’s address line 1',
          type: 'string',
        },
      ],
      valueInDemographics: 'primaryInsuredAddressLine1',
      colProps: baseColProps,
    },
    {
      label: 'Address line 2',
      placeHolder: '',
      type: 'string',
      valueInDemographics: 'primaryInsuredAddressLine2',
      colProps: baseColProps,
    },
    {
      label: 'City',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: 'Please input insured’s city',
          type: 'string',
        },
      ],
      valueInDemographics: 'primaryInsuredCity',
      colProps: baseColProps,
    },
    {
      label: 'State',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: 'Please input insured’s state',
          type: 'string',
        },
      ],
      valueInDemographics: 'primaryInsuredState',
      colProps: baseColProps,
    },
    {
      label: 'Zip code',
      placeHolder: '',
      type: 'string',
      rules: [
        {
          required: true,
          message: 'Please input insured’s zip code',
          type: 'string',
        },
      ],
      valueInDemographics: 'primaryInsuredZipCode',
      colProps: baseColProps,
    },
  ],
}

const initForm = [
  patientInfo,
  patientAddress,
  primaryInsuredInfo,
  primaryInsuredAddress,
  primaryInsuranceInfo,
  primaryInsuranceAddress,
]

export default function LabNewOrderMissingFieldsForm({
  isLoading,
  patientData,
  savePatientInfo,
}: MissingFieldsFormProps) {
  const [missingValuesForm] = Form.useForm()
  const [formGenerator, _setFormGenerator] =
    useState<LabsNewOrderMissingFieldsForm[]>(initForm)
  const [_forceUpdateState, setForceUpdateState] = useState<string>('')

  //We need to force update for the flow, depending on some inputs we need to show differents
  function forceUpdate() {
    setForceUpdateState(new Date().toISOString())
  }

  useEffect(() => {
    missingValuesForm.setFieldsValue({
      ...patientData,
      primaryInsuredLegalSex: patientData.primaryInsuredLegalSex
        ? getHGGenderValue(patientData.primaryInsuredLegalSex)
        : undefined,
      primaryInsuredRelationship:
        patientData.isPrimaryInsuranceHolder === 'Yes'
          ? 'self'
          : patientData.primaryInsuredRelationship,
      BillToInsurance: undefined,
      DateOfBirth: patientData.DateOfBirth
        ? moment(patientData.DateOfBirth).utc()
        : undefined,
      primaryInsuredDateOfBirth:
        patientData.primaryInsuredDateOfBirth &&
        patientData.primaryInsuredDateOfBirth.date
          ? moment(patientData.primaryInsuredDateOfBirth.date).utc()
          : undefined,
      legalSex: patientData.legalSex
        ? getHGGenderValue(patientData.legalSex)
        : undefined,
    })
    forceUpdate()
  }, [])

  function GetInptuData(valueInDemographics: string) {
    const searchInputs: LabsNewOrderMissingFieldsInput[] = []
    for (const internalForm of formGenerator) {
      searchInputs.push(...internalForm.inputs)
    }
    return searchInputs.find((input) => {
      return input.valueInDemographics === valueInDemographics
    })
  }

  function FormInputsWraper({
    input,
    children,
  }: {
    input: LabsNewOrderMissingFieldsInput
    children: ReactNode
  }) {
    return (
      <Col {...input.colProps}>
        <Form.Item
          className={'missing-values-form-item-container'}
          name={input.valueInDemographics}
          label={
            <>
              <Text>{input.label}</Text>
              {!input.rules && (
                <Text className={styles.missingValuesInputLabel}>
                  {' (optional)'}
                </Text>
              )}
            </>
          }
          rules={input.rules}
        >
          {children}
        </Form.Item>
      </Col>
    )
  }
  return (
    <Form
      form={missingValuesForm}
      scrollToFirstError
      layout="vertical"
      onFinish={(values) => {
        if (values.BillToInsurance === 'no') {
          values.insuranceName = ''
          values.insuranceMemberId = ''
          values.insuranceGroupId = ''
        }
        values.DateOfBirth = values.DateOfBirth.toISOString()
        if (values.primaryInsuredDateOfBirth) {
          const date = moment(
            values.primaryInsuredDateOfBirth.utc().toISOString()
          ).format('MM/DD/YYYY')
          values.primaryInsuredDateOfBirth = {
            date: date,
            format: 'monthDayYear',
          }
        }
        values.primaryInsuranceEmail = patientData.primaryInsuranceEmail
        savePatientInfo(values)
      }}
      onValuesChange={(changedValues) => {
        const data = GetInptuData(Object.keys(changedValues)[0])
        if (data && data.canForceUpdate) {
          forceUpdate()
        }
      }}
    >
      {formGenerator.map((form, formIndex) => {
        const fieldsValues = {
          ...missingValuesForm.getFieldsValue(),
          primaryInsuredRelationship: missingValuesForm.getFieldValue(
            'primaryInsuredRelationship'
          ),
          BillToInsurance: missingValuesForm.getFieldValue('BillToInsurance'),
        }
        if (form.canShowForm !== undefined && !form.canShowForm(fieldsValues)) {
          return undefined
        }
        return (
          <div key={formIndex}>
            {form.addSeparator && (
              <Divider className={styles.missingValuesFormDivider} />
            )}
            {form.title && (
              <Title
                level={3}
                className={styles.missingValuesFormTitleContainer}
              >
                {form.title}
              </Title>
            )}
            {form.subtitle && (
              <Title
                level={5}
                className={
                  form.title
                    ? styles.missingValuesFormSubTitleContainer
                    : styles.missingValuesFormSubTitleContainerNoTitle
                }
              >
                {form.subtitle}
              </Title>
            )}
            <Row>
              {form.inputs.map((input, inputIndex) => {
                if (
                  input.canShowInput !== undefined &&
                  !input.canShowInput(missingValuesForm.getFieldsValue())
                ) {
                  return undefined
                }
                switch (input.type) {
                  case 'date':
                    return (
                      <FormInputsWraper input={input} key={inputIndex}>
                        <DatePicker
                          placeholder={input.placeHolder}
                          style={{ width: '100%' }}
                          {...input.inputProps}
                        />
                      </FormInputsWraper>
                    )
                  case 'select':
                    return (
                      <FormInputsWraper input={input} key={inputIndex}>
                        <Select
                          placeholder={input.placeHolder}
                          style={{ width: '100%' }}
                          {...input.inputProps}
                        />
                      </FormInputsWraper>
                    )
                  case 'string':
                    return (
                      <FormInputsWraper input={input} key={inputIndex}>
                        <Input
                          placeholder={input.placeHolder}
                          style={{ width: '100%' }}
                          {...input.inputProps}
                        />
                      </FormInputsWraper>
                    )
                  default:
                    return <div key={inputIndex} />
                }
              })}
            </Row>
          </div>
        )
      })}
      <Button
        type="primary"
        className={styles.orderNewLabAction}
        loading={isLoading}
        htmlType="submit"
      >
        Order new lab
      </Button>
    </Form>
  )
}
