import { useEffect, useState } from 'react'

import {
  changeEmailMessageEnable,
  getProviderInformation,
} from '../../../api/api-lib'
import { onError } from '../../../libs/errorLib'
import { notification } from '../../../libs/notificationLib'
import { Card, Spinner, Switch } from '../../../stories/BaseComponents'

import styles from '../../../containers/_shared.module.scss'
import './EmailNotificationsToggle.scss'

interface NotificationProps {
  providerEmail: string
}

export const EmailNotificationsToggle = ({
  providerEmail,
}: NotificationProps) => {
  const [shouldEnableNotifs, setShouldEnableNotifs] = useState(false)
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const [isLoadingSwitch, setIsLoadingSwitch] = useState(false)

  useEffect(() => {
    const onLoad = async () => {
      setIsLoadingPage(true)
      const {
        providerInfo: { IsEmailNotificationsEnabled = false },
      } = await getProviderInformation()
      setShouldEnableNotifs(IsEmailNotificationsEnabled)
    }

    onLoad()
      .catch((e) => {
        onError(e, 500, 'Error loading notifications setting')
      })
      .finally(() => {
        setIsLoadingPage(false)
      })
  }, [])

  const toggleEmailNotifs = async (checked: boolean) => {
    setIsLoadingSwitch(true)
    try {
      await changeEmailMessageEnable(providerEmail, checked)
      setShouldEnableNotifs(checked)
      if (checked) {
        notification(
          'Settings updated: You will now receive emails for new secure messages',
          'success'
        )
      } else {
        notification(
          'Settings updated: You will no longer receive emails for new secure messages',
          'success'
        )
      }
    } catch (e) {
      onError(
        e,
        500,
        'Updating email notification preference was unsuccessful. Please contact Osmind support.'
      )
    } finally {
      setIsLoadingSwitch(false)
    }
  }

  return (
    <div className={styles.spacedContainer}>
      {isLoadingPage ? (
        <Spinner
          fontSize={40}
          className="d-flex justify-content-center calendar-spinner scheduling-spinner"
          style={{ marginTop: 48 }}
        />
      ) : (
        <Card bordered={false}>
          <p id="email-notifications-header">Notifications</p>
          <div id="email-notifications-body">
            <p className={'notification-text'}>
              Receive email notifications for new secure messages
            </p>
            <Switch
              checked={shouldEnableNotifs}
              loading={isLoadingSwitch}
              onToggle={() => toggleEmailNotifs(!shouldEnableNotifs)}
            />
          </div>
        </Card>
      )}
    </div>
  )
}
