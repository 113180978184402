import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const languageFHIR: PatientDemographicObject[] = [
  { aar: { title: 'Afar' } },
  { abk: { title: 'Abkhazian' } },
  { ace: { title: 'Achinese' } },
  { ach: { title: 'Acoli' } },
  { ada: { title: 'Adangme' } },
  { ady: { title: 'Adyghe; Adygei' } },
  { afa: { title: 'Afro-Asiatic languages' } },
  { afh: { title: 'Afrihili' } },
  { afr: { title: 'Afrikaans' } },
  { ain: { title: 'Ainu' } },
  { aka: { title: 'Akan' } },
  { akk: { title: 'Akkadian' } },
  { sqi: { title: 'Albanian' } },
  { ale: { title: 'Aleut' } },
  { alg: { title: 'Algonquian languages' } },
  { alt: { title: 'Southern Altai' } },
  { amh: { title: 'Amharic' } },
  { ang: { title: 'English, Old (ca.450-1100)' } },
  { anp: { title: 'Angika' } },
  { apa: { title: 'Apache languages' } },
  { ara: { title: 'Arabic' } },
  {
    arc: {
      title: 'Official Aramaic (700-300 BCE); Imperial Aramaic (700-300 BCE)',
    },
  },
  { arg: { title: 'Aragonese' } },
  { hye: { title: 'Armenian' } },
  { arn: { title: 'Mapudungun; Mapuche' } },
  { arp: { title: 'Arapaho' } },
  { art: { title: 'Artificial languages' } },
  { arw: { title: 'Arawak' } },
  { asm: { title: 'Assamese' } },
  { ast: { title: 'Asturian; Bable; Leonese; Asturleonese' } },
  { ath: { title: 'Athapascan languages' } },
  { aus: { title: 'Australian languages' } },
  { ava: { title: 'Avaric' } },
  { ave: { title: 'Avestan' } },
  { awa: { title: 'Awadhi' } },
  { aym: { title: 'Aymara' } },
  { aze: { title: 'Azerbaijani' } },
  { bad: { title: 'Banda languages' } },
  { bai: { title: 'Bamileke languages' } },
  { bak: { title: 'Bashkir' } },
  { bal: { title: 'Baluchi' } },
  { bam: { title: 'Bambara' } },
  { ban: { title: 'Balinese' } },
  { eus: { title: 'Basque' } },
  { bas: { title: 'Basa' } },
  { bat: { title: 'Baltic languages' } },
  { bej: { title: 'Beja; Bedawiyet' } },
  { bel: { title: 'Belarusian' } },
  { bem: { title: 'Bemba' } },
  { ben: { title: 'Bengali' } },
  { ber: { title: 'Berber languages' } },
  { bho: { title: 'Bhojpuri' } },
  { bih: { title: 'Bihari languages' } },
  { bik: { title: 'Bikol' } },
  { bin: { title: 'Bini; Edo' } },
  { bis: { title: 'Bislama' } },
  { bla: { title: 'Siksika' } },
  { bnt: { title: 'Bantu languages' } },
  { bod: { title: 'Tibetan' } },
  { bos: { title: 'Bosnian' } },
  { bra: { title: 'Braj' } },
  { bre: { title: 'Breton' } },
  { btk: { title: 'Batak languages' } },
  { bua: { title: 'Buriat' } },
  { bug: { title: 'Buginese' } },
  { bul: { title: 'Bulgarian' } },
  { mya: { title: 'Burmese' } },
  { byn: { title: 'Blin; Bilin' } },
  { cad: { title: 'Caddo' } },
  { cai: { title: 'Central American Indian languages' } },
  { car: { title: 'Galibi Carib' } },
  { cat: { title: 'Catalan; Valencian' } },
  { cau: { title: 'Caucasian languages' } },
  { ceb: { title: 'Cebuano' } },
  { cel: { title: 'Celtic languages' } },
  { ces: { title: 'Czech' } },
  { cha: { title: 'Chamorro' } },
  { chb: { title: 'Chibcha' } },
  { che: { title: 'Chechen' } },
  { chg: { title: 'Chagatai' } },
  { zho: { title: 'Chinese' } },
  { chk: { title: 'Chuukese' } },
  { chm: { title: 'Mari' } },
  { chn: { title: 'Chinook jargon' } },
  { cho: { title: 'Choctaw' } },
  { chp: { title: 'Chipewyan; Dene Suline' } },
  { chr: { title: 'Cherokee' } },
  {
    chu: {
      title:
        'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
    },
  },
  { chv: { title: 'Chuvash' } },
  { chy: { title: 'Cheyenne' } },
  { cmc: { title: 'Chamic languages' } },
  { cnr: { title: 'Montenegrin' } },
  { cop: { title: 'Coptic' } },
  { cor: { title: 'Cornish' } },
  { cos: { title: 'Corsican' } },
  { cpe: { title: 'Creoles and pidgins, English based' } },
  { cpf: { title: 'Creoles and pidgins, French-based' } },
  { cpp: { title: 'Creoles and pidgins, Portuguese-based' } },
  { cre: { title: 'Cree' } },
  { crh: { title: 'Crimean Tatar; Crimean Turkish' } },
  { crp: { title: 'Creoles and pidgins' } },
  { csb: { title: 'Kashubian' } },
  { cus: { title: 'Cushitic languages' } },
  { cym: { title: 'Welsh' } },
  { ces: { title: 'Czech' } },
  { dak: { title: 'Dakota' } },
  { dan: { title: 'Danish' } },
  { dar: { title: 'Dargwa' } },
  { day: { title: 'Land Dayak languages' } },
  { del: { title: 'Delaware' } },
  { den: { title: 'Slave (Athapascan)' } },
  { deu: { title: 'German' } },
  { dgr: { title: 'Dogrib' } },
  { din: { title: 'Dinka' } },
  { div: { title: 'Divehi; Dhivehi; Maldivian' } },
  { doi: { title: 'Dogri' } },
  { dra: { title: 'Dravidian languages' } },
  { dsb: { title: 'Lower Sorbian' } },
  { dua: { title: 'Duala' } },
  { dum: { title: 'Dutch, Middle (ca.1050-1350)' } },
  { nld: { title: 'Dutch; Flemish' } },
  { dyu: { title: 'Dyula' } },
  { dzo: { title: 'Dzongkha' } },
  { efi: { title: 'Efik' } },
  { egy: { title: 'Egyptian (Ancient)' } },
  { eka: { title: 'Ekajuk' } },
  { ell: { title: 'Greek, Modern (1453-)' } },
  { elx: { title: 'Elamite' } },
  { eng: { title: 'English' } },
  { enm: { title: 'English, Middle (1100-1500)' } },
  { epo: { title: 'Esperanto' } },
  { est: { title: 'Estonian' } },
  { eus: { title: 'Basque' } },
  { ewe: { title: 'Ewe' } },
  { ewo: { title: 'Ewondo' } },
  { fan: { title: 'Fang' } },
  { fao: { title: 'Faroese' } },
  { fas: { title: 'Persian' } },
  { fat: { title: 'Fanti' } },
  { fij: { title: 'Fijian' } },
  { fil: { title: 'Filipino; Pilipino' } },
  { fin: { title: 'Finnish' } },
  { fiu: { title: 'Finno-Ugrian languages' } },
  { fon: { title: 'Fon' } },
  { fra: { title: 'French' } },
  { frm: { title: 'French, Middle (ca.1400-1600)' } },
  { fro: { title: 'French, Old (842-ca.1400)' } },
  { frr: { title: 'Northern Frisian' } },
  { frs: { title: 'Eastern Frisian' } },
  { fry: { title: 'Western Frisian' } },
  { ful: { title: 'Fulah' } },
  { fur: { title: 'Friulian' } },
  { gaa: { title: 'Ga' } },
  { gay: { title: 'Gayo' } },
  { gba: { title: 'Gbaya' } },
  { gem: { title: 'Germanic languages' } },
  { kat: { title: 'Georgian' } },
  { deu: { title: 'German' } },
  { gez: { title: 'Geez' } },
  { gil: { title: 'Gilbertese' } },
  { gla: { title: 'Gaelic; Scottish Gaelic' } },
  { gle: { title: 'Irish' } },
  { glg: { title: 'Galician' } },
  { glv: { title: 'Manx' } },
  { gmh: { title: 'German, Middle High (ca.1050-1500)' } },
  { goh: { title: 'German, Old High (ca.750-1050)' } },
  { gon: { title: 'Gondi' } },
  { gor: { title: 'Gorontalo' } },
  { got: { title: 'Gothic' } },
  { grb: { title: 'Grebo' } },
  { grc: { title: 'Greek, Ancient (to 1453)' } },
  { ell: { title: 'Greek, Modern (1453-)' } },
  { grn: { title: 'Guarani' } },
  { gsw: { title: 'Swiss German; Alemannic; Alsatian' } },
  { guj: { title: 'Gujarati' } },
  { gwi: { title: "Gwich'in" } },
  { hai: { title: 'Haida' } },
  { hat: { title: 'Haitian; Haitian Creole' } },
  { hau: { title: 'Hausa' } },
  { haw: { title: 'Hawaiian' } },
  { heb: { title: 'Hebrew' } },
  { her: { title: 'Herero' } },
  { hil: { title: 'Hiligaynon' } },
  { him: { title: 'Himachali languages; Western Pahari languages' } },
  { hin: { title: 'Hindi' } },
  { hit: { title: 'Hittite' } },
  { hmn: { title: 'Hmong; Mong' } },
  { hmo: { title: 'Hiri Motu' } },
  { hrv: { title: 'Croatian' } },
  { hsb: { title: 'Upper Sorbian' } },
  { hun: { title: 'Hungarian' } },
  { hup: { title: 'Hupa' } },
  { hye: { title: 'Armenian' } },
  { iba: { title: 'Iban' } },
  { ibo: { title: 'Igbo' } },
  { isl: { title: 'Icelandic' } },
  { ido: { title: 'Ido' } },
  { iii: { title: 'Sichuan Yi; Nuosu' } },
  { ijo: { title: 'Ijo languages' } },
  { iku: { title: 'Inuktitut' } },
  { ile: { title: 'Interlingue; Occidental' } },
  { ilo: { title: 'Iloko' } },
  {
    ina: {
      title: 'Interlingua (International Auxiliary Language Association)',
    },
  },
  { inc: { title: 'Indic languages' } },
  { ind: { title: 'Indonesian' } },
  { ine: { title: 'Indo-European languages' } },
  { inh: { title: 'Ingush' } },
  { ipk: { title: 'Inupiaq' } },
  { ira: { title: 'Iranian languages' } },
  { iro: { title: 'Iroquoian languages' } },
  { isl: { title: 'Icelandic' } },
  { ita: { title: 'Italian' } },
  { jav: { title: 'Javanese' } },
  { jbo: { title: 'Lojban' } },
  { jpn: { title: 'Japanese' } },
  { jpr: { title: 'Judeo-Persian' } },
  { jrb: { title: 'Judeo-Arabic' } },
  { kaa: { title: 'Kara-Kalpak' } },
  { kab: { title: 'Kabyle' } },
  { kac: { title: 'Kachin; Jingpho' } },
  { kal: { title: 'Kalaallisut; Greenlandic' } },
  { kam: { title: 'Kamba' } },
  { kan: { title: 'Kannada' } },
  { kar: { title: 'Karen languages' } },
  { kas: { title: 'Kashmiri' } },
  { kat: { title: 'Georgian' } },
  { kau: { title: 'Kanuri' } },
  { kaw: { title: 'Kawi' } },
  { kaz: { title: 'Kazakh' } },
  { kbd: { title: 'Kabardian' } },
  { kha: { title: 'Khasi' } },
  { khi: { title: 'Khoisan languages' } },
  { khm: { title: 'Central Khmer' } },
  { kho: { title: 'Khotanese; Sakan' } },
  { kik: { title: 'Kikuyu; Gikuyu' } },
  { kin: { title: 'Kinyarwanda' } },
  { kir: { title: 'Kirghiz; Kyrgyz' } },
  { kmb: { title: 'Kimbundu' } },
  { kok: { title: 'Konkani' } },
  { kom: { title: 'Komi' } },
  { kon: { title: 'Kongo' } },
  { kor: { title: 'Korean' } },
  { kos: { title: 'Kosraean' } },
  { kpe: { title: 'Kpelle' } },
  { krc: { title: 'Karachay-Balkar' } },
  { krl: { title: 'Karelian' } },
  { kro: { title: 'Kru languages' } },
  { kru: { title: 'Kurukh' } },
  { kua: { title: 'Kuanyama; Kwanyama' } },
  { kum: { title: 'Kumyk' } },
  { kur: { title: 'Kurdish' } },
  { kut: { title: 'Kutenai' } },
  { lad: { title: 'Ladino' } },
  { lah: { title: 'Lahnda' } },
  { lam: { title: 'Lamba' } },
  { lao: { title: 'Lao' } },
  { lat: { title: 'Latin' } },
  { lav: { title: 'Latvian' } },
  { lez: { title: 'Lezghian' } },
  { lim: { title: 'Limburgan; Limburger; Limburgish' } },
  { lin: { title: 'Lingala' } },
  { lit: { title: 'Lithuanian' } },
  { lol: { title: 'Mongo' } },
  { loz: { title: 'Lozi' } },
  { ltz: { title: 'Luxembourgish; Letzeburgesch' } },
  { lua: { title: 'Luba-Lulua' } },
  { lub: { title: 'Luba-Katanga' } },
  { lug: { title: 'Ganda' } },
  { lui: { title: 'Luiseno' } },
  { lun: { title: 'Lunda' } },
  { luo: { title: 'Luo (Kenya and Tanzania)' } },
  { lus: { title: 'Lushai' } },
  { mkd: { title: 'Macedonian' } },
  { mad: { title: 'Madurese' } },
  { mag: { title: 'Magahi' } },
  { mah: { title: 'Marshallese' } },
  { mai: { title: 'Maithili' } },
  { mak: { title: 'Makasar' } },
  { mal: { title: 'Malayalam' } },
  { man: { title: 'Mandingo' } },
  { mri: { title: 'Maori' } },
  { map: { title: 'Austronesian languages' } },
  { mar: { title: 'Marathi' } },
  { mas: { title: 'Masai' } },
  { msa: { title: 'Malay' } },
  { mdf: { title: 'Moksha' } },
  { mdr: { title: 'Mandar' } },
  { men: { title: 'Mende' } },
  { mga: { title: 'Irish, Middle (900-1200)' } },
  { mic: { title: "Mi'kmaq; Micmac" } },
  { min: { title: 'Minangkabau' } },
  { mis: { title: 'Uncoded languages' } },
  { mkd: { title: 'Macedonian' } },
  { mkh: { title: 'Mon-Khmer languages' } },
  { mlg: { title: 'Malagasy' } },
  { mlt: { title: 'Maltese' } },
  { mnc: { title: 'Manchu' } },
  { mni: { title: 'Manipuri' } },
  { mno: { title: 'Manobo languages' } },
  { moh: { title: 'Mohawk' } },
  { mon: { title: 'Mongolian' } },
  { mos: { title: 'Mossi' } },
  { mri: { title: 'Maori' } },
  { msa: { title: 'Malay' } },
  { mul: { title: 'Multiple languages' } },
  { mun: { title: 'Munda languages' } },
  { mus: { title: 'Creek' } },
  { mwl: { title: 'Mirandese' } },
  { mwr: { title: 'Marwari' } },
  { mya: { title: 'Burmese' } },
  { myn: { title: 'Mayan languages' } },
  { myv: { title: 'Erzya' } },
  { nah: { title: 'Nahuatl languages' } },
  { nai: { title: 'North American Indian languages' } },
  { nap: { title: 'Neapolitan' } },
  { nau: { title: 'Nauru' } },
  { nav: { title: 'Navajo; Navaho' } },
  { nbl: { title: 'Ndebele, South; South Ndebele' } },
  { nde: { title: 'Ndebele, North; North Ndebele' } },
  { ndo: { title: 'Ndonga' } },
  { nds: { title: 'Low German; Low Saxon; German, Low; Saxon, Low' } },
  { nep: { title: 'Nepali' } },
  { new: { title: 'Nepal Bhasa; Newari' } },
  { nia: { title: 'Nias' } },
  { nic: { title: 'Niger-Kordofanian languages' } },
  { niu: { title: 'Niuean' } },
  { nld: { title: 'Dutch; Flemish' } },
  { nno: { title: 'Norwegian Nynorsk; Nynorsk, Norwegian' } },
  { nob: { title: 'Bokmål, Norwegian; Norwegian Bokmål' } },
  { nog: { title: 'Nogai' } },
  { non: { title: 'Norse, Old' } },
  { nor: { title: 'Norwegian' } },
  { nqo: { title: "N'Ko" } },
  { nso: { title: 'Pedi; Sepedi; Northern Sotho' } },
  { nub: { title: 'Nubian languages' } },
  { nwc: { title: 'Classical Newari; Old Newari; Classical Nepal Bhasa' } },
  { nya: { title: 'Chichewa; Chewa; Nyanja' } },
  { nym: { title: 'Nyamwezi' } },
  { nyn: { title: 'Nyankole' } },
  { nyo: { title: 'Nyoro' } },
  { nzi: { title: 'Nzima' } },
  { oci: { title: 'Occitan (post 1500)' } },
  { oji: { title: 'Ojibwa' } },
  { ori: { title: 'Oriya' } },
  { orm: { title: 'Oromo' } },
  { osa: { title: 'Osage' } },
  { oss: { title: 'Ossetian; Ossetic' } },
  { ota: { title: 'Turkish, Ottoman (1500-1928)' } },
  { oto: { title: 'Otomian languages' } },
  { paa: { title: 'Papuan languages' } },
  { pag: { title: 'Pangasinan' } },
  { pal: { title: 'Pahlavi' } },
  { pam: { title: 'Pampanga; Kapampangan' } },
  { pan: { title: 'Panjabi; Punjabi' } },
  { pap: { title: 'Papiamento' } },
  { pau: { title: 'Palauan' } },
  { peo: { title: 'Persian, Old (ca.600-400 B.C.)' } },
  { fas: { title: 'Persian' } },
  { phi: { title: 'Philippine languages' } },
  { phn: { title: 'Phoenician' } },
  { pli: { title: 'Pali' } },
  { pol: { title: 'Polish' } },
  { pon: { title: 'Pohnpeian' } },
  { por: { title: 'Portuguese' } },
  { pra: { title: 'Prakrit languages' } },
  { pro: { title: 'Provençal, Old (to 1500);Occitan, Old (to 1500)' } },
  { pus: { title: 'Pushto; Pashto' } },
  { que: { title: 'Quechua' } },
  { raj: { title: 'Rajasthani' } },
  { rap: { title: 'Rapanui' } },
  { rar: { title: 'Rarotongan; Cook Islands Maori' } },
  { roa: { title: 'Romance languages' } },
  { roh: { title: 'Romansh' } },
  { rom: { title: 'Romany' } },
  { ron: { title: 'Romanian; Moldavian; Moldovan' } },
  { run: { title: 'Rundi' } },
  { rup: { title: 'Aromanian; Arumanian; Macedo-Romanian' } },
  { rus: { title: 'Russian' } },
  { sad: { title: 'Sandawe' } },
  { sag: { title: 'Sango' } },
  { sah: { title: 'Yakut' } },
  { sai: { title: 'South American Indian languages' } },
  { sal: { title: 'Salishan languages' } },
  { sam: { title: 'Samaritan Aramaic' } },
  { san: { title: 'Sanskrit' } },
  { sas: { title: 'Sasak' } },
  { sat: { title: 'Santali' } },
  { scn: { title: 'Sicilian' } },
  { sco: { title: 'Scots' } },
  { sel: { title: 'Selkup' } },
  { sem: { title: 'Semitic languages' } },
  { sga: { title: 'Irish, Old (to 900)' } },
  { sgn: { title: 'Sign Languages' } },
  { shn: { title: 'Shan' } },
  { sid: { title: 'Sidamo' } },
  { sin: { title: 'Sinhala; Sinhalese' } },
  { sio: { title: 'Siouan languages' } },
  { sit: { title: 'Sino-Tibetan languages' } },
  { sla: { title: 'Slavic languages' } },
  { slk: { title: 'Slovak' } },
  { slv: { title: 'Slovenian' } },
  { sma: { title: 'Southern Sami' } },
  { sme: { title: 'Northern Sami' } },
  { smi: { title: 'Sami languages' } },
  { smj: { title: 'Lule Sami' } },
  { smn: { title: 'Inari Sami' } },
  { smo: { title: 'Samoan' } },
  { sms: { title: 'Skolt Sami' } },
  { sna: { title: 'Shona' } },
  { snd: { title: 'Sindhi' } },
  { snk: { title: 'Soninke' } },
  { sog: { title: 'Sogdian' } },
  { som: { title: 'Somali' } },
  { son: { title: 'Songhai languages' } },
  { sot: { title: 'Sotho, Southern' } },
  { spa: { title: 'Spanish; Castilian' } },
  { sqi: { title: 'Albanian' } },
  { srd: { title: 'Sardinian' } },
  { srn: { title: 'Sranan Tongo' } },
  { srp: { title: 'Serbian' } },
  { srr: { title: 'Serer' } },
  { ssa: { title: 'Nilo-Saharan languages' } },
  { ssw: { title: 'Swati' } },
  { suk: { title: 'Sukuma' } },
  { sun: { title: 'Sundanese' } },
  { sus: { title: 'Susu' } },
  { sux: { title: 'Sumerian' } },
  { swa: { title: 'Swahili' } },
  { swe: { title: 'Swedish' } },
  { syc: { title: 'Classical Syriac' } },
  { syr: { title: 'Syriac' } },
  { tah: { title: 'Tahitian' } },
  { tai: { title: 'Tai languages' } },
  { tam: { title: 'Tamil' } },
  { tat: { title: 'Tatar' } },
  { tel: { title: 'Telugu' } },
  { tem: { title: 'Timne' } },
  { ter: { title: 'Tereno' } },
  { tet: { title: 'Tetum' } },
  { tgk: { title: 'Tajik' } },
  { tgl: { title: 'Tagalog' } },
  { tha: { title: 'Thai' } },
  { bod: { title: 'Tibetan' } },
  { tig: { title: 'Tigre' } },
  { tir: { title: 'Tigrinya' } },
  { tiv: { title: 'Tiv' } },
  { tkl: { title: 'Tokelau' } },
  { tlh: { title: 'Klingon; tlhIngan-Hol' } },
  { tli: { title: 'Tlingit' } },
  { tmh: { title: 'Tamashek' } },
  { tog: { title: 'Tonga (Nyasa)' } },
  { ton: { title: 'Tonga (Tonga Islands)' } },
  { tpi: { title: 'Tok Pisin' } },
  { tsi: { title: 'Tsimshian' } },
  { tsn: { title: 'Tswana' } },
  { tso: { title: 'Tsonga' } },
  { tuk: { title: 'Turkmen' } },
  { tum: { title: 'Tumbuka' } },
  { tup: { title: 'Tupi languages' } },
  { tur: { title: 'Turkish' } },
  { tut: { title: 'Altaic languages' } },
  { tvl: { title: 'Tuvalu' } },
  { twi: { title: 'Twi' } },
  { tyv: { title: 'Tuvinian' } },
  { udm: { title: 'Udmurt' } },
  { uga: { title: 'Ugaritic' } },
  { uig: { title: 'Uighur; Uyghur' } },
  { ukr: { title: 'Ukrainian' } },
  { umb: { title: 'Umbundu' } },
  { und: { title: 'Undetermined' } },
  { urd: { title: 'Urdu' } },
  { uzb: { title: 'Uzbek' } },
  { vai: { title: 'Vai' } },
  { ven: { title: 'Venda' } },
  { vie: { title: 'Vietnamese' } },
  { vol: { title: 'Volapük' } },
  { vot: { title: 'Votic' } },
  { wak: { title: 'Wakashan languages' } },
  { wal: { title: 'Wolaitta; Wolaytta' } },
  { war: { title: 'Waray' } },
  { was: { title: 'Washo' } },
  { cym: { title: 'Welsh' } },
  { wen: { title: 'Sorbian languages' } },
  { wln: { title: 'Walloon' } },
  { wol: { title: 'Wolof' } },
  { xal: { title: 'Kalmyk; Oirat' } },
  { xho: { title: 'Xhosa' } },
  { yao: { title: 'Yao' } },
  { yap: { title: 'Yapese' } },
  { yid: { title: 'Yiddish' } },
  { yor: { title: 'Yoruba' } },
  { ypk: { title: 'Yupik languages' } },
  { zap: { title: 'Zapotec' } },
  { zbl: { title: 'Blissymbols; Blissymbolics; Bliss' } },
  { zen: { title: 'Zenaga' } },
  { zgh: { title: 'Standard Moroccan Tamazight' } },
  { zha: { title: 'Zhuang; Chuang' } },
  { zho: { title: 'Chinese' } },
  { znd: { title: 'Zande languages' } },
  { zul: { title: 'Zulu' } },
  { zun: { title: 'Zuni' } },
  { zxx: { title: 'No linguistic content; Not applicable' } },
  { zza: { title: 'Zaza; Dimili; Dimli; Kirdki; Kirmanjki; Zazaki' } },
]

export const LanguageDropDownValues = constructPatientDemographicObject(
  languageFHIR,
  CodeSystemOIDs.FHIRHl7ValueSetLanguages,
  Categories.LANGUAGE
)
