import cx from 'classnames'
import 'moment-timezone'
import { Button, Col, Form, Table } from 'react-bootstrap'
import 'react-dates/initialize'

import { timeDiff } from '../../../../../../libs/dates'
import CustomTimePicker from '../../../../../../stories/BaseComponents/CustomTimePicker'
import { AutoSizingTextArea } from '../../../../../../stories/BaseComponents/TextAreaInput'
import AddOneVital from '../../Sections/AddOneVital'
import AddVitals from '../../Sections/AddVitals'
import ShowEntriesSpravato from '../../Tables/ShowEntriesSpravato'
import ShowMedicationSpravato from '../../Tables/ShowMedicationSpravato'
import ShowSavedVS from '../../Tables/ShowSavedVS'
import PatientSedation from './PatientSedation'
import SeriousAdverseEvent from './SeriousAdverseEvent'
import { generateSpravatoClassName } from './Spravato'

import 'react-dates/lib/css/_datepicker.css'

interface AddSpravatoMonitoringProps {
  disabled: boolean
  fields: any
  detail?: string
  shortPublicId: string
  saveEditAdditionalEntries: (index: number, editVitSign: any) => void
  saveAdditionalMedicationsDissociation: () => void
  saveEditEntries: (index: number, editVitSign: any) => void
  deleteEntries: (index: number) => void
  deleteAdditionalEntries: () => void
  saveEditAdditionalMedication: (index: number, editVitSign: any) => void
  deleteAM: (index: number) => void
  saveEditAdditionalMedicationSedation: (
    index: number,
    editVitSign: any
  ) => void
  deleteAMSedation: (index: number) => void
  saveEditAdditionalMedicationDissociation: (
    index: number,
    editVitSign: any
  ) => void
  deleteAMDissociation: (index: number) => void
  deleteVitals: (index: number) => void
  setAddVitals: () => void
  saveNewVitals: () => void
  saveNewCaretakerVitals: () => void
  saveAdditionalMedicationsSedation: () => void
  saveNewAdditionalEntriesSpravato: () => void
  saveNewMedicationSpravato: () => void
  saveFortyMinSpravatoVitals: (editVitSign: any) => void
  saveEndSpravatoVitals: (editVitSign: any) => void
  saveEditVitalSigns: (index: number, editVitSign: any) => void
  handleFieldChange: (obj: any) => void
}

export const totalMonitoringTime = (note: any) => {
  const { SpravatoStartTime, SpravatoEndTime } = note
  if (!SpravatoStartTime || !SpravatoEndTime) return 0
  const diff = timeDiff(SpravatoStartTime, SpravatoEndTime, 'minutes')
  if (diff < 0) {
    // end > start, assume times cross days and add 24h to flip the sign
    return 24 * 60 + diff
  }
  return diff
}

export default function SpravatoMonitoring(props: AddSpravatoMonitoringProps) {
  const areThereAdditionalSavedEntries =
    props.fields.SavedAdditionalEntries &&
    props.fields.SavedAdditionalEntries.length !== 0
  const areThereSavedAdditionalMedications =
    props.fields.SavedAdditionalMedications &&
    props.fields.SavedAdditionalMedications.length !== 0
  const areThereSavedSedationMedications =
    props.fields.SavedAdditionalMedicationsSedation &&
    props.fields.SavedAdditionalMedicationsSedation.length !== 0
  const areThereSavedDissociationMedications =
    props.fields.SavedAdditionalMedicationsDissociation &&
    props.fields.SavedAdditionalMedicationsDissociation.length !== 0
  const areThereSavedVitalSigns =
    props.fields.SavedVitalSigns && props.fields.SavedVitalSigns.length !== 0
  const totalTime = totalMonitoringTime(props.fields)

  function handleShowAdditionalEntries() {
    return (
      areThereAdditionalSavedEntries && (
        <>
          {props.fields.SavedAdditionalEntries.map(
            (entry: any, index: number) => {
              return (
                <ShowEntriesSpravato
                  key={index}
                  index={index}
                  Type={entry.Type}
                  Date={entry.Date}
                  Description={entry.Description}
                  Resolution={entry.Resolution}
                  saveEditEntries={props.saveEditAdditionalEntries}
                  deleteEntries={props.deleteAdditionalEntries}
                  disabled={props.disabled}
                  entriesType={props.fields.SavedAdditionalEntries}
                />
              )
            }
          )}
        </>
      )
    )
  }

  function handleShowMedication() {
    return (
      areThereSavedAdditionalMedications && (
        <>
          {props.fields.SavedAdditionalMedications.map(
            (entry: any, index: number) => {
              return (
                <ShowMedicationSpravato
                  key={index}
                  index={index}
                  Name={entry.Name}
                  Dose={entry.Dose}
                  Time={entry.Time}
                  Comments={entry.Comments}
                  saveEditAdditionalMedication={
                    props.saveEditAdditionalMedication
                  }
                  deleteAM={props.deleteAM}
                  disabled={props.disabled}
                />
              )
            }
          )}
        </>
      )
    )
  }

  function handleShowMedicationSedation() {
    return (
      areThereSavedSedationMedications && (
        <>
          {props.fields.SavedAdditionalMedicationsSedation.map(
            (entry: any, index: number) => {
              return (
                <ShowMedicationSpravato
                  key={index}
                  index={index}
                  Name={entry.Name}
                  Dose={entry.Dose}
                  Time={entry.Time}
                  Comments={entry.Comments}
                  saveEditAdditionalMedication={
                    props.saveEditAdditionalMedicationSedation
                  }
                  deleteAM={props.deleteAMSedation}
                  disabled={props.disabled}
                  medicationType={
                    props.fields.SavedAdditionalMedicationsSedation
                  }
                />
              )
            }
          )}
        </>
      )
    )
  }

  function handleShowMedicationDissociation() {
    return (
      areThereSavedDissociationMedications && (
        <>
          {props.fields.SavedAdditionalMedicationsDissociation.map(
            (entry: any, index: number) => {
              return (
                <ShowMedicationSpravato
                  key={index}
                  index={index}
                  Name={entry.Name}
                  Dose={entry.Dose}
                  Time={entry.Time}
                  Comments={entry.Comments}
                  saveEditAdditionalMedication={
                    props.saveEditAdditionalMedicationDissociation
                  }
                  deleteAM={props.deleteAMDissociation}
                  disabled={props.disabled}
                  medicationType={
                    props.fields.SavedAdditionalMedicationsDissociation
                  }
                />
              )
            }
          )}
        </>
      )
    )
  }

  function handleShowSavedVitalsSpravato() {
    return (
      areThereSavedVitalSigns && (
        <>
          {props.fields.SavedVitalSigns.map((vitals: any, index: number) => {
            return (
              <ShowSavedVS
                key={index}
                index={index}
                deleteVitals={props.deleteVitals}
                VitalSignsType={vitals.VitalSignsType}
                Time={vitals.Time}
                HR={vitals.HR}
                BP={vitals.BP}
                BpArm={vitals.BpArm}
                RR={vitals.RR}
                SPO2={vitals.SPO2}
                Comments={vitals.Comments}
                EKG={vitals.EKG}
                RASSscore={vitals.RASSscore}
                Temp={vitals.Temp}
                saveEditVitalSigns={props.saveEditVitalSigns}
                disabled={props.disabled}
                noteType={props.fields.NoteType}
                discharge
              />
            )
          })}
        </>
      )
    )
  }
  return (
    <>
      <div
        className="center info"
        style={{ fontWeight: 'bold', paddingBottom: '1em' }}
      >
        40 Minutes Post-Administration Vitals
      </div>
      <AddOneVital
        Time={props.fields.FortyMinSpravatoVitals.Time}
        HR={props.fields.FortyMinSpravatoVitals.HR}
        BP={props.fields.FortyMinSpravatoVitals.BP}
        RR={props.fields.FortyMinSpravatoVitals.RR}
        Comments={props.fields.FortyMinSpravatoVitals.Comments}
        Temp={props.fields.FortyMinSpravatoVitals.Temp}
        fields={props.fields}
        saveOneVital={props.saveFortyMinSpravatoVitals}
        disabled={props.disabled}
        noteType={props.fields.NoteType}
        id="spravato-forty-min-vital"
        requireBP
      />
      <br />
      <div
        className="center info"
        style={{ fontWeight: 'bold', paddingBottom: '1em' }}
      >
        Additional Vitals
      </div>
      <Table responsive>
        {(props.fields.SavedVitalSigns.length !== 0 || props.detail) && (
          <thead>
            <tr>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '140px',
                }}
              >
                Time
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                HR
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                BP
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                RR
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                Temp
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '360px',
                }}
              >
                Comments
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '140px',
                }}
              >
                &nbsp;
              </th>
            </tr>
          </thead>
        )}
        <tbody>{handleShowSavedVitalsSpravato()}</tbody>
      </Table>
      <AddVitals
        shortPublicId={props.shortPublicId}
        disabled={props.disabled}
        setAddVitals={props.setAddVitals}
        savedVitalSigns={props.fields.SavedVitalSigns}
        bPPlacement={props.fields.BpPlacement}
        handleSaveVitals={props.saveNewVitals}
        handleSaveCaretakerVitals={props.saveNewCaretakerVitals}
        handleFieldChange={(value) => {
          props.handleFieldChange({ ...props.fields, BpPlacement: value })
        }}
      />
      {!props.disabled && (
        <Form.Text
          className="center hideDuringPrint"
          style={{ fontWeight: 'bold', color: '#A9A9A9' }}
        >
          Small screens may need to scroll horizontally for a full view of
          medication and vitals.
        </Form.Text>
      )}
      <hr />
      <div className="subsection-header">Sedation:</div>
      {/* Sedation */}
      <PatientSedation
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        showMeds={handleShowMedicationSedation}
        saveMeds={props.saveAdditionalMedicationsSedation}
        disabled={props.disabled}
        detail
        eventType="SpravatoSedation"
        entriesType="SavedAdditionalMedicationsSedation"
      />
      {/* Dissociation */}
      <PatientSedation
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        showMeds={handleShowMedicationDissociation}
        saveMeds={props.saveAdditionalMedicationsDissociation}
        disabled={props.disabled}
        detail
        eventType="SpravatoDissociation"
        entriesType="SavedAdditionalMedicationsDissociation"
      />
      <Table responsive>
        {props.fields.SavedAdditionalMedications.length !== 0 && (
          <thead>
            <tr>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '136px',
                }}
              >
                Medication Name
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                Dose
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                Time
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '150px',
                }}
              >
                Comments
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '140px',
                }}
              >
                &nbsp;
              </th>
            </tr>
          </thead>
        )}
        <tbody>{handleShowMedication()}</tbody>
      </Table>
      {!props.disabled && (
        <Form.Row>
          <Form.Group
            as={Col}
            style={{ textAlign: 'center', marginLeft: 'auto' }}
          >
            <Button
              bsPrefix="plain-button center info button-label"
              onClick={() => {
                props.saveNewMedicationSpravato()
              }}
            >
              + Add additional medications administered during treatment
            </Button>
          </Form.Group>
        </Form.Row>
      )}
      <hr />
      <div className="subsection-header">Serious Adverse Events:</div>
      <SeriousAdverseEvent
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        showEntries={handleShowAdditionalEntries}
        saveEntries={props.saveNewAdditionalEntriesSpravato}
        disabled={props.disabled}
        eventType="SpravatoAdverseEventDuring"
        entriesType="SavedAdditionalEntries"
      />
      <hr />
      <div
        className="center info"
        style={{ fontWeight: 'bold', paddingBottom: '1em' }}
      >
        Vitals prior to treatment session completion
      </div>
      <AddOneVital
        Time={props.fields.EndSpravatoVitals.Time}
        HR={props.fields.EndSpravatoVitals.HR}
        BP={props.fields.EndSpravatoVitals.BP}
        RR={props.fields.EndSpravatoVitals.RR}
        Comments={props.fields.EndSpravatoVitals.Comments}
        Temp={props.fields.EndSpravatoVitals.Temp}
        fields={props.fields}
        saveOneVital={props.saveEndSpravatoVitals}
        disabled={props.disabled}
        noteType={props.fields.NoteType}
        discharge
        start
        id="spravato-end-vital"
        requireBP
      />
      <Form.Row style={{ width: 'fit-content' }}>
        <Form.Group
          className={
            props.fields.SpravatoSessionRange === null
              ? generateSpravatoClassName()
              : ''
          }
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          controlId="SpravatoSessionRange"
        >
          <Form.Label className="font-weight-bold">
            Were vitals prior to treatment session completion in acceptable
            range
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.SpravatoSessionRange)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="SpravatoSessionRangeYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoSessionRange: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.SpravatoSessionRange) === false}
            style={{ marginLeft: '10px' }}
            value="false"
            id="SpravatoSessionRangeNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoSessionRange: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <hr />
      <div className="subsection-header">Session information:</div>
      <div
        data-testid="spravato-time-container"
        // Highlight fields together so error is more prominent
        className={cx(totalTime < 120 ? generateSpravatoClassName() : '')}
        // Fix padding to match other fields
        style={{
          marginLeft: '-5px',
          marginRight: '-5px',
          paddingLeft: '5px',
          paddingRight: '5px',
        }}
      >
        <Form.Row>
          <Form.Group
            id="spravato-time-completion"
            as={Col}
            xs={12}
            sm={12}
            lg={12}
            xl={12}
            controlId="SpravatoEndTime"
          >
            <Form.Label data-testid="spravato-end-time-label">
              <span className="font-weight-bold">
                Time of completion of monitoring.&nbsp;
              </span>
              Patient must be monitored for at least 2 hours
            </Form.Label>
            <br />

            <div
              // Manually add bs classes for responsive
              className={cx('col-xs-12', 'col-sm-12', 'col-lg-6', 'col-xl-4')}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <CustomTimePicker
                value={props.fields.SpravatoEndTime}
                onChange={(value: any) => {
                  props.handleFieldChange({
                    ...props.fields,
                    SpravatoEndTime: value,
                  })
                }}
                className="time-picker"
                disabled={props.disabled}
              />
            </div>
          </Form.Group>
        </Form.Row>
        <Form.Row
          style={{
            width: 'fit-content',
          }}
        >
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            lg={12}
            xl={12}
            controlId="SpravatoTotalTime"
          >
            <Form.Label data-testid="spravato-total-time-label">
              <span className="font-weight-bold">Total Time:&nbsp;</span>
              {`${totalTime} minutes`}
            </Form.Label>
            <br />
          </Form.Group>
        </Form.Row>
      </div>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          controlId="SessionMonitoringTime"
        >
          <Form.Label style={{ fontWeight: 'bold', marginBottom: '-20px' }}>
            If there was not a 2-hour minimum monitoring requirement, when would
            this patient have been ready to leave/no longer require monitoring?
          </Form.Label>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={4} sm={4} md={4} lg={4} xl={4}>
          <Form.Control
            id="session-monitoring-time"
            bsPrefix={
              props.fields.SessionMonitoringTime === ''
                ? generateSpravatoClassName(true)
                : 'form-input'
            }
            type="number"
            value={props.fields.SessionMonitoringTime}
            disabled={props.disabled}
            onWheel={(event: React.WheelEvent<HTMLInputElement>) =>
              event.currentTarget.blur()
            }
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SessionMonitoringTime: event.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} xs={8} sm={8} lg={8} xl={8}>
          <Form.Label style={{ marginTop: '5px' }}>
            minutes from start of administration
          </Form.Label>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label className="font-weight-bold">
            Personnel for monitoring/observation (verifying data is accurate)
          </Form.Label>
          <Form.Control
            bsPrefix="form-input"
            value={props.fields.SessionPersonnelNotes}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SessionPersonnelNotes: event.target.value,
              })
            }
            disabled={props.disabled}
            as="textarea"
            rows={1}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label className="font-weight-bold">
            Additional notes, side effects, or observations
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.SessionAdditionalNotes}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SessionAdditionalNotes: event.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
