import { UseQueryResult, useQuery } from '@tanstack/react-query'

import {
  getPatientInsuranceCards,
  loadProviderSidePatientData,
} from '../../api/api-lib'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'
import { ProviderSidePatientData } from '../../shared-types'

export const useProviderSidePatientData = (
  patientId: string
): UseQueryResult<ProviderSidePatientData> => {
  return useQuery<ProviderSidePatientData>(
    [patientDataTypes.ProviderSidePatientData, patientId],
    {
      queryFn: async () => {
        const insurance = await getPatientInsuranceCards(patientId)
        const results = await loadProviderSidePatientData(patientId)
        results.insurance = insurance
        return results
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
