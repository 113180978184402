export const getItemClassName = ({
  className = '',
  link = '',
  isSelected = false,
}) => {
  const isSelectedClassActive: boolean =
    Boolean(location.pathname === link) || isSelected

  return `${className}${
    isSelectedClassActive ? ' ant-menu-item-selected  nav-selected' : ''
  }`
}

export const generateKey = ({
  mode = 'item',
  id = '',
  title = '',
  isExternalLink = false,
}) =>
  `${mode}-${id || 'no-id'}-${title || 'divider'}${
    isExternalLink ? '-is-external-link' : ''
  }`
