import React, { useState } from 'react'

import { ArrowLeftOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { useHistory } from 'react-router-dom'

import { INVOICE_FORM_MODES } from '../../libs/constants'
import { Button } from '../BaseComponents'

import styles from './Invoice.module.scss'

export enum DISCARD_INVOICE_MODAL_CONFIG {
  BACK_ARROW = 'back_arrow',
  DISCARD_BUTTON = 'discard_button',
}

type DeleteInvoiceModalProps = {
  invoiceNumber?: number
  patientId?: string
  providerId?: string
  mode: string
  config: DISCARD_INVOICE_MODAL_CONFIG
  isDiscardDisabled: boolean
}

export const DiscardInvoiceModal = ({
  invoiceNumber,
  patientId,
  providerId,
  mode,
  config,
  isDiscardDisabled,
}: DeleteInvoiceModalProps) => {
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const titleText =
    mode === INVOICE_FORM_MODES.CREATE
      ? `Discard invoice #${invoiceNumber}?`
      : `Discard changes?`
  const confirmationText =
    mode === INVOICE_FORM_MODES.CREATE
      ? `Are you sure you want to discard this invoice? Changes will be not be saved.`
      : `Are you sure you want to discard changes to this invoice? Changes will be not be saved.`

  const handleOk = () => {
    history.push(
      `patient/billing?patientId=${patientId}&providerId=${providerId}`
    )
  }

  const handleClick = () => {
    if (mode === INVOICE_FORM_MODES.VIEW) {
      history.push(
        `patient/billing?patientId=${patientId}&providerId=${providerId}`
      )
    } else {
      setVisible(true)
    }
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <>
      {config === DISCARD_INVOICE_MODAL_CONFIG.BACK_ARROW ? (
        <ArrowLeftOutlined className={styles.backIcon} onClick={handleClick} />
      ) : (
        <Button onClick={handleClick} disabled={isDiscardDisabled}>
          Discard
        </Button>
      )}
      <Modal
        title={titleText}
        visible={visible}
        onOk={handleOk}
        confirmLoading={false}
        onCancel={handleCancel}
      >
        <p>{confirmationText}</p>
      </Modal>
    </>
  )
}
