interface PsychHistoryCodeset {
  [any: string]: {
    title: string
    codeSystemOid?: string
    SNOMEDCode?: string
  }
}

type PsychHistoryCodesets = PsychHistoryCodeset[]

const anxietyCodeset: PsychHistoryCodesets = [
  { F419: { title: 'Anxiety', SNOMEDCode: '197480006' } },
  { F411: { title: 'Generalized Anxiety Disorder', SNOMEDCode: '21897009' } },
  {
    F422: {
      title: 'Obsessive-Compulsive Disorder (OCD)',
      SNOMEDCode: '191736004',
    },
  },
  { F410: { title: 'Panic Disorder', SNOMEDCode: '371631005' } },
  { F409: { title: 'Phobias', SNOMEDCode: '386810004' } },
]

const depressionCodeset: PsychHistoryCodesets = [
  { F32A: { title: 'Depression', SNOMEDCode: '35489007' } },
  { F329: { title: 'Major Depressive Disorder', SNOMEDCode: '370143000' } },
  { F530: { title: 'Postpartum Depression', SNOMEDCode: '58703003' } },
  {
    'F32A:TRD': {
      title: 'Treatment-Resistant Depression',
      SNOMEDCode: '1153570009',
    },
  },
]

const eatingDisorderCodeset: PsychHistoryCodesets = [
  { F509: { title: 'Eating Disorder', SNOMEDCode: '72366004' } },
  { F5000: { title: 'Anorexia Nervosa', SNOMEDCode: '56882008' } },
  { F5081: { title: 'Binge Eating Disorder', SNOMEDCode: '439960005' } },
  { F502: { title: 'Bulimia Nervosa', SNOMEDCode: '78004001' } },
]

const personalityDisorderCodeset: PsychHistoryCodesets = [
  { F609: { title: 'Personality Disorder', SNOMEDCode: '33449004' } },
  {
    F602: { title: 'Antisocial Personality Disorder', SNOMEDCode: '55341008' },
  },
  { F606: { title: 'Avoidant Personality Disorder', SNOMEDCode: '37746008' } },
  {
    F603: { title: 'Borderline Personality Disorder', SNOMEDCode: '20010003' },
  },
  {
    F604: { title: 'Histrionic Personality Disorder', SNOMEDCode: '55341008' },
  },
  {
    F6081: {
      title: 'Narcissistic Personality Disorder',
      SNOMEDCode: '80711002',
    },
  },
  {
    F605: {
      title: 'Obsessive Compulsive Personality Disorder',
      SNOMEDCode: '1376001',
    },
  },
  { F600: { title: 'Paranoid Personality Disorder', SNOMEDCode: '13601005' } },
  { F601: { title: 'Schizoid Personality Disorder', SNOMEDCode: '52954000' } },
  {
    F21: { title: 'Schizotypal Personality Disorder', SNOMEDCode: '31027006' },
  },
]

const substanceUseDisorderCodeset: PsychHistoryCodesets = [
  { F1920: { title: 'Substance Use Disorder', SNOMEDCode: '724724008' } },
  {
    F1020: {
      title: 'Alcohol Use Disorder (Alcoholism)',
      SNOMEDCode: '7200002',
    },
  },
  { F1220: { title: 'Cannabis Use Disorder', SNOMEDCode: '723933008' } },
  { F1120: { title: 'Opioid Use Disorder', SNOMEDCode: '724653003' } },
  {
    F1399: {
      title: 'Sedative, Hypnotic and Anxiolytic Use Disorder',
      SNOMEDCode: '312098001',
    },
  },
  { F1520: { title: 'Stimulant Use Disorder', SNOMEDCode: '442406005' } },
]

const generalPsychConditionsCodeset: PsychHistoryCodesets = [
  {
    F902: { title: 'Attention Deficit Disorder (ADD)', SNOMEDCode: '35253001' },
  },
  {
    F901: {
      title: 'Attention Deficit Hyperactivity Disorder (ADHD)',
      SNOMEDCode: '31177006',
    },
  },
  {
    F840: { title: 'Autistic Spectrum Disorder (ASD)', SNOMEDCode: '35919005' },
  },
  { F319: { title: 'Bipolar I', SNOMEDCode: '371596008' } },
  { F3181: { title: 'Bipolar II', SNOMEDCode: '83225003' } },
  { F0390: { title: 'Dementia', SNOMEDCode: '52448006' } },
  { F819: { title: 'Learning Disorder', SNOMEDCode: '1855002' } },
  {
    F4310: {
      title: 'Post-Traumatic Stress Disorder (PTSD)',
      SNOMEDCode: '47505003',
    },
  },
  { F29: { title: 'Psychosis', SNOMEDCode: '69322001' } },
  { F259: { title: 'Schizoaffective Disorder', SNOMEDCode: '68890003' } },
  { F209: { title: 'Schizophrenia', SNOMEDCode: '58214004' } },
  { R45851: { title: 'Suicidal Ideation', SNOMEDCode: '6471006' } },
]

const ssriCodeset: PsychHistoryCodesets = [
  {
    '2556': {
      title: 'Celexa (citalopram)',
      codeSystemOid: 'citalopram',
    },
  },
  {
    '321988': {
      title: 'Lexapro (escitalopram)',
      codeSystemOid: 'escitalopram',
    },
  },
  {
    '42355': {
      title: 'Luvox (fluvoxamine)',
      codeSystemOid: 'fluvoxamine',
    },
  },
  {
    '32937': {
      title: 'Paxil (paroxetine)',
      codeSystemOid: 'paroxetine',
    },
  },
  {
    '4493': {
      title: 'Prozac (fluoxetine)',
      codeSystemOid: 'fluoxetine',
    },
  },
  {
    '1455099': {
      title: 'Trintellix (vortioxetine)',
      codeSystemOid: 'vortioxetine',
    },
  },
  {
    '1086769': {
      title: 'Viibryd (vilazodone)',
      codeSystemOid: 'vilazodone',
    },
  },
  {
    '36437': {
      title: 'Zoloft (sertraline)',
      codeSystemOid: 'sertraline',
    },
  },
]

const snriCodeset: PsychHistoryCodesets = [
  {
    '72625': {
      title: 'Cymbalta (duloxetine)',
      codeSystemOid: 'duloxetine',
    },
  },
  {
    '39786': {
      title: 'Effexor (venlafaxine)',
      codeSystemOid: 'venlafaxine',
    },
  },
  {
    '39786:XR': {
      title: 'Effexor XR (venlafaxine)',
      codeSystemOid: 'venlafaxine',
    },
  },
  {
    '1433212': {
      title: 'Fetzima (levomilnacipran)',
      codeSystemOid: 'levomilnacipran',
    },
  },
  {
    '734064': {
      title: 'Pristiq (desvenlafaxine)',
      codeSystemOid: 'desvenlafaxine',
    },
  },
]

const ndriCodeset: PsychHistoryCodesets = [
  {
    '42347': {
      title: 'Wellbutrin (bupropion)',
      codeSystemOid: 'bupropion',
    },
  },
  {
    '42347:SR': {
      title: 'Wellbutrin SR (bupropion)',
      codeSystemOid: 'bupropion',
    },
  },
  {
    '42347:XL': {
      title: 'Wellbutrin XL (bupropion)',
      codeSystemOid: 'bupropion',
    },
  },
]

const maoiCodeset: PsychHistoryCodesets = [
  {
    '9639': {
      title: 'Emsam (selegiline)',
      codeSystemOid: 'selegiline',
    },
  },
  {
    '6011': {
      title: 'Marplan (isocarboxazid)',
      codeSystemOid: 'isocarboxazid',
    },
  },
  {
    '8123': {
      title: 'Nardil (phenelzine)',
      codeSystemOid: 'phenelzine',
    },
  },
  {
    '10734': {
      title: 'Parnate (tranylcypromine)',
      codeSystemOid: 'tranylcypromine',
    },
  },
]

const tcaCodeset: PsychHistoryCodesets = [
  {
    '722': {
      title: 'Amoxapine',
      codeSystemOid: 'amoxapine',
    },
  },
  {
    '2597': {
      title: 'Anafranil (clomipramine)',
      codeSystemOid: 'clomipramine',
    },
  },
  {
    '3638': {
      title: 'Doxepin',
      codeSystemOid: 'doxepin',
    },
  },
  {
    '704': {
      title: 'Elavil (amitriptyline)',
      codeSystemOid: 'amitriptyline',
    },
  },
  {
    '6646': {
      title: 'Maprotiline',
      codeSystemOid: 'maprotiline',
    },
  },
  {
    '3247': {
      title: 'Norpramin (desipramine)',
      codeSystemOid: 'desipramine',
    },
  },
  {
    '7531': {
      title: 'Pamelor (nortriptyline)',
      codeSystemOid: 'nortriptyline',
    },
  },
  {
    '8886': {
      title: 'Protriptyline',
      codeSystemOid: 'protriptyline',
    },
  },
  {
    '10834': {
      title: 'Surmontil (trimipramine)',
      codeSystemOid: 'trimipramine',
    },
  },
  {
    '5691': {
      title: 'Tofranil (Imipramine)',
      codeSystemOid: 'imipramine',
    },
  },
]

const otherAntidepressants: PsychHistoryCodesets = [
  {
    '261126': {
      title: 'Auvelity (Bupropion/dextromethorphan)',
      codeSystemOid: 'bupropion_dextromethorphan',
    },
  },
  {
    '10737': {
      title: 'Desyrel (trazodone)',
      codeSystemOid: 'trazodone',
    },
  },
  {
    '15996': {
      title: 'Remeron (mirtazapine)',
      codeSystemOid: 'mirtazapine',
    },
  },
  {
    '31565': {
      title: 'Serzone (nefazodone)',
      codeSystemOid: 'nefazodone',
    },
  },
  {
    '2119365': {
      title: 'Spravato (esketamine)',
      codeSystemOid: 'esketamine',
    },
  },
  {
    '2121777': {
      title: 'Zulresso (brexanolone)',
      codeSystemOid: 'brexanolone',
    },
  },
  {
    '2669': {
      title: 'Zurzuvae (zuranolone)',
      codeSystemOid: 'zuranolone',
    },
  },
]

const antipsychoticsCodeset: PsychHistoryCodesets = [
  {
    '89013': {
      title: 'Abilify (aripiprazole)',
      codeSystemOid: 'aripiprazole',
    },
  },
  {
    '1673265': {
      title: 'Aristada (aripiprazole lauroxil)',
      codeSystemOid: 'aripiprazole lauroxil',
    },
  },
  {
    '2626': {
      title: 'Clozaril (clozapine)',
      codeSystemOid: 'clozapine',
    },
  },
  {
    '115698': {
      title: 'Geodon (ziprasidone)',
      codeSystemOid: 'ziprasidone',
    },
  },
  {
    '5093': {
      title: 'Haldol (haloperidol)',
      codeSystemOid: 'haloperidol',
    },
  },
  {
    '679314': {
      title: 'Invega (paliperidone)',
      codeSystemOid: 'paliperidone',
    },
  },
  {
    '1040028': {
      title: 'Latuda (lurasidone)',
      codeSystemOid: 'lurasidone',
    },
  },
  {
    '4496': {
      title: 'Prolixin (fluphenazine)',
      codeSystemOid: 'fluphenazine',
    },
  },
  {
    '1658314': {
      title: 'Rexulti (brexpiprazole)',
      codeSystemOid: 'brexpiprazole',
    },
  },
  {
    '35636': {
      title: 'Risperdal (risperidone)',
      codeSystemOid: 'risperidone',
    },
  },
  {
    '784649': {
      title: 'Saphris (asenapine)',
      codeSystemOid: 'asenapine',
    },
  },
  {
    '51272': {
      title: 'Seroquel (quetiapine)',
      codeSystemOid: 'quetiapine',
    },
  },
  {
    '10510': {
      title: 'Thiothixene',
      codeSystemOid: 'thiothixene',
    },
  },
  {
    '1667655': {
      title: 'Vraylar (cariprazine)',
      codeSystemOid: 'cariprazine',
    },
  },
  {
    '61381': {
      title: 'Zyprexa (olanzapine)',
      codeSystemOid: 'olanzapine',
    },
  },
]

const stabilizersCodeset: PsychHistoryCodesets = [
  {
    '40254': {
      title: 'Depakote (valproate)',
      codeSystemOid: 'valproate',
    },
  },
  {
    '28439': {
      title: 'Lamictal (lamotrigine)',
      codeSystemOid: 'lamotrigine',
    },
  },
  {
    '6448': {
      title: 'Lithium',
      codeSystemOid: 'lithium',
    },
  },
  {
    '2002': {
      title: 'Tegretol (carbemazepine)',
      codeSystemOid: 'carbamazepine',
    },
  },
  {
    '38404': {
      title: 'Topamax (topiramate)',
      codeSystemOid: 'topiramate',
    },
  },
]

const otherAugCodeset: PsychHistoryCodesets = [
  {
    '1827': {
      title: 'Buspar (buspirone)',
      codeSystemOid: 'buspirone',
    },
  },
  {
    '10814': {
      title: 'Cytomel (liothyronine)',
      codeSystemOid: 'liothyronine',
    },
  },
  {
    '746741': {
      title: 'Mirapex (pramipexole)',
      codeSystemOid: 'pramipexole',
    },
  },
  {
    '1592257': {
      title: 'Deplin (l-methylfolate)',
      codeSystemOid: 'levomefolate',
    },
  },
]

const sedativeCodeset: PsychHistoryCodesets = [
  {
    '39993': {
      title: 'Ambien (zolpidem)',
      codeSystemOid: 'zolpidem',
    },
  },
  {
    '461016': {
      title: 'Lunesta (eszopiclone)',
      codeSystemOid: 'eszopiclone',
    },
  },
  {
    '10355': {
      title: 'Restoril (temazepam)',
      codeSystemOid: 'temazepam',
    },
  },
  {
    '596205': {
      title: 'Rozerem (ramelteon)',
      codeSystemOid: 'ramelteon',
    },
  },
  {
    '74667': {
      title: 'Sonata (zaleplon)',
      codeSystemOid: 'zaleplon',
    },
  },
]

const adhdCodeset: PsychHistoryCodesets = [
  {
    '822929:ADD': {
      title:
        'Adderall (amphetamine aspartate / amphetamine sulfate / dextroamphetamine saccharate / dextroamphetamine sulfate)',
      codeSystemOid:
        'amphetamine aspartate / amphetamine sulfate / dextroamphetamine saccharate / dextroamphetamine sulfate',
    },
  },
  {
    '822929:ADD-XR': {
      title:
        'Adderall XR (amphetamine aspartate / amphetamine sulfate / dextroamphetamine saccharate / dextroamphetamine sulfate)',
      codeSystemOid:
        'amphetamine aspartate / amphetamine sulfate / dextroamphetamine saccharate / dextroamphetamine sulfate',
    },
  },
  {
    '2562192': {
      title:
        'Azstarys (serdexmethylphenidate and dexmethylphenidate) (dexmethylphenidate / serdexmethylphenidate)',
      codeSystemOid: 'dexmethylphenidate / serdexmethylphenidate',
    },
  },
  {
    '6901:CON': {
      title: 'Concerta (methylphenidate XR)',
      codeSystemOid: 'methylphenidate',
    },
  },
  {
    '6901:COT-XR-ODT': {
      title: 'Cotempla XR-ODT (methylphenidate)',
      codeSystemOid: 'methylphenidate',
    },
  },
  {
    '6901:DAY': {
      title: 'Daytrana (methylphenidate)',
      codeSystemOid: 'methylphenidate',
    },
  },
  {
    '725-ODT': {
      title: 'Evekeo ODT (amphetamine)',
      codeSystemOid: 'amphetamine',
    },
  },
  {
    '352372:XR': {
      title: 'Focalin XR (dexmethylphenidate)',
      codeSystemOid: 'dexmethylphenidate',
    },
  },
  {
    '40114': {
      title: 'Intuniv (guanfacine)',
      codeSystemOid: 'guanfacine',
    },
  },
  {
    '6901:JO-PM': {
      title: 'Jornay PM (methylphenidate)',
      codeSystemOid: 'methylphenidate',
    },
  },
  {
    '2599': {
      title: 'Kapvay (clonidine)',
      codeSystemOid: 'clonidine',
    },
  },
  {
    '6901:CD': {
      title: 'Metadate CD (methylphenidate)',
      codeSystemOid: 'methylphenidate',
    },
  },
  {
    '6901:ER': {
      title: 'Methylin ER (methylphenidate)',
      codeSystemOid: 'methylphenidate',
    },
  },
  {
    '822929:MYD': {
      title:
        'Mydayis (amphetamine aspartate / amphetamine sulfate / dextroamphetamine saccharate / dextroamphetamine sulfate)',
      codeSystemOid:
        'amphetamine aspartate / amphetamine sulfate / dextroamphetamine saccharate / dextroamphetamine sulfate',
    },
  },
  {
    '6901:QC-ER': {
      title: 'QuilliChew ER (methylphenidate)',
      codeSystemOid: 'methylphenidate',
    },
  },
  {
    '6901:RIT': {
      title: 'Ritalin (methylphenidate)',
      codeSystemOid: 'methylphenidate',
    },
  },
  {
    '38400': {
      title: 'Strattera (atomoxetine)',
      codeSystemOid: 'atomoxetine',
    },
  },
  {
    '700810': {
      title: 'Vyvanse (lisdexamfetamine)',
      codeSystemOid: 'lisdexamfetamine',
    },
  },
  {
    '3288': {
      title: 'Zenzedi (dextroamphetamine)',
      codeSystemOid: 'dextroamphetamine',
    },
  },
]

const antiAnxietyCodeset: PsychHistoryCodesets = [
  {
    '6470': {
      title: 'Ativan (lorazepam)',
      codeSystemOid: 'lorazepam',
    },
  },
  {
    '1827': {
      title: 'Buspar (buspirone)',
      codeSystemOid: 'buspirone',
    },
  },
  {
    '2598': {
      title: 'Klonopin (clonazepam)',
      codeSystemOid: 'clonazepam',
    },
  },
  {
    '2353': {
      title: 'Tranxene (clorazepate)',
      codeSystemOid: 'clorazepate',
    },
  },
  {
    '3322': {
      title: 'Valium (diazepam)',
      codeSystemOid: 'diazepam',
    },
  },
  {
    '596': {
      title: 'Xanax (alprazolam)',
      codeSystemOid: 'alprazolam',
    },
  },
  {
    '25480': {
      title: 'Neurontin (gabapentin)',
      codeSystemOid: 'gabapentin',
    },
  },
]

const glutamateModCodeset: PsychHistoryCodesets = [
  {
    '1040053': {
      title: 'Nuedexta (dextromethorphan / quinidine)',
      codeSystemOid: 'dextromethorphan / quinidine',
    },
  },
]

const substanceUseCodeset: PsychHistoryCodesets = [
  {
    '82819': {
      title: 'Acamprosate',
      codeSystemOid: 'acamprosate',
    },
  },
  {
    '3554': {
      title: 'Antabuse (disulfiram)',
      codeSystemOid: 'disulfiram',
    },
  },
  {
    '591622': {
      title: 'Chantix (varenicline)',
      codeSystemOid: 'varenicline',
    },
  },
  {
    '6813': {
      title: 'Methadone',
      codeSystemOid: 'methadone',
    },
  },
  {
    '7243:NAL': {
      title: 'Naltrexone',
      codeSystemOid: 'naltrexone',
    },
  },
  {
    '7243:VIV': {
      title: 'Vivitrol (naltrexone)',
      codeSystemOid: 'naltrexone',
    },
  },
  {
    '352364': {
      title: 'Suboxone (buprenorphine / naloxone)',
      codeSystemOid: 'buprenorphine / naloxone',
    },
  },
]

const miscMedCodeset: PsychHistoryCodesets = [
  {
    '2599': {
      title: 'Catapres (clonidine)',
      codeSystemOid: 'clonidine',
    },
  },
  {
    '8787': {
      title: 'Inderal (propranolol)',
      codeSystemOid: 'propranolol',
    },
  },
  {
    '8629': {
      title: 'Minipress (prazosin)',
      codeSystemOid: 'prazosin',
    },
  },
  {
    '5553': {
      title: 'Vistaril (hydroxyzine)',
      codeSystemOid: 'hydroxyzine',
    },
  },
]

export {
  ssriCodeset,
  snriCodeset,
  tcaCodeset,
  ndriCodeset,
  maoiCodeset,
  otherAntidepressants,
  antipsychoticsCodeset,
  stabilizersCodeset,
  otherAugCodeset,
  sedativeCodeset,
  adhdCodeset,
  antiAnxietyCodeset,
  glutamateModCodeset,
  substanceUseCodeset,
  miscMedCodeset,
  anxietyCodeset,
  depressionCodeset,
  personalityDisorderCodeset,
  eatingDisorderCodeset,
  substanceUseDisorderCodeset,
  generalPsychConditionsCodeset,
}

export type { PsychHistoryCodeset }
