import styles from './SummaryCard.module.scss'

export type SummaryCardProps = {
  description: string
  value: string
  isLoading?: boolean
  testId?: string
}

export const SummaryCard = ({
  description,
  value,
  isLoading,
  testId,
}: SummaryCardProps) => {
  return (
    <div className={styles.summaryCard} data-testid={testId}>
      <div className={styles.summaryCardDescription}>{description}</div>
      {isLoading ? <div className={styles.valueLoading} /> : <h4>{value}</h4>}
    </div>
  )
}
