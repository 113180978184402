import {
  HTMLAttributes,
  RefObject,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useQuery } from '@tanstack/react-query'
import {
  addDays,
  addMinutes,
  differenceInDays,
  differenceInSeconds,
  isAfter,
  isBefore,
  isEqual,
  setHours,
  setMinutes,
  subDays,
  subMinutes,
} from 'date-fns'
import moment from 'moment'
import 'moment-timezone'
import { View, momentLocalizer, stringOrDate } from 'react-big-calendar'
import { useHistory } from 'react-router-dom'

import { getAllPatients, getCalendarTimezone } from '../../../../api/api-lib'
import {
  changeAppointmentRequestStatus,
  getAppointments,
  getScheduledEvents,
} from '../../../../api/api-lib-typed'
import { DEFAULT_TIMEZONE } from '../../../../containers/Provider/availability/helpers'
import {
  AcceptedAppointmentParams,
  trackAppointmentRequestApprovals,
} from '../../../../libs/freshpaint/selfSchedulingEvents'
import { notification } from '../../../../libs/notificationLib'
import { isPatientActive } from '../../../../libs/utils'
import { useSchedulingContext } from '../../../../providers'
import {
  Event,
  Patient,
  ProviderAccountInfo,
  ScheduledEvent,
} from '../../../../shared-types'
import getCognitoUser from '../../../../shared/Helpers/getCognitoUser'
import { Col, Row, Spinner } from '../../../BaseComponents'
import LeftSidebarContainer from '../../../BaseComponents/LeftSidebar'
import { AppointmentDrawer } from '../../AppointmentsDrawer'
import CalendarNavigation from '../../CalendarNavigation'
import { CustomCalendar } from '../../CustomCalendar'
import { EventModal } from '../../EventModal'
import { INITIAL_PATIENT_ID_SELECTED } from '../../EventModal/EventModal'
import {
  ModalActions,
  Timezone,
  UNASSIGNED_CATEGORY,
  Views,
} from '../../constants'
import { formatTimeInUTCWithRounding } from '../../helpers'
import { EventCategory } from '../../types'
import { Filters } from '../Filters'
import {
  getAllGoogleCalendarIds,
  getDateBoundariesOfView,
  getEndOfNextMonthDate,
  getFetchedDateRanges,
  handleEventPropGetter,
  handleTooltip,
  hasPatientSelected,
  mapEventsToCalendar,
} from '../SchedulingPage.helpers'

export interface HandleSelectValues {
  start: Date
  end: Date
  resourceId?: string
}

export interface Settings {
  startHour: number
  endHour: number
}

interface SchedulingState {
  // UI state
  action: ModalActions
  dateSelected: Date // selected by the controls, as a JavaScript Date object
  monthSelected: number

  resourceId: string | undefined
}

export type SchedulingProps = {
  handleCollapseClick: () => void
  collapseButtonRef: RefObject<HTMLButtonElement>
  collapsed: boolean
  providerId: string
}

const { start: initialStart, end: initialEnd } = getFetchedDateRanges(undefined)

// Instantiating date's outside of the react context
// does not allow jest to mock the date object through
// jest.setSystemTime().
const initialUI: Omit<SchedulingState, 'dateSelected'> = {
  // UI state
  action: ModalActions.CREATE,
  monthSelected: moment().month(),
  resourceId: undefined,
}

const resetEvent: Event = {
  allDay: undefined,
  end: undefined,
  eventId: undefined,
  resource: undefined,
  resourceId: undefined,
  showTimespan: undefined,
  start: undefined,
  title: undefined,
}

const DEFAULT_SETTINGS: Settings = {
  startHour: 6,
  endHour: 21,
}

const SCHEDULING_VIEW_LOCAL_STORAGE_KEY = 'schedulingView'
const DEFAULT_SCHEDULING_VIEW = Views.DAY

export const hashEvents = ({ events }: { events: ScheduledEvent[] }) => {
  const newEvents: Record<string, ScheduledEvent> = {}
  events.forEach((event: ScheduledEvent) => {
    const uniqueGoogleEventId = `${event.googleId}_${event.GoogleCalendarAccountName}`
    newEvents[event.OsmindEventId || uniqueGoogleEventId] = event
  })
  return newEvents
}

export const SchedulingPageFunc = memo(
  ({
    providerId,
    collapseButtonRef,
    collapsed,
    handleCollapseClick,
  }: SchedulingProps) => {
    const colorMapRef = useRef<Map<string, string>>(new Map())
    // React big calendar will not re-render DayView after initial load
    // due to inefficiency of the component, ensuring that latest
    // current calendar user is set prior to mounting the calendar.
    const [isProviderInfoLoaded, setIsProviderInfoLoaded] = useState(false)
    const { data: allPatients, isLoading: isAllPatientsLoading } = useQuery<
      {
        PatientId: string
        PublicId: string
        PatientName: string
        IsActive: boolean
      }[]
    >(['schedulingPatients'], {
      queryFn: () => getAllPatients(true),
      refetchInterval: 0,
      refetchOnWindowFocus: false,
    })
    const { data: currentTimezone, isLoading: isTimezoneLoading } =
      useQuery<Timezone>(['schedulingTimezone'], {
        queryFn: () => getCalendarTimezone(),
        refetchInterval: 0,
        refetchOnWindowFocus: false,
      })
    const {
      data: appointmentRequests,
      isLoading: isAppointmentRequestsLoading,
      refetch: refetchAppointmentRequests,
    } = useQuery(['schedulingAppointmentRequests'], {
      queryFn: () =>
        getAppointments<false>({
          startDate: new Date().toISOString(),
          endDate: addDays(new Date(), 120).toISOString(),
          includeDetails: true,
          statuses: ['PENDING'],
        }),
      refetchInterval: 0,
      refetchOnWindowFocus: false,
    })
    const history = useHistory()
    //TODO: Unravel this state object over time
    const [schedulingState, setSchedulingState] = useState<SchedulingState>({
      ...initialUI,
      dateSelected: moment().toDate(),
    })
    const [fetchedStart, setFetchedStart] = useState(initialStart)
    const [fetchedEnd, setFetchedEnd] = useState(initialEnd)
    const {
      data: fetchedEvents,
      isLoading: isEventsLoading,
      isRefetching: isEventsRefetching,
      refetch: refetchEvents,
    } = useQuery<{
      accountsWithErrors: string[]
      events: ScheduledEvent[]
    }>(['schedulingEvents', fetchedStart, fetchedEnd, true], {
      queryFn: getScheduledEvents,
      refetchInterval: 0,
      refetchOnWindowFocus: false,
    })
    const { data: user, isLoading: isUserLoading } = useQuery(
      ['schedulingUser'],
      {
        queryFn: () => getCognitoUser(),
        refetchInterval: 0,
      }
    )
    const [patients, setPatients] = useState<Patient[]>([])
    const [patientList, setPatientList] = useState<Map<string, string>>(
      new Map()
    )
    const [isAcceptingIds, setIsAcceptingIds] = useState<Set<string>>(new Set())
    const [eventSelected, setEventSelected] = useState<Event>({})
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentCalendarUser, setCurrentCalendarUser] = useState('')
    const [numberOfAgendaDays, setNumberOfAgendaDays] = useState(
      moment(getEndOfNextMonthDate(new Date())).diff(new Date(), 'days')
    )
    const [viewSelected, setViewSelected] = useState(
      (localStorage.getItem(SCHEDULING_VIEW_LOCAL_STORAGE_KEY) as Views) ??
        DEFAULT_SCHEDULING_VIEW
    )

    const {
      teammatesSelected,
      roomsSelected,
      appointmentTypesSelected,
      isUnassignedSelected,
      handleUpdatePatientIdSelected,
      patientIdSelected,
      teammates,
      integratedExternalCalendarIds,
      teammateCalendars,
      rooms,
      locations,
      roomsSelectOptions,
      appointmentSettings,
      appointmentTypes,
      appointmentTypesOptionsSet,
      teammatesSelectOptions,
      isSchedulingDataLoading,
    } = useSchedulingContext()

    //Loading variables
    const isLoading =
      isTimezoneLoading ||
      isAppointmentRequestsLoading ||
      isAllPatientsLoading ||
      isEventsLoading ||
      isEventsRefetching ||
      isUserLoading ||
      !isProviderInfoLoaded ||
      isSchedulingDataLoading

    const allEvents = useMemo(() => {
      if (!fetchedEvents) {
        return {}
      }
      return hashEvents(fetchedEvents)
    }, [fetchedEvents])

    const events = useMemo(() => {
      return mapEventsToCalendar(
        allEvents,
        {
          appointmentTypesSelected,
          patientIdSelected,
          roomsSelected,
          teammatesSelected,
          isUnassignedSelected,
          appointmentTypesOptionsSet,
        },
        INITIAL_PATIENT_ID_SELECTED
      )
    }, [
      allEvents,
      appointmentTypesSelected,
      patientIdSelected,
      roomsSelected,
      teammatesSelected,
      isUnassignedSelected,
      appointmentTypesOptionsSet,
    ])

    // This is triggered when a user selects a Patient to view all events for that patient, or de-selects a patient
    const handleSelectPatient = async (patientId?: string): Promise<void> => {
      if (patientId === patientIdSelected) {
        // Do nothing
        return
      }

      //Patient has changed at this point
      if (patientId && patientId !== INITIAL_PATIENT_ID_SELECTED) {
        return handleUpdatePatientIdSelected(patientId)
      }

      setCurrentCalendarUser('')
      handleUpdatePatientIdSelected(INITIAL_PATIENT_ID_SELECTED)
    }

    const handleViewChange = (newView: View): void => {
      const { endDate: endDateInView, startDate: startDateInView } =
        getDateBoundariesOfView(schedulingState.dateSelected, newView)

      const { start: newFetchedStart, end: newFetchedEnd } =
        getFetchedDateRanges(schedulingState.dateSelected)

      if (
        isAfter(endDateInView, new Date(fetchedEnd)) ||
        isBefore(startDateInView, new Date(fetchedStart))
      ) {
        setFetchedEnd(newFetchedEnd)
        setFetchedStart(newFetchedStart)
        const newNumberOfAgendaDays = differenceInDays(
          new Date(newFetchedEnd),
          schedulingState.dateSelected
        )
        setNumberOfAgendaDays(newNumberOfAgendaDays)
      }

      if (
        newView === Views.AGENDA &&
        hasPatientSelected(patientIdSelected, INITIAL_PATIENT_ID_SELECTED)
      ) {
        handleSelectPatient(patientIdSelected)
      }

      setViewSelected(newView as Views)
      localStorage.setItem(SCHEDULING_VIEW_LOCAL_STORAGE_KEY, newView)
    }

    const handleCloseModal = async (action: ModalActions): Promise<void> => {
      if (action !== ModalActions.VIEW && action !== ModalActions.NONE) {
        setIsModalOpen(false)
        await refetchEvents()
        return setSchedulingState((currState) => ({
          ...currState,
          minFetchedStart: fetchedStart,
          maxFetchedEnd: fetchedEnd,
        }))
      } else {
        setEventSelected({ ...resetEvent })
        return setIsModalOpen(false)
      }
    }

    const handleCalendarNavigation = async (
      dateSelected: Date
    ): Promise<void> => {
      const monthSelected = moment(dateSelected).month()
      const { endDate: endDateInView, startDate: startDateInView } =
        getDateBoundariesOfView(dateSelected, viewSelected)

      const { start: newFetchedStart, end: newFetchedEnd } =
        getFetchedDateRanges(dateSelected)
      setSchedulingState((currState) => ({
        ...currState,
        monthSelected,
        dateSelected,
      }))

      if (
        isAfter(endDateInView, new Date(fetchedEnd)) ||
        isBefore(startDateInView, new Date(fetchedStart))
      ) {
        setFetchedEnd(newFetchedEnd)
        setFetchedStart(newFetchedStart)
        const newNumberOfAgendaDays = moment(newFetchedEnd).diff(
          moment(newFetchedStart),
          'days'
        )
        setNumberOfAgendaDays(newNumberOfAgendaDays)
        return
      }

      const newNumberOfAgendaDays = moment(fetchedEnd).diff(
        moment(dateSelected),
        'days'
      )
      setNumberOfAgendaDays(newNumberOfAgendaDays)
    }

    const updateAcceptingIds = (ids: Set<string>) => {
      setIsAcceptingIds(new Set(ids))
    }

    const handleAccept = async (
      appointmentId: string,
      {
        apptTypeId,
        apptTypeName,
        patientId,
        requestedApptTime,
        providerId,
      }: Omit<AcceptedAppointmentParams, 'clinicId' | 'eventName'>
    ) => {
      try {
        const newAcceptingIds = isAcceptingIds.add(appointmentId)
        updateAcceptingIds(newAcceptingIds)
        await changeAppointmentRequestStatus(appointmentId, {
          appointmentStatus: 'ACCEPTED',
        })
        trackAppointmentRequestApprovals({
          eventName: 'Accepted appointment request',
          apptTypeId,
          apptTypeName,
          patientId,
          requestedApptTime,
          providerId,
          clinicId: providerId,
        })
        notification('Appointment scheduled', 'success')
        await refetchEvents()
        await refetchAppointmentRequests()
      } catch (e) {
        console.error('Error accepting appointment: ', JSON.stringify(e))
        notification('Error scheduling appointment', 'error')
      } finally {
        const newAcceptingIds = new Set(isAcceptingIds)
        newAcceptingIds.delete(appointmentId)
        updateAcceptingIds(newAcceptingIds)
      }
    }

    const handleCloseDrawer = () => {
      setIsDrawerOpen(false)
    }

    // This event is triggered when a user selects an existing event
    const handleSelectEvent = (event: Event): void => {
      setEventSelected(event)
      setIsModalOpen(true)
      setSchedulingState((currState) => ({
        ...currState,
        action: ModalActions.VIEW,
        resourceId: event.resourceId,
      }))
    }

    // This event is triggered when a user selects an empty time/date slot on the calendar
    // TODO: PRAC-2115: To be covered when adding event creation tests per ticket.
    /* istanbul ignore next */
    const handleSelectSlot = ({
      start,
      end,
      resourceId,
    }: HandleSelectValues): void => {
      // If in Day View and the user attempts to create an event
      // in google swimlane, alert them
      if (
        !eventSelected?.resource &&
        viewSelected === Views.DAY &&
        getAllGoogleCalendarIds(teammateCalendars).includes(resourceId || '')
      ) {
        notification(
          'You can only create and edit Google calendar events from your Google account.',
          'info'
        )
        return
      }

      let startTime = new Date(start)

      if (viewSelected === Views.MONTH) {
        const now = new Date()
        startTime.setMinutes(now.getMinutes())
        startTime.setHours(now.getHours())
        const { day } = formatTimeInUTCWithRounding(startTime)

        startTime = day
      }

      let endTime = new Date(end)

      const isEmptySlot = isEqual(startTime, endTime)
      const duration = Math.abs(differenceInSeconds(startTime, endTime))

      const DAY_IN_SECONDS = 86400
      const areMultipleDaysSelected = duration > DAY_IN_SECONDS
      const isOneDayExactly = duration === DAY_IN_SECONDS

      // selecting multiple days adds an extra day when saved
      // thus, we need to remove a day (or 15 minutes if only one day)
      if (areMultipleDaysSelected) endTime = subDays(endTime, 1)
      else if (isOneDayExactly) endTime = subMinutes(endTime, 15)

      if (viewSelected === Views.MONTH && isEmptySlot) {
        setViewSelected(Views.DAY)
        return setSchedulingState((currState) => ({
          ...currState,
          dateSelected: startTime,
        }))
      }

      let newEventSelected: {
        start: Date
        end: Date
      } = {
        start: startTime,
        end: endTime,
      }

      // If its an empty slot, then the time will be 0:00, so instead we will set
      // the times at the beginning of the day
      if (isEmptySlot) {
        let startOfDay = setHours(startTime, DEFAULT_SETTINGS.startHour)
        startOfDay = setMinutes(startOfDay, 0)
        newEventSelected = {
          start: startOfDay,
          end: addMinutes(startOfDay, 30),
        }
      }

      setSchedulingState((currState) => ({
        ...currState,
        action: ModalActions.CREATE,
        resourceId,
      }))
      setEventSelected({
        ...resetEvent,
        ...newEventSelected,
      })
      setIsModalOpen(true)
    }

    const handleTooltipWithState = (event: Event): string => {
      return handleTooltip(event, teammates ?? [], patientList)
    }

    const handleEventPropGetterWithState = (
      event: Event,
      start: stringOrDate,
      end: stringOrDate
    ): HTMLAttributes<HTMLDivElement> => {
      return handleEventPropGetter(event, start, end, allEvents, viewSelected)
    }

    const handleNotificationClick = async () => {
      await refetchAppointmentRequests()
      trackAppointmentRequestApprovals({
        eventName: 'Opened appointment requests drawer',
        clinicId: providerId,
        providerId: currentCalendarUser,
      })
      setIsDrawerOpen((currIsOpen) => !currIsOpen)
    }

    const handleClearPatient = () => {
      handleUpdatePatientIdSelected('')
    }

    // Set color map based upon fetched appointment types
    useEffect(() => {
      if (!appointmentSettings) {
        return
      }
      const { appointmentTypes } = appointmentSettings
      appointmentTypes.forEach((appointmentType) => {
        if (appointmentType.colorHex) {
          colorMapRef.current.set(
            appointmentType.name,
            appointmentType.colorHex
          )
        }
      })
    }, [appointmentSettings])

    // Timezone related hooks
    useEffect(() => {
      if (currentTimezone) {
        moment.tz.setDefault(currentTimezone)
      }
    }, [currentTimezone])

    const localizer = useMemo(() => {
      if (!currentTimezone) {
        return
      }
      return momentLocalizer(moment)
    }, [currentTimezone])

    const loadProviderInfo = (
      currentUser: ProviderAccountInfo,
      patients: NonNullable<typeof allPatients>
    ): void => {
      const {
        id: value,
        attributes: { email },
      } = currentUser

      if (email && value) {
        // sets the current user as selected
        setCurrentCalendarUser(value)
      }

      const activePatients: Patient[] = []
      const patientList = new Map<string, string>()

      for (const patient of patients) {
        if (isPatientActive(patient.IsActive)) {
          activePatients.push({
            id: patient.PatientId,
            publicId: patient.PublicId,
            name: patient.PatientName,
          })
          patientList.set(patient.PatientId, patient.PatientName)
        }
      }

      setPatientList(patientList)
      setPatients(activePatients)
    }

    const patientSelectOptions = useMemo(() => {
      return patients.map(({ id, name }) => ({
        name,
        value: id,
      }))
    }, [patients])

    const eventCategories = useMemo<EventCategory[] | undefined>(() => {
      if (viewSelected !== Views.DAY) return undefined

      const teammateCategories = teammatesSelectOptions.filter(
        ({ value }) =>
          Array.from(teammatesSelected).includes(value as string) &&
          !integratedExternalCalendarIds.includes(value as string)
      )

      const roomCategories = roomsSelectOptions.filter(({ value }) =>
        Array.from(roomsSelected).includes(value as string)
      )

      const unassignedCategory = isUnassignedSelected
        ? [UNASSIGNED_CATEGORY]
        : []

      const categories = [
        ...teammateCategories,
        ...roomCategories,
        ...unassignedCategory,
      ]
      if (categories.length === 0) {
        return [{ label: '', value: '' }]
      }

      return categories
    }, [
      viewSelected,
      teammatesSelected,
      roomsSelected,
      roomsSelectOptions,
      teammatesSelectOptions,
      integratedExternalCalendarIds,
      isUnassignedSelected,
    ])

    useEffect(() => {
      if (!allPatients || !user) {
        return
      }

      loadProviderInfo(user, allPatients)
      setIsProviderInfoLoaded(true)
    }, [allPatients, user, patientIdSelected, events, history])

    return (
      <>
        {
          /** TODO: Fix form component such that this does not need to be null if
        it's not open */
          isModalOpen ? (
            <EventModal
              isOpen={isModalOpen}
              event={eventSelected}
              closeModal={handleCloseModal}
              patients={patients}
              rooms={rooms ?? []}
              locations={locations ?? []}
              appointmentTypes={appointmentTypes}
              teamData={teammates ?? []}
              timezone={currentTimezone ?? DEFAULT_TIMEZONE}
              dateSelected={schedulingState.dateSelected}
              resourceId={schedulingState.resourceId}
              patientIdSelected={patientIdSelected}
            />
          ) : null
        }
        <AppointmentDrawer
          onNavigationChange={handleCalendarNavigation}
          loadAppointmentRequests={refetchAppointmentRequests}
          appointmentRequests={appointmentRequests?.appointments ?? []}
          canSeeAllPatients={appointmentRequests?.canSeeAllPatients ?? false}
          handleCloseDrawer={handleCloseDrawer}
          isDrawerOpen={isDrawerOpen}
          providerId={currentCalendarUser}
          onAccept={handleAccept}
          isAcceptingIds={isAcceptingIds}
          currentTimezone={currentTimezone ?? DEFAULT_TIMEZONE}
          clinicId={providerId}
        />
        <Row id="scheduling-page-row" justify="start" wrap={false}>
          <LeftSidebarContainer
            handleCollapseClick={handleCollapseClick}
            collapseButtonRef={collapseButtonRef}
            collapsed={collapsed}
            testId={'scheduling-left-sidebar'}
            tooltip={'calendar filters'}
            noWidthCollapse
            hasFixedWidth={false}
            increasedFixedButtonHeight={true}
          >
            <Col flex="none" id="scheduling-side-column">
              <CalendarNavigation
                selectedDate={schedulingState.dateSelected}
                onNavigationChange={handleCalendarNavigation}
              />
              <Filters />
            </Col>
          </LeftSidebarContainer>
          <Col flex="auto" id="scheduling-main-calendar">
            {isLoading || !localizer ? (
              <Spinner
                fontSize={40}
                className="d-flex justify-content-center calendar-spinner scheduling-spinner"
              />
            ) : (
              <CustomCalendar
                agendaLength={numberOfAgendaDays}
                colorMap={colorMapRef.current}
                currentDate={schedulingState.dateSelected}
                defaultView={DEFAULT_SCHEDULING_VIEW}
                currentView={viewSelected}
                localizer={localizer}
                events={events}
                settings={DEFAULT_SETTINGS}
                setCurrentView={handleViewChange}
                handleSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                handleNavigate={handleCalendarNavigation}
                handleTooltip={handleTooltipWithState}
                currentPatient={patientIdSelected}
                onClearPatient={handleClearPatient}
                locations={locations ?? []}
                patientList={patientList}
                patientOptions={patientSelectOptions}
                rooms={rooms}
                eventPropGetter={(
                  event: Event,
                  start: stringOrDate,
                  end: stringOrDate
                ) => handleEventPropGetterWithState(event, start, end)}
                handlePatientChange={handleSelectPatient}
                onCreateEventClick={() => {
                  setEventSelected({})
                  setIsModalOpen(true)
                }}
                onNotificationClick={handleNotificationClick}
                eventCategories={eventCategories}
                currentTimezone={currentTimezone ?? DEFAULT_TIMEZONE}
                currentProviderId={currentCalendarUser}
              />
            )}
          </Col>
        </Row>
      </>
    )
  }
)
