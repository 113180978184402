import React, { JSX, useMemo } from 'react'

import { compareAsc } from 'date-fns'
import { Accordion, Card } from 'react-bootstrap'

import { RenderChart } from '../../libs/chartsLib'
import {
  ClinicalNote,
  PatientSurveySubmissions,
  Questionnaire,
  SurveySubmission,
} from '../../shared-types'

interface ChartProps {
  currentProviderId: string
  clinicalNotes: ClinicalNote[] | undefined
  handleApiChange: () => void
}

type ScoreTypeContainerProps = {
  submissions: SurveySubmission[]
  questionnaire: Questionnaire
} & ChartProps

type RecurringSurveyChartListProps = {
  questionnaires: Questionnaire[]
  completed: PatientSurveySubmissions
} & ChartProps
/**
 * Pulls the name from questionnaire and lays out the accordion,
 * this is just a wrapper around legacy component.
 *
 * WARNING: RenderChart is unexplored
 *
 * @param questionnaire - source scale compute, as well as question text
 * @param currentProviderId
 * @param clinicalNotes
 * @param handleApiChange
 * @param submissions - array patient answers to a form, also has a questionnaire, but no steps..
 * @constructor
 */
const ScoreTypeContainer = ({
  questionnaire,
  currentProviderId,
  clinicalNotes,
  handleApiChange,
  submissions,
}: ScoreTypeContainerProps) => {
  return (
    <div
      data-testid={`${questionnaire.ScoreTypeEnum}-chart-accordion`}
      key={`${questionnaire.ScoreTypeEnum}-chart-accordion`}
    >
      <Accordion bsPrefix="plain-accordion">
        <Accordion.Toggle
          bsPrefix="align-center accordion-header accordion-toggle patient-card very-large"
          as={Card.Header}
          variant="link"
          eventKey="1"
        >
          {questionnaire.Name}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1" style={{ backgroundColor: 'white' }}>
          <RenderChart
            loggedInProviderId={currentProviderId}
            handleApiChange={handleApiChange}
            type={questionnaire.ScoreTypeEnum}
            clinicalNotes={clinicalNotes ?? []}
            survey={questionnaire}
            submissions={submissions}
          />
        </Accordion.Collapse>
      </Accordion>
    </div>
  )
}

export const RecurringSurveyChartList = (
  props: RecurringSurveyChartListProps
) => {
  const {
    questionnaires,
    completed,
    currentProviderId,
    clinicalNotes,
    handleApiChange,
  } = props

  const scoreTypeRows = useMemo(() => {
    const items: { sortKey: string; displayItem: JSX.Element }[] = []

    for (const [scoreType, completedByType] of Object.entries(completed)) {
      if (!completedByType.length) {
        continue
      }

      const latestVersion = questionnaires
        .filter((q) => q.ScoreTypeEnum === scoreType && q.IsRecurring)
        .sort((a, b) => compareAsc(a.CreatedAt, b.CreatedAt))
        .pop()

      if (!latestVersion) {
        continue
      }

      items.push({
        sortKey: latestVersion.Name,
        displayItem: (
          <ScoreTypeContainer
            submissions={completedByType}
            questionnaire={latestVersion}
            currentProviderId={currentProviderId}
            clinicalNotes={clinicalNotes}
            handleApiChange={handleApiChange}
          />
        ),
      })
    }

    return items
      .sort((a, b) => a.sortKey.localeCompare(b.sortKey))
      .map(({ displayItem }) => displayItem)
  }, [completed, questionnaires])

  return <>{scoreTypeRows}</>
}
