import React, { useEffect, useRef, useState } from 'react'

import { SizeType } from 'antd/lib/config-provider/SizeContext'

import { Radio } from '../Radio'
import InlineEditBase, { focusRef } from './InlineEditBase'
import { ActiveComponentProps, BaseInterface } from './shared-types'

import styles from './_shared.module.scss'

export interface Props extends BaseInterface {
  rows?: number
  size?: SizeType
  value?: string | number
  options: OptionsProps[]
}

export interface OptionsProps {
  value: string
  label: string
}

/**
  Editable inline Radio
*/
const InlineRadio = ({
  testId,
  requireConfirmation = false,
  value: initialValue,
  options = [],
  size = undefined,
  autoFocus,
  disabled = false,
  ...props
}: Props) => {
  const radioContainerRef: React.Ref<HTMLDivElement> = useRef(null)
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRendered(true)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const getFocusedValue = (value: string, index: number) => {
    if (initialValue) {
      return value === initialValue
    }
    return index === 0
  }

  const ActiveRadio = ({
    handleChange,
    toggleSave,
    toggleCancel,
  }: ActiveComponentProps) => (
    <Radio.Group
      className={styles.activeRadio}
      onChange={handleChange}
      buttonStyle="solid"
      size={size}
      disabled={disabled}
    >
      {options.map((item: OptionsProps, index) => (
        <Radio.Button
          disabled={disabled}
          autoFocus={getFocusedValue(item.value, index)}
          onKeyDown={({ key }) => {
            if (key === 'Enter') {
              toggleSave()
            }
            if (key === 'Escape') {
              toggleCancel()
            }
          }}
          key={item.value}
          value={item.value}
        >
          {item.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  )

  const getValue = (): string => {
    if (!initialValue) return ''
    return options.filter((e) => e.value === initialValue)[0].label
  }

  return (
    <div className={styles.refContainer} ref={radioContainerRef}>
      <InlineEditBase
        {...props}
        activeComponent={ActiveRadio}
        inactiveCursor="pointer"
        value={getValue()}
        toggleActive={autoFocus}
        onActive={() =>
          focusRef(autoFocus, radioContainerRef, undefined, rendered)
        }
        size={size}
        showActions={requireConfirmation}
        testId={testId}
        autoSave={!requireConfirmation}
        disabled={disabled}
      />
    </div>
  )
}

export default InlineRadio
