import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const LungCodes: any = [
  {
    19829001: {
      title: 'Lung/Respiratory Disorder',
      snomedcode: 19829001,
      description: 'Disorder of lung (disorder)',
      icdcode: 'J984',
      icddescription: 'Other disorders of lung',
    },
  },
  {
    195967001: {
      title: 'resp.Asthma',
      description: 'Asthma (disorder)',
      snomedcode: 195967001,
      icdcode: 'J45909',
      icddescription: 'Unspecified Asthma, Uncomplicated',
    },
  },
  {
    63480004: {
      title: 'resp.Chronic Bronchitis',
      description: 'Chronic bronchitis (disorder)',
      snomedcode: 63480004,
      icdcode: 'J42',
      icddescription: 'Unspecified chronic bronchitis',
    },
  },
  {
    13645005: {
      title: 'resp.Chronic Obstructive Pulmonary Disease (COPD)',
      description: 'Chronic obstructive lung disease (disorder)',
      snomedcode: 13645005,
      icdcode: 'J449',
      icddescription: 'Chronic obstructive pulmonary disease, unspecified',
    },
  },
  {
    87433001: {
      title: 'resp.Emphysema',
      description: 'Pulmonary emphysema (disorder)',
      snomedcode: 87433001,
      icdcode: 'J439',
      icddescription: 'Emphysema, unspecified',
    },
  },
  {
    78275009: {
      title: 'resp.Obstructive Sleep Apnea',
      description: 'Obstructive sleep apnea syndrome (disorder)',
      snomedcode: 78275009,
      icdcode: 'G4733',
      icddescription: 'Obstructive sleep apnea (adult) (pediatric)',
    },
  },
  {
    267036007: {
      title: 'resp.Shortness of Breath/Dyspnea',
      description: 'Dyspnea (finding)',
      snomedcode: 267036007,
      icdcode: 'R0600',
      icddescription: 'Dyspnea, unspecified',
    },
  },
]

export const LungDropDownValues = constructPatientDemographicObject(
  LungCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
