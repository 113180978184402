import React from 'react'

import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Input } from 'antd'

import {
  mapToOptionGroups,
  useBillingCodeDropdownOptions,
} from '../../../containers/Billing/useBillingCodeDropdownOptions'
import {
  BareBillingCode,
  useBillingCodes,
} from '../../../hooks/queries/useBillingCodes'
import { useBillingTemplates } from '../../../hooks/queries/useBillingTemplates'
import { BillingTemplate, LineItem } from '../../../shared-types'
import {
  Button,
  Col,
  Option,
  Row,
  Select,
  Tooltip,
} from '../../../stories/BaseComponents'
import { Diagnosis } from '../../../stories/Invoice/Diagnoses'
import { ChargeBreakdown } from './ClaimChargeBreakdown'
import { LineItems } from './ClaimLineItems'

import styles from '../../../stories/Invoice/Invoice.module.scss'

const BILLING_CODE_TOOLTIP =
  'For E/M codes, Modifier 25 is commonly used. If you include another service (e.g. ketamine infusion/injection) on the same date, this modifier should be included. It indicates a significant, separately identifiable preventive or other E/M service that was provided on the same date.'

type LineItemsContainerProps = {
  selectedTemplateId?: number
  lineItems: LineItem[]
  diagnoses: Diagnosis[]
  onLineItemUpdate: any
  onLineItemDelete: any
  onTemplateChange: (billingTemplate?: BillingTemplate) => void
  onBillingCodeAdd: (bc: BareBillingCode) => void
  isEditing: boolean
  isLoading: boolean
  authNumber?: string
  setAuthNumber: Function
}

export const LineItemsContainer = ({
  isEditing,
  isLoading,
  lineItems,
  diagnoses,
  onLineItemUpdate,
  onLineItemDelete,
  onTemplateChange,
  onBillingCodeAdd,
  selectedTemplateId,
  authNumber,
  setAuthNumber,
}: LineItemsContainerProps) => {
  const [selectedBillingCode, setSelectedBillingCode] = React.useState<
    BareBillingCode | undefined
  >(undefined)
  const [billingCodeSearch, setBillingCodeSearch] = React.useState('')
  const [billingTemplateSearch, setBillingTemplateSearch] = React.useState<
    string | undefined
  >('')

  const {
    templates: billingTemplates,
    templatesById,
    isLoading: billingTemplatesLoading,
  } = useBillingTemplates(billingTemplateSearch)

  const {
    codes: billingCodes,
    codesById,
    isLoading: isLoadingBCs,
  } = useBillingCodes(billingCodeSearch)

  const billingCodeOptions = useBillingCodeDropdownOptions(billingCodes)

  const handleBillingTemplateSelect = (value?: unknown): void => {
    if (value) {
      onTemplateChange(templatesById[Number(value)])
    }
  }

  const billingCodeSelectHandler = (value?: number) => {
    if (value) {
      const selectedBillingCode = codesById[value]
      setSelectedBillingCode(selectedBillingCode)
    } else {
      setSelectedBillingCode(undefined)
    }
  }

  const addBillingCodeHandler = () =>
    selectedBillingCode && onBillingCodeAdd(selectedBillingCode)

  const billingTemplateOptions: JSX.Element[] = (billingTemplates || []).map(
    (template: BillingTemplate) => (
      <Option value={template.id} key={template.id}>
        {template.name}
      </Option>
    )
  )

  return (
    <>
      {isEditing && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            columnGap: 15,
            marginBottom: 16,
          }}
          data-testid={'claim-line-items-inputs'}
        >
          <Col style={{ width: '100%' }}>
            <Row style={{ width: 432, marginBottom: 16 }}>
              <div className={styles.fullWidth}>
                <div className={styles.subheader}>
                  Prior authorization number{' '}
                  <span className={styles.optional}>(optional)</span>
                </div>
                <div className="flex">
                  <Input
                    placeholder="Add"
                    style={{ maxWidth: 432 }}
                    onChange={(e) => {
                      e.preventDefault()
                      setAuthNumber(e.target.value)
                    }}
                    value={authNumber}
                    data-testid={
                      'claim-line-items-prior-authorization-number-input'
                    }
                  />
                </div>
              </div>
            </Row>
            <Row>
              <Col style={{ marginRight: 10 }}>
                <div className={styles.subheader}>Billing template</div>
                <div className="flex" style={{ marginBottom: 16 }}>
                  <Select
                    loading={billingTemplatesLoading}
                    style={{ width: 432, maxWidth: 432 }}
                    value={selectedTemplateId}
                    disabled={!isEditing}
                    showSearch
                    searchValue={billingTemplateSearch}
                    filterOption={false}
                    onSearch={setBillingTemplateSearch}
                    onSelect={handleBillingTemplateSelect}
                    placeholder="Select a billing template"
                    testId={'claim-line-items-billing-template-select'}
                  >
                    {billingTemplateOptions.map(
                      (option: JSX.Element) => option
                    )}
                  </Select>
                </div>
              </Col>
              <Col>
                <div className={styles.halfWidth}>
                  <div className={styles.subheader}>
                    Add billing code
                    <Tooltip
                      overlayClassName="billing-code-tooltip"
                      placement="top"
                      title={BILLING_CODE_TOOLTIP}
                    >
                      <InfoCircleOutlined
                        width={14}
                        style={{ cursor: 'pointer', marginLeft: 5 }}
                      />
                    </Tooltip>
                  </div>
                  <div>
                    <Select
                      className="add-billing-code__input"
                      loading={isLoadingBCs}
                      disabled={!isEditing}
                      showSearch
                      placeholder="Search for billing code"
                      style={{ marginRight: 16, width: 432, maxWidth: 432 }}
                      searchValue={billingCodeSearch}
                      filterOption={false}
                      onSearch={setBillingCodeSearch}
                      onSelect={(value: unknown) =>
                        billingCodeSelectHandler(
                          value ? Number(value) : undefined
                        )
                      }
                      testId={'claim-line-items-billing-code-select'}
                    >
                      {mapToOptionGroups(billingCodeOptions)}
                    </Select>
                    <Button
                      testId="add-billing-code-button"
                      icon={<PlusOutlined />}
                      type="default"
                      color="#262626"
                      disabled={!isEditing}
                      onClick={addBillingCodeHandler}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </div>
      )}

      <LineItems
        isLoading={isLoading}
        isEditing={isEditing}
        lineItems={lineItems}
        diagnoses={diagnoses}
        onDelete={onLineItemDelete}
        onUpdate={onLineItemUpdate}
        testId={'claim-line-items-table'}
      />
      <ChargeBreakdown
        isEditing={isEditing}
        lineItems={lineItems}
        testId={'claim-line-items-charge-breakdown'}
      />
    </>
  )
}
