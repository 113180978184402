import { useMemo } from 'react'

import { CheckOutlined } from '@ant-design/icons'
import { Button, Form, FormInstance, Input } from 'antd'

import { passwordRegex } from '../../libs/regex'
import { rulesConstructor } from '../../libs/utils'
import {
  EINInput,
  NPIInput,
  Password,
  PhoneNumberInput,
} from '../BaseComponents'
import AddressAutocomplete, {
  Address,
} from '../BaseComponents/AddressAutocomplete'
import CredentialsMultiSelect from '../BaseComponents/SpecialInputFields/CredentialsMultiSelect'
import LogoCard from './LogoCard'

import styles from '../../containers/_shared.module.scss'

export interface FormValues {
  confirmPassword: string
  password: string

  practiceName: string
  firstName: string
  middleName: string
  lastName: string
  phoneNumber: string

  qikCode: string
  npiNumber: string
  practiceNpiNumber: string
  ein: string

  // TODO(?): change credential to accept an array of enums
  credential: string
  specialty: string

  practiceAddress: Address
}

interface SignupPracticeCard {
  form: FormInstance
  handleAddressChange: (values: Address) => void
  handleSubmit: (values: FormValues) => Promise<void>
  initialValues?: any
  isLoading: boolean
}

export default function Login({
  form,
  initialValues,
  isLoading,
  handleAddressChange,
  handleSubmit,
}: SignupPracticeCard) {
  const formValues = useMemo(() => {
    const values = form.getFieldsValue() ?? {}
    return {
      quikCode: '',
      ...values,
    }
  }, [form])

  return (
    <div className={styles.scroll}>
      <LogoCard id="signup-card" title="Create your Osmind account">
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={initialValues}
          scrollToFirstError
          layout="vertical"
        >
          <div className="subsection-title">Provider information</div>
          <NPIInput form={form} name="npiNumber" />

          <Form.Item
            name="firstName"
            label="First Name:"
            rules={[
              rulesConstructor({ message: 'Please input your first name' }),
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="middleName" label="Middle Name:">
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name:"
            rules={[
              rulesConstructor({ message: 'Please input your last name' }),
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Credential(s)" name="credential">
            <CredentialsMultiSelect
              handleChange={(values) =>
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  credential: values.join(', '),
                })
              }
              placeholder="Select your credentials"
            />
          </Form.Item>

          <Form.Item label="Specialty" name="specialty">
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              { message: 'Please input a password', required: true },
              () => ({
                validator(_, value) {
                  const reg = new RegExp(passwordRegex)
                  if (!value || (value.length >= 8 && reg.test(value))) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      'At least 8 characters, 1 uppercase, 1 lowercase, 1 special character, 1 number'
                    )
                  )
                },
              }),
            ]}
            hasFeedback
          >
            <Password />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match')
                  )
                },
              }),
            ]}
          >
            <Password />
          </Form.Item>

          <div className="subsection-title subsection-title-practice">
            Practice information
          </div>

          <NPIInput form={form} name="practiceNpiNumber" />

          <Form.Item
            extra="This is the name patients will see when signing up."
            label="Practice name"
            name="practiceName"
            rules={[
              rulesConstructor({ message: 'Please input your practice name' }),
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="practiceAddress">
            <AddressAutocomplete
              value={formValues.AutocompleteAddress}
              onChange={handleAddressChange}
            />
          </Form.Item>

          <PhoneNumberInput form={form} required={true} />

          <EINInput form={form} name="ein" />

          <Form.Item>
            <Button
              id="signup-card-signup-button"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {!isLoading && <CheckOutlined />} Activate Practice
            </Button>
          </Form.Item>
        </Form>
      </LogoCard>
    </div>
  )
}
