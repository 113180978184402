import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { getDiagnosisNoteBlock } from '../../api/patients'
import { DiagnosisContentBlock } from '../../shared-types'

export enum DiagnosisNoteBlockQueryKeys {
  DiagnosisNoteBlock = 'DIAGNOSIS_NOTE_BLOCK',
}

export interface DiagnosisBlockHook {
  diagnosisBlock: DiagnosisContentBlock
}

interface useDiagnosisNoteBlockParams {
  noteId: number
  enabled?: boolean
}
export const useDiagnosisNoteBlock = ({
  noteId,
  enabled = true,
}: useDiagnosisNoteBlockParams): UseQueryResult<DiagnosisBlockHook> => {
  return useQuery<DiagnosisBlockHook>(
    [DiagnosisNoteBlockQueryKeys.DiagnosisNoteBlock, noteId],
    {
      queryFn: async () => {
        const diagnosisBlock = await getDiagnosisNoteBlock(noteId)
        return {
          diagnosisBlock,
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  )
}
