import React, { useEffect, useState } from 'react'

import moment, { Moment } from 'moment'
import 'moment-timezone'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'

import { AutoSizingTextArea } from '../../../../../stories/BaseComponents/TextAreaInput'
import { generateSpravatoClassName } from '../NoteType/Spravato/Spravato'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

interface EntriesType {
  Type: string
  Description: string
  Date: moment.Moment | null
  Resolution: string
}
interface ShowEntriesSpravatoProps {
  key: number
  index: number
  Type: string
  Date: any
  Description: string
  Resolution: string
  saveEditEntries: (index: number, editVitSign: any) => void
  deleteEntries: (index: number) => void
  disabled: boolean
  entriesType: EntriesType[]
}
export default function ShowEntriesSpravato(props: ShowEntriesSpravatoProps) {
  const [type, setType] = useState(props.Type || '')
  const [date, setDate] = useState<Moment | null>(props.Date || null)
  const [description, setDescription] = useState(props.Description || '')
  const [resolution, setResolution] = useState(props.Resolution || '')
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    setType(props.Type || '')
    setDescription(props.Description || '')
    setResolution(props.Resolution || '')
  }, [props.Type, props.Date, props.Description, props.Resolution])

  useEffect(() => {
    const fields = {
      Type: type,
      Date: date,
      Description: description,
      Resolution: resolution,
    }
    props.saveEditEntries(props.index, fields)
  }, [type, date, description, resolution])

  const eventType = [
    'Hospitalization',
    'Disability or permanent damage',
    'Death',
    'Life-threatening',
    'Important medical event',
  ]

  const eventResolution = ['Yes', 'No', 'Unknown']

  return (
    <>
      <tr>
        <td>
          <Form.Control
            bsPrefix={
              type === '' ? generateSpravatoClassName(true) : 'form-input'
            }
            style={{ alignSelf: 'center', textAlign: 'center' }}
            as="select"
            onChange={(e) => setType(e.target.value)}
            value={type}
            disabled={props.disabled}
          >
            <option key={0} value="">
              Select one
            </option>
            {eventType.map((unit, index) => (
              <option key={index + 1} value={unit}>
                {unit}
              </option>
            ))}
          </Form.Control>
        </td>
        <td>
          <div
            className={
              date === null
                ? `entries-date-height ${generateSpravatoClassName()}`
                : 'entries-date-height'
            }
          >
            <SingleDatePicker
              date={date === null ? null : moment(props.Date)} // momentPropTypes.momentObj or null
              onDateChange={(value) => {
                setDate(value)
              }} // PropTypes.func.isRequired
              id="SpravatoEntryDate" // PropTypes.string.isRequired,
              focused={focused} // PropTypes.bool
              onFocusChange={(focused) => setFocused(focused.focused)}
              numberOfMonths={1}
              placeholder="MM/DD/YYYY"
              displayFormat="MM/DD/YYYY"
              onClose={() => setFocused(false)}
              isOutsideRange={() => false}
              disabled={props.disabled}
            />
          </div>
        </td>
        <td>
          <Form.Control
            bsPrefix={
              description === ''
                ? `form-control ${generateSpravatoClassName()}`
                : 'form-control'
            }
            className="field-value-edit"
            style={{ alignSelf: 'center', textAlign: 'center' }}
            value={description}
            as={AutoSizingTextArea}
            minRows={1}
            disabled={props.disabled}
            onChange={(e) => setDescription(e.target.value)}
          />
        </td>
        <td>
          <Form.Control
            bsPrefix={
              resolution === '' ? generateSpravatoClassName(true) : 'form-input'
            }
            style={{ alignSelf: 'center', textAlign: 'center' }}
            as="select"
            onChange={(e) => setResolution(e.target.value)}
            value={resolution}
            disabled={props.disabled}
          >
            <option key={0} value="">
              Select one
            </option>
            {eventResolution.map((unit, index) => (
              <option key={index + 1} value={unit}>
                {unit}
              </option>
            ))}
          </Form.Control>
        </td>
        <td className="center">
          <Row>
            <Col>
              {!props.disabled && props.entriesType?.length !== 1 && (
                <Button
                  bsPrefix="center plain-button info button-label"
                  onClick={() => {
                    props.deleteEntries(props.index)
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true" />
                  &nbsp;Delete
                </Button>
              )}
            </Col>
          </Row>
        </td>
      </tr>
    </>
  )
}
