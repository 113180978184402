import { format, utcToZonedTime } from 'date-fns-tz'

import { getNoteTimeDisplay } from '../utils'

import styles from './NoteDateTimeDisplay.module.scss'

const getNoteDateDisplay = (date: string, timezone: string | null): string => {
  const zonedTime = timezone ? utcToZonedTime(date, timezone) : new Date(date)
  return format(zonedTime, 'P')
}

interface NoteDateTimeDisplayProps {
  timestamp: string
  timezone: string | null
}

/**
 * Helper component for displaying note timestamps in a consistent manner.
 */
export const NoteDateTimeDisplay = ({
  timestamp,
  timezone,
}: NoteDateTimeDisplayProps) => {
  const dot = <span className={styles.dot}>•</span>
  const date = getNoteDateDisplay(timestamp, timezone)
  const time = getNoteTimeDisplay({
    time: timestamp,
    timezone: timezone,
  })

  return (
    <>
      {date} {dot} {time}
    </>
  )
}
