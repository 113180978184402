import _ from 'lodash'

import {
  FieldStyling,
  TemplateFields,
} from '../../../components/Forms/FormMachine/types'
import { MedicalIssue, YesNoMaybeNA } from './types'

export const styling = {
  editColClassName: 'col-bottom-edit',
  colClassName: 'bottom-line',
  editLabelClassName: 'field-label-edit',
  editValueClassName: 'field-value-edit',
  labelClassName: 'col-squished field-label',
  formRowStyle: {
    margin: '0px',
  },
  valueClassName: 'field-value',
  colSize: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
} as FieldStyling

const yesNoMaybeNA = Object.values(YesNoMaybeNA)

const noBottomBorder = {
  ...styling, // preventing other styles from being over overwritten
  editColClassName: 'no-bottom-line',
  colClassName: 'no-bottom-line',
}

const smallerLabel = {
  ...noBottomBorder,
  editLabelStyle: {
    fontSize: 15,
  },
  labelStyle: {
    fontSize: 16,
  },
}

const fullWidth = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }

const whenField = {
  columnSizes: { xs: 12, sm: 12, md: 12, lg: 7, xl: 6 },
  customStyling: {
    ...smallerLabel,
    editColStyle: {
      paddingLeft: '0% 3%',
    },
  },
  dateType: 'year',
  fieldType: 'datepicker',
  label: 'Start',
  toggleDateType: ['year', 'yearRange'],
}

const commentsField = {
  columnSizes: { xs: 12, sm: 12, md: 12, lg: 5, xl: 6 },
  customStyling: {
    ...smallerLabel,
    editColStyle: {
      padding: '0% 3%',
    },
    editValueStyle: {
      marginTop: '2.5px',
    },
  },
  fieldType: 'text',
  label: 'Comments',
}

const medicalConditionalOptions = [
  ...Object.values(MedicalIssue)
    .filter((issue) => issue !== MedicalIssue.OTHER)
    .map((issue) => ({
      option: issue,
      conditionalComponent: [
        { ...whenField, id: `${_.camelCase(issue)}Year` },
        { ...commentsField, id: `${_.camelCase(issue)}Comments` },
      ],
    })),
  {
    option: MedicalIssue.OTHER,
    label: 'Other medical conditions',
    conditionalComponent: [
      {
        addFieldLabel: 'Add new medical condition',
        columnSizes: fullWidth,
        fieldType: 'multiple',
        id: 'otherConditions',
        label: '',
        multipleComponent: [
          {
            columnSizes: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            customStyling: smallerLabel,
            fieldType: 'textarea',
            id: 'description',
            label: 'Description',
            size: 1,
          },
        ],
      },
    ],
  },
]

export const template = [
  // MEDICAL CONDITIONS
  {
    columnSizes: fullWidth,
    conditionalComponent: {
      columnSizes: fullWidth,
      conditionalOptions: medicalConditionalOptions,
      customStyling: noBottomBorder,
      fieldType: 'checkbox',
      id: 'medicalConditions',
      label: 'Conditions',
      options: Object.values(MedicalIssue),
    },
    conditionalOption: 'No relevant history in this section',
    customStyling: {
      ...styling,
      editValueStyle: {
        marginLeft: '14px',
      },
    },
    fieldType: 'checkbox',
    hideConditional: true,
    id: 'hasHadNoRelevantMedHistory',
    label: 'Medical issues/conditions/diagnoses',
    options: ['No relevant history in this section'],
  },
  // PREGNANCY
  {
    columnSizes: fullWidth,
    conditionalComponent: [
      {
        columnSizes: fullWidth,
        customStyling: {
          ...noBottomBorder,
          editValueStyle: { marginTop: '11px' },
          isLabelOnSameLine: true,
          colSize: { xs: 12, sm: 'auto', xl: 'auto' },
        },
        fieldType: 'radio',
        id: 'isPregnantOrNursing',
        label: 'Pregnant or nursing?',
        options: yesNoMaybeNA,
      },
      {
        addFieldLabel: 'Add new pregnancy',
        columnSizes: fullWidth,
        customStyling: noBottomBorder,
        fieldType: 'multiple',
        id: 'previousPregnancies',
        label: 'Previous pregnancies',
        multipleComponent: [
          {
            columnSizes: { xs: 12, sm: 12, md: 12, lg: 7, xl: 6 },
            customStyling: smallerLabel,
            dateType: 'year',
            fieldType: 'datepicker',
            id: 'year',
            label: '',
            toggleDateType: ['year', 'yearRange'],
          },
          {
            columnSizes: { xs: 12, sm: 12, md: 12, lg: 5, xl: 6 },
            customStyling: smallerLabel,
            fieldType: 'textarea',
            id: 'resultOfPregnancy',
            label: 'Result of pregnancy',
            size: 1,
          },
        ],
      },
      {
        columnSizes: fullWidth,
        customStyling: noBottomBorder,
        fieldType: 'textarea',
        id: 'pregnancyNotes',
        label: 'Notes',
        size: 1,
      },
    ],
    conditionalOption: 'No relevant history in this section',
    customStyling: {
      ...styling,
      editValueStyle: {
        marginLeft: '14px',
      },
      formRowStyle: {
        margin: 0,
      },
    },
    fieldType: 'checkbox',
    hideConditional: true,
    id: 'hasNoPregnancyHistory',
    label: 'Pregnancies',
    options: ['No relevant history in this section'],
  },
  // SURGERIES
  {
    columnSizes: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    conditionalComponent: {
      addFieldLabel: 'Add new surgery',
      customStyling: {
        ...noBottomBorder,
        labelStyle: {
          paddingBottom: '0px',
        },
      },
      columnSizes: fullWidth,
      multipleComponent: [
        {
          columnSizes: { xs: 12, sm: 12, md: 6, lg: 4, xl: 4 },
          customStyling: smallerLabel,
          fieldType: 'text',
          id: 'name',
          label: 'Procedure name',
        },
        {
          columnSizes: { xs: 12, sm: 12, md: 6, lg: 4, xl: 3 },
          customStyling: smallerLabel,
          dateType: 'monthYear',
          fieldType: 'datepicker',
          id: 'year',
          label: 'When',
          toggleDateType: ['year', 'monthYear'],
        },
        {
          columnSizes: { xs: 12, sm: 12, md: 6, lg: 4, xl: 5 },
          customStyling: smallerLabel,
          fieldType: 'textarea',
          id: 'complications',
          label: 'Complications',
          size: 1,
        },
        {
          columnSizes: { xs: 12, sm: 12, md: 6, lg: 6, xl: 4 },
          customStyling: smallerLabel,
          fieldType: 'textarea',
          id: 'reason',
          label: 'Reason',
          size: 1,
        },
        {
          columnSizes: { xs: 12, sm: 12, md: 12, lg: 6, xl: 8 },
          customStyling: smallerLabel,
          fieldType: 'textarea',
          id: 'additionalNotes',
          label: 'Additional notes',
          size: 1,
        },
      ],
      fieldType: 'multiple',
      id: 'surgicalHistory',
      label: '',
    },
    conditionalOption: 'No relevant history in this section',
    customStyling: {
      ...styling,
      editValueStyle: {
        marginLeft: '14px',
      },
    },
    fieldType: 'checkbox',
    hideConditional: true,
    id: 'hasHadNoSurgeries',
    label: 'Surgical History',
    options: ['No relevant history in this section'],
  },
  // HOSPITALIZATIONS
  {
    columnSizes: fullWidth,
    conditionalComponent: {
      addFieldLabel: 'Add new hospitalization',
      columnSizes: fullWidth,
      customStyling: {
        ...noBottomBorder,
        labelStyle: {
          paddingBottom: '0px',
        },
      },
      fieldType: 'multiple',
      id: 'hospitalizations',
      label: '',
      multipleComponent: [
        {
          columnSizes: { xs: 12, sm: 12, md: 8, lg: 8, xl: 8 },
          customStyling: smallerLabel,
          fieldType: 'textarea',
          id: 'reason',
          label: 'Reason',
          size: 1,
        },
        {
          columnSizes: { xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
          customStyling: smallerLabel,
          fieldType: 'text',
          id: 'when',
          label: 'When',
        },
      ],
    },
    conditionalOption: 'No relevant history in this section',
    customStyling: {
      ...styling,
      editValueStyle: {
        marginLeft: '14px',
      },
    },
    fieldType: 'checkbox',
    hideConditional: true,
    id: 'hasHadNoHospitalizations',
    label: 'Hospitalizations for non-mental health reasons',
    options: ['No relevant history in this section'],
  },
  // CARE FACILITY / HOSPICE
  {
    columnSizes: fullWidth,
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'textarea',
      id: 'isInCareFacilityExplanation',
      label: 'Notes',
      size: 1,
    },
    conditionalOption: 'Yes',
    customStyling: {
      ...styling,
      editValueStyle: { marginTop: '11px' },
      isLabelOnSameLine: true,
      colSize: { xs: 12, sm: 'auto', xl: 'auto' },
    },
    fieldType: 'yesNo',
    id: 'isInCareFacility',
    label: 'Currently in long-term care facility or hospice?',
  },
  // IMPLANTED DEVICES
  {
    columnSizes: fullWidth,
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'textarea',
      id: 'hasImplantedDevicesExplanation',
      label: 'Notes',
      size: 1,
    },
    conditionalOption: 'Yes',
    customStyling: {
      ...styling,
      editValueStyle: { marginTop: '11px' },
      isLabelOnSameLine: true,
      colSize: { xs: 12, sm: 'auto', xl: 'auto' },
    },
    fieldType: 'yesNo',
    id: 'hasImplantedDevices',
    label: 'Has implanted device(s)?',
  },
  // ADDITIONAL NOTES
  {
    columnSizes: fullWidth,
    customStyling: {
      ...styling,
      formRowClassName: 'bottom-buffer',
    },
    fieldType: 'textarea',
    id: 'additionalNotes',
    label: 'Additional notes',
  },
] as TemplateFields
