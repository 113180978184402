import { useEffect } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'

import { pageToLimitOffsetParams } from '../../../../api/pagination'
import { usePrevious } from '../../../../hooks/usePrevious'
import { ListNoteTemplatesSortParam, listNoteTemplates } from '../../api'
import { NOTE_TEMPLATES_QUERY_KEY } from '../../constants'

export const PAGE_SIZE = 25
export const useGetAllNoteTemplates = ({
  hasEnabledCustomTemplates,
  sorts,
}: {
  hasEnabledCustomTemplates: boolean
  sorts?: ListNoteTemplatesSortParam[]
}) => {
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      // Added after reading this discussion: https://github.com/TanStack/query/discussions/1255
      queryKey: ['Infinite', NOTE_TEMPLATES_QUERY_KEY, sorts],
      queryFn: ({ pageParam }) => {
        return listNoteTemplates({
          ...pageToLimitOffsetParams({
            currentPage:
              pageParam !== undefined && pageParam > 0 ? pageParam : 1,
            pageSize: PAGE_SIZE,
          }),
          sorts,
        })
      },
      getNextPageParam: ({ totalCount }, allPages) => {
        const currentPage = allPages.length
        const totalPages = Math.ceil(totalCount / PAGE_SIZE)
        const nextPage = currentPage < totalPages ? currentPage + 1 : undefined
        return nextPage
      },
      // https://tanstack.com/query/v4/docs/framework/react/guides/paginated-queries#better-paginated-queries-with-keeppreviousdata
      keepPreviousData: true,
      enabled: hasEnabledCustomTemplates,
      refetchOnWindowFocus: true,
    })
  const wasFetching = usePrevious(isFetching)

  // As long as there are more pages to fetch, keep fetching
  useEffect(() => {
    if (wasFetching && !isFetching && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetching, wasFetching])

  return { response: data, isLoading }
}
