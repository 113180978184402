import React, { useEffect } from 'react'

import { Form, FormInstance, FormItemProps, Input, InputProps } from 'antd'
import { NamePath } from 'rc-field-form/lib/interface'

import { TestId } from '../../shared-types'

type AutoFormatInputProps = {
  disabled?: boolean
  form: FormInstance
  formatInput: (input: string) => string
  formItemProps: FormItemProps
  inputProps?: InputProps
  listName?: string
  name: NamePath
} & TestId

const AutoFormatInput: React.FC<AutoFormatInputProps> = ({
  disabled,
  form,
  formatInput,
  formItemProps,
  listName,
  name,
  inputProps = {},
  testId,
}) => {
  const onChange = () => {
    if (!formItemProps.rules || !name) return
    const newValues = form.getFieldsValue() ?? {}

    // sometimes names are arrays and not just strings! go figure
    // used mainly when AutoFormatInput is used within Form.List
    // see https://stackoverflow.com/questions/62393532/antd-v4-form-list-setfieldvalue
    // for more info!
    if (Array.isArray(name)) {
      // listName is needed to specify the path further, can't seem to get it
      // to work by just making it part of the NamePath "name" attribute
      // so here it goes
      if (!listName) return
      const [index = 0, subIndex] = name
      const value = newValues[listName]?.[index]?.[subIndex]
      if (!value || !value.toString()) return
      form.setFields([
        {
          name: [listName, ...name],
          value: formatInput(value.toString()),
        },
      ])
      return
    }
    const value = form.getFieldValue(name)
    if (!value) return
    const formatted = formatInput(value.toString())
    form.setFieldsValue({
      ...newValues,
      [name]: formatted,
    })
  }

  useEffect(onChange, [formatInput, formItemProps, name])

  return (
    <Form.Item {...formItemProps} name={name} data-testid={testId}>
      <Input disabled={disabled} {...inputProps} onChange={onChange} />
    </Form.Item>
  )
}

export default AutoFormatInput
