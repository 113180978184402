import { useQuery } from '@tanstack/react-query'

import { SortParam, pageToLimitOffsetParams } from '../../../../api/pagination'
import { ListNoteTemplatesSortParam, listNoteTemplates } from '../../api'
import { NOTE_TEMPLATES_QUERY_KEY } from '../../constants'

const noteTemplatesQueryKey = (
  page: number,
  sorts: SortParam<string>[] | undefined
) => [
  NOTE_TEMPLATES_QUERY_KEY,
  {
    page,
    sort: JSON.stringify(sorts),
  },
]

export const useListNoteTemplates = ({
  currentPage,
  pageSize,
  sorts,
}: {
  currentPage: number
  pageSize: number
  sorts?: ListNoteTemplatesSortParam[]
}) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: noteTemplatesQueryKey(currentPage, sorts),
    queryFn: () =>
      listNoteTemplates({
        ...pageToLimitOffsetParams({ currentPage, pageSize }),
        sorts,
      }),
    // https://tanstack.com/query/v4/docs/framework/react/guides/paginated-queries#better-paginated-queries-with-keeppreviousdata
    keepPreviousData: true,
  })
  return { response: data, isLoading, isSuccess, isError }
}
