import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const educationLevelHL7: PatientDemographicObject[] = [
  { GD: { title: 'Graduate degree' } },
  { BD: { title: 'College degree' } },
  { ASSOC: { title: 'Associate degree' } },
  { SCOL: { title: 'Some college' } },
  { HS: { title: 'High school diploma' } },
  { SEC: { title: 'Some high school' } },
  { ELEM: { title: 'Elementary school' } },
]

export const educationLevelDropDownValues = constructPatientDemographicObject(
  educationLevelHL7,
  CodeSystemOIDs.HL7EducationLevel,
  Categories.EDUCATION_LEVEL
)
