import { useMutation } from '@tanstack/react-query'

import { CreateDynamicBlockOptions } from '../../Components/Blocks/Core/Block/DynamicBlockTypes'
import { createNoteBlock } from '../../api'

export const useCreateNoteBlock = () => {
  const {
    mutateAsync: create,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(createNoteBlock)

  const callCreate = ({ noteUuid, blockConfig }: CreateDynamicBlockOptions) => {
    return create({ noteUuid, blockConfig })
  }

  return {
    createNewNoteBlock: callCreate,
    isLoading,
    isSuccess,
    isError,
  }
}
