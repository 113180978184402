import moment from 'moment'
import { Badge, Col, Container, Form, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { useProfileUrlParams } from '../../../hooks/useProfileUrlParams'
import { QUERY_PARAMS } from '../../../libs/constants'
import {
  NotesEvents,
  trackNotesEvent,
} from '../../../libs/freshpaint/notesEvents'
import { useQuery } from '../../../libs/utils'
import {
  ClinicalNote,
  NoteTypes,
  UserFacingNoteTypes,
} from '../../../shared-types'
import { getClinicalNotesUrl } from './utils'

import './ClinicalNotes.scss'
import './CreateClinicalNotePage.scss'
import './ViewEditNotePage.scss'

interface ClinicalNoteTabProps {
  note: ClinicalNote
}

export default function ClinicalNoteTab({ note }: ClinicalNoteTabProps) {
  const history = useHistory()
  const { urlParams } = useProfileUrlParams()
  const query = useQuery()
  const patientId = query.get(QUERY_PARAMS.patientId) || ''

  const handleClick = () => {
    trackNotesEvent(NotesEvents.VIEWED_NOTE, {
      patientId,
      noteId: note.NoteId,
      noteType: note.NoteType,
    })
    history.push(
      getClinicalNotesUrl({
        urlParams,
        noteId: note.NoteId,
        isV2: note.version === 2,
      })
    )
  }

  const noteType = () => {
    if (!note.NoteType) {
      return (
        <Badge pill variant="SCN">
          Clinical Note
        </Badge>
      )
    }

    const PillVariants = {
      [NoteTypes.CLINICAL_NOTE]: 'SCN',
      [NoteTypes.EVALUATION_NOTE]: 'EVAL',
      [NoteTypes.IV_KETAMINE]: 'IV',
      [NoteTypes.IM_KETAMINE]: 'IM',
      [NoteTypes.MEDICAL_SOAP]: 'MedicalSOAP',
      [NoteTypes.THERAPY_SOAP]: 'TherapySOAP',
      [NoteTypes.PSYCHOTHERAPY_NOTE]: 'PsychotherapyNote',
      [NoteTypes.NOTE_V2]: 'V2',
      [NoteTypes.KAP]: NoteTypes.KAP,
      [NoteTypes.MEMO]: NoteTypes.MEMO,
      [NoteTypes.SPRAVATO]: NoteTypes.SPRAVATO,
    }

    return (
      <Badge pill variant={PillVariants[note.NoteType]}>
        {note.NoteType === NoteTypes.NOTE_V2
          ? 'Beta Note'
          : UserFacingNoteTypes[note.NoteType]}
      </Badge>
    )
  }

  return (
    <Container
      key={note.NoteId}
      data-testid={`note-li-${note.NoteId}`}
      bsPrefix="patient-card toggle"
      onClick={handleClick}
    >
      <Row>
        <Form.Group
          as={Col}
          bsPrefix="patient"
          sm={3}
          md={3}
          lg={3}
          xl={3}
          style={{
            textAlign: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          {note.Title ? note.Title : 'No Title'}
        </Form.Group>
        <Form.Group
          as={Col}
          bsPrefix="patient"
          sm={3}
          md={3}
          lg={3}
          xl={3}
          style={{
            textAlign: 'center',
            fontSize: '19px',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          {noteType()}
        </Form.Group>
        <Form.Group
          as={Col}
          bsPrefix="patient"
          sm={3}
          md={3}
          lg={3}
          xl={3}
          style={{
            textAlign: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          <span className="emphasis">
            {moment(new Date(note.CreatedOn)).format('LL') + '  '}
          </span>
        </Form.Group>
        <Form.Group
          as={Col}
          bsPrefix="patient"
          sm={3}
          md={3}
          lg={3}
          xl={3}
          style={{
            textAlign: 'center',
            fontSize: '19px',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          {!note.Signatures?.length && (
            <Badge pill variant="unsigned">
              Unsigned
            </Badge>
          )}
        </Form.Group>
      </Row>
    </Container>
  )
}
