import { useCallback, useEffect, useState } from 'react'

import debounce from 'lodash/debounce'

export const INACTIVE_STATE_CHANGE_DEBOUNCE_TIME = 500

/**
 * Override the browser Event typing
 * - Inspecting the actual events proves that these props exist
 */
export type VisibilityEvent = Event & {
  target: {
    visibilityState: 'visible'
  }
  srcElement: {
    visibilityState: 'visible'
  }
}

export type InactiveState = 'INACTIVE' | 'ACTIVE'

/**
 * Hook for registering listeners for window/tab focus/blur,background/foreground
 * - Provide callbacks for onActive or onInactive
 * - Since it's possible for some of the state change listenters to be simultaneously triggered,
 *      the calls to the callbacks are debounced
 */
export const useInactiveWindowTab = ({
  onInactive,
  onActive,
}: {
  onInactive: () => void
  onActive: () => void
}) => {
  const [isActive, setIsActive] = useState<boolean>(
    document.visibilityState === 'visible'
  )

  const onInactiveAction = useCallback(
    debounce(
      () => {
        setIsActive(false)
        onInactive()
      },
      /**
       * debouncing settings
       * - evaluate immediately (`leading: true`)
       * - ignore anything within 1s (`VISIBILITY_HANDLER_DEBOUNCE_TIME`, e.g. `1000`)
       * - don't fire at the end (to prevent duplicate events, `trailing: false`)
       */
      INACTIVE_STATE_CHANGE_DEBOUNCE_TIME,
      { leading: true, trailing: false }
    ),
    [onInactive]
  )
  const onActiveAction = useCallback(
    debounce(
      () => {
        setIsActive(true)
        onActive()
      },
      /**
       * debouncing settings
       * - evaluate immediately (`leading: true`)
       * - ignore anything within 1s (`VISIBILITY_HANDLER_DEBOUNCE_TIME`, e.g. `1000`)
       * - don't fire at the end (to prevent duplicate events, `trailing: false`)
       */
      INACTIVE_STATE_CHANGE_DEBOUNCE_TIME,
      { leading: true, trailing: false }
    ),
    [onInactive]
  )

  /**
   * ---------------------------------------------------------------------------------------
   * WINDOW FOCUS / BLUR
   */
  useEffect(() => {
    window.addEventListener('focus', onActiveAction)

    // On cleanup: Unsubscribe from listener
    return () => window.removeEventListener('focus', onActiveAction)
  }, [window, onActiveAction, setIsActive])
  useEffect(() => {
    window.addEventListener('blur', onInactiveAction)

    // On cleanup: Unsubscribe from listener
    return () => window.removeEventListener('blur', onInactiveAction)
  }, [window, onInactiveAction])

  /**
   * ---------------------------------------------------------------------------------------
   * DOCUMENT VISIBILITY
   */
  const visibilityChangeListener = useCallback(
    (evt: Event) => {
      // cast Event to VisibilityEvent
      const event = evt as VisibilityEvent
      if (event.target.visibilityState === 'visible') {
        onActiveAction()
      } else {
        onInactiveAction()
      }
    },
    [onActiveAction, onInactiveAction]
  )

  // for inactive tabs: listen for visibility change
  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityChangeListener)
    // On cleanup: Unsubscribe from listener
    return () =>
      document.removeEventListener('visibilitychange', visibilityChangeListener)
  }, [document, visibilityChangeListener])

  return {
    isActive,
  }
}
