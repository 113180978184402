import { API } from 'aws-amplify'

export const fetchAllergies = async (patientId: string) => {
  return API.get('patient-profile', `/patients/${patientId}/allergies`, {})
}

export const fetchDiagnoses = async (patientId: string) => {
  return API.get('patient-profile', `/patients/${patientId}/diagnoses`, {})
}

export const getPatientInsurance = async (patientId: string) => {
  return API.get('core-api', `/patient/insurance/${patientId}`, {})
}

export const loadPatientDocuments = async (patientId: string) => {
  return API.get('patient-profile', `/patients/${patientId}/documents`, {})
}

export const loadPatientMedications = async (patientId: string) => {
  return API.get('patient-profile', `/patients/${patientId}/medications`, {})
}

export const loadPatientMedicationHistory = async (
  patientId: string,
  startDate?: Date | string | null,
  endDate?: Date | string | null
) => {
  const queryStringParameters: Record<string, string> = {}
  if (startDate) {
    queryStringParameters.startDate = new Date(startDate).toISOString()
  }

  if (endDate) {
    queryStringParameters.endDate = new Date(endDate).toISOString()
  }

  return API.get(
    'patient-profile',
    `/patients/${patientId}/medication-history`,
    { queryStringParameters }
  )
}

export const loadPatientJournalNotes = async (patientId: string) => {
  return API.get('patient-profile', `/patients/${patientId}/journal`, {})
}

export const getPatientSettings = async (patientId: string) => {
  return API.get(
    'patient-profile',
    `/patients/${patientId}/patientSettings`,
    {}
  )
}

export const getDiagnosisNoteBlock = async (noteId: number) => {
  return await API.get(
    'clinical-notes',
    `/notes/v2/${noteId}/diagnosis-block`,
    {}
  )
}

export const removeDiagnosisNoteBlock = async (noteId: number | string) => {
  return await API.del(
    'clinical-notes',
    `/notes/v2/${noteId}/diagnosis-block`,
    {}
  )
}

export const getDiagnosisSnapshot = async (
  patientId: string,
  timestamp?: Date | null
) => {
  const queryStringParameters = {
    timestamp: timestamp?.toISOString?.(),
  }

  return await API.get(
    'patient-profile',
    `/patients/${patientId}/diagnosis-snapshot`,
    { queryStringParameters }
  )
}

export const getAllergySnapshot = async (
  patientId: string,
  timestamp?: Date | null
) => {
  const queryStringParameters = {
    timestamp: timestamp?.toISOString?.(),
  }

  return await API.get(
    'patient-profile',
    `/patients/${patientId}/allergy-snapshot`,
    { queryStringParameters }
  )
}
