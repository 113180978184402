import React from 'react'

import _cloneDeep from 'lodash/cloneDeep'
import { Button, Col, Row } from 'react-bootstrap'

import { formatDate } from '../../../libs/utils'
import { formatPhoneNumber } from '../../../shared/Helpers/utils'
import CheckEditField from './CheckFields/CheckEditField'
import DatePickerEditField from './DatePickerFields/DatePickerEditField'
import DefaultEditField from './DefaultFields/DefaultEditField'
import DefaultSaveField from './DefaultFields/DefaultSaveField'
import DropdownEditField from './DropdownFields/DropdownEditField'
import MultipleEditField from './MultipleFields/MultipleEditField'
import MultipleSaveField from './MultipleFields/MultipleSaveField'
import PhoneEditField from './PhoneField/PhoneEditField'
import UnitEditField from './UnitFields/UnitEditField'
import {
  calculateAge,
  calculateBMI,
  getFeetAndInches,
} from './UnitFields/unit-lib'
import {
  CheckInput,
  CustomStyling,
  DateField,
  FieldStyling,
  FormInput,
  MultipleInput,
  StoredFields,
  TemplateField,
  Unit,
} from './types'

interface FieldGeneratorProps {
  storedFields: StoredFields
  templateField: TemplateField
  handleFieldChange: FormInput
  handleCheckChange: CheckInput
  handleMultipleSubmit: MultipleInput
  stylingProps: FieldStyling | CustomStyling
  value?: number | string | StoredFields[] | null
}

export function generateTextField(
  props: FieldGeneratorProps,
  value: number | string
) {
  return (
    <DefaultSaveField
      storedFields={props.storedFields}
      styling={props.stylingProps}
      templateField={props.templateField}
      value={value}
    />
  )
}

function generateNoInputField(
  props: FieldGeneratorProps,
  value: number | string,
  isEditing = false
) {
  const newProps = _cloneDeep(props)
  const customStyling = newProps.templateField.customStyling
  // shifts styling that would be used for editing,
  // to apply to a saved field (in this case,
  // since there's no edited component)
  if (customStyling && isEditing) {
    newProps.stylingProps = {
      colStyle: customStyling.editColStyle,
      colClassName: customStyling.editColClassName,
      colBsPrefix: customStyling.editColBsPrefix,
      labelStyle: customStyling.editLabelStyle,
      labelClassName: customStyling.editLabelClassName,
      labelBsPrefix: customStyling.editLabelBsPrefix,
      valueStyle: customStyling.editValueStyle,
      valueClassName: customStyling.editValueClassName,
      valueBsPrefix: customStyling.editValueBsPrefix,
    }
  }
  return generateTextField(newProps, value)
}

export function generateCustomField(
  props: FieldGeneratorProps,
  value: number | string,
  isEditing: boolean
) {
  const id = props.templateField.id
  const storedFields = props.storedFields
  switch (id) {
    case 'bmi':
      value = calculateBMI(storedFields)
      return generateNoInputField(props, value, isEditing)
    case 'age':
      value = calculateAge(storedFields?.DateOfBirth)
      return generateNoInputField(props, value, isEditing)
  }
}

export function generateUnitField(
  props: FieldGeneratorProps,
  value: number,
  isEditing: boolean
) {
  const unitValue = props.storedFields[props.templateField.id + 'Unit'] as Unit
  const units = props.templateField.units

  if (isEditing) {
    return (
      <UnitEditField
        handleFieldChange={props.handleFieldChange}
        handleRadioChange={props.handleCheckChange}
        styling={props.stylingProps}
        templateField={props.templateField}
        units={units}
        unitValue={unitValue}
        value={value}
      />
    )
  }

  let newValue = `${value.toString()} ${
    unitValue || (value && units && units[0]) || ''
  }`
  const isIdHeight = props.templateField.id === 'height'
  const isHeightInInches =
    isIdHeight && value && (unitValue === 'in' || !unitValue)
  if (isHeightInInches) {
    newValue = getFeetAndInches(value as number)
  }
  return generateTextField(props, newValue)
}

export function generateCheckField(
  props: FieldGeneratorProps,
  value: string | string[],
  isEditing: boolean
) {
  if (!isEditing) {
    if (Array.isArray(value)) value = value.join(', ') || ''
    // if array, comes from checkbox input
    return generateTextField(props, value)
  }

  const field = props.templateField
  const type = field.fieldType
  if (type === 'yesNo') field.options = ['Yes', 'No']

  return (
    <CheckEditField
      handleCheckChange={props.handleCheckChange}
      handleFieldChange={props.handleFieldChange}
      handleMultipleSubmit={props.handleMultipleSubmit}
      templateField={props.templateField}
      storedFields={props.storedFields}
      styling={props.stylingProps}
      type={type}
      value={value}
    />
  )
}

export function generateDatePickerField(
  props: FieldGeneratorProps,
  value: DateField | Date | null,
  isEditing: boolean
) {
  if (isEditing) {
    return (
      <DatePickerEditField
        handleFieldChange={props.handleFieldChange}
        templateField={props.templateField}
        styling={props.stylingProps}
        value={value}
      />
    )
  }

  if (!value) return generateTextField(props, '')
  let date = ''

  const isObject = typeof value === 'object'
  const isRangeField = isObject && 'startDate' in value && 'endDate' in value
  if (isRangeField && value?.startDate && value?.endDate) {
    date = `${value.startDate} to ${value.endDate}`
  } else if (isObject && 'date' in value && value?.date) {
    date = value.date
  } else {
    date = formatDate({ value })
  }

  return generateTextField(props, date)
}

export function generateDropdownField(
  props: FieldGeneratorProps,
  value: string,
  isEditing: boolean
) {
  if (!isEditing) {
    return generateTextField(props, value)
  }
  return (
    <DropdownEditField
      handleFieldChange={props.handleFieldChange}
      templateField={props.templateField}
      storedFields={props.storedFields}
      styling={props.stylingProps}
      value={value}
    />
  )
}

export function generateMultiple(
  props: FieldGeneratorProps,
  value: StoredFields[],
  isEditing: boolean
) {
  const {
    handleFieldChange,
    handleMultipleSubmit,
    stylingProps,
    templateField,
  } = props
  const multipleTemplate = templateField.multipleComponent

  if (!multipleTemplate) {
    return null
  }

  const {
    editColStyle,
    editColBsPrefix,
    editColClassName,
    editLabelStyle,
    editLabelBsPrefix,
    editLabelClassName,
    colBsPrefix,
    colClassName,
    colStyle,
    labelBsPrefix,
    labelClassName,
    labelStyle,
  } = stylingProps

  stylingProps.formRowStyle = { margin: '0px' }

  if (isEditing) {
    return (
      <Col
        bsPrefix={editColBsPrefix}
        className={editColClassName}
        style={editColStyle}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        {templateField.label && (
          <Row>
            <Col
              bsPrefix={editLabelBsPrefix}
              className={editLabelClassName}
              style={editLabelStyle}
            >
              {templateField.label}
            </Col>
          </Row>
        )}
        <Row>
          <MultipleEditField
            fields={value}
            id={templateField.id}
            handleFieldChange={handleFieldChange}
            handleMultipleSubmit={handleMultipleSubmit}
            styling={stylingProps}
            templateFields={multipleTemplate}
          />
        </Row>
        <Row>
          <Col xs={5} xl={5} className="center">
            <Button
              id={'addNewInput' + templateField.id}
              bsPrefix="info plain-button button-label"
              onClick={() => handleMultipleSubmit({}, templateField.id)}
            >
              <i className="fa fa-plus" aria-hidden="true" />
              &nbsp;{templateField.addFieldLabel || 'Open new field'}
            </Button>
          </Col>
        </Row>
      </Col>
    )
  } else {
    return (
      <Col
        bsPrefix={colBsPrefix}
        className={colClassName}
        style={colStyle}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        {templateField.label && (
          <Row>
            <Col
              bsPrefix={labelBsPrefix}
              className={labelClassName}
              style={labelStyle}
            >
              {templateField.label}
            </Col>
          </Row>
        )}
        <Row>
          <MultipleSaveField
            fields={value}
            id={templateField.id}
            styling={stylingProps}
            templateFields={multipleTemplate}
          />
        </Row>
      </Col>
    )
  }
}

export function generateDefaultField(
  props: FieldGeneratorProps,
  value: number | string,
  isEditing: boolean
) {
  if (isEditing) {
    return (
      <DefaultEditField
        handleFieldChange={props.handleFieldChange}
        templateField={props.templateField}
        styling={props.stylingProps}
        value={value}
      />
    )
  } else {
    return generateTextField(props, value)
  }
}

export function generatePhoneField(
  props: FieldGeneratorProps,
  value: number | string,
  isEditing: boolean
) {
  if (isEditing) {
    return (
      <PhoneEditField
        handleFieldChange={props.handleFieldChange}
        templateField={props.templateField}
        styling={props.stylingProps}
        value={value}
      />
    )
  } else {
    return generateTextField(props, formatPhoneNumber(value.toString()))
  }
}

export function generateLabelField(
  props: FieldGeneratorProps,
  value: DateField,
  isEditing: boolean
) {
  const { stylingProps, templateField } = props
  const {
    editLabelStyle,
    editLabelBsPrefix,
    editLabelClassName,
    labelBsPrefix,
    labelClassName,
    labelStyle,
  } = stylingProps

  const { columnSizes } = templateField

  if (isEditing) {
    return (
      <Col {...columnSizes}>
        {templateField.label && (
          <Row>
            <Col
              bsPrefix={editLabelBsPrefix}
              className={editLabelClassName}
              style={editLabelStyle}
            >
              {templateField.label}
            </Col>
          </Row>
        )}
      </Col>
    )
  } else {
    return (
      <Col {...columnSizes}>
        {templateField.label && (
          <Row>
            <Col
              bsPrefix={labelBsPrefix}
              className={labelClassName}
              style={labelStyle}
            >
              {templateField.label}
            </Col>
          </Row>
        )}
      </Col>
    )
  }
}
