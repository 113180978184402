import React, { useEffect, useState } from 'react'

import {
  getStripeAccountStatus,
  getStripeLoginLink,
  getStripeOnboardingLink,
} from '../../api/api-lib'
import { useHasPermissions } from '../../hooks/useHasPermissions'
import useQueryString from '../../hooks/useQueryString'
import paymentsLogos from '../../images/payments-logos.png'
import { notification } from '../../libs/notificationLib'
import {
  Button,
  Divider,
  PageHeader,
  Spinner,
  Tag,
  Tooltip,
} from '../../stories/BaseComponents'
import { STRIPE_ACCOUNT_STATUSES } from './stripe-constants'

import sharedStyles from '../_shared.module.scss'
import styles from './PaymentsOnboarding.module.scss'

const PaymentsOnboarding: React.FC = () => {
  const isSuperAdmin = useHasPermissions({
    adminUsers: 'WRITE',
  })

  const [stripeAccountStatus, setStripeAccountStatus] = useState(null)
  const [isFetchingStripeLink, setIsFetchingStripeLink] = useState(false)
  //add a URL param to show result=fail
  const query = useQueryString()
  useEffect(() => {
    const failure = query.get('failure')
    if (failure) {
      notification(
        'Stripe onboarding failed. Please try again or contact support.',
        'failure'
      )
    }
  }, [])

  function showStatusBadge() {
    let tagText = 'status uknown'
    let tagColor = 'yellow'
    if (stripeAccountStatus) {
      switch (stripeAccountStatus) {
        case STRIPE_ACCOUNT_STATUSES.setupComplete:
          tagText = 'Connected'
          tagColor = 'green'
          break
        case STRIPE_ACCOUNT_STATUSES.setupIncomplete:
          tagText = 'Incomplete'
          tagColor = 'orange'
          break
        case STRIPE_ACCOUNT_STATUSES.unlaunched:
          tagText = ''
          tagColor = 'white'
          break
      }
      return (
        <div>
          <Tag id={styles.statusTag} color={tagColor}>
            {tagText}
          </Tag>
        </div>
      )
    }
  }

  useEffect(() => {
    getStripeAccountStatus().then((resp) => {
      setStripeAccountStatus(resp.status)
    })
  }, [])

  const initiateStripeOnboarding = () => {
    setIsFetchingStripeLink(true)
    getStripeOnboardingLink().then((resp) => {
      location.href = resp.authorizeUrl
    })
  }

  const redirectToStripe = () => {
    setIsFetchingStripeLink(true)
    getStripeLoginLink().then((resp) => {
      setIsFetchingStripeLink(false)
      window.open(resp.redirectUrl)
    })
  }

  let buttonText = ''
  let infoText = ''
  if (stripeAccountStatus) {
    switch (stripeAccountStatus) {
      case STRIPE_ACCOUNT_STATUSES.unlaunched:
        buttonText = 'Connect'
        infoText = 'Osmind payments (powered by Stripe)'
        break
      case STRIPE_ACCOUNT_STATUSES.setupComplete:
        buttonText = 'Go to Stripe'
        infoText = "You're connected to Osmind payments (powered by Stripe)"
        break
      case STRIPE_ACCOUNT_STATUSES.setupIncomplete:
        buttonText = 'Go to Stripe'
        infoText =
          'Stripe may take a few minutes to finish verifying your account. You can also go to Stripe to check for any remaining action items.'
        break
      default:
        console.error('Stripe account status is not a recognized status')
    }
  }

  const isButtonDisabled = !isSuperAdmin || isFetchingStripeLink
  return (
    <>
      {stripeAccountStatus === null ? (
        <Spinner />
      ) : (
        <div className={sharedStyles.spacedContainer}>
          <div
            data-testid="payment-processing-onboarding"
            className={styles.page}
          >
            <PageHeader
              title="Payment processing"
              description="Connect to Osmind payments. It's free to set up and takes about 5 minutes to connect."
            />
            <div className={styles.body}>
              <Divider className={styles.antDividerFullLength} />
              <div className={styles.stripeContainer}>
                <div className={styles.infoContainer}>
                  <img className={styles.logos} src={paymentsLogos}></img>
                  <div
                    className={
                      stripeAccountStatus ===
                      STRIPE_ACCOUNT_STATUSES.setupIncomplete
                        ? styles.incompleteSetupInfo
                        : styles.info
                    }
                  >
                    {infoText}
                  </div>
                </div>
                <div className={styles.buttonContainer}>
                  {showStatusBadge()}
                  <div>
                    <Tooltip
                      className={styles.tooltip}
                      title={
                        !isSuperAdmin
                          ? 'Only the Super admin may configure Stripe'
                          : ''
                      }
                    >
                      <div>
                        <Button
                          className={styles.button}
                          type="link"
                          disabled={isButtonDisabled}
                          onClick={
                            stripeAccountStatus ===
                            STRIPE_ACCOUNT_STATUSES.unlaunched
                              ? initiateStripeOnboarding
                              : redirectToStripe
                          }
                          loading={isFetchingStripeLink}
                        >
                          <span className={styles.buttonText}>
                            {buttonText}
                          </span>
                        </Button>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <Divider className={styles.antDividerFullLength} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PaymentsOnboarding
