import React, { useEffect, useState } from 'react'

import 'moment-timezone'
import { Button, Col, Form, Row } from 'react-bootstrap'
import 'react-dates/initialize'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function ShowSavedMedicationProgress(props) {
  const [medicationName, setMedicationName] = useState(
    props.MedicationName || ''
  )
  const [efficacy, setEfficacy] = useState(props.Efficacy || '')
  const [adherence, setAdherence] = useState(props.Adherence || '')
  const [sideEffects, setSideEffects] = useState(props.SideEffects || '')
  const [sideEffectDescription, setSideEffectDescription] = useState(
    props.SideEffectDescription || ''
  )

  useEffect(() => {
    setMedicationName(props.MedicationName)
    setEfficacy(props.Efficacy)
    setAdherence(props.Adherence)
    setSideEffects(props.SideEffects)
    setSideEffectDescription(props.SideEffectDescription)
  }, [
    props.MedicationName,
    props.Efficacy,
    props.Adherence,
    props.SideEffects,
    props.SideEffectDescription,
  ])

  useEffect(() => {
    const fields = {
      MedicationName: medicationName,
      Efficacy: efficacy,
      Adherence: adherence,
      SideEffects: sideEffects,
      SideEffectDescription: sideEffectDescription,
    }
    props.saveEditMedProgress(props.index, fields)
  }, [medicationName, efficacy, adherence, sideEffects, sideEffectDescription])

  return (
    <>
      <tr style={{ width: '800px' }}>
        <td>
          <Form.Control
            bsPrefix="form-input"
            style={{ alignSelf: 'center', textAlign: 'center' }}
            disabled
            value={medicationName}
          />
        </td>
        <td>
          <Form.Control
            bsPrefix="form-input"
            style={{ alignSelf: 'center', textAlign: 'center' }}
            as="select"
            disabled={props.disabled}
            onChange={(e) => setEfficacy(e.target.value)}
            value={efficacy}
          >
            <option key={0} name="Select One" value="">
              Select One
            </option>
            <option key={1} name="Good" value="Good">
              Good
            </option>
            <option key={2} name="Fair" value="Fair">
              Fair
            </option>
            <option key={3} name="Poor" value="Poor">
              Poor
            </option>
          </Form.Control>
        </td>
        <td>
          <Form.Control
            bsPrefix="form-input"
            style={{ alignSelf: 'center', textAlign: 'center' }}
            as="select"
            disabled={props.disabled}
            onChange={(e) => setAdherence(e.target.value)}
            value={adherence}
          >
            <option key={0} name="Select One" value="">
              Select One
            </option>
            <option key={1} name="Good" value="Good">
              Good
            </option>
            <option key={2} name="Fair" value="Fair">
              Fair
            </option>
            <option key={3} name="Poor" value="Poor">
              Poor
            </option>
          </Form.Control>
        </td>
        <td>
          <Form.Control
            bsPrefix="form-input"
            style={{ alignSelf: 'center', textAlign: 'center' }}
            as="select"
            disabled={props.disabled}
            onChange={(e) => setSideEffects(e.target.value)}
            value={sideEffects}
          >
            <option key={0} name="Select One" value="">
              Select One
            </option>
            <option key={1} name="No" value="No">
              No
            </option>
            <option key={2} name="Yes" value="Yes">
              Yes
            </option>
          </Form.Control>
        </td>
        <td>
          {sideEffects === 'No' || sideEffects === '' ? (
            <Form.Control
              bsPrefix="form-input"
              style={{ alignSelf: 'center', textAlign: 'center' }}
              disabled
              as="textarea"
              rows={1}
              value=""
            />
          ) : (
            <Form.Control
              bsPrefix="form-input"
              style={{ alignSelf: 'center', textAlign: 'center' }}
              disabled={props.disabled}
              as="textarea"
              rows={1}
              onChange={(e) => setSideEffectDescription(e.target.value)}
              value={sideEffectDescription}
            />
          )}
        </td>
        <td style={{ textAlign: 'center' }}>
          <Row>
            <Col>
              {!props.disabled && (
                <Button
                  bsPrefix="center plain-button info button-label"
                  onClick={() => {
                    props.deleteMedProgress(props.index)
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true" />
                  &nbsp;Delete
                </Button>
              )}
            </Col>
          </Row>
        </td>
      </tr>
    </>
  )
}
