import React, { useMemo } from 'react'

import { Divider } from 'antd'
import { Form, FormikHandlers } from 'formik'

import { calculatedOutstandingBalanceBillableEntity } from '../../../../libs/billing'
import { PAYMENT_TYPE } from '../../../../libs/constants'
import { InsuranceClaim, Invoice } from '../../../../shared-types'
import { Radio } from '../../../../stories/BaseComponents'
import { PaymentFormValues } from './AddPaymentForm'

import styles from '../Billing.module.scss'

type AddPaymentTypeFormProps = {
  claims: InsuranceClaim[]
  invoices: Invoice[]
  values: PaymentFormValues
  handleChange: FormikHandlers['handleChange']
  fromClaim: boolean
  fromGlobalView: boolean

  setFieldValue: (field: string, value: unknown) => void
}
export const AddPaymentTypeForm = ({
  claims,
  invoices,
  values,
  handleChange,
  fromClaim,
  fromGlobalView,

  setFieldValue,
}: AddPaymentTypeFormProps) => {
  const outstandingBalanceInvoices = useMemo(
    () => calculatedOutstandingBalanceBillableEntity(invoices),
    [invoices]
  )

  const outstandingBalanceClaims = useMemo(
    () => calculatedOutstandingBalanceBillableEntity(claims),
    [claims]
  )

  const hasNoClaims = claims.length === 0

  const hasNoInvoices =
    invoices.length === 0 || outstandingBalanceInvoices === 0

  useMemo(() => {
    if ((hasNoClaims && hasNoInvoices) || fromGlobalView) {
      values.paymentType = null
    } else if (hasNoClaims) {
      setFieldValue('paymentType', PAYMENT_TYPE.INVOICE)
    } else if (hasNoInvoices) {
      setFieldValue('paymentType', PAYMENT_TYPE.CLAIM)
    } else {
      setFieldValue(
        'paymentType',
        fromClaim ? PAYMENT_TYPE.CLAIM : PAYMENT_TYPE.INVOICE
      )
    }
  }, [
    claims,
    invoices,
    fromClaim,
    outstandingBalanceClaims,
    fromGlobalView,
    outstandingBalanceInvoices,
  ])

  return (
    <Form>
      <div>
        <div className={styles.sectionHeader}>
          <b>Select the payment type</b>
        </div>
        <Radio.Group
          name="paymentType"
          onChange={handleChange}
          data-testid="payment-type-radio-group"
          id="payment-type-radio-group"
          value={values.paymentType}
        >
          <div className={styles.optionsContainer}>
            <Radio
              value={PAYMENT_TYPE.INVOICE}
              className={styles.paymentOptionRadio}
              data-testid="pay-invoice-type-radio"
              disabled={hasNoInvoices}
            >
              <span>Pay an invoice </span>
            </Radio>
            <Radio
              value={PAYMENT_TYPE.CLAIM}
              className={styles.paymentOptionRadio}
              data-testid="pay-claim-type-radio"
              disabled={hasNoClaims}
            >
              <span>Pay an insurance claim</span>
            </Radio>
          </div>
        </Radio.Group>
      </div>
      {values.paymentType && <Divider />}
    </Form>
  )
}
