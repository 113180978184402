import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { useDebounce } from '../../useDebounce'

const ICD_SEARCH_URL =
  'https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search'

type Icd10Response = [unknown, unknown, unknown, [string, string][]]

interface UseIcd10CodeDataParams {
  searchValue: string
}

export const useIcd10CodeData = ({ searchValue }: UseIcd10CodeDataParams) => {
  const debouncedSearchValue = useDebounce(searchValue, 250)
  const { isLoading, isError, data } = useQuery(
    ['icd10Data', debouncedSearchValue],
    async () => {
      const url = new URL(ICD_SEARCH_URL)
      url.searchParams.append('sf', 'code,name')
      url.searchParams.append('terms', debouncedSearchValue)
      const res = await axios.get(url.toString())
      return res.data as Icd10Response
    }
  )

  const icd10Codes = useMemo(() => {
    if (!data) {
      return []
    }

    // This is according to spec where the data we want code/name is located in
    // the 4th position/3rd index of the array
    // See: https://clinicaltables.nlm.nih.gov/apidoc/icd10cm/v3/doc.html
    return data[3].map((opt, idx) => {
      return {
        index: idx,
        name: `${opt[0]} ${opt[1]}`,
        value: {
          code: opt[0],
          description: opt[1],
        },
      }
    })
  }, [data])

  return { isLoading, isError, icd10Codes }
}
