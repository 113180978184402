import { useMemo } from 'react'

import { blue } from '@ant-design/colors'
import MessageOutlined from '@ant-design/icons/lib/icons/MessageOutlined'
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import SolutionOutlined from '@ant-design/icons/lib/icons/SolutionOutlined'
import WarningOutlined from '@ant-design/icons/lib/icons/WarningOutlined'
import { ColumnsType } from 'antd/es/table'
import { ColumnFilterItem } from 'antd/es/table/interface'
import Badge from 'antd/lib/badge'
import Tooltip from 'antd/lib/tooltip'
import { format } from 'date-fns'

import { DateTimeFormat } from '../../libs/constants'
import { Teammate } from '../../shared-types'
import { getPatientUrl } from '../../shared/Helpers/utils'
import { Text } from '../BaseComponents'
import Link from '../BaseComponents/Link'
import CareTeamSelection from './CareTeamSelection'
import PatientTags from './PatientTags'
import { PATIENT_TABLE } from './_constants'
import { CareTeam, PatientTableData, Tag } from './_types'

import styles from './_shared.module.scss'
import './_shared.scss'

type patientListConstructorType = {
  table: {
    columns: ColumnsType<PatientTableData>
  }
}

interface PatientTableConstructorProps {
  dataSource: PatientTableData[]
  allTags: Tag[]
  teammates: Teammate[]
  providerId: string
  updateCareTeam: Function
}

function getCareTeamPrimaryProviderId(careTeam: CareTeam[]) {
  const careTeamArray =
    careTeam.length > 0
      ? careTeam
          .filter((team) => team.isPrimaryProvider === true)
          .map((team) => team.providerId)
      : []

  if (careTeamArray.length > 0) {
    return careTeamArray[0]
  } else {
    return ''
  }
}

function getCareTeam(careTeam: CareTeam[]) {
  const careTeamArray =
    careTeam.length > 0
      ? careTeam
          .filter((team) => team.isPrimaryProvider === false)
          .map((team) => team.providerId)
      : []

  return careTeamArray
}

const sortScores = (a: number | null, b: number | null) => {
  if (a) {
    if (b) {
      return a - b
    }
    return 1
  }

  if (b) {
    return -1
  }
  return 0
}

export const filterTags = (input: string, record: ColumnFilterItem | {}) => {
  try {
    const { value } = record as unknown as { value: string }
    return value.indexOf(input) >= 0
  } catch {
    return false
  }
}

const constructor: (
  props: PatientTableConstructorProps
) => patientListConstructorType = ({
  dataSource,
  allTags,
  providerId,
  teammates,
  updateCareTeam,
}) => ({
  table: {
    columns: useMemo(
      () => [
        {
          dataIndex: 'isSuicidal',
          key: 'isSuicidal',
          width: '2%',
          render: (_text, { isSuicidal }) => {
            return (
              <>
                {isSuicidal && (
                  <Tooltip title="Patient reported thoughts of suicide or self-harm on their last PHQ-9, BDI-II, C-SSR, and/or DSM-XC survey(s).">
                    <WarningOutlined className="warning-icon" />
                  </Tooltip>
                )}
              </>
            )
          },
        },
        {
          title: 'Patient name',
          dataIndex: ['name', 'publicId'],
          key: 'name',
          width: '15%',
          render: (_text, { name, patientId }) => {
            return (
              <span className={styles.patientListLink}>
                <Link
                  data-testid={PATIENT_TABLE.PATIENT_NAME}
                  to={getPatientUrl(patientId, providerId)}
                >
                  {name}
                </Link>
              </span>
            )
          },
          sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
          title: 'Primary clinician',
          dataIndex: ['primaryProvider'],
          key: 'primaryProvider',
          width: '10%',
          render: (_text, { patientId, careTeam }) => {
            const primaryProvId = getCareTeamPrimaryProviderId(careTeam)
            const careTeamArray = getCareTeam(careTeam)
            return (
              <CareTeamSelection
                patientId={patientId}
                teammates={teammates}
                teammatesToDisplay={[primaryProvId]}
                primaryProvider={primaryProvId}
                careTeam={careTeamArray}
                updateCareTeam={updateCareTeam}
              />
            )
          },
        },
        {
          title: 'Care team',
          dataIndex: ['careTeam'],
          key: 'careTeam',
          width: '10%',
          render: (_text, { patientId, careTeam }) => {
            const primaryProvId = getCareTeamPrimaryProviderId(careTeam)
            const careTeamArray = getCareTeam(careTeam)
            return (
              <CareTeamSelection
                patientId={patientId}
                teammates={teammates}
                teammatesToDisplay={careTeamArray}
                primaryProvider={primaryProvId}
                careTeam={careTeamArray}
                updateCareTeam={updateCareTeam}
              />
            )
          },
        },
        {
          title: 'Tags',
          dataIndex: 'tags',
          key: 'tags',
          filterIcon: (filtered) => (
            <SearchOutlined
              data-testid={'patient-list-filter-icon'}
              style={{ color: filtered ? blue.primary : undefined }}
            />
          ),
          filters: allTags.map((tag) => ({
            text: (
              <Tooltip
                placement="leftTop"
                title={tag.label.length < 27 ? '' : tag.label}
              >
                {tag.label}
              </Tooltip>
            ),
            value: tag.label,
          })),
          filterSearch: filterTags,
          onFilter: (value, record) =>
            record.tags.some((patientTag) => patientTag.tag.label === value),
          render: (_text, { tags, patientId }) => (
            <PatientTags
              isLoading={false}
              patientId={patientId}
              tagsAlreadyAttached={tags}
            />
          ),
        },
        {
          title: 'Last mood score',
          dataIndex: 'moodScore',
          key: 'moodScore',
          width: '9%',
          sorter: (a, b) => sortScores(a.moodScore, b.moodScore),
          render: (_text, { moodScore }) => (
            <Text data-testid={PATIENT_TABLE.MOOD_SCORE}>{moodScore}</Text>
          ),
        },
        {
          title: 'Mood score date',
          dataIndex: 'moodScoreDate',
          key: 'moodScoreDate',
          width: '9%',
          sorter: (a, b) =>
            sortScores(
              a.moodScoreDate ? new Date(a.moodScoreDate).valueOf() : null,
              b.moodScoreDate ? new Date(b.moodScoreDate).valueOf() : null
            ),
          render: (_text, { moodScoreDate }) => (
            <Text data-testid={PATIENT_TABLE.MOOD_DATE}>
              {moodScoreDate
                ? format(
                    new Date(moodScoreDate),
                    DateTimeFormat.FULL_DATE_FORMAT
                  )
                : ''}
            </Text>
          ),
        },
        {
          title: 'Last PHQ-9 score',
          dataIndex: 'phqScore',
          key: 'phqScore',
          width: '9%',
          sorter: (a, b) => sortScores(a.phqScore, b.phqScore),
          render: (_text, { phqScore }) => (
            <Text data-testid={PATIENT_TABLE.PHQ_SCORE}>{phqScore}</Text>
          ),
        },
        {
          title: 'PHQ-9 score date',
          dataIndex: 'phqScoreDate',
          key: 'phqScoreDate',
          width: '9%',
          sorter: (a, b) =>
            sortScores(
              a.phqScoreDate ? new Date(a.phqScoreDate).valueOf() : null,
              b.phqScoreDate ? new Date(b.phqScoreDate).valueOf() : null
            ),
          render: (_text, { phqScoreDate }) => (
            <Text data-testid={PATIENT_TABLE.PHQ_DATE}>
              {phqScoreDate
                ? format(
                    new Date(phqScoreDate),
                    DateTimeFormat.FULL_DATE_FORMAT
                  )
                : ''}
            </Text>
          ),
        },
        {
          dataIndex: ['unreadMessageCount', 'needReview', 'publicId'],
          key: 'icon-group',
          width: '5%',
          filters: [
            {
              text: 'Unread messages',
              value: 'message',
            },
            {
              text: 'New intake forms',
              value: 'form',
            },
          ],
          onFilter: (value, record) => {
            if (value === 'message') {
              return record.unreadMessageCount > 0
            } else {
              return record.needReview
            }
          },
          render: (_text, { unreadMessageCount, needReview, patientId }) => {
            return (
              <div className="icon-group">
                {unreadMessageCount > 0 && (
                  <Link
                    to={`/patient/messages?patientId=${patientId}&providerId=${providerId}`}
                  >
                    <Tooltip title="Unread messages">
                      <Badge
                        className="message-badge"
                        count={unreadMessageCount}
                      >
                        <MessageOutlined className="message-icon" />
                      </Badge>
                    </Tooltip>
                  </Link>
                )}
                {needReview && (
                  <Link
                    to={`/patient/documents?patientId=${patientId}&providerId=${providerId}`}
                  >
                    <Tooltip title="Intake forms require review">
                      <SolutionOutlined className="review-icon" />
                    </Tooltip>
                  </Link>
                )}
              </div>
            )
          },
        },
      ],
      [dataSource, allTags]
    ),
  },
})

export default constructor
