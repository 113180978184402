import { CodeSystemOIDs } from './CodeSystems'
import { Categories, constructPatientDemographicObject } from './types'

/**
 * These codesets prioritize using SNOWMED and fall back to NUCC
 * if the SNOWMED code is not defined
 */
type ExternalCareTeamRoleCodesetObject = {
  [code: string]: {
    title: string
    description: string
    snomedcode?: string
    nucccode?: string
    nuccdescription?: string
  }
}

const medicalCareTeamRoleCodes: ExternalCareTeamRoleCodesetObject[] = [
  {
    '446701002': {
      title: 'Addiction Medicine Specialist',
      description: 'Addiction Medicine Specialist',
      snomedcode: '446701002',
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '88189002': {
      title: 'Anesthesiologist',
      description: 'Anesthesiologist',
      snomedcode: '88189002',
      nucccode: '207L00000X',
      nuccdescription: 'Anesthesiology Physician',
    },
  },
  {
    '17561000': {
      title: 'Cardiologist',
      description: 'Cardiologist',
      snomedcode: '17561000',
      nucccode: '207RC0000X',
      nuccdescription: 'Cardiovascular Disease Physician',
    },
  },
  {
    '309369000': {
      title: 'Cardiothoracic Surgeon',
      description: 'Cardiothoracic Surgeon',
      snomedcode: '309369000',
      nucccode: '208G00000X',
      nuccdescription:
        'Thoracic Surgery (Cardiothoracic Vascular Surgery) Physician',
    },
  },
  {
    '309361002': {
      title: 'Child and Adolescent Psychiatrist',
      description: 'Child and Adolescent Psychiatrist',
      snomedcode: '309361002',
      nucccode: '2084P0804X',
      nuccdescription: 'Child & Adolescent Psychiatry Physician',
    },
  },
  {
    ECTR_CODE_UNDEFINED_1: {
      title: 'Concierge Physician',
      description: 'Concierge Physician',
      snomedcode: undefined,
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '106289002': {
      title: 'Dentist',
      description: 'Dentist',
      snomedcode: '106289002',
      nucccode: '122300000X',
      nuccdescription: 'Dentist',
    },
  },
  {
    '18803008': {
      title: 'Dermatologist',
      description: 'Dermatologist',
      snomedcode: '18803008',
      nucccode: '207N00000X',
      nuccdescription: 'Dermatology Physician',
    },
  },
  {
    '309294001': {
      title: 'Emergency Medicine',
      description: 'Emergency Medicine',
      snomedcode: '309294001',
      nucccode: '207P00000X',
      nuccdescription: 'Emergency Medicine Physician',
    },
  },
  {
    '61894003': {
      title: 'Endocrinologist',
      description: 'Endocrinologist',
      snomedcode: '61894003',
      nucccode: '207RE0101X',
      nuccdescription: 'Endocrinology, Diabetes & Metabolism Physician',
    },
  },
  {
    '61345009': {
      title: 'ENT',
      description: 'ENT',
      snomedcode: '61345009',
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '71838004': {
      title: 'Gastroenterologist',
      description: 'Gastroenterologist',
      snomedcode: '71838004',
      nucccode: '207RG0100X',
      nuccdescription: 'Gastroenterology Physician',
    },
  },
  {
    '78703002': {
      title: 'General Surgeron',
      description: 'General Surgeron',
      snomedcode: '78703002',
      nucccode: '208600000X',
      nuccdescription: 'Surgery Physician',
    },
  },
  {
    '159141008': {
      title: 'Geneticist',
      description: 'Geneticist',
      snomedcode: '159141008',
      nucccode: '207SG0201X',
      nuccdescription: 'Clinical Genetics (M.D.) Physician',
    },
  },
  {
    '90655003': {
      title: 'Geriatrics',
      description: 'Geriatrics',
      snomedcode: '90655003',
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '40204001': {
      title: 'Hematologist',
      description: 'Hematologist',
      snomedcode: '40204001',
      nucccode: '207RH0000X',
      nuccdescription: 'Hematology (Internal Medicine) Physician',
    },
  },
  {
    '768837005': {
      title: 'Hospitalist',
      description: 'Hospitalist',
      snomedcode: '768837005',
      nucccode: '208M00000X',
      nuccdescription: 'Hospitalist Physician',
    },
  },
  {
    '721943002': {
      title: 'Immunology and Allergy Specialist',
      description: 'Immunology and Allergy Specialist',
      snomedcode: '721943002',
      nucccode: '207RA0201X',
      nuccdescription: 'Allergy & Immunology (Internal Medicine) Physician',
    },
  },
  {
    '76899008': {
      title: 'Infectious Disease Specialist',
      description: 'Infectious Disease Specialist',
      snomedcode: '76899008',
      nucccode: '207RI0200X',
      nuccdescription: 'Infectious Disease Physician',
    },
  },
  {
    '202D00000X': {
      title: 'Integrative Medicine',
      description: 'Integrative Medicine',
      snomedcode: undefined,
      nucccode: '202D00000X',
      nuccdescription: 'Integrative Medicine Physician',
    },
  },
  {
    '309339007': {
      title: 'Intensivist',
      description: 'Intensivist',
      snomedcode: '309339007',
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '398130009': {
      title: 'Medical Student',
      description: 'Medical Student',
      snomedcode: '398130009',
      nucccode: '390200000X',
      nuccdescription:
        'Student in an Organized Health Care Education/Training Program',
    },
  },
  {
    '175F00000X': {
      title: 'Naturopathic Doctor',
      description: 'Naturopathic Doctor',
      snomedcode: undefined,
      nucccode: '175F00000X',
      nuccdescription: 'Naturopath',
    },
  },
  {
    '28411006': {
      title: 'Neonatologist',
      description: 'Neonatologist',
      snomedcode: '28411006',
      nucccode: '2080N0001X',
      nuccdescription: 'Neonatal-Perinatal Medicine Physician',
    },
  },
  {
    '11911009': {
      title: 'Nephrologist',
      description: 'Nephrologist',
      snomedcode: '11911009',
      nucccode: '207RN0300X',
      nuccdescription: 'Nephrology Physician',
    },
  },
  {
    '56397003': {
      title: 'Neurologist',
      description: 'Neurologist',
      snomedcode: '56397003',
      nucccode: '2084N0400X',
      nuccdescription: 'Neurology Physician',
    },
  },
  {
    '21450003': {
      title: 'Neuropsychiatrist',
      description: 'Neuropsychiatrist',
      snomedcode: '21450003',
      nucccode: '2084P0800X',
      nuccdescription: 'Psychiatry Physician',
    },
  },
  {
    '45544007': {
      title: 'Neurosurgeon',
      description: 'Neurosurgeon',
      snomedcode: '45544007',
      nucccode: '207T00000X',
      nuccdescription: 'Neurological Surgery Physician',
    },
  },
  {
    ECTR_CODE_UNDEFINED_2: {
      title: 'Obesity Medicine',
      description: 'Obesity Medicine',
      snomedcode: undefined,
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '309367003': {
      title: 'Obstetrician/Gynecologist',
      description: 'Obstetrician/Gynecologist',
      snomedcode: '309367003',
      nucccode: '207V00000X',
      nuccdescription: 'Obstetrics & Gynecology Physician',
    },
  },
  {
    '309295000': {
      title: 'Oncologist',
      description: 'Oncologist',
      snomedcode: '309295000',
      nucccode: '207RX0202X',
      nuccdescription: 'Medical Oncology Physician',
    },
  },
  {
    '422234006': {
      title: 'Ophthalmologist',
      description: 'Ophthalmologist',
      snomedcode: '422234006',
      nucccode: '207W00000X',
      nuccdescription: 'Ophthalmology Physician',
    },
  },
  {
    '22731001': {
      title: 'Orthopaedic Surgeon',
      description: 'Orthopaedic Surgeon',
      snomedcode: '22731001',
      nucccode: '207X00000X',
      nuccdescription: 'Orthopaedic Surgery Physician',
    },
  },
  {
    '61345009': {
      title: 'Otolaryngologist',
      description: 'Otolaryngologist',
      snomedcode: '61345009',
      nucccode: '207Y00000X',
      nuccdescription: 'Otolaryngology Physician',
    },
  },
  {
    '82296001': {
      title: 'Pediatrician',
      description: 'Pediatrician',
      snomedcode: '82296001',
      nucccode: '208000000X',
      nuccdescription: 'Pediatrics Physician',
    },
  },
  {
    '2080N0001X': {
      title: 'Perinatologist',
      description: 'Perinatologist',
      snomedcode: undefined,
      nucccode: '2080N0001X',
      nuccdescription: 'Neonatal-Perinatal Medicine Physician',
    },
  },
  {
    '24430003': {
      title: 'Physical Medicine and Rehabilitation',
      description: 'Physical Medicine and Rehabilitation',
      snomedcode: '24430003',
      nucccode: '208100000X',
      nuccdescription: 'Physical Medicine & Rehabilitation Physician',
    },
  },
  {
    '8724009': {
      title: 'Plastic Surgeon',
      description: 'Plastic Surgeon',
      snomedcode: '8724009',
      nucccode: '208200000X',
      nuccdescription: 'Plastic Surgery Physician',
    },
  },
  {
    '159034004': {
      title: 'Podiatrist',
      description: 'Podiatrist',
      snomedcode: '159034004',
      nucccode: '213E00000X',
      nuccdescription: 'Podiatrist',
    },
  },
  {
    '446050000': {
      title: 'Primary Care Physician',
      description: 'Primary Care Physician',
      snomedcode: '446050000',
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '80584001': {
      title: 'Psychiatrist',
      description: 'Psychiatrist',
      snomedcode: '80584001',
      nucccode: '2084P0800X',
      nuccdescription: 'Psychiatry Physician',
    },
  },
  {
    '41672002': {
      title: 'Pulmonologist',
      description: 'Pulmonologist',
      snomedcode: '41672002',
      nucccode: '207RP1001X',
      nuccdescription: 'Pulmonary Disease Physician',
    },
  },
  {
    '309353007': {
      title: 'Reproductive Endocrinology and Infertility',
      description: 'Reproductive Endocrinology and Infertility',
      snomedcode: '309353007',
      nucccode: '207VE0102X',
      nuccdescription: 'Reproductive Endocrinology Physician',
    },
  },
  {
    '405277009': {
      title: 'Resident',
      description: 'Resident',
      snomedcode: '405277009',
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '45440000': {
      title: 'Rheumatologist',
      description: 'Rheumatologist',
      snomedcode: '45440000',
      nucccode: '207RR0500X',
      nuccdescription: 'Rheumatology Physician',
    },
  },
  {
    '720503005': {
      title: 'Sleep Medicine Specialist',
      description: 'Sleep Medicine Specialist',
      snomedcode: '720503005',
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    ECTR_CODE_UNDEFINED_3: {
      title: 'Sports Medicine Specialist',
      description: 'Sports Medicine Specialist',
      snomedcode: undefined,
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '309386006': {
      title: 'Trauma Surgeon',
      description: 'Trauma Surgeon',
      snomedcode: '309386006',
      nucccode: '2086S0127X',
      nuccdescription: 'Trauma Surgery Physician',
    },
  },
  {
    ECTR_CODE_UNDEFINED_4: {
      title: 'Urgent Care Specialist',
      description: 'Urgent Care Specialist',
      snomedcode: undefined,
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '24590004': {
      title: 'Urologist',
      description: 'Urologist',
      snomedcode: '24590004',
      nucccode: '208800000X',
      nuccdescription: 'Urology Physician',
    },
  },
  {
    '309388007': {
      title: 'Vascular Surgeon',
      description: 'Vascular Surgeon',
      snomedcode: '309388007',
      nucccode: '2086S0129X',
      nuccdescription: 'Vascular Surgery Physician',
    },
  },
]

const mentalHealthCareTeamRoleCodes: ExternalCareTeamRoleCodesetObject[] = [
  {
    '309410006': {
      title: 'Arts Therapist',
      description: 'Arts Therapist',
      snomedcode: '309410006',
      nucccode: '221700000X',
      nuccdescription: 'Art Therapist',
    },
  },
  {
    '224595007': {
      title: 'Counselor',
      description: 'Counselor',
      snomedcode: '224595007',
      nucccode: '101Y00000X',
      nuccdescription: 'Counselor',
    },
  },
  {
    '101YA0400X': {
      title: 'Counselor Addictions',
      description: 'Counselor Addictions',
      snomedcode: undefined,
      nucccode: '101YA0400X',
      nuccdescription: 'Addiction (Substance Use Disorder) Counselor',
    },
  },
  {
    '309411005': {
      title: 'Dance Therapist',
      description: 'Dance Therapist',
      snomedcode: '309411005',
      nucccode: '225600000X',
      nuccdescription: 'Dance Therapist',
    },
  },
  {
    '224585000': {
      title: 'Drama Therapist',
      description: 'Drama Therapist',
      snomedcode: '224585000',
      nucccode: '101200000X',
      nuccdescription: 'Drama Therapist',
    },
  },
  {
    ECTR_CODE_UNDEFINED_5: {
      title: 'Equine Therapist',
      description: 'Equine Therapist',
      snomedcode: undefined,
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    ECTR_CODE_UNDEFINED_6: {
      title: 'Group Therapist',
      description: 'Group Therapist',
      snomedcode: undefined,
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '224596008': {
      title: 'Marriage and Family Therapist',
      description: 'Marriage and Family Therapist',
      snomedcode: '224596008',
      nucccode: '106H00000X',
      nuccdescription: 'Marriage & Family Therapist',
    },
  },
  {
    '310190000': {
      title: 'Mental Health Counselor',
      description: 'Mental Health Counselor',
      snomedcode: '310190000',
      nucccode: '101YM0800X',
      nuccdescription: 'Mental Health Counselor',
    },
  },
  {
    '309412003': {
      title: 'Music Therapist',
      description: 'Music Therapist',
      snomedcode: '309412003',
      nucccode: '225A00000X',
      nuccdescription: 'Music Therapist',
    },
  },
  {
    '101YP1600X': {
      title: 'Pastoral Counselor',
      description: 'Pastoral Counselor',
      snomedcode: undefined,
      nucccode: '101YP1600X',
      nuccdescription: 'Pastoral Counselor',
    },
  },
  {
    '175T00000X': {
      title: 'Peer Specialist',
      description: 'Peer Specialist',
      snomedcode: undefined,
      nucccode: '175T00000X',
      nuccdescription: 'Peer Specialist',
    },
  },
  {
    '102X00000X': {
      title: 'Poetry Therapist',
      description: 'Poetry Therapist',
      snomedcode: undefined,
      nucccode: '102X00000X',
      nuccdescription: 'Poetry Therapist',
    },
  },
  {
    ECTR_CODE_UNDEFINED_7: {
      title: 'Psychedelic Therapy Facilitator',
      description: 'Psychedelic Therapy Facilitator',
      snomedcode: undefined,
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '363LP0808X': {
      title: 'Psychiatric Nurse Practitioner',
      description: 'Psychiatric Nurse Practitioner',
      snomedcode: undefined,
      nucccode: '363LP0808X',
      nuccdescription: 'Psychiatric/Mental Health Nurse Practitioner',
    },
  },
  {
    '59944000': {
      title: 'Psychologist',
      description: 'Psychologist',
      snomedcode: '59944000',
      nucccode: '103T00000X',
      nuccdescription: 'Psychologist',
    },
  },
  {
    '225800000X': {
      title: 'Recreational Therapist',
      description: 'Recreational Therapist',
      snomedcode: undefined,
      nucccode: '225800000X',
      nuccdescription: 'Recreation Therapist',
    },
  },
  {
    '106328005': {
      title: 'Social Worker',
      description: 'Social Worker',
      snomedcode: '106328005',
      nucccode: '104100000X',
      nuccdescription: 'Social Worker',
    },
  },
  {
    '224610007': {
      title: 'Support Specialist/Recovery Coach',
      description: 'Support Specialist/Recovery Coach',
      snomedcode: '224610007',
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '65853000': {
      title: 'Therapy/Psychology Student',
      description: 'Therapy/Psychology Student',
      snomedcode: '65853000',
      nucccode: '390200000X',
      nuccdescription:
        'Student in an Organized Health Care Education/Training Program',
    },
  },
  {
    ECTR_CODE_UNDEFINED_8: {
      title: 'Therapist',
      description: 'Therapist',
      snomedcode: undefined,
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
]

const nursingCareTeamRoleCodes: ExternalCareTeamRoleCodesetObject[] = [
  {
    '224563006': {
      title: 'Mental Health Nurse',
      description: 'Mental Health Nurse',
      snomedcode: '224563006',
      nucccode: '163WP0808X',
      nuccdescription: 'Psychiatric/Mental Health Registered Nurse',
    },
  },
  {
    '224570006': {
      title: 'Infusion Nurse',
      description: 'Infusion Nurse',
      snomedcode: '224570006',
      nucccode: '163WI0500X',
      nuccdescription: 'Infusion Therapy Registered Nurse',
    },
  },
  {
    '442251000124100': {
      title: 'Licensed Practical Nurse (LPN)',
      description: 'Licensed Practical Nurse (LPN)',
      snomedcode: '442251000124100',
      nucccode: '164W00000X',
      nuccdescription: 'Licensed Practical Nurse',
    },
  },
  {
    '224571005': {
      title: 'Nurse Practitioner',
      description: 'Nurse Practitioner',
      snomedcode: '224571005',
      nucccode: '363L00000X',
      nuccdescription: 'Nurse Practitioner',
    },
  },
  {
    '159005005': {
      title: 'Nursing Student',
      description: 'Nursing Student',
      snomedcode: '159005005',
      nucccode: '390200000X',
      nuccdescription:
        'Student in an Organized Health Care Education/Training Program',
    },
  },
  {
    '449161006': {
      title: 'Physician Assistant',
      description: 'Physician Assistant',
      snomedcode: '449161006',
      nucccode: '363A00000X',
      nuccdescription: 'Physician Assistant',
    },
  },
  {
    '224535009': {
      title: 'Registered nurse (RN)',
      description: 'Registered nurse (RN)',
      snomedcode: '224535009',
      nucccode: '163W00000X',
      nuccdescription: 'Registered Nurse',
    },
  },
]

const alliedHealthCareTeamRoleCodes: ExternalCareTeamRoleCodesetObject[] = [
  {
    '450044741000087104': {
      title: 'Acupuncturist',
      description: 'Acupuncturist',
      snomedcode: '450044741000087104',
      nucccode: '171100000X',
      nuccdescription: 'Acupuncturist',
    },
  },
  {
    '3842006': {
      title: 'Chiropractor',
      description: 'Chiropractor',
      snomedcode: '3842006',
      nucccode: '111N00000X',
      nuccdescription: 'Chiropractor',
    },
  },
  {
    '40127002': {
      title: 'Dietician',
      description: 'Dietician',
      snomedcode: '40127002',
      nucccode: '132700000X',
      nuccdescription: 'Dietary Manager',
    },
  },
  {
    '225726006': {
      title: 'Lactation Consultant',
      description: 'Lactation Consultant',
      snomedcode: '225726006',
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '1172950003': {
      title: 'Massage Therapists',
      description: 'Massage Therapists',
      snomedcode: '1172950003',
      nucccode: '225700000X',
      nuccdescription: 'Massage Therapist',
    },
  },
  {
    '768827000': {
      title: 'Nutritionist',
      description: 'Nutritionist',
      snomedcode: '768827000',
      nucccode: '133N00000X',
      nuccdescription: 'Nutritionist',
    },
  },
  {
    '80546007': {
      title: 'Occupational Therapist',
      description: 'Occupational Therapist',
      snomedcode: '80546007',
      nucccode: '225X00000X',
      nuccdescription: 'Occupational Therapist',
    },
  },
  {
    '224587008': {
      title: 'Occupational Therapy Assistant',
      description: 'Occupational Therapy Assistant',
      snomedcode: '224587008',
      nucccode: '224Z00000X',
      nuccdescription: 'Occupational Therapy Assistant',
    },
  },
  {
    '840584008': {
      title: 'Occupational Therapy Student',
      description: 'Occupational Therapy Student',
      snomedcode: '840584008',
      nucccode: '390200000X',
      nuccdescription:
        'Student in an Organized Health Care Education/Training Program',
    },
  },
  {
    '40677000': {
      title: 'Personal Trainer/Athletic trainer',
      description: 'Personal Trainer/Athletic trainer',
      snomedcode: '40677000',
      nucccode: '2255A2300X',
      nuccdescription: 'Athletic Trainer',
    },
  },
  {
    '46255001': {
      title: 'Pharmacist',
      description: 'Pharmacist',
      snomedcode: '46255001',
      nucccode: '183500000X',
      nuccdescription: 'Pharmacist',
    },
  },
  {
    '36682004': {
      title: 'Physical Therapist',
      description: 'Physical Therapist',
      snomedcode: '36682004',
      nucccode: '225100000X',
      nuccdescription: 'Physical Therapist',
    },
  },
  {
    '309404006': {
      title: 'Physical Therapy Assistant',
      description: 'Physical Therapy Assistant',
      snomedcode: '309404006',
      nucccode: '225200000X',
      nuccdescription: 'Physical Therapy Assistant',
    },
  },
  {
    '840584008': {
      title: 'Physical Therapy Student',
      description: 'Physical Therapy Student',
      snomedcode: '840584008',
      nucccode: '390200000X',
      nuccdescription:
        'Student in an Organized Health Care Education/Training Program',
    },
  },
  {
    '442867008': {
      title: 'Respiratory Therapist',
      description: 'Respiratory Therapist',
      snomedcode: '442867008',
      nucccode: '227900000X',
      nuccdescription: 'Registered Respiratory Therapist',
    },
  },
  {
    '840584008': {
      title: 'Respiratory Therapy Student',
      description: 'Respiratory Therapy Student',
      snomedcode: '840584008',
      nucccode: '390200000X',
      nuccdescription:
        'Student in an Organized Health Care Education/Training Program',
    },
  },
  {
    '159026005': {
      title: 'Speech Language Pathologist',
      description: 'Speech Language Pathologist',
      snomedcode: '159026005',
      nucccode: '235Z00000X',
      nuccdescription: 'Speech-Language Pathologist',
    },
  },
]

const supportStaffRoleCodes: ExternalCareTeamRoleCodesetObject[] = [
  {
    '840584008': {
      title: 'Speech Language Pathology Student',
      description: 'Speech Language Pathology Student',
      snomedcode: '840584008',
      nucccode: '390200000X',
      nuccdescription:
        'Student in an Organized Health Care Education/Training Program',
    },
  },
  {
    '768832004': {
      title: 'Case Manager',
      description: 'Case Manager',
      snomedcode: '768832004',
      nucccode: '171M00000X',
      nuccdescription: 'Case Manager/Care Coordinator',
    },
  },
  {
    '768730001': {
      title: 'Home Health Aide',
      description: 'Home Health Aide',
      snomedcode: '768730001',
      nucccode: '374U00000X',
      nuccdescription: 'Home Health Aide',
    },
  },
  {
    '22515006': {
      title: 'Medical Assistant (MA)',
      description: 'Medical Assistant (MA)',
      snomedcode: '22515006',
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '374700000X': {
      title: 'Patient Care Tech',
      description: 'Patient Care Tech',
      snomedcode: undefined,
      nucccode: '374700000X',
      nuccdescription: 'Technician',
    },
  },
  {
    '768836001': {
      title: 'Coordination Specialist/Care Navigator',
      description: 'Coordination Specialist/Care Navigator',
      snomedcode: '768836001',
      nucccode: '171M00000X',
      nuccdescription: 'Case Manager/Care Coordinator',
    },
  },
  {
    '768826009': {
      title: 'Crisis Specialist',
      description: 'Crisis Specialist',
      snomedcode: '768826009',
      nucccode: undefined,
      nuccdescription: undefined,
    },
  },
  {
    '158912007': {
      title: 'Vocational Rehab Counselor',
      description: 'Vocational Rehab Counselor',
      snomedcode: '158912007',
      nucccode: '225X00000X',
      nuccdescription: 'Occupational Therapist',
    },
  },
]

export const medicalCareTeamRoleDropdownValues =
  constructPatientDemographicObject(
    medicalCareTeamRoleCodes,
    CodeSystemOIDs.SNOMEDCT, // Defaults to SnowMED, but has NUCC and undefined codes
    Categories.PROVIDER_ROLE
  )
export const mentalHealthCareTeamRoleDropdownValues =
  constructPatientDemographicObject(
    mentalHealthCareTeamRoleCodes,
    CodeSystemOIDs.SNOMEDCT, // Defaults to SnowMED, but has NUCC and undefined codes
    Categories.PROVIDER_ROLE
  )
export const nursingCareTeamRoleDropdownValues =
  constructPatientDemographicObject(
    nursingCareTeamRoleCodes,
    CodeSystemOIDs.SNOMEDCT, // Defaults to SnowMED, but has NUCC and undefined codes
    Categories.PROVIDER_ROLE
  )
export const alliedHealthCareTeamRoleDropdownValues =
  constructPatientDemographicObject(
    alliedHealthCareTeamRoleCodes,
    CodeSystemOIDs.SNOMEDCT, // Defaults to SnowMED, but has NUCC and undefined codes
    Categories.PROVIDER_ROLE
  )
export const supportStaffRoleDropdownValues = constructPatientDemographicObject(
  supportStaffRoleCodes,
  CodeSystemOIDs.SNOMEDCT, // Defaults to SnowMED, but has NUCC and undefined codes
  Categories.PROVIDER_ROLE
)
