import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const suffixHL7: PatientDemographicObject[] = [
  { II: { title: 'II', description: 'The Second' } },
  { III: { title: 'III', description: 'The Third' } },
  { IV: { title: 'IV', description: 'The Fourth' } },
  { V: { title: 'V', description: 'The Fifth' } },
  { DDS: { title: 'DDS', description: 'Doctor of Dental Medicine' } },
  { Esq: { title: 'Esq', description: 'Esquire' } },
  { JD: { title: 'JD', description: 'Jurist Doctor' } },
  { Jr: { title: 'Jr', description: 'Junior' } },
  { LLD: { title: 'LLD', description: 'Doctor of Laws' } },
  { MD: { title: 'MD', description: 'Doctor of Medicine' } },
  { PhD: { title: 'PhD', description: 'Doctorate' } },
  { Ret: { title: 'Ret', description: 'Retired from Armed Forces' } },
  { USN: { title: 'USN', description: 'US Navy' } },
  { USMC: { title: 'USMC', description: 'US Marine Corps' } },
  { USAF: { title: 'USAF', description: 'US Air force' } },
  { USA: { title: 'USA', description: 'US Army' } },
  { Sr: { title: 'Sr', description: 'Senior' } },
  { DO: { title: 'DO', description: 'Doctor of Osteopathy' } },
  { PharmD: { title: 'PharmD', description: 'Doctor of Pharmacy' } },
  { DoNotDisplay: { title: 'Other', description: 'Other' } },
  { 'Do Not Display': { title: 'None', description: 'None' } },
]

export const suffixDropDownValues = constructPatientDemographicObject(
  suffixHL7,
  CodeSystemOIDs.HL7EntityNamePartQualifier,
  Categories.SUFFIX
)
