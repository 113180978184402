import React, { useState } from 'react'

import 'moment-timezone'
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
import 'react-dates/initialize'
import { Link } from 'react-router-dom'

import useQueryString from '../../../../../hooks/useQueryString'
import { NoteTypes } from '../../../../../shared-types'
import { AutoSizingTextArea } from '../../../../../stories/BaseComponents/TextAreaInput'
import ComprehensiveMedicalROS from '../Sections/ComprehensiveMedicalROS'
import MentalStatusExam from '../Sections/MSE'
import ShowSavedVS from '../Tables/ShowSavedVS'
import AddVitals from './AddVitals'
import PhysicalExam from './PhysicalExam'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function Objective(props) {
  const query = useQueryString()
  const patientId = query.get('patientId')
  const providerId = query.get('providerId')
  const [labRec, setLabRec] = useState(false)
  const [img, setImg] = useState(false)
  const isNotInSpravatoNotes = !props.Spravato
  const { NoteType } = props.fields

  function handleShowSavedVitals() {
    if (props.fields.SavedVitalSigns.length !== 0) {
      return (
        <>
          {props.fields.SavedVitalSigns.map((vitals, index) => {
            return (
              <ShowSavedVS
                key={index}
                index={index}
                deleteVitals={props.deleteVitals}
                VitalSignsType={vitals.VitalSignsType}
                Time={vitals.Time}
                HR={vitals.HR}
                BP={vitals.BP}
                BpArm={vitals.BpArm}
                RR={vitals.RR}
                SPO2={vitals.SPO2}
                Comments={vitals.Comments}
                EKG={vitals.EKG}
                RASSscore={vitals.RASSscore}
                saveEditVitalSigns={props.saveEditVitalSigns}
                disabled={props.disabled}
                discharge
              />
            )
          })}
        </>
      )
    }
  }
  return (
    <>
      {props.Medical && (
        <>
          <Row>
            <Form.Group
              as={Col}
              sm={12}
              md={12}
              lg={12}
              style={{ textAlign: 'center', marginTop: '10px' }}
            >
              {props.fields.ROS && (
                <>
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Medical ROS:
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    value={props.fields.ROS}
                    disabled={props.disabled}
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        ROS: e.target.value,
                      })
                    }
                    as="textarea"
                    rows={2}
                  />
                </>
              )}
            </Form.Group>
          </Row>
        </>
      )}
      {[
        NoteTypes.SPRAVATO,
        NoteTypes.MEDICAL_SOAP,
        NoteTypes.CLINICAL_NOTE,
      ].includes(NoteType) && (
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          style={{ marginTop: '5px' }}
        >
          <ComprehensiveMedicalROS {...props} />
          <MentalStatusExam {...props} />
          <PhysicalExam {...props} />
        </Form.Group>
      )}
      {[NoteTypes.THERAPY_SOAP].includes(NoteType) && (
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          style={{ marginTop: '5px' }}
        >
          <MentalStatusExam {...props} />
        </Form.Group>
      )}

      <Row>
        <Link
          to={{
            pathname: `/patient/progress?patientId=${patientId}&providerId=${providerId}`,
          }}
          target="_blank"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '10px',
            marginTop: '10px',
          }}
        >
          <Button variant="primary">View validated questionnaires</Button>
        </Link>
      </Row>
      {props.Medical && (
        <>
          {isNotInSpravatoNotes && (
            <>
              <Table
                style={{ marginTop: '20px', marginBottom: '30px' }}
                responsive
              >
                {props.fields.SavedVitalSigns.length !== 0 || !props.detail ? (
                  <thead>
                    <tr>
                      <th
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          minWidth: '140px',
                        }}
                      >
                        Time
                      </th>
                      <th
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          minWidth: '96px',
                        }}
                      >
                        HR
                      </th>
                      <th
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          minWidth: '96px',
                        }}
                      >
                        BP
                      </th>
                      <th
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          minWidth: '96px',
                        }}
                      >
                        SpO2
                      </th>
                      <th
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          minWidth: '96px',
                        }}
                      >
                        EKG
                      </th>
                      <th
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          minWidth: '96px',
                        }}
                      >
                        RR
                      </th>
                      <th
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          minWidth: '96px',
                        }}
                      >
                        RASS
                      </th>
                      <th
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          minWidth: '336px',
                        }}
                      >
                        Comments
                      </th>
                      <th
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          minWidth: '112px',
                        }}
                      >
                        &nbsp;
                      </th>
                    </tr>
                  </thead>
                ) : (
                  <></>
                )}
                <tbody>{handleShowSavedVitals()}</tbody>
              </Table>
              <AddVitals
                shortPublicId={props.shortPublicId}
                disabled={props.disabled}
                setAddVitals={props.setAddVitals}
                savedVitalSigns={props.fields.SavedVitalSigns}
                bPPlacement={props.fields.BpPlacement}
                handleSaveVitals={props.saveNewVitals}
                handleSaveCaretakerVitals={props.saveNewCaretakerVitals}
                handleFieldChange={(value) => {
                  props.handleFieldChange({
                    ...props.fields,
                    BpPlacement: value,
                  })
                }}
              />
              <Form.Row>
                <Col className="center">
                  <Form.Text
                    style={{
                      fontWeight: 'bold',
                      color: '#A9A9A9',
                      paddingBottom: '20px',
                    }}
                    className="hideDuringPrint"
                  >
                    Small screens may need to scroll horizontally for a full
                    view of medication and vitals.
                  </Form.Text>
                </Col>
              </Form.Row>
            </>
          )}
          <hr />
          <Row style={{ marginTop: '10px' }}>
            <Form.Group
              as={Col}
              sm={12}
              md={6}
              lg={6}
              style={{
                textAlign: 'center',
                margin: 'auto',
                marginBottom: '10px',
              }}
            >
              {!props.fields.LabRecords && !labRec ? (
                <Button
                  variant="outline-primary"
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '10px',
                  }}
                  disabled={props.disabled}
                  onClick={() => {
                    setLabRec(!labRec)
                  }}
                >
                  + Add labs/records
                </Button>
              ) : (
                <>
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Labs/Records:
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-control"
                    className="field-value-edit"
                    value={props.fields.LabRecords}
                    disabled={props.disabled}
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        LabRecords: e.target.value,
                      })
                    }
                    as={AutoSizingTextArea}
                    minRows={1}
                  />
                </>
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              sm={12}
              md={6}
              lg={6}
              style={{
                textAlign: 'center',
                margin: 'auto',
                marginBottom: '10px',
              }}
            >
              {!props.fields.Imaging && !img ? (
                <Button
                  variant="outline-primary"
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '10px',
                  }}
                  disabled={props.disabled}
                  onClick={() => {
                    setImg(!img)
                  }}
                >
                  + Add imaging
                </Button>
              ) : (
                <>
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Imaging:
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-control"
                    className="field-value-edit"
                    value={props.fields.Imaging}
                    disabled={props.disabled}
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        Imaging: e.target.value,
                      })
                    }
                    as={AutoSizingTextArea}
                    minRows={1}
                  />
                </>
              )}
            </Form.Group>
          </Row>
        </>
      )}
      {props.Therapy && isNotInSpravatoNotes && (
        <Row>
          <Form.Group
            as={Col}
            sm={12}
            md={12}
            lg={12}
            style={{
              textAlign: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Additional Notes
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.TreatmentType}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  TreatmentType: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        </Row>
      )}
    </>
  )
}
