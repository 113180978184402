import { RefObject, useRef, useState } from 'react'

import { SchedulingProvider } from '../../providers'
import { SchedulingPageFunc } from './SchedulingPage'

interface SchedulingWrapperProps {
  clinicId: string
}

const SchedulingWrapper = ({ clinicId }: SchedulingWrapperProps) => {
  const collapseButtonRef: RefObject<HTMLButtonElement> = useRef(null)
  const [collapsed, setCollapsed] = useState<boolean>(() => {
    return JSON.parse(
      localStorage.getItem('scheduling_collapsedSidebar') ?? 'false'
    )
  })

  const handleCollapseClick = () => {
    localStorage.setItem(
      'scheduling_collapsedSidebar',
      JSON.stringify(!collapsed)
    )
    setCollapsed(!collapsed)
  }

  return (
    <SchedulingProvider providerId={clinicId}>
      <SchedulingPageFunc
        handleCollapseClick={handleCollapseClick}
        collapseButtonRef={collapseButtonRef}
        collapsed={collapsed}
        providerId={clinicId}
      />
    </SchedulingProvider>
  )
}

export default SchedulingWrapper
