import { API } from 'aws-amplify'

import { ReportType } from '../stories/Reports/types'

export interface SurveyItem {
  discriminator: ReportType.SURVEYS
  patientFirstName: string
  patientMiddleName?: string
  patientLastName: string
  type: string
  score: number
  publicId: string
  patientId: string
  createdAt: string
  providerId: string
}

export interface SurveysResponse {
  surveys: SurveyItem[]
  total: number
}

type getSurveysByDateRange = (
  start: string,
  end: string
) => Promise<SurveysResponse>

const mapSurveyItems = ({ surveys, ...aggregations }: SurveysResponse) => {
  const mappedSurveys = surveys.map(({ type, ...survey }) => {
    return {
      ...survey,
      type: type ? type : 'None',
      discriminator: ReportType.SURVEYS,
    } as SurveyItem
  })

  return { surveys: mappedSurveys, ...aggregations }
}

export const getSurveysByDateRange: getSurveysByDateRange = async (
  start,
  end
) => {
  return mapSurveyItems(
    await API.get('core-api', `/provider/surveys?start=${start}&end=${end}`, {})
  )
}
