import React, { HTMLProps, useEffect, useRef, useState } from 'react'

import { ClockCircleOutlined, CloseOutlined } from '@ant-design/icons'
import 'moment-timezone'
import 'react-dates/initialize'

import { TestId } from '../../shared-types'
import {
  convertTime12to24,
  convertTime24to12,
} from '../../shared/Helpers/utils'
import { ToggleSave } from './InlineEditFields/shared-types'

import 'react-dates/lib/css/_datepicker.css'

interface TimeValues {
  hours: string
  minutes: string
  modifier: string
}

interface TimePickerProps
  extends Omit<HTMLProps<HTMLDivElement>, 'onChange'>,
    TestId {
  inline?: boolean | null
  onChange: (newTime: string) => void
  value: string
  toggleSave?: ToggleSave
  toggleBlur?: () => void
}

export default function TimePicker(props: TimePickerProps) {
  const [column, setColumn] = useState<number | null>(
    props.inline == null ? null : 0
  )
  const [value, setValue] = useState<string>('')
  const [fields, setFields] = useState<TimeValues>({
    hours: 'hh',
    minutes: 'mm',
    modifier: 'aa',
  })
  const [lastKey, setLastKey] = useState<string>('0')

  const timePickerContainerRef = useRef<any>(null)

  const inputTextField = () => {
    return timePickerContainerRef.current?.firstChild?.firstChild?.firstChild
  }

  const selectColumn = (col: number | null) => {
    if (col !== column) {
      setColumn(col)
      setLastKey('0')
    }
    const e = inputTextField()
    if (col === null) {
      e.blur()
    } else {
      const start = col * 3
      e.focus()
      setTimeout(function () {
        e.setSelectionRange(start, start)
        e.setSelectionRange(start, start + 2)
      }, 1)
    }
  }

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value)
    }
    const { hours, minutes, modifier } = convertTime24to12(props.value)
    setFields({ hours, minutes, modifier })
    if (props.inline == null) {
      selectColumn(null)
    }
  }, [props.value])

  useEffect(() => {
    const time = `${fields.hours}:${fields.minutes} ${fields.modifier}`
    inputTextField().setAttribute('value', time)
    inputTextField().value = time
    selectColumn(column)
  }, [fields, value])

  useEffect(() => {
    if (props.disabled && props.value === '') {
      setFields({ hours: 'hh', minutes: 'mm', modifier: 'aa' })
    }
  }, [props.disabled])

  const onBlur = () => {
    props.onChange(convertTime12to24(value))
  }

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const el = e.target as HTMLElement
    if (el.tagName === 'svg' || el.tagName === 'path') {
      if (props.toggleSave) {
        const { hours, minutes, modifier } = convertTime24to12('')
        inputTextField().value = 'hh:mm aa'
        setValue('')
        setFields({ hours, minutes, modifier })
      } else {
        props.onChange('')
      }
      return
    }
    const pos = inputTextField().selectionStart
    const selectedColumn = Math.floor(pos / 3)
    selectColumn(selectedColumn)
  }

  const moveRight = (
    e: React.KeyboardEvent<HTMLDivElement>,
    modifier: string,
    column: number
  ) => {
    e.stopPropagation()
    e.preventDefault()
    if (modifier === '') {
      const nextColumn = column === 1 ? 1 : (column + 1) % 2
      selectColumn(nextColumn)
    } else {
      const nextColumn = column === 2 ? 2 : (column + 1) % 3
      selectColumn(nextColumn)
    }
  }

  const moveLeft = (
    e: React.KeyboardEvent<HTMLDivElement>,
    modifier: string,
    column: number
  ) => {
    e.stopPropagation()
    e.preventDefault()
    const prevColumn = column === 0 ? 0 : column - 1
    selectColumn(prevColumn)
  }

  const updateInputField = (key: string, timeField: TimeValues) => {
    let { hours, minutes, modifier } = timeField
    if (key >= '0' && key <= '9') {
      if (column === 0) {
        hours = lastKey + key
        if (+hours >= 0 && +hours <= 2) {
          setLastKey(key)
        } else {
          if (+hours > 0 && +hours < 24) {
            setLastKey('0')
            selectColumn(1)
          } else {
            hours = '0' + lastKey
          }
        }
      }
      if (column === 1) {
        minutes = lastKey + key
        if (+minutes <= 5) {
          setLastKey(key)
        } else {
          setLastKey('0')
          if (+hours <= 12) {
            selectColumn(2)
          }
        }
      }
    } else if (key.toLowerCase() === 'a' || key.toLowerCase() === 'p') {
      if (column === 2) {
        if (key.toLowerCase() === 'a') {
          modifier = 'AM'
        }
        if (key.toLowerCase() === 'p') {
          modifier = 'PM'
        }
      }
    }
    modifier =
      +hours > 12 || +hours === 0 ? '' : modifier === '' ? 'AM' : modifier
    const time = `${hours}:${minutes} ${modifier}`
    if (
      (+hours <= 12 &&
        +hours > 0 &&
        +minutes < 60 &&
        +minutes >= 0 &&
        (modifier === 'AM' || modifier === 'PM')) ||
      ((+hours > 12 || +hours === 0) &&
        +hours < 24 &&
        +minutes < 60 &&
        +minutes >= 0 &&
        modifier === '')
    ) {
      if (modifier === '') {
        setValue(time)
      } else {
        setValue(convertTime12to24(time))
      }
    }
    setFields({ hours, minutes, modifier })
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const key = e.key
    let { hours, minutes, modifier } = fields

    if (column === null) {
      return
    }
    switch (e.key) {
      case 'Enter':
        if (props.toggleSave) {
          const data = convertTime12to24(value)
          props.toggleSave(data === '' ? ' ' : data)
        } else {
          props.onChange(convertTime12to24(value))
        }
        selectColumn(null)
        return
      case 'ArrowRight':
        moveRight(e, modifier, column)
        return
      case 'Tab':
        if (e.shiftKey) {
          if (column === 0) {
            selectColumn(null)
          } else {
            moveLeft(e, modifier, column)
          }
        } else {
          if (modifier === '' ? column === 1 : column === 2) {
            selectColumn(null)
          } else {
            moveRight(e, modifier, column)
          }
        }
        return
      case 'ArrowLeft':
      case 'Backspace': {
        moveLeft(e, modifier, column)
        return
      }
      case 'ArrowUp':
      case 'ArrowDown': {
        e.stopPropagation()
        e.preventDefault()
        const step = key === 'ArrowUp' ? 1 : -1
        if (column === 0) {
          if (Number.isNaN(+hours)) {
            hours = key === 'ArrowUp' ? '01' : '12'
          } else {
            if (modifier === '') {
              hours = ((+hours + step) % 24).toString()
            } else {
              hours =
                hours === '12' && step === 1
                  ? '01'
                  : hours === '01' && step === -1
                  ? '12'
                  : (+hours + step).toString()
            }
            hours = hours.length === 1 ? '0' + hours : hours
            setFields({ hours, minutes, modifier })
          }
        }
        if (column === 1) {
          if (Number.isNaN(+minutes)) {
            minutes = key === 'ArrowUp' ? '00' : '59'
          } else {
            minutes =
              +minutes === 0 && step === -1
                ? '59'
                : ((+minutes + step) % 60).toString()
            minutes = minutes.length === 1 ? '0' + minutes : minutes
          }
        }
        if (column === 2) {
          modifier = modifier === 'AM' ? 'PM' : 'AM'
        }
        break
      }
    }
    updateInputField('Undefined', { hours, minutes, modifier })
  }

  const onFocus = (e: any) => {
    if (column === null && e.relatedTarget != null) {
      const result = inputTextField().compareDocumentPosition(e.relatedTarget)
      if (result === Node.DOCUMENT_POSITION_PRECEDING) {
        selectColumn(0)
      } else {
        if (result === Node.DOCUMENT_POSITION_FOLLOWING) {
          selectColumn(2)
        }
      }
    }
  }

  const onInput = (e: React.FormEvent<HTMLInputElement>) => {
    const event = e.nativeEvent as InputEvent
    const data = event.data
    if (data != null) {
      updateInputField(data[data.length - 1], fields)
    }
    selectColumn(column)
  }

  return (
    <div
      onBlur={onBlur}
      onClick={onClick}
      onKeyDownCapture={onKeyPress}
      onInput={onInput}
      onFocus={onFocus}
      ref={timePickerContainerRef}
      data-testid={props.testId ? `time-picker-${props.testId}` : undefined}
    >
      <div
        className={`${props.className} ant-picker ${
          props.inline == null ? 'time-picker' : ''
        } ${props.disabled ? 'ant-picker-disabled' : ''}`}
      >
        <div className="ant-picker-input">
          <input
            data-testid={
              props.testId ? `time-picker-input-${props.testId}` : undefined
            }
            disabled={props.disabled}
            spellCheck={false}
            size={10}
          />
          {!props.disabled && (
            <>
              <span className="ant-picker-suffix">
                <ClockCircleOutlined />
              </span>
              <span
                data-testid={
                  props.testId
                    ? `time-picker-input-clear-${props.testId}`
                    : undefined
                }
                className="ant-picker-clear"
              >
                <CloseOutlined />
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
