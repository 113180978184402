import moment from 'moment'

import {
  BaseIntakeFormResponseObject,
  FormSectionKey,
  SupportedFormResponseTypes,
} from '../../api/intakeForms'
import { adjustPatientBirthdate } from '../../hooks/usePatientProfile/utils'
import { INTAKE_REVIEW_FORM } from './sections/helpers'
import {
  AllergiesMedicationsQuestionKeys,
  GeneralInformationQuestionKeys,
  InsuranceQuestionKeys,
  MedHistoryNonPsychQuestionKeys,
  OtherInformationQuestionKeys,
  PsychHistoryHospQuestionKeys,
  PsychHistoryMedsQuestionKeys,
  PsychiatricHistoryQuestionKeys,
  SocialHistoryQuestionKeys,
} from './sections/question-keys'

type QuestionKeyToSectionNameMapper = (
  sectionKeyEnumArray: string[],
  sectionName: FormSectionKey
) => Record<string, FormSectionKey>

/**
 * maps questionKeys of each question to its form section name FormSectionKey
 * @param sectionKeyEnumArray - string[]
 * @param sectionname - FormSectionKey
 * @returns Record<string, FormSectionKey>
 */
export const mapQuestionKeyToFormSection: QuestionKeyToSectionNameMapper = (
  sectionKeyEnumArray,
  sectionname
) => {
  return sectionKeyEnumArray.reduce<{ [key: string]: FormSectionKey }>(
    (output, questionKey: string) => {
      output[questionKey] = sectionname
      return output
    },
    {}
  )
}

/**
 * maps questions to their form section name (e.g., "general_information")
 * @returns Record<string, FormSectionKey>
 */
export const getSectionNameMappedByQuestionKey = () => {
  const generalInfo = mapQuestionKeyToFormSection(
    Object.values(GeneralInformationQuestionKeys),
    FormSectionKey.GENERAL_INFORMATION
  )
  const insurance = mapQuestionKeyToFormSection(
    Object.values(InsuranceQuestionKeys),
    FormSectionKey.INSURANCE
  )
  const allergiesAndMeds = mapQuestionKeyToFormSection(
    Object.values(AllergiesMedicationsQuestionKeys),
    FormSectionKey.ALLERGIES_MEDICATIONS
  )
  const psychHistoryGeneral = mapQuestionKeyToFormSection(
    Object.values(PsychiatricHistoryQuestionKeys),
    FormSectionKey.PSYCHIATRIC_HISTORY
  )
  const psychHistoryHospital = mapQuestionKeyToFormSection(
    Object.values(PsychHistoryHospQuestionKeys),
    FormSectionKey.PSYCHIATRIC_HISTORY
  )
  const psychHistoryMeds = mapQuestionKeyToFormSection(
    Object.values(PsychHistoryMedsQuestionKeys),
    FormSectionKey.PSYCHIATRIC_HISTORY
  )
  const medHistory = mapQuestionKeyToFormSection(
    Object.values(MedHistoryNonPsychQuestionKeys),
    FormSectionKey.MEDICAL_HISTORY
  )
  const socialHistory = mapQuestionKeyToFormSection(
    Object.values(SocialHistoryQuestionKeys),
    FormSectionKey.SOCIAL_HISTORY
  )
  const otherInfo = mapQuestionKeyToFormSection(
    Object.values(OtherInformationQuestionKeys),
    FormSectionKey.OTHER_INFORMATION
  )

  return {
    ...generalInfo,
    ...insurance,
    ...allergiesAndMeds,
    ...psychHistoryGeneral,
    ...psychHistoryHospital,
    ...psychHistoryMeds,
    ...medHistory,
    ...socialHistory,
    ...otherInfo,
  }
}

/**
 * takes an IntakeFormResponse.answer value, checks its type and cleans or converts value if needed before submission
 * @param value any value - unknown
 * @returns any value
 * - trim strings
 * - Moment -> date
 */
export const parseValueForSubmission = (
  value: unknown,
  questionKey?: string
): unknown => {
  try {
    // Trims strings
    if (typeof value === 'string') {
      return value.trim()
    }

    // Convert moment objects to date string
    if (moment.isMoment(value)) {
      if (questionKey === GeneralInformationQuestionKeys.DOB) {
        return new Date(adjustPatientBirthdate(value.toDate().toISOString()))
      }
      return value.toDate()
    }

    // Leave nulls, undefined, numbers and booleans as is
    if (!value || typeof value !== 'object') {
      return value
    }

    // Iterate through array items and recursively run through this function again
    if (Array.isArray(value)) {
      return value.map((value) => parseValueForSubmission(value))
    }

    // Iterate through objects and recursively run values through this function
    return value
      ? Object.entries(value).reduce<{ [key: string]: unknown }>(
          (output, [questionKey, answer]) => {
            if (questionKey && answer) {
              const parsedAnswer = parseValueForSubmission(answer)
              output[questionKey] = parsedAnswer
            }
            return output
          },
          {}
        )
      : value
  } catch (err) {
    throw new Error(`Unable to parse ${value}`)
  }
}

/**
 *
 * @param formData array of intake form response values - unknown[]
 * @returns BaseIntakeFormResponseObject[]
 */
export const validateIntakeFormForSubmission = (
  formData: SupportedFormResponseTypes[]
): BaseIntakeFormResponseObject[] => {
  const sectionNameMap = getSectionNameMappedByQuestionKey()
  return Object.entries(formData).map(([questionKey, value]) => ({
    questionKey,
    formSection: sectionNameMap[questionKey],
    answer: parseValueForSubmission(value, questionKey),
  }))
}

/**
 * takes an IntakeFormResponse.answer value and outputs values compatible with a table used for form review and PDF downloads
 * @param value any value - unknown
 * @returns any value
 * - trim strings
 * - Moment -> date
 */
export const parseResponsesForFormReview = (value: unknown): unknown => {
  try {
    // Trims strings
    if (typeof value === 'string') {
      return value.trim()
    }

    // Leave nulls, undefined, numbers and booleans as is
    if (!value || typeof value !== 'object') {
      return value
    }

    // Iterate through array items and recursively run through this function again
    if (Array.isArray(value)) {
      const arrayValue = value.map((item) => {
        // Return yes for empty arrays since we do not handle empty hospitilzation
        if (!item) {
          return {
            label: '',
            value: 'Yes',
          }
        }
        // array of text
        if (typeof item !== 'object') {
          return {
            label: '',
            value: item,
          }
        }
        // array of object
        const objects = Object.entries(item).map((entry) => {
          if (entry[0].includes('ADDRESS_OBJECT')) {
            const addressObject = entry[1] as {
              Address1?: string
              Address2?: string
              City?: string
              State?: string
              Zipcode?: string
            }
            return {
              label: 'Address',
              value:
                [
                  addressObject.Address1,
                  addressObject.Address2,
                  addressObject.City,
                  addressObject.State,
                ]
                  .filter(Boolean)
                  .join(', ') + ` ${addressObject.Zipcode}`,
            }
          } else if (
            entry[0].includes('UNIT_NUMBER') ||
            entry[0].includes('ADDRESS')
          ) {
            // Use the ADDRESS_OBJECT to build a more complete string instead of having two
            // separate address components that could end up getting lost or not displaying next to each other
            return
          }
          const found = INTAKE_REVIEW_FORM.find(
            (value) => value.name === entry[0]
          )
          return {
            label: found?.title ?? entry[0],
            value: entry[1],
          }
        })
        return {
          label: '',
          value: objects.filter((item) => item !== undefined),
        }
      })
      return arrayValue
    }
    if (value) {
      const objectValue = Object.entries(value).map((entry) => {
        return {
          label: entry[0],
          value: parseResponsesForFormReview(entry[1]),
        }
      })
      return objectValue
    }

    return value
  } catch (err) {
    throw new Error(`Unable to parse ${value}`)
  }
}
