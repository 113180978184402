import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const DepressionCodes: any = [
  {
    '35489007': {
      title: 'Depression',
      description: 'Depressive disorder (disorder)',
      snomedcode: 35489007,
      icdcode: 'F32A',
      icddescription: 'Depression, unspecified',
    },
  },
  {
    '370143000': {
      title: 'dp.Major Depressive Disorder',
      description: 'Major depressive disorder (disorder)',
      snomedcode: 370143000,
      icdcode: 'F329',
      icddescription: 'Major depressive disorder, single episode, unspecified',
    },
  },
  {
    '58703003': {
      title: 'dp.Postpartum Depression',
      description: 'Postpartum depression (disorder)',
      snomedcode: 58703003,
      icdcode: 'F530',
      icddescription: 'Postpartum depression',
    },
  },
  {
    '1153570009': {
      title: 'dp.Treatment-Resistant Depression',
      description: 'Treatment resistant depression (disorder)',
      snomedcode: 1153570009,
      icdcode: 'F32A',
      icddescription: 'Depression, unspecified',
    },
  },
]

export const DepressionDropDownValues = constructPatientDemographicObject(
  DepressionCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.PYSCH_CONDITIONS
)
