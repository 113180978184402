/*
  Map data from claim form to claim data and vice versa
*/
import { format } from 'date-fns'
import { omit } from 'lodash'

import { ChangePayersList } from '../../../api/intakeForms'
import { Diagnosis } from '../../../shared-types'
import {
  DEFAULT_INSURANCE_TYPE_CODE,
  EXCLUDED_DIAGNOSIS_ORDER,
  RELATIONSHIP,
} from './constants'
import {
  Address,
  AddressInput,
  AddressUpperCase,
  BoolOptionValue,
  ClaimData,
  ClaimForm,
  ClaimFormDiagnosis,
  ClaimFormProvider,
  ClaimStatus,
  InsuranceClaimDiagnosis,
  Patient,
  PatientInsurance,
  Provider,
} from './types'
import {
  extractDigits,
  getClaimFilingCodeForPayer,
  getRefreshedDiagnoses,
  isClaimPresubmit,
  isMedicare,
} from './utils'

// date in claim data -> date input value
/* note: this works for fields where time does not matter,
such as date of birth, which is stored as ISO string in UTC by combining the date and an arbitrary time
example:  "1989-04-13T00:00:00.000Z"
*/
export const toDateInputValue = (isoString?: string | null) => {
  if (!isoString) {
    return null
  }
  // remove the timezone ("Z")
  const isoWithoutTZ = isoString.replace('Z', '')
  // attaches local timezone so the date display is always correct regardless of where our user is
  const dateObj = new Date(isoWithoutTZ)
  return format(dateObj, 'MM/dd/yyyy')
}

// address in claim data -> address1 input value
export const toAddressInputValue = (
  addressObj?: AddressUpperCase | Address | null
): AddressInput | null => {
  if (addressObj && 'AddressLine1' in addressObj) {
    return {
      Address1: addressObj?.AddressLine1 ?? null,
      Address2: addressObj?.AddressLine2 ?? null,
      City: addressObj?.City ?? null,
      State: addressObj?.State ?? null,
      Zipcode: addressObj?.ZipCode ?? null,
    }
  }

  if (addressObj && 'addressLine1' in addressObj) {
    return {
      Address1: addressObj?.addressLine1 ?? null,
      Address2: addressObj?.addressLine2 ?? null,
      City: addressObj?.city ?? null,
      State: addressObj?.state ?? null,
      Zipcode: addressObj?.zipCode ?? null,
    }
  }

  return null
}

export const toBoolInputValue = (
  boolFieldValue?: boolean | null
): BoolOptionValue | null => {
  switch (boolFieldValue) {
    case true:
      return 'yes'
    case false:
      return 'no'
    default:
      return null
  }
}

export const toEinInputValue = (ein?: string | null) =>
  ein ? extractDigits(ein) : null

export const toSsnInputValue = (ssnLastFour?: string | null) =>
  ssnLastFour ? `*****${ssnLastFour}` : null

export const toBoolValue = (
  boolInputValue?: BoolOptionValue | null
): boolean | null => {
  switch (boolInputValue) {
    case 'yes':
      return true
    case 'no':
      return false
    default:
      return null
  }
}
// Address object in types "PatientInsurance", "Note", and "Billing"
export const toAddress = (
  address?: AddressInput | null,
  address2?: string | null
): Address | null => {
  if (!address) {
    return null
  }
  return {
    addressLine1: address.Address1,
    addressLine2: address2,
    city: address.City,
    state: address.State,
    zipCode: address.Zipcode,
    country: address.Address1 ? 'US' : null,
  }
}
// Address object in type "Patient"
export const toAddressUppercase = (
  address?: AddressInput | null,
  address2?: string | null
): AddressUpperCase | null => {
  if (!address) {
    return null
  }
  return {
    AddressLine1: address.Address1,
    AddressLine2: address2,
    City: address.City,
    State: address.State,
    ZipCode: address.Zipcode,
    Country: address.Address1 ? 'US' : null,
  }
}
// claim data -> form values
// TODO: most of the "?? null" can be removed after implementing #CARE-2254
export const toFormPatient = ({
  firstName = null,
  lastName = null,
  dateOfBirth = null,
  address = null,
  legalSex = null,
}: Patient = {}): ClaimForm['patient'] => ({
  firstName,
  lastName,
  dateOfBirth: toDateInputValue(dateOfBirth),
  address: toAddressInputValue(address),
  address2: address?.AddressLine2 ?? null,
  legalSex,
})

export const toFormPrimaryInsurance = ({
  name = null,
  claimFilingCode = null,
  memberId = null,
  groupId = null,
  isInformationReleaseAuthorized = null,
  isPaymentAuthorized = null,
  isSubscriber = null,
  subscriberRelationship = null,
  subscriberFirstName = null,
  subscriberLastName = null,
  subscriberDateOfBirth = null,
  subscriberLegalSex = null,
  subscriberEmail = null,
  subscriberPhoneNumber = null,
  subscriberAddress = null,
}: PatientInsurance = {}): ClaimForm['primaryInsurance'] => ({
  name,
  claimFilingCode,
  memberId,
  groupId,
  informationReleaseAuthorized: toBoolInputValue(
    isInformationReleaseAuthorized
  ),
  paymentAuthorized: toBoolInputValue(isPaymentAuthorized),
  subscriberRelationship: isSubscriber
    ? RELATIONSHIP.SELF
    : subscriberRelationship,
  subscriberFirstName,
  subscriberLastName,
  subscriberDateOfBirth: toDateInputValue(subscriberDateOfBirth),
  subscriberLegalSex,
  subscriberEmail,
  subscriberPhoneNumber,
  subscriberAddressSameAsPatient: subscriberAddress?.addressLine1
    ? 'no'
    : 'yes',
  subscriberAddress: toAddressInputValue(subscriberAddress),
  subscriberAddress2: subscriberAddress?.addressLine2 ?? null,
})

export const toFormSecondaryInsurance = ({
  isIncluded = null, // Need to discuss what this actually represents with with BE
  name = null,
  claimFilingCode = null,
  insuranceTypeCode = null,
  memberId = null,
  groupId = null,
  isInformationReleaseAuthorized = null,
  isPaymentAuthorized = null,
  isSubscriber = null,
  subscriberRelationship = null,
  subscriberFirstName = null,
  subscriberLastName = null,
  subscriberDateOfBirth = null,
}: PatientInsurance = {}): ClaimForm['secondaryInsurance'] => ({
  isIncluded,
  name,
  claimFilingCode,
  insuranceTypeCode,
  memberId,
  groupId,
  informationReleaseAuthorized: toBoolInputValue(
    isInformationReleaseAuthorized
  ),
  paymentAuthorized: toBoolInputValue(isPaymentAuthorized),
  subscriberRelationship: isSubscriber
    ? RELATIONSHIP.SELF
    : subscriberRelationship,
  subscriberFirstName,
  subscriberLastName,
  subscriberDateOfBirth: toDateInputValue(subscriberDateOfBirth),
})

export const toFormReferringProvider = ({
  isIncluded = null,
  providerFirstName = null,
  providerLastName = null,
  providerEin = null,
  providerNpi = null,
}: ClaimData['referringProvider'] = {}): ClaimForm['referringProvider'] => ({
  isIncluded,
  providerFirstName,
  providerLastName,
  providerEin,
  providerNpi,
})

export const toFormProvider = ({
  isIncluded,
  providerId = null,
  taxType = null,
  providerEin = null,
  providerSSNlastFour = null,
  providerNpi = null,
  providerTaxonomyCode = null,
}: Provider = {}): ClaimFormProvider => ({
  isIncluded,
  providerId,
  taxType,
  providerEin,
  providerSsn: toSsnInputValue(providerSSNlastFour),
  providerNpi,
  providerTaxonomyCode,
})

export const toFormBilling = ({
  type = null,
  name = null,
  providerId = null,
  locationId = null,
  address = null,
  phoneNumber = null,
  taxType = null,
  ein = null,
  ssnLastFour = null,
  npi = null,
  taxonomyCode = null,
}: ClaimData['billing'] = {}): ClaimForm['billing'] => ({
  type,
  name,
  providerId,
  locationId,
  address: toAddressInputValue(address),
  address2: address?.addressLine2 ?? null,
  phoneNumber,
  taxType,
  ein,
  ssn: toSsnInputValue(ssnLastFour),
  npi,
  taxonomyCode,
})

export const toFormNote = ({
  noteId = null,
  startDate = null,
  endDate = null,
  practiceName = null,
  locationId = null,
  address = null,
  posCode = null,
  practiceNpi = null,
}: ClaimData['note'] = {}): ClaimForm['note'] => ({
  noteId,
  startDate: toDateInputValue(startDate),
  endDate: toDateInputValue(endDate),
  practiceName,
  locationId,
  address: toAddressInputValue(address),
  address2: address?.addressLine2 ?? null,
  posCode,
  practiceNpi,
})

export const toFormPatientConditionRelation = ({
  employmentAccident = null,
  autoAccident = null,
  accidentState = null,
  otherAccident = null,
}: ClaimData['patientConditionRelation'] = {}): ClaimForm['patientConditionRelation'] => ({
  employmentAccident: toBoolInputValue(employmentAccident),
  autoAccident: toBoolInputValue(autoAccident),
  accidentState,
  otherAccident: toBoolInputValue(otherAccident),
})

/*
  1. If diagnoses exist in claim, honor them.
  2. If a claim is submitted, honor the order they come in. If not, sort them by diagnosisDate.
  3. If diagnoses does not exist in claim, AND the claims is not submitted, add active diagnoses.
  For diagnoses created in V1 claims, the orders may have gaps in them, need to reassign orders. We will do that at update/submit.
*/
const sortByDiagnosisDate = (
  a: InsuranceClaimDiagnosis,
  b: InsuranceClaimDiagnosis
) =>
  new Date(b.diagnosisDate ?? 0).valueOf() -
  new Date(a.diagnosisDate ?? 0).valueOf()
const sortByOrder = (a: InsuranceClaimDiagnosis, b: InsuranceClaimDiagnosis) =>
  a.order - b.order
export const toFormDiagnoses = (
  diagnoses: InsuranceClaimDiagnosis[],
  claimStatus: ClaimStatus,
  activeDiagnoses: Diagnosis[]
) => {
  const isPresubmit = isClaimPresubmit(claimStatus)

  if (diagnoses.length) {
    return diagnoses
      .sort(isPresubmit ? sortByDiagnosisDate : sortByOrder)
      .map((d) => omit(d, 'order'))
  }

  if (isClaimPresubmit(claimStatus)) {
    return getRefreshedDiagnoses(diagnoses, activeDiagnoses)
  }

  return []
}
// assign proper order to included and excluded diagnoses
export const toDiagnoses = (
  diagnoses: ClaimFormDiagnosis[]
): InsuranceClaimDiagnosis[] => {
  const included = diagnoses
    .filter((d) => !d.isExcluded)
    .map((d, index) => ({ ...d, order: index }))
  const excluded = diagnoses
    .filter((d) => d.isExcluded)
    .map((d) => ({ ...d, order: EXCLUDED_DIAGNOSIS_ORDER }))

  return [...included, ...excluded]
}

export const toFormSignature = ({
  providerId = null,
}: ClaimData['signature'] = {}): ClaimForm['signature'] => ({
  providerId,
})
/**
  All "toForm*" functions transforms claim data from BE to claim form values
*/
export const toFormValues = (
  {
    claimMemo,
    patient,
    primaryInsurance,
    selectedInsuranceName,
    secondaryInsurance,
    referringProvider,
    note,
    renderingProvider,
    supervisingProvider,
    billing,
    patientConditionRelation,
    diagnoses = [],
    signature,
    unsavedDraft,
    claimStatus = ClaimStatus.DRAFT,
  }: ClaimData,
  payersByName: { [name: string]: ChangePayersList },
  activeDiagnoses: Diagnosis[]
): ClaimForm => {
  const formValues: ClaimForm = {
    claimMemo: claimMemo ?? null,
    patient: toFormPatient(patient),
    primaryInsurance: toFormPrimaryInsurance(primaryInsurance),
    selectedInsuranceName: selectedInsuranceName ?? null,
    secondaryInsurance: toFormSecondaryInsurance(secondaryInsurance),
    referringProvider: toFormReferringProvider(referringProvider),
    note: toFormNote(note),
    renderingProvider: toFormProvider(renderingProvider),
    supervisingProvider: toFormProvider(supervisingProvider),
    billing: toFormBilling(billing),
    patientConditionRelation: toFormPatientConditionRelation(
      patientConditionRelation
    ),
    diagnoses: toFormDiagnoses(diagnoses, claimStatus, activeDiagnoses),
    signature: toFormSignature(signature),
  }

  // auto fill some values if it is a newly created claim
  // can be removed after implementation of CARE-2349
  if (unsavedDraft) {
    if (formValues.primaryInsurance.name && payersByName) {
      formValues.primaryInsurance.claimFilingCode = getClaimFilingCodeForPayer(
        payersByName,
        formValues.primaryInsurance.name
      )
    } else {
      formValues.primaryInsurance.claimFilingCode = null
    }

    if (formValues.primaryInsurance.name) {
      formValues.selectedInsuranceName = formValues.primaryInsurance.name
    } else {
      formValues.selectedInsuranceName = null
    }

    if (formValues.secondaryInsurance.name && payersByName) {
      formValues.secondaryInsurance.claimFilingCode =
        getClaimFilingCodeForPayer(
          payersByName,
          formValues.secondaryInsurance.name
        )
    } else {
      formValues.secondaryInsurance.claimFilingCode = null
    }

    if (isMedicare(formValues.secondaryInsurance.claimFilingCode)) {
      formValues.secondaryInsurance.insuranceTypeCode =
        DEFAULT_INSURANCE_TYPE_CODE
    }
  }

  return formValues
}

const toPatient = (
  patientId: string,
  {
    firstName,
    lastName,
    dateOfBirth,
    legalSex,
    address,
    address2,
  }: ClaimForm['patient']
): ClaimData['patient'] => ({
  id: patientId,
  firstName,
  lastName,
  dateOfBirth,
  legalSex,
  address: toAddressUppercase(address, address2),
})

const toPrimaryInsurance = ({
  name,
  claimFilingCode,
  memberId,
  groupId,
  informationReleaseAuthorized,
  paymentAuthorized,
  subscriberRelationship,
  subscriberFirstName,
  subscriberLastName,
  subscriberDateOfBirth,
  subscriberLegalSex,
  subscriberEmail,
  subscriberPhoneNumber,
  subscriberAddressSameAsPatient,
  subscriberAddress,
  subscriberAddress2,
}: ClaimForm['primaryInsurance']): ClaimData['primaryInsurance'] => ({
  name,
  claimFilingCode,
  memberId,
  groupId,
  isInformationReleaseAuthorized: toBoolValue(informationReleaseAuthorized),
  isPaymentAuthorized: toBoolValue(paymentAuthorized),
  subscriberRelationship,
  subscriberFirstName,
  subscriberLastName,
  subscriberDateOfBirth,
  subscriberLegalSex,
  subscriberEmail,
  subscriberPhoneNumber,
  // Right now BE is relying on empty object to fill out subscriber address as patient address
  subscriberAddress:
    subscriberAddressSameAsPatient === 'yes'
      ? {}
      : toAddress(subscriberAddress, subscriberAddress2),
  // isSubscriber is a required field in claim submission
  isSubscriber: subscriberRelationship
    ? subscriberRelationship === RELATIONSHIP.SELF
    : null,
})

const toSecondaryInsurance = ({
  isIncluded,
  name,
  claimFilingCode,
  insuranceTypeCode,
  memberId,
  groupId,
  informationReleaseAuthorized,
  paymentAuthorized,
  subscriberRelationship,
  subscriberFirstName,
  subscriberLastName,
  subscriberDateOfBirth,
}: ClaimForm['secondaryInsurance']): ClaimData['secondaryInsurance'] => ({
  isIncluded,
  name,
  claimFilingCode,
  insuranceTypeCode,
  memberId,
  groupId,
  isInformationReleaseAuthorized: toBoolValue(informationReleaseAuthorized),
  isPaymentAuthorized: toBoolValue(paymentAuthorized),
  subscriberRelationship,
  subscriberFirstName,
  subscriberLastName,
  subscriberDateOfBirth,
  // isSubscriber is a required field in claim submission
  isSubscriber: subscriberRelationship
    ? subscriberRelationship === RELATIONSHIP.SELF
    : null,
})

export const toProvider = ({
  isIncluded,
  providerId,
  taxType,
  providerEin,
  providerSsn,
  providerSsnFirstFive,
  providerSsnLastFour,
  providerNpi,
  providerTaxonomyCode,
}: ClaimFormProvider): Provider => {
  const providerData: Provider = {
    providerId,
    taxType,
    providerEin,
    providerSsn,
    providerSSNfirstFive: providerSsnFirstFive,
    providerSSNlastFour: providerSsnLastFour,
    providerNpi,
    providerTaxonomyCode,
  }
  if (isIncluded !== undefined) {
    providerData.isIncluded = isIncluded
  }
  /*
    "providerSsn" can either be masked (*****1212) or plain digits

    If "providerSsn" is in plain digits, it means that user manually entered the value
      In this case, we do not pass in "providerSSNfirstFive" and "providerSSNlastFour" because
        1. not needed
        2. these values can be outdated, happens if SSN values is prefilled first, and manually entered.
      Alternative solution to #2: we can keep these three fields ("providerSsn", "providerSSNfirstFive", "providerSSNlastFour") in sync
      whenever "providerSsn" changes, by building this logic into a special "onChange" handler for "providerSsn".
      For now it is easier to just delete them when constructing claim data since it does not affect UI and BE does not need them.

    If "providerSsn" is masked, there are two possibilities:
        Scenario 1: the value has been saved previously and it has not changed
        Scenario 2: user selected a new provider, and we prefill the providerSsn with providersData
      We do not pass in providerSsn if it is masked since it does not contain the full value of SSN.
        For scenario 1, we do not send SSN values, BE can grab them from the previous snapshot
        For scenario 2, we send "providerSSNfirstFive" (encrypted) and "providerSSNlastFour".

  */
  if (providerSsn?.includes('*')) {
    delete providerData.providerSsn
  } else if (providerSsn) {
    delete providerData.providerSSNfirstFive
    delete providerData.providerSSNlastFour
  }
  return providerData
}

export const toBilling = ({
  type,
  name,
  providerId,
  locationId,
  address,
  address2,
  phoneNumber,
  taxType,
  ein,
  ssn,
  ssnFirstFive,
  ssnLastFour,
  npi,
  taxonomyCode,
}: ClaimForm['billing']): ClaimData['billing'] => {
  const billingData: ClaimData['billing'] = {
    type,
    name,
    providerId,
    locationId,
    address: toAddress(address, address2),
    phoneNumber,
    taxType,
    ein,
    ssn,
    ssnFirstFive,
    ssnLastFour,
    npi,
    taxonomyCode,
  }
  // similar logic for SSN fields in renderingProvider
  if (ssn?.includes('*')) {
    delete billingData.ssn
  } else if (ssn) {
    delete billingData.ssnFirstFive
    delete billingData.ssnLastFour
  }
  return billingData
}

export const toNote = ({
  noteId,
  startDate,
  endDate,
  practiceName,
  locationId,
  address,
  address2,
  posCode,
  practiceNpi,
}: ClaimForm['note']): ClaimData['note'] => ({
  noteId,
  startDate,
  endDate,
  practiceName,
  locationId,
  address: toAddress(address, address2),
  posCode,
  practiceNpi,
})

export const toPatientConditionRelation = ({
  employmentAccident,
  autoAccident,
  accidentState,
  otherAccident,
}: ClaimForm['patientConditionRelation']): ClaimData['patientConditionRelation'] => ({
  employmentAccident: toBoolValue(employmentAccident),
  autoAccident: toBoolValue(autoAccident),
  accidentState,
  otherAccident: toBoolValue(otherAccident),
})

/**
  All "to*" functions transforms claim form values to claim data to send to BE
*/
export const toClaimData = (
  patientId: string,
  patientControlNumber: string,
  {
    claimMemo,
    patient,
    primaryInsurance,
    selectedInsuranceName,
    secondaryInsurance,
    referringProvider,
    note,
    renderingProvider,
    supervisingProvider,
    billing,
    patientConditionRelation,
    diagnoses,
    signature,
  }: ClaimForm
): Omit<ClaimData, 'claimStatusUpdate'> => {
  return {
    patientControlNumber,
    claimMemo,
    patient: toPatient(patientId, patient),
    primaryInsurance: toPrimaryInsurance(primaryInsurance),
    selectedInsuranceName,
    secondaryInsurance: toSecondaryInsurance(secondaryInsurance),
    referringProvider,
    note: toNote(note),
    renderingProvider: toProvider(renderingProvider),
    supervisingProvider: toProvider(supervisingProvider),
    billing: toBilling(billing),
    patientConditionRelation: toPatientConditionRelation(
      patientConditionRelation
    ),
    diagnoses: toDiagnoses(diagnoses),
    signature,
  }
}
