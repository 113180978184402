import { useState } from 'react'

import { useMutation } from '@tanstack/react-query'

import { getNotePdfS3Key } from '../../api/notes'
import { getS3Item } from '../../libs/awsLib'
import { downloadPDFToBrowser } from '../../libs/file-handling'
import { notification } from '../../libs/notificationLib'
import { NotificationType } from '../../stories/BaseComponents/Notification'

export const useDownloadClinicalNotePDF = (
  noteId?: string,
  disabled?: boolean
) => {
  const [isPrinting, setIsPrinting] = useState(false)
  const [error, setError] = useState('')
  const { mutateAsync: getPdfS3Key } = useMutation(async () => {
    return await getNotePdfS3Key(noteId)
  })

  const notifyPrintError = () => {
    notification(
      'An error occurred trying to print note.',
      NotificationType.ERROR
    )
  }

  const handlePrint = async () => {
    setIsPrinting(true)
    try {
      const s3Key = await getPdfS3Key()
      if (!s3Key) {
        setError('No S3 key found')
        throw new Error('No S3 key found')
      }

      const data = await getS3Item(s3Key, {
        ResponseCacheControl: 'max-age=0',
      })
      if (!data) {
        setError('No data found')
        throw new Error('No data found')
      }

      const [_private, _providerId, _patientId, _noteId, ...rest] =
        s3Key.split('/')

      const title = rest.join('/')
      downloadPDFToBrowser(data, title)
      setIsPrinting(false)
    } catch (e) {
      notifyPrintError()
    } finally {
      setIsPrinting(false)
    }
  }

  const isDisabled = disabled || isPrinting

  return { handlePrint, isDisabled, isPrinting, error }
}
