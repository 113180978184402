import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { getReadyForReviewBoolean } from '../../api/labs'

export const useReadyForReviewLabs = (): UseQueryResult<boolean> => {
  return useQuery(['getReadyForReviewLabs'], {
    queryFn: () => getReadyForReviewBoolean(),
    retry: false,
    refetchOnWindowFocus: false,
  })
}
