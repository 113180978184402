import * as Sentry from '@sentry/react'

import { globalConfig } from '../config/config'

export const supportedEnvs = ['prod', 'staging']

export const DEFAULT_SENTRY_TAGS = {
  'app.showed_error_boundary': 'no',
}

export function initializeSentry() {
  const config = globalConfig.get()

  if (!supportedEnvs.includes(config.ENV)) {
    console.warn(`Skipping initialization of sentry for env: ${config.ENV}`)
    return
  }

  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.ENV === 'prod' ? 'production' : 'staging',
    // This is a benign error https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
    // Because this is the highest volume error in Sentry, it's ignored here so our sentry is more useful
    // If we're worried about recursive loops in our usage of ResizeObserver, we should wrap it and log explicitly
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications',
    ],
    // use for testing sentry integration locally as you will receive 413 errors
    // if this is not included
    // integrations (integrations) {
    //   return integrations.filter(integration => integration.name !== 'Breadcrumbs')
    // }
  })
  Sentry.setTags(DEFAULT_SENTRY_TAGS)
}

export default Sentry
