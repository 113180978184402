import React from 'react'

import _ from 'lodash'
import { Col, Row } from 'react-bootstrap'

import FormMachine, { generateComponent } from '../FormMachine'
import { ConditionalOption, TemplateField } from '../types'
import { DefaultSaveFieldProps } from './types'

export default function DefaultSaveField(props: DefaultSaveFieldProps) {
  const { storedFields, styling, templateField, value } = props
  const {
    conditionalOption = false,
    conditionalOptions = null,
    conditionalComponent = null,
    columnSizes,
    hideConditional,
    id,
    label,
  } = templateField

  const {
    colBsPrefix = '',
    colClassName = '',
    colSize = {},
    colStyle = {},
    labelBsPrefix = '',
    labelClassName = '',
    labelStyle = {},
    isLabelOnSameLine = false,
    valueBsPrefix = '',
    valueClassName = '',
    valueStyle = {},
  } = styling

  let conditionalSection = null
  const isCheckbox = templateField.fieldType === 'checkbox'
  let shouldIncludeConditional = false

  // searches to check if option in conditionalOptions array is selected
  // and then displays conditional component
  if (conditionalOptions) {
    const components = [] as any[]
    conditionalOptions.forEach((option: ConditionalOption) => {
      const optionComponent = option.conditionalComponent
      if (optionComponent && isCheckbox) {
        shouldIncludeConditional = value.toString().includes(option.option)
      } else if (optionComponent) {
        shouldIncludeConditional = value === option.option
      }
      if (shouldIncludeConditional) {
        components.push({
          fields: optionComponent,
          label: option.label || option.option,
        })
      }
    })

    conditionalSection = (
      <>
        {components.map((template: any, index: number) => {
          const stylingCopy = _.cloneDeep(styling)
          return (
            <React.Fragment key={`form-${index}`}>
              <Col xs={12} md={12} lg={6} xl={6}>
                <Row>
                  <Col
                    bsPrefix={labelBsPrefix}
                    className="info"
                    style={{
                      marginBottom: 0,
                    }}
                    xs={12}
                    xl={12}
                  >
                    {template.label}
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    lg={6}
                    xl={6}
                    style={{ marginLeft: '18px' }}
                  >
                    <FormMachine
                      handleFieldChange={_.noop}
                      storedFields={storedFields}
                      template={template.fields}
                      isEditing={false}
                      styling={{
                        ...stylingCopy,
                        formRowStyle: {
                          margin: 0,
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </React.Fragment>
          )
        })}
      </>
    )
  } else if (conditionalComponent && conditionalOption) {
    // searches if just conditionalOption is selected and displays conditionalComponent
    if (isCheckbox) {
      shouldIncludeConditional = value.toString().includes(conditionalOption)
    } else {
      shouldIncludeConditional = value === conditionalOption
    }

    if (hideConditional) {
      shouldIncludeConditional = !shouldIncludeConditional
    }

    if (shouldIncludeConditional) {
      if (Array.isArray(conditionalComponent)) {
        conditionalSection = (
          <FormMachine
            handleFieldChange={_.noop}
            isEditing={false}
            storedFields={storedFields}
            styling={styling}
            template={conditionalComponent as TemplateField[]}
          />
        )
      } else {
        conditionalSection = generateComponent(
          {
            isEditing: false,
            storedFields,
            styling,
          },
          conditionalComponent as TemplateField
        )
      }
    }
  }

  const fieldColSize = isLabelOnSameLine ? colSize : { xs: 12, xl: 12 }

  return (
    <Col
      bsPrefix={colBsPrefix}
      className={colClassName}
      style={colStyle}
      {...columnSizes}
      id={id}
    >
      <Row>
        <Col
          {...fieldColSize}
          bsPrefix={labelBsPrefix}
          className={labelClassName}
          style={labelStyle}
        >
          {label}
        </Col>
        <Col
          xs={12}
          xl={12}
          bsPrefix={valueBsPrefix}
          className={`${valueClassName} format-white-space`}
          style={valueStyle}
        >
          {value}
        </Col>
        {conditionalSection}
      </Row>
    </Col>
  )
}
