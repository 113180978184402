import React, { useState } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import classNames from 'classnames'

import {
  sendNewTeammateInvite,
  updateUserActivationStatus,
} from '../../api/api-lib'
import { useHasPermissions } from '../../hooks/useHasPermissions'
import { notification } from '../../libs/notificationLib'
import { RoleTitles } from '../../shared-types'
import { Button, Modal, Space } from '../BaseComponents'
import { FILTER_LOCAL_STORAGE_LOCATION } from '../Scheduling/constants'
import ProviderInfoBox from './ProviderInfoBox'
import { TeammateActionInfo } from './TeamSettings'
import { WRITE_PERMISSIONS } from './_constants'

import './TeamSettings.scss'

interface Props {
  storyBook: boolean
  masterProviderId: string
  provider: any
  refresh: boolean
  isResendInviteAction: boolean
  setRefresh: Function
  teammateForAction: TeammateActionInfo | undefined
  setTeammate: Function
  closeSlider?: Function
}

/**
 * Handles deactivation/activation of teammates
 */
const DeactivationProvider: React.FC<Props> = ({
  storyBook,
  masterProviderId,
  provider,
  setRefresh,
  refresh,
  isResendInviteAction,
  teammateForAction,
  setTeammate,
  closeSlider,
}) => {
  const [isLoadingDeactivate, setLoadingDeactivate] = useState<boolean>(false)
  const [isDisableEnableModalOpen, setIsDisableEnableModalOpen] =
    useState<boolean>(false)
  const [isReactivate, setIsReactivate] = useState<boolean>(false)
  const hasAdminPermission = useHasPermissions({
    adminUsers: WRITE_PERMISSIONS,
    nonAdminUsers: WRITE_PERMISSIONS,
  })
  const hasOnlyAdminPermission = useHasPermissions({
    adminUsers: WRITE_PERMISSIONS,
  })
  const providerRoleTitles: RoleTitles[] = provider.roleTitles
  const isProvider = !!providerRoleTitles.find(
    (role) => role.label === 'Provider'
  )

  function handleDisableEnableModal(
    email: string,
    cognitoId: string,
    reactivate: boolean
  ) {
    setTeammate({
      email,
      cognitoId,
    })
    setIsReactivate(reactivate)
    setIsDisableEnableModalOpen(true)
  }

  const updateActivationStatus = async (
    providerEmail: string,
    shouldBeEnabled: boolean,
    userId = ''
  ) => {
    if (storyBook) {
      return
    }

    await updateUserActivationStatus({ providerEmail, shouldBeEnabled })
    if (shouldBeEnabled) {
      notification('User reactivated.', 'success')
    } else {
      notification('User deactivated.', 'success')
    }
    // if we're disabling an account, remove this provider from selected scheduling filters
    if (!shouldBeEnabled && userId) {
      const schedulingFilters = JSON.parse(
        localStorage.getItem(FILTER_LOCAL_STORAGE_LOCATION) || '{}'
      )
      if (!schedulingFilters.teammatesSelected) {
        schedulingFilters.teammatesSelected = []
      }
      const newProviderFilters = schedulingFilters.teammatesSelected.filter(
        (t: string) => t !== userId
      )
      schedulingFilters.teammatesSelected = newProviderFilters
      localStorage.setItem(
        FILTER_LOCAL_STORAGE_LOCATION,
        JSON.stringify(schedulingFilters)
      )
    }
    setLoadingDeactivate(false)
    if (closeSlider) {
      closeSlider()
    }
    setTeammate(undefined)
    setRefresh(!refresh)
  }

  const { email, isDeactivated, cognitoId } = provider
  let actionSpan = (
    <span
      className={classNames('action deactivate-action', {
        'disabled-action': !hasAdminPermission,
      })}
      onClick={() => handleDisableEnableModal(email, cognitoId, false)}
    >
      Deactivate
    </span>
  )
  if (isResendInviteAction) {
    actionSpan = (
      <span
        onClick={() => {
          const sendInviteRequest = {
            ClinicId: masterProviderId,
            ProviderEmail: email,
            isResendInviteRequest: true,
            ProviderId: cognitoId,
            RoleIds: provider.roleTitles.map((role: RoleTitles) => role.value),
          }
          sendNewTeammateInvite(sendInviteRequest)
          notification(
            `Invite has successfully been sent to ${email} again.`,
            'success'
          )
        }}
        className={classNames('action resend-invite-action', {
          'disabled-action': !hasAdminPermission,
        })}
      >
        Resend Invite
      </span>
    )
    return actionSpan
  }
  const providerSuperAdmin = providerRoleTitles.find(
    (rT) => rT.label === 'Super-Admin'
  )

  if (
    !hasAdminPermission ||
    provider.cognitoId === masterProviderId ||
    (!hasOnlyAdminPermission && providerSuperAdmin?.label === 'Super-Admin')
  ) {
    // DISABLED DEACTIVATE
    actionSpan = <span className="action disabled-action">Deactivate</span>
  } else if (isDeactivated === true) {
    // RE-ACTIVATE
    actionSpan = (
      <span
        onClick={() => handleDisableEnableModal(email, cognitoId, true)}
        className={classNames('action reactivate-action', {
          'disabled-action': !hasAdminPermission,
        })}
      >
        Reactivate
      </span>
    )
  }

  return (
    <div>
      <Space size="middle">{actionSpan}</Space>
      <Modal
        visible={!!teammateForAction && isDisableEnableModalOpen}
        onOk={() => {
          if (teammateForAction) {
            updateActivationStatus(
              teammateForAction.email,
              isReactivate,
              teammateForAction.cognitoId
            )
          }
        }}
        onCancel={() => setTeammate(undefined)}
        footer={[
          <div className="antd-modal-footer">
            <Button id="cancelInvite" onClick={() => setTeammate(undefined)}>
              Cancel
            </Button>
            <Button
              id="deactivateTeammate"
              type="primary"
              loading={isLoadingDeactivate}
              onClick={() => {
                if (teammateForAction) {
                  setLoadingDeactivate(true)
                  updateActivationStatus(
                    teammateForAction.email,
                    isReactivate,
                    teammateForAction.cognitoId
                  )
                  if (closeSlider) {
                    closeSlider()
                  }
                }
              }}
            >
              Ok
            </Button>
          </div>,
        ]}
      >
        <div className="disable-teammate">
          <InfoCircleOutlined style={{ fontSize: '26px', color: 'orange' }} />
          <span style={{ fontSize: '24px' }}>
            {isReactivate ? '    Reactivate user' : '    Deactivate user'}
          </span>
        </div>
        {isReactivate && <ProviderInfoBox isProvider={isProvider} />}
        <span>
          Are you sure you want to {isReactivate ? 'reactivate' : 'deactivate'}{' '}
          this user? This action is reversible.
        </span>
      </Modal>
    </div>
  )
}

export default DeactivationProvider
