export enum PatientProfileEvents {
  VIEW_DETAIL_PANEL = 'Opened Patient Profile Detail Panel', // Event when user clicks on sidebar to see a detail panel
  VIEW_OVERVIEW_PANEL = 'Opened Patient Profile Overview', // Event when user dispatch overview panel on sidebar
  UPDATE_SUMMARY = 'Updated Patient Summary', // Event when a user change the patient summary and clicks on save
  UPDATE_TAGS = 'Edited Tag from Profile', // Event when a user edits a patient tags and clicks on save
  HIDE_PATIENT_PROFILE = 'Hide Patient Profile', // Event when a user clicks on hide sidebar
  EXPAND_PATIENT_PROFILE = 'Expand Patient Profile', // Event when a user clicks on hide expands
  MARKED_AS_READ = 'Messages marked as read', // Event when a user clicks Mark messages from <Patient Name> as read
  MARKED_AS_UNREAD = 'Messages marked as unread', // Event when a user clicks Mark messages from <Patient Name> as unread
}

type PatietProfileEventProperties = {
  profileSection?: string
  tagCount?: number
  patientId: string
  clinicId: string
  providerId?: string
  currentPage?: string
  userActionType?: string
}

export function trackPatientProfileEvent(
  eventName: PatientProfileEvents,
  eventDetails: PatietProfileEventProperties
) {
  window?.freshpaint?.track(eventName, eventDetails)
}
