import { API } from 'aws-amplify'

import { GeneralHistoryResponse } from '../containers/Patient/General/types'
import { PatientInfo } from '../hooks/usePatientProfile/shared-types'
import { DateISO8601 } from '../shared-types'

export type UpdateDemographicsPayload = Partial<PatientInfo> &
  Partial<GeneralHistoryResponse> & {
    dateOfBirth?: DateISO8601
  }

/**
 * Goes over the incoming data and returns only fields that have been updated
 * in order to allow for partial updates
 *
 * @param patientData - existing data for patient demographics/patient info
 * @param formData - incoming updates
 */
export const detectChangedFields = (
  patientData: UpdateDemographicsPayload,
  formData: Partial<UpdateDemographicsPayload>
): UpdateDemographicsPayload => {
  const updatedFields: UpdateDemographicsPayload = {}
  for (const field of Object.keys(
    formData
  ) as (keyof UpdateDemographicsPayload)[]) {
    // date of birth field is incorrect on the form, so we need to translate and diff
    if (field === 'DateOfBirth') {
      if (formData.DateOfBirth !== patientData.dateOfBirth) {
        updatedFields['dateOfBirth'] = formData[field]
      }
      continue
    }
    // they can't share a conditional because type assertions
    if (field === 'mentalHealthcareProviders') {
      updatedFields[field] = formData[field]
      continue
    }

    if (field === 'otherHealthcareProviders') {
      updatedFields[field] = formData[field]
      continue
    }

    if (field === 'emergencyContacts') {
      updatedFields[field] = formData[field]
      continue
    }

    if (formData[field] !== patientData[field]) {
      updatedFields[field] = formData[field]
    }
  }

  return updatedFields
}

// this is a clone of submitEditGeneralHistoryRequest that takes a type, but a different one.
export const updatePatientDemographics = async (
  body: UpdateDemographicsPayload
) => {
  return API.put('patient-profile', '/profile/demographics', { body })
}
