import React from 'react'

import { Button, Col, Form } from 'react-bootstrap'

import LoaderButton from '../../../../components/Buttons/LoaderButton'
import { AutoSizingTextArea } from '../../../../stories/BaseComponents/TextAreaInput'
import { OtherNotesFields } from '../OtherNotes'

interface EditProps {
  currentData: OtherNotesFields
  handleFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSubmit: (event: React.MouseEvent<HTMLElement>) => void
  isDisabled: boolean
  isLoading: boolean
  revert: () => void
}

export default function EditNotes(props: EditProps) {
  const currentData = props.currentData

  const form = (
    <>
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} xl={12} controlId="clinicalTrialNotes">
          <Form.Label className="patient topic">
            Clinical Trial Notes
          </Form.Label>
          <Form.Control
            bsPrefix="form-input"
            className="text-area"
            onChange={props.handleFieldChange}
            placeholder="Clinical trials participated in, details, etc."
            type="text"
            as={AutoSizingTextArea}
            minRows={1}
            value={currentData.clinicalTrialNotes}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} xl={12} controlId="additionalNotes">
          <Form.Label className="patient topic">Additional Notes</Form.Label>
          <Form.Control
            as={AutoSizingTextArea}
            minRows={1}
            bsPrefix="form-control"
            className="field-value-edit"
            onChange={props.handleFieldChange}
            placeholder="Additional notes, other observations, etc."
            type="text"
            value={currentData.additionalNotes}
          />
        </Form.Group>
      </Form.Row>
    </>
  )

  const buttons = (
    <Form.Row>
      <Form.Group sm={12} md={5} lg={{ span: 4, offset: 2 }} as={Col}>
        <LoaderButton
          bsPrefix="icon-button"
          isDisabled={props.isDisabled}
          isLoading={props.isLoading}
          loadingText="Saving"
          onClick={props.handleSubmit}
        >
          <i className="fa fa-floppy-o" aria-hidden="true" />
          &nbsp; Save notes
        </LoaderButton>
      </Form.Group>
      <Form.Group sm={12} md={5} as={Col}>
        <Button bsPrefix="button-close icon-button" onClick={props.revert}>
          <i className="fa fa-times" aria-hidden="true" />
          &nbsp; Cancel
        </Button>
      </Form.Group>
    </Form.Row>
  )

  return (
    <Form style={{ padding: '16px' }} noValidate>
      {form}
      <br />
      {buttons}
    </Form>
  )
}
