import React from 'react'

import { Container, Form } from 'react-bootstrap'

interface NoRelevantHistoryProps {
  title: string
  noRecordedInfo?: boolean
  hasNoRelevantHistory?: boolean
}

export default function NoRelevantHistory(props: NoRelevantHistoryProps) {
  const { title, noRecordedInfo, hasNoRelevantHistory } = props

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">{title}:</Form.Row>
      {noRecordedInfo && !hasNoRelevantHistory && (
        <Form.Group className="border-bottom indent">
          <Container bsPrefix="psych">None recorded</Container>
        </Form.Group>
      )}
      {hasNoRelevantHistory && !noRecordedInfo && (
        <Form.Group className="border-bottom indent">
          <Container bsPrefix="psych">
            <Form.Check className="psych-checkbox" type="checkbox" checked />
            <span id="NoObsessiveCompulsiveSaved">
              No relevant history in this section
            </span>
          </Container>
        </Form.Group>
      )}
    </Form.Group>
  )
}
