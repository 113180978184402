export enum FieldType {
  CHECKBOX = 'checkbox',
  TEXT = 'text',
}

export enum Findings {
  General = 'General',
  Vitals = 'Vitals',
  ThoraxAndLungs = 'Thorax and lungs',
  Cardiovascular = 'Cardiovascular',
  Integumentary = 'Integumentary',
  Musculoskeletal = 'Musculoskeletal',
  AdditionalNotes = 'Additional notes',
  HEENT = 'HEENT',
  NervousSystem = 'Nervous system',
  PeripheralVascularSystem = 'Peripheral vascular system',
  Abdomen = 'Abdomen',
  GI = 'GI',
  Breasts = 'Breasts and axillae',
  GU = 'GU, pelvic ',
}

interface TextField {
  type: FieldType.TEXT
}

interface CheckboxField {
  type: FieldType.CHECKBOX
  hasText?: boolean
}

type Field = (TextField | CheckboxField) & { break?: boolean }

export const Section: Record<Findings, Field> = {
  [Findings.General]: { type: FieldType.TEXT },
  [Findings.Vitals]: { type: FieldType.TEXT },
  [Findings.ThoraxAndLungs]: { type: FieldType.TEXT },
  [Findings.Cardiovascular]: { type: FieldType.TEXT },
  [Findings.Integumentary]: { type: FieldType.TEXT },
  [Findings.Musculoskeletal]: { type: FieldType.TEXT },
  [Findings.AdditionalNotes]: { type: FieldType.TEXT, break: true },
  [Findings.HEENT]: { type: FieldType.CHECKBOX, hasText: true },
  [Findings.NervousSystem]: { type: FieldType.CHECKBOX, hasText: true },
  [Findings.PeripheralVascularSystem]: {
    type: FieldType.CHECKBOX,
    hasText: true,
  },
  [Findings.Abdomen]: { type: FieldType.CHECKBOX, hasText: true },
  [Findings.GI]: { type: FieldType.CHECKBOX, hasText: true },
  [Findings.Breasts]: { type: FieldType.CHECKBOX, hasText: true },
  [Findings.GU]: { type: FieldType.CHECKBOX, hasText: true },
}
