import React, { useCallback, useMemo } from 'react'

import { Form } from 'antd'
import { Auth } from 'aws-amplify'

import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import { Button, Card } from '../../stories/BaseComponents'
import { Password } from '../../stories/BaseComponents/Input'

import styles from '../_shared.module.scss'
import './ChangePassword.scss'

interface FormValues {
  password: string
  oldPassword: string
  confirmPassword: string
}

/**
 * Handles change password for logged in users
 */
const ChangePassword: React.FC = () => {
  const [form] = Form.useForm()
  const initialValues: FormValues = useMemo(
    () => ({
      password: '',
      oldPassword: '',
      confirmPassword: '',
    }),
    []
  )

  const handleSubmit = useCallback(async (values: FormValues) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(
        currentUser,
        values.oldPassword,
        values.password
      )
      notification('Your password has been successfully changed.', 'success')
      form.resetFields()
    } catch (error: any) {
      // Auth returns a string code instead of a number code
      error.code === 'NotAuthorizedException'
        ? onError(
            error,
            400,
            'The current password you have entered is incorrect. Please try again.'
          )
        : onError(error)
    }
  }, [])

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        <Card bordered={false}>
          <div className="headerWrapper">
            <span className="headerWrapper_title">Change password</span>
            <span className="headerWrapper_description">
              Choose a strong password that you do not use with other accounts.
            </span>
          </div>

          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            initialValues={initialValues}
          >
            <Form.Item
              id="oldPassword"
              name="oldPassword"
              label="Current password"
              rules={[
                {
                  required: true,
                  message: 'Please input current password',
                },
              ]}
            >
              <Password />
            </Form.Item>
            <Form.Item
              id="password"
              name="password"
              label="New password"
              extra="Must be at least 8 characters long and contain at least 1 lowercase letter, uppercase letter, number, or special character."
              rules={[
                {
                  required: true,
                  message: 'Please input new password',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const reg = new RegExp(
                      '^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*!=]).*$'
                    )
                    if (value === getFieldValue('oldPassword')) {
                      return Promise.reject(
                        new Error('Please input the different password.')
                      )
                    }
                    if (!value || (value.length >= 8 && reg.test(value))) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error('Please input the correct password.')
                    )
                  },
                }),
              ]}
            >
              <Password />
            </Form.Item>
            <Form.Item
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm password"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please input current password',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!'
                      )
                    )
                  },
                }),
              ]}
            >
              <Password />
            </Form.Item>
            <Form.Item id="submit">
              <Button type="primary" htmlType="submit">
                Change password
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  )
}

export default ChangePassword
