import React from 'react'

import { Col, Form } from 'react-bootstrap'

export default function OtherConditions(props) {
  if (props.patientData.NoOtherConditions) {
    return (
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Other conditions:
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="NoOtherConditions"
            label="No relevant history in this section"
            checked={JSON.parse(props.patientData.NoOtherConditions)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NoOtherConditions: !props.patientData.NoOtherConditions,
              })
            }}
          />
        </Form.Group>
      </Form.Row>
    )
  }
  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Other conditions:
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label className="indentsubtext" style={{ color: '#A9A9A9' }}>
            (e.g. dissociative; somatic; elimination; sleep-wake; sexual; gender
            dysphoria; disruptive, impulse-control, conduct; personality;
            paraphilic)
          </Form.Label>
          {!props.patientData.OtherConditionsAdditionalNotes ? (
            <Form.Check
              type="checkbox"
              id="NoOtherConditions"
              label="No relevant history in this section"
              checked={JSON.parse(props.patientData.NoOtherConditions)}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoOtherConditions: !props.patientData.NoOtherConditions,
                })
              }}
            />
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional notes
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            bsPrefix="form-input"
            disabled={props.patientData.NoOtherConditions}
            onChange={(e) => {
              props.setPatientData({
                ...props.patientData,
                OtherConditionsAdditionalNotes: e.target.value,
              })
            }}
            value={props.patientData.OtherConditionsAdditionalNotes}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
