import React from 'react'

import { Col, Form } from 'react-bootstrap'

import SubstanceUse from './Tables/SubstanceUse'

export default function SubstanceAddictive(props) {
  function saveSubstanceAlcohol(fields) {
    props.setPatientData({ ...props.patientData, Alcohol: fields })
  }

  function saveSubstanceTobacco(fields) {
    props.setPatientData({ ...props.patientData, Tobacco: fields })
  }

  function saveSubstanceCannabis(fields) {
    props.setPatientData({ ...props.patientData, Cannabis: fields })
  }

  function saveSubstanceCocaine(fields) {
    props.setPatientData({ ...props.patientData, Cocaine: fields })
  }

  function saveSubstanceHeroin(fields) {
    props.setPatientData({ ...props.patientData, Heroin: fields })
  }

  function saveSubstanceMethAmphetamines(fields) {
    props.setPatientData({ ...props.patientData, MethAmphetamines: fields })
  }

  function saveSubstanceMDMA(fields) {
    props.setPatientData({ ...props.patientData, MDMA: fields })
  }

  function saveSubstancePsilocybin(fields) {
    props.setPatientData({ ...props.patientData, Psilocybin: fields })
  }

  function saveSubstanceOtherPsychedelics(fields) {
    props.setPatientData({ ...props.patientData, OtherPsychedelics: fields })
  }

  function saveSubstanceStimulants(fields) {
    props.setPatientData({ ...props.patientData, Stimulants: fields })
  }

  function saveSubstanceSedatives(fields) {
    props.setPatientData({ ...props.patientData, Sedatives: fields })
  }

  function saveSubstanceOtherPrescription(fields) {
    props.setPatientData({ ...props.patientData, OtherPrescription: fields })
  }

  function saveSubstanceOtherAbuseSubstance(fields) {
    props.setPatientData({ ...props.patientData, OtherAbuseSubstance: fields })
  }

  if (props.patientData.NoSubstanceAddiction) {
    return (
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Substance-related and addictive:
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="NoSubstanceAddiction"
            label="No relevant history in this section"
            checked={JSON.parse(props.patientData.NoSubstanceAddiction)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NoSubstanceAddiction: !props.patientData.NoSubstanceAddiction,
              })
            }}
          />
        </Form.Group>
      </Form.Row>
    )
  }

  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Substance-related and addictive:
          </Form.Label>
        </Form.Group>
        {props.patientData.SUD.state === false &&
        props.patientData.Alcoholism.state === false &&
        props.patientData.OUD.state === false &&
        props.patientData.OtherSubstance.state === false &&
        props.patientData.SubstanceAdditionalNotes === '' ? (
          <Form.Group as={Col} sm={12} md={12} lg={12}>
            <Form.Check
              type="checkbox"
              id="NoSubstanceAddiction"
              label="No relevant history in this section"
              checked={JSON.parse(props.patientData.NoSubstanceAddiction)}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoSubstanceAddiction: !props.patientData.NoSubstanceAddiction,
                })
              }}
            />
          </Form.Group>
        ) : (
          ''
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Diagnoses
          </Form.Label>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.SUD.state ? 2 : 12}
          lg={props.patientData.SUD.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="SUD"
            label="SUD"
            disabled={props.patientData.NoSubstanceAddiction}
            checked={JSON.parse(props.patientData.SUD.state)}
            onChange={() => {
              props.setPatientData({
                ...props.patientData,
                SUD: {
                  ...props.patientData.SUD,
                  state: !props.patientData.SUD.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.SUD.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    SUD: { ...props.patientData.SUD, Notes: e.target.value },
                  })
                }}
                value={props.patientData.SUD.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.Alcoholism.state ? 2 : 12}
          lg={props.patientData.Alcoholism.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="Alcoholism"
            label="Alcoholism"
            disabled={props.patientData.NoSubstanceAddiction}
            checked={JSON.parse(props.patientData.Alcoholism.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                Alcoholism: {
                  ...props.patientData.Alcoholism,
                  state: !props.patientData.Alcoholism.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Alcoholism.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    Alcoholism: {
                      ...props.patientData.Alcoholism,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.Alcoholism.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.OUD.state ? 2 : 12}
          lg={props.patientData.OUD.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="OUD"
            label="OUD"
            disabled={props.patientData.NoSubstanceAddiction}
            checked={JSON.parse(props.patientData.OUD.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                OUD: {
                  ...props.patientData.OUD,
                  state: !props.patientData.OUD.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OUD.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    OUD: { ...props.patientData.OUD, Notes: e.target.value },
                  })
                }}
                value={props.patientData.OUD.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.OtherSubstance.state ? 2 : 12}
          lg={props.patientData.OtherSubstance.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="OtherSubstance"
            label="Other"
            disabled={props.patientData.NoSubstanceAddiction}
            checked={JSON.parse(props.patientData.OtherSubstance.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                OtherSubstance: {
                  ...props.patientData.OtherSubstance,
                  state: !props.patientData.OtherSubstance.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OtherSubstance.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    OtherSubstance: {
                      ...props.patientData.OtherSubstance,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.OtherSubstance.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Substance use
          </Form.Label>
          <Form.Check
            type="checkbox"
            id="AlcoholCheckbox"
            label="Alcohol"
            checked={props.patientData.Alcohol.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                Alcohol: {
                  ...props.patientData.Alcohol,
                  state: !props.patientData.Alcohol.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Alcohol.state && (
          <>
            <SubstanceUse
              CurrentPastUse={props.patientData.Alcohol.currentPastUse}
              DrinksPerWeek={props.patientData.Alcohol.drinksPerWeek}
              BingeDrink={props.patientData.Alcohol.bingeDrink}
              DateRange={props.patientData.Alcohol.dateRange}
              Frequency={props.patientData.Alcohol.frequency}
              Quantity={props.patientData.Alcohol.quantity}
              Route={props.patientData.Alcohol.route}
              CAGE={props.patientData.Alcohol.cAGE}
              Notes={props.patientData.Alcohol.notes}
              SaveSubstance={saveSubstanceAlcohol}
              Substance="Alcohol"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="TobaccoCheckbox"
            label="Tobacco"
            checked={props.patientData.Tobacco.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                Tobacco: {
                  ...props.patientData.Tobacco,
                  state: !props.patientData.Tobacco.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Tobacco.state && (
          <>
            <SubstanceUse
              CurrentPastUse={props.patientData.Tobacco.currentPastUse}
              DrinksPerWeek={props.patientData.Tobacco.drinksPerWeek}
              BingeDrink={props.patientData.Tobacco.bingeDrink}
              DateRange={props.patientData.Tobacco.dateRange}
              Frequency={props.patientData.Tobacco.frequency}
              Quantity={props.patientData.Tobacco.quantity}
              Route={props.patientData.Tobacco.route}
              CAGE={props.patientData.Tobacco.cAGE}
              Notes={props.patientData.Tobacco.notes}
              SaveSubstance={saveSubstanceTobacco}
              Substance="Tobacco"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="CannabisCheckbox"
            label="Cannabis"
            checked={props.patientData.Cannabis.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                Cannabis: {
                  ...props.patientData.Cannabis,
                  state: !props.patientData.Cannabis.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Cannabis.state && (
          <>
            <SubstanceUse
              CurrentPastUse={props.patientData.Cannabis.currentPastUse}
              DrinksPerWeek={props.patientData.Cannabis.drinksPerWeek}
              BingeDrink={props.patientData.Cannabis.bingeDrink}
              DateRange={props.patientData.Cannabis.dateRange}
              Frequency={props.patientData.Cannabis.frequency}
              Quantity={props.patientData.Cannabis.quantity}
              Route={props.patientData.Cannabis.route}
              CAGE={props.patientData.Cannabis.cAGE}
              Notes={props.patientData.Cannabis.notes}
              SaveSubstance={saveSubstanceCannabis}
              Substance="Cannabis"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="CocaineCheckbox"
            label="Cocaine"
            checked={props.patientData.Cocaine.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                Cocaine: {
                  ...props.patientData.Cocaine,
                  state: !props.patientData.Cocaine.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Cocaine.state && (
          <>
            <SubstanceUse
              CurrentPastUse={props.patientData.Cocaine.currentPastUse}
              DrinksPerWeek={props.patientData.Cocaine.drinksPerWeek}
              BingeDrink={props.patientData.Cocaine.bingeDrink}
              DateRange={props.patientData.Cocaine.dateRange}
              Frequency={props.patientData.Cocaine.frequency}
              Quantity={props.patientData.Cocaine.quantity}
              Route={props.patientData.Cocaine.route}
              CAGE={props.patientData.Cocaine.cAGE}
              Notes={props.patientData.Cocaine.notes}
              SaveSubstance={saveSubstanceCocaine}
              Substance="Cocaine"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="HeroinCheckbox"
            label="Heroin"
            checked={props.patientData.Heroin.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                Heroin: {
                  ...props.patientData.Heroin,
                  state: !props.patientData.Heroin.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Heroin.state && (
          <>
            <SubstanceUse
              CurrentPastUse={props.patientData.Heroin.currentPastUse}
              DrinksPerWeek={props.patientData.Heroin.drinksPerWeek}
              BingeDrink={props.patientData.Heroin.bingeDrink}
              DateRange={props.patientData.Heroin.dateRange}
              Frequency={props.patientData.Heroin.frequency}
              Quantity={props.patientData.Heroin.quantity}
              Route={props.patientData.Heroin.route}
              CAGE={props.patientData.Heroin.cAGE}
              Notes={props.patientData.Heroin.notes}
              SaveSubstance={saveSubstanceHeroin}
              Substance="Heroin"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="MethAmphetaminesCheckbox"
            label="Meth/Amphetamines"
            checked={props.patientData.MethAmphetamines.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                MethAmphetamines: {
                  ...props.patientData.MethAmphetamines,
                  state: !props.patientData.MethAmphetamines.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.MethAmphetamines.state && (
          <>
            <SubstanceUse
              CurrentPastUse={props.patientData.MethAmphetamines.currentPastUse}
              DrinksPerWeek={props.patientData.MethAmphetamines.drinksPerWeek}
              BingeDrink={props.patientData.MethAmphetamines.bingeDrink}
              DateRange={props.patientData.MethAmphetamines.dateRange}
              Frequency={props.patientData.MethAmphetamines.frequency}
              Quantity={props.patientData.MethAmphetamines.quantity}
              Route={props.patientData.MethAmphetamines.route}
              CAGE={props.patientData.MethAmphetamines.cAGE}
              Notes={props.patientData.MethAmphetamines.notes}
              SaveSubstance={saveSubstanceMethAmphetamines}
              Substance="MethAmphetamines"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="MDMACheckbox"
            label="MDMA"
            checked={props.patientData.MDMA.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                MDMA: {
                  ...props.patientData.MDMA,
                  state: !props.patientData.MDMA.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.MDMA.state && (
          <>
            <SubstanceUse
              CurrentPastUse={props.patientData.MDMA.currentPastUse}
              DrinksPerWeek={props.patientData.MDMA.drinksPerWeek}
              BingeDrink={props.patientData.MDMA.bingeDrink}
              DateRange={props.patientData.MDMA.dateRange}
              Frequency={props.patientData.MDMA.frequency}
              Quantity={props.patientData.MDMA.quantity}
              Route={props.patientData.MDMA.route}
              CAGE={props.patientData.MDMA.cAGE}
              Notes={props.patientData.MDMA.notes}
              SaveSubstance={saveSubstanceMDMA}
              Substance="MDMA"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="PsilocybinCheckbox"
            label="Psilocybin"
            checked={props.patientData.Psilocybin.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                Psilocybin: {
                  ...props.patientData.Psilocybin,
                  state: !props.patientData.Psilocybin.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Psilocybin.state && (
          <>
            <SubstanceUse
              CurrentPastUse={props.patientData.Psilocybin.currentPastUse}
              DrinksPerWeek={props.patientData.Psilocybin.drinksPerWeek}
              BingeDrink={props.patientData.Psilocybin.bingeDrink}
              DateRange={props.patientData.Psilocybin.dateRange}
              Frequency={props.patientData.Psilocybin.frequency}
              Quantity={props.patientData.Psilocybin.quantity}
              Route={props.patientData.Psilocybin.route}
              CAGE={props.patientData.Psilocybin.cAGE}
              Notes={props.patientData.Psilocybin.notes}
              SaveSubstance={saveSubstancePsilocybin}
              Substance="Psilocybin"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="OtherPsychedelicsCheckbox"
            label="Other psychedelics and hallucinogens (PCP, LSD, DMT, etc)"
            checked={props.patientData.OtherPsychedelics.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                OtherPsychedelics: {
                  ...props.patientData.OtherPsychedelics,
                  state: !props.patientData.OtherPsychedelics.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OtherPsychedelics.state && (
          <>
            <SubstanceUse
              CurrentPastUse={
                props.patientData.OtherPsychedelics.currentPastUse
              }
              DrinksPerWeek={props.patientData.OtherPsychedelics.drinksPerWeek}
              BingeDrink={props.patientData.OtherPsychedelics.bingeDrink}
              DateRange={props.patientData.OtherPsychedelics.dateRange}
              Frequency={props.patientData.OtherPsychedelics.frequency}
              Quantity={props.patientData.OtherPsychedelics.quantity}
              Route={props.patientData.OtherPsychedelics.route}
              CAGE={props.patientData.OtherPsychedelics.cAGE}
              Notes={props.patientData.OtherPsychedelics.notes}
              SaveSubstance={saveSubstanceOtherPsychedelics}
              Substance="OtherPsychedelics"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="StimulantsCheckbox"
            label="Stimulants (Adderall, Ritalin, Ephedrine, etc)"
            checked={props.patientData.Stimulants.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                Stimulants: {
                  ...props.patientData.Stimulants,
                  state: !props.patientData.Stimulants.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Stimulants.state && (
          <>
            <SubstanceUse
              CurrentPastUse={props.patientData.Stimulants.currentPastUse}
              DrinksPerWeek={props.patientData.Stimulants.drinksPerWeek}
              BingeDrink={props.patientData.Stimulants.bingeDrink}
              DateRange={props.patientData.Stimulants.dateRange}
              Frequency={props.patientData.Stimulants.frequency}
              Quantity={props.patientData.Stimulants.quantity}
              Route={props.patientData.Stimulants.route}
              CAGE={props.patientData.Stimulants.cAGE}
              Notes={props.patientData.Stimulants.notes}
              SaveSubstance={saveSubstanceStimulants}
              Substance="Stimulants"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="SedativesCheckbox"
            label="Sedatives or tranquilizers (sleeping pills, Valium, etc)"
            checked={props.patientData.Sedatives.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                Sedatives: {
                  ...props.patientData.Sedatives,
                  state: !props.patientData.Sedatives.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Sedatives.state && (
          <>
            <SubstanceUse
              CurrentPastUse={props.patientData.Sedatives.currentPastUse}
              DrinksPerWeek={props.patientData.Sedatives.drinksPerWeek}
              BingeDrink={props.patientData.Sedatives.bingeDrink}
              DateRange={props.patientData.Sedatives.dateRange}
              Frequency={props.patientData.Sedatives.frequency}
              Quantity={props.patientData.Sedatives.quantity}
              Route={props.patientData.Sedatives.route}
              CAGE={props.patientData.Sedatives.cAGE}
              Notes={props.patientData.Sedatives.notes}
              SaveSubstance={saveSubstanceSedatives}
              Substance="Sedatives"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="OtherPrescriptionCheckbox"
            label="Other prescription medications that were not prescribed to the patient (e.g. pain medications, benzodiazepines, etc)"
            checked={props.patientData.OtherPrescription.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                OtherPrescription: {
                  ...props.patientData.OtherPrescription,
                  state: !props.patientData.OtherPrescription.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OtherPrescription.state && (
          <>
            <SubstanceUse
              CurrentPastUse={
                props.patientData.OtherPrescription.currentPastUse
              }
              DrinksPerWeek={props.patientData.OtherPrescription.drinksPerWeek}
              BingeDrink={props.patientData.OtherPrescription.bingeDrink}
              DateRange={props.patientData.OtherPrescription.dateRange}
              Frequency={props.patientData.OtherPrescription.frequency}
              Quantity={props.patientData.OtherPrescription.quantity}
              Route={props.patientData.OtherPrescription.route}
              CAGE={props.patientData.OtherPrescription.cAGE}
              Notes={props.patientData.OtherPrescription.notes}
              SaveSubstance={saveSubstanceOtherPrescription}
              Substance="OtherPrescription"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="OtherAbuseSubstanceCheckbox"
            label="Other (synthetics, inhalants, over the counter, etc)"
            checked={props.patientData.OtherAbuseSubstance.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                OtherAbuseSubstance: {
                  ...props.patientData.OtherAbuseSubstance,
                  state: !props.patientData.OtherAbuseSubstance.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OtherAbuseSubstance.state && (
          <>
            <SubstanceUse
              CurrentPastUse={
                props.patientData.OtherAbuseSubstance.currentPastUse
              }
              DrinksPerWeek={
                props.patientData.OtherAbuseSubstance.drinksPerWeek
              }
              BingeDrink={props.patientData.OtherAbuseSubstance.bingeDrink}
              DateRange={props.patientData.OtherAbuseSubstance.dateRange}
              Frequency={props.patientData.OtherAbuseSubstance.frequency}
              Quantity={props.patientData.OtherAbuseSubstance.quantity}
              Route={props.patientData.OtherAbuseSubstance.route}
              CAGE={props.patientData.OtherAbuseSubstance.cAGE}
              Notes={props.patientData.OtherAbuseSubstance.notes}
              SaveSubstance={saveSubstanceOtherAbuseSubstance}
              Substance="OtherAbuseSubstance"
            />
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional notes
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            bsPrefix="form-input"
            disabled={props.patientData.NoSubstanceAddiction}
            onChange={(e) => {
              props.setPatientData({
                ...props.patientData,
                SubstanceAdditionalNotes: e.target.value,
              })
            }}
            value={props.patientData.SubstanceAdditionalNotes}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
