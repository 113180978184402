import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const substanceUse: PatientDemographicObject[] = [
  { '39953003': { title: 'Tobacco' } },
  { '398705004': { title: 'Cannabis (leaf, oil, resin)' } },
  { '387085005': { title: 'Cocaine' } },
  { '387341002': { title: 'Heroin' } },
  { '412035000': { title: 'Methamphetamine/amphetamine' } },
  { '288459003': { title: 'MDMA' } },
  { '53513007': { title: 'Psilocybin' } },
  {
    '373469002': {
      title: 'Other psychedelics and hallucinogens (PCP, LSD, DMT, etc.)',
    },
  },
  { '387459000': { title: 'Stimulants (adderall, ritalin, ephedrine)' } },
  {
    '373209002': {
      title: 'Sedatives or tranquilizers (sleeping pills, Valium, Xanax, etc.)',
    },
  },
  {
    '191939002': {
      title:
        'Other prescription medicines not prescibed to the patient (e.g. pain meditcations, benzodiazepines, etc.)',
    },
  },
  {
    SU_CODE_UNDEFINED_1: {
      title: 'Other (synthetics, inhalants, over the counter, etc.)',
    },
  },
]

export const substanceUseDropdownValues = constructPatientDemographicObject(
  substanceUse,
  CodeSystemOIDs.SNOMEDCT,
  Categories.SUBSTANCE_ABUSE,
  true
)
