import React from 'react'

import { Container, Form, Row } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'

import './../PatientProfile.scss'

export default function ManicSaved(props) {
  const hasNoRelevantHistory =
    props.patientData.BipolarI.state === false &&
    props.patientData.BipolarII.state === false &&
    props.patientData.OtherManic.state === false &&
    props.patientData.IrritableMood.state === false &&
    !props.patientData.ManicAdditionalNotes &&
    props.patientData.NoManicBipolar === true

  const noRecordedInfo =
    props.patientData.BipolarI.state === false &&
    props.patientData.BipolarII.state === false &&
    props.patientData.OtherManic.state === false &&
    props.patientData.IrritableMood.state === false &&
    !props.patientData.ManicAdditionalNotes &&
    props.patientData.NoManicBipolar === false

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Manic/Hypomanic/Bipolar"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">
        Manic/Hypomanic/Bipolar:
      </Form.Row>
      <Form.Group className="indent">
        {props.patientData.BipolarI.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.BipolarI.state)}
            />
            <span id="BipolarIShow">Bipolar I</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Number of Episodes:{' '}
            </span>
            {props.patientData.BipolarI.NumberEpisodes}
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.BipolarI.Notes}
          </Container>
        )}
        {props.patientData.BipolarII.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.BipolarII.state)}
            />
            <span id="BipolarIIShow">Bipolar II</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Number of Episodes:{' '}
            </span>
            {props.patientData.BipolarII.NumberEpisodes}
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.BipolarII.Notes}
          </Container>
        )}
        {props.patientData.OtherManic.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.OtherManic.state)}
            />
            <span id="OtherManicShow">Other</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Number of Episodes:{' '}
            </span>
            {props.patientData.OtherManic.NumberEpisodes}
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.OtherManic.Notes}
          </Container>
        )}
        {props.patientData.IrritableMood.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.IrritableMood.state)}
            />
            <span id="IrritableMoodShow">
              Patient has previously had abnormally elevated or irritable mood
              for 3-4d in a row
            </span>
            <br />
            {props.patientData.IrritableMood.IncreasedImpulsivity && (
              <>
                <Form.Check
                  className="psych-checkbox indent"
                  type="checkbox"
                  checked={JSON.parse(
                    props.patientData.IrritableMood.IncreasedImpulsivity
                  )}
                />
                <span id="IncreasedImpulsivityShow">
                  Increased impulsivity/risk taking
                </span>
                <br />
              </>
            )}
            {props.patientData.IrritableMood.TalkingFaster && (
              <>
                <Form.Check
                  className="psych-checkbox indent"
                  type="checkbox"
                  checked={JSON.parse(
                    props.patientData.IrritableMood.TalkingFaster
                  )}
                />
                <span id="TalkingFasterShow">
                  Talking faster/more or interruptions
                </span>
                <br />
              </>
            )}
            {props.patientData.IrritableMood.MoreEnergy && (
              <>
                <Form.Check
                  className="psych-checkbox indent"
                  type="checkbox"
                  checked={JSON.parse(
                    props.patientData.IrritableMood.MoreEnergy
                  )}
                />
                <span id="MoreEnergyShow">More energy / need less sleep</span>
                <br />
              </>
            )}
            {props.patientData.IrritableMood.ExcessiveConfidence && (
              <>
                <Form.Check
                  className="psych-checkbox indent"
                  type="checkbox"
                  checked={JSON.parse(
                    props.patientData.IrritableMood.ExcessiveConfidence
                  )}
                />
                <span id="ExcessiveConfidenceShow">
                  Feeling excessively confident or grandiose
                </span>
                <br />
              </>
            )}
            {props.patientData.IrritableMood.FasterThoughts && (
              <>
                <Form.Check
                  className="psych-checkbox indent"
                  type="checkbox"
                  checked={JSON.parse(
                    props.patientData.IrritableMood.FasterThoughts
                  )}
                />
                <span id="FasterThoughtsShow">
                  Faster thoughts, flight of ideas
                </span>
                <br />
              </>
            )}
            {props.patientData.IrritableMood.IncreasedAgitation && (
              <>
                <Form.Check
                  className="psych-checkbox indent"
                  type="checkbox"
                  checked={JSON.parse(
                    props.patientData.IrritableMood.IncreasedAgitation
                  )}
                />
                <span id="IncreasedAgitationShow">
                  Increased psychomotor agitation or ideas/projects
                </span>
                <br />
              </>
            )}
            {props.patientData.IrritableMood.IncreasedDistractibility && (
              <>
                <Form.Check
                  className="psych-checkbox indent"
                  type="checkbox"
                  checked={JSON.parse(
                    props.patientData.IrritableMood.IncreasedDistractibility
                  )}
                />
                <span id="IncreasedDistractibilityShow">
                  Increased distractibility
                </span>
                <br />
              </>
            )}
            {props.patientData.IrritableMood.ChangeMedication && (
              <>
                <Form.Check
                  className="psych-checkbox indent"
                  type="checkbox"
                  checked={JSON.parse(
                    props.patientData.IrritableMood.ChangeMedication
                  )}
                />
                <span id="ChangeMedicationShow">
                  Change in substances/medications during episode
                </span>
                <br />
              </>
            )}
            {props.patientData.IrritableMood.Other && (
              <>
                <Form.Check
                  className="psych-checkbox indent"
                  type="checkbox"
                  checked={JSON.parse(props.patientData.IrritableMood.Other)}
                />
                <span id="OtherTextShow">
                  {props.patientData.IrritableMood.OtherText}
                </span>
                <br />
              </>
            )}
          </Container>
        )}
      </Form.Group>
      <Form.Group className="border-bottom indent">
        {props.patientData.ManicAdditionalNotes && (
          <Container bsPrefix="psych">
            <Form.Row className="patient-dark">Additional Notes:</Form.Row>
            <Row bsPrefix="psych-sub">
              {props.patientData.ManicAdditionalNotes}
            </Row>
          </Container>
        )}
      </Form.Group>
    </Form.Group>
  )
}
