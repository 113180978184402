import React from 'react'

import 'moment-timezone'
import { Col, Form, Row } from 'react-bootstrap'
import 'react-dates/initialize'

import CustomTimePicker from '../../../../../stories/BaseComponents/CustomTimePicker'
import { AutoSizingTextArea } from '../../../../../stories/BaseComponents/TextAreaInput'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function SOAPPlan(props) {
  return (
    <>
      <Form.Group as={Col} sm={12} md={12} lg={12}>
        <Form.Label
          style={{ fontWeight: 'bold', fontSize: '17px', marginTop: '10px' }}
        >
          Recommendations:
        </Form.Label>
      </Form.Group>
      <Row>
        {!props.Therapy && (
          <Form.Group as={Col} sm={12} md={6} lg={6}>
            <Form.Label style={{ fontWeight: 'bold' }}>Medication</Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.MedicationName}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  MedicationName: e.target.value,
                })
              }
              disabled={props.disabled}
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={!props.Therapy ? 6 : 12}
          lg={!props.Therapy ? 6 : 12}
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Psych testing / medical / labs / medical care
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.PsychCare}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PsychCare: e.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>Psychotherapy</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.PsychotherapyNotes}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PsychotherapyNotes: e.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Other (sleep, hygiene, lifestyle, social/environment, safety)
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.OtherSafety}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                OtherSafety: e.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Row>
      <hr />
      <Row>
        <Form.Group as={Col} sm={12} md={6} lg={6}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Treatment Goals
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.TreatmentPlan}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                TreatmentPlan: e.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} md={6} lg={6}>
          <Form.Label style={{ fontWeight: 'bold' }}>Risk Level</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.RiskLevel}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                RiskLevel: e.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>Follow Up</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.DischargeNotes}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                DischargeNotes: e.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} sm={12} lg={3}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Treatment end time
          </Form.Label>
          <CustomTimePicker
            value={props.fields.DischargeTime}
            onChange={(value) => {
              props.handleFieldChange({ ...props.fields, DischargeTime: value })
            }}
            disabled={props.disabled}
          />
        </Form.Group>
      </Row>
    </>
  )
}
