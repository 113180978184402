import { useQuery } from '@tanstack/react-query'

import { Roles, getPermissionCookie } from '../../api/permissions'

// This function uses OR logic for determining if the user has the roles.
// If two roles are passed function returns true if one role meets the criteria
export const useHasPermissions = (roles: Roles) => {
  const { data: currentUser } = useQuery(
    ['userPermissions'],
    getPermissionCookie, // 5 minutes
    { staleTime: 60000 * 5 }
  )
  if (currentUser?.role) {
    const userRole = currentUser.role
    return Object.entries(userRole).some(
      ([key, value]) => value === roles[key as keyof Roles]
    )
  } else {
    return false
  }
}
