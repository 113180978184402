import { OptionInput } from '../BaseComponents/MultiSelect'
import { ResourceType } from './constants'

export interface EventCategory extends OptionInput {
  color?: string // hex or rgb values
  // value?: string // cognitoId or roomId (already extended)
  // label?: string // provider name or facilities/room name (already extended)
  type?: ResourceType
  subLabel?: string
}

export enum CalendarAccountType {
  CLINIC = 'CLINIC',
  USER = 'USER',
}

export enum CalendarType {
  CLINIC = 'USER',
  PERSONAL = 'PERSONAL',
  PERSONAL_OSMIND = 'PERSONAL_OSMIND',
}

export enum CalendarSettingsType {
  // only the one owning user can see this calendar in Osmind
  INDIVIDUAL = 'INDIVIDUAL',
  // everyone in a clinic can see this calendar in Osmind
  // but with no event details
  EVERYONE_PRIVATE = 'EVERYONE_PRIVATE',
  // everyone in a clinic can see this calendar in Osmind
  // with all event details
  EVERYONE_PUBLIC = 'EVERYONE_PUBLIC',
  // show patient initials on these Osmind google events
  WITH_PATIENT_INITIALS = 'WITH_PATIENT_INITIALS',
  // do not show patient initials on these Osmind google events
  WITHOUT_PATIENT_INITIALS = 'WITHOUT_PATIENT_INITIALS',
  INTEGRATED_PUBLIC = 'INTEGRATED_PUBLIC',
  INTEGRATED_PRIVATE = 'INTEGRATED_PRIVATE',
}

export enum CalendarEventPrivacy {
  INTEGRATED_WITH_DETAILS = 'INTEGRATED_WITH_DETAILS',
  INTEGRATED = 'INTEGRATED',
  PUBLIC_WITH_DETAILS = 'PUBLIC_WITH_DETAILS',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  HIDDEN = 'HIDDEN',
}

export interface Calendar {
  eventPrivacy?: CalendarEventPrivacy
  accountId: number
  calendarId: string
  providerIntegrationId: number
  externalCalendarId: number
  calendarName: string
  calendarSettings: CalendarSettingsType
  calendarType: CalendarType
  createdAt?: Date
  showInOsmind?: boolean
  updatedAt?: Date
}

export type PersonalCalendar = Calendar
export type PersonalCalendars = PersonalCalendar[]

export interface UserCalendarAccount {
  id: number
  accountName: string
  calendarAccountType: CalendarAccountType
  calendars: Calendar[]
  clinicId: string
  clinicResourceId: string | null
  internalId: string
  createdAt?: Date
  updatedAt?: Date
  needsReauth: boolean
}
