import { useCallback, useEffect, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import 'moment-timezone'

import {
  IntakeForm,
  getCompletedIntakeForm,
  importIntakeForm,
  reviewIntakeForm,
} from '../../api/intakeForms'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'
import { QueryKeys } from '../../hooks/usePatientInfo'
import { PatientIntakeFormQueryKeys } from '../../hooks/usePatientIntakeForm/useIntakeFormQueries'
import {
  QueryKeys as PatientProfilePanel,
  useInsuranceInfo,
} from '../../hooks/usePatientProfile'
import useQueryString from '../../hooks/useQueryString'
import {
  ProviderSideIntakeFormEvents,
  trackIntakeFormEvent,
} from '../../libs/freshpaint/intakeFormEvents'
import { notification } from '../../libs/notificationLib'
import { Button, Modal, Skeleton } from '../BaseComponents'
import { StandardSkeletonRows } from '../BaseComponents/Skeleton'
import PatientIntakeResponses from './PatientIntakeResponses'
import { sampleForm } from './mocks/sample-form'

import './ReviewPatientIntake.scss'

interface ReviewProps {
  formId: string
  storybook?: boolean
  toggleButtonText: 'Review and Import' | 'Import Form' | 'View Form'
}
type Primitives = string | number | boolean
type ArrayVariations = (string | number | boolean | NestedObject)[]
type NestedObject = Record<string, Primitives | ArrayVariations>

export default function ReviewPatientIntake({
  formId,
  toggleButtonText,
  storybook = false,
}: ReviewProps) {
  const queryClient = useQueryClient()
  const query = useQueryString()
  const patientId = query.get('patientId') ?? ''
  const [showModal, setShowModal] = useState(false)
  const [loadingForm, setLoadingForm] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [canBeReviewed, setCanBeReviewed] = useState(false)
  const [canBeImported, setCanBeImported] = useState(false)
  const [completedForm, setCompletedForm] = useState<IntakeForm | null>(null)

  const setOptionsForIntakeForm = (completedForm: IntakeForm) => {
    if (!completedForm.reviewedAt) setCanBeReviewed(true)
    if (!completedForm.importedAt) setCanBeImported(true)
    setCompletedForm(completedForm)
  }

  const updateCache = useCallback(
    // Update the cache of patient's Osmind intake forms
    async (updatedForm: IntakeForm) => {
      const queryKey = [
        PatientIntakeFormQueryKeys.ALL_INTAKE_FORMS_FOR_PATIENT,
        updatedForm.patientId,
      ]
      queryClient.setQueryData<IntakeForm[] | undefined>(
        queryKey,
        (previousData) => {
          if (!previousData) return undefined

          return previousData.map((cachedIntakeForm) =>
            cachedIntakeForm.id === updatedForm.id
              ? updatedForm
              : cachedIntakeForm
          )
        }
      )
    },
    [queryClient]
  )

  useEffect(() => {
    if (!showModal) return
    ;(async function () {
      try {
        if (storybook) {
          setOptionsForIntakeForm(sampleForm as IntakeForm)
          setLoadingForm(false)
          return
        }
        const completedForm = await getCompletedIntakeForm(formId)
        setOptionsForIntakeForm(completedForm)
        setLoadingForm(false)
      } catch (err) {
        const errorMessage =
          err.response?.data?.error ?? 'An unhandled error occurred.'
        notification(errorMessage, 'error')
      }
    })()
  }, [formId, showModal])

  const { refetch } = useInsuranceInfo({ patientId })

  const importForm = useCallback(async () => {
    try {
      setCanBeImported(false)
      setCanBeReviewed(false)
      setIsSubmitting(true)
      const updatedFormData = await importIntakeForm(formId)
      trackIntakeFormEvent(ProviderSideIntakeFormEvents.IMPORTED_FORM, {
        osmindIntakeId: formId,
      })
      // Update patient data and intake reminders/notifications
      queryClient.invalidateQueries([
        patientDataTypes.ProviderSidePatientData,
        updatedFormData.patientId,
      ])
      // Update patient demographics cache for patient profile
      queryClient.invalidateQueries([
        QueryKeys.DEMOGRAPHICS,
        updatedFormData.patientId,
      ])
      queryClient.invalidateQueries([
        patientDataTypes.PatientDiagnoses,
        updatedFormData.patientId,
      ])
      queryClient.invalidateQueries([
        patientDataTypes.PatientAllergies,
        updatedFormData.patientId,
      ])
      queryClient.invalidateQueries([PatientProfilePanel.CORE_IDENTIFIERS])
      queryClient.invalidateQueries([
        PatientProfilePanel.DEMOGRAPHICS_CONTACT_INFO,
      ])
      updateCache(updatedFormData)
      notification('Successfully imported form.', 'success')
    } catch (err) {
      const errorMessage =
        err.response?.data?.error ?? 'Failed to import all patient information.'
      notification(errorMessage, 'error')
    } finally {
      setIsSubmitting(false)
      setShowModal(false)
      refetch()
    }
  }, [formId])

  const reviewForm = useCallback(async () => {
    try {
      setCanBeReviewed(false)
      setIsSubmitting(true)
      const updatedFormData = await reviewIntakeForm(formId)
      trackIntakeFormEvent(ProviderSideIntakeFormEvents.MARKED_REVIEWED, {
        osmindIntakeId: formId,
      })
      // Update patient data and intake reminders/notifications
      queryClient.invalidateQueries([
        patientDataTypes.ProviderSidePatientData,
        updatedFormData.patientId,
      ])
      updateCache(updatedFormData)
      notification('Successfully marked form as reviewed.', 'success')
    } catch (err) {
      const errorMessage =
        err.response?.data?.error ?? 'Failed to mark intake form as reviewed.'
      notification(errorMessage, 'error')
    } finally {
      setIsSubmitting(false)
      setShowModal(false)
    }
  }, [formId])

  const onOpenModal = () => {
    setShowModal(true)
    trackIntakeFormEvent(ProviderSideIntakeFormEvents.REVIEW_AND_IMPORT, {
      osmindIntakeId: formId,
    })
  }
  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <Button
        type={toggleButtonText === 'Review and Import' ? 'primary' : 'default'}
        onClick={onOpenModal}
      >
        {toggleButtonText}
      </Button>
      <Modal
        title="Review intake form"
        visible={showModal}
        onCancel={onCloseModal}
        width={1000}
        footer={[
          <Button
            key="intake-form-modal-cancel-btn"
            hidden={loadingForm}
            onClick={onCloseModal}
            loading={isSubmitting}
          >
            Cancel
          </Button>,
          <Button
            key="intake-form-modal-mark-reviewed-btn"
            onClick={reviewForm}
            hidden={!canBeReviewed}
            loading={isSubmitting}
          >
            Mark Reviewed
          </Button>,
          <Button
            key="intake-form-modal-import-btn"
            onClick={importForm}
            hidden={!canBeImported}
            loading={isSubmitting}
            type="primary"
          >
            Import Form
          </Button>,
        ]}
      >
        {loadingForm ? (
          <Skeleton paragraph={{ rows: StandardSkeletonRows.fullPage }} />
        ) : (
          <PatientIntakeResponses completedForm={completedForm} />
        )}
      </Modal>
    </>
  )
}
