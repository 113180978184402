import { useMemo, useState } from 'react'

import { AppointmentDeclineModal } from '../../components/Scheduling/AppointmentDeclineModal'
import { AppointmentRequestCard } from '../../components/Scheduling/AppointmentRequestCard'
import { NoAppointmentRequestCard } from '../../components/Scheduling/NoAppointmentRequestsCard'
import {
  AcceptedAppointmentParams,
  trackAppointmentRequestApprovals,
} from '../../libs/freshpaint/selfSchedulingEvents'
import { Appointment } from '../../shared-types'
import { Drawer } from '../BaseComponents'
import { Tabs } from '../BaseComponents/Tabs'

export type AppointmentDrawerProps = {
  appointmentRequests: Appointment<false>[]
  canSeeAllPatients: boolean
  handleCloseDrawer: () => void
  isDrawerOpen: boolean
  providerId: string
  clinicId: string
  onAccept(
    appointmentId: string,
    trackingData: Omit<AcceptedAppointmentParams, 'clinicId' | 'eventName'>
  ): void
  isAcceptingIds: Set<string>
  loadAppointmentRequests: () => void
  onNavigationChange: (dateSelected: Date) => void
  currentTimezone: string
}

const TabKeys = {
  ALL_REQUESTS: '1',
  MY_REQUESTS: '2',
} as const

export function AppointmentDrawer({
  onNavigationChange,
  loadAppointmentRequests,
  appointmentRequests,
  canSeeAllPatients,
  handleCloseDrawer,
  isDrawerOpen,
  providerId,
  clinicId,
  onAccept,
  isAcceptingIds,
  currentTimezone,
}: AppointmentDrawerProps) {
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false)
  const [appointmentToDecline, setAppointmentToDecline] =
    useState<Appointment<false> | null>(null)
  const openDeclineModal = (appointment: Appointment<false>) => {
    setIsDeclineModalVisible(true)
    setAppointmentToDecline(appointment)
  }
  const filteredAppointments = useMemo(() => {
    return appointmentRequests.filter(
      ({ providerIsOnCareTeam }) => providerIsOnCareTeam
    )
  }, [appointmentRequests])

  const handleTabChange = (key: string) => {
    if (key === TabKeys.ALL_REQUESTS) {
      trackAppointmentRequestApprovals({
        eventName: 'Filtered appointment requests drawer',
        clinicId,
        filter: 'All requests',
        providerId,
      })
      return
    }
    trackAppointmentRequestApprovals({
      eventName: 'Filtered appointment requests drawer',
      clinicId,
      filter: 'My requests',
      providerId,
    })
  }

  return (
    <>
      <AppointmentDeclineModal
        loadAppointmentRequests={loadAppointmentRequests}
        appointmentToDecline={{
          appointmentTypeName: appointmentToDecline?.appointmentType.name || '',
          apptTypeId: appointmentToDecline?.appointmentType.id || '',
          id: appointmentToDecline?.id || '',
          patientId: appointmentToDecline?.patient.PatientId || '',
        }}
        visibleModal={isDeclineModalVisible}
        handleVisibleModal={setIsDeclineModalVisible}
        providerId={providerId}
        clinicId={clinicId}
      />
      <Drawer
        testId={'appointments-request-drawer'}
        title="Appointment requests"
        placement="right"
        onClose={handleCloseDrawer}
        open={isDrawerOpen}
        visible={isDrawerOpen}
        bodyStyle={{ paddingTop: 0 }}
        maskClosable={false}
        mask={false}
        getContainer={false}
        contentWrapperStyle={{
          marginTop: '6.80em',
          boxShadow:
            'rgba(0, 0, 0, 0.08) 0px 20px 16px -8px, rgba(0, 0, 0, 0.05) -19px 20px 28px 0px, rgba(0, 0, 0, 0.03) 0px 20px 20px 0px',
        }}
      >
        <Tabs
          defaultActiveKey={
            canSeeAllPatients ? TabKeys.ALL_REQUESTS : TabKeys.MY_REQUESTS
          }
          onChange={handleTabChange}
        >
          <Tabs.TabPane
            tab="All requests"
            key={TabKeys.ALL_REQUESTS}
            disabled={!canSeeAllPatients}
          >
            {appointmentRequests.length ? (
              appointmentRequests.map((apptRequest) => (
                <AppointmentRequestCard
                  providerId={providerId}
                  key={apptRequest.id}
                  appointment={apptRequest}
                  onAccept={onAccept}
                  isAccepting={isAcceptingIds.has(apptRequest.id)}
                  openDeclineModal={openDeclineModal}
                  onNavigationChange={onNavigationChange}
                  currentTimezone={currentTimezone}
                />
              ))
            ) : (
              <NoAppointmentRequestCard />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="My requests" key={TabKeys.MY_REQUESTS}>
            {filteredAppointments.length > 0 ? (
              filteredAppointments.map((apptRequest) => (
                <AppointmentRequestCard
                  providerId={providerId}
                  key={apptRequest.id}
                  appointment={apptRequest}
                  onAccept={onAccept}
                  isAccepting={isAcceptingIds.has(apptRequest.id)}
                  openDeclineModal={openDeclineModal}
                  onNavigationChange={onNavigationChange}
                  currentTimezone={currentTimezone}
                />
              ))
            ) : (
              <NoAppointmentRequestCard />
            )}
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
    </>
  )
}
