import React, { useEffect, useState } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'

import { getPermissionCookie, updateUserRoles } from '../../api/permissions'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import { RoleTitles, Teammate } from '../../shared-types'
import {
  Button,
  Checkbox,
  Divider,
  Modal,
  Row,
  Tooltip,
} from '../BaseComponents'
import ProviderInfoBox from './ProviderInfoBox'

import './TeamSettings.scss'
import styles from './TeammateModals.module.scss'

interface Props {
  masterProviderId: string
  loggedInUserCognitoId: string
  teammates: Teammate[]
  record: Teammate
  refresh: boolean
  storybook?: boolean
  allRoles: RoleTitles[]
  refreshSelectedTeamate: Function
}

const TeamRolesModal: React.FC<Props> = ({
  masterProviderId,
  loggedInUserCognitoId,
  teammates,
  refresh,
  record,
  allRoles,
  refreshSelectedTeamate,
  storybook = false,
}) => {
  const [isSavingRoles, setIsSavingRoles] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [roleOptions, setRoleOptions] = useState<RoleTitles[]>()
  const [userName, setUserName] = useState<string>('')
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [requiredSelectedRoles, setRequiredSelectedRoles] = useState<number[]>(
    []
  )
  const [nonRequiredSelectedRoles, setNonRequiredSelectedRoles] = useState<
    number[]
  >([])
  const [currentRoleTitles, setCurrentRoleTitles] = useState<string[]>([])
  const [providerRole, setProviderRole] = useState<boolean>(false)
  const [clinicalStaffRole, setClinicalStaffRole] = useState<boolean>(false)
  const [nonClinicalStaffRole, setNonClinicalStaffRole] =
    useState<boolean>(false)
  const [superAdminRole, setSuperAdminRole] = useState<boolean>(false)
  const [adminRole, setAdminRole] = useState<boolean>(false)

  const { refetch } = useQuery(
    ['userPermissions'],
    getPermissionCookie, // 5 minutes
    { staleTime: 60000 * 5 }
  )

  function setChecks(check: boolean, role: string) {
    switch (role) {
      case 'Provider':
        setProviderRole(check)
        break
      case 'Clinical staff':
        setClinicalStaffRole(check)
        break
      case 'Non-clinical':
        setNonClinicalStaffRole(check)
        break
      case 'Super-Admin':
        setSuperAdminRole(check)
        break
      case 'Admin':
        setAdminRole(check)
        break
    }
  }

  function restartRoles() {
    setProviderRole(false)
    setClinicalStaffRole(false)
    setNonClinicalStaffRole(false)
    setSuperAdminRole(false)
    setAdminRole(false)
  }

  function setRolesForRecord() {
    restartRoles()
    setRoleOptions(allRoles)
    if (!record || !record.roleTitles) return
    const currentRoles = record.roleTitles
    const reqRoles = []
    const nonReqRoles = []
    const roleTitles = []

    for (const cR of currentRoles) {
      if (
        cR.label === 'Super-Admin' ||
        cR.label === 'Admin' ||
        cR.label === 'View all patients'
      ) {
        nonReqRoles.push(cR.value)
      } else {
        reqRoles.push(cR.value)
      }
      if (cR.label !== 'View all patients') {
        roleTitles.push(cR.label)
      }

      setChecks(true, cR.label)
    }
    setUserName(record.name ? record.name : record.email)
    setRequiredSelectedRoles(reqRoles)
    setNonRequiredSelectedRoles(nonReqRoles)
    setCurrentRoleTitles(roleTitles)
  }

  useEffect(() => {
    setRolesForRecord()
  }, [allRoles, record, refresh, teammates])

  useEffect(() => {
    if (!loggedInUserCognitoId || !teammates.length) return
    const currentUserArray = teammates.filter(
      (t) => t.cognitoId === loggedInUserCognitoId
    )
    const currentUser = currentUserArray[0]
    if (!currentUser || !currentUser?.roleTitles) return
    let isCurrentLoggedSuperAdmin = false
    let isCurrentLoggedAdmin = false
    for (const rT of currentUser.roleTitles) {
      if (rT.label === 'Super-Admin') {
        isCurrentLoggedSuperAdmin = true
      } else if (rT.label === 'Admin') {
        isCurrentLoggedAdmin = true
        const recordTitles = record.roleTitles
        const s_a_r = recordTitles?.find((rT) => rT.label === 'Super-Admin')
        if (s_a_r?.label === 'Super-Admin') {
          // currently logged in is Admin, but record is from Super-Admin so they can't edit
          isCurrentLoggedAdmin = false
        }
      }
    }
    setIsSuperAdmin(isCurrentLoggedSuperAdmin)
    setIsAdmin(isCurrentLoggedAdmin)
  }, [loggedInUserCognitoId, teammates])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setRolesForRecord()
    setIsModalVisible(false)
  }

  async function handleInviteTeammate() {
    setIsSavingRoles(true)
    const rolesToSave = requiredSelectedRoles.concat(nonRequiredSelectedRoles)
    if (!rolesToSave.length) {
      return
    }
    try {
      const data = {
        providerId: record.cognitoId,
        roleIds: rolesToSave,
      }
      await updateUserRoles(data)
      notification(
        `Roles seats added to ${record.name ? record.name : record.email}.`,
        'success'
      )
      refetch()
      handleCancel()
      refreshSelectedTeamate(record.cognitoId)
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
    setIsSavingRoles(false)
  }

  const handleOk = () => {
    if (requiredSelectedRoles.length) {
      if (storybook) return
      handleInviteTeammate()
    }
  }

  function handleRequiredRoleChange(role: string) {
    const checkedRole = roleOptions?.find((rO) => rO.label === role)
    if (!checkedRole) return
    let shouldCheck = false
    if (requiredSelectedRoles.includes(checkedRole.value)) {
      const rolesToSave = requiredSelectedRoles.filter((sR) => {
        if (sR !== checkedRole.value) {
          return sR
        }
      })
      setRequiredSelectedRoles(rolesToSave)
    } else {
      setRequiredSelectedRoles([...requiredSelectedRoles, checkedRole.value])
      shouldCheck = true
    }
    setChecks(shouldCheck, role)
  }

  function isCurrentMainProvider() {
    return record.cognitoId === masterProviderId
  }

  function handleNonRequiredRoleChange(role: string) {
    if (isCurrentMainProvider()) {
      return
    }
    const checkedRole = roleOptions?.find((rO) => rO.label === role)
    if (!checkedRole) return
    let shouldCheck = false
    if (nonRequiredSelectedRoles.includes(checkedRole.value)) {
      const rolesToSave = nonRequiredSelectedRoles.filter((sR) => {
        if (sR !== checkedRole.value) {
          return sR
        }
      })
      setNonRequiredSelectedRoles(rolesToSave)
    } else {
      setNonRequiredSelectedRoles([
        ...nonRequiredSelectedRoles,
        checkedRole.value,
      ])
      shouldCheck = true
    }
    setChecks(shouldCheck, role)
  }

  function getRoleTitles() {
    let rolesString = currentRoleTitles.join(', ')
    if (rolesString.includes('Super-Admin')) {
      rolesString = rolesString.replace('Super-Admin', 'Super admin')
    }
    if (rolesString.includes('Non-clinical')) {
      rolesString = rolesString.replace('Non-clinical', 'Non-clinical staff')
    }
    return rolesString
  }

  return (
    <>
      {isSuperAdmin || isAdmin ? (
        <span onClick={showModal} className={'action reactivate-action'}>
          {getRoleTitles()}
        </span>
      ) : (
        <span className="action disabled-action">
          {currentRoleTitles.join(', ')}
        </span>
      )}
      <Modal
        title="Add role"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div className="antd-modal-footer">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              id="updateUserRoles"
              type="primary"
              onClick={handleOk}
              loading={isSavingRoles}
              disabled={!requiredSelectedRoles.length}
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <Form layout="vertical">
          {!currentRoleTitles.includes('Provider') && (
            <ProviderInfoBox isProvider={providerRole} />
          )}
          <span>
            Select the roles and permissions for {<span>{userName}</span>}
          </span>
          <Form.Item
            required={true}
            label={
              <>
                <span>Select user role(s) </span>
                <a
                  href={
                    'https://support.osmind.org/en/articles/6446843-user-roles-permissions'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.linkText}
                >
                  See role definitions
                </a>
              </>
            }
          >
            <Row
              onClick={() => handleRequiredRoleChange('Provider')}
              className={styles.row}
            >
              <Checkbox checked={providerRole} />
              <span className={styles.spanRow}>Provider</span>
              <Tooltip
                className={styles.tooltipRow}
                title="The Provider role has access to all standard Osmind EHR features, in addition to signing and finalizing a note and being listed as the rendering provider on a note and superbill."
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Row>
            <Row
              onClick={() => handleRequiredRoleChange('Clinical staff')}
              className={styles.row}
            >
              <Checkbox checked={clinicalStaffRole} />
              <span className={styles.spanRow}>Clinical staff</span>
              <Tooltip
                className={styles.tooltipRow}
                title="The Clinical staff role, intended for non-provider staff that perform clinical workflows, has access to all standard Osmind EHR features."
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Row>
            <Row
              onClick={() => handleRequiredRoleChange('Non-clinical')}
              className={styles.row}
            >
              <Checkbox checked={nonClinicalStaffRole} />
              <span className={styles.spanRow}>Non-clinical Staff</span>
              <Tooltip
                className={styles.tooltipRow}
                title="The Non-clinical staff role, intended for staff that perform administrative workflows, has access to all standard Osmind EHR features."
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Row>
          </Form.Item>
          <Divider
            orientationMargin="0"
            style={{ marginTop: '26px', marginBottom: '26px' }}
          />
          <Form.Item
            label={
              <>
                <span>{'Additional permissions'}</span>
                <span className={styles.optionalText}>{'(optional)'}</span>
              </>
            }
          >
            {isSuperAdmin ? (
              <Row
                onClick={() => handleNonRequiredRoleChange('Super-Admin')}
                className={styles.row}
              >
                <Checkbox
                  checked={superAdminRole}
                  disabled={isCurrentMainProvider()}
                />
                <span className={styles.spanRow}>Super admin</span>
                <Tooltip
                  className={styles.tooltipRow}
                  title="The Super admin permission allows users to edit both Super admin and non-Super admin users. Note: This permission must be given in addition to a user role."
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </Row>
            ) : (
              ''
            )}
            <Row
              onClick={() => handleNonRequiredRoleChange('Admin')}
              className={styles.row}
            >
              <Checkbox
                checked={adminRole}
                disabled={isCurrentMainProvider()}
              />
              <span className={styles.spanRow}>Admin</span>
              <Tooltip
                className={styles.tooltipRow}
                title="The admin permission allows users to edit non-Super admin users. Note: This permission must be given in addition to a user role."
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default TeamRolesModal
