import { useCallback } from 'react'

import {
  calculateClaimAndPatientOutstanding,
  filterSuccessful,
} from '../../../libs/billing'
import { PAYMENT_FORM_OPTIONS, PAYMENT_TYPE } from '../../../libs/constants'
import {
  InsuranceClaim,
  Invoice,
  PaymentAttempt,
  PaymentMethod,
} from '../../../shared-types'

export type InvoiceToBePaid = {
  invoiceUuid: string
  amountCentsToPay: number
}

export type CreditUsageByPaymentMethod = {
  [paymentMethodUuid: string]: {
    totalCredit: number
    creditUtilized: number
  }
}

export const getDefaultCreditUsageByPaymentMethod = (
  paymentMethods: PaymentMethod[],
  payments: PaymentAttempt[],
  totalChargeAmountCents?: number
) => {
  const creditUsageByPaymentMethod = {} as CreditUsageByPaymentMethod
  let remainTotalChargeAmountCents = totalChargeAmountCents || 0

  paymentMethods.forEach((paymentMethod) => {
    if (!totalChargeAmountCents) {
      creditUsageByPaymentMethod[paymentMethod.uuid] = {
        totalCredit: 0,
        creditUtilized: 0,
      }
      return
    }

    const paymentsForMethod = payments
      .filter((payment) => payment.paymentMethodUuid === paymentMethod.uuid)
      .filter(filterSuccessful)

    let creditAmount = 0
    paymentsForMethod.forEach((payment: PaymentAttempt) => {
      creditAmount += payment.amountCentsUnapplied
      if (payment.refundAttempts) {
        payment.refundAttempts.filter(filterSuccessful).forEach((refund) => {
          creditAmount -= refund.amountCentsUnapplied
        })
      }
    })

    const totalCredit = creditAmount <= 0 ? 0 : creditAmount
    const creditUtilized =
      remainTotalChargeAmountCents > totalCredit
        ? totalCredit
        : remainTotalChargeAmountCents

    if (
      remainTotalChargeAmountCents !== 0 &&
      remainTotalChargeAmountCents >= totalCredit
    ) {
      remainTotalChargeAmountCents -= totalCredit
    }

    if (creditUtilized !== totalCredit) {
      remainTotalChargeAmountCents = 0
    }

    creditUsageByPaymentMethod[paymentMethod.uuid] = {
      totalCredit,
      creditUtilized,
    }
  })

  return creditUsageByPaymentMethod
}

export const usePaymentAttribution = ({
  billableEntities,
}: {
  billableEntities: Invoice[] | InsuranceClaim[]
}) => {
  const buildBillableEntityApplicationData = useCallback(
    ({
      selectedBillableEntity,
      customAmount,
      paymentOption,
      paymentType,
    }: {
      selectedBillableEntity?: Invoice | InsuranceClaim
      customAmount?: number
      paymentOption: PAYMENT_FORM_OPTIONS
      paymentType: PAYMENT_TYPE
    }) => {
      const isInvoiceSelected = paymentType === PAYMENT_TYPE.INVOICE
      const amountCentsDue = isInvoiceSelected
        ? (selectedBillableEntity as Invoice)?.amountCentsDue
        : calculateClaimAndPatientOutstanding(
            selectedBillableEntity as InsuranceClaim
          )?.patientOutstanding
      if (paymentOption === PAYMENT_FORM_OPTIONS.ADD_PATIENT_CREDIT) {
        return []
      } else if (
        paymentOption === PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL &&
        selectedBillableEntity
      ) {
        const amountToApply = customAmount || amountCentsDue
        return [
          {
            [`${paymentType}Uuid`]: selectedBillableEntity.uuid,
            amountCents: amountToApply,
          },
        ]
      } else {
        // filter out any billable entities that have no amount due
        return billableEntities
          .map((entity) => {
            const amountCents = isInvoiceSelected
              ? (entity as Invoice)?.amountCentsDue
              : calculateClaimAndPatientOutstanding(entity as InsuranceClaim)
                  ?.patientOutstanding
            return { entity, amountCents }
          })
          .filter(({ amountCents }) => amountCents > 0)
          .map(({ entity, amountCents }) => ({
            [`${paymentType}Uuid`]: entity.uuid,
            amountCents: amountCents,
          }))
      }
    },
    [billableEntities]
  )

  return { buildBillableEntityApplicationData }
}
