import 'moment-timezone'
import { Button, Col, Form } from 'react-bootstrap'
import 'react-dates/initialize'
import { Link } from 'react-router-dom'

import useQueryString from '../../../../../../hooks/useQueryString'
import { AutoSizingTextArea } from '../../../../../../stories/BaseComponents/TextAreaInput'
import AssessmentPreparations from '../../Sections/AssessmentPreparations'
import CGI from '../../Sections/CGI'
import Objective from '../../Sections/Objective'
import ShowEntriesSpravato from '../../Tables/ShowEntriesSpravato'
import SeriousAdverseEvent from './SeriousAdverseEvent'
import { generateSpravatoClassName } from './Spravato'

import 'react-dates/lib/css/_datepicker.css'

interface AddAssessmentPreparationsProps {
  disabled: boolean
  shortPublicId: string
  previousNotes: any[]
  fields: any
  pregnantStatus: string
  saveNewEntriesSpravato: () => void
  saveEditEntries: (index: number, editVitSign: any) => void
  deleteEntries: (index: number) => void
  saveNewVitals: () => void
  saveNewCaretakerVitals: () => void
  saveEditVitalSigns: (index: number, editVitSign: any) => void
  setAddVitals: () => void
  deleteVitals: (index: number) => void
  detail?: string
  Medical?: boolean
  Spravato?: boolean
  handleFieldChange: (obj: any) => void
}

export default function SpravatoAssessmentPreparations(
  props: AddAssessmentPreparationsProps
) {
  const query = useQueryString()
  const patientId = query.get('patientId')
  const providerId = query.get('providerId')

  function handleShowEntries() {
    if (props.fields.SavedEntries && props.fields.SavedEntries.length !== 0) {
      return (
        <>
          {props.fields.SavedEntries.map((entry: any, index: number) => {
            return (
              <ShowEntriesSpravato
                key={index}
                index={index}
                Type={entry.Type}
                Date={entry.Date}
                Description={entry.Description}
                Resolution={entry.Resolution}
                saveEditEntries={props.saveEditEntries}
                deleteEntries={props.deleteEntries}
                disabled={props.disabled}
                entriesType={props.fields.SavedEntries}
              />
            )
          })}
        </>
      )
    }
  }
  return (
    <>
      <Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Form.Label className="font-weight-bold">
            Patient narrative & response to treatment
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.PatientResponseToExperience}
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                PatientResponseToExperience: event.target.value,
              })
            }
            as={AutoSizingTextArea}
            minRows={2}
          />
        </Form.Group>
      </Form.Row>
      <Objective
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        shortPublicId={props.shortPublicId}
        saveNewVitals={props.saveNewVitals}
        saveNewCaretakerVitals={props.saveNewCaretakerVitals}
        saveEditVitalSigns={props.saveEditVitalSigns}
        setAddVitals={props.setAddVitals}
        deleteVitals={props.deleteVitals}
        disabled={props.disabled}
        detail={props.detail}
        Medical
        Spravato
      />
      <CGI
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        previousNotes={props.previousNotes}
        disabled={props.disabled}
      />
      <br />
      <Form.Row>
        <Form.Group as={Col} xs={12} sm={12} lg={12} xl={12}>
          <Form.Label className="font-weight-bold">
            Is the patient currently taking any of the following medication(s)
            that may cause sedation or blood pressure changes?
          </Form.Label>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          style={{ marginRight: 8 }}
          className={
            props.fields.Benzodiazepines === null
              ? generateSpravatoClassName()
              : ''
          }
          as={Col}
          xm={12}
          sm={12}
          md={6}
          lg={4}
          controlId="Benzodiazepines"
        >
          <Form.Label className="font-weight-bold">Benzodiazepines</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.Benzodiazepines)}
            value="true"
            id="BenzodiazepinesYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                Benzodiazepines: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.Benzodiazepines) === false}
            value="false"
            id="BenzodiazepinesNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                Benzodiazepines: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        <Form.Group
          style={{ marginRight: 8 }}
          className={
            props.fields.NonBenzodiazepine === null
              ? generateSpravatoClassName()
              : ''
          }
          as={Col}
          xm={12}
          sm={12}
          md={6}
          lg={4}
          controlId="NonBenzodiazepine"
        >
          <Form.Label className="font-weight-bold">
            Non-benzodiazepine sedative hypnotics
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.NonBenzodiazepine)}
            value="true"
            id="NonBenzodiazepineYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                NonBenzodiazepine: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.NonBenzodiazepine) === false}
            value="false"
            id="NonBenzodiazepineNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                NonBenzodiazepine: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        <Form.Group
          style={{ marginRight: 8 }}
          className={
            props.fields.Psychostimulants === null
              ? generateSpravatoClassName()
              : ''
          }
          as={Col}
          xm={12}
          sm={12}
          md={6}
          lg={4}
          controlId="Psychostimulants"
        >
          <Form.Label className="font-weight-bold">Psychostimulants</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.Psychostimulants)}
            value="true"
            id="PsychostimulantsYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                Psychostimulants: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.Psychostimulants) === false}
            value="false"
            id="PsychostimulantsNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                Psychostimulants: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        <Form.Group
          className={
            props.fields.MAOIs === null ? generateSpravatoClassName() : ''
          }
          as={Col}
          xm={12}
          sm={12}
          md={6}
          lg={4}
          controlId="MAOIs"
        >
          <Form.Label className="font-weight-bold">
            Monoamine oxidase inhibitors (MAOIs)
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.MAOIs)}
            value="true"
            id="MAOIsYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                MAOIs: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.MAOIs) === false}
            value="false"
            id="MAOIsNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                MAOIs: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Link
          to={{
            pathname: `/patient/medication?patientId=${patientId}&providerId=${providerId}`,
          }}
          target="_blank"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '10px',
            marginTop: '10px',
          }}
        >
          <Button variant="primary">View Patient Medications</Button>
        </Link>
      </Form.Row>
      <hr />
      <AssessmentPreparations
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        pregnantStatus={props.pregnantStatus}
        detail={props.detail}
        disabled={props.disabled}
        Spravato
      />
      <hr />
      <SeriousAdverseEvent
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        showEntries={handleShowEntries}
        saveEntries={props.saveNewEntriesSpravato}
        disabled={props.disabled}
        eventType="SpravatoAdverseEventBetween"
        entriesType="SavedEntries"
      />
      <Form.Row>
        <Form.Group as={Col} xs={12} sm={12} lg={6} xl={6}>
          <Form.Check
            type="checkbox"
            id="PatientTreatmentConsent"
            checked={JSON.parse(props.fields.PatientTreatmentConsent)}
            label="Patient identified and treatment consent provided"
            className="font-weight-bold"
            disabled={props.disabled}
            onChange={() =>
              props.handleFieldChange({
                ...props.fields,
                PatientTreatmentConsent: !props.fields.PatientTreatmentConsent,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12} sm={12} lg={6} xl={6}>
          <Form.Check
            type="checkbox"
            id="RisksAndQuestions"
            checked={JSON.parse(props.fields.RisksAndQuestions)}
            className="font-weight-bold"
            disabled={props.disabled}
            label="Provider discussed risks and benefits and answered all patient questions"
            onChange={() =>
              props.handleFieldChange({
                ...props.fields,
                RisksAndQuestions: !props.fields.RisksAndQuestions,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label className="font-weight-bold">Additional Notes</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.IVNotes}
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                IVNotes: event.target.value,
              })
            }
            as={AutoSizingTextArea}
            minRows={2}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
