import { useState } from 'react'

import { DatePicker } from 'antd'
import moment from 'moment'
import { CSVLink } from 'react-csv'

import { usePaymentsSummaryData } from '../../../hooks/useBillingInfo'
import { notification } from '../../../libs/notificationLib'
import { Button, Divider, Modal, Text } from '../../BaseComponents'
import {
  getDefaultStartMonthDate,
  getFilename,
  validatePaymentsForCSV,
} from './ExportTransactionsModal.helpers'

import styles from './ExportTransactionsModal.module.scss'

interface ExportTransactionsModalProps {
  isOpen: boolean
  onClose(): void
}

export const ExportTransactionsModal = ({
  isOpen,
  onClose,
}: ExportTransactionsModalProps) => {
  const [startDate, setStartDate] = useState<Date | null | undefined>(
    getDefaultStartMonthDate().js
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(new Date())
  const { data: paymentsSummaryData, isLoading } = usePaymentsSummaryData(
    startDate || new Date(),
    endDate || new Date()
  )

  const csvHeaders = [
    { label: 'Payment Attempt Timestamp (Pacific)', key: 'createdAt' },
    { label: 'Patient Name', key: 'patientName' },
    { label: 'Primary Clinician', key: 'primaryClinician' },
    { label: 'Acting User', key: 'actingUser' },
    { label: 'Card Info', key: 'cardInfo' },
    { label: 'Amount', key: 'amount' },
    { label: 'Stripe Fee', key: 'stripeFee' },
    { label: 'Net Amount', key: 'netAmount' },
    { label: 'Patient Refunded Amount', key: 'refundedAmount' },
    { label: 'Status', key: 'status' },
    { label: 'Error Code', key: 'errorCode' },
    { label: 'Error Message', key: 'errorMessage' },
  ]

  const onExportClick = () => {
    notification('Transactions successfully exported.', 'success')
    onClose()
  }

  const onChangeStartDate = (date: moment.Moment | null) => {
    setStartDate(date?.toDate())
  }

  const onChangeEndDate = (date: moment.Moment | null) => {
    setEndDate(date?.toDate())
  }

  const disableStartDate = (date: moment.Moment) => {
    return endDate ? date.toDate() > endDate : date.toDate() > new Date()
  }

  const disableEndDate = (date: moment.Moment) => {
    if (date.toDate() > new Date()) {
      return true
    }
    if (startDate && startDate > date.toDate()) {
      return true
    }
    return false
  }

  return (
    <Modal
      visible={isOpen}
      destroyOnClose={true}
      footer={null}
      closable={true}
      maskClosable={true}
      onCancel={onClose}
    >
      <Text className={styles.title}>Export transactions</Text>
      <Divider />
      <div>Select a date range below to export your transactions as a CSV.</div>
      <div className={styles.datePickerContainer}>
        <div className={styles.column}>
          <div>Start Date</div>
          <DatePicker
            placeholder="Select start date"
            onChange={onChangeStartDate}
            disabledDate={disableStartDate}
            showToday={false}
            defaultValue={getDefaultStartMonthDate().moment}
            className={styles.datePicker}
          />
        </div>
        <div className={styles.column}>
          <div>End Date</div>
          <DatePicker
            placeholder="Select end date"
            onChange={onChangeEndDate}
            disabledDate={disableEndDate}
            showToday={false}
            defaultValue={moment()}
            className={styles.datePicker}
          />
        </div>
      </div>
      <Divider />
      <div className={styles.footer}>
        <Button
          disabled={!startDate || !endDate}
          type="primary"
          className={styles.footerButton}
          loading={isLoading}
        >
          <CSVLink
            data={validatePaymentsForCSV(
              startDate,
              endDate,
              paymentsSummaryData
            )}
            headers={csvHeaders}
            filename={getFilename(startDate, endDate, paymentsSummaryData)}
            onClick={onExportClick}
          >
            Export
          </CSVLink>
        </Button>
        <Button onClick={onClose} className={styles.footerButton}>
          Cancel
        </Button>
      </div>
    </Modal>
  )
}
