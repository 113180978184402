import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { putNewScore } from '../../api/api-lib'

const useScoreMutation = (options: UseMutationOptions = {}) => {
  const queryClient = useQueryClient()

  return useMutation(putNewScore, {
    ...options,
    onSuccess: (data, variables, context) => {
      options.onSuccess && options.onSuccess(data, variables, context)
      queryClient.invalidateQueries(['scores'])
    },
  })
}

export default useScoreMutation
