import React from 'react'

import { useMutation } from '@tanstack/react-query'

import { connectAmd } from '../../api/api-lib'
import { notification } from '../../libs/notificationLib'
import { Button, Form, Spinner } from '../BaseComponents'

import './_amd.scss'

type AmdInput = {
  username: string
  password: string
  officeCode: number
}

const AmdForm = ({
  amd,
  isLoading,
}: {
  amd?: { username: string; officeCode: number }
  isLoading: boolean
}) => {
  const initialValues = amd
    ? { ...amd, officeCode: amd.officeCode?.toString(), password: '' }
    : {
        username: '',
        password: '',
        officeCode: '',
      }

  const { mutate, isLoading: isConnecting } = useMutation(connectAmd, {
    onSuccess: () => notification('Successfully connected to AdvancedMD.'),
    onError: () =>
      notification(
        'Error: Could not connect to AdvancedMD; please check your credentials and try again.',
        'failure'
      ),
  })

  const fieldTypes = [
    {
      label: 'Username',
      name: 'username',
      type: 'input',
      required: true,
      validationMessage: 'Username is a required field.',
      className: 'top-ant-form-item',
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      required: true,
      validationMessage: 'Password is a required field.',
      className: 'top-ant-form-item',
    },
    {
      label: 'Office code',
      name: 'officeCode',
      type: 'input',
      required: true,
      pattern: new RegExp(/\d+/),
      validationMessage: 'Office code is a required field.',
      className: 'top-ant-form-item',
    },
  ]

  const onSubmit = React.useCallback(
    ({ username, password, officeCode }: AmdInput) => {
      mutate({
        username: username.trim(),
        password: password,
        officeCode: Number(officeCode),
      })
    },
    [mutate]
  )

  if (isLoading && !amd?.username) return <Spinner />

  const button = (
    <Button
      className="advancedmd__save-button"
      loading={isLoading || isConnecting}
      type="primary"
      form="amdForm"
      htmlType="submit"
    >
      {amd?.username ? 'Update' : 'Save'}
    </Button>
  )

  return (
    <div className="advancedmd__form">
      <Form
        fields={fieldTypes}
        layout="vertical"
        handleSubmit={onSubmit}
        formName="amdForm"
        size="middle"
        initialValues={initialValues}
        key="amdModal-form"
      />

      {button}
    </div>
  )
}

export default AmdForm
