import { AllergyHistory } from '../../../../../../../shared-types'
import { Table } from '../../../../../../../stories/BaseComponents'
import {
  AllergyData,
  getColumns,
  serializeAllergyData,
} from './AllergiesBlockTypes'

import styles from '../../../styles.module.scss'

export const AllergiesBlockTable = ({
  blockData,
  isSigned,
  patientName,
  isLoading,
}: {
  blockData: AllergyHistory[] | undefined
  isSigned: boolean
  patientName: string
  isLoading: boolean
}) => {
  const columns = getColumns({ isSigned })
  const data: AllergyData[] = serializeAllergyData(blockData)

  return (
    <Table
      addClassNames={[
        'no-padding',
        'no-table-tools-section',
        'alternate-colors',
        styles.tableBlock,
      ]}
      rowKey={'key'}
      columns={columns}
      dataSource={data}
      loading={isLoading}
      pagination={false}
      locale={{
        emptyText: isLoading ? (
          'Loading'
        ) : (
          <div className={styles['empty-state']}>
            {patientName} has no active allergies recorded
          </div>
        ),
      }}
    />
  )
}
