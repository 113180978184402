import React from 'react'

import _ from 'lodash'
import _isEqual from 'lodash/isEqual'
import { Col } from 'react-bootstrap'

import FormMachine from '../FormMachine'
import { MultipleSaveFieldProps } from './types'

export default function MultipleSaveField(props: MultipleSaveFieldProps) {
  const { fields = [], id, styling, templateFields } = props

  if (!Array.isArray(fields)) return null

  return (
    <Col>
      {fields.map((field: object, index: number) => {
        return (
          <React.Fragment key={`${id}-${index}`}>
            <FormMachine
              handleFieldChange={_.noop}
              storedFields={field}
              template={templateFields}
              isEditing={false}
              styling={{ ...styling, formRowClassName: 'bottom-line' }}
            />
          </React.Fragment>
        )
      })}
    </Col>
  )
}
