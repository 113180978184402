import {
  Skeleton as AntDSkeleton,
  SkeletonProps as AntDSkeletonProps,
} from 'antd'

export enum StandardSkeletonRows {
  fullPage = 19,
}

export type SkeletonProps = {
  active?: AntDSkeletonProps['active']
  title?: AntDSkeletonProps['title']
  paragraph?: AntDSkeletonProps['paragraph']
  avatar?: AntDSkeletonProps['avatar']
  round?: AntDSkeletonProps['round']
  style?: AntDSkeletonProps['style']
}

export const Skeleton = ({
  active,
  title,
  paragraph,
  style,
}: SkeletonProps) => (
  <AntDSkeleton
    active={active}
    title={title}
    paragraph={paragraph}
    style={style}
  />
)
