import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import _isEqual from 'lodash/isEqual'

import {
  submitEditImmunizationsRequest,
  submitNewImmunizationsRequest,
} from '../../../api/api-lib'
import GenericAccordion from '../../../components/Accordions/GenericAccordion'
import PatientProfileButtons from '../../../components/Buttons/PatientProfileButtons'
import FormMachine from '../../../components/Forms/FormMachine/FormMachine'
import { onError } from '../../../libs/errorLib'
import { useFormFields } from '../../../libs/hooksLib'
import { notification } from '../../../libs/notificationLib'
import {
  ImmunizationRequest,
  ImmunizationsResponse,
} from '../../../shared-types'
import { patientDataTypes } from '../patient-data-types'
import { styling, template } from './template'

import '../PatientProfile.scss'

interface ImmunizationsProps {
  handleApiChange: (queryName: string[]) => void
  response: ImmunizationsResponse
  patientId: string
}

export default function Immunizations(props: ImmunizationsProps) {
  const [copy, setCopy] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [storedFields, handleFieldChange] = useFormFields({
    ...props.response,
  })

  function copyStoredFields() {
    // recursively clone the fields so that even nested data in the immunizations list reverts back to it's original form if cancel editing
    setCopy(_.cloneDeep(storedFields))
  }

  function switchToEdit() {
    copyStoredFields()
    setIsEditing(true)
  }

  function switchToSave() {
    setIsEditing(false)
  }

  useEffect(() => {
    copyStoredFields()
  }, [])

  async function handleSubmit(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()

    try {
      setIsLoading(true)
      storedFields.immunizations = storedFields.immunizations || []
      for (const immunization of storedFields.immunizations) {
        // default all immunization notes to an empty string if no notes are recorded
        if (!immunization.notes) {
          immunization.notes = ''
        }
      }

      const data: ImmunizationRequest = {
        patientId: props.patientId,
        immunizations: storedFields.immunizations,
        additionalNotes: storedFields.additionalNotes || '',
      }

      if (props.response.createdAt) {
        await submitEditImmunizationsRequest(data)
      } else {
        await submitNewImmunizationsRequest(data)
      }
      props.handleApiChange([
        patientDataTypes.ProviderSidePatientData,
        props.patientId,
      ])
      notification(
        'You have successfully updated the Immunizations section on the patient.',
        'success'
      )
      copyStoredFields()
      setIsLoading(false)
      switchToSave()
    } catch (error) {
      console.log(error)
      onError(
        error,
        500,
        "Failed to update the patient's immunizations information. Please inform your administrator."
      )
      setIsLoading(false)
    }
  }

  function handleMultipleSubmit(field: object | number, id: string) {
    let newValue = []

    if (typeof field === 'object') {
      newValue = storedFields[id] ? [...storedFields[id], field] : [field]
    } else {
      storedFields[id].splice(field, 1)
      newValue = storedFields[id]
    }

    handleFieldChange({
      target: {
        id: id,
        value: newValue,
      },
    })
  }

  function cancelEditing() {
    handleFieldChange(copy, false)
    switchToSave()
  }

  return (
    <GenericAccordion title="Immunizations">
      <FormMachine
        storedFields={storedFields}
        template={template}
        handleFieldChange={handleFieldChange}
        handleMultipleSubmit={handleMultipleSubmit}
        isEditing={isEditing}
        styling={styling}
      />
      <PatientProfileButtons
        cancelEditing={cancelEditing}
        isDisabled={false}
        isEditing={isEditing}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        switchToEdit={switchToEdit}
      />
    </GenericAccordion>
  )
}
