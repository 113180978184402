import { EventProps, View } from 'react-big-calendar'

import { RoomItem } from '../../../../containers/Authentication/Locations'
import EventItem from '../../CalendarViews/Components/EventItem'
import { CustomCalendarProps } from '../CustomCalendar'

export type EventCellProps = Pick<EventProps, 'event'> &
  Pick<
    CustomCalendarProps,
    'colorMap' | 'isDev' | 'patientList' | 'currentTimezone'
  > & { rooms: RoomItem[]; reactBigCalendarView: View } & NonNullable<
    Pick<CustomCalendarProps, 'locations'>
  >

export const EventCell = ({
  event,
  colorMap,
  isDev,
  rooms,
  locations,
  patientList,
  currentTimezone,
  reactBigCalendarView,
}: EventCellProps) => {
  const { Room: roomId = null } = event?.resource ?? {}

  let roomLocationName = ''
  const room = rooms.find((room) => room.roomId === roomId)
  if (room) {
    roomLocationName = ''
    const { roomName, locationId } = room
    const location = locations.find(
      (location) => location.LocationId === locationId
    )
    roomLocationName = !location
      ? roomName
      : `${location.LocationName} ${roomName}`
  }

  return (
    <EventItem
      colorMap={colorMap}
      currentView={reactBigCalendarView}
      event={event}
      isDev={isDev}
      patientList={patientList}
      roomLocationName={roomLocationName}
      timezone={currentTimezone}
    />
  )
}
