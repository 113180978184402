import { NoteTypes } from '../../../../../shared-types'

/**
 * This function converts weight from 'lb' to 'kg' if the weightUnit is 'lb'.
 * @param weight - weight of the patient
 * @param weightUnit - unit of the weight. 'lb' will be converted to 'kg',
 * all other units will be returned as is. Ideally this would be typed as 'kg' or 'lb' instead
 * of string, but cannot restrict typing given legacy code assumes string.
 * @returns converted weight in 'kg' as a string with 2 decimal places
 */
export const convertWeightToKgs = ({
  weight,
  weightUnit,
}: {
  weight: number
  weightUnit: string
}): string => {
  const convertedWeight =
    weightUnit.toLowerCase() === 'lb' ? weight * 0.453592 : weight
  return convertedWeight.toFixed(2)
}

/**
 * This function determines the weight to be used when creating a note based on the note type.
 * @description
 * Different note types assume different starting weight units (kg vs. lb),
 * and because weight units are not captured in a saved clinical note,
 * we need to convert the weight to the appropriate value based on
 * note type prior to creation of the note.
 * NOTE: Ketamine specific note types set the 'KetamineUnits' on create,
 * so only need to convert to kgs in non-ketamine note types.
 * @param weight - weight of the patient
 * @param weightUnit - unit of the weight. Ideally this would be typed as 'kg' or 'lb' instead
 * of string, but cannot restrict typing given legacy code assumes string.
 * @param noteType - type of note
 * @returns weight to be used in the note as a string
 */
export const getWeightForNoteType = ({
  weight,
  weightUnit,
  noteType,
}: {
  noteType: NoteTypes
  weight: number | '' | null | undefined
  weightUnit: string
}): string => {
  // If weight is falsy, but not 0, return empty string
  if (weight === null || weight === undefined || weight === '') {
    return ''
  }
  if (
    [NoteTypes.IM_KETAMINE, NoteTypes.IV_KETAMINE, NoteTypes.KAP].includes(
      noteType
    )
  ) {
    return weight.toString()
  }
  return convertWeightToKgs({ weight: Number(weight), weightUnit })
}
