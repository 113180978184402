import React, { useEffect, useState } from 'react'

import { Modal as AntDModal } from 'antd'

import { saveCareTeam } from '../../../src/api/api-lib'
import { notification } from '../../../src/libs/notificationLib'
import { Teammate } from '../../shared-types'
import Modal from '../BaseComponents/Modal'
import MultiSelect, { OptionInput } from '../BaseComponents/MultiSelect'
import Select, { DefaultOptionType } from '../BaseComponents/Select'

import './CareTeamSelection.scss'

interface CareTeamSelectionProps {
  patientId: string
  teammates: Teammate[]
  teammatesToDisplay?: string[]
  primaryProvider?: string
  careTeam?: string[]
  updateCareTeam: Function
}

const CareTeamSelection: React.FC<CareTeamSelectionProps> = ({
  patientId,
  teammates,
  teammatesToDisplay,
  primaryProvider,
  careTeam,
  updateCareTeam,
}) => {
  const [patient, setPatient] = useState<string>('')
  const [allTeammates, setAllTeammates] = useState<Teammate[]>([])
  const [displayTeammates, setDisplayTeammates] = useState<string[]>([])
  const [primaryProv, setPrimaryProv] = useState<string | undefined>()
  const [primaryProvOptions, setPrimaryProvOptions] = useState<
    DefaultOptionType[]
  >([])
  const [careTeamOptions, setCareTeamOptions] = useState<OptionInput[]>([])
  const [careTeammates, setCareTeammates] = useState<string[] | undefined>([])
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setPatient(patientId)
    setAllTeammates(teammates)
    setDisplayTeammates(teammatesToDisplay ?? [])
    setPrimaryProv(primaryProvider ?? '')
    setCareTeammates(careTeam ?? [])
  }, [patientId, teammates, teammatesToDisplay])

  useEffect(() => {
    const options = teammates
      .filter(
        (tm) =>
          !careTeammates?.includes(tm.cognitoId) && tm.isDeactivated === false
      )
      .map((tm: any) => {
        let providerFullName
        if (tm.name && tm.credential) {
          providerFullName = `${tm.name}, ${tm.credential}`
        } else if (tm.credential) {
          providerFullName = `${tm.email}, ${tm.credential}`
        } else if (tm.name) {
          providerFullName = `${tm.name}`
        } else {
          providerFullName = tm.email
        }
        return {
          label: providerFullName,
          value: tm.cognitoId,
        }
      })
    setPrimaryProvOptions(options)
  }, [careTeammates])

  useEffect(() => {
    const options = teammates
      .filter((tm) => tm.cognitoId != primaryProv && tm.isDeactivated === false)
      .map((tm: any) => {
        let providerFullName
        if (tm.name && tm.credential) {
          providerFullName = `${tm.name}, ${tm.credential}`
        } else if (tm.credential) {
          providerFullName = `${tm.email}, ${tm.credential}`
        } else if (tm.name) {
          providerFullName = `${tm.name}`
        } else {
          providerFullName = tm.email
        }
        return {
          label: providerFullName,
          value: tm.cognitoId,
        }
      })
    setCareTeamOptions(options)
  }, [primaryProv])

  async function handleOk() {
    setIsLoading(true)
    try {
      const data = {
        primaryProvider: primaryProv ? primaryProv : undefined,
        careTeam: careTeammates,
        patientId: patient,
      }
      const newCareTeam: any = await saveCareTeam(data)
      await updateCareTeam(patientId, newCareTeam)
      notification(
        `You have successfully updated the patient's Care Team.`,
        'success'
      )
      setShowEditModal(false)
    } catch (e) {
      console.log('Error: ', e.message)
      notification(
        `There was a problem saving the patient's Care Team.`,
        'failure'
      )
    } finally {
      setIsLoading(false)
    }
  }

  function textToDisplay() {
    if (!displayTeammates?.length) {
      return 'Add care team'
    }
    const cognitoIdToDisplay = displayTeammates[0]
    const [teammateInfo] = allTeammates.filter(
      (aT) => aT.cognitoId === cognitoIdToDisplay
    )
    if (!teammateInfo) {
      return 'Add care team'
    }
    return teammateInfo.name ? teammateInfo.name : teammateInfo.email
  }

  function plusCareTeam() {
    if (!displayTeammates || displayTeammates.length < 2) {
      return
    }
    return (
      <span className="action" onClick={() => setShowEditModal(true)}>
        {` + ${displayTeammates.length - 1} Others`}
      </span>
    )
  }

  const onCancel = () => {
    const noChangesMade =
      primaryProvider === primaryProv && careTeam === careTeammates

    if (noChangesMade) {
      return setShowEditModal(false)
    }

    AntDModal.confirm({
      title: 'Unsaved changes',
      centered: true,
      content:
        'Are you sure you want to close this window? Any unsaved changes will be lost.',
      onOk() {
        setPrimaryProv(primaryProvider ?? '')
        setCareTeammates(careTeam ?? [])
        setShowEditModal(false)
      },
    })
  }

  return (
    <div>
      <span
        className="action careteam-action"
        onClick={() => setShowEditModal(true)}
      >
        {textToDisplay()}
      </span>
      {plusCareTeam()}
      <Modal
        visible={showEditModal}
        title="Edit Care Team"
        onCancel={onCancel}
        onOk={handleOk}
        closable={true}
        hasOkButton={true}
        hasCloseButton={true}
        cancelText="Cancel"
        confirmLoading={isLoading}
      >
        <div>
          <div>Select users that you’d like to be part of this care team:</div>
          <div className="div-margin-top">Primary clinician</div>
          <Select
            allowClear={true}
            value={primaryProv}
            className="select-primary-provider"
            onChange={(value) => setPrimaryProv(value?.toString())}
            placeholder="Assign primary clinician"
            dropdownAlign={{ overflow: { adjustY: 0 } }}
            options={primaryProvOptions}
          >
            <span></span>
          </Select>
          <div className="div-margin-top">
            {'Additional care team members '}
            <span className="optional-text">(optional)</span>
          </div>
          <MultiSelect
            value={careTeammates}
            handleChange={(value) => setCareTeammates(value)}
            placeholder="Assign care team"
            // maxTagCount={10}
            // Filter out selected primaryprovider from the care team list
            dropdownAlign={{ overflow: { adjustY: 0 } }}
            options={careTeamOptions}
          />
        </div>
      </Modal>
    </div>
  )
}

export default CareTeamSelection
