import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { getLabsResults } from '../../api/labs'

export enum QueryKeys {
  LABSRESULTS = 'LABSRESULTS',
}

export const usePatientLabsResults = (
  patientId: string
): UseQueryResult<any> => {
  return useQuery<any[]>([QueryKeys.LABSRESULTS, patientId], {
    queryFn: async () => getLabsResults(patientId),
    retry: false,
    refetchOnWindowFocus: false,
  })
}
