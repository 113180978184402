import React from 'react'

// eslint-disable-next-line import/no-unresolved
import { SendbirdChatType } from '@sendbird/uikit-react/types/lib/types'
import { useQueryClient } from '@tanstack/react-query'

import { UnreadMessageCountsContext } from '.'
import {
  persistMessagesAsRead,
  persistMessagesAsUnread,
} from '../../../libs/sendbird/sendbird'
import { PROVIDER_MESSAGE } from './constants'
import { IncrementMessageCountForPatientFn } from './types'
import {
  incrementCountAction,
  initialState_useAllSendbirdChannelsForUserId,
  markAsReadAction,
  markAsUnreadAction,
  updateUnreadMessageCountsAction,
  useAllSendbirdChannelsForUserIdInitialStateReducer,
} from './useAllSendbirdChannelsForUserIdReducer'
import {
  GET_ALL_SENDBIRD_CHANNELS_FOR_USER_ID_QUERY_KEY,
  useQueryForAllSendbirdChannelsForUserId,
} from './useQueryForAllSendbirdChannelsForUserId'

export const useAllSendbirdChannelsForUserId = ({
  sendbirdSdk,
  isSecureMessagingEnabled,
  sendbirdUserId,
}: {
  sendbirdSdk: SendbirdChatType
  isSecureMessagingEnabled: boolean
  sendbirdUserId: string
}) => {
  const queryClient = useQueryClient()
  const [state, dispatch] = React.useReducer(
    useAllSendbirdChannelsForUserIdInitialStateReducer,
    initialState_useAllSendbirdChannelsForUserId
  )

  /**
   * Invalidate cache for group channels data
   * - Will trigger a refetch
   * - Refetch will be based on useQuery staletime
   *
   * Improvement Idea: instead of fetching all the channels, consider fetching/updating only the target channel
   *   - Come up with a reasonable way to ensure all messages are cached/updated/cache-invalidated appropriately
   */
  const invalidateGetSendbirdChannelsForUserId = () =>
    queryClient.invalidateQueries({
      queryKey: [
        GET_ALL_SENDBIRD_CHANNELS_FOR_USER_ID_QUERY_KEY,
        sendbirdUserId,
        isSecureMessagingEnabled,
        !!sendbirdSdk?.groupChannel,
      ],
    })

  const { data, isFetching, isLoading, isError, error } =
    useQueryForAllSendbirdChannelsForUserId({
      sendbirdUserId,
      sendbirdSdk,
      isSecureMessagingEnabled,
    })

  React.useEffect(() => {
    dispatch(
      updateUnreadMessageCountsAction(
        new Map(
          data?.unreadMessageCounts?.size
            ? data?.unreadMessageCounts
            : undefined
        )
      )
    )
  }, [data?.unreadMessageCounts])

  // Clears mutations when sendbirdUserId changes
  React.useEffect(() => {
    dispatch(
      updateUnreadMessageCountsAction(
        new Map(
          data?.unreadMessageCounts?.size
            ? data?.unreadMessageCounts
            : undefined
        )
      )
    )
  }, [sendbirdUserId])

  const markAsUnreadForPatient: UnreadMessageCountsContext['markAsUnreadForPatient'] =
    async (patientChannelUrl) => {
      if (!patientChannelUrl) {
        return
      }
      // update in-memory state
      dispatch(markAsUnreadAction(patientChannelUrl))
      invalidateGetSendbirdChannelsForUserId()
      await persistMessagesAsUnread(patientChannelUrl)
    }
  const markAsReadForPatient: UnreadMessageCountsContext['markAsReadForPatient'] =
    async (patientChannelUrl) => {
      if (!patientChannelUrl) {
        return
      }
      // update in-memory state
      dispatch(markAsReadAction(patientChannelUrl))
      invalidateGetSendbirdChannelsForUserId()
      await persistMessagesAsRead(patientChannelUrl)
    }
  const incrementMessageCountForPatient: IncrementMessageCountForPatientFn = (
    patientChannelUrl,
    { messageCustomType }
  ) => {
    if (!patientChannelUrl || messageCustomType === PROVIDER_MESSAGE) {
      return
    }
    // update in-memory state
    dispatch(incrementCountAction(patientChannelUrl))
  }

  return {
    unreadMessageCounts: state.unreadMessageCounts,
    error,
    isFetching,
    isLoading,
    isError,
    markAsUnreadForPatient,
    markAsReadForPatient,
    // internal use, not exported in Context
    _incrementMessageCountForPatient: incrementMessageCountForPatient,
  }
}
