import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import 'moment-timezone'
import { Col, Form } from 'react-bootstrap'
import 'react-dates/initialize'

import { getLocation, getTeammateData } from '../../../../api/api-lib'
import { useRooms } from '../../../../hooks/useBillingInfo'
import { useFeatureFlags } from '../../../../libs/featureFlags'
import { Spinner } from '../../../../stories/BaseComponents'
import { getTextToDisplay } from '../../../Scheduling/utils/providers-name-text-display'
import { generateSpravatoClassName } from './NoteType/Spravato/Spravato'

import '../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function ProvidersInfo({ disabled, fields, handleFieldChange }) {
  /** NOTE: Should move all these into react query. They're slow here. */
  const [roomOptions, setRoomOptions] = useState(null)
  const [teamOptions, setTeamOptions] = useState(null)

  const { claims } = useFeatureFlags()

  const { data: rooms, isLoading: roomsLoading } = useRooms()

  const { data: locations, isLoading: locationsLoading } = useQuery(
    ['locations'],
    getLocation
  )
  const { data: teammates, isLoading: teamLoading } = useQuery(
    ['teammates'],
    getTeammateData
  )

  useEffect(() => {
    if (!locations?.length || !rooms?.length) {
      return
    }

    const roomOptionComponents = []
    let numberOfOptions = 0

    const filteredLocations = locations.filter(
      ({ LocationName = false, LocationId = false, deleted = false }) =>
        Boolean(!deleted && LocationId && LocationName)
    )

    filteredLocations.forEach(
      ({ LocationName: locationName = '', LocationId: locationId = '' }) => {
        roomOptionComponents.push(
          <option
            disabled
            key={numberOfOptions}
            name={locationName}
            value={locationId}
          >
            {locationName}
          </option>
        )
        numberOfOptions++

        const roomsInLocation = rooms.filter(
          ({
            locationId: roomLocationId,
            roomName = false,
            roomId = false,
            deleted = false,
          }) =>
            Boolean(!deleted && roomId && roomName) &&
            roomLocationId === locationId
        )

        roomsInLocation.forEach(({ roomName, roomId }) => {
          roomOptionComponents.push(
            <option key={numberOfOptions} name={roomName} value={roomId}>
              {roomName}
            </option>
          )
          numberOfOptions++
        })
      }
    )
    setRoomOptions(roomOptionComponents)
  }, [disabled, locations, rooms])

  useEffect(() => {
    if (!teammates?.length) {
      return
    }

    const teamOptionComponents = []
    let numberOfOptions = 0
    for (const t of teammates) {
      if (t?.roleTitles?.some((role) => role.label === 'Provider')) {
        teamOptionComponents.push(
          <option
            key={numberOfOptions}
            name={getTextToDisplay(t.cognitoId, teammates)}
            value={t.cognitoId}
            disabled={t.isDeactivated || t.lastActive === 'never_logged_in'}
          >
            {getTextToDisplay(t.cognitoId, teammates)}
          </option>
        )
      }

      numberOfOptions++
    }
    setTeamOptions(teamOptionComponents)
  }, [teammates])

  return (
    <>
      <Form.Group as={Col} sm={6} md={5} lg={5} controlId="RenderingProvider">
        <Form.Label style={{ fontWeight: 'bold' }}>
          Rendering Provider
        </Form.Label>
        {!teamOptions?.length && teamLoading ? (
          <Spinner
            fontSize={20}
            style={{ display: 'block', textAlign: 'left' }}
          />
        ) : (
          <Form.Control
            bsPrefix={
              fields.NoteType === 'Spravato' && fields.RenderingProvider === ''
                ? generateSpravatoClassName(true, true)
                : 'form-input'
            }
            as="select"
            disabled={disabled}
            onChange={(e) =>
              handleFieldChange({
                ...fields,
                RenderingProvider: e.target.value,
              })
            }
            value={fields.RenderingProvider}
          >
            <option disabled key={0} name="Select One" value="">
              Select one
            </option>
            {teamOptions}
          </Form.Control>
        )}
      </Form.Group>
      <Form.Group as={Col} sm={6} md={5} lg={5} controlId="Rooms">
        <Form.Label style={{ fontWeight: 'bold' }}>Room</Form.Label>
        {!roomOptions?.length && (locationsLoading || roomsLoading) ? (
          <Spinner
            fontSize={20}
            style={{ display: 'block', textAlign: 'left' }}
          />
        ) : (
          <Form.Control
            bsPrefix="form-input"
            as="select"
            disabled={disabled}
            onChange={(e) =>
              handleFieldChange({
                ...fields,
                Rooms: e.target.value,
              })
            }
            value={fields.Rooms}
          >
            <option key={0} name="Select One" value="" disabled>
              Select one
            </option>
            {roomOptions}
          </Form.Control>
        )}
      </Form.Group>
      {claims && (
        <Form.Group as={Col} sm={3} md={2} lg={2} controlId="BillingType">
          <Form.Label style={{ fontWeight: 'bold' }}>Billing type</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="select"
            disabled={disabled}
            onChange={(e) =>
              handleFieldChange({
                ...fields,
                BillingType: e.target.value,
              })
            }
            value={fields.BillingType}
          >
            <option key={0} name="Select One" value="" disabled>
              Select one
            </option>
            <option key={1} name="In network" value="IN_NETWORK">
              In network
            </option>
            <option key={2} name="Private pay" value="PRIVATE_PAY">
              Private pay
            </option>
          </Form.Control>
        </Form.Group>
      )}
    </>
  )
}
