import { Button } from '../../../../stories/BaseComponents'

export interface FooterProps {
  isLoading: boolean
  isSendingEmail: boolean
  handleCancel: () => void
  sendDeclineAppointment: (sendEmail: boolean) => void
}
export function Footer({
  isLoading,
  isSendingEmail,
  handleCancel,
  sendDeclineAppointment,
}: FooterProps) {
  return (
    <>
      <Button disabled={isLoading} key="cancel-decline" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        disabled={isLoading}
        loading={isLoading && !isSendingEmail}
        key="decline-not-send-email"
        onClick={() => sendDeclineAppointment(false)}
      >
        Don’t send
      </Button>
      <Button
        disabled={isLoading}
        loading={isLoading && isSendingEmail}
        key="decline-send-email"
        type="primary"
        onClick={() => sendDeclineAppointment(true)}
      >
        Send
      </Button>
    </>
  )
}
