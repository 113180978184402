import React from 'react'

import 'moment-timezone'
import { Button, Col, Form, Table } from 'react-bootstrap'
import 'react-dates/initialize'

import { NoteTypes } from '../../../../../shared-types'
import CustomTimePicker from '../../../../../stories/BaseComponents/CustomTimePicker'
import { AutoSizingTextArea } from '../../../../../stories/BaseComponents/TextAreaInput'
import ShowSavedVS from './../Tables/ShowSavedVS'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function Discharge(props) {
  function handleShowSavedDischargeVitals() {
    if (
      props.fields.SavedDischargeVitalSigns &&
      Object.keys(props.fields.SavedDischargeVitalSigns).length !== 0
    ) {
      return (
        <>
          <ShowSavedVS
            key={0}
            index={0}
            deleteVitals={props.deleteVitals}
            VitalSignsType={
              props.fields.SavedDischargeVitalSigns.VitalSignsType
            }
            Time={props.fields.SavedDischargeVitalSigns.Time}
            HR={props.fields.SavedDischargeVitalSigns.HR}
            BP={props.fields.SavedDischargeVitalSigns.BP}
            RR={props.fields.SavedDischargeVitalSigns.RR}
            SPO2={props.fields.SavedDischargeVitalSigns.SPO2}
            Comments={props.fields.SavedDischargeVitalSigns.Comments}
            EKG={props.fields.SavedDischargeVitalSigns.EKG}
            RASSscore={props.fields.SavedDischargeVitalSigns.RASSscore}
            saveEditVitalSigns={props.saveEditDischargeVitalSigns}
            disabled={props.disabled}
            discharge
          />
        </>
      )
    }
  }

  return (
    <>
      <Table responsive>
        {!(Object.keys(props.fields.SavedDischargeVitalSigns).length === 0) && (
          <thead>
            <tr>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '140px',
                }}
              >
                Time
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                HR
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                BP
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                SpO2
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                EKG
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                RR
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                RASS
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '360px',
                }}
              >
                Comments
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '112px',
                }}
              >
                &nbsp;
              </th>
            </tr>
          </thead>
        )}
        <tbody>{handleShowSavedDischargeVitals()}</tbody>
      </Table>

      {Object.keys(props.fields.SavedDischargeVitalSigns).length !== 0 && (
        <hr className="style-one" />
      )}

      {!props.disabled &&
        Object.keys(props.fields.SavedDischargeVitalSigns).length === 0 && (
          <Button
            bsPrefix="center plain-button info button-label"
            onClick={() => {
              props.saveNewDischargeVitals()
              props.setAddDischargeVitals(true)
            }}
          >
            + Discharge Vitals
          </Button>
        )}

      <Form.Row>
        <Col className="center">
          <Form.Text
            style={{ fontWeight: 'bold', color: '#A9A9A9' }}
            className="hideDuringPrint"
          >
            Small screens may need to scroll horizontally for a full view of
            medication and vitals.
          </Form.Text>
        </Col>
      </Form.Row>
      <hr />
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginTop: '15px', marginLeft: '15px' }}
          controlId="SpontaneousVentilation"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Spontaneous ventilation/respirations maintained throughout
            treatment:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.SpontaneousVentilation)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="SpontaneousVentilationYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                SpontaneousVentilation: e.target.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.SpontaneousVentilation)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="SpontaneousVentilationNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                SpontaneousVentilation: e.target.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="UneventfulInfusionAndRecovery"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Uneventful{' '}
            {props.template === NoteTypes.IV_KETAMINE
              ? 'infusion'
              : props.template === NoteTypes.IM_KETAMINE
              ? 'injection'
              : 'treatment'}{' '}
            and recovery:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.UneventfulInfusionAndRecovery)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="UneventfulInfusionAndRecoveryYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                UneventfulInfusionAndRecovery: e.target.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.UneventfulInfusionAndRecovery)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="UneventfulInfusionAndRecoveryNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                UneventfulInfusionAndRecovery: e.target.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="PatientAlertAndResponsive"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Patient alert and responsive:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.PatientAlertAndResponsive)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="PatientAlertAndResponsiveYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientAlertAndResponsive: e.target.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.PatientAlertAndResponsive)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="PatientAlertAndResponsiveNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientAlertAndResponsive: e.target.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="PatientMetalStatus"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Patient at baseline mental status:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.PatientMetalStatus)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="PatientMetalStatusYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientMetalStatus: e.target.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.PatientMetalStatus)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="PatientMetalStatusNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientMetalStatus: e.target.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="PatientDeniesSuicidalIdeation"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Patient denies suicidal ideation:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.PatientDeniesSuicidalIdeation)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="PatientDeniesSuicidalIdeationYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientDeniesSuicidalIdeation: e.target.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.PatientDeniesSuicidalIdeation)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="PatientDeniesSuicidalIdeationNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientDeniesSuicidalIdeation: e.target.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="Nausea"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Nausea:</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.Nausea)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="NauseaYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                Nausea: e.target.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.Nausea)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="NauseaNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                Nausea: e.target.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="VitalsStable"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Vitals stable:</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.VitalsStable)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="VitalsStableYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                VitalsStable: e.target.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.VitalsStable)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="VitalsStableNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                VitalsStable: e.target.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="Ambulation"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Ambulation:</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.Ambulation)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="AmbulationYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                Ambulation: e.target.value,
              })
            }
            type="radio"
            label="Requires assistance"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.Ambulation)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="AmbulationNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                Ambulation: e.target.value,
              })
            }
            type="radio"
            label="Walking unassisted"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="DischargeInstruccionsReviewed"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Discharge instructions reviewed with patient + provided copy:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.DischargeInstruccionsReviewed)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="DischargeInstruccionsReviewedYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                DischargeInstruccionsReviewed: e.target.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.DischargeInstruccionsReviewed)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="DischargeInstruccionsReviewedNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                DischargeInstruccionsReviewed: e.target.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="ResponsibleAdultEscort"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Responsible adult escort. Discharged home with driver:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.ResponsibleAdultEscort)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="ResponsibleAdultEscortYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                ResponsibleAdultEscort: e.target.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.ResponsibleAdultEscort)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="ResponsibleAdultEscortNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                ResponsibleAdultEscort: e.target.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          lg={12}
          style={{ marginLeft: '15px' }}
          controlId="DischargeNotes"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Discharge Notes
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.DischargeNotes}
            placeholder={props.detail ? '' : 'E.g. discharged to, by whom'}
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                DischargeNotes: e.target.value,
              })
            }
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Form.Row>
      <Form.Group as={Col} sm={12} md={6}>
        <Form.Label style={{ fontWeight: 'bold' }}>
          Treatment end time/Discharge time
        </Form.Label>
        <CustomTimePicker
          value={props.fields.DischargeTime}
          onChange={(value) => {
            props.handleFieldChange({ ...props.fields, DischargeTime: value })
          }}
          disabled={props.disabled}
        />
      </Form.Group>
    </>
  )
}
