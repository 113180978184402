import cn from 'classnames'

import styles from './IndicatorWrapper.module.scss'

type MenuIconProps = {
  label?: never
  hasIndicator: boolean
  indicatorTestId: string
  icon: JSX.Element
  isCollapsed: boolean
}

type MenuLabelProps = {
  label: JSX.Element
  hasIndicator: boolean
  indicatorTestId: string
  icon?: never
  isCollapsed: boolean
}

type IndicatorWrapperProps = MenuIconProps | MenuLabelProps

export const IndicatorWrapper = (props: IndicatorWrapperProps) => {
  if (props.icon) {
    const { isCollapsed, icon, hasIndicator, indicatorTestId } = props

    // Do not show indicator on the icon if the menu is not collapsed
    if (!isCollapsed) {
      return icon
    }
    return (
      <div className={styles.container}>
        {icon}
        {hasIndicator && (
          <div
            data-testid={indicatorTestId}
            role="alert"
            className={styles.statusDot}
          />
        )}
      </div>
    )
  }

  const { hasIndicator, indicatorTestId, label, isCollapsed } = props

  return (
    <div className={cn(styles.container, { [styles.isHidden]: isCollapsed })}>
      <div className={styles.labelContainer}>{label}</div>
      {hasIndicator && (
        <div
          data-testid={indicatorTestId}
          role="alert"
          className={styles.statusDot}
        />
      )}
    </div>
  )
}
