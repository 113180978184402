import {
  MyMemberStateFilter,
  UnreadChannelFilter,
} from '@sendbird/chat/groupChannel'
// eslint-disable-next-line import/no-unresolved
import { SendbirdChatType } from '@sendbird/uikit-react/types/lib/types'
import { useQuery } from '@tanstack/react-query'

import { simpleLoggerWithPrefixFactory } from '../../../libs/sendbird/simpleLogger'
import { UnreadMessageCountsMap } from './types'

export const GET_ALL_SENDBIRD_CHANNELS_FOR_USER_ID_QUERY_KEY =
  'getAllSendbirdChannelsForUserId'

const queryForAllChannelsForUserIdLogger = simpleLoggerWithPrefixFactory(
  'SendbirdQueryForAllChannelsForUserId'
)

export const useQueryForAllSendbirdChannelsForUserId = ({
  sendbirdUserId,
  sendbirdSdk,
  isSecureMessagingEnabled,
}: {
  sendbirdUserId: string
  sendbirdSdk: SendbirdChatType
  isSecureMessagingEnabled: boolean
}) =>
  useQuery({
    // queryKey includes dependent variables (similar to useEffect dependencies)
    queryKey: [
      GET_ALL_SENDBIRD_CHANNELS_FOR_USER_ID_QUERY_KEY,
      sendbirdUserId,
      isSecureMessagingEnabled,
      !!sendbirdSdk?.groupChannel,
    ],
    queryFn: async (): Promise<{
      unreadMessageCounts: UnreadMessageCountsMap
    }> => {
      const unreadMessageCounts = new Map()

      queryForAllChannelsForUserIdLogger.info(
        'Running useQuery function...',
        [
          GET_ALL_SENDBIRD_CHANNELS_FOR_USER_ID_QUERY_KEY,
          sendbirdUserId,
          isSecureMessagingEnabled,
          !!sendbirdSdk?.groupChannel,
        ],
        { sendbirdUserId }
      )

      if (
        // abort if secure messaging is not enabled
        !isSecureMessagingEnabled ||
        // abort if sendbird SDK is not yet loaded
        !sendbirdSdk?.groupChannel
      ) {
        return { unreadMessageCounts }
      }

      const listQuery = sendbirdSdk.groupChannel.createMyGroupChannelListQuery({
        includeEmpty: true,
        // includeFrozen: false,
        includeMetaData: false,
        unreadChannelFilter: UnreadChannelFilter.UNREAD_MESSAGE,
        myMemberStateFilter: MyMemberStateFilter.JOINED,
        /**
         * Max limit is 100, per Sendbird docs
         * @see https://sendbird.com/docs/chat/sdk/v3/javascript/guides/group-channel
         */
        limit: 100,
      })

      while (listQuery.hasNext) {
        try {
          queryForAllChannelsForUserIdLogger.info(
            'Querying batch of channels...',
            { sendbirdUserId }
          )
          const groupChannels = await listQuery.next()
          for (const { data, url, unreadMessageCount } of groupChannels) {
            const { unread } = data ? JSON.parse(data) : { unread: undefined }
            // idea: consider only setting if there's a valid url
            unreadMessageCounts.set(url, {
              count: unreadMessageCount,
              markedUnread: !!unread,
            })
          }
        } catch (e) {
          queryForAllChannelsForUserIdLogger.error(
            'Error retrieving sendbird channel data',
            e,
            `ListQueryHasNext:${listQuery.hasNext ? 'true' : 'false'}`
          )
          throw e
        }
      }

      queryForAllChannelsForUserIdLogger.info(
        `Completed useQuery function with ${
          unreadMessageCounts.size ? 'non-' : ''
        }emtpy message data`,
        { sendbirdUserId }
      )

      return {
        unreadMessageCounts,
      }
    },
  })
