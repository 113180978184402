import React from 'react'

import { ModalBasic } from '../BaseComponents'

export type SendSuperbillWithoutPaymentModalProps = {
  visible: boolean
  onCancel: () => void
  onAddPayment: () => void
  onSend: () => void
  isSending: boolean
}
const SendSuperbillWithoutPaymentModal: React.FC<
  SendSuperbillWithoutPaymentModalProps
> = ({ visible, onCancel, onAddPayment, onSend, isSending }) => {
  return (
    <ModalBasic
      visible={visible}
      title={'Send superbill without payment?'}
      content={
        'Are you sure you want to send the superbill without processing full payment?'
      }
      onCancel={onCancel}
      cancelButtonProps={{
        disabled: isSending,
        testId: 'cancel-send-superbill-without-payment-button',
      }}
      onConfirm={onAddPayment}
      confirmButtonLabel={'Add payment'}
      confirmButtonProps={{
        disabled: isSending,
        testId: 'add-payment-for-superbill-button',
      }}
      onSecondaryAction={onSend}
      secondaryActionButtonLabel={'Send without payment'}
      secondaryActionButtonProps={{
        loading: isSending,
        disabled: isSending,
        danger: false,
        testId: 'send-superbill-without-payment-button',
      }}
      contentTestId={'send-superbill-to-patient-without-payment-modal'}
      hasSecondaryAction
      width={500}
    />
  )
}

export default SendSuperbillWithoutPaymentModal
