import styles from './GoogleCalendarCheckbox.module.scss'

type GoogleCalendarCheckboxProps = {
  calendarName: string
  googleAccountName: string
}

export const GoogleCalendarCheckbox = ({
  calendarName,
  googleAccountName,
}: GoogleCalendarCheckboxProps) => {
  return (
    <>
      {calendarName}{' '}
      <span className={styles.googleAccountName}>({googleAccountName})</span>
    </>
  )
}
