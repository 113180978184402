export enum DateTimeFormat {
  FULL_DATE_FORMAT = 'MM/dd/yyyy',
  DATE_TIME_FORMAT = 'MM/dd/yyyy h:mm aa',
  YEAR_MONTH_FORMAT = 'MM/yyyy',
  YEAR_FORMAT = 'yyyy',
}

export const DELETE_COLOR = '#F5222D'

export enum DIAGNOSES_STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  RESOLVED = 'Resolved',
  DIFFERENTIAL = 'Differential diagnosis/provisional',
}

export enum PAYMENT_METHOD_STATUSES {
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export const AMERICA_LOS_ANGELES_TZ = 'America/Los_Angeles'

export enum PAYMENT_FORM_OPTIONS {
  PAY_OUTSTANDING_BALANCE = 'PAY_OUTSTANDING_BALANCE',
  PAY_INDIVIDUAL = 'PAY_INDIVIDUAL',
  ADD_PATIENT_CREDIT = 'ADD_PATIENT_CREDIT',
}

export enum PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS {
  OUTSTANDING_BALANCE = 'OUTSTANDING_BALANCE',
  CUSTOM_AMOUNT = 'CUSTOM_AMOUNT',
}
export enum PAYMENT_TYPE {
  CLAIM = 'claim',
  INVOICE = 'invoice',
}
export enum INVOICE_FORM_MODES {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum EXTERNAL_SERVICES {
  STRIPE = 'STRIPE',
}

export enum REFUND_MODAL_MODE {
  INVOICE = 'invoice',
  PAYMENT = 'payment',
  INSURANCE_CLAIM = 'insurance_claim',
}

export enum STRIPE_ACCOUNT_STATUSES {
  SETUP_COMPLETE = 'setup_complete',
  SETUP_INCOMPLETE = 'setup_incomplete',
  UNLAUNCHED = 'unlaunched',
}

export enum CARD_ISSUERS {
  VISA = 'VISA',
  DISCOVER = 'DISCOVER',
  AMEX = 'AMEX',
  MASTERCARD = 'MASTERCARD',
}

export enum PAYMENT_METHOD_TYPES {
  STRIPE_CREDIT_CARD = 'STRIPE_CREDIT_CARD',
  EXTERNAL_CREDIT_CARD = 'EXTERNAL_CREDIT_CARD',
  CASH = 'CASH',
  CHECK = 'CHECK',
  ACH = 'ACH',
  VENMO = 'VENMO',
  ZELLE = 'ZELLE',
  PATIENT_FINANCING = 'PATIENT_FINANCING',
  OTHER = 'OTHER',
}

export const PLACEHOLDER = '---'
export const DX_POINTER_LIMIT = 4
export const MODS_LIMIT = 4
export const ROW_MIN_WIDTH = 150
export const MAXIMUM_MEMO_LENGTH = 50
export const MINIMUM_INPUT_NUMBER_VALUE = 100

export const legalSexArray = ['F', 'M', 'X', 'Unknown']
export const birthSexArray = ['F', 'M', 'X', 'Unknown']

export const pronounsArray = [
  {
    code: 'LA29519-8',
    value: 'she/her/hers',
    label: 'she/her/hers',
  },
  {
    code: 'LA29518-0',
    value: 'he/him/his',
    label: 'he/him/his',
  },
  {
    code: 'LA29520-6',
    value: 'they/them/theirs',
    label: 'they/them/theirs',
  },
  {
    code: 'Unknown',
    value: 'Unknown',
    label: 'Unknown',
  },
]

export enum QUERY_PARAMS {
  saltNoteId = 'saltNoteId',
  patientId = 'patientId',
  providerId = 'providerId',
  profilePanel = 'profilePanel',
  editMode = 'editMode',
  saltAlert = 'saltAlert',
  fromLast = 'fromLast',
}
