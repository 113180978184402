import React, { useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import { cloneDeep } from 'lodash'

import SkeletonLoadingTransition from '../../../components/Animation/SkeletonLoadingTransition'
import { SupervisingProviderType } from '../../../hooks/usePatientProfile/shared-types'
import { Teammate } from '../../../shared-types'
import Fields, { FieldGroupProps } from '../../../stories/PatientProfile/Fields'
import {
  handleSupervisingProvUpdate,
  populateSupervisingProviderInfo,
} from './InsuranceClaim-helper'
import { supervisingProviderComponent } from './constants'

import styles from './InsuranceClaim.module.scss'

export interface SupervisingProviderProps {
  testId?: string
  supervisingProvider: SupervisingProviderType
  setSupervisingProvider: Function
  teamLoading: boolean
  teammates: Teammate[]
  includeSupervisingProvider?: boolean
  setIncludeSupervisingProvider?: Function
  isSupervising?: boolean
  disabled?: boolean
}

const Loading = () => (
  <div className={styles.skeletonContainer}>
    <Skeleton
      active
      title={{ width: 85 }}
      paragraph={{
        rows: 3,
        width: '100%',
        className: styles.skeletonParagraph,
      }}
    />
  </div>
)

const SupervisingProvider: React.FC<SupervisingProviderProps> = ({
  testId,
  supervisingProvider,
  setSupervisingProvider,
  teammates,
  teamLoading,
  includeSupervisingProvider = undefined,
  setIncludeSupervisingProvider,
  disabled = false,
}) => {
  const [supervisingProviderFields, setSupervisingProviderFields] = useState<
    FieldGroupProps[]
  >(supervisingProviderComponent)

  function isTeammateProvider(t: Teammate): boolean {
    let isProvider = false
    t.roleTitles?.forEach((r) => {
      if (r.label === 'Provider') isProvider = true
    })
    return isProvider
  }

  function filterTeammates() {
    const activeTeammates = teammates.filter((t) => !t.isDeactivated)
    const teammateOptions = []
    for (const t of activeTeammates) {
      if (isTeammateProvider(t)) {
        teammateOptions.push({
          value: t.cognitoId,
          label: `${t.name ? t.name : t.email}`,
          disabled: t.lastActive === 'never_logged_in',
        })
      }
    }
    return teammateOptions
  }

  useEffect(() => {
    const itemsCopy: any = cloneDeep(supervisingProviderComponent)
    if (!teamLoading) {
      const teammatesOptions = filterTeammates()
      itemsCopy[0].items[0].options = teammatesOptions
      if (supervisingProvider) {
        populateSupervisingProviderInfo(itemsCopy, supervisingProvider)
      }
      setSupervisingProviderFields(itemsCopy)
    }
  }, [supervisingProvider, teammates, teamLoading])

  const handleSave = (
    newVal: any,
    id: string,
    _groupName: string,
    _label?: string
  ) => {
    const copySupervisingProviderInfo = cloneDeep(supervisingProvider)
    const selectedTeammate = teammates.filter((t) => t.cognitoId === newVal)
    const shouldSave = handleSupervisingProvUpdate(
      newVal,
      id,
      copySupervisingProviderInfo,
      selectedTeammate
    )
    if (shouldSave) {
      setSupervisingProvider(copySupervisingProviderInfo)
    }
  }

  return (
    <SkeletonLoadingTransition
      isLoading={teamLoading}
      skeletonComponent={<Loading />}
      loadedComponent={
        <div className={styles.topMargin}>
          <Fields
            testId={testId}
            items={supervisingProviderFields}
            compact={false}
            handleSave={handleSave}
            isIncluded={includeSupervisingProvider}
            setIsIncluded={setIncludeSupervisingProvider}
            includedText="This visit had a supervising provider"
            isClaim
            disableAll={disabled}
          />
        </div>
      }
    />
  )
}

export default SupervisingProvider
