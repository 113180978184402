import { useMemo } from 'react'

import { TimeGridProps } from 'react-big-calendar'
// @ts-ignore we need to use the WeekView from react-big-calendar, but it's not typed
import TimeGrid from 'react-big-calendar/lib/TimeGrid'

import { WeekView } from './WeekView'
import { WorkWeekView } from './WorkWeekView'

export interface WeekViewProps extends TimeGridProps {
  localizer: any
  date: Date
}

export function BaseWeekView({
  date,
  localizer,
  max = localizer.endOf(new Date(), 'day'),
  min = localizer.startOf(new Date(), 'day'),
  scrollToTime = localizer.startOf(new Date(), 'day'),
  workWeek,
  ...props
}: WeekViewProps & { workWeek?: boolean }) {
  const currRange = useMemo(
    () =>
      workWeek
        ? WorkWeekView.range(date, { localizer })
        : WeekView.range(date, { localizer }),
    [date, localizer]
  )
  return (
    <TimeGrid
      date={date}
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      scrollToTime={scrollToTime}
      {...props}
    />
  )
}
