import React from 'react'

import { InfoPage } from '../../../stories/BaseComponents'

const ErrorView = () => {
  return (
    <InfoPage
      status="warning"
      title="Sorry, there was a problem loading this page"
      details="Oops! Something went wrong. Please contact your Osmind representative if this issue persists."
      redirectButtonText="Return to Patient List"
      redirectLink="/"
    />
  )
}

export default ErrorView
