import React from 'react'

import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined'
import RightOutlined from '@ant-design/icons/lib/icons/RightOutlined'
import {
  addDays,
  addMonths,
  addWeeks,
  isToday,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns'

import { Button } from '../BaseComponents'
import { CalendarNavigationOptions, Views } from './constants'

import './Scheduling.scss'

const NavigationButtonLabels = {
  [CalendarNavigationOptions.BACK]: <LeftOutlined />,
  [CalendarNavigationOptions.NEXT]: <RightOutlined />,
  [CalendarNavigationOptions.TODAY]: 'Today',
}

const NavigationButtonFunctions = {
  [CalendarNavigationOptions.BACK]: {
    [Views.DAY]: subDays,
    [Views.WEEK]: subWeeks,
    [Views.MONTH]: subMonths,
    [Views.AGENDA]: subDays,
  },
  [CalendarNavigationOptions.NEXT]: {
    [Views.DAY]: addDays,
    [Views.WEEK]: addWeeks,
    [Views.MONTH]: addMonths,
    [Views.AGENDA]: addDays,
  },
}

const NavigateButtons = ({
  selectedDate,
  currentView,
  onNavigationChange,
}: {
  selectedDate: Date
  currentView: Views
  onNavigationChange: (selectedDate: Date) => void
}) => {
  const todayIsHighlighted = isToday(selectedDate)

  const navigate = (navigateAction: CalendarNavigationOptions) => {
    if (navigateAction === CalendarNavigationOptions.TODAY) {
      return onNavigationChange(new Date())
    } else {
      return onNavigationChange(
        NavigationButtonFunctions[navigateAction][currentView as Views](
          selectedDate,
          1
        )
      )
    }
  }

  const navigateButtons = () => {
    return Object.values(CalendarNavigationOptions).map((navigateAction) => (
      <Button
        className={`navigation-button-${navigateAction.toLowerCase()}`}
        key={navigateAction}
        onClick={() => navigate(navigateAction)}
        disabled={
          navigateAction === CalendarNavigationOptions.TODAY &&
          todayIsHighlighted
        }
      >
        {NavigationButtonLabels[navigateAction]}
      </Button>
    ))
  }

  return (
    <div
      className="calendar-nav-btns"
      data-testid="scheduling_navigationButtons"
    >
      {navigateButtons()}
    </div>
  )
}

export default NavigateButtons
