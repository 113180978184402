// Import react
import React, { useState } from 'react'

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
// Import components
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
} from 'react-bootstrap'

// Import libs/other
import { deletePastMed } from '../../api/api-lib'
import EditMedication from '../../components/Forms/EditMedication'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'

const PastMedsAccordion = (props) => {
  const [medicationId] = useState(
    props.medicine.ActiveMedicationId || props.medicine.PastMedicationId
  )
  const [patientId] = useState(props.medicine.PatientId)
  const [administrationIntervalString] = useState(
    props.medicine.AdministrationIntervalInDays
      ? props.medicine.AdministrationIntervalInDays === '1'
        ? 'Every day'
        : 'Every ' + props.medicine.AdministrationIntervalInDays + ' days'
      : ''
  )

  const rangeArr = props.medicine?.DateRange?.split(' - ') ?? ['', '']
  const start = rangeArr[0]
    ? moment(rangeArr[0], 'YYYY-MM-DD').format('LL')
    : ''
  const end = rangeArr[1] ? moment(rangeArr[1], 'YYYY-MM-DD').format('LL') : ''
  const range =
    (start ? start.toString() : '') + (end ? ' up to ' + end.toString() : '')

  async function handleDeletePastMed(event) {
    event.preventDefault()

    try {
      await deletePastMed({
        PastMedicationId: medicationId,
        PatientId: patientId,
      })
      props.handleApiChange()

      notification(
        `You have successfully deleted ${props.medicine.MedicationName} as a past medication for ${props.patient.PatientName}.`,
        'success'
      )
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
  }

  return (
    <Container>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={props.index.toString()}
        id="header"
        bsPrefix="patient-card"
      >
        <Container fluid>
          <Row>
            <Col sm={6} lg={12}>
              <Row>
                <Col sm={12} md={12} lg={2}>
                  <span className="emphasis info larger">
                    {props.medicine.MedicationName}
                  </span>
                </Col>
                <Col sm={12} md={6} lg={2}>
                  {props.medicine.Dosage || props.medicine.Administration ? (
                    <span>
                      <i className="fa fa-check-circle-o" aria-hidden="true" />
                      &nbsp;
                    </span>
                  ) : (
                    ''
                  )}
                  {props.medicine.Dosage ? (
                    <span className="emphasis-light larger">
                      {props.medicine.Dosage}&nbsp;
                    </span>
                  ) : (
                    ''
                  )}
                  {!props.medicine.Administration ? (
                    ''
                  ) : props.medicine.Administration === 'Other (specify)' ? (
                    <span>
                      by&nbsp;
                      <span className="emphasis-light larger">
                        {props.medicine.AdministrationOther}
                      </span>
                    </span>
                  ) : (
                    <span>
                      by&nbsp;
                      <span className="emphasis-light larger">
                        {props.medicine.Administration}
                      </span>
                    </span>
                  )}
                </Col>
                <Col sm={12} md={6} lg={2} className="emphasis-light larger">
                  {props.medicine.Frecuency ? (
                    <span>{props.medicine.Frecuency}</span>
                  ) : (
                    ''
                  )}
                </Col>
                <Col sm={12} md={12} lg={4}>
                  <span className="emphasis info larger">
                    {props.medicine.ProviderStartDate
                      ? props.medicine.ProviderStartDate
                      : ''}
                    {props.medicine.ProviderEndDate ? ' - ' : ''}
                    {props.medicine.ProviderEndDate
                      ? props.medicine.ProviderEndDate
                      : ''}
                  </span>
                </Col>
                <Col sm={12} md={12} lg={2}>
                  <span className="emphasis info larger">
                    Prescribed by you:{' '}
                    {props.medicine.PrescribedByProvider ? 'Yes' : 'No'}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={props.index.toString()}>
        <div>
          <Table hover bsPrefix="rounded" responsive>
            <tbody>
              <tr className="very-large">
                <td
                  style={{ width: '28vw' }}
                  className="patient emphasis-light"
                >
                  Medication:
                </td>
                <td className="patient emphasis-light">
                  {props.medicine.MedicationName} &nbsp;({' '}
                  {props.medicine.Administration !== 'Other (specify)'
                    ? props.medicine.Administration
                    : props.medicine.AdministrationOther}{' '}
                  )
                </td>
              </tr>

              <tr className="emphasis-light larger patient">
                <td>Dosage:</td>
                <td>
                  {props.medicine.Dosage} -{' '}
                  {props.medicine.AdministrationIntervalInDays
                    ? administrationIntervalString
                    : ''}
                </td>
              </tr>

              <tr className="emphasis-light">
                <td>Frequency:</td>
                <td>
                  {props.medicine.Frecuency ? props.medicine.Frecuency : ''}
                </td>
              </tr>
              <tr>
                <td className="emphasis-light">Start/End Dates:</td>
                <td>
                  {props.medicine.ProviderStartDate
                    ? props.medicine.ProviderStartDate
                    : ''}{' '}
                  -{' '}
                  {props.medicine.ProviderEndDate
                    ? props.medicine.ProviderEndDate
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="emphasis-light">
                  Reminder dates of administration:
                </td>
                <td>{range}</td>
              </tr>

              <tr>
                <td className="emphasis-light">Prescribed by you:</td>
                <td>{props.medicine.PrescribedByProvider ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td className="emphasis-light">Side Effects:</td>
                <td>{props.medicine.SideEffects}</td>
              </tr>
              <tr>
                <td className="emphasis-light">Reason discontinued:</td>
                <td>{props.medicine.ReasonDiscontinued}</td>
              </tr>
              <tr>
                <td className="emphasis-light">
                  Is this medication for mental health or psychiatric
                  conditions:
                </td>
                <td>
                  {props.medicine.MentalPsychiatricConditions ? 'Yes' : 'No'}
                </td>
              </tr>
              {!props.medicine.MentalPsychiatricConditions ? (
                ''
              ) : (
                <tr>
                  <td className="emphasis-light">Reason for use:</td>
                  <td>{props.medicine.ReasonForUse}</td>
                </tr>
              )}
              {!props.medicine.MentalPsychiatricConditions ? (
                ''
              ) : (
                <tr>
                  <td className="emphasis-light">
                    Patient perspective of efficacy:
                  </td>
                  <td>{props.medicine.PatientPerspectiveEfficacy}</td>
                </tr>
              )}
              <tr>
                <td className="emphasis-light">Comments:</td>
                <td>{props.medicine.Comments}</td>
              </tr>
            </tbody>
          </Table>
          <Col
            sm={6}
            lg={4}
            style={{ marginLeft: 'auto', marginBottom: '20px' }}
          >
            <Row>
              <Col>
                <Button
                  style={{ display: 'contents', marginLeft: 56 }}
                  bsPrefix="button-two right"
                  onClick={handleDeletePastMed}
                >
                  <span className="larger">
                    <FontAwesomeIcon icon={faTrash} />
                    &nbsp;Delete
                  </span>
                </Button>
              </Col>
              <Col>
                <EditMedication
                  medicine={props.medicine}
                  medicationType={false}
                  handleApiChange={props.handleApiChange}
                />
              </Col>
            </Row>
          </Col>
        </div>
      </Accordion.Collapse>
    </Container>
  )
}

export default PastMedsAccordion
