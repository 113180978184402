import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const EyeCodes: any = [
  {
    371409005: {
      title: 'Eye Disorder',
      snomedcode: 371409005,
      description: 'Disorder of eye region (disorder)',
      icdcode: 'H579',
      icddescription: 'Unspecified disorder of eye and adnexa',
    },
  },
  {
    105597003: {
      title: 'eye.Blind or Low Vision',
      description: 'Blindness AND/OR vision impairment level (disorder)',
      snomedcode: 105597003,
      icdcode: 'H547',
      icddescription: 'Unspecified visual loss',
    },
  },
  {
    193570009: {
      title: 'eye.Cataract',
      description: 'Cataract (disorder)',
      snomedcode: 193570009,
      icdcode: 'H269',
      icddescription: 'Unspecified cataract',
    },
  },
  {
    23986001: {
      title: 'eye.Glaucoma',
      description: 'Glaucoma (disorder)',
      snomedcode: 23986001,
      icdcode: 'H409',
      icddescription: 'Unspecified glaucoma',
    },
  },
]

export const EyeDropDownValues = constructPatientDemographicObject(
  EyeCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
