// Import react
import React, { Component } from 'react'

// Import libs/other
import dayjs from 'dayjs'
// Switch from dayjs to date-fns
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import last from 'lodash/last'
import moment from 'moment'
import { extendMoment } from 'moment-range'
// Import styles
import { Button, Form, Row } from 'react-bootstrap'
import { DateRangePicker } from 'react-dates'
// Import components
import 'react-dates/initialize'
import {
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryClipContainer,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryStack,
  VictoryTooltip,
  createContainer,
} from 'victory'

import { LegacyNoteTypes, NoteTypes } from '../shared-types'
import Answers from './questionnairesLib/questionnaireConverter'

import '../DateRange.scss'
import './ChartsLib.scss'
import 'react-dates/lib/css/_datepicker.css'

dayjs.extend(isSameOrAfter)
const momentRange = extendMoment(moment)
const VictoryVoronoiContainer = createContainer('voronoi')

moment().format('MM/DD/YYYY')

function addLabel(score) {
  const labeledScore = score
  let newLabel
  const date = score.a.format('MMMM DD, YYYY')
  if (date === 'Invalid date') {
    newLabel = 'Score: ' + score.b + '\n' + score.a
  } else {
    newLabel = 'Score: ' + score.b + '\n' + date
  }

  labeledScore.label = newLabel.toString()
  return labeledScore
}

// *** add different chart types here! ***
// typeSpecificValues()
// input: a string (mood, phq, phq4, gad, pcl, pain)
// output: a dictionary with all the info you need to feed into RenderChart
//         (for now)
// ** in order to add a new chart type, **
// ** add a case with the tick values, **
// ** max number (size), plus the y-axis label (name) **
function typeSpecificValues(chartType, survey) {
  const dict = {
    array: [],
    size: 0,
    name: '',
    numOfAnswers: 1,
    // colorScale refers to the colors of the ranges that each graph has as background. If empty, white background is applied. The first color is the one on the top
    colorScale: ['#ffffff'],
    // colorRanges refers to the max limits for each range. If empty, no colors are applied.
    colorRanges: [],
    invertAxisEnabled: false,
  }

  switch (chartType) {
    case 'MoodScore':
      dict.array = [2, 4, 6, 8, 10]
      dict.size = 10
      dict.name = 'Mood Score'
      break
    case 'PhqScore':
      dict.array = [3, 6, 9, 12, 15, 18, 21, 24, 27]
      dict.size = 27
      dict.name = 'PHQ-9 Score'
      dict.colorScale = [
        'rgba(56, 160, 60, 0.4)',
        'rgba(184, 156, 56, 0.4)',
        'rgba(179, 141, 4, 0.5)',
        'rgba(204, 95, 45, 0.6)',
        'rgba(184, 71, 71, 0.67)',
      ]
      dict.colorRanges = [4.5, 9.5, 14.5, 19.5, dict.size]
      dict.numOfAnswers = 9
      dict.invertAxisEnabled = true
      break
    case 'PainScore':
      dict.array = [2, 4, 6, 8, 10]
      dict.size = 10
      dict.name = 'Pain Score'
      break
    case 'Beck':
      dict.array = [7, 14, 21, 28, 35, 42, 49, 56, 63]
      dict.colorScale = [
        'rgba(50, 170, 100, 0.5)',
        'rgba(156, 160, 60, 0.56)',
        'rgba(180, 160, 70, 0.45)',
        'rgba(228, 100, 45, 0.6)',
        'rgba(200, 65, 45, 0.6)',
        'rgba(180, 56, 40, 0.67)',
      ]
      dict.colorRanges = [10, 16, 20, 30, 40, 63]
      dict.name = 'BDI-II  Score'
      dict.numOfAnswers = 23
      dict.size = 63
      break
    case 'Resilience': // guessing there's no chart involved
      dict.size = 14
      dict.name = 'Resilience Score'
      dict.numOfAnswers = 14
      break
    case 'PEG':
      dict.size = 10
      dict.numOfAnswers = survey.Steps.length
      break
    default:
      if (survey) {
        dict.size = survey.Steps.map((step) =>
          step.Type === 'SCALE'
            ? [step.Properties.Max.Score]
            : step.Type === 'SELECT'
            ? step.Options.map((option) => option.Score)
            : [0]
        ).reduce((max, scores) => max + Math.max(...scores), 0)

        dict.numOfAnswers = survey.Steps.length

        return dict
      }
  }

  return dict
}

// Transform the clinical notes into data for bar chart generation (vertical lines)
export function transformClinicalNotes(clinicalNotes, type, survey) {
  const data = []
  for (let i = 0; i < clinicalNotes?.length; i++) {
    const cNote = clinicalNotes[i]
    if (cNote.ReceivedTreatment || cNote.NoteType === NoteTypes.SPRAVATO) {
      const cNoteData = {}
      const cmd = new Date(cNote.CreatedOn) // moment(new Date(cNote.CreatedOn)).local().format("MM-DD-YYYY");
      cNoteData.x = cmd

      // TODO: change the y value based on the type!
      const highestY = typeSpecificValues(type, survey)
      cNoteData.y = highestY.array[highestY.array.length - 1] + 1
      cNoteData.y0 = 0
      // Create the label
      let medicine = ''
      let doseBool = false
      let patientWeightBool = false
      let dose = null
      let doseLabel = ''
      let administration = ''
      if (cNote.NoteType === NoteTypes.CLINICAL_NOTE) {
        medicine =
          cNote.MedicationName.length >= 1 ? cNote.MedicationName : 'N/A'
        doseBool = cNote.Dosage ? cNote.Dosage.length > 0 : false
        patientWeightBool = cNote.PatientWeight
          ? cNote.PatientWeight.length > 0
          : false
        dose =
          doseBool && patientWeightBool
            ? parseInt(cNote.Dosage) / parseInt(cNote.PatientWeight)
            : null
        doseLabel = dose ? `, ${dose.toFixed(2)} mg/kg` : ''
        administration =
          cNote.Administration.length > 0
            ? cNote.Administration !== 'Other (please specify)'
              ? cNote.Administration
              : cNote.OtherAdministration
            : 'N/A'
        cNoteData.label =
          medicine +
          doseLabel +
          '\n' +
          administration +
          '\n' +
          cNoteData.x.toDateString()
      } else if (
        cNote.NoteType === NoteTypes.IV_KETAMINE ||
        cNote.NoteType === LegacyNoteTypes.IV_KETAMINE_INFUSION
      ) {
        medicine = 'Ketamine'
        if (cNote.KetamineDose !== '') {
          doseLabel = `, ${cNote.KetamineDose} mg/kg`
        } else if (cNote.KetamineTotalDose !== '') {
          if (cNote.PatientWeight) {
            doseLabel = `, ${(
              cNote.KetamineTotalDose /
              (cNote.KetamineUnits === 'kg'
                ? cNote.PatientWeight
                : cNote.PatientWeight * 0.453592)
            ).toFixed(2)} mg/kg`
          }
        }
        administration = 'IV ' + (cNote.IVType ? cNote.IVType : '')
        cNoteData.label =
          medicine +
          doseLabel +
          '\n' +
          administration +
          '\n' +
          cNoteData.x.toDateString()
      } else if (cNote.NoteType === NoteTypes.IM_KETAMINE) {
        medicine = 'Ketamine'
        administration = 'IM'
        cNoteData.label =
          medicine + '\n' + administration + '\n' + cNoteData.x.toDateString()
      } else if (cNote.NoteType === NoteTypes.KAP) {
        medicine = 'Ketamine'
        administration = NoteTypes.KAP
        cNoteData.label =
          medicine + '\n' + administration + '\n' + cNoteData.x.toDateString()
      } else if (cNote.NoteType === NoteTypes.SPRAVATO) {
        medicine = NoteTypes.SPRAVATO
        administration = 'Nasal Spray'
        cNoteData.label =
          medicine + '\n' + administration + '\n' + cNoteData.x.toDateString()
      }
      data.push(cNoteData)
    }
  }
  return data
}

function xLabel(x) {
  const date = moment(new Date(x)).format('MM/DD/YY')
  return date.toString()
}

export class RenderChart extends Component {
  // (scores, type, clinicalNotes)
  constructor(props) {
    super(props)

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleRange = this.handleRange.bind(this)
    this.handleRangeChange = this.handleRangeChange.bind(this)
    this.handleClickScatter = this.handleClickScatter.bind(this)
    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      axisInverted: false,
      endDate: moment(),
      focusedInput: null,
      show: false,
      startDate: moment(last(this.props.submissions)?.Date),
      targetSubmission: null,
      typeSpecificValues: typeSpecificValues(
        this.props.type,
        this.props.survey
      ),
      showChartControls: this.props.showChartControls ?? true,
    }
  }

  handleShow() {
    this.setState({
      show: true,
    })
  }

  handleClose() {
    this.setState({
      show: false,
    })
  }

  // toggling modal with patient responses on and off
  handleClickScatter(e, props) {
    const submission = this.props.submissions[props.index]
    if (submission.Answers) {
      this.setState({ targetSubmission: submission })
    }
  }

  // change the date range from the date picker
  handleRange(day) {
    const fixedRange = momentRange.range(
      last(this.props.submissions).Date,
      moment()
    )
    const flexRange = momentRange.range(this.state.startDate, moment())
    if (!day.within(fixedRange)) {
      return !day.within(flexRange)
    } else {
      return !day.within(fixedRange)
    }
  }

  // change the date range from the buttons
  handleRangeChange(e) {
    let startDate = moment(last(this.props.submissions).Date)
    const lastDate = moment()
    const endDate = moment()

    switch (e.target.name) {
      case 'last-two-weeks':
        startDate = lastDate.subtract(14, 'days')
        break
      case 'last-month':
        startDate = lastDate.subtract(1, 'months')
        break
      case 'last-three-months':
        startDate = lastDate.subtract(3, 'months')
        break
      case 'last-year':
        startDate = lastDate.subtract(1, 'years')
        break
      case 'entire-range':
        break
      default:
    }
    this.setState({ startDate: startDate, endDate: endDate })
  }

  handleChange(e) {
    this.e.target.name.setState(e.target.value)
  }

  // Add functions to deal with hovering over bar (active)
  handleMouseEnter(e) {
    e.target.style.stroke = 'rgb(0, 101, 223)'
    e.target.style.cursor = 'pointer'
  }

  handleMouseLeave(e) {
    e.target.style.stroke = 'grey'
  }

  render() {
    const { clinicalNotes, name, type, survey, submissions, handleApiChange } =
      this.props
    const { startDate, endDate } = this.state
    const {
      array,
      colorRanges = null,
      colorScale = null,
      size,
    } = this.state.typeSpecificValues
    // Get formatted data values for clinical notes
    const clinicalNotesData = transformClinicalNotes(
      clinicalNotes,
      type,
      survey
    )
    const inRangeClinicalNotes = clinicalNotesData.filter((note) => {
      // ensuring clinical notes are in range
      // (a workaround to a bug with the bars being out of bounds
      // despite having a defined domain)
      const date = dayjs(note.x)
      const isWithinRange = date.isValid() && date.isSameOrAfter(startDate)
      return isWithinRange
    })

    const startDateId = `start-date-chart-date-range-picker-${Math.floor(
      Math.random() * 2800 + 1
    ).toString()}`
    const endDateId = `end-date-chart-date-range-picker-${Math.floor(
      Math.random() * 2800 + 1
    ).toString()}`
    const maxY = size + 1

    const scores = submissions.map(({ Date, Score }) => ({
      a: moment.utc(Date),
      b: Score,
    }))
    return (
      <>
        <div id={`${type}`}>
          <VictoryChart
            scale={{
              x: 'time',
            }}
            height={128}
            domainPadding={4}
            containerComponent={<VictoryVoronoiContainer />}
            domain={{
              x: [startDate, endDate],
              y: [0, maxY],
            }}
            padding={{
              top: 14,
              left: 21,
              right: 33,
              bottom: 42,
            }}
          >
            {/* Add vertical bars for treatments */}
            <VictoryBar
              data={inRangeClinicalNotes}
              labelComponent={
                <VictoryTooltip
                  constrainToVisibleArea
                  dy={181}
                  cornerRadius={10}
                  pointerLength={0}
                  flyoutStyle={{
                    stroke: 'rgb(170,170,170)',
                    fill: 'white',
                  }}
                />
              }
              style={{
                data: {
                  fill: 'grey',
                  width: 1,
                },
                labels: {
                  fontSize: 7,
                  padding: 3,
                },
              }}
            />

            <VictoryLine
              data={scores}
              style={{
                data: {
                  stroke: '#fc1c4d',
                  strokeWidth: 1,
                },
                labels: {
                  fontSize: 1,
                },
              }}
              labelComponent={
                <VictoryTooltip
                  dy={-7}
                  cornerRadius={10}
                  pointerLength={0}
                  flyoutStyle={{
                    stroke: 'transparent',
                    fill: 'transparent',
                  }}
                />
              }
              x="a"
              y="b"
            />

            {/* This VictoryStack is to give range colors to the background */}
            {Array.isArray(colorScale) && Array.isArray(colorRanges) && (
              <VictoryStack
                style={{ data: { strokeWidth: 0.1 } }}
                colorScale={colorScale}
              >
                {colorRanges.map((colorRange, index) => {
                  // setting start date earlier to offset padding
                  const newStartDate = new Date(
                    dayjs(startDate).subtract(4, 'day')
                  )
                  let y = colorRange
                  if (index > 0) {
                    y = y - colorRanges[index - 1]
                  }
                  return (
                    <VictoryGroup
                      data={[
                        { x: newStartDate, y: y },
                        { x: new Date(endDate), y: y },
                      ]}
                      key={`victory-color-${colorRange + index}`}
                    >
                      <VictoryArea />
                    </VictoryGroup>
                  )
                })}
              </VictoryStack>
            )}

            <VictoryScatter
              className="scatterChart"
              style={{
                data: {
                  fill: '#bc422a',
                  cursor:
                    type !== 'mood' && type !== 'pain' ? 'pointer' : 'auto',
                },
                labels: {
                  fontSize: 7,
                  padding: 3,
                },
              }}
              events={[
                {
                  target: 'data',
                  eventHandlers: {
                    onClick: (e, props) =>
                      this.handleClickScatter(e.target, props),
                  },
                },
              ]}
              groupComponent={<VictoryClipContainer />}
              labelComponent={
                <VictoryTooltip
                  dy={-7}
                  cornerRadius={10}
                  size={6}
                  pointerLength={0}
                  flyoutStyle={{
                    stroke: '#87CEFA',
                    fill: 'white',
                  }}
                />
              }
              data={scores.map(addLabel)}
              size={({ active }) => (active ? 4 : 1)}
              x="a"
              y="b"
            />

            <VictoryAxis
              tickFormat={(x) => xLabel(x)}
              label="Date"
              scale={{ x: 'time' }}
              fixLabelOverlap
              style={{
                axis: { stroke: '#0f6fd7' },
                axisLabel: { fontSize: 8, padding: 28, fill: '#0f6fd7' },
                ticks: { stroke: '#0f6fd7', size: 5 },
                tickLabels: { fontSize: 7, padding: 8, fill: '#0f6fd7' },
                tickValues: { fill: '#756f6a' },
              }}
            />

            <VictoryAxis
              dependentAxis
              tickValues={array}
              tickFormat={(y) => parseInt(y).toFixed(0)}
              invertAxis={this.state.axisInverted}
              label={name}
              style={{
                axis: { stroke: '#0f6fd7' },
                axisLabel: { fontSize: 8, padding: 28, fill: '#0f6fd7' },
                ticks: { stroke: '#0f6fd7', size: 5 },
                tickLabels: { fontSize: 7, padding: 4, fill: '#0f6fd7' },
              }}
            />
          </VictoryChart>
        </div>

        {this.state.showChartControls && (
          <>
            <Row
              bsPrefix="center marginal-half very-large info"
              style={{ marginTop: 0 }}
            >
              {this.state.typeSpecificValues.invertAxisEnabled && (
                <Form.Switch
                  id="custom-switch"
                  label="Invert Y axis"
                  onChange={() =>
                    this.setState({ axisInverted: !this.state.axisInverted })
                  }
                />
              )}
              <i className="fa fa-pencil-square-o" aria-hidden="true" />
              &nbsp;Customize Date Range&nbsp;&nbsp;
              <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId={startDateId}
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId={endDateId}
                onDatesChange={({ startDate, endDate }) =>
                  this.setState({ startDate, endDate })
                } // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                } // PropTypes.func.isRequired,
                isOutsideRange={(day) => this.handleRange(day)}
              />
            </Row>

            <Row bsPrefix="center">
              <Button
                name="last-two-weeks"
                onClick={(e) => this.handleRangeChange(e)}
                bsPrefix="button button-range marginal-light"
                variant="light"
              >
                Last two weeks
              </Button>
              <Button
                name="last-month"
                onClick={(e) => this.handleRangeChange(e)}
                bsPrefix="button button-range marginal-light"
                variant="light"
              >
                Last month
              </Button>
              <Button
                name="last-three-months"
                onClick={(e) => this.handleRangeChange(e)}
                bsPrefix="button button-range marginal-light"
                variant="light"
              >
                Last three months
              </Button>
              <Button
                name="last-year"
                onClick={(e) => this.handleRangeChange(e)}
                bsPrefix="button button-range marginal-light"
                variant="light"
              >
                Last year
              </Button>
              <Button
                name="entire-range"
                onClick={(e) => this.handleRangeChange(e)}
                bsPrefix="button button-range marginal-light"
                variant="light"
              >
                <i className="fa fa-undo" aria-hidden="true" />
                &nbsp; Reset
              </Button>
            </Row>
            <hr
              style={{
                color: 'rgb(224, 240, 252)',
                borderBottom: 'rgb(227, 235, 255) solid thick',
                marginBottom: 0,
              }}
            />
          </>
        )}

        {this.state.targetSubmission && (
          <Answers
            loggedInProviderId={this.props.loggedInProviderId}
            handleApiChange={handleApiChange}
            type={type}
            submission={this.state.targetSubmission}
            onClose={() => this.setState({ targetSubmission: null })}
          />
        )}
      </>
    )
  }
}
