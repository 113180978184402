import React, { useCallback, useMemo } from 'react'

import { FormInstance } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { difference, intersection, union, without } from 'lodash'

import { prefixedCodesetCategoryRegex } from '../../../../libs/regex'
import { PatientDemographicObject } from '../../../../shared/Demographics'
import {
  Checkbox,
  CheckboxGroup,
  MultiSelect,
  Row,
} from '../../../BaseComponents'
import CheckboxesWithForm from '../CheckboxesWithForm'
import { MappedCodeSetOptions, mapCodesetToListView } from '../helpers'

import styles from '../_shared.module.scss'

enum CustomConditionTypes {
  CONSTITUTIONAL = 'con.',
}

function SubForm({
  form,
  options,
  questionKeyName,
  reviewMode,
}: {
  form: FormInstance
  options: ConditionsToDisplayType
  questionKeyName: string
  reviewMode: boolean
}) {
  const name = questionKeyName
  // @ts-ignore
  const subOptions = options.map(({ value }) => value)
  // @ts-ignore
  const items = options.map(({ label, value }) => ({
    label,
    value,
  }))
  const initialSelected: string[] = intersection(
    subOptions,
    form.getFieldValue(name)
  )

  const handleChange = (newValuesForSection: string[]) => {
    // overly complicated way of making sure that the value for
    // 'EXISTING_PSYCH_DATA' is an array of strings, composed of also the
    // sub options selected under pysch conditions
    const oldValuesFromForm = form.getFieldValue(name)
    // checks if options for this section are already selected for in the form
    const overlap = intersection(subOptions, oldValuesFromForm)
    // if so, and there are more selected in the previous state than in the
    // newValuesForSection, that means an option has been removed
    const isRemoving = overlap.length > newValuesForSection.length
    if (isRemoving) {
      const removedValue = difference(overlap, newValuesForSection)
      const newValuesForForm = without(oldValuesFromForm, ...removedValue)
      form.setFields([{ name, value: newValuesForForm }])
      return
    }
    const value = union(newValuesForSection, oldValuesFromForm)
    form.setFields([{ name, value }])
  }

  return (
    <>
      {`What type(s) of conditions?`}
      <MultiSelect
        disabled={reviewMode}
        defaultValue={initialSelected}
        handleChange={handleChange}
        options={items}
        showSearch
        allowClear
        size="large"
      />
    </>
  )
}

interface ConditionsCustomCodesetMappingType extends MappedCodeSetOptions {
  key: string
  questionKey: string
  label: string
  value: string
  title: string
  description?: string
}

const ConditionsCustomCodesetMapping = (
  codeset: PatientDemographicObject[]
): ConditionsCustomCodesetMappingType[] => {
  return codeset.map(mapCodesetToListView).map(({ label, ...rest }) => {
    const cleanedValue = label?.replace(prefixedCodesetCategoryRegex, '')
    return {
      ...rest,
      questionKey: cleanedValue,
      label: cleanedValue,
      value: cleanedValue,
    }
  })
}

type ConditionsToDisplayType = (
  | ConditionsCustomCodesetMappingType[]
  | ConditionsCustomCodesetMappingType
)[]

const ConditionsList = ({
  form,
  conditionsToShow,
  questionKeysName,
  reviewMode = false,
}: {
  form: FormInstance
  conditionsToShow: any
  questionKeysName: string
  reviewMode: boolean
}) => {
  const conditions = useMemo<ConditionsToDisplayType>(() => {
    const transformedMedicalConditions: ConditionsCustomCodesetMappingType[][] =
      Object.values(conditionsToShow).map((conditions: any) => {
        return ConditionsCustomCodesetMapping(conditions)
      })

    let conditionsToDisplay: ConditionsToDisplayType = []
    transformedMedicalConditions.forEach((conditions) => {
      // We are flattening constitutional because it does not provide the correct presentation for patients
      const conditionsShouldBeFlatCheckboxes = conditions.some((condition) => {
        return condition.title.includes(CustomConditionTypes.CONSTITUTIONAL)
      })

      if (conditionsShouldBeFlatCheckboxes) {
        conditionsToDisplay = [...conditionsToDisplay, ...conditions]
      } else {
        conditionsToDisplay.push(conditions)
      }
    })
    return conditionsToDisplay.sort((a, b) => {
      return (
        ((a as ConditionsCustomCodesetMappingType)?.label ??
          (a as ConditionsCustomCodesetMappingType[])[0]?.label) ||
        ''
      ).localeCompare(
        (b as ConditionsCustomCodesetMappingType)?.label ??
          (b as ConditionsCustomCodesetMappingType[])[0]?.label
      ) // sort based on the title of category
    })
  }, [])

  const onSubFormClose = useCallback(
    (options: any) => {
      // anything checked on the subform needs to be erased to make sure that
      // no diagnoses are mistakenly checked + submitted bc they weren't visible
      // @ts-ignore
      const listToRemove = options.map(({ value }) => value)
      const name = questionKeysName
      const oldValues = form.getFieldValue(name)
      const newValues = without(oldValues, ...listToRemove)
      form.setFields([{ name, value: newValues }])
    },
    [form]
  )

  const handleBasicChecboxChange = useCallback(
    (event: CheckboxChangeEvent) => {
      const oldValuesFromForm = form.getFieldValue(questionKeysName) ?? []
      const isChecked = event.target.checked
      const newValue = event.target.value
      const newValuesForForm = isChecked
        ? union(oldValuesFromForm, [newValue])
        : without(oldValuesFromForm, newValue)
      form.setFields([{ name: questionKeysName, value: newValuesForForm }])
    },
    [form]
  )

  return (
    <CheckboxGroup disabled={reviewMode}>
      {conditions.map((conditionList, key) =>
        Array.isArray(conditionList) ? (
          <div key={key}>
            <CheckboxesWithForm
              onChange={handleBasicChecboxChange}
              disabled={reviewMode}
              options={conditionList}
              showFirst
              onSubFormClose={() => onSubFormClose(conditionList.slice(1))}
              subForm={() => (
                <SubForm
                  reviewMode={reviewMode}
                  form={form}
                  options={conditionList.slice(1)}
                  questionKeyName={questionKeysName}
                />
              )}
            />
          </div>
        ) : (
          <Row key={conditionList.key} className={styles.checkboxPadding}>
            <Checkbox
              disabled={reviewMode}
              value={conditionList.value}
              onChange={handleBasicChecboxChange}
            >
              {conditionList.label}
            </Checkbox>
          </Row>
        )
      )}
    </CheckboxGroup>
  )
}

export default ConditionsList
