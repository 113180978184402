import React, { ReactNode, useEffect, useRef, useState } from 'react'

import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { isBoolean } from 'lodash'

import {
  InlineEditDate,
  InlineEditRadio,
  InlineEditSelect,
  InlineEditText,
  InlineEditTextarea,
  Row,
  Space,
  Switch,
  Text,
  Title,
} from '../BaseComponents'
import InlineEditAddress, {
  AddressAPIVendors,
} from '../BaseComponents/InlineEditFields/InlineEditAddress'
import { Option } from '../BaseComponents/InlineEditFields/InlineEditSelect'
import InlineDateInput from '../BaseComponents/SpecialInputFields/InlineDateInput'

import styles from './Fields.module.scss'

interface FieldProps {
  id: string
  label?: string
  value: any
  type: string
  groupName: string
  placeHolder: string
  requireConfirmation: boolean
  dropdownWidth?: number
  scrollToField: boolean
  allowClear?: boolean
  disabled?: boolean
  size: SizeType
  suffix?: ReactNode
  mode?: 'multiple' | 'tags'
  options?: { value: string; label: string }[]
  filterSort?: (optionA: Option, optionB: Option) => number
  isWrongValue?: boolean
  isClaim?: boolean
  isLocal?: boolean
}

export interface FieldGroupProps {
  groupName: string
  items: Item[]
}

export interface Item {
  id: string
  label?: string
  placeHolder: string
  requireConfirmation: boolean
  scrollToField: boolean
  size?: SizeType
  value: unknown
  type: string
  dropdownWidth?: number
  suffix?: ReactNode
  mode?: 'multiple' | 'tags'
  allowClear?: boolean
  disabled?: boolean
  format?: string[]
  options?: { value: string; label: string }[]
  filterSort?: (optionA: Option, optionB: Option) => number
  isWrongValue?: boolean
  isRequired?: boolean
}

export interface FieldsProps {
  testId?: string
  items: Array<FieldGroupProps>
  compact: boolean
  handleSave: (
    newVal: any,
    id: string,
    groupName: string,
    label?: string
  ) => void
  isIncluded?: boolean
  setIsIncluded?: Function
  includedText?: string
  showTitle?: boolean
  isClaim?: boolean
  disableAll?: boolean
  isLocal?: boolean
}

const Fields = ({
  testId,
  items,
  compact,
  handleSave,
  isIncluded,
  setIsIncluded,
  includedText,
  showTitle = true,
  isClaim,
  disableAll,
}: FieldsProps) => {
  const containerRef: React.Ref<HTMLDivElement> = useRef(null)
  const [fieldWidth, setFieldWidth] = useState<number>()
  const [rendered, setRendered] = useState<boolean>(false)
  const [clientWidth, setClientWidth] = useState<number>()
  const [fieldParam, setFieldParam] = useState<string | null>('')

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    setRendered(true)
    if (rendered) {
      setFieldParam(searchParams.get('field'))
      return
    }
    const timeout = setTimeout(() => {
      setFieldParam(searchParams.get('field'))
    }, 600)

    return () => {
      clearTimeout(timeout)
    }
  }, [location.search])

  const handleResize = () => {
    setClientWidth(containerRef.current?.clientWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const width = compact
        ? 380
        : clientWidth
        ? clientWidth - 159
        : (containerRef.current?.clientWidth || 301) - 159
      setFieldWidth(width)
    }
  }, [containerRef, compact, clientWidth])

  const renderItems = () => {
    const renderField = ({
      id,
      label,
      value,
      type,
      placeHolder,
      options,
      groupName,
      requireConfirmation,
      size,
      dropdownWidth,
      suffix,
      mode,
      allowClear,
      disabled = false,
      scrollToField,
      filterSort,
      isWrongValue = false,
      isLocal = false,
      ...rest
    }: FieldProps) => {
      switch (type) {
        case 'text':
          return (
            <InlineEditText
              key={id}
              testId={`${testId}-${id}`}
              onSave={(newVal) =>
                handleSave(newVal, id, groupName, `${groupName} ${label}`)
              }
              value={value}
              suffix={suffix}
              padded={!compact}
              requireConfirmation={requireConfirmation}
              scrollToField={scrollToField}
              size={size}
              autoFocus={fieldParam === id}
              placeholder={placeHolder}
              disabled={disabled || disableAll}
            />
          )
        case 'text-area':
          return (
            <InlineEditTextarea
              testId={`${testId}-${id}`}
              onSave={(newVal) =>
                handleSave(newVal, id, groupName, `${groupName} ${label}`)
              }
              value={value}
              placeholder={placeHolder}
              requireConfirmation={requireConfirmation}
              scrollToField={scrollToField}
              autoFocus={fieldParam === id}
              size={size}
              disabled={disabled || disableAll}
            />
          )
        case 'date':
          return (
            <InlineEditDate
              testId={`${testId}-${id}`}
              onSave={(newVal) =>
                handleSave(newVal, id, groupName, `${groupName} ${label}`)
              }
              value={value}
              padded={!compact}
              placeholder={placeHolder}
              requireConfirmation={requireConfirmation}
              autoFocus={fieldParam === id}
              scrollToField={scrollToField}
              size={size}
              allowClear={allowClear}
              disabled={disabled || disableAll}
              isLocal={isLocal}
            />
          )
        case 'select':
          return (
            options && (
              <InlineEditSelect
                testId={`${testId}-${id}`}
                onSave={(newVal) =>
                  handleSave(newVal, id, groupName, `${groupName} ${label}`)
                }
                options={options}
                value={value}
                padded={!compact}
                dropdownWidth={dropdownWidth}
                mode={mode}
                filterSort={filterSort}
                placeholder={placeHolder}
                fieldWidth={fieldWidth}
                requireConfirmation={requireConfirmation}
                scrollToField={scrollToField}
                autoFocus={fieldParam === id}
                size={size}
                allowClear={allowClear}
                disabled={disabled || disableAll}
                isWrongValue={isWrongValue}
              />
            )
          )
        case 'radio':
          return (
            options && (
              <InlineEditRadio
                testId={`${testId}-${id}`}
                onSave={(newVal) =>
                  handleSave(newVal, id, groupName, `${groupName} ${label}`)
                }
                options={options}
                value={value}
                padded={!compact}
                placeholder={placeHolder}
                requireConfirmation={requireConfirmation}
                scrollToField={scrollToField}
                autoFocus={fieldParam === id}
                size={size}
                disabled={disabled || disableAll}
              />
            )
          )
        case 'googleAddress':
          return (
            <InlineEditAddress
              testId={`${testId}-${id}`}
              onSave={(newVal) =>
                handleSave(newVal, id, groupName, `${groupName} ${label}`)
              }
              value={value}
              suffix={suffix}
              padded={!compact}
              requireConfirmation={requireConfirmation}
              scrollToField={scrollToField}
              size={size}
              autoFocus={fieldParam === id}
              placeholder={placeHolder}
              disabled={disabled || disableAll}
            />
          )
        case 'smartyAddress':
          return (
            <InlineEditAddress
              testId={`${testId}-${id}`}
              onSave={(newVal) =>
                handleSave(newVal, id, groupName, `${groupName} ${label}`)
              }
              value={value}
              suffix={suffix}
              padded={!compact}
              requireConfirmation={requireConfirmation}
              scrollToField={scrollToField}
              size={size}
              autoFocus={fieldParam === id}
              placeholder={placeHolder}
              disabled={disabled || disableAll}
              vendor={AddressAPIVendors.SmartyStreets}
            />
          )
        case 'dateInput':
          return (
            <InlineDateInput
              onSave={(newVal: string) =>
                handleSave(newVal, id, groupName, `${groupName} ${label}`)
              }
              required={false}
              padded={!compact}
              value={value}
              autoFocus={fieldParam === id}
              placeholder={placeHolder}
              {...rest}
            />
          )
      }
    }

    function shouldShowItems() {
      if (isBoolean(isIncluded)) {
        return isIncluded
      }
      return true
    }

    return items.map(({ groupName, items }, idx) => (
      <div className={`${styles.group} ${compact && styles.compact}`} key={idx}>
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          {showTitle &&
            (isClaim ? (
              <Text header="h4" className={styles.groupTitle}>
                {groupName}
              </Text>
            ) : (
              <Title level={5} className={styles.groupTitle}>
                {groupName}
              </Title>
            ))}

          {isBoolean(isIncluded) && (
            <Row className={styles.toggle}>
              <Switch
                checked={isIncluded}
                onToggle={() => {
                  if (setIsIncluded) {
                    setIsIncluded(!isIncluded)
                  }
                }}
                disabled={disableAll}
                data-testid="should-include-in-claim"
                label={includedText}
              />
            </Row>
          )}
          {shouldShowItems() && (
            <div>
              {items.map(
                (
                  {
                    value,
                    id,
                    type,
                    placeHolder,
                    options,
                    label,
                    suffix,
                    size,
                    mode,
                    requireConfirmation,
                    scrollToField,
                    filterSort,
                    dropdownWidth,
                    allowClear,
                    disabled,
                    isWrongValue,
                    isRequired,
                    ...rest
                  },
                  index
                ) => (
                  <div
                    key={index}
                    className={`${styles.item} ${
                      compact ? styles.column : styles.row
                    }`}
                  >
                    {Boolean(label) && (
                      <Text
                        className={isClaim ? styles.claimLabel : styles.label}
                      >
                        {label}
                        <span style={{ color: '#F5222D' }}>
                          {isRequired && ' *'}
                        </span>
                      </Text>
                    )}
                    {renderField({
                      id,
                      label,
                      value,
                      type,
                      placeHolder,
                      options,
                      groupName,
                      suffix,
                      mode,
                      size,
                      requireConfirmation,
                      scrollToField,
                      filterSort,
                      dropdownWidth,
                      allowClear,
                      disabled: disabled || disableAll,
                      isWrongValue,
                      ...rest,
                    })}
                  </div>
                )
              )}
            </div>
          )}
        </Space>
      </div>
    ))
  }
  return (
    <div ref={containerRef} className={styles.container}>
      {renderItems()}
    </div>
  )
}

export default Fields
