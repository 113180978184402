import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { loadPatientDocuments } from '../../api/patients'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'
import { Document } from '../../shared-types'

export const usePatientDocuments = (
  patientId: string
): UseQueryResult<Document[]> => {
  return useQuery<Document[]>([patientDataTypes.PatientDocuments, patientId], {
    queryFn: async () => {
      const data = await loadPatientDocuments(patientId)
      return data
    },
    retry: false,
    refetchOnWindowFocus: false,
  })
}
