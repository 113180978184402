import React from 'react'

import { FormInstance, FormItemProps, InputProps } from 'antd'
import { NamePath } from 'rc-field-form/lib/interface'

import { verifyPhoneNumber } from '../../../api/phoneNumbers'
import { formattedPhoneNumber } from '../../../libs/utils'
import { formatPhoneNumber } from '../../../shared/Helpers/utils'
import AutoFormatInput from '../AutoFormatInput'

interface PhoneNumberInputProps {
  disabled?: boolean
  form: FormInstance
  inputProps?: InputProps
  formItemProps?: FormItemProps
  listName?: string
  name?: NamePath
  required?: boolean
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  disabled,
  form,
  formItemProps = {},
  inputProps = {},
  listName,
  name = 'phoneNumber',
  required = false,
}) => {
  const formItems: FormItemProps = {
    ...formItemProps,
    label: formItemProps.label || 'Phone Number',
    rules: formItemProps.rules || [
      {
        required,
        message: 'Enter a valid 10-digit phone number',
      },
      {
        message: 'Enter a valid 10-digit phone number',
        min: 14,
        transform: formattedPhoneNumber,
        type: 'string',
        validateTrigger: 'onBlur',
        whitespace: true,
        validator: async (_, value) => {
          if (value && !(await verifyPhoneNumber(value)).valid) {
            throw new Error('Invalid Phone Number')
          }
        },
      },
    ],
  }

  return (
    <AutoFormatInput
      disabled={disabled}
      form={form}
      formatInput={formatPhoneNumber}
      formItemProps={formItems}
      inputProps={inputProps}
      listName={listName}
      name={name}
    />
  )
}

export default PhoneNumberInput
