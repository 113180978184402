import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const PersonalityDisorderCodes: any = [
  {
    '33449004': {
      title: 'Personality Disorder',
      description: 'Personality disorder (disorder)',
      snomedcode: 33449004,
      icdcode: 'F602',
      icddescription: 'Personality disorder, unspecified',
    },
  },
  {
    '55341008': {
      title: 'pd.Antisocial Personality Disorder',
      description: 'Antisocial personality disorder (disorder)',
      snomedcode: 55341008,
      icdcode: 'F602',
      icddescription: 'Antisocial personality disorder',
    },
  },
  {
    '37746008': {
      title: 'pd.Avoidant Personality Disorder',
      description: 'Avoidant personality disorder (disorder)',
      snomedcode: 37746008,
      icdcode: 'F606',
      icddescription: 'Avoidant personality disorder',
    },
  },
  {
    '20010003': {
      title: 'pd.Borderline Personality Disorder',
      description: 'Borderline personality disorder (disorder)',
      snomedcode: 20010003,
      icdcode: 'F603',
      icddescription: 'Borderline personality disorder',
    },
  },
  {
    '55341008': {
      title: 'pd.Histrionic Personality Disorder',
      description: 'Histrionic personality disorder (disorder)',
      snomedcode: 55341008,
      icdcode: 'F604',
      icddescription: 'Histrionic personality disorder',
    },
  },
  {
    '80711002': {
      title: 'pd.Narcissistic Personality Disorder',
      description: 'Narcissistic personality disorder (disorder)',
      snomedcode: 80711002,
      icdcode: 'F6081',
      icddescription: 'Narcissistic personality disorder',
    },
  },
  {
    '1376001': {
      title: 'pd.Obsessive Compulsive Personality Disorder',
      description: 'Obsessive compulsive personality disorder (disorder)',
      snomedcode: 1376001,
      icdcode: 'F605',
      icddescription: 'Obsessive-compulsive personality disorder',
    },
  },
  {
    '13601005': {
      title: 'pd.Paranoid Personality Disorder',
      description: 'Paranoid personality disorder (disorder)',
      snomedcode: 13601005,
      icdcode: 'F600',
      icddescription: 'Paranoid personality disorder',
    },
  },
  {
    '52954000': {
      title: 'pd.Schizoid Personality Disorder',
      description: 'Schizoid personality disorder (disorder)',
      snomedcode: 52954000,
      icdcode: 'F601',
      icddescription: 'Schizoid personality disorder',
    },
  },
  {
    '31027006': {
      title: 'pd.Schizotypal Personality Disorder',
      description: 'Schizotypal personality disorder (disorder)',
      snomedcode: 31027006,
      icdcode: 'F21',
      icddescription: 'Schizotypal disorder',
    },
  },
]

export const PersonalityDisorderDropDownValues =
  constructPatientDemographicObject(
    PersonalityDisorderCodes,
    CodeSystemOIDs.OSMINDCustomCodeSystem,
    Categories.PYSCH_CONDITIONS
  )
