import React from 'react'

import { ExclamationCircleFilled } from '@ant-design/icons'
import { differenceInDays } from 'date-fns'

import themeStyles from '../../../_theme.module.scss'

interface SpravatoNoteDaysToSignCellProps {
  date: Date
}

// Notes must be signed this # of days past the note's DOS.
const SPRAVATO_DEADLINE_DAYS = 7

export default function SpravatoNoteDaysToSignCell({
  date,
}: SpravatoNoteDaysToSignCellProps) {
  const daysUntilDue =
    differenceInDays(date, new Date()) + SPRAVATO_DEADLINE_DAYS
  const dayText = Math.abs(daysUntilDue) === 1 ? 'day' : 'days'

  // Still have some time until the signature is due...
  if (daysUntilDue > 3) {
    return (
      <>
        {daysUntilDue} {dayText} left
      </>
    )
  }

  // Signature due date is approaching...
  if (daysUntilDue >= 0) {
    return (
      <>
        <ExclamationCircleFilled
          style={{ color: themeStyles.red7, paddingRight: '5px' }}
        />
        <span style={{ color: themeStyles.red6 }}>
          {daysUntilDue} {dayText} left
        </span>
      </>
    )
  }

  // Signature due date has passed...
  return (
    <>
      <ExclamationCircleFilled
        style={{ color: themeStyles.gray9, paddingRight: '5px' }}
      />
      {Math.abs(daysUntilDue)} {dayText} overdue
    </>
  )
}
