import React from 'react'

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

import { Button, Col, Row, Text } from '../BaseComponents'

import styles from './Insurance.module.scss'

interface InsuranceCardsProps {
  testId: string
  imageUrlFront: string | null
  imageUrlBack: string | null
  setShowFrontCard: Function
  setShowBackCard: Function
  showFrontCard: boolean
  showBackCard: boolean
  loadingImages: boolean
  handleUploadFile: Function
  handleSave: Function
}

const InsuranceCards: React.FC<InsuranceCardsProps> = ({
  testId,
  imageUrlFront,
  imageUrlBack,
  setShowFrontCard,
  setShowBackCard,
  showFrontCard,
  showBackCard,
  loadingImages,
  handleUploadFile,
  handleSave,
}) => {
  return (
    <div data-testid={testId}>
      <Row className={styles.cardsTitleContainer}>
        <Text className={styles.sectionTitle}>Insurance card</Text>
      </Row>
      <Row className={styles.cardsTitleContainer}>
        <Col className={styles.insuranceCardCol}>
          <Text className={styles.imageTitle}>Front</Text>
        </Col>
        <Col className={styles.insuranceCardCol}>
          <Text className={styles.imageTitle}>Back</Text>
        </Col>
      </Row>
      <Row className={styles.insuranceCardContainer}>
        <Col className={styles.insuranceCardCol}>
          {/* <div>Front</div> */}
          <div className={styles.cardImageCol}>
            {imageUrlFront ? (
              <>
                <img
                  src={imageUrlFront}
                  onClick={() => setShowFrontCard(true)}
                  className={styles.imagesCardsInsurance}
                  alt="avatar"
                  style={{ width: '100%' }}
                />
                <Modal
                  visible={showFrontCard}
                  title={[<Text header="h4">Front</Text>]}
                  onCancel={() => setShowFrontCard(false)}
                  footer={[
                    <Button key="back" onClick={() => setShowFrontCard(false)}>
                      Close
                    </Button>,
                  ]}
                >
                  <img
                    src={imageUrlFront}
                    alt="avatar"
                    style={{ width: '100%' }}
                  />
                </Modal>
              </>
            ) : (
              <form className={styles.uploadButton}>
                <label
                  htmlFor="file-upload-front"
                  className={styles.pointerCursor}
                >
                  <div className={styles.canterPlusIcon}>
                    {loadingImages ? (
                      <LoadingOutlined
                        className={styles.plusCentered}
                        style={{ fontSize: '24px' }}
                      />
                    ) : (
                      <PlusOutlined
                        className={styles.plusCentered}
                        style={{ fontSize: '24px' }}
                      />
                    )}
                  </div>
                  <Text className={styles.uploadLabel} style={{ marginTop: 8 }}>
                    Upload
                  </Text>
                  {!loadingImages && (
                    <input
                      name="upload-Insurance-Card-Front"
                      id="file-upload-front"
                      data-testid="file-upload-front"
                      accept="image/*"
                      type="file"
                      hidden
                      onChange={(e) => handleUploadFile(e, 'FRONT')}
                    />
                  )}
                </label>
              </form>
            )}
          </div>
        </Col>
        <Col className={styles.insuranceCardCol}>
          {/* <div>Back</div> */}
          <div className={styles.cardImageCol}>
            {imageUrlBack ? (
              <>
                <img
                  className={styles.imagesCardsInsurance}
                  onClick={() => setShowBackCard(true)}
                  src={imageUrlBack}
                  alt="avatar"
                  style={{ width: '100%' }}
                />
                <Modal
                  visible={showBackCard}
                  title={[<Text header="h4">Back</Text>]}
                  onCancel={() => setShowBackCard(false)}
                  footer={[
                    <Button key="back" onClick={() => setShowBackCard(false)}>
                      Close
                    </Button>,
                  ]}
                >
                  <img
                    src={imageUrlBack}
                    alt="avatar"
                    style={{ width: '100%' }}
                  />
                </Modal>
              </>
            ) : (
              <form className={styles.uploadButton}>
                <label
                  htmlFor="file-upload-back"
                  className={styles.pointerCursor}
                >
                  {loadingImages ? (
                    <LoadingOutlined
                      className={styles.plusCentered}
                      style={{ fontSize: '24px' }}
                    />
                  ) : (
                    <PlusOutlined
                      className={styles.plusCentered}
                      style={{ fontSize: '24px' }}
                    />
                  )}
                  <Text className={styles.uploadLabel} style={{ marginTop: 8 }}>
                    Upload
                  </Text>
                  {!loadingImages && (
                    <input
                      name="upload-Insurance-Card-Back"
                      id="file-upload-back"
                      data-testid="file-upload-back"
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleUploadFile(e, 'BACK')}
                    />
                  )}
                </label>
              </form>
            )}
          </div>
        </Col>
      </Row>
      <Row className={styles.removeButtonContainer}>
        <Col className={styles.insuranceCardCol}>
          <Button
            className={styles.removeCardButton}
            onClick={() => handleSave(null, 'removeBackFront', 'frontCard')}
            disabled={!imageUrlFront}
          >
            Remove
          </Button>
        </Col>
        <Col className={styles.insuranceCardCol}>
          <Button
            className={styles.removeCardButton}
            onClick={() => handleSave(null, 'removeBackCard', 'backCard')}
            disabled={!imageUrlBack}
          >
            Remove
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default InsuranceCards
