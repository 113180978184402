import { MouseEvent } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { ArgsProps } from 'antd/lib/message'

import { generateComponentKey } from '../../libs/utils'

import './Notification.scss'

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  LOADING = 'loading',
}

export type ConfigProps = Omit<ArgsProps, 'content' | 'type'>

export interface NotificationProps {
  config?: ConfigProps
  content: string
  type?: NotificationType
}

export const generateNotification = (
  content: string,
  type: NotificationType = NotificationType.INFO,
  config: ConfigProps = {
    duration: 4,
  }
) => {
  const key = generateComponentKey('notification-popup')
  const onClick = (_event: MouseEvent<HTMLDivElement>) => message.destroy(key)

  const fullConfig: ArgsProps = {
    ...config,
    content: (
      <>
        {content}{' '}
        <CloseOutlined
          className="notification-close-button"
          data-testid="notification-close-button"
        />{' '}
      </>
    ),
    key,
    onClick,
    type: type,
  }
  return message[type](fullConfig)
}
