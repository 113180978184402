import React, { useMemo } from 'react'

import { Addendum, Signature } from '../../types'
import { NoteDateTimeDisplay } from '../NoteDateTimeDisplay'
import { Card, Typography } from './../../../../stories/BaseComponents'
import { Addendums } from './Addendums'

import styles from './Signatures.module.scss'

export type SignaturesProps = {
  signatures: Signature[]
  addendums: Addendum[]
  signatureContainerRef?: React.RefObject<HTMLDivElement>
  addendumContainerRef?: React.RefObject<HTMLDivElement>
  printMode?: boolean
  timezone: string | null
}

export const Signatures = ({
  signatures,
  signatureContainerRef,
  addendumContainerRef,
  addendums,
  printMode,
  timezone,
}: SignaturesProps) => {
  const { Text } = Typography

  // Memoize the rendering logic for signatures to prevent unnecessary re-renders
  const renderedSignatures = useMemo(() => {
    if (!Array.isArray(signatures) || signatures.length === 0) {
      return null
    }

    return signatures.map((signature, i) => {
      if (!signature || !signature.signedAt || !signature.providerName) {
        // Log error or handle it as needed
        return null
      }

      if (printMode) {
        return (
          <div
            key={`signature-${i}`}
            style={{
              paddingBottom: '10px',
              display: 'flex',
              flexDirection: 'column',
              fontSize: '11pt',
            }}
          >
            <Text>
              <b style={{ paddingRight: '1ch', margin: '2px 0' }}>Signee: </b>
              {`Electronically signed by ${signature.providerName}`}
            </Text>
            <Text style={{ margin: '2px 0' }}>
              <NoteDateTimeDisplay
                timestamp={signature.signedAt}
                timezone={timezone}
              />
            </Text>
            <Text
              style={{
                display: 'flex',
                margin: '2px 0',
              }}
            >
              <b style={{ paddingRight: '1ch' }}>Comments: </b>
              {signature.comments}
            </Text>
          </div>
        )
      }

      return (
        <Card
          key={`signature-${i}`}
          className={styles.signatureCard}
          bodyStyle={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
        >
          <Text className={styles.title}>
            {`Signed by ${signature.providerName}`}
          </Text>
          <Text className={styles.text}>
            <NoteDateTimeDisplay
              timestamp={signature.signedAt}
              timezone={timezone}
            />
          </Text>
          <Text className={styles.comments}>{signature.comments}</Text>
        </Card>
      )
    })
  }, [signatures])

  if (!renderedSignatures) {
    return null
  }

  if (printMode) {
    return (
      <div>
        <h1>Signatures</h1>
        {renderedSignatures}
        {addendums.length > 0 && (
          <Addendums addendums={addendums} timezone={timezone} />
        )}
      </div>
    )
  }

  return (
    <div className={styles.signatures}>
      <div className={styles.box}>
        <div ref={signatureContainerRef}>
          <Text className={styles.headerTitle}>Signatures</Text>
          {renderedSignatures}
        </div>

        {addendums.length > 0 && (
          <div ref={addendumContainerRef}>
            <Addendums addendums={addendums} timezone={timezone} />
          </div>
        )}
      </div>
    </div>
  )
}
