import { useState } from 'react'

import { Form } from 'antd'

import { changeAppointmentRequestStatus } from '../../../api/api-lib-typed'
import { trackAppointmentRequestApprovals } from '../../../libs/freshpaint/selfSchedulingEvents'
import { notification } from '../../../libs/notificationLib'
import { Modal, Text, TextArea } from '../../../stories/BaseComponents'
import { Footer } from './Footer'

export interface AppointmentDeclineModalProps {
  appointmentToDecline: {
    id: string
    apptTypeId: string
    appointmentTypeName: string
    patientId: string
  } | null
  visibleModal: boolean
  handleVisibleModal: (visible: boolean) => void
  loadAppointmentRequests: () => void
  providerId: string
  clinicId: string
}
export function AppointmentDeclineModal({
  appointmentToDecline,
  visibleModal,
  handleVisibleModal,
  loadAppointmentRequests,
  clinicId,
  providerId,
}: AppointmentDeclineModalProps) {
  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState(false)
  const [isSendingEmail, setIsSendingEmail] = useState(false)

  const sendDeclineAppointment = async (sendEmail: boolean) => {
    if (!appointmentToDecline || isLoading) return
    setLoading(true)
    if (sendEmail) {
      setIsSendingEmail(true)
    }
    try {
      const cancellationReason = form.getFieldValue('cancellationReason')
      const { id, apptTypeId, appointmentTypeName, patientId } =
        appointmentToDecline
      await changeAppointmentRequestStatus(id, {
        appointmentStatus: 'CANCELLED',
        sendCancellationMessage: sendEmail,
        cancellationReason,
      })
      trackAppointmentRequestApprovals({
        eventName: 'Declined appointment request',
        apptTypeId,
        apptTypeName: appointmentTypeName,
        isSentCustomMessage: !!cancellationReason,
        isSentMessage: sendEmail,
        patientId,
        providerId,
        clinicId,
      })
      notification('Appointment declined.', 'success')
    } catch (e) {
      notification('Declining appointment failed. Try again.', 'error')
    } finally {
      loadAppointmentRequests()
      setLoading(false)
      setIsSendingEmail(false)
      handleVisibleModal(false)
      form.resetFields()
    }
  }

  const handleCancel = () => {
    if (isLoading) return
    handleVisibleModal(false)
  }

  return (
    <Modal
      visible={visibleModal}
      onCancel={handleCancel}
      title={'Send decline message?'}
      footer={
        <Footer
          isSendingEmail={isSendingEmail}
          isLoading={isLoading}
          handleCancel={handleCancel}
          sendDeclineAppointment={sendDeclineAppointment}
        />
      }
    >
      <Text>
        Would you like to send a decline message to your patient? <br />
        The message will be sent to the patient’s email address on file.
      </Text>
      <Form form={form} scrollToFirstError layout="vertical">
        <Form.Item name="cancellationReason">
          <TextArea
            rows={4}
            placeholder="Add an optional message for the decline"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
