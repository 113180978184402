import cx from 'classnames'

import { Skeleton } from '../../../../../../stories/BaseComponents'

import styles from './LoadingSkeleton.module.scss'

export const LoadingSkeleton = () => {
  return (
    <Skeleton
      style={{ minHeight: '30px', verticalAlign: 'middle' }}
      title={{
        className: cx(styles.skeletonTitle, styles.skeletonRow),
      }}
      paragraph={{
        rows: 0,
        className: styles.skeletonParagraph,
      }}
    />
  )
}
