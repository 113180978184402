import { ForwardRefExoticComponent } from 'react'

import * as Icons from '@ant-design/icons'

import ERxOutlined from '../../../images/Icons/ERxOutlined'
import LocationOutlined from '../../../images/Icons/LocationOutlined'

import styles from '../../../stories/Labs/LabsResultsTable.module.scss'

type IconsComponent = {
  [key: string]: ForwardRefExoticComponent<any> | any
}

const components: IconsComponent = { ...Icons }

export const getIcon = (
  icon: string
): ((props: any) => JSX.Element) | undefined => {
  if (icon === 'ERxOutlined') {
    return ERxOutlined
  }
  if (icon === 'LocationOutlined') {
    return LocationOutlined
  }

  return components[icon as string]
}

type MenuIconProps = {
  icon?: ReturnType<typeof getIcon>
  hasLeftIndicator: boolean
  hasTopLeftIndicator: boolean
}

export const MenuIcon = ({
  icon: Icon,
  hasLeftIndicator,
  hasTopLeftIndicator,
}: MenuIconProps) => {
  if (!Icon) {
    return null
  }
  return (
    <>
      {hasTopLeftIndicator && (
        <div className={styles.statusContainer} style={{ marginLeft: '-16px' }}>
          <div role="alert" className={styles.statusDotViolet} />
        </div>
      )}
      {hasLeftIndicator && (
        <div
          className={styles.statusContainer}
          style={{
            position: 'absolute',
            marginLeft: '-16px',
            marginTop: '22px',
          }}
        >
          <div role="alert" className={styles.statusDotViolet} />
        </div>
      )}

      <Icon className="anticon anticon-home anticon-menu-item" />
    </>
  )
}
