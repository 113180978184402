import React from 'react'

import 'moment-timezone'

import { Allergy, Diagnosis } from '../../../../../../shared-types'
import SpravatoAssessmentPlan from './SpravatoAssessmentPlan'
import SpravatoAssessmentPreparations from './SpravatoAssessmentPreparations'
import SpravatoDischarge from './SpravatoDischarge'
import SpravatoHealthcareSettings from './SpravatoHealthcareSettings'
import SpravatoMonitoring from './SpravatoMonitoring'
import SpravatoTreatment from './SpravatoTreatment'

import 'react-dates/lib/css/_datepicker.css'

export const generateSpravatoClassName = (
  addFormInputClassName?: boolean,
  addDisabledClassName?: boolean
) => {
  const spravatoInputClassnames = ['spravato-incomplete']
  if (addFormInputClassName) {
    spravatoInputClassnames.push('form-input')
  }
  if (addDisabledClassName) {
    spravatoInputClassnames.push('spravato-disabled')
  }
  return spravatoInputClassnames.join(' ')
}

interface AddSpravatoProps {
  disabled: boolean
  fields: any
  detail: string
  patient: any
  Allergies: Allergy[]
  Diagnosis: Diagnosis[]
  activeMeds: any[]
  pregnantStatus: string
  saveNewEntriesSpravato: () => void
  previousNotes: any[]
  saveEditAdditionalEntries: (index: number, editVitSign: any) => void
  saveAdditionalMedicationsDissociation: () => void
  saveEditEntries: (index: number, editVitSign: any) => void
  deleteEntries: (index: number) => void
  deleteAdditionalEntries: () => void
  saveEditAdditionalMedication: (index: number, editVitSign: any) => void
  deleteAM: (index: number) => void
  saveEditAdditionalMedicationSedation: (
    index: number,
    editVitSign: any
  ) => void
  deleteAMSedation: (index: number) => void
  saveEditAdditionalMedicationDissociation: (
    index: number,
    editVitSign: any
  ) => void
  deleteAMDissociation: (index: number) => void
  deleteVitals: (index: number) => void
  setAddVitals: () => void
  saveNewVitals: () => void
  saveNewCaretakerVitals: () => void
  saveAdditionalMedicationsSedation: () => void
  saveNewAdditionalEntriesSpravato: () => void
  saveNewMedicationSpravato: () => void
  saveEditVitalSigns: (index: number, editVitSign: any) => void
  handleFieldChange: (obj: any) => void
  saveStartSpravatoVitals: (editVitSign: any) => void
  saveFortyMinSpravatoVitals: (editVitSign: any) => void
  saveEndSpravatoVitals: (editVitSign: any) => void
}

export default function Spravato(props: AddSpravatoProps) {
  return (
    <div style={{ position: 'relative' }}>
      <div className="alert-clinical-notes clinical-note-header">
        Healthcare provider and healthcare setting
      </div>
      <SpravatoHealthcareSettings
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        disabled={props.disabled}
      />
      <div className="alert-clinical-notes clinical-note-header">
        Assessment and preparations
      </div>
      <SpravatoAssessmentPreparations
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        shortPublicId={props.patient.ShortPublicId}
        previousNotes={props.previousNotes}
        saveNewVitals={props.saveNewVitals}
        saveEditEntries={props.saveEditEntries}
        deleteEntries={props.deleteEntries}
        pregnantStatus={props.pregnantStatus}
        saveNewEntriesSpravato={props.saveNewEntriesSpravato}
        saveNewCaretakerVitals={props.saveNewCaretakerVitals}
        saveEditVitalSigns={props.saveEditVitalSigns}
        setAddVitals={props.setAddVitals}
        deleteVitals={props.deleteVitals}
        disabled={props.disabled}
        detail={props.detail}
        Medical
        Spravato
      />
      <div className="alert-clinical-notes clinical-note-header">
        Spravato Treatment
      </div>
      <SpravatoTreatment
        fields={props.fields}
        disabled={props.disabled}
        saveStartSpravatoVitals={props.saveStartSpravatoVitals}
        handleFieldChange={props.handleFieldChange}
        handleTimeChange={(value) => {
          props.handleFieldChange({ ...props.fields, SpravatoStartTime: value })
        }}
      />
      <div className="alert-clinical-notes clinical-note-header">
        Monitoring
      </div>
      <SpravatoMonitoring
        shortPublicId={props.patient.ShortPublicId}
        fields={props.fields}
        deleteVitals={props.deleteVitals}
        saveEditEntries={props.saveEditEntries}
        deleteEntries={props.deleteEntries}
        saveEditAdditionalEntries={props.saveEditAdditionalEntries}
        deleteAdditionalEntries={props.deleteAdditionalEntries}
        saveEditAdditionalMedication={props.saveEditAdditionalMedication}
        deleteAM={props.deleteAM}
        saveEditAdditionalMedicationSedation={
          props.saveEditAdditionalMedicationSedation
        }
        deleteAMSedation={props.deleteAMSedation}
        saveEditAdditionalMedicationDissociation={
          props.saveEditAdditionalMedicationDissociation
        }
        deleteAMDissociation={props.deleteAMDissociation}
        handleFieldChange={props.handleFieldChange}
        detail={props.detail}
        disabled={props.disabled}
        saveEditVitalSigns={props.saveEditVitalSigns}
        saveNewCaretakerVitals={props.saveNewCaretakerVitals}
        saveAdditionalMedicationsDissociation={
          props.saveAdditionalMedicationsDissociation
        }
        setAddVitals={props.setAddVitals}
        saveNewVitals={props.saveNewVitals}
        saveFortyMinSpravatoVitals={props.saveFortyMinSpravatoVitals}
        saveEndSpravatoVitals={props.saveEndSpravatoVitals}
        saveAdditionalMedicationsSedation={
          props.saveAdditionalMedicationsSedation
        }
        saveNewAdditionalEntriesSpravato={
          props.saveNewAdditionalEntriesSpravato
        }
        saveNewMedicationSpravato={props.saveNewMedicationSpravato}
      />
      <div className="alert-clinical-notes clinical-note-header">
        Assessment and Plan
      </div>
      <SpravatoAssessmentPlan
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        disabled={props.disabled}
      />
      <div className="alert-clinical-notes clinical-note-header">Discharge</div>
      <SpravatoDischarge
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        detail={props.detail}
        disabled={props.disabled}
      />
    </div>
  )
}
