import { Teammate } from '../../shared-types'

export const fakeActiveTeammate: Teammate[] = [
  {
    name: 'Micah Cohen',
    email: 'Micah@gmail.com',
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-1',
    isDeactivated: false,
    isPrimaryUser: true,
    amdProfileId: '9',
    phone: `5329298913`,
    billingNPI: '12312312',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Angelica Matthews',
    email: 'Angelica@gmail.com',
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-2',
    isDeactivated: false,
    isPrimaryUser: false,
    amdProfileId: '9',
    phone: `5329292313`,
    billingNPI: '23456',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Darryl Fuller',
    email: 'Darryl@gmail.com',
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-3',
    isDeactivated: false,
    isPrimaryUser: false,
    amdProfileId: '9',
    phone: `5329292313`,
    billingNPI: '5634234',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Alison Nichols',
    email: 'Alison@gmail.com',
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-4',
    isDeactivated: false,
    isPrimaryUser: false,
    amdProfileId: '9',
    phone: `5329292313`,
    billingNPI: '4564312',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Precious Burke',
    email: 'Precious@gmail.com',
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-5',
    isDeactivated: false,
    isPrimaryUser: false,
    amdProfileId: '9',
    phone: `5329292313`,
    billingNPI: '534',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Trystan Giles',
    email: 'Trystan@gmail.com',
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-6',
    isDeactivated: false,
    isPrimaryUser: false,
    amdProfileId: '9',
    phone: `5329292313`,
    billingNPI: '234',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Zane Noble',
    email: 'Zane@gmail.com',
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-7',
    isDeactivated: false,
    isPrimaryUser: false,
    amdProfileId: '9',
    phone: `5329292313`,
    billingNPI: '6342345',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Valentino Braun',
    email: 'Valentino@gmail.com',
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-8',
    isDeactivated: false,
    isPrimaryUser: false,
    amdProfileId: '9',
    phone: `42329292313`,
    billingNPI: '123',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Miracle Osborne',
    email: 'Miracle@gmail.com',
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-2',
    isDeactivated: false,
    isPrimaryUser: false,
    amdProfileId: '9',
    phone: `1239292313`,
    billingNPI: '23464576',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Rocco Curtis',
    email: 'Rocco@gmail.com',
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-9',
    isDeactivated: false,
    isPrimaryUser: false,
    amdProfileId: '9',
    phone: `5323452313`,
    billingNPI: '32466',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Madison Bird',
    email: 'Madison@gmail.com',
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-10',
    isDeactivated: false,
    isPrimaryUser: false,
    amdProfileId: '9',
    phone: `5214292313`,
    billingNPI: '76786',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Isai Hardy',
    email: 'Isai@gmail.com',
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-11',
    isDeactivated: false,
    isPrimaryUser: false,
    amdProfileId: '9',
    phone: `5322132313`,
    billingNPI: '76786',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
]

export const fakeDeavtivatedTeammate: Teammate[] = [
  {
    name: 'Dennis Sanchez',
    email: 'Dennis@gmail.com',
    phone: `5329292313`,
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-12',
    isDeactivated: true,
    isPrimaryUser: false,
    billingNPI: '76786',
    amdProfileId: '9',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Paul Vargas',
    email: 'Paul@gmail.com',
    phone: `5329292313`,
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-13',
    isDeactivated: true,
    isPrimaryUser: false,
    billingNPI: '32532',
    amdProfileId: '9',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Aaliyah Villanueva',
    email: 'Aaliyah@gmail.com',
    phone: `5329292313`,
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-14',
    isDeactivated: true,
    isPrimaryUser: false,
    billingNPI: '36445',
    amdProfileId: '9',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Tianna Cooper',
    email: 'Tianna@gmail.com',
    phone: `5329292313`,
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-15',
    isDeactivated: true,
    isPrimaryUser: false,
    billingNPI: '465436',
    amdProfileId: '9',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
  {
    name: 'Bryan Shelton',
    email: 'Bryan@gmail.com',
    phone: `5329292313`,
    lastActive: new Date().toISOString(),
    cognitoId: 'cognito-id-16',
    isDeactivated: true,
    isPrimaryUser: false,
    billingNPI: '234324',
    amdProfileId: '9',
    credential: 'MD',
    roleTitles: [],
    roles: {
      adminUsers: 'WRITE',
      nonAdminUsers: 'WRITE',
      notes: 'WRITE',
      renderServices: 'WRITE',
      labsServices: 'WRITE',
      erx: 'WRITE',
      reports: 'WRITE',
      zoom: 'WRITE',
    },
  },
]
