import React from 'react'

import { Typography } from 'antd'
import cx from 'classnames'

import styles from './Text.module.scss'

const AntDText = Typography.Text
type TextProps = {
  header?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
} & React.ComponentProps<typeof AntDText>

const Text: React.FC<TextProps> = ({
  children,
  className,
  header,
  ...props
}) => {
  return (
    <AntDText
      className={cx(
        className,
        { [styles.header]: !!header }, // classname: .header for defaults
        header && styles[header] // classname: .h1, .h2, etc. for font-size
      )}
      {...props}
    >
      {children}
    </AntDText>
  )
}

export default Text
