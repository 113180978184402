import React from 'react'

import 'moment-timezone'
import { Col, Form } from 'react-bootstrap'
import 'react-dates/initialize'

import { NoteTypes } from '../../../../../shared-types'
import { AutoSizingTextArea } from '../../../../../stories/BaseComponents/TextAreaInput'
import ComprehensiveMedicalROS from './ComprehensiveMedicalROS'
import KetamineSpecificROS from './KetamineSpecificROS'
import MentalStatusExam from './MSE'
import PhysicalExam from './PhysicalExam'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function AssessmentPreparations(props) {
  const { Spravato, disabled, handleFieldChange, pregnantStatus, KAP, NPO } =
    props

  const {
    NoteType,
    SuicidalIdeation,
    SuicidalPlan,
    SuicidalIntent,
    SuicidalFrequency,
    SuicidalDetails,
    BladderSx,
    BladderExplanation,
    PregnantStatus,
    Exam,
    ROS,
    AdditionalInformation,
    PatientTreatmentConsent,
    MedicalHistoyPhysicalExam,
    RisksAndQuestions,
    NPOhours,
    MonitorEKG,
    MonitorNIBP,
    MonitorOther,
    MonitorSpO2,
    EquipmentCheck,
    NPO: NPOField,
    MonitorOtherText,
    OtherAdditionalInformation,
  } = props.fields

  const isNotInSpravatoNotes = !Spravato
  return (
    <div>
      {isNotInSpravatoNotes && (
        <div
          className="alert-clinical-notes"
          style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
        >
          Assessment and Preparations
        </div>
      )}
      <br />
      <Form.Row>
        <Form.Group as={Col} sm={12} md={4} lg={2} controlId="SuicidalIdeation">
          <Form.Label style={{ fontWeight: 'bold' }}>
            Suicidal ideation
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(SuicidalIdeation)}
            value="true"
            id="SuicidalIdeationYes"
            disabled={disabled}
            onChange={(e) =>
              handleFieldChange({
                ...props.fields,
                SuicidalIdeation: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(SuicidalIdeation) === false}
            value="false"
            id="SuicidalIdeationNo"
            disabled={disabled}
            onChange={(e) =>
              handleFieldChange({
                ...props.fields,
                SuicidalIdeation: e.target.value,
                SuicidalPlan: null,
                SuicidalIntent: null,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {String(SuicidalIdeation) === 'true' && (
          <>
            <Form.Group as={Col} sm={6} md={4} lg={2} controlId="SuicidalPlan">
              <Form.Label style={{ fontWeight: 'bold' }}>Plan?</Form.Label>
              <Form.Check
                bsPrefix="form-check"
                checked={JSON.parse(SuicidalPlan)}
                value="true"
                id="SuicidalPlanYes"
                disabled={disabled}
                onChange={(e) =>
                  handleFieldChange({
                    ...props.fields,
                    SuicidalPlan: e.target.value,
                  })
                }
                type="radio"
                label="Yes"
              />
              <Form.Check
                bsPrefix="form-check"
                checked={JSON.parse(SuicidalPlan) === false}
                value="false"
                id="SuicidalPlanNo"
                disabled={disabled}
                onChange={(e) =>
                  handleFieldChange({
                    ...props.fields,
                    SuicidalPlan: e.target.value,
                  })
                }
                type="radio"
                label="No"
              />
            </Form.Group>
            <Form.Group
              as={Col}
              sm={6}
              md={4}
              lg={2}
              controlId="SuicidalIntent"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>Intent?</Form.Label>
              <Form.Check
                bsPrefix="form-check"
                checked={JSON.parse(SuicidalIntent)}
                value="true"
                id="SuicidalIntentYes"
                disabled={disabled}
                onChange={(e) =>
                  handleFieldChange({
                    ...props.fields,
                    SuicidalIntent: e.target.value,
                  })
                }
                type="radio"
                label="Yes"
              />
              <Form.Check
                bsPrefix="form-check"
                checked={JSON.parse(SuicidalIntent) === false}
                value="false"
                id="SuicidalIntentNo"
                disabled={disabled}
                onChange={(e) =>
                  handleFieldChange({
                    ...props.fields,
                    SuicidalIntent: e.target.value,
                  })
                }
                type="radio"
                label="No"
              />
            </Form.Group>
            <Form.Group
              as={Col}
              sm={12}
              md={6}
              lg={2}
              controlId="SuicidalFrequency"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>Frequency</Form.Label>
              <Form.Control
                bsPrefix="form-input"
                value={SuicidalFrequency}
                disabled={disabled}
                onChange={(e) =>
                  handleFieldChange({
                    ...props.fields,
                    SuicidalFrequency: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group
              as={Col}
              sm={12}
              md={6}
              lg={4}
              controlId="SuicidalDetails"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>Details</Form.Label>
              <Form.Control
                bsPrefix="form-input"
                value={SuicidalDetails}
                onChange={(e) =>
                  handleFieldChange({
                    ...props.fields,
                    SuicidalDetails: e.target.value,
                  })
                }
                disabled={disabled}
                as="textarea"
                rows="1"
              />
            </Form.Group>
          </>
        )}
      </Form.Row>
      {String(SuicidalIdeation) === 'true' && isNotInSpravatoNotes && <hr />}
      <Form.Row>
        <Form.Group as={Col} sm={12} md={3} lg={3} controlId="BladderSx">
          <Form.Label style={{ fontWeight: 'bold' }}>Bladder sx</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(BladderSx)}
            value="true"
            id="BladderSxYes"
            disabled={disabled}
            onChange={(e) =>
              handleFieldChange({
                ...props.fields,
                BladderSx: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(BladderSx) === false}
            value="false"
            id="BladderSxNo"
            disabled={disabled}
            onChange={(e) =>
              handleFieldChange({
                ...props.fields,
                BladderSx: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(BladderSx) && (
          <Form.Group
            as={Col}
            sm={12}
            md={9}
            lg={6}
            controlId="BladderExplanation"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>Details</Form.Label>
            <Form.Control
              bsPrefix="form-input"
              value={BladderExplanation}
              disabled={disabled}
              onChange={(e) =>
                handleFieldChange({
                  ...props.fields,
                  BladderExplanation: e.target.value,
                })
              }
            />
          </Form.Group>
        )}
      </Form.Row>
      {JSON.parse(BladderSx) && isNotInSpravatoNotes && <hr />}
      <Form.Row>
        <Form.Group as={Col} sm={12} md={2} lg={2} controlId="PregnantStatus">
          <Form.Label style={{ fontWeight: 'bold' }}>Pregnant</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="select"
            disabled={disabled}
            onChange={(e) =>
              handleFieldChange({
                ...props.fields,
                PregnantStatus: e.target.value,
              })
            }
            value={PregnantStatus}
          >
            <option key={0} name="N/A" value="N/A">
              N/A
            </option>
            {pregnantStatus.map((pregStatus, index) => (
              <option key={index + 1} name={pregStatus} value={pregStatus}>
                {pregStatus}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {isNotInSpravatoNotes && (
          <>
            {ROS && (
              <Form.Group as={Col} sm={12} md={5} lg={5} controlId="ROS">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  {KAP ? 'Medical ROS' : 'ROS'}
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  value={ROS}
                  disabled={disabled}
                  onChange={(e) =>
                    handleFieldChange({
                      ...props.fields,
                      ROS: e.target.value,
                    })
                  }
                  as="textarea"
                  rows="1"
                />
              </Form.Group>
            )}
            {Exam && (
              <Form.Group as={Col} sm={12} md={5} lg={5} controlId="Exam">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  {KAP ? 'Physical exam' : 'Exam'}
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  value={Exam}
                  disabled={disabled}
                  onChange={(e) =>
                    handleFieldChange({
                      ...props.fields,
                      Exam: e.target.value,
                    })
                  }
                  as="textarea"
                  rows="1"
                />
              </Form.Group>
            )}
          </>
        )}
      </Form.Row>
      {[NoteTypes.IV_KETAMINE, NoteTypes.IM_KETAMINE, NoteTypes.KAP].includes(
        NoteType
      ) && (
        <>
          <ComprehensiveMedicalROS {...props} />
          <MentalStatusExam {...props} />
          <PhysicalExam {...props} />
          <KetamineSpecificROS {...props} />
        </>
      )}

      {isNotInSpravatoNotes && (
        <>
          <Form.Row>
            <Form.Group
              as={Col}
              xs={12}
              sm={12}
              lg={12}
              xl={12}
              controlId="AdditionalInformation"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>
                Additional information
              </Form.Label>
              <Form.Control
                bsPrefix="form-control"
                className="field-value-edit"
                value={AdditionalInformation}
                placeholder={
                  props.detail
                    ? ''
                    : 'Dosing session number, general impression, progress, etc.'
                }
                disabled={disabled}
                onChange={(e) =>
                  handleFieldChange({
                    ...props.fields,
                    AdditionalInformation: e.target.value,
                  })
                }
                as={AutoSizingTextArea}
                minRows={1}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={12} lg={6} xl={6}>
              <Form.Check
                type="checkbox"
                id="PatientTreatmentConsent"
                checked={JSON.parse(PatientTreatmentConsent)}
                label="Patient identified and treatment consent provided"
                style={{ fontWeight: 'bold' }}
                disabled={disabled}
                onChange={() =>
                  handleFieldChange({
                    ...props.fields,
                    PatientTreatmentConsent: !PatientTreatmentConsent,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={12} lg={6} xl={6}>
              <Form.Check
                type="checkbox"
                id="MedicalHistoyPhysicalExam"
                checked={JSON.parse(MedicalHistoyPhysicalExam)}
                style={{ fontWeight: 'bold' }}
                disabled={disabled}
                label="Medical history/Physical exam reviewed"
                onChange={() =>
                  handleFieldChange({
                    ...props.fields,
                    MedicalHistoyPhysicalExam: !MedicalHistoyPhysicalExam,
                  })
                }
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={12} lg={6} xl={6}>
              <Form.Check
                type="checkbox"
                id="RisksAndQuestions"
                checked={JSON.parse(RisksAndQuestions)}
                style={{ fontWeight: 'bold' }}
                disabled={disabled}
                label="Provider discussed risks and benefits and answered all patient questions"
                onChange={() =>
                  handleFieldChange({
                    ...props.fields,
                    RisksAndQuestions: !RisksAndQuestions,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={12} lg={6} xl={6}>
              <Form.Check
                type="checkbox"
                id="EquipmentCheck"
                checked={JSON.parse(EquipmentCheck)}
                style={{ fontWeight: 'bold' }}
                disabled={disabled}
                label="Equipment check"
                onChange={() =>
                  handleFieldChange({
                    ...props.fields,
                    EquipmentCheck: !EquipmentCheck,
                  })
                }
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={12} lg={1} xl={1}>
              <Form.Check
                type="checkbox"
                id="NPO"
                checked={JSON.parse(NPOField)}
                style={{ fontWeight: 'bold' }}
                disabled={disabled}
                label="NPO"
                onChange={() =>
                  handleFieldChange({
                    ...props.fields,
                    NPO: !NPOField,
                  })
                }
              />
            </Form.Group>
            {NPO && (
              <Form.Group
                as={Col}
                xs={12}
                sm={12}
                lg={2}
                xl={2}
                controlId="NPOhours"
              >
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Number of hours
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  type="number"
                  disabled={disabled}
                  value={NPOhours}
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    handleFieldChange({
                      ...props.fields,
                      NPOhours: e.target.value,
                    })
                  }
                />
              </Form.Group>
            )}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={12} lg={2} xl={2}>
              <Form.Label style={{ fontWeight: 'bold' }}>Monitors: </Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={12} lg={1} xl={1}>
              <Form.Check
                type="checkbox"
                id="MonitorNIBP"
                checked={JSON.parse(MonitorNIBP)}
                style={{ fontWeight: 'bold' }}
                disabled={disabled}
                label="NIBP"
                onChange={() =>
                  handleFieldChange({
                    ...props.fields,
                    MonitorNIBP: !MonitorNIBP,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={12} lg={1} xl={1}>
              <Form.Check
                type="checkbox"
                id="MonitorSpO2"
                checked={JSON.parse(MonitorSpO2)}
                style={{ fontWeight: 'bold' }}
                disabled={disabled}
                label="SpO2"
                onChange={() =>
                  handleFieldChange({
                    ...props.fields,
                    MonitorSpO2: !MonitorSpO2,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={12} lg={1} xl={1}>
              <Form.Check
                type="checkbox"
                id="MonitorEKG"
                checked={JSON.parse(MonitorEKG)}
                style={{ fontWeight: 'bold' }}
                disabled={disabled}
                label="EKG"
                onChange={() =>
                  handleFieldChange({
                    ...props.fields,
                    MonitorEKG: !MonitorEKG,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={12} lg={1} xl={1}>
              <Form.Check
                type="checkbox"
                id="MonitorOther"
                checked={JSON.parse(MonitorOther)}
                style={{ fontWeight: 'bold' }}
                disabled={disabled}
                label="Other"
                onChange={() =>
                  handleFieldChange({
                    ...props.fields,
                    MonitorOther: !MonitorOther,
                  })
                }
              />
            </Form.Group>
            {MonitorOther && (
              <Form.Group
                as={Col}
                xs={12}
                sm={12}
                lg={6}
                xl={6}
                controlId="MonitorOtherText"
              >
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Other (monitor/s)
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  value={MonitorOtherText}
                  disabled={disabled}
                  onChange={(e) =>
                    handleFieldChange({
                      ...props.fields,
                      MonitorOtherText: e.target.value,
                    })
                  }
                />
              </Form.Group>
            )}
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              xs={12}
              sm={12}
              lg={12}
              xl={12}
              controlId="OtherAdditionalInformation"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>Other</Form.Label>
              <Form.Control
                bsPrefix="form-control"
                className="field-value-edit"
                disabled={disabled}
                value={OtherAdditionalInformation}
                onChange={(e) =>
                  handleFieldChange({
                    ...props.fields,
                    OtherAdditionalInformation: e.target.value,
                  })
                }
                as={AutoSizingTextArea}
                minRows={1}
              />
            </Form.Group>
          </Form.Row>
        </>
      )}
    </div>
  )
}
