import React from 'react'

import OsmindLogo from '../../images/OsmindLogo'
import { Card } from '../BaseComponents'

import './LogoCard.scss'

interface LogoCardProps {
  children: React.ReactNode
  id?: string
  title?: string
}

export default function LogoCard({
  children,
  id = '',
  title = '',
}: LogoCardProps) {
  return (
    <Card id={id} className="logo-card">
      <OsmindLogo className="logo-card-logo" />
      <div className="logo-card-title">{title}</div>
      {children}
    </Card>
  )
}
