import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const blackOrAfricanAmericanSubRaceCDC: PatientDemographicObject[] = [
  { '2054-5': { title: 'Black or African American' } },
  { '2056-0': { title: 'Black' } },
  { '2058-6': { title: 'African American' } },
  { '2060-2': { title: 'African' } },
  { '2067-7': { title: 'Bahamian' } },
  { '2068-5': { title: 'Barbadian' } },
  { '2069-3': { title: 'Dominican' } },
  { '2070-1': { title: 'Dominica Islander' } },
  { '2071-9': { title: 'Haitian' } },
  { '2072-7': { title: 'Jamaican' } },
  { '2073-5': { title: 'Tobagoan' } },
  { '2074-3': { title: 'Trinidadian' } },
  { '2075-0': { title: 'West Indian' } },
  { '2061-0': { title: 'Botswanan' } },
  { '2062-8': { title: 'Ethiopian' } },
  { '2063-6': { title: 'Liberian' } },
  { '2064-4': { title: 'Namibian' } },
  { '2065-1': { title: 'Nigerian' } },
  { '2066-9': { title: 'Zairean' } },
]

export const blackOrAfricanAmericanSubRaceDropDownValues =
  constructPatientDemographicObject(
    blackOrAfricanAmericanSubRaceCDC,
    CodeSystemOIDs.PHVSRaceCategoryCDCRef,
    Categories.BLACK_OR_AFRICAN_AMERICAN_SUB_RACE
  )
