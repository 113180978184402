import React, { useEffect, useState } from 'react'

import 'moment-timezone'
import { Button, Col, Form, Row } from 'react-bootstrap'
import 'react-dates/initialize'

import { convertTime12to24 } from '../../../../../shared/Helpers/utils'
import CustomTimePicker from '../../../../../stories/BaseComponents/CustomTimePicker'
import { AutoSizingTextArea } from '../../../../../stories/BaseComponents/TextAreaInput'
import { calculateRelativeDose, calculateTotalDose } from '../calculations'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function ShowEntriesKAP(props) {
  const [time, setTime] = useState(props.Time || '')
  const [dose, setDose] = useState(props.Dose || '')
  const [totalDose, setTotalDose] = useState(props.TotalDose || '')
  const [location, setLocation] = useState(props.Location || '')
  const [notes, setNotes] = useState(props.Notes || '')
  const [route, setRoute] = useState(props.Route || '')
  const [routeOther, setRouteOther] = useState(props.RouteOther || '')
  const [medicationType, setMedicationType] = useState(
    props.MedicationType || ''
  )
  const [medicationTypeOther, setMedicationTypeOther] = useState(
    props.MedicationTypeOther || ''
  )

  const routes = ['IM', 'Sublingual', 'Oral', 'Other (type)']
  const medTypes = ['Dissolving wafer', 'Troche', 'Other (type)']

  useEffect(() => {
    setTime(props.Time || '')
    setDose(props.Dose || '')
    setTotalDose(props.TotalDose || '')
    setLocation(props.Location || '')
    setNotes(props.Notes || '')
    setRoute(props.Route || '')
    setRouteOther(props.RouteOther || '')
    setMedicationType(props.MedicationType || '')
    setMedicationTypeOther(props.MedicationTypeOther || '')
  }, [
    props.Time,
    props.Dose,
    props.TotalDose,
    props.Location,
    props.Notes,
    props.Route,
    props.RouteOther,
    props.MedicationType,
    props.MedicationTypeOther,
  ])

  useEffect(() => {
    const fields = {
      Time: time,
      Dose: dose,
      TotalDose: totalDose,
      Location: location,
      Notes: notes,
      Route: route,
      RouteOther: routeOther,
      MedicationType: medicationType,
      MedicationTypeOther: medicationTypeOther,
    }
    props.saveEditEntries(props.index, fields)
  }, [
    time,
    dose,
    totalDose,
    location,
    notes,
    route,
    routeOther,
    medicationType,
    medicationTypeOther,
  ])

  return (
    <>
      <tr>
        <td>
          <CustomTimePicker
            value={time}
            onChange={(value) => {
              setTime(convertTime12to24(value))
            }}
            disabled={props.disabled}
          />
        </td>
        <td>
          {route !== 'Other (type)' ? (
            <Form.Control
              bsPrefix="form-input center"
              style={{ alignSelf: 'center', textAlign: 'center' }}
              as="select"
              disabled={props.disabled}
              onChange={(e) => setRoute(e.target.value)}
              value={route}
            >
              <option key={0} name="Select" value="">
                Select one
              </option>
              {routes.map((rout, index) => (
                <option key={index + 1} name={rout} value={rout}>
                  {rout}
                </option>
              ))}
            </Form.Control>
          ) : (
            <Form.Control
              bsPrefix="form-input center"
              style={{ alignSelf: 'center', textAlign: 'center' }}
              value={routeOther}
              disabled={props.disabled}
              onChange={(e) => setRouteOther(e.target.value)}
            />
          )}
        </td>
        <td>
          {!totalDose ? (
            <Form.Control
              bsPrefix="form-input center"
              type="number"
              step=".01"
              disabled={props.disabled}
              value={dose}
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(e) => setDose(e.target.value)}
            />
          ) : (
            <Form.Control
              bsPrefix="form-input"
              disabled
              value={
                props.PatientWeight
                  ? props.isBeforeNewDoseCalculation
                    ? calculateTotalDose(
                        totalDose,
                        props.PatientWeight,
                        props.KetamineUnits
                      )
                    : calculateRelativeDose(
                        totalDose,
                        props.PatientWeight,
                        props.KetamineUnits
                      )
                  : ''
              }
            />
          )}
        </td>
        <td>
          {!dose ? (
            <Form.Control
              bsPrefix="form-input center"
              type="number"
              step=".01"
              disabled={props.disabled}
              value={totalDose}
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(e) => setTotalDose(e.target.value)}
            />
          ) : (
            <Form.Control
              bsPrefix="form-input"
              disabled
              value={
                props.PatientWeight
                  ? props.isBeforeNewDoseCalculation
                    ? calculateRelativeDose(
                        dose,
                        props.PatientWeight,
                        props.KetamineUnits
                      )
                    : calculateTotalDose(
                        dose,
                        props.PatientWeight,
                        props.KetamineUnits
                      )
                  : ''
              }
            />
          )}
        </td>
        <td style={{ textAlign: 'center' }}>
          {(route === 'IM' || route === 'Other (type)') && (
            <Form.Control
              bsPrefix="form-input center"
              style={{ alignSelf: 'center', textAlign: 'center' }}
              value={location}
              disabled={props.disabled}
              onChange={(e) => setLocation(e.target.value)}
            />
          )}
        </td>
        <td style={{ textAlign: 'center' }}>
          {route === 'Sublingual' &&
            (medicationType !== 'Other (type)' ? (
              <Form.Control
                bsPrefix="form-input center"
                style={{ alignSelf: 'center', textAlign: 'center' }}
                as="select"
                disabled={props.disabled}
                onChange={(e) => setMedicationType(e.target.value)}
                value={medicationType}
              >
                <option key={0} name="Select" value="">
                  Select one
                </option>
                {medTypes.map((med, index) => (
                  <option key={index + 1} name={med} value={med}>
                    {med}
                  </option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control
                bsPrefix="form-input center"
                style={{ alignSelf: 'center', textAlign: 'center' }}
                value={medicationTypeOther}
                disabled={props.disabled}
                onChange={(e) => setMedicationTypeOther(e.target.value)}
              />
            ))}
        </td>
        <td style={{ textAlign: 'center' }}>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            style={{ alignSelf: 'center', textAlign: 'center' }}
            value={notes}
            as={AutoSizingTextArea}
            minRows={1}
            disabled={props.disabled}
            onChange={(e) => setNotes(e.target.value)}
          />
        </td>
        {!props.disabled && (
          <td className="center">
            <Row>
              <Col>
                <Button
                  bsPrefix="center plain-button info button-label"
                  onClick={() => {
                    props.deleteEntries(props.index)
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true" />
                  &nbsp;Delete
                </Button>
              </Col>
            </Row>
          </td>
        )}
      </tr>
    </>
  )
}
