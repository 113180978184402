import React, { useState } from 'react'

import { CLAIM_STATUS } from '../../../shared-types'
import { ModalBasic } from '../../../stories/BaseComponents'

interface ManualStatusUpdateModalProps {
  claimStatus: CLAIM_STATUS
  claimId?: string
  patientId?: string
  visible: boolean
  setVisible: Function
  setDisplayStatus: Function
  handleUpdateStatus: Function
  testId: string
}

const ManualStatusUpdateModal: React.FC<ManualStatusUpdateModalProps> = ({
  claimStatus,
  claimId,
  patientId,
  visible,
  setVisible,
  setDisplayStatus,
  handleUpdateStatus,
  testId,
  ...props
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const title = 'Confirm claim status update'
  const okText = 'Yes, update status'
  let message = <p></p>
  if (claimStatus === CLAIM_STATUS.DRAFT) {
    message = (
      <div>
        <p>
          Updating to 'Manually submitted' allows payment entry but prevents
          further changes to the claim.
        </p>
        <p>
          Are you sure you want to change the claim status to 'Manually
          submitted'?
        </p>
      </div>
    )
    // Manual adjudication
  } else {
    message = (
      <div>
        <p>
          Are you sure you want to change the claim status to "Adjudicated"?
        </p>
      </div>
    )
  }

  const handleOk = () => {
    if (!claimId || !patientId) {
      throw new Error(
        'claimId and patientId must be defined to update claim status'
      )
    }
    const displayStatuses = {
      [CLAIM_STATUS.MANUALLY_SUBMITTED]: 'Manually submitted',
      [CLAIM_STATUS.MANUALLY_ADJUDICATED]: 'Adjudicated',
    }
    const toStatus =
      claimStatus === CLAIM_STATUS.DRAFT
        ? CLAIM_STATUS.MANUALLY_SUBMITTED
        : CLAIM_STATUS.MANUALLY_ADJUDICATED
    setConfirmLoading(true)
    handleUpdateStatus(toStatus)
      .then(() => {
        setDisplayStatus(displayStatuses[toStatus])
      })
      .finally(() => {
        setConfirmLoading(false)
        setVisible(false)
      })
  }
  const handleCancel = () => {
    setVisible(false)
  }
  return (
    <ModalBasic
      confirmButtonLabel={okText}
      visible={visible}
      title={title}
      onConfirm={handleOk}
      onCancel={handleCancel}
      confirmButtonProps={{ disabled: confirmLoading }}
      cancelButtonProps={{ disabled: confirmLoading }}
      testId={testId}
      {...props}
    >
      {message}
    </ModalBasic>
  )
}

export default ManualStatusUpdateModal
