import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const nativeHawaiianSubRaceCDC: PatientDemographicObject[] = [
  { '2078-4': { title: 'Polynesian' } },
  { '2085-9': { title: 'Micronesian' } },
  { '2100-6': { title: 'Melanesian' } },
  { '2500-7': { title: 'Other Pacific Islander' } },
  { '2079-2': { title: 'Native Hawaiian' } },
  { '2080-0': { title: 'Samoan' } },
  { '2081-8': { title: 'Tahitian' } },
  { '2082-6': { title: 'Tongan' } },
  { '2083-4': { title: 'Tokelauan' } },
  { '2086-7': { title: 'Guamanian or Chamorro' } },
  { '2087-5': { title: 'Guamanian' } },
  { '2088-3': { title: 'Chamorro' } },
  { '2089-1': { title: 'Mariana Islander' } },
  { '2090-9': { title: 'Marshallese' } },
  { '2091-7': { title: 'Palauan' } },
  { '2092-5': { title: 'Carolinian' } },
  { '2093-3': { title: 'Kosraean' } },
  { '2094-1': { title: 'Pohnpeian' } },
  { '2095-8': { title: 'Saipanese' } },
  { '2096-6': { title: 'Kiribati' } },
  { '2097-4': { title: 'Chuukese' } },
  { '2098-2': { title: 'Yapese' } },
  { '2101-4': { title: 'Fijian' } },
  { '2102-2': { title: 'Papua New Guinean' } },
  { '2103-0': { title: 'Solomon Islander' } },
  { '2104-8': { title: 'New Hebrides' } },
]

export const nativeHawaiianSubRaceDropDownValues =
  constructPatientDemographicObject(
    nativeHawaiianSubRaceCDC,
    CodeSystemOIDs.PHVSRaceCategoryCDCRef,
    Categories.NATIVE_HAWAIIAN_SUB_RACE
  )
