import { Button, Modal } from '../../../../stories/BaseComponents'
import { DynamicBlockTypes } from '../Blocks/Core/Block/DynamicBlockTypes'

import styles from './RefreshConfirmationModal.module.scss'

type RefreshConfirmationModalProps = {
  isVisible: boolean
  modalType: DynamicBlockTypes
  onConfirm: () => void
  onCancel: () => void
}

const modalTypeToTextMapping: Record<DynamicBlockTypes, string> = {
  DIAGNOSIS:
    'Refreshing will update the table to include all active diagnoses from the patient chart.',
  MEDICATIONS:
    'Refreshing will update the table to include all active medications from the patient chart.',
  ALLERGIES:
    'Refreshing will update the table to include all active allergies from the patient chart.',
} as const

export const modalTypeToModalHeadingMapping: Record<DynamicBlockTypes, string> =
  {
    DIAGNOSIS: 'diagnoses',
    MEDICATIONS: 'medications',
    ALLERGIES: 'allergies',
  } as const

export const RefreshConfirmationModal = ({
  isVisible,
  modalType,
  onConfirm,
  onCancel,
}: RefreshConfirmationModalProps) => {
  return (
    <Modal
      visible={isVisible}
      hasOkButton={false}
      hasCloseButton={false}
      footer={null}
      closable={false}
      width={400}
    >
      <div className={styles.container}>
        <h2 className={styles.refreshText}>
          Refresh {modalTypeToModalHeadingMapping[modalType]}
        </h2>
        <p>{modalTypeToTextMapping[modalType]}</p>
        <div className={styles.buttonContainer}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onConfirm} type="primary">
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  )
}
