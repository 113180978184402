import React from 'react'

import { useFormikContext } from 'formik'

import {
  Button,
  CollapsibleCard,
  TextArea,
} from '../../../../stories/BaseComponents'
import { ClaimForm, ClaimFormStatus } from '../types'
import { isFormBusy, isFormSavingMemo } from '../utils'

import styles from './Memo.module.scss'

type MemoProps = {
  onSave: () => void
  initialValue: string | null
  formStatus: ClaimFormStatus
}

export const testIds = {
  container: 'claim-memo',
  collapseIcon: 'claim-memo-collapse-icon',
  input: 'claim-memo-input',
  cancelButton: 'claim-memo-cancel-button',
  saveButton: 'claim-memo-save-button',
}
const Memo: React.FC<MemoProps> = ({ onSave, initialValue, formStatus }) => {
  const {
    values: { claimMemo },
    setFieldValue,
  } = useFormikContext<ClaimForm>()

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value
    if (newValue) {
      setFieldValue('claimMemo', newValue)
    } else {
      setFieldValue('claimMemo', null)
    }
  }

  const handleCancel = () => {
    setFieldValue('claimMemo', initialValue)
  }

  const isDisabled = isFormBusy(formStatus)
  const isValueSame = claimMemo === initialValue
  return (
    <CollapsibleCard
      title="Claim memo"
      isDefaultOpened
      testId={testIds.container}
      iconTestId={testIds.collapseIcon}
      style={{ marginTop: 24 }}
      bodyStyle={{ paddingBottom: 16 }}
    >
      <TextArea
        className={styles.input}
        placeholder="Add notes"
        value={claimMemo ?? ''}
        onChange={handleChange}
        disabled={isDisabled}
        data-testid={testIds.input}
      />
      <div className={styles.buttonsContainer}>
        <Button
          type="default"
          onClick={handleCancel}
          testId={testIds.cancelButton}
          disabled={isDisabled || isValueSame}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={onSave}
          testId={testIds.saveButton}
          loading={isFormSavingMemo(formStatus)}
          disabled={isDisabled || isValueSame}
        >
          Save
        </Button>
      </div>
    </CollapsibleCard>
  )
}

export default Memo
