import { Button } from '../../../BaseComponents'
import { Mode } from '../EventModal'

export type FooterProps = {
  hasChanges: boolean
  onShowConfirmDiscard: () => void
  onConfirmDelete: () => void
  onCancel: () => void
  isSubmitting: boolean
  isDeleting: boolean
  mode: Mode
}

export const Footer = ({
  hasChanges,
  onShowConfirmDiscard,
  onCancel,
  isSubmitting,
  isDeleting,
  mode,
  onConfirmDelete,
}: FooterProps) => {
  return (
    <>
      {mode === Mode.EDIT && (
        <Button
          danger
          onClick={onConfirmDelete}
          disabled={isSubmitting}
          loading={isDeleting}
        >
          Delete
        </Button>
      )}
      <Button
        key="back"
        onClick={hasChanges ? onShowConfirmDiscard : onCancel}
        disabled={isSubmitting || isDeleting}
      >
        Cancel
      </Button>
      <Button
        key="ok"
        form="eventModal"
        htmlType="submit"
        type="primary"
        disabled={isDeleting}
        loading={isSubmitting}
      >
        Ok
      </Button>
    </>
  )
}
