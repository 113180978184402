import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  StepProps as AntDStepProps,
  Steps as AntDSteps,
  StepsProps as AntDStepsProps,
} from 'antd'

import Button from './Button'

import './Steps.scss'

const { Step } = AntDSteps

interface FooterStyling {
  backClassName?: string
  cancelClassName?: string
  doneClassName?: string
  nextClassName?: string
}

export interface StepType extends AntDStepProps {
  title: string
  content: any
  step: number
}

export interface StepsProps extends AntDStepsProps {
  footerStyling?: FooterStyling
  steps: StepType[]
  hideHeader?: boolean
  className?: string // className will be added to its Step children
  direction?: 'horizontal' | 'vertical'
  initial?: number
  labelPlacement?: 'horizontal' | 'vertical'
  size?: 'default' | 'small'
  isLoading?: boolean
  currentStep?: number
  showBackButton?: boolean
  nextButtonIcon?: React.ReactNode
  nextButtonContent?: React.ReactNode
  leftAlign?: boolean
  onChange?: (value: number) => void
  onBackClick?: () => Promise<void>
  validateBeforeNext?: () => Promise<boolean>
  handleSubmit: () => void
  cancelCallback?: () => void
}

const Steps: React.FC<StepsProps> = ({
  footerStyling = {},
  steps,
  hideHeader = false,
  isLoading = false,
  className = '-',
  direction = 'horizontal',
  initial = 0,
  labelPlacement = 'horizontal',
  size = 'default',
  currentStep = 0,
  showBackButton = true,
  nextButtonContent,
  nextButtonIcon = null,
  leftAlign = false,
  onChange = undefined,
  onBackClick,
  validateBeforeNext,
  handleSubmit,
  cancelCallback,
}) => {
  const {
    backClassName = 'ant-button-item',
    cancelClassName = 'ant-button-item',
    doneClassName = '',
    nextClassName = '',
  } = footerStyling
  const [current, setCurrent] = useState(0)
  const stepsLastItem = useMemo(() => {
    return steps.length - 1
  }, [steps])

  const currentWithInitial = useMemo(() => {
    return current + initial
  }, [current])

  useEffect(() => {
    setCurrent(currentStep)
  }, [currentStep])

  const next = useCallback(async () => {
    if (!validateBeforeNext) return setCurrent(current + 1)
    const success = await validateBeforeNext()
    if (success) return setCurrent(current + 1)
  }, [current, validateBeforeNext])

  const prev = useCallback(async () => {
    if (onBackClick) await onBackClick()
    setCurrent(current - 1)
  }, [current, onBackClick])

  const handleChange = useCallback(
    (value: number) => {
      onChange && onChange(value)
    },
    [onChange]
  )

  const stepControl = useMemo(() => {
    if (onChange) return
    return (
      <div className="steps-action" style={{ marginTop: 'auto' }}>
        {!leftAlign && <hr className="hr" />}
        {current > 0 && showBackButton && (
          <Button
            className={backClassName}
            onClick={prev}
            icon={<ArrowLeftOutlined />}
            loading={isLoading}
          >
            Back
          </Button>
        )}
        {cancelCallback && (
          <Button
            className={cancelClassName}
            onClick={cancelCallback}
            loading={isLoading}
          >
            Cancel
          </Button>
        )}
        {current < stepsLastItem && (
          <Button
            className={nextClassName}
            type="primary"
            onClick={next}
            icon={nextButtonIcon}
            loading={isLoading}
          >
            {nextButtonContent ? nextButtonContent : 'Next'}
          </Button>
        )}
        {current === stepsLastItem && (
          <Button
            className={doneClassName}
            type="primary"
            onClick={handleSubmit}
            loading={isLoading}
          >
            Done
          </Button>
        )}
      </div>
    )
  }, [onChange, current, stepsLastItem, isLoading, showBackButton])

  function isHidden(step: number, current: number) {
    return step !== current + 1 ? 'hidden' : ''
  }

  return (
    <div>
      <AntDSteps
        className={`${className} ${hideHeader ? 'hide-header' : ''}`}
        current={currentWithInitial}
        status={steps[current].status}
        direction={direction}
        initial={initial}
        labelPlacement={labelPlacement}
        size={size}
        onChange={handleChange}
      >
        {steps.map(({ title, subTitle, description, icon }, i) => (
          <Step
            key={`${title}-${i}`}
            title={title}
            subTitle={subTitle}
            description={description}
            icon={icon}
          />
        ))}
      </AntDSteps>
      {steps.map(({ title, step, content }, i) => (
        <div
          key={`${title}-content-${i}`}
          className={`steps-content ${isHidden(step, current)}`}
        >
          {content}
        </div>
      ))}
      {stepControl}
    </div>
  )
}

export default Steps
