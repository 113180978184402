import React, { useRef, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Space } from 'antd'
import {
  FilterConfirmProps,
  FilterDropdownProps,
} from 'antd/es/table/interface'

interface SearchBoxProps extends FilterDropdownProps {
  dataIndex: string
  placeholder: string
}

const SearchBox: React.FC<SearchBoxProps> = ({
  dataIndex,
  placeholder,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const [_searchText, setSearchText] = useState('')
  const [_searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const handleSearch = (
    selectedKeys: React.Key[] | React.SetStateAction<string>[],
    confirm: {
      (param?: FilterConfirmProps | undefined): void
      (param?: FilterConfirmProps | undefined): void
      (): void
    },
    dataIndex: React.SetStateAction<string>
  ) => {
    confirm()
    setSearchText(selectedKeys[0] as string)
    setSearchedColumn(dataIndex)
  }

  // The types from ant design are not great so we need to do an any type.
  const handleReset = (clearFilters: any): void => {
    clearFilters()
    setSearchText('')
  }

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={placeholder}
        // html input element won't accept bigint as a value, but React.Key can be a bigint. cast to string like we do in the handler
        value={
          typeof selectedKeys[0] === 'bigint'
            ? String(selectedKeys[0])
            : selectedKeys[0]
        }
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  )
}

export default SearchBox
