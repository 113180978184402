import { FormSectionKey, OsmindIntakeFormTitle } from '../../api/intakeForms'

export enum ProviderSideIntakeFormEvents {
  CREATED_PATIENT = 'Created Patient', // Event when a new patient is created
  SENT_FORM = 'Sent Form', // Event when a form is sent to the patient in the Documents tab
  RESENT_FORM = 'Resent Intake Form Invite', // Event when a user clicks “Resend Invite” for an intake form in the Documents tab
  REVIEW_AND_IMPORT = 'Review and Import Intake Form', // Event when a user clicks “Review & Import” for an intake form in the Documents tab
  IMPORTED_FORM = 'Imported Intake Form', // Event when a user clicks “Import” after “Review & Import” for an intake form in the Documents tab
  MARKED_REVIEWED = 'Marked Intake Form as Reviewed', // Event when a user clicks “Mark as Reviewed” after “Review & Import” for an intake form in the Documents tab
  VIEWED_FORM_QR = 'Viewed Intake Form QR Code', // Event when a user clicks “Show QR code” for an intake form in the Documents tab
}

export type BaseIntakeFormEvent = {
  osmindIntakeId: string
}

export type SendIntakeFormEvent = BaseIntakeFormEvent & {
  osmindIntakeName: string
  osmindIntakeFormSections: string[]
  hellosignFormNames: string[]
  sentFormTypes: Array<typeof OsmindIntakeFormTitle | 'HelloSign'>
}

export type SendIntakeFormWithPatientInviteEvent = SendIntakeFormEvent & {
  providerId: string
  patientId: string
}

export type IntakeFormViewEvent = BaseIntakeFormEvent & {
  osmindIntakeFormSections: string[]
}

export type IntakeFormNavigationEvent = BaseIntakeFormEvent & {
  navigatedTo?: string
  returnedTo?: string
}

type IntakeEventProperties =
  | BaseIntakeFormEvent
  | SendIntakeFormEvent
  | SendIntakeFormWithPatientInviteEvent
  | IntakeFormViewEvent
  | IntakeFormNavigationEvent

export function trackIntakeFormEvent(
  eventName: ProviderSideIntakeFormEvents,
  eventDetails: IntakeEventProperties
) {
  window?.freshpaint?.track(eventName, eventDetails)
}

/** Converts snake-cased section name to sentence-case for human readability */
export function formatSectionName(sectionName: FormSectionKey) {
  return (
    sectionName[0].toUpperCase() +
    sectionName.slice(1).split('_').join(' ').replace('and', '&')
  )
}
