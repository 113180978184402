import { PaymentMethod } from '../../../shared-types'
import { Menu } from '../../../stories/BaseComponents'
import { MenuItem } from '../../../stories/BaseComponents/Menu'
import { DeleteCreditCardModal } from './DeleteCreditCardModal'

interface CardsTableOptions {
  creditCard: PaymentMethod
  handleChangeDefault: (uuid: string) => void
  handleEditCreditCard: (creditCard: PaymentMethod) => void
  patientId: string
  refreshPaymentMethods: () => void
  stripePaymentMethods: PaymentMethod[]
}

export default function CardsTableOptions({
  creditCard,
  handleChangeDefault,
  handleEditCreditCard,
  patientId,
  refreshPaymentMethods,
  stripePaymentMethods,
}: CardsTableOptions) {
  return (
    <Menu defaultSelectedKeys={[]}>
      <MenuItem
        key={`set-defaul-option-${creditCard.uuid}`}
        disabled={creditCard.isDefault}
        onClick={() => handleChangeDefault(creditCard.uuid)}
      >
        Set as default
      </MenuItem>

      <MenuItem
        key={`edit-option-${creditCard.uuid}`}
        onClick={() => handleEditCreditCard(creditCard)}
      >
        Edit
      </MenuItem>

      <DeleteCreditCardModal
        key={`delete-option-${creditCard.uuid}`}
        creditCard={creditCard}
        creditCards={stripePaymentMethods}
        isDefault={creditCard.isDefault}
        onDelete={refreshPaymentMethods}
        patientId={patientId}
      />
    </Menu>
  )
}
