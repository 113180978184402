import React, { useEffect, useState } from 'react'

import { Location } from 'history'
import { Prompt, useHistory } from 'react-router-dom'

import { Button, Modal } from '../../../../../stories/BaseComponents'

import '../../../../../stories/BaseComponents/Modal.scss'

export interface NavigationGuardModalProps {
  title?: string
  content?: string
  when?: boolean
  blockRefreshAndClosing?: boolean
  shouldBlockNav?: boolean
  onConfirmCallback?: () => void
  onSaveClick: (navigate: boolean) => void
  shouldBlockNavigation?: (location: Location) => boolean
  setNavguardOpen?: (navigate: boolean) => void
}

/**
 * Component used to prevent navigating away by popping up a confirmation modal first
 */
const NavigationGuardModal: React.FC<NavigationGuardModalProps> = ({
  when,
  blockRefreshAndClosing = false,
  title = 'Unsaved changes',
  content = 'Close without saving?',
  shouldBlockNav,
  onConfirmCallback,
  onSaveClick,
  shouldBlockNavigation,
  setNavguardOpen,
}) => {
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const history = useHistory()

  useEffect(() => {
    // this controls native browser pop-up that guards against page refreshes & closing tab/window
    if (!blockRefreshAndClosing || !when) {
      // disabled by default
      // also use "when" prop that is passed to Prompt for logic parity
      return
    }

    const unloadGuard = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }

    window.addEventListener('beforeunload', unloadGuard)
    return () => {
      window.removeEventListener('beforeunload', unloadGuard)
    }
  }, [when, blockRefreshAndClosing])

  useEffect(() => {
    // runs the navigation after user clicks ok
    if (confirmedNavigation && lastLocation && !shouldBlockNav) {
      onConfirmCallback?.()
      history.push(`${lastLocation.pathname}${lastLocation.search ?? ''}`) // Navigate to the previous blocked location
    }
  }, [confirmedNavigation, lastLocation, shouldBlockNav])

  const handleBlockedNavigation = (nextLocation: Location | any): boolean => {
    const pathname = nextLocation.pathname ?? nextLocation.location.pathname

    const blockNavigation =
      !confirmedNavigation &&
      (shouldBlockNavigation?.(nextLocation) ?? true) &&
      !pathname.includes('clinical-notes/new') &&
      !pathname.includes('/patient/clinical-notes/')
    if (blockNavigation) {
      setLastLocation(nextLocation)
      setShowModal(true)
      setNavguardOpen && setNavguardOpen(true)
      return false
    }
    return true
  }

  const handleOkClick = async () => {
    await onSaveClick(false)
    setShowModal(false)
    setConfirmedNavigation(true)
  }

  const handleCancelClick = () => {
    setConfirmedNavigation(true)
    setShowModal(false)
  }

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        data-testid="navigation-guard-modal"
        className="nav-guard-modal"
        title={<p className="modal-title">{title}</p>}
        visible={showModal}
        onOk={handleOkClick}
        maskClosable={false}
        okText={'Save'}
        onCancel={handleCancelClick}
        closable={false}
        footer={[
          <Button
            style={{ position: 'relative', float: 'left' }}
            key="back"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>,
          <Button key="submit" danger onClick={handleCancelClick}>
            Don’t save
          </Button>,
          <Button key="submit" type="primary" onClick={handleOkClick}>
            Save
          </Button>,
        ]}
      >
        <p>{content}</p>
      </Modal>
    </>
  )
}
export default NavigationGuardModal
