import { ActiveMedicationHistory } from '../../../../../../../shared-types'
import { Table } from '../../../../../../../stories/BaseComponents'
import { getColumns, serializeMedicationData } from './MedicationBlockTypes'

import styles from '../../../styles.module.scss'

export const MedicationsBlockTable = ({
  blockData,
  isSigned,
  isDrFirst = false,
  patientName,
  isLoading,
}: {
  blockData: ActiveMedicationHistory[] | undefined
  isSigned: boolean
  isDrFirst?: boolean
  patientName: string
  isLoading: boolean
}) => {
  const columns = getColumns({ isDrFirst, isSigned })
  const data = serializeMedicationData(blockData, isDrFirst)

  return (
    <Table
      addClassNames={[
        'no-padding',
        'no-table-tools-section',
        'alternate-colors',
        styles.tableBlock,
      ]}
      rowKey={'id'}
      columns={columns}
      dataSource={data}
      loading={isLoading}
      pagination={false}
      locale={{
        emptyText: isLoading ? (
          'Loading'
        ) : (
          <div className={styles['empty-state']}>
            {patientName} has no active medications recorded
          </div>
        ),
      }}
    />
  )
}
