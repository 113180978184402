import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const hispanicLatinoSubRaceCDC: PatientDemographicObject[] = [
  { '2137-8': { title: 'Spaniard' } },
  { '2148-5': { title: 'Mexican' } },
  { '2155-0': { title: 'Central American' } },
  { '2165-9': { title: 'South American' } },
  { '2178-2': { title: 'Latin American' } },
  { '2180-8': { title: 'Puerto Rican' } },
  { '2182-4': { title: 'Cuban' } },
  { '2184-0': { title: 'Dominican' } },
  { '2138-6': { title: 'Andalusian' } },
  { '2139-4': { title: 'Asturian' } },
  { '2140-2': { title: 'Castillian' } },
  { '2141-0': { title: 'Catalonian' } },
  { '2142-8': { title: 'Belearic Islander' } },
  { '2143-6': { title: 'Gallego' } },
  { '2144-4': { title: 'Valencian' } },
  { '2145-1': { title: 'Canarian' } },
  { '2146-9': { title: 'Spanish Basque' } },
  { '2149-3': { title: 'Mexican American' } },
  { '2150-1': { title: 'Mexicano' } },
  { '2151-9': { title: 'Chicano' } },
  { '2152-7': { title: 'La Raza' } },
  { '2153-5': { title: 'Mexican American Indian' } },
  { '2156-8': { title: 'Costa Rican' } },
  { '2157-6': { title: 'Guatemalan' } },
  { '2158-4': { title: 'Honduran' } },
  { '2159-2': { title: 'Nicaraguan' } },
  { '2160-0': { title: 'Panamanian' } },
  { '2161-8': { title: 'Salvadoran' } },
  { '2162-6': { title: 'Central American Indian' } },
  { '2163-4': { title: 'Canal Zone' } },
  { '2166-7': { title: 'Argentinean' } },
  { '2167-5': { title: 'Bolivian' } },
  { '2168-3': { title: 'Chilean' } },
  { '2169-1': { title: 'Colombian' } },
  { '2170-9': { title: 'Ecuadorian' } },
  { '2171-7': { title: 'Paraguayan' } },
  { '2172-5': { title: 'Peruvian' } },
  { '2173-3': { title: 'Uruguayan' } },
  { '2174-1': { title: 'Venezuelan' } },
  { '2175-8': { title: 'South American Indian' } },
  { '2176-6': { title: 'Criollo' } },
]

export const hispanicLatinoSubEthnicityDropDownValues =
  constructPatientDemographicObject(
    hispanicLatinoSubRaceCDC,
    CodeSystemOIDs.PHVSRaceCategoryCDCRef,
    Categories.HISPANIC_LATINO_SUB_ETHNICITY
  )
