import { Navigate, NavigateAction } from 'react-big-calendar'

import { BaseWeekView, WeekViewProps } from './BaseWeekView'

export function WeekView(props: WeekViewProps) {
  return <BaseWeekView {...props} />
}

WeekView.range = (date: Date, { localizer }: { localizer: any }) => {
  const firstOfWeek = localizer.startOfWeek()
  const start = localizer.startOf(date, 'week', firstOfWeek)
  const end = localizer.endOf(date, 'week', firstOfWeek)
  return localizer.range(start, end)
}

WeekView.navigate = (
  date: Date,
  action: NavigateAction,
  { localizer }: { localizer: any }
) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, 'week')

    case Navigate.NEXT:
      return localizer.add(date, 1, 'week')

    default:
      return date
  }
}

WeekView.title = (date: Date) => {
  return `${date.toLocaleDateString()}`
}
