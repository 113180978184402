import React from 'react'

import { Line, LineConfig } from '@ant-design/charts'
import { Types } from '@antv/g2'

import { TestId } from '../../shared-types'

type LineGraphProps = LineConfig &
  TestId & {
    data: Array<{
      date: string
      value: number
      category: string
    }>
    colors: string[] // make sure to have one color for each data.category type
    hiddenCategories?: string[]
  }

const LineGraph: React.FC<LineGraphProps> = ({
  data,
  colors,
  hiddenCategories = [],
  loading,
  testId,
}) => {
  const setHiddenCategories = hiddenCategories.reduce(
    (accum: Record<string, boolean>, categoryToHide) => {
      accum[categoryToHide] = false
      return accum
    },
    {}
  )
  const config: LineConfig = {
    height: 232,
    data: data,
    xField: 'date',
    yField: 'value',
    xAxis: {
      line: { style: { stroke: '#777777' } },
      tickLine: {
        style: {
          stroke: '#777777',
        },
        length: 5,
      },
      grid: {
        line: {
          style: {
            stroke: '#ddd',
          },
        },
      },
    },
    yAxis: {
      line: { style: { stroke: '#777777' } },
    },
    seriesField: 'category',
    color: colors ?? [
      '#5B8FF9',
      '#6DC8EC',
      '#5D7092',
      '#F6BD16',
      'green',
      'red',
    ],
    legend: loading
      ? false
      : ({
          selected: setHiddenCategories,
          label: {
            align: 'right',
            spacing: 10,
          },
          position: 'bottom-left',
          padding: [16, 0, 0, 0],
        } as Types.LegendCfg),
  }

  return (
    <>
      <Line data-testid={testId} {...config} />
    </>
  )
}

export default LineGraph
