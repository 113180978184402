import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import {
  getAppointmentSettings,
  getLocation,
  getTeammateData,
} from '../../api/api-lib'
import { getRooms } from '../../api/api-lib-typed'
import { AppointmentType } from '../../components/Scheduling/AppointmentSettings'
import {
  LocationItem,
  RoomItem,
} from '../../containers/Authentication/Locations'
import { Teammate } from '../../shared-types'
import { CalendarEventPrivacy } from '../../stories/Scheduling/types'
import { useTeammateCalendars } from '../useCalendars'

export type LocationWithRoomInfo = {
  [locationId: string]: {
    locationName: string
    rooms: {
      roomId: string
      roomName: string
    }[]
  }
}

export const useSchedulingData = () => {
  const { data: teammateCalendars } = useTeammateCalendars()
  const { data: teammates, isFetching: isTeammatesLoading } = useQuery<
    Teammate[]
  >(['schedulingTeammates'], {
    queryFn: () => getTeammateData(),
    refetchInterval: 0,
    refetchOnWindowFocus: false,
  })
  const { data: rooms, isFetching: isRoomsLoading } = useQuery<RoomItem[]>(
    ['schedulingRooms'],
    {
      queryFn: () => getRooms(),
      refetchInterval: 0,
      refetchOnWindowFocus: false,
    }
  )
  const { data: locations, isFetching: isLocationsLoading } = useQuery<
    LocationItem[]
  >(['schedulingLocations'], {
    queryFn: () => getLocation(),
    refetchInterval: 0,
    refetchOnWindowFocus: false,
  })
  const {
    data: appointmentSettings,
    isFetching: isAppointmentSettingsLoading,
  } = useQuery<{
    appointmentTypes: AppointmentType[]
  }>(['schedulingAppointmentSettings'], {
    queryFn: () => getAppointmentSettings(),
    refetchInterval: 0,
    refetchOnWindowFocus: false,
  })

  const activeTeammates = useMemo(() => {
    if (!teammates) {
      return undefined
    }

    return teammates.reduce<Teammate[]>((acc, teammate) => {
      if (teammate.lastActive === 'never_logged_in' || teammate.isDeactivated) {
        return acc
      }
      acc.push(teammate)
      return acc
    }, [])
  }, [teammates])

  const integratedExternalCalendarIds = useMemo(() => {
    if (!teammateCalendars) {
      return []
    }
    const intCalendars = teammateCalendars.map(({ calendars }) => {
      return calendars.reduce<string[]>(
        (acc, { externalCalendarId, eventPrivacy }) => {
          if (
            eventPrivacy &&
            [
              CalendarEventPrivacy.INTEGRATED_WITH_DETAILS,
              CalendarEventPrivacy.INTEGRATED,
            ].includes(eventPrivacy) &&
            !!externalCalendarId
          ) {
            acc.push(externalCalendarId.toString())
          }
          return acc
        },
        []
      )
    })
    return intCalendars.flat()
  }, [teammateCalendars])

  const nonDeletedRooms = useMemo(() => {
    if (!rooms) {
      return []
    }
    return rooms.filter((r) => !r.deleted)
  }, [rooms])

  const nonDeletedLocations = useMemo(() => {
    if (!locations) {
      return []
    }

    return locations.filter((l) => !l.Deleted)
  }, [locations])

  const locationsWithRoomInformation = useMemo(() => {
    const locationsWithRooms: LocationWithRoomInfo = {}

    nonDeletedRooms.forEach((room) => {
      locationsWithRooms[room.locationId] = {
        locationName: '',
        rooms: [
          ...(locationsWithRooms[room.locationId]
            ? //Location id already exists, add room to existing room array
              locationsWithRooms[room.locationId].rooms
            : []),
          {
            roomId: room.roomId,
            roomName: room.roomName,
          },
        ],
      }
    })

    // Assign names to locations
    nonDeletedLocations.forEach((location) => {
      if (locationsWithRooms[location.LocationId]) {
        locationsWithRooms[location.LocationId].locationName =
          location.LocationName
      }
    })

    return locationsWithRooms
  }, [nonDeletedLocations, nonDeletedRooms])

  const appointmentTypes = useMemo(() => {
    if (!appointmentSettings) {
      return undefined
    }
    return appointmentSettings.appointmentTypes
  }, [appointmentSettings])

  const isSchedulingDataLoading =
    isTeammatesLoading ||
    isRoomsLoading ||
    isLocationsLoading ||
    isAppointmentSettingsLoading

  return {
    teammates,
    activeTeammates,
    teammateCalendars,
    integratedExternalCalendarIds,
    rooms,
    nonDeletedRooms,
    locations,
    nonDeletedLocations,
    appointmentSettings,
    appointmentTypes,
    locationsWithRoomInformation,
    isSchedulingDataLoading,
  }
}
