import { ReactNode } from 'react'

import { ArrowLeftOutlined } from '@ant-design/icons'
import cn from 'classnames'

import SkeletonLoadingTransition from '../../../../components/Animation/SkeletonLoadingTransition'
import NavigationGuardModal from '../../../../containers/Patient/ClinicalNotes/ClinicalNotesComponents/GuardModals/NavigationGuardModal'
import {
  Button,
  Skeleton,
  Space,
  Tooltip,
} from '../../../../stories/BaseComponents'
import { NavigationBarBadge } from './NavigationBarBadge'

import styles from './NavigationBar.module.scss'

export type NavigationBarProps = {
  title: string
  hasError: boolean
  isLoading: boolean
  isSaving: boolean
  hideBadge?: boolean
  onBackClicked: () => void
  backButtonText: string
  onNavigateAway: () => void
  /**
   * Optional content that will be placed above the NavigationBar.
   * Any additional navigation done by this content will be guarded by a modal
   * to prevent navigating away during a save operation.
   */
  topContent?: ReactNode
  /**
   * Optional content that will be placed on the right side of the NavigationBar.
   * Any additional navigation done by this content will be guarded by a modal
   * to prevent navigating away during a save operation.
   */
  rightSideContent?: ReactNode
}

export const NavigationBar = ({
  title,
  hasError,
  isLoading,
  isSaving,
  hideBadge,
  onBackClicked,
  backButtonText,
  onNavigateAway,
  topContent,
  rightSideContent,
}: NavigationBarProps) => {
  const handleSaveClick = () => {
    onNavigateAway()
  }

  const areActionsDisabled = isSaving || isLoading

  return (
    <>
      <NavigationGuardModal
        when={areActionsDisabled}
        content="Would you like to save your work before leaving?"
        onSaveClick={handleSaveClick}
        shouldBlockNavigation={() => {
          return areActionsDisabled
        }}
        blockRefreshAndClosing
      />
      {topContent}
      <div className={styles.noteNavBar}>
        <div className={styles.leftSideNavBar}>
          <Tooltip
            title={areActionsDisabled ? 'Waiting for saving to complete' : ''}
            placement="bottomLeft"
          >
            <div
              className={cn(styles.backButtonWrapper, {
                [styles.disabled]: areActionsDisabled,
              })}
            >
              <Button
                type="link"
                className={cn(styles.backButton, {
                  [styles.disabledButton]: areActionsDisabled,
                })}
                onClick={onBackClicked}
                testId="navigation-bar--back-button"
                icon={<ArrowLeftOutlined />}
                disabled={areActionsDisabled}
              >
                {backButtonText}
              </Button>
            </div>
          </Tooltip>
          <span className={styles.separator}>/</span>
          <Space className={styles.titleBadgeContainer}>
            <span className={styles.title}>
              <SkeletonLoadingTransition
                isLoading={isLoading}
                skeletonComponent={
                  <Skeleton
                    title={{
                      className: styles.skeletonTitle,
                    }}
                    paragraph={{
                      rows: 0,
                      className: styles.skeletonParagraph,
                    }}
                  />
                }
                loadedComponent={title}
              />
            </span>
            {!hideBadge && (
              <NavigationBarBadge
                isLoading={isLoading}
                isSaving={isSaving}
                hasError={hasError}
              />
            )}
          </Space>
        </div>
        {rightSideContent && (
          <div className={styles.rightSideNavBar}>{rightSideContent}</div>
        )}
      </div>
    </>
  )
}
