import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const SexCodes: any = [
  {
    56925008: {
      title: 'Sexual Dysfunction',
      snomedcode: 56925008,
      description: 'Abnormal sexual function',
      icdcode: 'R37',
      icddescription: 'Sexual dysfunction, unspecified',
    },
  },
  {
    860914002: {
      title: 'sxd.Erectile Dysfunction (Impotence)',
      description: 'Erectile dysfunction (disorder)',
      snomedcode: 860914002,
      icdcode: 'N529',
      icddescription: 'Male erectile dysfunction, unspecified',
    },
  },
  {
    46372006: {
      title: 'sxd.Female Sexual Arousal Disorder',
      description: 'Female sexual arousal disorder (disorder)',
      snomedcode: 46372006,
      icdcode: 'F5222',
      icddescription: 'Female sexual arousal disorder',
    },
  },
  {
    270903007: {
      title: 'sxd.Lack or Loss of Sexual Desire',
      description: 'Lack or loss of sexual desire (disorder)',
      snomedcode: 270903007,
      icdcode: 'F520',
      icddescription: 'Hypoactive sexual desire disorder',
    },
  },
]

export const SexDropDownValues = constructPatientDemographicObject(
  SexCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
