import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query'

import { copyNoteV1 } from '../../../../api/notes'
import {
  NotesEvents,
  trackNotesEvent,
} from '../../../../libs/freshpaint/notesEvents'
import Sentry from '../../../../libs/sentry'
import { ClinicalNote } from '../../../../shared-types'
import {
  NotificationType,
  generateNotification,
} from '../../../../stories/BaseComponents/Notification'

/**
 * This function will take a note id and create a copy of it on the backend. If successful, it will then refetch the
 * clinical notes list and
 *
 * @param noteId - The id of the note to be copied
 * @param providerId - The id of the provider performing the copy
 * @param refetchNotesList - Callback to refetch the notes list in order to pull in the newly-created note into react query cache
 */
export async function copyToNewNote({
  noteId,
  providerId,
  refetchNotesList,
}: {
  noteId?: string
  providerId: string
  refetchNotesList: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ClinicalNote[], unknown>>
}): Promise<ClinicalNote | undefined> {
  try {
    if (!noteId) {
      throw new Error(
        `Provider ${providerId} attempted to create a copy of a note while the noteId parameter is ${noteId}.`
      )
    }

    const newNote = await copyNoteV1(noteId)
    await refetchNotesList()
    trackNotesEvent(NotesEvents.AUTOSAVE_COPIED_NOTE, {
      copiedFromNote: noteId,
      noteId: newNote.NoteId,
      providerId,
      patientId: newNote.PatientId,
    })
    return newNote
  } catch (err) {
    generateNotification(
      'Note creation failed. Try again.',
      NotificationType.ERROR
    )
    Sentry.captureException(
      new Error(
        `An error occurred trying to create a copy of a note: ${
          err.message ?? err
        }`
      )
    )
    return
  }
}
