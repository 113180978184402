import { usaStatesData } from '../../containers/Provider/UsaStates'

export function convertToAbbrState(stateName: string) {
  const trimmedStateName = stateName.trim()
  if (trimmedStateName.length === 2) {
    // still check that its a valid state abbreviation
    return (
      usaStatesData.find(
        (state) =>
          state.abbreviation.toLowerCase() === trimmedStateName.toLowerCase()
      )?.abbreviation ?? ''
    )
  } else {
    return (
      usaStatesData.find(
        (state) => state.name.toLowerCase() === trimmedStateName.toLowerCase()
      )?.abbreviation ?? ''
    )
  }
}
