export const LabResultsStateEnum = {
  registered: 'registered',
  partial: 'partial',
  preliminary: 'preliminary',
  final: 'final',
  amended: 'amended',
  corrected: 'corrected',
  appended: 'appended',
  cancelled: 'cancelled',
  enteredInError: 'entered-in-error',
  unknown: 'unknown',
}
export const LabResultsStateValues = [
  ...Object.keys(LabResultsStateEnum),
] as const
type LabResultsState = typeof LabResultsStateValues[number]
export function getLabResultsStateColor(state: LabResultsState) {
  switch (state) {
    case LabResultsStateEnum.partial:
    case LabResultsStateEnum.preliminary:
    case LabResultsStateEnum.registered: {
      return 'blue'
    }
    case LabResultsStateEnum.final:
    case LabResultsStateEnum.amended:
    case LabResultsStateEnum.corrected:
    case LabResultsStateEnum.appended: {
      return 'green'
    }
    case LabResultsStateEnum.cancelled:
    case LabResultsStateEnum.enteredInError: {
      return 'red'
    }
    case LabResultsStateEnum.unknown: {
      return 'gray'
    }
  }
}

export const LabResultsAcknowledgedStateEnum = {
  notReady: 'notReady',
  readyForReview: 'readyForReview',
  reviewed: 'reviewed',
  notApplicable: 'notApplicable',
}
export const LabResultsAcknowledgedStateValues = [
  ...Object.keys(LabResultsAcknowledgedStateEnum),
] as const
type LabResultsAcknowledgedState =
  typeof LabResultsAcknowledgedStateValues[number]

export interface LabsResultData {
  key: string
  labsOrderId: string
  labsOrderDocumentReference?: string
  labsOrderDocumentTitle?: string
  patientId: string
  patientFirstName: string
  patientMiddleName?: string
  patientLastName: string
  orderingClinicianId: string
  orderingClinicianLastName: string
  orderingClinicianFirstName: string
  orderingClinicianCredentials?: string
  ordered: string
  resulted?: string
  state: LabResultsState
  acknowledgedStatus: LabResultsAcknowledgedState
  acknowledgedDate?: string
  acknowledgedByFirstName?: string
  acknowledgedByLastName?: string
  acknowledgedByCredential?: string
  viewed?: string
  practiceTimezone?: string
}

export type HGMissingValuesJSON = {
  place: string
  value: string
  needValue?: string
}[]
export const HGGenderValues = [
  { label: 'Female', value: 'f' },
  { label: 'Male', value: 'm' },
  { label: 'Unknown', value: 'unknown' },
]

export function getHGGenderValue(legalSexValue?: string): string | undefined {
  if (!legalSexValue) {
    return undefined
  }
  return legalSexValue?.toLowerCase()[0] === 'f'
    ? 'f'
    : legalSexValue?.toLowerCase()[0] === 'm'
    ? 'm'
    : ['unknown'].includes(legalSexValue?.toLowerCase())
    ? 'unknown'
    : ['other', 'x'].includes(legalSexValue?.toLowerCase())
    ? 'x'
    : undefined
}

export const HGRelationshipValues = [
  'self',
  'spouse',
  'parent',
  'other',
  'child',
  'life partner',
]
