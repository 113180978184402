import { differenceInCalendarMonths, differenceInYears } from 'date-fns'
import qs from 'querystring'

import { getPaymentAttempt } from '../api/api-lib'
import { ClaimData } from '../containers/Patient/ClaimsV2/types'
import {
  BillingCode,
  InsuranceClaim,
  Invoice,
  LineItem,
  PaymentAttempt,
  PaymentMethod,
  PaymentStatus,
  RefundAttempt,
} from '../shared-types'
import { Diagnosis } from '../stories/Invoice/Diagnoses'
import { PAYMENT_METHOD_TYPES } from './constants'
import { sleep } from './utils'

export function convertDollarsToCents(num?: string | number | null): number {
  return Math.round(Number(num || 0) * 100)
}

export const convertCentsToDollars = (amountCents: number) => {
  if (amountCents === 0) {
    return `0.00`
  }

  const dollars =
    amountCents < 0
      ? Math.ceil(amountCents / 100)
      : Math.floor(amountCents / 100)
  const cents = Math.abs(amountCents % 100)

  const centsString = cents < 10 ? `0${cents}` : cents.toString()
  const needsExplicitNegativeSign = amountCents < 0 && amountCents > -100
  return `${needsExplicitNegativeSign ? '-' : ''}${dollars}.${centsString}`
}

export const convertCentsToDollarsNumber = (amountCents: number): number => {
  if (amountCents <= 0) {
    return 0
  }

  return parseFloat((amountCents / 100).toFixed(2))
}

type FormatToUsdOptions = {
  excludeDollarSymbol?: boolean
  defaultZero?: boolean
}

// formats a number with commas to use just for display to the user (isn't currently used to override the field value)
// eg: 12345.12345 -> '12,345.12345'
export function formatNumberStringToUsdString(
  value: string | number | undefined,
  options?: FormatToUsdOptions
): string {
  if (!value)
    return options?.defaultZero
      ? `${!options?.excludeDollarSymbol && '$'}0.00`
      : ''
  let newString = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(value))

  newString = options?.excludeDollarSymbol
    ? newString.replace('$', '')
    : newString

  return newString
}

export function centsToUsdString(value: number, options?: FormatToUsdOptions) {
  return formatNumberStringToUsdString(convertCentsToDollars(value), options)
}

export const filterSuccessful = (
  transaction: PaymentAttempt | RefundAttempt
) => {
  return transaction.status === PaymentStatus.SUCCESSFUL
}

export const getDisplayNameForPaymentMethodType = (
  paymentMethodType: PAYMENT_METHOD_TYPES
) => {
  switch (paymentMethodType) {
    case PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD:
      return 'Credit Card (Stripe)'
    case PAYMENT_METHOD_TYPES.EXTERNAL_CREDIT_CARD:
      return 'Credit Card (External)'
    case PAYMENT_METHOD_TYPES.CASH:
      return 'Cash'
    case PAYMENT_METHOD_TYPES.CHECK:
      return 'Check'
    case PAYMENT_METHOD_TYPES.ACH:
      return 'Wire Transfer/ACH'
    case PAYMENT_METHOD_TYPES.VENMO:
      return 'Venmo'
    case PAYMENT_METHOD_TYPES.ZELLE:
      return 'Zelle'
    case PAYMENT_METHOD_TYPES.PATIENT_FINANCING:
      return 'Patient Financing'
    case PAYMENT_METHOD_TYPES.OTHER:
      return 'Other'
  }
}

export const calculateAvailableCredit = (payments: PaymentAttempt[]) => {
  let availableCredit = 0

  payments.filter(filterSuccessful).forEach((payment) => {
    availableCredit += payment.amountCentsUnapplied

    if (payment.refundAttempts) {
      payment.refundAttempts.filter(filterSuccessful).forEach((refund) => {
        availableCredit -= refund.amountCentsUnapplied
      })
    }
  })

  return Math.max(availableCredit, 0)
}

/**
 *  Calculate the claim and patient outstanding amounts
 * @param claim  InsuranceClaim | ClaimData
 * @returns  {patientOutstanding: number, claimOutstanding: number}
 */
export const calculateClaimAndPatientOutstanding = (
  claim: InsuranceClaim | ClaimData
) => {
  if (!claim) return { patientOutstanding: 0, claimOutstanding: 0 }
  const {
    patientResponsibilityAmountCents = 0,
    patientPaidAmountCents = 0,
    insurancePaidAmountCents = 0,
    writeOffAmountCents = 0,
    billableEntity,
  } = claim

  const patientOutstanding =
    (patientResponsibilityAmountCents || 0) - (patientPaidAmountCents || 0)

  const claimOutstanding =
    (billableEntity?.totalAmountCents || 0) -
    (insurancePaidAmountCents || 0) -
    (patientPaidAmountCents || 0) -
    (writeOffAmountCents || 0)

  return {
    patientOutstanding,
    claimOutstanding,
  }
}

/**
 * Calculates the total outstanding balance for a list of billable entities.
 *
 * This function iterates over the provided list of billable entities, which can be either Invoices or InsuranceClaims.
 * For each Invoice, it adds the `amountCentsDue` to the total outstanding amount.
 * For each InsuranceClaim, it adds the patient's outstanding amount to the total outstanding amount.
 *
 * @param {Array<Invoice | InsuranceClaim>} billableEntities - The list of billable entities to calculate the outstanding balance for.
 * @returns {number} The total outstanding balance in cents.
 */
export const calculatedOutstandingBalanceBillableEntity = (
  billableEntities: (Invoice | InsuranceClaim)[]
) => {
  let totalOutstandingAmountCents = 0
  billableEntities.forEach((billableEntity) => {
    // Invoice
    if ('amountCentsDue' in billableEntity && billableEntity.amountCentsDue) {
      totalOutstandingAmountCents += billableEntity.amountCentsDue
      // Claims
    } else if ('patientResponsibilityAmountCents' in billableEntity) {
      // If negative amount, set to 0
      totalOutstandingAmountCents += Math.max(
        calculateClaimAndPatientOutstanding(billableEntity)?.patientOutstanding,
        0
      )
    }
  })
  return totalOutstandingAmountCents
}

/*
  calculateRefundAmountCents return the total amount due to refunds found on a given PaymentAttempt.
  If an invoiceUuid is given, it will only return the sum of refunds that were applied to that specific
  invoice.
  If no invoiceUuid is given, it will return the sum of refunds that exist on PaymentAttempt.
  This behavior was implemented to fix negative refunds sum due to return all refunds even though they didn't
  match the invoice given.
*/
export const calculateRefundAmountCents = (
  payment: PaymentAttempt,
  invoiceUuid?: string | null,
  insuranceClaimUuid?: string | null
) => {
  return payment.refundAttempts?.reduce((acc, refund) => {
    if (
      refund.status !== PaymentStatus.SUCCESSFUL ||
      (invoiceUuid &&
        !refund.billableEntityPayments.some(
          (ip) => ip.invoiceUuid === invoiceUuid
        )) ||
      (insuranceClaimUuid &&
        !refund.billableEntityPayments.some(
          (icp) => icp.insuranceClaimUuid === insuranceClaimUuid
        ))
    ) {
      return acc
    }
    return acc + refund.totalAmountCents
  }, 0)
}

export const pollForPaymentStatus = async (
  paymentAttemptUuid: string,
  numAttempts: number
): Promise<PaymentStatus> => {
  const response = await getPaymentAttempt(paymentAttemptUuid, numAttempts)
  const paymentStatus = response.paymentAttempt.status

  if (paymentStatus === PaymentStatus.PENDING && numAttempts < 20) {
    sleep(2000)
    return await pollForPaymentStatus(paymentAttemptUuid, numAttempts + 1)
  } else {
    return paymentStatus
  }
}

export const totalRefund = (payment: PaymentAttempt) => {
  return (payment.refundAttempts || [])
    .filter(filterSuccessful)
    .reduce((acc, currentRefund) => acc + currentRefund.totalAmountCents, 0)
}

export const isFullyRefunded = (payment: PaymentAttempt) => {
  return payment.totalAmountCents - totalRefund(payment) === 0
}

export const getBillingCodeDisplayName = (billingCode: BillingCode): string => {
  return `${billingCode?.code} ${
    billingCode?.shortDescription ?? billingCode?.description ?? ''
  }`.trim()
}

export const superbillText = (invoice?: Invoice) =>
  invoice?.superbill?.id ? 'View superbill' : 'Create superbill'

export const superbillUrl = (invoice?: Invoice) => {
  if (!invoice) return null

  const queryString = qs.stringify({
    noteId: invoice?.clinicalNoteId,
    invoiceUuid: invoice?.uuid,
    patientId: invoice?.patientId,
  })

  return `/superbill?${queryString}`
}
export const invoiceUrl = ({
  invoice,
  providerId,
  documentReference,
}: {
  invoice?: Invoice
  providerId: string
  documentReference: string
}) => {
  const queryString = qs.stringify({
    patientId: invoice?.patientId,
    providerId: providerId,
    invoices: true,
    documentReference,
  })
  return `/patient/documents?${queryString}`
}

export const markDxAsDeletedAndUpdatePointers = (
  diagnoses: Diagnosis[],
  lineItems: LineItem[],
  dxCode: string
) => {
  const dxIndex = diagnoses.findIndex((dx) => dx.code === dxCode)

  const dxs = Array.from(diagnoses)
  dxs[dxIndex].isDeleted = true

  const dxOrderDelete = dxs[dxIndex].order

  const activeDxs = dxs.filter((dx) => !dx.isDeleted)

  const newLineItems = lineItems.map((lineItem) => {
    const newDxPointers = (lineItem.dxPointers || [])
      .map((i) => {
        if (Number(i) === dxOrderDelete) {
          // remove references to the deleted dx by setting value to -1
          return -1
        } else if (Number(i) <= dxOrderDelete) {
          // don't change the value if the pointer is less than the deleted dx's order (because the deletion doesn't impact the matching dx)
          return Number(i)
        } else {
          // update references to the remaining dxs to one less (because the remaining dxs move UP)
          return Number(i) - 1
        }
      })
      .filter((i) => i > -1)

    if (newDxPointers.length === 0 && activeDxs.length) {
      // if the delete would otherwise remove all dxPointers but there are active dxs, default to the first dx
      newDxPointers.push(0)
    }

    return { ...lineItem, dxPointers: newDxPointers }
  })

  let i = 0
  const newDiagnoses = dxs.map((dx) => {
    if (!dx.isDeleted) {
      dx.order = i
      i++
    }
    return dx
  })

  return { newDiagnoses, newLineItems }
}

export const isExpiredCard = ({ expYear, expMonth }: PaymentMethod) => {
  if (!expYear || !expMonth) {
    return false
  }
  return (
    differenceInYears(new Date(expYear), new Date()) <= 0 &&
    differenceInCalendarMonths(new Date(`${expYear}-${expMonth}`), new Date()) <
      -1
  )
}

export const calculateMaxRefundAmountFromPayment = (
  payment: PaymentAttempt
) => {
  let maxSum = payment.totalAmountCents
  payment.refundAttempts.filter(filterSuccessful).forEach((refund) => {
    maxSum -= refund.totalAmountCents
  })
  return maxSum
}
