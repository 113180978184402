import React from 'react'

import { Col, Form } from 'react-bootstrap'
import 'react-dates/initialize'

import CustomTimePicker from '../../../../../stories/BaseComponents/CustomTimePicker'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function FacilityStaff(props) {
  return (
    <Form.Row>
      <Form.Group
        as={Col}
        sm={12}
        md={!props.PsychotherapyNotes ? 8 : 12}
        lg={!props.PsychotherapyNotes ? 8 : 12}
        controlId="Staff"
      >
        <Form.Label style={{ fontWeight: 'bold' }}>Additional Staff</Form.Label>
        <Form.Control
          bsPrefix="form-input"
          value={props.fields.Staff}
          disabled={props.disabled}
          onChange={(e) =>
            props.handleFieldChange({ ...props.fields, Staff: e.target.value })
          }
          as="textarea"
          rows="1"
        />
      </Form.Group>
      {!props.PsychotherapyNotes && (
        <Form.Group as={Col} sm={12} md={4} lg={4} controlId="startTime">
          <Form.Label style={{ fontWeight: 'bold' }}>
            Treatment Start (HH:mm)
          </Form.Label>
          <br />
          <CustomTimePicker
            value={props.fields.StartTime}
            onChange={(value) => {
              props.handleFieldChange({ ...props.fields, StartTime: value })
            }}
            disabled={props.disabled}
          />
        </Form.Group>
      )}
    </Form.Row>
  )
}
