import { useParams } from 'react-router-dom'

import { useDiagnosisNoteBlock } from '../../../../../hooks/useDiagnosisNoteBlock'
import { generateCoreIdDisplayName } from '../../../../../hooks/usePatientProfile/utils'
import { useNote } from '../../../hooks'
import { DiagnosesBlockTable } from '../BlockTypeRenderer/types/diagnoses/DiagnosesBlockTable'

export const ActiveDiagnosisBlock = ({
  isSigned = false,
}: {
  isSigned?: boolean
}) => {
  const { noteId } = useParams() as { noteId: string }
  const { note, isLoading: isNoteLoading } = useNote(noteId)
  const { data: dxData, isLoading } = useDiagnosisNoteBlock({
    noteId: note?.uuid,
    enabled: !!note,
  })
  const data = dxData?.diagnosisBlock.diagnoses.map((d) => d.diagnosis)

  const patientName = note?.patient && generateCoreIdDisplayName(note?.patient)
  const isAnythingLoading = isLoading || isNoteLoading

  return (
    <DiagnosesBlockTable
      blockData={data}
      isSigned={isSigned}
      patientName={patientName}
      isLoading={isAnythingLoading}
    />
  )
}
