import { useCallback, useEffect, useState } from 'react'

import CopyIcon from '@ant-design/icons/CopyOutlined'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'

import Button, { ButtonProps } from '../../Button'
import Tooltip from '../../Tooltip'

import styles from './index.module.scss'

export interface CopyButtonProps extends Omit<ButtonProps, 'children'> {
  text: string
}

export default function CopyButton({
  className,
  testId,
  text,
  ...restProps
}: CopyButtonProps) {
  const [showCopied, setShowCopied] = useState(false)

  const onCopy = useCallback(() => {
    copy(text)
    setShowCopied(true)
  }, [text])

  useEffect(() => {
    if (!showCopied) {
      return
    }
    const timeoutId = setTimeout(() => {
      setShowCopied(false)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [showCopied])

  return (
    <Tooltip
      data-testid={testId ? `${testId}_tooltip` : undefined}
      align={{ offset: [0, 8] }}
      placement="top"
      title="Copied"
      visible={showCopied}
    >
      <Button
        {...restProps}
        testId={testId}
        onClick={onCopy}
        className={classNames(styles.button, className)}
      >
        <CopyIcon />
      </Button>
    </Tooltip>
  )
}
