import React, { useCallback } from 'react'

import { ClockCircleOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import { DefaultOptionType, SelectProps } from 'antd/lib/select'
import { addMinutes, differenceInMinutes, format } from 'date-fns'

import {
  convertFreeTextTo12HourFormat,
  convertTime12to24,
} from '../../../libs/utils'
import { TestId } from '../../../shared-types'
import {
  END_DAY_FIELD,
  END_HOUR_FIELD,
  START_DAY_FIELD,
  START_HOUR_FIELD,
} from '../../Scheduling/EventModal/EventModal'
import { generateTimeOptions } from '../helpers/generateTimeOptions'

const { Option } = Select

export type TimeSelectorProps = SelectProps & {
  minuteStep: number
  name?: string
  form?: FormInstance
  startHour?: number
  endHour?: number
  value?: string
  className?: string
} & TestId

export const TimeSelector: React.FC<TimeSelectorProps> = ({
  placeholder,
  minuteStep,
  name,
  form,
  value,
  startHour,
  endHour,
  testId,
  onSelect,
  ...props
}) => {
  const [validSearch, setValidSearch] = React.useState<string | undefined>(
    undefined
  )
  const [internalValue, setInternalValue] = React.useState<string | undefined>(
    value
  )

  const getEventDuration = () => {
    if (!form) return { duration: 0 }

    const startTime = convertTime12to24(form.getFieldValue(START_HOUR_FIELD))
    const endTime = convertTime12to24(form.getFieldValue(END_HOUR_FIELD))
    const startDay = format(
      new Date(form.getFieldValue(START_DAY_FIELD)),
      'MM/dd/yyyy'
    )
    const endDay = format(
      new Date(form.getFieldValue(END_DAY_FIELD)),
      'MM/dd/yyyy'
    )
    const startDate = new Date(`${startDay} ${startTime}`)
    const endDate = new Date(`${endDay} ${endTime}`)
    const duration = differenceInMinutes(endDate, startDate)
    return { duration }
  }

  const handleSelectChange = useCallback(
    (newStart: string, options: DefaultOptionType | DefaultOptionType[]) => {
      // custom handler for the event modal that updates the end time of the event
      // if the start time is being updated and an appointment type is selected
      if (name === START_HOUR_FIELD && form) {
        const { duration } = getEventDuration()
        const startTime = new Date(
          `${format(
            new Date(form.getFieldValue(START_DAY_FIELD)),
            'MM/dd/yyyy'
          )} ${convertTime12to24(newStart)}`
        )
        const endTime = addMinutes(startTime, duration)
        const endDay = new Date(format(endTime, 'MM/dd/yyyy'))
        const endHour = format(endTime, 'h:mmaaa')
        form.setFieldsValue({
          [END_DAY_FIELD]: endDay,
          [END_HOUR_FIELD]: endHour,
        })
      }

      if (props.onChange) {
        props.onChange(newStart, options)
      }
      setInternalValue(newStart)
    },
    [name, form, props.onChange]
  )

  const handleNotValidSearch = () => {
    setValidSearch(value)
    handleSelectChange(value, [])
  }

  const handleSearch = (searchValue: string) => {
    if (!searchValue) {
      return
    }
    const formatedValue = convertFreeTextTo12HourFormat(searchValue)
    if (!formatedValue) {
      handleNotValidSearch()
      return
    }
    setValidSearch(formatedValue)
    handleSelectChange(formatedValue, [])
  }

  const handleFilter = () => {
    return true
  }

  return (
    <Select
      value={form ? value : internalValue}
      placeholder={placeholder}
      suffixIcon={<ClockCircleOutlined />}
      {...props}
      onChange={handleSelectChange}
      onSelect={onSelect}
      showSearch
      filterOption={handleFilter}
      onSearch={handleSearch}
      data-testid={testId}
    >
      {generateTimeOptions(minuteStep, startHour, endHour, validSearch).map(
        (option) => (
          <Option {...option}>{option.value}</Option>
        )
      )}
    </Select>
  )
}
