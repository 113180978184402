import { useState } from 'react'

import { CopyOutlined } from '@ant-design/icons'
import ClickAwayListener from 'react-click-away-listener'

import { DayOfWeek } from '../../../../../api/api-lib-typed'
import { Button, Checkbox } from '../../../../../stories/BaseComponents'
import { DAYS_OF_WEEK } from '../../AvailabilitySettings/AvailabilitySettings'

import styles from './CopyDropdown.module.scss'

const dayOfWeekMapping = {
  FRI: 'Friday',
  MON: 'Monday',
  SAT: 'Saturday',
  SUN: 'Sunday',
  THUR: 'Thursday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
} as const

export const resetCheckboxes = (currentDayOfWeek: DayOfWeek) => {
  return {
    FRI: false,
    MON: false,
    SAT: false,
    SUN: false,
    THUR: false,
    TUE: false,
    WED: false,
    [currentDayOfWeek]: true,
  }
}

export type CopyDropdownProps = {
  currentDayOfWeek: DayOfWeek
  onCopyApply(currentDayOfWeek: DayOfWeek, daysToApplyCopy: DayOfWeek[]): void
}

export const CopyDropdown = ({
  currentDayOfWeek,
  onCopyApply,
}: CopyDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [checkedDays, setCheckedDays] = useState<Record<DayOfWeek, boolean>>(
    resetCheckboxes(currentDayOfWeek)
  )
  const handleToggleDropdownOpen = () => {
    setIsDropdownOpen((isOpen) => !isOpen)
    setCheckedDays(resetCheckboxes(currentDayOfWeek))
  }

  const handleDropdownClose = () => {
    setIsDropdownOpen(false)
    setCheckedDays(resetCheckboxes(currentDayOfWeek))
  }

  const handleToggleCheck = (checkedDay: DayOfWeek) => () => {
    setCheckedDays((currentCheckedDays) => {
      return {
        ...currentCheckedDays,
        [checkedDay]: !currentCheckedDays[checkedDay],
      }
    })
  }

  const handleCopyApply = () => {
    onCopyApply(
      currentDayOfWeek,
      Object.entries(checkedDays).reduce<DayOfWeek[]>(
        (acc, [day, isChecked]) => {
          if (isChecked && day !== currentDayOfWeek) {
            acc.push(day as DayOfWeek)
          }
          return acc
        },
        []
      )
    )
    setIsDropdownOpen(false)
    setCheckedDays(resetCheckboxes(currentDayOfWeek))
  }

  return (
    <div className={styles.container}>
      <Button
        className={styles.button}
        onClick={handleToggleDropdownOpen}
        type="text"
      >
        <CopyOutlined />
      </Button>
      {isDropdownOpen && (
        <ClickAwayListener onClickAway={handleDropdownClose}>
          <div className={styles.dropdownContainer} role="listbox">
            <p className={styles.dropdownTitleText}>Copy Times To</p>
            {DAYS_OF_WEEK.map(({ key }) => {
              return (
                <div key={`${currentDayOfWeek}_${key}_copyDropdown`}>
                  <Checkbox
                    checked={checkedDays[key]}
                    onClick={handleToggleCheck(key)}
                    disabled={currentDayOfWeek === key}
                  >
                    <p className={styles.checkboxOption}>
                      {dayOfWeekMapping[key]}
                    </p>
                  </Checkbox>
                </div>
              )
            })}
            <Button
              className={styles.applyButton}
              type="primary"
              onClick={handleCopyApply}
              disabled={
                !Object.entries(checkedDays).some(
                  ([day, isChecked]) => isChecked && currentDayOfWeek !== day
                )
              }
            >
              Apply
            </Button>
          </div>
        </ClickAwayListener>
      )}
    </div>
  )
}
