import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const legalRecordedSex: PatientDemographicObject[] = [
  { F: { title: 'F' } },
  { M: { title: 'M' } },
  { X: { title: 'X' } },
]

const birthSex: PatientDemographicObject[] = [
  { F: { title: 'F' } },
  { M: { title: 'M' } },
  { X: { title: 'X' } },
]

export const LegalRecordedSexDropDownValues = constructPatientDemographicObject(
  legalRecordedSex,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.LEGAL_RECORD_SEX
)

export const BirthSexDropDownValues = constructPatientDemographicObject(
  birthSex,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.LEGAL_RECORD_SEX
)
