import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const genderIdentityFHIR: PatientDemographicObject[] = [
  {
    '446151000124109': {
      title: 'Male',
      description: 'Identifies as male gender',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
  {
    '446141000124107': {
      title: 'Female',
      description: 'Identifies as female gender',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
  {
    '407377005': {
      title: 'Transgender male / Trans man (Female-to-male)',
      description: 'Female-to-male transsexual',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
  {
    '407376001': {
      title: 'Transgender female / Trans woman (Male-to-female)',
      description: 'Male-to-female transsexual',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
  {
    PHC1490: {
      title: 'Cis female / Cis woman',
      description: 'Cisgender/Not transgender',
      codeSystemOid: CodeSystemOIDs.PHPHINVSCDC,
    },
  },
  {
    PHC1490: {
      title: 'Cis male / Cis man',
      description: 'Cisgender/Not transgender ',
      codeSystemOid: CodeSystemOIDs.PHPHINVSCDC,
    },
  },
  {
    '446131000124102': {
      title: 'Non-binary / Genderqueer / Gender non-conforming',
      description: 'Identifies as non-conforming gender',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
  {
    '446131000124102': {
      title: 'Pangender',
      description: 'Identifies as non-conforming gender',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
  {
    '446131000124102': {
      title: 'Agender',
      description: 'Identifies as non-conforming gender',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
  {
    '446131000124102': {
      title: 'Two-spirit',
      description: 'Identifies as non-conforming gender',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
  {
    '446131000124102': {
      title: 'Unsure / Questioning',
      description: 'Identifies as non-conforming gender',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
  {
    ASKU: {
      title: 'Does not wish to disclose',
      description: 'Choose not to disclose',
      codeSystemOid: CodeSystemOIDs.SNOMEDCT,
    },
  },
]

export const GenderIdentityDropDownValues = constructPatientDemographicObject(
  genderIdentityFHIR,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.GENDER_IDENTITY
)
