import { API } from 'aws-amplify'

import { DateISO8601 } from '../shared-types'

export const OsmindIntakeFormTitle = 'Osmind Intake Form'

/* These are SignatureRequest to support legacy method of allerting when form is ready for review. It does not mean that a signature is required. */
export enum IntakeFormSignatureRequestStatus {
  LEGACY_INTAKE_REQUIRES_REVIEW = 'requires review',
  OSMIND_INTAKE_SENT = 'osmind intake form sent',
  OSMIND_INTAKE_REVIEWED = 'osmind intake form reviewed',
  OSMIND_INTAKE_REQUIRES_REVIEW = 'osmind intake requires review',
}
// Identifiers used to distinguish intake form types for SignatureRequests
export const JOTFORM_IDENTIFIER = 'JOTFORM#' // Legacy intake forms (temporary)
export const OSMIND_INTAKE_IDENTIFIER = 'OSMIND_INTAKE#' // New intake forms

export enum FormSectionKey {
  INTRODUCTION = 'introduction' /** For UI only */,
  GENERAL_INFORMATION = 'general_information',
  INSURANCE = 'insurance',
  ALLERGIES_MEDICATIONS = 'allergies_and_medications',
  PSYCHIATRIC_HISTORY = 'psychiatric_history',
  MEDICAL_HISTORY = 'medical_history',
  SOCIAL_HISTORY = 'social_history',
  OTHER_INFORMATION = 'other_information',
}

/** This is the full form response object that the database sends back */
export interface IntakeForm {
  id: string
  providerId: string
  patientId: string
  title: string
  sections: FormSectionKey[]
  lastInvitedAt: DateISO8601
  startedAt: DateISO8601 | null
  completedAt: DateISO8601 | null
  importedAt: DateISO8601 | null
  reviewedAt: DateISO8601 | null
  createdAt: DateISO8601
  createdBy: string
  updatedAt: DateISO8601
  updatedBy: string
  responses?: IntakeFormResponseObject[]
}

export enum PayersClaimTypes {
  PROFESSIONAL = 'Professional',
}

export interface ChangePayersList {
  uuid?: number
  id: string
  organizationName: string
  tradingPartnerServiceId: string
  claimType: PayersClaimTypes
  paperCPID: string
  claimStatusPayerId?: string
  claimInsuranceType?: string
  createdAt: string
  isActive: boolean
}

/** The form we have to support is dynamic, type unknown */
export type SupportedFormResponseTypes = unknown
export interface BaseIntakeFormResponseObject {
  formSection: FormSectionKey
  questionKey: string
  answer: SupportedFormResponseTypes
}

/** This is the full form response object that the database sends back */
export interface IntakeFormResponseObject extends BaseIntakeFormResponseObject {
  formId: string
}

type SendFormToPatientRequest = (args: {
  patientId: string
  formTitle: string
  formSections: FormSectionKey[]
}) => Promise<IntakeForm>

type BaseFormRequest = (formId: string) => Promise<IntakeForm>

type SubmitIntakeFormRequest = (args: {
  formId: string
  responses: BaseIntakeFormResponseObject[]
}) => Promise<void>

type GetCompletedIntakeFormRequest = (
  formId: string
) => Promise<IntakeForm & { responses: IntakeFormResponseObject[] }>

type ListIntakeFormsRequest = (patientId: string) => Promise<IntakeForm[]>

export type FormStatusChangeRequest = (formId: string) => Promise<IntakeForm>

export enum SectionNavigationMethod {
  NEXT = 'next',
  BACK = 'back',
}
type TrackIntakeFormNavigationRequest = (
  formId: string,
  sectionName: FormSectionKey,
  navigation: SectionNavigationMethod
) => Promise<void>

type GetLatestCompletedIntakeFormRequest = (
  patientId: string,
  markNoteAsImported?: string,
  version?: string
) => Promise<IntakeForm>

type GetChangePayersList = () => Promise<ChangePayersList[]>

export const sendIntakeForm: SendFormToPatientRequest = ({
  patientId,
  formTitle,
  formSections,
}) => {
  return API.post('patient-intake', '/intake-form', {
    body: {
      patientId,
      formTitle,
      formSections,
    },
  })
}

export const getIncompleteIntakeForm: BaseFormRequest = (formId) => {
  return API.get('patient-intake', `/intake-form/${formId}/start`, {})
}

export const resendIntakeFormInvite: BaseFormRequest = (formId) => {
  return API.put('patient-intake', `/intake-form/${formId}/resend`, {})
}

export const submitIntakeForm: SubmitIntakeFormRequest = ({
  formId,
  responses,
}) => {
  return API.post('patient-intake', `/intake-form/${formId}`, {
    body: { responses },
  })
}

export const getCompletedIntakeForm: GetCompletedIntakeFormRequest = (
  formId
) => {
  return API.get('patient-intake', `/intake-form/${formId}/review`, {})
}

export const listIntakeForms: ListIntakeFormsRequest = (patientId) => {
  return API.get('patient-intake', `/intake-form/patient/${patientId}`, {})
}

export const reviewIntakeForm: FormStatusChangeRequest = (formId) => {
  return API.put('patient-intake', `/intake-form/${formId}/review`, {})
}

export const importIntakeForm: FormStatusChangeRequest = (formId) => {
  return API.post('patient-intake', `/intake-form/${formId}/import`, {})
}

export const trackIntakeFormNavigation: TrackIntakeFormNavigationRequest = (
  formId,
  sectionName,
  navigation
) => {
  return API.put('patient-intake', `/intake-form/${formId}/navigate`, {
    body: { sectionName, navigation },
  })
}

/**
 * returns a presigned url for uploading insurance cards
 * @param {string} patientId - the patient id'
 * @param {string} key - the name of the file
 * @param {string} [contentType] - the content type of the file
 * @returns {Promise<string>} - the presigned url
 */
export async function getInsuranceCardUploadUrl(
  patientId: string,
  key: string,
  contentType: string
): Promise<string> {
  return await API.post(
    'patient-intake',
    `/intake-form/insurance/upload/${patientId}`,
    { body: { key, contentType } }
  )
}

export async function updateInsuranceCardKey(patientId: string, key: string) {
  return await API.put(
    'patient-intake',
    `/intake-form/insurance/key/${patientId}`,
    { body: { key } }
  )
}

export const getLatestCompletedIntakeForm: GetLatestCompletedIntakeFormRequest =
  (patientId) => {
    return API.get(
      'patient-intake',
      `/intake-form/patient/${patientId}/evalnote`,
      {}
    )
  }

export const getChangePayersList: GetChangePayersList = () => {
  return API.get('patient-intake', `/intake-form/payers-list`, {})
}
