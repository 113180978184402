import { useQuery, useQueryClient } from '@tanstack/react-query'

import { getTeammateData } from '../../api/api-lib'
import { Table } from '../BaseComponents'
import { Provider } from '../types'
import ConnectProviderButton from './ConnectProviderButton'

const Providers = ({ isAmdConnected = false }) => {
  const queryClient = useQueryClient()

  const { data: providers, isInitialLoading } = useQuery(
    ['providers'],
    getTeammateData,
    { enabled: isAmdConnected }
  )

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
    },
    {
      dataIndex: 'billingNPI',
      key: 'billingNPI',
      title: 'NPI',
      render: (billingNPI: string) => (
        <>
          {billingNPI ? (
            billingNPI
          ) : (
            <span className="advancedmd__table__item-none">(None)</span>
          )}
        </>
      ),
    },
    {
      dataIndex: 'amdProfileId',
      key: 'amdProfileId',
      title: 'Billing permissions',
      render: (amdProfileId: string) => <>{amdProfileId ? 'Yes' : 'No'}</>,
    },
    {
      dataIndex: 'amdProfileId',
      key: 'amdProfileId',
      title: '',
      render: (amdProfileId: string, { cognitoId }: Provider) => {
        return (
          <ConnectProviderButton
            isAmdConnected={isAmdConnected}
            providerId={cognitoId}
            amdProfileId={amdProfileId}
            onSuccess={(newAmdProfileId: string) => {
              const updatedProviders = Array.from(providers) as Provider[]
              const providerIdx = updatedProviders.findIndex(
                (provider: Provider) => {
                  return provider.cognitoId === cognitoId
                }
              )
              if (providerIdx !== -1) {
                updatedProviders[providerIdx].amdProfileId = newAmdProfileId
                queryClient.setQueryData(['providers'], updatedProviders)
              }
            }}
          />
        )
      },
    },
  ]

  const providerRows = ((providers || []) as Provider[])
    .filter(({ isDeactivated }) => !isDeactivated)
    .map(({ cognitoId: id, billingNPI, amdProfileId, name }) => ({
      key: id,
      cognitoId: id,
      name,
      billingNPI,
      amdProfileId,
    }))

  return (
    <div className="advancedmd__table">
      <Table
        loading={isInitialLoading}
        columns={columns}
        dataSource={providerRows}
        pagination={false}
      />
    </div>
  )
}

export default Providers
