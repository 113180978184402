import { addDays, format, isBefore, startOfMonth, startOfWeek } from 'date-fns'
import { isEmpty } from 'lodash'

import { AppointmentItem } from '../../api/appointments'
import { MappedNoteItem } from '../../api/notes'
import { SurveyItem } from '../../api/surveys'
import { getNameToDisplay } from '../../shared/Helpers/utils'
import {
  AppointmentsReportData,
  NotesReportData,
  SurveysReportData,
} from '../BaseComponents/tableData.types'
import {
  AggregationMethodEnum,
  GraphDataProps,
  ReportType,
  TreatmentListEnum,
} from './types'

const SLASH_DELIMINATED_STANDARD_DATE_FORMAT = 'MM/dd/yyyy'

export type DataTypes = MappedNoteItem | AppointmentItem | SurveyItem
export type DataToAggregate = Array<DataTypes>

export type TreatmentListConstructorProps = {
  [key in TreatmentListEnum]: NumberOfItemsInDateRange
}

interface AppointmentListConstructorProps {
  [key: string]: NumberOfItemsInDateRange
}

type NumberOfItemsInDateRange = {
  [key: string]: number
}

type BatchType = string | TreatmentListEnum
interface BatchAggregation {
  [key: BatchType]: NumberOfItemsInDateRange
}

type AggregationConstructorProps = {
  [key in AggregationMethodEnum]:
    | TreatmentListConstructorProps
    | AppointmentListConstructorProps
    | any
}

export function isNote(object: any): object is MappedNoteItem {
  return object.discriminator === ReportType.NOTES
}

export function isAppointment(object: any): object is AppointmentItem {
  return object.discriminator === ReportType.APPOINTMENTS
}

export function isSurveys(object: any): object is SurveyItem {
  return object.discriminator === ReportType.SURVEYS
}

export const graphDisplayConstructor = (): GraphDataProps => {
  return {
    [AggregationMethodEnum.DAY]: [],
    [AggregationMethodEnum.WEEK]: [],
    [AggregationMethodEnum.MONTH]: [],
  }
}

export const treatmentListConstructor = (): TreatmentListConstructorProps => {
  return {
    [TreatmentListEnum.IV_KETAMINE]: {},
    [TreatmentListEnum.IM_KETAMINE]: {},
    [TreatmentListEnum.SPRAVATO]: {},
    [TreatmentListEnum.MEDICAL_SOAP]: {},
    [TreatmentListEnum.THERAPY_SOAP]: {},
    [TreatmentListEnum.KAP]: {},
    [TreatmentListEnum.PSYCHOTHERAPY_NOTE]: {},
    [TreatmentListEnum.MEMO]: {},
    [TreatmentListEnum.CLINICAL_NOTE]: {},
    [TreatmentListEnum.EVALUATION_NOTE]: {},
  }
}

export const initializationForEachType = (
  data: DataToAggregate,
  batchKey: keyof DataTypes,
  batchKeyAliases: { [key: string]: string }
): AppointmentListConstructorProps => {
  const categoriesToBatch: BatchAggregation = {}
  if (isEmpty(data)) {
    categoriesToBatch[''] = {}
  }

  data.forEach((d: DataTypes) => {
    let batchKeyValue = batchKey ? d[batchKey] : 'None'
    if (batchKeyAliases[batchKeyValue]) {
      batchKeyValue = batchKeyAliases[batchKeyValue]
    }

    if (!categoriesToBatch[batchKeyValue]) {
      categoriesToBatch[batchKeyValue] = {}
    }
  })
  return categoriesToBatch
}

export const aggregationConstructor = (
  data: DataToAggregate,
  batchKey: keyof DataTypes,
  batchKeyAliases: { [key: string]: string }
): AggregationConstructorProps => {
  return {
    [AggregationMethodEnum.DAY]: initializationForEachType(
      data,
      batchKey,
      batchKeyAliases
    ),
    [AggregationMethodEnum.WEEK]: initializationForEachType(
      data,
      batchKey,
      batchKeyAliases
    ),
    [AggregationMethodEnum.MONTH]: initializationForEachType(
      data,
      batchKey,
      batchKeyAliases
    ),
  }
}

export const aggregateByInterval = (
  data: DataToAggregate,
  startDate: Date,
  endDate: Date,
  batchKey: keyof DataTypes,
  batchKeyAliases: { [key: string]: string }
): AggregationConstructorProps => {
  let dateIterator = startDate
  const initializedAggregation = aggregationConstructor(
    data,
    batchKey,
    batchKeyAliases
  )
  while (isBefore(dateIterator, endDate)) {
    const dayInterval = format(
      dateIterator,
      SLASH_DELIMINATED_STANDARD_DATE_FORMAT
    )
    const weekInterval = format(
      startOfWeek(dateIterator),
      SLASH_DELIMINATED_STANDARD_DATE_FORMAT
    )
    const monthInterval = format(
      startOfMonth(dateIterator),
      SLASH_DELIMINATED_STANDARD_DATE_FORMAT
    )
    if (isEmpty(data)) {
      initializedAggregation[AggregationMethodEnum.DAY][''][dayInterval] = 0
      initializedAggregation[AggregationMethodEnum.WEEK][''][weekInterval] = 0
      initializedAggregation[AggregationMethodEnum.MONTH][''][monthInterval] = 0
    }

    data.forEach((d: DataTypes) => {
      let batchKeyValue = batchKey ? d[batchKey] : 'None'
      if (batchKeyAliases[batchKeyValue]) {
        batchKeyValue = batchKeyAliases[batchKeyValue]
      }
      initializedAggregation[AggregationMethodEnum.DAY][batchKeyValue][
        dayInterval
      ] = 0
      initializedAggregation[AggregationMethodEnum.WEEK][batchKeyValue][
        weekInterval
      ] = 0
      initializedAggregation[AggregationMethodEnum.MONTH][batchKeyValue][
        monthInterval
      ] = 0
    })
    dateIterator = addDays(dateIterator, 1)
  }
  return initializedAggregation
}

export const createAggregationsForDateRange = (
  data: DataToAggregate,
  startDate: Date,
  endDate: Date,
  batchKey: keyof DataTypes = 'type',
  batchKeyAliases: { [key: string]: string } = {}
): GraphDataProps => {
  const sumForEachAggregationMethodAndBatchType: AggregationConstructorProps =
    data.reduce((acc, { ...item }) => {
      let batchKeyValue = batchKey ? item[batchKey] : 'None'
      if (batchKeyAliases[batchKeyValue]) {
        batchKeyValue = batchKeyAliases[batchKeyValue]
      }

      let timeUsedForAggregation
      if (isAppointment(item)) {
        timeUsedForAggregation = new Date(item.startTime)
      } else if (isNote(item)) {
        timeUsedForAggregation = new Date(item.createdOn)
      } else if (isSurveys(item)) {
        timeUsedForAggregation = new Date(item.createdAt)
      }

      if (timeUsedForAggregation) {
        acc[AggregationMethodEnum.DAY][batchKeyValue][
          format(timeUsedForAggregation, SLASH_DELIMINATED_STANDARD_DATE_FORMAT)
        ] += 1
        acc[AggregationMethodEnum.WEEK][batchKeyValue][
          format(
            startOfWeek(timeUsedForAggregation),
            SLASH_DELIMINATED_STANDARD_DATE_FORMAT
          )
        ] += 1
        acc[AggregationMethodEnum.MONTH][batchKeyValue][
          format(
            startOfMonth(timeUsedForAggregation),
            SLASH_DELIMINATED_STANDARD_DATE_FORMAT
          )
        ] += 1
      }
      return acc
    }, aggregateByInterval(data, startDate, endDate, batchKey, batchKeyAliases))

  const aggregations: GraphDataProps = graphDisplayConstructor()
  // Constructs the data in the correct format that needs to be used for Ant Design's Graph component
  const aggregationsForInterval = (interval: AggregationMethodEnum) => {
    Object.entries(sumForEachAggregationMethodAndBatchType[interval]).forEach(
      ([batchType, aggregationBasedOnDate]) => {
        Object.entries(
          aggregationBasedOnDate as NumberOfItemsInDateRange
        ).forEach(([key, value]) => {
          aggregations[interval].push({ category: batchType, date: key, value })
        })
      }
    )
  }

  // Aggregates for each interval
  Object.values(AggregationMethodEnum).forEach((value) => {
    aggregationsForInterval(value)
  })
  return aggregations
}

export const buildNoteTableData = (data: MappedNoteItem[]): NotesReportData[] =>
  data.map((note, i) => ({
    key: note.patientId + i,
    noteId: note.noteId,
    patientName: getNameToDisplay({
      firstName: note.patientFirstName,
      middleName: note.patientMiddleName,
      lastName: note.patientLastName,
    }),
    type: note.type,
    title: note.title,
    date: new Date(note.createdOn),
    signatures: note.signatures,
    publicId: note.publicId,
    csvSigned: note.signatures?.length ? 'Signed' : 'Unsigned',
    providerId: note.providerId,
    patientId: note.patientId,
  }))

export const buildAppointmentTableData = (
  appointments: AppointmentItem[]
): AppointmentsReportData[] =>
  appointments.map((appointment, i) => ({
    key: appointment.osmindPatientId + i,
    patientName: getNameToDisplay({
      firstName: appointment.patientFirstName,
      middleName: appointment.patientMiddleName,
      lastName: appointment.patientLastName,
    }),
    provider: appointment.eventProviders?.join(', '),
    type: appointment.type,
    eventName: appointment.eventName,
    date: new Date(appointment.startTime),
    patientPublicId: appointment.patientPublicId,
    providerId: appointment.providerId,
    patientId: appointment.osmindPatientId,
  }))

export const buildSurveyTableData = (
  surveys: SurveyItem[]
): SurveysReportData[] =>
  surveys.map((survey, i) => ({
    key: survey.patientId + i,
    patientName: getNameToDisplay({
      firstName: survey.patientFirstName,
      middleName: survey.patientMiddleName,
      lastName: survey.patientLastName,
    }),
    type: survey.type,
    date: new Date(survey.createdAt),
    score: survey.score,
    publicId: survey.publicId,
    patientId: survey.patientId,
    providerId: survey.providerId,
  }))

type BuildTableDataStrategy = {
  [ReportType.APPOINTMENTS]: (
    data: AppointmentItem[]
  ) => AppointmentsReportData[]
  [ReportType.NOTES]: (data: MappedNoteItem[]) => NotesReportData[]
  [ReportType.SURVEYS]: (data: SurveyItem[]) => SurveysReportData[]
}

export const buildTableDataStrategy: BuildTableDataStrategy = {
  [ReportType.APPOINTMENTS]: buildAppointmentTableData,
  [ReportType.NOTES]: buildNoteTableData,
  [ReportType.SURVEYS]: buildSurveyTableData,
}
