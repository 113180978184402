import React, { useCallback, useEffect, useState } from 'react'

import { Form } from 'antd'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'

import { activateNewUser, verifyNewUserInvite } from '../../api/api-lib'
import useQueryString from '../../hooks/useQueryString'
import { useAppContext } from '../../libs/contextLib'
import { setFreshpaintUserAndGroupProperties } from '../../libs/freshpaint'
import { notification } from '../../libs/notificationLib'
import SignupCard, { FormValues } from '../../stories/Authentication/SignupCard'
import SignupErrorCard, {
  InvalidInviteInfo,
} from '../../stories/Authentication/SignupErrorCard'
import { Address } from '../../stories/BaseComponents/AddressAutocomplete'

const ActivateUser: React.FC = () => {
  const [isCheckingInvite, setIsCheckingInvite] = useState<boolean>(true)
  const [isAValidInvite, setIsAValidInvite] = useState<boolean>(true)
  const [invalidInviteData, setInvalidInviteData] = useState<InvalidInviteInfo>(
    {
      title: 'Sorry, this activation link is no longer valid.',
      body: 'Please contact admin for assistance.',
    }
  )
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { userHasAuthenticated, setIsAuthenticating } = useAppContext() as any
  const [form] = Form.useForm()
  const query = useQueryString()
  const history = useHistory()

  const activationId = query.get('activationId')
  if (!activationId) {
    history.push('/login')
  }

  const initialValues: FormValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    phoneNumber: '',
    NPI: '',
    AutocompleteAddress: {
      Address1: '',
      Address2: '',
      City: '',
      State: '',
      Zipcode: '',
    },
    password: '',
    confirmPassword: '',
  }

  const handleAddressChange = useCallback(async (values: Address) => {
    const updateItem = form.getFieldsValue()
    if (updateItem.AutocompleteAddress) {
      updateItem.AutocompleteAddress.Address1 = values?.Address1 ?? ''
      updateItem.AutocompleteAddress.Address2 = values?.Address2 ?? ''
      updateItem.AutocompleteAddress.City = values?.City ?? ''
      updateItem.AutocompleteAddress.State = values?.State ?? ''
      updateItem.AutocompleteAddress.Zipcode = values?.Zipcode ?? ''
      form.setFieldsValue(updateItem)
    }
  }, [])

  const handleSubmit = async (values: FormValues) => {
    if (!activationId) return
    setIsSubmitting(true)
    try {
      const activationResponse = await activateNewUser({
        FirstName: values.firstName,
        MiddleName: values.middleName,
        LastName: values.lastName,
        Phone: values.phoneNumber,
        City: values.AutocompleteAddress.City,
        AddressLine1: values.AutocompleteAddress.Address1,
        AddressLine2: values.AutocompleteAddress.Address2,
        State: values.AutocompleteAddress.State,
        Zipcode: values.AutocompleteAddress.Zipcode,
        Password: values.password,
        ActivationId: activationId,
        NationalProviderIdentifier: values.NPI,
      })
      notification('You have successfully activated your account.', 'success')
      const newUserEmail = activationResponse.email
      await Auth.signIn(newUserEmail, values.password)
      history.push('/')
      setIsAuthenticating(true)
      userHasAuthenticated(true)
      await setFreshpaintUserAndGroupProperties()
    } catch (e: any) {
      notification(
        'Account activation failed. Please contact admin for assistance.',
        'error'
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const verifyActivationId = async () => {
    try {
      const activationId = query.get('activationId')
      await verifyNewUserInvite({ ActivationId: activationId })
    } catch (e) {
      const errorResponse = e.response.data
      if (errorResponse.error.includes('ActivationLinkExpired')) {
        const errorExpiredData = JSON.parse(errorResponse.error)
        const emailSubject = 'Activation link expired.'
        const emailBody = 'My activation link expired.'
        setInvalidInviteData({
          title: 'Sorry, this activation link is no longer valid.',
          body: `Please reach out to ${errorExpiredData.mainProviderName} for a new activation link.`,
          buttonData: {
            buttonTitle: 'Email Provider',
            buttonAction: (e) => {
              window.location.href = `mailto:${errorExpiredData.mainProviderEmail}?subject=${emailSubject}&body=${emailBody}`
              e.preventDefault()
            },
          },
        })
      }
      setIsAValidInvite(false)
    }
    setIsCheckingInvite(false)
  }

  useEffect(() => {
    verifyActivationId()
  }, [])

  if (isCheckingInvite || !isAValidInvite) {
    return (
      <SignupErrorCard isLoading={isCheckingInvite} {...invalidInviteData} />
    )
  }

  return (
    <SignupCard
      isLoading={isSubmitting}
      form={form}
      initialValues={initialValues}
      handleAddressChange={handleAddressChange}
      handleSubmit={handleSubmit}
    />
  )
}

export default ActivateUser
