import React from 'react'

import 'moment-timezone'
import { Col, Form } from 'react-bootstrap'
import 'react-dates/initialize'

import { AutoSizingTextArea } from '../../../../../../stories/BaseComponents/TextAreaInput'

import 'react-dates/lib/css/_datepicker.css'

interface AddDischargeProps {
  disabled: boolean
  fields: any
  detail: string
  handleFieldChange: (obj: any) => void
}

export default function SpravatoDischarge(props: AddDischargeProps) {
  return (
    <>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="PatientAlertAndResponsive"
        >
          <Form.Label className="font-weight-bold">
            Patient alert and responsive:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.PatientAlertAndResponsive)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="PatientAlertAndResponsiveYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                PatientAlertAndResponsive: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.PatientAlertAndResponsive)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="PatientAlertAndResponsiveNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                PatientAlertAndResponsive: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="PatientMentalStatus"
        >
          <Form.Label className="font-weight-bold">
            Patient at baseline mental status:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.PatientMetalStatus)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="PatientMentalStatusYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                PatientMetalStatus: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.PatientMetalStatus)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="PatientMentalStatusNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                PatientMetalStatus: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="PatientDeniesSuicidalIdeation"
        >
          <Form.Label className="font-weight-bold">
            Patient denies suicidal ideation:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.PatientDeniesSuicidalIdeation)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="PatientDeniesSuicidalIdeationYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                PatientDeniesSuicidalIdeation: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.PatientDeniesSuicidalIdeation)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="PatientDeniesSuicidalIdeationNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                PatientDeniesSuicidalIdeation: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="Nausea"
        >
          <Form.Label className="font-weight-bold">Nausea:</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.Nausea)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="NauseaYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                Nausea: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.Nausea)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="NauseaNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                Nausea: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="VitalsStable"
        >
          <Form.Label className="font-weight-bold">Vitals stable:</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.VitalsStable)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="VitalsStableYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                VitalsStable: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.VitalsStable)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="VitalsStableNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                VitalsStable: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="Ambulation"
        >
          <Form.Label className="font-weight-bold">Ambulation:</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.Ambulation)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="AmbulationYes"
            disabled={props.disabled}
            onChange={(eventvent) =>
              props.handleFieldChange({
                ...props.fields,
                Ambulation: eventvent.currentTarget.value,
              })
            }
            type="radio"
            label="Requires assistance"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.Ambulation)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="AmbulationNo"
            disabled={props.disabled}
            onChange={(eventvent) =>
              props.handleFieldChange({
                ...props.fields,
                Ambulation: eventvent.currentTarget.value,
              })
            }
            type="radio"
            label="Walking unassisted"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="DischargeInstruccionsReviewed"
        >
          <Form.Label className="font-weight-bold">
            Discharge instructions reviewed with patient + provided copy:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.DischargeInstruccionsReviewed)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="DischargeInstruccionsReviewedYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                DischargeInstruccionsReviewed: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.DischargeInstruccionsReviewed)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="DischargeInstruccionsReviewedNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                DischargeInstruccionsReviewed: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="ResponsibleAdultEscort"
        >
          <Form.Label className="font-weight-bold">
            Escorted to car service by clinic staff / discharged home with
            family/friend/caregiver:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.ResponsibleAdultEscort)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="ResponsibleAdultEscortYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                ResponsibleAdultEscort: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.ResponsibleAdultEscort)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="ResponsibleAdultEscortNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                ResponsibleAdultEscort: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          lg={12}
          style={{ marginLeft: '15px' }}
          controlId="DischargeNotes"
        >
          <Form.Label className="font-weight-bold">Discharge Notes</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.DischargeNotes}
            placeholder={props.detail ? '' : 'E.g. discharged to, by whom'}
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                DischargeNotes: event.target.value,
              })
            }
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          lg={12}
          style={{ marginLeft: '15px' }}
          controlId="FaceTimeNotes"
        >
          <Form.Label className="font-weight-bold">
            MD/NP Face-to-face Time
          </Form.Label>
          <Form.Control
            bsPrefix="form-input"
            value={props.fields.FaceTimeNotes}
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                FaceTimeNotes: event.target.value,
              })
            }
            as="textarea"
            rows={1}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          lg={12}
          style={{ marginLeft: '15px' }}
          controlId="SupportDocumentationNotes"
        >
          <Form.Label className="font-weight-bold">
            MD/NP Support/Documentation Time
          </Form.Label>
          <Form.Control
            bsPrefix="form-input"
            value={props.fields.SupportDocumentationNotes}
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SupportDocumentationNotes: event.target.value,
              })
            }
            as="textarea"
            rows={1}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          lg={12}
          style={{ marginLeft: '15px' }}
          controlId="SpravatoDischargeNotes"
        >
          <Form.Label className="font-weight-bold">Notes</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.SpravatoDischargeNotes}
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoDischargeNotes: event.target.value,
              })
            }
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
