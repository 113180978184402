import moment from 'moment'

import { BaseWeekView, WeekViewProps } from './BaseWeekView'
import { WeekView } from './WeekView'

export function WorkWeekView(props: WeekViewProps) {
  return <BaseWeekView {...props} workWeek />
}

WorkWeekView.range = (date: Date, { localizer }: { localizer: any }) => {
  return WeekView.range(date, { localizer }).filter(
    (d: Date) => moment(d).isoWeekday() <= 5
  )
}
WorkWeekView.navigate = WeekView.navigate
WorkWeekView.title = (date: Date) => {
  return `${date.toLocaleDateString()}`
}
