import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const americanIndianSubRaceCDC: PatientDemographicObject[] = [
  { '1004-1': { title: 'American Indian' } },
  { '1735-0': { title: 'Alaska Native' } },
  { '1006-6': { title: 'Abenaki' } },
  { '1008-2': { title: 'Algonquian' } },
  { '1010-8': { title: 'Apache' } },
  { '1021-5': { title: 'Arapaho' } },
  { '1026-4': { title: 'Arikara' } },
  { '1028-0': { title: 'Assiniboine' } },
  { '1030-6': { title: 'Assiniboine Sioux' } },
  { '1033-0': { title: 'Bannock' } },
  { '1035-5': { title: 'Blackfeet' } },
  { '1037-1': { title: 'Brotherton' } },
  { '1039-7': { title: 'Burt Lake Band' } },
  { '1041-3': { title: 'Caddo' } },
  { '1044-7': { title: 'Cahuilla' } },
  { '1053-8': { title: 'California Tribes' } },
  { '1068-6': { title: 'Canadian and Latin American Indian' } },
  { '1076-9': { title: 'Catawba' } },
  { '1078-5': { title: 'Cayuse' } },
  { '1080-1': { title: 'Chehalis' } },
  { '1082-7': { title: 'Chemakuan' } },
  { '1086-8': { title: 'Chemehuevi' } },
  { '1088-4': { title: 'Cherokee' } },
  { '1100-7': { title: 'Cherokee Shawnee' } },
  { '1102-3': { title: 'Cheyenne' } },
  { '1106-4': { title: 'Cheyenne-Arapaho' } },
  { '1108-0': { title: 'Chickahominy' } },
  { '1112-2': { title: 'Chickasaw' } },
  { '1114-8': { title: 'Chinook' } },
  { '1123-9': { title: 'Chippewa' } },
  { '1150-2': { title: 'Chippewa Cree' } },
  { '1153-6': { title: 'Chitimacha' } },
  { '1155-1': { title: 'Choctaw' } },
  { '1162-7': { title: 'Chumash' } },
  { '1165-0': { title: 'Clear Lake' } },
  { '1167-6': { title: "Coeur D'Alene" } },
  { '1169-2': { title: 'Coharie' } },
  { '1171-8': { title: 'Colorado River' } },
  { '1173-4': { title: 'Colville' } },
  { '1175-9': { title: 'Comanche' } },
  { '1178-3': { title: 'Coos, Lower Umpqua, Siuslaw' } },
  { '1180-9': { title: 'Coos' } },
  { '1182-5': { title: 'Coquilles' } },
  { '1184-1': { title: 'Costanoan' } },
  { '1186-6': { title: 'Coushatta' } },
  { '1189-0': { title: 'Cowlitz' } },
  { '1191-6': { title: 'Cree' } },
  { '1193-2': { title: 'Creek' } },
  { '1207-0': { title: 'Croatan' } },
  { '1209-6': { title: 'Crow' } },
  { '1211-2': { title: 'Cupeno' } },
  { '1214-6': { title: 'Delaware' } },
  { '1222-9': { title: 'Diegueno' } },
  { '1233-6': { title: 'Eastern Tribes' } },
  { '1250-0': { title: 'Esselen' } },
  { '1252-6': { title: 'Fort Belknap' } },
  { '1254-2': { title: 'Fort Berthold' } },
  { '1256-7': { title: 'Fort Mcdowell' } },
  { '1258-3': { title: 'Fort Hall' } },
  { '1260-9': { title: 'Gabrieleno' } },
  { '1262-5': { title: 'Grand Ronde' } },
  { '1264-1': { title: 'Gros Ventres' } },
  { '1267-4': { title: 'Haliwa' } },
  { '1269-0': { title: 'Hidatsa' } },
  { '1271-6': { title: 'Hoopa' } },
  { '1275-7': { title: 'Hoopa Extension' } },
  { '1277-3': { title: 'Houma' } },
  { '1279-9': { title: 'Inaja-Cosmit' } },
  { '1281-5': { title: 'Iowa' } },
  { '1285-6': { title: 'Iroquois' } },
  { '1297-1': { title: 'Juaneno' } },
  { '1299-7': { title: 'Kalispel' } },
  { '1301-1': { title: 'Karuk' } },
  { '1303-7': { title: 'Kaw' } },
  { '1305-2': { title: 'Kickapoo' } },
  { '1309-4': { title: 'Kiowa' } },
  { '1312-8': { title: 'Klallam' } },
  { '1317-7': { title: 'Klamath' } },
  { '1319-3': { title: 'Konkow' } },
  { '1321-9': { title: 'Kootenai' } },
  { '1323-5': { title: 'Lassik' } },
  { '1325-0': { title: 'Long Island' } },
  { '1331-8': { title: 'Luiseno' } },
  { '1340-9': { title: 'Lumbee' } },
  { '1342-5': { title: 'Lummi' } },
  { '1344-1': { title: 'Maidu' } },
  { '1348-2': { title: 'Makah' } },
  { '1350-8': { title: 'Maliseet' } },
  { '1352-4': { title: 'Mandan' } },
  { '1354-0': { title: 'Mattaponi' } },
  { '1356-5': { title: 'Menominee' } },
  { '1358-1': { title: 'Miami' } },
  { '1363-1': { title: 'Miccosukee' } },
  { '1365-6': { title: 'Micmac' } },
  { '1368-0': { title: 'Mission Indians' } },
  { '1370-6': { title: 'Miwok' } },
  { '1372-2': { title: 'Modoc' } },
  { '1374-8': { title: 'Mohegan' } },
  { '1376-3': { title: 'Mono' } },
  { '1378-9': { title: 'Nanticoke' } },
  { '1380-5': { title: 'Narragansett' } },
  { '1382-1': { title: 'Navajo' } },
  { '1387-0': { title: 'Nez Perce' } },
  { '1389-6': { title: 'Nomalaki' } },
  { '1391-2': { title: 'Northwest Tribes' } },
  { '1403-5': { title: 'Omaha' } },
  { '1405-0': { title: 'Oregon Athabaskan' } },
  { '1407-6': { title: 'Osage' } },
  { '1409-2': { title: 'Otoe-Missouria' } },
  { '1411-8': { title: 'Ottawa' } },
  { '1416-7': { title: 'Paiute' } },
  { '1439-9': { title: 'Pamunkey' } },
  { '1441-5': { title: 'Passamaquoddy' } },
  { '1445-6': { title: 'Pawnee' } },
  { '1448-0': { title: 'Penobscot' } },
  { '1450-6': { title: 'Peoria' } },
  { '1453-0': { title: 'Pequot' } },
  { '1456-3': { title: 'Pima' } },
  { '1460-5': { title: 'Piscataway' } },
  { '1462-1': { title: 'Pit River' } },
  { '1464-7': { title: 'Pomo' } },
  { '1474-6': { title: 'Ponca' } },
  { '1478-7': { title: 'Potawatomi' } },
  { '1487-8': { title: 'Powhatan' } },
  { '1489-4': { title: 'Pueblo' } },
  { '1518-0': { title: 'Puget Sound Salish' } },
  { '1541-2': { title: 'Quapaw' } },
  { '1543-8': { title: 'Quinault' } },
  { '1545-3': { title: 'Rappahannock' } },
  { '1547-9': { title: 'Reno-Sparks' } },
  { '1549-5': { title: 'Round Valley' } },
  { '1551-1': { title: 'Sac and Fox' } },
  { '1556-0': { title: 'Salinan' } },
  { '1558-6': { title: 'Salish' } },
  { '1560-2': { title: 'Salish and Kootenai' } },
  { '1562-8': { title: 'Schaghticoke' } },
  { '1564-4': { title: 'Scott Valley' } },
  { '1566-9': { title: 'Seminole' } },
  { '1573-5': { title: 'Serrano' } },
  { '1576-8': { title: 'Shasta' } },
  { '1578-4': { title: 'Shawnee' } },
  { '1582-6': { title: 'Shinnecock' } },
  { '1584-2': { title: 'Shoalwater Bay' } },
  { '1586-7': { title: 'Shoshone' } },
  { '1602-2': { title: 'Shoshone Paiute' } },
  { '1607-1': { title: 'Siletz' } },
  { '1609-7': { title: 'Sioux' } },
  { '1643-6': { title: 'Siuslaw' } },
  { '1645-1': { title: 'Spokane' } },
  { '1647-7': { title: 'Stewart' } },
  { '1649-3': { title: 'Stockbridge' } },
  { '1651-9': { title: 'Susanville' } },
  { '1653-5': { title: "Tohono O'Odham" } },
  { '1659-2': { title: 'Tolowa' } },
  { '1661-8': { title: 'Tonkawa' } },
  { '1663-4': { title: 'Tygh' } },
  { '1665-9': { title: 'Umatilla' } },
  { '1667-5': { title: 'Umpqua' } },
  { '1670-9': { title: 'Ute' } },
  { '1675-8': { title: 'Wailaki' } },
  { '1677-4': { title: 'Walla-Walla' } },
  { '1679-0': { title: 'Wampanoag' } },
  { '1683-2': { title: 'Warm Springs' } },
  { '1685-7': { title: 'Wascopum' } },
  { '1687-3': { title: 'Washoe' } },
  { '1692-3': { title: 'Wichita' } },
  { '1694-9': { title: 'Wind River' } },
  { '1696-4': { title: 'Winnebago' } },
  { '1700-4': { title: 'Winnemucca' } },
  { '1702-0': { title: 'Wintun' } },
  { '1704-6': { title: 'Wiyot' } },
  { '1707-9': { title: 'Yakama' } },
  { '1709-5': { title: 'Yakama Cowlitz' } },
  { '1711-1': { title: 'Yaqui' } },
  { '1715-2': { title: 'Yavapai Apache' } },
  { '1717-8': { title: 'Yokuts' } },
  { '1722-8': { title: 'Yuchi' } },
  { '1724-4': { title: 'Yuman' } },
  { '1732-7': { title: 'Yurok' } },
  { '1011-6': { title: 'Chiricahua' } },
  { '1012-4': { title: 'Fort Sill Apache' } },
  { '1013-2': { title: 'Jicarilla Apache' } },
  { '1014-0': { title: 'Lipan Apache' } },
  { '1015-7': { title: 'Mescalero Apache' } },
  { '1016-5': { title: 'Oklahoma Apache' } },
  { '1017-3': { title: 'Payson Apache' } },
  { '1018-1': { title: 'San Carlos Apache' } },
  { '1019-9': { title: 'White Mountain Apache' } },
  { '1022-3': { title: 'Northern Arapaho' } },
  { '1023-1': { title: 'Southern Arapaho' } },
  { '1024-9': { title: 'Wind River Arapaho' } },
  { '1031-4': { title: 'Fort Peck Assiniboine Sioux' } },
  { '1042-1': { title: 'Oklahoma Cado' } },
  { '1045-4': { title: 'Agua Caliente Cahuilla' } },
  { '1046-2': { title: 'Augustine' } },
  { '1047-0': { title: 'Cabazon' } },
  { '1048-8': { title: 'Los Coyotes' } },
  { '1049-6': { title: 'Morongo' } },
  { '1050-4': { title: 'Santa Rosa Cahuilla' } },
  { '1051-2': { title: 'Torres-Martinez' } },
  { '1054-6': { title: 'Cahto' } },
  { '1055-3': { title: 'Chimariko' } },
  { '1056-1': { title: 'Coast Miwok' } },
  { '1057-9': { title: 'Digger' } },
  { '1058-7': { title: 'Kawaiisu' } },
  { '1059-5': { title: 'Kern River' } },
  { '1060-3': { title: 'Mattole' } },
  { '1061-1': { title: 'Red Wood' } },
  { '1062-9': { title: 'Santa Rosa' } },
  { '1063-7': { title: 'Takelma' } },
  { '1064-5': { title: 'Wappo' } },
  { '1065-2': { title: 'Yana' } },
  { '1066-0': { title: 'Yuki' } },
  { '1069-4': { title: 'Canadian Indian' } },
  { '1070-2': { title: 'Central American Indian' } },
  { '1071-0': { title: 'French American Indian' } },
  { '1072-8': { title: 'Mexican American Indian' } },
  { '1073-6': { title: 'South American Indian' } },
  { '1074-4': { title: 'Spanish American Indian' } },
  { '1083-5': { title: 'Hoh' } },
  { '1084-3': { title: 'Quileute' } },
  { '1089-2': { title: 'Cherokee Alabama' } },
  { '1090-0': { title: 'Cherokees of Northeast Alabama' } },
  { '1091-8': { title: 'Cherokees of Southeast Alabama' } },
  { '1092-6': { title: 'Eastern Cherokee' } },
  { '1093-4': { title: 'Echota Cherokee' } },
  { '1094-2': { title: 'Etowah Cherokee' } },
  { '1095-9': { title: 'Northern Cherokee' } },
  { '1096-7': { title: 'Tuscola' } },
  { '1097-5': { title: 'United Keetowah Band of Cherokee' } },
  { '1098-3': { title: 'Western Cherokee' } },
  { '1103-1': { title: 'Northern Cheyenne' } },
  { '1104-9': { title: 'Southern Cheyenne' } },
  { '1109-8': { title: 'Eastern Chickahominy' } },
  { '1110-6': { title: 'Western Chickahominy' } },
  { '1115-5': { title: 'Clatsop' } },
  { '1116-3': { title: 'Columbia River Chinook' } },
  { '1117-1': { title: 'Kathlamet' } },
  { '1118-9': { title: 'Upper Chinook' } },
  { '1119-7': { title: 'Wakiakum Chinook' } },
  { '1120-5': { title: 'Willapa Chinook' } },
  { '1121-3': { title: 'Wishram' } },
  { '1124-7': { title: 'Bad River' } },
  { '1125-4': { title: 'Bay Mills Chippewa' } },
  { '1126-2': { title: 'Bois Forte' } },
  { '1127-0': { title: 'Burt Lake Chippewa' } },
  { '1128-8': { title: 'Fond du Lac' } },
  { '1129-6': { title: 'Grand Portage' } },
  { '1130-4': { title: 'Grand Traverse Band of Ottawa/Chippewa' } },
  { '1131-2': { title: 'Keweenaw' } },
  { '1132-0': { title: 'Lac Courte Oreilles' } },
  { '1133-8': { title: 'Lac du Flambeau' } },
  { '1134-6': { title: 'Lac Vieux Desert Chippewa' } },
  { '1135-3': { title: 'Lake Superior' } },
  { '1136-1': { title: 'Leech Lake' } },
  { '1137-9': { title: 'Little Shell Chippewa' } },
  { '1138-7': { title: 'Mille Lacs' } },
  { '1139-5': { title: 'Minnesota Chippewa' } },
  { '1140-3': { title: 'Ontonagon' } },
  { '1141-1': { title: 'Red Cliff Chippewa' } },
  { '1142-9': { title: 'Red Lake Chippewa' } },
  { '1143-7': { title: 'Saginaw Chippewa' } },
  { '1144-5': { title: 'St. Croix Chippewa' } },
  { '1145-2': { title: 'Sault Ste. Marie Chippewa' } },
  { '1146-0': { title: 'Sokoagon Chippewa' } },
  { '1147-8': { title: 'Turtle Mountain' } },
  { '1148-6': { title: 'White Earth' } },
  { '1151-0': { title: "Rocky Boy's Chippewa Cree" } },
  { '1156-9': { title: 'Clifton Choctaw' } },
  { '1157-7': { title: 'Jena Choctaw' } },
  { '1158-5': { title: 'Mississippi Choctaw' } },
  { '1159-3': { title: 'Mowa Band of Choctaw' } },
  { '1160-1': { title: 'Oklahoma Choctaw' } },
  { '1163-5': { title: 'Santa Ynez' } },
  { '1176-7': { title: 'Oklahoma Comanche' } },
  { '1187-4': { title: 'Alabama Coushatta' } },
  { '1194-0': { title: 'Alabama Creek' } },
  { '1195-7': { title: 'Alabama Quassarte' } },
  { '1196-5': { title: 'Eastern Creek' } },
  { '1197-3': { title: 'Eastern Muscogee' } },
  { '1198-1': { title: 'Kialegee' } },
  { '1199-9': { title: 'Lower Muscogee' } },
  { '1200-5': { title: 'Machis Lower Creek Indian' } },
  { '1201-3': { title: 'Poarch Band' } },
  { '1202-1': { title: 'Principal Creek Indian Nation' } },
  { '1203-9': { title: 'Star Clan of Muscogee Creeks' } },
  { '1204-7': { title: 'Thlopthlocco' } },
  { '1205-4': { title: 'Tuckabachee' } },
  { '1212-0': { title: 'Agua Caliente' } },
  { '1215-3': { title: 'Eastern Delaware' } },
  { '1216-1': { title: 'Lenni-Lenape' } },
  { '1217-9': { title: 'Munsee' } },
  { '1218-7': { title: 'Oklahoma Delaware' } },
  { '1219-5': { title: 'Rampough Mountain' } },
  { '1220-3': { title: 'Sand Hill' } },
  { '1223-7': { title: 'Campo' } },
  { '1224-5': { title: 'Capitan Grande' } },
  { '1225-2': { title: 'Cuyapaipe' } },
  { '1226-0': { title: 'La Posta' } },
  { '1227-8': { title: 'Manzanita' } },
  { '1228-6': { title: 'Mesa Grande' } },
  { '1229-4': { title: 'San Pasqual' } },
  { '1230-2': { title: 'Santa Ysabel' } },
  { '1231-0': { title: 'Sycuan' } },
  { '1234-4': { title: 'Attacapa' } },
  { '1235-1': { title: 'Biloxi' } },
  { '1236-9': { title: 'Georgetown (Eastern Tribes)' } },
  { '1237-7': { title: 'Moor' } },
  { '1238-5': { title: 'Nansemond' } },
  { '1239-3': { title: 'Natchez' } },
  { '1240-1': { title: 'Nausu Waiwash' } },
  { '1241-9': { title: 'Nipmuc' } },
  { '1242-7': { title: 'Paugussett' } },
  { '1243-5': { title: 'Pocomoke Acohonock' } },
  { '1244-3': { title: 'Southeastern Indians' } },
  { '1245-0': { title: 'Susquehanock' } },
  { '1246-8': { title: 'Tunica Biloxi' } },
  { '1247-6': { title: 'Waccamaw-Siousan' } },
  { '1248-4': { title: 'Wicomico' } },
  { '1265-8': { title: 'Atsina' } },
  { '1272-4': { title: 'Trinity' } },
  { '1273-2': { title: 'Whilkut' } },
  { '1282-3': { title: 'Iowa of Kansas-Nebraska' } },
  { '1283-1': { title: 'Iowa of Oklahoma' } },
  { '1286-4': { title: 'Cayuga' } },
  { '1287-2': { title: 'Mohawk' } },
  { '1288-0': { title: 'Oneida' } },
  { '1289-8': { title: 'Onondaga' } },
  { '1290-6': { title: 'Seneca' } },
  { '1291-4': { title: 'Seneca Nation' } },
  { '1292-2': { title: 'Seneca-Cayuga' } },
  { '1293-0': { title: 'Tonawanda Seneca' } },
  { '1294-8': { title: 'Tuscarora' } },
  { '1295-5': { title: 'Wyandotte' } },
  { '1306-0': { title: 'Oklahoma Kickapoo' } },
  { '1307-8': { title: 'Texas Kickapoo' } },
  { '1310-2': { title: 'Oklahoma Kiowa' } },
  { '1313-6': { title: 'Jamestown' } },
  { '1314-4': { title: 'Lower Elwha' } },
  { '1315-1': { title: 'Port Gamble Klallam' } },
  { '1326-8': { title: 'Matinecock' } },
  { '1327-6': { title: 'Montauk' } },
  { '1328-4': { title: 'Poospatuck' } },
  { '1329-2': { title: 'Setauket' } },
  { '1332-6': { title: 'La Jolla' } },
  { '1333-4': { title: 'Pala' } },
  { '1334-2': { title: 'Pauma' } },
  { '1335-9': { title: 'Pechanga' } },
  { '1336-7': { title: 'Soboba' } },
  { '1337-5': { title: 'Twenty-Nine Palms' } },
  { '1338-3': { title: 'Temecula' } },
  { '1345-8': { title: 'Mountain Maidu' } },
  { '1346-6': { title: 'Nishinam' } },
  { '1359-9': { title: 'Illinois Miami' } },
  { '1360-7': { title: 'Indiana Miami' } },
  { '1361-5': { title: 'Oklahoma Miami' } },
  { '1366-4': { title: 'Aroostook' } },
  { '1383-9': { title: 'Alamo Navajo' } },
  { '1384-7': { title: 'Canoncito Navajo' } },
  { '1385-4': { title: 'Ramah Navajo' } },
  { '1392-0': { title: 'Alsea' } },
  { '1393-8': { title: 'Celilo' } },
  { '1394-6': { title: 'Columbia' } },
  { '1395-3': { title: 'Kalapuya' } },
  { '1396-1': { title: 'Molala' } },
  { '1397-9': { title: 'Talakamish' } },
  { '1398-7': { title: 'Tenino' } },
  { '1399-5': { title: 'Tillamook' } },
  { '1400-1': { title: 'Wenatchee' } },
  { '1401-9': { title: 'Yahooskin' } },
  { '1412-6': { title: 'Burt Lake Ottawa' } },
  { '1413-4': { title: 'Michigan Ottawa' } },
  { '1414-2': { title: 'Oklahoma Ottawa' } },
  { '1417-5': { title: 'Bishop' } },
  { '1418-3': { title: 'Bridgeport' } },
  { '1419-1': { title: 'Burns Paiute' } },
  { '1420-9': { title: 'Cedarville' } },
  { '1421-7': { title: 'Fort Bidwell' } },
  { '1422-5': { title: 'Fort Independence' } },
  { '1423-3': { title: 'Kaibab' } },
  { '1424-1': { title: 'Las Vegas' } },
  { '1425-8': { title: 'Lone Pine' } },
  { '1426-6': { title: 'Lovelock' } },
  { '1427-4': { title: 'Malheur Paiute' } },
  { '1428-2': { title: 'Moapa' } },
  { '1429-0': { title: 'Northern Paiute' } },
  { '1430-8': { title: 'Owens Valley' } },
  { '1431-6': { title: 'Pyramid Lake' } },
  { '1432-4': { title: 'San Juan Southern Paiute' } },
  { '1433-2': { title: 'Southern Paiute' } },
  { '1434-0': { title: 'Summit Lake' } },
  { '1435-7': { title: 'Utu Utu Gwaitu Paiute' } },
  { '1436-5': { title: 'Walker River' } },
  { '1437-3': { title: 'Yerington Paiute' } },
  { '1442-3': { title: 'Indian Township' } },
  { '1443-1': { title: 'Pleasant Point Passamaquoddy' } },
  { '1446-4': { title: 'Oklahoma Pawnee' } },
  { '1451-4': { title: 'Oklahoma Peoria' } },
  { '1454-8': { title: 'Marshantucket Pequot' } },
  { '1457-1': { title: 'Gila River Pima-Maricopa' } },
  { '1458-9': { title: 'Salt River Pima-Maricopa' } },
  { '1465-4': { title: 'Central Pomo' } },
  { '1466-2': { title: 'Dry Creek' } },
  { '1467-0': { title: 'Eastern Pomo' } },
  { '1468-8': { title: 'Kashia' } },
  { '1469-6': { title: 'Northern Pomo' } },
  { '1470-4': { title: 'Scotts Valley' } },
  { '1471-2': { title: 'Stonyford' } },
  { '1472-0': { title: 'Sulphur Bank' } },
  { '1475-3': { title: 'Nebraska Ponca' } },
  { '1476-1': { title: 'Oklahoma Ponca' } },
  { '1479-5': { title: 'Citizen Band Potawatomi' } },
  { '1480-3': { title: 'Forest County' } },
  { '1481-1': { title: 'Hannahville' } },
  { '1482-9': { title: 'Huron Potawatomi' } },
  { '1483-7': { title: 'Pokagon Potawatomi' } },
  { '1484-5': { title: 'Prairie Band' } },
  { '1485-2': { title: 'Wisconsin Potawatomi' } },
  { '1490-2': { title: 'Acoma' } },
  { '1491-0': { title: 'Arizona Tewa' } },
  { '1492-8': { title: 'Cochiti' } },
  { '1493-6': { title: 'Hopi' } },
  { '1494-4': { title: 'Isleta' } },
  { '1495-1': { title: 'Jemez' } },
  { '1496-9': { title: 'Keres' } },
  { '1497-7': { title: 'Laguna' } },
  { '1498-5': { title: 'Nambe' } },
  { '1499-3': { title: 'Picuris' } },
  { '1500-8': { title: 'Piro' } },
  { '1501-6': { title: 'Pojoaque' } },
  { '1502-4': { title: 'San Felipe' } },
  { '1503-2': { title: 'San Ildefonso' } },
  { '1504-0': { title: 'San Juan Pueblo' } },
  { '1505-7': { title: 'San Juan De' } },
  { '1506-5': { title: 'San Juan' } },
  { '1507-3': { title: 'Sandia' } },
  { '1508-1': { title: 'Santa Ana' } },
  { '1509-9': { title: 'Santa Clara' } },
  { '1510-7': { title: 'Santo Domingo' } },
  { '1511-5': { title: 'Taos' } },
  { '1512-3': { title: 'Tesuque' } },
  { '1513-1': { title: 'Tewa' } },
  { '1514-9': { title: 'Tigua' } },
  { '1515-6': { title: 'Zia' } },
  { '1516-4': { title: 'Zuni' } },
  { '1519-8': { title: 'Duwamish' } },
  { '1520-6': { title: 'Kikiallus' } },
  { '1521-4': { title: 'Lower Skagit' } },
  { '1522-2': { title: 'Muckleshoot' } },
  { '1523-0': { title: 'Nisqually' } },
  { '1524-8': { title: 'Nooksack' } },
  { '1525-5': { title: 'Port Madison' } },
  { '1526-3': { title: 'Puyallup' } },
  { '1527-1': { title: 'Samish' } },
  { '1528-9': { title: 'Sauk-Suiattle' } },
  { '1529-7': { title: 'Skokomish' } },
  { '1530-5': { title: 'Skykomish' } },
  { '1531-3': { title: 'Snohomish' } },
  { '1532-1': { title: 'Snoqualmie' } },
  { '1533-9': { title: 'Squaxin Island' } },
  { '1534-7': { title: 'Steilacoom' } },
  { '1535-4': { title: 'Stillaguamish' } },
  { '1536-2': { title: 'Suquamish' } },
  { '1537-0': { title: 'Swinomish' } },
  { '1538-8': { title: 'Tulalip' } },
  { '1539-6': { title: 'Upper Skagit' } },
  { '1552-9': { title: 'Iowa Sac and Fox' } },
  { '1553-7': { title: 'Missouri Sac and Fox' } },
  { '1554-5': { title: 'Oklahoma Sac and Fox' } },
  { '1567-7': { title: 'Big Cypress' } },
  { '1568-5': { title: 'Brighton' } },
  { '1569-3': { title: 'Florida Seminole' } },
  { '1570-1': { title: 'Hollywood Seminole' } },
  { '1571-9': { title: 'Oklahoma Seminole' } },
  { '1574-3': { title: 'San Manual' } },
  { '1579-2': { title: 'Absentee Shawnee' } },
  { '1580-0': { title: 'Eastern Shawnee' } },
  { '1587-5': { title: 'Battle Mountain' } },
  { '1588-3': { title: 'Duckwater' } },
  { '1589-1': { title: 'Elko' } },
  { '1590-9': { title: 'Ely' } },
  { '1591-7': { title: 'Goshute' } },
  { '1592-5': { title: 'Panamint' } },
  { '1593-3': { title: 'Ruby Valley' } },
  { '1594-1': { title: 'Skull Valley' } },
  { '1595-8': { title: 'South Fork Shoshone' } },
  { '1596-6': { title: 'Te-Moak Western Shoshone' } },
  { '1597-4': { title: 'Timbi-Sha Shoshone' } },
  { '1598-2': { title: 'Washakie' } },
  { '1599-0': { title: 'Wind River Shoshone' } },
  { '1600-6': { title: 'Yomba' } },
  { '1603-0': { title: 'Duck Valley' } },
  { '1604-8': { title: 'Fallon' } },
  { '1605-5': { title: 'Fort McDermitt' } },
  { '1610-5': { title: 'Blackfoot Sioux' } },
  { '1611-3': { title: 'Brule Sioux' } },
  { '1612-1': { title: 'Cheyenne River Sioux' } },
  { '1613-9': { title: 'Crow Creek Sioux' } },
  { '1614-7': { title: 'Dakota Sioux' } },
  { '1615-4': { title: 'Flandreau Santee' } },
  { '1616-2': { title: 'Fort Peck' } },
  { '1617-0': { title: 'Lake Traverse Sioux' } },
  { '1618-8': { title: 'Lower Brule Sioux' } },
  { '1619-6': { title: 'Lower Sioux' } },
  { '1620-4': { title: 'Mdewakanton Sioux' } },
  { '1621-2': { title: 'Miniconjou' } },
  { '1622-0': { title: 'Oglala Sioux' } },
  { '1623-8': { title: 'Pine Ridge Sioux' } },
  { '1624-6': { title: 'Pipestone Sioux' } },
  { '1625-3': { title: 'Prairie Island Sioux' } },
  { '1626-1': { title: 'Prior Lake Sioux' } },
  { '1627-9': { title: 'Rosebud Sioux' } },
  { '1628-7': { title: 'Sans Arc Sioux' } },
  { '1629-5': { title: 'Santee Sioux' } },
  { '1630-3': { title: 'Sisseton-Wahpeton' } },
  { '1631-1': { title: 'Sisseton Sioux' } },
  { '1632-9': { title: 'Spirit Lake Sioux' } },
  { '1633-7': { title: 'Standing Rock Sioux' } },
  { '1634-5': { title: 'Teton Sioux' } },
  { '1635-2': { title: 'Two Kettle Sioux' } },
  { '1636-0': { title: 'Upper Sioux' } },
  { '1637-8': { title: 'Wahpekute Sioux' } },
  { '1638-6': { title: 'Wahpeton Sioux' } },
  { '1639-4': { title: 'Wazhaza Sioux' } },
  { '1640-2': { title: 'Yankton Sioux' } },
  { '1641-0': { title: 'Yanktonai Sioux' } },
  { '1654-3': { title: 'Ak-Chin' } },
  { '1655-0': { title: 'Gila Bend' } },
  { '1656-8': { title: 'San Xavier' } },
  { '1657-6': { title: 'Sells' } },
  { '1668-3': { title: 'Cow Creek Umpqua' } },
  { '1671-7': { title: 'Allen Canyon' } },
  { '1672-5': { title: 'Uintah Ute' } },
  { '1673-3': { title: 'Ute Mountain Ute' } },
  { '1680-8': { title: 'Gay Head Wampanoag' } },
  { '1681-6': { title: 'Mashpee Wampanoag' } },
  { '1688-1': { title: 'Alpine' } },
  { '1689-9': { title: 'Carson' } },
  { '1690-7': { title: 'Dresslerville' } },
  { '1697-2': { title: 'Ho-chunk' } },
  { '1698-0': { title: 'Nebraska Winnebago' } },
  { '1705-3': { title: 'Table Bluff' } },
  { '1712-9': { title: 'Barrio Libre' } },
  { '1713-7': { title: 'Pascua Yaqui' } },
  { '1718-6': { title: 'Chukchansi' } },
  { '1719-4': { title: 'Tachi' } },
  { '1720-2': { title: 'Tule River' } },
  { '1725-1': { title: 'Cocopah' } },
  { '1726-9': { title: 'Havasupai' } },
  { '1727-7': { title: 'Hualapai' } },
  { '1728-5': { title: 'Maricopa' } },
  { '1729-3': { title: 'Mohave' } },
  { '1730-1': { title: 'Quechan' } },
  { '1731-9': { title: 'Yavapai' } },
  { '1733-5': { title: 'Coast Yurok' } },
  { '1737-6': { title: 'Alaska Indian' } },
  { '1840-8': { title: 'Eskimo' } },
  { '1966-1': { title: 'Aleut' } },
  { '1739-2': { title: 'Alaskan Athabascan' } },
  { '1811-9': { title: 'Southeast Alaska' } },
  { '1740-0': { title: 'Ahtna' } },
  { '1741-8': { title: 'Alatna' } },
  { '1742-6': { title: 'Alexander' } },
  { '1743-4': { title: 'Allakaket' } },
  { '1744-2': { title: 'Alanvik' } },
  { '1745-9': { title: 'Anvik' } },
  { '1746-7': { title: 'Arctic' } },
  { '1747-5': { title: 'Beaver' } },
  { '1748-3': { title: 'Birch Creek' } },
  { '1749-1': { title: 'Cantwell' } },
  { '1750-9': { title: 'Chalkyitsik' } },
  { '1751-7': { title: 'Chickaloon' } },
  { '1752-5': { title: 'Chistochina' } },
  { '1753-3': { title: 'Chitina' } },
  { '1754-1': { title: 'Circle' } },
  { '1755-8': { title: 'Cook Inlet' } },
  { '1756-6': { title: 'Copper Center' } },
  { '1757-4': { title: 'Copper River' } },
  { '1758-2': { title: 'Dot Lake' } },
  { '1759-0': { title: 'Doyon' } },
  { '1760-8': { title: 'Eagle' } },
  { '1761-6': { title: 'Eklutna' } },
  { '1762-4': { title: 'Evansville' } },
  { '1763-2': { title: 'Fort Yukon' } },
  { '1764-0': { title: 'Gakona' } },
  { '1765-7': { title: 'Galena' } },
  { '1766-5': { title: 'Grayling' } },
  { '1767-3': { title: 'Gulkana' } },
  { '1768-1': { title: 'Healy Lake' } },
  { '1769-9': { title: 'Holy Cross' } },
  { '1770-7': { title: 'Hughes' } },
  { '1771-5': { title: 'Huslia' } },
  { '1772-3': { title: 'Iliamna' } },
  { '1773-1': { title: 'Kaltag' } },
  { '1774-9': { title: 'Kluti Kaah' } },
  { '1775-6': { title: 'Knik' } },
  { '1776-4': { title: 'Koyukuk' } },
  { '1777-2': { title: 'Lake Minchumina' } },
  { '1778-0': { title: 'Lime' } },
  { '1779-8': { title: 'Mcgrath' } },
  { '1780-6': { title: 'Manley Hot Springs' } },
  { '1781-4': { title: 'Mentasta Lake' } },
  { '1782-2': { title: 'Minto' } },
  { '1783-0': { title: 'Nenana' } },
  { '1784-8': { title: 'Nikolai' } },
  { '1785-5': { title: 'Ninilchik' } },
  { '1786-3': { title: 'Nondalton' } },
  { '1787-1': { title: 'Northway' } },
  { '1788-9': { title: 'Nulato' } },
  { '1789-7': { title: 'Pedro Bay' } },
  { '1790-5': { title: 'Rampart' } },
  { '1791-3': { title: 'Ruby' } },
  { '1792-1': { title: 'Salamatof' } },
  { '1793-9': { title: 'Seldovia' } },
  { '1794-7': { title: 'Slana' } },
  { '1795-4': { title: 'Shageluk' } },
  { '1796-2': { title: 'Stevens' } },
  { '1797-0': { title: 'Stony River' } },
  { '1798-8': { title: 'Takotna' } },
  { '1799-6': { title: 'Tanacross' } },
  { '1800-2': { title: 'Tanaina' } },
  { '1801-0': { title: 'Tanana' } },
  { '1802-8': { title: 'Tanana Chiefs' } },
  { '1803-6': { title: 'Tazlina' } },
  { '1804-4': { title: 'Telida' } },
  { '1805-1': { title: 'Tetlin' } },
  { '1806-9': { title: 'Tok' } },
  { '1807-7': { title: 'Tyonek' } },
  { '1808-5': { title: 'Venetie' } },
  { '1809-3': { title: 'Wiseman' } },
  { '1813-5': { title: 'Tlingit-Haida' } },
  { '1837-4': { title: 'Tsimshian' } },
  { '1814-3': { title: 'Angoon' } },
  { '1815-0': { title: 'Central Council of Tlingit and Haida Tribes' } },
  { '1816-8': { title: 'Chilkat' } },
  { '1817-6': { title: 'Chilkoot' } },
  { '1818-4': { title: 'Craig' } },
  { '1819-2': { title: 'Douglas' } },
  { '1820-0': { title: 'Haida' } },
  { '1821-8': { title: 'Hoonah' } },
  { '1822-6': { title: 'Hydaburg' } },
  { '1823-4': { title: 'Kake' } },
  { '1824-2': { title: 'Kasaan' } },
  { '1825-9': { title: 'Kenaitze' } },
  { '1826-7': { title: 'Ketchikan' } },
  { '1827-5': { title: 'Klawock' } },
  { '1828-3': { title: 'Pelican' } },
  { '1829-1': { title: 'Petersburg' } },
  { '1830-9': { title: 'Saxman' } },
  { '1831-7': { title: 'Sitka' } },
  { '1832-5': { title: 'Tenakee Springs' } },
  { '1833-3': { title: 'Tlingit' } },
  { '1834-1': { title: 'Wrangell' } },
  { '1835-8': { title: 'Yakutat' } },
  { '1838-2': { title: 'Metlakatla' } },
  { '1842-4': { title: 'Greenland Eskimo' } },
  { '1844-0': { title: 'Inupiat Eskimo' } },
  { '1891-1': { title: 'Siberian Eskimo' } },
  { '1896-0': { title: 'Yupik Eskimo' } },
  { '1845-7': { title: 'Ambler' } },
  { '1846-5': { title: 'Anaktuvuk' } },
  { '1847-3': { title: 'Anaktuvuk Pass' } },
  { '1848-1': { title: 'Arctic Slope Inupiat' } },
  { '1849-9': { title: 'Arctic Slope Corporation' } },
  { '1850-7': { title: 'Atqasuk' } },
  { '1851-5': { title: 'Barrow' } },
  { '1852-3': { title: 'Bering Straits Inupiat' } },
  { '1853-1': { title: 'Brevig Mission' } },
  { '1854-9': { title: 'Buckland' } },
  { '1855-6': { title: 'Chinik' } },
  { '1856-4': { title: 'Council' } },
  { '1857-2': { title: 'Deering' } },
  { '1858-0': { title: 'Elim' } },
  { '1859-8': { title: 'Golovin' } },
  { '1860-6': { title: 'Inalik Diomede' } },
  { '1861-4': { title: 'Inupiaq' } },
  { '1862-2': { title: 'Kaktovik' } },
  { '1863-0': { title: 'Kawerak' } },
  { '1864-8': { title: 'Kiana' } },
  { '1865-5': { title: 'Kivalina' } },
  { '1866-3': { title: 'Kobuk' } },
  { '1867-1': { title: 'Kotzebue' } },
  { '1868-9': { title: 'Koyuk' } },
  { '1869-7': { title: 'Kwiguk' } },
  { '1870-5': { title: 'Mauneluk Inupiat' } },
  { '1871-3': { title: 'Nana Inupiat' } },
  { '1872-1': { title: 'Noatak' } },
  { '1873-9': { title: 'Nome' } },
  { '1874-7': { title: 'Noorvik' } },
  { '1875-4': { title: 'Nuiqsut' } },
  { '1876-2': { title: 'Point Hope' } },
  { '1877-0': { title: 'Point Lay' } },
  { '1878-8': { title: 'Selawik' } },
  { '1879-6': { title: 'Shaktoolik' } },
  { '1880-4': { title: 'Shishmaref' } },
  { '1881-2': { title: 'Shungnak' } },
  { '1882-0': { title: 'Solomon' } },
  { '1883-8': { title: 'Teller' } },
  { '1884-6': { title: 'Unalakleet' } },
  { '1885-3': { title: 'Wainwright' } },
  { '1886-1': { title: 'Wales' } },
  { '1887-9': { title: 'White Mountain' } },
  { '1888-7': { title: 'White Mountain Inupiat' } },
  { '1889-5': { title: "Mary's Igloo" } },
  { '1892-9': { title: 'Gambell' } },
  { '1893-7': { title: 'Savoonga' } },
  { '1894-5': { title: 'Siberian Yupik' } },
  { '1897-8': { title: 'Akiachak' } },
  { '1898-6': { title: 'Akiak' } },
  { '1899-4': { title: 'Alakanuk' } },
  { '1900-0': { title: 'Aleknagik' } },
  { '1901-8': { title: 'Andreafsky' } },
  { '1902-6': { title: 'Aniak' } },
  { '1903-4': { title: 'Atmautluak' } },
  { '1904-2': { title: 'Bethel' } },
  { '1905-9': { title: "Bill Moore's Slough" } },
  { '1906-7': { title: 'Bristol Bay Yupik' } },
  { '1907-5': { title: 'Calista Yupik' } },
  { '1908-3': { title: 'Chefornak' } },
  { '1909-1': { title: 'Chevak' } },
  { '1910-9': { title: 'Chuathbaluk' } },
  { '1911-7': { title: "Clark's Point" } },
  { '1912-5': { title: 'Crooked Creek' } },
  { '1913-3': { title: 'Dillingham' } },
  { '1914-1': { title: 'Eek' } },
  { '1915-8': { title: 'Ekuk' } },
  { '1916-6': { title: 'Ekwok' } },
  { '1917-4': { title: 'Emmonak' } },
  { '1918-2': { title: 'Goodnews Bay' } },
  { '1919-0': { title: 'Hooper Bay' } },
  { '1920-8': { title: 'Iqurmuit (Russian Mission)' } },
  { '1921-6': { title: 'Kalskag' } },
  { '1922-4': { title: 'Kasigluk' } },
  { '1923-2': { title: 'Kipnuk' } },
  { '1924-0': { title: 'Koliganek' } },
  { '1925-7': { title: 'Kongiganak' } },
  { '1926-5': { title: 'Kotlik' } },
  { '1927-3': { title: 'Kwethluk' } },
  { '1928-1': { title: 'Kwigillingok' } },
  { '1929-9': { title: 'Levelock' } },
  { '1930-7': { title: 'Lower Kalskag' } },
  { '1931-5': { title: 'Manokotak' } },
  { '1932-3': { title: 'Marshall' } },
  { '1933-1': { title: 'Mekoryuk' } },
  { '1934-9': { title: 'Mountain Village' } },
  { '1935-6': { title: 'Naknek' } },
  { '1936-4': { title: 'Napaumute' } },
  { '1937-2': { title: 'Napakiak' } },
  { '1938-0': { title: 'Napaskiak' } },
  { '1939-8': { title: 'Newhalen' } },
  { '1940-6': { title: 'New Stuyahok' } },
  { '1941-4': { title: 'Newtok' } },
  { '1942-2': { title: 'Nightmute' } },
  { '1943-0': { title: 'Nunapitchukv' } },
  { '1944-8': { title: 'Oscarville' } },
  { '1945-5': { title: 'Pilot Station' } },
  { '1946-3': { title: 'Pitkas Point' } },
  { '1947-1': { title: 'Platinum' } },
  { '1948-9': { title: 'Portage Creek' } },
  { '1949-7': { title: 'Quinhagak' } },
  { '1950-5': { title: 'Red Devil' } },
  { '1951-3': { title: 'St. Michael' } },
  { '1952-1': { title: 'Scammon Bay' } },
  { '1953-9': { title: "Sheldon's Point" } },
  { '1954-7': { title: 'Sleetmute' } },
  { '1955-4': { title: 'Stebbins' } },
  { '1956-2': { title: 'Togiak' } },
  { '1957-0': { title: 'Toksook' } },
  { '1958-8': { title: 'Tulukskak' } },
  { '1959-6': { title: 'Tuntutuliak' } },
  { '1960-4': { title: 'Tununak' } },
  { '1961-2': { title: 'Twin Hills' } },
  { '1962-0': { title: 'Georgetown (Yupik-Eskimo)' } },
  { '1963-8': { title: "St. Mary's" } },
  { '1964-6': { title: 'Umkumiate' } },
  { '1968-7': { title: 'Alutiiq Aleut' } },
  { '1972-9': { title: 'Bristol Bay Aleut' } },
  { '1984-4': { title: 'Chugach Aleut' } },
  { '1990-1': { title: 'Eyak' } },
  { '1992-7': { title: 'Koniag Aleut' } },
  { '2002-4': { title: 'Sugpiaq' } },
  { '2004-0': { title: 'Suqpigaq' } },
  { '2006-5': { title: 'Unangan Aleut' } },
  { '1969-5': { title: 'Tatitlek' } },
  { '1970-3': { title: 'Ugashik' } },
  { '1973-7': { title: 'Chignik' } },
  { '1974-5': { title: 'Chignik Lake' } },
  { '1975-2': { title: 'Egegik' } },
  { '1976-0': { title: 'Igiugig' } },
  { '1977-8': { title: 'Ivanof Bay' } },
  { '1978-6': { title: 'King Salmon' } },
  { '1979-4': { title: 'Kokhanok' } },
  { '1980-2': { title: 'Perryville' } },
  { '1981-0': { title: 'Pilot Point' } },
  { '1982-8': { title: 'Port Heiden' } },
  { '1985-1': { title: 'Chenega' } },
  { '1986-9': { title: 'Chugach Corporation' } },
  { '1987-7': { title: 'English Bay' } },
  { '1988-5': { title: 'Port Graham' } },
  { '1993-5': { title: 'Akhiok' } },
  { '1994-3': { title: 'Agdaagux' } },
  { '1995-0': { title: 'Karluk' } },
  { '1996-8': { title: 'Kodiak' } },
  { '1997-6': { title: 'Larsen Bay' } },
  { '1998-4': { title: 'Old Harbor' } },
  { '1999-2': { title: 'Ouzinkie' } },
  { '2000-8': { title: 'Port Lions' } },
  { '2007-3': { title: 'Akutan' } },
  { '2008-1': { title: 'Aleut Corporation' } },
  { '2009-9': { title: 'Aleutian' } },
  { '2010-7': { title: 'Aleutian Islander' } },
  { '2011-5': { title: 'Atka' } },
  { '2012-3': { title: 'Belkofski' } },
  { '2013-1': { title: 'Chignik Lagoon' } },
  { '2014-9': { title: 'King Cove' } },
  { '2015-6': { title: 'False Pass' } },
  { '2016-4': { title: 'Nelson Lagoon' } },
  { '2017-2': { title: 'Nikolski' } },
  { '2018-0': { title: 'Pauloff Harbor' } },
  { '2019-8': { title: 'Qagan Toyagungin' } },
  { '2020-6': { title: 'Qawalangin' } },
  { '2021-4': { title: 'St. George' } },
  { '2022-2': { title: 'St. Paul' } },
  { '2023-0': { title: 'Sand Point' } },
  { '2024-8': { title: 'South Naknek' } },
  { '2025-5': { title: 'Unalaska' } },
  { '2026-3': { title: 'Unga' } },
]

export const americanIndianSubRaceDropdownValues =
  constructPatientDemographicObject(
    americanIndianSubRaceCDC,
    CodeSystemOIDs.PHVSRaceCategoryCDCRef,
    Categories.AMERICAN_INDIAN_SUB_RACE
  )
