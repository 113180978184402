import { UseQueryResult, useQuery } from '@tanstack/react-query'

import {
  getCalendarTimezone,
  getIntegratedCalendarsForCurrentProvider,
} from '../../api/api-lib'
import { getIntegratedCalendarsForSecondaryProviders } from '../../api/api-lib-typed'
import { UserCalendarAccount } from '../../stories/Scheduling/types'

export enum CalendarQueries {
  SECONDARY_PROVIDERS = 'SECONDARY_PROVIDERS',
  CURRENT_PROVIDER = 'CURRENT_PROVIDER',
  TIMEZONE = 'TIMEZONE',
}

export const useUserCalendars = (): UseQueryResult<UserCalendarAccount[]> => {
  return useQuery<UserCalendarAccount[]>([CalendarQueries.CURRENT_PROVIDER], {
    queryFn: () => getIntegratedCalendarsForCurrentProvider(),
    retry: false,
  })
}

// TODO: Delete this hook once filter work is completed
export const useTeammateCalendars = () => {
  return useQuery([CalendarQueries.SECONDARY_PROVIDERS], {
    queryFn: () => getIntegratedCalendarsForSecondaryProviders(),
    retry: false,
  })
}

export const useCalendarTimezone = (): UseQueryResult<string> => {
  return useQuery<string>([CalendarQueries.TIMEZONE], {
    queryFn: () => getCalendarTimezone(),
    retry: false,
  })
}
