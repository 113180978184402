import React from 'react'

import { Col, Form } from 'react-bootstrap'

export default function ObsessiveCompulsive(props) {
  if (props.patientData.NoObsessiveCompulsive) {
    return (
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Obsessive-compulsive:
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="NoObsessiveCompulsive"
            label="No relevant history in this section"
            checked={JSON.parse(props.patientData.NoObsessiveCompulsive)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NoObsessiveCompulsive: !props.patientData.NoObsessiveCompulsive,
              })
            }}
          />
        </Form.Group>
      </Form.Row>
    )
  }

  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Obsessive-compulsive:
          </Form.Label>
        </Form.Group>
        {props.patientData.OCD.state === false &&
        props.patientData.OtherObsessiveCompulsive.state === false &&
        props.patientData.UnwantedThoughts.state === false &&
        props.patientData.ObsessiveCompulsiveAdditionalNotes === '' ? (
          <Form.Group as={Col} sm={12} md={12} lg={12}>
            <Form.Check
              type="checkbox"
              id="NoObsessiveCompulsive"
              label="No relevant history in this section"
              checked={JSON.parse(props.patientData.NoObsessiveCompulsive)}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoObsessiveCompulsive:
                    !props.patientData.NoObsessiveCompulsive,
                })
              }}
            />
          </Form.Group>
        ) : (
          ''
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Diagnoses
          </Form.Label>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.OCD.state ? 2 : 12}
          lg={props.patientData.OCD.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="OCD"
            label="OCD"
            disabled={props.patientData.NoObsessiveCompulsive}
            checked={JSON.parse(props.patientData.OCD.state)}
            onChange={() => {
              props.setPatientData({
                ...props.patientData,
                OCD: {
                  ...props.patientData.OCD,
                  state: !props.patientData.OCD.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OCD.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    OCD: { ...props.patientData.OCD, Notes: e.target.value },
                  })
                }}
                value={props.patientData.OCD.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.OtherObsessiveCompulsive.state ? 2 : 12}
          lg={props.patientData.OtherObsessiveCompulsive.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="OtherObsessiveCompulsive"
            label="Other"
            disabled={props.patientData.NoObsessiveCompulsive}
            checked={JSON.parse(
              props.patientData.OtherObsessiveCompulsive.state
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                OtherObsessiveCompulsive: {
                  ...props.patientData.OtherObsessiveCompulsive,
                  state: !props.patientData.OtherObsessiveCompulsive.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OtherObsessiveCompulsive.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    OtherObsessiveCompulsive: {
                      ...props.patientData.OtherObsessiveCompulsive,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.OtherObsessiveCompulsive.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Symptoms
          </Form.Label>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.UnwantedThoughts.state ? 2 : 12}
          lg={props.patientData.UnwantedThoughts.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="UnwantedThoughts"
            label="Unwanted thoughts or behaviors"
            disabled={props.patientData.NoObsessiveCompulsive}
            checked={JSON.parse(props.patientData.UnwantedThoughts.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                UnwantedThoughts: {
                  ...props.patientData.UnwantedThoughts,
                  state: !props.patientData.UnwantedThoughts.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.UnwantedThoughts.state && (
          <>
            <Form.Group
              as={Col}
              style={{ marginTop: '20px' }}
              sm={12}
              md={10}
              lg={10}
            >
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    UnwantedThoughts: {
                      ...props.patientData.UnwantedThoughts,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.UnwantedThoughts.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional notes
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            bsPrefix="form-input"
            disabled={props.patientData.NoObsessiveCompulsive}
            onChange={(e) => {
              props.setPatientData({
                ...props.patientData,
                ObsessiveCompulsiveAdditionalNotes: e.target.value,
              })
            }}
            value={props.patientData.ObsessiveCompulsiveAdditionalNotes}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
