import { formatDate } from '../../../../libs/utils'
import { isDateNonStandard } from '../../utils'

import styles from './styles.module.scss'

export const EmptyColumn = () => {
  return <span className={styles['partial-value']}>--</span>
}

export const renderDate = (date?: string | Date | number | null) => {
  if (!date) {
    return <EmptyColumn />
  }

  if (isDateNonStandard(date)) {
    return <span>{String(date)}</span>
  }

  return <span>{formatDate({ value: new Date(date) })}</span>
}

export const renderValue = (value?: string | number | null) =>
  value ? <span>{value}</span> : <EmptyColumn />
