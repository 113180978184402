import React from 'react'

import _ from 'lodash'
import { Row } from 'react-bootstrap'

import {
  generateCheckField,
  generateCustomField,
  generateDatePickerField,
  generateDefaultField,
  generateDropdownField,
  generateLabelField,
  generateMultiple,
  generatePhoneField,
  generateUnitField,
} from './field-generator-lib'
import {
  CustomStyling,
  FormMachineProps,
  GenerateFieldProps,
  TemplateField,
} from './types'

import './styles.scss'

export function generateComponent(
  attributes: GenerateFieldProps,
  templateField: TemplateField
) {
  const {
    storedFields,
    handleFieldChange = _.noop,
    handleCheckChange = _.noop,
    handleMultipleSubmit = _.noop,
    isEditing,
    styling,
  } = attributes
  const fieldType = templateField.fieldType
  const id = templateField.id
  const types = [
    'custom',
    'checkbox',
    'datepicker',
    'dropdown',
    'label',
    'multiple',
    'number',
    'radio',
    'text',
    'textarea',
    'unit',
    'yesNo',
    'tel',
  ]
  const isValidType = types.find((standardType) => standardType === fieldType)
  if (!isValidType) {
    console.error('FormMachine: Not a valid type given for fieldType')
    throw new Error('Not a valid type given for fieldType')
  }

  const isThereUniqueStyling =
    typeof templateField.customStyling !== 'undefined'
  const stylingProps = isThereUniqueStyling
    ? (templateField.customStyling as CustomStyling)
    : styling

  const props = {
    storedFields,
    templateField,
    handleFieldChange,
    handleCheckChange,
    handleMultipleSubmit,
    stylingProps,
  }

  let value = storedFields[id] || ''
  let fieldComponent
  switch (fieldType) {
    case 'multiple':
      fieldComponent = generateMultiple(props, value, isEditing)
      break
    case 'unit':
      value = (value as number) || ''
      fieldComponent = generateUnitField(props, value, isEditing)
      break
    case 'custom':
      fieldComponent = generateCustomField(props, value, isEditing)
      break
    case 'number':
    case 'text':
    case 'textarea':
      fieldComponent = generateDefaultField(props, value, isEditing)
      break
    case 'tel':
      fieldComponent = generatePhoneField(props, value, isEditing)
      break
    case 'dropdown':
      fieldComponent = generateDropdownField(props, value, isEditing)
      break
    case 'checkbox':
    case 'radio':
    case 'yesNo':
      fieldComponent = generateCheckField(props, value, isEditing)
      break
    case 'datepicker':
      fieldComponent = generateDatePickerField(props, value, isEditing)
      break
    case 'label':
      fieldComponent = generateLabelField(props, value, isEditing)
      break
    default:
      fieldComponent = <i key={`no-type-${id}`} />
  }
  return (
    <React.Fragment key={`${fieldType}-${id}`}>{fieldComponent}</React.Fragment>
  )
}

export default function FormMachine(props: FormMachineProps) {
  const {
    formRowBsPrefix,
    formRowClassName,
    formRowStyle = { margin: '28px' },
  } = props.styling

  return (
    <Row
      bsPrefix={formRowBsPrefix}
      className={formRowClassName}
      style={formRowStyle}
    >
      {props.template.map((templateField) =>
        generateComponent(props, templateField)
      )}
    </Row>
  )
}
