import * as React from 'react'

const ClipboardOutlined = (props) => {
  const { fill = 'white' } = props
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.66675 12V2.66667H5.00008V1.66667H1.33341C0.965225 1.66667 0.666748 1.96514 0.666748 2.33333V12.3333C0.666748 12.7015 0.965225 13 1.33341 13H10.6667C11.0349 13 11.3334 12.7015 11.3334 12.3333V2.33333C11.3334 1.96514 11.0349 1.66667 10.6667 1.66667H7.00008V2.66667H10.3334V12H1.66675Z"
        fill={fill}
      />
      <path
        d="M5.00008 1.66667V1.33333H7.00008V1.66667H8.00008V1C8.00008 0.63181 7.7016 0.333333 7.33341 0.333333H4.66675C4.29856 0.333333 4.00008 0.63181 4.00008 1V1.66667H5.00008Z"
        fill={fill}
      />
      <path
        d="M2.66675 2.33333V4.33333H9.33341V2.33333H8.33341V3.33333H3.66675V2.33333H2.66675Z"
        fill={fill}
      />
      <path d="M3.33341 5.66667H8.66675V6.33333H3.33341V5.66667Z" fill={fill} />
      <path d="M3.33341 7.66667H8.66675V8.33333H3.33341V7.66667Z" fill={fill} />
      <path d="M3.33341 9.66667H8.66675V10.3333H3.33341V9.66667Z" fill={fill} />
    </svg>
  )
}

export default ClipboardOutlined
