export const downloadPDFToBrowser = (
  data: Blob | Uint8Array,
  title: string
) => {
  const fileData = new File([data], '', { type: 'application/pdf' })
  const fileURL = URL.createObjectURL(fileData)
  const link = document.createElement('a')
  link.href = fileURL
  link.download = title
  link.click()
}
