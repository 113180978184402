import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Form, Modal } from 'antd'
import _ from 'lodash'

import { createLocation, updateLocation } from '../../api/api-lib'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import { npiRegex } from '../../libs/regex'
import { TypeLocation } from '../../shared-types'
import {
  Button,
  Col,
  Input,
  Radio,
  Row,
  Space,
} from '../../stories/BaseComponents'
import AddressAutocomplete, {
  Address,
} from '../../stories/BaseComponents/AddressAutocomplete'
import EINInput from '../../stories/BaseComponents/SpecialInputFields/EINInput'
import PhoneNumberInput from '../../stories/BaseComponents/SpecialInputFields/PhoneNumberInput'
import PlaceOfServiceInput from '../../stories/BaseComponents/SpecialInputFields/PlaceOfServiceInput'
import {
  LocationItem,
  LocationTableItem,
  PlaceOfServiceCode,
} from './Locations'

import './Locations.scss'

interface Props {
  show: boolean
  getLocations: () => void
  onHide: () => void
  locationItem: LocationTableItem | undefined
}

interface FormValues {
  locationType: string
  locationName: string
  Address1: string
  Address2: string
  City: string
  State: string
  Zipcode: string
  locationOtherDescription: string
  locationBillingNPI?: string
  locationEIN?: string
  locationPhoneNumber?: string
  locationPOS?: PlaceOfServiceCode
  AutocompleteAddress?: Address
}

interface SubmitValues {
  type?: string
  locationName?: string
  addressLine1?: string
  addressLine2?: string
  description?: string
  city?: string
  state?: string
  zipcode?: string
  locationId?: string
  billingNPI?: string
  ein?: string
  phoneNumber?: string
  pos?: PlaceOfServiceCode
  AutocompleteAddress?: Address
}

enum Mode {
  EDIT = 'isEdit',
  ADD = 'isAdd',
}

export const getAddress = (locationItem: LocationItem) => {
  let fullAddress = ''

  if (locationItem.AddressLine1) fullAddress += `${locationItem.AddressLine1}`
  if (locationItem.AddressLine2) fullAddress += `, ${locationItem.AddressLine2}`
  if (locationItem.City) fullAddress += `, ${locationItem.City}`
  if (locationItem.LocationState)
    fullAddress += `, ${locationItem.LocationState}`
  if (locationItem.Zipcode) fullAddress += `, ${locationItem.Zipcode}`

  return fullAddress
}

const LocationModal: React.FC<Props> = ({
  show,
  onHide,
  locationItem,
  getLocations,
}) => {
  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState<boolean>(false)
  const mode = locationItem ? Mode.EDIT : Mode.ADD
  const [type, setType] = useState<string>(
    locationItem?.LocationType ?? TypeLocation.PHYSICAL
  )
  const initialValues: FormValues = {
    locationType: locationItem?.LocationType ?? TypeLocation.PHYSICAL,
    locationName: locationItem?.LocationName ?? '',
    Address1: locationItem?.AddressLine1 ?? '',
    Address2: locationItem?.AddressLine2 ?? '',
    City: locationItem?.City ?? '',
    State: locationItem?.LocationState ?? '',
    Zipcode: locationItem?.Zipcode ?? '',
    locationOtherDescription: locationItem?.Description ?? '',
    locationBillingNPI: locationItem?.LocationBillingNPI ?? '',
    locationEIN: locationItem?.LocationEIN ?? '',
    locationPhoneNumber: locationItem?.LocationPhoneNumber ?? '',
    locationPOS: locationItem?.LocationPOS ?? '',
    AutocompleteAddress: {
      Address1: locationItem?.AddressLine1 ?? '',
      Address2: locationItem?.AddressLine2 ?? '',
      City: locationItem?.City ?? '',
      State: locationItem?.LocationState ?? '',
      Zipcode: locationItem?.Zipcode ?? '',
    },
  }

  useEffect(() => {
    setType(initialValues.locationType)
    form.setFieldsValue(initialValues)
  }, [show])

  const checkFormChanged = () => {
    const values = {
      ...initialValues,
      ...form.getFieldsValue(),
    }
    if (mode === Mode.ADD) {
      const tmpInitialValues = _.omit(initialValues, 'locationType')
      const tmpFormValues = _.omit(values, 'locationType')
      if (_.isEqual(tmpInitialValues, tmpFormValues)) return false
      return true
    }

    if (_.isEqual(values, initialValues)) return false
    return true
  }

  const handleAddressChange = useCallback(async (values: Address) => {
    const updateItem = form.getFieldsValue()
    if (updateItem.AutocompleteAddress) {
      updateItem.AutocompleteAddress.Address1 = values?.Address1 ?? ''
      updateItem.AutocompleteAddress.Address2 = values?.Address2 ?? ''
      updateItem.AutocompleteAddress.City = values?.City ?? ''
      updateItem.AutocompleteAddress.State = values?.State ?? ''
      updateItem.AutocompleteAddress.Zipcode = values?.Zipcode ?? ''
      form.setFieldsValue(updateItem)
    }
  }, [])

  const getAllItems = (
    items: SubmitValues,
    values: FormValues
  ): SubmitValues => {
    let phoneNumber = ''
    let ein = ''
    if (values.locationPhoneNumber) {
      phoneNumber = values.locationPhoneNumber.replace(/\D/g, '')
    }
    if (values.locationEIN) {
      ein = values.locationEIN.replace(/\D/g, '')
    }

    return {
      addressLine1: items.AutocompleteAddress?.Address1 ?? '',
      addressLine2: items.AutocompleteAddress?.Address2 ?? '',
      city: items.AutocompleteAddress?.City ?? '',
      state: items.AutocompleteAddress?.State ?? '',
      zipcode: items.AutocompleteAddress?.Zipcode ?? '',
      billingNPI: values.locationBillingNPI,
      locationId: items.locationId,
      description: items.description,
      locationName: items.locationName,
      type: items.type,
      ein, // saved without any special characters (ie. '-')
      phoneNumber, // saved without any special characters (ie. ')', '(', '-')
      pos: values.locationPOS ?? '',
    }
  }

  const createUserLocation = async (values: FormValues): Promise<void> => {
    const item =
      values.locationType === TypeLocation.PHYSICAL
        ? {
            type: values.locationType,
            locationName: values.locationName,
            AutocompleteAddress: {
              Address1: values.AutocompleteAddress?.Address1 ?? '',
              Address2: values.AutocompleteAddress?.Address2 ?? '',
              City: values.AutocompleteAddress?.City ?? '',
              State: values.AutocompleteAddress?.State ?? '',
              Zipcode: values.AutocompleteAddress?.Zipcode ?? '',
            },
          }
        : {
            type: values.locationType,
            locationName: values.locationName,
            description: values.locationOtherDescription,
          }

    const items = getAllItems(item, values)

    await createLocation(items)
  }

  const editUserLocation = async (values: FormValues): Promise<void> => {
    let item

    if (values.locationType === TypeLocation.PHYSICAL) {
      item = {
        locationId: locationItem?.LocationId,
        type: values.locationType,
        locationName: values.locationName,
        AutocompleteAddress: {
          Address1: values.AutocompleteAddress?.Address1 ?? '',
          Address2: values.AutocompleteAddress?.Address2 ?? '',
          City: values.AutocompleteAddress?.City ?? '',
          State: values.AutocompleteAddress?.State ?? '',
          Zipcode: values.AutocompleteAddress?.Zipcode ?? '',
        },
      }
    } else {
      item = {
        locationId: locationItem?.LocationId,
        type: values.locationType,
        locationName: values.locationName,
        description: values.locationOtherDescription,
        AutocompleteAddress: {
          Address1: '',
          Address2: '',
          City: '',
          State: '',
          Zipcode: '',
        },
      }
    }

    item = getAllItems(item, values)
    await updateLocation(item)
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      setLoading(true)
      if (mode === Mode.ADD) {
        await createUserLocation(values)
      } else {
        await editUserLocation(values)
      }
      notification('Successfully added a location.', 'success')
      getLocations()
    } catch (e) {
      console.error(e)
      onError(new Error('Unable to add a location'))
    } finally {
      setLoading(false)
      onHide()
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values)
      })
      .catch((info) => {
        console.log('Validate Failed: ', info)
      })
  }

  const handleClose = () => {
    if (!checkFormChanged()) {
      setLoading(false)
      onHide()
    } else {
      Modal.confirm({
        title: 'Unsaved changes',
        content:
          'Are you sure you want to close this window? Any unsaved changes will be lost.',
        okText: 'Ok',
        cancelText: 'Cancel',
        cancelButtonProps: {
          type: 'default',
        },
        okButtonProps: {
          type: 'primary',
          loading: isLoading,
        },
        onOk: () => {
          setLoading(false)
          onHide()
        },
      })
    }
  }

  const locationTypeAndNameField = (
    <>
      <Form.Item
        className="top-ant-form-item"
        name="locationType"
        rules={[
          {
            required: true,
            message: 'Please select the location type!',
            validateTrigger: 'onBlur',
          },
        ]}
      >
        <Space direction="horizontal">
          <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
            <Radio value={TypeLocation.PHYSICAL}>Physical</Radio>
            <Radio value={TypeLocation.OTHER}>Virtual</Radio>
          </Radio.Group>
        </Space>
      </Form.Item>
      <Form.Item
        name="locationName"
        label="Location Name"
        rules={[
          {
            required: true,
            message: 'Please input location name!',
            type: 'string',
            validateTrigger: 'onBlur',
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  )
  const formValues = useMemo(() => form.getFieldsValue(), [])
  const physicalLocationForm = (
    <>
      <Form.Item label={''} name="AutocompleteAddress">
        <AddressAutocomplete
          value={formValues.AutocompleteAddress}
          onChange={handleAddressChange}
        />
      </Form.Item>
    </>
  )

  const locationAdditionalFields = (
    <Row className="location-row-container">
      <Col md={11}>
        <PhoneNumberInput form={form} name="locationPhoneNumber" />
      </Col>
      <Col md={{ span: 11, offset: 2 }}>
        <Form.Item
          name="locationBillingNPI"
          label="Billing NPI"
          rules={[
            {
              message: 'Enter a 10-digit code',
              pattern: npiRegex,
              type: 'string',
              validateTrigger: 'onBlur',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col md={11}>
        <EINInput form={form} name="locationEIN" />
      </Col>

      <Col md={{ span: 11, offset: 2 }}>
        <PlaceOfServiceInput name="locationPOS" />
      </Col>
    </Row>
  )

  const otherLocationForm = (
    <Form.Item
      name="locationOtherDescription"
      label="Description"
      rules={[
        {
          required: true,
          message: 'Please input description',
          type: 'string',
          validateTrigger: 'onBlur',
          whitespace: true,
        },
      ]}
    >
      <Input placeholder="e.g. Zoom link" />
    </Form.Item>
  )

  const footer = [
    <div className="antd-modal-footer">
      <Button type="default" onClick={handleClose}>
        Cancel
      </Button>
      <Button type="primary" onClick={handleOk} loading={isLoading}>
        Save
      </Button>
    </div>,
  ]

  return (
    <Modal
      title={!locationItem ? 'Add location' : 'Edit location'}
      visible={show}
      onOk={handleOk}
      onCancel={handleClose}
      footer={footer}
    >
      <Form
        form={form}
        onFinish={handleOk}
        scrollToFirstError
        layout="vertical"
        validateTrigger="onBlur"
      >
        {locationTypeAndNameField}
        {type === TypeLocation.PHYSICAL
          ? physicalLocationForm
          : otherLocationForm}
        {locationAdditionalFields}
      </Form>
    </Modal>
  )
}

export default LocationModal
