import React from 'react'

import cx from 'classnames'

import { TestId } from '../../shared-types'

import styles from './_shared.module.scss'

type HoverIconProps = {
  icon: React.ReactNode
  onClick?: () => void
  style?: React.CSSProperties
  className?: string
} & TestId

export const HoverIcon = ({
  style = {},
  className = '',
  testId,
  icon,
  onClick,
}: HoverIconProps) => {
  return (
    <div
      data-testid={testId}
      style={style}
      className={cx([styles.hoverIcon, className])}
      onClick={() => onClick?.()}
    >
      {icon}
    </div>
  )
}
