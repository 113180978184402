import React from 'react'

import SkeletonLoadingTransition from '../../components/Animation/SkeletonLoadingTransition'
import { Skeleton, Typography } from '../BaseComponents'

import styles from './IdentifyingText.module.scss'

export interface IdentifyingTextProps {
  loading?: boolean
  displayName?: string
  birthOfDate?: string
  age?: string
  pronouns?: string[]
  genderIdentity?: string
  onClickBirthDate?: () => void
  onClickPronouns?: () => void
  onClickGenderIdentity?: () => void
  suicidalIntention?: boolean
}

const { Text, Link } = Typography

const IdentifyingText: React.FC<IdentifyingTextProps> = ({
  loading = true,
  displayName,
  birthOfDate,
  age,
  pronouns,
  genderIdentity,
  onClickBirthDate,
  onClickPronouns,
  onClickGenderIdentity,
}) => {
  const handleOnKeyDown = (
    event: React.KeyboardEvent<HTMLAnchorElement>,
    field: string
  ) => {
    if (event.key === 'Enter') {
      switch (field) {
        case 'dateOfBirth':
          onClickBirthDate?.()
          break
        case 'pronouns':
          onClickPronouns?.()
          break
        case 'genderIdentity':
          onClickGenderIdentity?.()
          break
        default:
          break
      }
    }
  }

  const dot = <span style={{ color: '#BFBFBF' }}>•</span>
  const skeleton = (
    <div style={{ width: '100%' }}>
      <Skeleton
        paragraph={{
          rows: 2,
          width: '100%',
          className: styles.skeletonParagraph,
        }}
        title={{
          width: '90%',
          className: styles.skeletonTitle,
        }}
        active
      />
    </div>
  )

  const loaded = (
    <div className={styles.container}>
      <Text
        data-testid={'identifyingText-displayName-content'}
        className={styles.title}
      >
        {displayName}{' '}
      </Text>
      <br />
      {birthOfDate ? (
        <Text
          data-testid={'identifyingText-dob-content'}
          className={styles.text}
        >
          {`${age} `}
          {dot} {` DOB ${birthOfDate}`}
        </Text>
      ) : (
        <Text
          data-testid={'identifyingText-dob-content'}
          className={styles.text}
        >
          <Link
            tabIndex={0}
            onClick={onClickBirthDate}
            onKeyDown={(e) => handleOnKeyDown(e, 'dateOfBirth')}
            className={`${styles.text} ${styles.link}`}
          >
            Add date of birth
          </Link>
        </Text>
      )}
      <br />
      <Text
        data-testid={'identifyingText-pronounsGender-content'}
        className={styles.text}
      >
        {pronouns?.join(', ') || (
          <Link
            tabIndex={0}
            onKeyDown={(e) => handleOnKeyDown(e, 'pronouns')}
            onClick={onClickPronouns}
            className={`${styles.text} ${styles.link}`}
          >
            Add pronouns
          </Link>
        )}{' '}
        {pronouns?.length && pronouns?.length > 2 ? null : dot}{' '}
        {genderIdentity || (
          <Link
            tabIndex={0}
            onClick={onClickGenderIdentity}
            onKeyDown={(e) => handleOnKeyDown(e, 'genderIdentity')}
            className={`${styles.text} ${styles.link}`}
          >
            Add gender identity
          </Link>
        )}
      </Text>
    </div>
  )

  return (
    <div style={{ position: 'relative' }}>
      <SkeletonLoadingTransition
        isLoading={loading}
        skeletonComponent={skeleton}
        loadedComponent={loaded}
      />
    </div>
  )
}

export default IdentifyingText
