import { useQuery, useQueryClient } from '@tanstack/react-query'

const FIVE_MINUTES = 1000 * 60 * 5
export const useQueryStorage = <T>(
  queryKey: string[],
  cacheTime = FIVE_MINUTES
): {
  cacheData: T | null
  upsertCache: (newData: T) => T | undefined
  deleteCache: () => void
} => {
  const queryClient = useQueryClient()

  const { data: cacheData } = useQuery<T | null>(queryKey, {
    initialData: () => queryClient.getQueryData<T>(queryKey) ?? null,
    queryFn: () => queryClient.getQueryData<T>(queryKey) ?? null,
    cacheTime: !queryKey.length ? 0 : cacheTime,
  })

  const upsertCache = (newData: T) =>
    queryClient.setQueryData<T>(queryKey, newData)
  const deleteCache = () => {
    queryClient.removeQueries(queryKey)
  }

  return {
    cacheData,
    upsertCache,
    deleteCache,
  }
}
