import React from 'react'

import { useQuery } from '@tanstack/react-query'

import { searchBillingCodes } from '../../api/api-lib'
import { BillingCodesTypes } from '../../shared-types'

export type BillingCode = {
  id: number
  code: string
  version: BillingCodesTypes
  ownerId: string
  description: string
  shortDescription?: string
}

export type BareBillingCode = {
  code: string
  version: BillingCodesTypes
  ownerId: string
  description?: string
  shortDescription?: string
  id: number
}

export type BillingCodesByType = {
  [key in BillingCodesTypes]?: BillingCode[]
}

const defaultByType: BillingCodesByType = (
  Object.keys(BillingCodesTypes) as BillingCodesTypes[]
).reduce<BillingCodesByType>((acc, key) => {
  acc[key] = []
  return acc
}, {})

// Returns billing codes organized by their type, eg:
// {
//    CPT_V2020: [{ code: ..., version: ..., description: ..., }, ],
//    CUSTOM: [...],
//    HCPCS_V2020: [],
// }
export const useBillingCodes = (
  input?: string
): {
  codes: BillingCodesByType
  codesById: Record<number, BillingCode>
  count: number
  isLoading: boolean
} => {
  const { data: codes, isLoading } = useQuery<BillingCode[]>(
    ['billingCodes', input],
    async ({ queryKey }) => await searchBillingCodes(queryKey[1] as string)
  )

  const [codesByType, setCodesByType] =
    React.useState<BillingCodesByType>(defaultByType)
  const [codesById, setCodesById] = React.useState<Record<number, BillingCode>>(
    {}
  )

  React.useEffect(() => {
    if (!codes) return

    const newCodesByType: BillingCodesByType = { ...codesByType }
    const newCodesById: Record<number, BillingCode> = { ...codesById }
    codes.forEach((code) => {
      if (
        !newCodesByType[code.version] ||
        newCodesByType[code.version]?.length === 0
      ) {
        newCodesByType[code.version] = [code]
      } else if (
        !newCodesByType?.[code.version]?.find((c) => c.id === code.id)
      ) {
        newCodesByType?.[code.version]?.push(code)
      }

      newCodesById[code.id] = code
    })

    setCodesByType(newCodesByType)
    setCodesById(newCodesById)
  }, [codes])

  return {
    codes: codesByType,
    codesById,
    count: Object.values(codesByType).flat().length,
    isLoading,
  }
}
