import { API } from 'aws-amplify'

export const fetchBillingCodeDxMapping = async (patientId: string) => {
  return API.get(
    'billing',
    `/billing/billing-code-to-dx-mapping?patientId=${patientId}`,
    {}
  )
}

export async function shareSuperbill({
  invoiceId,
  superbillId,
}: {
  invoiceId: string
  superbillId: number
}) {
  return API.put(
    'billing',
    `/billing/v2/invoices/${invoiceId}/superbills/${superbillId}/shared`,
    {}
  )
}
