import ConfirmationModal from '../../components/Other/ConfirmationModal'

export const CONFIRM_LOGOUT_TEXT = 'Log out'

export const LogoutAcrossTabsConfirmationModal = ({
  performLogout,
  isShownLogoutConfirmationModal,
  dismissLogoutConfirmationModal,
}: {
  performLogout: () => Promise<void>
  isShownLogoutConfirmationModal: boolean
  dismissLogoutConfirmationModal: () => void
}) => {
  const onConfirmLogout = async () => {
    await performLogout()
    dismissLogoutConfirmationModal()
  }

  return (
    <ConfirmationModal
      id="logoutConfirmationModal"
      title="Are you sure you want to log out?"
      okText={CONFIRM_LOGOUT_TEXT}
      body="Any unsaved changes on your open tabs will be lost."
      size="lg"
      show={isShownLogoutConfirmationModal}
      onCancel={dismissLogoutConfirmationModal}
      onConfirm={onConfirmLogout}
    />
  )
}
