import React, { useState } from 'react'

import { CheckOutlined, SendOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { Auth } from 'aws-amplify'
import { Link, useLocation } from 'react-router-dom'

import { onError } from '../../libs/errorLib'
import LogoCard from '../../stories/Authentication/LogoCard'
import { Button, Input, Password } from '../../stories/BaseComponents'

import './ResetPassword.scss'

/**
 * Handles forgot password and password reset for logged out users
 */
export default function ResetPassword() {
  const [form] = Form.useForm()
  const location = useLocation()
  // todo: add better state management for this email bit
  const [emailPassed, setEmailPassed] = useState(location?.state?.email ?? '')
  const [codeSent, setCodeSent] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isSendingCode, setIsSendingCode] = useState(false)
  /**
   * Checks that code and new password length > 0 and that passwords match
   */
  const validatePassword = ({ getFieldValue }) => ({
    validator(_, confirmPassword = '') {
      const password = getFieldValue('password') ?? ''
      if (!password.length && !confirmPassword.length) {
        return Promise.reject(new Error(''))
      }

      if (password.length && password === confirmPassword) {
        return Promise.resolve()
      }
      return Promise.reject(
        new Error('The two passwords entered do not match.')
      )
    },
  })

  /**
   * Handles submission of renderRequestCodeForm
   * Sends verification code to specified email address
   * Success: Display renderConfirmationForm
   * Failure: Error
   * @param event - form event
   */
  async function handleSendCodeClick() {
    setIsSendingCode(true)
    const email = form.getFieldValue('email')

    try {
      await Auth.forgotPassword(email)
      setEmailPassed(email)
      setCodeSent(true)
    } catch (error) {
      onError(error)
      setIsSendingCode(false)
    }
  }

  /**
   * Handles submission of renderConfirmationForm
   * Sets new password
   * Success: Display renderSuccessMessage
   * Failure: Error
   * @params event
   */
  async function handleConfirmClick() {
    setIsConfirming(true)
    const { code, password } = form.getFieldsValue()

    try {
      await Auth.forgotPasswordSubmit(emailPassed, code, password)
      setConfirmed(true)
    } catch (error) {
      onError(error)
      setIsConfirming(false)
    }
  }

  /**
   * Prompts user to submit email for confirmation
   */
  function renderRequestCodeForm() {
    return (
      <Form
        autoComplete="off"
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{ email: emailPassed }}
        onFinish={handleSendCodeClick}
      >
        <Form.Item
          label="Email"
          name="email"
          requiredMark={false}
          rules={[{ required: true, message: 'Email required' }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            id="logo-card-confirmation-btn-send"
            loading={isSendingCode}
            type="primary"
          >
            {!isSendingCode && <SendOutlined />} Send Confirmation
          </Button>
        </Form.Item>
      </Form>
    )
  }

  /**
   * Prompts user to enter verification code and new password
   */
  function renderConfirmationForm() {
    return (
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        onFinish={handleConfirmClick}
      >
        <Form.Item
          label="Confirmation code"
          name="code"
          rules={[
            { required: true, message: 'Please enter the confirmation code' },
          ]}
        >
          <Input placeholder="Confirmation Code" />
          {/* <HelpBlock>
                Please check your email ({fields.email}) for the confirmation code.
              </HelpBlock> */}
        </Form.Item>
        <Form.Item
          label="New password"
          name="password"
          rules={[
            { required: true, message: 'Please enter your new password' },
          ]}
        >
          <Password placeholder="New password" />
        </Form.Item>
        <Form.Item
          label="Confirm password"
          name="confirmPassword"
          validateStatus={validatePassword}
          rules={[
            { required: true, message: 'Please confirm your new password' },
            validatePassword,
          ]}
        >
          <Password placeholder="Confirm your new password" />
        </Form.Item>
        <Button
          id="logo-card-confirmation-btn-submit"
          loading={isConfirming}
          htmlType="submit"
          type="primary"
        >
          <CheckOutlined /> Confirm
        </Button>
      </Form>
    )
  }

  /**
   * Confirms successful password reset
   * Presents link to login
   */
  function renderSuccessMessage() {
    return (
      <>
        <p>Your password has been successfully reset.</p>
        <p>
          <Link to="/login">Log in with your new credentials now.</Link>
        </p>
      </>
    )
  }

  return (
    <LogoCard id="password-retrieval-card" title="Retrieve password">
      {!codeSent
        ? renderRequestCodeForm()
        : !confirmed
        ? renderConfirmationForm()
        : renderSuccessMessage()}
    </LogoCard>
  )
}
