import React from 'react'

import { Container, Form, Row } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'

import './../PatientProfile.scss'

export default function AnxietySaved(props) {
  const hasNoRelevantHistory =
    props.patientData.GeneralizedAnxietyDisorder.state === false &&
    props.patientData.PanicDisorder.state === false &&
    props.patientData.Phobias.state === false &&
    props.patientData.OtherAnxiety.state === false &&
    !props.patientData.AnxietyAdditionalNotes &&
    !Object.values(props.patientData.AnxietySymptoms).some((v) => !!v) &&
    props.patientData.NoAnxiety === true

  const noRecordedInfo =
    props.patientData.GeneralizedAnxietyDisorder.state === false &&
    props.patientData.PanicDisorder.state === false &&
    props.patientData.Phobias.state === false &&
    props.patientData.OtherAnxiety.state === false &&
    !props.patientData.AnxietyAdditionalNotes &&
    !Object.values(props.patientData.AnxietySymptoms).some((v) => !!v) &&
    props.patientData.NoAnxiety === false

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Anxiety"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">Anxiety:</Form.Row>
      <Form.Group className="indent">
        {props.patientData.GeneralizedAnxietyDisorder.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.GeneralizedAnxietyDisorder.state
              )}
            />
            <span id="GeneralizedAnxietyDisorderShow">
              Generalized anxiety disorder
            </span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.GeneralizedAnxietyDisorder.Notes}
          </Container>
        )}
        {props.patientData.PanicDisorder.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.PanicDisorder.state)}
            />
            <span id="PanicDisorderShow">Panic disorder</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.PanicDisorder.Notes}
          </Container>
        )}
        {props.patientData.Phobias.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.Phobias.state)}
            />
            <span id="PhobiasShow">Phobias</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.Phobias.Notes}
          </Container>
        )}
        {props.patientData.OtherAnxiety.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.OtherAnxiety.state)}
            />
            <span id="OtherAnxietyShow">Other</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.OtherAnxiety.Notes}
          </Container>
        )}
        {props.patientData.AnxietySymptoms.MuscleTension && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.AnxietySymptoms.MuscleTension
              )}
            />
            <span id="MuscleTensionShow">Muscle Tension</span>
            <br />
          </Container>
        )}
        {props.patientData.AnxietySymptoms.SleepDisruption && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.AnxietySymptoms.SleepDisruption
              )}
            />
            <span id="SleepDisruptionShow">Sleep Disruption</span>
            <br />
          </Container>
        )}
        {props.patientData.AnxietySymptoms.PanicAttacks && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.AnxietySymptoms.PanicAttacks
              )}
            />
            <span id="PanicAttacksShow">Panic attacks</span>
            <br />
          </Container>
        )}
        {props.patientData.AnxietySymptoms.SocialAnxiety && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.AnxietySymptoms.SocialAnxiety
              )}
            />
            <span id="SocialAnxietyShow">Social anxiety</span>
            <br />
          </Container>
        )}
        {props.patientData.AnxietySymptoms.CurrentStressEvents && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.AnxietySymptoms.CurrentStressEvents
              )}
            />
            <span id="CurrentStressEventsShow">
              Events affecting current stress/anxiety or irritability/mood
            </span>
            <br />
          </Container>
        )}
        {props.patientData.AnxietySymptoms.Other && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.AnxietySymptoms.Other)}
            />
            <span id="OtherTextShow">
              {props.patientData.AnxietySymptoms.OtherText}
            </span>
            <br />
          </Container>
        )}
      </Form.Group>
      <Form.Group className="border-bottom indent">
        {props.patientData.AnxietyAdditionalNotes && (
          <Container bsPrefix="psych">
            <Form.Row className="patient-dark">Additional Notes:</Form.Row>
            <Row bsPrefix="psych-sub">
              {props.patientData.AnxietyAdditionalNotes}
            </Row>
          </Container>
        )}
      </Form.Group>
    </Form.Group>
  )
}
