import { Alert } from '../../../../../../stories/BaseComponents'

import styles from './SpravatoAlerts.module.scss'

export function SpravatoGeneralAlert({
  isVisible,
}: {
  readonly isVisible: boolean
}) {
  return (
    <Alert
      message={
        <b style={{ fontWeight: 600 }}>
          Required fields are highlighted in red. A green check mark at the
          bottom of this form indicates all Spravato REMS Patient Monitoring
          fields are complete. Forms are faxed only when requirements are
          complete and after signing.
        </b>
      }
      parentClassname={styles.spravatoAlert}
      testId="spravato-alert"
      showIcon
      parentStyle={{
        display: isVisible ? 'block' : 'none',
      }}
      className="spravato-alert"
      type="info"
      closable
    />
  )
}

export function SpravatoViewModeAlert({
  isVisible,
}: {
  readonly isVisible: boolean
}) {
  return (
    <Alert
      message={
        <p className={styles.spravatoTitle}>
          Patient Monitoring Form NOT submitted
        </p>
      }
      description={
        <p style={{ margin: 0 }}>
          The Patient Monitoring Form for this note was not submitted due to
          incomplete requirements. Please submit the{' '}
          <a
            style={{ color: '#1890FF' }}
            href="https://spravatoremsenroll.com/#Public"
            target="blank"
          >
            <u>Patient Monitoring Form</u>
          </a>{' '}
          for this encounter online or fax to REMS within 7 days of the date of
          service.
        </p>
      }
      parentClassname={styles.spravatoAlert + ' ' + styles.spravatoViewAlert}
      testId="spravato-view-alert"
      showIcon={false}
      parentStyle={{
        display: isVisible ? 'block' : 'none',
      }}
      className="spravato-alert"
      type="error"
      closable={false}
    />
  )
}

export function SpravatoIsFaxedAlert({
  isVisible,
}: {
  readonly isVisible: boolean
}) {
  return (
    <Alert
      message={
        <p style={{ margin: 0 }}>
          The Patient Monitoring Form associated with this encounter was faxed.
        </p>
      }
      parentClassname={styles.spravatoAlert}
      testId="spravato-faxed-alert"
      showIcon={true}
      parentStyle={{
        display: isVisible ? 'block' : 'none',
      }}
      className="spravato-alert"
      type="success"
      closable={false}
    />
  )
}
