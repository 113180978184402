import { RefObject, useEffect, useRef, useState } from 'react'

import {
  PatientProfileEvents,
  trackPatientProfileEvent,
} from '../../libs/freshpaint/patientProfileEvents'

export const usePatientSidePanelState = (providerId?: string) => {
  const [collapsed, setCollapsed] = useState(false)

  const collapseButtonRef: RefObject<HTMLButtonElement> = useRef(null)

  const handleCollapseClick = (patientId: string) => {
    const currentPage = window.location.pathname.replace('/patient/', '')
    const lsCollapsedValue = localStorage.getItem(
      `${providerId}_collapsedSidebar`
    )
    const boolValue = lsCollapsedValue
      ? !JSON.parse(lsCollapsedValue || '')
      : true

    trackPatientProfileEvent(
      boolValue
        ? PatientProfileEvents.HIDE_PATIENT_PROFILE
        : PatientProfileEvents.EXPAND_PATIENT_PROFILE,
      {
        patientId,
        clinicId: providerId ?? '',
        currentPage,
        userActionType: 'click',
      }
    )

    localStorage.setItem(
      `${providerId}_collapsedSidebar`,
      JSON.stringify(
        lsCollapsedValue ? !JSON.parse(lsCollapsedValue || '') : true
      )
    )
    window.dispatchEvent(new Event('patientSidebarChanged'))
    collapseButtonRef.current?.blur()
  }

  useEffect(() => {
    // Load the collapsed state on mount
    const collapsedString = localStorage.getItem(
      `${providerId}_collapsedSidebar`
    )
    if (collapsedString) {
      const collapsed = JSON.parse(collapsedString)
      setCollapsed(collapsed)
    }

    // Respond to the `patientSidebarChanged` event
    function storageEventHandler() {
      const collapsedUpdate = localStorage.getItem(
        `${providerId}_collapsedSidebar`
      )
      const collapsed = JSON.parse(collapsedUpdate ?? '')
      setCollapsed(collapsed)
    }
    window.addEventListener('patientSidebarChanged', storageEventHandler)
    return () => {
      window.removeEventListener('patientSidebarChanged', storageEventHandler)
    }
  }, [])

  return { collapsed, collapseButtonRef, handleCollapseClick }
}
