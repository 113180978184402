import { useCallback, useEffect, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { syncRCopiaAllergies, syncRCopiaMeds } from '../../api/api-lib'
import { DiagnosisHistoryQueryKeys } from '../../hooks/useDiagnosisHistory'
import { usePatientClinicalNotes } from '../../hooks/usePatientClinicalNotes'
import {
  QueryKeys as PatientInfoQueryKeys,
  usePatientDemographics,
} from '../../hooks/usePatientInfo'
import { QueryKeys as MedHistoryQueryKeys } from '../../hooks/usePatientMedicationHistory'
import { ProviderQueries } from '../../hooks/useProviderInfo'
import useQueryString from '../../hooks/useQueryString'
import { InfoPage, Skeleton, Spinner } from '../../stories/BaseComponents'
import { StandardSkeletonRows } from '../../stories/BaseComponents/Skeleton'
import { useNotes } from '../../v2/notes/hooks/useNotes'
import { NotesListHeader } from './ClinicalNotes/ClinicalNotesComponents/NoteHeaderV2/NotesListHeader'
import ClinicalNotesList from './ClinicalNotes/ClinicalNotesList'
import { useNotesFeatures } from './ClinicalNotes/helpers'
import { PatientHeader } from './PatientHeader'
import { patientDataTypes } from './patient-data-types'

import styles from '../_shared.module.scss'
import './Patient.scss'

const ErrorView = (
  <InfoPage
    status="warning"
    title="Sorry there was a problem loading this page"
    details="Oops something went wrong. Please contact your Osmind representative if this issue persists."
    redirectButtonText="Return to Patient List"
    redirectLink="/"
  />
)

export default function PatientClinicalNotes({
  healthGorillaUserName,
}: {
  healthGorillaUserName: string
}) {
  const { hasEnabledCustomNotes } = useNotesFeatures()
  const query = useQueryString()
  const queryClient = useQueryClient()
  const patientId = query.get('patientId')
  const providerId = query.get('providerId')
  const [showSpinner, setShowSpinner] = useState(false)

  const {
    isInitialLoading: patientDataIsLoading,
    isError: patientDataIsError,
    data: patient,
  } = usePatientDemographics(patientId ?? '')

  const {
    isInitialLoading: providerSidePatientIsLoading,
    isError: providerSidePatientIsError,
    data: patientClinicalNotes,
    refetch: refetchV1Notes,
  } = usePatientClinicalNotes(patientId ?? '', !hasEnabledCustomNotes)

  const {
    notes: allV1AndV2Notes,
    isInitialLoading: allNotesIsLoading,
    isError: allNotesIsError,
    refetch: refetchV1AndV2Notes,
  } = useNotes(patientId, hasEnabledCustomNotes)

  const noteList = hasEnabledCustomNotes
    ? allV1AndV2Notes
    : patientClinicalNotes

  const invalidateNotesList = async () => {
    if (hasEnabledCustomNotes) {
      await refetchV1AndV2Notes()
    } else {
      await refetchV1Notes()
    }
  }

  const queriesLoading =
    patientDataIsLoading || providerSidePatientIsLoading || allNotesIsLoading
  const queriesError =
    patientDataIsError || providerSidePatientIsError || allNotesIsError
  const noQueryData = !patient || !noteList

  const handleApiChange = useCallback(() => {
    queryClient.invalidateQueries([
      patientDataTypes.PatientClinicalNotes,
      patientId,
    ])
    queryClient.invalidateQueries([
      PatientInfoQueryKeys.DEMOGRAPHICS,
      patientId,
    ])
    queryClient.invalidateQueries([ProviderQueries.PROVIDER_DETAILS, patientId])
    queryClient.invalidateQueries([MedHistoryQueryKeys])
    queryClient.invalidateQueries([DiagnosisHistoryQueryKeys.DiagnosisSnapshot])
  }, [queryClient])

  useEffect(() => {
    if (!patient?.PatientRCopiaId) {
      return
    }

    ;(async () => {
      // if this patient is onboarded for erx, download their meds and allergies from RCopia to sync with Osmind if updates were made since last call
      try {
        const data = {
          PatientRCopiaId: patient.PatientRCopiaId,
          PatientId: patient.PatientId,
        }
        await syncRCopiaAllergies({
          ...data,
          LastAllergyUpdateTimestamp: patient.LastAllergyUpdateTimestamp || '',
        })
        await syncRCopiaMeds({
          ...data,
          LastMedicationUpdateTimestamp:
            patient.LastMedicationUpdateTimestamp || '',
        })
        handleApiChange()
      } catch (error) {
        console.error(
          `Download data calls to RCopia failed for PatientRCopiaId ${patient.PatientRCopiaId} with error - `,
          error
        )
      }
    })()
  }, [patient?.PatientRCopiaId])

  if (queriesError || !patientId || !providerId) {
    return ErrorView
  }

  const showSkeleton = queriesLoading || noQueryData

  return (
    <div className={styles.scroll}>
      <PatientHeader
        providerId={providerId}
        patientId={patientId}
        healthGorillaUserName={healthGorillaUserName}
      />
      <NotesListHeader
        notesList={noteList ?? []}
        patientId={patientId ?? ''}
        setIsLoading={setShowSpinner}
        invalidateNotesList={invalidateNotesList}
      />
      <div className="patientNotesContainer">
        {showSkeleton ? (
          <Skeleton paragraph={{ rows: StandardSkeletonRows.fullPage }} />
        ) : (
          <div className={styles.headerMargin}>
            {showSpinner ? (
              <Spinner className={styles.notesListSpinner} fontSize={24} />
            ) : (
              <ClinicalNotesList clinicalNotes={noteList} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
