import { useState } from 'react'

type LocalStorageSetter = <ReturnValueType>(
  newValue: ReturnValueType | ((prevValue: ReturnValueType) => ReturnValueType)
) => void

export enum FormSectionLocalStorage {
  INTAKE_FORM_SWITCH = 'INTAKE_FORM_SWITCH',
  INTAKE_SECTIONS = 'INTAKE_SECTIONS',
}

export const useLocalStorage = <ReturnValueType>(
  keyName: string,
  defaultValue: ReturnValueType
): [ReturnValueType, LocalStorageSetter] => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName)

      if (value) {
        return JSON.parse(value)
      } else {
        const stringValue =
          typeof defaultValue === 'string'
            ? defaultValue
            : JSON.stringify(defaultValue)
        window.localStorage.setItem(keyName, stringValue)
        return defaultValue
      }
    } catch (err) {
      console.error(err)
      return defaultValue
    }
  })

  const setValue = <ReturnValue>(
    newValue: ReturnValue | ((pastValue: ReturnValue) => ReturnValue)
  ) => {
    const resolvedNewValue =
      newValue instanceof Function ? newValue(storedValue) : newValue
    try {
      const stringValue =
        typeof resolvedNewValue === 'string'
          ? resolvedNewValue
          : JSON.stringify(resolvedNewValue)
      window.localStorage.setItem(keyName, stringValue)
      setStoredValue(resolvedNewValue)
    } catch (err) {
      console.error(err)
      return storedValue
    }
  }

  return [storedValue, setValue]
}
