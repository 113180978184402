import { useEffect, useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import 'moment-timezone'
import { Col, Container, Form, ListGroup } from 'react-bootstrap'
import 'react-dates/initialize'

import useAllergiesInClinicNotes from '../../../../hooks/useAllergiesInClinicNotes'
import {
  DiagnosisHistoryQueryKeys,
  useDiagnosisSnapshot,
} from '../../../../hooks/useDiagnosisHistory'
import { QueryKeys as MedicationHistoryQueryKeys } from '../../../../hooks/usePatientMedicationHistory'
import { formatDate } from '../../../../libs/utils'
import { NoteTypes } from '../../../../shared-types'
import { determineNoteDateWithTime } from '../../../../shared/Helpers/utils'
import { DateWarningIcon, Spinner } from '../../../../stories/BaseComponents'
import Allergies from '../../Allergies'
import { generateSpravatoClassName } from './NoteType/Spravato/Spravato'
import ActiveMedications from './Sections/medications/ActiveMedications'

import '../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function PatientInfo(props) {
  const { noteType, noteDate, startTime } = props
  const queryClient = useQueryClient()

  const isSpravato = noteType === NoteTypes.SPRAVATO
  const dateOfBirth = useMemo(
    () => formatDate({ value: props.patient.DateOfBirth }),
    [props.patient]
  )

  const [noteDateForSnapshot, setNoteDateForSnapshot] = useState(null)

  useEffect(() => {
    setNoteDateForSnapshot(determineNoteDateWithTime(noteDate, startTime))
  }, [noteDate, startTime])

  const {
    data: diagnosisSnapshot = {},
    isLoading: diagnosisSnapshotLoading,
    isError: diagnosisSnapshotError,
  } = useDiagnosisSnapshot(
    props.patient.PatientId,
    noteDateForSnapshot,
    props.signedAt
  )

  const {
    activeDiagnoses,
    inactiveDiagnoses,
    resolvedDiagnoses,
    patientReportedDiagnoses,
  } = diagnosisSnapshot

  useEffect(() => {
    queryClient.invalidateQueries([
      DiagnosisHistoryQueryKeys.DiagnosisSnapshot,
      MedicationHistoryQueryKeys.ACTIVE_MED_HISTORY,
      props.patient.patientId,
      noteDateForSnapshot,
      props.signedAt,
    ])
  }, [noteDateForSnapshot])

  const { allergiesWithDateWarning, allergiesWithValidStartDate } =
    useAllergiesInClinicNotes(props.Allergies, noteDate)
  const warningAllergiesIds = allergiesWithDateWarning.map(
    (allergy) => allergy.AllergyId
  )

  const diagnosisHistoryList = (status, diagnosisList) => {
    return (
      <Container className="diagnosis-snapshot-list">
        {diagnosisList.map((diagnosis, i) => {
          return (
            <ListGroup.Item key={`${status.toLowerCase()}-diagnosis-list-${i}`}>
              <span>{diagnosis.Diagnosis} </span>
              <span className="diagnosis-snapshot-text-muted">
                • {diagnosis.DiagnosisCode || 'N/C'}
              </span>
              <br />
              <span className="diagnosis-snapshot-onset-text">
                {'Onset ' +
                  (moment(diagnosis.DiagnosisOnsetDate).isValid()
                    ? moment(diagnosis.DiagnosisOnsetDate).format('MM/DD/YYYY')
                    : moment(diagnosis.DiagnosisDate).isValid()
                    ? moment(diagnosis.DiagnosisDate).format('MM/DD/YYYY')
                    : 'N/A')}
              </span>
              <span className="diagnosis-snapshot-onset-text">
                {moment(diagnosis.DiagnosisHistoryCreatedAt).isValid() &&
                diagnosis.DiagnosisChangeAction !== 'BACKFILL'
                  ? ` • Marked ${status.toLowerCase()}:  ${moment(
                      diagnosis.DiagnosisHistoryCreatedAt
                    ).format('MM/DD/YYYY')}`
                  : ''}
              </span>
            </ListGroup.Item>
          )
        })}
      </Container>
    )
  }

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} sm={12} md={12} lg={6}>
          <Form.Label style={{ fontWeight: 'bold' }}>Patient Name</Form.Label>
          <Form.Control
            bsPrefix={
              isSpravato &&
              (!props.patient.firstName || !props.patient.lastName)
                ? generateSpravatoClassName(true, true)
                : 'form-input'
            }
            value={props.patient.PatientName}
            disabled
          />
          {isSpravato && !props.patient.firstName && (
            <div className="text-danger">First Name is required</div>
          )}
          {isSpravato && !props.patient.lastName && (
            <div className="text-danger">Last Name is required</div>
          )}
        </Form.Group>
        <Form.Group as={Col} sm={12} md={6} lg={6} xl={2}>
          <Form.Label style={{ fontWeight: 'bold' }}>Date of birth</Form.Label>
          <Form.Control
            bsPrefix={
              isSpravato && !props.patient.DateOfBirth
                ? generateSpravatoClassName(true, true)
                : 'form-input'
            }
            value={dateOfBirth || null}
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} md={6} lg={6} xl={2}>
          <Form.Label style={{ fontWeight: 'bold' }}>Legal Sex</Form.Label>
          <Form.Control
            bsPrefix={
              isSpravato && !props.patient.legalSex
                ? generateSpravatoClassName(true, true)
                : 'form-input'
            }
            value={props.patient.legalSex}
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={6}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Gender Identity
          </Form.Label>
          <Form.Control
            bsPrefix="form-input"
            value={props.patient.genderIdentity}
            disabled
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <hr />
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          style={{ marginTop: '10px' }}
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Diagnoses</Form.Label>
          <ListGroup>
            {diagnosisSnapshotLoading && (
              <div style={{ paddingTop: '20px', textAlign: 'center' }}>
                <p>Loading diagnosis snapshot...</p>
                <Spinner />
              </div>
            )}
            {diagnosisSnapshotError && (
              <div style={{ paddingTop: '5px', textAlign: 'center' }}>
                An error occurred loading diagnosis snapshot. Please try again.
              </div>
            )}
            {!diagnosisSnapshotLoading && !diagnosisSnapshotError && (
              <Container>
                <b>Active</b>
                <Container className="diagnosis-snapshot">
                  {!activeDiagnoses?.length && (
                    <div style={{ paddingTop: '5px' }}>
                      No diagnoses are active at this note date.
                    </div>
                  )}
                  {diagnosisHistoryList('Active', activeDiagnoses)}
                </Container>
              </Container>
            )}
            {!diagnosisSnapshotLoading &&
              !diagnosisSnapshotError &&
              inactiveDiagnoses?.length > 0 && (
                <Container>
                  <b>Marked Inactive</b>
                  <Container className="diagnosis-snapshot">
                    {diagnosisHistoryList('Inactive', inactiveDiagnoses)}
                  </Container>
                </Container>
              )}
            {!diagnosisSnapshotLoading &&
              !diagnosisSnapshotError &&
              resolvedDiagnoses?.length > 0 && (
                <Container>
                  <b>Marked Resolved</b>
                  <Container className="diagnosis-snapshot">
                    {diagnosisHistoryList('Resolved', resolvedDiagnoses)}
                  </Container>
                </Container>
              )}
            {!diagnosisSnapshotLoading &&
              !diagnosisSnapshotError &&
              patientReportedDiagnoses?.length > 0 && (
                <Container>
                  <b>Patient-reported</b>
                  <Container className="diagnosis-snapshot">
                    <Container className="diagnosis-snapshot-list">
                      {patientReportedDiagnoses.map((diagnosis, i) => {
                        return (
                          <ListGroup.Item
                            key={'patient-reported-diagnosis-list-' + i}
                          >
                            <span>{diagnosis.Diagnosis}</span>
                          </ListGroup.Item>
                        )
                      })}
                    </Container>
                  </Container>
                </Container>
              )}
          </ListGroup>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          style={{ marginTop: '10px' }}
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Active Medications
          </Form.Label>
          <ActiveMedications
            PatientId={props.patient.PatientId}
            EffectiveDate={noteDateForSnapshot}
            HandleRCopiaApiChange={props.handleApiChange}
            Patient={props.patient}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          style={{ marginTop: '10px' }}
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Allergies{' '}
            {allergiesWithDateWarning && allergiesWithDateWarning.length > 0 ? (
              <DateWarningIcon
                iconPaddingLeft="10px"
                iconPaddingRight="5px"
                text="Onset date not indicated"
              />
            ) : null}
          </Form.Label>
          <Allergies
            Allergies={[
              ...allergiesWithDateWarning,
              ...allergiesWithValidStartDate,
            ]}
            Patient={props.patient}
            handleApiChange={props.handleApiChange}
            ClinicalNoteDisplay
            NoAccordion
            isEditingNote={props.handleEdit}
            handleEditingAllergies={props.handleEditingAllergies}
            noteDate={noteDate}
            warningAllergiesIds={warningAllergiesIds}
          />
        </Form.Group>
        <Form.Group as={Col} xl={12}>
          {props.patient.PatientRCopiaId ? (
            <Form.Text
              className="center"
              style={{ fontWeight: 'bold', color: '#A9A9A9' }}
            >
              Diagnoses are copied from the Patient Profile, Allergies and
              Medications are copied from the eRx module.
              <br />
              If updates have recently been made within the eRx module, please
              refresh the page to see them reflected here.
            </Form.Text>
          ) : (
            <Form.Text
              className="center hideDuringPrint"
              style={{ fontWeight: 'bold', color: '#A9A9A9' }}
            >
              Diagnoses, Allergies and Medications are copied from the Patient
              Profile.
            </Form.Text>
          )}
        </Form.Group>
      </Form.Row>
    </>
  )
}
