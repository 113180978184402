import React from 'react'

import 'moment-timezone'
import { Button, Col, Form, Row } from 'react-bootstrap'
import 'react-dates/initialize'

import CustomTimePicker from '../../../../../stories/BaseComponents/CustomTimePicker'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function ShowSavedVSClinicalNote(props) {
  return (
    <>
      <Form.Row>
        <Form.Group as={Col} sm={12} lg={10}>
          <span className="patient-name">{props.index + 1}&nbsp;</span> :
        </Form.Group>
        <Form.Group as={Col} sm={12} lg={2}>
          <Button
            variant="outline-danger primary button-label"
            block
            size="sm"
            disabled={props.disabled}
            onClick={() => {
              props.deleteVitals(props.index)
            }}
          >
            Delete
          </Button>
        </Form.Group>
      </Form.Row>
      <Row>
        <Form.Group as={Col} sm={12} lg={3}>
          <Form.Label style={{ fontWeight: 'bold' }}>Time</Form.Label>
          <br />
          <CustomTimePicker
            value={props.Time}
            onChange={(value) => {
              props.handleFieldChange({ ...props.fields, Time: value })
            }}
            disabled={props.disabled}
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} lg={3}>
          <Form.Label style={{ fontWeight: 'bold' }}>HR</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            type="text"
            readOnly
            value={props.HR}
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} lg={3}>
          <Form.Label style={{ fontWeight: 'bold' }}>Temperature</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            type="number"
            readOnly
            value={props.Temperature}
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} lg={3}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Temperature Units
          </Form.Label>
          <Form.Control
            bsPrefix="form-input"
            type="text"
            readOnly
            value={props.TemperatureUnits}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} sm={12} lg={3}>
          <Form.Label style={{ fontWeight: 'bold' }}>RR</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            type="text"
            readOnly
            value={props.RR}
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} lg={3}>
          <Form.Label style={{ fontWeight: 'bold' }}>SPO2</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            type="text"
            readOnly
            value={props.SPO2}
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} lg={3}>
          <Form.Label style={{ fontWeight: 'bold' }}>BP</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            type="text"
            value={props.BP}
            readOnly
          />
        </Form.Group>

        <Form.Group as={Col} sm={12} lg={3}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Provider Initials
          </Form.Label>
          <Form.Control
            bsPrefix="form-input"
            type="text"
            readOnly
            value={props.ProviderInitials}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} sm={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>Comments</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            value={props.Comments}
            readOnly
            as="textarea"
            rows="3"
          />
        </Form.Group>
      </Row>
    </>
  )
}
