import { mapKeys } from 'lodash'
import moment, { Moment } from 'moment'

import { useFeatureFlags } from '../../../../libs/featureFlags'
import {
  DateISO8601,
  NoteTypes,
  ReverseUserFacingNoteTypes,
} from '../../../../shared-types'

/**
 * This function takes a sentence-cased note type and reverse maps it into a NoteTypes enum for the backend.
 * If it doesn't match any of the enum values, it will return null.
 *
 * @param {string} sentenceCaseNoteType - Sentence-cased note types that are displayed on the UI
 * @returns {NoteTypes | null} - Note type enums as they are stored in the database
 */
export function reverseMapNoteTypes(sentenceCaseNoteType: string): NoteTypes {
  const lowercasedKeyObject = mapKeys(
    ReverseUserFacingNoteTypes,
    (_value, key) => key.toLowerCase()
  )
  if (lowercasedKeyObject[sentenceCaseNoteType.toLowerCase()]) {
    return lowercasedKeyObject[sentenceCaseNoteType.toLowerCase()]
  }

  throw new Error(
    `Cannot find a matching note type for "${sentenceCaseNoteType}"`
  )
}

/**
 * These are the vital defaulted moment data that are needed when SALT-ing a note. Since
 * we are planning to move the SALT logic to the backend, we need to pass these as overrides to the
 * API to include the client date/time
 */
export function generateDefaultSaltFields(): {
  DischargeTime: DateISO8601
  StartTime: DateISO8601
  EndTime: DateISO8601
  InfusionStartTime: DateISO8601
  InfusionEndTime: DateISO8601
  NoteDate: Moment
} {
  const now = moment()
  return {
    DischargeTime: now.format('HH:mm'),
    StartTime: now.clone().add(1, 'minutes').format('HH:mm'),
    EndTime: now.format('HH:mm'),
    InfusionStartTime: now.format('HH:mm'),
    InfusionEndTime: now.format('HH:mm'),
    NoteDate: now.hour(12).minute(0).second(0).millisecond(0),
  }
}

/**
 * These are notes-related feature flags
 *
 * @returns {Object} NotesFeatureFlags - object with all notes-related feature flags
 * @returns {boolean} NotesFeatureFlags.hasEnabledCustomNotes - Has enabled/disabled Notes V2 or custom notes
 * @returns {number} NotesFeatureFlags.autosaveDebounceNotev1Seconds - Number of seconds to debounce an autosave for notes v1 (0 = off)
 */
export function useNotesFeatures() {
  const { hasEnabledCustomNotes, autosaveDebounceNotev1Seconds } =
    useFeatureFlags()

  return {
    hasEnabledCustomNotes,
    autosaveDebounceNotev1Seconds,
  }
}
