/**
 * List of all DataDog Custom Actions
 *
 * List the specific information first since in DD the second part is often truncated by narrower column width
 *
 * @example
 * <specific>.<general>
 */
export const DATADOG_ACTION = {
  OsmindPatientMessaging: {
    SendbirdWSConnectionManagementSetupFailed:
      'SendbirdWSConnectionManagementSetupFailed.OsmindPatientMessaging',
    SendbirdWSConnectionForceDisconnecting:
      'SendbirdWSConnectionForceDisconnected.OsmindPatientMessaging',
    SendbirdWSConnectionReconnecting:
      'SendbirdWSConnectionReconnecting.OsmindPatientMessaging',
    /**
     * Connect or Reconnect failed
     */
    SendbirdWSConnectionReconnectFailed:
      'SendbirdWSConnectionReconnectFailed.OsmindPatientMessaging',
    SendbirdWSConnectionReconnected:
      'SendbirdWSConnectionReconnected.OsmindPatientMessaging',
    SendbirdWSConnectionLimitExceeded:
      'SendbirdWSConnectionLimitExceeded.OsmindPatientMessaging',
    SendbirdInitializationError:
      'SendbirdInitializationError.OsmindPatientMessaging',
    /**
     * Unread message counts
     */
    SetMessagesAsUnread: 'SetMessagesAsUnread.OsmindPatientMessaging',
    SetMessagesAsRead: 'SetMessagesAsRead.OsmindPatientMessaging',
    /**
     * Sendbird Connection Handler actions
     */
    SendbirdConnectionFailed: 'SendbirdConnectionFailed.OsmindPatientMessaging',
  },
}
