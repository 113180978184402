import { Button, Result } from 'antd'

export type ErrorViewProps = {
  title: string

  actionText: string
  onClick: () => void
}

export const ErrorView = ({ title, onClick, actionText }: ErrorViewProps) => {
  return (
    <Result
      status="warning"
      title={title}
      subTitle="If the issue persists, or is unexepected, please contact Osmind support."
      extra={
        <Button type="primary" key="back-to-notes" onClick={onClick}>
          {actionText}
        </Button>
      }
    />
  )
}
