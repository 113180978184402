import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const HeadachesCodes: any = [
  {
    25064002: {
      title: 'Headaches (Including Migraines)',
      description: 'Headache (finding)',
      snomedcode: 362969004,
      icdcode: 'R519',
      icddescription: 'Headache, unspecified',
    },
  },
  {
    '230477005': {
      title: 'ha.Chronic Post Traumatic Headache',
      description: 'Chronic post-traumatic headache (disorder)',
      snomedcode: 230477005,
      icdcode: 'G44329',
      icddescription: 'Chronic post-traumatic headache, not intractable',
    },
  },
  {
    '193031009': {
      title: 'ha.Cluster Headache',
      description: 'Cluster headache syndrome (disorder)',
      snomedcode: 193031009,
      icdcode: 'G44009',
      icddescription: 'Cluster headache syndrome, unspecified, not intractable',
    },
  },
  {
    '37796009': {
      title: 'ha.Migraine',
      description: 'Migraine (disorder)',
      snomedcode: 37796009,
      icdcode: 'G43909',
      icddescription:
        'Migraine, unspecified, not intractable, without status migrainosus',
    },
  },
  {
    '398057008': {
      title: 'ha.Tension Headache',
      description: 'Tension-type headache (disorder)',
      snomedcode: 398057008,
      icdcode: 'G44209',
      icddescription: 'Tension-type headache, unspecified, not intractable',
    },
  },
]

export const HeadachesDropDownValues = constructPatientDemographicObject(
  HeadachesCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
