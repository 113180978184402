import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { IntakeForm, getIncompleteIntakeForm } from '../../api/intakeForms'

interface UsePatientFormState {
  error: string | null
  loading: boolean
  data: IntakeForm | null
}

export default function useIntakeForm(formId: string): UsePatientFormState {
  const history = useHistory()
  const [error, setError] = useState<null | string>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<null | IntakeForm>(null)

  useEffect(() => {
    setLoading(true)

    const fetchForm = async () => {
      try {
        const incompleteForm = await getIncompleteIntakeForm(formId)
        setData(incompleteForm)
      } catch (err) {
        const errorMessage = err.response?.data?.error ?? undefined

        if (errorMessage === 'This form is no longer accessible') {
          history.push('/form/success')
        } else {
          console.error(errorMessage ?? err)
          setError(
            errorMessage ??
              'Unable to access this form. Please contact an Osmind support rep.'
          )
        }
      } finally {
        setLoading(false)
      }
    }

    fetchForm()
  }, [formId])

  return {
    error,
    loading,
    data,
  }
}
