import * as React from 'react'

const SuccessIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 0.5C14.6047 0.5 0.5 14.6047 0.5 32C0.5 49.3953 14.6047 63.5 32 63.5C49.3953 63.5 63.5 49.3953 63.5 32C63.5 14.6047 49.3953 0.5 32 0.5ZM45.6055 21.7133L30.7977 42.2445C30.5907 42.5334 30.3179 42.7688 30.0018 42.9312C29.6857 43.0935 29.3354 43.1782 28.9801 43.1782C28.6247 43.1782 28.2745 43.0935 27.9584 42.9312C27.6423 42.7688 27.3695 42.5334 27.1625 42.2445L18.3945 30.0945C18.1273 29.7219 18.3945 29.2016 18.8516 29.2016H22.1492C22.8664 29.2016 23.5484 29.5461 23.9703 30.1367L28.9766 37.0836L40.0297 21.7555C40.4516 21.1719 41.1266 20.8203 41.8508 20.8203H45.1484C45.6055 20.8203 45.8727 21.3406 45.6055 21.7133Z"
        fill="#52C41A"
      />
    </svg>
  )
}

export default SuccessIcon
