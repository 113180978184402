import { useCallback, useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { FixedType } from 'rc-table/lib/interface'

import PaymentModals from '../../../containers/Patient/Billing/PaymentModals'
import {
  QueryKeys as BillingQueryKeys,
  PatientBillingSummary,
  useBillingPatientList,
  useStripeAccountStatus,
} from '../../../hooks/useBillingInfo'
import { formatNumberStringToUsdString } from '../../../libs/billing'
import { Button, Table } from '../../BaseComponents'
import {
  BillingPatientSummaryColumns,
  BillingPatientSummaryData,
} from '../../BaseComponents/tableData.types'
import { NavTableItem } from '../NavTableItem'

import styles from './BillingTable.module.scss'

export type BillingTableProps = {
  isShown: boolean
  searchValue: string
  providerId: string
}

const BILLING_ROUTE = '/patient/billing'

const PAGE_SIZE_OPTIONS: PageSize[] = [10, 20, 50]

const convertDataToTableData = (
  data: PatientBillingSummary,
  onPaymentClicked: (patientId: string) => void,
  isAddPaymentModalLoading: boolean,
  paymentModalPatientId: string,
  providerId: string
): BillingPatientSummaryData[] => {
  return data.patients.map(
    ({
      availableCreditAmountCents,
      lastPaymentDate,
      patientId,
      patientName,
      totalOutstandingAmountCents,
      isProviderAllowedToView,
    }) => {
      return {
        availableCreditAmountDollars: formatNumberStringToUsdString(
          (availableCreditAmountCents / 100).toFixed(2)
        ),
        key: patientId,
        lastPaymentDate: lastPaymentDate
          ? new Date(lastPaymentDate).toLocaleDateString()
          : '',
        patientId,
        patientName: (
          <NavTableItem
            isNavigationAllowed={isProviderAllowedToView}
            linkText={patientName}
            url={`${BILLING_ROUTE}?patientId=${patientId}&providerId=${providerId}`}
          />
        ),
        totalOutstandingAmountDollars: formatNumberStringToUsdString(
          (totalOutstandingAmountCents / 100).toFixed(2)
        ),
        actions: (
          <Button
            type={'link'}
            disabled={
              !isProviderAllowedToView ||
              (isAddPaymentModalLoading && paymentModalPatientId !== patientId)
            }
            loading={
              isAddPaymentModalLoading && paymentModalPatientId === patientId
            }
            onClick={() => {
              onPaymentClicked(patientId)
            }}
            className={styles.newPaymentButton}
          >
            New Payment
          </Button>
        ),
      }
    }
  )
}

type PageSize = 10 | 20 | 50

const tableColumns: BillingPatientSummaryColumns[] = [
  {
    key: 'patientName',
    dataIndex: 'patientName',
    title: 'Patient Name',
    fixed: 'left' as FixedType,
    width: 150,
  },
  {
    dataIndex: 'lastPaymentDate',
    key: 'lastPaymentDate',
    title: 'Last payment',
    width: 100,
  },
  {
    dataIndex: 'availableCreditAmountDollars',
    key: 'availableCreditAmountDollars',
    title: 'Available patient credit',
    width: 150,
  },
  {
    key: 'totalOutstandingAmountDollars',
    dataIndex: 'totalOutstandingAmountDollars',
    title: 'Total Outstanding - Invoices',
    width: 150,
  },
  {
    dataIndex: 'actions',
    key: 'actions',
    title: 'Actions',
    fixed: 'right' as FixedType,
    width: 100,
  },
]

export const BillingTable = ({
  isShown,
  searchValue,
  providerId,
}: BillingTableProps) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [selectedPatientId, setSelectedPatientId] = useState('')
  const [pageSize, setPageSize] = useState<PageSize>(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [isAddPaymentModalLoading, setIsAddPaymentModalLoading] =
    useState(false)
  const queryClient = useQueryClient()
  const { data, isLoading } = useBillingPatientList(
    pageNumber,
    searchValue,
    pageSize
  )

  const {
    isLoading: _stripeAccountStatusIsLoading,
    data: stripeAccountStatusData,
  } = useStripeAccountStatus()

  const handleOnClickNewPayment = (patientId: string) => {
    setShowPaymentModal(true)
    setSelectedPatientId(patientId)
    setIsAddPaymentModalLoading(true)
  }

  const handleClosePaymentModal = () => {
    setShowPaymentModal(false)
    setSelectedPatientId('')
    queryClient.invalidateQueries([BillingQueryKeys.CLINIC_SUMMARY])
    queryClient.invalidateQueries([BillingQueryKeys.PATIENT_LIST])
  }

  const stopLoadingButton = useCallback(
    () => setTimeout(() => setIsAddPaymentModalLoading(false), 500),
    []
  )

  const patientData = useMemo(() => {
    if (!data) return []
    return convertDataToTableData(
      data,
      (patientId: string) => handleOnClickNewPayment(patientId),
      isAddPaymentModalLoading,
      selectedPatientId,
      providerId
    )
  }, [data, isAddPaymentModalLoading])

  const handlePageSizeChange = (pageNumber: number, pageSize: number) => {
    setPageSize(pageSize as PageSize)
    setPageNumber(pageNumber)
  }

  const isTableLoading = isLoading || !isShown
  return (
    <div>
      {selectedPatientId && (
        <PaymentModals
          patientId={selectedPatientId}
          isOpen={showPaymentModal}
          onClose={() => handleClosePaymentModal()}
          stripeAccountStatus={stripeAccountStatusData?.status}
          stopModalLoading={isAddPaymentModalLoading ? stopLoadingButton : null}
          fromClaim={false}
          fromGlobalView={true}
        />
      )}
      <Table
        size="middle"
        scroll={{ x: 650 }}
        addClassNames={[styles.container]}
        dataSource={patientData}
        columns={tableColumns}
        loading={isTableLoading}
        noToolsSection
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          onChange: handlePageSizeChange,
          total: data?.totalPatients,
        }}
        pageSize={pageSize}
      />
    </div>
  )
}
