import { useEffect, useState } from 'react'

import { Checkbox, Form, FormInstance } from 'antd'

import { usePrevious } from '../../hooks/usePrevious'
import Icon from '../../images/Icons/GoogleCalendarIcon'
import { Col, Divider, Modal as OsmindModal, Row } from '../BaseComponents'
import {
  CalendarSettingsType,
  CalendarType,
  PersonalCalendar,
  UserCalendarAccount,
} from './types'

import './Scheduling.scss'

interface FormValues {
  [params: string]: string[]
}

interface OsmindCalendarCheckboxesProps {
  calendarId: string
  form: FormInstance
  visible: boolean
}

interface CalendarSettingsEditProps {
  calendarAccount: UserCalendarAccount | null
  handleCancel: () => void
  handleOk: (data: any) => Promise<void> | void
  visible: boolean
}

interface PersonalCalendarBody {
  calendarId: string
  showInOsmind: boolean
  displayEventDetails: boolean
  visibleToPractice: boolean
  displayInProviderCalendar: boolean
}

const OsmindCalendarCheckboxes = ({
  calendarId,
  form,
  visible,
}: OsmindCalendarCheckboxesProps) => {
  const hasNoId = calendarId ? false : true
  const [shouldDisable, setShouldDisable] = useState<boolean>(hasNoId)

  useEffect(() => setShouldDisable(calendarId ? false : true), [visible])

  const onChange = () => {
    const formCopy = form.getFieldsValue()
    const field = formCopy[calendarId] ?? []
    const isDisplayed = field.includes('displayEventsInGoogle')

    setShouldDisable(!isDisplayed)

    if (!isDisplayed) {
      form.setFieldsValue({ ...formCopy, [calendarId]: [] })
    }
  }

  return (
    <Form.Item name={calendarId} key={`osmind-cal-${calendarId}`}>
      <Checkbox.Group onChange={onChange}>
        <Row>
          <Col span={24} className="settings-checkbox">
            <Checkbox value="displayEventsInGoogle">
              Display events in Google Calendar
            </Checkbox>
          </Col>
          <Col span={24} className="settings-checkbox-indent">
            <Checkbox disabled={shouldDisable} value="includePatientInitials">
              Include patient initials in event title
            </Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
    </Form.Item>
  )
}

interface UserCalendarCheckboxesProps {
  calendar: PersonalCalendar
  form: FormInstance
  allFormValues: FormValues
  onFormValueChange(calendarId: string, values: string[]): void
}

const UserCalendarCheckboxes = ({
  calendar,
  form,
  allFormValues,
  onFormValueChange,
}: UserCalendarCheckboxesProps) => {
  const { calendarId, calendarName } = calendar
  const formValues: string[] | undefined = allFormValues[calendarId]

  const onChange = (values: string[]) => {
    let mutatedValues: string[] = []

    if (!values.includes('showInOsmind')) {
      mutatedValues = []
    } else if (
      values.includes('displayInProviderCalendar') &&
      !values.includes('visibleToPractice')
    ) {
      mutatedValues = [...values, 'visibleToPractice']
    } else if (
      !values.includes('visibleToPractice') &&
      values.includes('displayEventDetails')
    ) {
      mutatedValues = values.filter((value) => value !== 'displayEventDetails')
    } else {
      mutatedValues = values
    }
    form.setFieldValue(calendarId, mutatedValues)
    onFormValueChange(calendarId, mutatedValues)
  }

  const disableAllChildren = !formValues?.includes('showInOsmind')
  const disableVisibility = formValues?.includes('displayInProviderCalendar')
  const disableDetails = !formValues?.includes('visibleToPractice')

  return (
    <Form.Item name={calendarId} key={`user-cal-${calendarId}`}>
      <Checkbox.Group onChange={(values) => onChange(values as string[])}>
        <Row>
          <Col span={24} className="settings-checkbox">
            <Checkbox value="showInOsmind">{calendarName}</Checkbox>
          </Col>
          <Col span={24} className="settings-checkbox-indent">
            <Checkbox
              disabled={disableAllChildren}
              value="displayInProviderCalendar"
            >
              Combine with my Osmind calendar
            </Checkbox>
          </Col>
          <Col span={24} className="settings-checkbox-indent">
            <Checkbox
              disabled={disableAllChildren || disableVisibility}
              value="visibleToPractice"
            >
              Visible to other practice staff
            </Checkbox>
          </Col>
          <Col span={24} className="settings-checkbox-indent">
            <Checkbox
              disabled={disableAllChildren || disableDetails}
              value="displayEventDetails"
            >
              Display events details
            </Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
    </Form.Item>
  )
}

const CalendarSettingsEditModal = ({
  calendarAccount,
  handleCancel,
  handleOk,
  visible = false,
}: CalendarSettingsEditProps) => {
  const hasArrayOfCalendars = Array.isArray(calendarAccount?.calendars)
  const googleOsmindCalendar = hasArrayOfCalendars
    ? calendarAccount?.calendars?.filter(
        (cal) => cal.calendarType === CalendarType.PERSONAL_OSMIND
      )[0]
    : { calendarId: '' }
  const osmindGoogleCalendarId = googleOsmindCalendar?.calendarId ?? ''
  const [isOkLoading, setIsOkLoading] = useState(false)
  const [form] = Form.useForm()
  const wasVisible = usePrevious(visible)
  const [formValues, setFormValues] = useState<FormValues>({})

  const initFormValues = () => {
    if (!calendarAccount || !Array.isArray(calendarAccount?.calendars)) {
      return
    }
    const newInitialValues: FormValues = {}
    calendarAccount.calendars.forEach(
      ({ calendarSettings, calendarType, calendarId, showInOsmind }) => {
        newInitialValues[calendarId] = []
        if (!showInOsmind) {
          return
        }

        if (calendarType === CalendarType.PERSONAL_OSMIND) {
          newInitialValues[calendarId].push('displayEventsInGoogle')

          if (calendarSettings === CalendarSettingsType.WITH_PATIENT_INITIALS) {
            newInitialValues[calendarId].push('includePatientInitials')
          }
          return
        }

        newInitialValues[calendarId].push('showInOsmind')

        if (calendarSettings === CalendarSettingsType.INDIVIDUAL) {
          return
        }
        newInitialValues[calendarId].push('visibleToPractice')

        if (
          [
            CalendarSettingsType.INTEGRATED_PRIVATE,
            CalendarSettingsType.INTEGRATED_PUBLIC,
          ].includes(calendarSettings)
        ) {
          newInitialValues[calendarId].push('displayInProviderCalendar')
        }

        if (
          ![
            CalendarSettingsType.EVERYONE_PUBLIC,
            CalendarSettingsType.INTEGRATED_PUBLIC,
          ].includes(calendarSettings)
        ) {
          return
        }
        newInitialValues[calendarId].push('displayEventDetails')
      }
    )

    setFormValues({ ...newInitialValues })
    form.setFieldsValue(newInitialValues)
  }

  useEffect(() => {
    if (!wasVisible && visible) {
      initFormValues()
    }
  }, [visible, wasVisible])

  const onFinish = async () => {
    try {
      setIsOkLoading(true)
      if (!calendarAccount) return

      const { id } = calendarAccount
      const formValues = form.getFieldsValue()

      const keys = Object.keys(formValues)
      const values = Object.values(formValues)
      const osmindGoogleCalendar = {
        calendarId: osmindGoogleCalendarId,
        includePatientInitials: false,
        displayEventsInGoogle: false,
      }
      const personalCalendars: PersonalCalendarBody[] = []
      values.forEach((checked, index) => {
        const calendarId = keys[index]
        const isGoogleCalendarId = calendarId === osmindGoogleCalendarId

        if (!Array.isArray(checked)) {
          if (isGoogleCalendarId) return

          personalCalendars.push({
            calendarId,
            showInOsmind: false,
            displayEventDetails: false,
            visibleToPractice: false,
            displayInProviderCalendar: false,
          })
          return
        }

        if (isGoogleCalendarId) {
          const includeInitials = checked.includes('includePatientInitials')
          const displayEvents = checked.includes('displayEventsInGoogle')
          osmindGoogleCalendar.includePatientInitials = includeInitials
          osmindGoogleCalendar.displayEventsInGoogle = displayEvents
          return
        }

        personalCalendars.push({
          calendarId,
          showInOsmind: checked.includes('showInOsmind'),
          displayEventDetails: checked.includes('displayEventDetails'),
          visibleToPractice: checked.includes('visibleToPractice'),
          displayInProviderCalendar: checked.includes(
            'displayInProviderCalendar'
          ),
        })
      })

      const data = {
        accountId: id,
        osmindGoogleCalendar,
        personalCalendars,
      }

      await handleOk(data)
      initFormValues()
    } catch (e) {
      console.error(e)
    } finally {
      setIsOkLoading(false)
    }
  }

  const toggleCancel = () => {
    form.resetFields()
    handleCancel()
  }

  const handleFormValueChange = (calendarId: string, values: string[]) => {
    setFormValues((currValues) => ({
      ...currValues,
      [calendarId]: [...values],
    }))
  }

  return (
    <OsmindModal
      confirmLoading={isOkLoading}
      closable={true}
      title="Google account settings"
      visible={visible}
      onOk={onFinish}
      onCancel={toggleCancel}
      className="edit-calendar-name-modal"
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        {Boolean(calendarAccount) && (
          <div className="email-card">
            <Icon />
            <span>{calendarAccount?.accountName}</span>
          </div>
        )}
        {
          <>
            <Divider />
            Choose how to display Osmind events in Google Calendar:
            <OsmindCalendarCheckboxes
              calendarId={osmindGoogleCalendarId}
              form={form}
              visible={visible}
            />
          </>
        }
        {!calendarAccount || !Array.isArray(calendarAccount?.calendars) ? (
          <></>
        ) : (
          <>
            <Divider />
            Choose how to display Google Calendar events in Osmind:
            {calendarAccount.calendars.map((calendar: PersonalCalendar) => {
              if (calendar.calendarType === CalendarType.PERSONAL_OSMIND) return
              return (
                <UserCalendarCheckboxes
                  allFormValues={formValues}
                  calendar={calendar}
                  form={form}
                  onFormValueChange={handleFormValueChange}
                />
              )
            })}
          </>
        )}
      </Form>
    </OsmindModal>
  )
}

export default CalendarSettingsEditModal
