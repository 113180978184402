import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'

import { ClinicBillingSummary } from '../../../hooks/useBillingInfo'
import {
  convertCentsToDollars,
  formatNumberStringToUsdString,
} from '../../../libs/billing'
import { useFeatureFlags } from '../../../libs/featureFlags'
import { Button, Title } from '../../BaseComponents'
import { SummaryCard } from '../SummaryCard'

import styles from './TopBar.module.scss'

export type TopBarProps = Partial<ClinicBillingSummary> & {
  isLoading: boolean
  handleSearch(value: string): void
  openExportModal(): void
}

const { Search } = Input

const calculateNetCollectionRate = (
  totalOutstandingInvoiceAmountCents?: number,
  totalCollectedPaymentAmountCents?: number,
  totalAppliedToClaimsAmountCents?: number
) => {
  if (!totalOutstandingInvoiceAmountCents || !totalCollectedPaymentAmountCents)
    return '0.0%'

  const totalCollectedExcludingClaimsAttributions =
    totalCollectedPaymentAmountCents - (totalAppliedToClaimsAmountCents || 0)
  return (
    (
      (totalCollectedExcludingClaimsAttributions /
        (totalCollectedExcludingClaimsAttributions +
          totalOutstandingInvoiceAmountCents)) *
      100
    ).toFixed(1) + '%'
  )
}

export const TopBar = ({
  averageInvoiceAge,
  patientsWithBalance,
  totalCollectedPaymentAmountCents,
  isLoading,
  totalOutstandingInvoicesAmountCents,
  totalOutstandingClaimsAmountCents,
  totalOutstandingCredits,
  totalAppliedToClaimsAmountCents,
  handleSearch,
  openExportModal,
}: TopBarProps) => {
  const netCollectionRate = calculateNetCollectionRate(
    totalOutstandingInvoicesAmountCents,
    totalCollectedPaymentAmountCents,
    totalAppliedToClaimsAmountCents
  )

  const { claims } = useFeatureFlags()

  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <Title level={3} className="header-title">
          Billing
        </Title>
        <div className={styles.rightTopBar}>
          <div className={styles.inputContainer}>
            <Search
              placeholder="Search patients"
              allowClear
              onSearch={handleSearch}
              loading={isLoading}
              enterButton={<SearchOutlined />}
              className={styles.searchBar}
            />
          </div>
          <Button
            type="primary"
            onClick={openExportModal}
            className={styles.exportButton}
          >
            Export
          </Button>
        </div>
      </div>
      <div className={styles.cardContainer}>
        {claims ? (
          <SummaryCard
            description="Total outstanding claims"
            value={formatNumberStringToUsdString(
              convertCentsToDollars(totalOutstandingClaimsAmountCents || 0)
            )}
            isLoading={isLoading}
            testId="total-outstanding-claims"
          />
        ) : null}
        <SummaryCard
          description="Total outstanding invoices"
          value={formatNumberStringToUsdString(
            convertCentsToDollars(totalOutstandingInvoicesAmountCents || 0)
          )}
          isLoading={isLoading}
          testId="total-outstanding-invoices"
        />
        <SummaryCard
          description="Total collected payment"
          value={formatNumberStringToUsdString(
            convertCentsToDollars(totalCollectedPaymentAmountCents || 0)
          )}
          isLoading={isLoading}
          testId="total-collected-payment"
        />
        <SummaryCard
          description="Total outstanding credit"
          value={formatNumberStringToUsdString(
            convertCentsToDollars(totalOutstandingCredits || 0)
          )}
          isLoading={isLoading}
          testId="total-outstanding-credit"
        />
        <SummaryCard
          description="Invoice net collection rate"
          value={netCollectionRate}
          isLoading={isLoading}
          testId="invoice-net-collection-rate"
        />
        <SummaryCard
          description="Patients w/ invoice balance"
          value={patientsWithBalance?.toString() || '0'}
          isLoading={isLoading}
          testId="patients-with-balance"
        />
        <SummaryCard
          description="Average invoice age"
          value={`${averageInvoiceAge?.toFixed(2) || '0.00'} days`}
          isLoading={isLoading}
          testId="average-invoice-age"
        />
      </div>
    </div>
  )
}
