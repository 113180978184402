export enum SelfSchedulingEvents {
  ENABLE_APPT_TYPE = 'Enabled Appointment Type for Self-Scheduling',
  DISABLE_APPT_TYPE = 'Disabled Appointment Type for Self-Scheduling',
}

type SelfSchedulingEventProperties = {
  appointmentTypeId: string
  appointmentTypeName: string
  eventDate: string
}

export function trackSelfScheduling(
  eventName: SelfSchedulingEvents,
  eventDetails: SelfSchedulingEventProperties
) {
  window?.freshpaint?.track(eventName, eventDetails)
}

export type OpenedDrawerParams = {
  eventName: 'Opened appointment requests drawer'
  providerId: string
  clinicId: string
}

export type FilteredDrawerParams = {
  eventName: 'Filtered appointment requests drawer'
  providerId: string
  clinicId: string
  filter: 'All requests' | 'My requests'
}

export type AcceptedAppointmentParams = {
  eventName: 'Accepted appointment request'
  providerId: string
  clinicId: string
  apptTypeId: string
  apptTypeName: string
  patientId: string
  requestedApptTime: Date
}

export type DeclinedAppointmentRequest = {
  eventName: 'Declined appointment request'
  providerId: string
  clinicId: string
  apptTypeId: string
  apptTypeName: string
  patientId: string
  isSentMessage: boolean
  isSentCustomMessage: boolean
}

type AppointmentRequestApprovalTracking =
  | OpenedDrawerParams
  | FilteredDrawerParams
  | AcceptedAppointmentParams
  | DeclinedAppointmentRequest

export const trackAppointmentRequestApprovals = (
  trackingData: AppointmentRequestApprovalTracking
) => {
  if (trackingData.eventName === 'Filtered appointment requests drawer') {
    const { clinicId, filter, providerId, eventName } = trackingData
    window?.freshpaint?.track(eventName, {
      clinicId,
      filter,
      providerId,
    })
    return
  }

  if (trackingData.eventName === 'Accepted appointment request') {
    const {
      apptTypeId,
      apptTypeName,
      clinicId,
      patientId,
      providerId,
      requestedApptTime,
      eventName,
    } = trackingData

    window?.freshpaint?.track(eventName, {
      apptTypeId,
      apptTypeName,
      clinicId,
      patientId,
      providerId,
      requestedApptTime,
    })
    return
  }

  if (trackingData.eventName === 'Declined appointment request') {
    const {
      apptTypeId,
      apptTypeName,
      clinicId,
      isSentCustomMessage,
      isSentMessage,
      patientId,
      providerId,
      eventName,
    } = trackingData
    window?.freshpaint?.track(eventName, {
      apptTypeId,
      apptTypeName,
      clinicId,
      isSentCustomMessage,
      isSentMessage,
      patientId,
      providerId,
    })
    return
  }

  const { clinicId, providerId, eventName } = trackingData
  window?.freshpaint?.track(eventName, {
    clinicId,
    providerId,
  })
}
