import React from 'react'

import { Input as AntDInput } from 'antd'
import {
  PasswordProps as AntDInputPasswordProps,
  InputProps as AntDInputProps,
  TextAreaProps as AntDInputTextAreaProps,
} from 'antd/lib/input'

import { TestId } from '../../shared-types'

type InputProps = AntDInputProps & TestId

const Input: React.FC<InputProps> = ({ testId, ...props }) => {
  return <AntDInput data-testid={testId} {...props} />
}

type InputPasswordProps = AntDInputPasswordProps & TestId
const Password: React.FC<InputPasswordProps> = ({ testId, ...props }) => {
  return <AntDInput.Password data-testid={testId} {...props} />
}

type InputTextAreaProps = AntDInputTextAreaProps & TestId
const TextArea: React.FC<InputTextAreaProps> = ({ testId, ...props }) => {
  return <AntDInput.TextArea data-testid={testId} {...props} />
}

export default Input
export { Password, TextArea }
