import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import SharePatientProgress from '../../components/Accordions/SharePatientProgress/SharePatientProgress'
import ChangeActiveStatusButton from '../../components/Buttons/ChangeActiveStatusButton'
import { usePatientClinicalNotes } from '../../hooks/usePatientClinicalNotes'
import {
  QueryKeys as PatientProfileKeys,
  usePatientDemographics,
} from '../../hooks/usePatientInfo'
import { usePatientSettings } from '../../hooks/usePatientSettings'
import { useProviderDetails } from '../../hooks/useProviderInfo'
import { useProviderSidePatientData } from '../../hooks/useProviderSidePatientData'
import useQueryString from '../../hooks/useQueryString'
import { isPatientActive } from '../../libs/utils'
import { ProviderAccountInfo } from '../../shared-types'
import getCognitoUser from '../../shared/Helpers/getCognitoUser'
import { InfoPage, Row, Skeleton } from '../../stories/BaseComponents'
import { StandardSkeletonRows } from '../../stories/BaseComponents/Skeleton'
import { PatientHeader } from './PatientHeader'
import PatientSettingsComponent from './PatientSettings'

import styles from '../_shared.module.scss'
import './Patient.scss'

const ErrorView = (
  <InfoPage
    status="warning"
    title="Sorry there was a problem loading this page"
    details="Oops something went wrong. Please contact your Osmind representative if this issue persists."
    redirectButtonText="Return to Patient List"
    redirectLink="/"
  />
)

export default function PatientSettingsPage({
  healthGorillaUserName,
}: {
  healthGorillaUserName: string
}) {
  const query = useQueryString()
  const queryClient = useQueryClient()
  const patientId = query.get('patientId')
  const providerId = query.get('providerId')
  const [providerAccountState, setProviderAccountState] = useState<{
    error: boolean
    loading: boolean
    data: ProviderAccountInfo | null
  }>({
    error: false,
    loading: true,
    data: null,
  })

  useEffect(() => {
    const getProviderFromCognitoStorage = async () => {
      const data = await getCognitoUser()
      return data
    }

    // call the function
    getProviderFromCognitoStorage()
      .then((data) => {
        setProviderAccountState({
          data,
          error: false,
          loading: false,
        })
      })
      .catch((err) => {
        setProviderAccountState((currentState) => ({
          ...currentState,
          error: true,
        }))
        console.error(err)
      })
  }, [])

  const {
    isLoading: providerSidePatientIsLoading,
    isError: providerSidePatientIsError,
    data: providerSidePatientData,
  } = useProviderSidePatientData(patientId ?? '')

  const { data: patientClinicalNotes } = usePatientClinicalNotes(
    patientId ?? ''
  )

  const {
    isLoading: patientSettingsIsLoading,
    isError: patientSettingsIsError,
    data: patientSettingsData,
  } = usePatientSettings(patientId ?? '')

  const {
    isLoading: providerDetailsIsLoading,
    isError: providerDetailsIsError,
    data: providerDetails,
  } = useProviderDetails()

  const {
    isLoading: patientDataIsLoading,
    isError: patientDataIsError,
    data: patient,
  } = usePatientDemographics(patientId ?? '')

  const updatePatientCache = useCallback(
    () =>
      queryClient.invalidateQueries([
        PatientProfileKeys.DEMOGRAPHICS,
        patientId,
      ]),
    [queryClient]
  )

  const patientSettingsInfo = useMemo(() => {
    if (patientSettingsData) {
      return {
        PatientId: patientId,
        ...patientSettingsData,
      }
    } else {
      return undefined
    }
  }, [patientSettingsData])

  const queriesError =
    providerSidePatientIsError ||
    providerAccountState.error ||
    patientSettingsIsError ||
    providerDetailsIsError ||
    patientDataIsError

  const queriesLoading =
    providerSidePatientIsLoading ||
    patientSettingsIsLoading ||
    providerAccountState.loading ||
    providerDetailsIsLoading ||
    patientDataIsLoading

  const noQueryData =
    !providerSidePatientData ||
    !patientSettingsData ||
    !providerDetails ||
    !patient

  if (queriesError || !patientId || !providerId || !providerAccountState.data) {
    return ErrorView
  }

  return (
    <div className={styles.scroll}>
      <PatientHeader
        providerId={providerId}
        patientId={patientId}
        healthGorillaUserName={healthGorillaUserName}
      />
      <div className="patientSettingsContainer">
        {queriesLoading || noQueryData ? (
          <Skeleton paragraph={{ rows: StandardSkeletonRows.fullPage }} />
        ) : (
          <div className={styles.patientSettingsContainer}>
            <br />
            <Row>
              <h3 className={`${styles.centeredHeader} modal-header-blue`}>
                Patient Information
              </h3>
            </Row>
            <SharePatientProgress
              homepageData={providerDetails}
              provider={providerAccountState.data}
              patientSettingsData={patientSettingsData}
              patientClinicalNotes={patientClinicalNotes ?? []}
              patient={patient}
            />
            <hr className={styles.sectionSeparator} />
            <br />
            <Row>
              <h3
                className={`${styles.centeredHeader} ${styles.surveySettingsSection} modal-header-blue`}
              >
                Survey Settings
              </h3>
            </Row>
            <br />
            <PatientSettingsComponent
              patientSettingsInfo={patientSettingsInfo}
              surveyData={providerDetails.surveyData}
              defaultSurveySettings={undefined}
              showModalRef={undefined}
            />
            <ChangeActiveStatusButton
              patientId={patient.PatientId}
              isActive={isPatientActive(patient.IsActive)}
              handleApiChange={updatePatientCache}
            />
          </div>
        )}
      </div>
    </div>
  )
}
