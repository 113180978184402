import React from 'react'

import { format, getHours, isValid } from 'date-fns'
import parseHTML from 'html-react-parser'

import GoogleCalendarLogo from '../../../images/Icons/GoogleCalendarLogo'
import { isSameHourAndMinutes } from '../../../libs/utils'
import {
  ScheduledEventTypeAllDay,
  ScheduledEventTypeDateTime,
} from '../../../shared-types'
import { Button, Row } from '../../BaseComponents'
import Modal from '../../BaseComponents/Modal'

import '../Scheduling.scss'

export type ModalProps = {
  onClose: () => void
  event:
    | Pick<
        ScheduledEventTypeDateTime,
        | 'IsAllDayEvent'
        | 'StartTime'
        | 'EndTime'
        | 'Description'
        | 'GoogleCalendar'
        | 'EventName'
      >
    | Pick<
        ScheduledEventTypeAllDay,
        | 'IsAllDayEvent'
        | 'StartDate'
        | 'EndDate'
        | 'Description'
        | 'GoogleCalendar'
        | 'EventName'
      >
  isOpen: boolean
}

export const WEEKDAY_MONTH_DAY = 'EEEE, LLLL d'

export const GoogleEventModal: React.FC<ModalProps> = ({
  onClose,
  event,
  isOpen,
}) => {
  const MONTH_DAY_YEAR = 'MM-dd-yyyy'

  let dateDisplay = ''

  const start = new Date(
    event.IsAllDayEvent ? event.StartDate : event.StartTime
  )
  const end = new Date(event.IsAllDayEvent ? event.EndDate : event.EndTime)

  const isSameTime = Boolean(isSameHourAndMinutes(end, start))
  const isStartMidnight = start.getUTCHours() === 0 || getHours(start) === 0
  const isBothMidnight = isSameTime && isStartMidnight

  const isMultiDay =
    format(start, MONTH_DAY_YEAR) !== format(end, MONTH_DAY_YEAR)
  const isAllDaySingle =
    (isValid(start) && start === end) || (!isMultiDay && isBothMidnight)

  // if all day event, just show that date
  if (isAllDaySingle) {
    dateDisplay = format(start, WEEKDAY_MONTH_DAY)
    // if multi day event, show date range
  } else if (!isValid(start) || !isValid(end)) {
    dateDisplay = 'N/A'
  } else if (isMultiDay) {
    const startDisplay = format(start, WEEKDAY_MONTH_DAY)
    const endDisplay = format(end, WEEKDAY_MONTH_DAY)
    dateDisplay = `${startDisplay} - ${endDisplay}`
    // if not all day or multi day event, show time range
  } else {
    const day = format(start, WEEKDAY_MONTH_DAY)
    const bullet = '\u2022'
    const startTime = format(start, 'h:mm')
    const endTime = format(end, 'h:mmaaa')
    dateDisplay = `${day} ${bullet} ${startTime} - ${endTime}`
  }

  const footer = (
    <>
      <Button type="primary" onClick={onClose}>
        Close
      </Button>
    </>
  )

  const children = (
    <>
      <Row>
        <div>
          <GoogleCalendarLogo />
        </div>
        <div>
          <h4 className="google-event-title">{event.EventName}</h4>
          <span className="google-event-date-display">{dateDisplay}</span>
        </div>
      </Row>
      <hr style={{ marginTop: 32 }} />
      {event.Description?.length ? (
        <Row style={{ flexWrap: 'nowrap', marginBottom: 12 }}>
          <div style={{ width: 100 }}>Description:</div>
          <div style={{ width: 400 }}>
            {parseHTML(`<div>${event.Description}</div>`)}
          </div>
        </Row>
      ) : null}
      <Row style={{ flexWrap: 'nowrap' }}>
        <div style={{ width: 100 }}>Calendar:</div>
        <div style={{ width: 400 }}>{event.GoogleCalendar}</div>
      </Row>
    </>
  )

  return (
    <Modal
      closable={true}
      title={'Event'}
      visible={isOpen}
      footer={footer}
      onCancel={onClose}
      children={children}
    />
  )
}
