import { useMemo } from 'react'

import { ListGroup } from 'react-bootstrap'

import { usePatientMedicationHistory } from '../../../../../../hooks/usePatientMedicationHistory'
import { MedicationOrigin } from '../../../../../../shared-types'
import { Spinner } from '../../../../../../stories/BaseComponents'
import RCopiaMedsDisplay from '../../../../RCopiaMedsDisplay'

type ActiveMedicationProps = {
  PatientId: string
  EffectiveDate: Date | null

  HandleRCopiaApiChange: () => void
  Patient: any
}
export default function ActiveMedications(props: ActiveMedicationProps) {
  const {
    data: medications,
    isLoading,
    isError,
  } = usePatientMedicationHistory({
    patientId: props.PatientId,
    startDate: undefined,
    endDate: props.EffectiveDate,
    enabled: !!props.EffectiveDate,
  })

  return useMemo(() => {
    if (medications && medications.length > 0) {
      if (props.Patient.PatientRCopiaId) {
        const rcopiaMeds = medications.filter(({ origin }) =>
          [MedicationOrigin.DR_FIRST, MedicationOrigin.UNKNOWN].includes(origin)
        )

        return (
          <RCopiaMedsDisplay
            activeMeds={rcopiaMeds}
            patient={props.Patient}
            handleApiChange={props.HandleRCopiaApiChange}
          />
        )
      } else {
        const activeMeds = medications.filter(({ origin }) =>
          [MedicationOrigin.OSMIND, MedicationOrigin.UNKNOWN].includes(origin)
        )

        return (
          <ListGroup className="form-list-group">
            {activeMeds.map((actMeds, i) => {
              return (
                <ListGroup.Item key={'active-meds-' + i}>
                  {actMeds.MedicationName}
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        )
      }
    }

    if (medications && medications.length === 0) {
      return (
        <div style={{ paddingTop: '5px' }}>
          No medications are active at this note date.
        </div>
      )
    }

    if (isError) {
      return (
        <div style={{ paddingTop: '5px', textAlign: 'center' }}>
          An error occurred loading medication snapshot. Please try again.
        </div>
      )
    }

    return (
      <div style={{ paddingTop: '20px', textAlign: 'center' }}>
        <p>Loading medication snapshot...</p>
        <Spinner />
      </div>
    )
  }, [medications, isLoading, isError])
}
