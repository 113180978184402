import React from 'react'

import 'moment-timezone'
import { Button, Col, Form } from 'react-bootstrap'
import 'react-dates/initialize'

import { generateSpravatoClassName } from './Spravato'

import 'react-dates/lib/css/_datepicker.css'

interface SeriousAdverseEventProps {
  disabled: boolean
  fields: any
  eventType: string
  entriesType: string
  showEntries: () => React.ReactElement | undefined
  saveEntries: () => void
  handleFieldChange: (obj: any) => void
}

export default function SeriousAdverseEvent(props: SeriousAdverseEventProps) {
  const areThereEntries = props.fields[props.entriesType].length !== 0
  let duringOrBetween = null
  let entriesType = ''
  if (props.eventType === 'SpravatoAdverseEventDuring') {
    duringOrBetween = 'during the treatment session'
    entriesType = 'SavedAdditionalEntries'
  } else if (props.eventType === 'SpravatoAdverseEventBetween') {
    duringOrBetween = 'between treatment sessions'
    entriesType = 'SavedEntries'
  }
  function handleOnChange(eventType: string, value: any) {
    props.fields[eventType] = value
    props.handleFieldChange({ ...props.fields })
  }

  return (
    <>
      <Form.Row style={{ width: 'fit-content' }}>
        <Form.Group
          controlId={props.eventType}
          className={
            props.fields[props.eventType] === null
              ? generateSpravatoClassName()
              : ''
          }
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
        >
          {duringOrBetween && (
            <Form.Label className="font-weight-bold">
              Did the patient experience a serious adverse event{' '}
              {duringOrBetween}?
            </Form.Label>
          )}
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields[props.eventType])}
            style={{ marginLeft: '10px' }}
            value="true"
            id={`${duringOrBetween}Yes`}
            disabled={props.disabled}
            onChange={(event) => {
              handleOnChange(props.eventType, event.currentTarget.value)
              props.fields[entriesType]?.length === 0 && props.saveEntries()
            }}
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields[props.eventType]) === false}
            style={{ marginLeft: '10px' }}
            value="false"
            id={`${duringOrBetween}No`}
            disabled={props.disabled}
            onChange={(event) =>
              handleOnChange(props.eventType, event.currentTarget.value)
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Text className="adverse-event">
        A serious adverse event (SAE) for this SPRAVATO® REMS is defined as any
        event that results in/is: Hospitalization, Disability or permanent
        damage, Death, Life-threatening, Important medical event (defined as any
        event that may jeopardize the patient or may require intervention to
        prevent one of the above outcomes)
      </Form.Text>
      {JSON.parse(props.fields[props.eventType]) && (
        <>
          {/* Allows datepicker to drop down without y-scroll */}
          <div
            className={`table-responsive table ${
              !props.disabled && 'responsive-with-padding-adverse-event'
            }`}
            style={{ pointerEvents: 'none' }}
          >
            <table className="table">
              {areThereEntries && (
                <thead>
                  <tr>
                    <th
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        minWidth: '136px',
                      }}
                    >
                      Type
                    </th>
                    <th
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        minWidth: '96px',
                      }}
                    >
                      Date
                    </th>
                    <th
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        minWidth: '96px',
                      }}
                    >
                      Description
                    </th>
                    <th
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        minWidth: '150px',
                      }}
                    >
                      Resolution
                    </th>
                    <th
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        minWidth: '140px',
                      }}
                    >
                      &nbsp;
                    </th>
                  </tr>
                </thead>
              )}
              {/* Enables overlapped portion to allow pointer clicks */}
              <tbody style={{ pointerEvents: 'all' }}>
                {props.showEntries()}
              </tbody>
            </table>
          </div>
          {!props.disabled && (
            <div style={{ marginTop: '-376px' }}>
              <Form.Row>
                <Form.Group
                  as={Col}
                  style={{ textAlign: 'center', marginLeft: 'auto' }}
                >
                  <Button
                    bsPrefix="plain-button center info button-label"
                    onClick={() => {
                      props.saveEntries()
                    }}
                  >
                    + Add New Entry
                  </Button>
                </Form.Group>
              </Form.Row>
            </div>
          )}
        </>
      )}
    </>
  )
}
