import { BlockConfig } from '../../types'
import { Block } from '../Block'

export const BlockWrapper = (config: BlockConfig, isSigned: boolean) => {
  return () => {
    return (
      <Block
        isSigned={isSigned}
        header={config.header}
        actionIcon={config.actionIcon}
        actionLabel={config.actionLabel}
        onActionClick={({ options }: { options?: Record<string, any> }) =>
          config.onActionClick && config.onActionClick({ options })
        }
        content={config.content}
        useOptions={config.useOptions}
      />
    )
  }
}
