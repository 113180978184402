import React from 'react'

import _ from 'lodash'
import { Col, Form, Row } from 'react-bootstrap'

import FormMachine, { generateComponent } from '../FormMachine'
import {
  CheckForm,
  ColumnSizes,
  ConditionalOption,
  TemplateField,
  TemplateFields,
} from '../types'
import { CheckEditProps } from './types'

export default function CheckEditField(props: CheckEditProps) {
  const {
    handleCheckChange,
    handleFieldChange,
    handleMultipleSubmit,
    storedFields,
    styling = {},
    templateField,
    type,
    value = null,
  } = props

  const {
    columnSizes,
    conditionalOption = '',
    conditionalComponent = null,
    conditionalOptions = null,
    defaultOption = null,
    hideConditional = false,
    id,
    label,
    options = [],
  } = templateField

  const {
    colBsPrefix = '',
    colClassName = '',
    colStyle = {},
    editLabelBsPrefix = '',
    editLabelClassName = '',
    editLabelStyle = {},
    editValueBsPrefix = '',
    editValueClassName = '',
    editValueStyle = {},
    isLabelOnSameLine = false,
  } = styling

  let inputType = CheckForm.RADIO

  // type retyped as enums to appease typescript
  switch (type) {
    case 'checkbox':
      inputType = CheckForm.CHECKBOX
      break
    case 'switch':
      inputType = CheckForm.SWITCH
  }

  const isRadio = inputType === CheckForm.RADIO
  const isCheckbox = inputType === CheckForm.CHECKBOX
  const areCheckboxValuesStored = Array.isArray(value)
  const valueAsArray = value as string[]

  let conditionalSection = null
  let shouldIncludeConditional = false

  // if the option has a conditional associated with it,
  // it will appear when the option is checked.
  // if it only applies to one option, then it is defined here
  if (conditionalOption && conditionalComponent) {
    // if there's values already stored and is selected,
    // for checkbox and radio as follows
    if (isCheckbox && areCheckboxValuesStored) {
      const isChecked =
        typeof valueAsArray.find(
          (checked: string) => checked === conditionalOption
        ) !== 'undefined'
      shouldIncludeConditional = isChecked
    }
    // if no other special cases apply, simply checks if the conditionalOption is selected
    shouldIncludeConditional =
      shouldIncludeConditional || conditionalOption === value

    // if hideConditional is added for this templateField,
    // when the option is checked, the conditional will be hidden rather than displayed
    if (hideConditional) {
      shouldIncludeConditional = !shouldIncludeConditional
    }

    if (shouldIncludeConditional) {
      // checks if a whole form will be added conditionally vs. just one field
      const ifConditionalComponentIsForm = Array.isArray(conditionalComponent)
      if (ifConditionalComponentIsForm) {
        conditionalSection = (
          <FormMachine
            handleFieldChange={handleFieldChange}
            handleCheckChange={handleCheckChange}
            handleMultipleSubmit={handleMultipleSubmit}
            isEditing
            storedFields={storedFields}
            styling={styling}
            template={conditionalComponent as TemplateField[]}
          />
        )
      } else {
        conditionalSection = generateComponent(
          {
            handleCheckChange,
            handleFieldChange,
            handleMultipleSubmit,
            isEditing: true,
            storedFields,
            styling,
          },
          conditionalComponent as TemplateField
        )
      }
    }
  }

  const doesColSizeExist = styling.colSize
  let colString = ''
  if (doesColSizeExist) {
    const { xs, sm, md, lg, xl } = styling.colSize as ColumnSizes
    colString = `col-xs-${xs} col-sm-${sm} col-md-${md} col-lg-${lg} col-xl-${xl}`
  }

  const columnString = isLabelOnSameLine
    ? { xs: 12, sm: 'auto', xl: 'auto' }
    : { xs: 12, sm: 12, xl: 12 }

  return (
    <Col
      bsPrefix={colBsPrefix}
      className={colClassName}
      id={`form-field-${id}`}
      style={colStyle}
      {...columnSizes}
    >
      <Form.Group controlId={id}>
        <div className={doesColSizeExist ? 'row' : ''}>
          <Form.Label
            as={Col}
            bsPrefix={editLabelBsPrefix}
            className={editLabelClassName}
            style={{
              ...editLabelStyle,
              marginLeft: '14px',
            }}
            {...columnString}
          >
            {label}
          </Form.Label>
          <Col>
            <Row>
              {options.map((option, index) => {
                let isChecked = false

                const shouldCheckIfDefault = defaultOption && !value
                if (shouldCheckIfDefault) {
                  // if there's no value given and there's a default option defined in the TemplateField,
                  // then the option should be checked if they match
                  isChecked = option === defaultOption
                } else if (isRadio) {
                  // check if stored value already matches option
                  isChecked = value === option
                } else if (isCheckbox && areCheckboxValuesStored) {
                  // looks through whatever values are already stored
                  isChecked =
                    typeof valueAsArray.find(
                      (choice: string) => choice === option
                    ) !== 'undefined'
                }

                const handleChange = () =>
                  handleCheckChange(id, index, isChecked)

                const isCheckedWithoutValueStored = isChecked && !value
                if (isCheckedWithoutValueStored) {
                  // updates stored values to add checked
                  // (ie. if checked and default option or first option (and radio))
                  handleChange()
                }

                const bsPrefixDefault = doesColSizeExist
                  ? `form-check ${editValueBsPrefix}`
                  : `form-check form-check-inline ${editValueBsPrefix}`

                // if the option has a conditional associated with it,
                // it will appear when the option is checked.
                // if there are conditionals for several options,
                // then they will be checked for and defined here
                let conditionalFormOfOption = null
                const isConditionalOptionsAnArray =
                  Array.isArray(conditionalOptions)
                if (isConditionalOptionsAnArray && isChecked) {
                  const options = conditionalOptions as ConditionalOption[]
                  const option = options[index]
                  const doesOptionHaveComponent = Array.isArray(
                    option?.conditionalComponent
                  )
                  if (doesOptionHaveComponent) {
                    const template = option.conditionalComponent
                    const marginLessStyling = {
                      ..._.cloneDeep(styling),
                      formRowStyle: { margin: 0 },
                    }
                    conditionalFormOfOption = (
                      <Col xs={12} xl={12}>
                        <FormMachine
                          handleFieldChange={handleFieldChange}
                          handleCheckChange={handleCheckChange}
                          handleMultipleSubmit={handleMultipleSubmit}
                          isEditing
                          storedFields={storedFields}
                          styling={marginLessStyling}
                          template={template as TemplateFields}
                        />
                      </Col>
                    )
                  }
                }

                const optionId = `${id}-${index}`
                return (
                  <React.Fragment key={`form-check-${optionId}`}>
                    {isConditionalOptionsAnArray ? (
                      <Col
                        bsPrefix={bsPrefixDefault}
                        className={
                          doesColSizeExist ? colString : editValueClassName
                        }
                        style={editValueStyle}
                      >
                        <Row>
                          <Col>
                            <Form.Check
                              checked={isChecked}
                              inline
                              label={option}
                              type={inputType}
                              key={optionId}
                              id={optionId}
                              onChange={handleChange}
                            />
                          </Col>
                          {conditionalFormOfOption}
                        </Row>
                      </Col>
                    ) : (
                      <Form.Check
                        bsPrefix={bsPrefixDefault}
                        className={
                          doesColSizeExist ? colString : editValueClassName
                        }
                        style={editValueStyle}
                        checked={isChecked}
                        inline
                        label={option}
                        type={inputType}
                        key={optionId}
                        id={optionId}
                        onChange={handleChange}
                      />
                    )}
                  </React.Fragment>
                )
              })}
            </Row>
          </Col>
        </div>
        {conditionalSection}
      </Form.Group>
    </Col>
  )
}
