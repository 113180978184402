import { useEffect } from 'react'

import { usePrevious } from '../usePrevious'

type UseAppReloadParams = {
  /**
   * Has a reload been requested?
   * If true, a reload will occur as soon as valid conditions are met.
   */
  shouldReload: boolean
  /**
   * The current route path.
   */
  currentPathname: string
}

/**
 * If on one of these routes, a page reload will occur immediately when a
 * reload request is received. These routes should be limited to routes where
 * refreshing will not cause data loss.
 */
const SAFE_ROUTES = ['/', '/login']

/**
 * Handles app reload logic.
 * On a requested reload, the app will be reloaded if:
 *  - We are on a 'safe' route and can reload immediately.
 *  - The user navigates to a different route.
 */
export const useAppReload = ({
  shouldReload,
  currentPathname,
}: UseAppReloadParams): void => {
  const prevPathname: string | undefined = usePrevious(currentPathname)

  useEffect(() => {
    const isSafeRoute = SAFE_ROUTES.includes(currentPathname)
    const pathChanged =
      prevPathname !== undefined && currentPathname !== prevPathname
    if (shouldReload && (isSafeRoute || pathChanged)) {
      console.log('Force app reload.')
      window.location.reload()
    }
  }, [shouldReload, currentPathname])
}
