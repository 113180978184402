import React, { useEffect, useState } from 'react'

import {
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Form } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload/interface'

import {
  changeTemplateName,
  createNewTemplateUrl,
  deleteTemplate,
  getTemplateEditUrl,
} from '../api/hellosign'
import {
  HellosignTemplate,
  HomepageData,
} from '../components/Accordions/SharePatientProgress/attachmentLib'
import { s3UploadPublic } from '../libs/awsLib'
import { onError } from '../libs/errorLib'
import { openHellosignWindow } from '../libs/hellosign'
import { notification } from '../libs/notificationLib'
import {
  Checkbox,
  Dropdown,
  Input,
  Message,
  PageHeader,
  Space,
  Tooltip,
  Upload,
} from '../stories/BaseComponents'
import Button from '../stories/BaseComponents/Button'
import Card from '../stories/BaseComponents/Card'
import ConfirmModal from '../stories/BaseComponents/ConfirmModal'
import Modal from '../stories/BaseComponents/Modal'
import Table from '../stories/BaseComponents/Table'
import { TemplateTableData } from '../stories/BaseComponents/tableData.types'
import PDFIcon from '../stories/assets/pdf.svg'

import './Intake.scss'
import './Patient/Patient.scss'
import styles from './_shared.module.scss'

const { Dragger } = Upload

interface IntakeProps {
  homepageData: HomepageData
  updateProviderData: Function
}

interface DraggerProps {
  setFileList: (list: UploadFile[]) => void
}

const TemplateDragger: React.FC<DraggerProps> = ({ setFileList }) => {
  const handleChange = (info: UploadChangeParam) => {
    const { status } = info.file
    if (status === 'done') {
      Message.success(`${info.file.name} file uploaded successfully.`)
    } else if (status === 'error') {
      Message.error(`${info.file.name} file upload failed.`)
    }
    setFileList([...info.fileList])
  }

  const dummyRequest = ({ _file, onSuccess }: any) => {
    onSuccess('ok')
  }

  return (
    <Dragger
      data-testid="intake--drag-and-drop--listener"
      name="file"
      multiple={true}
      beforeUpload={(file: RcFile) => {
        if (file.type !== 'application/pdf') {
          Message.error(`${file.name} is not a pdf file`)
        }
        return file.type === 'application/pdf' ? true : Upload.LIST_IGNORE
      }}
      iconRender={() => <img src={PDFIcon} alt="PDF Icon" />}
      onChange={handleChange}
      customRequest={dummyRequest}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">.pdf files only</p>
    </Dragger>
  )
}

interface TemplateItem {
  editUrl: string
  providerId: string
  templateId: string
}

/**
 * This component needs some love and refactoring:
 * Ref: https://osmind.atlassian.net/browse/CC-2680
 */
const Intake: React.FC<IntakeProps> = (props) => {
  const [form] = Form.useForm()
  const [email, setEmail] = useState<string>('')
  const [templateList, setTemplateList] = useState<HellosignTemplate[]>([])
  const [createShow, setCreateShow] = useState<boolean>(false)
  const [providerId, setproviderId] = useState<string>('')

  const [newTemplateWrapInfo, setNewTemplateWrapInfo] =
    useState<null | TemplateItem>(null)
  const [editTemplateWrapInfo, setEditTemplateWrapInfo] = useState<
    string | null
  >(null)
  const [showEditNameModal, setShowEditNameModal] = useState<boolean>(false)
  const [editTemplateName, setEditName] = useState<string>('')
  const [editTemplateId, setEditTemplateId] = useState<string>('')

  useEffect(() => {
    function getCurrentTemplateIdList() {
      const templateList = props.homepageData?.hellosignTemplates

      if (templateList === undefined) {
        return 'Templates Not Configured'
      }

      return templateList
    }

    function onLoad() {
      try {
        const clientemail = props.homepageData?.hellosign[0]?.ProviderEmail
        const providerId = props.homepageData?.providerId
        setproviderId(providerId)
        if (clientemail) {
          setEmail(clientemail)
        }
        const templateData = getCurrentTemplateIdList()
        if (templateData !== 'Templates Not Configured') {
          setTemplateList(templateData)
        }
      } catch (e) {
        console.log('error', e)
      }
    }

    onLoad()
  }, [props.homepageData])

  const templateData = React.useMemo(() => {
    const list: TemplateTableData[] | [] = templateList.map((item) => {
      return {
        key: `${item.TemplateId}`,
        TemplateId: item.TemplateId,
        TemplateName: item.TemplateName,
        ProviderId: item.ProviderId,
      }
    })

    return list
  }, [templateList])

  const WrapModal = ({ showWrapperModal, setShowWrapperModal }: any) => {
    const handleOK = async () => {
      await deleteTemplate({
        templateId: newTemplateWrapInfo?.templateId,
        providerId: providerId,
        notCreatedOnHelloSign: true,
      })
      await props.updateProviderData()
      setShowWrapperModal(false)
      setCreateShow(false)
    }

    const showConfirmModal = () => {
      ConfirmModal({
        title: 'Are you sure?',
        content:
          'Are you sure you want to close this dialog? The template is not completed, so you will lose it.',
        okText: 'Close',
        cancelText: 'Cancel',
        handleOk: handleOK,
      })
    }

    return (
      <Modal
        className="form-template-wrapper-modal"
        data-testid="IntakeTemplate--CreateFormModal"
        title="Create Form Template"
        visible={showWrapperModal}
        onOk={() => {
          setShowWrapperModal(false)
          setCreateShow(false)
        }}
        onCancel={showConfirmModal}
        footer={null}
      >
        <div id="form-template-modal-content" />
      </Modal>
    )
  }

  const EditWrapModal = ({ showWrapperModal, setShowWrapperModal }: any) => {
    const showConfirmModal = () => {
      ConfirmModal({
        title: 'Are you sure?',
        content:
          'Are you sure you want to close this dialog? You will lose the changes.',
        okText: 'Close',
        cancelText: 'Cancel',
        handleOk: () => setShowWrapperModal(false),
      })
    }

    return (
      <Modal
        data-testid="IntakeForm--EditTemplateModal"
        className="form-template-wrapper-modal"
        title="Edit Form Template"
        visible={showWrapperModal}
        onOk={() => setShowWrapperModal(false)}
        onCancel={showConfirmModal}
        closeIcon={
          <CloseOutlined data-testid="IntakeForm--EditTemplateModal--Close" />
        }
        footer={null}
      >
        <div
          data-testid="IntakeForm--EditTemplateModal--Content"
          id="edit-form-template-modal-content"
        />
      </Modal>
    )
  }

  async function editTemplate(templateId: string, email: string) {
    try {
      const newUrl = await getTemplateEditUrl({
        templateId: templateId,
        email: email,
      })
      setEditTemplateWrapInfo(newUrl)
    } catch (error) {
      onError(
        error,
        500,
        'Your template is not yet available for editing. Please try again in one minute.'
      )
    }
  }

  async function handleDeleteTemplate(templateId: string, providerId: string) {
    try {
      await deleteTemplate({
        templateId: templateId,
        providerId: providerId,
      })
      notification(
        'You have successfully deleted a template. Please wait a moment to see your changes.',
        'success'
      )
      await props.updateProviderData()
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
  }

  function EditTemplateNameModal(modalProps: any) {
    const { templateName, show } = modalProps
    const [name, setName] = useState<string>(templateName)
    const [isSubmitting, setSubmitting] = useState<boolean>(false)
    const [editForm] = Form.useForm()

    const initialValues = {
      templateName: name,
    }

    useEffect(() => {
      editForm.setFieldsValue(initialValues)
    }, [show])

    const handleSubmit = async () => {
      setSubmitting(true)
      try {
        const data = {
          templateId: editTemplateId,
          newName: name,
          providerId: providerId,
        }
        await changeTemplateName(data)
        setSubmitting(false)
        notification(
          "You have successfully updated your template's name.",
          'success'
        )
        await props.updateProviderData()
        setShowEditNameModal(false)
      } catch {
        onError(
          500,
          'Updating template name failed. Please try again in one minute.'
        )
        setSubmitting(false)
      }
    }

    const handleOk = () => {
      form
        .validateFields()
        .then(() => {
          handleSubmit()
        })
        .catch((info) => {
          console.log('Validate Failed: ', info)
        })
    }

    return (
      <Modal
        title="Edit template name"
        data-testid="IntakeForm--EditTemplateNameModal"
        visible={show}
        onOk={handleOk}
        onCancel={() => setShowEditNameModal(false)}
        footer={
          <div className="antd-modal-footer">
            <Button type="default" onClick={() => setShowEditNameModal(false)}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleOk}
              loading={isSubmitting}
              disabled={!name}
            >
              Save
            </Button>
          </div>
        }
      >
        <Form
          form={editForm}
          onFinish={handleOk}
          scrollToFirstError
          layout="vertical"
          key="TemplateNameModalForm"
          data-testid="IntakeForm--EditTemplateNameModal--Form"
        >
          <Form.Item
            name="templateName"
            label="Template Name"
            key={templateName}
            className="template-name-input-container"
            rules={[
              {
                required: true,
                message: 'Please input template name!',
                type: 'string',
                whitespace: true,
              },
            ]}
          >
            <Input
              onChange={(e) => setName(e.target.value)}
              data-testid="IntakeForm--EditTemplateNameModal--Form--Input"
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  function CreateTemplateModal(modalProps: any) {
    const [templateName, setTemplateName] = useState('')
    const [files, setFileList] = useState<UploadFile[]>([])
    const [isSubmitting, setSubmitting] = useState(false)
    const { onHide, show } = modalProps

    const handleSubmit = async (values: any) => {
      setSubmitting(true)
      // quick notification to tell the user not to leave the page until the next
      // notification tells them to edit and complete their intake form
      notification(
        'Please wait and do not leave this page until you complete your new intake form.',
        'quick'
      )
      // upload each file to s3
      const keys = []
      for (let i = 0; i < files.length; i++) {
        const file = files[i].originFileObj
        if (!file) {
          continue
        }
        try {
          const res = await s3UploadPublic(file)
          keys.push(res)
        } catch (e) {
          setSubmitting(false)
          onError(
            e,
            500,
            'There was an error uploading your document. Please confirm the file has been exported in PDF format and the filename does not contain any "*" characters.'
          )
          return
        }
      }
      if (keys.length === files.length) {
        const ret: TemplateItem = await createNewTemplateUrl({
          title: email,
          files: keys,
          email: email,
          providerId: providerId,
          templateName: templateName,
          sigOption: values.countersignature ? 'twoWay' : 'oneWay',
        })
        setNewTemplateWrapInfo(ret)
      } else {
        setSubmitting(false)
        setNewTemplateWrapInfo(null)
        notification(
          'There was an error uploading your document. Please confirm the file has been exported in PDF format and the filename does not contain any "*" characters.',
          'error'
        )
      }
    }

    useEffect(() => {
      if (newTemplateWrapInfo) {
        const output = document.getElementById('form-template-modal-content')
        if (output) {
          openHellosignWindow(
            newTemplateWrapInfo.editUrl,
            async () => {
              try {
                notification(
                  'The new template is created successfully.',
                  'success'
                )
                await props.updateProviderData()
                onHide()
              } catch (e) {
                notification(
                  'Could not fetch the latest provider data.',
                  'error'
                )
              }
            },
            output
          )
        } else {
          notification(
            "Edit the template and press 'Continue' to submit your new intake form.",
            'success'
          )
          openHellosignWindow(
            newTemplateWrapInfo.editUrl,
            props.updateProviderData
          )
        }
      }
    }, [newTemplateWrapInfo, props.updateProviderData])

    const handleOk = () => {
      form
        .validateFields()
        .then((values) => {
          handleSubmit(values)
        })
        .catch((info) => {
          console.log('Validate Failed: ', info)
        })
    }

    return (
      <Modal
        title="Form template"
        visible={show}
        onOk={handleOk}
        onCancel={onHide}
        footer={[
          <div className="antd-modal-footer">
            <Button type="default" onClick={onHide}>
              Cancel
            </Button>
            <Button
              data-testid="intake--submit-new-template--button"
              type="primary"
              onClick={handleOk}
              loading={isSubmitting}
              disabled={!templateName || files.length === 0}
            >
              Next
            </Button>
          </div>,
        ]}
      >
        <Form
          form={form}
          onFinish={handleOk}
          scrollToFirstError
          layout="vertical"
          initialValues={{ countersignature: false }}
        >
          <Form.Item
            name="templateName"
            label="Template Name"
            rules={[
              {
                required: true,
                message: 'Please input template name!',
                type: 'string',
                whitespace: true,
              },
            ]}
          >
            <Input onChange={(e) => setTemplateName(e.target.value)} />
          </Form.Item>

          <Form.Item>
            <TemplateDragger setFileList={setFileList} />
          </Form.Item>

          <Form.Item name="countersignature" valuePropName="checked">
            <Checkbox>
              Clinic countersignature required
              <Tooltip
                className="template-form-item_tooltip"
                title="Clinic must countersign after the patient completes the form."
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  // Current logic is waiting for a rerender prior to opening the Hellosign modal, so that the anchor in the
  // modal is visible. During the upgrade to React-18, we discovered that the anchor for the iframe wouldn't
  // show up until after the iframe would render, causing the iframe to not be visibile.
  useEffect(() => {
    if (editTemplateWrapInfo) {
      const output = document.getElementById('edit-form-template-modal-content')
      if (output) {
        openHellosignWindow(
          editTemplateWrapInfo,
          async () => {
            notification('The template is updated successfully.', 'success')
            setEditTemplateWrapInfo(null)
            await props.updateProviderData()
          },
          output
        )
      } else {
        openHellosignWindow(editTemplateWrapInfo)
      }
    }
  }, [editTemplateWrapInfo])

  function handleHide() {
    setCreateShow(false)
    setNewTemplateWrapInfo(null)
    form.resetFields()
  }

  const templateColumns: ColumnsType<TemplateTableData> = [
    {
      title: 'Template Name',
      dataIndex: 'TemplateName',
      width: '80%',
      key: 'TemplateName',
      sorter: {
        compare: (a: TemplateTableData, b: TemplateTableData) => {
          if (a.TemplateName > b.TemplateName) return 1
          else if (a.TemplateName === b.TemplateName) return 0
          return -1
        },
        multiple: 1,
      },
    },
    {
      title: '',
      key: 'action',
      render: (text: any, record: TemplateTableData) => {
        return (
          <Space
            size="small"
            className="tableButtonContainer"
            data-testid={`IntakeTemplateRow--${record.TemplateId}`}
          >
            <Dropdown
              testId={`IntakeTemplateRow--EditDropdown--${record.TemplateId}`}
              trigger={['click']}
              items={[
                {
                  text: 'Edit Template',
                  key: `IntakeTemplateRow--EditDropdown--EditTemplate--${record.TemplateId}`,
                  onClick: () => {
                    setEditTemplateId(record.TemplateId)
                    editTemplate(record.TemplateId, email)
                    setEditName(record.TemplateName)
                  },
                },
                {
                  text: 'Edit Name',
                  key: `IntakeTemplateRow--EditDropdown--EditName--${record.TemplateId}`,
                  onClick: () => {
                    setEditTemplateId(record.TemplateId)
                    setEditName(record.TemplateName)
                    setShowEditNameModal(true)
                  },
                },
              ]}
            >
              <span
                className="dropdown-inner-link"
                data-testid={`IntakeTemplateRow--EditDropdown--Inner--${record.TemplateId}`}
              >
                Edit <DownOutlined />
              </span>
            </Dropdown>

            <Button
              type="text"
              data-testid={`IntakeTemplateRow--Delete--${record.TemplateId}`}
              icon={<DeleteOutlined />}
              onClick={(event) => {
                event.stopPropagation()
                ConfirmModal({
                  title: 'Are you sure?',
                  content:
                    'Are you sure you want to delete this template? This action cannot be undone.',
                  okText: 'Delete',
                  cancelText: 'Cancel',
                  handleOk: () =>
                    handleDeleteTemplate(record.TemplateId, record.ProviderId),
                })
              }}
              className="antd-center-button action-button-delete"
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        <PageHeader
          title="Custom form templates"
          description={
            'Create your own forms (e.g. in Word or Google Docs), save them as PDFs, and upload them here. You’ll drag and drop fields like Patient Name, Date, and Signature fields for consent forms. You can also add checkboxes, radio buttons, and drop-down fields for other forms. We recommend creating individual form templates for each of your unique forms. Patient-signed forms will be added to the patient chart under Documents.' +
            '\n\n' +
            'Note: These forms do not yet connect to the patient profile. If you’d like the patient information to auto-fill the patient chart, use the pre-built Osmind Intake form for patient information, and custom forms for consent forms or custom questionnaires.'
          }
          descriptionStyle={{ whiteSpace: 'pre-line' }}
          extra={
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setCreateShow(true)}
              className="antd-center-button"
              data-testid="Intake--AddTemplate-Button"
            >
              Add template
            </Button>
          }
        />
        <Card bordered={false}>
          <Table
            columns={templateColumns}
            dataSource={templateData}
            pagination={false}
            size="middle"
            showHeader={true}
            noToolsSection
            addClassNames={['container-override']}
          />
        </Card>
        <CreateTemplateModal show={createShow} onHide={handleHide} />
        <EditTemplateNameModal
          show={showEditNameModal}
          templateName={editTemplateName}
        />
        <WrapModal
          showWrapperModal={newTemplateWrapInfo}
          setShowWrapperModal={setNewTemplateWrapInfo}
        />
        <EditWrapModal
          showWrapperModal={editTemplateWrapInfo}
          setShowWrapperModal={setEditTemplateWrapInfo}
        />
      </div>
    </div>
  )
}

export default Intake
