import React, { RefObject } from 'react'

import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'
import { Button, Layout } from 'antd'
import cx from 'classnames'
import { Helmet } from 'react-helmet'

import { checkIfIsMobile } from '../../libs/utils'
import { Tooltip } from '../BaseComponents'

import styles from './LeftSidebar.module.scss'

export interface LeftSidebarProps extends React.PropsWithChildren {
  handleCollapseClick: () => void
  collapseButtonRef: RefObject<HTMLButtonElement>
  collapsed: boolean
  tooltip: string
  testId: string
  noWidthCollapse?: boolean
  hasFixedWidth?: boolean
  increasedFixedButtonHeight?: boolean
}
const { Sider } = Layout
const LeftSidebarContainer: React.FC<LeftSidebarProps> = ({
  children,
  handleCollapseClick,
  collapseButtonRef,
  collapsed,
  tooltip,
  testId,
  noWidthCollapse = false,
  hasFixedWidth = true,
  increasedFixedButtonHeight = false,
}) => {
  return (
    <>
      <Helmet>
        {checkIfIsMobile() && (
          <meta name="viewport" content="user-scalable=1" />
        )}
      </Helmet>
      <Sider
        data-testid={`left-sidebar-container-${testId}`}
        trigger={null}
        onMouseEnter={() => collapsed && collapseButtonRef?.current?.focus()}
        onMouseLeave={() => collapsed && collapseButtonRef?.current?.blur()}
        className={cx(
          hasFixedWidth ? styles.fixedWidth : styles.expandToFitChildren,
          styles.leftSidebar,
          collapsed &&
            (noWidthCollapse ? styles.fullCollapsed : styles.collapsed)
        )}
        onClick={() => collapsed && handleCollapseClick()}
        theme="light"
      >
        {children}
        <Tooltip
          overlayClassName={styles.collapsedTooltip}
          color="#000000"
          placement={'right'}
          visible={false}
          title={collapsed ? `Show ${tooltip}: [` : `Hide ${tooltip}: [`}
        >
          <Button
            data-testid={`collapse-button-${testId}`}
            ref={collapseButtonRef}
            onClick={() => !collapsed && handleCollapseClick()}
            className={cx(
              styles.expandButton,
              collapsed && styles.collapsed,
              increasedFixedButtonHeight && styles.increasedFixedButtonHeight
            )}
            icon={collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          >
            {!collapsed && 'Hide'}
          </Button>
        </Tooltip>
      </Sider>
    </>
  )
}

export default LeftSidebarContainer
