export async function getBase64FromUrl(imgUrl: string) {
  const response = await fetch(imgUrl)
  if (!response.ok) {
    return Promise.reject('The image could not be loaded.')
  }
  const buffer = await response.arrayBuffer()
  const base64String = btoa(
    new Uint8Array(buffer).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  )
  return `data:${response.headers.get(
    'content-type'
  )};base64,${base64String}`.replace('application/octet-stream', 'image/png')
}
