import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const GastrointestineCodes: any = [
  {
    119292006: {
      title: 'Gastrointestinal Disorder',
      snomedcode: 119292006,
      description: 'Disorder of gastrointestinal tract (disorder)',
      icdcode: 'K929',
      icddescription: 'Disease of digestive system, unspecified',
    },
  },
  {
    34000006: {
      title: "gi.Crohn's Disease",
      description: "Crohn's disease (disorder)",
      snomedcode: 34000006,
      icdcode: 'K50919',
      icddescription:
        "Crohn's disease, unspecified, with unspecified complications",
    },
  },
  {
    271366000: {
      title: 'gi.Diverticulitis',
      description: 'Diverticulitis of gastrointestinal tract (disorder)',
      snomedcode: 271366000,
      icdcode: 'K5792',
      icddescription:
        'Diverticulitis of intestine, part unspecified, without perforation or abscess without bleeding',
    },
  },
  {
    40739000: {
      title: 'gi.Dysphagia (Swallowing Problems)',
      description: 'Dysphagia (disorder)',
      snomedcode: 40739000,
      icdcode: 'R1310',
      icddescription: 'Dysphagia, unspecified',
    },
  },
  {
    235595009: {
      title: 'gi.Gastro-Esophageal Reflux Disease (GERD)',
      description: 'Gastroesophageal reflux disease (disorder)',
      snomedcode: 235595009,
      icdcode: 'K219',
      icddescription: 'Gastro-esophageal reflux disease without esophagitis',
    },
  },
  {
    84089009: {
      title: 'gi.Hiatal Hernia',
      description: 'Hiatal hernia (disorder)',
      snomedcode: 84089009,
      icdcode: 'K449',
      icddescription: 'Diaphragmatic hernia without obstruction or gangrene',
    },
  },
  {
    10743008: {
      title: 'gi.Irritable Bowel Syndrome (IBS)',
      description: 'Irritable bowel syndrome (disorder)',
      snomedcode: 10743008,
      icdcode: 'K589',
      icddescription: 'Irritable bowel syndrome without diarrhea',
    },
  },
  {
    75694006: {
      title: 'gi.Pancreatitis',
      description: 'Pancreatitis (disorder)',
      snomedcode: 75694006,
      icdcode: 'K8590',
      icddescription:
        'Acute pancreatitis without necrosis or infection, unspecified',
    },
  },
  {
    397825006: {
      title: 'gi.Stomach Ulcer',
      description: 'Gastric ulcer (disorder)',
      snomedcode: 397825006,
      icdcode: 'K259',
      icddescription:
        'Gastric ulcer, unspecified as acute or chronic, without hemorrhage or perforation',
    },
  },
  {
    64766004: {
      title: 'gi.Ulcerative Colitis',
      description: 'Ulcerative colitis (disorder)',
      snomedcode: 64766004,
      icdcode: 'K5190',
      icddescription: 'Ulcerative colitis, unspecified, without complications',
    },
  },
]

export const GastrointestineDropDownValues = constructPatientDemographicObject(
  GastrointestineCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
