import React from 'react'

import { StatusCategoryValue } from '../../../shared-types'

import styles from './ClaimStatusDisplay.module.scss'

type ClaimStatusDisplayProps = {
  displayMessage: string
  statusCategory: StatusCategoryValue
  testId: string
}

function getClaimStatusColorStyle(status: StatusCategoryValue) {
  switch (status) {
    case 'NO_ACTION': {
      return styles.statusDotBlue
    }
    case 'DONE': {
      return styles.statusDotGreen
    }
    case 'INFO':
    case 'WARNING': {
      return styles.statusDotOrange
    }
    case 'ACTION_REQUIRED': {
      return styles.statusDotRed
    }
    case 'DRAFT': {
      return styles.statusDotGray
    }
  }
}

const ClaimStatusDisplay: React.FC<ClaimStatusDisplayProps> = ({
  displayMessage,
  statusCategory,
  testId,
}) => {
  const dotStyle = getClaimStatusColorStyle(statusCategory)

  return (
    <div className={styles.statusContainer} data-testid={testId}>
      <div className={dotStyle} />
      {displayMessage}
    </div>
  )
}

export default ClaimStatusDisplay
