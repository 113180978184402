import { useEffect, useMemo } from 'react'

import {
  MutationStatus,
  QueryStatus,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { keyBy } from 'lodash'

import { getLocation, getPOSCodes, getTeammateData } from '../../../api/api-lib'
import {
  createClaimFromPatientInfo,
  deleteClaim,
  getClaimById,
  submitClaim,
  updateClaim,
  updateClaimMemo,
} from '../../../api/insuranceClaimsV2'
import { fetchDiagnoses } from '../../../api/patients'
import { QueryKeys as InsuranceClaimQueryKeys } from '../../../hooks/useInsuranceClaims'
import { useChangePayers } from '../../../hooks/usePatientProfile'
import { getInsurancePayerOptions } from '../../../libs/utils'
import { Diagnosis, PlaceOfServiceCode } from '../../../shared-types'
import { getAllPatientNoteDetails } from '../../../v2/notes/api'
import { ClaimData, ClaimLocation, ClaimProvider } from './types'

const QUERY_KEYS = {
  CLAIM: 'claim',
  TEAMMATES: 'teammates',
  LOCATIONS: 'locations',
  POS: 'posCodes',
  NOTES: 'notes',
  ACTIVE_DIAGNOSES: 'activeDiagnoses',
} as const

const STALE_TIME = { short: 1_000, medium: 30_000 }

type UseGetClaimDataInput = {
  patientId: string
  claimId?: string
  noteId?: string
}

type UseGetClaimDataResult = {
  data?: ClaimData
  error: unknown
  status: QueryStatus | MutationStatus
}

export const useGetClaimData = ({
  patientId,
  claimId,
  noteId,
}: UseGetClaimDataInput): UseGetClaimDataResult => {
  const queryClient = useQueryClient()
  const {
    data: existingClaimData,
    error: existingClaimError,
    status: existingClaimFetchStatus,
  } = useQuery(
    [QUERY_KEYS.CLAIM, claimId],
    () => getClaimById(claimId as string),
    {
      enabled: !!claimId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  )

  const {
    mutate: doCreateClaim,
    data: newClaimData,
    error: newClaimError,
    status: newClaimFetchStatus,
  } = useMutation({
    mutationFn: createClaimFromPatientInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [InsuranceClaimQueryKeys.INSURANCE_CLAIMS, patientId],
      })
    },
  })

  useEffect(() => {
    if (!claimId) {
      doCreateClaim({ patientId, noteId })
    }
  }, [claimId, patientId, noteId])

  // use useMemo to avoid creating a new object on every render, critical for preventing extra rendering when we include the returned "data" as a dependency in a useEffect
  const existingClaimDataWithClaimId = useMemo(
    () =>
      existingClaimData ? { ...existingClaimData, claimId } : existingClaimData,
    [existingClaimData]
  )

  if (claimId) {
    return {
      data: existingClaimDataWithClaimId,
      error: existingClaimError,
      status: existingClaimFetchStatus,
    }
  } else {
    return {
      data: newClaimData,
      error: newClaimError,
      status: newClaimFetchStatus,
    }
  }
}

export const useDeleteClaim = ({ patientId }: { patientId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteClaim,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [InsuranceClaimQueryKeys.INSURANCE_CLAIMS, patientId],
      })
    },
  })
}

export const useSaveClaim = ({ claimId }: { claimId?: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateClaim,
    onSuccess: () => {
      // invalidate getClaimById query after claim update.
      // Otherwise useGetClaimData will serve stale data when the same claim loads for the second time, which is not a problem if we set enableReinitialize prop to true for <Formik/>
      // However, that causes extra re-initialization and flash of old claim data.
      if (claimId) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CLAIM, claimId],
        })
      }
    },
  })
}

export const useSaveClaimMemo = ({ claimId }: { claimId?: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateClaimMemo,
    onSuccess: () => {
      if (claimId) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CLAIM, claimId],
        })
      }
    },
  })
}

export const useSubmitClaim = ({ claimId }: { claimId?: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: submitClaim,
    onSuccess: () => {
      if (claimId) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CLAIM, claimId],
        })
      }
    },
  })
}

export const usePayers = () => {
  const { data, status } = useChangePayers()

  const payersData = useMemo(() => {
    if (!data?.length) {
      return { payerOptions: [], payersByName: {} }
    }
    return {
      payerOptions: getInsurancePayerOptions(data),
      payersByName: keyBy(data, 'organizationName'),
    }
  }, [data])

  return {
    status,
    data: payersData,
  }
}

export const useProviders = () => {
  const { status, data }: { status: QueryStatus; data?: ClaimProvider[] } =
    useQuery([QUERY_KEYS.TEAMMATES], getTeammateData, {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME.medium,
    })

  const providersData = useMemo(() => {
    if (!data?.length) {
      return { providerOptions: [], providersById: {} }
    }
    return {
      providerOptions: data
        .filter(
          (el) => !el.isDeactivated && el.lastActive !== 'never_logged_in'
        )
        .map(({ name, email, cognitoId }) => ({
          label: name || email,
          value: cognitoId,
        })),
      providersById: keyBy(data, 'cognitoId'),
    }
  }, [data])

  return {
    status,
    data: providersData,
  }
}

export type UseProvidersResult = ReturnType<typeof useProviders>

export const useLocations = () => {
  const { status, data }: { status: QueryStatus; data?: ClaimLocation[] } =
    useQuery([QUERY_KEYS.LOCATIONS], getLocation, {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME.medium,
    })

  const locationsData = useMemo(() => {
    if (!data?.length) {
      return { locationOptions: [], locationsByLocationId: {} }
    }
    return {
      locationOptions: data.map(({ LocationName, LocationId }) => ({
        label: LocationName,
        value: LocationId,
      })),
      locationsByLocationId: keyBy(data, 'LocationId'),
    }
  }, [data])

  return {
    status,
    data: locationsData,
  }
}

export const DEFAULT_NOTE_TITLE = 'No Title'
export const useNotes = (patientId: string) => {
  const { status, data } = useQuery(
    [QUERY_KEYS.NOTES, patientId],
    () => getAllPatientNoteDetails({ patientId }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME.short,
    }
  )

  const notesData = useMemo(() => {
    if (!data?.data.length) {
      return { noteOptions: [], notesById: {} }
    }

    const notes = data.data.map((note) => ({
      ...note,
      title: note.title || DEFAULT_NOTE_TITLE,
    }))

    return {
      noteOptions: notes.map(({ title, noteId }) => ({
        label: title,
        value: noteId,
      })),
      notesById: keyBy(notes, 'noteId'),
    }
  }, [data])

  return {
    status,
    data: notesData,
  }
}

export const usePosCodes = () => {
  const { status, data }: { status: QueryStatus; data?: PlaceOfServiceCode[] } =
    useQuery([QUERY_KEYS.POS], getPOSCodes, {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME.medium,
    })

  const posData = useMemo(() => {
    if (!data?.length) {
      return { posOptions: [] }
    }
    return {
      posOptions: data.map(({ name, code }) => ({
        label: name || code,
        value: code,
      })),
    }
  }, [data])

  return {
    status,
    data: posData,
  }
}
/**
 @return Active diagnoses sorted by diagnosis data in descending order
*/
export const useActiveDiagnoses = (patientId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ACTIVE_DIAGNOSES, patientId],
    queryFn: async () => {
      const diagnoses: Diagnosis[] = await fetchDiagnoses(patientId)
      if (!diagnoses.length) {
        return []
      }
      return diagnoses
        .filter((diagnosis) => diagnosis.DiagnosisStatus === 'Active')
        .sort(
          (a, b) =>
            new Date(b.DiagnosisDate ?? 0).valueOf() -
            new Date(a.DiagnosisDate ?? 0).valueOf()
        )
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME.short,
  })
}
