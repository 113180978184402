import React from 'react'

import { Col, MiniCalendar, Row } from '../BaseComponents'

import './Scheduling.scss'

interface CalendarNavigationProps {
  selectedDate: Date
  onNavigationChange: (selectedDate: Date) => void
}

const CalendarNavigation: React.FC<CalendarNavigationProps> = ({
  selectedDate,
  onNavigationChange,
}) => {
  return (
    <Row>
      <Col className="calendar-nav">
        <MiniCalendar
          selectedDate={selectedDate}
          onChange={onNavigationChange}
        />
      </Col>
    </Row>
  )
}

export default CalendarNavigation
