import { useEffect, useState } from 'react'

import SkeletonLoadingTransition from '../../components/Animation/SkeletonLoadingTransition'
import { InlineEditTextarea, Skeleton } from '../BaseComponents'
import { OnSave } from '../BaseComponents/InlineEditFields/shared-types'

import styles from './Summary.module.scss'

interface Props {
  id?: string
  isLoading?: boolean
  onSave?: OnSave<string>
  testId?: string
  value?: string
}

/**
  Editable inline textarea
*/
const Summary = ({
  isLoading = false,
  testId,
  value,
  onSave,
  ...props
}: Props) => {
  const [stateValue, setStateValue] = useState<string>(value || '')

  useEffect(() => {
    if (value) {
      setStateValue(value)
    }
    return () => {
      setStateValue('')
    }
  }, [value])

  return (
    <div className={styles.container}>
      <SkeletonLoadingTransition
        isLoading={isLoading}
        skeletonComponent={
          <Skeleton
            active
            paragraph={{ rows: 2, className: styles.skeleton }}
            title={false}
          />
        }
        loadedComponent={
          <InlineEditTextarea
            {...props}
            onSave={(value) => {
              setStateValue(value.toString())
              onSave && onSave(value.toString())
            }}
            maxLength={500}
            placeholder="Add a summary..."
            requireConfirmation
            showCount
            testId={testId}
            value={stateValue}
            lineClamp
          />
        }
      />
    </div>
  )
}

export default Summary
