import React, { useEffect, useState } from 'react'

import moment from 'moment'
import 'moment-timezone'
import { Button, Col, Form, Row } from 'react-bootstrap'
import 'react-dates/initialize'
import ReactTimePicker from 'react-time-picker'

import { convertTime12to24 } from '../../../../shared/Helpers/utils'
import CustomTimePicker from '../../../../stories/BaseComponents/CustomTimePicker'
import { AutoSizingTextArea } from '../../../../stories/BaseComponents/TextAreaInput'
import { getCaretakerData } from './../../../../api/api-lib'
import { onError } from './../../../../libs/errorLib'
import ComprehensiveMedicalROS from './Sections/ComprehensiveMedicalROS'
import KetamineSpecificROS from './Sections/KetamineSpecificROS'
import MentalStatusExam from './Sections/MSE'
import PhysicalExam from './Sections/PhysicalExam'
import ShowSavedAMClinicalNote from './Tables/ShowSavedAMClinicalNote'
import ShowSavedVSClinicalNote from './Tables/ShowSavedVSClinicalNote'

import '../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function SimpleClinicalNote(props) {
  const [newVitalsTime, setNewVitalsTime] = useState(moment().format('HH:mm'))
  const [showCaretakerVitals, setShowCaretakerVitals] = useState(false)
  const [caretakerData, setCaretakerData] = useState(null)

  useEffect(() => {
    if (
      props.fields.SavedAdditionalMedications?.length ||
      !props.handleFieldChange
    )
      return

    props.handleFieldChange({
      ...props.fields,
      SavedAdditionalMedications: [],
    })
  }, [])

  function handleShowSavedVitalsClinicalNote() {
    if (!props.fields.SavedVitalSigns?.length) return null
    return (
      <>
        <Form.Row>
          <div className="patient-name" style={{ fontWeight: 'bold' }}>
            Saved Vital Signs&nbsp;
          </div>
          :
        </Form.Row>
        {props.fields.SavedVitalSigns.map((vitals, index) => {
          return (
            <ShowSavedVSClinicalNote
              key={index}
              index={index}
              deleteVitals={props.deleteVitals}
              Time={vitals.Time}
              HR={vitals.HR}
              BP={vitals.BP}
              RR={vitals.RR}
              SPO2={vitals.SPO2}
              Temperature={vitals.Temperature}
              TemperatureUnits={vitals.TemperatureUnits}
              Comments={vitals.Comments}
              ProviderInitials={vitals.ProviderInitials}
              disabled={props.disabled}
            />
          )
        })}
      </>
    )
  }

  function handleShowAdditionalMedicationClinicalNote() {
    if (!props.fields.SavedAdditionalMedications?.length) return null
    return (
      <>
        <Form.Row>
          <div className="patient-name" style={{ fontWeight: 'bold' }}>
            Saved Additional Medications&nbsp;
          </div>
          :
        </Form.Row>
        {props.fields.SavedAdditionalMedications.map((addMed, index) => {
          return (
            <ShowSavedAMClinicalNote
              key={index}
              index={index}
              deleteAM={props.deleteAM}
              AdditionalMedicationName={addMed.AdditionalMedicationName}
              AdditionalMedicationAdministrationRoute={
                addMed.AdditionalMedicationAdministrationRoute
              }
              AdditionalMedicationDosage={addMed.AdditionalMedicationDosage}
              AdditionalMedicationPatientWeight={
                addMed.AdditionalMedicationPatientWeight
              }
              AdditionalMedicationOtherAdministrationRoute={
                addMed.AdditionalMedicationOtherAdministrationRoute
              }
              disabled={props.disabled}
            />
          )
        })}
      </>
    )
  }

  function clearNewAdditionalMedicationClinicalNote() {
    props.setAddAdditionalMedication(false)
    props.fields.AdditionalMedicationName = ''
    props.fields.AdditionalMedicationAdministrationRoute = ''
    props.fields.AdditionalMedicationOtherAdministrationRoute = ''
    props.fields.AdditionalMedicationDosage = ''
    props.fields.AdditionalMedicationPatientWeight = ''
  }

  function saveNewAdditionalMedicationClinicalNote() {
    props.setAddAdditionalMedication(false)
    const oldSavedAdditionalMeds = props.fields.SavedAdditionalMedications || []
    const newAditionalMedication = [
      {
        AdditionalMedicationName: props.fields.AdditionalMedicationName,
        AdditionalMedicationAdministrationRoute:
          props.fields.AdditionalMedicationAdministrationRoute,
        AdditionalMedicationOtherAdministrationRoute:
          props.fields.AdditionalMedicationOtherAdministrationRoute,
        AdditionalMedicationDosage: props.fields.AdditionalMedicationDosage,
        AdditionalMedicationPatientWeight:
          props.fields.AdditionalMedicationPatientWeight,
      },
    ]
    props.handleFieldChange({
      ...props.fields,
      SavedAdditionalMedications: oldSavedAdditionalMeds.concat(
        newAditionalMedication
      ),
    })
    clearNewAdditionalMedicationClinicalNote()
  }

  function clearNewVitalsClinicalNote() {
    setNewVitalsTime(moment().format('HH:mm'))
    props.fields.BP = ''
    props.fields.HR = ''
    props.fields.RR = ''
    props.fields.SPO2 = ''
    props.fields.Temperature = ''
    props.fields.TemperatureUnits = ''
    props.fields.Comments = ''
    props.fields.ProviderInitials = ''
  }

  function clearNewCaretakerVitals() {
    setCaretakerData(null)
    setShowCaretakerVitals(false)
  }

  function saveNewVitalsClinicalNote() {
    props.setAddVitals(false)
    const newVitalSigns = [
      {
        Time: newVitalsTime,
        BP: props.fields.BP,
        HR: props.fields.HR,
        RR: props.fields.RR,
        SPO2: props.fields.SPO2,
        Temperature: props.fields.Temperature,
        TemperatureUnits: props.fields.TemperatureUnits,
        Comments: props.fields.Comments,
        ProviderInitials: props.fields.ProviderInitials,
      },
    ]
    if (props.fields.SavedVitalSigns) {
      props.handleFieldChange({
        ...props.fields,
        SavedVitalSigns: props.fields.SavedVitalSigns.concat(newVitalSigns),
      })
    } else {
      props.handleFieldChange({
        ...props.fields,
        SavedVitalSigns: newVitalSigns,
      })
    }
    clearNewVitalsClinicalNote()
  }

  function saveNewCaretakerVitalsClinicalNote() {
    const newVitalSigns = caretakerData.map((vital) => {
      return {
        Time: vital.Timestamp,
        BP: vital.BP,
        HR: vital.HR,
        RR: vital.RR,
        SPO2: vital.SPO2,
        Temperature: vital.Temperature,
        TemperatureUnits: vital.TemperatureUnits,
        Comments: vital.Comments,
        ProviderInitials: vital.ProviderInitials,
        BpArm: vital.BpArm,
      }
    })
    if (props.fields.SavedVitalSigns) {
      props.handleFieldChange({
        ...props.fields,
        SavedVitalSigns: props.fields.SavedVitalSigns.concat(newVitalSigns),
      })
    } else {
      props.handleFieldChange({
        ...props.fields,
        SavedVitalSigns: newVitalSigns,
      })
    }
    clearNewCaretakerVitals()
  }

  function handleVitalFieldChange(event, idx, vitalField) {
    if (vitalField === 'Timestamp') {
      const newCaretakerData = [...caretakerData]
      newCaretakerData[idx][vitalField] = event
      setCaretakerData(newCaretakerData)
    } else {
      event.persist()
      const newCaretakerData = [...caretakerData]
      newCaretakerData[idx][vitalField] = event?.target.value
      setCaretakerData(newCaretakerData)
    }
  }

  async function handleAddCaretakerVitals() {
    try {
      const caretakerData = await getCaretakerData(
        props.patient.ShortPublicId,
        props.fields.CreatedOn
      )
      const newCaretakerData = caretakerData.map((vital) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { Timestamp, UTCOffset, ...rest } = vital
        const isEmpty = Object.values(rest).every((x) => x === null || x === '')
        return {
          Timestamp: moment(vital.Timestamp, 'X')
            .utcOffset(vital.UTCOffset)
            .format('HH:mm'),
          BP:
            vital.Systolic && vital.Diastolic
              ? `${vital.Systolic}/${vital.Diastolic}`
              : '',
          HR: vital.HeartRate,
          RR: vital.RespiratoryRate,
          SPO2: vital.SPO2,
          Temperature: '',
          BpArm: '',
          TemperatureUnits: '',
          Comments: isEmpty ? 'No data sent from Caretaker at this time' : '',
          ProviderInitials: '',
        }
      })
      setCaretakerData(newCaretakerData)
      setShowCaretakerVitals(true)
      props.setAddVitals(false)
    } catch (e) {
      onError(
        e,
        e.response.status,
        `${e.response.data.error}. Please inform your administrator.`
      )
    }
  }

  return (
    <div>
      <ComprehensiveMedicalROS {...props} />
      <MentalStatusExam {...props} />
      <PhysicalExam {...props} />
      <KetamineSpecificROS {...props} />

      <Form.Group controlId="ClinicalNoteContent">
        <Form.Label style={{ fontWeight: 'bold' }}>Clinical Note</Form.Label>
        <Form.Control
          bsPrefix="form-control"
          className="field-value-edit"
          value={props.fields.ClinicalNoteContent}
          onChange={(e) =>
            props.handleFieldChange({
              ...props.fields,
              ClinicalNoteContent: e.target.value,
            })
          }
          disabled={props.disabled}
          as={AutoSizingTextArea}
          minRows={3}
        />
      </Form.Group>

      {props.fields.ReceivedTreatment && <hr />}

      {props.fields.ReceivedTreatment ? (
        <div>
          <Form.Row className="patient-name" style={{ fontWeight: 'bold' }}>
            Treatment
          </Form.Row>

          <br />
          <Form.Row>
            <Form.Group as={Col} md={12} lg={4} controlId="MedicationName">
              <Form.Label style={{ fontWeight: 'bold' }}>
                Medication name
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                type="text"
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    MedicationName: e.target.value,
                  })
                }
                value={props.fields.MedicationName}
                placeholder={props.detail ? '' : 'Ketamine, esketamine, etc.'}
                disabled={props.disabled}
              />
            </Form.Group>

            <Form.Group
              as={Col}
              md={6}
              lg={4}
              xl={4}
              controlId="Administration"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>
                Administration route
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                as="select"
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    Administration: e.target.value,
                  })
                }
                value={props.fields.Administration}
                disabled={props.disabled}
              >
                <option key={0} name="Select Administration" value="">
                  Select administration
                </option>
                {props.defaultAdministrationRoutes.map((route, index) => (
                  <option key={index + 1} name={route} value={route}>
                    {route}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {props.fields.Administration === 'Other (please specify)' ? (
              <Form.Group
                as={Col}
                md={6}
                lg={4}
                xl={4}
                controlId="OtherAdministration"
              >
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Please specify &rdquo;other&rdquo;.{' '}
                  <span className="danger">*</span>
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  required
                  type="text"
                  onChange={(e) =>
                    props.handleFieldChange({
                      ...props.fields,
                      OtherAdministration: e.target.value,
                    })
                  }
                  value={props.fields.OtherAdministration}
                  disabled={props.disabled}
                />
              </Form.Group>
            ) : (
              ''
            )}

            {props.fields.Administration === 'IV Infusion' ? (
              <Form.Group as={Col} md={6} lg={4} xl={4} controlId="IvPlacement">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  IV Placement
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  type="text"
                  onChange={(e) =>
                    props.handleFieldChange({
                      ...props.fields,
                      IvPlacement: e.target.value,
                    })
                  }
                  value={props.fields.IvPlacement}
                  disabled={props.disabled}
                />
              </Form.Group>
            ) : (
              ''
            )}
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              xs={6}
              sm={6}
              lg={6}
              xl={3}
              controlId="StartTime"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>
                Treatment Start (HH:mm)
              </Form.Label>
              <br />
              <CustomTimePicker
                value={props.fields.StartTime}
                onChange={(value) => {
                  props.handleFieldChange({ ...props.fields, StartTime: value })
                }}
                disabled={props.disabled}
              />
            </Form.Group>
            <Form.Group as={Col} xs={6} sm={6} lg={6} xl={3}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Treatment End (HH:mm)
              </Form.Label>
              <br />
              <CustomTimePicker
                value={props.fields.EndTime}
                onChange={(value) => {
                  props.handleFieldChange({ ...props.fields, EndTime: value })
                }}
                disabled={props.disabled}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm={6} md={4} lg={4} xl={3} controlId="Dosage">
              <Form.Label style={{ fontWeight: 'bold' }}>
                Total Dosage (mg)
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                type="number"
                step=".01"
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    Dosage: e.target.value,
                  })
                }
                value={props.fields.Dosage}
                disabled={props.disabled}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              sm={6}
              md={5}
              lg={4}
              xl={3}
              controlId="PatientWeight"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>
                Patient Weight (kg)
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                type="number"
                step=".01"
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    PatientWeight: e.target.value,
                  })
                }
                value={props.fields.PatientWeight}
                disabled={props.disabled}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              sm={12}
              md={3}
              lg={4}
              xl={2}
              controlId="Dosage"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>
                Dose (mg/kg)
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                type="text"
                value={
                  props.fields.PatientWeight && props.fields.Dosage
                    ? (
                        props.fields.Dosage / props.fields.PatientWeight
                      ).toFixed(2)
                    : '-'
                }
                readOnly
              />
            </Form.Group>
          </Form.Row>

          {handleShowAdditionalMedicationClinicalNote()}

          {!props.addAdditionalMedication ? (
            ''
          ) : (
            <div>
              <Form.Row className="patient-name" style={{ fontWeight: 'bold' }}>
                Add an Additional Medication
              </Form.Row>

              <br />
              <Form.Row>
                <Form.Group
                  as={Col}
                  md={6}
                  lg={4}
                  controlId="AdditionalMedicationName"
                >
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Medication name
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    type="text"
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        AdditionalMedicationName: e.target.value,
                      })
                    }
                    value={props.fields.AdditionalMedicationName}
                    placeholder={
                      props.detail ? '' : 'Ketamine, esketamine, etc.'
                    }
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md={6}
                  lg={4}
                  controlId="AdditionalMedicationAdministrationRoute"
                >
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Administration route
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    as="select"
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        AdditionalMedicationAdministrationRoute: e.target.value,
                      })
                    }
                    value={props.fields.AdditionalMedicationAdministrationRoute}
                  >
                    <option key={0} name="Select Administration" value="">
                      Select administration
                    </option>
                    {props.defaultAdministrationRoutes.map((route, index) => (
                      <option key={index + 1} name={route} value={route}>
                        {route}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {props.fields.AdditionalMedicationAdministrationRoute ===
                'Other (please specify)' ? (
                  <Form.Group
                    as={Col}
                    md={12}
                    lg={4}
                    controlId="AdditionalMedicationOtherAdministrationRoute"
                  >
                    <Form.Label style={{ fontWeight: 'bold' }}>
                      Please specify &rdquo;other&rdquo;{' '}
                      <span className="danger">*</span>
                    </Form.Label>
                    <Form.Control
                      bsPrefix="form-input"
                      required
                      type="text"
                      onChange={(e) =>
                        props.handleFieldChange({
                          ...props.fields,
                          AdditionalMedicationOtherAdministrationRoute:
                            e.target.value,
                        })
                      }
                      value={
                        props.fields
                          .AdditionalMedicationOtherAdministrationRoute
                      }
                    />
                  </Form.Group>
                ) : (
                  ''
                )}

                {props.fields.AdditionalMedicationAdministrationRoute ===
                'IV Infusion' ? (
                  <Form.Group
                    as={Col}
                    md={12}
                    lg={4}
                    controlId="AdditionalMedicationIvPlacement"
                  >
                    <Form.Label style={{ fontWeight: 'bold' }}>
                      IV Placement
                    </Form.Label>
                    <Form.Control
                      bsPrefix="form-input"
                      type="text"
                      onChange={(e) =>
                        props.handleFieldChange({
                          ...props.fields,
                          AdditionalMedicationIvPlacement: e.target.value,
                        })
                      }
                      value={props.fields.AdditionalMedicationIvPlacement}
                    />
                  </Form.Group>
                ) : (
                  ''
                )}
              </Form.Row>
              <Form.Row>
                <Form.Group
                  as={Col}
                  sm={12}
                  lg={4}
                  controlId="AdditionalMedicationDosage"
                >
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Total Dosage (mg)
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    type="number"
                    step=".01"
                    onWheel={(event) => event.currentTarget.blur()}
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        AdditionalMedicationDosage: e.target.value,
                      })
                    }
                    value={props.fields.AdditionalMedicationDosage}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  sm={12}
                  lg={4}
                  controlId="AdditionalMedicationPatientWeight"
                >
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Patient Weight (kg)
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    type="number"
                    step=".01"
                    onWheel={(event) => event.currentTarget.blur()}
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        AdditionalMedicationPatientWeight: e.target.value,
                      })
                    }
                    value={props.fields.AdditionalMedicationPatientWeight}
                  />
                </Form.Group>
                <Form.Group as={Col} sm={12} lg={4}>
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Dose (mg/kg)
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    type="text"
                    value={
                      props.fields.AdditionalMedicationPatientWeight &&
                      props.fields.AdditionalMedicationDosage
                        ? (
                            props.fields.AdditionalMedicationDosage /
                            props.fields.AdditionalMedicationPatientWeight
                          ).toFixed(2)
                        : '-'
                    }
                    readOnly
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group
                  as={Col}
                  sm={12}
                  md={4}
                  lg={4}
                  style={{
                    textAlign: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Button
                    variant="outline-danger"
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginBottom: '10px',
                    }}
                    onClick={() => clearNewAdditionalMedicationClinicalNote()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outline-primary"
                    style={{
                      marginLeft: '15px',
                      marginRight: 'auto',
                      marginBottom: '10px',
                    }}
                    onClick={() => saveNewAdditionalMedicationClinicalNote()}
                  >
                    Save additional medication
                  </Button>
                </Form.Group>
              </Form.Row>
            </div>
          )}
          {props.disabled ? (
            ''
          ) : props.addAdditionalMedication ? (
            ''
          ) : (
            <Row>
              <Form.Group
                as={Col}
                sm={12}
                lg={12}
                style={{
                  textAlign: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Button
                  variant="outline-primary"
                  style={{
                    marginLeft: '15px',
                    marginRight: '10px',
                    marginBottom: '10px',
                  }}
                  onClick={() => {
                    props.setAddAdditionalMedication(true)
                  }}
                >
                  + Add Additional Medication
                </Button>
              </Form.Group>
            </Row>
          )}

          {handleShowSavedVitalsClinicalNote()}

          {!props.addVitals ? (
            ''
          ) : (
            <div>
              <Form.Row className="patient-name">Input Vital Signs</Form.Row>
              <br />
              <Form.Row>
                <Form.Group as={Col} sm={6} md={2} lg={2} controlId="HR">
                  <Form.Label style={{ fontWeight: 'bold' }}>HR</Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    type="text"
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        HR: e.target.value,
                      })
                    }
                    value={props.fields.HR}
                  />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={2} lg={2} controlId="RR">
                  <Form.Label style={{ fontWeight: 'bold' }}>RR</Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    type="text"
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        RR: e.target.value,
                      })
                    }
                    value={props.fields.RR}
                  />
                </Form.Group>
                <Form.Group as={Col} sm={5} md={3} lg={2} controlId="BP">
                  <Form.Label style={{ fontWeight: 'bold' }}>BP</Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    type="text"
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        BP: e.target.value,
                      })
                    }
                    value={props.fields.BP}
                  />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={5} lg={4} controlId="BpArm">
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    BP Monitor Placement
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    as="select"
                    disabled={props.disabled}
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        BpArm: e.target.value,
                      })
                    }
                    value={props.fields.BpArm}
                  >
                    <option>N/A</option>
                    <option>Left Arm</option>
                    <option>Right Arm</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} sm={3} md={4} lg={2} controlId="SPO2">
                  <Form.Label style={{ fontWeight: 'bold' }}>SPO2</Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    type="text"
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        SPO2: e.target.value,
                      })
                    }
                    value={props.fields.SPO2}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  sm={4}
                  md={3}
                  lg={3}
                  controlId="Temperature"
                >
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Temperature
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    type="number"
                    step=".01"
                    onWheel={(event) => event.currentTarget.blur()}
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        Temperature: e.target.value,
                      })
                    }
                    value={props.fields.Temperature}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  sm={5}
                  md={5}
                  lg={3}
                  controlId="TemperatureUnits"
                >
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Temperature Units
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    as="select"
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        TemperatureUnits: e.target.value,
                      })
                    }
                    value={props.fields.TemperatureUnits}
                  >
                    <option key={0} name="Select Units" value="">
                      Select Units
                    </option>
                    {['Fahrenheit', 'Celcius'].map((units, index) => (
                      <option key={index + 1} name={units} value={units}>
                        {units}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} sm={6} md={6} lg={3}>
                  <Form.Label style={{ fontWeight: 'bold' }}>Time</Form.Label>
                  <br />
                  <CustomTimePicker
                    value={newVitalsTime}
                    onChange={(value) => {
                      setNewVitalsTime(convertTime12to24(value))
                    }}
                    disabled={props.disabled}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm={6}
                  md={6}
                  lg={3}
                  controlId="ProviderInitials"
                >
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Provider Initials
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    type="text"
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        ProviderInitials: e.target.value,
                      })
                    }
                    value={props.fields.ProviderInitials}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={12} lg={12} controlId="Comments">
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Comments
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    value={props.fields.Comments}
                    onChange={(e) =>
                      props.handleFieldChange({
                        ...props.fields,
                        Comments: e.target.value,
                      })
                    }
                    as="textarea"
                    rows="3"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group
                  as={Col}
                  sm={12}
                  md={4}
                  lg={4}
                  style={{
                    textAlign: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Button
                    variant="outline-danger"
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginBottom: '10px',
                    }}
                    onClick={() => clearNewVitalsClinicalNote()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outline-primary"
                    style={{
                      marginLeft: '15px',
                      marginRight: 'auto',
                      marginBottom: '10px',
                    }}
                    onClick={() => saveNewVitalsClinicalNote()}
                  >
                    Save Vitals
                  </Button>
                </Form.Group>
              </Form.Row>
            </div>
          )}

          {caretakerData && showCaretakerVitals && (
            <>
              <Form.Row className="patient-name">
                Add Caretaker Vital Signs
              </Form.Row>
              {caretakerData?.map((vital, idx) => {
                return (
                  <div key={idx}>
                    <Form.Row>
                      <Form.Group as={Col} sm={12} lg={12}>
                        <span className="patient-name">{idx + 1}&nbsp;</span> :
                      </Form.Group>
                      <Form.Group as={Col} sm={6} md={2} lg={2} controlId="HR">
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          HR
                        </Form.Label>
                        <Form.Control
                          bsPrefix="form-input"
                          type="text"
                          onChange={(event) => {
                            handleVitalFieldChange(event, idx, 'HR')
                          }}
                          value={vital.HR}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={6} md={2} lg={2} controlId="RR">
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          RR
                        </Form.Label>
                        <Form.Control
                          bsPrefix="form-input"
                          type="text"
                          onChange={(event) => {
                            handleVitalFieldChange(event, idx, 'RR')
                          }}
                          value={vital.RR}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={5} md={3} lg={2} controlId="BP">
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          BP
                        </Form.Label>
                        <Form.Control
                          bsPrefix="form-input"
                          type="text"
                          onChange={(event) => {
                            handleVitalFieldChange(event, idx, 'BP')
                          }}
                          value={vital.BP}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={6}
                        md={5}
                        lg={4}
                        controlId="BpArm"
                      >
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          BP Monitor Placement
                        </Form.Label>
                        <Form.Control
                          bsPrefix="form-input"
                          as="select"
                          disabled={props.disabled}
                          onChange={(event) => {
                            handleVitalFieldChange(event, idx, 'BpArm')
                          }}
                          value={vital.BpArm}
                        >
                          <option>N/A</option>
                          <option>Left Arm</option>
                          <option>Right Arm</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={3}
                        md={4}
                        lg={2}
                        controlId="SPO2"
                      >
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          SPO2
                        </Form.Label>
                        <Form.Control
                          bsPrefix="form-input"
                          type="text"
                          onChange={(event) => {
                            handleVitalFieldChange(event, idx, 'SPO2')
                          }}
                          value={vital.SPO2}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={4}
                        md={3}
                        lg={3}
                        controlId="Temperature"
                      >
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          Temperature
                        </Form.Label>
                        <Form.Control
                          bsPrefix="form-input"
                          type="number"
                          step=".01"
                          onWheel={(event) => event.currentTarget.blur()}
                          onChange={(event) => {
                            handleVitalFieldChange(event, idx, 'Temperature')
                          }}
                          value={vital.Temperature}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={5}
                        md={5}
                        lg={3}
                        controlId="TemperatureUnits"
                      >
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          Temperature Units
                        </Form.Label>
                        <Form.Control
                          bsPrefix="form-input"
                          as="select"
                          onChange={(event) => {
                            handleVitalFieldChange(
                              event,
                              idx,
                              'TemperatureUnits'
                            )
                          }}
                          value={vital.TemperatureUnits}
                        >
                          <option key={0} name="Select Units" value="">
                            Select Units
                          </option>
                          {['Fahrenheit', 'Celcius'].map((units, index) => (
                            <option key={index + 1} name={units} value={units}>
                              {units}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} sm={6} md={6} lg={3}>
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          Time
                        </Form.Label>
                        <br />
                        <ReactTimePicker
                          value={vital.Timestamp}
                          format="hh:mm a"
                          disableClock
                          onChange={(event) => {
                            handleVitalFieldChange(event, idx, 'Timestamp')
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        sm={6}
                        md={6}
                        lg={3}
                        controlId="ProviderInitials"
                      >
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          Provider Initials
                        </Form.Label>
                        <Form.Control
                          bsPrefix="form-input"
                          type="text"
                          onChange={(event) => {
                            handleVitalFieldChange(
                              event,
                              idx,
                              'ProviderInitials'
                            )
                          }}
                          value={vital.ProviderInitials}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={12} lg={12} controlId="Comments">
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          Comments
                        </Form.Label>
                        <Form.Control
                          bsPrefix="form-input"
                          value={vital.Comments}
                          onChange={(event) => {
                            handleVitalFieldChange(event, idx, 'Comments')
                          }}
                          as="textarea"
                          rows="3"
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>
                )
              })}
              <Form.Row>
                <Form.Group
                  as={Col}
                  sm={12}
                  md={4}
                  lg={4}
                  style={{
                    textAlign: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Button
                    variant="outline-danger"
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginBottom: '10px',
                    }}
                    onClick={() => clearNewCaretakerVitals()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outline-primary"
                    style={{
                      marginLeft: '15px',
                      marginRight: 'auto',
                      marginBottom: '10px',
                    }}
                    onClick={() => saveNewCaretakerVitalsClinicalNote()}
                  >
                    Save Vitals
                  </Button>
                </Form.Group>
              </Form.Row>
            </>
          )}

          {!props.addVitals ? '' : <hr className="style-one" />}

          {!props.disabled && !props.addVitals && !showCaretakerVitals && (
            <Row>
              <Form.Group
                as={Col}
                sm={12}
                lg={12}
                style={{
                  textAlign: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Button
                  variant="outline-primary"
                  style={{
                    marginLeft: 'auto',
                    marginRight: '10px',
                    marginBottom: '10px',
                  }}
                  onClick={() => {
                    props.setAddVitals(true)
                  }}
                >
                  + Add Vitals
                </Button>
                <Button
                  variant="outline-primary"
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '10px',
                  }}
                  onClick={() => {
                    handleAddCaretakerVitals()
                  }}
                >
                  + Add Caretaker Vitals
                </Button>
              </Form.Group>
            </Row>
          )}

          <Form.Group controlId="IsTreatmentConcurrentPsychotherapy">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Did {props.patient.PatientName} receive psychotherapy?
            </Form.Label>
            <Form.Check
              bsPrefix="form-check"
              checked={props.fields.IsTreatmentConcurrentPsychotherapy}
              value="true"
              id="IsTreatmentConcurrentPsychotherapyYes"
              onChange={() =>
                props.handleFieldChange({
                  ...props.fields,
                  IsTreatmentConcurrentPsychotherapy: true,
                })
              }
              type="radio"
              label="Yes"
              disabled={props.disabled}
            />
            <Form.Check
              bsPrefix="form-check"
              checked={!props.fields.IsTreatmentConcurrentPsychotherapy}
              value="false"
              id="IsTreatmentConcurrentPsychotherapyNo"
              onChange={() =>
                props.handleFieldChange({
                  ...props.fields,
                  IsTreatmentConcurrentPsychotherapy: false,
                })
              }
              type="radio"
              label="No"
              disabled={props.disabled}
            />
          </Form.Group>
          {props.fields.IsTreatmentConcurrentPsychotherapy && (
            <Form.Group controlId="PsychotherapyNotes">
              <Form.Label style={{ fontWeight: 'bold' }}>
                Psychotherapy Notes
              </Form.Label>
              <Form.Control
                bsPrefix="form-control"
                className="field-value-edit"
                value={props.fields.PsychotherapyNotes}
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    PsychotherapyNotes: e.target.value,
                  })
                }
                disabled={props.disabled}
                as={AutoSizingTextArea}
                minRows={3}
              />
            </Form.Group>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
