import { ReportType } from '../types'
import AppointmentsConstructor from './AppointmentsConstructor'
import NotesConstructor from './NotesConstructor'
import SurveysConstructor from './SurveysConstructor'

export default {
  [ReportType.APPOINTMENTS]: AppointmentsConstructor,
  [ReportType.NOTES]: NotesConstructor,
  [ReportType.SURVEYS]: SurveysConstructor,
}
