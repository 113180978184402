import React from 'react'

import { RedoOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import { FixedType } from 'rc-table/lib/interface'

import { DELETE_COLOR } from '../../libs/constants'
import Button from '../BaseComponents/Button'
import Card from '../BaseComponents/Card'
import Table from '../BaseComponents/Table'
import { DiagnosesTableData } from '../BaseComponents/tableData.types'
import { InvoiceComponentProps } from './constants'

import styles from './Invoice.module.scss'

export type Diagnosis = {
  id?: number | string
  order: number
  code: string
  description?: string
  isDeleted: boolean
  status: string
}

type DiagnosesProps = {
  diagnoses: Diagnosis[]
  onDelete: (dxCode: string) => void
  onReset: () => void
  isClaim?: boolean
  disabled?: boolean
} & InvoiceComponentProps

export const Diagnoses = ({
  isLoading,
  isEditing,
  diagnoses = [],
  onDelete,
  onReset,
  isClaim = false,
  disabled = false,
}: DiagnosesProps) => {
  const columns: ColumnsType<DiagnosesTableData> = [
    {
      dataIndex: 'displayOrder',
      key: 'displayOrder',
      title: 'Diagnosis #',
      fixed: 'left' as FixedType,
      width: 100,
    },
    {
      dataIndex: 'code',
      key: 'code',
      title: 'ICD code',
      width: 150,
    },
    {
      dataIndex: 'description',
      key: 'description',
      title: 'Diagnosis',
      width: 250,
    },
  ]

  if (isEditing) {
    columns.push({
      title: '',
      key: 'action',
      fixed: 'right' as FixedType,
      width: 150,
      render: (data: { code: string }) => (
        <Button
          type="link"
          className="delete-btn"
          style={{ color: DELETE_COLOR }}
          onClick={() => onDelete(data.code)}
          disabled={disabled}
        >
          {!isClaim ? 'Delete' : 'Exclude from claim'}
        </Button>
      ),
    })
  }

  const dataSource: DiagnosesTableData[] = diagnoses.map((d) => ({
    ...d,
    displayOrder: d.order + 1,
    key: d.order,
  }))

  return (
    <Card
      loading={isLoading}
      testId="invoice-diagnoses-card"
      style={{ borderColor: isClaim ? 'white' : undefined }}
    >
      <div
        className={styles.sectionHeader}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: 0,
        }}
      >
        <div className={styles.boldSubTitle}>Diagnoses</div>
        {isEditing && (
          <Button disabled={disabled} type="link" onClick={onReset}>
            Refresh patient diagnoses <RedoOutlined />
          </Button>
        )}
      </div>
      <div style={{ marginBottom: 24 }}>
        {!isClaim
          ? `Diagnoses are only visible upon Superbill generation.`
          : null}
      </div>

      <Table
        size="small"
        scroll={{ x: isEditing ? 650 : 500 }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        addClassNames={[
          'no-padding',
          'no-table-tools-section',
          'container-override',
          styles.tableBorder,
        ]}
      />
    </Card>
  )
}
