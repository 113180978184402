import { format, isValid, parseISO } from 'date-fns'

import {
  FormSectionKey,
  IntakeFormResponseObject,
} from '../../../api/intakeForms'
import { normalizedAndFormatDate } from '../../../hooks/usePatientProfile/utils'
import {
  INTAKE_REVIEW_FORM,
  INTAKE_REVIEW_FORM_EDITOR_STYLE,
  INTAKE_REVIEW_FORM_ITEM_TYPE,
  INTAKE_REVIEW_FORM_MAP,
  INTAKE_REVIEW_FORM_V2,
  IntakeReviewFormSection,
} from '../sections/helpers'
import { PsychiatricHistoryQuestionKeys } from '../sections/question-keys'
import { createBulletListNode, createNode } from './createV2Node'
import { getEmptyStateOrAnswer } from './empty-state-or-default'
import {
  addressObjectToString,
  booleanToString,
  isBulletList,
  isEmptyValue,
  isFollowedByDoubleSpace,
  isMedItem,
  isNestedSubformItem,
  isPrecededByDoubleSpace,
  isQuestionToIgnore,
  isSubformItem,
  isViewableAddress,
  outputBulletList,
  outputMedicationList,
  outputSubSectionList,
  updateBulletList,
  updateMedicationList,
  updateSubSectionList,
} from './helpers'

/**
 * Main function that converts an array of intake form responses to a big string for the EvalNote
 */
export function convertResponsesToEvalNote(
  intakeResponses: IntakeFormResponseObject[],
  sentFormSections: string[]
): string {
  try {
    const intakeReponsesMap = intakeResponses.reduce<{
      [questionKey: string]: unknown
    }>(
      (mappedResponses, { questionKey, answer }) => ({
        ...mappedResponses,
        [questionKey]: answer,
      }),
      {}
    )

    // Ignores insurance section
    const sectionsToDisplay = sentFormSections.filter(
      (section) => section !== FormSectionKey.INSURANCE
    )

    // Convert intake responses into an evaluation note
    return INTAKE_REVIEW_FORM.reduce<string>(
      (evaluationNoteText, { name, title, type, section }) => {
        // Set new form section header only if the section was sent with form
        if (
          type === INTAKE_REVIEW_FORM_ITEM_TYPE.section &&
          sectionsToDisplay.includes(name)
        ) {
          const sectionSpace =
            name === FormSectionKey.GENERAL_INFORMATION
              ? ''
              : // Removes unnecessary spacing
              evaluationNoteText.substring(evaluationNoteText.length - 2) !==
                '\n\n'
              ? '\n\n'
              : '\n'
          evaluationNoteText += `${sectionSpace}${title.toUpperCase()}\n\n`
          return evaluationNoteText
        }

        // Skip displaying answer at all if it wasn't from a section sent
        if (!sectionsToDisplay.includes(section ?? '')) {
          return evaluationNoteText
        }

        // Early return if question should be ignored
        if (isQuestionToIgnore(name)) {
          return evaluationNoteText
        }

        const answer = intakeReponsesMap[name]

        // Address objects are prioritized but not in the INTAKE_REVIEW_FORM_MAP
        if (isViewableAddress(name)) {
          evaluationNoteText += addressObjectToString(answer)
        }

        const questionLabel = INTAKE_REVIEW_FORM_MAP[name]?.title

        if (!questionLabel) {
          // Warn when the INTAKE_REVIEW_FORM array doesn't have a question title for a question and don't display the answer for it
          console.warn(
            `INTAKE_REVIEW_FORM array is missing question title for ${name}`
          )
          return evaluationNoteText
        }

        // if response item needs spacing before it and doesn't have it already
        if (
          isPrecededByDoubleSpace(name) &&
          evaluationNoteText.substring(evaluationNoteText.length - 2) !== '\n\n'
        ) {
          evaluationNoteText += '\n'
        }

        // Display field even if no answer provided but make sure its not a nested subsection item, a med list or generic subsection
        if (
          isEmptyValue(answer) &&
          !isNestedSubformItem(name) &&
          !isMedItem(name) &&
          !isSubformItem(name)
        ) {
          evaluationNoteText += `${questionLabel}:\n`
        }

        if (typeof answer === 'boolean') {
          // Booleans to 'Yes' or 'No'
          evaluationNoteText += `${questionLabel}: ${booleanToString(answer)}\n`
        } else if (isBulletList(name)) {
          // Arrays that should be bulleted list
          evaluationNoteText += `${outputBulletList(questionLabel, answer)}\n`
        } else if (isSubformItem(name)) {
          // Nested subform items to string
          evaluationNoteText += `${outputSubSectionList(
            questionLabel,
            answer,
            name
          )}`
        } else if (isMedItem(name)) {
          // Meds array
          evaluationNoteText += `${outputMedicationList(answer, name)}`
        } else if (typeof answer === 'string' && isValid(parseISO(answer))) {
          // Dates
          const outputDateString = answer.includes('T00:00:00.000Z')
            ? normalizedAndFormatDate(new Date(answer).toISOString(), 'P')
            : format(parseISO(answer), 'P')
          evaluationNoteText += `${questionLabel}: ${outputDateString}\n`
        } else if (typeof answer === 'string' || typeof answer === 'number') {
          evaluationNoteText += `${questionLabel}: ${answer ?? ''}\n`
        } else if (Array.isArray(answer)) {
          evaluationNoteText += `${questionLabel}: ${
            answer.length ? answer.join(', ') : ''
          }\n`
        }

        if (isFollowedByDoubleSpace(name)) {
          // if response item needs spacing after it
          evaluationNoteText += '\n'
        }

        return evaluationNoteText
      },
      ''
    )
  } catch (err) {
    console.error('Failed to generate evaluation note with err:', err)
    return ''
  }
}

const parseFormSection = (
  contents: Object[],
  sectionsToDisplay: string[],
  intakeResponsesMap: IntakeResponseMapItem,
  name: string,
  title: string,
  type?: INTAKE_REVIEW_FORM_ITEM_TYPE,
  section?: FormSectionKey,
  style?: INTAKE_REVIEW_FORM_EDITOR_STYLE,
  relatedQuestion?: IntakeReviewFormSection[]
): Object[] => {
  // Set new form section header only if the section was sent with form
  if (
    type === INTAKE_REVIEW_FORM_ITEM_TYPE.section &&
    sectionsToDisplay.includes(name)
  ) {
    createNode(contents, `${title}`, style)
    return contents
  }

  // Skip displaying answer at all if it wasn't from a section sent
  if (!sectionsToDisplay.includes(section ?? '')) {
    return contents
  }

  // Early return if question should be ignored
  if (isQuestionToIgnore(name)) {
    return contents
  }

  const answer = intakeResponsesMap[name]
  const questionLabel = INTAKE_REVIEW_FORM_MAP[name]?.title

  if (!questionLabel) {
    console.warn(
      `INTAKE_REVIEW_FORM array is missing question title for ${name}`
    )
    return contents
  }

  // Display field even if no answer provided but make sure it's not a nested subsection item, a med list or generic subsection
  if (
    isEmptyValue(answer) && // this is what's causing empty answers to get rendered as "<title>: "
    !relatedQuestion && // but if we have dependency questions, process in other sections
    !isNestedSubformItem(name) &&
    !isMedItem(name) &&
    !isSubformItem(name) &&
    !isBulletList(name)
  ) {
    createNode(contents, `${questionLabel}:\n`, style)
  }

  if (typeof answer === 'boolean') {
    const answerText = getEmptyStateOrAnswer(name, answer)
    createNode(contents, `${questionLabel}: ${answerText}\n`, style)

    // conditionally render the questions that have a subsection
    if (relatedQuestion && relatedQuestion.length > 0 && answer) {
      contents.push(
        ...relatedQuestion.reduce<Object[]>(
          (subContent, { name, title, type, section, style }) => {
            return parseFormSection(
              subContent,
              sectionsToDisplay,
              intakeResponsesMap,
              name,
              title,
              type,
              section,
              style,
              undefined // don't support deeper nests
            )
          },
          []
        )
      )
    }
  } else if (isBulletList(name)) {
    // Arrays that should be bulleted list
    createNode(contents, `${title}`, style, 'bullet-list-header')
    createBulletListNode(contents, updateBulletList(questionLabel, answer))
  } else if (isSubformItem(name)) {
    if (
      name === PsychiatricHistoryQuestionKeys.HOSPITALIZATIONS &&
      !intakeResponsesMap[PsychiatricHistoryQuestionKeys.HOSPITALIZATIONS]
    ) {
      createNode(
        contents,
        `${title}: ${'No previous psychiatric hospitalizations'}`,
        INTAKE_REVIEW_FORM_EDITOR_STYLE.block
      )
      return contents
    }
    // create section header
    createNode(contents, `${title}`, style)
    // Nested subform items
    createBulletListNode(
      contents,
      updateSubSectionList(questionLabel, answer, name)
    )
  } else if (isMedItem(name)) {
    createBulletListNode(
      contents,
      updateMedicationList(answer, name),
      'intake-medications'
    )
  } else if (typeof answer === 'string' && isValid(parseISO(answer))) {
    // Dates
    const outputDateString = answer.includes('T00:00:00.000Z')
      ? normalizedAndFormatDate(new Date(answer).toISOString(), 'P')
      : format(parseISO(answer), 'P')
    createNode(contents, `${questionLabel}: ${outputDateString}\n`, style)
  } else if (typeof answer === 'string' || typeof answer === 'number') {
    createNode(contents, `${questionLabel}: ${answer ?? ''}\n`, style)
  } else if (Array.isArray(answer)) {
    createNode(
      contents,
      `${questionLabel}: ${answer.length ? answer.join(', ') : ''}\n`,
      style
    )
  }
  return contents
}

type IntakeResponseMapItem = { [questionKey: string]: unknown }

/**
 * Main function that converts an array of intake form responses to content arrays for v2 note
 */
export function convertResponsesToV2Note(
  intakeResponses: IntakeFormResponseObject[],
  sentFormSections: string[]
): Object[] {
  try {
    const intakeReponsesMap = intakeResponses.reduce<{
      [questionKey: string]: unknown
    }>(
      (mappedResponses, { questionKey, answer }) => ({
        ...mappedResponses,
        [questionKey]: answer,
      }),
      {}
    )

    // Ignores general, other, and insurance sections from intake all the time.
    const sectionsToDisplay = sentFormSections.filter(
      (section) =>
        section !== FormSectionKey.INSURANCE &&
        section !== FormSectionKey.GENERAL_INFORMATION &&
        section !== FormSectionKey.OTHER_INFORMATION
    )
    return INTAKE_REVIEW_FORM_V2.reduce<Object[]>(
      (contents, { name, title, type, section, style, relatedQuestions }) => {
        return parseFormSection(
          contents,
          sectionsToDisplay,
          intakeReponsesMap,
          name,
          title,
          type,
          section,
          style,
          relatedQuestions
        )
      },
      []
    )
  } catch (err) {
    console.error('Failed to generate evaluation note with err:', err)
    return []
  }
}
