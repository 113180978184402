import React, { useCallback, useEffect, useMemo } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { datadogRum } from '@datadog/browser-rum'
import { Form, FormikErrors, FormikHandlers, FormikTouched } from 'formik'
import { sumBy } from 'lodash'

import {
  calculateClaimAndPatientOutstanding,
  calculatedOutstandingBalanceBillableEntity,
  convertCentsToDollars,
  convertCentsToDollarsNumber,
  convertDollarsToCents,
  formatNumberStringToUsdString,
  getDisplayNameForPaymentMethodType,
  isExpiredCard,
} from '../../../../libs/billing'
import {
  MAXIMUM_MEMO_LENGTH,
  PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS,
  PAYMENT_FORM_OPTIONS,
  PAYMENT_METHOD_TYPES,
  PAYMENT_TYPE,
  STRIPE_ACCOUNT_STATUSES,
} from '../../../../libs/constants'
import { onError } from '../../../../libs/errorLib'
import { notification } from '../../../../libs/notificationLib'
import {
  InsuranceClaim,
  Invoice,
  PaymentAttempt,
  PaymentMethod,
} from '../../../../shared-types'
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  CollapsePanel,
  Divider,
  Option,
  Radio,
  Select,
  Tag,
  TextArea,
  Tooltip,
} from '../../../../stories/BaseComponents'
import { USDInput } from '../../../../stories/BaseComponents/EditableInputs'
import { getPaymentMethodDisplayContent } from '../Billing'
import {
  CreditUsageByPaymentMethod,
  getDefaultCreditUsageByPaymentMethod,
} from '../payment-attribution'
import { PatientCreditInputs } from './PatientCreditInputs'
import { ClaimOption, ErrorSpan, InvoiceOption } from './Utilities'

import styles from '../Billing.module.scss'

export type PaymentFormValues = {
  paymentType: PAYMENT_TYPE | null
  paymentOption: PAYMENT_FORM_OPTIONS
  paymentMethodType: PAYMENT_METHOD_TYPES
  billableEntitySubOption: PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS
  paymentMethod: PaymentMethod | null
  invoiceUuid: string | null
  claimUuid: string | null
  selectedInvoice?: Invoice
  selectedClaim?: InsuranceClaim
  customAmount: number
  patientCreditAmount: number
  memo: string
  creditUsage: CreditUsageByPaymentMethod
  stripeCreditCard: PaymentMethod | null
  isApplyingCredit: boolean
  totalAppliedCredit: number
  totalChargeAmountCents: number
}

type AddPaymentFormProps = {
  invoices: Invoice[]
  stripeCreditCards: PaymentMethod[]
  preSelectedInvoiceUuid?: string | null
  preSelectedClaimUuid?: string | null
  payments: PaymentAttempt[]
  stripeAccountStatus: STRIPE_ACCOUNT_STATUSES
  isLoading: boolean
  paymentMethods: PaymentMethod[]
  paymentIntentSecret?: string | null
  stripePaymentsEnabled: boolean
  totalPatientCreditAmountCents: number
  claims: InsuranceClaim[]
  showAddCreditCardModal: () => void

  values: PaymentFormValues
  touched: FormikTouched<PaymentFormValues>
  errors: FormikErrors<PaymentFormValues>
  setTouched: (input: FormikTouched<PaymentFormValues>) => void
  setFieldValue: (field: string, value: unknown) => void
  handleChange: FormikHandlers['handleChange']
  handleBlur: FormikHandlers['handleBlur']
  validateForm: () => Promise<FormikErrors<PaymentFormValues>>
}

const NEW_CARD = 'newCard'

const customAmountDescription = (
  <div className={styles.customAmountDescription}>
    <span>Custom amount</span>
  </div>
)

export const AddPaymentForm = ({
  invoices,
  claims,
  stripeCreditCards,
  preSelectedInvoiceUuid,
  preSelectedClaimUuid,
  payments,
  stripeAccountStatus,
  isLoading,
  paymentMethods,
  paymentIntentSecret,
  stripePaymentsEnabled,
  totalPatientCreditAmountCents,
  showAddCreditCardModal,

  values,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
  errors,
  validateForm,
  setTouched,
}: AddPaymentFormProps) => {
  const outstandingBalanceInvoices = useMemo(
    () => calculatedOutstandingBalanceBillableEntity(invoices),
    [invoices]
  )
  const outstandingBalanceClaims = useMemo(
    () => calculatedOutstandingBalanceBillableEntity(claims ?? []),
    [claims]
  )

  useEffect(() => {
    if (preSelectedClaimUuid) {
      setFieldValue('claimUuid', preSelectedClaimUuid)
    }

    if (preSelectedInvoiceUuid) {
      setFieldValue('paymentOption', PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL)
    }
  }, [preSelectedInvoiceUuid, preSelectedClaimUuid])

  const selectedClaimPatientOutstanding = useMemo(() => {
    if (values.selectedClaim) {
      return (
        calculateClaimAndPatientOutstanding(values.selectedClaim)
          ?.patientOutstanding || 0
      )
    } else {
      return 0
    }
  }, [values.selectedClaim, claims])

  useEffect(() => {
    if (preSelectedClaimUuid || outstandingBalanceClaims <= 0) {
      setFieldValue('paymentOption', PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL)
      if (selectedClaimPatientOutstanding <= 0) {
        setFieldValue('customAmount', 100)
      }
    }
  }, [
    preSelectedClaimUuid,
    selectedClaimPatientOutstanding,
    outstandingBalanceClaims,
  ])

  const shouldApplyPatientCredit =
    values.paymentOption !== PAYMENT_FORM_OPTIONS.ADD_PATIENT_CREDIT &&
    values.isApplyingCredit

  useEffect(() => {
    setFieldValue(
      'creditUsage',
      getDefaultCreditUsageByPaymentMethod(
        paymentMethods,
        payments,
        shouldApplyPatientCredit ? values.totalChargeAmountCents : 0
      )
    )
  }, [
    paymentMethods,
    payments,
    values.totalChargeAmountCents,
    shouldApplyPatientCredit,
  ])

  useEffect(() => {
    // This validation is used to solve an issue with formik that was not rendering errors in time.
    validateForm()
  }, [errors.totalAppliedCredit, errors.paymentMethod])

  const isApplyingCreditDisabled =
    totalPatientCreditAmountCents === 0 ||
    values.paymentOption === PAYMENT_FORM_OPTIONS.ADD_PATIENT_CREDIT

  useEffect(() => {
    if (
      (!preSelectedClaimUuid && values.paymentType === PAYMENT_TYPE.CLAIM) ||
      (!preSelectedInvoiceUuid && values.paymentType === PAYMENT_TYPE.INVOICE)
    ) {
      if (
        (values.paymentType === PAYMENT_TYPE.CLAIM &&
          outstandingBalanceClaims > 0) ||
        (values.paymentType === PAYMENT_TYPE.INVOICE &&
          outstandingBalanceInvoices > 0)
      ) {
        setFieldValue(
          'paymentOption',
          PAYMENT_FORM_OPTIONS.PAY_OUTSTANDING_BALANCE
        )
      } else if (values.paymentType === PAYMENT_TYPE.CLAIM) {
        setFieldValue('selectedClaim', claims[0])
        setFieldValue('paymentOption', PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL)
        setFieldValue(
          'customAmount',
          calculateClaimAndPatientOutstanding(claims[0]).patientOutstanding ||
            100
        )
      } else if (invoices.length === 0 && claims.length === 0) {
        setFieldValue('paymentOption', PAYMENT_FORM_OPTIONS.ADD_PATIENT_CREDIT)
      } else {
        setFieldValue('customAmount', invoices[0]?.amountCentsDue)
        setFieldValue('selectedInvoice', invoices[0])
      }
    }
  }, [values.paymentType])

  useEffect(() => {
    if (!paymentMethods) return
    if (!stripePaymentsEnabled) {
      setFieldValue('paymentMethodType', PAYMENT_METHOD_TYPES.CASH)
      const cashPaymentMethod = paymentMethods.find(
        (pm: PaymentMethod) => pm.type === PAYMENT_METHOD_TYPES.CASH
      )
      setFieldValue('paymentMethod', cashPaymentMethod)
      setFieldValue('stripeCreditCard', null)
    } else {
      const defaultStripeCard = stripeCreditCards.length
        ? stripeCreditCards.find((card) => card.isDefault) ||
          stripeCreditCards[0]
        : null
      setFieldValue(
        'paymentMethodType',
        PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD
      )
      setFieldValue('paymentMethod', defaultStripeCard)
      setFieldValue('stripeCreditCard', defaultStripeCard)
    }
  }, [paymentMethods, stripeCreditCards, stripePaymentsEnabled])

  // if paymentMethodType changes, change the downstream values
  useEffect(() => {
    if (values.paymentMethodType !== PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD) {
      const newPaymentMethod = paymentMethods.find(
        (paymentMethod: PaymentMethod) =>
          paymentMethod.type === values.paymentMethodType
      )

      setFieldValue('paymentMethod', newPaymentMethod ?? null)
    } else {
      let newPaymentMethod = null
      if (!values.stripeCreditCard) {
        const stripeCreditCards = paymentMethods.filter(
          (paymentMethod: PaymentMethod) =>
            paymentMethod.type === PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD &&
            !paymentMethod.deletedAt
        )
        if (stripeCreditCards.length > 0) {
          newPaymentMethod =
            stripeCreditCards.find(
              (stripeCard: PaymentMethod) => stripeCard.isDefault
            ) || stripeCreditCards[0]
        }
      } else {
        newPaymentMethod = values.stripeCreditCard
      }

      setFieldValue('paymentMethod', newPaymentMethod)
      setFieldValue('stripeCreditCard', newPaymentMethod)
    }
  }, [values.paymentMethodType])

  // if paymentOption changes, update the downstream values
  useEffect(() => {
    switch (values.paymentOption) {
      case PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL:
        // the only way for selectedInvoice not to exist is if there are no invoices
        // if no invoices, this option will not be visible in the UI
        if (!values.selectedInvoice && !values.selectedClaim) {
          return
        }

        if (
          values.selectedClaim &&
          selectedClaimPatientOutstanding <= 0 &&
          values.paymentType === PAYMENT_TYPE.CLAIM
        ) {
          setFieldValue(
            'billableEntitySubOption',
            PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS.CUSTOM_AMOUNT
          )
          setFieldValue('customAmount', 100)
        } else {
          setFieldValue(
            'billableEntitySubOption',
            PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS.OUTSTANDING_BALANCE
          )
          // Whenever the payment option changes or a new claim/invoice is selected
          // the custom amount should be set to the amount due (excluding the <= 0 case for claims)
          const customAmountCentsDue =
            values.paymentType === PAYMENT_TYPE.CLAIM
              ? selectedClaimPatientOutstanding
              : values.selectedInvoice?.amountCentsDue
          setFieldValue('customAmount', customAmountCentsDue)
        }
        break
      case PAYMENT_FORM_OPTIONS.ADD_PATIENT_CREDIT:
        setFieldValue('isApplyingCredit', false)
        break
    }
  }, [values.paymentOption, values.selectedInvoice, values.selectedClaim])

  // compute and set totalChargeAmountCents when values change
  useEffect(() => {
    const newTotalChargeAmountCents = () => {
      if (
        values.paymentOption === PAYMENT_FORM_OPTIONS.PAY_OUTSTANDING_BALANCE &&
        values.paymentType === PAYMENT_TYPE.INVOICE
      ) {
        return outstandingBalanceInvoices
      } else if (
        values.paymentOption === PAYMENT_FORM_OPTIONS.PAY_OUTSTANDING_BALANCE &&
        values.paymentType === PAYMENT_TYPE.CLAIM
      ) {
        return outstandingBalanceClaims
      } else if (
        values.paymentOption === PAYMENT_FORM_OPTIONS.ADD_PATIENT_CREDIT
      ) {
        return values.patientCreditAmount
      } else if (
        values.paymentOption === PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL &&
        (values.selectedInvoice || values.selectedClaim)
      ) {
        if (
          values.billableEntitySubOption ===
          PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS.CUSTOM_AMOUNT
        ) {
          return values.customAmount
        } else {
          return values.paymentType === PAYMENT_TYPE.CLAIM &&
            values.selectedClaim
            ? selectedClaimPatientOutstanding
            : values.selectedInvoice?.amountCentsDue
        }
      }

      return 0
    }

    setFieldValue('totalChargeAmountCents', newTotalChargeAmountCents())
  }, [
    outstandingBalanceInvoices,
    outstandingBalanceClaims,
    values.paymentOption,
    values.paymentType,
    values.selectedInvoice,
    values.selectedClaim,
    values.billableEntitySubOption,
    values.customAmount,
    values.patientCreditAmount,
  ])

  // compute total applied credit
  useEffect(() => {
    const newTotalAppliedCredit = shouldApplyPatientCredit
      ? sumBy(
          Object.keys(values.creditUsage),
          (paymentMethodUuid: string) =>
            values.creditUsage[paymentMethodUuid].creditUtilized
        )
      : 0
    setFieldValue('totalAppliedCredit', newTotalAppliedCredit)
  }, [values.creditUsage, shouldApplyPatientCredit])
  // on invoice select
  useEffect(() => {
    if (!values.invoiceUuid) {
      return
    }

    const newSelectedInvoice = invoices.find(
      (invoice: Invoice) => invoice.uuid === values.invoiceUuid
    )

    if (!newSelectedInvoice) {
      onError(
        new Error(`Invoice ${values.selectedInvoice?.invoiceNumber} not found.`)
      )
      datadogRum.addAction(`ErrorSelectingInvoice.BillingPaymentForm`, {
        error: `Invoice ${values.invoiceUuid} not found.`,
      })
      return
    }

    setFieldValue('selectedInvoice', newSelectedInvoice)

    if (
      values.billableEntitySubOption !==
      PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS.CUSTOM_AMOUNT
    ) {
      setFieldValue('customAmount', newSelectedInvoice.amountCentsDue)
    }
  }, [values.invoiceUuid, values.billableEntitySubOption, invoices])

  // on claim select
  useEffect(() => {
    if (!values.claimUuid) {
      return
    }

    const newSelectedClaim = claims.find(
      (claim: InsuranceClaim) => claim.uuid === values.claimUuid
    )

    if (!newSelectedClaim) {
      console.error(`Claim ${values.claimUuid} not found.`)
      return
    }

    setFieldValue('selectedClaim', newSelectedClaim)

    if (!touched.customAmount && values.paymentType === PAYMENT_TYPE.CLAIM) {
      // If patient outstanding is 0, set custom amount to 1
      const newClaimPatientOutstanding =
        calculateClaimAndPatientOutstanding(newSelectedClaim).patientOutstanding
      if (newClaimPatientOutstanding <= 0) {
        setFieldValue('customAmount', 100)
      } else {
        setFieldValue('customAmount', newClaimPatientOutstanding)
      }
    }
  }, [values.claimUuid])

  const showStripeDropdown =
    values.paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD &&
    paymentIntentSecret

  const hasNoClaims = () => {
    return claims.length === 0
  }

  const hasNoInvoices = () => {
    return invoices.length === 0 || outstandingBalanceInvoices === 0
  }

  const getDefaultStripeCreditCard = useCallback(() => {
    if (
      values.paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD &&
      stripeCreditCards.length > 0
    ) {
      return values.paymentMethod
    }

    return null
  }, [values.paymentMethod, values.paymentMethodType, stripeCreditCards])

  const totalContent = useMemo(
    () =>
      errors.totalAppliedCredit
        ? '--'
        : formatNumberStringToUsdString(
            convertCentsToDollars(
              values.totalChargeAmountCents - values.totalAppliedCredit
            )
          ),
    [
      errors.totalAppliedCredit,
      values.totalChargeAmountCents,
      values.totalAppliedCredit,
    ]
  )
  const handleStripeCreditCardSelect = (value: any) => {
    if (value === NEW_CARD) {
      showAddCreditCardModal()
    } else {
      const newStripeCard = paymentMethods.find(
        (pm: PaymentMethod) => pm.uuid === value
      )

      if (!newStripeCard) {
        notification('Card could not be found.')
        return
      }

      setFieldValue('paymentMethod', newStripeCard)
      setFieldValue('stripeCreditCard', newStripeCard)
    }
  }

  const handleDisplayExpiryDate = (card: PaymentMethod) => {
    const { expYear, expMonth } = card
    if (!expYear || !expMonth || !isExpiredCard(card)) {
      return ''
    }
    return (
      <span className={styles.expiredDisabled}>
        Expired on {expMonth}/{expYear}
      </span>
    )
  }

  // set the stripeCreditCard, if values it depends on change
  useEffect(() => {
    const newStripeCard = () => {
      if (
        values.paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD
      ) {
        if (
          stripeCreditCards.length > 0 &&
          stripeCreditCards.find(
            (card) => card.uuid === values.paymentMethod?.uuid
          )
        ) {
          return values.paymentMethod
        }
      }
      return null
    }
    setFieldValue('stripeCreditCard', newStripeCard())
  }, [values.paymentMethod, values.paymentMethodType, stripeCreditCards])

  const applyCreditCheckbox = useMemo(
    () => (
      <div>
        <Checkbox
          name="isApplyingCredit"
          disabled={isApplyingCreditDisabled}
          checked={shouldApplyPatientCredit}
          onChange={handleChange}
          data-testid="apply-credit-checkbox"
        >
          <div data-testid="apply-credit-content">
            <span>Apply patient credit - </span>
            <span className={styles.creditTotal}>
              {formatNumberStringToUsdString(
                convertCentsToDollars(totalPatientCreditAmountCents)
              )}{' '}
              available
            </span>
          </div>
        </Checkbox>
        {errors.totalAppliedCredit && (
          <div className={styles.creditUsageErrorContainer}>
            <Alert
              message={errors.totalAppliedCredit}
              type="error"
              testId="apply-credit-error-msg"
            />
          </div>
        )}
      </div>
    ),
    [
      isApplyingCreditDisabled,
      shouldApplyPatientCredit,
      totalPatientCreditAmountCents,
      errors.totalAppliedCredit,
    ]
  )

  const stripeCardSelect = useMemo(() => {
    if (!(stripePaymentsEnabled && showStripeDropdown)) return <span />

    if (stripeCreditCards.length === 0) {
      return (
        <div
          className={styles.addNewCardButton}
          data-testid="payment-modal-add-card-button"
        >
          <Button type="primary" ghost onClick={() => showAddCreditCardModal()}>
            + Add new card
          </Button>
        </div>
      )
    }
    return (
      <div className={styles.selectCreditCardContainer}>
        <div className={styles.fieldLabel}>
          <span className={styles.requiredFieldMarking}>*</span> Select a card
        </div>
        <Select
          style={{ width: '100%' }}
          onChange={handleStripeCreditCardSelect}
          defaultValue={getDefaultStripeCreditCard()?.uuid || null}
          value={values.stripeCreditCard?.uuid || null}
          disabled={values.totalAppliedCredit >= values.totalChargeAmountCents}
          testId="payment-modal-credit-card-select"
        >
          {stripeCreditCards.map((creditCard, index) => {
            return (
              <Option
                data-testid={`payment-modal-credit-card-option-${index}`}
                key={`credit-card-option-${creditCard.uuid}`}
                value={creditCard.uuid}
              >
                {getPaymentMethodDisplayContent(creditCard)}{' '}
                {creditCard.isDefault ? (
                  <Tag className={styles.defaultTag}>Default</Tag>
                ) : null}
                {handleDisplayExpiryDate(creditCard)}
              </Option>
            )
          })}
          <Option value={NEW_CARD} disabled={isLoading}>
            <p className={styles.addNewCardText}>+ Add new card...</p>
          </Option>
        </Select>
        {touched.paymentMethod && errors.paymentMethod && (
          <ErrorSpan
            error={errors.paymentMethod}
            testId="payment-method-error"
          />
        )}
      </div>
    )
  }, [
    stripePaymentsEnabled,
    showStripeDropdown,
    handleStripeCreditCardSelect,
    stripeCreditCards,
    isLoading,
    values,
    errors,
  ])

  return (
    <Form>
      <div className={styles.sectionHeader}>
        <b>Select an option to pay</b>
      </div>
      <Radio.Group
        name="paymentOption"
        value={values.paymentOption}
        onChange={handleChange}
        data-testid="payment-option-radio-group"
      >
        <div className={styles.optionsContainer}>
          <Radio
            value={PAYMENT_FORM_OPTIONS.PAY_OUTSTANDING_BALANCE}
            className={styles.paymentOptionRadio}
            data-testid="pay-outstanding-balance-radio"
            disabled={
              values.paymentType === PAYMENT_TYPE.CLAIM
                ? outstandingBalanceClaims === 0
                : outstandingBalanceInvoices === 0
            }
          >
            <span>
              Pay total patient outstanding balance -{' '}
              {values.paymentType
                ? values.paymentType.charAt(0).toUpperCase() +
                  values.paymentType.slice(1) +
                  's'
                : ''}
              {' - '}
            </span>
            <span className={styles.billableEntityBalance}>
              {formatNumberStringToUsdString(
                convertCentsToDollars(
                  values.paymentType === PAYMENT_TYPE.CLAIM
                    ? outstandingBalanceClaims
                    : outstandingBalanceInvoices
                )
              )}
            </span>
          </Radio>
          <Radio
            value={PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL}
            className={styles.paymentOptionRadio}
            data-testid="pay-billable-entity-radio"
            disabled={hasNoInvoices() && hasNoClaims()}
          >
            Pay an individual {values.paymentType}
            {values.paymentOption === PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL && (
              <div className={styles.optionsContainer}>
                {/* TODO: CARE-1032 make the invoices and claims section into a
                    generic billable entity component*/}
                {/* Invoices */}
                {values.paymentType === PAYMENT_TYPE.INVOICE && (
                  <>
                    {preSelectedInvoiceUuid ? (
                      <>
                        {values.selectedInvoice && (
                          <div
                            className={
                              styles.preSelectedBillableEntityDescription
                            }
                          >
                            <InvoiceOption invoice={values.selectedInvoice} />
                          </div>
                        )}
                      </>
                    ) : (
                      <Select
                        defaultValue={
                          invoices.length ? invoices[0]?.uuid : null
                        }
                        onChange={(uuid: any) =>
                          setFieldValue('invoiceUuid', uuid)
                        }
                        onClick={(e) => e.preventDefault()}
                        className={styles.billableEntityDropdown}
                      >
                        {invoices.map((invoice) => (
                          <Option value={invoice.uuid} key={invoice.uuid}>
                            <InvoiceOption invoice={invoice} />
                          </Option>
                        ))}
                      </Select>
                    )}
                    <Radio.Group
                      name="billableEntitySubOption"
                      onChange={handleChange}
                      value={values.billableEntitySubOption}
                    >
                      <Radio
                        value={
                          PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS.OUTSTANDING_BALANCE
                        }
                        className={styles.paymentSuboptionRadio}
                        data-testid="invoice-outstanding-balance-radio"
                      >
                        Invoice outstanding balance -{' '}
                        <span className={styles.billableEntityBalance}>
                          {values.selectedInvoice &&
                            formatNumberStringToUsdString(
                              convertCentsToDollars(
                                values.selectedInvoice.amountCentsDue
                              )
                            )}
                        </span>
                      </Radio>
                      <Radio
                        value={
                          PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS.CUSTOM_AMOUNT
                        }
                        className={styles.paymentSuboptionRadio}
                        data-testid="invoice-custom-amount-radio"
                      >
                        {customAmountDescription}
                        {values.paymentOption ===
                          PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL &&
                          values.billableEntitySubOption ===
                            PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS.CUSTOM_AMOUNT && (
                            <>
                              <div
                                className={styles.customAmountInputContainer}
                              >
                                <USDInput
                                  value={convertCentsToDollarsNumber(
                                    values.customAmount
                                  )}
                                  onChange={(amount) => {
                                    setFieldValue(
                                      'customAmount',
                                      convertDollarsToCents(amount)
                                    )
                                    validateForm()
                                  }}
                                  className={styles.customAmountUsdInput}
                                  onBlur={() =>
                                    setTouched({ customAmount: true })
                                  }
                                  status={
                                    touched.customAmount && errors.customAmount
                                      ? 'error'
                                      : undefined
                                  }
                                  testId="invoice-custom-amount-input"
                                />
                              </div>
                              {touched.customAmount && errors.customAmount && (
                                <ErrorSpan
                                  error={errors.customAmount}
                                  testId="custom-amount-error-msg"
                                />
                              )}
                            </>
                          )}
                      </Radio>
                    </Radio.Group>
                  </>
                )}
                {/* Claims */}
                {values.paymentType === PAYMENT_TYPE.CLAIM && (
                  <>
                    {preSelectedClaimUuid ? (
                      <>
                        {values.selectedClaim && (
                          <div
                            className={
                              styles.preSelectedBillableEntityDescription
                            }
                          >
                            <ClaimOption
                              claim={values.selectedClaim}
                              claimPatientOutstanding={
                                selectedClaimPatientOutstanding
                              }
                              testId={`payment-modal-claim-select-${preSelectedClaimUuid}`}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <Select
                        defaultValue={claims.length ? claims[0].uuid : null}
                        onChange={(uuid: any) =>
                          setFieldValue('claimUuid', uuid)
                        }
                        onClick={(e) => e.preventDefault()}
                        className={styles.billableEntityDropdown}
                      >
                        {claims.map((claim) => (
                          <Option value={claim.uuid} key={claim.uuid}>
                            <ClaimOption
                              claim={claim}
                              claimPatientOutstanding={
                                calculateClaimAndPatientOutstanding(claim)
                                  .patientOutstanding
                              }
                              testId={`payment-modal-claim-select-${claim.uuid}`}
                            />
                          </Option>
                        ))}
                      </Select>
                    )}
                    <Radio.Group
                      name="billableEntitySubOption"
                      onChange={handleChange}
                      value={values.billableEntitySubOption}
                    >
                      <Radio
                        value={
                          PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS.OUTSTANDING_BALANCE
                        }
                        className={styles.paymentSuboptionRadio}
                        disabled={selectedClaimPatientOutstanding <= 0}
                        data-testid="claim-outstanding-balance-radio"
                      >
                        Pay total patient outstanding balance -{' '}
                        <span className={styles.billableEntityBalance}>
                          {(values?.selectedClaim?.patientPaidAmountCents ??
                            0) === 0 && selectedClaimPatientOutstanding === 0
                            ? 'Pending'
                            : formatNumberStringToUsdString(
                                values?.selectedClaim
                                  ? selectedClaimPatientOutstanding / 100
                                  : 0
                              )}
                        </span>
                      </Radio>
                      <Radio
                        value={
                          PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS.CUSTOM_AMOUNT
                        }
                        className={styles.paymentSuboptionRadio}
                        data-testid="claim-custom-amount-radio"
                      >
                        {customAmountDescription}
                        {values.paymentOption ===
                          PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL &&
                          values.billableEntitySubOption ===
                            PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS.CUSTOM_AMOUNT && (
                            <>
                              <div
                                className={styles.customAmountInputContainer}
                              >
                                <USDInput
                                  value={convertCentsToDollarsNumber(
                                    values.customAmount
                                  )}
                                  onChange={(amount) => {
                                    setFieldValue(
                                      'customAmount',
                                      convertDollarsToCents(amount)
                                    )
                                    validateForm()
                                  }}
                                  className={styles.customAmountUsdInput}
                                  onBlur={() =>
                                    setTouched({ customAmount: true })
                                  }
                                  status={
                                    touched.customAmount && errors.customAmount
                                      ? 'error'
                                      : undefined
                                  }
                                  testId="claim-custom-amount-input"
                                />
                              </div>
                              {touched.customAmount && errors.customAmount && (
                                <ErrorSpan
                                  error={errors.customAmount}
                                  testId="custom-amount-error-msg"
                                />
                              )}
                            </>
                          )}
                      </Radio>
                    </Radio.Group>
                  </>
                )}
              </div>
            )}
          </Radio>
          <Radio
            value={PAYMENT_FORM_OPTIONS.ADD_PATIENT_CREDIT}
            className={styles.paymentOptionRadio}
            disabled={shouldApplyPatientCredit}
            data-testid="add-patient-credit-radio"
          >
            <div>
              <span>Add credit to patient account </span>
              <div style={{ display: 'inline', paddingLeft: '4px' }}>
                <Tooltip title="Patient credit can be paid for in advance and used anytime towards an invoice. Patient credit is refundable.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            </div>

            {values.paymentOption ===
              PAYMENT_FORM_OPTIONS.ADD_PATIENT_CREDIT && (
              <div className={styles.addCreditInputContainer}>
                <USDInput
                  className={styles.addCreditUsdInput}
                  value={convertCentsToDollarsNumber(
                    values.patientCreditAmount
                  )}
                  onChange={(value: number | null) => {
                    const amountCents = value ? convertDollarsToCents(value) : 0
                    setFieldValue('patientCreditAmount', amountCents)
                    setFieldValue('totalChargeAmountCents', amountCents)
                  }}
                  onBlur={() => setTouched({ patientCreditAmount: true })}
                  status={
                    touched.patientCreditAmount && errors.patientCreditAmount
                      ? 'error'
                      : undefined
                  }
                  testId="add-patient-credit-input"
                />
                {touched.patientCreditAmount && errors.patientCreditAmount && (
                  <ErrorSpan
                    error={errors.patientCreditAmount}
                    testId="add-credit-error-msg"
                  />
                )}
              </div>
            )}
          </Radio>
        </div>
      </Radio.Group>

      <Divider />

      <div className={styles.isApplyingCreditInputContainer}>
        {shouldApplyPatientCredit ? (
          <Collapse ghost expandIconPosition="end" destroyInactivePanel>
            <CollapsePanel
              className={styles.isApplyingCreditCollapse}
              header={applyCreditCheckbox}
              key="applyCreditPanel"
            >
              <div className={styles.creditUsageContainer}>
                <PatientCreditInputs
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  paymentMethods={paymentMethods}
                  setTouched={setTouched}
                />
                <div className={styles.creditSummaryContainer}>
                  <span className={styles.creditSummaryTitle}>Apply:</span>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <span className={styles.creditSummaryUtilized}>
                      {formatNumberStringToUsdString(
                        convertCentsToDollars(values.totalAppliedCredit)
                      )}
                    </span>
                    <span className={styles.creditSummaryTotal}>
                      {' '}
                      of{' '}
                      {formatNumberStringToUsdString(
                        convertCentsToDollars(totalPatientCreditAmountCents)
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </CollapsePanel>
          </Collapse>
        ) : (
          applyCreditCheckbox
        )}
      </div>

      <Divider />

      <div className={styles.sectionHeader}>
        <b>Choose a payment method</b>
      </div>

      <div>
        <div className={styles.fieldLabel}>
          <span className={styles.requiredFieldMarking}>*</span> Payment method
        </div>
        <Select
          style={{ width: '100%' }}
          value={values.paymentMethodType}
          onChange={(value: any) => {
            setFieldValue('paymentMethodType', value)
            if (value !== PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD) {
              const newPaymentMethod = paymentMethods.find(
                (paymentMethod: PaymentMethod) => paymentMethod.type === value
              )

              setFieldValue('paymentMethod', newPaymentMethod ?? null)
            }
          }}
          disabled={
            Boolean(errors.patientCreditAmount) ||
            (values.paymentOption !== PAYMENT_FORM_OPTIONS.ADD_PATIENT_CREDIT &&
              values.totalAppliedCredit >= values.totalChargeAmountCents)
          }
          data-testid="payment-method-type-select"
        >
          {Object.keys(PAYMENT_METHOD_TYPES).map((paymentMethodType) => {
            if (
              !stripePaymentsEnabled &&
              paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD
            )
              return
            return (
              <Option
                value={paymentMethodType}
                data-testid={`payment-method-type-option-${paymentMethodType}`}
                disabled={
                  paymentMethodType ===
                    PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD &&
                  stripeAccountStatus !== STRIPE_ACCOUNT_STATUSES.SETUP_COMPLETE
                }
                key={paymentMethodType}
              >
                {getDisplayNameForPaymentMethodType(
                  paymentMethodType as PAYMENT_METHOD_TYPES
                )}
              </Option>
            )
          })}
        </Select>
        {touched.paymentMethodType && errors.paymentMethodType && (
          <ErrorSpan
            error={errors.paymentMethodType}
            testId="payment-method-type-error"
          />
        )}
      </div>

      {stripeCardSelect}

      <Divider />

      <div>Memo</div>
      <TextArea
        className={styles.memoTextArea}
        showCount
        maxLength={MAXIMUM_MEMO_LENGTH}
        name="memo"
        value={values.memo}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {touched.memo && errors.memo && (
        <ErrorSpan error={errors.memo} testId="memo-error" />
      )}

      <Divider />

      <div className={styles.chargeSummaryContainer}>
        <div className={styles.chargeSummaryRow}>
          <span className={styles.chargeSummaryFieldName}>Total charges:</span>
          <span className={styles.chargeSummaryFieldValue}>
            {formatNumberStringToUsdString(
              convertCentsToDollars(values.totalChargeAmountCents)
            )}
          </span>
        </div>

        <div className={styles.chargeSummaryRow}>
          <span className={styles.chargeSummaryFieldName}>
            Patient credit applied:
          </span>
          <span
            className={styles.chargeSummaryFieldValue}
            data-testid="credit-amount"
          >
            {values.totalAppliedCredit > 0 ? '-' : null}{' '}
            {formatNumberStringToUsdString(
              convertCentsToDollars(values.totalAppliedCredit)
            )}
          </span>
        </div>

        <div className={styles.chargeSummaryTotalRow}>
          <span className={styles.chargeSummaryFieldName}>Total:</span>
          <span
            className={styles.chargeSummaryFieldValue}
            data-testid="total-charge"
          >
            {totalContent}
          </span>
        </div>
      </div>
    </Form>
  )
}
