import React from 'react'

import cx from 'classnames'

import { TestId } from '../../shared-types'

import styles from './InfoGrid.module.scss'

export type InfoRow = {
  label: string | React.ReactNode
  content?: string | React.ReactNode
}

type InfoGridProps = {
  rows: InfoRow[]
  style?: React.CSSProperties
  className?: string
} & TestId

// TODO: This could be tweaked in the future to allow multiple columns; for now, it's just a single column because that's all we need
export const InfoGrid = ({
  rows,
  style = {},
  className = '',
  testId,
}: InfoGridProps) => {
  return (
    <div
      data-testid={testId}
      className={cx([styles.container, className])}
      style={style}
    >
      {rows?.map(({ label, content }, i) => (
        <React.Fragment key={`info-grid-${i}`}>
          <div className={styles.label}>{label}</div>
          <div className={styles.content}>{content || ''}</div>
        </React.Fragment>
      ))}
    </div>
  )
}
