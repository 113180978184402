import moment from 'moment'
import { HeaderProps } from 'react-big-calendar'

import { Col, Row } from '../../BaseComponents'

export default function WeekViewHeader({ date }: HeaderProps) {
  const dayOfMonth = moment(date).format('D')
  const dayOfWeek = moment(date).format('ddd')

  return (
    <Col className="weekday-header-cell">
      <Row className="weekday-number-header-cell">
        {dayOfWeek}&nbsp;{dayOfMonth}
      </Row>
    </Col>
  )
}
