import { AppointmentInfoContent } from './AppointmentInfoContent'

import styles from './AppointmentInfo.module.scss'

export type AppointmentInfoProps = {
  title: 'Last' | 'Next' | 'Today'
  appointmentInfo?: {
    date: string
    startTime: string
    endTime: string
    clinicians?: string[]
    room?: string
  }[]
  isLoading: boolean
}

export const AppointmentInfo = ({
  title,
  appointmentInfo,
  isLoading,
}: AppointmentInfoProps) => {
  return (
    <>
      <h4 className={styles.title}>{title}</h4>
      <AppointmentInfoContent
        isLoading={isLoading}
        title={title}
        appointmentInfo={appointmentInfo}
      />
    </>
  )
}
