import React, { useCallback, useEffect, useRef, useState } from 'react'

import {
  DownloadOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Button, Form, Spinner } from 'react-bootstrap'

import { getLatestCompletedIntakeForm } from '../../../../api/intakeForms'
import { Modal } from '../../../../stories/BaseComponents'
import { AutoSizingTextArea } from '../../../../stories/BaseComponents/TextAreaInput'
import { convertResponsesToEvalNote } from '../../../../stories/PatientIntake/convert-to-eval-note'

import styles from './ImportIntake.module.scss'

interface ModalProps {
  title?: React.ReactNode
  message?: string
  hasOkButton?: boolean
  cancelText?: string
  cancelButton?: any
  visible: boolean
  onOkay?: () => void
}
interface ImportIntakeProps {
  fields: any
  handleFieldChange: any
  disabled: boolean
}

const ImportIntake: React.FC<ImportIntakeProps> = ({
  fields,
  handleFieldChange,
  disabled,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasExistingText, setHasExistingText] = useState<boolean>(false)
  const [modal, setModal] = useState<ModalProps>({
    visible: false,
  })

  const toggleErrorModal = (message: string) => {
    setModal({
      title: (
        <div>
          <InfoCircleOutlined id={styles.errorColor} />
          &nbsp;&nbsp;Unable to import patient intake responses
        </div>
      ),
      visible: true,
      message: message,
      hasOkButton: false,
      cancelText: 'Ok',
      cancelButton: {
        type: 'primary',
      },
    })
  }
  const toggleConfirmModal = (message: string, onOkay: () => void) => {
    setModal({
      title: (
        <div>
          <QuestionCircleOutlined id={styles.confirmationColor} />
          &nbsp;&nbsp;Are you sure you want to import?
        </div>
      ),
      visible: true,
      message: message,
      hasOkButton: true,
      cancelText: '',
      cancelButton: undefined,
      onOkay: onOkay,
    })
  }

  useEffect(() => {
    if (fields.ClinicalNoteContent !== '') {
      setHasExistingText(true)
    } else {
      setHasExistingText(false)
    }
  }, [fields])

  const closeImportModal = () => {
    setModal({ visible: false })
  }

  const importWithEmptyText = useCallback(async () => {
    try {
      setIsLoading(true)
      const latestIntakeForm = await getLatestCompletedIntakeForm(
        fields.PatientId
      )

      if (!latestIntakeForm) {
        throw new Error('')
      }

      const resultingEvalNote = convertResponsesToEvalNote(
        latestIntakeForm.responses ?? [],
        latestIntakeForm.sections
      )
      handleFieldChange({
        ...fields,
        importedIntakeFormId: latestIntakeForm.id,
        ClinicalNoteContent: resultingEvalNote,
      })
      closeImportModal()
    } catch (err) {
      const errorMessage =
        err.response?.data?.error ?? 'Failed to import an Osmind Intake Form.'
      toggleErrorModal(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }, [fields, handleFieldChange])

  const checkExistingText = () => {
    toggleConfirmModal(
      'Importing the Osmind Intake Form responses will overwrite any existing text in the Clinical Note field.',
      importWithEmptyText
    )
  }

  const handleClinicalNoteChanges = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const clinicalNoteChange = {
        ...fields,
        ClinicalNoteContent: e.target.value,
      }
      if (e.target.value === '' && fields.importedIntakeFormId) {
        // If user had "importedIntakeFormId" and then deleted imported text, set it back to null
        clinicalNoteChange.importedIntakeFormId = null
      }
      handleFieldChange(clinicalNoteChange)
    },
    [fields]
  )

  return (
    <>
      <Modal
        visible={modal.visible}
        title={modal.title}
        hasOkButton={modal.hasOkButton}
        cancelButtonProps={modal.cancelButton}
        cancelText={modal.cancelText}
        okText="Import"
        onCancel={closeImportModal}
        onOk={importWithEmptyText}
        zIndex={100000}
      >
        <p>{modal.message}</p>
      </Modal>
      <Form.Group
        controlId="ClinicalNoteContent"
        className={styles.clinicalNoteContent}
      >
        <Form.Label style={{ fontWeight: 'bold' }}>Clinical Note</Form.Label>
        <span>
          <Button
            variant="outline-primary"
            size="sm"
            disabled={isLoading || disabled}
            className={styles.importButton}
            onClick={() => {
              hasExistingText ? checkExistingText() : importWithEmptyText()
            }}
          >
            {isLoading ? (
              <div>
                <Spinner animation="border" size="sm" />
                &nbsp;&nbsp;Import Patient Intake Responses
              </div>
            ) : (
              <div>
                <DownloadOutlined /> Import Patient Intake Responses
              </div>
            )}
          </Button>
        </span>
        <Form.Control
          ref={textAreaRef}
          bsPrefix="form-control"
          className="field-value-edit"
          value={fields.ClinicalNoteContent}
          as={AutoSizingTextArea}
          disabled={isLoading || disabled}
          onChange={handleClinicalNoteChanges}
        />
      </Form.Group>
    </>
  )
}

export default ImportIntake
