import { useParams } from 'react-router-dom'

import { usePatientMedicationHistory } from '../../../../../hooks/usePatientMedicationHistory'
import { generateCoreIdDisplayName } from '../../../../../hooks/usePatientProfile/utils'
import { useProfileUrlParams } from '../../../../../hooks/useProfileUrlParams'
import { useNote } from '../../../hooks'
import { MedicationsBlockTable } from '../BlockTypeRenderer/types/medications/MedicationsBlockTable'
import { StructuredBlockProps } from '../types'

type ActiveMedicationsOptions = {
  isDrFirst?: boolean
}

export const ActiveMedicationsBlock = ({
  options,
  isSigned,
}: StructuredBlockProps<ActiveMedicationsOptions>) => {
  const { isDrFirst = false } = options || {}
  const { noteId } = useParams() as { noteId: string }
  const { patientId } = useProfileUrlParams() as { patientId: string }
  const { note, isLoading: isNoteLoading } = useNote(noteId)

  const { data: medications, isInitialLoading: isMedHistoryLoading } =
    usePatientMedicationHistory({
      patientId,
      endDate: note?.appointmentDate
        ? new Date(note?.appointmentDate)
        : new Date(),
      enabled: !!note,
    })

  const patientName = note?.patient && generateCoreIdDisplayName(note?.patient)
  const isAnythingLoading = isMedHistoryLoading || isNoteLoading
  return (
    <MedicationsBlockTable
      blockData={medications}
      isSigned={isSigned ?? false}
      isDrFirst={isDrFirst}
      patientName={patientName}
      isLoading={isAnythingLoading}
    />
  )
}
