import { useCallback, useEffect, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'
import debounce from 'lodash/debounce'
import { useHistory } from 'react-router-dom'

import { OnLogoutCallback } from '../../stories/Navigation/types'
import { LogoutAcrossTabsConfirmationModal } from './LogoutAcrossTabsConfirmationModal'
import { logout } from './logout/logout'

export const VISIBILITY_HANDLER_DEBOUNCE_TIME = 1000

export const useLogoutAcrossTabsComponent = ({
  onLogoutCallback,
}: {
  onLogoutCallback: OnLogoutCallback
}) => {
  const [isShownLogoutConfirmationModal, setIsShownLogoutConfirmationModal] =
    useState(false)

  const showLogoutConfirmationModal = () =>
    setIsShownLogoutConfirmationModal(true)
  const dismissLogoutConfirmationModal = () =>
    setIsShownLogoutConfirmationModal(false)

  const queryClient = useQueryClient()
  const history = useHistory()

  const performLogout = async () =>
    await logout({ queryClient, history, onLogoutCallback })

  const LogoutConfirmationModal = () => (
    <LogoutAcrossTabsConfirmationModal
      isShownLogoutConfirmationModal={isShownLogoutConfirmationModal}
      dismissLogoutConfirmationModal={dismissLogoutConfirmationModal}
      performLogout={performLogout}
    />
  )

  /**
   * An inactive tab that becomes visible, should immediately have the logout applied, if relevant
   *
   * - See flowchart for more details: ./DETECTING_VISIBILITY_CHANGES_FOR_LOGOUT.md
   *
   * If there's any concern about needing the tab-change listenter on a page without the <NavigationBar />
   * - Then consider moving this out to its own hook
   * - However, the setup for `login` might make that optimization not worth the effort
   */
  const maybeLogoutOnVisibilityChange = useCallback(
    debounce(
      (evt) => {
        if (
          (evt.type === 'visibilitychange' &&
            evt.target.visibilityState === 'visible') ||
          evt.type === 'focus'
        ) {
          ;(async () => {
            try {
              // Active session will not throw an error
              await Auth.currentSession()
            } catch {
              // Logout if error is thrown
              await performLogout().catch()
            }
          })()
        }

        /**
         * debouncing settings
         * - evaluate immediately (`leading: true`)
         * - ignore anything within 1s (`VISIBILITY_HANDLER_DEBOUNCE_TIME`, e.g. `1000`)
         * - don't fire at the end (to prevent duplicate events, `trailing: false`)
         */
      },
      VISIBILITY_HANDLER_DEBOUNCE_TIME,
      { leading: true, trailing: false }
    ),
    [performLogout]
  )

  // for inactive tabs: listen for visibility change
  useEffect(() => {
    window.addEventListener('focus', maybeLogoutOnVisibilityChange)
    // On cleanup: Unsubscribe from listener
    return () =>
      window.removeEventListener('focus', maybeLogoutOnVisibilityChange)
  }, [maybeLogoutOnVisibilityChange])

  // for inactive tabs: listen for visibility change
  useEffect(() => {
    document.addEventListener('visibilitychange', maybeLogoutOnVisibilityChange)
    // On cleanup: Unsubscribe from listener
    return () =>
      document.removeEventListener(
        'visibilitychange',
        maybeLogoutOnVisibilityChange
      )
  }, [maybeLogoutOnVisibilityChange])

  return {
    initiateLogoutWithConfirmation: showLogoutConfirmationModal,
    LogoutConfirmationModal,
  }
}
