import React, { useState } from 'react'

import {
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Col, Container, ProgressBar, Row } from 'react-bootstrap'

import { editPatientReportedMedication } from '../../api/api-lib'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import ConfirmationModal from './ConfirmationModal'

interface ConfirmationModalProps {
  patientReportedData: string
  title: string
  patientId: string
  fieldId: string
  isDismissed: boolean
  handleApiChange: () => void
}

export default function PatientReportedField(props: ConfirmationModalProps) {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const ignoreKeyWords = ['None', 'none', 'n/a', 'N/A', 'None.', 'none.', '']
  const data = ignoreKeyWords.includes(props.patientReportedData)
    ? `Patient did not report ${props.title}`
    : props.patientReportedData
  const isLoading = props.patientReportedData === undefined

  async function handleConfirm() {
    const data = {
      patientId: props.patientId,
      fieldId: props.fieldId,
    }
    try {
      await editPatientReportedMedication(data)
      props.handleApiChange()
      setShowDeleteModal(false)
      notification(
        `You have successfully dismissed the patient reported section for ${props.title}. Please wait a moment to see your changes.`,
        'success'
      )
    } catch (error) {
      console.error('Error dismissing patient reported section', error)
      onError(
        error,
        500,
        'There was an internal error dismissing the patient reported section. Please inform your administrator.'
      )
    }
  }

  const renderDeleteConfirmationModal = () => {
    return (
      <ConfirmationModal
        title="Are you sure?"
        okText="Delete"
        body={
          <>
            <p>
              You are about to permanently delete the following patient reported
              section for{' '}
              <span className="font-weight-bold">{props.title}</span>
            </p>
            <p>
              <span className="danger">WARNING: </span>
              This is an unofficial list of medications reported by the patient
              on the intake forms. It should be verified by a clinician and
              entered into the formal medication list. Please make sure all
              medications have been processed into the formal medication list
              before removing.
            </p>
            <p>Are you really sure you want to delete this section?</p>
          </>
        }
        size="lg"
        show={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleConfirm}
      />
    )
  }

  return (
    <>
      {isLoading ? (
        <Card bsPrefix="summary-card marginal">
          <div className="very-large">
            Loading patient reported information...
          </div>
          <ProgressBar animated now={70} />
        </Card>
      ) : (
        <>
          {!props.isDismissed && (
            <Card style={{ margin: '0px' }} bsPrefix="summary-card">
              <Container>
                <Row>
                  <Col>
                    <div className="font-weight-bold">
                      <span>
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          color="#bc422a"
                        />{' '}
                      </span>
                      {`Patient reported: ${props.title}`}
                      <span>
                        <FontAwesomeIcon
                          icon={faTimes}
                          color="#00000"
                          style={{ float: 'right', cursor: 'pointer' }}
                          onClick={() => setShowDeleteModal(true)}
                        />
                      </span>
                    </div>
                  </Col>
                </Row>
                <div className="profile-copied font-weight-bold">
                  This is an unofficial list of medications reported by the
                  patient on the intake forms. It should be verified by a
                  clinician and entered into the formal medication list.
                </div>
                <div style={{ whiteSpace: 'pre-line', paddingTop: '9px' }}>
                  {data}
                </div>
              </Container>
              {renderDeleteConfirmationModal()}
            </Card>
          )}
        </>
      )}
    </>
  )
}
