import React, { ReactNode } from 'react'

import { ExclamationCircleFilled } from '@ant-design/icons'

import SignatureStroke from '../../../../containers/Patient/ClinicalNotes/ClinicalNotesComponents/assets/SignatureStroke.svg'
import { Modal } from '../../../../stories/BaseComponents'
import TextAreaInput from '../../../../stories/BaseComponents/TextAreaInput'

import styles from './SignatureModal.module.scss'

type SignatureModalProps = {
  toggleSignatureModal: boolean
  handleSignatureCancel: () => void
  handleSignNote: () => void
  onChangeSignature: (e: any) => void
  getInfoBoxText: () => ReactNode
  value?: string
  isSaving: boolean
  signerName: string
}

export const SignatureModal = ({
  toggleSignatureModal,
  handleSignatureCancel,
  handleSignNote,
  onChangeSignature,
  getInfoBoxText,
  isSaving,
  value,
  signerName: signeeName,
}: SignatureModalProps) => {
  return (
    <Modal
      visible={toggleSignatureModal}
      zIndex={5000}
      centered={true}
      mask={true}
      title="Sign note"
      onCancel={handleSignatureCancel}
      onOk={handleSignNote}
      width={600}
      okText="Sign note"
      okButtonProps={{
        id: styles.signatureButton,
        icon: <img src={SignatureStroke} alt="Signature" />,
        loading: isSaving,
      }}
      isOkayDisabled={isSaving}
    >
      <div className={`${styles.infoBox}`}>
        <div id={styles.infoBoxContents}>
          <div className={`${styles.exclamationCircle}`}>
            <ExclamationCircleFilled />
          </div>
          <div id={styles.infoText}>{getInfoBoxText()}</div>
        </div>
      </div>
      <div id={styles.signeeSection}>
        <b>Signee</b>
        <span id={styles.signeeText}>{signeeName}</span>
      </div>
      <div className={styles.signatureComments}>
        <div id={styles.commentText}>Comments</div>
        <TextAreaInput
          onChange={(e: any) => {
            onChangeSignature(e.target?.value)
          }}
          allowClear={true}
          placeholder="Enter comments"
          rows={5}
          testId="comments-text"
          value={value}
        />
      </div>
    </Modal>
  )
}
