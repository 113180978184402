import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import { getBillingTemplates } from '../../api/api-lib'
import { BillingTemplate } from '../../shared-types'

export const COOL_DOWN_TIME = 100

export const useBillingTemplates = (
  input?: string
): {
  templates: BillingTemplate[] | undefined
  templatesById: Record<number, BillingTemplate>
  isLoading: boolean
} => {
  const [templatesById, setTemplatesById] = useState<
    Record<number, BillingTemplate>
  >({})
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)
  const [templatesCache, setTemplatesCache] = useState<BillingTemplate[]>([])

  useEffect(() => {
    /// Only start timeout when there is input provided
    if (!timeoutId && input) {
      setTimeoutId(
        setTimeout(() => {
          setTimeoutId(null)
        }, COOL_DOWN_TIME)
      )
    } else if (timeoutId && !input) {
      /// Clear timeout when there is no input
      clearTimeout(timeoutId)
      setTimeoutId(null)
    }
    return () => {
      clearTimeout(timeoutId || undefined)
    }
  }, [input])

  const { data: templates, isLoading } = useQuery<
    BillingTemplate[] | undefined
  >(['billingTemplates', input], async () => {
    return !timeoutId ? await getBillingTemplates(input) : templatesCache
  })

  useEffect(() => {
    if (!templates) return
    if (!timeoutId) {
      setTemplatesCache(templates)
    }
    const newTemplatesById: Record<number, BillingTemplate> = {}
    templates.forEach((template) => {
      newTemplatesById[template.id] = template
    })
    setTemplatesById(newTemplatesById)
  }, [templates])

  return {
    templates,
    templatesById,
    isLoading,
  }
}
