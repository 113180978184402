import { Ref, useEffect, useRef, useState } from 'react'

import { SizeType } from 'antd/lib/config-provider/SizeContext'
// importing from antd bc otherwise refs won't work with the component (for now)
import TextArea, {
  TextAreaProps as TextareaProps,
} from 'antd/lib/input/TextArea'

import InlineEditBase, { focusRef } from './InlineEditBase'
import { checkIfCtrlOrCmdKeyIsPressed } from './helpers'
import {
  ActiveComponentProps,
  BaseInterface,
  OnSave,
  Validator,
} from './shared-types'

import styles from './_shared.module.scss'

interface Props extends BaseInterface, TextareaProps {
  maxLength?: number
  onSave?: OnSave<string | number>
  rows?: number
  showCount?: boolean
  lineClamp?: boolean
  size?: SizeType
  value?: string | number
  validators?: Validator<string | number>[]
}

/**
  Editable inline textarea
*/
const InlineTextArea = ({
  requireConfirmation = false,
  showCount = false,
  testId,
  value: initialValue,
  // BaseInterface props to pass to base component
  className = '',
  customActions,
  customDefault,
  id,
  noEmpty,
  onSave,
  padded,
  placeholderElement,
  validators,
  validateOnChange,
  scrollToField,
  size,
  autoFocus,
  lineClamp,
  disabled = false,
  // props should be TextareaProps
  ...props
}: Props) => {
  const baseComponentProps = {
    customActions,
    customDefault,
    id,
    placeholder: props.placeholder,
    noEmpty,
    onSave,
    padded,
    size,
    placeholderElement,
    validators,
    validateOnChange,
    scrollToField,
  }
  const textareaRef: Ref<any> = useRef(null)
  const textContainerRef: Ref<HTMLDivElement> = useRef(null)
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRendered(true)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const ActiveTextarea = ({
    handleChange,
    hasError = false,
    toggleCancel,
    toggleFocus,
    toggleSave,
    value,
  }: ActiveComponentProps) => {
    const isValidType = typeof value === 'string' || typeof value === 'number'
    return (
      <TextArea
        {...props}
        autoSize={{ maxRows: props.rows, minRows: 1 }}
        className={`${styles.activeTextarea} ${
          (!value && styles.noValue) || ''
        }`}
        data-testid={testId ? `textarea-${testId}` : undefined}
        defaultValue={initialValue}
        onChange={handleChange}
        onFocus={(e) => {
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          )
          toggleFocus()
        }}
        onKeyDown={(event) => {
          if (event.key === 'Escape') return toggleCancel()
          const isEitherKey = checkIfCtrlOrCmdKeyIsPressed(event)
          if (event.key !== 'Enter' || !isEitherKey) return
          event.preventDefault()
          if (toggleSave) toggleSave()
        }}
        ref={textareaRef}
        showCount={showCount}
        size={size}
        status={hasError ? 'error' : undefined}
        style={{ height: 'inherit', resize: 'vertical' }}
        value={isValidType && value ? value : undefined}
      />
    )
  }

  return (
    <div className={styles.refContainer} ref={textContainerRef}>
      <InlineEditBase
        {...baseComponentProps}
        activeComponent={ActiveTextarea}
        toggleActive={autoFocus}
        className={`${styles.inlineTextarea} ${className} ${
          lineClamp ? styles.lineClamp : ''
        }`}
        onActive={async () => {
          await focusRef(autoFocus, textContainerRef, textareaRef, rendered)
        }}
        showActions={requireConfirmation}
        testId={testId}
        value={initialValue}
        disabled={disabled}
      />
    </div>
  )
}

export default InlineTextArea
