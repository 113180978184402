import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const GenitourinaryCodes: any = [
  {
    42030000: {
      title: 'Genitourinary Disorder',
      snomedcode: 42030000,
      description: 'Disorder of the genitourinary system (disorder)',
      icdcode: 'R69',
      icddescription: 'Illness, unspecified',
    },
  },
  {
    38822007: {
      title: 'gu.Cystitis',
      description: 'Cystitis (disorder)',
      snomedcode: 38822007,
      icdcode: 'N3090',
      icddescription: 'Cystitis, unspecified without hematuria',
    },
  },
  {
    129103003: {
      title: 'gu.Endometriosis',
      description: 'Endometriosis (disorder)',
      snomedcode: 129103003,
      icdcode: 'N809',
      icddescription: 'Endometriosis, unspecified',
    },
  },
  {
    95315005: {
      title: 'gu.Fibroids',
      description: 'Uterine leiomyoma (disorder)',
      snomedcode: 95315005,
      icdcode: 'D259',
      icddescription: 'Leiomyoma of uterus, unspecified',
    },
  },
  {
    266569009: {
      title: 'gu.Prostate Enlargement (BPH)',
      description: 'Benign prostatic hyperplasia (disorder)',
      snomedcode: 266569009,
      icdcode: 'N400',
      icddescription:
        'Benign prostatic hyperplasia without lower urinary tract symptoms',
    },
  },
]

export const GenitourinaryDropDownValues = constructPatientDemographicObject(
  GenitourinaryCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
