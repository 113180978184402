import React from 'react'

interface SVGProps {
  width: number | string
  height: number | string
  color: string
}

const RedoIcon: React.FC<SVGProps> = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 17"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8529 0.238745C13.931 0.160645 14.0576 0.160654 14.1357 0.238764L19.2097 5.31344C19.2877 5.39149 19.2878 5.51802 19.2098 5.59614L14.1361 10.6792C14.0581 10.7574 13.9314 10.7575 13.8533 10.6795L12.9402 9.76817C12.8621 9.69014 12.862 9.5635 12.94 9.48532L16.1179 6.30149L3.9074 6.29795C2.94518 6.29809 2.15468 7.08867 2.15468 8.05092V12.7074C2.15468 13.6698 2.94531 14.4604 3.90765 14.4604H11.8118C11.9222 14.4604 12.0118 14.55 12.0118 14.6604V15.9504C12.0118 16.0609 11.9222 16.1504 11.8118 16.1504H3.90765C2.01195 16.1504 0.464682 14.6031 0.464682 12.7074V8.05092C0.464682 6.15523 2.01195 4.60795 3.90765 4.60795L16.118 4.61149L12.9406 1.43369C12.8625 1.35558 12.8625 1.22895 12.9406 1.15085L13.8529 0.238745Z"
        fill={color}
      />
    </svg>
  )
}

export default RedoIcon
