export enum Constitutional {
  RecentWeightLossOrGain = 'Recent weight loss or gain',
  FeverOrChills = 'Fever or chills',
  LossOfAppetite = 'Loss of appetite',
  Fatigue = 'Fatigue',
  Weakness = 'Weakness',
}

export enum Eyes {
  GlassesOrContactsLenses = 'Glasses or contacts lenses',
  VisionChanges = 'Vision changes',
  Pain = 'Pain',
  Redness = 'Redness',
  BlurryOrDoubleVision = 'Blurry or double vision',
  BlindSpots = 'Blind spots',
  LossOfVision = 'Loss of vision',
  SpotsOrSpecks = 'Spots or specks',
  FlashingLights = 'flashing lights',
  Glaucoma = 'Glaucoma',
  Cataracts = 'cataracts',
  ExcessiveTearing = 'Excessive tearing',
}

export enum ENTEars {
  ChangesInHearingOrLossOfHearing = 'Changes in hearing or loss of hearing',
  Earache = 'Earache',
  Ringing = 'Ringing (tinnitus)',
  EarDrainage = 'Ear drainage',
  Vertigo = 'Vertigo',
  EarInfection = 'Ear infection',
}

export enum ENTNoseAndSinuses {
  LossOfSmell = 'Loss of smell',
  Stuffiness = 'Stuffiness',
  Itching = 'Itching',
  BloodyNose = 'Bloody nose',
  RunnyNose = 'Runny nose',
  SinusPain = 'Sinus pain',
}

export enum ENTMouthAndPharynx {
  LossOfTaste = 'Loss of taste',
  ToothPain = 'Tooth pain',
  GumPainOrBleeding = 'Gum pain or bleeding',
  Dentures = 'Dentures',
  SoreTongue = 'Sore tongue',
  DryMouth = 'Dry mouth',
  SoreThroat = 'Sore throat',
  Hoarseness = 'Hoarseness',
  DifficultySwallowing = 'Difficulty swallowing',
  SoresInMouth = 'Sores in mouth',
}

export enum ENTNeck {
  Goiter = 'Goiter',
  Lumps = 'lumps',
  Pain = 'pain',
  Stiffness = 'stiffness',
}

export enum Cardiovascular {
  ChestPainOrDiscomfort = 'Chest pain or discomfort',
  Tachycardia = 'tachycardia',
  Hypertension = 'hypertension',
  Tightness = 'Tightness',
  ChestPalpitations = 'Chest palpitations',
  Orthopnea = 'Orthopnea',
  Edema = 'Edema',
  PainInLegsWithExercise = 'Pain in legs with exercise',
  LegCramps = 'Leg cramps',
  ParoxysmalNocturnalDyspnea = 'Paroxysmal nocturnal dyspnea',
  Pacemaker = 'Pacemaker',
}

export enum Respiratory {
  Cough = 'Cough (productive or dry)',
  Sputum = 'Sputum (color and amount)',
  Hemoptysis = 'Hemoptysis',
  Dyspnea = 'Dyspnea',
  NocturnalDyspnea = 'Nocturnal Dyspnea',
  WheezingOrAsthma = 'Wheezing/asthma',
  PainfulBreathing = 'painful breathing',
}
export enum Gastrointestinal {
  Heartburn = 'heartburn',
  AbdominalPain = 'abdominal pain',
  Nausea = 'nausea',
  Vomiting = 'vomiting',
  ChangeInBowelHabits = 'change in bowel habits',
  RectalBleeding = 'rectal bleeding',
  BloodInStools = 'blood in stools',
  Constipation = 'constipation',
  Diarrhea = 'diarrhea',
  Jaundice = 'jaundice',
  PainfulBowelMovements = 'painful bowel movements',
}
export enum GenitourinaryMale {
  SexualDysfunction = 'Sexual dysfunction',
  PainWithSex = 'pain with sex',
  PenileDischarge = 'penile discharge',
  Hernias = 'hernias',
  Sores = 'sores',
  Masses = 'masses',
  TesticularPain = 'testicular pain',
  ChangesInUrinaryFrequency = 'Changes in urinary frequency',
  ChangesInUrinaryUrgency = 'Changes in urinary urgency',
  PainfulUrination = 'painful urination',
  BloodInUrine = 'blood in urine',
  UrinatingAtNight = 'urinating at night',
  Incontinence = 'incontinence',
  ChangesInUrinaryStream = 'changes in urinary stream',
}

export enum GenitourinaryFemale {
  SexualDysfunction = 'Sexual dysfunction',
  PainWithSex = 'pain with sex',
  BladderDysfunctionOrCystitis = 'bladder dysfunction/cystitis',
  UnusualVaginalDischarge = 'Unusual vaginal discharge',
  VaginalDryness = 'vaginal dryness',
  Itching = 'itching',
  Rashes = 'rashes',
  Sores = 'sores',
  HotFlashes = 'hot flashes',
  MenstrualIrregularity = 'menstrual irregularity',
  PainfulPeriods = 'painful periods',
  PainfulUrination = 'painful urination',
  BloodInUrine = 'blood in urine',
  Incontinence = 'incontinence',
  ChangesInUrinaryStream = 'changes in urinary stream',
  UrinatingAtNight = 'urinating at night',
}

export enum Musculoskeletal {
  MuscleOrJointPain = 'muscle or joint pain',
  BackPain = 'back pain',
  SwellingOfJoints = 'swelling of joints',
  Stiffness = 'stiffness',
  Arthritis = 'arthritis',
  Gout = 'gout',
  RednessOfJoints = 'redness of joints',
  Trauma = 'trauma',
  RangeOfMotion = 'range of motion',
  Instability = 'instability',
  Backache = 'backache',
}

export enum Integumentary {
  Rashes = 'rashes',
  Itching = 'itching',
  ColorChanges = 'color changes',
  Lumps = 'lumps',
  Sores = 'sores',
  Dryness = 'dryness',
  HairAndNailChanges = 'hair and nail changes',
  ChangesInSizeOrColorOfMoles = 'changes in size or color of moles',
  NippleDischarge = 'nipple discharge',
  HairLoss = 'hair loss',
}

export enum Neurological {
  Headache = 'Headache',
  HeadInjury = 'Head injury',
  Dizziness = 'dizziness',
  Fainting = 'fainting',
  Seizures = 'seizures',
  Weakness = 'weakness',
  Tremor = 'tremor',
  Numbness = 'numbness',
  Tingling = 'tingling',
  BalanceTrouble = 'balance trouble',
  LossOfConsciousness = 'loss of consciousness',
  Drowsiness = 'Drowsiness',
  SlurredSpeech = 'Slurred speech',
  SpeechProblems = 'speech problems (other)',
  DifficultyWalking = 'difficulty walking',
  FacialDrooping = 'facial drooping',
  MemoryLoss = 'memory loss',
  MentalConfusion = 'mental confusion',
  Paralysis = 'paralysis',
}

export enum Psychiatric {}

export enum Endocrine {
  HeatOrColdIntolerance = 'Heat or cold intolerance',
  ExcessiveSweating = 'Excessive sweating',
  Polyuria = 'polyuria',
  ExcessiveThirstOrHunger = 'excessive thirst or hunger',
}

export enum HematologicOrLymphatic {
  Anemia = 'anemia',
  EasyBruising = 'easy bruising',
  EasyBleeding = 'easy bleeding',
  RedSpotsOnSkin = 'red spots on skin',
  LymphNodeEnlargement = 'lymph node enlargement',
}

export enum AllergicOrImmunologic {
  Reactions = 'Reactions to food, drugs, other',
  Hives = 'Hives',
  HayFever = 'Hay fever',
}

export enum Section {
  Constitutional = 'Constitutional',
  Eyes = 'Eyes',
  ENTEars = 'ENT - Ears',
  ENTNoseAndSinuses = 'ENT - Nose And Sinuses',
  ENTMouthAndPharynx = 'ENT - Mouth And Pharynx',
  ENTNeck = 'ENT - Neck',
  Cardiovascular = 'Cardiovascular',
  Respiratory = 'Respiratory',
  Gastrointestinal = 'Gastrointestinal',
  GenitourinaryMale = 'Genitourinary (Male)',
  GenitourinaryFemale = 'Genitourinary (Female)',
  Musculoskeletal = 'Musculoskeletal',
  Integumentary = 'Integumentary',
  Neurological = 'Neurological',
  Psychiatric = 'Psychiatric',
  Endocrine = 'Endocrine',
  HematologicOrLymphatic = 'Hematologic / Lymphatic',
  AllergicOrImmunologic = 'Allergic / Immunologic',
}

export const SectionFieldMap: Record<Section, Record<string, string>> = {
  [Section.Constitutional]: Constitutional,
  [Section.Eyes]: Eyes,
  [Section.ENTEars]: ENTEars,
  [Section.ENTNoseAndSinuses]: ENTNoseAndSinuses,
  [Section.ENTMouthAndPharynx]: ENTMouthAndPharynx,
  [Section.ENTNeck]: ENTNeck,
  [Section.Cardiovascular]: Cardiovascular,
  [Section.Respiratory]: Respiratory,
  [Section.Gastrointestinal]: Gastrointestinal,
  [Section.GenitourinaryMale]: GenitourinaryMale,
  [Section.GenitourinaryFemale]: GenitourinaryFemale,
  [Section.Musculoskeletal]: Musculoskeletal,
  [Section.Integumentary]: Integumentary,
  [Section.Neurological]: Neurological,
  [Section.Psychiatric]: Psychiatric,
  [Section.Endocrine]: Endocrine,
  [Section.HematologicOrLymphatic]: HematologicOrLymphatic,
  [Section.AllergicOrImmunologic]: AllergicOrImmunologic,
}

export const Normal = Object.fromEntries(
  Object.keys(Section).map((section) => [section, null])
)
