import React, { useState } from 'react'

import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import { CheckboxOptionType } from 'antd/es/checkbox'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import generatePicker from 'antd/lib/date-picker/generatePicker'
import { NamePath } from 'antd/lib/form/interface'
import { addMinutes, format, isValid } from 'date-fns'
import { Rule, RuleObject } from 'rc-field-form/lib/interface'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'

import { convertTime12to24 } from '../../libs/utils'
import { TestId } from '../../shared-types'
import {
  END_DAY_FIELD,
  END_HOUR_FIELD,
  START_DAY_FIELD,
  START_HOUR_FIELD,
} from '../Scheduling/EventModal/EventModal'
import InputNumber from './InputNumber'
import MultiSelect from './MultiSelect'
import { TimeSelector } from './TimeSelector'
import { detectBadCSVValues } from './helpers/validationRules'

const { Option, OptGroup } = Select

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig)

export interface SelectOptions {
  name: string
  value: string | any
  addLine?: boolean
  grouped?: boolean
  groupItems?: Record<string, any>[]
  disableItem?: boolean
  addOnAfterType?: string
  addOnAfterLabel?: string
  defaultValue?: string | boolean | number
  className?: string
  prefillValues?: Record<string, any>[]
  min?: number
  onChange?: (value: any, form: FormInstance) => void
}

export interface MultiSelectOptions {
  name: string
  value: string
  label: string
}

export interface FormField {
  hidden?: boolean
  label?: string
  name: string
  type?: string
  validationMessage?: string
  validator?: (form: FormInstance, value: any) => Promise<void | never>
  required?: boolean
  placeholder?: string
  pattern?: RegExp
  defaultValue?: string | Date | boolean | number
  selectOptions?: SelectOptions[]
  groupOptions?: FormField[]
  multiSelectOptions?: MultiSelectOptions[]
  checkboxOptions?: CheckboxOptionType[]
  minuteStep?: number
  sharedRow?: boolean
  rowmates?: FormField[]
  prefillValues?: Record<string, any>[]
  className?: string
  startHour?: number
  endHour?: number
  useTitleProp?: boolean
  showSearch?: boolean
  allowClear?: boolean
  value?: string | boolean
  hideLabel?: boolean
  min?: number
  onChange?: (value: any, form: FormInstance) => void
  addonAfter?: string
  nestedInput?: FormField
  dependencies?: NamePath[]
  dynamicRequired?: (form: FormInstance) => void
  defaultChecked?: boolean
}

type FormProps = {
  layout: 'vertical' | 'horizontal' | 'inline'
  fields: FormField[]
  formName: string
  handleSubmit: (values: any) => void
  size: SizeType
  initialValues?: Record<string, any>
  setHasChanges?: (changes: boolean, hasError?: boolean) => void
  onChangesSaveValue?: (form: FormInstance) => void
} & TestId

const FormComponent: React.FC<FormProps> = ({
  layout,
  fields,
  formName,
  handleSubmit,
  size,
  initialValues,
  setHasChanges,
  onChangesSaveValue,
  testId,
}) => {
  const [refresh, setRefresh] = useState([]) as any
  const [form] = Form.useForm()

  // updates the form values when a select change is made
  const handleSelectChange = (
    value: any,
    name: string,
    prefillValues: Record<string, any>[] | undefined = undefined
  ) => {
    form.setFieldsValue({ [name]: value })
    onChangesSaveValue && onChangesSaveValue(form)
    // if theres no value we are clearing a select field and we do not want to
    // run the prefill value flow
    if (!value) return
    // prepopulate other fields on the form if this selection has prefillValues
    if (prefillValues) {
      const prefillMatch = prefillValues.find((p) => p.value === value)
      if (!prefillMatch) {
        throw new Error(
          `No prefill match found for ${value} among prefillValues ${prefillValues}`
        )
      }

      // iterate over every key, representing a form field, in the prefill object and update
      // the value of that form field with the prefill value
      for (const field of Object.keys(prefillMatch)) {
        // if it is a duration update, we need to add time to the forms 'end' field
        // using the date field (for e.g. used when we select an appointment type when creating
        // an event and want to adjust the end time of that event based on the appointment type)
        if (field === 'duration') {
          const duration = prefillMatch[field]
          const startHour = convertTime12to24(
            form.getFieldValue(START_HOUR_FIELD)
          )
          const startDay = format(
            new Date(form.getFieldValue(START_DAY_FIELD)),
            'MM/dd/yyyy'
          )
          const dayAndTime = new Date(`${startDay} ${startHour}`)
          const endTime = addMinutes(dayAndTime, duration)
          if (isValid(endTime)) {
            const endDay = new Date(format(endTime, 'MM/dd/yyyy'))
            const endHour = format(endTime, 'h:mmaaa')
            form.setFieldsValue({
              [END_DAY_FIELD]: endDay,
              [END_HOUR_FIELD]: endHour,
            })
          }
        } else {
          form.setFieldsValue({ [field]: prefillMatch[field] })
        }
      }
    }
    // update state so the new values of the form are shown
    setRefresh([...refresh, [0]])
  }

  const getDatePicker = (
    field: FormField | SelectOptions,
    disabled = false
  ) => {
    if (!form.getFieldValue(field.name)) {
      form.setFieldsValue({ [field.name]: field.defaultValue })
    }
    return (
      <Space direction="vertical" className={field.className}>
        <DatePicker
          allowClear={false}
          data-testid={testId ? `${testId}-${field.name}` : field.name}
          getPopupContainer={(trigger) =>
            trigger?.parentElement || document.body
          }
          value={form.getFieldValue(field.name)}
          onChange={(value) => {
            field.onChange && field.onChange(value, form)
            return handleSelectChange(value, field.name, field.prefillValues)
          }}
          disabled={disabled}
        />
      </Space>
    )
  }

  const renderFormItem = (field: FormField, disabled = false) => {
    const getNestedInput = (field: FormField, disabled: boolean) => {
      const rules: Rule[] = [
        {
          pattern: field.pattern || /^(\s|\S){0,}$/,
          required: !!(
            (field.required ||
              (field.dynamicRequired && field.dynamicRequired(form))) &&
            !field.hidden
          ),
          message: field.validationMessage,
          whitespace: false,
        },
        {
          validator: detectBadCSVValues,
        },
      ]
      if (field.type === 'input') {
        ;(rules[0] as RuleObject).whitespace = true
      }
      if (typeof field.validator === 'function') {
        const fieldValidator = () => ({
          validator(_: any, value: any) {
            // check included to appease typescript
            if (field.validator) return field.validator(form, value)
            return Promise.resolve()
          },
        })
        rules.push(fieldValidator)
      }
      return (
        <Form.Item
          key={`form-item-${field.name}`}
          className={field.className}
          label={!field.hideLabel && field.label}
          name={field.name}
          rules={rules}
          hidden={field.hidden}
          dependencies={field.dependencies}
        >
          {renderFormItem(field, disabled)}
        </Form.Item>
      )
    }

    const dataTestId = testId ? `${testId}-${field.name}` : field.name

    if (field.type === 'input') {
      return (
        <Input
          className={field.className}
          placeholder={field.placeholder}
          data-testid={dataTestId}
          onChange={(e: any) => {
            if (field.onChange) {
              field.onChange(e.target.value, form)
            }
          }}
        />
      )
    } else if (field.type === 'select') {
      if (!field.selectOptions) {
        throw new Error(
          'selectOptions are required in fields to use type select'
        )
      }

      if (!form.getFieldValue(field.name) && field.defaultValue === undefined) {
        form.setFieldsValue({ [field.name]: field.defaultValue })
      }
      return (
        <Select
          className={field.className}
          data-testid={dataTestId}
          optionLabelProp={field.useTitleProp ? 'title' : 'children'}
          placeholder={field.placeholder}
          value={form.getFieldValue(field.name)}
          onChange={(value) => {
            field.onChange && field.onChange(value, form)
            handleSelectChange(value, field.name, field.prefillValues)
          }}
          showSearch={field.showSearch}
          allowClear={field.allowClear}
          filterOption={
            field.showSearch
              ? (input, option) => {
                  const { children = '' } = option ?? {}
                  if (!children || typeof children !== 'string') return false
                  const lowerCase = (children as string).toLowerCase()
                  return lowerCase.indexOf(input.toLowerCase()) >= 0
                }
              : false
          }
        >
          {field.selectOptions.map((opt) => {
            if (opt.addLine) {
              return (
                <React.Fragment key={`select-divider-${opt.value}`}>
                  <Option value={opt.value} style={{ marginTop: 4 }}>
                    {opt.name}
                  </Option>
                  <Option
                    disabled
                    value="null"
                    style={{ margin: 0, padding: 0 }}
                  >
                    <hr />
                  </Option>
                </React.Fragment>
              )
            } else if (opt.grouped) {
              return (
                <OptGroup
                  className={field.className}
                  key={`opt-group-label-${opt.name}-${opt.value}`}
                  label={opt.name}
                >
                  {opt.groupItems?.map((item) => (
                    <Option
                      key={`opt-group-option-${opt.name}-${opt.value}-${item.value}-${item.name}`}
                      title={`${opt.name} - ${item.name}`}
                      name={item.name}
                      value={item.value}
                      disabled={
                        opt.disableItem && item.value === '' && item.name === ''
                      }
                    >
                      {item.name}
                    </Option>
                  ))}
                </OptGroup>
              )
            } else {
              return (
                <Option
                  className={field.className}
                  key={`select-option-${opt.value}-${opt.name}`}
                  value={opt.value}
                  style={{ marginTop: 4 }}
                >
                  {opt.name}
                </Option>
              )
            }
          })}
        </Select>
      )
    } else if (field.type === 'textArea') {
      return (
        <Input.TextArea
          placeholder={field.placeholder}
          data-testid={dataTestId}
        />
      )
    } else if (field.type === 'timeselector') {
      if (!field.minuteStep) {
        throw new Error(
          'minuteStep are required in fields to use type timeselector'
        )
      }
      return (
        <TimeSelector
          testId={dataTestId}
          placeholder={field.placeholder}
          value={form.getFieldValue(field.name)}
          minuteStep={field.minuteStep}
          startHour={field.startHour}
          endHour={field.endHour}
          name={field.name}
          form={form}
          className={field.className}
          onChange={(value) => field.onChange && field.onChange(value, form)}
        />
      )
    } else if (field.type === 'multiSelect') {
      if (!field.multiSelectOptions) {
        throw new Error(
          'multiSelectOptions are required in fields to use type multiSelect'
        )
      }
      return (
        <MultiSelect
          testId={dataTestId}
          options={field.multiSelectOptions}
          handleChange={(value) =>
            handleSelectChange(value, field.name, field.prefillValues)
          }
          placeholder={field.placeholder}
          value={form.getFieldValue(field.name)}
          className={field.className}
        />
      )
    } else if (field.type === 'datepicker') {
      return getDatePicker(field, disabled)
    } else if (field.type === 'checkbox') {
      return (
        <Checkbox
          data-testid={dataTestId}
          onChange={({ target: { checked } }) => {
            field.onChange && field.onChange(checked, form)
            form.setFieldsValue({ [field.name]: checked })
          }}
          defaultChecked={field.defaultChecked}
        >
          {field.label}
        </Checkbox>
      )
    } else if (field.type === 'numberinput') {
      return (
        <InputNumber
          testId={dataTestId}
          addonAfter={field.addonAfter}
          min={field.min}
          disabled={disabled}
        />
      )
    } else if (field.type === 'radioGroup') {
      return (
        <Radio.Group
          data-testid={dataTestId}
          onChange={({ target: { value } }) =>
            handleSelectChange(value, field.name)
          }
        >
          <Space direction="vertical">
            {field.groupOptions?.map((option, index) => {
              const fieldValue = form.getFieldValue(field.name)
              return (
                <Row
                  align="middle"
                  onClick={() => handleSelectChange(option.value, field.name)}
                  key={`radio-group-${field.name}-${index}`}
                >
                  <Col>
                    <Radio value={option.value}>{option.name}</Radio>{' '}
                  </Col>
                  <Col>
                    {option.nestedInput &&
                      getNestedInput(
                        option.nestedInput,
                        option.nestedInput.name !== fieldValue
                      )}
                  </Col>
                </Row>
              )
            })}
          </Space>
        </Radio.Group>
      )
    } else if (field.type === 'checkboxgroup') {
      return (
        <Checkbox.Group
          data-testid={dataTestId}
          options={field.checkboxOptions}
          onChange={(value) => field.onChange && field.onChange(value, form)}
        />
      )
    } else if (field.type === 'password') {
      return <Input type="password" data-testid={dataTestId} />
    }
  }

  return (
    <Form
      data-testid={testId}
      form={form}
      layout={layout}
      onFinish={handleSubmit}
      name={formName}
      size={size}
      initialValues={initialValues}
      // if needed, using this prop will tell your component when a form has been edited
      onFieldsChange={() => {
        setHasChanges
          ? (_changed: unknown, allFields: { errors?: string[] }[]) => {
              setHasChanges(
                true,
                !!allFields?.find(
                  (field) => field?.errors && field.errors.length > 0
                )
              )
            }
          : undefined
        onChangesSaveValue && onChangesSaveValue(form)
      }}
    >
      {fields.map((field) => {
        if (field.sharedRow) {
          if (!field.rowmates) {
            throw new Error('rowmates are required to use sharedRows')
          }
          const spanNum = 24 / (field.rowmates.length + 1)
          return (
            <Row gutter={20} key={`form-row-${field.name}`}>
              <Col span={spanNum}>
                <Form.Item
                  className={field.className}
                  label={field.label}
                  name={field.name}
                  hidden={field.hidden}
                  rules={[
                    {
                      required: field.required,
                      message: field.validationMessage,
                    },
                    {
                      validator: detectBadCSVValues,
                    },
                  ]}
                  dependencies={field.dependencies}
                >
                  {renderFormItem(field)}
                </Form.Item>
              </Col>
              {field.rowmates.map((mate) => {
                const rules: Rule[] = [
                  {
                    required: mate.required,
                    message: mate.validationMessage,
                  },
                  {
                    validator: detectBadCSVValues,
                  },
                ]
                if (typeof mate.validator === 'function') {
                  const fieldValidator = () => ({
                    validator(_: any, value: any) {
                      // check included to appease typescript
                      if (mate.validator) return mate.validator(form, value)
                      return Promise.resolve()
                    },
                  })
                  rules.push(fieldValidator)
                }
                return (
                  <Col key={`rowmate-column-${mate.name}`} span={spanNum}>
                    <Form.Item
                      className={field.className}
                      label={!mate.hideLabel && mate.label}
                      name={mate.name}
                      hidden={field.hidden}
                      rules={rules}
                      dependencies={mate.dependencies}
                    >
                      {renderFormItem(mate)}
                    </Form.Item>
                  </Col>
                )
              })}
            </Row>
          )
        } else {
          const rules: Rule[] = [
            {
              pattern: field.pattern || /^(\s|\S){0,}$/,
              required: field.required && !field.hidden,
              message: field.validationMessage,
              whitespace: field.type === 'input',
            },
            {
              validator: detectBadCSVValues,
            },
          ]
          if (typeof field.validator === 'function') {
            const fieldValidator = () => ({
              validator(_: any, value: any) {
                if (field.validator) return field.validator(form, value)
                return Promise.resolve()
              },
            })
            rules.push(fieldValidator)
          }
          return (
            <Form.Item
              key={`form-item-${field.name}`}
              className={field.className}
              label={!field.hideLabel && field.label}
              name={field.name}
              rules={rules}
              hidden={field.hidden}
              dependencies={field.dependencies}
            >
              {renderFormItem(field)}
            </Form.Item>
          )
        }
      })}
    </Form>
  )
}

export default FormComponent
