import { loadProviderInfo } from '../../api/api-lib'
import {
  getLoggedinUserRoles,
  getPermissionCookie,
} from '../../api/permissions'
import { RoleTitles } from '../../shared-types'
import getCognitoUser from '../../shared/Helpers/getCognitoUser'
import { logError } from '../errorLib'

type FreshpaintObject = Record<string, unknown>
type FreshpaintCallback = (args: unknown) => unknown

declare global {
  interface Window {
    freshpaint?: {
      /**
       * These are just the mose commonly used ones on our app, there are more
       * here: https://documentation.freshpaint.io/developer-docs/freshpaint-sdk-reference
       */
      track(
        eventName: string,
        properties?: FreshpaintObject,
        options?: FreshpaintObject,
        callback?: FreshpaintCallback
      ): void
      identify(properties?: FreshpaintObject): void
      identify(
        uniqueId?: string,
        properties?: FreshpaintObject,
        options?: FreshpaintObject,
        callback?: FreshpaintCallback
      ): void
      addEventProperties(properties: FreshpaintObject): void
      page(
        category?: string,
        name?: string,
        properties?: FreshpaintObject,
        options?: FreshpaintObject,
        callback?: FreshpaintCallback
      ): void
      group(
        uniqueId: string,
        properties?: FreshpaintObject,
        options?: FreshpaintObject,
        callback?: FreshpaintCallback
      ): void
    }
  }
}

function parseCreatedAt(rawCreatedAt: string): string {
  try {
    return new Date(Date.parse(rawCreatedAt)).toISOString()
  } catch (e) {
    return rawCreatedAt
  }
}

export async function setFreshpaintUserAndGroupProperties(
  providerData: any = null
) {
  // Adding template string to var so that linting does not blow up
  // Secondary accounts will not have a firstname/lastname so we instead
  // check if its a secondary account and then set to a default
  const provider = await getCognitoUser()
  const userId = provider.id

  let data
  if (providerData) {
    data = providerData
  } else {
    console.log('freshpaint')
    data = await loadProviderInfo()
  }
  const providerRole = userId === data.providerId ? 'Primary' : 'Secondary'

  const {
    clinicData,
    providerName,
    providerEmail,
    appSettings,
    isTestAccount,
    tier,
  } = data

  // Get permission and role data
  const permissionCookie = await getPermissionCookie()

  const roles: RoleTitles[] = await getLoggedinUserRoles()

  const rolesStringArray = roles.map((role) => {
    return role.label
  })

  // CreatedAt is created on backend using ``Date()` which is a string representation
  // of the value and not an actual ISO timestamp.
  const createdAt = parseCreatedAt(clinicData.CreatedAt)

  window?.freshpaint?.identify(userId, {
    name: providerName,
    email: providerEmail,
    city: clinicData.ProviderPracticeCity,
    region: clinicData.ProviderPracticeState,
    practiceName: clinicData.PracticeName,
    credential: clinicData.ProviderCredential,
    role: providerRole,
    osmindUrl: clinicData.osmindUrl,
    isErxEnabled: appSettings.erx,
    isJournalEnabled: appSettings.journal,
    isSecureMessagingEnabled: appSettings.secureMessaging,
    isCommunityEnabled: appSettings.community,
    isSuperbillSubmissionEnabled: appSettings.superbillSubmission,
    clinicId: data.providerId,
    permissions: permissionCookie?.role,
    roles: rolesStringArray,
    paidSeats: clinicData.PaidSeats,
    isTestAccount: isTestAccount,
    tier: tier,
  })

  window?.freshpaint?.group(data.providerId, {
    clinicId: data.providerId,
    name: clinicData.PracticeName,
    createdAt: createdAt,
  })

  window?.freshpaint?.addEventProperties({
    clinicId: data.providerId,
    providerId: userId,
  })
}

export async function setFreshpaintUserAndGroupPropertiesWrapper(
  providerData: any = null
) {
  try {
    await setFreshpaintUserAndGroupProperties(providerData)
  } catch (e) {
    logError(e)
  }
}

export function logFlags(userId: string, flags: { [flagKey: string]: any }) {
  const freshpaintFeatureFlagData = Object.keys(flags).reduce(
    (output, flag) => {
      output[`flag-${flag}`] = flags[flag]
      return output
    },
    {} as { [key: string]: any }
  )

  window?.freshpaint?.identify(userId, freshpaintFeatureFlagData)
}
