import React, { useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import { cloneDeep } from 'lodash'

import SkeletonLoadingTransition from '../../../components/Animation/SkeletonLoadingTransition'
import {
  BillingInfo,
  PracticeDataType,
} from '../../../hooks/usePatientProfile/shared-types'
import { Location, Teammate } from '../../../shared-types'
import Fields, { FieldGroupProps } from '../../../stories/PatientProfile/Fields'
import {
  handleBillingInfoUpdate,
  populateBillinInfo,
} from './InsuranceClaim-helper'
import {
  billingInfoComponents,
  billingProviderCopmponent,
  billingProviderTaxonomyCodeComponent,
} from './constants'

import styles from './InsuranceClaim.module.scss'

export interface PatientConditionRelationProps {
  testId?: string
  loading: boolean
  billingInfo: BillingInfo
  setBillingInfo: Function
  locations?: Location[]
  teammates: Teammate[]
  practiceData: Omit<PracticeDataType, 'CreatedAt'>
  renderingProviderId: string | null
  disabled: boolean
}

interface OptionsType {
  label: string
  value?: string
  disabled?: boolean
}

const Loading = () => (
  <div className={styles.skeletonContainer}>
    <Skeleton
      active
      title={{ width: 85 }}
      paragraph={{
        rows: 3,
        width: '100%',
        className: styles.skeletonParagraph,
      }}
    />
  </div>
)

const BillingClaim: React.FC<PatientConditionRelationProps> = ({
  testId,
  billingInfo,
  setBillingInfo,
  locations = [],
  teammates,
  practiceData,
  loading,
  renderingProviderId,
  disabled = false,
}) => {
  const [billingComponents, setBillingComponents] = useState<FieldGroupProps[]>(
    billingInfoComponents
  )

  useEffect(() => {
    const itemsCopy: any = cloneDeep(billingInfoComponents)
    if (!billingInfo?.type) {
      const noTypeComponent = itemsCopy[0].items.slice(0, 1)
      itemsCopy[0].items = noTypeComponent
    }
    if (!loading && billingInfo) {
      if (billingInfo.type) {
        const locationOptions: OptionsType[] = []
        locations.forEach((l) => {
          locationOptions.push({
            label: l.Name ? l.Name : 'Location',
            value: l.LocationId,
          })
        })
        itemsCopy[0].items[2].options = locationOptions
      }
      if (billingInfo.type === 'Provider') {
        const activeTeammates = teammates.filter((t) => !t.isDeactivated)
        const teammatesOptions = activeTeammates.map((t) => {
          return {
            value: t.cognitoId,
            label: `${t.name ? t.name : t.email}`,
            disabled: t.lastActive === 'never_logged_in',
          }
        })
        const providerComponent = cloneDeep(billingProviderCopmponent)
        const providerTaxonomyCode = cloneDeep(
          billingProviderTaxonomyCodeComponent
        )
        // @ts-ignore
        providerComponent.options = teammatesOptions
        itemsCopy[0].items[1] = providerComponent
        itemsCopy[0].items.push(providerTaxonomyCode)
      } else if (billingInfo.type === 'Practice') {
        itemsCopy[0].items[6].options = [
          {
            value: 'EIN',
            label: 'EIN',
          },
        ]
      }
      if (!billingInfo?.taxType) {
        const idsToRemove = ['EIN', 'SSN']
        const noTaxTypeComponent = itemsCopy[0].items.filter(
          (obj: { id: string }) => !idsToRemove.includes(obj.id)
        )
        itemsCopy[0].items = noTaxTypeComponent
      } else {
        const idToRemove = billingInfo?.taxType === 'EIN' ? 'SSN' : 'EIN'
        const noTaxTypeComponent = itemsCopy[0].items.filter(
          (obj: { id: string }) => obj.id !== idToRemove
        )
        itemsCopy[0].items = noTaxTypeComponent
      }
      populateBillinInfo(itemsCopy, billingInfo)
    }
    setBillingComponents(itemsCopy)
  }, [billingInfo, loading])

  const handleSave = (
    newValue: any,
    id: string,
    _groupName: string,
    _label?: string
  ) => {
    const newVal: any =
      typeof newValue === 'string' ? newValue.trim() : newValue
    const copyBilingInfo = cloneDeep(billingInfo)
    const shouldSave = handleBillingInfoUpdate(
      newVal,
      id,
      copyBilingInfo,
      teammates,
      practiceData,
      locations,
      renderingProviderId
    )

    if (shouldSave) {
      setBillingInfo(copyBilingInfo)
    }
  }

  return (
    <SkeletonLoadingTransition
      isLoading={loading}
      skeletonComponent={<Loading />}
      loadedComponent={
        <div className={styles.topMargin}>
          <Fields
            testId={testId}
            items={billingComponents}
            compact={false}
            handleSave={handleSave}
            isClaim
            disableAll={disabled}
          />
        </div>
      }
    />
  )
}

export default BillingClaim
