import { Space, Typography } from 'antd'

import { Divider, Progress } from '../../BaseComponents'
import { SuccessTitle } from './helpers'

import sharedStyles from '../../../containers/_shared.module.scss'
import styles from './_shared.module.scss'

const { Title, Text } = Typography

const PatientIntake = () => {
  return (
    <div className={sharedStyles.scroll}>
      <Progress
        className={styles.progressBar}
        percent={100}
        showInfo={false}
        trailColor="white"
      />
      <div className={styles.intakeFormClass}>
        <SuccessTitle
          titleText="All done!"
          description="Our clinical team will do a careful review of the information in order to personalize the care that we provide for you and make sure it is of highest quality and follows evidence-based practices."
        />
        <Divider />
        <Title level={3}>Next Steps</Title>
        <Space className={styles.descriptionText}>
          <Text>
            Your next step is to log in to the Osmind mobile app to fill out the
            questionnaires we've assigned to you. These will serve as your
            baseline measurements to help us track how you are progressing
            throughout your care journey with us.
          </Text>
        </Space>
        <Space className={styles.descriptionText}>
          <Text>
            Please take a moment to log into the app and complete your
            questionnaires. We have already sent you an invite (you will need
            the invite to join the system).
          </Text>
        </Space>
        <Space className={`${styles.descriptionText} ${styles.paddingBottom}`}>
          <Text>
            Please contact{' '}
            <a href="mailto:helpdesk@osmind.org" target="_blank">
              helpdesk@osmind.org
            </a>{' '}
            if you need help getting into the system.
          </Text>
        </Space>
      </div>
    </div>
  )
}

export default PatientIntake
