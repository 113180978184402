import React, { useEffect, useState } from 'react'

import moment from 'moment'
import 'moment-timezone'
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  ToggleButton,
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-dates/initialize'

import './DatePicker.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dates/lib/css/_datepicker.css'

const TREATMENTTYPES = [
  { name: 'Therapy', value: 'Therapy' },
  { name: 'TMS', value: 'TMS' },
  { name: 'ECT', value: 'ECT' },
  { name: 'Ketamine', value: 'Ketamine' },
  {
    name: 'Other e.g. self-help groups (e.g. AA), rehab programs, other services',
    value:
      'Other e.g. self-help groups (e.g. AA), rehab programs, other services',
  },
]

const dateFormats = [{ format: 'MM/YYYY' }, { format: 'YYYY' }]

export default function ShowTreatments(props) {
  const [treatmentType, setTreatmentType] = useState(props.TreatmentType || '')
  const [therapyType, setTherapyType] = useState(props.TherapyType || '')
  const [numberSessions, setNumberSessions] = useState(
    props.NumberSessions || ''
  )
  const [startDate, setStartDate] = useState(props.StartDate || '')
  const [endDate, setEndDate] = useState(props.EndDate || '')
  const [reasonForTreatment, setReasonForTreatment] = useState(
    props.ReasonForTreatment || ''
  )
  const [treatmentEfficacy, setTreatmentEfficacy] = useState(
    props.TreatmentEfficacy || ''
  )
  const [nameOfClinician, setNameOfClinician] = useState(
    props.NameOfClinician || ''
  )
  const [additionalNotes, setAdditionalNotes] = useState(
    props.AdditionalNotes || ''
  )

  const [diagnosisOnsetDateFormat, setDiagnosisOnsetDateFormat] = useState(
    props.DateFormat ? props.DateFormat : 'MM/YYYY'
  )
  const [diagnosisOnsetDate, setDiagnosisOnsetDate] = useState(null)

  useEffect(() => {
    setTreatmentType(props.TreatmentType || '')
    setTherapyType(props.TherapyType || '')
    setNumberSessions(props.NumberSessions || '')
    setStartDate(props.StartDate || '')
    setEndDate(props.EndDate || '')
    setReasonForTreatment(props.ReasonForTreatment || '')
    setTreatmentEfficacy(props.TreatmentEfficacy || '')
    setNameOfClinician(props.NameOfClinician || '')
    setAdditionalNotes(props.AdditionalNotes || '')
    setDiagnosisOnsetDateFormat(props.DateFormat ? props.DateFormat : 'MM/YYYY')
  }, [
    props.TreatmentType,
    props.TherapyType,
    props.NumberSessions,
    props.StartDate,
    props.EndDate,
    props.ReasonForTreatment,
    props.TreatmentEfficacy,
    props.NameOfClinician,
    props.AdditionalNotes,
  ])

  useEffect(() => {
    const fields = {
      treatmentType: treatmentType,
      therapyType: therapyType,
      numberSessions: numberSessions,
      startDate: startDate,
      endDate: endDate,
      reasonForTreatment: reasonForTreatment,
      treatmentEfficacy: treatmentEfficacy,
      nameOfClinician: nameOfClinician,
      additionalNotes: additionalNotes,
      dateFormat: diagnosisOnsetDateFormat,
    }
    props.saveTreatment(fields, props.index)
  }, [
    treatmentType,
    therapyType,
    numberSessions,
    startDate,
    endDate,
    reasonForTreatment,
    additionalNotes,
    nameOfClinician,
    treatmentEfficacy,
    diagnosisOnsetDateFormat,
  ])

  function handleDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        setDiagnosisOnsetDate(date)
        let newOnsetDate = diagnosisOnsetDate
        newOnsetDate = date.toDate()
        setStartDate(newOnsetDate)
      } else {
        const newOnsetDate = moment(date, diagnosisOnsetDateFormat)
        setDiagnosisOnsetDate(newOnsetDate)
        setStartDate(date)
        console.log('SavedDate: ', date)
      }
    } else {
      setStartDate('')
    }
  }
  function handleEndDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        setDiagnosisOnsetDate(date)
        let newOnsetDate = diagnosisOnsetDate
        newOnsetDate = date.toDate()
        setEndDate(newOnsetDate)
      } else {
        const newOnsetDate = moment(date, diagnosisOnsetDateFormat)
        setDiagnosisOnsetDate(newOnsetDate)
        setEndDate(date)
      }
    } else {
      setEndDate('')
    }
  }

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} xs={6} sm={6} md={3} lg={3} xl={3}>
          <Form.Label className="patient">Treatment Type</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="select"
            value={treatmentType}
            disabled={props.disabled}
            onChange={(e) => setTreatmentType(e.target.value)}
          >
            <option key={0} name="Select one" value="">
              Select one
            </option>
            {TREATMENTTYPES.map((type, index) => (
              <option key={index + 1} name={type.name} value={type.value}>
                {type.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {treatmentType === 'Therapy' && (
          <Form.Group as={Col} xs={6} sm={6} md={3} lg={3} xl={3}>
            <Form.Label className="patient">Therapy Type</Form.Label>
            <Form.Control
              bsPrefix="form-input"
              as="textarea"
              rows={1}
              value={therapyType}
              disabled={props.disabled}
              onChange={(e) => setTherapyType(e.target.value)}
            />
          </Form.Group>
        )}
        {treatmentType !== 'Therapy' && (
          <Form.Group as={Col} xs={6} sm={6} md={3} lg={3} xl={3}>
            <Form.Label className="patient">
              Number of courses/sessions
            </Form.Label>
            <Form.Control
              bsPrefix="form-input"
              type="number"
              value={numberSessions}
              disabled={props.disabled}
              onChange={(e) => setNumberSessions(e.target.value)}
            />
          </Form.Group>
        )}
        <Form.Group as={Col} xs={6} sm={6} md={3} lg={3} xl={3}>
          <Form.Label className="patient">Reason for Treatment</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="textarea"
            rows={1}
            value={reasonForTreatment}
            disabled={props.disabled}
            onChange={(e) => setReasonForTreatment(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} xs={6} sm={6} md={3} lg={3} xl={3}>
          <Form.Label className="patient">Name of Clinician</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="textarea"
            rows={1}
            value={nameOfClinician}
            disabled={props.disabled}
            onChange={(e) => setNameOfClinician(e.target.value)}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={6} sm={6} md={2} lg={2} xl={2}>
          <Row style={{ width: 'min-content' }}>
            <Col>
              <Form.Label style={{ fontWeight: 'bold' }}>Start</Form.Label>
            </Col>
            <Col>
              <ButtonGroup style={{ marginLeft: -14 }} toggle size="sm">
                {dateFormats.map((btn, idx) => (
                  <ToggleButton
                    className="date-format-selector"
                    variant="primary"
                    key={idx}
                    type="radio"
                    name="radio"
                    value={btn.format}
                    style={{ fontSize: '10px' }}
                    checked={diagnosisOnsetDateFormat === btn.format}
                    onChange={(e) =>
                      setDiagnosisOnsetDateFormat(e.currentTarget.value)
                    }
                  >
                    {btn.format}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Col>
          </Row>

          <Form.Group controlId="DiagnosisOnsetDatePicker">
            {diagnosisOnsetDateFormat === 'MM/YYYY' && (
              <DatePicker
                className="date-input"
                selected={startDate === '' ? '' : new Date(startDate)}
                placeholderText={diagnosisOnsetDateFormat}
                onChange={(date) => handleDate(date)}
                maxDate={new Date()}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            )}

            {diagnosisOnsetDateFormat === 'YYYY' && (
              <DatePicker
                className="date-input"
                selected={startDate === '' ? '' : new Date(startDate)}
                placeholderText={diagnosisOnsetDateFormat}
                onChange={(date) => handleDate(date)}
                maxDate={new Date()}
                dateFormat="yyyy"
                showYearPicker
              />
            )}
          </Form.Group>
        </Form.Group>
        <Form.Group as={Col} xs={6} sm={6} md={2} lg={2} xl={2}>
          <Form.Label className="patient">End</Form.Label>
          {diagnosisOnsetDateFormat === 'MM/YYYY' && (
            <DatePicker
              className="date-input"
              selected={endDate === '' ? '' : new Date(endDate)}
              placeholderText={diagnosisOnsetDateFormat}
              onChange={(date) => handleEndDate(date)}
              maxDate={new Date()}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          )}

          {diagnosisOnsetDateFormat === 'YYYY' && (
            <DatePicker
              className="date-input"
              selected={endDate === '' ? '' : new Date(endDate)}
              placeholderText={diagnosisOnsetDateFormat}
              onChange={(date) => handleEndDate(date)}
              maxDate={new Date()}
              dateFormat="yyyy"
              showYearPicker
            />
          )}
        </Form.Group>
        <Form.Group as={Col} xs={6} sm={6} md={4} lg={4} xl={4}>
          <Form.Label className="patient">Treatment Efficacy</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="textarea"
            rows={1}
            value={treatmentEfficacy}
            disabled={props.disabled}
            onChange={(e) => setTreatmentEfficacy(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} xs={6} sm={6} md={4} lg={4} xl={4}>
          <Form.Label className="patient">Additional Notes</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="textarea"
            rows={1}
            value={additionalNotes}
            disabled={props.disabled}
            onChange={(e) => setAdditionalNotes(e.target.value)}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          style={{ alignSelf: 'center', textAlign: 'center' }}
        >
          <Button
            bsPrefix="center plain-button info button-label"
            onClick={() => {
              props.deleteTreatment(props.index)
            }}
          >
            <i className="fa fa-trash" aria-hidden="true" />
            &nbsp;Delete
          </Button>
        </Form.Group>
      </Form.Row>
      <hr />
    </>
  )
}
