import { ReactNode, createContext, useContext } from 'react'

import { AppointmentType } from '../../../components/Scheduling/AppointmentSettings'
import {
  LocationItem,
  RoomItem,
} from '../../../containers/Authentication/Locations'
import { useSchedulingData } from '../../../hooks/useSchedulingData'
import { LocationWithRoomInfo } from '../../../hooks/useSchedulingData/useSchedulingData'
import { useSchedulingFilters } from '../../../hooks/useSchedulingFilters'
import { CalendarCheckbox } from '../../../hooks/useSchedulingFilters/useSchedulingFilters.helpers'
import { Teammate } from '../../../shared-types'
import {
  EventCategory,
  UserCalendarAccount,
} from '../../../stories/Scheduling/types'

type SchedulingContext = {
  teammatesSelected: Set<string>
  handleToggleCalendarSelected: (teammateId: string) => () => void
  roomsSelected: Set<string>
  handleToggleRoomSelected: (roomId: string) => () => void
  appointmentTypesSelected: Set<string>
  handleToggleAppointmentTypesSelected: (apptTypeId: string) => () => void
  isUnassignedSelected: boolean
  handleToggleUnassignedSelected(): void
  patientIdSelected: string
  handleUpdatePatientIdSelected(id: string): void
  teammates: Teammate[] | undefined
  isSchedulingDataLoading: boolean
  activeTeammates: Teammate[]
  personalCalendarCheckboxes: CalendarCheckbox
  teammateCalendarCheckboxes: CalendarCheckbox
  integratedExternalCalendarIds: string[]
  teammateCalendars: UserCalendarAccount[] | undefined
  rooms: RoomItem[] | undefined
  nonDeletedRooms: RoomItem[]
  locations: LocationItem[] | undefined
  nonDeletedLocations: LocationItem[]
  appointmentSettings:
    | {
        appointmentTypes: AppointmentType[]
      }
    | undefined
  appointmentTypes: AppointmentType[]
  appointmentTypesOptionsSet: Set<string>
  roomsSelectOptions: EventCategory[]
  appointmentTypesOptions: EventCategory[]
  teammatesSelectOptions: EventCategory[]
  locationsWithRoomInformation: LocationWithRoomInfo
  providerId: string
  handleDeselectAllAppointmentTypes(): void
  handleSelectAllAppointmentTypes(): void
  handleDeselectAllRooms(): void
  handleSelectAllRooms(): void
  handleDeselectAllTeammates(): void
  handleSelectAllTeammates(): void
}
const SchedulingContext = createContext<SchedulingContext | null>(null)

export const SchedulingProvider = ({
  children,
  providerId,
}: {
  children: ReactNode
  providerId: string
}) => {
  const {
    teammates,
    activeTeammates,
    integratedExternalCalendarIds,
    teammateCalendars,
    rooms,
    nonDeletedRooms,
    locations,
    nonDeletedLocations,
    appointmentSettings,
    appointmentTypes,
    locationsWithRoomInformation,
    isSchedulingDataLoading,
  } = useSchedulingData()

  const {
    teammatesSelected,
    handleToggleCalendarSelected,
    handleToggleRoomSelected,
    roomsSelected,
    appointmentTypesSelected,
    handleToggleAppointmentTypesSelected,
    handleToggleUnassignedSelected,
    isUnassignedSelected,
    handleUpdatePatientIdSelected,
    patientIdSelected,
    roomsSelectOptions,
    appointmentTypesOptions,
    teammatesSelectOptions,
    personalCalendarCheckboxes,
    teammateCalendarCheckboxes,
    handleDeselectAllAppointmentTypes,
    handleSelectAllAppointmentTypes,
    handleDeselectAllRooms,
    handleSelectAllRooms,
    handleDeselectAllTeammates,
    appointmentTypesOptionsSet,
    handleSelectAllTeammates,
  } = useSchedulingFilters({
    teammateCalendars,
    rooms,
    locationsWithRoomInformation,
    appointmentTypes,
    activeTeammates,
    providerId,
    isSchedulingDataLoading,
  })

  return (
    <SchedulingContext.Provider
      value={{
        isSchedulingDataLoading,
        teammatesSelected,
        personalCalendarCheckboxes,
        teammateCalendarCheckboxes,
        handleToggleCalendarSelected,
        handleToggleRoomSelected,
        roomsSelected,
        appointmentTypesSelected,
        handleToggleAppointmentTypesSelected,
        isUnassignedSelected,
        handleToggleUnassignedSelected,
        patientIdSelected,
        handleUpdatePatientIdSelected,
        teammates,
        activeTeammates: activeTeammates ?? [],
        integratedExternalCalendarIds,
        teammateCalendars,
        rooms,
        nonDeletedRooms,
        locations,
        nonDeletedLocations,
        roomsSelectOptions,
        appointmentSettings,
        appointmentTypes: appointmentTypes ?? [],
        appointmentTypesOptions,
        appointmentTypesOptionsSet,
        teammatesSelectOptions,
        locationsWithRoomInformation,
        providerId,
        handleDeselectAllAppointmentTypes,
        handleSelectAllAppointmentTypes,
        handleDeselectAllRooms,
        handleSelectAllRooms,
        handleDeselectAllTeammates,
        handleSelectAllTeammates,
      }}
    >
      {children}
    </SchedulingContext.Provider>
  )
}

export const useSchedulingContext = () => {
  const context = useContext(SchedulingContext)
  if (!context) {
    throw new Error(
      'useSchedulingContext should be used within a SchedulingProvider'
    )
  }

  return context
}
