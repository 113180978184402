import { FormikErrors } from 'formik'

import { convertCentsToDollars } from '../../../../libs/billing'
import {
  MAXIMUM_MEMO_LENGTH,
  MINIMUM_INPUT_NUMBER_VALUE,
  PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS,
  PAYMENT_FORM_OPTIONS,
  PAYMENT_METHOD_TYPES,
  PAYMENT_TYPE,
} from '../../../../libs/constants'
import { containsACreditCardNumber } from '../../../../libs/utils'
import { CreditUsageByPaymentMethod } from '../payment-attribution'
import { PaymentFormValues } from './AddPaymentForm'

export const validatePaymentForm = (values: PaymentFormValues) => {
  const errors: FormikErrors<PaymentFormValues> = {}
  const selectedEntity = values.selectedInvoice ?? values.selectedClaim
  const amountCentsDue =
    values.selectedInvoice?.amountCentsDue ??
    values.selectedClaim?.patientResponsibilityAmountCents ??
    0
  if (
    values.billableEntitySubOption ===
    PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS.CUSTOM_AMOUNT
  ) {
    if (values.customAmount < MINIMUM_INPUT_NUMBER_VALUE) {
      errors.customAmount = 'Custom amount must be at least $1.'
    } else if (
      values.paymentType === PAYMENT_TYPE.INVOICE &&
      selectedEntity &&
      values.customAmount > amountCentsDue
    ) {
      errors.customAmount = `Custom amount cannot exceed ${values.paymentType} total.`
    }
  }

  if (!values.paymentType) {
    errors.paymentType = 'Please select a payment type.'
  }

  if (
    values.paymentMethodType !== PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD &&
    !values.paymentMethod &&
    values.totalAppliedCredit < values.totalChargeAmountCents
  ) {
    errors.paymentMethod = 'Please select a payment method.'
  }

  if (
    values.paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD &&
    !values.paymentMethod &&
    values.totalAppliedCredit < values.totalChargeAmountCents
  ) {
    errors.paymentMethod = 'Please select credit card.'
  }

  const isAddingCredit =
    values.paymentOption === PAYMENT_FORM_OPTIONS.ADD_PATIENT_CREDIT
  if (
    isAddingCredit &&
    values.patientCreditAmount < MINIMUM_INPUT_NUMBER_VALUE
  ) {
    errors.patientCreditAmount = 'Charge amount must be at least $1.'
  }

  if (isAddingCredit && !values.patientCreditAmount) {
    errors.patientCreditAmount = 'Please enter a credit amount.'
  }

  if (values.isApplyingCredit) {
    Object.keys(values.creditUsage).forEach((paymentMethodUuid: string) => {
      const totalAvailable =
        values.creditUsage[paymentMethodUuid]['totalCredit']
      const creditUtilized =
        values.creditUsage[paymentMethodUuid]['creditUtilized']

      if (totalAvailable < creditUtilized) {
        errors.creditUsage =
          errors.creditUsage || ({} as FormikErrors<CreditUsageByPaymentMethod>)

        errors.creditUsage[paymentMethodUuid] = {
          creditUtilized:
            'You entered an amount greater than what is available. Please enter a lower amount.',
        }
      }
    })

    if (values.totalAppliedCredit > values.totalChargeAmountCents) {
      errors.totalAppliedCredit = `Applied credit amount cannot exceed $${convertCentsToDollars(
        values.totalChargeAmountCents
      )}.`
    }
  }

  if (values.memo && values.memo.length > MAXIMUM_MEMO_LENGTH) {
    errors.memo = `Memo must be ${MAXIMUM_MEMO_LENGTH} characters or fewer.`
  }

  if (values.memo && containsACreditCardNumber(values.memo)) {
    errors.memo = `Cannot save credit card numbers in the memo field.`
  }

  return errors
}
