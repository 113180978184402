// Import react
import React, { useEffect, useMemo, useState } from 'react'

// Import libs/other
import { API } from 'aws-amplify'
import moment from 'moment'
// Import components
import { Button, Col, Form, Modal } from 'react-bootstrap'

import { onError } from '../../libs/errorLib'
import { useFormFields } from '../../libs/hooksLib'
import { notification } from '../../libs/notificationLib'

// Import styles
import './MedicationForms.scss'

const NewActiveMedication = (props) => {
  const [show, setShow] = useState(false)
  const [disableEdit, setDisableEdit] = useState(false)
  const administrationRoute = [
    'Oral',
    'Sublingual',
    'Topical',
    'IV',
    'IM',
    'Nasal',
    'Other (specify)',
  ]

  const rangeArr = !props.medicationType
    ? props.medicine?.DateRange?.split(' - ') ?? ['', '']
    : ' - '
  const start = rangeArr[0] ? moment(rangeArr[0]).format('YYYY-MM-DD') : ''
  const end = rangeArr[1] ? moment(rangeArr[1]).format('YYYY-MM-DD') : ''

  const [providerEndDate, setProviderEndDate] = useState(
    props.medicine.ProviderEndDate ? props.medicine.ProviderEndDate : ''
  )
  const [providerStartDate, setProviderStartDate] = useState(
    props.medicine.ProviderStartDate ? props.medicine.ProviderStartDate : ''
  )

  const initialFields = useMemo(() => {
    return {
      PatientId: props.medicine.PatientId,
      Administration: props.medicine.Administration
        ? props.medicine.Administration === 'Oral' ||
          props.medicine.Administration === 'Sublingual' ||
          props.medicine.Administration === 'Topical' ||
          props.medicine.Administration === 'IV' ||
          props.medicine.Administration === 'IM' ||
          props.medicine.Administration === 'Nasal'
          ? props.medicine.Administration
          : 'Other (specify)'
        : '',
      Dosage: props.medicine.Dosage ? props.medicine.Dosage : '',
      AdministrationIntervalInDays: props.medicine.AdministrationIntervalInDays
        ? props.medicine.AdministrationIntervalInDays
        : '',
      DosesRemainingEstimate: props.medicine.DosesRemainingEstimate
        ? props.medicine.DosesRemainingEstimate
        : '',
      MedicationName: props.medicine.MedicationName,
      AdditionalInstructions: props.medicine.AdditionalInstructions
        ? props.medicine.AdditionalInstructions
        : '',
      StartDate: props.medicationType ? props.medicine.StartDate : start,

      MedicationType: props.medicationType,

      PrescribedByProvider: props.medicine.PrescribedByProvider
        ? props.medicine.PrescribedByProvider
        : false,
      EndDate: props.medicationType ? props.medicine.EndDate : end,
      AdministrationOther: props.medicine.AdministrationOther
        ? props.medicine.AdministrationOther
        : props.medicine.Administration === 'Oral' ||
          props.medicine.Administration === 'Sublingual' ||
          props.medicine.Administration === 'Topical' ||
          props.medicine.Administration === 'IV' ||
          props.medicine.Administration === 'IM' ||
          props.medicine.Administration === 'Nasal' ||
          props.medicine.Administration === 'Other (specify)'
        ? ''
        : props.medicine.Administration,
      SideEffects: props.medicine.SideEffects ? props.medicine.SideEffects : '',
      MentalPsychiatricConditions: props.medicine.MentalPsychiatricConditions
        ? props.medicine.MentalPsychiatricConditions
        : false,
      ReasonForUse: props.medicine.ReasonForUse
        ? props.medicine.ReasonForUse
        : '',
      PatientPerspectiveEfficacy: props.medicine.PatientPerspectiveEfficacy
        ? props.medicine.PatientPerspectiveEfficacy
        : '',
      ReasonDiscontinued: props.medicine.ReasonDiscontinued
        ? props.medicine.ReasonDiscontinued
        : '',
      DisallowMedicationAdherence: props.medicine.DisallowMedicationAdherence
        ? props.medicine.DisallowMedicationAdherence
        : false,
      Comments: props.medicine.Comments ? props.medicine.Comments : '',
      Frecuency: props.medicine.Frecuency ? props.medicine.Frecuency : '',
    }
  }, [props.medicine])
  const [fields, handleFieldChange] = useFormFields(initialFields)

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  function putEditActiveMedication(data) {
    data.UpdateEnum = 'EditActive'
    return API.post('api', '/meds', {
      body: data,
    })
  }

  function putEditPastMedication(data) {
    data.UpdateEnum = 'EditPast'
    return API.post('api', '/meds', {
      body: data,
    })
  }

  async function handleAddNewActiveMed(event) {
    event.preventDefault()

    try {
      if (fields.MedicationType) {
        if (
          !fields.DisallowMedicationAdherence &&
          (!fields.MedicationName ||
            !fields.AdministrationIntervalInDays ||
            !fields.StartDate)
        ) {
          notification('Please, fill all the required information.')
          setDisableEdit(false)
          return
        } else if (
          fields.DisallowMedicationAdherence &&
          !fields.MedicationName
        ) {
          notification('Please, fill all the required information.')
          setDisableEdit(false)
          return
        }
        await putEditActiveMedication({
          PatientId: fields.PatientId,
          ActiveMedicationId: props.medicine.ActiveMedicationId,
          Administration: fields.Administration,
          Dosage: fields.Dosage,
          AdministrationIntervalInDays: fields.AdministrationIntervalInDays,
          DosesRemainingEstimate: fields.DosesRemainingEstimate,
          MedicationName: fields.MedicationName,
          AdditionalInstructions: fields.AdditionalInstructions,
          StartDate: fields.StartDate,

          PrescribedByProvider: fields.PrescribedByProvider,
          AdministrationOther: fields.AdministrationOther,
          SideEffects: fields.SideEffects,
          MentalPsychiatricConditions: fields.MentalPsychiatricConditions,
          ReasonForUse: fields.ReasonForUse,
          PatientPerspectiveEfficacy: fields.PatientPerspectiveEfficacy,
          ReasonDiscontinued: fields.ReasonDiscontinued,
          DisallowMedicationAdherence: fields.DisallowMedicationAdherence,
          Comments: fields.Comments,
          Frecuency: fields.Frecuency,
          ProviderStartDate: providerStartDate,
        })
        props.handleApiChange()
        setDisableEdit(false)
        setShow(false)
      } else {
        if (!fields.MedicationName) {
          notification('Please, fill all the required information.')
          setDisableEdit(false)
          return
        }
        await putEditPastMedication({
          PatientId: fields.PatientId,
          PastMedicationId: props.medicine.PastMedicationId,
          Administration: fields.Administration,
          DateRange: `${fields.StartDate.toString()} - ${fields.EndDate.toString()}`,
          Dosage: fields.Dosage,
          AdministrationIntervalInDays: fields.AdministrationIntervalInDays,
          MedicationName: fields.MedicationName,
          PrescribedByProvider: fields.PrescribedByProvider,
          AdministrationOther: fields.AdministrationOther,
          SideEffects: fields.SideEffects,
          MentalPsychiatricConditions: fields.MentalPsychiatricConditions,
          ReasonForUse: fields.ReasonForUse,
          PatientPerspectiveEfficacy: fields.PatientPerspectiveEfficacy,
          ReasonDiscontinued: fields.ReasonDiscontinued,
          Comments: fields.Comments,
          Frecuency: fields.Frecuency,
          ProviderEndDate: providerEndDate,
          ProviderStartDate: providerStartDate,
        })
        props.handleApiChange()
        setDisableEdit(false)
        setShow(false)
      }

      notification(
        `You have successfully edited ${fields.MedicationName}.`,
        'success'
      )
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
      setDisableEdit(false)
    }
  }

  useEffect(() => {
    handleFieldChange(false, initialFields)
  }, [initialFields])

  return (
    <span>
      <Button
        style={{ display: 'contents' }}
        bsPrefix="button larger right"
        onClick={handleShow}
      >
        Edit
      </Button>

      <Modal size="xl" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title dialogClassName="modal-header">
            <Form.Row>
              Edit&nbsp;
              <span className="emphasis">
                {props.medicationType ? 'active' : 'past'}
              </span>
              &nbsp;medication.
            </Form.Row>
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group>
              <span className="danger">*</span>
              <Form.Label className="form-label">Required.</Form.Label>
            </Form.Group>

            <Form.Row>
              <Form.Group
                as={Col}
                xs={12}
                sm={12}
                lg={6}
                xl={6}
                controlId="MedicationName"
              >
                <Form.Label
                  className="form-label"
                  style={{ fontWeight: 'bold' }}
                >
                  Medication name:
                </Form.Label>
                <span className="danger"> *</span>
                <Form.Control
                  bsPrefix="form-input"
                  onChange={handleFieldChange}
                  placeholder="Ketamine, esketamine, prozac, etc"
                  required
                  type="text"
                  value={fields.MedicationName}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xs={12}
                sm={12}
                lg={6}
                xl={6}
                controlId="PrescribedByProvider"
              >
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Prescribed by you?
                </Form.Label>
                <Form.Check
                  bsPrefix="form-check"
                  checked={JSON.parse(fields.PrescribedByProvider)}
                  style={{ marginLeft: '10px' }}
                  id="PrescribedByProviderYes"
                  onChange={() =>
                    handleFieldChange(false, {
                      ...fields,
                      PrescribedByProvider: true,
                    })
                  }
                  type="radio"
                  label="Yes"
                />
                <Form.Check
                  bsPrefix="form-check"
                  checked={!JSON.parse(fields.PrescribedByProvider)}
                  style={{ marginLeft: '10px' }}
                  id="PrescribedByProviderNo"
                  onChange={() =>
                    handleFieldChange(false, {
                      ...fields,
                      PrescribedByProvider: false,
                    })
                  }
                  type="radio"
                  label="No"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12} sm={12} lg={6} xl={6}>
                <Form.Label
                  className="form-label"
                  style={{ fontWeight: 'bold' }}
                >
                  Start Date:
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  onChange={(e) => setProviderStartDate(e.target.value)}
                  placeholder="MM/YYYY"
                  value={providerStartDate}
                  type="text"
                />
              </Form.Group>

              {fields.MedicationType ? (
                ''
              ) : (
                <Form.Group as={Col} xs={12} sm={12} lg={6} xl={6}>
                  <Form.Label
                    className="form-label"
                    style={{ fontWeight: 'bold' }}
                  >
                    End Date:
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    onChange={(e) => setProviderEndDate(e.target.value)}
                    placeholder="MM/YYYY"
                    value={providerEndDate}
                    type="text"
                  />
                </Form.Group>
              )}
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                xs={12}
                sm={12}
                lg={4}
                xl={4}
                controlId="Dosage"
              >
                <Form.Label
                  className="form-label"
                  style={{ fontWeight: 'bold' }}
                >
                  Dosage:
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  placeholder="Specify number and units (e.g 15 mg)"
                  onChange={handleFieldChange}
                  required
                  type="text"
                  value={fields.Dosage}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xs={12}
                sm={12}
                lg={4}
                xl={4}
                controlId="Frecuency"
              >
                <Form.Label
                  className="form-label"
                  style={{ fontWeight: 'bold' }}
                >
                  Frequency:
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  onChange={handleFieldChange}
                  required
                  as="textarea"
                  rows={1}
                  value={fields.Frecuency}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                xs={12}
                sm={12}
                lg={4}
                xl={4}
                controlId="Administration"
              >
                <Form.Label
                  className="form-label"
                  style={{ fontWeight: 'bold' }}
                >
                  Administration:
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  onChange={handleFieldChange}
                  as="select"
                  required
                  type="text"
                  value={fields.Administration}
                >
                  <option key={0} name="Select one" value="">
                    Select one
                  </option>
                  {administrationRoute.map((medType, index) => (
                    <option key={index + 1} name={medType} value={medType}>
                      {medType}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            {fields.Administration !== 'Other (specify)' ? (
              ''
            ) : (
              <Form.Row>
                <Form.Group as={Col} controlId="AdministrationOther">
                  <Form.Label
                    className="form-label"
                    style={{ fontWeight: 'bold' }}
                  >
                    Other (Administration):
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    required
                    onChange={handleFieldChange}
                    value={fields.AdministrationOther}
                  />
                </Form.Group>
              </Form.Row>
            )}

            <Form.Row>
              <Form.Group as={Col} controlId="SideEffects">
                <Form.Label
                  className="form-label"
                  style={{ fontWeight: 'bold' }}
                >
                  Side Effects:
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  required
                  onChange={handleFieldChange}
                  value={fields.SideEffects}
                  as="textarea"
                  rows="1"
                />
              </Form.Group>
            </Form.Row>

            <Form.Group
              xs={12}
              sm={12}
              lg={12}
              xl={12}
              controlId="MentalPsychiatricConditions"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>
                Is this medication for mental health or psychiatric conditions?
              </Form.Label>
              <Form.Check
                bsPrefix="form-check"
                checked={JSON.parse(fields.MentalPsychiatricConditions)}
                style={{ marginLeft: '10px' }}
                id="MentalPsychiatricConditionsYes"
                onChange={() =>
                  handleFieldChange(false, {
                    ...fields,
                    MentalPsychiatricConditions: true,
                  })
                }
                type="radio"
                label="Yes"
              />
              <Form.Check
                bsPrefix="form-check"
                checked={!JSON.parse(fields.MentalPsychiatricConditions)}
                style={{ marginLeft: '10px' }}
                id="MentalPsychiatricConditionsNo"
                onChange={() =>
                  handleFieldChange(false, {
                    ...fields,
                    MentalPsychiatricConditions: false,
                  })
                }
                type="radio"
                label="No"
              />
            </Form.Group>

            {!fields.MentalPsychiatricConditions ? (
              ''
            ) : (
              <div>
                <Form.Row>
                  <Form.Group
                    as={Col}
                    xs={12}
                    sm={12}
                    lg={6}
                    xl={6}
                    controlId="ReasonForUse"
                  >
                    <Form.Label
                      className="form-label"
                      style={{ fontWeight: 'bold' }}
                    >
                      Reason for use:
                    </Form.Label>
                    <Form.Control
                      bsPrefix="form-input"
                      required
                      onChange={handleFieldChange}
                      value={fields.ReasonForUse}
                      as="textarea"
                      rows="1"
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    xs={12}
                    sm={12}
                    lg={6}
                    xl={6}
                    controlId="PatientPerspectiveEfficacy"
                  >
                    <Form.Label
                      className="form-label"
                      style={{ fontWeight: 'bold' }}
                    >
                      Patient perspective of efficacy:
                    </Form.Label>
                    <Form.Control
                      bsPrefix="form-input"
                      required
                      onChange={handleFieldChange}
                      value={fields.PatientPerspectiveEfficacy}
                      as="textarea"
                      rows="1"
                    />
                  </Form.Group>
                </Form.Row>
              </div>
            )}

            {fields.MedicationType ? <hr className="style-one" /> : ''}

            {!fields.MedicationType ? (
              ''
            ) : (
              <div>
                <Form.Group
                  xs={12}
                  sm={12}
                  lg={12}
                  xl={12}
                  controlId="DisallowMedicationAdherence"
                >
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Use medication adherence module?
                  </Form.Label>
                  <Form.Check
                    bsPrefix="form-check"
                    checked={!JSON.parse(fields.DisallowMedicationAdherence)}
                    style={{ marginLeft: '10px' }}
                    id="DisallowMedicationAdherenceYes"
                    onChange={() =>
                      handleFieldChange(false, {
                        ...fields,
                        DisallowMedicationAdherence: false,
                      })
                    }
                    type="radio"
                    label="Yes"
                  />
                  <Form.Check
                    bsPrefix="form-check"
                    checked={JSON.parse(fields.DisallowMedicationAdherence)}
                    style={{ marginLeft: '10px' }}
                    id="DisallowMedicationAdherenceNo"
                    onChange={() =>
                      handleFieldChange(false, {
                        ...fields,
                        DisallowMedicationAdherence: true,
                      })
                    }
                    type="radio"
                    label="No"
                  />
                </Form.Group>

                {fields.DisallowMedicationAdherence ? (
                  ''
                ) : (
                  <div>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        xs={12}
                        sm={12}
                        lg={6}
                        xl={6}
                        controlId="StartDate"
                      >
                        <Form.Label
                          className="form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          Reminder start date:
                        </Form.Label>
                        {fields.MedicationType &&
                        !fields.DisallowMedicationAdherence ? (
                          <span className="danger"> *</span>
                        ) : (
                          ''
                        )}
                        <Form.Control
                          bsPrefix="form-input"
                          onChange={handleFieldChange}
                          placeholder=""
                          required
                          type="date"
                          value={fields.StartDate}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        controlId="AdministrationIntervalInDays"
                      >
                        <Form.Label
                          className="form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          Administration Interval In Days:
                        </Form.Label>
                        {fields.MedicationType &&
                        !fields.DisallowMedicationAdherence ? (
                          <span className="danger"> *</span>
                        ) : (
                          ''
                        )}
                        <Form.Control
                          bsPrefix="form-input"
                          required
                          type="number"
                          onChange={handleFieldChange}
                          value={fields.AdministrationIntervalInDays}
                          placeholder="Must be a number (e.g. 1 = daily, 2 = every two days, etc)"
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group
                        as={Col}
                        xs={12}
                        sm={12}
                        lg={6}
                        xl={6}
                        controlId="DosesRemainingEstimate"
                      >
                        <Form.Label
                          className="form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          Doses Remaining Estimate:
                        </Form.Label>
                        <Form.Control
                          bsPrefix="form-input"
                          required
                          type="number"
                          onChange={handleFieldChange}
                          value={fields.DosesRemainingEstimate}
                          placeholder="Must be a number"
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        xs={12}
                        sm={12}
                        lg={6}
                        xl={6}
                        controlId="AdditionalInstructions"
                      >
                        <Form.Label
                          className="form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          Additional Instructions:
                        </Form.Label>
                        <Form.Control
                          bsPrefix="form-input"
                          type="text"
                          onChange={handleFieldChange}
                          value={fields.AdditionalInstructions}
                          placeholder="Write a journal entry, etc"
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>
                )}
              </div>
            )}
            {fields.MedicationType ? <hr className="style-one" /> : ''}
            {fields.MedicationType ? (
              <Form.Row>
                <Form.Group as={Col} controlId="Comments">
                  <Form.Label
                    className="form-label"
                    style={{ fontWeight: 'bold' }}
                  >
                    Comments:
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    type="text"
                    onChange={handleFieldChange}
                    value={fields.Comments}
                    as="textarea"
                    rows="1"
                    placeholder="E.g. prescribing provider, reason prescribed, etc."
                  />
                </Form.Group>
              </Form.Row>
            ) : (
              <Form.Row>
                <Form.Group
                  as={Col}
                  xs={12}
                  sm={12}
                  lg={6}
                  xl={6}
                  controlId="ReasonDiscontinued"
                >
                  <Form.Label
                    className="form-label"
                    style={{ fontWeight: 'bold' }}
                  >
                    Reason discontinued:
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    required
                    onChange={handleFieldChange}
                    value={fields.ReasonDiscontinued}
                    as="textarea"
                    rows="1"
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  sm={12}
                  lg={6}
                  xl={6}
                  controlId="Comments"
                >
                  <Form.Label
                    className="form-label"
                    style={{ fontWeight: 'bold' }}
                  >
                    Comments:
                  </Form.Label>
                  <Form.Control
                    bsPrefix="form-input"
                    type="text"
                    onChange={handleFieldChange}
                    value={fields.Comments}
                    as="textarea"
                    rows="1"
                    placeholder="E.g. prescribing provider, reason prescribed, etc."
                  />
                </Form.Group>
              </Form.Row>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              bsPrefix="button-block"
              disabled={disableEdit}
              onClick={(e) => {
                setDisableEdit(true)
                handleAddNewActiveMed(e)
              }}
            >
              <i className="fa fa-plus-circle" />
              &nbsp; Save Edit
            </Button>
            <Button bsPrefix="button-close" onClick={handleClose}>
              <i className="fa fa-times" />
              &nbsp; Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </span>
  )
}

export default NewActiveMedication
