import { DateISO8601 } from '../../../shared-types'
import { GenderIdentityDropDownValues } from '../../../shared/Demographics'
import { mapCodesetValues } from '../../../stories/PatientIntake/sections/helpers'

export enum LegalSex {
  MALE = 'M',
  FEMALE = 'F',
  X = 'X',
  UNKNOWN = 'Unknown',
}

export enum Relationship {
  PARENT = 'Parent',
  RELATIVE = 'Relative',
  SPOUSE = 'Spouse',
  SIBLING = 'Sibling',
  FRIEND = 'Friend',
  OTHER = 'Other',
}

export enum SpecificEthnicity {
  SPANIARD = 'Spaniard',
  MEXICAN = 'Mexican',
  CENTRAL_AMERICAN = 'Central American',
  SOUTH_AMERICAN = 'South American',
  LATIN_AMERICAN = 'Latin American',
  PUERTO_RICAN = 'Puerto Rican',
  CUBAN = 'Cuban',
  DOMINICAN = 'Dominican',
  ANDALUSIAN = 'Andalusian',
  ASTURIAN = 'Asturian',
  CASTILLIAN = 'Castillian',
  CATALONIAN = 'Catalonian',
  BELEARIC_ISLANDER = 'Belearic Islander',
  GALLEGO = 'Gallego',
  VALENCIAN = 'Valencian',
  CANARIAN = 'Canarian',
  SPANISH_BASQUE = 'Spanish Basque',
  MEXICAN_AMERICAN = 'Mexican American',
  MEXICANO = 'Mexicano',
  CHICANO = 'Chicano',
  LA_RAZA = 'La Raza',
  MEXICAN_AMERICAN_INDIAN = 'Mexican American Indian',
  COSTA_RICAN = 'Costa Rican',
  GUATEMALAN = 'Guatemalan',
  HONDURAN = 'Honduran',
  NICARAGUAN = 'Nicaraguan',
  PANAMANIAN = 'Panamanian',
  SALVADORAN = 'Salvadoran',
  CENTRAL_AMERICAN_INDIAN = 'Central American Indian',
  CANAL_ZONE = 'Canal Zone',
  ARGENTINEAN = 'Argentinean',
  BOLIVIAN = 'Bolivian',
  CHILEAN = 'Chilean',
  COLOMBIAN = 'Colombian',
  ECUADORIAN = 'Ecuadorian',
  PARAGUAYAN = 'Paraguayan',
  PERUVIAN = 'Peruvian',
  URUGUAYAN = 'Uruguayan',
  VENEZUELAN = 'Venezuelan',
  SOUTH_AMERICAN_INDIAN = 'South American Indian',
  CRIOLLO = 'Criollo',
}

export enum YesNo {
  YES = 'Yes',
  NO = 'No',
}

export interface RCopia {
  patientPublicId: string
  PatientFirstName: string
  PatientMiddleName?: string
  PatientLastName: string
  DateOfBirth?: Date | null
  AddressLine1: string
  AddressLine2: string
  City: string
  State: string
  ZipCode: string
  PatientGender: string
  PatientPhoneNumber: string
  PatientHomePhoneNumber: string
}
interface DateField {
  date?: string
  format: string
}
interface EmergencyContact {
  emergencyName: string
  emergencyPhone: string
  emergencyEmail: string
  emergencyRelationship?: Relationship[]
  emergencyRelationshipOther?: string
  emergencyAuthorization: YesNo
}

interface MentalHealthProviders {
  mentalHealthProviderName: string
  mentalHealthProviderPhone: string
  mentalHealthProviderEmail: string
  mentalHealthProviderCity: string
  mentalHealthProviderAuth: YesNo
  mentalHealthProviderNotes: string
}

interface OtherHealthcareProviders {
  otherHealthProviderName: string
  otherHealthProviderPhone: string
  otherHealthProviderEmail: string
  otherHealthProviderCity: string
  otherHealthProviderAuth: YesNo
  otherHealthProviderNotes: string
}

const genderIdentity = GenderIdentityDropDownValues.map(mapCodesetValues)
export type GenderIdentity = typeof genderIdentity[number]
export interface GeneralHistoryResponse {
  summary: string
  firstName: string
  middleName: string
  lastName: string
  firstNameToUse: string
  middleNameToUse: string
  lastNameToUse: string
  formerFirstName: string
  formerMiddleName: string
  formerLastName: string
  pronouns: string[]
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zipCode: string
  email: string
  cellPhone: string
  homePhone: string
  workPhone: string
  legalSex: LegalSex
  genderIdentity: GenderIdentity
  raceEthnicity: string
  emergencyContacts: EmergencyContact[]
  primaryInsuredRelationship: string
  primaryInsuredFirstName: string
  primaryInsuredLastName: string
  primaryInsuredDateOfBirth: DateField
  primaryInsuredLegalSex: string
  primaryInsuredEmail: string
  primaryInsuredPhone: string
  primaryInsuredAddressLine1: string
  primaryInsuredAddressLine2: string
  primaryInsuredCity: string
  primaryInsuredState: string
  primaryInsuredZipCode: string
  isPrimaryInsuranceHolder: YesNo
  insuranceName: string
  insuranceMemberId: string
  insuranceGroupId: string
  primaryProviderName: string
  primaryProviderPhone: string
  primaryProviderEmail: string
  primaryProviderCity: string
  primaryProviderAuth: YesNo
  primaryProviderNotes: string
  mentalHealthcareProviders: MentalHealthProviders[]
  otherHealthcareProviders: OtherHealthcareProviders[]
  preferredPharmacy: string
  notes: string
  createdAt?: DateISO8601
  CreatedAt?: DateISO8601
}
export interface VitalsQRCodeProps {
  shortPublicId: string
  handleCaretakerVitalsCancel: () => void
  visible: boolean
}
