import React from 'react'

import { Button, Col, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Anxiety(props) {
  const { patientId = '', providerId = '' } = props

  if (props.patientData.NoAnxiety) {
    return (
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Anxiety:
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="NoAnxiety"
            label="No relevant history in this section"
            checked={!!props.patientData.NoAnxiety}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NoAnxiety: !props.patientData.NoAnxiety,
              })
            }}
          />
        </Form.Group>
      </Form.Row>
    )
  }
  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Anxiety:
          </Form.Label>
        </Form.Group>
        {props.patientData.GeneralizedAnxietyDisorder.state === false &&
        props.patientData.PanicDisorder.state === false &&
        props.patientData.Phobias.state === false &&
        props.patientData.OtherAnxiety.state === false &&
        props.patientData.AnxietyAdditionalNotes === '' ? (
          <Form.Group as={Col} sm={12} md={12} lg={12}>
            <Form.Check
              type="checkbox"
              id="NoAnxiety"
              label="No relevant history in this section"
              checked={JSON.parse(props.patientData.NoAnxiety)}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoAnxiety: !props.patientData.NoAnxiety,
                })
              }}
            />
          </Form.Group>
        ) : (
          ''
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Diagnoses
          </Form.Label>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.GeneralizedAnxietyDisorder.state ? 2 : 12}
          lg={props.patientData.GeneralizedAnxietyDisorder.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="GeneralizedAnxietyDisorder"
            label="Generalized anxiety disorder"
            disabled={props.patientData.NoAnxiety}
            checked={JSON.parse(
              props.patientData.GeneralizedAnxietyDisorder.state
            )}
            onChange={() => {
              props.setPatientData({
                ...props.patientData,
                GeneralizedAnxietyDisorder: {
                  ...props.patientData.GeneralizedAnxietyDisorder,
                  state: !props.patientData.GeneralizedAnxietyDisorder.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.GeneralizedAnxietyDisorder.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    GeneralizedAnxietyDisorder: {
                      ...props.patientData.GeneralizedAnxietyDisorder,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.GeneralizedAnxietyDisorder.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.PanicDisorder.state ? 2 : 12}
          lg={props.patientData.PanicDisorder.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="PanicDisorder"
            label="Panic disorder"
            disabled={props.patientData.NoAnxiety}
            checked={JSON.parse(props.patientData.PanicDisorder.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                PanicDisorder: {
                  ...props.patientData.PanicDisorder,
                  state: !props.patientData.PanicDisorder.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.PanicDisorder.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    PanicDisorder: {
                      ...props.patientData.PanicDisorder,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.PanicDisorder.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.Phobias.state ? 2 : 12}
          lg={props.patientData.Phobias.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="Phobias"
            label="Phobias"
            disabled={props.patientData.NoAnxiety}
            checked={JSON.parse(props.patientData.Phobias.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                Phobias: {
                  ...props.patientData.Phobias,
                  state: !props.patientData.Phobias.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Phobias.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    Phobias: {
                      ...props.patientData.Phobias,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.Phobias.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.OtherAnxiety.state ? 2 : 12}
          lg={props.patientData.OtherAnxiety.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="OtherAnxiety"
            label="Other"
            disabled={props.patientData.NoAnxiety}
            checked={JSON.parse(props.patientData.OtherAnxiety.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                OtherAnxiety: {
                  ...props.patientData.OtherAnxiety,
                  state: !props.patientData.OtherAnxiety.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OtherAnxiety.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    OtherAnxiety: {
                      ...props.patientData.OtherAnxiety,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.OtherAnxiety.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          style={{ marginTop: '0px' }}
          as={Col}
          sm={12}
          md={12}
          lg={12}
        >
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Symptoms
          </Form.Label>
          <Form.Check
            type="checkbox"
            id="MuscleTension"
            label="Muscle Tension"
            disabled={props.patientData.NoAnxiety}
            checked={JSON.parse(
              props.patientData.AnxietySymptoms.MuscleTension
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                AnxietySymptoms: {
                  ...props.patientData.AnxietySymptoms,
                  MuscleTension:
                    !props.patientData.AnxietySymptoms.MuscleTension,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="SleepDisruption"
            label="Sleep disruption"
            disabled={props.patientData.NoAnxiety}
            checked={JSON.parse(
              props.patientData.AnxietySymptoms.SleepDisruption
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                AnxietySymptoms: {
                  ...props.patientData.AnxietySymptoms,
                  SleepDisruption:
                    !props.patientData.AnxietySymptoms.SleepDisruption,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="PanicAttacks"
            label="Panic attacks"
            disabled={props.patientData.NoAnxiety}
            checked={JSON.parse(props.patientData.AnxietySymptoms.PanicAttacks)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                AnxietySymptoms: {
                  ...props.patientData.AnxietySymptoms,
                  PanicAttacks: !props.patientData.AnxietySymptoms.PanicAttacks,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="SocialAnxiety"
            label="Social anxiety"
            disabled={props.patientData.NoAnxiety}
            checked={JSON.parse(
              props.patientData.AnxietySymptoms.SocialAnxiety
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                AnxietySymptoms: {
                  ...props.patientData.AnxietySymptoms,
                  SocialAnxiety:
                    !props.patientData.AnxietySymptoms.SocialAnxiety,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="CurrentStressEvents"
            label="Events affecting current stress/anxiety or irritability/mood"
            disabled={props.patientData.NoAnxiety}
            checked={JSON.parse(
              props.patientData.AnxietySymptoms.CurrentStressEvents
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                AnxietySymptoms: {
                  ...props.patientData.AnxietySymptoms,
                  CurrentStressEvents:
                    !props.patientData.AnxietySymptoms.CurrentStressEvents,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="OtherAnxietySymptoms"
            label="Other"
            disabled={props.patientData.NoAnxiety}
            checked={JSON.parse(props.patientData.AnxietySymptoms.Other)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                AnxietySymptoms: {
                  ...props.patientData.AnxietySymptoms,
                  Other: !props.patientData.AnxietySymptoms.Other,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.AnxietySymptoms.Other && (
          <Form.Group
            className="indent"
            style={{ marginTop: '0px' }}
            as={Col}
            sm={12}
            md={12}
            lg={12}
          >
            <Form.Control
              as="textarea"
              rows={1}
              bsPrefix="form-input"
              onChange={(e) => {
                props.setPatientData({
                  ...props.patientData,
                  AnxietySymptoms: {
                    ...props.patientData.AnxietySymptoms,
                    OtherText: e.target.value,
                  },
                })
              }}
              value={props.patientData.AnxietySymptoms.OtherText}
            />
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          style={{ textAlign: 'center', marginLeft: 'auto' }}
        >
          <Link
            to={{
              pathname: `/patient/progress?patientId=${patientId}&providerId=${providerId}`,
            }}
            target="_blank"
            style={{ marginLeft: 'auto', marginRight: '7px' }}
          >
            <Button variant="primary">See anxiety questionnaire scores</Button>
          </Link>
          <Link
            to={{
              pathname: `/patient/settings?patientId=${patientId}&providerId=${providerId}`,
            }}
            target="_blank"
            style={{ marginLeft: '7px', marginRight: 'auto' }}
          >
            <Button variant="primary">Administer questionnaires</Button>
          </Link>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional notes
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            bsPrefix="form-input"
            disabled={props.patientData.NoAnxiety}
            onChange={(e) => {
              props.setPatientData({
                ...props.patientData,
                AnxietyAdditionalNotes: e.target.value,
              })
            }}
            value={props.patientData.AnxietyAdditionalNotes}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
