import { ReactNode } from 'react'

import { DownOutlined } from '@ant-design/icons'

import { Dropdown } from '../../../../../stories/BaseComponents'

import styles from './EditorToolbar.module.scss'

export type DropdownSelectorItem = {
  text: string
  key: string
  onClick: () => void
}

export const DropdownSelector = ({
  icon,
  label,
  testId,
  items,
  isLoading,
}: {
  icon: ReactNode
  label: string
  testId?: string
  items: DropdownSelectorItem[]
  isLoading: boolean
}) => {
  return (
    <div className={styles.dropdownWrapper} data-testid={testId}>
      <Dropdown
        trigger={['click']}
        items={items}
        disabled={isLoading}
        testId={`${testId}-dropdown`}
      >
        <div className={styles.dropdown}>
          {icon}
          <span>{label}</span>
          <DownOutlined style={{ fontSize: 16 }} />
        </div>
      </Dropdown>
    </div>
  )
}
