import React, { useEffect, useRef } from 'react'

import SimpleBar from 'simplebar-react'

import './HoverScrollview.scss'
import 'simplebar/src/simplebar.css'

interface HoverScrollviewProps extends React.PropsWithChildren {
  height?: number | string
  onRefCreated?: (args: any) => void
  setScrollTop?: (scrollTop: number) => void
}

const HoverScrollview: React.FC<HoverScrollviewProps> = ({
  children,
  height = 300,
  onRefCreated,
  setScrollTop,
}) => {
  const scrollableNodeRef = useRef()
  useEffect(() => {
    const allWithClass = Array.from(
      document.getElementsByClassName('simplebar-content-wrapper')
    )
    if (allWithClass[0]) allWithClass[0].removeAttribute('tabIndex')
  }, [])

  useEffect(() => {
    if (scrollableNodeRef.current) {
      onRefCreated && onRefCreated(scrollableNodeRef.current)
    }
  }, [scrollableNodeRef])

  return (
    <SimpleBar
      scrollableNodeProps={{
        tabIndex: -1,
        ref: scrollableNodeRef,
        onScroll: (e: any) => {
          setScrollTop && setScrollTop(e.currentTarget.scrollTop)
        },
      }}
      tabIndex={-1}
      style={{ height: height }}
    >
      {children}
    </SimpleBar>
  )
}

export default HoverScrollview
