import React from 'react'

import { blue } from '@ant-design/colors'
import { SearchOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'

import { getTeammateData } from '../../../api/api-lib'
// Replace with structured map of provider list
import { getAppointmentsByDateRange } from '../../../api/appointments'
import { Teammate } from '../../../shared-types'
import { getPatientUrl } from '../../../shared/Helpers/utils'
import SearchBox from '../../BaseComponents/SearchBox'
import { AppointmentsReportData } from '../../BaseComponents/tableData.types'
import { METRICS_HEADER_TOTAL_APPOINTMENTS } from '../constants'
import { GraphBatchingConfigs, GraphFilterConfigs } from '../types'

export default {
  fetch: getAppointmentsByDateRange,
  header: {
    description: 'Explore your practice’s appointments.',
    link: {
      url: 'https://support.osmind.org/en/articles/5665898-reports',
      text: 'Learn more.',
    },
    metrics: [{ title: METRICS_HEADER_TOTAL_APPOINTMENTS, dataSource: 0 }],
    title: 'Appointments',
  },
  graph: {},
  graphFilterConfigs: [] as GraphFilterConfigs[],
  dataBatching: {
    options: [
      { label: 'Appointment type', value: 'type' },
      { label: 'Provider', value: 'osmindProviderId' },
    ],
    fetchKeyAlias: {
      osmindProviderId: async () => {
        const providers: Teammate[] = await getTeammateData()
        return providers.reduce((accum, provider) => {
          if (
            !provider.isDeactivated &&
            provider.lastActive !== 'never_logged_in'
          ) {
            accum[provider.cognitoId] = provider.name || provider.email
          }
          return accum
        }, {} as { [providerId: string]: string })
      },
    },
  } as GraphBatchingConfigs,
  table: {
    columns: [
      {
        title: 'Patient name',
        dataIndex: ['patientName', 'patientPublicId'],
        key: 'patientName',
        render: (_text, record) => (
          <Link
            style={{ color: blue.primary }}
            to={getPatientUrl(record.patientId, record.providerId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record.patientName}
          </Link>
        ),
        sorter: (a, b) => a.patientName.localeCompare(b.patientName),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        filterDropdown: (args) => (
          <SearchBox
            dataIndex="patientName"
            placeholder="Search Patient Name"
            {...args}
          />
        ),
        onFilter: (value, record: AppointmentsReportData) =>
          record.patientName
            ? record.patientName
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            : false,
      },
      {
        title: 'Event name',
        dataIndex: 'eventName',
        key: 'eventName',
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        filterDropdown: (args) => (
          <SearchBox
            dataIndex="eventName"
            placeholder="Search Event Name"
            {...args}
          />
        ),
        onFilter: (value, record: AppointmentsReportData) =>
          record.eventName
            ? record.eventName
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            : false,
        sorter: (a, b) => a.eventName.localeCompare(b.eventName),
      },
      {
        title: 'Appointment type',
        dataIndex: 'type',
        key: 'type',
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        filterDropdown: (args) => (
          <SearchBox
            dataIndex="type"
            placeholder="Search Appointment Type"
            {...args}
          />
        ),
        onFilter: (value, record: AppointmentsReportData) =>
          record.type
            ? record.type
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            : false,
        sorter: (a, b) => a.type.localeCompare(b.type),
        // TODO: Make the constructor more generic to develop filters based on what is getting passed in
        // filters: Object.values(NoteTypes).map(treatment => ({ text: treatment, value: treatment })),
        // onFilter: (value, record) => record.type.indexOf(value as any) === 0,
      },
      // TODO: We need to figure out secundary accounts relationship before we are able to associate it to a provider
      {
        title: 'Provider',
        dataIndex: 'provider',
        key: 'provider',
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        filterDropdown: (args) => (
          <SearchBox
            dataIndex="provider"
            placeholder="Search Provider Name"
            {...args}
          />
        ),
        onFilter: (value, record: AppointmentsReportData) =>
          record.provider
            ? record.provider
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            : false,
        ellipsis: true,
        // sorter: (a, b) => a.provider.localeCompare(b.provider),
        // filters: providerList.map(provider => ({ text: provider, value: provider })),
        // onFilter: (value, record) => record.provider.includes(value as any),
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => a.date.valueOf() - b.date.valueOf(),
        render: (date) =>
          date.toLocaleString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }),
      },
    ] as ColumnsType<AppointmentsReportData>,
    csvHeaders: [
      { label: 'Patient name', key: 'patientName' },
      { label: 'Event name', key: 'eventName' },
      { label: 'Appointment type', key: 'type' },
      { label: 'Provider', key: 'provider' },
      { label: 'Date', key: 'date' },
      { label: 'Time', key: 'time' },
    ],
  },
}
