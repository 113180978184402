import { FC, useEffect, useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'

import {
  QueryKeys,
  useDemographicsContactInfo,
  usePatientCoreIdentifiers,
  useUpdatePatientCoreIdentifiersMutation,
} from '../../hooks/usePatientProfile'
import useQueryString from '../../hooks/useQueryString'
import {
  PatientProfileEvents,
  trackPatientProfileEvent,
} from '../../libs/freshpaint/patientProfileEvents'
import { isValidUrl } from '../../libs/utils'
import { AlertIndicator } from '../BaseComponents'
import { Value } from '../BaseComponents/InlineEditFields/shared-types'
import { getPatientImageUrl } from '../BaseComponents/helpers/imageUploadHelpers'
import PatientTags from '../Patients/PatientTags'
import IdentifyingText from './IdentifyingText'
import ProfilePhoto from './ProfilePhoto'
import Summary from './Summary'

import styles from './CoreIdentifiers.module.scss'

interface CoreIdentifiersProps {
  testId?: string
  collapsed?: boolean
}

const CoreIdentifiers: FC<CoreIdentifiersProps> = ({
  testId,
  collapsed,
}: CoreIdentifiersProps) => {
  const history = useHistory()
  const query = useQueryString()
  const providerId = query.get('providerId') ?? ''
  const patientId = query.get('patientId') ?? ''
  const queryClient = useQueryClient()
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false)

  const { coreIdentifiers: data, isFetching: loading } =
    usePatientCoreIdentifiers({
      patientId,
    })

  useDemographicsContactInfo({
    patientId,
  })

  useEffect(() => {
    if (!data?.birthdateUpdated) {
      return
    }

    queryClient.invalidateQueries([QueryKeys.DEMOGRAPHICS_CONTACT_INFO])
  }, [data])

  const { mutateAsync } = useUpdatePatientCoreIdentifiersMutation({
    patientId,
  })

  const handleSaveSummary = async (value: Value) => {
    trackPatientProfileEvent(PatientProfileEvents.UPDATE_SUMMARY, {
      patientId,
      clinicId: providerId,
    })
    await mutateAsync({ summary: value?.toString() })
  }

  const [imageUrl, setImageUrl] = useState('')

  const patientNameMemo = useMemo(() => data?.patientName, [data?.patientName])

  useEffect(() => {
    const retrieveImageData = async () => {
      setIsImageLoading(true)
      const imgUrl = await getPatientImageUrl(
        providerId,
        data?.patientName ?? ''
      )
      setImageUrl(imgUrl)
      setIsImageLoading(false)
    }
    if (data) {
      retrieveImageData()
    }
  }, [patientNameMemo])

  useEffect(() => {
    if (imageUrl !== '' && !isValidUrl(imageUrl)) {
      console.log('WARNING: image url is not valid')
      setImageUrl('')
    }
  }, [imageUrl])

  return (
    <div id={styles.container} className={cx(collapsed && styles.collapsed)}>
      <div className={styles.topContainer}>
        <ProfilePhoto
          {...data}
          providerId={providerId}
          loading={loading}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          isImageLoading={isImageLoading}
          setIsImageLoading={setIsImageLoading}
          collapsed={collapsed}
        />
        {!collapsed && (
          <IdentifyingText
            loading={loading}
            onClickBirthDate={() => {
              const searchParams = new URLSearchParams(location.search)
              history.push({
                search: location.search.replace(
                  searchParams
                    .toString()
                    .slice(searchParams.toString().indexOf('profilePanel')),
                  'profilePanel=demographics&field=DateOfBirth'
                ),
              })
            }}
            onClickGenderIdentity={() => {
              const searchParams = new URLSearchParams(location.search)
              history.push({
                search: location.search.replace(
                  searchParams
                    .toString()
                    .slice(searchParams.toString().indexOf('profilePanel')),
                  'profilePanel=demographics&field=genderIdentity'
                ),
              })
            }}
            onClickPronouns={() => {
              const searchParams = new URLSearchParams(location.search)
              history.push({
                search: location.search.replace(
                  searchParams
                    .toString()
                    .slice(searchParams.toString().indexOf('profilePanel')),
                  'profilePanel=demographics&field=pronouns'
                ),
              })
            }}
            {...data}
          />
        )}
        <AlertIndicator
          loading={loading}
          className={cx(styles.alertIndicator, collapsed && styles.collapsed)}
          visible={data?.suicidalIntention}
          tooltip="Patient reported thoughts of suicide or self-harm on their last PHQ-9, BDI-II, C-SSR, and/or DSM-XC survey(s)."
        />
      </div>

      {!collapsed && (
        <PatientTags
          isLoading={Boolean(loading && data && data?.tags)}
          tagsAlreadyAttached={data?.tags ?? []}
          displayLabel={false}
          patientId={patientId}
        />
      )}

      {!collapsed && (
        <Summary
          id="summary"
          isLoading={loading}
          onSave={handleSaveSummary}
          testId={testId ? `summary-${testId}` : undefined}
          value={data?.summary}
        />
      )}
    </div>
  )
}

export default CoreIdentifiers
