import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const GeneralPsychCodes: any = [
  {
    '35253001': {
      title: 'con.Attention Deficit Disorder (ADD)',
      description:
        'Attention deficit hyperactivity disorder, predominantly inattentive type (disorder)',
      snomedcode: 35253001,
      icdcode: 'F902',
      icddescription:
        'Attention-deficit hyperactivity disorder, predominantly inattentive type',
    },
  },
  {
    '31177006': {
      title: 'con.Attention Deficit Hyperactivity Disorder (ADHD)',
      description:
        'Attention deficit hyperactivity disorder, combined type (disorder)',
      snomedcode: 31177006,
      icdcode: 'F901',
      icddescription: 'Attention-deficit hyperactivity disorder, combined type',
    },
  },
  {
    '35919005': {
      title: 'con.Autistic Spectrum Disorder (ASD)',
      description: 'Pervasive developmental disorder (disorder)',
      snomedcode: 35919005,
      icdcode: 'F840',
      icddescription: 'Autistic disorder',
    },
  },
  {
    '371596008': {
      title: 'con.Bipolar I',
      description: 'Bipolar I disorder (disorder)',
      snomedcode: 371596008,
      icdcode: 'F319',
      icddescription: 'Bipolar disorder, unspecified',
    },
  },
  {
    '83225003': {
      title: 'con.Bipolar II',
      description: 'Bipolar II disorder (disorder)',
      snomedcode: 83225003,
      icdcode: 'F3181',
      icddescription: 'Bipolar II disorder',
    },
  },
  {
    '52448006': {
      title: 'con.Dementia',
      description: 'Dementia (disorder)',
      snomedcode: 52448006,
      icdcode: 'F0390',
      icddescription: 'Unspecified dementia without behavioral disturbance',
    },
  },
  {
    '1855002': {
      title: 'con.Learning Disorder',
      description: 'Developmental academic disorder (disorder)',
      snomedcode: 1855002,
      icdcode: 'F819',
      icddescription:
        'Developmental disorder of scholastic skills, unspecified',
    },
  },
  {
    '47505003': {
      title: 'con.Post-Traumatic Stress Disorder (PTSD)',
      description: 'Posttraumatic stress disorder (disorder)',
      snomedcode: 47505003,
      icdcode: 'F4310',
      icddescription: 'Post-traumatic stress disorder, unspecified',
    },
  },
  {
    '69322001': {
      title: 'con.Psychosis',
      description: 'Psychotic disorder (disorder)',
      snomedcode: 69322001,
      icdcode: 'F29',
      icddescription:
        'Unspecified psychosis not due to a substance or known physiological condition',
    },
  },
  {
    '68890003': {
      title: 'con.Schizoaffective Disorder',
      description: 'Schizoaffective disorder (disorder)',
      snomedcode: 68890003,
      icdcode: 'F259',
      icddescription: 'Schizoaffective disorder, unspecified',
    },
  },
  {
    '58214004': {
      title: 'con.Schizophrenia',
      description: 'Schizophrenia (disorder)',
      snomedcode: 58214004,
      icdcode: 'F209',
      icddescription: 'Schizophrenia, unspecified',
    },
  },
  {
    '6471006': {
      title: 'con.Suicidal Ideation',
      description: 'Suicidal thoughts (finding)',
      snomedcode: 6471006,
      icdcode: 'R45851',
      icddescription: 'Suicidal ideations',
    },
  },
]

export const GeneralPsychDropDownValues = constructPatientDemographicObject(
  GeneralPsychCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.PYSCH_CONDITIONS
)
