import React from 'react'

import { Button, Col, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function ManicHypomanicBipolar(props) {
  const { patientId = '', providerId = '' } = props

  if (props.patientData.NoManicBipolar) {
    return (
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Manic/Hypomanic/Bipolar:
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="NoManicBipolar"
            label="No relevant history in this section"
            checked={JSON.parse(props.patientData.NoManicBipolar)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NoManicBipolar: !props.patientData.NoManicBipolar,
              })
            }}
          />
        </Form.Group>
      </Form.Row>
    )
  }

  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Manic/Hypomanic/Bipolar:
          </Form.Label>
        </Form.Group>
        {props.patientData.BipolarI.state === false &&
        props.patientData.BipolarII.state === false &&
        props.patientData.OtherManic.state === false &&
        props.patientData.IrritableMood.state === false &&
        props.patientData.ManicAdditionalNotes === '' ? (
          <Form.Group as={Col} sm={12} md={12} lg={12}>
            <Form.Check
              type="checkbox"
              id="NoManicBipolar"
              label="No relevant history in this section"
              checked={JSON.parse(props.patientData.NoManicBipolar)}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoManicBipolar: !props.patientData.NoManicBipolar,
                })
              }}
            />
          </Form.Group>
        ) : (
          ''
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.BipolarI.state ? 2 : 12}
          lg={props.patientData.BipolarI.state ? 2 : 12}
        >
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Diagnoses
          </Form.Label>
          <Form.Check
            type="checkbox"
            id="BipolarI"
            label="Bipolar I"
            disabled={props.patientData.NoManicBipolar}
            checked={JSON.parse(props.patientData.BipolarI.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                BipolarI: {
                  ...props.patientData.BipolarI,
                  state: !props.patientData.BipolarI.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.BipolarI.state && (
          <>
            <Form.Group as={Col} sm={12} md={2} lg={2}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Number of manic episodes
              </Form.Label>
              <Form.Control
                className="text-area"
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    BipolarI: {
                      ...props.patientData.BipolarI,
                      NumberEpisodes: e.target.value,
                    },
                  })
                }}
                type="number"
                value={props.patientData.BipolarI.NumberEpisodes}
              />
            </Form.Group>
            <Form.Group as={Col} sm={12} md={8} lg={8}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    BipolarI: {
                      ...props.patientData.BipolarI,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.BipolarI.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.BipolarII.state ? 2 : 12}
          lg={props.patientData.BipolarII.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="BipolarII"
            label="Bipolar II"
            disabled={props.patientData.NoManicBipolar}
            checked={JSON.parse(props.patientData.BipolarII.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                BipolarII: {
                  ...props.patientData.BipolarII,
                  state: !props.patientData.BipolarII.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.BipolarII.state && (
          <>
            <Form.Group as={Col} sm={12} md={2} lg={2}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Number of manic episodes
              </Form.Label>
              <Form.Control
                className="text-area"
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    BipolarII: {
                      ...props.patientData.BipolarII,
                      NumberEpisodes: e.target.value,
                    },
                  })
                }}
                type="number"
                value={props.patientData.BipolarII.NumberEpisodes}
              />
            </Form.Group>
            <Form.Group as={Col} sm={12} md={8} lg={8}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    BipolarII: {
                      ...props.patientData.BipolarII,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.BipolarII.Notes}
              />
            </Form.Group>
          </>
        )}

        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.OtherManic.state ? 2 : 12}
          lg={props.patientData.OtherManic.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="OtherManic"
            label="Other"
            disabled={props.patientData.NoManicBipolar}
            checked={JSON.parse(props.patientData.OtherManic.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                OtherManic: {
                  ...props.patientData.OtherManic,
                  state: !props.patientData.OtherManic.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OtherManic.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    OtherManic: {
                      ...props.patientData.OtherManic,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.OtherManic.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Symptoms
          </Form.Label>
          <Form.Check
            type="checkbox"
            id="IrritableMood"
            label="Patient has previously had abnormally elevated or irritable mood for 3-4d in a row"
            disabled={props.patientData.NoManicBipolar}
            checked={JSON.parse(props.patientData.IrritableMood.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                IrritableMood: {
                  state: !props.patientData.IrritableMood.state,
                  IncreasedImpulsivity: false,
                  TalkingFaster: false,
                  MoreEnergy: false,
                  ExcessiveConfidence: false,
                  FasterThoughts: false,
                  IncreasedAgitation: false,
                  IncreasedDistractibility: false,
                  ChangeMedication: false,
                  Other: false,
                  OtherText: '',
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.IrritableMood.state && (
          <>
            <Form.Group
              className="indent"
              style={{ marginTop: '0px' }}
              as={Col}
              sm={12}
              md={12}
              lg={12}
            >
              <Form.Check
                type="checkbox"
                id="IncreasedImpulsivity"
                label="Increased impulsivity/risk taking"
                disabled={props.patientData.NoManicBipolar}
                checked={JSON.parse(
                  props.patientData.IrritableMood.IncreasedImpulsivity
                )}
                onClick={() => {
                  props.setPatientData({
                    ...props.patientData,
                    IrritableMood: {
                      ...props.patientData.IrritableMood,
                      IncreasedImpulsivity:
                        !props.patientData.IrritableMood.IncreasedImpulsivity,
                    },
                  })
                }}
              />
              <Form.Check
                type="checkbox"
                id="TalkingFaster"
                label="Talking faster/more or interruptions"
                disabled={props.patientData.NoManicBipolar}
                checked={JSON.parse(
                  props.patientData.IrritableMood.TalkingFaster
                )}
                onClick={() => {
                  props.setPatientData({
                    ...props.patientData,
                    IrritableMood: {
                      ...props.patientData.IrritableMood,
                      TalkingFaster:
                        !props.patientData.IrritableMood.TalkingFaster,
                    },
                  })
                }}
              />
              <Form.Check
                type="checkbox"
                id="MoreEnergy"
                label="More energy / need less sleep"
                disabled={props.patientData.NoManicBipolar}
                checked={JSON.parse(props.patientData.IrritableMood.MoreEnergy)}
                onClick={() => {
                  props.setPatientData({
                    ...props.patientData,
                    IrritableMood: {
                      ...props.patientData.IrritableMood,
                      MoreEnergy: !props.patientData.IrritableMood.MoreEnergy,
                    },
                  })
                }}
              />
              <Form.Check
                type="checkbox"
                id="ExcessiveConfidence"
                label="Feeling excessively confident or grandiose"
                disabled={props.patientData.NoManicBipolar}
                checked={JSON.parse(
                  props.patientData.IrritableMood.ExcessiveConfidence
                )}
                onClick={() => {
                  props.setPatientData({
                    ...props.patientData,
                    IrritableMood: {
                      ...props.patientData.IrritableMood,
                      ExcessiveConfidence:
                        !props.patientData.IrritableMood.ExcessiveConfidence,
                    },
                  })
                }}
              />
              <Form.Check
                type="checkbox"
                id="FasterThoughts"
                label="Faster thoughts, flight of ideas"
                disabled={props.patientData.NoManicBipolar}
                checked={JSON.parse(
                  props.patientData.IrritableMood.FasterThoughts
                )}
                onClick={() => {
                  props.setPatientData({
                    ...props.patientData,
                    IrritableMood: {
                      ...props.patientData.IrritableMood,
                      FasterThoughts:
                        !props.patientData.IrritableMood.FasterThoughts,
                    },
                  })
                }}
              />
              <Form.Check
                type="checkbox"
                id="IncreasedAgitation"
                label="Increased psychomotor agitation or ideas/projects"
                disabled={props.patientData.NoManicBipolar}
                checked={JSON.parse(
                  props.patientData.IrritableMood.IncreasedAgitation
                )}
                onClick={() => {
                  props.setPatientData({
                    ...props.patientData,
                    IrritableMood: {
                      ...props.patientData.IrritableMood,
                      IncreasedAgitation:
                        !props.patientData.IrritableMood.IncreasedAgitation,
                    },
                  })
                }}
              />
              <Form.Check
                type="checkbox"
                id="IncreasedDistractibility"
                label="Increased distractibility"
                disabled={props.patientData.NoManicBipolar}
                checked={JSON.parse(
                  props.patientData.IrritableMood.IncreasedDistractibility
                )}
                onClick={() => {
                  props.setPatientData({
                    ...props.patientData,
                    IrritableMood: {
                      ...props.patientData.IrritableMood,
                      IncreasedDistractibility:
                        !props.patientData.IrritableMood
                          .IncreasedDistractibility,
                    },
                  })
                }}
              />
              <Form.Check
                type="checkbox"
                id="ChangeMedication"
                label="Change in substances/medications during episode"
                disabled={props.patientData.NoManicBipolar}
                checked={JSON.parse(
                  props.patientData.IrritableMood.ChangeMedication
                )}
                onClick={() => {
                  props.setPatientData({
                    ...props.patientData,
                    IrritableMood: {
                      ...props.patientData.IrritableMood,
                      ChangeMedication:
                        !props.patientData.IrritableMood.ChangeMedication,
                    },
                  })
                }}
              />
              <Form.Check
                type="checkbox"
                id="OtherIrritableMood"
                label="Other"
                disabled={props.patientData.NoManicBipolar}
                checked={JSON.parse(props.patientData.IrritableMood.Other)}
                onClick={() => {
                  props.setPatientData({
                    ...props.patientData,
                    IrritableMood: {
                      ...props.patientData.IrritableMood,
                      Other: !props.patientData.IrritableMood.Other,
                    },
                  })
                }}
              />
            </Form.Group>
            {props.patientData.IrritableMood.Other && (
              <Form.Group
                className="indent"
                style={{ marginTop: '0px' }}
                as={Col}
                sm={12}
                md={12}
                lg={12}
              >
                <Form.Control
                  as="textarea"
                  rows={1}
                  bsPrefix="form-input"
                  onChange={(e) => {
                    props.setPatientData({
                      ...props.patientData,
                      IrritableMood: {
                        ...props.patientData.IrritableMood,
                        OtherText: e.target.value,
                      },
                    })
                  }}
                  value={props.patientData.IrritableMood.OtherText}
                />
              </Form.Group>
            )}
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          style={{ textAlign: 'center' }}
        >
          <Link
            to={{
              pathname: `/patient/progress?patientId=${patientId}&providerId=${providerId}`,
            }}
            target="_blank"
          >
            <Button variant="primary">
              See manic/bipolar questionnaire scores
            </Button>
          </Link>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional notes
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            bsPrefix="form-input"
            disabled={props.patientData.NoManicBipolar}
            onChange={(e) => {
              props.setPatientData({
                ...props.patientData,
                ManicAdditionalNotes: e.target.value,
              })
            }}
            value={props.patientData.ManicAdditionalNotes}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
