import React from 'react'

import { format, isSameDay } from 'date-fns'
import { Event, EventProps } from 'react-big-calendar'

import { Avatar, Badge, Typography } from '../../BaseComponents'

interface AgendaDateProps {
  currentDate: Date
  day: Date
}

export const AgendaDate: React.ComponentType<AgendaDateProps> = ({
  currentDate,
  day,
}) => {
  const [dayNumber, monthName] = format(day, 'dd MMM').split(' ')
  const isToday = isSameDay(day, new Date())
  const isSelected = isSameDay(day, currentDate)
  return (
    <div className="custom-date-cell">
      {isToday ? (
        <Avatar
          size={36}
          icon={<span>{dayNumber}</span>}
          className={'avatar today ' + (isSelected && 'circled')}
        />
      ) : (
        <Avatar
          size={36}
          icon={<span>{dayNumber}</span>}
          className={'avatar clear-bg ' + (isSelected && 'circled')}
        />
      )}
      <Typography.Text
        className={isToday ? 'month-label blue-text' : 'month-label'}
      >
        {monthName}
      </Typography.Text>
    </div>
  )
}

interface AgendaTimeProps {
  day: Date
  label: string
  event: Event // pass color for event here
}

export const AgendaTime: React.ComponentType<AgendaTimeProps> = ({
  label,
  event,
}) => {
  // TODO: devise a way to pass event color (from provider, room or just event itself)
  const eventColor = event.resource.ColorHex || 'blue'

  return <Badge color={eventColor} text={label} />
}

export const AgendaEvent: React.ComponentType<EventProps<Event>> = ({
  title,
}) => {
  return <Typography.Text className="event-title">{title}</Typography.Text>
}
