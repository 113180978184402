import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const personalPronounFHIR: PatientDemographicObject[] = [
  { 'LA29518-0': { title: 'he/him/his/his/himself' } },
  { 'LA29519-8': { title: 'she/her/her/hers/herself' } },
  { 'LA29520-6': { title: 'they/them/their/theirs/themselves' } },
  { 'LA29523-0': { title: 'ze/zir/zir/zirs/zirself' } },
  { 'LA29521-4': { title: 'xie/hir ("here")/hir/hirs/hirself' } },
  { 'LA29515-6': { title: 'co/co/cos/cos/coself' } },
  { 'LA29516-4': { title: 'en/en/ens/ens/enself' } },
  { 'LA29517-2': { title: 'ey/em/eir/eirs/emself' } },
  { 'LA29522-2': { title: 'yo/yo/yos/yos/yoself' } },
  { 'LA29524-8': { title: 've/vis/ver/ver/verself' } },
]

export const personalPronounDropDownValues = constructPatientDemographicObject(
  personalPronounFHIR,
  CodeSystemOIDs.FHIRHl7ValueSetLanguages,
  Categories.LANGUAGE
)
