import { useState } from 'react'

import { useClinicSummaryData } from '../../hooks/useBillingInfo'
import { BillingTable } from './BillingTable/BillingTable'
import { ExportTransactionsModal } from './ExportTransactionsModal'
import { TopBar } from './TopBar'

import styles from '../../containers/_shared.module.scss'

export const ClinicBillingSummary = ({
  providerId,
}: {
  providerId: string
}) => {
  const [searchValue, setSearchValue] = useState('')
  const { data: clinicSummaryData, isLoading: isClinicSummaryDataLoading } =
    useClinicSummaryData()

  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  const [isExportModalOpen, setIsExportModalOpen] = useState(false)

  return (
    <div className={styles.scroll}>
      <ExportTransactionsModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
      />
      <TopBar
        totalOutstandingInvoicesAmountCents={
          clinicSummaryData?.totalOutstandingInvoicesAmountCents
        }
        totalCollectedPaymentAmountCents={
          clinicSummaryData?.totalCollectedPaymentAmountCents
        }
        totalOutstandingClaimsAmountCents={
          clinicSummaryData?.totalOutstandingClaimsAmountCents
        }
        totalOutstandingCredits={clinicSummaryData?.totalOutstandingCredits}
        totalAppliedToClaimsAmountCents={
          clinicSummaryData?.totalAppliedToClaimsAmountCents
        }
        patientsWithBalance={clinicSummaryData?.patientsWithBalance}
        averageInvoiceAge={clinicSummaryData?.averageInvoiceAge}
        isLoading={isClinicSummaryDataLoading}
        handleSearch={handleSearch}
        openExportModal={() => setIsExportModalOpen(true)}
      />
      <BillingTable
        isShown={!isClinicSummaryDataLoading}
        searchValue={searchValue}
        providerId={providerId}
      />
    </div>
  )
}
