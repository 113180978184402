import React from 'react'

import { Container, Form, Row } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'

import './../PatientProfile.scss'

export default function PTSDTraumaSaved(props) {
  const hasNoRelevantHistory =
    props.patientData.PTSD.state === false &&
    props.patientData.OtherPTSD.state === false &&
    props.patientData.TraumaAbuseHistory.state === false &&
    !props.patientData.PTSDTraumaAdditionalNotes &&
    props.patientData.NoPTSDTrauma === true

  const noRecordedInfo =
    props.patientData.PTSD.state === false &&
    props.patientData.OtherPTSD.state === false &&
    props.patientData.TraumaAbuseHistory.state === false &&
    !props.patientData.PTSDTraumaAdditionalNotes &&
    props.patientData.NoPTSDTrauma === false

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="PTSD and trauma"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">PTSD and trauma:</Form.Row>
      <Form.Group className="indent">
        {props.patientData.PTSD.state && (
          <>
            <Container bsPrefix="psych">
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(props.patientData.PTSD.state)}
              />
              <span id="PTSDShow">PTSD</span>
              <span className="subtopic" style={{ marginLeft: '25px' }}>
                Notes:{' '}
              </span>
              {props.patientData.PTSD.Notes}
            </Container>
          </>
        )}
        {props.patientData.OtherPTSD.state && (
          <>
            <Container bsPrefix="psych">
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(props.patientData.OtherPTSD.state)}
              />
              <span id="OtherPTSDShow">Other</span>
              <span className="subtopic" style={{ marginLeft: '25px' }}>
                Notes:{' '}
              </span>
              {props.patientData.OtherPTSD.Notes}
            </Container>
          </>
        )}
        {props.patientData.TraumaAbuseHistory.state && (
          <>
            <Container bsPrefix="psych">
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(props.patientData.TraumaAbuseHistory.state)}
              />
              <span id="TraumaAbuseHistoryShow">
                History of trauma or abuse
              </span>
              <span className="subtopic" style={{ marginLeft: '25px' }}>
                Notes:{' '}
              </span>
              {props.patientData.TraumaAbuseHistory.Notes}
            </Container>
          </>
        )}
      </Form.Group>
      <Form.Group className="border-bottom indent">
        {props.patientData.PTSDTraumaAdditionalNotes && (
          <Container bsPrefix="psych">
            <Form.Row className="patient-dark">Additional Notes:</Form.Row>
            <Row bsPrefix="psych-sub">
              {props.patientData.PTSDTraumaAdditionalNotes}
            </Row>
          </Container>
        )}
      </Form.Group>
    </Form.Group>
  )
}
