import React, { useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import { cloneDeep } from 'lodash'

import SkeletonLoadingTransition from '../../../components/Animation/SkeletonLoadingTransition'
import { RenderingProviderType } from '../../../hooks/usePatientProfile/shared-types'
import { Teammate } from '../../../shared-types'
import Fields, { FieldGroupProps } from '../../../stories/PatientProfile/Fields'
import {
  handleRenderingProvUpdate,
  populateRenderingProviderInfo,
} from './InsuranceClaim-helper'
import { renderingProviderComponent } from './constants'

import styles from './InsuranceClaim.module.scss'

export interface PatientConditionRelationProps {
  testId?: string
  renderingProviderInfo: RenderingProviderType
  setRenderingProviderInfo: Function
  teamLoading: boolean
  teammates: Teammate[]
  includeSupervisingProvider?: boolean
  setIncludeSupervisingProvider?: Function
  isSupervising?: boolean
  disabled?: boolean
}

const Loading = () => (
  <div className={styles.skeletonContainer}>
    <Skeleton
      active
      title={{ width: 85 }}
      paragraph={{
        rows: 3,
        width: '100%',
        className: styles.skeletonParagraph,
      }}
    />
  </div>
)

const RenderingProvider: React.FC<PatientConditionRelationProps> = ({
  testId,
  renderingProviderInfo,
  setRenderingProviderInfo,
  teammates,
  teamLoading,
  includeSupervisingProvider = undefined,
  setIncludeSupervisingProvider,
  isSupervising = false,
  disabled = false,
}) => {
  const [rendringProvider, setRenderingProvider] = useState<FieldGroupProps[]>(
    renderingProviderComponent
  )

  function isTeammateProvider(t: Teammate): boolean {
    let isProvider = false
    t.roleTitles?.forEach((r) => {
      if (r.label === 'Provider') isProvider = true
    })
    return isProvider
  }

  function filterTeammates() {
    const activeTeammates = teammates.filter((t) => !t.isDeactivated)
    const teammateOptions = []
    for (const t of activeTeammates) {
      if (isSupervising) {
        if (isTeammateProvider(t)) {
          teammateOptions.push({
            value: t.cognitoId,
            label: `${t.name ? t.name : t.email}`,
            disabled: t.lastActive === 'never_logged_in',
          })
        }
      } else {
        teammateOptions.push({
          value: t.cognitoId,
          label: `${t.name ? t.name : t.email}`,
          disabled: t.lastActive === 'never_logged_in',
        })
      }
    }
    return teammateOptions
  }

  useEffect(() => {
    const itemsCopy: any = cloneDeep(renderingProviderComponent)
    if (!teamLoading) {
      const teammatesOptions = filterTeammates()
      itemsCopy[0].items[0].options = teammatesOptions
      if (isSupervising) {
        itemsCopy[0].groupName = 'Supervising provider'
      }

      if (!renderingProviderInfo?.taxType) {
        const idsToRemove = ['providerEin', 'providerSsn']
        const noTaxTypeComponent = itemsCopy[0].items.filter(
          (obj: { id: string }) => !idsToRemove.includes(obj.id)
        )
        itemsCopy[0].items = noTaxTypeComponent
      } else {
        const idToRemove =
          renderingProviderInfo?.taxType === 'EIN'
            ? 'providerSsn'
            : 'providerEin'
        const noTaxTypeComponent = itemsCopy[0].items.filter(
          (obj: { id: string }) => obj.id !== idToRemove
        )
        itemsCopy[0].items = noTaxTypeComponent
      }

      if (renderingProviderInfo) {
        populateRenderingProviderInfo(itemsCopy, renderingProviderInfo)
      }
      setRenderingProvider(itemsCopy)
    }
  }, [renderingProviderInfo, teammates, teamLoading])

  const handleSave = (
    newVal: any,
    id: string,
    _groupName: string,
    _label?: string
  ) => {
    const copyRenderingProviderInfo = cloneDeep(renderingProviderInfo)
    const selectedTeammate = teammates.filter((t) => t.cognitoId === newVal)
    const shouldSave = handleRenderingProvUpdate(
      newVal,
      id,
      copyRenderingProviderInfo,
      selectedTeammate
    )
    if (shouldSave) {
      setRenderingProviderInfo(copyRenderingProviderInfo)
    }
  }

  return (
    <SkeletonLoadingTransition
      isLoading={teamLoading}
      skeletonComponent={<Loading />}
      loadedComponent={
        <div className={styles.topMargin}>
          <Fields
            testId={testId}
            items={rendringProvider}
            compact={false}
            handleSave={handleSave}
            isIncluded={includeSupervisingProvider}
            setIsIncluded={setIncludeSupervisingProvider}
            includedText="This visit had a supervising provider"
            isClaim
            disableAll={disabled}
          />
        </div>
      }
    />
  )
}

export default RenderingProvider
