import React from 'react'

import { Select as AntDSelect } from 'antd'
import {
  SelectProps as AntDSelectProps,
  SelectValue as AntDSelectValue,
  BaseOptionType,
} from 'antd/lib/select'
import classnames from 'classnames'

import { TestId } from '../../shared-types'

import './Select.scss'

export interface DefaultOptionType extends BaseOptionType {
  label: React.ReactNode
  value?: string | number | null
  children?: Omit<DefaultOptionType, 'children'>[]
}
type SelectProps = AntDSelectProps<AntDSelectValue> &
  TestId & {
    wrapOptions?: boolean
  }

const Select: React.FC<SelectProps> = ({
  children,
  wrapOptions,
  testId,
  ...props
}) => {
  const filterValueAndLabel = (
    input: string,
    option: DefaultOptionType | undefined
  ) =>
    (option?.children ?? '')
      .toString()
      .toLowerCase()
      .indexOf(input.toLowerCase()) >= 0 ||
    (option?.value ?? '')
      .toString()
      .toLowerCase()
      .indexOf(input.toLowerCase()) >= 0 ||
    (option?.label ?? '')
      .toString()
      .toLowerCase()
      .indexOf(input.toLowerCase()) >= 0

  return (
    <AntDSelect
      data-testid={testId}
      {...props}
      filterOption={filterValueAndLabel}
      dropdownClassName={classnames({
        'wrap-options': !!wrapOptions,
      })}
    >
      {children}
    </AntDSelect>
  )
}
export default Select
