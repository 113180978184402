import * as React from 'react'
import { useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Dropdown, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { compareAsc } from 'date-fns'
import qs from 'qs'
import { FixedType } from 'rc-table/lib/interface'
import { Link, useHistory } from 'react-router-dom'

import { generateInvoicePDF } from '../../../api/api-lib'
import { QueryKeys as BillingQueryKeys } from '../../../hooks/useBillingInfo'
import {
  convertCentsToDollars,
  formatNumberStringToUsdString,
  invoiceUrl,
} from '../../../libs/billing'
import { useFeatureFlags } from '../../../libs/featureFlags'
import { Invoice, Superbill } from '../../../shared-types'
import { Spinner, Tag } from '../../../stories/BaseComponents'
import { DeleteInvoiceModal } from '../../../stories/Invoice/DeleteInvoiceModal'
import ExportPdfConfirmationModal from '../../../stories/Invoice/ExportPdfConfirmationModal'
import { routeParameters } from '../../../stories/Invoice/InvoiceForm'

import styles from './Billing.module.scss'

interface InvoiceTableProps {
  invoices: Array<Invoice>
  patientId: string
  shouldFilterOutstanding: boolean
  providerId: string
  openAddPaymentModal: (invoiceUuid: string) => void
  openSuperbillExport: (invoice: Invoice) => void
  onStartSuperbillSend: (invoice: Invoice) => void
  type: string
}

type InvoiceRow = {
  status: string | React.ReactElement
  invoiceNumber: number
  createdAt: string | Date
  noteId: string | React.ReactElement
  amountCentsDue: number
  actions: React.ReactNode
}

const getCreatedAtContent = (timestamp: string) => {
  const createdAt = new Date(timestamp)
  return (
    <span>
      {createdAt.getMonth() + 1}/{createdAt.getDate()}/{createdAt.getFullYear()}
    </span>
  )
}

const createRelativeInvoicePath = (
  patientId: string,
  invoiceUuid: string,
  shouldCreateSuperbill: boolean
) => {
  const urlParams: { [key: string]: string } = {
    [routeParameters.patientId]: patientId,
    [routeParameters.invoiceUuid]: invoiceUuid,
  }
  if (shouldCreateSuperbill) {
    urlParams[routeParameters.createSuperbill] = 'true'
  }
  const queryString = qs.stringify(urlParams)
  return `/invoice?${queryString}`
}

export default function InvoiceTable({
  invoices,
  shouldFilterOutstanding,
  openAddPaymentModal,
  openSuperbillExport,
  onStartSuperbillSend,
  providerId,
  patientId,
  type,
}: InvoiceTableProps) {
  const history = useHistory()
  const { enableProviderSuperbillSend } = useFeatureFlags()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState<null | string>(null)
  const [isExportConfirmationOpen, setIsExportConfirmationOpen] =
    useState(false)
  const [invoiceToExport, setInvoiceToExport] = useState<Invoice | null>(null)

  const filterOutstandingInvoices = () => {
    return invoices.filter((invoice: Invoice) => invoice.amountCentsDue > 0)
  }

  const queryClient = useQueryClient()
  const refreshPaymentData = () => {
    queryClient.invalidateQueries([BillingQueryKeys.INVOICES])
    queryClient.invalidateQueries([BillingQueryKeys.TRANSACTIONS])
  }

  const columns: ColumnsType<InvoiceRow> = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      fixed: 'left' as FixedType,
    },
    {
      title: 'Invoice #',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      sorter: (a: InvoiceRow, b: InvoiceRow) =>
        a.invoiceNumber - b.invoiceNumber,
      render: (invoiceNumber: number) => {
        return <span>#{invoiceNumber}</span>
      },
      width: 100,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a: InvoiceRow, b: InvoiceRow) =>
        compareAsc(new Date(a.createdAt), new Date(b.createdAt)),
      render: (createdAt: string) => {
        return getCreatedAtContent(createdAt)
      },
      width: 150,
    },
    {
      title: 'Chart note',
      dataIndex: 'noteId',
      key: 'noteId',
      width: 150,
    },
    {
      title: 'Superbill',
      dataIndex: 'superbill',
      key: 'superbill',
      width: 150,
    },
    {
      title: 'Amount due',
      dataIndex: 'amountCentsDue',
      key: 'amountCentsDue',
      sorter: (a: InvoiceRow, b: InvoiceRow) =>
        a.amountCentsDue - b.amountCentsDue,
      render: (amountCentsDue: number) => {
        return (
          <span>
            {formatNumberStringToUsdString(
              convertCentsToDollars(amountCentsDue)
            )}
          </span>
        )
      },
      width: 150,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right' as FixedType,
      width: 150,
    },
  ]

  const getInvoiceStatusContent = (invoice: any) => {
    if (invoice.amountCentsDue === 0) {
      return <Tag color="green">Paid</Tag>
    } else if (invoice.amountCentsDue === invoice.totalAmountCents) {
      return <Tag color="red">Unpaid</Tag>
    } else {
      return <Tag color="gold">Partially paid</Tag>
    }
  }

  function onDelete() {
    refreshPaymentData()
  }

  const handleExportInvoice = async () => {
    if (!invoiceToExport) return
    setSelectedInvoice(invoiceToExport.uuid)
    setIsLoading(true)
    try {
      await generateInvoicePDF(invoiceToExport.uuid)
      setIsLoading(false)
      setSelectedInvoice(null)
      setInvoiceToExport(null)
      setIsExportConfirmationOpen(false)
      history.push(
        invoiceUrl({
          invoice: invoiceToExport,
          providerId,
          documentReference: invoiceToExport.uuid,
        })
      )
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      setSelectedInvoice(null)
      setInvoiceToExport(null)
      setIsExportConfirmationOpen(false)
    }
  }

  const handleExportClick = (invoice: Invoice) => {
    setIsExportConfirmationOpen(true)
    setInvoiceToExport(invoice)
  }

  const formatInvoicesAsDataSource = () => {
    const invoicesDataSource: any = []
    const invoicesToShow = shouldFilterOutstanding
      ? filterOutstandingInvoices()
      : invoices

    invoicesToShow
      .sort((a, b) => b.invoiceNumber - a.invoiceNumber)
      .forEach((invoice: Invoice, idx: number) => {
        const overlay = (
          invoiceUuid: string,
          invoiceNumber: number,
          patientId: string,
          amountCentsDue: number,
          superbill?: Superbill | null
        ) => (
          <ul>
            {amountCentsDue > 0 && (
              <li onClick={() => openAddPaymentModal(invoiceUuid)}>
                New payment
              </li>
            )}

            {!superbill && (
              <Link
                className={styles.createSuperbillButton}
                to={createRelativeInvoicePath(patientId, invoiceUuid, true)}
              >
                <li>Create superbill</li>
              </Link>
            )}

            <li onClick={() => handleExportClick(invoice)}>
              Export invoice as PDF
            </li>

            {superbill && (
              <li onClick={() => openSuperbillExport(invoice)}>
                Export superbill as PDF
              </li>
            )}

            {superbill && enableProviderSuperbillSend && (
              <li
                onClick={() => onStartSuperbillSend(invoice)}
                onKeyDown={({ key }) => {
                  if (key === 'Enter') {
                    onStartSuperbillSend(invoice)
                  }
                }}
              >
                Send superbill to patient
              </li>
            )}

            <li className={styles.deleteInvoiceButton}>
              <DeleteInvoiceModal
                invoiceUuid={invoiceUuid}
                invoiceNumber={invoiceNumber}
                patientId={patientId}
                onDelete={onDelete}
              />
            </li>
          </ul>
        )
        const invoiceData = {
          key: idx.toString(),
          status: getInvoiceStatusContent(invoice),
          invoiceNumber: invoice.invoiceNumber,
          noteId: <span>{invoice.clinicalNote?.Title ?? ''}</span>,
          superbill: invoice.superbill && (
            <Tag>SB #{invoice.invoiceNumber}</Tag>
          ),
          amountCentsDue: invoice.amountCentsDue,
          createdAt: invoice.createdAt,
          invoiceUuid: invoice.uuid,
          actions: (
            <div className={styles.actions}>
              <a
                className={styles.paymentActionLink}
                onClick={() =>
                  history.push(
                    `/invoice?patientId=${patientId}&invoiceUuid=${invoice.uuid}`
                  )
                }
              >
                View invoice
              </a>
              {isLoading && selectedInvoice === invoice.uuid ? (
                <div style={{ display: 'contents' }}>
                  <Spinner fontSize={16} />
                  &nbsp;&nbsp; Loading
                </div>
              ) : (
                <Dropdown
                  getPopupContainer={() =>
                    document.getElementById(
                      `dropdownArea-${type}`
                    ) as HTMLElement
                  }
                  overlayClassName={styles.dropdownMenu}
                  disabled={isLoading && selectedInvoice !== invoice.uuid}
                  overlay={overlay(
                    invoice.uuid,
                    invoice.invoiceNumber,
                    invoice.patientId,
                    invoice.amountCentsDue,
                    invoice.superbill
                  )}
                >
                  <a
                    className={styles.paymentActionLink}
                    data-testid={`${invoice.uuid}-actions`}
                  >
                    Actions <i className="fa fa-angle-down fa-lg"></i>
                  </a>
                </Dropdown>
              )}
            </div>
          ),
        }

        invoicesDataSource.push(invoiceData)
      })

    return invoicesDataSource
  }

  return (
    <>
      <ExportPdfConfirmationModal
        isVisible={isExportConfirmationOpen}
        onCancel={() => {
          setIsExportConfirmationOpen(false)
        }}
        okButtonProps={{ loading: isLoading }}
        onOk={handleExportInvoice}
        cancelButtonProps={{ disabled: isLoading }}
        closable={!isLoading}
        maskClosable={!isLoading}
        fileType="invoice"
      />
      <Table
        size="middle"
        scroll={{ x: 1050 }}
        columns={columns}
        dataSource={formatInvoicesAsDataSource()}
      />
      {/* This element is used to place dropdown and
          avoid table positions issues.  */}
      <div style={{ position: 'relative' }} id={`dropdownArea-${type}`} />
    </>
  )
}
