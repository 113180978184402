import { useMemo } from 'react'

import { Select as AntSelect } from 'antd'

import {
  BareBillingCode,
  BillingCode,
  BillingCodesByType,
} from '../../hooks/queries/useBillingCodes'
import { BillingCodesTypes } from '../../shared-types'

const { Option, OptGroup } = AntSelect

export type BillingCodeOption = {
  index?: number
  name: string
  value: BareBillingCode
  disabled?: boolean
}

export type BillingCodeGroup = {
  name: string
  value: string
  disabled?: boolean
  groupItems: BillingCodeOption[]
}

const billingCodeToOption = (
  { code, description, version, ownerId, shortDescription, id }: BillingCode,
  index: number
): BillingCodeOption => {
  const friendlyDescription =
    description.length > 100 ? `${description.slice(0, 100)}...` : description
  const currentCode = {
    index,
    name: `${code} ${friendlyDescription}`,
    value: {
      code,
      version,
      ownerId,
      shortDescription: shortDescription ?? '',
      id,
    },
    disabled: false,
  }
  return currentCode
}

export const useBillingCodeDropdownOptions = (
  billingCodes: BillingCodesByType
) => {
  const billingCodeOptions: BillingCodeGroup[] = useMemo(() => {
    const cptVersions = Object.keys(billingCodes).filter((version) =>
      version.match(/CPT/i)
    )

    const allCptCodes = cptVersions
      .map((version) => billingCodes[version as BillingCodesTypes] || [])
      .flat()

    const displayOptions = []

    displayOptions.push({
      name: 'Custom codes',
      value: 'CUSTOM',

      groupItems: (billingCodes[BillingCodesTypes.CUSTOM] || []).map(
        billingCodeToOption
      ),
    })

    displayOptions.push({
      name: 'CPT codes',
      value: 'CPT',
      groupItems: allCptCodes.map(billingCodeToOption),
    })

    displayOptions.push({
      name: 'HCPCS codes',
      value: 'HCPCS',
      groupItems: (billingCodes[BillingCodesTypes.HCPCS_V2020] || []).map(
        billingCodeToOption
      ),
    })

    return displayOptions
  }, [billingCodes])

  return billingCodeOptions
}

export const mapToOptionGroups = (codeOptions: BillingCodeGroup[]) => {
  return codeOptions.map((opt) => (
    <OptGroup key={`opt-group-label-${opt.name}`} label={opt.name}>
      {opt.groupItems.map((item) => (
        <Option
          key={`opt-group-option-${item.value.code}-${item.value.version}`}
          title={`${opt.name} - ${item.name}`}
          value={item.value.id}
          style={{ marginTop: 4 }}
        >
          {item.name}
        </Option>
      ))}
    </OptGroup>
  ))
}
