import cn from 'classnames'

import { Tooltip } from '../../../../../../stories/BaseComponents'
import { Icon } from './Icon'

import styles from './DragHandle.module.scss'

type DragHandleProps = {
  noHeight?: boolean
}

export const DragHandle = ({ noHeight }: DragHandleProps) => {
  return (
    <Tooltip placement="right" mouseEnterDelay={2} title="Click & drag to move">
      <div
        className={cn(styles.dragHandle, { [styles.noHeight]: noHeight })}
        data-drag-handle
      >
        <Icon />
      </div>
    </Tooltip>
  )
}
