import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const whiteSubRaceCDC: PatientDemographicObject[] = [
  { '2108-9': { title: 'European' } },
  { '2118-8': { title: 'Middle Eastern or North African' } },
  { '2129-5': { title: 'Arab' } },
  { '2109-7': { title: 'Armenian' } },
  { '2110-5': { title: 'English' } },
  { '2111-3': { title: 'French' } },
  { '2112-1': { title: 'German' } },
  { '2113-9': { title: 'Irish' } },
  { '2114-7': { title: 'Italian' } },
  { '2115-4': { title: 'Polish' } },
  { '2116-2': { title: 'Scottish' } },
  { '2119-6': { title: 'Assyrian' } },
  { '2120-4': { title: 'Egyptian' } },
  { '2121-2': { title: 'Iranian' } },
  { '2122-0': { title: 'Iraqi' } },
  { '2123-8': { title: 'Lebanese' } },
  { '2124-6': { title: 'Palestinian' } },
  { '2125-3': { title: 'Syrian' } },
  { '2126-1': { title: 'Afghanistani' } },
  { '2127-9': { title: 'Israeili' } },
]

export const whiteSubRaceDropDownValues = constructPatientDemographicObject(
  whiteSubRaceCDC,
  CodeSystemOIDs.PHVSRaceCategoryCDCRef,
  Categories.WHITE_SUB_RACE
)
