import { QrcodeOutlined } from '@ant-design/icons'

import { FormSectionKey, IntakeForm } from '../../../../../api/intakeForms'
import {
  ProviderSideIntakeFormEvents,
  trackIntakeFormEvent,
} from '../../../../../libs/freshpaint/intakeFormEvents'
import {
  QRCodeModal,
  Spinner,
  Text,
} from '../../../../../stories/BaseComponents'
import IntakeFormDownloadButton from '../../../../../stories/Patients/IntakeFormDownloadButton'
import { IntakeRowActions } from './IntakeRowActions'

import styles from './IntakeRows.module.scss'

export type IntakeRowProps = {
  intakeFormsLoading: boolean
  resendingForm: boolean
  onResendIntakeInvite: ({ intakeFormId }: { intakeFormId: string }) => void
  showLocalTime: (timestamp: string | Date) => string
  patientPublicId: string | undefined
  intakeForms: IntakeForm[] | undefined
}

const getFormStatus = (
  newIntakeForm: IntakeForm,
  showLocalTime: IntakeRowProps['showLocalTime'],
  patientPublicId: IntakeRowProps['patientPublicId']
) => {
  if (newIntakeForm.importedAt) {
    return (
      <>
        {`Imported on ${showLocalTime(newIntakeForm.importedAt)}`}
        &nbsp;
        <IntakeFormDownloadButton
          formId={newIntakeForm.id}
          publicId={patientPublicId}
        />
      </>
    )
  } else if (newIntakeForm.reviewedAt) {
    return (
      <>
        {`Marked reviewed on ${showLocalTime(newIntakeForm.reviewedAt)}`}
        &nbsp;
        <IntakeFormDownloadButton
          formId={newIntakeForm.id}
          publicId={patientPublicId}
        />
      </>
    )
  } else if (newIntakeForm.completedAt) {
    return (
      <>
        {`Patient completed form at ${showLocalTime(
          newIntakeForm.completedAt
        )}`}
        &nbsp;
        <IntakeFormDownloadButton
          formId={newIntakeForm.id}
          publicId={patientPublicId}
        />
      </>
    )
  } else if (newIntakeForm.startedAt) {
    return `Patient viewed form at ${showLocalTime(newIntakeForm.startedAt)}`
  } else if (newIntakeForm.lastInvitedAt) {
    return `Last form sent at ${showLocalTime(newIntakeForm.lastInvitedAt)}`
  }
}

export const IntakeRows = ({
  intakeFormsLoading,
  resendingForm,
  intakeForms,
  showLocalTime,
  onResendIntakeInvite,
  patientPublicId,
}: IntakeRowProps) => {
  if (intakeFormsLoading)
    // This should be updated to not use a tr under a div
    return (
      <tr key={`loading-new-intake`}>
        <td>
          <Spinner />
        </td>
      </tr>
    )

  if (!intakeForms || intakeForms.length === 0) {
    return null
  }

  return (
    <>
      {intakeForms.map((intakeForm) => {
        const formUrl = `${window.location.origin}/form/${intakeForm.id}`
        const formStatus = getFormStatus(
          intakeForm,
          showLocalTime,
          patientPublicId
        )
        const sectionsOrdered: string[] = Object.values(FormSectionKey)
        const sectionNames = intakeForm.sections
          .sort(
            (a, b) => sectionsOrdered.indexOf(a) - sectionsOrdered.indexOf(b)
          )
          .map(
            (sectionName) =>
              sectionName[0].toUpperCase() +
              sectionName.slice(1).replaceAll('_', ' ').replaceAll('and', '&')
          )
          .join(', ')

        const onQrCodeModalOpen = () =>
          trackIntakeFormEvent(ProviderSideIntakeFormEvents.VIEWED_FORM_QR, {
            osmindIntakeId: intakeForm.id,
          })

        return (
          <tr key={`sigrequests-${intakeForm.id}`}>
            <td>
              <div>
                {intakeForm.title}
                <QRCodeModal
                  value={formUrl}
                  modalTrigger={
                    <>
                      <QrcodeOutlined size={20} />
                      <span>Show QR Code</span>
                    </>
                  }
                  modalDetailsText="Scan this QR code using a smart device to have your patient complete this form during visit:"
                  modalFooterText={`Form ID: ${intakeForm.id}`}
                  modalProps={{
                    title: `Access the Osmind intake form`,
                  }}
                  onModalOpen={onQrCodeModalOpen}
                />
              </div>
              <div className={styles.sectionNameText}>
                <Text>{sectionNames}</Text>
              </div>
            </td>
            <td>{showLocalTime(intakeForm.createdAt)}</td>
            <td>{formStatus}</td>
            <td>
              <IntakeRowActions
                resendingForm={resendingForm}
                newIntakeForm={intakeForm}
                onResendIntakeInvite={onResendIntakeInvite}
              />
            </td>
          </tr>
        )
      })}
    </>
  )
}
