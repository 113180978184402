import { DefaultOptionType } from 'antd/lib/select'

export type OptionInput = {
  label: string
  value: string | number
  grouped?: boolean
  groupItems?: Record<any, any>[]
}

export const filterValueAndLabel = (
  input: string,
  option: OptionInput | undefined
) =>
  (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
  (option?.value ?? '').toString().toLowerCase().indexOf(input.toLowerCase()) >=
    0

export const filterGroupedValueAndLabel = (
  input: string,
  option: DefaultOptionType | undefined
) =>
  option?.props?.children
    .toString()
    .toLowerCase()
    .indexOf(input.toLowerCase()) >= 0 ||
  option?.props?.value.toString().toLowerCase().indexOf(input.toLowerCase()) >=
    0
