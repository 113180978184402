import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { DownOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { Link, useHistory } from 'react-router-dom'

import { createNoteV1, saltNoteV1 } from '../../../../../api/notes'
import {
  UpdateType,
  getFields,
} from '../../../../../hooks/useClinicalNoteFields/utils'
import { useProfileUrlParams } from '../../../../../hooks/useProfileUrlParams'
import { useProviderDetails } from '../../../../../hooks/useProviderInfo'
import { useProviderSidePatientData } from '../../../../../hooks/useProviderSidePatientData'
import { QUERY_PARAMS } from '../../../../../libs/constants'
import { useFeatureFlags } from '../../../../../libs/featureFlags'
import {
  NotesEvents,
  trackNotesEvent,
} from '../../../../../libs/freshpaint/notesEvents'
import Sentry from '../../../../../libs/sentry'
import {
  ClinicalNote,
  NoteTypes,
  UserFacingNoteTypes,
  UserFacingNoteTypesV1,
} from '../../../../../shared-types'
import {
  Button,
  Dropdown,
  Spinner,
  Text,
} from '../../../../../stories/BaseComponents'
import { MenuItemType } from '../../../../../stories/BaseComponents/Dropdown'
import {
  NotificationType,
  generateNotification,
} from '../../../../../stories/BaseComponents/Notification'
import { CreateNoteButtonV2 } from '../../../../../v2/notes/Components/CreateNoteButton'
import { useCreateNote } from '../../../../../v2/notes/hooks'
import { useGetAllNoteTemplates } from '../../../../../v2/notes/hooks/templates/useGetAllNoteTemplates'
import { Note } from '../../../../../v2/notes/types'
import { reverseMapNoteTypes } from '../../helpers'
import { getClinicalNotesUrl, getNoteTemplateSettingsUrl } from '../../utils'
import { NoteV1HeaderText } from './NoteV1HeaderText'
import { NoteV2HeaderText } from './NoteV2HeaderText'
import { TemplateMenuItem } from './TemplateMenuItem'
import { getWeightForNoteType } from './calculations'

import styles from './NoteHeaderV2.module.scss'

interface NotesListHeaderProps {
  notesList: ClinicalNote[]
  patientId: string
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  invalidateNotesList: () => Promise<void>
}
const NOTE_TEMPLATE_SETTINGS_URL = getNoteTemplateSettingsUrl()
export const NotesListHeader: React.FC<NotesListHeaderProps> = ({
  notesList,
  patientId,
  setIsLoading,
  invalidateNotesList,
}) => {
  const [disableActions, setDisableActions] = useState(false)
  const history = useHistory()
  const { urlParams } = useProfileUrlParams()
  const { hasEnabledCustomNotes, enableNoteTemplates } = useFeatureFlags()
  const { response: allNoteTemplates, isLoading: areNoteTemplatesLoading } =
    useGetAllNoteTemplates({
      hasEnabledCustomTemplates: hasEnabledCustomNotes,
      sorts: [{ field: 'name', order: 'asc' }],
    })
  const displayedNoteTypes = Object.values(UserFacingNoteTypesV1) // Note types displayed to user
  const [createdNoteId, setCreatedNoteId] = useState<string | null>(null)
  const [saltedNoteId, setSaltedNoteId] = useState<string | null>(null)

  const {
    isInitialLoading: providerSidePatientIsLoading,
    data: providerSidePatientData,
  } = useProviderSidePatientData(patientId ?? '')

  const { isInitialLoading: providerDetailsIsLoading, data: providerDetails } =
    useProviderDetails()

  const {
    createNewNote,
    createNewFromLast,
    createNewNoteFromTemplateId,
    isLoading: isNoteBeingCreated,
  } = useCreateNote({
    onSuccess: (data: Note, options) => {
      history.push(
        getClinicalNotesUrl({
          isV2: true,
          noteId: data.uuid,
          urlParams,
          saltAlert: options.fromLast,
        })
      )
    },
  })

  useEffect(() => setIsLoading(isNoteBeingCreated), [isNoteBeingCreated])

  const createAndOpenNewNote = () => {
    createNewNote(patientId)
  }

  const createAndOpenNoteFromLast = () => {
    createNewFromLast(patientId)
  }

  const createAndOpenNewNoteFromTemplateId = (templateId: string) => () => {
    createNewNoteFromTemplateId({ patientId, templateId })
  }

  useEffect(() => {
    if (createdNoteId) {
      history.push(
        getClinicalNotesUrl({
          noteId: createdNoteId,
          urlParams: `${urlParams}&${QUERY_PARAMS.editMode}=true`,
        })
      )
    }
  }, [createdNoteId])

  useEffect(() => {
    if (saltedNoteId) {
      history.push(
        getClinicalNotesUrl({
          noteId: saltedNoteId,
          urlParams: `${urlParams}&${QUERY_PARAMS.editMode}=true&${QUERY_PARAMS.saltAlert}=true`,
        })
      )
    }
  }, [saltedNoteId])

  const createNote = useCallback(
    async (noteTypeForUi: string) => {
      try {
        setIsLoading(true)
        setDisableActions(true)
        const noteTypeEnum = reverseMapNoteTypes(noteTypeForUi)
        const patientPhysicalNotes = providerSidePatientData?.PhysicalNotes
        const noteFormData = {
          NoteType: noteTypeEnum,
          PatientWeight: getWeightForNoteType({
            noteType: noteTypeEnum,
            weight: patientPhysicalNotes?.weight,
            // Defaulting of weight is set to 'lb' in the API,
            // defaulting to 'lb' here for consistency
            weightUnit: patientPhysicalNotes?.weightUnit ?? 'lb',
          }),
          AdditionalMedicationPatientWeight:
            patientPhysicalNotes?.weight?.toString(),
          // weight unit is recorded as 'lb' in db but KetamineUnit value expects 'lbs'
          KetamineUnits:
            patientPhysicalNotes?.weightUnit === 'kg' ? 'kg' : 'lbs',
          NewSignature: null,
        }
        const noteData = getFields(
          UpdateType.NEW,
          patientId,
          noteFormData,
          providerDetails?.clinicData,
          providerSidePatientData?.PhysicalNotes
        )
        const newNote = await createNoteV1(noteData)
        // TODO: we should optimize this by updating ViewEditNotePage.tsx to not have to rely on patientClinicalNotes.find (especially when we decide to minimize this notes list data)
        await invalidateNotesList()
        trackNotesEvent(NotesEvents.AUTOSAVE_CREATED_NOTE2, {
          noteType: noteTypeEnum,
          noteId: newNote.NoteId,
          patientId,
          providerId: providerDetails?.loggedInProviderId,
        })
        setCreatedNoteId(newNote.NoteId)
      } catch (err) {
        generateNotification(
          'Note creation failed. Try again.',
          NotificationType.ERROR
        )
        Sentry.captureException(
          new Error(
            `Error creating new ${noteTypeForUi} note for autosave editor: ${
              err.message ?? err
            }`
          )
        )
      } finally {
        setDisableActions(false)
        setIsLoading(false)
      }
    },
    [patientId, providerSidePatientData, providerDetails]
  )

  const createSaltNote = useCallback(
    async (noteTypeForUi: string) => {
      try {
        setIsLoading(true)
        setDisableActions(true)
        const noteTypeEnum = reverseMapNoteTypes(noteTypeForUi)
        const newNote = await saltNoteV1(noteTypeEnum, patientId)
        // TODO: we should optimize this by updating ViewEditNotePage.tsx to not have to rely on patientClinicalNotes.find (especially when we decide to minimize this notes list data)
        await invalidateNotesList()
        trackNotesEvent(NotesEvents.AUTOSAVE_SALTED_NOTE, {
          noteType: noteTypeEnum,
          noteId: newNote.NoteId,
          patientId,
          providerId: providerDetails?.loggedInProviderId,
        })
        setSaltedNoteId(newNote.NoteId)
      } catch (err) {
        generateNotification(
          'Note creation failed. Try again.',
          NotificationType.ERROR
        )
        Sentry.captureException(
          new Error(
            `Error SALT-ing ${noteTypeForUi} note for autosave editor: ${
              err.message ?? err
            }`
          )
        )
      } finally {
        setDisableActions(false)
        setIsLoading(false)
      }
    },
    [patientId, providerDetails]
  )

  const createNoteOptions: MenuItemType[] = useMemo(() => {
    const dropdownItems: MenuItemType[] = []
    let templateEntries: NonNullable<MenuItemType['children']> = []

    if (enableNoteTemplates) {
      templateEntries =
        allNoteTemplates?.pages
          .map(({ data }) =>
            data.map((template) => ({
              text: <TemplateMenuItem name={template.name} />,
              key: template.uuid,
              onClick: createAndOpenNewNoteFromTemplateId(template.uuid),
            }))
          )
          .flat() || []

      if (templateEntries.length > 0) {
        templateEntries.push({
          type: 'divider',
          key: 'template-divider',
          text: '',
        })
      }

      templateEntries.push({
        text: (
          <Link
            className={styles.manageTemplates}
            target="_blank"
            type="text"
            to={NOTE_TEMPLATE_SETTINGS_URL}
          >
            Manage Templates
          </Link>
        ),
        key: 'manage-templates',
      })
    }

    if (hasEnabledCustomNotes) {
      dropdownItems.push(
        {
          text: <NoteV2HeaderText />,
          key: 'notes-v2-header-text',
          disabled: true,
        },
        {
          text: <CreateNoteButtonV2 isBlankNote />,
          key: 'beta-note',
          onClick: createAndOpenNewNote,
        },
        ...(enableNoteTemplates
          ? [
              {
                text: 'Custom templates',
                key: 'custom-templates',
                children: templateEntries,
                disabled: areNoteTemplatesLoading,
              },
            ]
          : []),
        {
          type: 'divider',
          key: 'beta-note-divider',
          text: '',
        },
        {
          text: <NoteV1HeaderText />,
          key: 'notes-v1-header-text',
          disabled: true,
        }
      )
    }

    dropdownItems.push(
      ...displayedNoteTypes.map((noteTypeForUI) => ({
        text: noteTypeForUI,
        key: noteTypeForUI.toLowerCase().replace(' ', '_'),
        onClick: () => createNote(noteTypeForUI),
      }))
    )

    return dropdownItems
  }, [
    displayedNoteTypes,
    hasEnabledCustomNotes,
    enableNoteTemplates,
    areNoteTemplatesLoading,
    createNote,
    createAndOpenNewNote,
    createAndOpenNewNoteFromTemplateId,
    allNoteTemplates,
  ])

  const saltNoteOptions: MenuItemType[] = useMemo(() => {
    const noteTypeSet = new Set()

    // Only show note types that are present in the notes list
    notesList.forEach((note) => {
      noteTypeSet.add(UserFacingNoteTypes[note.NoteType])
    })

    const notesToCopy: MenuItemType[] = displayedNoteTypes
      .filter((noteTypeForUI) => noteTypeSet.has(noteTypeForUI))
      .map((noteTypeForUI) => ({
        text: noteTypeForUI,
        key: noteTypeForUI.toLowerCase().replace(' ', '_'),
        onClick: () => createSaltNote(noteTypeForUI),
      }))

    const hasBetaNote = noteTypeSet.has(UserFacingNoteTypes[NoteTypes.NOTE_V2])

    if (hasEnabledCustomNotes && hasBetaNote) {
      const hasV1Notes = notesToCopy.length > 0
      notesToCopy.unshift(
        {
          text: <NoteV2HeaderText />,
          key: 'notes-v2-header-text',
          disabled: true,
        },
        {
          text: <CreateNoteButtonV2 isBlankNote={false} />,
          key: 'beta-note-from-last',
          onClick: createAndOpenNoteFromLast,
        }
      )

      if (hasV1Notes) {
        // Place a divider between the beta note and the v1 notes
        notesToCopy.splice(
          2,
          0,
          ...[
            {
              type: 'divider',
              key: 'beta-note-divider-from-last',
              text: '',
            },
            {
              text: <NoteV1HeaderText />,

              key: 'notes-v1-header-text',
              disabled: true,
            },
          ]
        )
      }
    }

    return notesToCopy
  }, [notesList, displayedNoteTypes, createSaltNote, createAndOpenNoteFromLast])

  const isLoading = providerSidePatientIsLoading || providerDetailsIsLoading
  return (
    <div className={cx(styles.notePageHeader, styles.notesListHeader)}>
      <Text className={styles.notesListText}>Notes</Text>
      {isLoading ? (
        <Spinner fontSize={16} />
      ) : (
        <div className={styles.dropdownContainer}>
          {saltNoteOptions.length ? (
            <div
              className={cx(styles.buttonsContainer, styles.notesListDropdowns)}
            >
              <Dropdown
                data-testid="from-last-note-dropdown"
                overlayStyle={{ minWidth: 140, minHeight: 300 }}
                trigger={['click']}
                items={saltNoteOptions}
                overlayClassName={cx(
                  styles.noteTypesDropdown,
                  styles.notesFromLastDropdown
                )}
                disabled={disableActions}
              >
                <Button
                  data-testid="from-last-note-button"
                  className={cx(styles.actionButton)}
                >
                  New note from last
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          ) : null}
          <div
            className={cx(styles.buttonsContainer, styles.notesListDropdowns)}
          >
            <Dropdown
              data-testid="new-note-dropdown"
              overlayStyle={{ minWidth: 140, minHeight: 300 }}
              trigger={['click']}
              items={createNoteOptions}
              overlayClassName={styles.noteTypesDropdown}
              disabled={disableActions}
            >
              <Button
                data-testid="new-note-button"
                className={cx(styles.actionButton)}
                type="primary"
              >
                New note
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      )}
    </div>
  )
}
