import * as React from 'react'

export default function LocationOutlined(props) {
  return (
    <svg
      width={12}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.353 3.517a5.664 5.664 0 00-1.248-1.808A5.86 5.86 0 006 .047c-.783 0-1.542.15-2.258.445a5.82 5.82 0 00-1.847 1.216A5.681 5.681 0 00.647 3.516a5.55 5.55 0 00-.46 2.218c0 1.104.265 2.202.783 3.261.417.852 1 1.682 1.735 2.47a13.954 13.954 0 002.943 2.391.685.685 0 00.7 0c.374-.22 1.69-1.043 2.944-2.39.735-.788 1.317-1.62 1.735-2.47.521-1.059.786-2.155.786-3.26 0-.769-.155-1.516-.46-2.219zM6 12.753c-1.03-.655-4.688-3.247-4.688-7.017 0-1.217.486-2.361 1.37-3.223A4.723 4.723 0 016 1.172c1.255 0 2.433.476 3.319 1.342a4.47 4.47 0 011.368 3.222c0 3.77-3.657 6.362-4.687 7.017zM6 3.11a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm1.237 3.988A1.744 1.744 0 016 7.609a1.744 1.744 0 01-1.617-1.08 1.744 1.744 0 01.38-1.907c.33-.331.77-.513 1.237-.513.467 0 .906.182 1.237.513.332.331.513.77.513 1.237 0 .468-.181.907-.513 1.238z"
        fill="#262626"
      />
    </svg>
  )
}
