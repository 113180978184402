import { JSONContent } from '@tiptap/react'

import { TemplateBlock } from '../../api'

export type NoteTemplateWithoutContent = {
  uuid: string
  name: string
  description?: string
}

export type NoteTemplate = {
  uuid: string
  name: string
  description?: string
  content?: JSONContent
  blocks: TemplateBlock[]
}

export const CUSTOM_TEMPLATE_BLOCK = 'TemplateBlock' as const
