import React from 'react'

import moment from 'moment'
import { Container, Form, Row } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'

import './../PatientProfile.scss'

export default function DepressiveSaved(props) {
  const hasNoRelevantHistory =
    props.patientData.SuicideAttempts.length === 0 &&
    props.patientData.SuicidalIdeation.state === false &&
    props.patientData.PreviousSuicidalIdeation.state === false &&
    props.patientData.SelfHarm.length === 0 &&
    props.patientData.ViolenceToOthers.state === false &&
    !props.patientData.SuicideAdditionalNotes &&
    props.patientData.NoSuicideDanger === true

  const noRecordedInfo =
    props.patientData.SuicideAttempts.length === 0 &&
    props.patientData.SuicidalIdeation.state === false &&
    props.patientData.PreviousSuicidalIdeation.state === false &&
    props.patientData.SelfHarm.length === 0 &&
    props.patientData.ViolenceToOthers.state === false &&
    !props.patientData.SuicideAdditionalNotes &&
    props.patientData.NoSuicideDanger === false

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Suicidality/danger to self/others"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">
        Suicidality/danger to self/others:
      </Form.Row>
      <Form.Group className="indent">
        {props.patientData.SuicideAttempts.length !== 0 && (
          <>
            <Form.Row className="patient-dark topic indent">
              Suicide attempts:
            </Form.Row>
            {props.patientData.SuicideAttempts.map((attempt, index) => {
              return (
                <Container bsPrefix="psych" key={index}>
                  <span className="subtopic" style={{ marginLeft: '25px' }}>
                    Date:{' '}
                  </span>
                  {attempt.startDate &&
                    moment(new Date(attempt.startDate)).format(
                      attempt.dateFormat
                    )}
                  {attempt.startDate && attempt.endDate && ' - '}
                  {attempt.endDate &&
                    moment(new Date(attempt.endDate)).format(
                      attempt.dateFormat
                    )}
                  <span className="subtopic" style={{ marginLeft: '25px' }}>
                    Description:{' '}
                  </span>
                  {attempt.description}
                </Container>
              )
            })}
            <hr />
          </>
        )}
        {props.patientData.SuicidalIdeation.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.SuicidalIdeation.state)}
            />
            <span id="SuicidalIdeationShow">Suicidal ideation</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Date:{' '}
            </span>
            {props.patientData.SuicidalIdeation.Date &&
              moment(new Date(props.patientData.SuicidalIdeation.Date)).format(
                props.patientData.SuicidalIdeation.DateFormat
              )}
            {props.patientData.SuicidalIdeation.Date && ' to Present'}
            {!props.patientData.SuicidalIdeation.Date && ' - '}
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Frequency:{' '}
            </span>
            {props.patientData.SuicidalIdeation.Frequency}
            {props.patientData.SuicidalIdeation.Notes && (
              <>
                <span className="subtopic" style={{ marginLeft: '25px' }}>
                  Notes:{' '}
                </span>
                {props.patientData.SuicidalIdeation.Notes}
              </>
            )}
          </Container>
        )}
        {props.patientData.PreviousSuicidalIdeation.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.PreviousSuicidalIdeation.state
              )}
            />
            <span id="PreviousSuicidalIdeationShow">
              Previous suicidal ideation
            </span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Start Date:{' '}
            </span>
            {props.patientData.PreviousSuicidalIdeation.StartDate &&
              moment(
                new Date(props.patientData.PreviousSuicidalIdeation.StartDate)
              ).format(props.patientData.PreviousSuicidalIdeation.DateFormat)}
            {!props.patientData.PreviousSuicidalIdeation.StartDate && ' - '}
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              End Date:{' '}
            </span>
            {props.patientData.PreviousSuicidalIdeation.EndDate &&
              moment(
                new Date(props.patientData.PreviousSuicidalIdeation.EndDate)
              ).format(props.patientData.PreviousSuicidalIdeation.DateFormat)}
            {!props.patientData.PreviousSuicidalIdeation.EndDate && ' - '}
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Frequency:{' '}
            </span>
            {props.patientData.PreviousSuicidalIdeation.Frequency}
            {props.patientData.PreviousSuicidalIdeation.Notes && (
              <>
                <span className="subtopic" style={{ marginLeft: '25px' }}>
                  Notes:{' '}
                </span>
                {props.patientData.PreviousSuicidalIdeation.Notes}
              </>
            )}
          </Container>
        )}
        {props.patientData.SelfHarm.length !== 0 && (
          <>
            <Form.Row className="patient topic indent">Self harm:</Form.Row>
            {props.patientData.SelfHarm.map((attempt, index) => {
              return (
                <Container bsPrefix="psych" key={index}>
                  <span className="subtopic" style={{ marginLeft: '25px' }}>
                    Date:{' '}
                  </span>
                  {attempt.startDate
                    ? moment(new Date(attempt.startDate)).format(
                        attempt.dateFormat
                      )
                    : ' - ' +
                      (attempt.endDate &&
                        ' up to ' +
                          moment(new Date(attempt.endDate)).format(
                            attempt.dateFormat
                          ))}
                  <span className="subtopic" style={{ marginLeft: '25px' }}>
                    Description:{' '}
                  </span>
                  {attempt.description}
                </Container>
              )
            })}
            <hr />
          </>
        )}
        {props.patientData.ViolenceToOthers.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.ViolenceToOthers.state)}
            />
            <span id="ViolenceToOthersShow">Violence to others</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Date:{' '}
            </span>
            {props.patientData.ViolenceToOthers.StartDate
              ? moment(
                  new Date(props.patientData.ViolenceToOthers.StartDate)
                ).format(props.patientData.ViolenceToOthers.DateFormat)
              : ' - ' +
                (props.patientData.ViolenceToOthers.EndDate &&
                  ' up to ' +
                    moment(
                      new Date(props.patientData.ViolenceToOthers.EndDate)
                    ).format(props.patientData.ViolenceToOthers.DateFormat))}
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.ViolenceToOthers.Notes}
          </Container>
        )}
      </Form.Group>
      <Form.Group className="border-bottom indent">
        {props.patientData.SuicideAdditionalNotes && (
          <Container bsPrefix="psych">
            <Form.Row className="patient-dark">Additional Notes:</Form.Row>
            <Row bsPrefix="psych-sub">
              {props.patientData.SuicideAdditionalNotes}
            </Row>
          </Container>
        )}
      </Form.Group>
    </Form.Group>
  )
}
