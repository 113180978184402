import { useEffect } from 'react'

export enum NotesEvents {
  SAVED_NEW_NOTE = 'Saved New Note', // Event when user saves a new note
  VIEWED_NOTE = 'Viewed Note', // Event when user views a note
  EDITED_NOTE = 'Edited Note', // Event when a user clicks on edit note
  SAVED_EDITED_NOTE = 'Saved Edited Note', // Event when a user saves an editing note
  NOTE_BACKUP_DETECTED = 'Note Backup Detected', // Event when note backup modal pops up
  DISCARD_NOTE_BACKUP = 'Discard Note Backup', // Event when user discard the note backup
  RECOVER_NOTE_BACKUP = 'Recover Note Backup', // Event when user restores the note backup
  AUTOSAVE_CREATED_NOTE = 'Created Note via AutoSave', // Event when the autosave creates a new note
  AUTOSAVE_EDITED_NOTE = 'Edited Note via AutoSave', // Event when the autosave updates an existing note (captures only the first autosave in edit session)
  AUTOSAVE_ERROR = 'AutoSave Error', // Event when the autosave API throws an error
  /* These events trigger a new flow where the note is pre-created before navigating to autosave editor for that note: */
  AUTOSAVE_CREATED_NOTE2 = 'Created Note Before Nav to AutoSave', // Event when user clicks "New Note" with a specific type
  AUTOSAVE_SALTED_NOTE = 'Salted Note Before Nav to AutoSave', // Event user creates a note by "From Last"
  AUTOSAVE_COPIED_NOTE = 'Copied Note Before Nav to AutoSave', // Event when user clicks "Copy to new note" from Actions menu
  /* end comment */
}

export enum NotesProblematicEvents {
  NOTE_UNFOCUSED = 'Unfocus from note', // Event when the user unfocuses from the window or tab
  NOTE_FOCUSED = 'Focus to note', // Event when the user comes back to the note
  EDITING_ON_OTHER_TAB = 'Note edited across tabs', // Event when the user opens the note in a new tab to edit
  MULTI_USER_EDIT = 'Multiple users editing same note', // Event when a provider starts editing a note that another provider is currently editing
}

type NotesEventProperties = {
  patientId: string
  noteId?: string
  noteType?: string
  providerId?: string
  copiedFromNote?: string
}

export function trackNotesEvent(
  eventName: NotesEvents,
  eventDetails: NotesEventProperties
) {
  window?.freshpaint?.track(eventName, eventDetails)
}

export function trackNoteEditingAcrossTabs(eventDetails: {
  noteId?: string
  noteType: string
  patientId: string
  autosaveTiming: number
}) {
  window?.freshpaint?.track(NotesProblematicEvents.EDITING_ON_OTHER_TAB, {
    ...eventDetails,
    autosaveTiming: eventDetails.autosaveTiming
      ? `${eventDetails.autosaveTiming}seconds`
      : 'OFF',
  })
}

export function trackNoteEditingByMultiUsers(eventDetails: {
  noteId: string
  noteType: string
  patientId: string
  autosaveTiming: number
  user1: string
  user2: string
}) {
  window?.freshpaint?.track(NotesProblematicEvents.MULTI_USER_EDIT, {
    ...eventDetails,
    autosaveTiming: eventDetails.autosaveTiming
      ? `${eventDetails.autosaveTiming}seconds`
      : 'OFF',
  })
}

export function useNoteFocusTracking({
  noteId,
  noteType,
  patientId,
  autosaveTiming,
}: {
  noteId: string
  noteType: string
  patientId: string
  autosaveTiming: number
}) {
  useEffect(() => {
    const trackFocus = () => {
      window?.freshpaint?.track(NotesProblematicEvents.NOTE_FOCUSED, {
        noteId,
        noteType,
        patientId,
        autosaveTiming: autosaveTiming ? `${autosaveTiming}seconds` : 'OFF',
      })
    }

    const trackBlur = () => {
      window?.freshpaint?.track(NotesProblematicEvents.NOTE_UNFOCUSED, {
        noteId,
        noteType,
        patientId,
        autosaveTiming: autosaveTiming ? `${autosaveTiming}seconds` : 'OFF',
      })
    }

    window.addEventListener('focus', trackFocus)
    window.addEventListener('blur', trackBlur)

    return () => {
      window.removeEventListener('focus', trackFocus)
      window.removeEventListener('blur', trackBlur)
    }
  }, [])
}
