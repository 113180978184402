import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import styles from './TeammateModals.module.scss'

interface Props {
  isProvider: boolean
}
const ProviderInfoBox: React.FC<Props> = ({ isProvider }) => {
  return (
    <div>
      {isProvider && (
        <div className={`${styles.infoBox} ${styles.yellowBackground}`}>
          <div id={styles.infoBoxContents}>
            <div
              id={styles.exclamationCircleContainer}
              className={`${styles.exclamationCircle} ${styles.yellowExclamationCircle}`}
            >
              <ExclamationCircleOutlined />
            </div>
            <div id={styles.infoText}>
              <div id={styles.titleFont}>
                You are adding another Provider user to your account.
              </div>
              <div>
                If the number of providers on your account exceeds the number in
                your contract, a member of our team will reach out to you to
                confirm any changes to your bill.
                <a
                  href={
                    'https://support.osmind.org/en/articles/6446843-user-roles-permissions'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.linkText}
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProviderInfoBox
