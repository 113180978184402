import React from 'react'

import { FormInstance } from 'antd'

import { FormSectionKey } from '../../../api/intakeForms'
import SuccessIcon from '../../../images/Icons/success'
import { PatientDemographicObject } from '../../../shared/Demographics'
import { Divider, Space, Typography } from '../../BaseComponents'
import {
  AllergiesAndMedication,
  GeneralInformation,
  Insurance,
  MedicalHistory,
  OtherInformation,
  PsychiatricHistory,
  SocialHistory,
} from './'
import Introduction from './Introduction'
import {
  AllergiesMedicationsQuestionKeys,
  GeneralInformationQuestionKeys,
  HealthcareProviderSubForm,
  InsuranceQuestionKeys,
  MedHistoryNonPsychQuestionKeys,
  MedicationsSubFormQuestionKeys,
  OtherInformationQuestionKeys,
  PsychHistoryHospQuestionKeys,
  PsychHistoryMedsQuestionKeys,
  PsychiatricHistoryQuestionKeys,
  SocialHistoryQuestionKeys,
} from './question-keys'

import styles from './_shared.module.scss'

type QuestionsMap = {
  [key: string]: {
    title: string
    type?: string
  }
}

const { Text, Title } = Typography

const HALF_SPAN = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }
const THIRD_SPAN = { xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }
const FULL_SPAN = { xs: 24 }

const INTAKE_FORM_SECTIONS = [
  { section: FormSectionKey.GENERAL_INFORMATION, title: 'General information' },
  { section: FormSectionKey.INSURANCE, title: 'Insurance information' },
  {
    section: FormSectionKey.ALLERGIES_MEDICATIONS,
    title: 'Allergies & medications',
  },
  { section: FormSectionKey.PSYCHIATRIC_HISTORY, title: 'Psychiatric history' },
  {
    section: FormSectionKey.MEDICAL_HISTORY,
    title: 'Medical history (non-psychiatric)',
  },
  { section: FormSectionKey.SOCIAL_HISTORY, title: 'Social history' },
  { section: FormSectionKey.OTHER_INFORMATION, title: 'Other information' },
]

export enum INTAKE_REVIEW_FORM_ITEM_TYPE {
  section = 'section',
}

export enum INTAKE_REVIEW_FORM_EDITOR_STYLE {
  section = 'section',
  block = 'block',
  subSection = 'subSection',
}

const INTAKE_REVIEW_FORM: Array<{
  name: string
  title: string
  type?: INTAKE_REVIEW_FORM_ITEM_TYPE.section
  section?: FormSectionKey
}> = [
  {
    name: FormSectionKey.GENERAL_INFORMATION,
    title: 'General Information',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
  },
  {
    name: GeneralInformationQuestionKeys.LEGAL_FIRST_NAME,
    title: 'Legal first name',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.LEGAL_MIDDLE_NAME,
    title: 'Legal middle name',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.LEGAL_LAST_NAME,
    title: 'Legal last name',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.FIRST_NAME_TO_USE,
    title: 'First name to use',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.MIDDLE_NAME_TO_USE,
    title: 'Middle name to use',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.LAST_NAME_TO_USE,
    title: 'Last name to use',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.FORMER_FIRST_NAME,
    title: 'Former first name',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.FORMER_MIDDLE_NAME,
    title: 'Former middle name',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.FORMER_LAST_NAME,
    title: 'Former last name',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.ADDRESS,
    title: 'Address',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.ADDRESS_UNIT_NUMBER,
    title: 'Unit or suite number',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.PHONE,
    title: 'Phone',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.PHONE_TYPE,
    title: 'Phone type',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.DOB,
    title: 'Date of birth',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.BIRTH_SEX,
    title: 'Birth sex',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.LEGAL_SEX,
    title: 'Legal sex',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.GENDER_IDENTITY,
    title: 'Gender identity',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.PRONOUNS,
    title: 'Pronouns',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.RACE,
    title: 'Race',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.RACE_SUBCATEGORY,
    title: 'Race subcategory',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.ETHNICITY,
    title: 'Ethnicity',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.SPECIFIC_ETHNICITY,
    title: 'Specific ethnicity',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.EMERGENCY_CONTACT_NAME,
    title: 'Emergency contact name',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.EMERGENCY_CONTACT_PHONE,
    title: 'Emergency contact phone',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.EMERGENCY_CONTACT_EMAIL,
    title: 'Emergency contact email',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.EMERGENCY_CONTACT_RELATIONSHIP,
    title: 'Emergency contact relationship',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.HEALTHCARE_PROVIDERS,
    title: 'Healthcare providers',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: HealthcareProviderSubForm.HEALTHCARE_PROVIDER_NAME,
    title: 'Name',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: HealthcareProviderSubForm.HEALTHCARE_PROVIDER_SPECIALTY,
    title: 'Role',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: HealthcareProviderSubForm.HEALTHCARE_PROVIDER_PHONE,
    title: 'Phone',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: HealthcareProviderSubForm.HEALTHCARE_PROVIDER_EMAIL,
    title: 'Email',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: HealthcareProviderSubForm.HEALTHCARE_PROVIDER_ADDRESS,
    title: 'Address',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: HealthcareProviderSubForm.HEALTHCARE_PROVIDER_ADDRESS_UNIT_NUMBER,
    title: 'Unit or suite number',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: FormSectionKey.INSURANCE,
    title: 'Insurance',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
  },
  {
    name: InsuranceQuestionKeys.INSURANCE_NAME,
    title: 'Insurance company',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.INSURANCE_MEMBER_ID,
    title: 'Insurance member ID',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.INSURANCE_GROUP_ID,
    title: 'Insurance group ID',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.IS_PRIMARY_INSURANCE_HOLDER,
    title: 'Primary insurance holder?',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.PRIMARY_INSURED_RELATIONSHIP,
    title: 'Relationship with primary insured',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.PRIMARY_INSURED_FIRST_NAME,
    title: 'Primary insurance subscriber’s first name',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.PRIMARY_INSURED_LAST_NAME,
    title: 'Primary insurance subscriber’s last name',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.PRIMARY_INSURED_DATE_OF_BIRTH,
    title: 'Primary insurance subscriber’s DOB',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.PRIMARY_INSURED_LEGAL_SEX,
    title: 'Primary insurance subscriber’s legal sex per the insurance plan',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.PRIMARY_INSURED_EMAIL,
    title: 'Primary insurance subscriber’s email',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.PRIMARY_INSURED_PHONE,
    title: 'Primary insurance subscriber’s phone',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.PRIMARY_INSURED_ADDRESS,
    title: 'Primary insurance subscriber’s address',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.PRIMARY_INSURED_ADDRESS_UNIT_NUMBER,
    title: 'Primary insurance subscriber’s unit or suite number',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.SECONDARY_INSURANCE_NAME,
    title: 'Secondary insurance company name',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.SECONDARY_INSURANCE_MEMBER_ID,
    title: 'Secondary insurance member ID',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.SECONDARY_INSURANCE_GROUP_ID,
    title: 'Secondary insurance group ID',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_RELATIONSHIP,
    title: 'Secondary insurance subscriber’s relationship',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_FIRST_NAME,
    title: 'Secondary insurance subscriber’s first name',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_LAST_NAME,
    title: 'Secondary insurance subscriber’s last name',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_DATE_OF_BIRTH,
    title: 'Secondary insurance subscriber’s date of birth',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.IS_PRIMARY_INFORMATION_RELEASE_AUTHORIZED,
    title:
      'Patient authorized the release of any medical or other information necessary to process insurance claims for their primary insurance',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.IS_SECONDARY_INFORMATION_RELEASE_AUTHORIZED,
    title:
      'Patient authorized the release of any medical or other information necessary to process insurance claims for their secondary insurance',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.IS_PRIMARY_PAYMENT_AUTHORIZED,
    title:
      'Patient authorized the primary insurance subscriber for payment of medical benefits to the undersigned physician or supplier for any in-network, claimed services',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: InsuranceQuestionKeys.IS_SECONDARY_PAYMENT_AUTHORIZED,
    title:
      'Patient authorized the secondary insurance subscriber for payment of medical benefits to the undersigned physician or supplier for any in-network, claimed services',
    section: FormSectionKey.INSURANCE,
  },
  {
    name: FormSectionKey.ALLERGIES_MEDICATIONS,
    title: 'Allergies & Medications',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
  },
  {
    name: AllergiesMedicationsQuestionKeys.HAS_ALLERGIES,
    title: 'Allergies',
    section: FormSectionKey.ALLERGIES_MEDICATIONS,
  },
  {
    name: AllergiesMedicationsQuestionKeys.ALLERGIES,
    title: 'Details of allergies to medications, foods or other substances',
    section: FormSectionKey.ALLERGIES_MEDICATIONS,
  },
  {
    name: AllergiesMedicationsQuestionKeys.HAS_MEDICATIONS,
    title: 'Current medications',
    section: FormSectionKey.ALLERGIES_MEDICATIONS,
  },
  {
    name: AllergiesMedicationsQuestionKeys.CURRENT_MEDICATIONS,
    title: ' ',
    section: FormSectionKey.ALLERGIES_MEDICATIONS,
  },
  {
    name: MedicationsSubFormQuestionKeys.MED_NAME,
    title: 'Medication name',
    section: FormSectionKey.ALLERGIES_MEDICATIONS,
  },
  {
    name: MedicationsSubFormQuestionKeys.DOSAGE,
    title: 'Dosage with units',
    section: FormSectionKey.ALLERGIES_MEDICATIONS,
  },
  {
    name: MedicationsSubFormQuestionKeys.FREQUENCY,
    title: 'Frequency',
    section: FormSectionKey.ALLERGIES_MEDICATIONS,
  },
  {
    name: MedicationsSubFormQuestionKeys.FREQUENCY_OPTIONAL_DETAIL,
    title: 'Additional frequency detail',
    section: FormSectionKey.ALLERGIES_MEDICATIONS,
  },
  {
    name: MedicationsSubFormQuestionKeys.REASONS_FOR_USE,
    title: 'Reason(s) for taking medication',
    section: FormSectionKey.ALLERGIES_MEDICATIONS,
  },
  {
    name: FormSectionKey.PSYCHIATRIC_HISTORY,
    title: 'Psychiatric History',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
  },
  {
    name: PsychiatricHistoryQuestionKeys.EXISTING_PSYCH_DATA,
    title: 'Previous psychiatric diagnoses',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.EXISTING_PSYCH_DATA_OTHER,
    title: 'Other psychiatric conditions',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_SUICIDAL_THOUGHTS,
    title: `Current suicidal thoughts`,
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_SUICIDAL_IDEATION,
    title: `Past suicidal thoughts`,
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_SUICIDAL_ATTEMPT,
    title: 'Prior suicide attempt(s)',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_HISTORY_OF_SELF_HARM,
    title: 'History of self-harm',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HOSPITALIZATIONS,
    title: 'Psychiatric hospitalizations',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryHospQuestionKeys.REASON_FOR_HOSPITALIZATION,
    title: 'Reason(s) for hospitalization',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryHospQuestionKeys.START_OF_HOSPITALIZATION,
    title: 'Approximate hospitalization start date',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryHospQuestionKeys.END_OF_HOSPITALIZATION,
    title: 'Approximate hospitalization end date',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryHospQuestionKeys.LENGTH_OF_HOSPITALIZATION,
    title: 'Approximate length of hospitalization',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_KETAMINE_TREATMENT,
    title: 'Prior ketamine treatment for mental health',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.NUMBER_OF_KETAMINE_TREATMENTS,
    title: 'Number of ketamine treatment sessions',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.KETAMINE_TREATMENT_DETAILS,
    title: 'Ketamine treatment details',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_THERAPY_TREATMENT,
    title: 'Prior therapy for mental health',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.THERAPY_TREATMENT_DETAILS,
    title: 'Therapy treatment details',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_TMS_TREATMENT,
    title: 'Prior Transcranial Magnetic Stimulation (TMS)',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.NUMBER_OF_TMS_TREATMENTS,
    title: 'Number of TMS courses',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.TMS_TREATMENT_DETAILS,
    title: 'TMS treatment details',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_ECT_TREATMENT,
    title: 'Prior Electroconvulsive Therapy (ECT)',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.NUMBER_OF_ECT_TREATMENTS,
    title: 'Number of ECT sessions',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.ECT_TREATMENT_DETAILS,
    title: 'ECT treatment details',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.OTHER_MENTAL_HEALTH_SERVICES,
    title:
      'Other mental health services or treatments including self-help groups (e.g. AA) and rehabilitation programs',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_TAKEN_PSYCH_MEDS,
    title: 'Prior psych meds',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.PSYCH_MEDS,
    title: ' ',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryMedsQuestionKeys.IS_STILL_PRESCRIBED,
    title: 'Currently prescribed',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryMedsQuestionKeys.START_OF_MED,
    title: 'Approximate medication start',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryMedsQuestionKeys.END_OF_MED,
    title: 'Approximate medication stop',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryMedsQuestionKeys.MAX_DOSE_PER_DAY,
    title: 'Max dose per day',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryMedsQuestionKeys.REASONS_FOR_USE,
    title: 'Reason(s) for taking medication',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryMedsQuestionKeys.REASON_FOR_DISCONTINUATION,
    title: 'Reason(s) for stopping medication',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryMedsQuestionKeys.HAS_SIDE_EFFECTS,
    title: 'Medication side effects',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryMedsQuestionKeys.SIDE_EFFECTS_DESCRIPTION,
    title: 'Side effects details',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychHistoryMedsQuestionKeys.REASON_FOR_DISCONTINUATION,
    title: 'Reason(s) for stopping medication',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: FormSectionKey.MEDICAL_HISTORY,
    title: 'Medical History',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.CONDITIONS,
    title: 'Current or past non-psychiatric issues/conditions/diagnoses',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.OTHER_CONDITIONS,
    title: 'Other non-psychiatric issues, conditions, or diagnoses',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.HEIGHT_FEET,
    title: 'Height (feet)',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.HEIGHT_INCHES,
    title: 'Height (inches)',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.WEIGHT,
    title: 'Weight (pounds)',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.IS_PREGNANT_OR_NURSING,
    title: 'Pregnant or nursing',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.IS_PLANNING_ON_BEING_PREGNANT,
    title: 'Plans to get pregnant in the near future',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.HAS_PREVIOUS_SURGERIES,
    title: 'Surgical history',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.HAS_PREVIOUS_HOSPITALIZATION,
    title: 'Prior non-psychiatric hospitalizations',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: FormSectionKey.SOCIAL_HISTORY,
    title: 'Social History',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
    section: FormSectionKey.SOCIAL_HISTORY,
  },
  {
    name: SocialHistoryQuestionKeys.IS_USING_ALCOHOL,
    title: 'Alcohol use',
    section: FormSectionKey.SOCIAL_HISTORY,
  },
  {
    name: SocialHistoryQuestionKeys.SUBSTANCE_USE_HISTORY,
    title: 'Substance use history',
    section: FormSectionKey.SOCIAL_HISTORY,
  },
  {
    name: SocialHistoryQuestionKeys.HIGHEST_EDUCATION_LEVEL,
    title: 'Highest education level',
    section: FormSectionKey.SOCIAL_HISTORY,
  },
  {
    name: SocialHistoryQuestionKeys.EMPLOYMENT_STATUS,
    title: 'Employment status',
    section: FormSectionKey.SOCIAL_HISTORY,
  },
  {
    name: SocialHistoryQuestionKeys.CURRENT_RELATIONSHIP_STATUS,
    title: 'Marital/relational status',
    section: FormSectionKey.SOCIAL_HISTORY,
  },
  {
    name: SocialHistoryQuestionKeys.HAS_SERVED_IN_MILITARY,
    title: 'Military service',
    section: FormSectionKey.SOCIAL_HISTORY,
  },
  {
    name: SocialHistoryQuestionKeys.HAS_COMBAT_EXPERIENCE,
    title: 'Combat experience',
    section: FormSectionKey.SOCIAL_HISTORY,
  },
  {
    name: FormSectionKey.OTHER_INFORMATION,
    title: 'Other Information',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
  },
  {
    name: OtherInformationQuestionKeys.HEAR_ABOUT_CLINIC,
    title: 'How patient heard about practice',
    section: FormSectionKey.OTHER_INFORMATION,
  },
  {
    name: OtherInformationQuestionKeys.REFERRING_CLINICIAN_NAME,
    title: "Referring clinician's name",
    section: FormSectionKey.OTHER_INFORMATION,
  },
  {
    name: OtherInformationQuestionKeys.REFERRING_CLINICIAN_PHONE,
    title: "Referring clinician's phone",
    section: FormSectionKey.OTHER_INFORMATION,
  },
  {
    name: OtherInformationQuestionKeys.REFERRING_CLINICIAN_EMAIL,
    title: "Referring clinician's email",
    section: FormSectionKey.OTHER_INFORMATION,
  },
  {
    name: OtherInformationQuestionKeys.REFERRING_CLINICIAN_SPECIALTY,
    title: "Referring clinician's role",
    section: FormSectionKey.OTHER_INFORMATION,
  },
  {
    name: OtherInformationQuestionKeys.OTHER_IMPORTANT_INFO,
    title:
      'Other important history and concerns (e.g. recent losses/stressors, spiritual concerns, legal issues, financial concerns).',
    section: FormSectionKey.OTHER_INFORMATION,
  },
]

export type IntakeReviewFormSection = {
  name: string
  title: string
  type?: INTAKE_REVIEW_FORM_ITEM_TYPE.section
  section?: FormSectionKey
  style?: INTAKE_REVIEW_FORM_EDITOR_STYLE
  relatedQuestions?: Array<IntakeReviewFormSection>
}
const INTAKE_REVIEW_FORM_V2: Array<IntakeReviewFormSection> = [
  {
    name: GeneralInformationQuestionKeys.HEALTHCARE_PROVIDERS,
    title: 'Healthcare Providers',
    section: FormSectionKey.GENERAL_INFORMATION,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.section,
  },
  {
    name: FormSectionKey.ALLERGIES_MEDICATIONS,
    title: 'Allergies & Medications',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.section,
  },
  {
    name: AllergiesMedicationsQuestionKeys.HAS_ALLERGIES,
    title: 'Allergies',
    section: FormSectionKey.ALLERGIES_MEDICATIONS,
    relatedQuestions: [
      {
        name: AllergiesMedicationsQuestionKeys.ALLERGIES,
        title: 'Details of allergies to medications, foods or other substances',
        section: FormSectionKey.ALLERGIES_MEDICATIONS,
      },
    ],
  },
  {
    name: AllergiesMedicationsQuestionKeys.HAS_MEDICATIONS,
    title: 'Current medications',
    section: FormSectionKey.ALLERGIES_MEDICATIONS,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.block,
    relatedQuestions: [
      {
        name: AllergiesMedicationsQuestionKeys.CURRENT_MEDICATIONS,
        title: ' ',
        section: FormSectionKey.ALLERGIES_MEDICATIONS,
        style: INTAKE_REVIEW_FORM_EDITOR_STYLE.block,
      },
    ],
  },

  {
    name: FormSectionKey.PSYCHIATRIC_HISTORY,
    title: 'Psychiatric History',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.section,
  },
  {
    name: PsychiatricHistoryQuestionKeys.EXISTING_PSYCH_DATA,
    title: 'Previous psychiatric diagnoses',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.subSection,
  },
  {
    name: PsychiatricHistoryQuestionKeys.EXISTING_PSYCH_DATA_OTHER,
    title: 'Other psychiatric conditions',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.block,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_SUICIDAL_THOUGHTS,
    title: `Current suicidal thoughts`,
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_SUICIDAL_IDEATION,
    title: `Past suicidal thoughts`,
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_SUICIDAL_ATTEMPT,
    title: 'Prior suicide attempt(s)',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_HISTORY_OF_SELF_HARM,
    title: 'History of self-harm',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HOSPITALIZATIONS,
    title: 'Previous psychiatric hospitalizations',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.subSection,
  },
  {
    name: FormSectionKey.PSYCHIATRIC_HISTORY,
    title: 'Previous psychiatric treatments',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.subSection,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_KETAMINE_TREATMENT,
    title: 'Prior ketamine treatment for mental health',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.block,
    relatedQuestions: [
      {
        name: PsychiatricHistoryQuestionKeys.NUMBER_OF_KETAMINE_TREATMENTS,
        title: 'Number of ketamine treatment sessions',
        section: FormSectionKey.PSYCHIATRIC_HISTORY,
      },
      {
        name: PsychiatricHistoryQuestionKeys.KETAMINE_TREATMENT_DETAILS,
        title: 'Ketamine treatment details',
        section: FormSectionKey.PSYCHIATRIC_HISTORY,
      },
    ],
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_THERAPY_TREATMENT,
    title: 'Prior therapy for mental health',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.block,
    relatedQuestions: [
      {
        name: PsychiatricHistoryQuestionKeys.THERAPY_TREATMENT_DETAILS,
        title: 'Therapy treatment details',
        section: FormSectionKey.PSYCHIATRIC_HISTORY,
      },
    ],
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_TMS_TREATMENT,
    title: 'Prior Transcranial Magnetic Stimulation (TMS)',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.block,
    relatedQuestions: [
      {
        name: PsychiatricHistoryQuestionKeys.NUMBER_OF_TMS_TREATMENTS,
        title: 'Number of TMS courses',
        section: FormSectionKey.PSYCHIATRIC_HISTORY,
      },
      {
        name: PsychiatricHistoryQuestionKeys.TMS_TREATMENT_DETAILS,
        title: 'TMS treatment details',
        section: FormSectionKey.PSYCHIATRIC_HISTORY,
      },
    ],
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_ECT_TREATMENT,
    title: 'Prior Electroconvulsive Therapy (ECT)',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.block,
    relatedQuestions: [
      {
        name: PsychiatricHistoryQuestionKeys.NUMBER_OF_ECT_TREATMENTS,
        title: 'Number of ECT sessions',
        section: FormSectionKey.PSYCHIATRIC_HISTORY,
      },
      {
        name: PsychiatricHistoryQuestionKeys.ECT_TREATMENT_DETAILS,
        title: 'ECT treatment details',
        section: FormSectionKey.PSYCHIATRIC_HISTORY,
      },
    ],
  },

  {
    name: PsychiatricHistoryQuestionKeys.OTHER_MENTAL_HEALTH_SERVICES,
    title:
      'Other mental health services or treatments including self-help groups (e.g. AA) and rehabilitation programs',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.block,
  },
  {
    name: FormSectionKey.PSYCHIATRIC_HISTORY,
    title: 'Previous psychiatric medications',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.subSection,
  },
  {
    name: PsychiatricHistoryQuestionKeys.HAS_TAKEN_PSYCH_MEDS,
    title: 'Prior psych meds',
    section: FormSectionKey.PSYCHIATRIC_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.block,
    relatedQuestions: [
      {
        name: PsychiatricHistoryQuestionKeys.PSYCH_MEDS,
        title: ' ',
        section: FormSectionKey.PSYCHIATRIC_HISTORY,
      },
    ],
  },
  {
    name: FormSectionKey.MEDICAL_HISTORY,
    title: 'Medical History',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.section,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.CONDITIONS,
    title: 'Current or past non-psychiatric issues/conditions/diagnoses',
    section: FormSectionKey.MEDICAL_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.subSection,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.OTHER_CONDITIONS,
    title: 'Other non-psychiatric issues, conditions, or diagnoses',
    section: FormSectionKey.MEDICAL_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.block,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.HEIGHT_FEET,
    title: 'Height (feet)',
    section: FormSectionKey.MEDICAL_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.block,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.HEIGHT_INCHES,
    title: 'Height (inches)',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.WEIGHT,
    title: 'Weight (pounds)',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.IS_PREGNANT_OR_NURSING,
    title: 'Pregnant or nursing',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.IS_PLANNING_ON_BEING_PREGNANT,
    title: 'Plans to get pregnant in the near future',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.HAS_PREVIOUS_SURGERIES,
    title: 'Surgical history',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: MedHistoryNonPsychQuestionKeys.HAS_PREVIOUS_HOSPITALIZATION,
    title: 'Prior non-psychiatric hospitalizations',
    section: FormSectionKey.MEDICAL_HISTORY,
  },
  {
    name: FormSectionKey.SOCIAL_HISTORY,
    title: 'Social History',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.section,
  },
  {
    name: GeneralInformationQuestionKeys.LEGAL_SEX,
    title: 'Legal sex',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.BIRTH_SEX,
    title: 'Birth sex',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.GENDER_IDENTITY,
    title: 'Gender identity',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.PRONOUNS,
    title: 'Pronouns',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.RACE,
    title: 'Race',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.RACE_SUBCATEGORY,
    title: 'Race subcategory',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.ETHNICITY,
    title: 'Ethnicity',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: GeneralInformationQuestionKeys.SPECIFIC_ETHNICITY,
    title: 'Specific ethnicity',
    section: FormSectionKey.GENERAL_INFORMATION,
  },
  {
    name: SocialHistoryQuestionKeys.IS_USING_ALCOHOL,
    title: 'Alcohol use',
    section: FormSectionKey.SOCIAL_HISTORY,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.block,
  },
  {
    name: SocialHistoryQuestionKeys.SUBSTANCE_USE_HISTORY,
    title: 'Substance use history',
    section: FormSectionKey.SOCIAL_HISTORY,
  },
  {
    name: SocialHistoryQuestionKeys.HIGHEST_EDUCATION_LEVEL,
    title: 'Highest education level',
    section: FormSectionKey.SOCIAL_HISTORY,
  },
  {
    name: SocialHistoryQuestionKeys.EMPLOYMENT_STATUS,
    title: 'Employment status',
    section: FormSectionKey.SOCIAL_HISTORY,
  },
  {
    name: SocialHistoryQuestionKeys.CURRENT_RELATIONSHIP_STATUS,
    title: 'Marital/relational status',
    section: FormSectionKey.SOCIAL_HISTORY,
  },
  {
    name: SocialHistoryQuestionKeys.HAS_SERVED_IN_MILITARY,
    title: 'Military service',
    section: FormSectionKey.SOCIAL_HISTORY,
    relatedQuestions: [
      {
        name: SocialHistoryQuestionKeys.HAS_COMBAT_EXPERIENCE,
        title: 'Combat experience',
        section: FormSectionKey.SOCIAL_HISTORY,
      },
    ],
  },
  {
    name: FormSectionKey.OTHER_INFORMATION,
    title: 'Other Information',
    type: INTAKE_REVIEW_FORM_ITEM_TYPE.section,
    section: FormSectionKey.OTHER_INFORMATION,
    style: INTAKE_REVIEW_FORM_EDITOR_STYLE.section,
  },
  {
    name: OtherInformationQuestionKeys.HEAR_ABOUT_CLINIC,
    title: 'How patient heard about practice',
    section: FormSectionKey.OTHER_INFORMATION,
  },
  {
    name: OtherInformationQuestionKeys.REFERRING_CLINICIAN_NAME,
    title: "Referring clinician's name",
    section: FormSectionKey.OTHER_INFORMATION,
  },
  {
    name: OtherInformationQuestionKeys.REFERRING_CLINICIAN_SPECIALTY,
    title: "Referring clinician's role",
    section: FormSectionKey.OTHER_INFORMATION,
  },
  {
    name: OtherInformationQuestionKeys.OTHER_IMPORTANT_INFO,
    title:
      'Other important history and concerns (e.g. recent losses/stressors, spiritual concerns, legal issues, financial concerns).',
    section: FormSectionKey.OTHER_INFORMATION,
  },
]

/**
 * Reference object that transforms the INTAKE_REVIEW_FORM array into an object mapping the
 * question title to question name (questionKey)
 */
const INTAKE_REVIEW_FORM_MAP = INTAKE_REVIEW_FORM.reduce<QuestionsMap>(
  (questionsMap, { name, ...rest }) => {
    questionsMap[name] = {
      title: rest.title,
      type: rest.type,
    }
    return questionsMap
  },
  {}
)

const getFormSectionComponent = (
  section: FormSectionKey,
  form: FormInstance,
  reviewMode = false
): React.ReactElement => {
  const sections = {
    [FormSectionKey.INTRODUCTION]: <Introduction />,
    [FormSectionKey.ALLERGIES_MEDICATIONS]: (
      <AllergiesAndMedication
        key="review-allergies-and-medications"
        reviewMode={reviewMode}
        form={form}
      />
    ),
    [FormSectionKey.INSURANCE]: (
      <Insurance key="review-insurance" reviewMode={reviewMode} form={form} />
    ),
    [FormSectionKey.MEDICAL_HISTORY]: (
      <MedicalHistory
        key="review-medical-history"
        reviewMode={reviewMode}
        form={form}
      />
    ),
    [FormSectionKey.SOCIAL_HISTORY]: (
      <SocialHistory
        key="review-social-history"
        reviewMode={reviewMode}
        form={form}
      />
    ),
    [FormSectionKey.PSYCHIATRIC_HISTORY]: (
      <PsychiatricHistory
        key="review-psych-history"
        reviewMode={reviewMode}
        form={form}
      />
    ),
    [FormSectionKey.OTHER_INFORMATION]: (
      <OtherInformation
        key="review-other-information"
        reviewMode={reviewMode}
        form={form}
      />
    ),
    [FormSectionKey.GENERAL_INFORMATION]: (
      <GeneralInformation
        key="review-general-info"
        reviewMode={reviewMode}
        form={form}
      />
    ),
  }

  return (
    sections[section] ?? (
      <GeneralInformation reviewMode={reviewMode} form={form} />
    )
  )
}

const OPTIONAL_TEXT = <Text type="secondary">(optional)</Text>

const Header: React.FC<{
  title?: string
  isOptional?: boolean
  description?: string
  shouldAddSpacing?: boolean
  levelSize?: 4 | 2 | 1 | 3 | 5 | undefined
}> = ({
  title = '',
  isOptional = false,
  description = '',
  shouldAddSpacing = false,
  levelSize = 4,
}) => (
  <Space
    className={shouldAddSpacing ? styles.titleSpaceName : styles.titleSpace}
    direction="vertical"
    size={0}
  >
    <Space>
      <Title level={levelSize}>{title}</Title>
      {isOptional && OPTIONAL_TEXT}
    </Space>
    <Text>{description}</Text>
  </Space>
)

const DividerWithSpacer: React.FC = () => (
  <Divider className={styles.dividerSpacer} />
)

const LargeSubText: React.FC<{
  paragraphText: string
  shouldAddSpacing?: boolean
}> = ({ paragraphText = '', shouldAddSpacing = false }) => (
  <Space
    className={shouldAddSpacing ? styles.titleSpaceName : styles.titleSpace}
    direction="vertical"
    size={4}
  >
    <Text className={styles.largeSubText}>{paragraphText}</Text>
  </Space>
)

const SectionHeader: React.FC<{
  sectionTitle: string
  description: string
}> = ({ sectionTitle = '', description = '' }) => (
  <Space direction="vertical" size={2}>
    <Space direction="vertical" size={5}>
      <Title level={2}>{sectionTitle}</Title>
    </Space>
    <LargeSubText paragraphText={description} />
  </Space>
)

const IntroSectionHeader: React.FC<{
  sectionTitle: string
  levelSize?: 4 | 2 | 1 | 3 | 5 | undefined
}> = ({ sectionTitle = '', levelSize = 2 }) => (
  <Space direction="vertical" size={2}>
    <Title level={levelSize}>{sectionTitle}</Title>
  </Space>
)

const ParagraphBlock: React.FC<{
  paragraphText: string
  shouldAddSpacing?: boolean
}> = ({ paragraphText = '', shouldAddSpacing = false }) => (
  <Space
    className={shouldAddSpacing ? styles.titleSpaceName : styles.titleSpace}
    direction="vertical"
    size={4}
  >
    <Text>{paragraphText}</Text>
  </Space>
)

const SuccessTitle: React.FC<{
  titleText: string
  description: string
}> = ({ titleText = '', description = '' }) => (
  <Space size={0} className={styles.successContainer} direction="vertical">
    <Space className={styles.successContainer}>
      <SuccessIcon />
    </Space>
    <Space size={4}>
      <Title level={4}>{titleText}</Title>
    </Space>
    <Space className={styles.descriptionText}>
      <Text>{description} </Text>
    </Space>
  </Space>
)

export interface MappedCodeSetOptions {
  key: string
  title: string
  label: string
  value: string
  description?: string
}

const mapCodesetToListView = (
  value: PatientDemographicObject
): MappedCodeSetOptions => {
  const [codeset, entry] = Object.entries(value)[0]
  return {
    key: `${entry.title}-${codeset}`,
    title: entry.title,
    label: entry.title,
    // value: codeset, <== Switch to this when codesets ready
    value: entry.title,
    description: entry.description ?? '',
  }
}

// Use temporary for legacy UI since form machine accepts array of string
const mapCodesetValues = (value: PatientDemographicObject): any => {
  const [_codeset, entry] = Object.entries(value)[0]
  return entry.title
}

export {
  HALF_SPAN,
  THIRD_SPAN,
  FULL_SPAN,
  INTAKE_FORM_SECTIONS,
  INTAKE_REVIEW_FORM,
  INTAKE_REVIEW_FORM_V2,
  INTAKE_REVIEW_FORM_MAP,
  OPTIONAL_TEXT,
  Header,
  SectionHeader,
  ParagraphBlock,
  IntroSectionHeader,
  SuccessTitle,
  DividerWithSpacer,
  LargeSubText,
  mapCodesetToListView,
  getFormSectionComponent,
  mapCodesetValues,
}
