import React, { useEffect, useState } from 'react'

import moment from 'moment'
import 'moment-timezone'
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  ToggleButton,
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-dates/initialize'

import './DatePicker.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dates/lib/css/_datepicker.css'

const dateFormats = [{ format: 'MM/YYYY' }, { format: 'YYYY' }]

export default function ShowHospitalizations(props) {
  const [reasonForHospitalization, setReasonForHospitalization] = useState(
    props.ReasonForHospitalization || ''
  )
  const [lenghtOfHospitalization, setLenghtOfHospitalization] = useState(
    props.LenghtOfHospitalization || ''
  )
  const [startDate, setStartDate] = useState(props.StartDate || '')
  const [endDate, setEndDate] = useState(props.EndDate || '')
  const [hospitalName, setHospitalName] = useState(props.HospitalName || '')
  const [additionalNotes, setAdditionalNotes] = useState(
    props.AdditionalNotes || ''
  )

  const [diagnosisOnsetDateFormat, setDiagnosisOnsetDateFormat] = useState(
    props.DateFormat ? props.DateFormat : 'MM/YYYY'
  )
  const [diagnosisOnsetDate, setDiagnosisOnsetDate] = useState(null)

  useEffect(() => {
    setReasonForHospitalization(props.ReasonForHospitalization || '')
    setLenghtOfHospitalization(props.LenghtOfHospitalization || '')
    setStartDate(props.StartDate || '')
    setEndDate(props.EndDate || '')
    setHospitalName(props.HospitalName || '')
    setAdditionalNotes(props.AdditionalNotes || '')
    setDiagnosisOnsetDateFormat(props.DateFormat ? props.DateFormat : 'MM/YYYY')
  }, [
    props.ReasonForHospitalization,
    props.LenghtOfHospitalization,
    props.StartDate,
    props.EndDate,
    props.HospitalName,
    props.AdditionalNotes,
    props.DateFormat,
  ])

  useEffect(() => {
    const fields = {
      reasonForHospitalization: reasonForHospitalization,
      lenghtOfHospitalization: lenghtOfHospitalization,
      startDate: startDate,
      endDate: endDate,
      hospitalName: hospitalName,
      additionalNotes: additionalNotes,
      dateFormat: diagnosisOnsetDateFormat,
    }
    props.saveHospitalization(fields, props.index)
  }, [
    reasonForHospitalization,
    lenghtOfHospitalization,
    startDate,
    endDate,
    additionalNotes,
    hospitalName,
    diagnosisOnsetDateFormat,
  ])

  function handleDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        setDiagnosisOnsetDate(date)
        let newOnsetDate = diagnosisOnsetDate
        newOnsetDate = date.toDate()
        setStartDate(newOnsetDate)
      } else {
        const newOnsetDate = moment(date, diagnosisOnsetDateFormat)
        setDiagnosisOnsetDate(newOnsetDate)
        setStartDate(date)
      }
    } else {
      setStartDate('')
    }
  }
  function handleEndDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        setDiagnosisOnsetDate(date)
        let newOnsetDate = diagnosisOnsetDate
        newOnsetDate = date.toDate()
        setEndDate(newOnsetDate)
      } else {
        const newOnsetDate = moment(date, diagnosisOnsetDateFormat)
        setDiagnosisOnsetDate(newOnsetDate)
        setEndDate(date)
      }
    } else {
      setEndDate('')
    }
  }

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} xs={12} sm={12} md={6} lg={6} xl={6}>
          <Form.Label className="patient">
            Reason for Hospitalization
          </Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="textarea"
            rows={1}
            value={reasonForHospitalization}
            disabled={props.disabled}
            onChange={(e) => setReasonForHospitalization(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={12} md={6} lg={6} xl={6}>
          <Form.Label className="patient">Additional Notes</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="textarea"
            rows={1}
            value={additionalNotes}
            disabled={props.disabled}
            onChange={(e) => setAdditionalNotes(e.target.value)}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={6} sm={6} md={5} lg={5} xl={5}>
          <Form.Label className="patient">Hospital Name</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="textarea"
            rows={1}
            value={hospitalName}
            disabled={props.disabled}
            onChange={(e) => setHospitalName(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} xs={6} sm={6} md={3} lg={3} xl={3}>
          <Form.Label className="patient">
            Hospitalization length (days)
          </Form.Label>
          <Form.Control
            bsPrefix="form-input"
            type="number"
            value={lenghtOfHospitalization}
            disabled={props.disabled}
            onChange={(e) => setLenghtOfHospitalization(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} xs={6} sm={6} md={2} lg={2} xl={2}>
          <Row style={{ width: 'min-content' }}>
            <Col>
              <Form.Label style={{ fontWeight: 'bold' }}>Start</Form.Label>
            </Col>
            <Col>
              <ButtonGroup style={{ marginLeft: -14 }} toggle size="sm">
                {dateFormats.map((btn, idx) => (
                  <ToggleButton
                    className="date-format-selector"
                    variant="primary"
                    key={idx}
                    type="radio"
                    name="radio"
                    value={btn.format}
                    style={{ fontSize: '10px' }}
                    checked={diagnosisOnsetDateFormat === btn.format}
                    onChange={(e) =>
                      setDiagnosisOnsetDateFormat(e.currentTarget.value)
                    }
                  >
                    {btn.format}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Col>
          </Row>

          <Form.Group controlId="DiagnosisOnsetDatePicker">
            {diagnosisOnsetDateFormat === 'MM/YYYY' && (
              <DatePicker
                className="date-input"
                selected={startDate === '' ? '' : new Date(startDate)}
                placeholderText={diagnosisOnsetDateFormat}
                onChange={(date) => handleDate(date)}
                maxDate={new Date()}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            )}

            {diagnosisOnsetDateFormat === 'YYYY' && (
              <DatePicker
                className="date-input"
                selected={startDate === '' ? '' : new Date(startDate)}
                placeholderText={diagnosisOnsetDateFormat}
                onChange={(date) => handleDate(date)}
                maxDate={new Date()}
                dateFormat="yyyy"
                showYearPicker
              />
            )}
          </Form.Group>
        </Form.Group>
        <Form.Group as={Col} xs={6} sm={6} md={2} lg={2} xl={2}>
          <Form.Label className="patient">End</Form.Label>
          {diagnosisOnsetDateFormat === 'MM/YYYY' && (
            <DatePicker
              className="date-input"
              selected={endDate === '' ? '' : new Date(endDate)}
              placeholderText={diagnosisOnsetDateFormat}
              onChange={(date) => handleEndDate(date)}
              maxDate={new Date()}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          )}

          {diagnosisOnsetDateFormat === 'YYYY' && (
            <DatePicker
              className="date-input"
              selected={endDate === '' ? '' : new Date(endDate)}
              placeholderText={diagnosisOnsetDateFormat}
              onChange={(date) => handleEndDate(date)}
              maxDate={new Date()}
              dateFormat="yyyy"
              showYearPicker
            />
          )}
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          style={{ alignSelf: 'center', textAlign: 'center' }}
        >
          <Button
            bsPrefix="center plain-button info button-label"
            onClick={() => {
              props.deleteHospitalization(props.index)
            }}
          >
            <i className="fa fa-trash" aria-hidden="true" />
            &nbsp;Delete
          </Button>
        </Form.Group>
      </Form.Row>
      <hr />
    </>
  )
}
