import { useEffect, useState } from 'react'

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Button, Card, Col, Form, Row, Tab } from 'react-bootstrap'

import { isEmptyObject } from '../../../../../../libs/utils'
import { useClinicalNoteContext } from '../../../ClinicalNoteContext'
import { Section } from './constants'

enum Common {
  NONE = 'No known issues',
  OTHER = 'Other',
}

export default function KetamineSpecificROS(props: any) {
  const { fields, setFields, findMostRecentEntry } = useClinicalNoteContext()
  const state = fields.KetamineSpecificROS as any

  const setState = (
    key: string,
    value?: string | boolean | Record<string, string>
  ) => {
    if (key === Common.NONE) {
      return setFields({
        ...fields,
        KetamineSpecificROS: value ? null : {},
      })
    }

    return setFields({
      ...fields,
      KetamineSpecificROS: {
        ...state,
        [key]: value,
      },
    })
  }
  const [expanded, setExpanded] = useState<boolean>(false)

  const mostRecentEntry = findMostRecentEntry('KetamineSpecificROS')

  useEffect(() => {
    setExpanded(
      (isExpanded) => isExpanded || !isEmptyObject(state) || state === null
    )
  }, [state])

  const renderCheckbox = (
    key: string,
    value: any,
    { hasText, nested, options, description }: any
  ) => {
    const checked = value !== undefined
    return (
      <Form.Group key={key} as={Col} xs={12} sm={12} lg={4} xl={4}>
        <Form.Row key={key}>
          <Form.Check
            id={`checkbox-${key}`}
            type="checkbox"
            style={{ marginLeft: '5px' }}
            checked={checked}
            label={key}
            disabled={props.disabled}
            onChange={(event) =>
              setState(key, event.currentTarget.checked ? '' : undefined)
            }
          />

          {checked && hasText && (
            <Form.Control
              bsPrefix="form-input"
              placeholder="Additional notes"
              style={{ margin: '5px 10px' }}
              value={value}
              disabled={props.disabled}
              onChange={(event) => setState(key, event.target.value)}
            />
          )}
          {checked && nested && (
            <Form.Group key={key} style={{ marginLeft: '25px' }}>
              <div
                className="semiTitle"
                style={{ fontSize: 14, margin: '10px 0' }}
              >
                {description}
              </div>
              {Object.entries(options).map(([option, label]: any) => {
                const checked = value?.[option] !== undefined
                return (
                  <Form.Row
                    key={key}
                    style={{
                      fontSize: 14,
                      marginLeft: 8,
                      paddingBottom: '5px',
                    }}
                  >
                    <Form.Check
                      id={`checkbox-${key}-${option}`}
                      type="checkbox"
                      checked={checked}
                      label={label}
                      disabled={props.disabled}
                      onChange={(event) =>
                        setState(key, {
                          ...value,
                          [option]: event.currentTarget.checked
                            ? ''
                            : undefined,
                        })
                      }
                    />
                  </Form.Row>
                )
              })}
            </Form.Group>
          )}
        </Form.Row>
      </Form.Group>
    )
  }

  const renderText = (key: any) => {
    const value = state?.[key]
    return (
      <Form.Group key={key} as={Col} xs={12} sm={12} lg={4} xl={4}>
        <Form.Label style={{ marginLeft: '5px', fontWeight: 600 }}>
          {' '}
          {key}
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={1}
          style={{ fontSize: '14px' }}
          bsPrefix="form-input"
          value={value}
          disabled={props.disabled}
          onChange={(event) => setState(key, event.target.value)}
        />
      </Form.Group>
    )
  }

  const renderNone = () => {
    return (
      <Form.Check
        className="green"
        type="checkbox"
        id="NoneKetamineROS"
        checked={state === null}
        style={{ marginLeft: '5px' }}
        label={Common.NONE}
        disabled={props.disabled}
        onChange={(event) => setState(Common.NONE, event.currentTarget.checked)}
      />
    )
  }

  return !expanded ? (
    <Row style={{ justifyContent: 'center' }}>
      <span>
        <Button
          bsPrefix="center plain-button info button-label"
          onClick={() => setExpanded(true)}
        >
          + Ketamine-specific Medical ROS
        </Button>
      </span>
    </Row>
  ) : (
    <Accordion defaultActiveKey="0">
      <Accordion.Toggle
        bsPrefix="accordion-header very-large accordion-toggle"
        as={Card.Header}
        eventKey="0"
      >
        <div
          className="alert-clinical-notes"
          style={{ cursor: 'pointer', marginBottom: 16, display: 'flex' }}
        >
          <span style={{ width: 'calc(100% - 150px)', paddingLeft: '120px' }}>
            Ketamine-specific Medical ROS
          </span>
          {mostRecentEntry && !props.disabled ? (
            <Button
              className="modal-save-button"
              style={{ width: '150px' }}
              onClick={() =>
                setFields({
                  ...fields,
                  KetamineSpecificROS: mostRecentEntry.value,
                })
              }
            >
              Copy most recent -{' '}
              {new Date(mostRecentEntry.CreatedOn).toLocaleDateString()}
            </Button>
          ) : null}
          {!props.disabled && (
            <Button
              className="modal-save-button"
              style={{ width: '120px' }}
              onClick={() => {
                setFields({
                  ...fields,
                  KetamineSpecificROS: {},
                })
                return setExpanded(false)
              }}
            >
              <FontAwesomeIcon
                icon={faTrash}
                style={{ marginLeft: '5px', marginRight: '5px', fontSize: 16 }}
              />
              Remove
            </Button>
          )}
        </div>
      </Accordion.Toggle>

      <Tab.Content
        style={{
          display: 'flex',
          flexFlow: 'column wrap',
          paddingTop: 16,
          maxHeight: 400,
          overflowX: 'scroll',
        }}
      >
        {Object.entries(Section).map(([key, config]) => {
          const value = state?.[key]
          return renderCheckbox(key, value, config)
        })}

        <Form.Group as={Col} xs={12} sm={12} lg={4} xl={4}>
          <Form.Row>{renderNone()}</Form.Row>
        </Form.Group>
        {renderText(Common.OTHER)}
      </Tab.Content>
    </Accordion>
  )
}
