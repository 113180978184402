import React from 'react'

import { Button, Modal } from 'react-bootstrap'

/**
 * Set in the ReactBootstrap's Modal component
 * Exporting this to make the coupling more obvious when other files rely on this role
 */
export const ROLE = 'dialog'

interface ConfirmationModalProps {
  show: boolean
  onCancel?: () => void
  onConfirm: () => void
  title?: string
  body?: React.ReactElement | string
  cancelText?: string
  okText?: string
  size?: string
  showIcon?: boolean
  singleButton?: boolean
  'data-testid'?: string
}

/**
 * Generates a reasonable testId based on inputs
 * - Uses testId, if provided
 * - Parses title, if provided
 *   - Removes all non-word characters
 *   - Replaces spaces with `-` (consolidating adjacent spaces into one `-`)
 * - Defaults to `ConfirmationModal`
 * @example
 * { title: 'Are you sure you want to log out?'
 *    ==> 'ConfirmationModal--Are-you-sure-you-want-to-log-out'
 * @example
 * { title: 'Remove ! characters' }
 *   ==> 'ConfirmationModal--Remove-characters'
 * @returns {string}
 */
export const getConfirmationModalTestId = ({
  testId,
  title,
}: {
  testId?: string
  title?: string
}) =>
  testId ??
  (title
    ? `ConfirmationModal--${title
        ?.replace(/[^\w-\s]/gi, '')
        .replace(/\s+/gi, '-')}`
    : 'ConfirmationModal')

export default function ConfirmationModal(
  props: ConfirmationModalProps & { id?: string }
) {
  const title = props.title || 'Are you sure?'
  const body = props.body || 'You have unsaved changes. Close without saving?'
  const cancel = props.cancelText || 'Cancel'
  const ok = props.okText || 'OK'
  const size = props.size || 'md'
  const showIcon = props.showIcon ?? true
  const singleButton = props.singleButton ?? false
  const testId = getConfirmationModalTestId({
    testId: props['data-testid'],
    title: props.title,
  })

  return (
    <Modal
      show={props.show}
      onHide={props.onCancel}
      backdropClassName="backdrop"
      // @ts-ignore
      size={size}
      className="delete-modal vertical-align-center front-modal"
      centered
      data-testid={testId}
    >
      <Modal.Header closeButton={!singleButton} className="modal-full-header">
        <Modal.Title id="contained-modal-title-vcenter">
          {showIcon ? (
            <>
              <i
                className="fa fa-exclamation-circle fa-lg delete-icon"
                aria-hidden="true"
              />
              &nbsp; {title}&nbsp;
            </>
          ) : (
            <>{title}</>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>
      <Modal.Footer>
        {!singleButton && (
          <Button
            onClick={props.onCancel}
            variant="secondary"
            className="cancel-btn"
          >
            {cancel}
          </Button>
        )}
        <Button
          id="deleteConfirmation"
          onClick={props.onConfirm}
          className="danger-btn"
        >
          {ok}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
