import { useEffect, useState } from 'react'

import { NoteType } from '../useClinicalNoteFields/utils'
import { useQueryStorage } from '../useQueryStorage'
import { NOTE_V1_BACKUP_CADENCE_IN_SECONDS } from './constants'

const ONE_WEEK = 1000 * 60 * 60 * 24 * 7

export const useSetClinicalNoteBackup = (
  patientId: string,
  hasPendingChanges?: boolean
) => {
  const [backupFields, setBackupFields] = useState<NoteType | null>(null)
  const [triggerUpdate, setTriggerUpdate] = useState(false)
  const [isNoteBackupChecked, setIsNoteBackupChecked] = useState(false)
  const [backupFound, setBackupFound] = useState(false)

  const { upsertCache, deleteCache } = useQueryStorage<Record<string, any>>(
    [], // With autosave enabled it can only save when autosave request failed with autosave flag disabled the default is true so backup saves constantly
    ONE_WEEK
  )

  // Hook that saves the data on query localstorage, using useEffect to avoid outdated states issues
  useEffect(() => {
    if (triggerUpdate) {
      upsertCache({
        noteData: { ...backupFields, PatientId: patientId },
        backupDate: new Date().toISOString(),
      })
      setTriggerUpdate(false)
    }
  }, [triggerUpdate])

  // Interval is always running but not always saving
  useEffect(() => {
    const interval = setInterval(() => {
      // Wait until note matches all the required parameters to start saving the backup
      if (
        backupFields?.NoteType &&
        !triggerUpdate &&
        isNoteBackupChecked &&
        hasPendingChanges
      ) {
        setTriggerUpdate(true)
      }
    }, NOTE_V1_BACKUP_CADENCE_IN_SECONDS * 1000)
    return () => clearInterval(interval)
  }, [backupFields, isNoteBackupChecked, hasPendingChanges])

  return {
    setBackupFields,
    setIsNoteBackupChecked,
    isNoteBackupChecked,
    deleteCache,
    setBackupFound,
    backupFound,
  }
}
