import React from 'react'

import { Text } from './index'

export const RequiredLabel = ({
  content,
  showAsterisk = true,
}: {
  content: string | React.ReactNode
  showAsterisk?: boolean
}) => (
  <Text>
    <span style={{ color: '#F5222D' }}>{showAsterisk && '*'}</span> {content}
  </Text>
)
