import React, { useEffect, useState } from 'react'

import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  GlobalOutlined,
  PlusOutlined,
  ShopOutlined,
} from '@ant-design/icons'
import { Modal } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { deleteLocation, getLocation } from '../../api/api-lib'
import { deleteRoom, getRooms } from '../../api/api-lib-typed'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import {
  Button,
  Card,
  Divider,
  Space,
  Table,
  TableAvatar,
} from '../../stories/BaseComponents'
import { FILTER_LOCAL_STORAGE_LOCATION } from '../../stories/Scheduling/constants'
import LocationModal, { getAddress } from './LocationModal'
import RoomModal from './RoomModal'

import styles from '../_shared.module.scss'
import './Locations.scss'

export type TypeLocation = 'physical' | 'other'

const TypeLocationIcon = {
  physical: <ShopOutlined />,
  other: <GlobalOutlined />,
}

type integer = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'
export type PlaceOfServiceCode = `${integer}${integer}` | ''

export interface LocationItem {
  PK: string
  SK: string
  LocationId: string
  LocationName: string
  LocationType: TypeLocation
  AddressLine1?: string
  AddressLine2?: string
  City?: string
  LocationState?: string
  Zipcode?: string
  Description?: string
  BillingNPI?: string
  Ein?: string
  PhoneNumber?: string
  Pos?: PlaceOfServiceCode
  CreatedAt: string
  UpdatedAt?: string
  Deleted?: boolean
}

export interface RoomItem {
  locationId: string
  roomName: string
  roomId: string
  deleted?: boolean
  createdAt?: string
}

export interface RoomTableItem {
  key: string
  LocationId: string
  RoomName: string
  RoomId: string
}

export interface LocationTableItem {
  key: string
  LocationId: string
  LocationType: string
  LocationName: string
  LocationAddress: string
  AddressLine1?: string
  AddressLine2?: string
  City?: string
  LocationState?: string
  Zipcode?: string
  Description: string
  LocationBillingNPI?: string
  LocationEIN?: string
  LocationPhoneNumber?: string
  LocationPOS?: PlaceOfServiceCode
}

/**
 * Add a new location and shows a location table
 */
export default function Locations() {
  const [showAddLocationModal, setShowAddLocationModal] =
    useState<boolean>(false)
  const [showAddRoomModal, setShowAddRoomModal] = useState<boolean>(false)
  const [locationModalType, setLocationModalType] = useState<string>('isAdd')
  const [isEditingRoom, setIsEditingRoom] = useState<boolean>(false)
  const [locationDisplayItem, setLocationDisplayItem] = useState<
    LocationTableItem | undefined
  >(undefined)
  const [locations, setLocations] = useState<LocationItem[]>()
  const [rooms, setRooms] = useState<RoomItem[]>()
  const [editLocationItem, setEditLocationItem] = useState<LocationTableItem>()
  const [editRoomItem, setEditRoomItem] = useState<RoomTableItem>()
  const [isTableLoading, setTableLoading] = useState<boolean>(false)

  async function getLocations() {
    try {
      setTableLoading(true)
      const locationInfo: LocationItem[] = await getLocation()
      setLocations(locationInfo.filter((l) => !l.Deleted))
      setTableLoading(false)
    } catch (e) {
      console.error('Error when fetching locations', e)
    }
  }

  async function getClinicRooms() {
    try {
      setTableLoading(true)
      const roomInfo: RoomItem[] = await getRooms()
      setRooms(roomInfo)
      setTableLoading(false)
    } catch (e) {
      console.error('Error when fetching rooms', e)
    }
  }

  useEffect(() => {
    getLocations()
    getClinicRooms()
  }, [])

  /**
   * Add a location button click handler
   */
  function handleAddLocation() {
    setLocationModalType('isAdd')
    setShowAddLocationModal(true)
  }

  function handleAddRoom() {
    setIsEditingRoom(false)
    setShowAddRoomModal(true)
  }

  /**
   * Location edit button click handler
   */
  function handleEditLocation(locationItem: LocationTableItem) {
    setLocationModalType('isEdit')
    setEditLocationItem(locationItem)
    setShowAddLocationModal(true)
  }

  function handleEditRoom(roomItem: RoomTableItem) {
    setIsEditingRoom(true)
    setEditRoomItem(roomItem)
    setShowAddRoomModal(true)
  }

  async function handleDeleteLocation(id: string) {
    try {
      await deleteLocation(id)
      notification('Successfully deleted a location.', 'success')
      getLocations()
    } catch (e) {
      console.error('Error when deleting a location', e)
      onError(new Error('Unable to edit location'))
    }
  }

  async function handleDeleteRoom(id: string) {
    try {
      await deleteRoom(id)

      // remove this roomId from selected scheduling filters
      const schedulingFilters = JSON.parse(
        localStorage.getItem(FILTER_LOCAL_STORAGE_LOCATION) || '{}'
      )
      const newRoomFilters = schedulingFilters.roomsSelected?.filter(
        (r: string) => r !== id
      )
      schedulingFilters.roomsSelected = newRoomFilters
      localStorage.setItem(
        FILTER_LOCAL_STORAGE_LOCATION,
        JSON.stringify(schedulingFilters)
      )

      notification('Successfully deleted a room.', 'success')
      getClinicRooms()
    } catch (e) {
      console.error('Error when deleting a room', e)
      onError(new Error('Unable to delete room'))
    }
  }

  /**
   * Location delete button click handler
   */
  function handleDeleteLocationClick(locationId: string) {
    Modal.confirm({
      title: 'Delete location',
      content:
        'Are you sure you want to delete this location and all associated rooms?',
      okText: 'Ok',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'default',
      },
      okButtonProps: {
        type: 'primary',
      },
      onOk: () => {
        handleDeleteLocation(locationId)
      },
    })
  }

  function handleDeleteRoomClick(roomId: string) {
    Modal.confirm({
      title: 'Delete room',
      content: 'Are you sure you want to delete this room?',
      okText: 'Ok',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'default',
      },
      okButtonProps: {
        type: 'primary',
      },
      onOk: () => {
        handleDeleteRoom(roomId)
      },
    })
  }

  const locationColumns: ColumnsType<LocationTableItem> = [
    {
      dataIndex: 'LocationName',
      key: 'LocationName',
      sorter: {
        compare: (a: LocationTableItem, b: LocationTableItem) => {
          if (a.LocationName > b.LocationName) return 1
          else if (a.LocationName === b.LocationName) return 0
          return -1
        },
        multiple: 2,
      },
      render: (_value, record) => {
        const roomCount = rooms
          ? rooms.filter(
              (room) => room.locationId === record.LocationId && !room.deleted
            ).length
          : 0

        return (
          <TableAvatar
            id={record.LocationId}
            icon={
              TypeLocationIcon[
                record.LocationType.toLowerCase() as TypeLocation
              ]
            }
            heading={record.LocationName}
            subheading={`(${roomCount} Rooms/resources)`}
            description={record.LocationAddress}
          />
        )
      },
    },
    {
      title: '',
      key: 'action',
      render: (text: any, record: LocationTableItem) => {
        return (
          <Space size="small" className="tableButtonContainer">
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={(event) => {
                event.stopPropagation()
                handleEditLocation(record)
              }}
              className="antd-center-button action-button-edit"
            >
              Edit
            </Button>

            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={(event) => {
                event.stopPropagation()
                handleDeleteLocationClick(record.LocationId)
              }}
              className="antd-center-button action-button-delete"
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  const roomColumns = [
    {
      title: 'Rooms/resources',
      dataIndex: 'RoomName',
      key: 'RoomName',
    },
    {
      title: '',
      key: 'action',
      align: 'right' as const,
      render: (text: any, record: RoomTableItem) => {
        return (
          <Space size="middle" className="tableButtonContainer">
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => handleEditRoom(record)}
              className="antd-center-button action-button-edit"
            >
              Edit
            </Button>

            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteRoomClick(record.RoomId)}
              className="antd-center-button action-button-delete"
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  const locationData = React.useMemo(() => {
    const locationList: LocationTableItem[] = (locations || []).map(
      (location, index) => {
        return {
          key: `${index}`,
          LocationId: location.LocationId,
          LocationType:
            location.LocationType.charAt(0).toUpperCase() +
            location.LocationType.slice(1),
          LocationName: location.LocationName,
          LocationAddress:
            location.LocationType === 'other' ? '' : getAddress(location),
          AddressLine1: location.AddressLine1,
          AddressLine2: location.AddressLine2,
          City: location.City,
          LocationState: location.LocationState,
          Zipcode: location.Zipcode,
          Description: location.Description ?? '',
          LocationBillingNPI: location.BillingNPI,
          LocationEIN: location.Ein,
          LocationPhoneNumber: location.PhoneNumber,
          LocationPOS: location.Pos,
        }
      }
    )

    return locationList
  }, [locations])

  const roomData = React.useMemo(() => {
    const roomList: RoomTableItem[] = []
    if (!rooms) {
      return []
    }
    for (const room of rooms) {
      if (
        room.locationId === locationDisplayItem?.LocationId &&
        !room.deleted
      ) {
        roomList.push({
          key: `${room.roomId}-${locationDisplayItem.LocationId}`,
          LocationId: room.locationId,
          RoomName: room.roomName,
          RoomId: room.roomId,
        })
      }
    }

    return roomList
  }, [rooms, locationDisplayItem, locations])

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        {!locationDisplayItem ? (
          <>
            <Card bordered={false}>
              <div className="headerWrapper headerWrapper_row">
                <span className="headerWrapper_title">Locations</span>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleAddLocation}
                  className="antd-center-button"
                >
                  Location
                </Button>
              </div>
              <Divider className="settings-table-divider" />
              <Table
                className="settings-table"
                columns={locationColumns}
                dataSource={locationData}
                pagination={false}
                loading={isTableLoading}
                size="middle"
                showHeader={false}
                onRow={(location) => ({
                  onClick: () => {
                    setLocationDisplayItem(location as LocationTableItem)
                  },
                })}
              />
            </Card>

            <LocationModal
              show={showAddLocationModal}
              getLocations={getLocations}
              onHide={() => setShowAddLocationModal(false)}
              locationItem={
                locationModalType === 'isAdd' ? undefined : editLocationItem
              }
            />
          </>
        ) : (
          <>
            <Card bordered={false}>
              <div className="headerWrapper headerWrapper_row">
                <span className="headerWrapper_title">
                  <ArrowLeftOutlined
                    style={{ marginRight: 8 }}
                    onClick={() => setLocationDisplayItem(undefined)}
                  />{' '}
                  {locationDisplayItem.LocationName}
                </span>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleAddRoom}
                  className="antd-center-button"
                >
                  Add room/resource
                </Button>
              </div>

              <Table
                columns={roomColumns}
                dataSource={roomData}
                pagination={false}
                loading={isTableLoading}
                size="middle"
              />
            </Card>

            <RoomModal
              show={showAddRoomModal}
              getRooms={getClinicRooms}
              onHide={() => setShowAddRoomModal(false)}
              editRoomItem={isEditingRoom ? editRoomItem : undefined}
              locationId={locationDisplayItem.LocationId}
              // pass down only the rooms that are associated with the selected location
              rooms={rooms?.filter(
                (room) =>
                  room.locationId === locationDisplayItem.LocationId &&
                  !room.deleted
              )}
            />
          </>
        )}
      </div>
    </div>
  )
}
