import { DateISO8601 } from '../../../shared-types'

export interface FamilyHistoryResponse {
  motherPsychNotes: string
  grandmotherMaternalPsychNotes: string
  grandfatherMaternalPsychNotes: string
  auntMaternalPsychNotes: string
  uncleMaternalPsychNotes: string
  cousinMaternalPsychNotes: string
  fatherPsychNotes: string
  grandmotherPaternalPsychNotes: string
  grandfatherPaternalPsychNotes: string
  auntPaternalPsychNotes: string
  unclePaternalPsychNotes: string
  cousinPaternalPsychNotes: string
  sisterPsychNotes: string
  brotherPsychNotes: string
  daughterPsychNotes: string
  sonPsychNotes: string
  motherConditions: Conditions[]
  grandmotherMaternalConditions: Conditions[]
  grandfatherMaternalConditions: Conditions[]
  auntMaternalConditions: Conditions[]
  uncleMaternalConditions: Conditions[]
  cousinMaternalConditions: Conditions[]
  fatherConditions: Conditions[]
  grandmotherPaternalConditions: Conditions[]
  grandfatherPaternalConditions: Conditions[]
  auntPaternalConditions: Conditions[]
  unclePaternalConditions: Conditions[]
  cousinPaternalConditions: Conditions[]
  sisterConditions: Conditions[]
  brotherConditions: Conditions[]
  daughterConditions: Conditions[]
  sonConditions: Conditions[]
  motherMedicalNotes: string
  grandmotherMaternalMedicalNotes: string
  grandfatherMaternalMedicalNotes: string
  auntMaternalMedicalNotes: string
  uncleMaternalMedicalNotes: string
  cousinMaternalMedicalNotes: string
  fatherMedicalNotes: string
  grandmotherPaternalMedicalNotes: string
  grandfatherPaternalMedicalNotes: string
  auntPaternalMedicalNotes: string
  unclePaternalMedicalNotes: string
  cousinPaternalMedicalNotes: string
  sisterMedicalNotes: string
  brotherMedicalNotes: string
  daughterMedicalNotes: string
  sonMedicalNotes: string
  familyMembers: FamilyMember[]
  additionalNotes: string
  familyMemberNotes: string
  noFamilyPsychConditions: boolean
  noFamilyMedicalConditions: boolean
  createdAt?: DateISO8601
}

export enum Conditions {
  ADHD = 'ADHD',
  AlcoholAbuse = 'Alcohol Abuse',
  Anger = 'Anger',
  Anxiety = 'Anxiety',
  ASD = 'ASD',
  Bipolar = 'Bipolar',
  Cognitive = 'Cognitive',
  Depression = 'Depression',
  PTSD = 'PTSD',
  Schizophrenia = 'Schizophrenia',
  Suicide = 'Suicide',
  SuicideAttempts = 'Suicide Attempts',
  SubstanceAbuse = 'Substance Abuse (excluding alcohol)',
  Violence = 'Violence',
}

export enum FamilyMember {
  Mother = 'mother',
  GrandmotherMaternal = 'grandmotherMaternal',
  GrandfatherMaternal = 'grandfatherMaternal',
  AuntMaternal = 'auntMaternal',
  UncleMaternal = 'uncleMaternal',
  CousinMaternal = 'cousinMaternal',
  Father = 'father',
  GrandmotherPaternal = 'grandmotherPaternal',
  GrandfatherPaternal = 'grandfatherPaternal',
  AuntPaternal = 'auntPaternal',
  UnclePaternal = 'unclePaternal',
  CousinPaternal = 'cousinPaternal',
  Sister = 'sister',
  Brother = 'brother',
  Son = 'son',
  Daughter = 'daughter',
}
