import React, { useEffect, useState } from 'react'

import { Modal as AntDModal, Form, FormInstance } from 'antd'

import { noBlankSpaceOnlyRegex } from '../../libs/regex'
import { Input } from '../BaseComponents'
import Modal, { ModalProps } from '../BaseComponents/Modal'
import { ModalDisplay } from './types'

import './SettingsTableDisplay.scss'

interface CustomBillingTableProps extends ModalProps {
  form: FormInstance
  onSubmit?: (data: any) => void
  mode?: ModalDisplay
  title?: string
  toggleCancel: () => void
}

const CustomBillingForm: React.FC<CustomBillingTableProps> = ({
  form,
  mode = ModalDisplay.NONE,
  onSubmit,
  title,
  toggleCancel,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (mode === ModalDisplay.NONE) {
      setIsLoading(false)
    }
  }, [mode])

  const handleSubmit = async (_data: any) => {
    setIsLoading(true)
    try {
      await form.validateFields()
      form.submit()
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const onCancel = React.useCallback(() => {
    form.isFieldsTouched()
      ? AntDModal.confirm({
          title: 'Unsaved changes',
          content:
            'Are you sure you want to close this window? Any unsaved changes will be lost.',
          okText: 'Ok',
          cancelText: 'Cancel',
          cancelButtonProps: {
            type: 'default',
          },
          okButtonProps: {
            type: 'primary',
            loading: isLoading,
          },
          onOk: () => {
            setIsLoading(false)
            toggleCancel()
          },
        })
      : toggleCancel()
  }, [toggleCancel, isLoading, form])

  return (
    <Modal
      confirmLoading={isLoading}
      data-id="custom-billing-modal"
      okText="Save"
      title={title ?? mode}
      visible={mode !== ModalDisplay.NONE}
      onOk={handleSubmit}
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        validateTrigger="onBlur"
      >
        <Form.Item
          name="code"
          label="Billing code"
          rules={[
            {
              message: 'Valid, unique billing code is required',
              pattern: noBlankSpaceOnlyRegex,
              required: true,
              type: 'string',
              validateTrigger: 'onBlur',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="shortDescription"
          label="Short description"
          rules={[
            {
              message: 'A short description is required',
              required: true,
              type: 'string',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CustomBillingForm
