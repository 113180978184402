import { NodeViewRendererProps } from '@tiptap/react'

import { Note } from '../../../../types'
import { DynamicBlockRouter } from '../../BlockTypeRenderer/DynamicBlockRenderer'
import { CoreNode } from '../CoreNode'
import {
  CreateDynamicBlockOptions,
  DynamicBlockTypes,
} from './DynamicBlockTypes'

/**
 * Block Action Item for DropdownSelector Menu
 *
 * @typedef {Object} BlockEditorActionItem
 * @property {string} noteId - The ID of the note the action item is associated with.
 * @property {string} displayName
 * @property {string} [key] - defaults to camelCase version of displayName
 * @property {DynamicBlockTypes} blockType
 * @property {function} onClick
 */
export type BlockEditorActionItem = {
  noteId: string
  displayName: string
  key?: string
  blockType: DynamicBlockTypes
  onClick: (opts: CreateDynamicBlockOptions) => Promise<void>
}

export const getEditorMenuItem = (args: BlockEditorActionItem) => {
  const { displayName, noteId, key, blockType } = args

  return {
    text: displayName,
    key: key ?? args.displayName.toLowerCase(),
    onClick: async () => {
      await args.onClick({
        noteUuid: noteId,
        blockConfig: {
          blockType: blockType,
          options: { limit: Date.now() },
        },
      })
    },
  }
}

export const DynamicBlock = ({ note }: { note: Note }) =>
  CoreNode({
    BlockComponent: (props: NodeViewRendererProps) => {
      return DynamicBlockRouter({ props, note })
    },
    name: 'DynamicBlock',
    tag: 'dynamic-block',
  })
