import React, { ReactElement } from 'react'

import { NodeViewWrapper } from '@tiptap/react'
import cx from 'classnames'

import { BlockHeader } from '../BlockHeader'
import { DragHandle } from '../DragHandle/DragHandle'

import styles from './Block.module.scss'

export const Block = ({
  actionIcon,
  actionLabel,
  onActionClick,
  header,
  content,
  useOptions,
  isSigned,
}: {
  onActionClick?: ({ options }: { options?: Record<string, any> }) => void
  actionIcon?: ReactElement
  actionLabel?: ({
    options,
  }: {
    options?: Record<string, any>
  }) => string | ReactElement
  header: string
  content: (options: Record<string, any>, isSigned?: boolean) => ReactElement
  useOptions?: () => Record<string, any>
  isSigned: boolean
}) => {
  const options = useOptions ? useOptions() : {}

  return (
    <NodeViewWrapper
      className={!isSigned ? 'structured-block' : 'signed-structured-block'}
    >
      {!isSigned && <DragHandle />}
      <div className={cx(styles.container)}>
        <BlockHeader
          label={header}
          actionIcon={actionIcon}
          actionLabel={actionLabel}
          onActionClick={onActionClick}
          options={options}
          isSigned={isSigned}
        />
        <div>{content(options, isSigned)}</div>
      </div>
    </NodeViewWrapper>
  )
}
