import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const EatingDisorderCodes: any = [
  {
    '72366004': {
      title: 'Eating Disorder',
      description: 'Eating disorder (disorder)',
      snomedcode: 72366004,
      icdcode: 'F509',
      icddescription: 'Eating disorder, unspecified',
    },
  },
  {
    '56882008': {
      title: 'ed.Anorexia Nervosa',
      description: 'Anorexia nervosa (disorder)',
      snomedcode: 56882008,
      icdcode: 'F5000',
      icddescription: 'Anorexia nervosa, unspecified',
    },
  },
  {
    '439960005': {
      title: 'ed.Binge Eating Disorder',
      description: 'Binge eating disorder (disorder)',
      snomedcode: 439960005,
      icdcode: 'F5081',
      icddescription: 'Binge eating disorder',
    },
  },
  {
    '78004001': {
      title: 'ed.Bulimia Nervosa',
      description: 'Bulimia nervosa (disorder)',
      snomedcode: 78004001,
      icdcode: 'F502',
      icddescription: 'Bulimia nervosa',
    },
  },
]

export const EatingDisorderDropDownValues = constructPatientDemographicObject(
  EatingDisorderCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.PYSCH_CONDITIONS
)
