import React, { useEffect, useState } from 'react'

import 'moment-timezone'
import { Button, Col, Form, Row } from 'react-bootstrap'
import 'react-dates/initialize'

import { NoteTypes } from '../../../../../shared-types'
import { convertTime12to24 } from '../../../../../shared/Helpers/utils'
import CustomTimePicker from '../../../../../stories/BaseComponents/CustomTimePicker'
import { AutoSizingTextArea } from '../../../../../stories/BaseComponents/TextAreaInput'
import { generateSpravatoClassName } from '../NoteType/Spravato/Spravato'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function ShowSavedVS(props) {
  const [vitalSignsType, setVitalSignsType] = useState(
    props.VitalSignsType || ''
  )
  const [time, setTime] = useState(props.Time || '')
  const [hR, setHR] = useState(props.HR || '')
  const [bP, setBP] = useState(props.BP || '')
  const [rR, setRR] = useState(props.RR || '')
  const [sPO2, setSPO2] = useState(props.SPO2 || '')
  const [comments, setComments] = useState(props.Comments || '')
  const [eKG, setEKG] = useState(props.EKG || '')
  const [rASSscore, setRASSscore] = useState(props.RASSscore || '')
  const [temp, setTemp] = useState(props.Temp || '')

  const discharge = props.discharge
  const isNotSpravato = props.noteType !== NoteTypes.SPRAVATO
  const isSpravato = props.noteType === NoteTypes.SPRAVATO

  useEffect(() => {
    setVitalSignsType(props.VitalSignsType || '')
    setTime(props.Time || '')
    setHR(props.HR || '')
    setBP(props.BP || '')
    setRR(props.RR || '')
    setSPO2(props.SPO2 || '')
    setComments(props.Comments || '')
    setEKG(props.EKG || '')
    setRASSscore(props.RASSscore || '')
    setTemp(props.Temp || '')
  }, [
    props.VitalSignsType,
    props.Time,
    props.HR,
    props.BP,
    props.RR,
    props.SPO2,
    props.Comments,
    props.EKG,
    props.RASSscore,
    props.Temp,
  ])

  useEffect(() => {
    const fields = {
      VitalSignsType: vitalSignsType,
      Time: time,
      HR: hR,
      BP: bP,
      RR: rR,
      SPO2: sPO2,
      Comments: comments,
      EKG: eKG,
      RASSscore: rASSscore,
      Temp: temp,
    }
    props.saveEditVitalSigns && props.saveEditVitalSigns(props.index, fields)
    props.saveOneVital && props.saveOneVital(fields)
  }, [vitalSignsType, time, hR, bP, rR, sPO2, comments, eKG, rASSscore, temp])
  return (
    <>
      <tr>
        {discharge ? (
          <></>
        ) : (
          <td style={{ width: '100px', padding: '0.2rem' }}>
            <Form.Control
              bsPrefix="form-input"
              as="select"
              style={{ height: 39 }}
              disabled={props.disabled}
              onChange={(e) => setVitalSignsType(e.target.value)}
              value={vitalSignsType}
            >
              <option key={0} name="Select one" value="">
                Select
              </option>
              <option key={1} name="Before Infusion" value="Before Infusion">
                Before
              </option>
              <option key={2} name="During Infusion" value="During Infusion">
                During
              </option>
              <option key={3} name="After Infusion" value="After Infusion">
                After
              </option>
            </Form.Control>
          </td>
        )}

        <td style={{ width: '100px', padding: '0.2rem' }}>
          <CustomTimePicker
            value={time}
            onChange={(value) => {
              setTime(convertTime12to24(value))
            }}
            disabled={props.disabled}
          />
        </td>
        <td style={{ padding: '0.2rem' }}>
          <Form.Control
            bsPrefix="form-input"
            type="text"
            value={hR}
            style={{ minWidth: '68px' }}
            disabled={props.disabled}
            onChange={(e) => setHR(e.target.value)}
          />
        </td>
        <td style={{ padding: '0.2rem' }}>
          <Form.Control
            id={props.requireBP ? props.id : ''}
            bsPrefix={
              props.requireBP && !bP.includes('/')
                ? generateSpravatoClassName(true)
                : 'form-input'
            }
            type="text"
            style={{ minWidth: '85px' }}
            value={bP}
            disabled={props.disabled}
            onChange={(e) => setBP(e.target.value)}
          />
          {!isNotSpravato && (
            <Form.Text
              className="center"
              style={{ fontWeight: 'bold', color: '#A9A9A9' }}
            >
              Example 125/83
            </Form.Text>
          )}
        </td>
        {isNotSpravato && (
          <td style={{ padding: '0.2rem' }}>
            <Form.Control
              bsPrefix="form-input"
              type="text"
              value={sPO2}
              style={{ minWidth: '68px' }}
              disabled={props.disabled}
              onChange={(e) => setSPO2(e.target.value)}
            />
          </td>
        )}
        {isNotSpravato && (
          <td style={{ padding: '0.2rem' }}>
            <Form.Control
              bsPrefix="form-input"
              type="text"
              value={eKG}
              style={{ minWidth: '80px' }}
              disabled={props.disabled}
              onChange={(e) => setEKG(e.target.value)}
            />
          </td>
        )}
        <td style={{ padding: '0.2rem' }}>
          <Form.Control
            bsPrefix="form-input"
            type="text"
            value={rR}
            style={{ minWidth: '55px' }}
            disabled={props.disabled}
            onChange={(e) => setRR(e.target.value)}
          />
        </td>
        {isNotSpravato && (
          <td style={{ padding: '0.2rem' }}>
            <Form.Control
              bsPrefix="form-input"
              value={rASSscore}
              disabled={props.disabled}
              onChange={(e) => setRASSscore(e.target.value)}
            />
          </td>
        )}
        {isSpravato && (
          <td style={{ padding: '0.2rem' }}>
            <Form.Control
              bsPrefix="form-input"
              type="text"
              value={temp}
              disabled={props.disabled}
              onChange={(e) => setTemp(e.target.value)}
            />
          </td>
        )}
        <td style={{ padding: '0.2rem' }}>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={comments}
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
            onChange={(e) => setComments(e.target.value)}
          />
        </td>
        {!props.start && !props.disabled && (
          <td>
            <Row>
              <Col>
                {!props.disabled && (
                  <Button
                    bsPrefix="center plain-button info button-label"
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: 'auto',
                      position: 'relative',
                      float: 'left',
                      padding: '0px 10px!important',
                    }}
                    onClick={() => {
                      props.deleteVitals(props.index)
                    }}
                  >
                    <i
                      style={{ fontSize: 19 }}
                      className="fa fa-trash"
                      aria-hidden="true"
                    />
                  </Button>
                )}
              </Col>
            </Row>
          </td>
        )}
      </tr>
    </>
  )
}
