import React from 'react'

import { Select as AntDSelect, Form, FormItemProps } from 'antd'

import {
  alliedHealthCareTeamRoleDropdownValues,
  medicalCareTeamRoleDropdownValues,
  mentalHealthCareTeamRoleDropdownValues,
  nursingCareTeamRoleDropdownValues,
  supportStaffRoleDropdownValues,
} from '../../../shared/Demographics/ExternalCareTeamRoleCodes'
import { Select } from '../../BaseComponents'
import { mapCodesetToListView } from './helpers'

const { OptGroup, Option } = AntDSelect

export default function ProviderRolesMultiSelect(formItemProps: FormItemProps) {
  const OptionGroups = {
    Medical: medicalCareTeamRoleDropdownValues.map(mapCodesetToListView),
    'Mental Health':
      mentalHealthCareTeamRoleDropdownValues.map(mapCodesetToListView),
    Nursing: nursingCareTeamRoleDropdownValues.map(mapCodesetToListView),
    'Allied Health':
      alliedHealthCareTeamRoleDropdownValues.map(mapCodesetToListView),
    'Support Staff': supportStaffRoleDropdownValues.map(mapCodesetToListView),
  }

  const providerRoles = Object.entries(OptionGroups).map(
    ([optionGroupName, options]) => (
      <OptGroup label={optionGroupName}>
        {options.map((option) => (
          <Option value={option.description}>{option.label}</Option>
        ))}
      </OptGroup>
    )
  )

  return (
    <Form.Item {...formItemProps}>
      <Select
        allowClear
        showSearch
        placeholder="Select all that apply"
        mode="multiple"
        size="large"
      >
        {providerRoles}
      </Select>
    </Form.Item>
  )
}
