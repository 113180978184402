import React from 'react'

import { useField } from 'formik'

import {
  Button,
  Card,
  Select,
  SignaturePreview,
} from '../../../../stories/BaseComponents'
import { FontOptions } from '../../../../stories/BaseComponents/SignaturePreview'
import { useProviders } from '../hooks'
import { ClaimFormStatus, ClaimStatus } from '../types'
import {
  isClaimPresubmit,
  isFormBusy,
  isFormSaving,
  isFormSubmitting,
} from '../utils'

import sectionStyles from './Signature.module.scss'
import styles from './shared.module.scss'

export interface SignatureProps {
  claimStatus?: ClaimStatus
  formStatus: ClaimFormStatus
  onDeleteClaim: () => void
  onSaveClaim: () => void
  onSubmitClaim: () => void
}

export const testIds = {
  container: 'claim-signature',
  deleteButton: 'claim-signature-delete-button',
  saveButton: 'claim-signature-save-button',
  submitButton: 'claim-signature-submit-button',
  preview: 'claim-signature-preview',
}

const SIGNATURE = {
  name: 'signature.providerId',
  label: 'Submitting provider',
  placeholder: 'Select a signature',
}

const getFont = (font?: string) => {
  if (font && Object.values(FontOptions).includes(font as FontOptions)) {
    return font as FontOptions
  }
  return FontOptions.SACRAMENTO
}

const Signature: React.FC<SignatureProps> = ({
  claimStatus = ClaimStatus.DRAFT,
  formStatus,
  onDeleteClaim,
  onSaveClaim,
  onSubmitClaim,
}) => {
  const { name, label, placeholder } = SIGNATURE
  const [{ value: selectedProviderId }, , { setValue }] = useField({ name })

  const {
    data: { providerOptions, providersById },
  } = useProviders()

  const handleChange = (selectedValue?: any) => {
    const newValue = selectedValue ?? null
    setValue(newValue)
  }

  const selectedProvider = selectedProviderId
    ? providersById[selectedProviderId]
    : null
  const isDisabled = isFormBusy(formStatus)

  return (
    <Card
      testId={testIds.container}
      style={{ marginTop: 24 }}
      bodyStyle={{ paddingBottom: 16 }}
    >
      <div className={styles.section}>
        <div className={styles.header}>
          <span className={styles.title}>{'Signature'}</span>
        </div>
        <div className={sectionStyles.body}>
          <div>
            <label>
              {label}
              <span className={styles.requiredSymbol}>{'*'}</span>
            </label>
          </div>
          <Select
            showSearch
            options={providerOptions}
            value={selectedProviderId}
            onChange={handleChange}
            placeholder={placeholder}
            testId={name}
            allowClear
            style={{ width: '100%' }}
          />
          <SignaturePreview
            name={selectedProvider?.name ?? ''}
            font={getFont(selectedProvider?.signatureFont)}
            testId={testIds.preview}
          />
        </div>
        {isClaimPresubmit(claimStatus) && (
          <div className={sectionStyles.buttonsContainer}>
            <Button
              testId={testIds.deleteButton}
              onClick={onDeleteClaim}
              disabled={isDisabled}
            >
              Delete claim draft
            </Button>
            <Button
              testId={testIds.saveButton}
              onClick={onSaveClaim}
              loading={isFormSaving(formStatus)}
              disabled={isDisabled}
            >
              Save as draft
            </Button>
            <Button
              testId={testIds.submitButton}
              onClick={onSubmitClaim}
              loading={isFormSubmitting(formStatus)}
              disabled={isDisabled}
              type="primary"
            >
              Submit claim
            </Button>
          </div>
        )}
      </div>
    </Card>
  )
}

export default Signature
