import { INTAKE_REVIEW_FORM_EDITOR_STYLE } from '../sections/helpers'

export const createKeyValueNode = (
  contents: Object[],
  value: string,
  style?: INTAKE_REVIEW_FORM_EDITOR_STYLE
) => {
  const [start, ...rest] = value.split(':')
  const pair = [start, rest.join(':')]
  if (pair.length === 2) {
    // marks: [{ type: 'italic' }]
    const nodes: Array<{ type: string; marks?: Object[]; text?: string }> = [
      { type: 'text', text: `${pair[0]}:` },
    ]
    if (pair[1] !== '') {
      nodes.push({ type: 'text', marks: [{ type: 'bold' }], text: pair[1] })
    }
    if (
      contents.length === 0 ||
      style === INTAKE_REVIEW_FORM_EDITOR_STYLE.block ||
      (contents[contents.length - 1] as { type: string; content: Object[] })
        .type !== 'paragraph'
    ) {
      contents.push({
        type: 'paragraph',
        content: nodes,
      })
    } else {
      const lastNode = contents[contents.length - 1] as {
        type: string
        content: Object[]
      }
      nodes.unshift({ type: 'hardBreak' })
      lastNode.content = lastNode.content.concat(nodes)
    }
  }
}

export const createNode = (
  contents: Object[],
  value: string,
  style?: INTAKE_REVIEW_FORM_EDITOR_STYLE,
  className?: string
) => {
  const strippedValue = value.replace(/[\n\r]/g, '')
  if (strippedValue !== '') {
    if (
      style === INTAKE_REVIEW_FORM_EDITOR_STYLE.section ||
      style === INTAKE_REVIEW_FORM_EDITOR_STYLE.subSection
    ) {
      const level = style === INTAKE_REVIEW_FORM_EDITOR_STYLE.section ? 2 : 3
      contents.push({
        type: 'heading',
        attrs: {
          level: level,
          class: className ? className : null,
        },
        content: [
          {
            type: 'text',
            text: `${strippedValue}`,
          },
        ],
      })
    } else {
      createKeyValueNode(contents, strippedValue, style)
    }
  }
}

export const createBulletListNode = (
  contents: Object[],
  node: Object[],
  id?: string
) => {
  if (node.length > 0) {
    contents.push({
      type: 'bulletList',
      attrs: id ? { class: id } : {},
      content: node,
    })
  }
}

export const createListItemNode = (
  contents: Object[],
  labelText: string,
  list?: Object[],
  margin?: string
) => {
  const label = { type: 'text', text: labelText }
  const node: { type: string; content: Object[]; attrs?: Record<string, any> } =
    {
      type: 'listItem',
      attrs: margin ? { class: margin } : {},
      content: [
        {
          type: 'paragraph',
          content: [list ? { ...label, marks: [{ type: 'bold' }] } : label],
        },
      ],
    }
  if (list) {
    node.content.push({
      type: 'bulletList',
      content: list,
    })
  }
  contents.push(node)
}
