import React, { useEffect, useState } from 'react'

import _isEqual from 'lodash/isEqual'

import {
  submitEditSocialHistoryNotesRequest,
  submitNewSocialHistoryNotesRequest,
} from '../../../api/api-lib'
import GenericAccordion from '../../../components/Accordions/GenericAccordion'
import PatientProfileButtons from '../../../components/Buttons/PatientProfileButtons'
import FormMachine from '../../../components/Forms/FormMachine/FormMachine'
import { onError } from '../../../libs/errorLib'
import { useFormFields } from '../../../libs/hooksLib'
import { notification } from '../../../libs/notificationLib'
import { patientDataTypes } from '../patient-data-types'
import { styling, template } from './template'
import { SocialHistoryNotesResponse } from './types'

import '../PatientProfile.scss'

interface SocialHistoryProps {
  handleApiChange: (queryName: string[]) => void
  response: SocialHistoryNotesResponse
  patientId: string
}

export default function SocialHistoryNotes(props: SocialHistoryProps) {
  const [copy, setCopy] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [storedFields, handleFieldChange] = useFormFields({ ...props.response })

  function switchToSave() {
    setIsEditing(false)
  }

  function cancelEditing() {
    handleFieldChange(copy, false)
    switchToSave()
  }

  function copyStoredFields() {
    setCopy(storedFields)
  }
  function switchToEdit() {
    copyStoredFields()
    setIsEditing(true)
  }

  function checkIfSaveButtonShouldBeDisabled() {
    const haveNotesNotChanged = _isEqual(storedFields, copy)
    setIsDisabled(haveNotesNotChanged)
  }

  useEffect(() => {
    copyStoredFields()
  }, [])

  useEffect(() => {
    checkIfSaveButtonShouldBeDisabled()
  }, [storedFields])

  function handleCheckChange(id: string, key: number, isChecked = false) {
    const field = template.find((field) => field.id === id)
    if (typeof field !== 'undefined') {
      const type = field.fieldType
      const event = { target: {} }
      if (type === 'units' && typeof field.units !== 'undefined') {
        const units = field.units
        event.target = {
          id: `${id}Unit`,
          type: 'radio',
          value: units[key],
        }
      } else if (field.options) {
        const options = field.options
        if (type === 'radio' || type === 'yesNo') {
          event.target = {
            id: id,
            type: 'radio',
            value: options[key],
          }
        } else if (type === 'checkbox') {
          let selectedOptions = storedFields[id] || []
          if (!isChecked) {
            selectedOptions.push(options[key])
          } else {
            selectedOptions = selectedOptions.filter(
              (choice: string) => choice !== options[key]
            )
          }
          event.target = {
            id: id,
            type: 'checkbox',
            checked: selectedOptions,
          }
        }
      }
      handleFieldChange(event)
    }
  }

  async function handleSubmit(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    try {
      setIsLoading(true)
      const data = {
        livingSituationNotes: storedFields.livingSituationNotes || '',
        livingSituation: storedFields.livingSituation || '',
        livingSituationOther: storedFields.livingSituationOther || '',
        hasSafetyConcerns: storedFields.hasSafetyConcerns || '',
        hasSafetyConcernsExplanation:
          storedFields.hasSafetyConcernsExplanation || '',
        hasAccessToWeapons: storedFields.hasAccessToWeapons || '',
        hasAccessToWeaponsExplanation:
          storedFields.hasAccessToWeaponsExplanation || '',
        occupationNotes: storedFields.occupationNotes || '',
        currentOccupation: storedFields.currentOccupation || '',
        currentOccupationOther: storedFields.currentOccupationOther || '',
        educationNotes: storedFields.educationNotes || '',
        hasLearningDisability: storedFields.hasLearningDisability || '',
        hasLearningDisabilityExplanation:
          storedFields.hasLearningDisabilityExplanation || '',
        degree: storedFields.degree || '',
        degreeOther: storedFields.degreeOther || '',
        relationalStatus: storedFields.relationalStatus || '',
        relationalStatusOther: storedFields.relationalStatusOther || '',
        familyRelationships: storedFields.familyRelationships || '',
        familyRelationshipsOther: storedFields.familyRelationshipsOther || '',
        socialRelationships: storedFields.socialRelationships || '',
        socialRelationshipsOther: storedFields.socialRelationshipsOther || '',
        exerciseNotes: storedFields.exerciseNotes || '',
        exerciseAmount: storedFields.exerciseAmount || '',
        sexualActivityNotes: storedFields.sexualActivityNotes || '',
        isSexuallyActive: storedFields.isSexuallyActive || '',
        isSexuallyActiveExplanation:
          storedFields.isSexuallyActiveExplanation || '',
        sexualOrientation: storedFields.sexualOrientation || '',
        sexualOrientationOther: storedFields.sexualOrientationOther || '',
        dietNotes: storedFields.dietNotes || '',
        diet: storedFields.diet || '',
        spiritualityNotes: storedFields.spiritualityNotes || '',
        hasSpiritualReligiousBeliefs:
          storedFields.hasSpiritualReligiousBeliefs || '',
        hasSpiritualReligiousBeliefsExplanation:
          storedFields.hasSpiritualReligiousBeliefsExplanation || '',
        hobbiesNotes: storedFields.hobbiesNotes || '',
        militaryServiceNotes: storedFields.militaryServiceNotes || '',
        hasCombatExperience: storedFields.hasCombatExperience || '',
        hasCombatExperienceExplanation:
          storedFields.hasCombatExperienceExplanation || '',
        legalBackgroundNotes: storedFields.legalBackgroundNotes || '',
        hasLegalTroubles: storedFields.hasLegalTroubles || '',
        hasLegalTroublesExplanation:
          storedFields.hasLegalTroublesExplanation || '',
        financesNotes: storedFields.financesNotes || '',
        hasEconomicHardships: storedFields.hasEconomicHardships || '',
        hasEconomicHardshipsExplanation:
          storedFields.hasEconomicHardshipsExplanation || '',
        additionalNotes: storedFields.additionalNotes || '',
        patientId: props.patientId,
      }
      if (props.response?.createdAt) {
        await submitEditSocialHistoryNotesRequest(data)
      } else {
        await submitNewSocialHistoryNotesRequest(data)
      }
      props.handleApiChange([
        patientDataTypes.ProviderSidePatientData,
        props.patientId,
      ])

      notification(
        'You have successfully updated the Social History subsection on the patient.',
        'success'
      )
      copyStoredFields()
      setIsLoading(false)
      setIsDisabled(true)
      switchToSave()
    } catch (error) {
      console.error(
        'Error submitting information on Social History subsection of Patient Profile',
        error
      )
      onError(
        error,
        500,
        "Failed to update the patient's additonal information. Please inform your administrator."
      )
      setIsLoading(false)
    }
  }

  return (
    <GenericAccordion title="Social History">
      <FormMachine
        storedFields={storedFields}
        template={template}
        handleCheckChange={handleCheckChange}
        handleFieldChange={handleFieldChange}
        isEditing={isEditing}
        styling={styling}
      />
      <PatientProfileButtons
        cancelEditing={cancelEditing}
        isDisabled={isDisabled}
        isEditing={isEditing}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        switchToEdit={switchToEdit}
      />
    </GenericAccordion>
  )
}
