import React, { useState } from 'react'

import moment from 'moment'
import 'moment-timezone'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import useQueryString from '../../../../hooks/useQueryString'
import { notification } from '../../../../libs/notificationLib'
import CustomTimePicker from '../../../../stories/BaseComponents/CustomTimePicker'
import { AutoSizingTextArea } from '../../../../stories/BaseComponents/TextAreaInput'
import FacilityStaff from './Sections/FacilityStaff'
import MentalStatusExam from './Sections/MSE'

import '../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

const treatmentTypes = [
  { name: 'Individual', value: 'Individual' },
  { name: 'Group', value: 'Group' },
  { name: 'Couples', value: 'Couples' },
  { name: 'Collateral/Parenting', value: 'Collateral/Parenting' },
  { name: 'Family', value: 'Family' },
  {
    name: 'Ketamine-assisted Psychotherapy',
    value: 'Ketamine-assisted Psychotherapy',
  },
  {
    name: 'Psychedelic-assisted Psychotherapy',
    value: 'Psychedelic-assisted Psychotherapy',
  },
  { name: 'Other (describe)', value: 'Other (describe)' },
]

const modalityTypes = [
  { name: 'Behavioral', value: 'Behavioral' },
  { name: 'Behavioral modification', value: 'Behavioral modification' },
  { name: 'Cognitive', value: 'Cognitive' },
  { name: 'Cognitive/behavioral', value: 'Cognitive/behavioral' },
  { name: 'Crisis intervention', value: 'Crisis intervention' },
  { name: 'DBT', value: 'DBT' },
  { name: 'Expressive', value: 'Expressive' },
  { name: 'Habit reversal training', value: 'Habit reversal training' },
  { name: 'Hypnosis', value: 'Hypnosis' },
  { name: 'Insight-oriented', value: 'Insight-oriented' },
  { name: 'Interpersonal', value: 'Interpersonal' },
  { name: 'MET', value: 'MET' },
  { name: 'Psychodynamic', value: 'Psychodynamic' },
  { name: 'Stress management', value: 'Stress management' },
  { name: 'Supportive', value: 'Supportive' },
  { name: 'Other (describe)', value: 'Other (describe)' },
]

export default function PsychotherapyNotes(props) {
  const query = useQueryString()
  const patientId = query.get('patientId')
  const providerId = query.get('providerId')
  const [linkNote, setLinkNote] = useState(false)
  function treatmentGoalsFromPreviousNotes() {
    if (props.previousNotes.length === 0) {
      notification(
        'There are no previous Psychotherapy notes to import from this patient.',
        'failure'
      )
      return
    }
    props.handleFieldChange({
      ...props.fields,
      AdditionalInformation: props.previousNotes[0].AdditionalInformation,
    })
  }
  return (
    <div>
      {/* Psychotherapy Notes Ketamine Template */}
      <Row>
        <Form.Group
          as={Col}
          sm={12}
          md={9}
          lg={6}
          style={{
            textAlign: 'center',
            marginTop: '10px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {!props.fields.LinkID && !linkNote ? (
            <Button
              variant="outline-primary"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '10px',
              }}
              disabled={props.disabled}
              onClick={() => {
                setLinkNote(!linkNote)
              }}
            >
              Link Note
            </Button>
          ) : (
            <>
              <Form.Label style={{ fontWeight: 'bold' }}>Link Note</Form.Label>
              <Form.Control
                bsPrefix="form-input"
                as="select"
                disabled={props.disabled}
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    LinkID: e.target.value,
                  })
                }
                value={props.fields.LinkID}
              >
                <option key={0} name="Select one" value="">
                  Select one (Note Title - Note Type - Note Date)
                </option>
                {props.previousKAPSOAPNotes.map((note, index) => (
                  <option key={index + 1} name={note.Title} value={note.NoteId}>
                    {note.Title ? note.Title : 'No Title'} - {note.NoteType} -{' '}
                    {moment(note.CreatedOnDate).format('LL')}
                  </option>
                ))}
              </Form.Control>
            </>
          )}
        </Form.Group>
      </Row>
      {!props.fields.LinkID ? (
        ''
      ) : (
        <Row>
          <Link
            to={{
              pathname: `/patient/clinical-notes/${props.fields?.LinkID}?patientId=${patientId}&providerId=${providerId}`,
            }}
            target="_blank"
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '10px',
              marginTop: '10px',
            }}
          >
            <Button variant="primary">Open Linked Note</Button>
          </Link>
        </Row>
      )}
      <hr />
      <FacilityStaff
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        disabled={props.disabled}
        PsychotherapyNotes
      />
      <hr />
      <Row>
        <Form.Group as={Col} sm={12} md={4} lg={4}>
          <Form.Label style={{ fontWeight: 'bold' }}>Session number</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            value={props.fields.InductionNumber}
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                InductionNumber: e.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} md={8} lg={8}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Present at session
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.PatientResponseToExperience}
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientResponseToExperience: e.target.value,
              })
            }
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} sm={12} md={6} lg={6} controlId="startTime">
          <Form.Label style={{ fontWeight: 'bold' }}>
            Therapy start (HH:mm)
          </Form.Label>
          <br />
          <CustomTimePicker
            value={props.fields.StartTime}
            onChange={(value) => {
              props.handleFieldChange({ ...props.fields, StartTime: value })
            }}
            disabled={props.disabled}
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} md={6} lg={6} controlId="startTime">
          <Form.Label style={{ fontWeight: 'bold' }}>
            Therapy end (HH:mm)
          </Form.Label>
          <br />
          <CustomTimePicker
            value={props.fields.EndTime}
            onChange={(value) => {
              props.handleFieldChange({ ...props.fields, EndTime: value })
            }}
            disabled={props.disabled}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group
          as={Col}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="TreatmentType"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Treatment Type</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="select"
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                TreatmentType: e.target.value,
              })
            }
            value={props.fields.TreatmentType}
          >
            <option key={0} name="Select one" value="">
              Select one
            </option>
            {treatmentTypes.map((type, index) => (
              <option key={index + 1} value={type.value}>
                {type.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {props.fields.TreatmentType !== 'Other (describe)' ? (
          ''
        ) : (
          <Form.Group as={Col} sm={12} md={8} lg={8}>
            <Form.Label style={{ fontWeight: 'bold' }}>
              Other (describe)
            </Form.Label>
            <Form.Control
              bsPrefix="form-input"
              value={props.fields.OtherAdministration}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  OtherAdministration: e.target.value,
                })
              }
              as="textarea"
              rows="1"
            />
          </Form.Group>
        )}
      </Row>
      <Row>
        <Form.Group
          as={Col}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="TreatmentType"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Therapy treatment modality/interventions
          </Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="select"
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                IVType: e.target.value,
              })
            }
            value={props.fields.IVType}
          >
            <option key={0} name="Select one" value="">
              Select one
            </option>
            {modalityTypes.map((type, index) => (
              <option key={index + 1} name={type.name} value={type.value}>
                {type.value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {props.fields.IVType !== 'Other (describe)' ? (
          ''
        ) : (
          <Form.Group as={Col} sm={12} md={8} lg={8}>
            <Form.Label style={{ fontWeight: 'bold' }}>
              Other (describe)
            </Form.Label>
            <Form.Control
              bsPrefix="form-input"
              value={props.fields.KROtherText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KROtherText: e.target.value,
                })
              }
              as="textarea"
              rows="1"
            />
          </Form.Group>
        )}
      </Row>

      <Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Treatment goals and recent progress
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.AdditionalInformation}
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                AdditionalInformation: e.target.value,
              })
            }
            as={AutoSizingTextArea}
            minRows={2}
          />
        </Form.Group>
      </Row>
      <Row>
        <Button
          variant="outline-primary"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '10px',
          }}
          disabled={props.disabled}
          onClick={() => {
            treatmentGoalsFromPreviousNotes()
          }}
        >
          Import from last Psychotherapy note
        </Button>
      </Row>
      <MentalStatusExam {...props} />
      <Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            New issue(s) presented today
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.OtherAdditionalInformation}
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                OtherAdditionalInformation: e.target.value,
              })
            }
            as={AutoSizingTextArea}
            minRows={2}
          />
        </Form.Group>
      </Row>
      <hr />
      <Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Content / Therapeutic interventions delivered in session
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.ClinicalNoteContent}
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                ClinicalNoteContent: e.target.value,
              })
            }
            as={AutoSizingTextArea}
            minRows={5}
          />
        </Form.Group>
      </Row>
      <hr />
      <Row>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>Follow Up</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.DischargeNotes}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                DischargeNotes: e.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Row>
    </div>
  )
}
