import React, { useState } from 'react'

import { Form, FormInstance, Typography } from 'antd'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'

import { Divider, PhoneNumberInput } from '../../BaseComponents'
import {
  CHARACTER_LIMIT,
  email,
  normalizeText,
  optionalFreeText,
} from '../validation-rules'
import ProviderRolesMultiSelect from './ProviderRolesMultiSelect'
import { OtherInformationQuestionKeys } from './question-keys'

const { Title } = Typography
const { TextArea } = Input

interface Props {
  form: FormInstance
  reviewMode?: boolean
}

const OtherInformation = ({ form, reviewMode }: Props) => {
  const [referred, setReferred] = useState(false)

  return (
    <>
      <Title level={2}>Other Information</Title>
      <Divider />
      <Form.Item
        label="How did you hear about our practice?"
        name={OtherInformationQuestionKeys.HEAR_ABOUT_CLINIC}
      >
        <TextArea disabled={reviewMode} rows={4} size="large" />
      </Form.Item>
      <Form.Item label="Were you referred by a clinician?">
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          onChange={(event) => setReferred(event.target.value)}
          size="large"
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {(referred || reviewMode) && (
        <>
          <Form.Item
            label="Referring clinician's name"
            name={OtherInformationQuestionKeys.REFERRING_CLINICIAN_NAME}
            rules={[optionalFreeText('Input valid clinician name')]}
            normalize={normalizeText}
          >
            <Input disabled={reviewMode} size="large" />
          </Form.Item>
          <PhoneNumberInput
            disabled={reviewMode}
            form={form}
            name={OtherInformationQuestionKeys.REFERRING_CLINICIAN_PHONE}
            formItemProps={{ label: "Referring clinician's phone" }}
            inputProps={{ size: 'large' }}
          />
          <Form.Item
            label="Referring clinician's email"
            name={OtherInformationQuestionKeys.REFERRING_CLINICIAN_EMAIL}
            rules={[email('Invalid email address')]}
          >
            <Input disabled={reviewMode} size="large" />
          </Form.Item>
          <ProviderRolesMultiSelect
            label="Referring clinician's role"
            name={OtherInformationQuestionKeys.REFERRING_CLINICIAN_SPECIALTY}
          />
        </>
      )}
      <Form.Item
        label="Please share anything else about yourself that you think is important (e.g. recent losses/stressors, spiritual concerns, legal issues, financial concerns)."
        name={OtherInformationQuestionKeys.OTHER_IMPORTANT_INFO}
        rules={[optionalFreeText('Input valid information')]}
        normalize={normalizeText}
      >
        <TextArea
          disabled={reviewMode}
          maxLength={CHARACTER_LIMIT}
          showCount
          size="large"
          rows={4}
        />
      </Form.Item>
      <Divider />
    </>
  )
}

export default OtherInformation
