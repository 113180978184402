import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  EllipsisOutlined,
  LoginOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Form, FormInstance } from 'antd'
import { useHistory } from 'react-router-dom'

import { Button, Input, Password } from '../BaseComponents'
import LogoCard from './LogoCard'

import styles from '../../containers/_shared.module.scss'

interface LoginCardProps {
  form: FormInstance
  handleSubmit: () => Promise<void>
  isLoading: boolean
}

export default function Login({
  form,
  isLoading,
  handleSubmit,
}: LoginCardProps) {
  // Next two lines needed to update disable state of login button
  const [, forceUpdate] = useState({})
  useEffect(() => forceUpdate({}), [])

  const history = useHistory()
  const navigateToReset = useCallback(() => {
    history.push({
      pathname: '/login/reset',
      state: { email: form.getFieldValue('email') },
    })
  }, [history, form])

  const getShouldDisable = () =>
    !form.isFieldsTouched(true) ||
    !!form.getFieldsError().filter(({ errors }) => errors.length).length

  const loginForm = useMemo(
    () => (
      <Form form={form} autoComplete="off" onFinish={handleSubmit}>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please enter your email' }]}
        >
          <Input
            suffix={<UserOutlined className="input-login-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please enter your password' }]}
        >
          <Password suffix={<EllipsisOutlined />} placeholder="Password" />
        </Form.Item>
        <div id="login-card-forgot">
          <span role="button" onClick={navigateToReset}>
            Forgot password?
          </span>
        </div>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              disabled={getShouldDisable()}
              htmlType="submit"
              id="login-card-button"
              loading={isLoading}
              type="primary"
            >
              {!isLoading && <LoginOutlined />} Log in
            </Button>
          )}
        </Form.Item>
        <div className="link-demo">
          Don't have an account?
          <a
            id="link-demo"
            href="https://www.osmind.org/demo"
            target="_blank"
            rel="noopener"
          >
            &nbsp;Book a demo
          </a>
        </div>
        <div className="link-patient-sign-in">
          Are you a patient?
          <a
            id="link-patient"
            href="https://osmind.app.link"
            target="_blank"
            rel="noopener"
          >
            &nbsp;Sign in here
          </a>
        </div>
      </Form>
    ),
    [form, isLoading, navigateToReset]
  )

  return (
    <div className={styles.scroll}>
      <LogoCard id="login-card" title="Log in">
        {loginForm}
      </LogoCard>
    </div>
  )
}
