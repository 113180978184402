import React from 'react'

import { Popconfirm as AntdPopconfirm } from 'antd'

interface PopconfirmProps {
  cancelText?: string
  confirmText?: string
  children: React.ReactNode
  onCancel?: () => void
  onConfirm: () => void
  title?: string
}

const Popconfirm = ({
  cancelText = 'Cancel',
  children,
  confirmText = 'Yes',
  onCancel,
  onConfirm,
  title = '',
}: PopconfirmProps) => {
  return (
    <AntdPopconfirm
      cancelText={cancelText}
      onConfirm={onConfirm}
      onCancel={onCancel}
      okText={confirmText}
      title={title}
    >
      {children}
    </AntdPopconfirm>
  )
}

export default Popconfirm
