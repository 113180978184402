import React from 'react'

import { CollapsibleCard, TextArea } from '../BaseComponents'
import { InvoiceComponentProps } from './constants'

import styles from './Invoice.module.scss'

export const NoteInformation = ({
  textContent,
  onTextContentChange,
  isLoading,
  isEditing,
}: {
  textContent?: string
  onTextContentChange: any
} & InvoiceComponentProps) => {
  return (
    <CollapsibleCard
      loading={isLoading}
      title="Invoice memo"
      iconTestId="invoice-memo-action"
    >
      <TextArea
        placeholder="Add notes that are visible to your patient"
        value={textContent}
        onChange={onTextContentChange}
        onBlur={onTextContentChange}
        className={styles.noteTextContent}
        disabled={!isEditing}
      />
    </CollapsibleCard>
  )
}
