import { ReactNode } from 'react'

import { Editor } from '@tiptap/react'
import cx from 'classnames'

import { Button } from '../../../../../stories/BaseComponents'

import styles from './EditorToolbar.module.scss'

export const MarkToggle = ({
  icon,
  isActive,
  onClick,
  name,
  editor,
  isLoading,
}: {
  icon: ReactNode
  isActive: boolean
  onClick: () => void
  name: string
  editor?: Editor
  isLoading: boolean
}) => {
  return (
    <Button
      data-testid={`${name}-toggle`}
      onClick={onClick}
      className={cx([styles.toggle, { [styles.active]: isActive }])}
      disabled={
        isLoading ||
        (name === 'undo' && !editor?.can().undo()) ||
        (name === 'redo' && !editor?.can().redo())
      }
    >
      {icon}
    </Button>
  )
}
