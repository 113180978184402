import { useEffect, useState } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { debounce } from 'lodash'
import fromPairs from 'lodash/fromPairs'
import toPairs from 'lodash/toPairs'

import { globalConfig } from '../../../config/config'
import { useFeatureFlags } from '../../../libs/featureFlags'
import { errorNotification } from '../../../libs/notificationLib'
import {
  addAddendum,
  deleteNote as deleteNoteApi,
  signNote,
  updateNote,
} from '../api'
import { Note, SignatureRequest } from '../types'
import {
  UseNoteDataQueryParams,
  getNoteQueryKey,
  useNoteData,
} from './useNoteData'
import { notePatientQueryKey } from './useNotes'

const DEFAULT_AUTOSAVE_MS = 5000
const ERROR_MESSAGE_DURATION_MS = 2000
const determineSaveError = (error: string) => {
  switch (error) {
    case 'Network Error':
      return 'Unable to save note. Please check your internet connection.'
    case 'Request failed with status code 500':
      return 'We encountered a server error; please contact your Osmind administrator.'
    default:
      return 'Unable to save note. Please try again.'
  }
}

const getValidChanges = (body: Partial<Note>) =>
  toPairs(body).filter(([_, value]) => value !== undefined)

interface UseNoteOptions {
  getNoteQueryParams?: UseNoteDataQueryParams
}

export const useNote = (noteId: string, options: UseNoteOptions = {}) => {
  // ----------------------------------------
  // 1. Setup saving, flags, etc
  // ----------------------------------------'
  const { isProd } = globalConfig?.get() || {
    isProd: false,
  }
  const { autosaveDebounceNotev1Seconds } = useFeatureFlags()
  const { getNoteQueryParams } = options
  const [saveError, setSaveError] = useState<string | null>(null)

  // ----------------------------------------
  // 2. Maintain all pending changes
  // ----------------------------------------
  const [pendingChanges, setPendingChanges] = useState<Partial<Note>>({})
  // undefined is stripped out by request serializers. Avoid sending an empty object
  const pendingChangesCount = Object.entries(pendingChanges).filter(
    ([_, val]) => val !== undefined
  ).length

  const noteQueryKey = getNoteQueryKey(noteId, getNoteQueryParams)

  const queryClient = useQueryClient()
  const updateNoteQueryData = (update: Partial<Note>) => {
    queryClient.setQueryData<Note>(noteQueryKey, (cachedData) =>
      cachedData
        ? {
            ...cachedData,
            ...update,
          }
        : undefined
    )
  }

  const { data, isLoading, isFetching, isSuccess, isError } = useNoteData(
    noteId,
    getNoteQueryParams
  )

  const { mutate: update, isLoading: isNoteSaving } = useMutation(
    async (body: Partial<Note>) => noteId && (await updateNote(noteId, body)),
    {
      // ----------------------------------------
      // 3. When we trigger a save:
      //    a. Cancel any pending GET queries, just in case
      //    b. Optimistically update browser state with the new data
      //    c. Clear the pending changes list so we don't save them again
      // ----------------------------------------
      onMutate: async (input) => {
        await queryClient.cancelQueries(noteQueryKey)
        updateNoteQueryData(input)
        setPendingChanges({})
      },
      // ---------------------
      // -------------------
      // 4. When a save has completed:
      //    a. clear any save errors and reset the consecutive save count
      //    b. since the server returns the most accurate data, combine it with
      //       any pending changes that have been made since this save was triggered
      // ----------------------------------------
      onSuccess: (result: Partial<Note>) => {
        setSaveError(null)
        updateNoteQueryData({ ...result, ...pendingChanges })
      },
      // ----------------------------------------
      // 5. When a save has failed:
      //    a. keep the same server state with the unsaved work
      //    b. add the pending changes back to the list of changes to
      //       save, making sure we prefer the changes made since this save was triggered
      //    c. increment the consecutive save error count, so we can compute a backoff wait
      //    d. show an error notification
      // ----------------------------------------
      onError: (error: Error, unsavedChanges) => {
        const errorText = determineSaveError(error.message)
        setSaveError(errorText)
        setPendingChanges((pendingChanges) => ({
          ...unsavedChanges,
          ...pendingChanges,
        }))
        errorNotification(errorText, {
          duration: (DEFAULT_AUTOSAVE_MS - ERROR_MESSAGE_DURATION_MS) / 1000,
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries(noteQueryKey)
      },
    }
  )

  // ----------------------------------------
  // 6. When we receive a change:
  //    a. add it to the list of changes waiting to be saved
  //    b. update browser state
  // ----------------------------------------
  const onChange = (body: Partial<Note>) => {
    if (!isProd) {
      console.log('Pending Changes to the note', body)
    }

    // Fetch libraries will strip out keys that have undefined values, since that is a cheaper way to represent "unset" on the wire
    // We should avoid trying to send empty updates altogether
    const validChanges = getValidChanges(body)
    if (!validChanges.length) {
      return
    }

    const validChangesObject: Partial<Note> = fromPairs(validChanges)
    setPendingChanges((previousChanges) => ({
      ...previousChanges,
      ...validChangesObject,
    }))

    updateNoteQueryData(validChangesObject)
  }

  // ----------------------------------------
  // 7. Debounce the save
  // ----------------------------------------
  const debouncedUpdate = debounce(
    () => update(pendingChanges),
    autosaveDebounceNotev1Seconds * 1000
  )

  // ----------------------------------------
  // 8. When we have pending changes:
  //    a. trigger the debounced save
  //    b. cancel the previous debounced save if there are new changes
  // ----------------------------------------
  useEffect(() => {
    const validChanges = getValidChanges(pendingChanges)
    if (validChanges.length) {
      debouncedUpdate()
    }

    return () => debouncedUpdate.cancel()
  }, [pendingChanges, debouncedUpdate])

  const { mutateAsync: deleteNote, isLoading: isDeleting } = useMutation(
    async () => noteId && (await deleteNoteApi(noteId)),
    {
      onMutate: () => {
        const currentList = queryClient.getQueryData<Note[]>(
          notePatientQueryKey(data?.patientId)
        )
        if (currentList) {
          const newList = currentList.filter(
            (note: Note) => note.uuid !== noteId
          )
          queryClient.setQueryData(
            notePatientQueryKey(data?.patientId),
            newList
          )
        }
      },
      onSuccess: async () => {
        await queryClient.refetchQueries(notePatientQueryKey(data?.patientId))
      },
    }
  )

  const { mutateAsync: onSign } = useMutation(
    async (body: SignatureRequest) => noteId && (await signNote(noteId, body)),
    {
      onSuccess: async (result: Partial<Note>) => {
        updateNoteQueryData(result)
      },
    }
  )

  const { mutateAsync: onAddAddendum, isLoading: isAddendumSaving } =
    useMutation(async (text: string) => await addAddendum(noteId, text), {
      onSuccess: async (result: Partial<Note>) => {
        queryClient.setQueryData(noteQueryKey, { ...result })
      },
    })

  const isSaving = isNoteSaving || isAddendumSaving

  return {
    note: data,
    isLoading,
    isFetching,
    isSaving,
    isSuccess,
    isError,
    onChange,
    saveError,
    pendingChanges,
    hasPendingChanges: pendingChangesCount > 0,
    onDelete: deleteNote,
    isDeleting,
    onSign,
    onAddAddendum,
    forceSave: () => update(pendingChanges),
  }
}
