import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { fetchAllergies } from '../../api/patients'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'
import { Allergy } from '../../shared-types'

export const usePatientAllergies = (
  patientId: string
): UseQueryResult<Allergy[]> => {
  return useQuery<Allergy[]>([patientDataTypes.PatientAllergies, patientId], {
    queryFn: async () => {
      const data = await fetchAllergies(patientId)
      return data
    },
    retry: false,
    refetchOnWindowFocus: false,
  })
}
