import { UnreadMessageCountsMap } from './types'

export type UseAllSendbirdChannelsForUserIdInitialState = {
  unreadMessageCounts: UnreadMessageCountsMap
}
export const initialState_useAllSendbirdChannelsForUserId: UseAllSendbirdChannelsForUserIdInitialState =
  {
    unreadMessageCounts: new Map(),
  }

export enum ACTION_TYPE {
  UPDATE_UNREAD_MESSAGE_COUNTS = 'UPDATE_UNREAD_MESSAGE_COUNTS',
  MARK_AS_UNREAD = 'MARK_AS_UNREAD',
  MARK_AS_READ = 'MARK_AS_READ',
  INCREMENT_COUNT = 'INCREMENT_COUNT',
}

export type UpdateUnreadMessageCountsAction = {
  type: ACTION_TYPE.UPDATE_UNREAD_MESSAGE_COUNTS
  unreadMessageCounts: UnreadMessageCountsMap
}
export const updateUnreadMessageCountsAction = (
  unreadMessageCounts: UnreadMessageCountsMap
): UpdateUnreadMessageCountsAction => ({
  type: ACTION_TYPE.UPDATE_UNREAD_MESSAGE_COUNTS,
  unreadMessageCounts,
})

export type MarkAsUnreadAction = {
  type: ACTION_TYPE.MARK_AS_UNREAD
  channelUrl: string
}
export const markAsUnreadAction = (channelUrl: string): MarkAsUnreadAction => ({
  type: ACTION_TYPE.MARK_AS_UNREAD,
  channelUrl,
})
export type MarkAsReadAction = {
  type: ACTION_TYPE.MARK_AS_READ
  channelUrl: string
}
export const markAsReadAction = (channelUrl: string): MarkAsReadAction => ({
  type: ACTION_TYPE.MARK_AS_READ,
  channelUrl,
})
export type IncrementCountAction = {
  type: ACTION_TYPE.INCREMENT_COUNT
  channelUrl: string
}
export const incrementCountAction = (
  channelUrl: string
): IncrementCountAction => ({
  type: ACTION_TYPE.INCREMENT_COUNT,
  channelUrl,
})

export type Actions =
  | UpdateUnreadMessageCountsAction
  | MarkAsUnreadAction
  | MarkAsReadAction
  | IncrementCountAction

export function useAllSendbirdChannelsForUserIdInitialStateReducer(
  state: UseAllSendbirdChannelsForUserIdInitialState,
  action: Actions
) {
  switch (action.type) {
    case ACTION_TYPE.UPDATE_UNREAD_MESSAGE_COUNTS: {
      const freshlyUpdatedCounts = new Map(action.unreadMessageCounts)
      // preserve "markedUnread" status even if new count data comes in
      state.unreadMessageCounts.forEach((val, key) => {
        if (val.markedUnread) {
          freshlyUpdatedCounts.set(key, {
            count: freshlyUpdatedCounts.get(key)?.count ?? 0,
            markedUnread: true,
          })
        }
      })
      return {
        unreadMessageCounts: freshlyUpdatedCounts,
      }
    }
    case ACTION_TYPE.MARK_AS_UNREAD: {
      const tempMap = new Map(state.unreadMessageCounts)
      tempMap.set(action.channelUrl, {
        count: state.unreadMessageCounts.get(action.channelUrl)?.count ?? 0,
        markedUnread: true,
      })
      return {
        unreadMessageCounts: tempMap,
      }
    }
    case ACTION_TYPE.MARK_AS_READ: {
      const tempMap = new Map(state.unreadMessageCounts)
      tempMap.set(action.channelUrl, {
        count: 0,
        markedUnread: false,
      })
      return {
        unreadMessageCounts: tempMap,
      }
    }
    case ACTION_TYPE.INCREMENT_COUNT: {
      const tempMap = new Map(state.unreadMessageCounts)
      const tempCountsObject = state.unreadMessageCounts.get(action.channelUrl)
      tempMap.set(action.channelUrl, {
        count: (tempCountsObject?.count ?? 0) + 1,
        markedUnread:
          tempCountsObject === undefined
            ? false
            : tempCountsObject.markedUnread,
      })
      return {
        unreadMessageCounts: tempMap,
      }
    }
  }
}
