import { CodeSystemOIDs } from './CodeSystems'

// SUB categories are dropdowns after a selection has occurred
export enum Categories {
  AMERICAN_INDIAN_SUB_RACE,
  ASIAN_SUB_RACE,
  BLACK_OR_AFRICAN_AMERICAN_SUB_RACE,
  EDUCATION_LEVEL,
  EMERGENCY_CONTACT_RELATIONSHIP,
  INSURANCE_HOLDER_RELATIONSHIP,
  EMPLOYMENT_STATUS,
  ETHNICITY,
  GENDER_IDENTITY,
  HISPANIC_LATINO_SUB_ETHNICITY,
  LANGUAGE,
  LEGAL_RECORD_SEX,
  NATIVE_HAWAIIAN_SUB_RACE,
  NON_CONFORMING_GENDER_SUB_GENDER_IDENTITY,
  RACE,
  RELATIONSHIP_STATUS,
  GENERAL_MEDICAL_CONDITIONS,
  MEDICAL_CONDITIONS,
  PYSCH_CONDITIONS,
  SUBSTANCE_ABUSE,
  SUFFIX,
  WHITE_SUB_RACE,
  PROVIDER_ROLE,
}

export interface PatientDemographicObject {
  [key: string]: {
    title: string
    description?: string
    codeSystemOid?: CodeSystemOIDs
    category?: Categories
  }
}

export function constructPatientDemographicObject(
  data: PatientDemographicObject[],
  codeSystemOID: CodeSystemOIDs,
  category: Categories,
  shouldBeAlphabetized = false
) {
  if (shouldBeAlphabetized) {
    data.sort((a, b) => {
      const { title: titleA } = Object.values(a)[0]
      const { title: titleB } = Object.values(b)[0]
      if (titleA === titleB) return 0
      const bottomOfList = ['Decline to Answer', 'Other', 'Unknown']
      if (bottomOfList.includes(titleA) || titleA >= titleB) return 1
      return -1
    })
  }
  const PatientDemographicObject: PatientDemographicObject[] = data.map((r) => {
    const codeKey = Object.keys(r)[0]
    return {
      [codeKey]: {
        title: r[codeKey].title,
        description: r[codeKey].description,
        // Prioritize code system within the incoming codes if not there use the default
        // code system OID from the function
        codeSystemOid: r[codeKey].codeSystemOid
          ? r[codeKey].codeSystemOid
          : codeSystemOID,
        category: category,
      },
    }
  })
  return PatientDemographicObject
}
