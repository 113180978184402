import { Ref, useEffect, useRef, useState } from 'react'

import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { InputProps } from 'antd/lib/input/Input'
import Autocomplete from 'react-google-autocomplete'

import {
  DynamicConfig,
  GlobalConfig,
  defaultConfig,
  globalConfig,
} from '../../../config/config'
import SmartyStreetAddressInput from '../SmartyStreetAddressInput'
import InlineEditBase, { focusRef } from './InlineEditBase'
import {
  ActiveComponentProps,
  BaseInterface,
  OnSave,
  Validator,
} from './shared-types'

import styles from './_shared.module.scss'

export enum AddressAPIVendors {
  SmartyStreets = 'SmartyStreets',
  Google = 'Google',
}
interface Props extends BaseInterface, InputProps {
  onSave?: OnSave<string>
  size?: SizeType
  value?: string
  validators?: Validator<string>[]
  vendor?: AddressAPIVendors
}

/**
  Editable inline Address
*/
const InlineEditAddress = ({
  placeholder,
  requireConfirmation = false,
  size,
  testId,
  value: initialValue,
  className,
  customActions,
  customDefault,
  id,
  noEmpty,
  onSave,
  padded,
  placeholderElement,
  validators,
  validateOnChange,
  scrollToField,
  autoFocus,
  disabled = false,
  vendor = AddressAPIVendors.Google,
}: Props) => {
  const inlineEditBaseProps = {
    className,
    customActions,
    customDefault,
    id,
    noEmpty,
    onSave,
    padded,
    placeholderElement,
    validators,
    validateOnChange,
    scrollToField,
  }
  const addressFieldRef: Ref<any> = useRef(null)
  const addressContainerRef: Ref<HTMLDivElement> = useRef(null)
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRendered(true)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const SmartyActiveText = ({
    handleChange,
    toggleFocus,
    toggleCancel,
  }: ActiveComponentProps) => {
    function onSelect(stringifiedPlace: string) {
      if (!stringifiedPlace) return
      const place: {
        street_line: string
        secondary: string
        city: string
        state: string
        zipcode: string
        entries: number
      } = JSON.parse(stringifiedPlace)

      if (Object.keys(place).length === 0) return

      const addressComponents = {
        Address1: place.street_line,
        Address2: place.secondary,
        City: place.city,
        State: place.state,
        Zipcode: place.zipcode,
      }

      handleChange(addressComponents)
    }

    function onChange(e: string) {
      if (e === '') {
        handleChange({
          Address1: '',
          Address2: '',
          City: '',
          State: '',
          Zipcode: '',
        })
      }
    }

    return (
      <SmartyStreetAddressInput
        onChange={onChange}
        onSelect={onSelect}
        toggleFocus={toggleFocus}
        onKeyDown={(event) => event.key === 'Escape' && toggleCancel()}
        defaultValue={initialValue}
      />
    )
  }
  const GoogleActiveText = ({
    handleChange,
    toggleCancel,
    toggleFocus,
    toggleSave,
    hasValueChange,
  }: ActiveComponentProps) => {
    let config: DynamicConfig | GlobalConfig
    try {
      config = globalConfig.get()
    } catch (e) {
      config = defaultConfig
    }

    function onSelect(place: any) {
      if (Object.keys(place).length === 0 || !place?.address_components) return

      const addressComponents = {
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        Zipcode: '',
      }

      let address1 = ''
      let postcode = ''

      place.address_components.forEach((component: any) => {
        const componentType = component.types[0]
        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`
            break
          }
          case 'subpremise': {
            addressComponents.Address2 = component.long_name
            break
          }
          case 'route': {
            address1 += component.short_name
            addressComponents.Address1 = address1
            break
          }
          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`
            addressComponents.Zipcode = postcode
            break
          }
          case 'locality':
            addressComponents.City = component.long_name
            break
          case 'administrative_area_level_1': {
            addressComponents.State = component.long_name
            break
          }
        }
      })

      handleChange(addressComponents)
    }

    function onChange(e: any) {
      e.preventDefault()
      const value = e.target.value.trim()
      if (value === '') {
        handleChange({
          Address1: '',
          Address2: '',
          City: '',
          State: '',
          Zipcode: '',
        })
      }
    }

    return (
      <Autocomplete
        apiKey={config.GOOGLE_API_KEY}
        className={styles.googleSearch}
        onPlaceSelected={onSelect}
        ref={addressFieldRef}
        defaultValue={initialValue}
        onKeyDown={(event) => event.key === 'Escape' && toggleCancel()}
        data-testid={testId ? `text-${testId}` : undefined}
        onFocus={toggleFocus}
        onKeyPress={(event) =>
          event.key === 'Enter' &&
          (hasValueChange ? toggleSave() : toggleCancel())
        }
        onChange={onChange}
        language="en"
        libraries={['places']}
        options={{
          componentRestrictions: { country: ['us'] },
          types: ['address'],
        }}
        itemType="address"
        inputAutocompleteValue={initialValue?.toString()}
      />
    )
  }

  const vendorComponents = {
    [AddressAPIVendors.SmartyStreets]: SmartyActiveText,
    [AddressAPIVendors.Google]: GoogleActiveText,
  }

  return (
    <div className={styles.refContainer} ref={addressContainerRef}>
      <InlineEditBase
        {...inlineEditBaseProps}
        activeComponent={vendorComponents[vendor]}
        testId={testId}
        toggleActive={autoFocus}
        size={size}
        onActive={() =>
          focusRef(autoFocus, addressContainerRef, addressFieldRef, rendered)
        }
        value={initialValue}
        placeholder={placeholder}
        showActions={requireConfirmation}
        disabled={disabled}
      />
    </div>
  )
}

export default InlineEditAddress
