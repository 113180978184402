import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

enum EthnicityCodesEnum {
  HISPANIC_LATINO = '2135-2',
  NON_HISPANIC_LATINO = '1002-5',
}

export enum EthnicityTitleEnum {
  HISPANIC_LATINO = 'Hispanic or Latino',
  NON_HISPANIC_LATINO = 'Non Hispanic or Latino',
}

const ethnicityCDC: PatientDemographicObject[] = [
  {
    [EthnicityCodesEnum.HISPANIC_LATINO]: {
      title: EthnicityTitleEnum.HISPANIC_LATINO,
    },
  },
  {
    [EthnicityCodesEnum.NON_HISPANIC_LATINO]: {
      title: EthnicityTitleEnum.NON_HISPANIC_LATINO,
    },
  },
]

export const ethnicityDropdownValues = constructPatientDemographicObject(
  ethnicityCDC,
  CodeSystemOIDs.PHRaceAndEthnicityCDC,
  Categories.RACE
)
