import { useCallback, useEffect, useMemo, useState } from 'react'

import { useTeammateData } from '../../../hooks/queries/useTeammateData'
import { useAppContext } from '../../../libs/contextLib'
import { onError } from '../../../libs/errorLib'
import Alert from '../../../stories/BaseComponents/Alert'
import { Badge } from '../../../stories/BaseComponents/Badge'
import Button from '../../../stories/BaseComponents/Button'
import Spinner from '../../../stories/BaseComponents/Spinner'
import { Tabs } from '../../../stories/BaseComponents/Tabs'
import Text from '../../../stories/BaseComponents/Text'
import useSendFeatureEnablementEmail from '../../mutations/useSendFeatureEnablementEmail'
import { getRCopiaNotificationsSSOUrl } from '../api'

import styles from '../../styles/page.module.scss'

/**
 * This component is to fulfill the Dr First requirements in that we must allow users to launch to both the report and message landing page.
 * The accordions expand to display an iFrame with different RCopia portal landing pages, but still allow for the user to navigate around
 * RCopia if they so choose
 */

const MESSAGES_KEY = 'messages'
const REPORTS_KEY = 'reports'

export interface Props {
  erxMessagesCount: number
  erxReportsCount: number
  erxNotificationCount: number
}

export default function ErxPage(props: Props) {
  const [messagesPageUrl, setMessagesPageUrl] = useState('')
  const [reportsPageUrl, setReportsPageUrl] = useState('')
  const { data: teammateData } = useTeammateData()
  const appContext = useAppContext()

  // clumsy way of finding lightweight info about the current provider
  // TODO: get a backend endpoint that's "users/{userId}" that's implicitly permissioned
  const currentProviderInfo = useMemo(() => {
    return teammateData?.find(
      (teammate) => teammate.cognitoId === appContext.providerId
    )
  }, [teammateData, appContext.providerId])

  const erxEnabledForClinic = !!appContext.appSettings.erx
  const hasLoadedProvider = !!currentProviderInfo
  const isRegisteredWithDrFirst =
    hasLoadedProvider && !!currentProviderInfo.erxUserId

  const loadRcopiaIframeUrls = async () => {
    try {
      const [messageUrl, reportsUrl] = await Promise.all([
        getRCopiaNotificationsSSOUrl('message'),
        getRCopiaNotificationsSSOUrl('report'),
      ])
      setMessagesPageUrl(messageUrl)
      setReportsPageUrl(reportsUrl)
    } catch (e: unknown) {
      console.log(e)
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
  }

  const onMessagesLoaded = () => {
    localStorage.setItem('erxMessagesCount', '0')
    localStorage.setItem(
      'erxNotificationCount',
      String(props.erxNotificationCount - props.erxMessagesCount)
    )
  }

  const onReportsLoaded = () => {
    localStorage.setItem('erxReportsCount', '0')
    localStorage.setItem(
      'erxNotificationCount',
      String(props.erxNotificationCount - props.erxReportsCount)
    )
  }

  const onTabChange = useCallback((nextTab: string) => {
    if (nextTab === REPORTS_KEY) {
      onReportsLoaded()
    }
  }, [])

  useEffect(() => {
    if (isRegisteredWithDrFirst) {
      // implicitly once this flag is true it won't flip back to false. we can be assured that this only tries to load once.
      loadRcopiaIframeUrls()
    }
  }, [isRegisteredWithDrFirst])

  const enablementRequestor = useSendFeatureEnablementEmail('e-prescribing')

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Text header="h4">e-Prescribe</Text>
        </div>
        <div className={styles.main}>
          {!erxEnabledForClinic && (
            <Alert
              message="E-prescribing is currently disabled for your clinic"
              description="To access e-prescribing, electronic prescribing of controlled substances (EPCS), prescription drug monitoring programs (PDMP), powerful medication and allergy interaction checkers, real-time prescription benefit eligibility checkers, and prior authorization automation, contact the Osmind team to enable the feature for you (may require an upgrade)."
              type="info"
              action={
                <Button
                  type="primary"
                  loading={enablementRequestor.isLoading}
                  onClick={() => enablementRequestor.mutate()}
                >
                  Request e-prescribing
                </Button>
              }
              showIcon
            />
          )}
          {erxEnabledForClinic && !hasLoadedProvider && <Spinner />}
          {erxEnabledForClinic && hasLoadedProvider && (
            <Tabs onChange={onTabChange}>
              <Tabs.TabPane
                tab={
                  <Badge
                    size="small"
                    color="blue"
                    count={props.erxMessagesCount}
                    offset={[5, 0]}
                  >
                    <div>Messages</div>
                  </Badge>
                }
                key={MESSAGES_KEY}
              >
                {!isRegisteredWithDrFirst && (
                  <Alert
                    message="You are not registered with Dr. First through Osmind"
                    type="info"
                    showIcon
                  />
                )}
                {isRegisteredWithDrFirst &&
                  (messagesPageUrl ? (
                    <iframe
                      title="Messages"
                      onLoad={onMessagesLoaded}
                      src={messagesPageUrl}
                      height="800"
                      width="100%"
                      style={{ marginTop: 16 }}
                    />
                  ) : (
                    <Spinner />
                  ))}
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <Badge
                    size="small"
                    color="blue"
                    count={props.erxReportsCount}
                    offset={[5, 0]}
                  >
                    <div>Pending prescriptions & reports</div>
                  </Badge>
                }
                key={REPORTS_KEY}
              >
                {!isRegisteredWithDrFirst && (
                  <Alert
                    message="You are not registered with Dr. First through Osmind"
                    type="info"
                    showIcon
                  />
                )}
                {isRegisteredWithDrFirst &&
                  (reportsPageUrl ? (
                    <iframe
                      title="Pending prescriptions & reports"
                      src={reportsPageUrl}
                      height="800"
                      width="100%"
                      style={{ marginTop: 16 }}
                    />
                  ) : (
                    <Spinner />
                  ))}
              </Tabs.TabPane>
            </Tabs>
          )}
        </div>
      </div>
    </div>
  )
}
