import React, { useEffect, useState } from 'react'

import 'moment-timezone'
import { Button, Col, Form } from 'react-bootstrap'
import 'react-dates/initialize'

import ShowDateRange from './ShowDateRange'

const FREQUENCY = [
  'Constantly',
  'Daily',
  'Between daily and weekly',
  'Weekly',
  'Between weekly and monthly',
  'Monthly',
  'Less frequent than monthly',
  'Rarely',
]

export default function SubstanceUse(props) {
  const [currentPastUse, setCurrentPastUse] = useState(
    props.CurrentPastUse || ''
  )
  const [drinksPerWeek, setDrinksPerWeek] = useState(props.DrinksPerWeek || '')
  const [bingeDrink, setBingeDrink] = useState(props.BingeDrink || '')
  const [dateRange, setDateRange] = useState(
    props.DateRange || [{ startDate: '', endDate: '', dateFormat: 'MM/YYYY' }]
  )
  const [frequency, setFrequency] = useState(props.Frequency || '')
  const [quantity, setQuantity] = useState(props.Quantity || '')
  const [route, setRoute] = useState(props.Route || '')
  const [cAGE, setCAGE] = useState(
    props.CAGE || {
      state: false,
      CuttingDown: false,
      AnnoyedCriticism: false,
      UseGuilt: false,
      MorningUse: false,
    }
  )
  const [notes, setNotes] = useState(props.Notes || '')

  useEffect(() => {
    const fields = {
      state: true,
      currentPastUse: currentPastUse,
      drinksPerWeek: drinksPerWeek,
      bingeDrink: bingeDrink,
      dateRange: dateRange,
      frequency: frequency,
      quantity: quantity,
      route: route,
      cAGE: cAGE,
      notes: notes,
    }
    props.SaveSubstance(fields)
  }, [
    currentPastUse,
    drinksPerWeek,
    bingeDrink,
    dateRange,
    frequency,
    quantity,
    route,
    cAGE,
    notes,
  ])

  function saveDateRange(newDateRange, index) {
    const array = dateRange.slice()
    array[index] = newDateRange
    setDateRange(array)
  }

  function newDateRange() {
    const array = dateRange.slice()
    const newDate = [{ startDate: '', endDate: '', dateFormat: 'MM/YYYY' }]
    setDateRange(array.concat(newDate))
  }

  function deleteDateRange() {
    if (dateRange.length === 1) {
      return
    }
    const array = dateRange.slice()
    array.splice(array.length - 1, 1)
    setDateRange(array)
  }

  return (
    <>
      <Form.Row className="border-bottom indent">
        <Form.Group
          as={Col}
          sm={12}
          md={currentPastUse === 'Current' ? 2 : 12}
          lg={currentPastUse === 'Current' ? 2 : 12}
        >
          <Form.Check
            type="radio"
            id={'CurrentUse' + props.Substance}
            label="Current use"
            checked={JSON.parse(currentPastUse === 'Current')}
            onChange={() => {
              setCurrentPastUse('Current')
            }}
          />
        </Form.Group>
        {currentPastUse === 'Current' && (
          <Form.Group as={Col} xs={12} sm={12} md={6} lg={6} xl={6}>
            <Form.Label className="patient">Start Date</Form.Label>
            <ShowDateRange
              key={0}
              index={0}
              StartDate={dateRange[0].startDate}
              EndDate={dateRange[0].endDate}
              saveDateRange={saveDateRange}
              CurrentUse
            />
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={currentPastUse === 'Past' ? 2 : 12}
          lg={currentPastUse === 'Past' ? 2 : 12}
        >
          <Form.Check
            type="radio"
            id={'PastUse' + props.Substance}
            label="Past use"
            checked={JSON.parse(currentPastUse === 'Past')}
            onChange={() => {
              setCurrentPastUse('Past')
            }}
          />
        </Form.Group>
        {currentPastUse === 'Past' && (
          <>
            <Form.Group as={Col} xs={12} sm={12} md={6} lg={6} xl={6}>
              <Form.Label className="patient">
                Date Range (Start-End date)
              </Form.Label>
              {dateRange.map((DR, index) => {
                return (
                  <ShowDateRange
                    key={index}
                    index={index}
                    StartDate={DR.startDate}
                    EndDate={DR.endDate}
                    saveDateRange={saveDateRange}
                    PastUse
                  />
                )
              })}
              <Form.Row style={{ marginTop: '10px' }}>
                <Form.Group
                  as={Col}
                  xs={2}
                  sm={2}
                  md={1}
                  lg={1}
                  xl={1}
                  style={{ marginLeft: 'auto', marginRight: '10px' }}
                >
                  <Button
                    bsPrefix="plain-button center info button-label"
                    onClick={() => {
                      newDateRange()
                    }}
                  >
                    <i
                      style={{ color: '#1254a1', fontSize: '24px' }}
                      className="fa fa-plus"
                      aria-hidden="true"
                    />
                  </Button>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={2}
                  sm={2}
                  md={1}
                  lg={1}
                  xl={1}
                  style={{ marginLeft: '10px', marginRight: 'auto' }}
                >
                  <Button
                    bsPrefix="plain-button center info button-label"
                    onClick={() => {
                      deleteDateRange()
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true" />
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={12} md={4} lg={4} xl={4} />
          </>
        )}
        <Form.Group as={Col} sm={12} md={2} lg={2} xl={2}>
          <Form.Label style={{ fontWeight: 'bold' }}>Frequency</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="select"
            onChange={(e) => setFrequency(e.target.value)}
            value={frequency}
          >
            <option key={0} name="Select one" value="">
              Select one
            </option>
            {FREQUENCY.map((type, index) => (
              <option key={index + 1} name={type} value={type}>
                {type}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {props.Substance !== 'Alcohol' && (
          <Form.Group as={Col} xs={6} sm={6} md={2} lg={2} xl={2}>
            <Form.Label className="patient">Quantity</Form.Label>
            <Form.Control
              bsPrefix="form-input"
              as="textarea"
              rows={1}
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </Form.Group>
        )}
        <Form.Group as={Col} xs={12} sm={12} md={2} lg={2} xl={2}>
          <Form.Label className="patient">Route</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="textarea"
            rows={1}
            value={route}
            onChange={(e) => setRoute(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={12} md={6} lg={6} xl={6}>
          <Form.Label className="patient">Notes</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="textarea"
            rows={1}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </Form.Group>
        {props.Substance === 'Alcohol' && (
          <>
            <Form.Group as={Col} xs={6} sm={6} md={4} lg={3} xl={3}>
              <Form.Label className="patient">
                How many drinks a week?
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                type="number"
                value={drinksPerWeek}
                onChange={(e) => setDrinksPerWeek(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} xs={6} sm={6} md={4} lg={3} xl={3}>
              <Form.Label className="patient">
                How often do they binge drink?
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                type="number"
                value={bingeDrink}
                onChange={(e) => setBingeDrink(e.target.value)}
              />
            </Form.Group>
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id={'CAGE' + props.Substance}
            label="Ask CAGE questions"
            checked={JSON.parse(cAGE.state)}
            onClick={() => {
              setCAGE({ ...cAGE, state: !cAGE.state })
            }}
          />
        </Form.Group>
        {cAGE.state && (
          <>
            <Form.Group
              className="indent"
              style={{ marginTop: '0px' }}
              as={Col}
              sm={12}
              md={12}
              lg={12}
            >
              <Form.Check
                type="checkbox"
                id={'CuttingDown' + props.Substance}
                label="Tried cutting down or quitting"
                checked={JSON.parse(cAGE.CuttingDown)}
                onClick={() => {
                  setCAGE({ ...cAGE, CuttingDown: !cAGE.CuttingDown })
                }}
              />
              <Form.Check
                type="checkbox"
                id={'AnnoyedCriticism' + props.Substance}
                label="Felt annoyed by others criticizing use"
                checked={JSON.parse(cAGE.AnnoyedCriticism)}
                onClick={() => {
                  setCAGE({ ...cAGE, AnnoyedCriticism: !cAGE.AnnoyedCriticism })
                }}
              />
              <Form.Check
                type="checkbox"
                id={'UseGuilt' + props.Substance}
                label="Felt bad or guilty about use"
                checked={JSON.parse(cAGE.UseGuilt)}
                onClick={() => {
                  setCAGE({ ...cAGE, UseGuilt: !cAGE.UseGuilt })
                }}
              />
              <Form.Check
                type="checkbox"
                id={'MorningUse' + props.Substance}
                label="Used first thing in the morning"
                checked={JSON.parse(cAGE.MorningUse)}
                onClick={() => {
                  setCAGE({ ...cAGE, MorningUse: !cAGE.MorningUse })
                }}
              />
            </Form.Group>
          </>
        )}
      </Form.Row>
      <hr />
    </>
  )
}
