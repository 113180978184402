import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const KidneyCodes: any = [
  {
    90708001: {
      title: 'Kidney Disease',
      snomedcode: 90708001,
      description: 'Kidney disease (disorder)',
      icdcode: 'N289',
      icddescription: 'Disorder of kidney and ureter, unspecified',
    },
  },
  {
    709044004: {
      title: 'kid.Chronic Kidney Disease',
      description: 'Chronic kidney disease (disorder)',
      snomedcode: 709044004,
      icdcode: 'N189',
      icddescription: 'Chronic kidney disease, unspecified',
    },
  },
  {
    95570007: {
      title: 'kid.Kidney Stone',
      description: 'Kidney stone (disorder)',
      snomedcode: 95570007,
      icdcode: 'N200',
      icddescription: 'Calculus of kidney',
    },
  },
]

export const KidneyDropDownValues = constructPatientDemographicObject(
  KidneyCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
