import { useCallback, useEffect, useMemo, useState } from 'react'

import { sum } from 'lodash'

import { AppointmentType } from '../../components/Scheduling/AppointmentSettings'
import { RoomItem } from '../../containers/Authentication/Locations'
import { Teammate } from '../../shared-types'
import { generateCalendarOptions } from '../../stories/Scheduling/SchedulingPage/SchedulingPage.helpers'
import {
  formatProviderName,
  sortCategories,
} from '../../stories/Scheduling/helpers'
import {
  EventCategory,
  UserCalendarAccount,
} from '../../stories/Scheduling/types'
import { usePrevious } from '../usePrevious'
import { LocationWithRoomInfo } from '../useSchedulingData/useSchedulingData'
import {
  getCalendarFilters,
  getLocalStorageFilterPropertyData,
  getTeammateGoogleCalendarIds,
  setLocalStorageFilterPropertyData,
  toSet,
} from './useSchedulingFilters.helpers'

export type LocalStorageFilterData = {
  roomsSelected?: string[]
  teammatesSelected?: string[]
  appointmentTypesSelected?: string[]
  unassignedSelected?: string[]
}

const TEAMMATES_SELECTED_KEY = 'teammatesSelected'
const ROOMS_SELECTED_KEY = 'roomsSelected'
const APPOINTMENT_TYPES_SELECTED_KEY = 'appointmentTypesSelected'
const UNASSIGNED_SELECTED_KEY = 'unassignedSelected'

const INITIAL_PATIENT_ID_SELECTED = 'Select a patient'

export const NO_APPT_TYPE = ''
export const DELETED_APPT_TYPE = 'DELETED'

export type UseSchedulingFilters = {
  teammateCalendars: UserCalendarAccount[] | undefined
  rooms: RoomItem[] | undefined
  appointmentTypes: AppointmentType[] | undefined
  activeTeammates: Teammate[] | undefined
  providerId: string
  locationsWithRoomInformation: LocationWithRoomInfo
  isSchedulingDataLoading: boolean
}

const getAllAppointmentTypeOptionIds = (
  appointmentTypesOptions: EventCategory[]
) => {
  return [
    ...appointmentTypesOptions.map(({ value }) => value as string),
    NO_APPT_TYPE,
    DELETED_APPT_TYPE,
  ]
}

export const useSchedulingFilters = ({
  teammateCalendars,
  rooms,
  locationsWithRoomInformation,
  appointmentTypes,
  activeTeammates,
  providerId,
  isSchedulingDataLoading,
}: UseSchedulingFilters) => {
  const [teammatesSelected, setTeammatesSelected] = useState<Set<string>>(
    toSet(getLocalStorageFilterPropertyData(TEAMMATES_SELECTED_KEY))
  )
  const [roomsSelected, setRoomsSelected] = useState<Set<string>>(
    toSet(getLocalStorageFilterPropertyData(ROOMS_SELECTED_KEY))
  )
  const [appointmentTypesSelected, setAppointmentTypesSelected] = useState<
    Set<string>
  >(toSet(getLocalStorageFilterPropertyData(APPOINTMENT_TYPES_SELECTED_KEY)))
  const [isUnassignedSelected, setIsUnassignedSelected] = useState(
    getLocalStorageFilterPropertyData(UNASSIGNED_SELECTED_KEY).length > 0
  )
  const [patientIdSelected, setPatientIdSelected] = useState(
    INITIAL_PATIENT_ID_SELECTED
  )
  const previousAppointmentTypes = usePrevious(appointmentTypes)
  const previousActiveTeammates = usePrevious(activeTeammates)
  const previousTeammateCalendars = usePrevious(teammateCalendars)
  const previousRooms = usePrevious(rooms)
  const wasSchedulingDataLoading = usePrevious(isSchedulingDataLoading)

  const appointmentTypesOptions = useMemo(() => {
    if (!appointmentTypes) {
      return []
    }
    const sortedAppointmentTypes = sortCategories(
      appointmentTypes
    ) as AppointmentType[]

    const currentOptions = sortedAppointmentTypes.reduce<EventCategory[]>(
      (acc, { isDeleted, id, name, colorHex }) => {
        if (isDeleted) {
          return acc
        }
        acc.push({
          label: name,
          value: id,
          color: colorHex,
        })
        return acc
      },
      []
    )
    return currentOptions
  }, [appointmentTypes])

  const appointmentTypesOptionsSet = useMemo(() => {
    return new Set(appointmentTypesOptions.map(({ value }) => value as string))
  }, [appointmentTypesOptions])

  // Remove deleted appointment types from selected filters if needed on
  // load of appointment type data
  useEffect(() => {
    if (!previousAppointmentTypes && appointmentTypes) {
      const loadedAppointmentTypeIds = toSet([
        ...appointmentTypes.map(({ id }) => id),
        NO_APPT_TYPE,
        DELETED_APPT_TYPE,
      ])

      const currentAppointmentTypesSelected = Array.from(
        appointmentTypesSelected
      ).filter((apptTypeId) => loadedAppointmentTypeIds.has(apptTypeId))
      setLocalStorageFilterPropertyData(
        'appointmentTypesSelected',
        currentAppointmentTypesSelected
      )
      setAppointmentTypesSelected(toSet(currentAppointmentTypesSelected))
    }
  }, [previousAppointmentTypes, appointmentTypes, appointmentTypesSelected])

  // Remove non-active teammates from selected filters if needed on load of
  // active teammate data and teammate calendar data
  useEffect(() => {
    if (
      (previousActiveTeammates === undefined ||
        previousTeammateCalendars === undefined) &&
      activeTeammates &&
      teammateCalendars
    ) {
      const loadedActiveTeammates = toSet(
        activeTeammates.map(({ cognitoId }) => cognitoId)
      )

      // Generate teammate ids for loaded TeammateCalendars
      const loadedTeammateCalendars = toSet(
        getTeammateGoogleCalendarIds(teammateCalendars)
      )

      // Populate current teammates selected given reloaded active teammates
      // and teammate calendars
      const currentTeammatesSelected = Array.from(teammatesSelected).filter(
        (teammateId) =>
          loadedActiveTeammates.has(teammateId) ||
          loadedTeammateCalendars.has(teammateId)
      )
      setLocalStorageFilterPropertyData(
        'teammatesSelected',
        currentTeammatesSelected
      )
      setTeammatesSelected(toSet(currentTeammatesSelected))
    }
  }, [
    previousActiveTeammates,
    activeTeammates,
    previousTeammateCalendars,
    teammateCalendars,
    teammatesSelected,
  ])

  // Remove non-active rooms from selected filters if needed on load of
  // room data
  useEffect(() => {
    if (!previousRooms && rooms) {
      const loadedRooms = toSet(rooms.map(({ roomId }) => roomId))

      const currentRoomsSelected = Array.from(roomsSelected).filter((roomId) =>
        loadedRooms.has(roomId)
      )
      setLocalStorageFilterPropertyData('roomsSelected', currentRoomsSelected)
      setRoomsSelected(toSet(currentRoomsSelected))
    }
  }, [previousRooms, rooms, roomsSelected])

  // If user is loading schedule for the first time
  // and no filters are selected, select all
  // appointment types by default
  useEffect(() => {
    if (
      !isUnassignedSelected &&
      sum([
        roomsSelected.size,
        teammatesSelected.size,
        appointmentTypesSelected.size,
      ]) === 0 &&
      !isSchedulingDataLoading &&
      wasSchedulingDataLoading
    ) {
      // Select all appointment types
      const allAppointmentTypeOptionIds = getAllAppointmentTypeOptionIds(
        appointmentTypesOptions
      )
      setAppointmentTypesSelected(toSet(allAppointmentTypeOptionIds))
      setLocalStorageFilterPropertyData(
        'appointmentTypesSelected',
        allAppointmentTypeOptionIds
      )

      // Select personal calendar
      setTeammatesSelected(new Set([providerId]))
      setLocalStorageFilterPropertyData('teammatesSelected', [providerId])
    }
  }, [
    isUnassignedSelected,
    roomsSelected,
    appointmentTypesSelected,
    teammatesSelected,
    wasSchedulingDataLoading,
    isSchedulingDataLoading,
    appointmentTypesOptions,
    providerId,
  ])

  const { personalCalendarCheckboxes, teammateCalendarCheckboxes } =
    useMemo(() => {
      if (!teammateCalendars || !activeTeammates) {
        return {
          personalCalendarCheckboxes: {},
          teammateCalendarCheckboxes: {},
        }
      }
      return getCalendarFilters(activeTeammates, teammateCalendars, providerId)
    }, [teammateCalendars, activeTeammates, providerId])

  const roomsSelectOptions = useMemo(() => {
    if (!rooms) {
      return []
    }
    const sortedRooms = sortCategories(rooms) as RoomItem[]

    const currentOptions = sortedRooms.reduce<EventCategory[]>(
      (acc, { roomId, roomName }) => {
        acc.push({
          label: roomName,
          value: roomId,
        })
        return acc
      },
      []
    )
    return currentOptions
  }, [rooms])

  const teammatesSelectOptions = useMemo(() => {
    const sortedTeammates = sortCategories(activeTeammates ?? []) as Teammate[]

    const currentOptions = sortedTeammates.reduce<EventCategory[]>(
      (acc, teammate) => {
        acc.push({
          label: teammate.name ? formatProviderName(teammate) : teammate.email,
          value: teammate.cognitoId,
        })
        return acc
      },
      []
    )
    return [...currentOptions, ...generateCalendarOptions(teammateCalendars)]
  }, [activeTeammates, teammateCalendars])

  const handleToggleCalendarSelected = useCallback(
    (teammateId: string) => () => {
      const newTeammatesSelected = new Set(teammatesSelected)
      if (newTeammatesSelected.has(teammateId)) {
        newTeammatesSelected.delete(teammateId)
      } else {
        newTeammatesSelected.add(teammateId)
      }
      const teammates = Array.from(newTeammatesSelected)

      setLocalStorageFilterPropertyData('teammatesSelected', teammates)
      setTeammatesSelected(toSet(teammates))
    },
    [teammatesSelected]
  )

  const handleToggleRoomSelected = useCallback(
    (roomId: string) => () => {
      const newRoomsSelected = new Set(roomsSelected)
      if (newRoomsSelected.has(roomId)) {
        newRoomsSelected.delete(roomId)
      } else {
        newRoomsSelected.add(roomId)
      }
      const rooms = Array.from(newRoomsSelected)

      setLocalStorageFilterPropertyData('roomsSelected', rooms)
      setRoomsSelected(toSet(rooms))
    },
    [roomsSelected]
  )

  const handleToggleAppointmentTypesSelected = useCallback(
    (apptTypeId: string) => () => {
      const newAppointmentTypes = new Set(appointmentTypesSelected)
      if (newAppointmentTypes.has(apptTypeId)) {
        newAppointmentTypes.delete(apptTypeId)
      } else {
        newAppointmentTypes.add(apptTypeId)
      }
      const appts = Array.from(newAppointmentTypes)

      setLocalStorageFilterPropertyData('appointmentTypesSelected', [...appts])
      setAppointmentTypesSelected(newAppointmentTypes)
    },
    [appointmentTypesSelected]
  )

  const handleToggleUnassignedSelected = useCallback(() => {
    setIsUnassignedSelected((currUnassigned) => {
      return !currUnassigned
    })
    const currSelected = getLocalStorageFilterPropertyData('unassignedSelected')
    const selected = currSelected.length === 0 ? ['unassigned'] : []
    setLocalStorageFilterPropertyData('unassignedSelected', selected)
  }, [])

  const handleUpdatePatientIdSelected = useCallback((patientId: string) => {
    setPatientIdSelected(patientId)
  }, [])

  const handleDeselectAllAppointmentTypes = useCallback(() => {
    setAppointmentTypesSelected(new Set())
    setLocalStorageFilterPropertyData('appointmentTypesSelected', [])
  }, [])

  const handleSelectAllAppointmentTypes = useCallback(() => {
    const allAppointmentTypeOptionIds = getAllAppointmentTypeOptionIds(
      appointmentTypesOptions
    )
    setAppointmentTypesSelected(new Set(allAppointmentTypeOptionIds))
    setLocalStorageFilterPropertyData(
      'appointmentTypesSelected',
      allAppointmentTypeOptionIds
    )
  }, [appointmentTypesOptions])

  const handleSelectAllRooms = useCallback(() => {
    const allRoomIds = Object.values(locationsWithRoomInformation).reduce<
      string[]
    >((acc, { rooms }) => {
      return [...acc, ...rooms.map(({ roomId }) => roomId)]
    }, [])
    setRoomsSelected(new Set(allRoomIds))
    setLocalStorageFilterPropertyData('roomsSelected', allRoomIds)
  }, [locationsWithRoomInformation])

  const handleDeselectAllRooms = useCallback(() => {
    setRoomsSelected(new Set())
    setLocalStorageFilterPropertyData('roomsSelected', [])
  }, [])

  const handleDeselectAllTeammates = useCallback(() => {
    const teammateCalendarIds = Object.values(teammateCalendarCheckboxes)
      .map((cal) => cal.map(({ calendarId }) => calendarId))
      .flat()

    const newTeammatesSelected = new Set(teammatesSelected)

    teammateCalendarIds.forEach((calId) => {
      if (teammatesSelected.has(calId)) {
        newTeammatesSelected.delete(calId)
      }
    })

    setTeammatesSelected(newTeammatesSelected)
    setLocalStorageFilterPropertyData(
      'teammatesSelected',
      Array.from(newTeammatesSelected)
    )
  }, [teammatesSelected, teammateCalendarCheckboxes])

  const handleSelectAllTeammates = useCallback(() => {
    const teammateCalendarIds = Object.values(teammateCalendarCheckboxes)
      .map((cal) => cal.map(({ calendarId }) => calendarId))
      .flat()

    const newTeammatesSelected: Set<string> = new Set(teammatesSelected)

    teammateCalendarIds.forEach((calId) => {
      newTeammatesSelected.add(calId)
    })

    setTeammatesSelected(newTeammatesSelected)
    setLocalStorageFilterPropertyData(
      'teammatesSelected',
      Array.from(newTeammatesSelected)
    )
  }, [teammatesSelected, teammateCalendarCheckboxes])

  return {
    teammatesSelected,
    handleToggleCalendarSelected,
    roomsSelected,
    handleToggleRoomSelected,
    appointmentTypesSelected,
    handleToggleAppointmentTypesSelected,
    isUnassignedSelected,
    handleToggleUnassignedSelected,
    patientIdSelected,
    handleUpdatePatientIdSelected,
    roomsSelectOptions,
    appointmentTypesOptions,
    appointmentTypesOptionsSet,
    teammatesSelectOptions,
    personalCalendarCheckboxes,
    teammateCalendarCheckboxes,
    handleDeselectAllAppointmentTypes,
    handleSelectAllAppointmentTypes,
    handleDeselectAllRooms,
    handleSelectAllRooms,
    handleDeselectAllTeammates,
    handleSelectAllTeammates,
  }
}
