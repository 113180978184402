import { useMemo } from 'react'

import { CheckOutlined } from '@ant-design/icons'
import { Form, FormInstance } from 'antd'

import { passwordRegex } from '../../libs/regex'
import { rulesConstructor } from '../../libs/utils'
import {
  Button,
  Input,
  NPIInput,
  Password,
  PhoneNumberInput,
} from '../BaseComponents'
import AddressAutocomplete, {
  Address,
} from '../BaseComponents/AddressAutocomplete'
import LogoCard from './LogoCard'

import styles from '../../containers/_shared.module.scss'

export interface FormValues {
  firstName: string
  middleName: string
  lastName: string
  phoneNumber: string
  NPI: string
  AutocompleteAddress: Address
  password: string
  confirmPassword: string
}

interface SignupCardProps {
  form: FormInstance
  handleAddressChange: (values: Address) => Promise<void>
  handleSubmit: (values: FormValues) => Promise<void>
  initialValues?: any
  isLoading: boolean
}

export default function SignupCard({
  form,
  initialValues,
  isLoading,
  handleAddressChange,
  handleSubmit,
}: SignupCardProps) {
  const formValues = useMemo(() => form.getFieldsValue(), [form])

  return (
    <div className={styles.scroll}>
      <LogoCard id="signup-card" title="Create your Osmind account">
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={initialValues}
          scrollToFirstError
          layout="vertical"
        >
          <div className="subsection-title">Provider information</div>
          <NPIInput form={form} name="NPI" />
          <Form.Item
            name="firstName"
            label="First Name:"
            rules={[
              rulesConstructor({ message: 'Please input your first name' }),
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="middleName" label="Middle Name:">
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name:"
            rules={[
              rulesConstructor({ message: 'Please input your last name' }),
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              { message: 'Please input a password', required: true },
              () => ({
                validator(_, value) {
                  const regex = new RegExp(passwordRegex)
                  if (!value || (value.length >= 8 && regex.test(value))) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      'Enter at least 8 characters, 1 uppercase, 1 lowercase, 1 special character, 1 number'
                    )
                  )
                },
              }),
            ]}
            hasFeedback
          >
            <Password />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              { message: 'Please confirm your password', required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match')
                  )
                },
              }),
            ]}
          >
            <Password />
          </Form.Item>

          <Form.Item name="AutocompleteAddress">
            <AddressAutocomplete
              value={formValues.AutocompleteAddress}
              onChange={handleAddressChange}
            />
          </Form.Item>

          <PhoneNumberInput form={form} required={true} />

          <Form.Item>
            <Button
              id="signup-card-signup-button"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {!isLoading && <CheckOutlined />} Activate
            </Button>
          </Form.Item>
        </Form>
      </LogoCard>
    </div>
  )
}
