import { ColumnType } from 'antd/lib/table'

import { AllergyHistory } from '../../../../../../../shared-types'
import { renderValue } from '../../../elements'

import styles from '../../../styles.module.scss'

export type AllergyData = {
  id: string
  name: string
  reaction?: string
  severity?: string
  onset?: string
  key?: number
}

export type AllergyColumn = ColumnType<AllergyData> & {
  sorter?: (a: AllergyData, b: AllergyData) => number
}

export const getColumns = ({
  isSigned,
}: {
  isSigned: boolean
}): AllergyColumn[] => {
  return [
    {
      title: 'Allergen Name',
      dataIndex: 'name',
      sorter: (a: AllergyData, b: AllergyData) => a.name?.localeCompare(b.name),
      className: styles['primary-column'],
    },
    {
      title: 'Reaction',
      dataIndex: 'reaction',
      render: renderValue,
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      render: renderValue,
    },
    {
      title: 'Onset',
      dataIndex: 'onset',
      render: renderValue,
    },
  ].map((col) => {
    if (isSigned) {
      delete col?.sorter
    }
    return col
  })
}

export const serializeAllergyData = (
  allergies: AllergyHistory[] | undefined
): AllergyData[] => {
  return (allergies || []).map((allergy) => {
    return {
      key: allergy.AllergyHistoryId,
      id: allergy.AllergyId,
      name: allergy.AllergyName,
      reaction: allergy.Reaction ?? undefined,
      severity: allergy.Severity ?? undefined,
      onset: allergy.Onset ?? undefined,
    }
  })
}
