export const ExternalLink = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon / Outlined / External Link">
        <path
          id="Vector"
          d="M13.75 14.25H2.25C1.97344 14.25 1.75 14.0266 1.75 13.75V2.25C1.75 1.97344 1.97344 1.75 2.25 1.75H7.875C7.94375 1.75 8 1.80625 8 1.875V2.75C8 2.81875 7.94375 2.875 7.875 2.875H2.875V13.125H13.125V8.125C13.125 8.05625 13.1813 8 13.25 8H14.125C14.1938 8 14.25 8.05625 14.25 8.125V13.75C14.25 14.0266 14.0266 14.25 13.75 14.25Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M11.2292 2.29577L12.0449 3.1114L8.0433 7.11609C7.99487 7.16453 7.99487 7.24421 8.0433 7.29265L8.70581 7.95515C8.75424 8.00359 8.83393 8.00359 8.88237 7.95515L12.8855 3.95202L13.7042 4.77077C13.7777 4.84421 13.9042 4.80046 13.9167 4.69734L14.2449 1.89421C14.2542 1.81296 14.1855 1.74577 14.1058 1.75515L11.3027 2.08327C11.2794 2.08613 11.2575 2.09546 11.2392 2.11019C11.221 2.12493 11.2073 2.14447 11.1997 2.16661C11.192 2.18875 11.1907 2.2126 11.196 2.23543C11.2012 2.25826 11.2127 2.27917 11.2292 2.29577Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
