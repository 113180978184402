import React from 'react'

import { Form as AntDForm, FormItemProps as AntDFormItemProps } from 'antd'

import { TestId } from '../../shared-types'
import { detectBadCSVValues } from './helpers/validationRules'

type FormItemProps = AntDFormItemProps & TestId

export default function FormItem({ testId, ...formItemProps }: FormItemProps) {
  const customRule = []
  formItemProps.rules
    ? formItemProps.rules.push({
        validator: detectBadCSVValues,
      })
    : customRule.push({
        validator: detectBadCSVValues,
      })
  const extraProp = customRule.length > 0 ? { rules: customRule } : {}

  return (
    <AntDForm.Item data-testid={testId} {...formItemProps} {...extraProp} />
  )
}
