import React from 'react'

import { Button, Col, Container, Row } from 'react-bootstrap'

import { OtherNotesFields } from '../OtherNotes'

interface SavedProps {
  currentData: OtherNotesFields
  switchToEdit: () => void
}

export default function SavedNotes(props: SavedProps) {
  const currentData = props.currentData

  const form = (
    <Row>
      <Col sm={12}>
        <Row className="border-bottom indent">
          <Col sm={12} className="patient topic">
            Clinical Trial Notes
          </Col>
          <Col sm={12} className="text-area">
            {currentData.clinicalTrialNotes}
          </Col>
        </Row>
      </Col>
      <Col sm={12}>
        <Row className="border-bottom indent">
          <Col sm={12} className="patient topic">
            Additional Notes
          </Col>
          <Col sm={12} className="text-area format-white-space">
            {currentData.additionalNotes}
          </Col>
        </Row>
      </Col>
      <br />
    </Row>
  )

  const buttons = (
    <Row>
      <Col>
        <Button
          bsPrefix="button-block icon-button"
          onClick={props.switchToEdit}
        >
          <i className="fa fa-pencil-square-o" aria-hidden="true" />
          &nbsp; Edit record
        </Button>
      </Col>
    </Row>
  )

  return (
    <Container>
      <br />
      {form}
      <br />
      {buttons}
      <br />
    </Container>
  )
}
