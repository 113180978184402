import React from 'react'

import ReactQRCode from 'react-qr-code'

export interface QRCodeProps {
  value: string
}

const QRCode: React.FC<QRCodeProps> = ({ value }) => {
  return <ReactQRCode value={value} />
}

export default QRCode
