import cx from 'classnames'

import {
  DELETED_APPT_TYPE,
  NO_APPT_TYPE,
} from '../../../../hooks/useSchedulingFilters/useSchedulingFilters'
import { useSchedulingContext } from '../../../../providers'
import { Button, Checkbox } from '../../../BaseComponents'
import { FilterAccordion } from '../FilterAccordion'
import { EmptyFilter } from './EmptyFilter'
import {
  areAllTeammateCalendarsSelected,
  getTotalNumberOfRoomsFromLocations,
  isSelectAllState,
  sortAlphabetically,
  sortTrueFirst,
} from './Filters.helpers'

import styles from './Filters.module.scss'

export const Filters = () => {
  const {
    locationsWithRoomInformation,
    handleToggleRoomSelected,
    roomsSelected,
    appointmentTypesOptions,
    handleToggleAppointmentTypesSelected,
    appointmentTypesSelected,
    handleToggleCalendarSelected,
    teammatesSelected,
    isUnassignedSelected,
    handleToggleUnassignedSelected,
    personalCalendarCheckboxes,
    teammateCalendarCheckboxes,
    handleDeselectAllAppointmentTypes,
    handleSelectAllAppointmentTypes,
    handleDeselectAllRooms,
    handleSelectAllRooms,
    handleDeselectAllTeammates,
    handleSelectAllTeammates,
  } = useSchedulingContext()

  return (
    <div className={styles.container}>
      <FilterAccordion title="My calendars" testId="myCalendarsAccordion">
        <Checkbox
          onClick={handleToggleUnassignedSelected}
          checked={isUnassignedSelected}
        >
          Show unassigned events
        </Checkbox>
        {Object.keys(personalCalendarCheckboxes).map((accountName) => {
          return (
            <div
              className={styles.filterContentContainer}
              key={`accountName_${accountName}`}
            >
              <div className={styles.checkboxContainer}>
                {personalCalendarCheckboxes[accountName]
                  .sort((a, z) =>
                    sortTrueFirst(a.isPrimaryCalendar, z.isPrimaryCalendar)
                  )
                  .map(({ calendarName, calendarId, isPrimaryCalendar }) => {
                    return (
                      <Checkbox
                        key={`checkbox_${calendarId}`}
                        onClick={handleToggleCalendarSelected(calendarId)}
                        checked={teammatesSelected.has(calendarId)}
                        className={cx(styles.checkbox, {
                          [styles.isIndented]: !isPrimaryCalendar,
                        })}
                      >
                        {calendarName}
                      </Checkbox>
                    )
                  })}
              </div>
            </div>
          )
        })}
      </FilterAccordion>
      <FilterAccordion
        title="Team members"
        testId="teamMembersCalendarsAccordion"
      >
        {Boolean(Object.keys(teammateCalendarCheckboxes).length === 0) && (
          <EmptyFilter
            bodyText="No other team members have been added to your practice."
            linkText="Add a team member"
            linkUrl="/settings/team"
          />
        )}
        {Object.keys(teammateCalendarCheckboxes)
          .sort(sortAlphabetically)
          .map((accountName) => {
            return (
              <div
                className={styles.filterContentContainer}
                key={`accountName_${accountName}`}
              >
                <div className={styles.checkboxContainer}>
                  {teammateCalendarCheckboxes[accountName]
                    .sort((a, z) =>
                      sortTrueFirst(a.isPrimaryCalendar, z.isPrimaryCalendar)
                    )
                    .map(({ calendarName, calendarId, isPrimaryCalendar }) => {
                      return (
                        <Checkbox
                          key={`checkbox_${calendarId}`}
                          className={cx(styles.checkbox, {
                            [styles.isIndented]: !isPrimaryCalendar,
                          })}
                          onClick={handleToggleCalendarSelected(calendarId)}
                          checked={teammatesSelected.has(calendarId)}
                        >
                          {calendarName}
                        </Checkbox>
                      )
                    })}
                </div>
              </div>
            )
          })}
        {Boolean(Object.keys(teammateCalendarCheckboxes).length) && (
          <Button
            className={styles.deselectAllButton}
            type="link"
            onClick={
              areAllTeammateCalendarsSelected(
                teammatesSelected,
                teammateCalendarCheckboxes
              )
                ? handleDeselectAllTeammates
                : handleSelectAllTeammates
            }
          >
            {areAllTeammateCalendarsSelected(
              teammatesSelected,
              teammateCalendarCheckboxes
            )
              ? 'Deselect all'
              : 'Select all'}
          </Button>
        )}
      </FilterAccordion>
      <FilterAccordion title="Rooms" testId="roomsAccordion">
        {Boolean(Object.keys(locationsWithRoomInformation).length === 0) && (
          <EmptyFilter
            bodyText="Rooms have not been set up for your practice."
            linkText="Add a room"
            linkUrl="/settings/location"
          />
        )}
        {Object.entries(locationsWithRoomInformation)
          .sort((a, z) =>
            sortAlphabetically(a[1].locationName, z[1].locationName)
          )
          .map(([locationId]) => {
            return (
              <div
                className={styles.filterContentContainer}
                key={`location_${locationId}`}
              >
                <p className={styles.filterTitle}>
                  {locationsWithRoomInformation[locationId].locationName}
                </p>
                <div className={styles.checkboxContainer}>
                  {locationsWithRoomInformation[locationId].rooms
                    .sort((a, z) => sortAlphabetically(a.roomName, z.roomName))
                    .map(({ roomName, roomId }, idx) => {
                      return (
                        <Checkbox
                          className={cx(styles.checkbox, {
                            [styles.lastRoom]:
                              idx ===
                              locationsWithRoomInformation[locationId].rooms
                                .length -
                                1,
                          })}
                          key={`checkbox_${roomId}`}
                          onClick={handleToggleRoomSelected(roomId)}
                          checked={roomsSelected.has(roomId)}
                        >
                          {roomName}
                        </Checkbox>
                      )
                    })}
                </div>
              </div>
            )
          })}
        {Boolean(Object.keys(locationsWithRoomInformation).length) && (
          <Button
            className={cx(styles.deselectAllButton, styles.noTopMargin)}
            type="link"
            onClick={
              isSelectAllState({
                selectedCount: roomsSelected.size,
                totalCount: getTotalNumberOfRoomsFromLocations(
                  locationsWithRoomInformation
                ),
              })
                ? handleSelectAllRooms
                : handleDeselectAllRooms
            }
          >
            {isSelectAllState({
              selectedCount: roomsSelected.size,
              totalCount: getTotalNumberOfRoomsFromLocations(
                locationsWithRoomInformation
              ),
            })
              ? 'Select all'
              : 'Deselect all'}
          </Button>
        )}
      </FilterAccordion>
      <FilterAccordion
        title="Appointment types"
        hasMarginBottom
        testId="appointmentTypesAccordion"
      >
        <div className={styles.appointmentTypeContainer}>
          <div className={cx(styles.checkboxContainer, styles.fitContent)}>
            <Checkbox
              onClick={handleToggleAppointmentTypesSelected(NO_APPT_TYPE)}
              checked={appointmentTypesSelected.has(NO_APPT_TYPE)}
            >
              No type
            </Checkbox>
          </div>
          <div
            className={cx(styles.appointmentTypeBubble, styles.noTypeBubble, {
              [styles.isSelected]: appointmentTypesSelected.has(NO_APPT_TYPE),
            })}
          />
        </div>
        {Boolean(Object.keys(appointmentTypesOptions).length === 0) && (
          <EmptyFilter
            bodyText="Appointment types have not been set up for your practice."
            linkText="Add an appointment type"
            linkUrl="/settings/appointment-settings"
          />
        )}
        <div className={styles.appointmentContainer}>
          {appointmentTypesOptions
            .sort((a, z) => sortAlphabetically(a.label, z.label))
            .map(({ label, value, color }, idx) => {
              const isSelected = appointmentTypesSelected.has(value as string)
              return (
                <div
                  data-testid={`AppointmentType--Option--${label.replaceAll(
                    /[$&+,:;=?@#|'<>.-^*()%!\s]/g,
                    ''
                  )}`}
                  className={styles.appointmentTypeContainer}
                  key={`appointment_type_${value}`}
                >
                  <div
                    className={cx(styles.checkboxContainer, styles.fitContent)}
                  >
                    <Checkbox
                      data-testid={`AppointmentType--Option--${label.replaceAll(
                        /[$&+,:;=?@#|'<>.-^*()%!\s]/g,
                        ''
                      )}-checkbox`}
                      className={cx(styles.appointmentLabel, {
                        [styles.checkboxPadding]: idx > 0,
                      })}
                      onClick={handleToggleAppointmentTypesSelected(
                        value as string
                      )}
                      checked={isSelected}
                    >
                      {label}
                    </Checkbox>
                  </div>
                  <div
                    data-testid={`AppointmentType--Option--${label.replaceAll(
                      /[$&+,:;=?@#|'<>.-^*()%!\s]/g,
                      ''
                    )}-color-${color}`}
                    className={cx(styles.appointmentTypeBubble, {
                      [styles.isSelected]: isSelected,
                    })}
                    style={{ backgroundColor: color }}
                  />
                </div>
              )
            })}
        </div>
        <div className={cx(styles.checkboxContainer, styles.fitContent)}>
          <Checkbox
            onClick={handleToggleAppointmentTypesSelected(DELETED_APPT_TYPE)}
            checked={appointmentTypesSelected.has(DELETED_APPT_TYPE)}
          >
            Deleted appointment types
          </Checkbox>
        </div>
        {Boolean(appointmentTypesOptions.length) && (
          <Button
            onClick={
              isSelectAllState({
                selectedCount: appointmentTypesSelected.size,
                totalCount: appointmentTypesOptions.length + 2,
              })
                ? handleSelectAllAppointmentTypes
                : handleDeselectAllAppointmentTypes
            }
            className={styles.deselectAllButton}
            type="link"
          >
            {isSelectAllState({
              selectedCount: appointmentTypesSelected.size,
              totalCount: appointmentTypesOptions.length + 2,
            })
              ? 'Select all'
              : 'Deselect all'}
          </Button>
        )}
      </FilterAccordion>
    </div>
  )
}
