import React, { useCallback, useContext, useEffect, useState } from 'react'

import { InfoCircleFilled, QrcodeOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import validator from 'validator'

import {
  submitNewGeneralHistoryRequest,
  updatePatientDemographicsErx,
  updatePatientEmail,
} from '../../../api/api-lib'
import {
  detectChangedFields,
  updatePatientDemographics,
} from '../../../api/patientInfo'
import { verifyPhoneNumber } from '../../../api/phoneNumbers'
import GenericAccordion from '../../../components/Accordions/GenericAccordion'
import PatientProfileButtons from '../../../components/Buttons/PatientProfileButtons'
import FormMachine from '../../../components/Forms/FormMachine/FormMachine'
import {
  TemplateField,
  TemplateFields,
} from '../../../components/Forms/FormMachine/types'
import { QueryKeys } from '../../../hooks/usePatientInfo'
import { onError } from '../../../libs/errorLib'
import { useFormFields } from '../../../libs/hooksLib'
import { notification } from '../../../libs/notificationLib'
import { DemographicsRequestOrigin } from '../../../shared-types'
import {
  Alert,
  Button as AntdButton,
  Space,
} from '../../../stories/BaseComponents'
import { UnreadMessageCountsContext } from '../../../v2/messaging/UnreadMessageCounts'
import VitalsQRCode from './VitalsQRCode'
import { styling, template } from './template'
import { GeneralHistoryResponse, RCopia } from './types'
import { phoneTypes } from './verifyPhoneHelper'

import '../PatientProfile.scss'
import styles from './General.module.scss'
import 'react-image-crop/src/ReactCrop.scss'

interface GeneralHistoryProps {
  patient: any
  handleApiChange: (queryName: string[]) => void
  response: GeneralHistoryResponse
  patientId: string
  providerId: string
}

interface CheckEvent {
  target: {
    checked?: string[]
    id: string
    type: 'yesNo' | 'checkbox' | 'radio'
    value?: string
  }
}

export default function GeneralHistory(props: GeneralHistoryProps) {
  const history = useHistory()
  const [isDisabled, _setIsDisabled] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [storedFields, handleFieldChange] = useFormFields({ ...props.response })
  const [patientEmail, setPatientEmail] = useState(props.patient.email)
  const [patientName, setPatientName] = useState(
    `${props.patient.firstName ?? ''}${
      !props.patient.middleName ? '' : ` ${props.patient.middleName}`
    }${!props.patient.lastName ? '' : ` ${props.patient.lastName}`}`
  )
  const [showQRModal, setShowQRModal] = useState(false)
  const { updateSendbirdUser } = useContext(UnreadMessageCountsContext)

  useEffect(() => {
    setPatientEmail(props.patient.email)
  }, [props.patient])

  useEffect(() => {
    const patientName = `${props.patient.firstName ?? ''}${
      !props.patient.middleName ? '' : ` ${props.patient.middleName}`
    }${!props.patient.lastName ? '' : ` ${props.patient.lastName}`}`
    setPatientName(patientName)
  }, [
    props.patient.firstName,
    props.patient.middleName,
    props.patient.lastName,
  ])

  const handleCaretakerVitalsCancel = useCallback(() => {
    setShowQRModal(false)
  }, [])

  const handleCaretakerVitalsOpen = useCallback(() => {
    setShowQRModal(true)
  }, [])

  const cancelEditing = () => {
    handleFieldChange(false, JSON.parse(JSON.stringify(props.response)))
    setIsEditing(false)
  }

  const switchToEdit = () => {
    setIsEditing(true)
  }

  const handleMultipleSubmit = (field: object | number, id: string) => {
    let newValue = []
    if (typeof field === 'object') {
      newValue = [field]
      if (storedFields[id]) {
        newValue = [...storedFields[id], field]
      }
    } else {
      storedFields[id].splice(field, 1)
      newValue = storedFields[id]
    }

    handleFieldChange({
      target: {
        id: id,
        value: newValue,
      },
    })
  }

  const findById = (
    object: TemplateField | TemplateFields | boolean,
    id: string
  ): TemplateField | boolean => {
    if (!Array.isArray(object) && typeof object === 'object') {
      const field = object.id === id ? object : false
      return field
    } else if (Array.isArray(object)) {
      const hasFoundId = object.find(
        (field: TemplateField) => field.id === id
      ) as unknown as TemplateField
      if (hasFoundId) {
        return hasFoundId
      } else {
        let foundObject = false as unknown as TemplateField | boolean
        object.forEach((field: TemplateField) => {
          if (field.conditionalComponent) {
            foundObject =
              foundObject || findById(field.conditionalComponent, id)
          }
        })
        if (typeof foundObject === 'object') return foundObject
      }
    }

    return false
  }

  const handleCheckChange = (id: string, key: number, isChecked = false) => {
    const field = findById(template, id)
    if (typeof field !== 'object' || !field.options) return

    const { fieldType, options } = field
    const type = fieldType === 'yesNo' ? 'radio' : 'checkbox'
    const value = options[key]
    const event: CheckEvent = { target: { id, type } }

    if (type === 'radio') event.target.value = value

    if (type !== 'checkbox') return handleFieldChange(event)

    // checkbox only
    let checked = storedFields[id] || []
    const target = options[key]
    if (!isChecked) checked.push(target)
    else checked = checked.filter((choice: string) => choice !== target)
    event.target.checked = checked
    handleFieldChange(event)
  }

  async function handleSubmit(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    setIsLoading(true)
    if (storedFields.firstName === '' || storedFields.lastName === '') {
      notification('Please enter a valid first or last name.')
      setIsLoading(false)
      return
    }
    for (
      let phoneTypeIndex = 0;
      phoneTypeIndex < phoneTypes.length;
      phoneTypeIndex++
    ) {
      const phoneType = phoneTypes[phoneTypeIndex]
      for (
        let fieldIndex = 0;
        fieldIndex < phoneType.count(storedFields);
        fieldIndex++
      ) {
        let storedNumber = phoneType.getStoredNumber(storedFields, fieldIndex)
        storedNumber = storedNumber?.replace(/\D+/g, '')
        if (
          (phoneType.required && !storedNumber) ||
          (storedNumber &&
            (storedNumber.length < 10 || storedNumber.length > 12))
        ) {
          notification(
            `Please enter a valid phone number: ${phoneType.getPhoneTypeForError(
              phoneType.getName && phoneType.getName(storedFields, fieldIndex)
            )}`
          )
          setIsLoading(false)
          return
        } else {
          if (storedNumber) {
            let verifiedPhone: any = {}
            try {
              verifiedPhone = await verifyPhoneNumber(storedNumber)
            } catch (err) {
              verifiedPhone.valid = false
              verifiedPhone.body = storedNumber
            }

            if (verifiedPhone.valid) {
              phoneType.setStoredNumber(
                storedFields,
                verifiedPhone.body,
                fieldIndex
              )
            } else {
              notification(
                `Please enter a valid phone number: ${phoneType.getPhoneTypeForError(
                  phoneType.getName &&
                    phoneType.getName(storedFields, fieldIndex)
                )}`
              )
              setIsLoading(false)
              return
            }
          }
        }
      }
    }

    if (!validator.isEmail(storedFields?.email || '')) {
      notification('Please enter a valid email.')
      setIsLoading(false)
      return
    }

    const newPatientName = `${storedFields.firstName ?? ''}${
      !storedFields.middleName ? '' : ` ${storedFields.middleName}`
    } ${storedFields.lastName ?? ''}`
    if (newPatientName !== patientName) {
      await updateSendbirdUser({
        patientId: props.patient.PatientId,
        patientName: newPatientName,
        channelUrl: props.patient.ChannelUrl,
      })
    }
    const hasChangedErxInfo =
      storedFields.addressLine1 !== props.patient.addressLine1 ||
      storedFields.addressLine2 !== props.patient.addressLine2 ||
      storedFields.city !== props.patient.city ||
      storedFields.state !== props.patient.state ||
      storedFields.zipCode !== props.patient.zipCode ||
      storedFields.cellPhone !== props.patient.cellPhone ||
      storedFields.homePhone !== props.patient.homePhone
    // if this patient has been onboarded with RCopia, update their RCopia patient demographics as well
    // with any potential updates made to their name, DOB and gender. Eventually we will incorpate address here
    // as well once we are storing the address as Line, City, State, Zip in our database
    if (props.patient.PatientRCopiaId && hasChangedErxInfo) {
      try {
        const updateRCopiaData = {} as RCopia
        updateRCopiaData.patientPublicId = props.patient.PublicId
        updateRCopiaData.AddressLine1 = storedFields.addressLine1
        updateRCopiaData.AddressLine2 = storedFields.addressLine2
        updateRCopiaData.City = storedFields.city
        updateRCopiaData.State = storedFields.state
        updateRCopiaData.ZipCode = storedFields.zipCode
        updateRCopiaData.PatientPhoneNumber = storedFields.cellPhone
        updateRCopiaData.PatientHomePhoneNumber = storedFields.homePhone

        await updatePatientDemographicsErx(updateRCopiaData)
      } catch (e) {
        onError(
          e,
          500,
          "Failed to update the patient's demographic info in their eRx module. Please inform your administrator."
        )
        setIsLoading(false)
      }
    }
    try {
      const data = {
        //compatibility with new field
        addressLine1: storedFields.addressLine1,
        addressLine2: storedFields.addressLine2,
        city: storedFields.city,
        state: storedFields.state,
        zipCode: storedFields.zipCode,
        email: storedFields.email || '',
        cellPhone: storedFields.cellPhone || '',
        homePhone: storedFields.homePhone || '',
        workPhone: storedFields.workPhone || '',
        emergencyContacts: storedFields.emergencyContacts || '',
        primaryProviderName: storedFields.primaryProviderName || '',
        primaryProviderPhone: storedFields.primaryProviderPhone || '',
        primaryProviderEmail: storedFields.primaryProviderEmail || '',
        primaryProviderCity: storedFields.primaryProviderCity || '',
        primaryProviderAuth: storedFields.primaryProviderAuth || '',
        primaryProviderNotes: storedFields.primaryProviderNotes || '',
        mentalHealthcareProviders: storedFields.mentalHealthcareProviders || '',
        otherHealthcareProviders: storedFields.otherHealthcareProviders || '',
        preferredPharmacy: storedFields.preferredPharmacy || '',
        patientId: props.patientId,
        updateOrigin: DemographicsRequestOrigin.OLD_PROFILE,
      }

      if (storedFields.email !== patientEmail) {
        await updatePatientEmail(
          props.patient.CognitoId,
          storedFields.email,
          patientEmail
        )
      }

      if (props.response?.createdAt || props.response?.CreatedAt) {
        await updatePatientDemographics({
          ...detectChangedFields(props.patient, data),
          updateOrigin: DemographicsRequestOrigin.OLD_PROFILE,
        })
      } else {
        await submitNewGeneralHistoryRequest(data)
      }
      props.handleApiChange([QueryKeys.DEMOGRAPHICS, props.patientId])
      notification(
        'You have successfully updated the Contact Info and Care Team subsection on the patient.',
        'success'
      )
      setIsLoading(false)
      setIsEditing(false)
      // type this error to 'any' to resolve typescript error "Object is of type 'unknown'"
    } catch (error: any) {
      if (
        error.response.data.error ===
        'An account with the given email already exists'
      ) {
        onError(
          error,
          500,
          'An account with that email address already exists. Please use a different email address.'
        )
      } else {
        console.error(
          'Error submitting information on Contact Info, Insurance & Care Team subsection of Patient Profile',
          error
        )
        onError(
          error,
          500,
          "Failed to update the patient's additonal information. Please inform your administrator."
        )
      }
      setIsLoading(false)
    }
  }

  const shortPublicId = (
    <>
      {!isEditing && (
        <Row
          style={{
            marginTop: -20,
            marginLeft: 28,
            marginRight: 28,
            marginBottom: 28,
          }}
        >
          <Col xs={12} md={12} lg={12} className="bottom-spacing border-bottom">
            <Row>
              <Col className="patient topic">Patient Id</Col>
            </Row>
            <Row>
              <Col className="font-weight-bold profile-copied">
                Note: '<span className="public-id-font">O</span>' is the letter,
                '<span className="public-id-font">0</span>' is the number zero
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  margin: 15,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span className="public-id-font" style={{ fontSize: 17 }}>
                  {props.patient.ShortPublicId}
                </span>
                <Button
                  bsPrefix="center plain-button info button-label"
                  onClick={handleCaretakerVitalsOpen}
                >
                  <QrcodeOutlined className="icon-vitals" />
                  Scan QR code
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      )}
    </>
  )

  const redirectToInsurance = () => {
    const searchParams = new URLSearchParams(location.search)

    history.replace({
      search: location.search.replace(
        searchParams
          .toString()
          .slice(searchParams.toString().indexOf('profilePanel')),
        `profilePanel=insurance`
      ),
    })
  }

  return (
    <>
      <VitalsQRCode
        shortPublicId={props.patient.ShortPublicId}
        handleCaretakerVitalsCancel={handleCaretakerVitalsCancel}
        visible={showQRModal}
      />
      <GenericAccordion title="Contact Info and Care Team">
        <Alert
          description="Insurance info has moved to the left-side panel"
          type="info"
          banner
          showIcon
          className={styles.alert}
          icon={<InfoCircleFilled className={styles.infoIcon} />}
          action={
            <Space direction="vertical">
              <AntdButton
                onClick={redirectToInsurance}
                size="small"
                type="primary"
              >
                View in the panel
              </AntdButton>
            </Space>
          }
        />
        <FormMachine
          storedFields={storedFields}
          template={template}
          handleCheckChange={handleCheckChange}
          handleMultipleSubmit={handleMultipleSubmit}
          handleFieldChange={handleFieldChange}
          isEditing={isEditing}
          styling={styling}
        />
        {props.patient.ShortPublicId && shortPublicId}
        <PatientProfileButtons
          cancelEditing={cancelEditing}
          isDisabled={isDisabled}
          isEditing={isEditing}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          switchToEdit={switchToEdit}
        />
      </GenericAccordion>
    </>
  )
}
