import { blue } from '@ant-design/colors'
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import { Dropdown } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { formatInTimeZone } from 'date-fns-tz'
import enUS from 'date-fns/locale/en-GB'
import { Link } from 'react-router-dom'

import { getS3FilePrivate } from '../../libs/awsLib'
import { downloadPDFToBrowser } from '../../libs/file-handling'
import { notification } from '../../libs/notificationLib'
import { capitalize, formatDate } from '../../libs/utils'
import { getNameToDisplay } from '../../shared/Helpers/utils'
import { Menu, SearchBox, Space, Tag, Tooltip } from '../BaseComponents'
import {
  LabResultsAcknowledgedStateEnum,
  LabResultsAcknowledgedStateValues,
  LabResultsStateEnum,
  LabResultsStateValues,
  LabsResultData,
  getLabResultsStateColor,
} from './_types'

import styles from './LabsResultsTable.module.scss'
import './LabsResultsTableColumns.scss'

export interface LabsResultsTableProps {
  results: LabsResultData[]
  showPatients?: boolean
  onDocuments?: boolean
  isLoading?: boolean
  providerId?: string
  viewResultPDF: (record: LabsResultData) => void
  refresh?: boolean
  noToolsSection?: boolean
}

export default function constructor({
  showPatients,
  results,
  viewResultPDF,
  providerId,
}: LabsResultsTableProps) {
  const clinicians = results.reduce(
    (returnClinicians: any[], record: LabsResultData) => {
      const value = record.orderingClinicianId
      const text = `${record.orderingClinicianLastName}, ${record.orderingClinicianFirstName}`
      if (
        !returnClinicians.find((clinician) => {
          return clinician.value === value
        })
      ) {
        returnClinicians.push({
          text: capitalize(text),
          value,
        })
      }
      return returnClinicians
    },
    []
  )

  function getToolTipMessage(name: string) {
    if (name === 'Final') {
      return 'The report is complete and verified by an authorized person.'
    } else if (name === 'Preliminary') {
      return 'Verified early results are available, but not all results are final.'
    } else if (name === 'Partial') {
      return 'This is a partial (e.g. initial, interim or preliminary) report: data in the report may be incomplete or unverified.'
    } else if (name === 'Registered') {
      return 'The existence of the report is registered, but there is nothing yet available.'
    } else if (name === 'Amended') {
      return 'Subsequent to being final, the report has been modified. This includes any change in the results, diagnosis, narrative text, or other content of a report that has been issued.'
    } else if (name === 'Corrected') {
      return 'Subsequent to being final, the report has been modified to correct an error in the report or referenced results.'
    } else if (name === 'Unknown') {
      return 'The authoring/source system does not know which of the status values currently applies for this observation.'
    } else if (name === 'Entered in error') {
      return 'The report has been withdrawn following a previous final release. This electronic record should never have existed, though it is possible that real-world decisions were based on it.'
    } else if (name === 'Appended') {
      return 'Subsequent to being final, the report has been modified by adding new content. The existing content is unchanged.'
    } else if (name === 'Cancelled') {
      return 'The report is unavailable because the measurement was not started or not completed (also sometimes called "aborted").'
    } else {
      return 'The authoring/source system does not know which of the status values currently applies for this observation.'
    }
  }

  const downloadResultPDF = async (record: LabsResultData) => {
    try {
      const data = await getS3FilePrivate(
        record.labsOrderDocumentReference,
        providerId,
        {
          ResponseCacheControl: 'max-age=0',
        }
      )
      if (!data) throw new Error('Document not found')
      const title = `${record.labsOrderDocumentTitle}.pdf`
      downloadPDFToBrowser(data, title)
    } catch (e) {
      notification(
        'Encountered an error while downloading document. Please contact support if the issue persists.',
        'error'
      )
    }
  }

  const columns: ColumnsType<any> = [
    {
      className: 'labs-results-column',
      dataIndex: 'viewed',
      key: 'ready-for-viewed',
      // title: 'Viewed',
      render: (_: any, record: LabsResultData) => {
        const labsResultStateColor = getLabResultsStateColor(record.state)
        if (
          record.viewed ||
          !record.labsOrderDocumentReference ||
          labsResultStateColor !== 'green'
        ) {
          return <></>
        }
        return (
          <div className={styles.statusContainer}>
            <div className={styles.statusDotViolet} />
          </div>
        )
      },
    },
    {
      className: 'labs-results-column',
      dataIndex: 'clinician',
      key: 'clinician',
      title: 'Clinician',
      filters: clinicians,
      onFilter: (value: any, record: LabsResultData) => {
        return value === record.orderingClinicianId
      },
      render: (_: any, record: LabsResultData) => {
        return (
          <>{`${record.orderingClinicianFirstName} ${record.orderingClinicianLastName}`}</>
        )
      },
    },
    {
      className: 'labs-results-column',
      dataIndex: 'ordered',
      key: 'ordered',
      title: 'Ordered',
      sorter: (a: LabsResultData, b: LabsResultData) =>
        new Date(a.ordered).getTime() - new Date(b.ordered).getTime(),
      render: (ordered: string) => <>{formatDate({ value: ordered })}</>,
    },
    {
      className: 'labs-results-column',
      dataIndex: 'resulted',
      key: 'resulted',
      title: 'Updated',
      sorter: (a: LabsResultData, b: LabsResultData) => {
        if (!a.resulted) {
          return -1
        } else if (!b.resulted) {
          return 1
        }
        return new Date(a.resulted).getTime() - new Date(b.resulted).getTime()
      },
      render: (resulted: string) => (
        <>{!resulted ? 'Pending' : formatDate({ value: resulted })}</>
      ),
    },
    {
      className: 'labs-results-column',
      dataIndex: 'state',
      key: 'state',
      title: 'Status',
      filters: LabResultsStateValues.map((stateValue) => {
        let value = stateValue
        let text = stateValue
        if (value === 'enteredInError') {
          value = LabResultsStateEnum.enteredInError
          text = 'Entered in error'
        }
        return {
          text: capitalize(text),
          value,
        }
      }),
      onFilter: (value: any, record: LabsResultData) => {
        return value === record.state
      },
      render: (state: string, _record: LabsResultData) => {
        const labsResultStateColor = getLabResultsStateColor(state)
        const labsResultStateName = capitalize(state.split('-').join(' '))
        let labsResultStateColorStyle = ''
        switch (labsResultStateColor) {
          case 'blue':
            labsResultStateColorStyle = styles.statusDotBlue
            break
          case 'green':
            labsResultStateColorStyle = styles.statusDotGreen
            break
          case 'red':
            labsResultStateColorStyle = styles.statusDotRed
            break
          case 'gray':
            labsResultStateColorStyle = styles.statusDotGray
            break
          default:
            labsResultStateColorStyle = styles.statusDotGray
            break
        }
        return (
          <div>
            <Tooltip
              id="not-ready-tooltip"
              placement="topLeft"
              mouseEnterDelay={0.5}
              title={getToolTipMessage(labsResultStateName)}
              className={styles.statusContainer}
            >
              <div className={labsResultStateColorStyle} />
              {labsResultStateName}
            </Tooltip>
          </div>
        )
      },
    },
    {
      className: 'labs-results-column results-pdf',
      dataIndex: 'results',
      key: 'results',
      title: 'Results',
      render: (_text: any, record: LabsResultData) => {
        if (!record.labsOrderDocumentReference) {
          return <span>Not Available</span>
        }
        const items = [
          {
            label: 'View PDF',
            key: 'view',
          },
          {
            label: 'Download PDF',
            key: 'download',
          },
        ]
        const labsResultStateColor = getLabResultsStateColor(record.state)
        if (labsResultStateColor !== 'green') {
          return (
            <Dropdown
              overlay={
                <Menu
                  onClick={(e) => {
                    if (e.key === 'view') {
                      viewResultPDF(record)
                    } else if (e.key === 'download') {
                      downloadResultPDF(record)
                    }
                  }}
                  items={items}
                />
              }
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  View order
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          )
        } else {
          return (
            <Dropdown
              overlay={
                <Menu
                  onClick={(e) => {
                    if (e.key === 'view') {
                      viewResultPDF(record)
                    } else if (e.key === 'download') {
                      downloadResultPDF(record)
                    }
                  }}
                  items={items}
                />
              }
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  View results
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          )
        }
      },
    },
    {
      className: 'labs-results-column reviewed-pdf',
      dataIndex: 'acknowledgedStatus',
      key: 'acknowledgedStatus',
      title: 'Reviewed',
      filters: LabResultsAcknowledgedStateValues.map(
        (acknowledgedStateValue) => {
          const value = acknowledgedStateValue
          let text = acknowledgedStateValue
          if (value === 'notApplicable') {
            text = 'Not applicable'
          }
          if (value === 'notReady') {
            text = 'Not ready'
          }
          if (value === 'readyForReview') {
            text = 'Ready for review'
          }
          return {
            text: capitalize(text),
            value,
          }
        }
      ),
      onFilter: (value: any, record: LabsResultData) => {
        let acknowledgedStatus = LabResultsAcknowledgedStateEnum.notApplicable
        const labsResultStateColor = getLabResultsStateColor(record.state)
        if (labsResultStateColor === 'blue') {
          acknowledgedStatus = LabResultsAcknowledgedStateEnum.notReady
        } else if (labsResultStateColor === 'green') {
          if (record.acknowledgedDate) {
            acknowledgedStatus = LabResultsAcknowledgedStateEnum.reviewed
          } else {
            acknowledgedStatus = LabResultsAcknowledgedStateEnum.readyForReview
          }
        }
        return value === acknowledgedStatus
      },
      render: (_acknowledgedStatus: string, record: LabsResultData) => {
        const labsResultStateColor = getLabResultsStateColor(record.state)
        if (labsResultStateColor === 'blue') {
          return (
            <Tooltip
              id="not-ready-tooltip"
              placement="topLeft"
              mouseEnterDelay={0.5}
              title="The lab order is still being processed."
            >
              <Tag>Not ready</Tag>
            </Tooltip>
          )
        } else if (labsResultStateColor === 'green') {
          switch (_acknowledgedStatus) {
            case LabResultsAcknowledgedStateEnum.reviewed:
              return (
                <Tooltip
                  id="not-ready-tooltip"
                  placement="topLeft"
                  mouseEnterDelay={0.5}
                  title={
                    <div>
                      <div>
                        {`${record.acknowledgedByFirstName} ${
                          record.acknowledgedByLastName
                        }${
                          record.acknowledgedByCredential
                            ? ', ' + record.acknowledgedByCredential
                            : ''
                        }`}
                      </div>
                      {record.acknowledgedDate ? (
                        <div>
                          {`${formatInTimeZone(
                            new Date(record.acknowledgedDate),
                            record.practiceTimezone
                              ? record.practiceTimezone
                              : Intl.DateTimeFormat().resolvedOptions()
                                  .timeZone,
                            'MM/dd/yyyy',
                            { locale: enUS }
                          )} - ${formatInTimeZone(
                            new Date(record.acknowledgedDate),
                            record.practiceTimezone
                              ? record.practiceTimezone
                              : Intl.DateTimeFormat().resolvedOptions()
                                  .timeZone,
                            'KK:ss aa',
                            { locale: enUS }
                          )} ${
                            new Date()
                              .toLocaleTimeString('en-us', {
                                timeZoneName: 'short',
                              })
                              .split(' ')[2]
                          }`}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  }
                >
                  <Tag color="green">Reviewed</Tag>
                </Tooltip>
              )
            case LabResultsAcknowledgedStateEnum.readyForReview:
              return (
                <Tooltip
                  id="not-ready-tooltip"
                  placement="topLeft"
                  mouseEnterDelay={0.5}
                  title={
                    'To mark the lab results as reviewed, open the lab results.'
                  }
                >
                  <Tag color="blue">Ready for review</Tag>
                </Tooltip>
              )
            default:
              return (
                <Tooltip
                  id="not-ready-tooltip"
                  placement="topLeft"
                  mouseEnterDelay={0.5}
                  title={`The reviewed status doesn’t apply to this lab.`}
                >
                  <Tag>Not applicable</Tag>
                </Tooltip>
              )
          }
        } else {
          return (
            <Tooltip
              id="not-ready-tooltip"
              placement="topLeft"
              mouseEnterDelay={0.5}
              title={`The reviewed status doesn’t apply to this lab.`}
            >
              <Tag>Not applicable</Tag>
            </Tooltip>
          )
        }
      },
    },
  ]
  if (showPatients) {
    const patients = results.reduce(
      (returnPatients: any[], record: LabsResultData) => {
        const value = record.patientId
        const text = `${record.patientFirstName}, ${record.patientLastName}`
        if (
          !returnPatients.find((patient) => {
            return patient.value === value
          })
        ) {
          returnPatients.push({
            text: capitalize(text),
            value,
          })
        }
        return returnPatients
      },
      []
    )

    columns.splice(1, 0, {
      className: 'labs-results-column',
      dataIndex: 'patient',
      key: 'patient',
      title: 'Patient',
      filters: patients,
      filterIcon: () => <SearchOutlined />,
      filterDropdown: (args) => (
        <SearchBox
          dataIndex="patientName"
          placeholder="Search Patient Name"
          {...args}
        />
      ),
      onFilter: (value, record: LabsResultData) => {
        if (
          record.patientFirstName
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          record.patientLastName
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        ) {
          return true
        }
        return false
      },
      render: (_: any, record: LabsResultData) => {
        return (
          <Link
            style={{ color: blue.primary }}
            to={`/patient/labs?patientId=${record.patientId}&providerId=${record.orderingClinicianId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getNameToDisplay({
              firstName: record.patientFirstName,
              middleName: record.patientMiddleName,
              lastName: record.patientLastName,
            })}
          </Link>
        )
      },
      filterSearch: true,
    })
  }

  return {
    columns,
    styles,
  }
}
