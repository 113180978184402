import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react'

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleFilled,
} from '@ant-design/icons'
import { Modal } from 'antd'
import { FixedType } from 'rc-table/lib/interface'
import { useHistory } from 'react-router-dom'

import {
  connectUserCalendar,
  deleteUserCalendarAccount,
  getGoogleOAuthUrl,
  updateCalendarAccountSettings,
  updateUserCalendarName,
} from '../../api/api-lib'
import { useUserCalendars } from '../../hooks/useCalendars'
import GoogleLogo from '../../images/Icons/GoogleLogo'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import { useQuery } from '../../libs/utils'
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  ConfirmOrExitInput,
  Divider,
  Modal as OsmindModal,
  Row,
  Spinner,
  Table,
  Text,
  Title,
} from '../BaseComponents'
import CalendarSettingsEditModal from './CalendarSettingsEditModal'
import {
  CalendarSettingsType,
  CalendarType,
  PersonalCalendar,
  UserCalendarAccount,
} from './types'

import styles from '../../containers/_shared.module.scss'
import './Scheduling.scss'

interface GoogleAccountItem {
  id?: number
  accountName: string
  calendarName: string
  calendarId: string
  providerIntegrationId: number
  displayEventsInGoogle?: boolean
  includePatientInitials?: boolean
  rowSpan?: number
  needsReauth: boolean
}

type GoogleAccountItems = GoogleAccountItem[]

export interface UserCalendarItem extends GoogleAccountItem {
  accountId?: number
  displayEventDetails?: boolean
  internalId: string
  visibleToPractice?: boolean
  showInOsmind?: boolean
  displayInProviderCalendar?: boolean
}

type UserCalendarItems = UserCalendarItem[]

// Calendar Ids in UserCalendarAccount are not unique given
// calendars can be shared across multiple accounts. Generate
// unique id to be used internally.
const generateUniqueGoogleCalendarId = ({
  calendarId,
  accountName,
}: {
  calendarId: string
  accountName: string
}) => {
  return `${calendarId}-${accountName}`
}

// TODO: Begin placing in util files to decrease size of files
const parseCalendars = (
  data: UserCalendarAccount[]
): [GoogleAccountItems, UserCalendarItems] => {
  const googleCalendarList: GoogleAccountItems = []
  let personalCalendarList: UserCalendarItems = []

  // keeps track if the Account Name + "Display Calendar Details" is shown
  // (will not be shown if row is after a calendar under the same account)
  const rowSpans = new Map()

  data.forEach(({ id, accountName, calendars, internalId, needsReauth }) => {
    let displayEventsInGoogle = false
    // need to know beforehand that there's a PERSONAL_OSMIND cal type here
    calendars.forEach(({ calendarType }) => {
      if (calendarType === CalendarType.PERSONAL_OSMIND) {
        displayEventsInGoogle = true
      }
    })

    // ensures calendars are always shown in the same order by pre-sorting
    calendars.sort((a: PersonalCalendar, b: PersonalCalendar) => {
      if (a.calendarId > b.calendarId) return 1
      else if (a.calendarId === b.calendarId) return 0
      else return -1
    })

    calendars.forEach((calendar, idx) => {
      const {
        accountId,
        calendarId,
        calendarName,
        calendarType,
        calendarSettings,
        showInOsmind,
        providerIntegrationId,
      } = calendar

      if (
        calendarType !== CalendarType.PERSONAL_OSMIND &&
        calendarType !== CalendarType.PERSONAL
      ) {
        return
      }

      const item: GoogleAccountItem = {
        id,
        accountName,
        calendarName,
        calendarId,
        needsReauth,
        providerIntegrationId,
      }

      if (calendarType === CalendarType.PERSONAL_OSMIND) {
        item.includePatientInitials =
          showInOsmind &&
          calendarSettings === CalendarSettingsType.WITH_PATIENT_INITIALS
        googleCalendarList.push(item)
        return
      }

      const displayEventDetails =
        showInOsmind &&
        Boolean(
          calendarSettings === CalendarSettingsType.EVERYONE_PUBLIC ||
            calendarSettings === CalendarSettingsType.INTEGRATED_PUBLIC
        )

      const visibleToPractice =
        showInOsmind &&
        Boolean(calendarSettings !== CalendarSettingsType.INDIVIDUAL)

      const displayInProviderCalendar =
        showInOsmind &&
        Boolean(
          [
            CalendarSettingsType.INTEGRATED_PRIVATE,
            CalendarSettingsType.INTEGRATED_PUBLIC,
          ].includes(calendarSettings)
        )

      const personalCalendarItem: UserCalendarItem = {
        ...item,
        accountId,
        internalId,
        displayEventsInGoogle,
        displayEventDetails,
        displayInProviderCalendar,
        showInOsmind,
        visibleToPractice,
      }
      personalCalendarList.push(personalCalendarItem)

      // Update rowSpan map with the number of rows under the current calendar account
      if (idx === calendars.length - 1) {
        const firstUserCalendarUniqueId = generateUniqueGoogleCalendarId({
          calendarId: calendars[0].calendarId,
          accountName,
        })
        const rowSpan = idx
        rowSpans.set(firstUserCalendarUniqueId, rowSpan)
      }
    })
  })

  const checkIfRowBeforeIsSameAccount = (
    record: UserCalendarItem,
    index: number
  ): boolean => {
    if (!index || personalCalendarList?.length < 2) return false

    const previousItem = personalCalendarList[index - 1]
    return previousItem?.accountName === record?.accountName
  }

  personalCalendarList = personalCalendarList.map((calendar, index) => {
    if (checkIfRowBeforeIsSameAccount(calendar, index)) {
      return { ...calendar, rowSpan: 0 }
    }
    // if not the same, then the rowSpan encompass the number of rows
    // under the same account
    const { calendarId, accountName } = calendar
    const uniqueGoogleCalendarId = generateUniqueGoogleCalendarId({
      calendarId,
      accountName,
    })

    return {
      ...calendar,
      calendarId: uniqueGoogleCalendarId,
      rowSpan: rowSpans.get(uniqueGoogleCalendarId) ?? 1,
    }
  })

  return [googleCalendarList, personalCalendarList]
}

const CalendarSettings = () => {
  const query = useQuery()
  const exchangeCode = query.get('code')
  const [isVisibleConnectModal, setIsVisibleConnectModal] =
    useState<boolean>(false)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [isUpdatingCalendar, setIsUpdatingCalendar] = useState<boolean>(false)
  const [calendarSettingsModal, setCalendarSettingsModal] =
    useState<ReactNode>()
  const [showEditCalendarModal, setShowEditCalendarModal] =
    useState<boolean>(false)
  const [editCalendarItem, setEditCalendarItem] =
    useState<UserCalendarAccount | null>(null)
  const [canSignIn, setCanSignIn] = useState<boolean>(false)
  // if there is a nylas code in the URL, we have just been redirected from the Google OAuth flow and need
  // to send this code to the backend to exchange for an access token to complete the user calendar connection
  const [shouldAuthenticateUser, setShouldAuthenticateUser] = useState<boolean>(
    !!exchangeCode
  )
  const {
    data: userCalendars,
    refetch: refetchUserCalendars,
    isFetching: isFetchingUserCalendars,
    isLoading: isLoadingUserCalendars,
  } = useUserCalendars()
  const history = useHistory()

  const sectionHeaderText = { fontWeight: 600, fontSize: 18 }

  const handleOk = async (data: any) => {
    try {
      setTableLoading(true)
      await updateCalendarAccountSettings(data)
      setIsUpdatingCalendar(true)
      notification('Successfully modified Google account settings.', 'success')
    } catch (e) {
      console.error('Update google account settings failed with: ', e)
      onError(new Error('Failed to update Google account settings.'))
    } finally {
      setIsUpdatingCalendar(false)
      setTableLoading(false)
      setShowEditCalendarModal(false)
    }
  }

  useEffect(
    () =>
      setCalendarSettingsModal(
        <CalendarSettingsEditModal
          calendarAccount={editCalendarItem}
          handleOk={handleOk}
          handleCancel={() => setShowEditCalendarModal(false)}
          visible={showEditCalendarModal}
        />
      ),
    [showEditCalendarModal]
  )

  const updateCalendarInfo = async () => {
    await refetchUserCalendars()
  }

  // TODO: Investigate if this should be done using react query
  // Additionally, investigate moving this into a higher level so that
  // re-querying for calendar data does not have to occur on mount.
  useEffect(() => {
    ;(async () => {
      await updateCalendarInfo()
    })()
  }, [])

  useEffect(() => {
    if (isUpdatingCalendar) {
      updateCalendarInfo()
    }
  }, [isUpdatingCalendar])

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const data = {
          ExchangeCode: exchangeCode,
          googleAuthCode: exchangeCode,
        }
        await connectUserCalendar(data)
        // update calendar list with this newly added calendar
        await updateCalendarInfo()
      } catch (e) {
        onError(e, 500, e.response.data.error)
      }

      // remove the url param after authentication so we do not
      // try to use this now invalid code again
      query.delete('code')
      history.replace({
        search: query.toString(),
      })
      setShouldAuthenticateUser(false)
    }
    // check for a nylas exchangeCode in the url params and hit the
    // authentication callback if one exists
    if (shouldAuthenticateUser) {
      authenticateUser()
    }
  }, [shouldAuthenticateUser])

  const handleAddGoogleCalendar = async () => {
    try {
      const googleOAuthUrl = await getGoogleOAuthUrl()
      window.location.href = googleOAuthUrl
    } catch (e) {
      console.error(e)
    }
  }

  const handleDeleteCalendar = async (id: number) => {
    try {
      setTableLoading(true)
      await deleteUserCalendarAccount({ accountId: id })
      setIsUpdatingCalendar(true)
      notification('Successfully removed a Google account.', 'success')
    } catch (e) {
      console.error(e)
      notification('Error: Unable to remove Google account.', 'failure')
    } finally {
      setIsUpdatingCalendar(false)
      setTableLoading(false)
    }
  }

  const handleEditCalendarName = async (data: {
    calendarName: string
    calendarId: string
    providerIntegrationId: number
  }) => {
    try {
      await updateUserCalendarName(data)
    } catch (e) {
      console.error(e)
      onError('Failed to update calendar name.')
    }
  }

  const toggleDeleteCalendar = async (id: number) => {
    if (!id) return
    Modal.confirm({
      title: 'Delete account',
      content: 'Are you sure you want to delete this account?',
      okText: 'Ok',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'default',
      },
      okButtonProps: {
        type: 'primary',
      },
      onOk: () => handleDeleteCalendar(id),
    })
  }

  const handleEditCalendarSettings = (calendar: UserCalendarAccount) => {
    setEditCalendarItem(calendar)
    setShowEditCalendarModal(true)
  }

  // parses boolean under record attribute into a 'Yes' or 'No' string
  const parseBoolean = (_: any, record: any, attribute = '') => {
    if (!attribute) return <></>
    const text = record[attribute] ? 'Yes' : 'No'
    const shouldDisable = !record['showInOsmind'] && text === 'No'
    const className = shouldDisable ? 'disable-row-text' : ''
    return <span className={className}>{text}</span>
  }

  const getRowSpan = ({ rowSpan = 1 }: UserCalendarItem) =>
    ({ rowSpan } as HTMLAttributes<any>)

  const renderNameInput = (
    _: any,
    {
      calendarName = '',
      calendarId,
      showInOsmind,
      needsReauth,
      providerIntegrationId,
    }: UserCalendarItem
  ) => {
    if (!showInOsmind || typeof calendarId === 'undefined' || needsReauth) {
      return <span className="disable-row-text">{calendarName}</span>
    }

    const onConfirm = async (newName?: string) => {
      if (!newName) return
      try {
        setTableLoading(true)
        await handleEditCalendarName({
          calendarId,
          calendarName: newName,
          providerIntegrationId,
        })
        setIsUpdatingCalendar(true)
      } catch (e) {
        console.error(e)
      } finally {
        setIsUpdatingCalendar(false)
        setTableLoading(false)
      }
    }

    return <ConfirmOrExitInput onConfirm={onConfirm} value={calendarName} />
  }

  const renderButtons = (_text: any, record: UserCalendarItem) => {
    const { id, accountName, needsReauth } = record
    const { rowSpan = 1 } = (getRowSpan(record) ?? {}) as {
      rowSpan: number
    }
    if (!rowSpan || !userCalendars || !id) return <></>
    const parentAccount = userCalendars.filter(
      (calendar) => calendar.accountName === accountName
    )[0]

    return (
      <Row key={accountName} style={{ width: '200px' }}>
        {needsReauth ? (
          <Col>
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => setIsVisibleConnectModal(true)}
              className="antd-center-button action-button-edit"
            >
              Reconnect
            </Button>
          </Col>
        ) : (
          <>
            <Col>
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={() => handleEditCalendarSettings(parentAccount)}
                className="antd-center-button action-button-edit"
              >
                Edit
              </Button>
            </Col>
            <Col>
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => toggleDeleteCalendar(id)}
                className="antd-center-button action-button-delete"
              >
                Delete
              </Button>
            </Col>
          </>
        )}
      </Row>
    )
  }

  const generateRowKey = (record: UserCalendarItem) => {
    const { accountName = '', calendarId = '' } = record ?? {}
    const firstHalf = accountName ? accountName : 'key-101'
    const secondHalf = calendarId ? calendarId.substring(5) : '-123'
    return `${firstHalf}${secondHalf}`
  }

  const styleIfNeedsReauth = (
    text: string | ReactNode,
    needsReauth: boolean
  ) => <span className={needsReauth ? 'disable-row-text' : ''}>{text}</span>

  const calendarColumns = [
    {
      title: 'Google account',
      dataIndex: 'accountName',
      width: 200,
      fixed: 'left' as FixedType,
      onCell: getRowSpan,
      render: (accountName: string, { needsReauth }: UserCalendarItem) => {
        if (needsReauth) {
          return (
            <span style={{ color: '#FF4D4F' }}>
              <ExclamationCircleOutlined style={{ padding: '0 5px' }} />
              {accountName}
            </span>
          )
        }

        return accountName
      },
    },
    {
      title: 'Calendar name',
      dataIndex: 'calendarName',
      width: 170,
      render: renderNameInput,
    },
    {
      title: 'Display events in Google',
      dataIndex: 'displayEventsInGoogle',
      width: 180,
      onCell: getRowSpan,
      render: (_: any, record: UserCalendarItem) =>
        styleIfNeedsReauth(
          parseBoolean(_, record, 'displayEventsInGoogle'),
          record.needsReauth
        ),
    },

    {
      title: 'Visible to practice staff',
      dataIndex: 'visibleToPractice',
      width: 170,
      render: (_: any, record: UserCalendarItem) =>
        styleIfNeedsReauth(
          parseBoolean(_, record, 'visibleToPractice'),
          record.needsReauth
        ),
    },
    {
      title: 'Display event details',
      dataIndex: 'displayEventDetails',
      width: 150,
      render: (_: any, record: UserCalendarItem) =>
        styleIfNeedsReauth(
          parseBoolean(_, record, 'displayEventDetails'),
          record.needsReauth
        ),
    },
    {
      title: 'Combine with my Osmind calendar',
      dataIndex: 'displayInProviderCalendar',
      width: 150,
      render: (_: any, record: UserCalendarItem) =>
        styleIfNeedsReauth(
          parseBoolean(_, record, 'displayInProviderCalendar'),
          record.needsReauth
        ),
    },
    {
      title: 'Actions',
      width: 200,
      render: renderButtons,
      fixed: 'right' as FixedType,
    },
  ]

  const calendarData = useMemo((): UserCalendarItems => {
    if (!userCalendars) return []

    const [_, calendars] = parseCalendars(userCalendars)

    return calendars
  }, [userCalendars])

  const loaderSpinner = (
    <Spinner
      fontSize={40}
      className="d-flex justify-content-center calendar-spinner scheduling-spinner"
      style={{ marginTop: 48 }}
    />
  )

  const calendarTitle = (
    <Title level={3} style={{ fontWeight: 600, marginBottom: '1.5em' }}>
      Connected accounts
    </Title>
  )
  const sectionSubText = { marginTop: 10, fontSize: 14 }
  const calendarTableTitle = (
    <div className="sectionHeader">
      <Text style={{ ...sectionHeaderText }}>Connected Google accounts</Text>
      <Text style={sectionSubText}>
        Connecting to Google calendar will allow your Osmind events to appear on
        that calendar. Note: your Osmind events will be de-identified and will
        not be editable from the Google calendar. Only events that you are
        assigned to in Osmind will appear in your Google calendar.
      </Text>
    </div>
  )

  const needsReauthAlert = userCalendars?.some(
    (calendar) => calendar.needsReauth
  ) ? (
    <div className="needs-reauth-alert">
      <Alert
        message="Your Google calendar was disconnected"
        type="error"
        showIcon
      />
    </div>
  ) : null

  const calendarTable = (
    <Table
      bordered
      className="connected-user-calendars"
      columns={calendarColumns}
      dataSource={calendarData}
      addClassNames={['no-padding', 'no-table-tools-section']}
      locale={{
        emptyText: 'There are no calendars linked to your account',
      }}
      // @ts-ignore
      rowKey={generateRowKey}
      showHeader={calendarData.length > 0}
      pagination={false}
      size="middle"
      loading={tableLoading || shouldAuthenticateUser}
      scroll={{ x: 1070 }}
    />
  )

  const connectButton = (
    <Button
      id="connect-with-google-button"
      onClick={() => setIsVisibleConnectModal(true)}
    >
      <div className="sign-in-with-google-button-inner">
        Connect Google Account
      </div>
    </Button>
  )

  const signInButton = (
    <Button
      size="large"
      id="sign-in-with-google-button"
      className={canSignIn ? '' : 'disabled'}
      onClick={handleAddGoogleCalendar}
      style={!canSignIn ? { color: '#BFBFBF' } : undefined}
      disabled={!canSignIn}
    >
      <div className="inner">
        <div className="logo">
          <GoogleLogo />
        </div>
        <div className="text">Sign in with Google</div>
      </div>
    </Button>
  )

  const connectModal = (
    <OsmindModal
      closable={true}
      visible={isVisibleConnectModal}
      title="Connected Google Accounts"
      className="edit-calendar-name-modal"
      onCancel={() => setIsVisibleConnectModal(false)}
      footer={
        <Button onClick={() => setIsVisibleConnectModal(false)}>Cancel</Button>
      }
    >
      <Row className="info-card info-card-modal">
        <Col className="info-card-icon">
          <InfoCircleFilled />
        </Col>
        <Col className="info-card-modal-text-box">
          Connecting Osmind to your Google accounts allows you to display Osmind
          events in Google Calendar, and Google events in Osmind. Osmind events
          are deidentified and are not editable from Google Calendar. You have
          full control of the visibility of your Google events to other practice
          users.
        </Col>
      </Row>
      <Divider />
      <div>
        By enabling sharing of your Osmind calendar with your Google Calendar,
        you acknowledge and agree to follow HIPAA compliance requirements and
        protect the calendar information, including any secure telehealth links.
      </div>
      <Row>
        <Col style={{ marginTop: '12px' }}>
          <Checkbox onChange={(event) => setCanSignIn(event.target.checked)}>
            I agree
          </Checkbox>
        </Col>
      </Row>
      {signInButton}
    </OsmindModal>
  )

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        <Row className="calendar-settings-row" justify="start" wrap={false}>
          <Card bordered={false} className="calendar-settings-card">
            <>
              {calendarTitle}
              {calendarTableTitle}
              {needsReauthAlert}
              {isFetchingUserCalendars && isLoadingUserCalendars
                ? loaderSpinner
                : calendarTable}
              {connectButton}
            </>
          </Card>
        </Row>
        {calendarSettingsModal}
        {connectModal}
      </div>
    </div>
  )
}

export default CalendarSettings
