import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const DiabetesCodes: any = [
  {
    362969004: {
      title: 'Endocrine Disorder (Including Diabetes and Thyroid)',
      description: 'Disorder of endocrine system (disorder)',
      snomedcode: 362969004,
      icdcode: 'E349',
      icddescription: 'Endocrine disorder, unspecified',
    },
  },
  {
    '46635009': {
      title: 'endo.Diabetes Type 1',
      description: 'Diabetes mellitus type 1 (disorder)',
      snomedcode: 46635009,
      icdcode: 'E109',
      icddescription: 'Type 1 diabetes mellitus without complications',
    },
  },
  {
    '44054006': {
      title: 'endo.Diabetes Type 2',
      description: 'Diabetes mellitus type 2 (disorder)',
      snomedcode: 44054006,
      icdcode: 'E119',
      icddescription: 'Type 2 diabetes mellitus without complications',
    },
  },
  {
    '80394007': {
      title: 'dia.Hyperglycemia',
      description: 'Hyperglycemia (disorder)',
      snomedcode: 80394007,
      icdcode: 'R739',
      icddescription: 'Hyperglycemia, unspecified',
    },
  },
  {
    '3716002': {
      title: 'endo.Goiter (Thyroid Enlargement)',
      description: 'Goiter (disorder)',
      snomedcode: 3716002,
      icdcode: 'E049',
      icddescription: 'Nontoxic goiter, unspecified',
    },
  },
  {
    '353295004': {
      title: 'endo.Graves Disease',
      description: "Graves' disease (disorder)",
      snomedcode: 353295004,
      icdcode: 'E0500',
      icddescription:
        'Thyrotoxicosis with diffuse goiter without thyrotoxic crisis or storm',
    },
  },
  {
    '40930008': {
      title: 'endo.Hypothyroidism',
      description: 'Hypothyroidism (disorder)',
      snomedcode: 40930008,
      icdcode: 'E039',
      icddescription: 'Hypothyroidism, unspecified',
    },
  },
  {
    '34486009': {
      title: 'endo.Hyperthyroidism',
      description: 'Hyperthyroidism (disorder)',
      snomedcode: 34486009,
      icdcode: 'E0590',
      icddescription:
        'Thyrotoxicosis, unspecified without thyrotoxic crisis or storm',
    },
  },
]

export const DiabetesDropDownValues = constructPatientDemographicObject(
  DiabetesCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
