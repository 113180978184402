import { useQuery } from '@tanstack/react-query'

import { getDiagnosis } from '../../api/api-lib-typed'

export const useGetDiagnosis = ({
  diagnosisId,
  patientId,
}: {
  diagnosisId: string | undefined
  patientId: string | undefined
}) => {
  return useQuery(
    ['DIAGNOSIS', diagnosisId, patientId],
    () => {
      if (!diagnosisId || !patientId) {
        return undefined
      }
      return getDiagnosis({ diagnosisId, patientId })
    },
    {
      enabled: !!(diagnosisId && patientId),
    }
  )
}
