import React from 'react'

import Icon from '@ant-design/icons'
import { differenceInMinutes, format, getMinutes } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { View, Views } from 'react-big-calendar'

import { useProviderDetails } from '../../../../hooks/useProviderInfo'
import ClipboardOutlined from '../../../../images/Icons/ClipboardOutlined'
import { Event, ScheduledEvent } from '../../../../shared-types'
import { getPatientUrl } from '../../../../shared/Helpers/utils'
import { Col, Row } from '../../../BaseComponents'
import { DEFAULT_EVENT_COLOR } from '../../constants'
import { convertHexToRGBA } from '../../helpers'

import './EventItem.scss'

export interface EventItemProps {
  colorMap: Map<string, string>
  currentView: View
  event: Event
  patientList: Map<string, string>
  timezone: string
  isDev?: boolean
  roomLocationName?: string
}

export default function EventItem({
  colorMap,
  currentView,
  event = {},
  isDev = false,
  patientList,
  roomLocationName = '',
  timezone,
}: EventItemProps) {
  const {
    allDay = false,
    showTimespan = false,
    title = '',
    resource = {},
  } = event
  const start = new Date(event?.start ?? '')
  const end = new Date(event?.end ?? '')
  const { data: providerDetails } = useProviderDetails()
  /*
    clarification:
      If allDay is true, then the event is a day or longer than a day.
      If showTimespan is false, then don't show the timespan
      (likely means that allDay is true, but not always)
  */

  const {
    OsmindPatientId: patientId,
    AppointmentType: appointmentType,
    Providers: providers,
  } = resource as ScheduledEvent

  const hasAppointmentColor = Boolean(colorMap.get(appointmentType))
  const sliverColor = hasAppointmentColor
    ? colorMap.get(appointmentType)
    : DEFAULT_EVENT_COLOR
  const backgroundColor = convertHexToRGBA(
    sliverColor || DEFAULT_EVENT_COLOR,
    0.1
  )
  const hoverColor = convertHexToRGBA(sliverColor || DEFAULT_EVENT_COLOR, 0.15)

  const getNumberOfLines = () => {
    if (allDay) return 1

    const eventLengthInMinutes = Math.abs(differenceInMinutes(end, start))

    const numberOfLines = Math.floor(eventLengthInMinutes / 30)
    // limit number of lines to 3 before it truncates
    return numberOfLines > 3 ? 5 : numberOfLines
  }

  const numberOfLines = getNumberOfLines()

  let startTime = ''
  let endTime = ''
  if (event?.start && event?.end) {
    const startFormat = getMinutes(start) ? 'h:mma' : 'ha'
    const endFormat = getMinutes(end) ? 'h:mma' : 'ha'
    startTime = format(utcToZonedTime(start, timezone), startFormat)
    endTime = format(utcToZonedTime(end, timezone), endFormat)
  }

  const shouldStartOnly = numberOfLines <= 1 && !allDay
  const timeSpan = shouldStartOnly ? startTime : `${startTime} - ${endTime}`
  const patientName = patientId ? patientList.get(patientId) || '' : ''
  const shouldDisplayDetail = currentView === Views.DAY && numberOfLines > 3
  const shouldDisplayTimeSpanRow = numberOfLines > 3

  const eventInfoChunks = [patientName]
  if (shouldDisplayDetail) {
    ;[appointmentType, providers, roomLocationName].forEach((chunk) => {
      eventInfoChunks.push(chunk)
    })
  } else if (!shouldDisplayTimeSpanRow) {
    eventInfoChunks.unshift(timeSpan)
  }

  let eventInfoText = showTimespan && eventInfoChunks.filter(Boolean).join(', ')

  let eventBody = <span />

  if (numberOfLines <= 1) {
    eventInfoText = eventInfoText ? `, ${eventInfoText}` : eventInfoText
    eventBody = (
      <>
        <span className="emphasis">{title}</span>
        {eventInfoText}
      </>
    )
  } else {
    eventBody = (
      <>
        <Row className={`emphasis event-text ${patientId && 'with-icon'}`}>
          {title}
        </Row>
        {shouldDisplayTimeSpanRow && (
          <Row className="event-text">{timeSpan}</Row>
        )}
        <Row className="event-text">{eventInfoText}</Row>
      </>
    )
  }

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (isDev) return
    window
      .open(getPatientUrl(patientId, providerDetails?.providerId), '_blank')
      ?.focus()
  }

  const colClassName = `event-cell-content-container${
    allDay ? ' all-day-event' : ''
  }`

  const wrapperStyles = {
    '--hover-color': hoverColor,
  } as any
  const sliverStyles = {
    '--sliver-color': sliverColor,
  } as any
  const bodyStyles = {
    '--event-color': backgroundColor,
  } as any

  return (
    <Col className={colClassName}>
      <Row style={wrapperStyles} className="event-body-wrapper">
        {hasAppointmentColor && (
          <Col
            className="sliver-event event-sliver-color"
            style={sliverStyles}
          />
        )}
        <Col
          className={`event-body event-body-color truncate-line ${
            !hasAppointmentColor && 'body-rounded'
          }`}
          style={bodyStyles}
        >
          {eventBody}
          {patientId && (
            <span style={bodyStyles}>
              <Icon
                className="schedule-icon"
                onClick={handleClick}
                component={ClipboardOutlined}
              />
            </span>
          )}
        </Col>
      </Row>
    </Col>
  )
}
