import React from 'react'

import { Button as AntDButton, ButtonProps as AntDButtonProps } from 'antd'

import { TestId } from '../../shared-types'

export type ButtonProps = AntDButtonProps & TestId

const Button: React.FC<ButtonProps> = ({ children, testId, ...props }) => {
  return (
    <AntDButton data-testid={testId} {...props}>
      {children}
    </AntDButton>
  )
}

export default Button
