import React from 'react'

import { DatePicker, DatePickerProps } from 'antd'

import { TestId } from '../../shared-types'

// TODO: style DOB to extend full width
const DayPicker = ({ testId, ...props }: DatePickerProps & TestId) => {
  return <DatePicker data-testid={testId} {...props} />
}

export default DayPicker
