import React, { useEffect, useMemo } from 'react'

import { useFormikContext } from 'formik'

import InlineField from './InlineField'
import { PROVIDER } from './field-constants'
import { useProviders } from './hooks'
import { toEinInputValue, toSsnInputValue } from './mappers'
import { ClaimForm, TAX_TYPE } from './types'
import { getNewProviderValuesOnProviderChange } from './utils'

export type ProviderType = 'renderingProvider' | 'supervisingProvider'
export type ProviderProps = {
  providerType: ProviderType
}
const getActiveProviderFieldsByTaxType = (taxType: TAX_TYPE | null) => {
  switch (taxType) {
    case TAX_TYPE.EIN:
      return PROVIDER.filter((field) => field.name !== 'providerSsn')
    case TAX_TYPE.SSN:
      return PROVIDER.filter((field) => field.name !== 'providerEin')
    default:
      return PROVIDER.filter(
        (field) => !['providerEin', 'providerSsn'].includes(field.name)
      )
  }
}

// shared provider fields between rendering provider and supervising provider sections
const Provider: React.FC<ProviderProps> = ({ providerType }) => {
  const {
    data: { providerOptions, providersById },
  } = useProviders()

  const { values, touched, setValues } = useFormikContext<ClaimForm>()
  const {
    [providerType]: { providerId, taxType },
  } = values

  const providerNameTouched = !!touched[providerType]?.providerId
  const taxTypeTouched = !!touched[providerType]?.taxType

  const activeFields = useMemo(() => {
    const activeProviderFields = getActiveProviderFieldsByTaxType(taxType)

    return activeProviderFields.map((field) => {
      // attach full provider field name, example: "renderingProvider.providerId"
      const newField = {
        ...field,
        name: `${providerType}.${field.name}`,
      }
      // populate provider dropdown list
      if (field.name === 'providerId') {
        newField.options = providerOptions
      }
      return newField
    })
  }, [taxType, providerOptions, providerType])

  // reset all values when user picks a different provider
  useEffect(() => {
    if (!providerNameTouched) {
      return
    }
    setValues({
      ...values,
      [providerType]: getNewProviderValuesOnProviderChange(
        values[providerType],
        providerId,
        providersById
      ),
    })
  }, [providerId, providerNameTouched])

  // reset EIN and SSN values when user picks a different tax type
  useEffect(() => {
    if (!taxTypeTouched) {
      return
    }
    const selectedProvider = providerId ? providersById[providerId] : null
    const isSsn = taxType === TAX_TYPE.SSN
    const isEin = taxType === TAX_TYPE.EIN
    setValues({
      ...values,
      [providerType]: {
        ...values[providerType],
        providerEin: isEin ? toEinInputValue(selectedProvider?.ein) : null,
        providerSsn: isSsn
          ? toSsnInputValue(selectedProvider?.ssnLastFour)
          : null,
        providerSsnFirstFive: isSsn ? selectedProvider?.ssnFirstFive : null,
        providerSsnLastFour: isSsn ? selectedProvider?.ssnLastFour : null,
      },
    })
  }, [taxType, taxTypeTouched])

  return (
    <div data-testid={`${providerType}-provider-fields`}>
      {activeFields.map((el) => (
        <InlineField {...el} key={el.name} />
      ))}
    </div>
  )
}

export default Provider
