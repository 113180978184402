import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined'
import { Modal, Popover, Skeleton } from 'antd'
import AntDTag from 'antd/lib/tag'
import Typography from 'antd/lib/typography'

import { bulkUpdatePatientTag, deletePatientTag } from '../../api/tags'
import SkeletonLoadingTransition from '../../components/Animation/SkeletonLoadingTransition'
import useQueryString from '../../hooks/useQueryString'
import { createTagMutation, useTagsData } from '../../hooks/useTags'
import {
  PatientProfileEvents,
  trackPatientProfileEvent,
} from '../../libs/freshpaint/patientProfileEvents'
import { notification } from '../../libs/notificationLib'
import { Space, Tooltip } from '../BaseComponents'
import AddTagPopover from './AddTagPopover'
import { PatientTag } from './_types'

import styles from './_shared.module.scss'

const { confirm } = Modal
interface PatientTagsProps {
  displayLabel?: boolean
  patientId: string
  tagsAlreadyAttached: PatientTag[]
  isLoading: boolean
}

export default function PatientTags({
  displayLabel,
  patientId,
  tagsAlreadyAttached,
  isLoading,
}: PatientTagsProps) {
  const query = useQueryString()
  const providerId = query.get('providerId') ?? ''
  const [addTagPopoverOpen, toggleAddTagPopover] = useState<boolean>(false)
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [isTagSaving, setIsTagSaving] = useState<boolean>(false)
  const [isOkayDisabled, setIsOkayDisabled] = useState<boolean>(true)
  const [dispatchConfirm, setDispatchConfirm] = useState<boolean>(false)
  const [isClosePopoverOpen, setIsClosePopoverOpen] = useState<boolean>(false)
  const [patientTags, setPatientTags] =
    useState<PatientTag[]>(tagsAlreadyAttached)
  const [overflowPatientTags, setOverflowPatientTags] = useState<PatientTag[]>(
    []
  )
  const [displayTags, setDisplayTags] = useState<PatientTag[]>([])

  useEffect(() => {
    setDisplayTags(tagsAlreadyAttached)
    setPatientTags(tagsAlreadyAttached)
  }, [tagsAlreadyAttached])

  const { mutate: createNewTag, isLoading: isLoadingTagMutation } =
    createTagMutation()
  const { data: tagsData = [] } = useTagsData()

  const closeAddTagPopover = useCallback(() => {
    toggleAddTagPopover(false)
  }, [])

  const createNewTagIfNotExist = useCallback(
    (value: string) => {
      const existingTags = tagsData.map((tag) => tag.label)

      if (!existingTags.includes(value)) {
        createNewTag({ label: value })
      }
    },
    [tagsData, createNewTag]
  )

  function areEqual(array1: string[], array2: string[]) {
    return (
      array1.length === array2.length &&
      array1.every((element) => array2.includes(element))
    )
  }

  const handleSelect = useCallback(
    (value: string) => {
      const trimmedValue = value.trim()
      if (trimmedValue.length > 100) {
        notification('Tag cannot exceed 100 characters.', 'failure')
      } else {
        if (trimmedValue !== '') {
          createNewTagIfNotExist(trimmedValue)
          setSelectedTags([...selectedTags, trimmedValue])
          setIsOkayDisabled(
            areEqual(
              patientTags.map(({ tag: { label } }) => label),
              [...selectedTags, trimmedValue]
            )
          )
        }
      }
    },
    [selectedTags, createNewTagIfNotExist]
  )

  const handleDeselect = useCallback(
    (value: unknown) => {
      setSelectedTags(selectedTags.filter((tag) => tag !== value))
      setIsOkayDisabled(
        areEqual(
          patientTags.map(({ tag: { label } }) => label),
          selectedTags.filter((tag) => tag !== value)
        )
      )
    },
    [selectedTags]
  )

  useEffect(() => {
    if (dispatchConfirm) {
      if (
        areEqual(
          patientTags.map(({ tag: { label } }) => label),
          selectedTags
        )
      ) {
        closeAddTagPopover()
        setDispatchConfirm(false)
      } else {
        setIsClosePopoverOpen(true)
        setDispatchConfirm(false)
      }
    }
  }, [dispatchConfirm])

  useEffect(() => {
    if (isClosePopoverOpen) {
      confirm({
        title: 'Unsaved changes',
        centered: true,
        content:
          'Are you sure you want to close this window? Any unsaved changes will be lost.',
        onOk() {
          closeAddTagPopover()
          setIsOkayDisabled(true)
          setIsClosePopoverOpen(false)
        },
        onCancel() {
          toggleAddTagPopover(true)
          setIsClosePopoverOpen(false)
        },
      })
    }
  }, [isClosePopoverOpen])

  const openAddTagPopover = useCallback(() => {
    if (addTagPopoverOpen) {
      setDispatchConfirm(true)
    } else {
      setSelectedTags(patientTags.map((patientTag) => patientTag.tag.label))
      toggleAddTagPopover(true)
    }
  }, [patientTags, addTagPopoverOpen])

  const handleSubmit = useCallback(async () => {
    setIsTagSaving(true)

    try {
      const patientTagsToAttach = selectedTags.filter(
        // if a selected tag is not already attached, attach it
        (selectedTag) =>
          !patientTags
            .map((patientTag) => patientTag.tag.label)
            .includes(selectedTag)
      )
      const tagIdsToAdd = tagsData
        .filter((tag) => patientTagsToAttach.includes(tag.label))
        .map((tag) => tag.id)
      const tagIdsToRemove = patientTags
        .filter(
          // if any attached tags aren't include in the selected tags, detach them
          (attachedTag) => !selectedTags.includes(attachedTag.tag.label)
        )
        .map((patientTag) => patientTag.tagId)
      const updatedPatientTags = await bulkUpdatePatientTag({
        patientId,
        tagIdsToAdd,
        tagIdsToRemove,
      })
      trackPatientProfileEvent(PatientProfileEvents.UPDATE_TAGS, {
        patientId,
        clinicId: providerId,
        tagCount: selectedTags.length,
      })
      setPatientTags(updatedPatientTags)
      setDisplayTags(updatedPatientTags)
      setIsOkayDisabled(true)
    } catch (err) {
      notification(
        'Unable to add all the listed tags to this patient. The patient may already have some of the tags added.',
        'failure'
      )
    }

    setIsTagSaving(false)
    closeAddTagPopover()
  }, [patientId, tagsData, selectedTags])

  const ROWS_HEIGHT = 90
  const ref = useRef<HTMLDivElement>(null)
  const height = () => {
    return ref.current !== null ? ref.current.getBoundingClientRect().height : 0
  }

  useLayoutEffect(() => {
    if (height() > ROWS_HEIGHT) {
      setDisplayTags(displayTags.slice(0, displayTags.length - 1))
      setOverflowPatientTags(patientTags.slice(displayTags.length - 1))
    }
  })

  useEffect(() => {
    setSelectedTags(patientTags.map((patientTag) => patientTag.tag.label))
  }, [patientTags, tagsAlreadyAttached])

  const overflow = patientTags.length - displayTags.length
  const content = (
    <div>
      {overflowPatientTags.map((item, _index) => (
        <div className={styles.overflowList}>• {item.tag.label}</div>
      ))}
    </div>
  )

  const loadedComponent = (
    <div style={{ display: 'flex' }}>
      <div ref={ref} className={styles.patientTagsContainer}>
        {displayLabel && (
          <Typography.Text className={styles.sectionLabel}>
            Tags:
          </Typography.Text>
        )}
        {displayTags.map((patientTag) => {
          const isLongTag = patientTag.tag.label.length > 40
          const tagElem = (
            <AntDTag
              color="blue"
              data-testid={`add-tag-to-${patientId}`}
              className={`${styles.tag} ${styles.tagBorder}`}
              key={`${patientId}-${patientTag.tagId}`}
              onClose={() =>
                deletePatientTag({ patientId, tagId: patientTag.tagId }).then(
                  (response: PatientTag) => {
                    const tags = patientTags.filter(
                      (patientTag) => patientTag.tagId !== response.tagId
                    )
                    setPatientTags(tags)
                    setDisplayTags(tags)
                  }
                )
              }
              closeIcon={<CloseOutlined className={styles.customCloseButton} />}
              closable
            >
              {isLongTag
                ? `${patientTag.tag.label.slice(0, 40)}...`
                : patientTag.tag.label}
            </AntDTag>
          )
          return isLongTag ? (
            <Tooltip title={patientTag.tag.label} key={patientTag.tag.label}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          )
        })}
        {overflow > 0 && (
          <Popover
            color="black"
            content={content}
            data-testid={`add-tag-to-${patientId}`}
            placement="bottom"
            trigger={['focus', 'hover']}
            className={`${styles.tagCustom} ${styles.tagPlusOverride}`}
          >
            <div tabIndex={0} className={styles.overflowText}>
              {overflow} more tags...
            </div>
          </Popover>
        )}
        <AddTagPopover
          onToggle={(val: boolean) => toggleAddTagPopover(val)}
          isOkayDisabled={isOkayDisabled || isClosePopoverOpen}
          isCancelDisabled={isClosePopoverOpen}
          popoverOpen={addTagPopoverOpen}
          selectedTags={selectedTags}
          tagsAlreadyAttached={patientTags}
          isLoading={isTagSaving || isLoadingTagMutation}
          onPopoverClose={() => setDispatchConfirm(true)}
          onPopoverSubmit={handleSubmit}
          onTagSelect={(value) => handleSelect(value as string)}
          onTagDeselect={handleDeselect}
          onPopoverOpen={openAddTagPopover}
        />
      </div>
    </div>
  )

  return (
    <div style={{ position: 'relative', width: '100%', minHeight: 24 }}>
      <SkeletonLoadingTransition
        isLoading={isLoading}
        loadedComponent={loadedComponent}
        skeletonComponent={
          <Space size={4}>
            <Skeleton.Button style={{ width: 75, height: 22 }} active />
            <Skeleton.Button style={{ width: 75, height: 22 }} active />
          </Space>
        }
      />
    </div>
  )
}
