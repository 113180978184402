import {
  FieldStyling,
  GridLayout,
  TemplateFields,
} from '../../../components/Forms/FormMachine/types'

export const styling = {
  valueClassName: 'field-value',
} as FieldStyling

export const gridLayout = [
  { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
] as GridLayout

export const template = [
  {
    columnSizes: gridLayout[0],
    fieldType: 'textarea',
    id: 'developmentalHistoryNotes',
    placeholder: 'Developmental history details, etc.',
  },
] as TemplateFields
