export enum ComparisonOperators {
  ABOVE = '>',
  ABOVE_OR_EQUAL = '>=',
  BELOW = '<',
  BELOW_OR_EQUAL = '<=',
}

export interface AllSurveySettings extends ConfigurableSurveySettings {
  readonly name: string
  readonly isRecurring: boolean
  readonly key: string
  readonly upperScoreRange: number
  readonly lowerScoreRange: number
  readonly scoreGuide?: string[]
  readonly comparisonOperator: ComparisonOperators
  readonly recommendedThreshold: number
}

export interface ConfigurableSurveySettings {
  /* Survey sending preferences */
  sendingEnabled: boolean
  sendInterval: number

  /* Survey alert preferences */
  alertingEnabled: boolean
  alertThreshold: number
}

export interface AddSurveyMenuItemData {
  key: string
  text: string
}
