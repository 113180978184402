import React from 'react'

import _isEqual from 'lodash/isEqual'
import { Button, Col, Row } from 'react-bootstrap'

import FormMachine from '../FormMachine'
import { StoredFields, TemplateFields } from '../types'
import { MultipleEditFieldProps } from './types'

function generateForm(
  handleFieldChange: any,
  storedFields: any,
  styling: any,
  template: TemplateFields
) {
  function handleCheckChange(id: string, key: number, isChecked = false) {
    const field = template.find((field) => field.id === id)
    if (typeof field !== 'undefined') {
      const type = field.fieldType
      const event = { target: {} }
      if (type === 'units' && typeof field.units !== 'undefined') {
        const units = field.units
        event.target = {
          id: `${id}Unit`,
          type: 'radio',
          value: units[key],
        }
      } else if (field.options) {
        const options = field.options
        if (type === 'radio' || type === 'yesNo') {
          event.target = {
            id: id,
            type: 'radio',
            value: options[key],
          }
        } else if (type === 'checkbox') {
          let selectedOptions = storedFields[id] || []
          if (!isChecked) {
            selectedOptions.push(options[key])
          } else {
            selectedOptions = selectedOptions.filter(
              (choice: string) => choice !== options[key]
            )
          }
          event.target = {
            id: id,
            type: 'checkbox',
            checked: selectedOptions,
          }
        }
      }
      handleFieldChange(event)
    }
  }

  return (
    <FormMachine
      handleCheckChange={handleCheckChange}
      handleFieldChange={handleFieldChange}
      isEditing
      storedFields={storedFields}
      styling={styling}
      template={template}
    />
  )
}

export default function MultipleEditField(props: MultipleEditFieldProps) {
  const {
    fields = [],
    handleFieldChange,
    handleMultipleSubmit,
    id,
    styling,
    templateFields,
  } = props

  if (!Array.isArray(fields)) return null

  function deleteField(index: number) {
    handleMultipleSubmit(index, id)
  }

  const fieldsList = fields.map((field: StoredFields, index: number) => {
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      fields[index][event.target.id] = event.target.value
      const newEvent = {
        target: {
          id: id,
          type: '',
          value: fields,
        },
      }

      // modifying above so it works with field change
      handleFieldChange(
        newEvent as unknown as React.ChangeEvent<HTMLInputElement>
      )
    }

    const deleteButton = (
      <Button
        id={props.id + 'Delete'}
        bsPrefix="plain-button info emphasis-light"
        onClick={() => {
          deleteField(index)
        }}
      >
        <i className="fa fa-trash" aria-hidden="true" />
        &nbsp;Delete
      </Button>
    )

    return (
      <Row key={`${id}-${index}-edit`}>
        <Col xs={12} md={12}>
          {generateForm(handleChange, field, styling, templateFields)}
        </Col>
        <Col
          className="center bottom-line"
          style={{ paddingBottom: '14px' }}
          xs={12}
          md={12}
        >
          {deleteButton}
        </Col>
      </Row>
    )
  })

  return (
    <Col style={{ marginBottom: '14px' }} xs={12} xl={12}>
      {fieldsList}
    </Col>
  )
}
