/*
  Hook and helper methods for granular graph data filtering
*/
import { useCallback, useEffect, useState } from 'react'

import { notification } from '../../../libs/notificationLib'
import {
  ActiveGraphFilter,
  GraphFilterConfigs,
  GraphFilterOptions,
} from '../types'

export const generateFilterOptions = async (
  graphFilterConfigs: GraphFilterConfigs[]
): Promise<GraphFilterOptions> => {
  const filterOptions: GraphFilterOptions = {}

  graphFilterConfigs.forEach(async (filterConfig) => {
    if (filterConfig?.fetchFilterOptions) {
      try {
        const fetchedFiltersOptions = await filterConfig.fetchFilterOptions()
        filterOptions[filterConfig.filterKey] = {
          filterName: filterConfig.filterName,
          minWidth: filterConfig.minWidth,
          options: fetchedFiltersOptions,
        }
      } catch (err) {
        const errMsg = `Failed to retrieve "${filterConfig.filterKey}" filters.`
        notification(errMsg, 'error')
        console.error(errMsg, err)
      }
    } else if (filterConfig.localFilterOptions.length) {
      filterOptions[filterConfig.filterKey] = {
        filterName: filterConfig.filterName,
        minWidth: filterConfig.minWidth,
        options: filterConfig.localFilterOptions,
      }
    }
  })

  return filterOptions
}

export const filterGraphDataHelper = (
  prePlottedData: Array<{ [key: string]: any }>,
  activeFilters: ActiveGraphFilter
) => {
  const activeFilterKeys = Object.keys(activeFilters)

  if (!activeFilterKeys.length) {
    return prePlottedData
  } else {
    return prePlottedData.filter((data) => {
      const filterChecks: boolean[] = activeFilterKeys.map((filterKey) => {
        if (data[filterKey] === undefined || data[filterKey] === null) {
          // Prevent missing data edge case
          return false
        }
        if (Array.isArray(data[filterKey])) {
          // If array, it will pass if 1 of the values in array matches the active filter value
          return data[filterKey].includes(activeFilters[filterKey])
        } else {
          return data[filterKey] === activeFilters[filterKey]
        }
      })
      /* Using "OR" filter logic -- if just one of the filterChecks pass, the item will be categorize as a match */
      return filterChecks.includes(true)
    })
  }
}

export default function useGraphFilters(
  graphFilterConfigs: GraphFilterConfigs[]
) {
  const [activeGraphFilters, setActiveGraphFilters] =
    useState<ActiveGraphFilter>({})
  const [graphFilterOptions, setGraphFilterOptions] =
    useState<GraphFilterOptions>({})

  const initialFetchFilters = useCallback(async () => {
    if (!graphFilterConfigs.length) {
      setGraphFilterOptions({})
    }
    try {
      const filterOptions = await generateFilterOptions(graphFilterConfigs)
      setGraphFilterOptions(filterOptions)
    } catch (err) {
      const errMsg = 'Error retrieving data for graph filters.'
      notification(errMsg, 'error')
      console.error(errMsg, err)
    }
    setActiveGraphFilters({})
  }, [graphFilterConfigs])

  useEffect(() => {
    initialFetchFilters()
  }, [graphFilterConfigs])

  return {
    graphFilterOptions,
    activeGraphFilters,
    setActiveGraphFilters,
  }
}
