import { API } from 'aws-amplify'

type HealGorillaURLRequest = (
  patientId: string,
  accessToken: string,
  sendInsurance: 'yes' | 'no'
) => Promise<string>

type SaveSuccessfulOrderBody = {
  responseCode: string | null
  responseMessage: string | null
  orderId: string | null
}

/*
HEALTH GORILLA ENDPOINTS
*/
export const getHealthGorillaAccessToken = async () => {
  return API.get('labs', `/labs/health-gorilla/oauth-token`, {})
}

export const getHealthGorillaURL: HealGorillaURLRequest = (
  patientId,
  accessToken,
  billToInsurance
) => {
  return API.get(
    'labs',
    `/labs/health-gorilla/order-url/${patientId}/${accessToken}/${billToInsurance}`,
    {}
  )
}

export const getLabsResults = async (patientId: string) => {
  return API.get('labs', `/labs/health-gorilla/results/${patientId}`, {})
}

export const saveMissingDemographicData = async (
  patientId: string,
  data: any
) => {
  return API.put(
    'labs',
    `/labs/health-gorilla/save-missing-data/${patientId}`,
    { body: data }
  )
}

export const saveSuccessfulOrder = async (
  labOrderId: string,
  data: SaveSuccessfulOrderBody
) => {
  return API.put(
    'labs',
    `/labs/health-gorilla/save-successful-order/${labOrderId}`,
    { body: data }
  )
}

export const saveViewOrder = async (id: string) => {
  return API.put('labs', `/labs/health-gorilla/view/${id}`, {})
}

export const reviewLabOrder = async (id: string) => {
  return API.put(
    'labs',
    `/labs/health-gorilla/acknowledge-lab-results/${id}`,
    {}
  )
}

export const getReportLabsResults = async (start: string, end: string) => {
  return API.get(
    'labs',
    `/labs/health-gorilla/reports?start=${start}&end=${end}`,
    {}
  )
}

export const getReadyForReviewBoolean = async () => {
  return API.get('labs', `/labs/health-gorilla/review`, {})
}
