import { MetricObject } from '../BaseComponents/Metrics'

export const notesAggregateMetrics: MetricObject[] = [
  { title: 'Unsigned Notes', dataSource: 33 },
  { title: 'Total Notes', dataSource: 125 },
]

export const METRICS_HEADER_TOTAL_APPOINTMENTS = 'Total appointments'
export const METRICS_HEADER_UNSIGNED_NOTES = 'Unsigned notes'
export const METRICS_HEADER_TOTAL_NOTES = 'Total notes'
export const METRICS_HEADER_TOTAL_SURVEYS = 'Total surveys'
export const METRICS_HEADER_TOTAL_LABS = 'Total labs'
