import React, { useEffect, useState } from 'react'

import moment from 'moment'
import 'moment-timezone'
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  ToggleButton,
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-dates/initialize'

import './DatePicker.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dates/lib/css/_datepicker.css'

const dateFormats = [{ format: 'MM/YYYY' }, { format: 'YYYY' }]

export default function ShowSuicideAttempts(props) {
  const [description, setDescription] = useState(props.Description || '')
  const [startDate, setStartDate] = useState(props.StartDate || '')
  const [endDate, setEndDate] = useState(props.EndDate || '')

  const [diagnosisOnsetDateFormat, setDiagnosisOnsetDateFormat] = useState(
    props.DateFormat ? props.DateFormat : 'MM/YYYY'
  )
  const [diagnosisOnsetDate, setDiagnosisOnsetDate] = useState(null)

  /*   useEffect(() => {
    setDescription(props.Description || '')
    setDateRange(props.DateRange || { StartDate: '', EndDate: '' })
    setDiagnosisOnsetDateFormat(props.DateFormat ? props.DateFormat : 'MM/YYYY')
  }, [props.Description, props.DateRange, props.DateFormat]) */

  useEffect(() => {
    const fields = {
      description: description,
      startDate: startDate,
      endDate: endDate,
      dateFormat: diagnosisOnsetDateFormat,
    }
    props.saveAttempt(fields, props.index)
  }, [description, startDate, endDate, diagnosisOnsetDateFormat])

  function handleDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        setDiagnosisOnsetDate(date)
        let newOnsetDate = diagnosisOnsetDate
        newOnsetDate = date.toDate()
        setStartDate(newOnsetDate)
      } else {
        const newOnsetDate = moment(date, diagnosisOnsetDateFormat)
        setDiagnosisOnsetDate(newOnsetDate)
        setStartDate(date)
      }
    } else {
      setStartDate('')
    }
  }

  function handleEndDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        setDiagnosisOnsetDate(date)
        let newOnsetDate = diagnosisOnsetDate
        newOnsetDate = date.toDate()
        setEndDate(newOnsetDate)
      } else {
        const newOnsetDate = moment(date, diagnosisOnsetDateFormat)
        setDiagnosisOnsetDate(newOnsetDate)
        setEndDate(date)
      }
    } else {
      setEndDate('')
    }
  }

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} xs={6} sm={6} md={3} lg={3} xl={3}>
          <Row style={{ width: 'min-content' }}>
            <Col>
              <Form.Label style={{ fontWeight: 'bold' }}>
                {props.selfHarm ? 'Start' : 'Date'}
              </Form.Label>
            </Col>
            <Col>
              <ButtonGroup toggle size="sm">
                {dateFormats.map((btn, idx) => (
                  <ToggleButton
                    className="date-format-selector"
                    variant="primary"
                    key={idx}
                    type="radio"
                    name="radio"
                    value={btn.format}
                    style={{ fontSize: '10px' }}
                    checked={diagnosisOnsetDateFormat === btn.format}
                    onChange={(e) =>
                      setDiagnosisOnsetDateFormat(e.currentTarget.value)
                    }
                  >
                    {btn.format}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Col>
          </Row>

          <Form.Group controlId="DiagnosisOnsetDatePicker">
            {diagnosisOnsetDateFormat === 'MM/YYYY' && (
              <DatePicker
                className="date-input"
                selected={startDate === '' ? '' : new Date(startDate)}
                placeholderText={diagnosisOnsetDateFormat}
                onChange={(date) => handleDate(date)}
                maxDate={new Date()}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            )}

            {diagnosisOnsetDateFormat === 'YYYY' && (
              <DatePicker
                className="date-input"
                selected={startDate === '' ? '' : new Date(startDate)}
                placeholderText={diagnosisOnsetDateFormat}
                onChange={(date) => handleDate(date)}
                maxDate={new Date()}
                dateFormat="yyyy"
                showYearPicker
              />
            )}
          </Form.Group>
        </Form.Group>
        {props.selfHarm && (
          <Form.Group as={Col} xs={6} sm={6} md={2} lg={2} xl={2}>
            <Form.Label className="patient">End</Form.Label>
            {diagnosisOnsetDateFormat === 'MM/YYYY' && (
              <DatePicker
                className="date-input"
                selected={endDate === '' ? '' : new Date(endDate)}
                placeholderText={diagnosisOnsetDateFormat}
                onChange={(date) => handleEndDate(date)}
                maxDate={new Date()}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            )}

            {diagnosisOnsetDateFormat === 'YYYY' && (
              <DatePicker
                className="date-input"
                selected={endDate === '' ? '' : new Date(endDate)}
                placeholderText={diagnosisOnsetDateFormat}
                onChange={(date) => handleEndDate(date)}
                maxDate={new Date()}
                dateFormat="yyyy"
                showYearPicker
              />
            )}
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={props.selfHarm ? 8 : 9}
          lg={props.selfHarm ? 8 : 9}
          xl={props.selfHarm ? 8 : 9}
        >
          <Form.Label className="patient">Description</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="textarea"
            rows={1}
            value={description}
            disabled={props.disabled}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          style={{ alignSelf: 'center', textAlign: 'center' }}
        >
          <Button
            bsPrefix="center plain-button info button-label"
            onClick={() => {
              props.deleteAttempt(props.index)
            }}
          >
            <i className="fa fa-trash" aria-hidden="true" />
            &nbsp;Delete
          </Button>
        </Form.Group>
      </Form.Row>
      <hr />
    </>
  )
}
