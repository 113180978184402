import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { loadProviderInfo } from '../../api/api-lib'
import { ProviderHomePageData } from '../../shared-types'

export enum ProviderQueries {
  PROVIDER_DETAILS = 'PROVIDER_DETAILS',
}

export const useProviderDetails = (): UseQueryResult<ProviderHomePageData> => {
  return useQuery<ProviderHomePageData>([ProviderQueries.PROVIDER_DETAILS], {
    queryFn: loadProviderInfo,
    retry: false,
  })
}
