import React, { useEffect, useState } from 'react'

import { CheckboxProps, FormInstance, Row } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { Checkbox } from '../../BaseComponents'

import styles from './_shared.module.scss'

interface SubformProps {
  label?: string
  disabled?: boolean
  value?: string
}

type SubFormType = (props: SubformProps) => React.ReactNode

interface CheckboxWithSwitchProps extends CheckboxProps {
  checkboxGroupName?: string
  label?: string
  index: number
  disabled?: boolean
  form?: FormInstance
  onSubFormClose?: () => void
  showFirst?: boolean
  subForm: SubFormType
}

interface CheckboxWithFormProps extends CheckboxProps {
  checkboxGroupName?: string
  disabled?: boolean
  form?: FormInstance
  onSubFormClose?: () => void
  options: { label: string; value: string }[]
  showFirst?: boolean
  subForm: SubFormType
}

const CheckboxWithSwitch = ({
  disabled,
  label = '',
  index = 0,
  onSubFormClose,
  showFirst = false,
  subForm,
  onChange,
  ...restCheckboxProps
}: CheckboxWithSwitchProps) => {
  const [showForm, setShowForm] = useState<boolean>(false)
  const key = `checkbox-${label.trim().replace(' ', '')}-${index}`
  const shouldHide = showFirst && !!index

  useEffect(() => {
    if (onSubFormClose && !showForm) {
      onSubFormClose()
    }
  }, [showForm])

  const handleChangeAndExpandSubForm = (event: CheckboxChangeEvent) => {
    // Expands sub form and call `onChange` prop if one was passed
    setShowForm(event.target.checked)
    if (onChange) onChange(event)
  }

  if (shouldHide) {
    // hide from view--only created to make sure antd keeps track of changes
    return <Checkbox key={key} style={{ display: 'none' }} />
  }
  return (
    <Row key={key} className={styles.checkboxPadding}>
      <Checkbox
        {...restCheckboxProps}
        disabled={disabled}
        onChange={handleChangeAndExpandSubForm}
      >
        {label}
      </Checkbox>
      {showForm && (
        <div className={styles.defaultBlueBackground}>
          {subForm({ label, disabled, value: restCheckboxProps.value })}
        </div>
      )}
    </Row>
  )
}

const CheckboxesWithForm = ({
  checkboxGroupName,
  disabled,
  form,
  options,
  onSubFormClose,
  showFirst = false,
  subForm,
  ...restCheckboxProps
}: CheckboxWithFormProps) => (
  <>
    {options.map(({ label, value }, index) => (
      <div key={index}>
        <CheckboxWithSwitch
          {...restCheckboxProps}
          value={value}
          checkboxGroupName={checkboxGroupName}
          disabled={disabled}
          form={form}
          label={label}
          onSubFormClose={onSubFormClose}
          showFirst={showFirst}
          subForm={subForm}
          index={index}
        />
      </div>
    ))}
  </>
)

export default CheckboxesWithForm
