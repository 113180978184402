import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Accordion, Card, Container, ProgressBar } from 'react-bootstrap'

import EPrescribeButton from '../../components/Buttons/EPrescribeButton'
import NewActiveMedication from '../../components/Forms/NewActiveMedication'
import PatientReportedField from '../../components/Other/PatientReportedField'
import { usePatientAllergies } from '../../hooks/usePatientAllergies'
import {
  QueryKeys as PatientProfileKeys,
  usePatientDemographics,
} from '../../hooks/usePatientInfo'
import { usePatientMedications } from '../../hooks/usePatientMedications'
import { useProviderDetails } from '../../hooks/useProviderInfo'
import useQueryString from '../../hooks/useQueryString'
import { InfoPage, Skeleton } from '../../stories/BaseComponents'
import { StandardSkeletonRows } from '../../stories/BaseComponents/Skeleton'
import ActiveMedsAccordion from './ActiveMedsAccordion'
import Allergies from './Allergies'
import MedicationLogs from './MedicationLogs'
import PastMedsAccordion from './PastMedsAccordion'
import { PatientHeader } from './PatientHeader'
import { patientDataTypes } from './patient-data-types'

import styles from '../_shared.module.scss'
import './Patient.scss'

const ErrorView = (
  <InfoPage
    status="warning"
    title="Sorry there was a problem loading this page"
    details="Oops something went wrong. Please contact your Osmind representative if this issue persists."
    redirectButtonText="Return to Patient List"
    redirectLink="/"
  />
)

export default function Medication({
  healthGorillaUserName,
}: {
  healthGorillaUserName: string
}) {
  const queryClient = useQueryClient()
  const query = useQueryString()
  const patientId = query.get('patientId')
  const providerId = query.get('providerId')
  const [isErxEnabled, setIsErxEnabled] = useState<boolean>(false)

  const {
    isLoading: patientMedicationsIsLoading,
    isError: patientMedicationsIsError,
    data: patientMedications,
  } = usePatientMedications(patientId ?? '')

  const {
    isLoading: patientIsLoading,
    isError: patientIsError,
    data: patientData,
  } = usePatientDemographics(patientId ?? '')

  const {
    isLoading: patientAllergiesIsLoading,
    isError: patientAllergiesIsError,
    data: patientAllergiesData,
  } = usePatientAllergies(patientId ?? '')

  const {
    isLoading: providerDetailsIsLoading,
    isError: providerDetailsIsError,
    data: providerDetails,
  } = useProviderDetails()

  const queriesError =
    patientIsError ||
    patientMedicationsIsError ||
    providerDetailsIsError ||
    patientAllergiesIsError
  const queriesLoading =
    patientMedicationsIsLoading ||
    patientIsLoading ||
    providerDetailsIsLoading ||
    patientAllergiesIsLoading

  const patientReportedCurrentMedication =
    (patientMedications?.PatientReportedMedications
      ?.CurrentMedications as string) || ''
  const patientReportedPastMedication =
    (patientMedications?.PatientReportedMedications
      ?.PastMedications as string) || ''
  const isDismissedPatientReportedCurrentMedication =
    (patientMedications?.PatientReportedMedications
      ?.DismissCurrentMedications as boolean) ?? true
  const isDismissedPatientReportedPastMedication =
    (patientMedications?.PatientReportedMedications
      ?.DismissPastMedications as boolean) ?? true

  useEffect(() => {
    if (providerDetails) {
      setIsErxEnabled(!!providerDetails.appSettings.erx)
    }
  }, [providerDetails])

  const updatePatientCache = useCallback(() => {
    queryClient.invalidateQueries([PatientProfileKeys.DEMOGRAPHICS, patientId])
    queryClient.invalidateQueries([
      patientDataTypes.PatientMedications,
      patientId,
    ])
  }, [queryClient])

  const updatePatientAllergiesCache = useCallback(() => {
    queryClient.invalidateQueries([
      patientDataTypes.PatientAllergies,
      patientId,
    ])
  }, [queryClient])

  const sortedActiveMedication = useMemo(() => {
    if (patientMedications) {
      return patientMedications?.activeMeds
        .filter((medication: any) => !medication.IsRetired)
        ?.sort(function (a, b) {
          const textA = a.MedicationName.toUpperCase()
          const textB = b.MedicationName.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
    }
  }, [patientMedications])

  const sortedPastMedication = useMemo(() => {
    if (patientMedications) {
      return patientMedications?.pastMeds?.sort(function (a, b) {
        const textA = a.MedicationName.toUpperCase()
        const textB = b.MedicationName.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
    }
  }, [patientMedications])

  const ActiveMedicines = () => {
    const activeMedications = sortedActiveMedication
    if (!activeMedications) {
      return (
        <Card bsPrefix="summary-card marginal activeMeds">
          <div className="very-large">
            Loading active medication information...
          </div>
          <ProgressBar animated now={70} />
        </Card>
      )
    }
    if (activeMedications?.length === 0) {
      return (
        <Card style={{ marginTop: '24px' }} bsPrefix="summary-card">
          <h5>No active medications found for this patient.</h5>
        </Card>
      )
    }
    return (
      <div>
        <br />
        <div style={{ padding: '15px 0px 15px 0px' }} className="summary-card">
          {activeMedications?.map((medicine, index) => {
            return (
              <Accordion key={index} bsPrefix="plain-accordion">
                <ActiveMedsAccordion
                  key={index}
                  medicine={medicine}
                  index={index}
                  handleApiChange={updatePatientCache}
                />
              </Accordion>
            )
          })}
        </div>
      </div>
    )
  }

  const PastMedicines = () => {
    const medicationHistory = sortedPastMedication
    if (!medicationHistory) {
      return (
        <Card bsPrefix="summary-card marginal activeMeds">
          <div className="very-large">
            Loading past medication information...
          </div>
          <ProgressBar animated now={70} />
        </Card>
      )
    }
    return medicationHistory?.length === 0 ? (
      <Card style={{ marginTop: '24px' }} bsPrefix="summary-card">
        <h5>No past medication history found for this patient.</h5>
      </Card>
    ) : (
      <div>
        <br />
        <div className="summary-card">
          {medicationHistory?.map((medicine, index) => {
            return (
              <Accordion key={index} bsPrefix="plain-accordion">
                <PastMedsAccordion
                  key={index}
                  medicine={medicine}
                  patient={patientData}
                  index={index}
                  handleApiChange={updatePatientCache}
                />
              </Accordion>
            )
          })}
        </div>
      </div>
    )
  }

  const loadErxMedicationsScreen = () => {
    const activeMedications = patientReportedCurrentMedication ? (
      <PatientReportedField
        title="Active Medications"
        fieldId="DismissCurrentMedications"
        patientId={patientData.PatientId}
        patientReportedData={patientReportedCurrentMedication}
        isDismissed={isDismissedPatientReportedCurrentMedication}
        handleApiChange={updatePatientCache}
      />
    ) : null
    const pastMedications =
      patientMedications?.PatientReportedMedications?.PastMedications || '' ? (
        <PatientReportedField
          title="Past Medications"
          fieldId="DismissPastMedications"
          patientId={patientData.PatientId}
          patientReportedData={patientReportedPastMedication}
          isDismissed={isDismissedPatientReportedPastMedication}
          handleApiChange={updatePatientCache}
        />
      ) : null

    return (
      <>
        <div>{activeMedications}</div>
        <div className="mt-3">{pastMedications}</div>
        <EPrescribeButton
          patient={patientData}
          handleApiChange={updatePatientCache}
        />
      </>
    )
  }

  const defaultMedicationScreen = () => (
    <>
      <Container className={styles.medicationsContainer}>
        <NewActiveMedication
          patient={patientData}
          handleApiChange={updatePatientCache}
        />
      </Container>

      <Container>
        <h4>
          <span className="info emphasis">Active Medications</span>
        </h4>
      </Container>
      <PatientReportedField
        title="Active Medications"
        fieldId="DismissCurrentMedications"
        patientId={patientData.PatientId}
        patientReportedData={patientReportedCurrentMedication}
        isDismissed={isDismissedPatientReportedCurrentMedication}
        handleApiChange={updatePatientCache}
      />
      <ActiveMedicines />
      <Container>
        <h4 className="mt-3">
          <span className="info emphasis">Past Medications</span>
        </h4>
      </Container>
      <PatientReportedField
        title="Past Medications"
        fieldId="DismissPastMedications"
        patientId={patientData.PatientId}
        patientReportedData={patientReportedPastMedication}
        isDismissed={isDismissedPatientReportedPastMedication}
        handleApiChange={updatePatientCache}
      />
      <PastMedicines />
      <Container>
        <h4>
          <span className="info emphasis">Allergies</span>
        </h4>
      </Container>
      <Card style={{ marginTop: '24px' }} bsPrefix="summary-card">
        <Allergies
          Patient={patientData}
          Allergies={patientAllergiesData || []}
          handleApiChange={updatePatientAllergiesCache}
          NoAccordion
        />
      </Card>

      {patientMedications?.medicationLogs === undefined ||
      !patientMedications?.medicationLogs ? (
        <Card bsPrefix="summary-card marginal MedicationLogs">
          <div className="very-large">Loading medication responses...</div>
          <ProgressBar animated now={80} />
        </Card>
      ) : (
        <MedicationLogs
          responses={patientMedications?.medicationLogs || {}}
          activeMeds={patientMedications?.activeMeds}
        />
      )}
    </>
  )

  if (queriesError || !patientId || !providerId) {
    return ErrorView
  }

  return (
    <div className={styles.scroll}>
      <PatientHeader
        providerId={providerId}
        patientId={patientId}
        healthGorillaUserName={healthGorillaUserName}
      />
      <div className="patientMedicationsContainer">
        {queriesLoading ? (
          <Skeleton paragraph={{ rows: StandardSkeletonRows.fullPage }} />
        ) : isErxEnabled ? (
          loadErxMedicationsScreen()
        ) : (
          defaultMedicationScreen()
        )}
      </div>
    </div>
  )
}
