import React, { useState } from 'react'

import { Button, Modal } from 'react-bootstrap'

import { updateActiveStatus } from '../../api/api-lib'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'

export default function ChangeActiveStatusButton(props) {
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isActive = props.isActive

  const titleText = isActive ? 'Deactivation' : 'Reactivation'
  const buttonText = isActive ? 'Deactivate Patient' : 'Reactivate Patient'
  const actionText = isActive ? 'deactivated' : 'reactivated'

  const deactivationExplanationText =
    isActive &&
    'If the patient is deactivated, all email/SMS/push notifications related to the patient will be hidden. ' +
      'Direct messaging and new scheduling events to the patient will be hidden. ' +
      "On the patient's account, provider feedback, new journal entries, and claims submission will no longer be available, " +
      'but they will have access to mood and survey features, as well as current medication information and old journal entries.'

  const reactivationExplanationText =
    !isActive &&
    'Upon reactivation, previous features and settings will be restored.'

  const restoreText = isActive ? 'reactivate' : 'deactivate'

  function handleShow() {
    setShow(true)
  }

  function handleClose() {
    setShow(false)
  }

  async function handleStatusChange() {
    try {
      setIsLoading(true)
      const newStatus = !isActive

      const data = {
        PatientId: props.patientId,
        IsActive: newStatus,
        UpdateActiveStatus: true,
      }

      await updateActiveStatus(data)

      props.handleApiChange()
      handleClose()

      notification(
        `You have successfully ${actionText} the patient's account. Please refresh to see changes.`,
        'success'
      )
    } catch (error) {
      onError(
        error,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const confirmationModal = (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="very-large">Confirm {titleText}</Modal.Header>
      <Modal.Body className="marginal">
        <p>
          Are you sure you want to {buttonText.toLowerCase()}'s account?&nbsp;
          {reactivationExplanationText}
        </p>
        <p>{deactivationExplanationText}</p>
        <p>
          You will be able to {restoreText} patient's account in the future.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsPrefix="button-block"
          onClick={handleStatusChange}
          disabled={isLoading}
        >
          {buttonText}
        </Button>
        <br /> <br />
        <Button
          bsPrefix="button-close"
          onClick={handleClose}
          disabled={isLoading}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )

  return (
    <>
      {isActive ? (
        <>
          <Button
            onClick={handleShow}
            bsPrefix="button-block"
            disabled={isLoading}
          >
            <i className="fa fa-archive" aria-hidden="true" /> Deactivate
            Patient
          </Button>{' '}
          {confirmationModal}
        </>
      ) : (
        <>
          <Button
            onClick={handleShow}
            bsPrefix="button-block"
            disabled={isLoading}
          >
            <i className="fa fa-users" aria-hidden="true" /> Reactivate Patient
          </Button>{' '}
          {confirmationModal}
        </>
      )}
    </>
  )
}
