import React, { useMemo } from 'react'

import { orderBy } from 'lodash'
import { Accordion, Card } from 'react-bootstrap'

import DisabledFeatureMessage from '../../components/Other/DisabledFeatureMessage'
import { usePatientJournalNotes } from '../../hooks/usePatientJournalNotes'
import { useProviderDetails } from '../../hooks/useProviderInfo'
import useQueryString from '../../hooks/useQueryString'
import { JournalNote } from '../../shared-types'
import { InfoPage, Skeleton, Text } from '../../stories/BaseComponents'
import { StandardSkeletonRows } from '../../stories/BaseComponents/Skeleton'
import JournalAccordion from './JournalAccordion'
import { PatientHeader } from './PatientHeader'

import styles from '../_shared.module.scss'
import './Patient.scss'

const ErrorView = (
  <InfoPage
    status="warning"
    title="Sorry there was a problem loading this page"
    details="Oops something went wrong. Please contact your Osmind representative if this issue persists."
    redirectButtonText="Return to Patient List"
    redirectLink="/"
  />
)

export default function PatientJournal({
  healthGorillaUserName,
}: {
  healthGorillaUserName: string
}) {
  const query = useQueryString()
  const patientId = query.get('patientId')
  const providerId = query.get('providerId')

  const {
    isLoading: providerSidePatientIsLoading,
    isError: providerSidePatientIsError,
    data: patientJournalNotes,
  } = usePatientJournalNotes(patientId ?? '')

  const {
    isLoading: providerDetailsIsLoading,
    isError: providerDetailsIsError,
    data: providerDetails,
  } = useProviderDetails()

  const queriesLoading =
    providerSidePatientIsLoading || providerDetailsIsLoading

  const queriesError = providerSidePatientIsError || providerDetailsIsError

  const noQueryData = !patientJournalNotes || !providerDetails

  const ComponentContent = () =>
    useMemo(() => {
      const sortedNotes = orderBy(
        patientJournalNotes,
        (notes: JournalNote) => {
          return new Date(notes.CreatedOn)
        },
        ['desc']
      )

      return queriesLoading || noQueryData ? (
        <Skeleton paragraph={{ rows: StandardSkeletonRows.fullPage }} />
      ) : (
        <>
          <Text
            className={`${styles.discretionNote} ${styles.marginBottom} ${styles.patientJournalNote}`}
          >
            Please note that patients are informed in the app that journal
            entries may not be actively monitored and are not to be used for
            emergency communications.
          </Text>
          <Card
            style={{ padding: '15px 0px 15px 0px' }}
            className="summary-card Journal"
          >
            {providerDetails.appSettings &&
            !providerDetails.appSettings.journal ? (
              <DisabledFeatureMessage feature="journaling" />
            ) : patientJournalNotes.length === 0 ? (
              <>
                <br />
                <h5 className={styles.noJournalNotice}>
                  No journal entries found for this patient.
                </h5>
              </>
            ) : (
              sortedNotes.map((note, index) => {
                return (
                  <Accordion
                    className={`table-container ${styles.journalEntryCard} ${styles.hoverTransition}`}
                    key={index}
                  >
                    <JournalAccordion note={note} type="active" index={index} />
                  </Accordion>
                )
              })
            )}
          </Card>
        </>
      )
    }, [patientJournalNotes, queriesLoading, noQueryData, providerDetails])

  if (queriesError || !patientId || !providerId) {
    return ErrorView
  }

  return (
    <div className={styles.scroll}>
      <PatientHeader
        providerId={providerId}
        patientId={patientId}
        healthGorillaUserName={healthGorillaUserName}
      />
      <div className="patientJournalContainer">
        <ComponentContent />
      </div>
    </div>
  )
}
