export type SortOrder = 'asc' | 'desc'

/**
 * @example Usage:
 *
 * // Define your allowed fieldname set:
 * type ListWidgetsSortFieldName = 'name' | 'createdAt' | 'updatedAt'
 *
 * // Define the SortParam type:
 * export type ListWidgetsSortParam = SortParam<ListWidgetsSortFieldName>
 *
 * // Use it in your API layer:
 * export const listWidgets = async ({
 *   ...
 *   sorts,
 * }: {
 *   ...
 *   sorts?: ListWidgetsSortParam[]
 * }) => {
 *   const query = new URLSearchParams({
 *     ...(sorts?.length ? { sort: buildSortStringForAPI(sorts) } : {}),
 *   })
 *
 *   return await API.get(
 *     WIDGETS,
 *     `/widgets?${query}`,
 *     {}
 *   )
 * }
 *
 */
export type SortParam<FieldName extends string> = {
  field: FieldName
  order: SortOrder
}

/**
 * Converts a list of SortParams into the sort:order pairs expected by the API layer.
 */
export function buildSortStringForAPI(sorts: SortParam<string>[]): string {
  return sorts.map((sort) => `${sort.field}:${sort.order}`).join(',')
}

/**
 * Helper method for converting from "pages" to "limit/offset" parameters.
 * @param currentPage The current page # (first page = 1).
 * @param pageSize The # of items per page.
 */
export const pageToLimitOffsetParams = ({
  currentPage,
  pageSize,
}: {
  currentPage: number
  pageSize: number
}) => {
  return {
    limit: pageSize,
    offset: (currentPage - 1) * pageSize,
  }
}
