import React from 'react'

import 'moment-timezone'
import { Table } from 'react-bootstrap'
import 'react-dates/initialize'

import ShowSavedVS from '../Tables/ShowSavedVS'

import 'react-dates/lib/css/_datepicker.css'

interface AddOneVitalProps {
  Time: any
  HR: number
  BP: number
  BpArm?: string
  RR: number
  Comments: string
  Temp: string
  noteType: string
  discharge?: any
  start?: any
  disabled: boolean
  fields: any
  requireBP?: boolean
  id?: string
  saveEditVitalSigns?: (index: number, editVitSign: any) => void
  saveOneVital?: (editVitSign: any) => void
}

export default function AddOneVital(props: AddOneVitalProps) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              minWidth: '136px',
            }}
          >
            Time
          </th>
          <th
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              minWidth: '96px',
            }}
          >
            HR
          </th>
          <th
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              minWidth: '96px',
            }}
          >
            BP
          </th>
          <th
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              minWidth: '150px',
            }}
          >
            RR
          </th>
          <th
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              minWidth: '150px',
            }}
          >
            Temp
          </th>
          <th
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              minWidth: '150px',
            }}
          >
            Comments
          </th>
        </tr>
      </thead>
      <tbody>
        <ShowSavedVS
          index={0}
          Time={props.Time}
          HR={props.HR}
          BP={props.BP}
          BpArm={props.BpArm}
          RR={props.RR}
          Comments={props.Comments}
          Temp={props.Temp}
          saveEditVitalSigns={props.saveEditVitalSigns}
          saveOneVital={props.saveOneVital}
          disabled={props.disabled}
          noteType={props.fields.NoteType}
          discharge
          start
          requireBP={props.requireBP}
          id={props.id}
        />
      </tbody>
    </Table>
  )
}
