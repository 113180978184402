import React from 'react'

export const ROW_MIN_WIDTH = 150

export const Row = ({
  label,
  value = '',
  minWidth = 300,
  rowStyle = {},
  rowClassName = 'info-row',
  labelStyle = {},
  testId,
}: {
  label: string | React.ReactElement
  value?: string | React.ReactElement
  minWidth?: number
  rowStyle?: React.CSSProperties
  rowClassName?: string
  labelStyle?: React.CSSProperties
  testId?: string
}) => {
  return (
    <div className={rowClassName} style={rowStyle}>
      <div
        className="label"
        style={{
          justifyContent: 'flex-end',
          minWidth: `${minWidth}px`,
          ...labelStyle,
        }}
      >
        {label}
      </div>
      <div
        className="full-width"
        style={{ justifyContent: 'flex-end' }}
        data-testid={testId}
      >
        {value}
      </div>
    </div>
  )
}
