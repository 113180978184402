import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { getPatientSettings } from '../../api/patients'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'
import { PatientSettingsData } from '../../shared-types'

export const usePatientSettings = (
  patientId: string
): UseQueryResult<PatientSettingsData> => {
  return useQuery<PatientSettingsData>(
    [patientDataTypes.PatientSettings, patientId],
    {
      queryFn: () => getPatientSettings(patientId),
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
