import React from 'react'

import 'moment-timezone'
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
import 'react-dates/initialize'
import { Link } from 'react-router-dom'

import useQueryString from '../../../../../hooks/useQueryString'
import { notification } from '../../../../../libs/notificationLib'
import { AutoSizingTextArea } from '../../../../../stories/BaseComponents/TextAreaInput'
import ShowSavedMedicationProgress from './../Tables/ShowSavedMedicationProgress.js'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function Subjective(props) {
  const query = useQueryString()
  const patientId = query.get('patientId')
  const providerId = query.get('providerId')
  function complaintFromPreviousNotes() {
    if (props.previousNotes.length === 0) {
      notification(
        'There are no previous SOAP notes to import from.',
        'failure'
      )
      return
    }
    props.handleFieldChange({
      ...props.fields,
      ClinicalNoteContent: props.previousNotes[0].ClinicalNoteContent,
    })
  }

  function handleShowMedicationProgress(medName) {
    const [medPro] = props.fields.SavedAdditionalMedications.filter((MP) => {
      return MP.MedicationName === medName
    })
    let index
    for (let i = 0; i < props.fields.SavedAdditionalMedications.length; i++) {
      if (
        props.fields.SavedAdditionalMedications[i].MedicationName === medName
      ) {
        index = i
      }
    }
    if (props.fields.SavedAdditionalMedications.length !== 0) {
      return (
        <>
          <Table responsive>
            <thead>
              <tr>
                <th
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    minWidth: '336px',
                  }}
                >
                  Medication Name
                </th>
                <th
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    minWidth: '151px',
                  }}
                >
                  Efficacy
                </th>
                <th
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    minWidth: '151px',
                  }}
                >
                  Adherence
                </th>
                <th
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    minWidth: '224px',
                  }}
                >
                  Side Effects
                </th>
                <th
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    minWidth: '112px',
                  }}
                >
                  Side Effects Details
                </th>
                {!props.disabled && (
                  <th
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      minWidth: '140px',
                    }}
                  >
                    &nbsp;
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              <ShowSavedMedicationProgress
                key={index}
                index={index}
                MedicationName={medPro.MedicationName}
                Efficacy={medPro.Efficacy}
                Adherence={medPro.Adherence}
                SideEffects={medPro.SideEffects}
                SideEffectDescription={medPro.SideEffectDescription}
                saveEditMedProgress={props.saveEditMedProgress}
                deleteMedProgress={props.deleteMedProgress}
                disabled={props.disabled}
              />
            </tbody>
          </Table>
        </>
      )
    }
  }

  function containsMedProgress(medName) {
    if (props.fields.SavedAdditionalMedications.length === 0) {
      return false
    }
    for (let i = 0; i < props.fields.SavedAdditionalMedications.length; i++) {
      if (
        props.fields.SavedAdditionalMedications[i].MedicationName === medName
      ) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <Row>
        <Link
          to={{
            pathname: `/patient/profile?patientId=${patientId}&providerId=${providerId}`,
          }}
          target="_blank"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '10px',
            marginTop: '10px',
          }}
        >
          <Button variant="primary">Open Patient Profile</Button>
        </Link>
        <Form.Text
          className="center"
          style={{ fontWeight: 'bold', color: '#A9A9A9' }}
        >
          <span style={{ fontSize: '13px' }} className="hideDuringPrint">
            Click to view/edit past medical history, past psychiatric history,
            substance use, family history, social history, allergies etc.
          </span>
        </Form.Text>
      </Row>
      <br />
      <Row>
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            {!props.Therapy ? 'Chief complaint and HPI' : 'Chief complaint'}
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.ClinicalNoteContent}
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                ClinicalNoteContent: e.target.value,
              })
            }
            as={AutoSizingTextArea}
          />
        </Form.Group>
      </Row>
      <Row>
        <Button
          variant="outline-primary"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '10px',
          }}
          disabled={props.disabled}
          onClick={() => {
            complaintFromPreviousNotes()
          }}
        >
          Import from last SOAP note
        </Button>
      </Row>
      <hr />
      {props.Medical && (
        <>
          <Form.Group
            as={Col}
            sm={12}
            md={6}
            lg={4}
            style={{
              textAlign: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Current Medications
            </Form.Label>
          </Form.Group>
          {props.activeMeds.length === 0 && (
            <Form.Group
              as={Col}
              style={{
                textAlign: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <div style={{ paddingTop: '5px' }}>
                No active medications available
              </div>
            </Form.Group>
          )}
          {props.activeMeds.length > 0 &&
            props.activeMeds.map((actMeds, i) => {
              return (
                <Row key={'active-meds-' + i}>
                  {containsMedProgress(actMeds.MedicationName) ? (
                    <>
                      <Form.Group
                        as={Col}
                        sm={12}
                        md={6}
                        lg={4}
                        style={{
                          textAlign: 'center',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      >
                        <Form.Control
                          bsPrefix="form-input"
                          value={actMeds.MedicationName}
                          disabled
                        />
                      </Form.Group>
                      {handleShowMedicationProgress(actMeds.MedicationName)}
                    </>
                  ) : (
                    <>
                      <Form.Group
                        as={Col}
                        sm={12}
                        md={6}
                        lg={4}
                        style={{
                          textAlign: 'center',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      >
                        <Form.Control
                          bsPrefix="form-input"
                          value={actMeds.MedicationName}
                          disabled
                        />
                      </Form.Group>
                      <Button
                        bsPrefix="center plain-button info button-label"
                        onClick={() => {
                          props.saveNewMedicationProgress(
                            actMeds.MedicationName
                          )
                        }}
                        disabled={props.disabled}
                      >
                        <i
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                        />
                        &nbsp;Record Progress
                      </Button>
                    </>
                  )}
                </Row>
              )
            })}
        </>
      )}
      <Row>
        <Link
          to={{
            pathname: `/patient/medication?patientId=${patientId}&providerId=${providerId}`,
          }}
          target="_blank"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '10px',
            marginTop: '10px',
          }}
        >
          <Button variant="primary">See more/edit Medications list</Button>
        </Link>
      </Row>
      <br />
      <Row>
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Patient narrative & response to treatment
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.PatientResponseToExperience}
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientResponseToExperience: e.target.value,
              })
            }
            as={AutoSizingTextArea}
            minRows={2}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional Notes
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.IVNotes}
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                IVNotes: e.target.value,
              })
            }
            as={AutoSizingTextArea}
            minRows={1}
          />
        </Form.Group>
      </Row>
    </>
  )
}
