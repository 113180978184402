import React from 'react'

import { Container, Form, Row } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'

import './../PatientProfile.scss'

export default function FeedingEatingSaved(props) {
  const hasNoRelevantHistory =
    props.patientData.Anorexia.state === false &&
    props.patientData.Bulimia.state === false &&
    props.patientData.OtherEating.state === false &&
    !props.patientData.EatingAdditionalNotes &&
    props.patientData.NoEatingDisorder === true

  const noRecordedInfo =
    props.patientData.Anorexia.state === false &&
    props.patientData.Bulimia.state === false &&
    props.patientData.OtherEating.state === false &&
    !props.patientData.EatingAdditionalNotes &&
    props.patientData.NoEatingDisorder === false

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Feeding and eating"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">Feeding and eating:</Form.Row>
      <Form.Group className="indent">
        {props.patientData.Anorexia.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.Anorexia.state)}
            />
            <span id="AnorexiaShow">Anorexia nervosa</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.Anorexia.Notes}
          </Container>
        )}
        {props.patientData.Bulimia.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.Bulimia.state)}
            />
            <span id="BulimiaShow">Bulimia nervosa</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.Bulimia.Notes}
          </Container>
        )}
        {props.patientData.BingeEatingDisorder.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.BingeEatingDisorder.state)}
            />
            <span id="BingeEatingDisorderShow">Binge-eating disorder</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.BingeEatingDisorder.Notes}
          </Container>
        )}
        {props.patientData.OtherEating.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.OtherEating.state)}
            />
            <span id="OtherEatingShow">Other</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.OtherEating.Notes}
          </Container>
        )}
        <Container bsPrefix="psych">
          {props.patientData.EatingSymptoms.RestrictingFood && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(
                  props.patientData.EatingSymptoms.RestrictingFood
                )}
              />
              <span id="RestrictingFoodShow">
                Restricting food to lose weight
              </span>
              <br />
            </>
          )}
          {props.patientData.EatingSymptoms.Purging && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(props.patientData.EatingSymptoms.Purging)}
              />
              <span id="PurgingShow">Purging</span>
              <br />
            </>
          )}
          {props.patientData.EatingSymptoms.BodyImage && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(props.patientData.EatingSymptoms.BodyImage)}
              />
              <span id="BodyImageShow">Problems with body image</span>
              <br />
            </>
          )}
          {props.patientData.EatingSymptoms.ExcessiveEating && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(
                  props.patientData.EatingSymptoms.ExcessiveEating
                )}
              />
              <span id="ExcessiveEatingShow">Excessive eating</span>
              <br />
            </>
          )}
          {props.patientData.EatingSymptoms.Other && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(props.patientData.EatingSymptoms.Other)}
              />
              <span id="OtherTextShow">
                {props.patientData.EatingSymptoms.OtherText}
              </span>
              <br />
            </>
          )}
        </Container>
      </Form.Group>
      <Form.Group className="border-bottom indent">
        {props.patientData.EatingAdditionalNotes && (
          <Container bsPrefix="psych">
            <Form.Row className="patient-dark">Additional Notes:</Form.Row>
            <Row bsPrefix="psych-sub">
              {props.patientData.EatingAdditionalNotes}
            </Row>
          </Container>
        )}
      </Form.Group>
    </Form.Group>
  )
}
