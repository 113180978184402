import { FC, useState } from 'react'

import { SelectValue } from 'antd/lib/select'

import { Option, Select } from '../../../stories/BaseComponents'

type AppointmentMinutesSelectProps = {
  onChange: (value: number) => void
  value: number
  shouldAllowMultiHourValues: boolean
}

const MAX_MINUTES = 60
const baseMinutesOptions: { value: number; name: string }[] = []
for (let i = 0; i < MAX_MINUTES; i += 5) {
  baseMinutesOptions.push({ value: i, name: `${i}` })
}

const generateMinuteOptions = (value: number) => {
  if (value === 0) {
    return baseMinutesOptions.filter((option) => option.value % 15 === 0)
  }

  if (value && !baseMinutesOptions.find((option) => option.value === value)) {
    return [{ value: value, name: `${value}` }, ...baseMinutesOptions]
  }

  return baseMinutesOptions
}

export const AppointmentMinutesSelect: FC<AppointmentMinutesSelectProps> = ({
  onChange,
  value,
  shouldAllowMultiHourValues,
}) => {
  const [searchValue, setSearchValue] = useState<number | null>(null)
  const handleSubmit = (value: number) => {
    if (value < 0) {
      setSearchValue(0)
      return onChange(0)
    }

    value = Math.round(value)

    if (value < MAX_MINUTES) {
      setSearchValue(value)
      return onChange(value)
    }

    if (shouldAllowMultiHourValues) {
      setSearchValue(value % MAX_MINUTES)
      return onChange(value)
    }

    setSearchValue(0)
    onChange(0)
  }

  const handleChange = (value: SelectValue) => {
    if (!value) {
      setSearchValue(0)
      return onChange(0)
    }
    const selectValue = value.valueOf()
    if (!Number(selectValue)) {
      throw new Error('The value should be a number')
    }
    handleSubmit(Number(selectValue))
  }

  const handleSearch = (value: string) => {
    if (!Number(value)) {
      return
    }
    setSearchValue(Number(value))
  }

  const handleBlur = () => {
    if (searchValue) {
      handleSubmit(searchValue)
    }
  }

  const handleSelect = (value: SelectValue) => {
    if (value === undefined) {
      if (searchValue) {
        handleSubmit(searchValue)
        return
      }
      return
    }

    const selectValue = value.valueOf()

    if (selectValue !== searchValue) {
      handleSubmit(Number(selectValue))
      return
    }
    if (searchValue) {
      handleSubmit(searchValue)
    }
  }

  return (
    <Select
      onSearch={handleSearch}
      onChange={handleChange}
      onSelect={handleSelect}
      onBlur={handleBlur}
      showSearch
      value={searchValue === null ? value : searchValue}
    >
      {generateMinuteOptions(searchValue || 0).map((option) => (
        <Option key={`select_minute_${option.name}`} value={option.value}>
          {option.name}
        </Option>
      ))}
    </Select>
  )
}
