import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const employmentStatusSnomed: PatientDemographicObject[] = [
  {
    '365458002': {
      title: 'Attending school/college/other work or educational programming',
    },
  },
  { '160903007': { title: 'Employed (full-time)' } },
  { '160904001': { title: 'Employed (part-time)' } },
  { '161048004': { title: 'On disability' } },
  { '160906004': { title: 'Self-employed' } },
  { '224376001': { title: 'Sporadically employed' } },
  { '73438004': { title: 'Unemployed' } },
]

export const employmentStatusDropDownValues = constructPatientDemographicObject(
  employmentStatusSnomed,
  CodeSystemOIDs.SNOMEDCT,
  Categories.EMPLOYMENT_STATUS
)
