import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Modal, Skeleton } from 'antd'
import { cloneDeep, isArray } from 'lodash'

import SkeletonLoadingTransition from '../../components/Animation/SkeletonLoadingTransition'
import {
  useDemographicsContactInfo,
  useDemographicsContactInfoMutation,
} from '../../hooks/usePatientProfile'
import {
  DemographicsContactInfo,
  DemographicsSection,
} from '../../hooks/usePatientProfile/shared-types'
import useQueryString from '../../hooks/useQueryString'
import { arrayHasValues } from '../../libs/utils'
import {
  EthnicityTitleEnum,
  GenderIdentityDropDownValues,
  RaceTitleEnum,
  americanIndianSubRaceDropdownValues,
  asianSubRaceDropDownValues,
  blackOrAfricanAmericanSubRaceDropDownValues,
  ethnicityDropdownValues,
  hispanicLatinoSubEthnicityDropDownValues,
  nativeHawaiianSubRaceDropDownValues,
  raceDropdownValues,
  whiteSubRaceDropDownValues,
} from '../../shared/Demographics'
import { UnreadMessageCountsContext } from '../../v2/messaging/UnreadMessageCounts'
import { Option } from '../BaseComponents/InlineEditFields/InlineEditSelect'
import { mapCodesetToListView } from '../PatientIntake/sections/helpers'
import Fields from './Fields'

import styles from './Demographics.module.scss'

const { confirm } = Modal
export interface DemographicsProps {
  loading?: boolean
  testId?: string
}

interface Item {
  id: string
  value: string
}

interface Items {
  groupName?: string
  items?: Array<Item>
}

const Loading = () => (
  <div className={styles.skeletonContainer}>
    <Skeleton
      active
      title={{ width: 85 }}
      paragraph={{
        rows: 3,
        width: '100%',
        className: styles.skeletonParagraph,
      }}
    />
  </div>
)

const Demographics: React.FC<DemographicsProps> = ({ testId }) => {
  const query = useQueryString()
  const patientId = query.get('patientId') ?? ''
  const containerRef: React.Ref<HTMLDivElement> = useRef(null)
  const [compact, setCompact] = useState(false)
  const { data, isFetching: loading } = useDemographicsContactInfo({
    patientId,
  })
  const { updateSendbirdUser } = useContext(UnreadMessageCountsContext)

  const { mutateAsync: updateDemographics } =
    useDemographicsContactInfoMutation({
      patientId,
      updateSendbirdUser,
    })

  const subRaces: any = useMemo(() => {
    return {
      [RaceTitleEnum.AMERICAN_INDIAN]:
        americanIndianSubRaceDropdownValues.map(mapCodesetToListView),
      [RaceTitleEnum.ASIAN]:
        asianSubRaceDropDownValues.map(mapCodesetToListView),
      [RaceTitleEnum.BLACK_OR_AFRICAN_AMERICAN]:
        blackOrAfricanAmericanSubRaceDropDownValues.map(mapCodesetToListView),
      [RaceTitleEnum.NATIVE_HAWAIIAN]:
        nativeHawaiianSubRaceDropDownValues.map(mapCodesetToListView),
      [RaceTitleEnum.WHITE]:
        whiteSubRaceDropDownValues.map(mapCodesetToListView),
      [RaceTitleEnum.UNKNOWN]: [],
      [RaceTitleEnum.NO_ANSWER]: [],
    }
  }, [])

  const genderIdentity = useMemo(() => {
    return GenderIdentityDropDownValues.map(mapCodesetToListView)
  }, [])

  const sortRaces = (a: Option, b: Option) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) return -1
    if (a.label.toLowerCase() > b.label.toLowerCase()) return 1
    return 0
  }

  const [items, setItems]: any = useState([
    {
      groupName: 'Legal name',
      items: [
        {
          id: DemographicsSection.firstName,
          label: 'First',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          value: '',
          type: 'text',
        },
        {
          id: DemographicsSection.middleName,
          label: 'Middle',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          value: '',
          type: 'text',
        },
        {
          id: DemographicsSection.lastName,
          label: 'Last',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          value: '',
          type: 'text',
        },
      ],
    },
    {
      groupName: 'Name to use',
      items: [
        {
          id: DemographicsSection.firstNameToUse,
          label: 'First',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          value: '',
          type: 'text',
        },
        {
          id: DemographicsSection.middleNameToUse,
          label: 'Middle',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          value: '',
          type: 'text',
        },
        {
          id: DemographicsSection.lastNameToUse,
          label: 'Last',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          value: '',
          type: 'text',
        },
      ],
    },
    {
      groupName: 'Former name',
      items: [
        {
          id: DemographicsSection.formerFirstName,
          label: 'First',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          value: '',
          type: 'text',
        },
        {
          id: DemographicsSection.formerMiddleName,
          label: 'Middle',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          value: '',
          type: 'text',
        },
        {
          id: DemographicsSection.formerLastName,
          label: 'Last',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          value: '',
          type: 'text',
        },
      ],
    },
    {
      groupName: 'Background',
      items: [
        {
          id: DemographicsSection.DateOfBirth,
          label: 'Date of birth',
          placeHolder: 'Add',
          scrollToField: true,
          value: '',
          type: 'dateInput',
        },
        {
          id: DemographicsSection.birthSex,
          label: 'Birth sex',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          value: '',
          type: 'radio',
          options: [
            {
              value: 'F',
              label: 'F',
            },
            {
              value: 'M',
              label: 'M',
            },
            {
              value: 'X',
              label: 'X',
            },
            {
              value: 'Unknown',
              label: 'Unknown',
            },
          ],
        },
        {
          id: DemographicsSection.legalSex,
          label: 'Legal sex',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          value: '',
          type: 'radio',
          options: [
            {
              value: 'F',
              label: 'F',
            },
            {
              value: 'M',
              label: 'M',
            },
            {
              value: 'X',
              label: 'X',
            },
            {
              value: 'Unknown',
              label: 'Unknown',
            },
          ],
        },
        {
          id: DemographicsSection.pronouns,
          label: 'Pronouns',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          mode: 'multiple',
          value: undefined,
          type: 'select',
          options: [
            {
              value: 'she/her/hers',
              label: 'she/her/hers',
            },
            {
              value: 'he/him/his',
              label: 'he/him/his',
            },
            {
              value: 'they/them/theirs',
              label: 'they/them/theirs',
            },
          ],
        },
        {
          id: DemographicsSection.genderIdentity,
          label: 'Gender identity',
          placeHolder: 'Add',
          requireConfirmation: true,
          allowClear: true,
          scrollToField: true,
          value: undefined,
          type: 'select',
          options: genderIdentity,
        },
        {
          id: DemographicsSection.race,
          label: 'Race',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          value: undefined,
          mode: 'multiple',
          type: 'select',
          options: raceDropdownValues.map(mapCodesetToListView),
        },
        {
          id: DemographicsSection.raceSubcategory,
          label: 'Race subcategory',
          placeHolder: 'Add',
          filterSort: sortRaces,
          requireConfirmation: true,
          scrollToField: true,
          value: undefined,
          type: 'select',
          mode: 'multiple',
          options: [],
        },
        {
          id: DemographicsSection.ethnicity,
          label: 'Ethnicity',
          placeHolder: 'Add',
          allowClear: true,
          requireConfirmation: true,
          scrollToField: true,
          value: '',
          type: 'select',
          options: ethnicityDropdownValues.map(mapCodesetToListView),
        },
        {
          id: DemographicsSection.specificEthnicity,
          label: 'Specific ethnicity',
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          mode: 'multiple',
          value: undefined,
          type: 'select',
          options:
            hispanicLatinoSubEthnicityDropDownValues.map(mapCodesetToListView),
        },
      ],
    },
    {
      groupName: 'Additional notes',
      items: [
        {
          id: DemographicsSection.notes,
          placeHolder: 'Add',
          requireConfirmation: true,
          scrollToField: true,
          label: '',
          value: '',
          type: 'text-area',
        },
      ],
    },
  ])

  const handleResize = () => {
    const shouldBeCompact = (containerRef.current?.clientWidth || 301) < 350
    setCompact(shouldBeCompact)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    handleResize()
  }, [containerRef.current])

  const checkRaceSubcategories = (
    rawNewValue: string[],
    newRaceValue: any[],
    raceSubcategoriesValue: any[]
  ) => {
    const newSubraces: any[] = []
    raceSubcategoriesValue.forEach((subcategory) => {
      newRaceValue.forEach(({ options }) => {
        if (
          options.findIndex(
            ({ value }: { value: string }) => value === subcategory
          ) !== -1
        ) {
          newSubraces.push(subcategory)
        }
      })
    })
    return {
      hasChanged:
        JSON.stringify(raceSubcategoriesValue) !== JSON.stringify(newSubraces),
      newSubraces: newSubraces,
      hasUnknownValue: arrayHasValues(rawNewValue, [
        'Unknown',
        'Decline to Answer',
      ]),
    }
  }

  useEffect(() => {
    if (!loading && data) {
      const itemsCopy = cloneDeep(items)
      const dataCopy = JSON.parse(JSON.stringify(data))
      const result: Array<{ key: string; value: string }> = []
      const keys = Object.keys(dataCopy)
      keys.forEach(function (key) {
        result.push({
          key: key,
          value: dataCopy[key],
        })
      })
      itemsCopy.forEach((e: any) => {
        e.items.forEach((i: Item, index: number) => {
          result.forEach((r: { key: string; value: any }) => {
            let newSubRaces: Array<any> = []
            if (r.key === 'race' && r.value) {
              if (r.value.length > 0) {
                r.value.forEach((e: string) => {
                  newSubRaces = [
                    ...newSubRaces,
                    {
                      label: e,
                      options: subRaces[e].sort(sortRaces),
                    },
                  ]
                })
              }
            }
            if (r.key === i.id) {
              i.value = r.value
              if (r.key === 'race') {
                e.items[index + 1].options = newSubRaces
                if (
                  r.value.length > 0 &&
                  !arrayHasValues(r.value, ['Unknown', 'Decline to Answer'])
                ) {
                  e.items[index + 1].disabled = false
                  e.items[index + 1].placeHolder = 'Add'
                } else {
                  e.items[index + 1].disabled = true
                  e.items[index + 1].placeHolder = 'Race required'
                }
              }
              if (r.key === 'ethnicity') {
                if (r.value === 'Hispanic or Latino') {
                  e.items[index + 1].disabled = false
                  e.items[index + 1].placeHolder = 'Add'
                } else {
                  e.items[index + 1].disabled = true
                  e.items[index + 1].placeHolder = 'Ethnicity required'
                }
              }
            }
          })
        })
      })
      setItems(itemsCopy)
    }
  }, [loading, data])

  const showConfirmationDialog = (
    newData: DemographicsContactInfo,
    id: DemographicsSection,
    title: string,
    content: string,
    label?: string
  ) => {
    confirm({
      title,
      centered: true,
      okText: 'Continue',
      content,
      onOk() {
        updateDemographics({
          formData: newData,
          patientInfo: data,
          newValueId: id,
          newValueLabel: label,
        })
      },
    })
  }

  const handleSave = (
    newVal: any,
    id: string,
    groupName: string,
    label?: string
  ) => {
    const itemsCopy = cloneDeep(items)
    let newData = { ...data, [id as string]: newVal }
    const groupIndex = itemsCopy.findIndex(
      (e: Items) => e.groupName === groupName
    )
    const itemIndex = itemsCopy[groupIndex].items.findIndex(
      (e: any) => e.id === id
    )
    if (isArray(newVal) && newVal.length === 0) {
      itemsCopy[groupIndex].items[itemIndex].value = undefined
    } else {
      itemsCopy[groupIndex].items[itemIndex].value = newVal
    }

    if (
      id === DemographicsSection.race &&
      itemsCopy[groupIndex].items[itemIndex + 1].options
    ) {
      if (newVal.length > 0) {
        let newSubRaces: Array<any> = []
        newVal.forEach((e: string) => {
          newSubRaces = [
            ...newSubRaces,
            {
              label: e,
              options: subRaces[e].sort(sortRaces),
            },
          ]
        })
        const { hasChanged, hasUnknownValue } = checkRaceSubcategories(
          newVal,
          newSubRaces,
          itemsCopy[groupIndex].items[itemIndex + 1].value
        )
        if (hasChanged || hasUnknownValue) {
          newData = {
            ...newData,
            [itemsCopy[groupIndex].items[itemIndex + 1].id]: [],
          }
          if (itemsCopy[groupIndex].items[itemIndex + 1].value.length > 0) {
            showConfirmationDialog(
              newData,
              id,
              'Race Changes',
              'Changing the Race field will clear any items currently selected in Race subcategory. Are you sure you want to continue?',
              label
            )
            return
          }
        } else {
          itemsCopy[groupIndex].items[itemIndex + 1].options = newSubRaces
          itemsCopy[groupIndex].items[itemIndex + 1].disabled = false
          itemsCopy[groupIndex].items[itemIndex + 1].placeHolder = 'Add'
        }
      } else {
        newData = {
          ...newData,
          [itemsCopy[groupIndex].items[itemIndex + 1].id]: [],
        }
        if (itemsCopy[groupIndex].items[itemIndex + 1].value.length > 0) {
          showConfirmationDialog(
            newData,
            id,
            'Race Changes',
            'Changing the Race field will clear any items currently selected in Race subcategory. Are you sure you want to continue?',
            label
          )
          return
        }
        itemsCopy[groupIndex].items[itemIndex + 1].value = ''
        itemsCopy[groupIndex].items[itemIndex + 1].disabled = true
        itemsCopy[groupIndex].items[itemIndex + 1].placeHolder = 'Race required'
      }
    }
    if (id === DemographicsSection.ethnicity) {
      if (newVal === EthnicityTitleEnum.HISPANIC_LATINO) {
        itemsCopy[groupIndex].items[itemIndex + 1].disabled = false
        itemsCopy[groupIndex].items[itemIndex + 1].placeHolder = 'Add'
      } else {
        itemsCopy[groupIndex].items[itemIndex + 1].disabled = true
        itemsCopy[groupIndex].items[itemIndex + 1].placeHolder =
          'Ethnicity required'
        newData = {
          ...newData,
          [itemsCopy[groupIndex].items[itemIndex + 1].id]: [],
        }
        if (itemsCopy[groupIndex].items[itemIndex + 1].value.length > 0) {
          showConfirmationDialog(
            newData,
            id,
            'Ethnicity Changes',
            'Changing the Ethnicity field will clear any items currently selected in Specific Ethnicity. Are you sure you want to continue?',
            label
          )
          return
        }
      }
    }
    setItems(itemsCopy)

    updateDemographics({
      formData: newData,
      patientInfo: data,
      newValueId: id,
      newValueLabel: label,
    })
  }

  return (
    <SkeletonLoadingTransition
      isLoading={loading}
      skeletonComponent={
        <>
          <Loading />
          <Loading />
          <Loading />
          <Loading />
          <Loading />
        </>
      }
      loadedComponent={
        <div className={styles.topMargin} ref={containerRef}>
          <Fields
            testId={testId}
            items={items}
            compact={compact}
            handleSave={handleSave}
          />
        </div>
      }
    />
  )
}

export default Demographics
