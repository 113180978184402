import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { getAllergySnapshot } from '../../api/patients'
import { AllergyHistory } from '../../shared-types'

export enum AllergyHistoryQueryKeys {
  AllergySnapshot = 'ALLERGY_HISTORY_SNAPSHOT',
}

interface filterOptions {
  status: string
}

interface allergySnapshotParams {
  patientId: string
  timestamp?: Date | null
  options?: filterOptions
  enabled?: boolean
}

export const useAllergySnapshot = ({
  patientId,
  timestamp,
  options,
  enabled = true,
}: allergySnapshotParams): UseQueryResult<AllergyHistory[]> => {
  return useQuery<AllergyHistory[]>(
    [AllergyHistoryQueryKeys.AllergySnapshot, patientId, timestamp],
    {
      queryFn: async () => {
        let results = await getAllergySnapshot(patientId, timestamp)

        if (options?.status) {
          results = results.filter(
            (allergy: AllergyHistory) =>
              allergy.AllergyStatus === options.status
          )
        }

        return results
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  )
}
