import { diacriticNameRegex, legalNameRegex } from '../../../libs/regex'

export async function detectBadCSVValues(_: any, value: any) {
  if (typeof value === 'string') {
    const badValues = ['@', '-', '=', '+']
    const isBad = badValues.some((badChar) => value.startsWith(badChar))
    return isBad
      ? Promise.reject(new Error('Cannot start with @, -, +, or ='))
      : Promise.resolve()
  }
  return Promise.resolve()
}

export function detectBadCSVValuesInReactBootstrap(value: any) {
  if (typeof value === 'string') {
    const badValues = ['@', '-', '=', '+']
    const isBad = badValues.some((badChar) => value.startsWith(badChar))
    return isBad ? true : false
  }
  return false
}

export async function detectBadPatientName(
  value: string,
  shouldIncludeDiacritics = false
) {
  if (!value) return Promise.resolve()

  if (shouldIncludeDiacritics) {
    if (diacriticNameRegex.test(value)) return Promise.resolve()
    return Promise.reject(
      new Error(
        'Only alphanumeric characters, hyphens, diacritic, and apostrophes are supported.'
      )
    )
  }

  if (legalNameRegex.test(value)) return Promise.resolve()

  return Promise.reject(
    new Error(
      'Only alphanumeric characters, hyphens, and apostrophes are supported.'
    )
  )
}
