import React, { useCallback, useMemo } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Select } from 'antd'

import { formatDate } from '../../libs/utils'
import { PlaceOfServiceCode, Practice } from '../../shared-types'
import { NoteDetail } from '../../v2/notes/types'
import { Card, Checkbox, Link, RequiredLabel, Tooltip } from '../BaseComponents'
import { EditableSelect } from '../BaseComponents/EditableInputs'
import { InfoGrid, InfoRow } from '../BaseComponents/InfoGrid'
import { Provider } from '../types'
import { InvoiceComponentProps } from './constants'

import styles from './Invoice.module.scss'

const { Option } = Select

type ApptInfoProps = {
  practice?: Practice
  posCodes?: PlaceOfServiceCode[] | null
  missingFields?: string[]
  providerId?: string | null
  selectedProvider?: Provider
  providers?: Provider[] | null
  appointmentDate?: string
  pos?: PlaceOfServiceCode | null
  includeSignature: boolean
  onSelectProvider: (providerId: string) => void
  onSelectPOS: (posId: number) => void
  onSelectIncludeSignature: (includeSignature: boolean) => void
  hasSuperbill: boolean
  onNoteSelect: (noteId: string) => void
  selectedNoteId?: string
  notes: NoteDetail[]
  patientId: string
} & InvoiceComponentProps

export const InvoiceAppointmentInformation = ({
  isLoading,
  isEditing,
  selectedProvider,
  providers,
  posCodes,
  appointmentDate,
  missingFields = [],
  pos,
  isSuperbillEnabled,
  includeSignature,
  onSelectProvider,
  onSelectPOS,
  onSelectIncludeSignature,
  hasSuperbill,
  onNoteSelect,
  notes,
  selectedNoteId,
  patientId,
  providerId,
}: ApptInfoProps) => {
  const note = useMemo(
    () => notes.find((n) => n.noteId === selectedNoteId),
    [notes, selectedNoteId]
  )
  const noteLink = useMemo(() => {
    if (!note) {
      return '#'
    }
    const versionInsert = note?.version === '1' ? '' : 'v2'
    const linkParts = [
      'patient',
      'clinical-notes',
      versionInsert,
      note.noteId,
    ].filter((p) => !!p)
    return `/${linkParts.join(
      '/'
    )}?patientId=${patientId}&providerId=${providerId}`
  }, [providerId, patientId, note])

  const isProviderUnactivated = useCallback(
    (provider?: Provider | null): boolean => {
      if (!provider || provider.lastActive === 'never_logged_in') {
        return true
      }
      return false
    },
    []
  )
  const isProviderValidAsRendering = useCallback(
    (provider?: Provider | null): boolean => {
      if (
        !provider ||
        provider.isDeactivated ||
        (!provider.name && !provider.email) ||
        !provider.roleTitles?.some((role) => role.label === 'Provider')
      ) {
        return false
      }
      return true
    },
    []
  )

  const buildProviderLabel = useCallback(
    (provider?: Provider | null): string => {
      if (!provider) return ''

      let label = provider.email || ''
      if (provider.name) label = provider.name
      if (provider.credential) label += `, ${provider.credential}`
      return label
    },
    []
  )

  const posCodeOptions = (posCodes || []).map((pos: PlaceOfServiceCode) => (
    <Option
      key={`${pos.version}-${pos.code}`}
      value={JSON.stringify({ code: pos.code, version: pos.version })}
    >
      {pos.name}
    </Option>
  ))

  const cachedPosDetails = React.useMemo(() => {
    return posCodes?.find((p: PlaceOfServiceCode) => p.code === pos?.code)
  }, [posCodes, pos])

  const tooltipMessage = hasSuperbill
    ? 'You cannot change a linked note after a superbill has been created.'
    : 'You can link a note to an invoice.'

  let rows: InfoRow[] = [
    {
      label: (
        <RequiredLabel
          content={
            <>
              <span>Link to a chart note: </span>
              <Tooltip title={tooltipMessage}>
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          }
          showAsterisk={isSuperbillEnabled && isEditing}
        />
      ),
      content:
        !isEditing && selectedNoteId ? (
          /* use || to catch v1 notes that default titles to empty strings */
          <Link to={noteLink}>{note?.title || 'No title'}</Link>
        ) : (
          <EditableSelect
            onChange={onNoteSelect}
            shouldHighlight={missingFields.includes('noteId')}
            label={
              selectedNoteId ? note?.title || 'No title' : 'Select a chart note'
            }
            placeholder="Select a chart note"
            value={selectedNoteId}
            disabled={!isEditing || hasSuperbill}
            options={notes.map((noteOption) => {
              // use || to catch v1 notes that default titles to empty strings
              const title = noteOption.title || 'No title'
              return (
                <Option
                  key={`invoice-form-note-${noteOption.noteId}`}
                  value={noteOption.noteId}
                >
                  {title}
                </Option>
              )
            })}
          />
        ),
    },
    ...(appointmentDate
      ? [
          {
            label: 'Appointment date:',
            content: formatDate({
              value: appointmentDate,
              // we intentionally set CreatedOn to be interpreted as local time so that the date does not shift
              // prevent the datepicker from trying to shift to UTC
              shouldLocalize: true,
              textWhenInvalid: '--',
            }),
          },
          {
            label: <RequiredLabel content="POS:" showAsterisk={isEditing} />,
            content: (
              <EditableSelect
                testId="pos-select"
                shouldHighlight={missingFields.includes('pos')}
                disabled={!isEditing}
                value={pos?.name || cachedPosDetails?.name}
                placeholder={isEditing ? 'Select POS...' : ''}
                onChange={(stringifiedPOS?: string) => {
                  stringifiedPOS?.length &&
                    onSelectPOS(JSON.parse(stringifiedPOS))
                }}
                options={posCodeOptions}
              />
            ),
          },
        ]
      : []),
    {
      label: (
        <RequiredLabel content="Rendering provider:" showAsterisk={isEditing} />
      ),
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
          }}
        >
          <EditableSelect
            shouldHighlight={!isProviderValidAsRendering(selectedProvider)}
            value={buildProviderLabel(selectedProvider)}
            disabled={!isEditing}
            placeholder={isEditing ? 'Select provider...' : ''}
            onChange={(providerId: string) => {
              if (
                Array.isArray(providerId) &&
                providerId.length === 1 &&
                providerId[0] === ''
              ) {
                return
              }
              onSelectProvider(providerId)
            }}
            options={(providers || []).map((provider: Provider) => {
              const isUnactivated = isProviderUnactivated(provider)
              const isValidAsRendering = isProviderValidAsRendering(provider)
              const label = buildProviderLabel(provider)
              if (!isValidAsRendering || !label) {
                return <></>
              }
              return (
                <Option disabled={isUnactivated} value={provider.cognitoId}>
                  {label}
                </Option>
              )
            })}
          />
          {isSuperbillEnabled && !!buildProviderLabel(selectedProvider) && (
            <Checkbox
              disabled={!isEditing}
              checked={includeSignature}
              onChange={(e) =>
                onSelectIncludeSignature(e?.target?.checked || false)
              }
            >
              Include signature
            </Checkbox>
          )}
        </div>
      ),
    },
  ]

  if (isSuperbillEnabled) {
    rows = rows.concat([
      {
        label: 'Rendering Provider NPI:',
        content: selectedProvider?.billingNPI,
      },
    ])
  }

  return (
    <Card loading={isLoading} className={styles.appointmentInfoContainer}>
      <div className={styles.sectionHeader}>Appointment information</div>
      <InfoGrid rows={rows} />
    </Card>
  )
}
