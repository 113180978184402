// Import react
import { useEffect, useState } from 'react'

import moment from 'moment'
import 'moment-timezone'
// Import components
import { Button, Col, Container, Form } from 'react-bootstrap'
import { SingleDatePicker } from 'react-dates'
// Import libs/other
import 'react-dates/initialize'

import { getCaretakerData } from '../../../api/api-lib'
import { onError } from '../../../libs/errorLib'
import { LegacyNoteTypes, NoteTypes } from '../../../shared-types'
import { LegacyFormItem } from '../../../stories/BaseComponents'
import EvalNote from './ClinicalNotesComponents/EvalNote'
import IMKetamine from './ClinicalNotesComponents/IMKetamine.js'
import IVKetamine from './ClinicalNotesComponents/IVKetamine.js'
import KAP from './ClinicalNotesComponents/KAP.js'
import MedicalSOAP from './ClinicalNotesComponents/MedicalSOAP.js'
import Memo from './ClinicalNotesComponents/Memo.js'
import Spravato from './ClinicalNotesComponents/NoteType/Spravato/Spravato'
import PatientInfo from './ClinicalNotesComponents/PatientInfo'
import ProviderInfo from './ClinicalNotesComponents/ProvidersInfo'
import PsychotherapyNotes from './ClinicalNotesComponents/PsychotherapyNotes.js'
import Addendums from './ClinicalNotesComponents/Sections/Addendums'
import AuditTrail from './ClinicalNotesComponents/Sections/AuditTrail'
import NewSignature from './ClinicalNotesComponents/Sections/NewSignature'
import NoteTypeSelect from './ClinicalNotesComponents/Sections/NoteTypeSelect'
import SimpleClinicalNote from './ClinicalNotesComponents/SimpleClinicalNote.js'
import TherapySOAP from './ClinicalNotesComponents/TherapySOAP.js'

import './NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function DetailClinicalNoteForm(props) {
  // TODO: create form validation *******
  const { setIsFormValid } = props
  const [focused, setFocused] = useState(null)
  const [addVitals, setAddVitals] = useState(false)
  const [saltNotAcknowledged, setSaltNotAcknowledged] = useState(props.salt)
  const [newVitalsTime, setNewVitalsTime] = useState(moment().format('HH:mm'))
  const [showSavedVitals, setShowSavedVitals] = useState(false)
  const [addAdditionalMedication, setAddAdditionalMedication] = useState(false)
  const [addDischargeVitals, setAddDischargeVitals] = useState(
    !!(
      props.note?.SavedDischargeVitalSigns &&
      Object.keys(props.note?.SavedDischargeVitalSigns).length !== 0
    )
  )

  const defaultAdministrationRoutes = [
    'IV Infusion',
    'IM',
    'Oral (swallowed)',
    'Sublingual (under the tongue)',
    'Intranasal',
    'Other (please specify)',
  ]
  const pregnantStatus = ['Negative', 'Positive', 'Declined']
  const catheter = ['20g', '22g', '24g', 'Other (type in)']
  const treatmentTypes = ['Induction', 'Booster']
  const createdOnDate = props.note?.CreatedOnDate
  const { fields, handleFieldChange } = props
  const isNoteTypeSelected = fields.NoteType !== ''

  useEffect(() => {
    if (props.saltFormattingFinalized) {
      setSaltNotAcknowledged(true)
      props.setSaltFormattingFinalized(false)
    }
  }, [props.saltFormattingFinalized])

  const isDisabled = () => {
    return fields.Signatures?.length ? true : props.handleEdit
  }

  function saveNewDischargeVitals() {
    setAddDischargeVitals(true)
    const newDischargeVitalSigns = {
      VitalSignsType: '',
      Time: moment().format('HH:mm'),
      BP: '',
      HR: '',
      RR: '',
      SPO2: '',
      Comments: '',
      EKG: '',
      RASSscore: '',
    }
    handleFieldChange({
      ...fields,
      SavedDischargeVitalSigns: newDischargeVitalSigns,
    })
    setShowSavedVitals(true)
  }

  function saveNewVitals() {
    setAddVitals(false)
    const newVitalSigns = [
      {
        VitalSignsType: '',
        Time: moment().format('HH:mm'),
        BP: '',
        HR: '',
        RR: '',
        SPO2: '',
        TemperatureUnits: '',
        Comments: '',
        EKG: '',
        RASSscore: '',
      },
    ]
    if (fields.SavedVitalSigns) {
      handleFieldChange({
        ...fields,
        SavedVitalSigns: fields.SavedVitalSigns.concat(newVitalSigns),
      })
    } else {
      handleFieldChange({ ...fields, SavedVitalSigns: newVitalSigns })
    }
    setShowSavedVitals(true)
  }

  async function saveNewCaretakerVitals() {
    try {
      const caretakerData = await getCaretakerData(
        props.patient?.ShortPublicId,
        fields.NoteDate
      )
      setAddVitals(false)
      const newVitalSigns = caretakerData.map((vital) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { Timestamp, UTCOffset, ...rest } = vital
        const isEmpty = Object.values(rest).every((x) => x === null || x === '')
        return {
          VitalSignsType: '',
          Time: moment(vital.Timestamp, 'X')
            .utcOffset(vital.UTCOffset)
            .format('HH:mm'),
          BP:
            vital.Systolic && vital.Diastolic
              ? `${vital.Systolic}/${vital.Diastolic}`
              : '',
          HR: vital.HeartRate,
          RR: vital.RespiratoryRate,
          SPO2: vital.SPO2,
          TemperatureUnits: '',
          Comments: isEmpty ? 'No data sent from Caretaker at this time' : '',
          EKG: '',
          RASSscore: '',
        }
      })
      if (fields.SavedVitalSigns) {
        handleFieldChange({
          ...fields,
          SavedVitalSigns: fields.SavedVitalSigns.concat(newVitalSigns),
        })
      } else {
        handleFieldChange({ ...fields, SavedVitalSigns: newVitalSigns })
      }
      setShowSavedVitals(true)
    } catch (e) {
      onError(
        e,
        e.response.status,
        `${e.response.data.error}. Please inform your administrator.`
      )
    }
  }

  function saveNewMedicationProgress(medName) {
    const newMedProgress = [
      {
        MedicationName: medName,
        Efficacy: '',
        Adherence: '',
        SideEffects: '',
        SideEffectDescription: '',
      },
    ]
    const auxMedProg = fields.SavedAdditionalMedications.concat(newMedProgress)
    handleFieldChange({ ...fields, SavedAdditionalMedications: auxMedProg })
  }

  function saveNewEntries() {
    const newEntries = [
      {
        Time: moment().format('HH:mm'),
        Dose: '',
        TotalDose: '',
        Location: '',
        Notes: '',
      },
    ]
    if (fields.SavedEntries) {
      handleFieldChange({
        ...fields,
        SavedEntries: fields.SavedEntries.concat(newEntries),
      })
    } else {
      handleFieldChange({ ...fields, SavedEntries: newEntries })
    }
  }

  function saveNewEntriesKAP() {
    const newEntries = [
      {
        Time: moment().format('HH:mm'),
        Dose: '',
        TotalDose: '',
        Location: '',
        Notes: '',
        Route: '',
        RouteOther: '',
        MedicationType: '',
        MedicationTypeOther: '',
      },
    ]
    if (fields.SavedEntries) {
      handleFieldChange({
        ...fields,
        SavedEntries: fields.SavedEntries.concat(newEntries),
      })
    } else {
      handleFieldChange({ ...fields, SavedEntries: newEntries })
    }
  }

  function saveNewEntriesSpravato() {
    const newEntries = [
      {
        Type: '',
        Date: null,
        Description: '',
        Resolution: '',
      },
    ]
    if (fields.SavedAdditionalEntries) {
      handleFieldChange({
        ...fields,
        SavedEntries: fields.SavedEntries.concat(newEntries),
      })
    } else {
      handleFieldChange({ ...fields, SavedEntries: newEntries })
    }
  }

  function saveNewAdditionalEntriesSpravato() {
    const newEntries = [
      {
        Type: '',
        Date: null,
        Description: '',
        Resolution: '',
      },
    ]
    if (fields.SavedAdditionalEntries) {
      handleFieldChange({
        ...fields,
        SavedAdditionalEntries:
          fields.SavedAdditionalEntries.concat(newEntries),
      })
    } else {
      handleFieldChange({ ...fields, SavedAdditionalEntries: newEntries })
    }
  }

  function saveNewMedicationSpravato() {
    const newMedication = [
      {
        Name: '',
        Dose: '',
        Time: '',
        Comments: '',
      },
    ]
    if (fields.SavedAdditionalMedications) {
      handleFieldChange({
        ...fields,
        SavedAdditionalMedications:
          fields.SavedAdditionalMedications.concat(newMedication),
      })
    } else {
      handleFieldChange({
        ...fields,
        SavedAdditionalMedications: newMedication,
      })
    }
  }

  function saveAdditionalMedicationsDissociation() {
    const newMedication = [
      {
        Name: '',
        Dose: '',
        Time: '',
        Comments: '',
      },
    ]
    if (fields.SavedAdditionalMedicationsDissociation) {
      handleFieldChange({
        ...fields,
        SavedAdditionalMedicationsDissociation:
          fields.SavedAdditionalMedicationsDissociation.concat(newMedication),
      })
    } else {
      handleFieldChange({
        ...fields,
        SavedAdditionalMedicationsDissocation: newMedication,
      })
    }
  }

  function saveAdditionalMedicationsSedation() {
    const newMedication = [
      {
        Name: '',
        Dose: '',
        Time: '',
        Comments: '',
      },
    ]
    if (fields.SavedAdditionalMedicationsSedation) {
      handleFieldChange({
        ...fields,
        SavedAdditionalMedicationsSedation:
          fields.SavedAdditionalMedicationsSedation.concat(newMedication),
      })
    } else {
      handleFieldChange({
        ...fields,
        SavedAdditionalMedicationsSedation: newMedication,
      })
    }
  }

  function saveNewAdditionalMedication() {
    setAddAdditionalMedication(false)
    const newAditionalMedication = [
      {
        AdditionalMedicationName: '',
        AdditionalMedicationOther: '',
        AdditionalMedicationAdministrationRoute: '',
        AdditionalMedicationOtherAdministrationRoute: '',
        AdditionalMedicationDosage: '',
        AdditionalMedicationUnits: '',
        AdditionalMedicationUnitsOther: '',
        AdditionalMedicationTime: moment().format('HH:mm'),
      },
    ]
    handleFieldChange({
      ...fields,
      SavedAdditionalMedications: fields.SavedAdditionalMedications.concat(
        newAditionalMedication
      ),
    })
  }

  function deleteVitals(index) {
    const array = fields.SavedVitalSigns.slice()
    array.splice(index, 1)
    handleFieldChange({ ...fields, SavedVitalSigns: array })
    setShowSavedVitals(!showSavedVitals)
  }

  function deleteMedProgress(index) {
    const array = fields.SavedAdditionalMedications.slice()
    array.splice(index, 1)
    handleFieldChange({ ...fields, SavedAdditionalMedications: array })
  }

  function deleteEntries(index) {
    const array = fields.SavedEntries.slice()
    array.splice(index, 1)
    handleFieldChange({ ...fields, SavedEntries: array })
  }

  function deleteAdditionalEntries(index) {
    const array = fields.SavedAdditionalEntries.slice()
    array.splice(index, 1)
    handleFieldChange({ ...fields, SavedAdditionalEntries: array })
  }

  function saveEditDischargeVitalSigns(index, editVitSign) {
    handleFieldChange({ ...fields, SavedDischargeVitalSigns: editVitSign })
  }

  function saveEditEntries(index, editEntry) {
    const addEntry = fields.SavedEntries
    addEntry[index] = editEntry
    handleFieldChange({ ...fields, SavedEntries: addEntry })
  }

  function saveEditSpravatoEntries(index, editEntry) {
    const addEntry = fields.SavedEntries.slice()
    addEntry[index] = editEntry
    handleFieldChange({ ...fields, SavedEntries: addEntry })
  }

  function saveEditAdditionalEntries(index, editEntry) {
    const addEntry = fields.SavedAdditionalEntries.slice()
    addEntry[index] = editEntry
    handleFieldChange({ ...fields, SavedAdditionalEntries: addEntry })
  }

  function saveEditVitalSigns(index, editVitSign) {
    const addVitSign = fields.SavedVitalSigns
    addVitSign[index] = editVitSign
    handleFieldChange({ ...fields, SavedVitalSigns: addVitSign })
  }

  function saveStartSpravatoVitals(editVitSign) {
    let addVitSign = fields.StartSpravatoVitals
    addVitSign = editVitSign
    handleFieldChange({ ...fields, StartSpravatoVitals: addVitSign })
  }

  function saveFortyMinSpravatoVitals(editVitSign) {
    let addVitSign = fields.FortyMinSpravatoVitals
    addVitSign = editVitSign
    handleFieldChange({ ...fields, FortyMinSpravatoVitals: addVitSign })
  }

  function saveEndSpravatoVitals(editVitSign) {
    let addVitSign = fields.EndSpravatoVitals
    addVitSign = editVitSign
    handleFieldChange({ ...fields, EndSpravatoVitals: addVitSign })
  }

  function saveEditMedProgress(index, editMedProgress) {
    const addMedPro = fields.SavedAdditionalMedications
    addMedPro[index] = editMedProgress
    handleFieldChange({ ...fields, SavedAdditionalMedications: addMedPro })
  }

  function deleteAM(index) {
    const array = fields.SavedAdditionalMedications.slice()
    array.splice(index, 1)
    handleFieldChange({ ...fields, SavedAdditionalMedications: array })
    setShowSavedVitals(!showSavedVitals)
  }

  function deleteAMSedation(index) {
    const array = fields.SavedAdditionalMedicationsSedation.slice()
    array.splice(index, 1)
    handleFieldChange({ ...fields, SavedAdditionalMedicationsSedation: array })
  }

  function deleteAMDissociation(index) {
    const array = fields.SavedAdditionalMedicationsDissociation.slice()
    array.splice(index, 1)
    handleFieldChange({
      ...fields,
      SavedAdditionalMedicationsDissociation: array,
    })
  }

  function deleteDischarge() {
    handleFieldChange({ ...fields, SavedDischargeVitalSigns: {} })
  }

  function saveEditAdditionalMedication(index, editAddMed) {
    const addMed = fields.SavedAdditionalMedications
    addMed[index] = editAddMed
    handleFieldChange({ ...fields, SavedAdditionalMedications: addMed })
  }

  function saveEditAdditionalMedicationSedation(index, editAddMed) {
    const addMed = fields.SavedAdditionalMedicationsSedation
    addMed[index] = editAddMed
    handleFieldChange({ ...fields, SavedAdditionalMedicationsSedation: addMed })
  }

  function saveEditAdditionalMedicationDissociation(index, editAddMed) {
    const addMed = fields.SavedAdditionalMedicationsDissociation
    addMed[index] = editAddMed
    handleFieldChange({
      ...fields,
      SavedAdditionalMedicationsDissociation: addMed,
    })
  }

  const renderBodyOfNoteType = () => (
    <>
      <hr />
      <PatientInfo
        noteType={fields.NoteType}
        noteDate={fields.NoteDate}
        startTime={fields.StartTime}
        signedAt={props.signedAt}
        patient={props.patient}
        Diagnosis={props.Diagnosis}
        activeMeds={props.activeMeds}
        Allergies={props.Allergies}
        handleApiChange={props.updateCache}
        handleEdit={props.handleEdit}
        handleEditingAllergies={props.setIsAllergiesBeingEdited}
      />
      {fields.NoteType === NoteTypes.CLINICAL_NOTE ? (
        <SimpleClinicalNote
          fields={fields}
          handleFieldChange={handleFieldChange}
          patient={props.patient}
          addAdditionalMedication={addAdditionalMedication}
          setAddAdditionalMedication={setAddAdditionalMedication}
          defaultAdministrationRoutes={defaultAdministrationRoutes}
          deleteVitals={deleteVitals}
          deleteAM={deleteAM}
          addVitals={addVitals}
          setAddVitals={setAddVitals}
          newVitalsTime={newVitalsTime}
          setNewVitalsTime={setNewVitalsTime}
          disabled={isDisabled()}
          detail
        />
      ) : fields.NoteType === NoteTypes.EVALUATION_NOTE ? (
        <EvalNote
          fields={fields}
          handleFieldChange={handleFieldChange}
          patient={props.patient}
          addAdditionalMedication={addAdditionalMedication}
          setAddAdditionalMedication={setAddAdditionalMedication}
          defaultAdministrationRoutes={defaultAdministrationRoutes}
          deleteVitals={deleteVitals}
          deleteAM={deleteAM}
          addVitals={addVitals}
          setAddVitals={setAddVitals}
          newVitalsTime={newVitalsTime}
          setNewVitalsTime={setNewVitalsTime}
          disabled={isDisabled()}
          detail
        />
      ) : fields.NoteType === NoteTypes.MEMO ? (
        <Memo
          fields={fields}
          handleFieldChange={handleFieldChange}
          detail
          disabled={isDisabled()}
        />
      ) : fields.NoteType === LegacyNoteTypes.IV_KETAMINE_INFUSION ||
        fields.NoteType === NoteTypes.IV_KETAMINE ? (
        <IVKetamine
          fields={fields}
          handleFieldChange={handleFieldChange}
          patient={props.patient}
          Allergies={props.Allergies}
          Diagnosis={props.Diagnosis}
          activeMeds={props.activeMeds}
          pregnantStatus={pregnantStatus}
          saveNewEntries={saveNewEntries}
          saveEditEntries={saveEditEntries}
          deleteAM={deleteAM}
          saveEditAdditionalMedication={saveEditAdditionalMedication}
          deleteVitals={deleteVitals}
          saveNewAdditionalMedication={saveNewAdditionalMedication}
          saveNewVitals={saveNewVitals}
          saveNewCaretakerVitals={saveNewCaretakerVitals}
          addVitals={addVitals}
          setAddVitals={setAddVitals}
          deleteDischarge={deleteDischarge}
          saveEditVitalSigns={saveEditVitalSigns}
          saveNewDischargeVitals={saveNewDischargeVitals}
          addDischargeVitals={addDischargeVitals}
          setAddDischargeVitals={setAddDischargeVitals}
          saveEditDischargeVitalSigns={saveEditDischargeVitalSigns}
          catheter={catheter}
          disabled={isDisabled()}
          detail
        />
      ) : fields.NoteType === NoteTypes.IM_KETAMINE ? (
        <IMKetamine
          fields={fields}
          handleFieldChange={handleFieldChange}
          patient={props.patient}
          Allergies={props.Allergies}
          Diagnosis={props.Diagnosis}
          activeMeds={props.activeMeds}
          pregnantStatus={pregnantStatus}
          saveNewEntries={saveNewEntries}
          saveEditEntries={saveEditEntries}
          deleteAM={deleteAM}
          setAddAdditionalMedication={setAddAdditionalMedication}
          saveEditAdditionalMedication={saveEditAdditionalMedication}
          deleteVitals={deleteVitals}
          saveNewVitals={saveNewVitals}
          addVitals={addVitals}
          setAddVitals={setAddVitals}
          saveEditVitalSigns={saveEditVitalSigns}
          saveNewDischargeVitals={saveNewDischargeVitals}
          addDischargeVitals={addDischargeVitals}
          setAddDischargeVitals={setAddDischargeVitals}
          deleteDischarge={deleteDischarge}
          saveEditDischargeVitalSigns={saveEditDischargeVitalSigns}
          deleteEntries={deleteEntries}
          disabled={isDisabled()}
          saveNewCaretakerVitals={saveNewCaretakerVitals}
          detail
          createdOnDate={createdOnDate}
        />
      ) : fields.NoteType === NoteTypes.KAP ? (
        <KAP
          fields={fields}
          handleFieldChange={handleFieldChange}
          patient={props.patient}
          Allergies={props.Allergies}
          Diagnosis={props.Diagnosis}
          activeMeds={props.activeMeds}
          pregnantStatus={pregnantStatus}
          saveNewEntriesKAP={saveNewEntriesKAP}
          saveEditEntries={saveEditEntries}
          deleteAM={deleteAM}
          setAddAdditionalMedication={setAddAdditionalMedication}
          saveEditAdditionalMedication={saveEditAdditionalMedication}
          deleteVitals={deleteVitals}
          saveNewVitals={saveNewVitals}
          addVitals={addVitals}
          setAddVitals={setAddVitals}
          saveEditVitalSigns={saveEditVitalSigns}
          saveNewDischargeVitals={saveNewDischargeVitals}
          addDischargeVitals={addDischargeVitals}
          deleteDischarge={deleteDischarge}
          setAddDischargeVitals={setAddDischargeVitals}
          saveEditDischargeVitalSigns={saveEditDischargeVitalSigns}
          deleteEntries={deleteEntries}
          disabled={isDisabled()}
          saveNewCaretakerVitals={saveNewCaretakerVitals}
          detail
          createdOnDate={createdOnDate}
        />
      ) : fields.NoteType === NoteTypes.MEDICAL_SOAP ? (
        <MedicalSOAP
          fields={fields}
          handleFieldChange={handleFieldChange}
          patient={props.patient}
          Diagnosis={props.Diagnosis}
          activeMeds={props.activeMeds}
          pregnantStatus={pregnantStatus}
          deleteVitals={deleteVitals}
          saveNewVitals={saveNewVitals}
          addVitals={addVitals}
          setAddVitals={setAddVitals}
          previousNotes={props.previousNotes.filter((note) => {
            return note.NoteType === NoteTypes.MEDICAL_SOAP
          })}
          saveEditVitalSigns={saveEditVitalSigns}
          saveNewMedicationProgress={saveNewMedicationProgress}
          saveEditMedProgress={saveEditMedProgress}
          deleteMedProgress={deleteMedProgress}
          disabled={isDisabled()}
          detail
        />
      ) : fields.NoteType === NoteTypes.THERAPY_SOAP ? (
        <TherapySOAP
          fields={fields}
          handleFieldChange={handleFieldChange}
          patient={props.patient}
          Diagnosis={props.Diagnosis}
          previousNotes={props.previousNotes.filter((note) => {
            return note.NoteType === NoteTypes.MEDICAL_SOAP
          })}
          disabled={isDisabled()}
          saveNewCaretakerVitals={saveNewCaretakerVitals}
          detail
        />
      ) : fields.NoteType === NoteTypes.PSYCHOTHERAPY_NOTE ? (
        <PsychotherapyNotes
          fields={fields}
          handleFieldChange={handleFieldChange}
          patient={props.patient}
          Diagnosis={props.Diagnosis}
          previousKAPSOAPNotes={props.previousNotes}
          previousNotes={props.previousNotes.filter((note) => {
            return note.NoteType === NoteTypes.PSYCHOTHERAPY_NOTE
          })}
          disabled={isDisabled()}
          saveNewCaretakerVitals={saveNewCaretakerVitals}
          detail
        />
      ) : fields.NoteType === NoteTypes.SPRAVATO ? (
        <Spravato
          fields={fields}
          handleFieldChange={handleFieldChange}
          patient={props.patient}
          Allergies={props.Allergies}
          Diagnosis={props.Diagnosis}
          activeMeds={props.activeMeds}
          pregnantStatus={pregnantStatus}
          deleteVitals={deleteVitals}
          saveNewVitals={saveNewVitals}
          saveNewCaretakerVitals={saveNewCaretakerVitals}
          addVitals={addVitals}
          setAddVitals={setAddVitals}
          previousNotes={props.previousNotes.filter((note) => {
            return note.NoteType === NoteTypes.SPRAVATO
          })}
          saveEditVitalSigns={saveEditVitalSigns}
          deleteEntries={deleteEntries}
          deleteAdditionalEntries={deleteAdditionalEntries}
          saveNewEntriesSpravato={saveNewEntriesSpravato}
          saveNewAdditionalEntriesSpravato={saveNewAdditionalEntriesSpravato}
          saveEditEntries={saveEditSpravatoEntries}
          saveEditAdditionalEntries={saveEditAdditionalEntries}
          saveNewMedicationSpravato={saveNewMedicationSpravato}
          saveAdditionalMedicationsDissociation={
            saveAdditionalMedicationsDissociation
          }
          saveAdditionalMedicationsSedation={saveAdditionalMedicationsSedation}
          saveEditAdditionalMedication={saveEditAdditionalMedication}
          saveEditAdditionalMedicationDissociation={
            saveEditAdditionalMedicationDissociation
          }
          saveEditAdditionalMedicationSedation={
            saveEditAdditionalMedicationSedation
          }
          deleteAM={deleteAM}
          saveStartSpravatoVitals={saveStartSpravatoVitals}
          saveFortyMinSpravatoVitals={saveFortyMinSpravatoVitals}
          saveEndSpravatoVitals={saveEndSpravatoVitals}
          deleteAMSedation={deleteAMSedation}
          deleteAMDissociation={deleteAMDissociation}
          disabled={isDisabled()}
          detail
        />
      ) : (
        ''
      )}
      <AuditTrail
        createdOnDate={props.auditTrail?.createdOnDate}
        createdOn={props.auditTrail?.createdOn}
        createdBy={props.auditTrail?.createdBy}
        editedOnDateBy={props.auditTrail?.editedOnDateBy}
      />
      <NewSignature
        fields={fields}
        handleFieldChange={handleFieldChange}
        disabled={props.handleEdit}
        handleClose={props.handleClose}
        PatientId={props.patient?.PatientId}
        NoteId={props.noteId}
        patient={props.patient}
        updateCacheWithNav={props.updateCacheWithNav}
        updateCache={props.updateCache}
        clinicInfo={props.clinicInfo}
        isValid={props.isValid}
        handleSave={props.handleSave}
        isSaving={props.isSaving}
        setShowSignedAndValid={props.setShowSignedAndValid}
        setShowSignedAndInvalid={props.setShowSignedAndInvalid}
        isAddSignatureDisabled={props.isAddSignatureDisabled}
      />
      {fields.Signatures?.length ? (
        <Addendums
          fields={fields}
          providerEmail={props.clinicInfo?.providerEmail}
          patient={props.patient}
          handleApiChange={props.updateCacheWithNav}
          handleClose={props.handleClose}
          PatientId={props.patient?.PatientId}
          NoteId={props.note?.NoteId}
        />
      ) : (
        ''
      )}
      {props.handleEdit ? (
        ''
      ) : (
        <>
          {fields.NoteType === NoteTypes.SPRAVATO && (
            <>
              {props.isValid ? (
                <div
                  style={{ width: '100%', padding: 20, marginBottom: 10 }}
                  className=""
                >
                  <div style={{ marginBottom: 0 }}>
                    <span>
                      <i
                        className="fa fa-check text-success"
                        aria-hidden="true"
                        style={{ fontSize: 40 }}
                      />
                    </span>
                    The fields required for the REMS Patient Monitoring form
                    have been completed. Signing the note will automatically
                    submit the REMS form.
                  </div>
                </div>
              ) : (
                <div
                  style={{ width: '100%', padding: 20 }}
                  className="salt-message-container"
                >
                  <i
                    className="fa fa-exclamation-triangle"
                    aria-hidden="true"
                    style={{ fontSize: 40 }}
                  />
                  <div style={{ marginLeft: 24 }}>
                    <div style={{ marginBottom: 0 }}>
                      The fields required for the REMS Patient Monitoring form
                      have not been completed. Complete the highlighted fields
                      to automatically submit the REMS form.
                    </div>
                  </div>
                  <div style={{ flexGrow: 1 }} />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  )

  return (
    <Container id="clinical-note" className="note-page-body">
      <Form>
        {/* show disclaimer message if this note is being copied over from previous note and the warning hasn't been acknowledged yet */}
        {props.salt && saltNotAcknowledged && (
          <div className="salt-message-container">
            <i
              className="fa fa-exclamation-triangle"
              aria-hidden="true"
              style={{ fontSize: 40 }}
            />
            <div style={{ marginLeft: 24 }}>
              <div style={{ marginBottom: 12 }}>
                Information from a previous note has been imported into this
                note.
              </div>
              <li style={{ marginLeft: 20, marginBottom: 12 }}>
                Sections that have likely changed, such as timestamps, vitals,
                and signatures, are not copied over.
              </li>
              <div style={{ fontWeight: 'bold' }}>
                Please double check all imported information to avoid loss of
                information and to ensure accuracy.
              </div>
            </div>
            <div style={{ flexGrow: 1 }} />
            <Button
              variant="outline-dark"
              className="salt-acknowledge"
              onClick={() => setSaltNotAcknowledged(false)}
            >
              Acknowledge
            </Button>
          </div>
        )}
        {!props.handleEdit && (
          <Form.Text>
            <span className="danger">*</span> Required.
          </Form.Text>
        )}
        <br />
        <Form.Row>
          <Form.Group as={Col} sm={12} md={8} lg={6} controlId="Title">
            <Form.Label style={{ fontWeight: 'bold' }}>Note Title</Form.Label>
            <LegacyFormItem
              setIsFormValid={setIsFormValid}
              bsPrefix="form-input"
              value={fields.Title}
              onChange={(e) =>
                handleFieldChange({ ...fields, Title: e.target.value })
              }
              readOnly={isDisabled()}
              disabled={isDisabled()}
            />
          </Form.Group>
          <Form.Group as={Col} sm={12} md={4} lg={3} controlId="NoteType">
            <NoteTypeSelect
              disabled={props.handleEdit}
              value={fields.NoteType}
              handleFieldChange={(value) => {
                handleFieldChange({ ...fields, NoteType: value })
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md={5} lg={2} controlId="NoteDate">
            <Form.Label className="form-label" style={{ fontWeight: 'bold' }}>
              Date {!props.handleEdit && <span className="danger">*</span>}
            </Form.Label>
            <SingleDatePicker
              date={fields.NoteDate} // momentPropTypes.momentObj or null
              onDateChange={(date) =>
                handleFieldChange({ ...fields, NoteDate: date })
              } // PropTypes.func.isRequired
              id="NoteDate" // PropTypes.string.isRequired,
              focused={focused} // PropTypes.bool
              onFocusChange={(focused) => setFocused(focused)}
              numberOfMonths={1}
              displayFormat="MM/DD/YYYY"
              onClose={() => setFocused(false)}
              isOutsideRange={() => false}
              disabled={isDisabled()}
            />
          </Form.Group>
          {isNoteTypeSelected && [
            fields.NoteType === NoteTypes.CLINICAL_NOTE ? (
              <Form.Group as={Col} lg={3} controlId="ReceivedTreatment">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Add a procedure/treatment:{' '}
                  {!props.handleEdit && <span className="danger">*</span>}
                </Form.Label>
                <Form.Check
                  bsPrefix="form-check"
                  checked={JSON.parse(fields.ReceivedTreatment)}
                  value="true"
                  onChange={(e) =>
                    handleFieldChange({
                      ...fields,
                      ReceivedTreatment: e.target.value,
                    })
                  }
                  type="radio"
                  label="Yes"
                  id="ReceivedTreatmentYes"
                  disabled={isDisabled()}
                />
                <Form.Check
                  bsPrefix="form-check"
                  checked={!JSON.parse(fields.ReceivedTreatment)}
                  value="false"
                  onChange={(e) =>
                    handleFieldChange({
                      ...fields,
                      ReceivedTreatment: e.target.value,
                    })
                  }
                  type="radio"
                  label="No"
                  id="ReceivedTreatmentNo"
                  disabled={isDisabled()}
                />
              </Form.Group>
            ) : (
              ''
            ),
            fields.NoteType === NoteTypes.IV_KETAMINE ||
            fields.NoteType === NoteTypes.IM_KETAMINE ||
            fields.NoteType === NoteTypes.KAP ? (
              <Form.Group
                as={Col}
                sm={12}
                md={6}
                lg={3}
                controlId="TreatmentType"
              >
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Treatment Type (session)
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  as="select"
                  onChange={(e) =>
                    handleFieldChange({
                      ...fields,
                      TreatmentType: e.target.value,
                    })
                  }
                  disabled={isDisabled()}
                  value={fields.TreatmentType}
                >
                  <option disabled key={0} name="Select one" value="">
                    Select one
                  </option>
                  {treatmentTypes.map((type, index) => (
                    <option key={index + 1} name={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            ) : (
              ''
            ),
            (fields.NoteType === NoteTypes.IV_KETAMINE ||
              fields.NoteType === NoteTypes.IM_KETAMINE ||
              fields.NoteType === NoteTypes.KAP) &&
            fields.TreatmentType === 'Induction' ? (
              <Form.Group as={Col} lg={2} xl={2} controlId="InductionNumber">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Induction Number
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  onChange={(e) =>
                    handleFieldChange({
                      ...fields,
                      InductionNumber: e.target.value,
                    })
                  }
                  value={fields.InductionNumber}
                  disabled={isDisabled()}
                  as="textarea"
                  rows="1"
                />
              </Form.Group>
            ) : (
              ''
            ),
            <ProviderInfo
              fields={fields}
              handleFieldChange={handleFieldChange}
              disabled={props.handleEdit}
            />,
          ]}
        </Form.Row>
        {/* If note type is not selected dont show the fields */}
        {isNoteTypeSelected && renderBodyOfNoteType()}
      </Form>
    </Container>
  )
}
