import React, { useMemo, useRef, useState } from 'react'

import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import { debounce } from 'lodash'

import { Spinner, Tooltip } from '../../../stories/BaseComponents'
import { search } from './NationalDrugCodeAPI'

import styles from './NationalDrugCodeSelect.module.scss'

export type NationalDrugCodeSelectProps = {
  value: string
  initialLabel?: string | null
  onChange: (option: NdcOption) => void
  disabled?: boolean
  popupContainerId: string
  style?: React.CSSProperties
  testId: string
}

export type NdcOption = {
  label: string
  value: string
}

const placeholder = 'Search by drug name, package NDC, or description'
const SEARCH_DELAY_MILLISECONDS = 400

const NationalDrugCodeSelect: React.FC<NationalDrugCodeSelectProps> = ({
  value,
  initialLabel, // label for initially selected value
  onChange,
  disabled = false,
  popupContainerId,
  style,
  testId,
}) => {
  const initialOptions = value
    ? [{ value, label: initialLabel ?? value }]
    : null // set inital value to null to distinguish from "no results"
  const [options, setOptions] = useState<NdcOption[] | null>(initialOptions)
  const [isFetching, setIsFetching] = useState(false) // loading bool flag for searching ndc
  const fetchRef = useRef(0)
  const valueWithLabel = useMemo(() => {
    if (options && value) {
      const selectedOption = options.find((el) => el.value === value)
      // use value as label in the unlikely event of failing to find this value in NDC db
      return selectedOption ?? { label: value, value }
    } else {
      return null
    }
  }, [options, value])

  const getPopupContainer = () =>
    document.getElementById(popupContainerId) as HTMLElement

  // pass selected value to parent when user selects an option
  const handleChange = (newOption: NdcOption) => {
    onChange(newOption)
  }

  const handleDebouncedSearch = useMemo(() => {
    const loadOptions = async (searchTerm: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setIsFetching(true)

      const result = await search(searchTerm)
      // make sure fetch order matches result
      if (fetchId !== fetchRef.current) {
        return
      }

      setOptions(result)
      setIsFetching(false)
    }

    return debounce(loadOptions, SEARCH_DELAY_MILLISECONDS)
  }, [search])

  return (
    <Select
      dropdownMatchSelectWidth={400}
      showSearch
      labelInValue
      value={valueWithLabel}
      onChange={handleChange}
      onSearch={handleDebouncedSearch}
      options={options ?? []}
      placeholder={
        <>
          <SearchOutlined />
          <span className={styles.placeholderText}>{placeholder}</span>
        </>
      }
      disabled={disabled}
      suffixIcon={
        <Tooltip
          data-testid="ndc-select-tooltip"
          title={placeholder}
          placement="right"
          getPopupContainer={getPopupContainer}
        >
          <InfoCircleOutlined />
        </Tooltip>
      }
      style={style}
      filterOption={false}
      placement="bottomLeft"
      getPopupContainer={getPopupContainer}
      notFoundContent={
        isFetching ? (
          <div className={styles.spinnerContainer}>
            <Spinner fontSize={24} />
          </div>
        ) : fetchRef.current > 0 ? (
          <span>Not found</span>
        ) : null
      }
      data-testid={testId}
    />
  )
}

export default NationalDrugCodeSelect
