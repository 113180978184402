import React, { useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { v4 } from 'uuid'

import {
  createCustomBillingCode,
  deleteCustomBillingCode,
  getAllCustomBillingCodes,
  updateCustomBillingCode,
} from '../../api/api-lib'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import { GenericTableProps } from '../../stories/BaseComponents/Table'
import CustomBillingModal from '../../stories/BillingTemplates/CustomBillingModal'
import CustomBillingTable from '../../stories/BillingTemplates/CustomBillingTable'
import SettingsTableDisplay from '../../stories/BillingTemplates/SettingsTableDisplay'
import { ModalDisplay } from '../../stories/BillingTemplates/types'

import styles from '../_shared.module.scss'

interface Code {
  key: string
  code: string
  description?: string
  shortDescription: string
}

export default function CustomBillingCode() {
  const [mode, setMode] = useState<ModalDisplay>(ModalDisplay.NONE)
  const [form] = Form.useForm()

  const toggleCancel = (): void => {
    setMode(ModalDisplay.NONE)
    form.resetFields()
  }
  const toggleShowAdd = (): void => setMode(ModalDisplay.ADD)
  const toggleShowEdit = (): void => setMode(ModalDisplay.EDIT)

  const getCodes = async () => {
    try {
      const codes = await getAllCustomBillingCodes()
      const codesWithKeys = codes.map((code: Code) => {
        code.key = `${code.code}-${v4()}`
        return code
      })
      return codesWithKeys
    } catch (e) {
      onError(e)
    }
  }

  const checkIfDuplicateCodeError = (error: Error | string) => {
    if (!(error instanceof Error)) return false
    const is409 = error.message.includes('409')
    if (mode === ModalDisplay.EDIT) {
      return error.message.includes('500') || is409
    }
    return is409
  }

  const onCreateEditError = (error: Error | string) => {
    const isDuplicateCodeError = checkIfDuplicateCodeError(error)
    if (isDuplicateCodeError) {
      notification('Code already exists, please enter a unique one.', 'warning')
      return
    }
    onError(error)
    toggleCancel()
  }

  const {
    data: codes,
    isLoading: isTableLoading,
    refetch: refetchCodes,
  } = useQuery(['codes'], getCodes)

  const onSuccess = async (actionText: string) => {
    notification(`Code ${actionText} successfully.`, 'success')
    toggleCancel()
    await refetchCodes()
  }

  const { mutate: createCode } = useMutation(createCustomBillingCode, {
    onSuccess: async () => await onSuccess('added'),
    onError: onCreateEditError,
  })

  const { mutate: updateCode } = useMutation(updateCustomBillingCode, {
    onSuccess: async () => await onSuccess('modified'),
    onError: onCreateEditError,
  })

  const { mutate: deleteCode } = useMutation(deleteCustomBillingCode, {
    onSuccess: async () => await onSuccess('deleted'),
    onError,
  })

  const onSubmit = async (data: any): Promise<void> => {
    const codeText = data.code.trim()
    const descriptionText = data.shortDescription.trim()

    const newCode = {
      code: codeText,
      description: descriptionText,
      shortDescription: descriptionText,
    }

    if (mode === ModalDisplay.ADD) {
      createCode({ code: codeText, shortDescription: descriptionText })
      return
    }

    const key = form.getFieldValue('key')

    let codeToUpdate = newCode.code
    codes.forEach((code: Code) => {
      if (code.key === key) {
        codeToUpdate = code.code
      }
    })
    updateCode({ update: newCode, codeToUpdate })
  }

  const onEdit = (data: Code): void => {
    const codeText = data.code.trim()
    const descriptionText = data.shortDescription.trim()

    form.setFieldsValue({
      key: data.key,
      code: codeText,
      description: descriptionText,
      shortDescription: descriptionText,
    })
    toggleShowEdit()
  }

  const onDelete = (data: Code) => deleteCode(data.code)

  const customModal = (
    <CustomBillingModal
      form={form}
      mode={mode}
      toggleCancel={toggleCancel}
      onSubmit={onSubmit}
      title="Custom billing code"
    />
  ) as unknown as React.FC<GenericTableProps>

  const customTable = (
    <CustomBillingTable
      dataSource={codes}
      isTableLoading={isTableLoading}
      onDelete={onDelete}
      onEdit={onEdit}
      size="middle"
    />
  ) as unknown as React.FC<GenericTableProps>

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        <SettingsTableDisplay
          buttonText="+ Add custom billing code"
          title="Custom billing codes"
          modal={customModal}
          table={customTable}
          show={mode !== ModalDisplay.NONE}
          toggleShow={toggleShowAdd}
        />
      </div>
    </div>
  )
}
