import React, { useState } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import { Editor } from '@tiptap/react'
import { message } from 'antd'
import { v4 } from 'uuid'

import { getLatestCompletedIntakeForm } from '../../../../../api/intakeForms'
import { globalConfig } from '../../../../../config/config'
import { notification } from '../../../../../libs/notificationLib'
import { Modal } from '../../../../../stories/BaseComponents'
import { convertResponsesToV2Note } from '../../../../../stories/PatientIntake/convert-to-eval-note'
import { useBlocks } from '../../../hooks'
import { Note } from '../../../types'
import { getEditorMenuItem } from '../../Blocks/Core/Block/DynamicBlock'
import {
  CreateDynamicBlockOptions,
  DYNAMIC_ALLERGIES_BLOCK,
  DYNAMIC_DIAGNOSIS_BLOCK,
  DYNAMIC_MEDICATIONS_BLOCK,
} from '../../Blocks/Core/Block/DynamicBlockTypes'
import { EditorToolbar } from './EditorToolbar'
import { qaTemplate } from './__mocks__/QATemplate'
import { SOAPTemplate } from './templates/SOAPTemplate'
import { insertBlockInEditor } from './utils'

import styles from './NoteEditorToolbar.module.scss'

interface ModalProps {
  title?: React.ReactNode
  message?: string
  hasOkButton?: boolean
  cancelText?: string
  cancelButton?: any
  visible: boolean
  onOkay?: () => void
}

interface NoteEditorToolbarProps {
  editor?: Editor
  isLoading: boolean
  isNoteSaving: boolean
  patientId: string
  noteId: string
  onAddDynamicBlock: ({
    noteUuid,
    blockConfig,
  }: CreateDynamicBlockOptions) => Promise<void>
  onChange: (val: Partial<Note>) => void
}

export const NoteEditorToolbar = ({
  editor,
  isLoading,
  isNoteSaving,
  patientId,
  noteId,
  onAddDynamicBlock,
  onChange,
}: NoteEditorToolbarProps) => {
  const blocks = useBlocks(false)
  const [modal, setModal] = useState<ModalProps>({
    visible: false,
  })

  const toggleErrorModal = (message: string) => {
    setModal({
      title: (
        <div>
          <InfoCircleOutlined id={styles.errorColor} />
          &nbsp;&nbsp;Unable to import patient intake responses
        </div>
      ),
      visible: true,
      message: message,
      hasOkButton: false,
      cancelText: 'Ok',
      cancelButton: {
        type: 'primary',
      },
    })
  }

  const closeImportModal = () => {
    setModal({ visible: false })
  }

  const importIntakeResponses = async (editor?: Editor) => {
    const hideMessage = message.loading(
      `Importing patient's intake responses`,
      /*duration:*/ 0
    )
    try {
      const latestIntakeForm = await getLatestCompletedIntakeForm(patientId)
      if (!latestIntakeForm) {
        throw new Error()
      }

      const resultingContents = convertResponsesToV2Note(
        latestIntakeForm.responses ?? [],
        latestIntakeForm.sections
      )
      if (resultingContents.length === 0) {
        notification(`Patient's Intake has nothing to import`, 'warn')
        return
      }

      editor?.commands.insertContent({
        type: 'doc',
        content: resultingContents,
      })
      onChange({ importedIntakeFormId: latestIntakeForm.id })

      notification(`Patient's intake responses imported`, 'success')
    } catch (err) {
      const errorMessage =
        err.response?.data?.error ?? 'Failed to import an Osmind Intake Form.'
      toggleErrorModal(errorMessage)
    } finally {
      hideMessage()
    }
  }

  // these are passed to "Insert" DropdownSelector
  const insertMenuItems = [
    ...(editor ? [SOAPTemplate(editor)] : []),

    {
      text: 'Patient Intake Responses',
      key: 'INTAKE',
      onClick: () => {
        importIntakeResponses(editor)
      },
    },

    getEditorMenuItem({
      displayName: 'Active Medications',
      noteId,
      blockType: DYNAMIC_MEDICATIONS_BLOCK,
      onClick: onAddDynamicBlock,
    }),
    getEditorMenuItem({
      displayName: 'Active Allergies',
      noteId,
      blockType: DYNAMIC_ALLERGIES_BLOCK,
      onClick: onAddDynamicBlock,
    }),
    getEditorMenuItem({
      displayName: 'Active Diagnoses',
      noteId,
      blockType: DYNAMIC_DIAGNOSIS_BLOCK,
      onClick: onAddDynamicBlock,
    }),

    ...blocks.map((block) => ({
      text: block.label,
      key: block.name,
      onClick: () => {
        const blockId = v4()
        insertBlockInEditor(
          editor,
          `<${block.tag} id="${blockId}"></${block.tag}>`
        )
      },
    })),
  ]

  const config = globalConfig.get()
  if (!config.isProd) {
    insertMenuItems.push(qaTemplate(editor))
  }

  return (
    <>
      <Modal
        visible={modal.visible}
        title={modal.title}
        hasOkButton={modal.hasOkButton}
        cancelButtonProps={modal.cancelButton}
        cancelText={modal.cancelText}
        okText="Import"
        onCancel={closeImportModal}
        zIndex={100000}
      >
        <p>{modal.message}</p>
      </Modal>
      <EditorToolbar
        editor={editor}
        isLoading={isLoading}
        disableInsertMenu={isNoteSaving}
        insertMenuItems={insertMenuItems}
      />
    </>
  )
}
