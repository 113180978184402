import { ComparisonOperators } from './_types'

const ComparisonMap = {
  [ComparisonOperators.ABOVE_OR_EQUAL]: 'above',
  [ComparisonOperators.BELOW_OR_EQUAL]: 'below',
  [ComparisonOperators.ABOVE]: 'above',
  [ComparisonOperators.BELOW]: 'below',
}

export const thresholdComparison = (comparisonOperator: ComparisonOperators) =>
  comparisonOperator ? ComparisonMap[comparisonOperator] : 'above' // falls back to above by default like for surveys that don't have a threshold comparison specification

export const thresholdSuggestion = ({
  name,
  lowerScoreRange,
  upperScoreRange,
  recommendedThreshold,
  comparisonOperator,
}: {
  name: string
  lowerScoreRange: number
  upperScoreRange: number
  recommendedThreshold?: number
  comparisonOperator?: ComparisonOperators
}) => {
  const thresholdGuideText = `The score threshold for ${name} surveys must be between ${lowerScoreRange} and ${upperScoreRange}.`
  if (recommendedThreshold && comparisonOperator) {
    return `Suggested threshold: ${recommendedThreshold} or ${thresholdComparison(
      comparisonOperator
    )}. ${thresholdGuideText}`
  }
  return thresholdGuideText
}
