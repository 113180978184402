import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Modal as AntDModal, Select as AntDSelect, Form } from 'antd'
import _ from 'lodash'

import { createAppointmentType, updateAppointmentType } from '../../api/api-lib'
import {
  LocationItem,
  RoomItem,
} from '../../containers/Authentication/Locations'
import { generateProviderOptions } from '../../containers/Scheduling/utils/generate-provider-options'
import { onError } from '../../libs/errorLib'
import {
  SelfSchedulingEvents,
  trackSelfScheduling,
} from '../../libs/freshpaint/selfSchedulingEvents'
import { notification } from '../../libs/notificationLib'
import { Teammate } from '../../shared-types'
import {
  Col,
  Colorpicker,
  Divider,
  Input,
  InputNumber,
  Option,
  Row,
  Select,
  Switch,
  TextArea,
} from '../../stories/BaseComponents'
import Modal from '../../stories/BaseComponents/Modal'
import { DEFAULT_APPT_TYPE_COLORS } from '../../stories/Scheduling/constants'
import { AppointmentMinutesSelect } from './AppointmentMinutesSelect'
import {
  AppointmentType,
  AppointmentTypeTableItem,
} from './AppointmentSettings'

const { OptGroup } = AntDSelect

interface Props {
  show: boolean
  onHide: () => void
  appointmentItem: AppointmentTypeTableItem | undefined
  appointmentTypes: AppointmentType[] | undefined
  teamData: Teammate[]
  rooms: RoomItem[] | undefined
  locations: LocationItem[] | undefined
  refreshTable: () => void
  isSelfSchedulingEnabled: boolean
}

interface FormValues {
  name: string
  durationInMinutes?: string | number
  appointmentHour?: number
  appointmentMinute?: number
  instructions: string
  internalNotes: string
  eventProviders: string[]
  roomId?: string
  colorHex?: string
  isSelfSchedulable?: boolean
  selfSchedulingRecipientIds?: string[]
}

enum Mode {
  EDIT = 'isEdit',
  ADD = 'isAdd',
}

const MINUTES_IN_HOUR = 60
const INITIAL_APPOINTMENT_MINUTE_VALUE = 0
const INITIAL_APPOINTMENT_HOUR_VALUE = 0

const getInitialAppointmentMinuteValue = (duration?: string | number) => {
  if (!duration) {
    return INITIAL_APPOINTMENT_MINUTE_VALUE
  }

  if (typeof duration === 'number') {
    return duration % 60
  }

  return parseInt(duration) % 60
}

const getInitialAppointmentHourValue = (duration?: string | number) => {
  if (!duration) {
    return INITIAL_APPOINTMENT_HOUR_VALUE
  }

  if (typeof duration === 'number') {
    return Math.floor(duration / 60)
  }

  return Math.floor(parseInt(duration) / 60)
}

const AppointmentSettingsModal: React.FC<Props> = ({
  show,
  onHide,
  appointmentItem,
  appointmentTypes,
  rooms,
  locations,
  teamData,
  refreshTable,
  isSelfSchedulingEnabled,
}) => {
  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState<boolean>(false)

  const [appointmentMinuteValue, setAppointmentMinuteValue] = useState(() =>
    getInitialAppointmentMinuteValue(appointmentItem?.durationInMinutes)
  )
  const [appointmentHourValue, setAppointmentHourValue] = useState(() =>
    getInitialAppointmentHourValue(appointmentItem?.durationInMinutes)
  )
  const [selectedColor, setSelectedColor] = useState<string>(
    appointmentItem?.colorHex ? appointmentItem.colorHex : ''
  )

  const [isSelfSchedulable, setIsSelfSchedulable] = useState<boolean>(
    appointmentItem?.isSelfSchedulable
      ? appointmentItem.isSelfSchedulable
      : false
  )

  useEffect(() => {
    if (show) {
      setAppointmentHourValue(
        getInitialAppointmentHourValue(appointmentItem?.durationInMinutes)
      )

      setAppointmentMinuteValue(
        getInitialAppointmentMinuteValue(appointmentItem?.durationInMinutes)
      )
    }
    setIsSelfSchedulable(appointmentItem?.isSelfSchedulable || false)
    form.setFieldValue(
      'isSelfSchedulable',
      appointmentItem?.isSelfSchedulable || false
    )
    form.setFieldValue(
      'selfSchedulingRecipientIds',
      appointmentItem?.selfSchedulingRecipientIds || []
    )
  }, [show, appointmentItem])
  const filteredLocations = locations?.filter((l) => !l.Deleted)
  const mode = appointmentItem ? Mode.EDIT : Mode.ADD

  let roomDisplay = appointmentItem?.roomId ? appointmentItem.roomId : undefined
  if (roomDisplay && rooms) {
    const roomItem = rooms.find((r) => r.roomId === roomDisplay)
    // if this roomId has been deleted, it won't be included in the list of dropdown options and therefore will
    // display to the user as a ulid so we should update the display to be identifiable to the user
    if (roomItem?.deleted) {
      const locationItem = locations?.find(
        (l) => l.LocationId === roomItem.locationId
      )
      roomDisplay = `${locationItem?.LocationName} - ${roomItem.roomName} (removed)`
    }
  }

  const initialValues: FormValues = useMemo(
    () => ({
      name: !appointmentItem ? '' : appointmentItem.name,
      appointmentHour: getInitialAppointmentHourValue(
        appointmentItem?.durationInMinutes
      ),
      appointmentMinute: getInitialAppointmentMinuteValue(
        appointmentItem?.durationInMinutes
      ),
      instructions: !appointmentItem?.instructions
        ? ''
        : appointmentItem.instructions,
      internalNotes: !appointmentItem?.internalNotes
        ? ''
        : appointmentItem.internalNotes,
      eventProviders: !appointmentItem?.eventProviders?.length
        ? []
        : appointmentItem.eventProviders,
      roomId: roomDisplay,
      colorHex: appointmentItem?.colorHex ? appointmentItem.colorHex : '',
      isSelfSchedulable: Boolean(appointmentItem?.isSelfSchedulable),
      selfSchedulingRecipients:
        appointmentItem?.selfSchedulingRecipientIds || [],
    }),
    [appointmentItem, roomDisplay]
  )

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [show])

  useEffect(() => {
    setSelectedColor(appointmentItem?.colorHex ? appointmentItem.colorHex : '')
  }, [appointmentItem])

  const generateRoomOptions = (locationItem: LocationItem) => {
    const filteredRooms = rooms?.filter(
      (r) => r.locationId === locationItem.LocationId && !r.deleted
    )

    if (!filteredRooms || filteredRooms.length === 0) {
      return [{ value: '', name: '' }]
    }

    return filteredRooms.map((r) => ({
      value: r.roomId,
      name: r.roomName,
    }))
  }

  const createAppointmentTypeSettings = async (values: FormValues) => {
    return await createAppointmentType({ ...values })
  }

  const editAppointmentType = async (values: FormValues) => {
    return await updateAppointmentType({
      ...values,
      id: appointmentItem?.id,
    })
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      setLoading(true)
      let durationValue = 0
      if (values.appointmentHour) {
        durationValue += values.appointmentHour * 60
      }
      if (values.appointmentMinute) {
        durationValue += values.appointmentMinute
      }

      // if the user is saving the form with a deleted room, be sure to save the value as the roomId
      // not the user friendly display string we updated the initialValues to be
      const roomId = values.roomId?.includes('(removed)')
        ? appointmentItem?.roomId || ''
        : values.roomId === undefined
        ? ''
        : values.roomId

      if (mode === Mode.ADD) {
        if (appointmentTypes) {
          for (const appointment of appointmentTypes) {
            if (appointment.name === values.name) {
              notification(
                `An appointment type with name ${values.name} already exists. Please try again with a new name or update the existing appointment type name.`,
                'error'
              )
              setLoading(false)
              return
            }
          }
        }
        await createAppointmentTypeSettings({
          name: values.name,
          durationInMinutes: durationValue,
          instructions: values.instructions,
          eventProviders: values.eventProviders,
          colorHex: selectedColor,
          internalNotes: values.internalNotes,
          roomId,
          isSelfSchedulable,
          selfSchedulingRecipientIds: values.selfSchedulingRecipientIds,
        })
        notification('Successfully added a new appointment type.', 'success')
      } else {
        await editAppointmentType({
          name: values.name,
          durationInMinutes: durationValue,
          instructions: values.instructions,
          eventProviders: values.eventProviders,
          colorHex: selectedColor,
          internalNotes: values.internalNotes,
          roomId,
          isSelfSchedulable,
          selfSchedulingRecipientIds: values.selfSchedulingRecipientIds,
        })
        notification('Successfully updated an appointment type.', 'success')
      }
      onHide()
      setLoading(false)
      setSelectedColor('')
      setIsSelfSchedulable(false)
      refreshTable()
    } catch (e) {
      console.error(e)
      onError(new Error('Unable to add a appointment type'))
      setLoading(false)
      setSelectedColor('')
      setIsSelfSchedulable(false)
      onHide()
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values)
        form.resetFields()
        setAppointmentHourValue(0)
        setAppointmentMinuteValue(0)
      })
      .catch((info) => {
        console.log('Validate Failed: ', info)
      })
  }

  const checkFormChanged = () => {
    const values = {
      ...initialValues,
      ...form.getFieldsValue(),
    }
    if (_.isEqual(initialValues, values)) return false
    return true
  }

  const handleClose = () => {
    if (!checkFormChanged()) {
      form.resetFields()
      setAppointmentHourValue(0)
      setAppointmentMinuteValue(0)
      setLoading(false)
      onHide()
    } else {
      AntDModal.confirm({
        title: 'Unsaved changes',
        content:
          'Are you sure you want to close this window? Any unsaved changes will be lost.',
        okText: 'Ok',
        centered: true,
        cancelText: 'Cancel',
        cancelButtonProps: {
          type: 'default',
        },
        okButtonProps: {
          type: 'primary',
          loading: isLoading,
        },
        onOk: () => {
          form.resetFields()
          setLoading(false)
          setAppointmentHourValue(0)
          setAppointmentMinuteValue(0)
          setSelectedColor(
            appointmentItem?.colorHex ? appointmentItem.colorHex : ''
          )
          onHide()
        },
      })
    }
  }

  const handleChangeAppointmentMinutes = useCallback(
    (value: number) => {
      if (!appointmentHourValue && value >= MINUTES_IN_HOUR) {
        const newHourValue = Math.floor(value / MINUTES_IN_HOUR)
        const newMinuteValue = value % MINUTES_IN_HOUR
        setAppointmentHourValue(newHourValue)
        setAppointmentMinuteValue(newMinuteValue)
        form.setFieldValue('appointmentMinute', newMinuteValue)
        form.setFieldValue('appointmentHour', newHourValue)
        return
      }
      setAppointmentMinuteValue(value)
      form.setFieldValue('appointmentMinute', value)
    },
    [form, appointmentHourValue]
  )

  const handleHourValueChange = (value: string | number | null) => {
    if (value === null) {
      setAppointmentHourValue(0)
      form.setFieldValue('appointmentHour', 0)
      return
    }
    const numValue = Math.round(Number(value))
    setAppointmentHourValue(numValue)
    form.setFieldValue('appointmentHour', numValue)
  }

  const handleSelfSchedulingToggle = () => {
    if (isSelfSchedulable) {
      form.setFieldValue('selfSchedulingRecipientIds', [])
    }
    trackSelfScheduling(
      !isSelfSchedulable
        ? SelfSchedulingEvents.ENABLE_APPT_TYPE
        : SelfSchedulingEvents.DISABLE_APPT_TYPE,
      {
        appointmentTypeId: appointmentItem?.id || '',
        appointmentTypeName:
          appointmentItem?.name || form.getFieldValue('name'),
        eventDate: new Date().toISOString(),
      }
    )
    form.setFieldValue('isSelfSchedulable', !isSelfSchedulable)
    setIsSelfSchedulable(!isSelfSchedulable)
  }

  const validateHoursAndMinutes = () => {
    const mins = form.getFieldValue('appointmentMinute') || 0
    const hrs = form.getFieldValue('appointmentHour') || 0
    const apptLengthInMinutes = hrs * 60 + mins
    if (!isSelfSchedulable || apptLengthInMinutes > 0) {
      return Promise.resolve()
    }
    return Promise.reject(new Error())
  }

  return (
    <Modal
      title={
        !appointmentItem ? 'Add Appointment Type' : 'Edit Appointment Type'
      }
      visible={show}
      onOk={handleOk}
      onCancel={handleClose}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        onFinish={handleOk}
        scrollToFirstError
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Appointment Type Name"
          rules={[
            {
              required: true,
              message: 'Please input appointment type name',
              type: 'string',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col span={11}>
            <Form.Item
              name="appointmentHour"
              validateTrigger="onSubmit"
              label="Hours"
              rules={[
                {
                  validator: validateHoursAndMinutes,
                  message: 'Appointment length is 0 minutes',
                },
              ]}
            >
              <InputNumber
                value={appointmentHourValue}
                style={{ width: '100%' }}
                onChange={handleHourValueChange}
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <Form.Item
              name="appointmentMinute"
              validateTrigger="onSubmit"
              label="Minutes"
              rules={[
                {
                  validator: validateHoursAndMinutes,
                  message: '',
                },
              ]}
            >
              <AppointmentMinutesSelect
                onChange={handleChangeAppointmentMinutes}
                value={appointmentMinuteValue}
                shouldAllowMultiHourValues={!appointmentHourValue}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="eventProviders"
          label="Provider(s)"
          rules={[
            {
              required: isSelfSchedulable,
              message: 'Please select at least one provider',
            },
          ]}
        >
          <Select mode="multiple">
            {generateProviderOptions(teamData).map((option) => (
              <Option
                key={`select_provider_${option.name}`}
                name={option.name}
                value={option.value}
              >
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="roomId" label="Location">
          <Select optionLabelProp="title" allowClear>
            {filteredLocations?.map((locationItem) => (
              <OptGroup
                label={locationItem.LocationName}
                key={`${locationItem.LocationId}-location`}
              >
                {generateRoomOptions(locationItem).map((option) => (
                  <Option
                    key={`select_room_${option.value}_${locationItem.LocationId}`}
                    name={option.name}
                    value={option.value}
                    title={`${locationItem.LocationName} - ${option.name}`}
                    disabled={option.name === '' && option.value === ''}
                  >
                    {option.name}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Form.Item>

        {isSelfSchedulingEnabled && (
          <div>
            <Divider />
            <Form.Item
              name="isSelfSchedulable"
              label="Enable online booking requests"
            >
              <Switch
                checked={form.getFieldValue('isSelfSchedulable')}
                onToggle={handleSelfSchedulingToggle}
                label="Patients can request this appointment in the Osmind app"
              />
            </Form.Item>

            {isSelfSchedulable && (
              <Form.Item
                name="selfSchedulingRecipientIds"
                label="Email recipients for online booking"
              >
                <Select mode="multiple">
                  {generateProviderOptions(teamData).map((option) => (
                    <Option
                      key={`select_provider_${option.name}`}
                      name={option.name}
                      value={option.value}
                    >
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </div>
        )}

        <Form.Item name="instructions" label="Patient instructions">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item name="internalNotes" label="Internal notes">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item name="colorHex" label="Calendar color">
          <Colorpicker
            colors={DEFAULT_APPT_TYPE_COLORS}
            selectedColor={selectedColor}
            onChange={(colorHex: string) => {
              setSelectedColor(colorHex)
              form.setFieldsValue({ colorHex })
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AppointmentSettingsModal
