import { INTAKE_REVIEW_FORM_MAP } from '../sections/helpers'
import {
  AllergiesMedicationsQuestionKeys,
  GeneralInformationQuestionKeys,
  HealthcareProviderSubForm,
  MedicationsSubFormQuestionKeys,
  PsychHistoryHospQuestionKeys,
  PsychHistoryMedsQuestionKeys,
  PsychiatricHistoryQuestionKeys,
} from '../sections/question-keys'

/**
 * Outputs a question label with either an empty value or the question key (used to perform a text replacement)
 */
export const getQuestionLabelTemplate = (
  questionKey: string,
  isBlank = false
) => {
  const placeholderValue = isBlank ? '' : questionKey
  return (
    `${INTAKE_REVIEW_FORM_MAP[questionKey].title}: ${placeholderValue}\n` ?? ''
  )
}

/**
 * These empty sub form item strings should be used when there is no intake response for them
 */
export const emptyIntakeResponseDefaults: Record<string, string> = {
  [AllergiesMedicationsQuestionKeys.CURRENT_MEDICATIONS]:
    getQuestionLabelTemplate(MedicationsSubFormQuestionKeys.MED_NAME, true) +
    getQuestionLabelTemplate(MedicationsSubFormQuestionKeys.DOSAGE, true) +
    getQuestionLabelTemplate(MedicationsSubFormQuestionKeys.FREQUENCY, true) +
    getQuestionLabelTemplate(
      MedicationsSubFormQuestionKeys.FREQUENCY_OPTIONAL_DETAIL,
      true
    ) +
    getQuestionLabelTemplate(
      MedicationsSubFormQuestionKeys.REASONS_FOR_USE,
      true
    ),
  [PsychiatricHistoryQuestionKeys.PSYCH_MEDS]:
    getQuestionLabelTemplate(MedicationsSubFormQuestionKeys.MED_NAME, true) + // consistency with non-psych meds
    getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.IS_STILL_PRESCRIBED,
      true
    ) +
    getQuestionLabelTemplate(PsychHistoryMedsQuestionKeys.START_OF_MED, true) +
    getQuestionLabelTemplate(PsychHistoryMedsQuestionKeys.END_OF_MED, true) +
    getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.REASONS_FOR_USE,
      true
    ) +
    getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.MAX_DOSE_PER_DAY,
      true
    ) +
    getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.HAS_SIDE_EFFECTS,
      true
    ) +
    getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.SIDE_EFFECTS_DESCRIPTION,
      true
    ) +
    getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.REASON_FOR_DISCONTINUATION,
      true
    ),
  [GeneralInformationQuestionKeys.HEALTHCARE_PROVIDERS]:
    '- Healthcare provider #1\n' +
    `   ${getQuestionLabelTemplate(
      HealthcareProviderSubForm.HEALTHCARE_PROVIDER_NAME,
      true
    )}` +
    `   ${getQuestionLabelTemplate(
      HealthcareProviderSubForm.HEALTHCARE_PROVIDER_SPECIALTY,
      true
    )}`,
  [PsychiatricHistoryQuestionKeys.HOSPITALIZATIONS]:
    '- Psychiatric hospitalization #1\n' +
    `   ${getQuestionLabelTemplate(
      PsychHistoryHospQuestionKeys.START_OF_HOSPITALIZATION,
      true
    )}` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryHospQuestionKeys.END_OF_HOSPITALIZATION,
      true
    )}` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryHospQuestionKeys.LENGTH_OF_HOSPITALIZATION,
      true
    )}` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryHospQuestionKeys.REASON_FOR_HOSPITALIZATION,
      true
    )}`,
}

/**
 * These unpopulated sub form item strings are used as the starting "template" for each intake response with a sub-section.
 * They have the question key as the value for each question label and allows use to replace each question keys with the actual answer value associated to the question key
 */
export const unpopulatedSubFormItems: Record<string, string> = {
  [AllergiesMedicationsQuestionKeys.CURRENT_MEDICATIONS]:
    `\n- ${MedicationsSubFormQuestionKeys.MED_NAME}\n` +
    `   ${getQuestionLabelTemplate(MedicationsSubFormQuestionKeys.DOSAGE)}` +
    `   ${getQuestionLabelTemplate(MedicationsSubFormQuestionKeys.FREQUENCY)}` +
    `   ${getQuestionLabelTemplate(
      MedicationsSubFormQuestionKeys.FREQUENCY_OPTIONAL_DETAIL
    )}` +
    `   ${getQuestionLabelTemplate(
      MedicationsSubFormQuestionKeys.REASONS_FOR_USE
    )}`,
  [PsychiatricHistoryQuestionKeys.PSYCH_MEDS]:
    `\n- MED_NAME\n` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.IS_STILL_PRESCRIBED
    )}` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.START_OF_MED
    )}` +
    `   ${getQuestionLabelTemplate(PsychHistoryMedsQuestionKeys.END_OF_MED)}` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.REASONS_FOR_USE
    )}` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.MAX_DOSE_PER_DAY
    )}` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.HAS_SIDE_EFFECTS
    )}` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.SIDE_EFFECTS_DESCRIPTION
    )}` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryMedsQuestionKeys.REASON_FOR_DISCONTINUATION
    )}`,
  [GeneralInformationQuestionKeys.HEALTHCARE_PROVIDERS]:
    `   ${getQuestionLabelTemplate(
      HealthcareProviderSubForm.HEALTHCARE_PROVIDER_NAME
    )}` +
    `   ${getQuestionLabelTemplate(
      HealthcareProviderSubForm.HEALTHCARE_PROVIDER_SPECIALTY
    )}`,
  [PsychiatricHistoryQuestionKeys.HOSPITALIZATIONS]:
    `   ${getQuestionLabelTemplate(
      PsychHistoryHospQuestionKeys.START_OF_HOSPITALIZATION
    )}` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryHospQuestionKeys.END_OF_HOSPITALIZATION
    )}` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryHospQuestionKeys.LENGTH_OF_HOSPITALIZATION
    )}` +
    `   ${getQuestionLabelTemplate(
      PsychHistoryHospQuestionKeys.REASON_FOR_HOSPITALIZATION
    )}`,
}

/**
 * These keys are used to associate a question key with all the keys of their nested sub-section items
 */
export const subSectionItemKeys: Record<string, string[]> = {
  [AllergiesMedicationsQuestionKeys.CURRENT_MEDICATIONS]: Object.values(
    MedicationsSubFormQuestionKeys
  ),
  [PsychiatricHistoryQuestionKeys.PSYCH_MEDS]: Object.values(
    PsychHistoryMedsQuestionKeys
  ),
  [GeneralInformationQuestionKeys.HEALTHCARE_PROVIDERS]: Object.values(
    HealthcareProviderSubForm
  ),
  [PsychiatricHistoryQuestionKeys.HOSPITALIZATIONS]: Object.values(
    PsychHistoryHospQuestionKeys
  ),
}

/**
 * Fields that should be hidden if they don't have a value
 */
export const conditionallyRenderedFields: Record<
  PsychHistoryMedsQuestionKeys.END_OF_MED,
  string
> = {
  [PsychHistoryMedsQuestionKeys.END_OF_MED]: `   ${getQuestionLabelTemplate(
    PsychHistoryMedsQuestionKeys.END_OF_MED,
    true
  )}`,
}
