import { ReactNode, useState } from 'react'

import { DownOutlined } from '@ant-design/icons'
import cx from 'classnames'

import styles from './FilterAccordion.module.scss'

export type FilterAccordionProps = {
  title: string
  children: ReactNode
  testId: string
  hasMarginBottom?: boolean
}

export const FilterAccordion = ({
  title,
  children,
  hasMarginBottom,
  testId,
}: FilterAccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const handleToggleExpanded = () => {
    setIsExpanded((currExpanded) => !currExpanded)
  }
  return (
    <section
      className={cx(styles.container, {
        [styles.hasMarginBottom]: hasMarginBottom,
      })}
      data-testid={testId}
    >
      <div
        className={styles.headerContainer}
        onClick={handleToggleExpanded}
        role="button"
        tabIndex={0}
      >
        <h6 className={styles.title}>{title}</h6>
        <DownOutlined
          className={cx(styles.downArrow, {
            [styles.isArrowUp]: isExpanded,
          })}
        />
      </div>
      <div
        className={cx(styles.accordion, {
          [styles.isClosed]: !isExpanded,
        })}
      >
        {children}
      </div>
    </section>
  )
}
