import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const GeneralMedicalCodes: any = [
  // {
  //   278844005: {
  //     title: 'Constitutional (General Conditions)',
  //     description: 'General clinical state (observable entity)',
  //     snomedcode: 278844005,
  //     icdcode: '',
  //     icddescription: '',
  //   },
  // },
  {
    52702003: {
      title: 'con.Chronic Fatigue Syndrome',
      description: 'Chronic fatigue syndrome (disorder)',
      snomedcode: 52702003,
      icdcode: 'R5382',
      icddescription: 'Chronic fatigue, unspecified',
    },
  },
  {
    193462001: {
      title: 'con.Insomnia',
      description: 'Insomnia (disorder)',
      snomedcode: 193462001,
      icdcode: 'G4700',
      icddescription: 'Insomnia, unspecified',
    },
  },
  {
    414916001: {
      title: 'con.Obesity',
      description: 'Obesity (disorder)',
      snomedcode: 414916001,
      icdcode: 'E669',
      icddescription: 'Obesity, unspecified',
    },
  },
  {
    441361000124104: {
      title: 'con.Unintentional Weight Gain',
      description: 'Unintentional weight gain (finding)',
      snomedcode: 441361000124104,
      icdcode: 'R635',
      icddescription: 'Abnormal weight gain',
    },
  },
  {
    448765001: {
      title: 'con.Unintentional Weight Loss',
      description: 'Unintentional weight loss (finding)',
      snomedcode: 448765001,
      icdcode: 'R634',
      icddescription: 'Abnormal weight loss',
    },
  },
  {
    38341003: {
      title: 'con.High Blood Pressure',
      description: 'Hypertensive disorder, systemic arterial (disorder)',
      snomedcode: 38341003,
      icdcode: 'I10',
      icddescription: 'Essential (Primary) Hypertension',
    },
  },
]

export const GeneralMedicalDropDownValues = constructPatientDemographicObject(
  GeneralMedicalCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
