import React from 'react'

import { ButtonType } from 'antd/lib/button'

import Modal from '../../../stories/BaseComponents/Modal'
import QRCode from '../../../stories/BaseComponents/QRCode'
import { VitalsQRCodeProps } from './types'

const VitalsQRCode: React.FC<VitalsQRCodeProps> = ({
  shortPublicId,
  visible,
  handleCaretakerVitalsCancel,
}) => {
  const cancelButtonProps = {
    type: 'primary' as ButtonType,
  }

  return (
    <Modal
      visible={visible}
      title="Add Caretaker® vitals"
      onCancel={handleCaretakerVitalsCancel}
      closable={false}
      hasOkButton={false}
      hasCloseButton={true}
      cancelButtonProps={cancelButtonProps}
      cancelText="Close"
    >
      <p>
        Scan this QR code using your Caretaker® device to fill in the patient
        ID.
      </p>
      <div style={{ textAlign: 'center' }}>
        <QRCode value={shortPublicId} />
        <div style={{ color: 'grey', marginTop: 20 }}>
          Patient ID: {shortPublicId}
        </div>
      </div>
    </Modal>
  )
}

export default VitalsQRCode
