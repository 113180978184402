import React from 'react'

import { Select } from 'antd'
import { OptionProps as AntDOptionProps } from 'antd/lib/select'
import { OptGroupProps as AntDOptGroupProps } from 'rc-select/lib/OptGroup'

const AntDOption = Select.Option
const AntDOptGroup = Select.OptGroup

const Option: React.FC<AntDOptionProps> = ({ children, ...props }) => (
  <AntDOption {...props}>{children}</AntDOption>
)

const OptGroup: React.FC<AntDOptGroupProps> = ({ children, ...props }) => (
  <AntDOptGroup {...props}>{children}</AntDOptGroup>
)

export { OptGroup }
export default Option
/** This usage throws:
 *
 * Warning: `children` should be `Select.Option` or `Select.OptGroup` instead of `Option`.
 *
 * We can ignore it for now, but we may want to clean it up by looking into a way to
 * case the type of `Select.Option` and/or `Select.OptGroup` onto this Option component
 */
