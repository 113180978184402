// Import react
import React from 'react'

import { Redirect, Route, useLocation } from 'react-router-dom'

// Import libs/other
import { useAppContext } from '../../libs/contextLib'

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation()
  const { isAuthenticated } = useAppContext()
  return (
    <Route {...rest}>
      {isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={`/login?redirect=${pathname}${encodeURIComponent(search)}`}
        />
      )}
    </Route>
  )
}
