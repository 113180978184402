// Import react
import React from 'react'

// Import styles
import './NotFound.scss'

export default function NotFound() {
  return (
    <div className="NotFound">
      <h3>Sorry, page not found!</h3>
    </div>
  )
}
