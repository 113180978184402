import { Typography } from 'antd'

import { PatientInfo } from '../../hooks/usePatientProfile/shared-types'
import { formatPhoneNumber } from '../../shared/Helpers/utils'
import CopyButton from '../BaseComponents/CopyableText/CopyButton'
import { Skeleton } from '../BaseComponents/Skeleton'

import styles from './ContactInfo.module.scss'

const { Text } = Typography
interface LoadedProps {
  patientInfo: PatientInfo
}

function LoadedContactInfo({ patientInfo }: LoadedProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <Text strong className={styles.label}>
          Phone
        </Text>
        <Text className={styles.value}>
          {formatPhoneNumber(patientInfo.cellPhone)}&nbsp;
          <Text type="secondary">(mobile)</Text>
          &nbsp;
          <CopyButton
            className={styles.copyButton}
            text={patientInfo.cellPhone ?? ''}
          />
        </Text>
      </div>
      {!!patientInfo.workPhone && (
        <div className={styles.row}>
          <Text className={styles.value}>
            {formatPhoneNumber(patientInfo.workPhone)}&nbsp;
            <Text type="secondary">(work)</Text>
            &nbsp;
            <CopyButton
              className={styles.copyButton}
              text={patientInfo.workPhone}
            />
          </Text>
        </div>
      )}
      {!!patientInfo.homePhone && (
        <div className={styles.row}>
          <Text className={styles.value}>
            {formatPhoneNumber(patientInfo.homePhone)}&nbsp;
            <Text type="secondary">(home)</Text>
            &nbsp;
            <CopyButton
              className={styles.copyButton}
              text={patientInfo.homePhone}
            />
          </Text>
        </div>
      )}
      <div className={styles.row}>
        <Text strong className={styles.label}>
          Email
        </Text>
        <Text className={styles.value}>
          <a href={`mailto:${patientInfo.email}`}>{patientInfo.email}</a>&nbsp;
          <CopyButton
            className={styles.copyButton}
            text={patientInfo.email ?? ''}
          />
        </Text>
      </div>
    </div>
  )
}

interface Props {
  patientInfo: PatientInfo | undefined
}

export default function ContactInfo({ patientInfo }: Props) {
  if (!patientInfo) {
    return (
      <>
        <Skeleton
          active
          title={{
            className: styles.skeletonTitle,
          }}
          paragraph={{
            rows: 0,
            className: styles.skeletonParagraph,
          }}
        />
        <Skeleton
          active
          title={{
            className: styles.skeletonTitle,
          }}
          paragraph={{
            rows: 0,
            className: styles.skeletonParagraph,
          }}
        />
      </>
    )
  }
  return <LoadedContactInfo patientInfo={patientInfo} />
}
