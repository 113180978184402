import { Transaction } from '@tiptap/pm/state'
import { Editor, EditorContent as TipTapEditorContent } from '@tiptap/react'
import { isEqual } from 'lodash'

import { Skeleton } from '../../../../../stories/BaseComponents'

import styles from './EditorContent.module.scss'

/**
 * TipTap utility to check of the content has changed.
 * Used to avoid saving the same unchanged content.
 */
export const didEditorContentChange = (transaction: Transaction): boolean => {
  return !isEqual(transaction.before.content, transaction.doc.content)
}

interface EditorContentProps {
  isLoading: boolean
  editor: Editor | null
}

export function EditorContent({ isLoading, editor }: EditorContentProps) {
  return (
    <>
      {isLoading && (
        <Skeleton
          title={{
            className: styles.skeletonBlock,
          }}
          paragraph={{
            rows: 3,
            className: styles.skeletonBlock,
          }}
        />
      )}
      {!isLoading && (
        <TipTapEditorContent className={styles.editor} editor={editor} />
      )}
    </>
  )
}
