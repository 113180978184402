// Import react
import React from 'react'

import { Redirect, Route } from 'react-router-dom'

// Import libs/other
import { useAppContext } from '../../libs/contextLib'

export default function UnauthenticatedOnlyRoute({ children, ...rest }) {
  const { isAuthenticated } = useAppContext()
  return (
    <Route {...rest}>
      {!isAuthenticated ? children : <Redirect to={'/error/logout'} />}
    </Route>
  )
}
