import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { Divider, Modal, message } from 'antd'
import cx from 'classnames'
import { isBefore } from 'date-fns'
import { cloneDeep } from 'lodash'
import { useHistory } from 'react-router-dom'

import {
  DayOfWeek,
  UpdateProviderAvailabilitySettingsBody,
  getAvailabilitySettings,
  updateAvailabilitySettings,
} from '../../../../api/api-lib-typed'
import { useCalendarTimezone } from '../../../../hooks/useCalendars'
import { Spinner, Text } from '../../../../stories/BaseComponents'
import {
  TIMEZONE_TO_LOCATION_MAPPING,
  Timezone,
} from '../../../../stories/Scheduling/constants'
import { DEFAULT_TIMEZONE, getDateAtHourAndMinute } from '../helpers'
import { AvailabilitySettingsForm } from './AvailabilitySettingsForm'
import { areAvailabilitiesDifferent, getInitialAvailability } from './helpers'

import sharedStyles from '../../../_shared.module.scss'
import styles from './AvailabilitySettings.module.scss'

export interface TimeRange {
  providerAvailabilityDayId: string
  isUnavailable: boolean
  timeRange: {
    hasValidationError: boolean
    startTimeHour: number
    startTimeMinute: number
    durationInMinutes: number
  }[]
}

export interface TimeSlot {
  startTime: Date
  durationInMinutes: number
}

export const DAYS_OF_WEEK: { key: DayOfWeek; label: string }[] = [
  {
    key: 'SUN',
    label: 'Sun',
  },
  {
    key: 'MON',
    label: 'Mon',
  },
  {
    key: 'TUE',
    label: 'Tue',
  },
  {
    key: 'WED',
    label: 'Wed',
  },
  {
    key: 'THUR',
    label: 'Thu',
  },
  {
    key: 'FRI',
    label: 'Fri',
  },
  {
    key: 'SAT',
    label: 'Sat',
  },
]

export type AvailabilitySettingsProps = {
  providerId: string
}

export const AvailabilitySettings = ({
  providerId,
}: AvailabilitySettingsProps) => {
  const nextRoute = useRef('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [availability, setAvailability] = useState<
    Record<DayOfWeek, TimeRange>
  >(getInitialAvailability())
  const [initialAvailability, setInitialAvailability] = useState<
    Record<DayOfWeek, TimeRange>
  >(getInitialAvailability())
  const { data: clinicCalendarTimezone } = useCalendarTimezone()

  const {
    isLoading: availabilityLoading,
    data: rawAvailability,
    refetch: refetchAvailabilitySettings,
  } = useQuery(['availability'], getAvailabilitySettings, {
    refetchOnWindowFocus: false,
  })
  const { mutateAsync: mutateAvailabilitySettings, isLoading: isUpdating } =
    useMutation(['updateAvailability'], updateAvailabilitySettings)
  const history = useHistory()
  const unblockRef = useRef()

  const hasChanges = useMemo(() => {
    return areAvailabilitiesDifferent(initialAvailability, availability)
  }, [initialAvailability, availability])

  const handleOk = useCallback(() => {
    history.push(nextRoute.current)
    nextRoute.current = ''
    setIsModalOpen(false)
  }, [history])

  const handleCancel = useCallback(() => {
    nextRoute.current = ''
    setIsModalOpen(false)
  }, [])

  useEffect(() => {
    unblockRef.current = history.block((location: { pathname: string }) => {
      if (hasChanges && !nextRoute.current) {
        nextRoute.current = location.pathname
        setIsModalOpen(true)
        return false
      }
      return true
    })

    return () => {
      if (unblockRef.current) {
        unblockRef.current = undefined
      }
    }
  }, [history, hasChanges, handleCancel, handleOk])

  // Show warning modal if next route is set given
  // there are unsaved changes.
  useEffect(() => {
    if (nextRoute.current !== '' && isModalOpen) {
      Modal.confirm({
        title: 'Unsaved changes',
        content:
          'Are you sure you want to close this window? Any unsaved changes will be lost.',
        cancelText: 'Cancel',
        onCancel: handleCancel,
        onOk: handleOk,
      })
    }
  }, [isModalOpen])

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasChanges) {
        e.preventDefault()
        e.returnValue = ''
        return ''
      }
    }
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => window.removeEventListener('beforeunload', onBeforeUnload)
  }, [hasChanges])

  useEffect(() => {
    if (!rawAvailability || !Object.keys(rawAvailability)) {
      return
    }

    const availability = getInitialAvailability()

    rawAvailability[providerId]?.forEach(
      ({
        dayOfWeek,
        isUnavailable,
        providerAvailabilityDayId,
        providerAvailabilityTimeslots,
      }) => {
        availability[dayOfWeek] = isUnavailable
          ? {
              providerAvailabilityDayId,
              timeRange: [],
              isUnavailable: true,
            }
          : {
              providerAvailabilityDayId,
              isUnavailable: false,
              timeRange: providerAvailabilityTimeslots.map((timeslot) => {
                const { startTimeHour, startTimeMinute, durationInMinutes } =
                  timeslot

                return {
                  startTimeHour,
                  startTimeMinute,
                  durationInMinutes,
                  hasValidationError: false,
                }
              }),
            }

        // Show times ascending
        availability[dayOfWeek].timeRange.sort((a, z) => {
          return isBefore(
            getDateAtHourAndMinute(a.startTimeHour, a.startTimeMinute),
            getDateAtHourAndMinute(z.startTimeHour, z.startTimeMinute)
          )
            ? -1
            : 1
        })
      }
    )

    setAvailability(cloneDeep(availability))
    setInitialAvailability(cloneDeep(availability))
  }, [providerId, rawAvailability, clinicCalendarTimezone])

  const updateAvailability = (
    newAvailability: Record<DayOfWeek, TimeRange>
  ) => {
    setAvailability(newAvailability)
  }

  const handleSubmit = async () => {
    if (!availability) {
      return
    }
    try {
      const providerAvailabilityDays: UpdateProviderAvailabilitySettingsBody['providerAvailabilityDays'] =
        Object.entries(availability).map(
          ([day, { providerAvailabilityDayId, timeRange, isUnavailable }]) => {
            const dayOfWeek = day as DayOfWeek
            if (isUnavailable) {
              return {
                dayOfWeek,
                providerAvailabilityDayId,
                isUnavailable,
                providerAvailabilityTimeslots: [],
              }
            }
            return {
              providerAvailabilityDayId,
              isUnavailable: timeRange.length === 0,
              dayOfWeek,
              providerAvailabilityTimeslots: timeRange.map(
                ({ durationInMinutes, startTimeHour, startTimeMinute }) => {
                  return {
                    durationInMinutes,
                    startTimeHour,
                    startTimeMinute,
                  }
                }
              ),
            }
          }
        )
      await mutateAvailabilitySettings({
        providerAvailabilityDays,
      })
      message.success('Changes saved.')
      await refetchAvailabilitySettings()
    } catch (e) {
      console.error(JSON.stringify(e))
      message.error('Changes not saved. Try again.')
    }
  }

  const isSaveDisabled = useMemo(() => {
    if (!availability) {
      return true
    }
    let isDisabled = false

    Object.values(availability).forEach(({ timeRange, isUnavailable }) => {
      if (!isUnavailable) {
        timeRange.forEach(({ durationInMinutes, hasValidationError }) => {
          if (hasValidationError || durationInMinutes <= 0) {
            isDisabled = true
            return
          }
        })
      }

      // Break early if one day of the week has validation issues
      if (isDisabled) {
        return
      }
    })
    return isDisabled
  }, [availability])

  if (availabilityLoading) {
    return (
      <Spinner
        style={{ marginTop: 48 }}
        className="d-flex justify-content-center"
      />
    )
  }

  return (
    <div className={cx([sharedStyles.scroll, styles.container])}>
      <Text className={styles.leftPadding} header="h3">
        My availability
      </Text>
      <Divider />
      <div className={cx(styles.leftPadding, styles.bottomPadding)}>
        <>
          <div className={styles.grid}>
            <Text header="h5">Set your availability</Text>
            <Text className={styles.patientText}>
              Patients will only be able to book during available hours. Your
              available hours combined with calendar events will determine your
              online booking availability.
            </Text>
          </div>
          <div className={styles.timezoneContainer}>
            <Text header="h5">Practice time zone:</Text>
            <Text className={styles.timezoneFont}>
              {clinicCalendarTimezone &&
                TIMEZONE_TO_LOCATION_MAPPING[
                  clinicCalendarTimezone as Timezone
                ]}
            </Text>
          </div>
        </>

        <Divider className={styles.divider} />
        <AvailabilitySettingsForm
          availability={availability}
          updateAvailability={updateAvailability}
          isSubmitting={isUpdating}
          onSubmit={handleSubmit}
          isSaveDisabled={isSaveDisabled}
          practiceTimezone={clinicCalendarTimezone || DEFAULT_TIMEZONE}
        />
      </div>
    </div>
  )
}
