import {
  FieldStyling,
  TemplateFields,
} from '../../../components/Forms/FormMachine/types'
import { RelationalStatus } from '../SocialHistoryNotes/types'

export const styling = {
  editColClassName: 'col-bottom-edit',
  colClassName: 'bottom-line',
  editLabelClassName: 'field-label-edit',
  editValueClassName: 'field-value-edit',
  labelClassName: 'col-squished field-label',
  valueClassName: 'field-value',
  colSize: { xs: 12, sm: 12, md: 12, lg: 5, xl: 5 },
} as FieldStyling

const livingSituationChoices = [
  'Lives alone independently',
  'Lives with roommate(s)/housemate(s)',
  'Lives with family member(s)',
  'Lives with romantic partner(s)',
  'Homeless',
  'Lives in a group home or other supported living situation',
  'Other [describe]',
]

const currentOccupationChoices = [
  'Attending school/college/other work or educational programming',
  'Employed (full-time)',
  'Employed (part-time)',
  'Sporadically employed',
  'Unemployed',
  'On disability',
  'Other [describe]',
]

const degreeChoices = [
  'Graduate degree',
  'College degree',
  'High school diploma',
  'Some high school',
  'No high school',
  'Other [describe]',
]

const relationalStatusChoices = Object.values(RelationalStatus)

const relationshipStressChoices = [
  'Connected',
  'Isolated',
  'Stressful',
  'Mildly stressful',
  'Not stressful',
  'Other [describe]',
]

const exerciseAmountChoices = [
  'Significantly inadequate',
  'Inadequate',
  'Appropriate',
  'Excessive',
  'Significantly excessive',
]

const sexualOrientationChoices = [
  'Straight or heterosexual',
  'Lesbian, gay, or homosexual',
  'Bisexual',
  'Other [describe]',
  "Don't know",
  'Patient declines to specify',
]

const dietChoices = ['Healthy', 'Mildly unhealthy', 'Significantly unhealthy']

const centerOptions = {
  ...styling,
  editValueStyle: {
    marginLeft: 28,
  },
}

const noBottomBorder = {
  ...styling,
  editColStyle: { borderBottom: 'none' },
  colStyle: { borderBottom: 'none' },
}

const fullWidth = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }
const halfWidth = { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }

const conditionalOption = 'Other [describe]'

export const template = [
  {
    columnSizes: { xs: 12, sm: 12, md: 6, lg: 12, xl: 4 },
    customStyling: noBottomBorder,
    fieldType: 'textarea',
    id: 'livingSituationNotes',
    label: 'Living situation, home environment, safety',
    size: 3,
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 6, lg: 12, xl: 8 },
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'text',
      id: 'livingSituationOther',
      label: 'Other living situation',
    },
    conditionalOption,
    customStyling: noBottomBorder,
    fieldType: 'checkbox',
    id: 'livingSituation',
    label: 'Living situation',
    options: livingSituationChoices,
  },
  {
    columnSizes: halfWidth,
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'textarea',
      id: 'hasSafetyConcernsExplanation',
      label: 'Additional notes',
      size: 1,
    },
    conditionalOption: 'Yes',
    customStyling: centerOptions,
    defaultOption: 'No',
    fieldType: 'yesNo',
    id: 'hasSafetyConcerns',
    label: 'Has safety concerns?',
  },
  {
    columnSizes: halfWidth,
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'textarea',
      id: 'hasAccessToWeaponsExplanation',
      label: 'Additional notes',
      size: 1,
    },
    conditionalOption: 'Yes',
    customStyling: centerOptions,
    defaultOption: 'No',
    fieldType: 'yesNo',
    id: 'hasAccessToWeapons',
    label: 'Has access to weapons?',
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
    fieldType: 'textarea',
    id: 'occupationNotes',
    label: 'Occupation',
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'text',
      id: 'currentOccupationOther',
      label: 'Other occupation status',
    },
    conditionalOption,
    fieldType: 'dropdown',
    id: 'currentOccupation',
    label: 'Status',
    options: currentOccupationChoices,
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    customStyling: noBottomBorder,
    fieldType: 'textarea',
    id: 'educationNotes',
    label: 'Education',
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'textarea',
      id: 'hasLearningDisabilityExplanation',
      label: 'Additional notes',
      size: 1,
    },
    conditionalOption: 'Yes',
    customStyling: centerOptions,
    defaultOption: 'No',
    fieldType: 'yesNo',
    id: 'hasLearningDisability',
    label: 'Ever been suspected of having a learning disability?',
  },
  {
    columnSizes: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'text',
      id: 'degreeOther',
      label: 'Other education',
    },
    conditionalOption,
    fieldType: 'dropdown',
    id: 'degree',
    label: 'Degree',
    options: degreeChoices,
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    customStyling: noBottomBorder,
    fieldType: 'textarea',
    id: 'relationshipsNotes',
    label: 'Relationships',
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
    customStyling: centerOptions,
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'text',
      id: 'relationalStatusOther',
      label: 'Additional notes',
    },
    conditionalOption,
    fieldType: 'dropdown',
    id: 'relationalStatus',
    label: 'Marital/Relational Status',
    options: relationalStatusChoices,
    size: 1,
  },
  {
    columnSizes: { xs: 6, sm: 6, md: 4, lg: 4, xl: 4 },
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'text',
      id: 'familyRelationshipsOther',
      label: 'Additional notes',
    },
    conditionalOption,
    customStyling: centerOptions,
    fieldType: 'dropdown',
    id: 'familyRelationships',
    label: 'Family relationships',
    options: relationshipStressChoices,
    size: 1,
  },
  {
    columnSizes: { xs: 6, sm: 6, md: 4, lg: 4, xl: 4 },
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'text',
      id: 'socialRelationshipsOther',
      label: 'Additional notes',
    },
    conditionalOption,
    customStyling: centerOptions,
    fieldType: 'dropdown',
    id: 'socialRelationships',
    label: 'Social relationships',
    options: relationshipStressChoices,
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
    fieldType: 'textarea',
    id: 'exerciseNotes',
    label: 'Exercise',
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
    conditionalOption,
    customStyling: centerOptions,
    fieldType: 'dropdown',
    id: 'exerciseAmount',
    label: 'Exercise amount',
    options: exerciseAmountChoices,
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    customStyling: noBottomBorder,
    fieldType: 'textarea',
    id: 'sexualActivityNotes',
    label: 'Sexual activity',
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
    customStyling: centerOptions,
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'text',
      id: 'isSexuallyActiveExplanation',
      label: 'Additional notes',
    },
    conditionalOption: 'Yes',
    defaultOption: 'No',
    fieldType: 'yesNo',
    id: 'isSexuallyActive',
    label: 'Is patient sexually active?',
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'text',
      id: 'sexualOrientationOther',
      label: 'Other sexual orientation',
    },
    conditionalOption,
    customStyling: centerOptions,
    defaultOption: 'No',
    fieldType: 'dropdown',
    id: 'sexualOrientation',
    label: 'Sexual orientation',
    options: sexualOrientationChoices,
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
    fieldType: 'textarea',
    id: 'dietNotes',
    label: 'Diet',
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
    customStyling: centerOptions,
    fieldType: 'dropdown',
    id: 'diet',
    label: 'Quality',
    options: dietChoices,
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
    fieldType: 'textarea',
    id: 'spiritualityNotes',
    label: 'Spirituality',
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'textarea',
      id: 'hasSpiritualReligiousBeliefsExplanation',
      label: 'Additional notes',
      size: 1,
    },
    conditionalOption: 'Yes',
    customStyling: centerOptions,
    defaultOption: 'No',
    fieldType: 'yesNo',
    id: 'hasSpiritualReligiousBeliefs',
    label: 'Has spirituality/religious beliefs?',
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    fieldType: 'textarea',
    id: 'hobbiesNotes',
    label: 'Hobbies',
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
    fieldType: 'textarea',
    id: 'militaryServiceNotes',
    label: 'Military service',
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'textarea',
      id: 'hasCombatExperienceExplanation',
      label: 'Additional notes',
      size: 1,
    },
    conditionalOption: 'Yes',
    customStyling: centerOptions,
    defaultOption: 'No',
    fieldType: 'yesNo',
    id: 'hasCombatExperience',
    label: 'Has combat experience?',
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
    fieldType: 'textarea',
    id: 'legalBackgroundNotes',
    label: 'Legal background',
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'textarea',
      id: 'hasLegalTroublesExplanation',
      label: 'Additional notes',
      size: 1,
    },
    conditionalOption: 'Yes',
    customStyling: centerOptions,
    defaultOption: 'No',
    fieldType: 'yesNo',
    id: 'hasLegalTroubles',
    label: 'Has or had legal troubles?',
  },
  {
    columnSizes: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
    fieldType: 'textarea',
    id: 'financesNotes',
    label: 'Finances',
    size: 1,
  },
  {
    columnSizes: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
    conditionalComponent: {
      columnSizes: fullWidth,
      customStyling: noBottomBorder,
      fieldType: 'textarea',
      id: 'hasEconomicHardshipsExplanation',
      label: 'Additional notes',
      size: 1,
    },
    conditionalOption: 'Yes',
    customStyling: centerOptions,
    defaultOption: 'No',
    fieldType: 'yesNo',
    id: 'hasEconomicHardships',
    label: 'Has economic hardships?',
  },
  {
    columnSizes: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    fieldType: 'textarea',
    id: 'additionalNotes',
    label: 'Additional notes',
  },
] as TemplateFields
