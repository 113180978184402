import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const relationshipStatusSnomed: PatientDemographicObject[] = [
  { unmappable: { title: 'Dating' } },
  { '20295000': { title: 'Divorced' } },
  { '54986009': { title: 'Engaged' } },
  { '430618002': { title: 'Long-term partnership' } },
  { '87915002': { title: 'Married' } },
  { unmappable: { title: 'Not dating' } },
  { '278836005': { title: 'Remarried' } },
  { '13184001': { title: 'Separated' } },
  { '125681006': { title: 'Single' } },
  { '33553000': { title: 'Widowed' } },
]

export const relationshipStatusDropDownValues =
  constructPatientDemographicObject(
    relationshipStatusSnomed,
    CodeSystemOIDs.SNOMEDCT,
    Categories.EMPLOYMENT_STATUS
  )
