import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Divider, InputNumber, Switch } from 'antd'

import FormContainer, {
  ExtendedFormFields,
  OutputFieldData,
} from '../BaseComponents/FormContainer'
import Modal from '../BaseComponents/Modal'
import { thresholdSuggestion } from './_constants'
import { AllSurveySettings, ConfigurableSurveySettings } from './_types'

import './_shared.scss'

interface SurveyEditModalProps {
  currentSurveySettings: AllSurveySettings
  visible: boolean
  isSubmitting: boolean
  title: string
  onSubmit: (newValues: ConfigurableSurveySettings, type: string) => void
  handleCancel: (type: string) => void
  type: string
}
const SurveyEditModal: React.FC<SurveyEditModalProps> = ({
  currentSurveySettings,
  handleCancel,
  visible,
  title,
  onSubmit,
  type,
}) => {
  const FORM_NAME = 'EditSurveySettings'
  const [formState, setFormState] = useState<AllSurveySettings>(
    currentSurveySettings
  )

  const {
    name,
    isRecurring,
    sendingEnabled,
    sendInterval,
    alertingEnabled,
    alertThreshold,
    lowerScoreRange,
    upperScoreRange,
    scoreGuide,
    comparisonOperator,
    recommendedThreshold,
  } = formState

  const sendIntervalRequired = sendingEnabled && isRecurring
  const sendIntervalValid =
    (sendIntervalRequired && sendInterval) || !sendIntervalRequired
  const alertThresholdValid =
    (alertingEnabled && alertThreshold !== null && alertThreshold >= 0) ||
    !alertingEnabled
  const inputsValid = sendIntervalValid && alertThresholdValid

  const tooltipProps = useMemo(() => {
    if (!scoreGuide?.length) {
      return
    }

    const elements: React.ReactElement[] = []
    scoreGuide?.forEach((element, index) => {
      if (index > 0) {
        elements.push(<br />)
      }
      elements.push(<>{element}</>)
    })
    return { title: <>{elements}</>, getPopupContainer: () => document.body }
  }, [scoreGuide])

  const FormFields: ExtendedFormFields[] = useMemo(() => {
    const formFields: ExtendedFormFields[] = [
      {
        name: 'sendingEnabled',
        value: sendingEnabled,
        formItemProps: {
          label: 'Send survey to patients',
          valuePropName: 'checked',
          // rules: [{ required: true }] // example usage of validation
        },
        render: () => (
          <Switch
            disabled={!isRecurring}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={(value) => {
              if (!value && !sendIntervalValid) {
                setFormState({
                  ...formState,
                  sendingEnabled: value,
                  sendInterval: 1,
                })
              }
            }}
          />
        ),
      },
      {
        name: '',
        render: () => <Divider className="form-divider" />,
      },
      {
        name: 'alertingEnabled',
        value: alertingEnabled,
        formItemProps: {
          label: 'Receive survey score threshold alerts',
          valuePropName: 'checked',
        },
        render: () => (
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={(value) => {
              if (!value && !alertThresholdValid) {
                setFormState({
                  ...formState,
                  alertingEnabled: value,
                  alertThreshold: 0,
                })
              }
            }}
          />
        ),
      },
      {
        name: 'alertThreshold',
        value: alertThreshold,
        formItemProps: {
          label: 'Survey score alert threshold',
          rules: [{ required: alertingEnabled }],
          tooltip: tooltipProps,
          dependencies: ['alertingEnabled'],
        },
        render: () => (
          <InputNumber
            min={lowerScoreRange}
            max={upperScoreRange}
            disabled={!alertingEnabled}
          />
        ),
      },
      {
        name: '',
        render: () => (
          <div className="threshold-text">
            <p>
              {thresholdSuggestion({
                name,
                lowerScoreRange,
                upperScoreRange,
                recommendedThreshold,
                comparisonOperator,
              })}
            </p>
          </div>
        ),
      },
    ]
    if (isRecurring) {
      formFields.splice(1, 0, {
        name: 'sendInterval',
        value: sendInterval,
        formItemProps: {
          label: 'Send interval (days)',
          rules: [{ required: sendingEnabled && isRecurring }],
          dependencies: ['sendingEnabled'],
        },
        render: () => (
          <InputNumber min={1} disabled={!sendingEnabled || !isRecurring} />
        ),
      })
    }
    return formFields
  }, [
    sendingEnabled,
    alertingEnabled,
    alertThreshold,
    isRecurring,
    sendInterval,
  ])

  useEffect(() => {
    if (currentSurveySettings?.key !== formState.key) {
      setFormState({ ...currentSurveySettings })
    }
  }, [currentSurveySettings?.key])

  const onCancel = () => {
    handleCancel(type)
  }

  const handleFieldChange = useCallback(
    (changedFields: OutputFieldData[]) => {
      const newSettingsState = changedFields.reduce(
        (newSettingsValues, field) => {
          const key =
            typeof field.name === 'object' ? field.name[0] : field.name
          return { ...newSettingsValues, [key]: field.value }
        },
        {} as ConfigurableSurveySettings
      )

      setFormState({
        ...formState,
        ...newSettingsState,
      })
    },
    [formState]
  )

  const handleFormSubmit = useCallback(
    (values: ConfigurableSurveySettings) => {
      const newValues = { scoreTypeEnum: currentSurveySettings.key, ...values }
      onSubmit(newValues, type)
    },
    [onSubmit]
  )
  return (
    <div className="modal-form">
      <Modal
        visible={visible}
        title={title}
        okButtonProps={{
          form: FORM_NAME,
          htmlType: 'submit',
          disabled: !inputsValid,
        }}
        onCancel={onCancel}
        wrapClassName="modal-form"
        centered
        width={600}
      >
        <div className="survey-edit-form">
          <FormContainer
            name={FORM_NAME}
            layout="horizontal"
            size="middle"
            fields={FormFields}
            onFinish={handleFormSubmit}
            onFieldsChange={handleFieldChange}
            validateMessages={{ required: 'Required' }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default SurveyEditModal
