import { addHours, isAfter, isBefore, subHours } from 'date-fns'
import { format, zonedTimeToUtc } from 'date-fns-tz'
import { isEqualWith } from 'lodash'

import { convertTime12to24 } from '../../../libs/utils'
import { formatTime } from '../helpers'

export enum Days {
  SU = 'Sunday',
  MO = 'Monday',
  TU = 'Tuesday',
  WE = 'Wednesday',
  TH = 'Thursday',
  FR = 'Friday',
  SA = 'Saturday',
}

export enum FrequencyType {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  WEEK_DAY = 'Every weekday (Monday to Friday)',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export const WEEKDAYS = [Days.MO, Days.TU, Days.WE, Days.TH, Days.FR]

const APPT_SEPARATOR = '@@^@'

export const buildAppointmentTypeValue = (typeId: string, typeName: string) => {
  return `${typeId}${APPT_SEPARATOR}${typeName}`
}

export const getAppointmentTypeId = (
  appointmentTypeOptions: { name: string; value: string }[],
  values: { appointmentType?: string }
): string | undefined => {
  const apptTypeWithId = appointmentTypeOptions.find(
    (apptType) => apptType.name === values.appointmentType
  )

  if (apptTypeWithId) {
    const [apptTypeId] = apptTypeWithId.value.split(APPT_SEPARATOR)
    return apptTypeId
  }
}

export const parseRecurrence = (
  recurrence?: string
): {
  frequency: 'days' | 'weeks' | 'months' | 'years'
  daysOfWeek: Days[]
  recurrenceEndDate: string | null
  date?: number
  on?: {
    ordinal: string
    day: string
  }
  interval?: number
  never?: boolean
  after?: number
} => {
  const {
    frequency,
    daysOfWeek,
    end: { on: recurrenceEndDate, never, after },
    date,
    on,
    interval,
  } = (recurrence && JSON.parse(recurrence)) || {
    frequency: 'days',
    end: { on: null },
  }

  return {
    frequency,
    daysOfWeek:
      daysOfWeek && daysOfWeek instanceof Array
        ? daysOfWeek.filter(
            (day: Days | null | undefined) => day !== null && day !== undefined
          )
        : [],
    recurrenceEndDate,
    date,
    on,
    interval,
    never,
    after,
  }
}

export const isStartTimeInRecurrence = (
  selectedWeeklyStartDays: Days[],
  startDayOfEvent: Days
) => {
  return selectedWeeklyStartDays.includes(startDayOfEvent)
}

export const areRecurrencesEqual = (
  newRecurrence: {
    frequency?: string
    daysOfWeek?: string[]
    recurrenceEndDate?: string
    date?: string
    on?: { day?: string; ordinal?: string }
    interval?: number
  },
  existingRecurrence?: string
) => {
  const parsedExistingRecurrence = parseRecurrence(existingRecurrence)

  let areRecurrenceTimesEqual = false
  if (
    !parsedExistingRecurrence.recurrenceEndDate &&
    !newRecurrence.recurrenceEndDate
  ) {
    areRecurrenceTimesEqual = true
  }
  if (
    parsedExistingRecurrence.recurrenceEndDate &&
    newRecurrence.recurrenceEndDate &&
    isBefore(
      new Date(parsedExistingRecurrence.recurrenceEndDate),
      addHours(new Date(newRecurrence.recurrenceEndDate), 6)
    ) &&
    isAfter(
      new Date(parsedExistingRecurrence.recurrenceEndDate),
      subHours(new Date(newRecurrence.recurrenceEndDate), 6)
    )
  ) {
    areRecurrenceTimesEqual = true
  }

  const newRecurrenceObj = { ...newRecurrence, recurrenceEndDate: undefined }
  const existingRecurringObj = {
    ...parsedExistingRecurrence,
    recurrenceEndDate: undefined,
  }

  return (
    areRecurrenceTimesEqual &&
    isEqualWith(newRecurrenceObj, existingRecurringObj)
  )
}

// round the default value to the nearest interval of minuteStep
// i.e. if the default value is a current time of 2:32pm and you are using
// 15 minute minuteSteps, it would use a default value of 2:30pm
// in the timezone the user is set to
export const getZonedTimes = (
  start: Date,
  end: Date,
  timezone: string,
  shouldRoundToMinuteStep: boolean
) => {
  const { day: startDay, hour: startHour } = formatTime(
    start,
    timezone,
    shouldRoundToMinuteStep
  )
  const { day: endDay, hour: endHour } = formatTime(
    end,
    timezone,
    shouldRoundToMinuteStep
  )
  return { startHour, endHour, startDay, endDay }
}

export const getHiddenFields = (
  isRecurring: boolean,
  frequency: FrequencyType
) => {
  return {
    frequency: !isRecurring,
    interval: !isRecurring,
    ends: !isRecurring,
    daysOfWeek:
      !isRecurring ||
      ![FrequencyType.WEEK_DAY, FrequencyType.WEEKLY].includes(frequency),
    monthlyFrequency: !isRecurring || FrequencyType.MONTHLY !== frequency,
  }
}

export const getFrequencyValue = (
  frequency: 'days' | 'weeks' | 'months' | 'years',
  daysOfWeek: Days[]
) => {
  switch (frequency) {
    case 'weeks':
      return WEEKDAYS.filter((weekday) => !daysOfWeek.includes(weekday))
        .length === 0 && daysOfWeek.length === 5
        ? FrequencyType.WEEK_DAY
        : FrequencyType.WEEKLY
    case 'months':
      return FrequencyType.MONTHLY
    case 'years':
      return FrequencyType.YEARLY
    case 'days':
    default:
      return FrequencyType.DAILY
  }
}

export const generateIntervalLabelAfter = (key: string) => {
  const labelMapping: { [index: string]: string } = {}
  labelMapping[FrequencyType.DAILY] = 'day(s)'
  labelMapping[FrequencyType.WEEKLY] = 'week(s)'
  labelMapping[FrequencyType.WEEK_DAY] = 'week(s)'
  labelMapping[FrequencyType.MONTHLY] = 'month(s)'
  labelMapping[FrequencyType.YEARLY] = 'year(s)'
  return labelMapping[key]
}

export enum ResourceType {
  ROOM = 'room',
  PROVIDER = 'provider',
  NONE = 'none',
}

export const getResourceType = (
  resourceId: string | undefined
): ResourceType => {
  if (!resourceId || resourceId === 'unassigned') return ResourceType.NONE
  // all filterable providers need to be fully activated users and all activated user cognitoIds
  // start with us-west-2 except for the localstack test provider offlineContext_cognitoIdentityId
  if (
    resourceId.includes('us-west-2') ||
    resourceId.includes('cognitoIdentityId') ||
    resourceId.includes('-')
  ) {
    return ResourceType.PROVIDER
  }
  return ResourceType.ROOM
}

export const generateFrequencyOptions = () => {
  return Object.values(FrequencyType).map((frequency) => {
    return { name: frequency, value: frequency }
  })
}
export interface DateParams {
  dayOfWeek: string
  dayOfMonth: number
  ordinal: number
}

export const generateDateParams = (date: Date): DateParams => {
  let dateParams = {
    dayOfWeek: Days.SU.toString(),
    dayOfMonth: 1,
    ordinal: 1,
  }
  const dayOfMonth = date.getDate()
  dateParams = {
    dayOfWeek: Object.values(Days)[date.getDay()],
    dayOfMonth,
    ordinal: Math.ceil(dayOfMonth / 7),
  }
  return dateParams
}

export const generateMonthlyFrequencyOptions = (dateParams: DateParams) => {
  const ordinalWords = ['first', 'second', 'third', 'fourth', 'fifth']
  return [
    {
      name: `Monthly on day ${dateParams.dayOfMonth}`,
      value: JSON.stringify({ date: dateParams.dayOfMonth }),
    },
    {
      name: `Monthly on the ${ordinalWords[dateParams.ordinal - 1]} ${
        dateParams.dayOfWeek
      }`,
      value: JSON.stringify({
        ordinal: dateParams.ordinal,
        day: dateParams.dayOfWeek,
      }),
    },
  ]
}

export const correctSingleDateTimeZone = (
  day: Date | string,
  hour: string,
  timezone: string
) => {
  day = format(new Date(day), 'MM/dd/yyyy')
  hour = convertTime12to24(hour)
  const time = zonedTimeToUtc(
    new Date(`${day} ${hour}`),
    timezone
  ).toISOString()
  return time
}

export const correctTimezone = ({
  startDay,
  endDay,
  startHour,
  endHour,
  timezone,
}: {
  startDay: Date | string
  endDay: Date | string
  startHour: string
  endHour: string
  timezone: string
}) => {
  const startTime = correctSingleDateTimeZone(startDay, startHour, timezone)
  const endTime = correctSingleDateTimeZone(endDay, endHour, timezone)
  return { startTime, endTime }
}
