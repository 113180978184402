import { Text } from '../../../../stories/BaseComponents'

import styles from './NoteButton.module.scss'

export const CreateNoteButtonV2 = ({
  isBlankNote,
}: {
  isBlankNote: boolean
}) => {
  return (
    <div className={styles.noteButtonV2}>
      <Text
        data-testid="notes-v2-create-blank-note"
        className={styles.blankNote}
      >
        {isBlankNote ? 'Blank note' : 'Custom note'}
      </Text>
    </div>
  )
}
