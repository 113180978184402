// Import libs/other
import {
  DeleteObjectCommand,
  GetObjectCommand,
  GetObjectCommandInput,
  PutObjectCommand,
  S3Client,
} from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { Auth, Storage } from 'aws-amplify'

import { globalConfig } from '../config/config'

let s3: S3Client

async function getS3Client() {
  const config = globalConfig.get()
  return (
    s3 ??
    new S3Client({
      credentials: await Auth.currentCredentials(),
      region: config.s3.REGION,
    })
  )
}

export async function s3UploadPublic(file: File) {
  // Some special characters don't give a valid URL so replace all
  // special char with underscore
  const removeFileNameSpecialChar = file.name.replace(/[^a-z0-9]/gi, '_')
  const filename = `${Date.now()}-${removeFileNameSpecialChar}`

  const stored = await Storage.put(filename, file, {
    bucket: process.env.REACT_APP_INTAKEFORMS_BUCKET,
    contentType: file.type,
    level: 'public',
  })

  return stored.key
}

export async function getS3Item(
  fileKey: string,
  options: Partial<GetObjectCommandInput> = {}
) {
  const s3 = await getS3Client()
  const config = globalConfig.get()
  try {
    const command = new GetObjectCommand({
      Key: fileKey,
      Bucket: config.s3.BUCKET,
      ...options,
    })
    const response = await s3.send(command)
    if (!response?.Body) {
      return
    }
    return response.Body.transformToByteArray()
  } catch (e) {
    console.error('error getting object from s3', e)
  }
}

export async function getS3FilePrivate(
  documentReference: string | undefined,
  providerId: string | null | undefined, // TODO: cleanup callers
  options: Partial<GetObjectCommandInput> = {}
) {
  return await getS3Item(`private/${providerId}/${documentReference}`, options)
}
export async function getS3ImagePrivate(
  documentReference: string,
  providerId: string,
  options: Partial<GetObjectCommandInput> = {}
) {
  const s3 = await getS3Client()
  const config = globalConfig.get()
  try {
    const command = new GetObjectCommand({
      Key: `private/${providerId}/${documentReference}`,
      Bucket: config.s3.BUCKET,
      ...options,
    })
    const signingParams = {
      expiresIn: 1800, // only valid for 30 min
    }
    return getSignedUrl(s3, command, signingParams)
  } catch (e) {
    console.error('error getting image from s3', e)
  }
}

export async function putS3FilePrivate(
  file: File | ArrayBuffer,
  documentReference: string,
  providerId: string
) {
  const s3 = await getS3Client()
  const config = globalConfig.get()
  try {
    const command = new PutObjectCommand({
      Body: !(file instanceof File) ? new File([file], '') : file,
      Key: `private/${providerId}/${documentReference}`,
      Bucket: config.s3.BUCKET,
    })
    await s3.send(command)
  } catch (e) {
    console.error('error putting object to s3', e)
    throw new Error(
      `Error uploading file to S3 for providerId: ${providerId}, error: ${e}`
    )
  }
}

export async function deleteS3FilePrivate(
  documentReference: string,
  providerId: string
) {
  const s3 = await getS3Client()
  const config = globalConfig.get()
  try {
    const command = new DeleteObjectCommand({
      Key: `private/${providerId}/${documentReference}`,
      Bucket: config.s3.BUCKET,
    })
    await s3.send(command)
  } catch (e) {
    console.error('error deleting object from s3', e)
    throw new Error(
      `Error deleting file from S3 for providerId: ${providerId}, error: ${e}`
    )
  }
}

export async function s3DeletePublic(filename: string) {
  return await Storage.remove(filename)
}

export async function s3DownloadPracticeLogos(key: string) {
  return await Storage.get(key, {
    bucket: 'osmind-practice-logos',
  })
}

export async function s3DownloadPatientImages(key: string) {
  return await Storage.get(key, {
    bucket: 'osmind-patient-images',
  })
}
