import { useEffect, useState } from 'react'

import { createPaymentIntent } from '../../../api/api-lib'
import { calculatedOutstandingBalanceBillableEntity } from '../../../libs/billing'
import { handleStripeError } from '../../../libs/utils'
import { Invoice } from '../../../shared-types'

const useCreatePaymentIntentWithSideEffects = ({
  patientId,
  invoices,
  isOpen,
  enabled = true,
}: {
  patientId?: string
  invoices?: Invoice[]
  enabled?: boolean
  isOpen?: boolean
}) => {
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
    useState<string>('')
  const [paymentIntentId, setPaymentIntentId] = useState(null)
  const [paymentIntentLoading, setPaymentIntentLoading] = useState(true)

  const createPaymentIntentWithSideEffects = async () => {
    if (!invoices || !enabled || !patientId || !isOpen) {
      return
    }

    setPaymentIntentLoading(true)
    try {
      const initialTotal = calculatedOutstandingBalanceBillableEntity(invoices)
      const paymentIntentAmountCents = initialTotal < 100 ? 100 : initialTotal
      const { clientSecret, paymentIntentId } = await createPaymentIntent(
        paymentIntentAmountCents,
        patientId,
        null
      )
      setPaymentIntentClientSecret(clientSecret)
      setPaymentIntentId(paymentIntentId)
    } catch (e: any) {
      const errorMessage = handleStripeError(e)
      console.error(errorMessage)
      console.error(e)
    } finally {
      setPaymentIntentLoading(false)
    }
  }

  useEffect(() => {
    createPaymentIntentWithSideEffects()
  }, [invoices, isOpen])

  const resetPaymentIntent = async () => {
    await createPaymentIntentWithSideEffects()
  }

  return {
    paymentIntentClientSecret,
    paymentIntentId,
    paymentIntentLoading,
    resetPaymentIntent,
  }
}

export default useCreatePaymentIntentWithSideEffects
