import { useCallback, useRef, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import { useHistory } from 'react-router'

import { shareSuperbill } from '../../../api/billing'
import useQueryString from '../../../hooks/useQueryString'
import { STRIPE_ACCOUNT_STATUSES } from '../../../libs/constants'
import { useFeatureFlags } from '../../../libs/featureFlags'
import { trackSuperbillEvent } from '../../../libs/freshpaint/superbillEvents'
import { notification } from '../../../libs/notificationLib'
import {
  InsuranceClaim,
  Invoice,
  PaymentAttempt,
  RefundAttempt,
  Superbill,
} from '../../../shared-types'
import { Button } from '../../../stories/BaseComponents'
import { Tabs } from '../../../stories/BaseComponents/Tabs'
import ExportSuperbillPdfConfirmationModal from '../../../stories/Invoice/ExportSuperbillPdfConfirmationModal'
import ResendSuperbillModal from '../../../stories/Invoice/ResendSuperbillModal'
import SendSuperbillModal from '../../../stories/Invoice/SendSuperbillModal'
import SendSuperbillWithoutPaymentModal from '../../../stories/Invoice/SendSuperbillWithoutPaymentModal'
import SuperbillWithoutPaymentConfirmationModal from '../../../stories/Invoice/SuperbillWithoutPaymentConfirmationModal'
import CardsTable from './CardsTable'
import ClaimsTable from './ClaimsTable'
import InvoiceTable from './InvoiceTable'
import PaymentModals from './PaymentModals'
import TransactionsTable from './TransactionsTable'

import styles from './Billing.module.scss'

interface BillingTabsProps {
  invoices: Invoice[]
  payments: PaymentAttempt[]
  refunds: RefundAttempt[]
  patientId: string
  providerId: string
  stripeAccountStatus: STRIPE_ACCOUNT_STATUSES
  claims: InsuranceClaim[]
  refetchInvoices: () => void
}

// Map of active key to tab name
const ACTIVE_KEY = {
  OUTSTANDING: '0',
  ALL_INVOICES: '1',
  CLAIMS: '2',
  PAYMENTS: '3',
  CARDS: '4',
} as const

export default function BillingTabs({
  invoices,
  claims,
  payments,
  refunds,
  patientId,
  providerId,
  stripeAccountStatus,
  refetchInvoices,
}: BillingTabsProps) {
  const { claims: claimFeatureFlag } = useFeatureFlags()

  const query = useQueryString()
  const tab = query.get('tab') ?? ''
  type ActiveKeyType = typeof ACTIVE_KEY[keyof typeof ACTIVE_KEY]
  const [activeKey, setActiveKey] = useState<ActiveKeyType>(
    tab === 'claims' ? ACTIVE_KEY.CLAIMS : ACTIVE_KEY.OUTSTANDING
  )
  const onKeyChange = (key: ActiveKeyType) => setActiveKey(key)
  const [isAddPaymentModalOpen, setIsAddPaymentModalOpen] = useState(false)
  const [isSuperbillExportOpen, setIsSuperbillExportOpen] = useState(false)
  const [fromClaim, setFromClaim] = useState(false)
  const [fromGlobalView, setFromGlobalView] = useState(true)
  const [showSuperbillExportAfterPayment, setShowSuperbillExportAfterPayment] =
    useState(false)
  const [
    isSuperbillWithoutPaymentConfirmationOpen,
    setIsSuperbillWithoutPaymentConfirmationOpen,
  ] = useState(false)
  const [invoiceToExport, setInvoiceToExport] = useState<Invoice | null>(null)
  const [selectedInvoiceUuid, setSelectedInvoiceUuid] = useState<string | null>(
    null
  )
  const [selectedClaimUuid, setSelectedClaimUuid] = useState<string | null>(
    null
  )
  const [isAddPaymentModalLoading, setIsAddPaymentModalLoading] =
    useState(false)
  const [invoiceToSendSuperbill, setInvoiceToSendSuperbill] =
    useState<Invoice | null>(null)
  const [showSendSuperbillModal, setShowSendSuperbillModal] = useState(false)
  const [
    showSendSuperbillWithoutPaymentModal,
    setShowSendSuperbillWithoutPaymentModal,
  ] = useState(false)
  const [showResendSuperbillModal, setShowResendSuperbillModal] =
    useState(false)
  const shouldShowSendSuperbillModalAfterPaymentRef = useRef(false)

  const history = useHistory()

  const { mutateAsync: doShareSuperbill, isLoading: isSharingSuperbill } =
    useMutation(shareSuperbill)

  // start the "send superbill to patient" flow
  const handleStartSuperbillSend = (invoice: Invoice) => {
    setInvoiceToSendSuperbill(invoice)
    const isInvoicePaid = invoice.amountCentsDue === 0
    const alreadySharedSuperbill = !!invoice.superbill?.sharedBy
    if (alreadySharedSuperbill) {
      setShowResendSuperbillModal(true)
    } else if (!isInvoicePaid) {
      setShowSendSuperbillWithoutPaymentModal(true)
    } else {
      setShowSendSuperbillModal(true)
    }
  }

  const sendSuperbillAndRefetchInvoices = async () => {
    if (!invoiceToSendSuperbill?.superbill?.id) return

    trackSuperbillEvent('share', {
      invoiceId: invoiceToSendSuperbill.uuid,
      superbillId: invoiceToSendSuperbill.superbill.id,
      patientId: invoiceToSendSuperbill.patientId,
    })

    try {
      await doShareSuperbill({
        invoiceId: invoiceToSendSuperbill.uuid,
        superbillId: invoiceToSendSuperbill.superbill.id,
      })
      notification('Superbill successfully sent to patient.', 'success')
      refetchInvoices()
    } catch (e) {
      notification('Failed to send superbill to patient', 'failure')
    }
  }

  const filterOutstandingInvoices = () => {
    return invoices.filter((invoice: Invoice) => invoice.amountCentsDue > 0)
  }

  const handleOnNewPaymentClick = (
    billableEntityUuid: string | null = null,
    fromClaim = false
  ) => {
    setFromClaim(fromClaim)
    // Payments, and Cards should be global
    // Invoices & Claims should have the modal option preselected
    const entityScoped = (
      [
        ACTIVE_KEY.OUTSTANDING,
        ACTIVE_KEY.CLAIMS,
        ACTIVE_KEY.ALL_INVOICES,
      ] as ActiveKeyType[]
    ).includes(activeKey)
    setFromGlobalView(!entityScoped && !billableEntityUuid)

    if (billableEntityUuid && fromClaim) {
      setSelectedClaimUuid(billableEntityUuid)
    } else if (billableEntityUuid) {
      setSelectedInvoiceUuid(billableEntityUuid)
    }

    setIsAddPaymentModalOpen(true)
    setIsAddPaymentModalLoading(true)
  }

  const createNewClaimRoute = () => {
    history.push(
      `/patient/billing/claim-create?patientId=${patientId}&providerId=${providerId}`
    )
  }

  const stopLoadingButton = useCallback(
    () => setTimeout(() => setIsAddPaymentModalLoading(false), 500),
    []
  )

  const handleAddPaymentFromSuperbillConfirmation = () => {
    if (!invoiceToExport) return
    setIsSuperbillWithoutPaymentConfirmationOpen(false)
    setShowSuperbillExportAfterPayment(true)
    handleOnNewPaymentClick(invoiceToExport.uuid)
  }

  const handleSuperbillExport = (invoice: Invoice) => {
    setInvoiceToExport(invoice)
    invoice.amountCentsDue === 0
      ? setIsSuperbillExportOpen(true)
      : setIsSuperbillWithoutPaymentConfirmationOpen(true)
  }

  const handleSuperbillExportWithoutPayment = () => {
    setIsSuperbillWithoutPaymentConfirmationOpen(false)
    setIsSuperbillExportOpen(true)
  }

  const handleCloseAddPaymentForm = () => {
    setSelectedInvoiceUuid(null)
    setSelectedClaimUuid(null)
    setIsAddPaymentModalOpen(false)
    showSuperbillExportAfterPayment && setIsSuperbillExportOpen(true)
    if (shouldShowSendSuperbillModalAfterPaymentRef.current) {
      setShowSendSuperbillModal(true)
      shouldShowSendSuperbillModalAfterPaymentRef.current = false
    }
  }

  const defaultTab = () => {
    if (tab === 'claims') {
      return '2'
    } else {
      return '0'
    }
  }

  const handleCloseExportSuperbillPdf = () => {
    setIsSuperbillExportOpen(false)
    setShowSuperbillExportAfterPayment(false)
    setInvoiceToExport(null)
  }

  const handleExportSuperbillPdfStarted = () => {
    setShowSuperbillExportAfterPayment(false)
  }

  const handleExportSuperbillPdfFinished = (superbill: Superbill) => {
    superbill.id &&
      history.push(
        `/patient/documents?patientId=${superbill.invoice.patientId}&documentReference=${superbill.clinicalNoteId}`
      )
  }

  const handleCloseSuperbillWithoutPaymentConfirmation = () => {
    setIsSuperbillWithoutPaymentConfirmationOpen(false)
  }

  let buttons
  if (activeKey === '2') {
    buttons = (
      <span>
        <Button
          loading={isAddPaymentModalLoading}
          onClick={() => handleOnNewPaymentClick(null, true)}
        >
          New payment
        </Button>
        <span> </span>
        <Button type="primary" onClick={createNewClaimRoute}>
          New claim
        </Button>
      </span>
    )
  } else {
    buttons = (
      <span>
        <Button
          loading={isAddPaymentModalLoading}
          onClick={() => handleOnNewPaymentClick()}
        >
          New payment
        </Button>
        <span> </span>
        <Button
          onClick={() => history.push(`/invoice?patientId=${patientId}`)}
          type="primary"
        >
          New invoice
        </Button>
      </span>
    )
  }

  const filteredInvoices = filterOutstandingInvoices()
  return (
    <>
      <ExportSuperbillPdfConfirmationModal
        invoice={invoiceToExport}
        isVisible={isSuperbillExportOpen}
        onCancel={handleCloseExportSuperbillPdf}
        onExportStarted={handleExportSuperbillPdfStarted}
        onExportFinished={handleExportSuperbillPdfFinished}
      />
      <PaymentModals
        patientId={patientId}
        isOpen={isAddPaymentModalOpen}
        onClose={handleCloseAddPaymentForm}
        stripeAccountStatus={stripeAccountStatus}
        preSelectedInvoiceUuid={selectedInvoiceUuid}
        preSelectedClaimUuid={selectedClaimUuid}
        stopModalLoading={isAddPaymentModalLoading ? stopLoadingButton : null}
        fromClaim={fromClaim}
        fromGlobalView={fromGlobalView}
      />
      <SuperbillWithoutPaymentConfirmationModal
        isVisible={isSuperbillWithoutPaymentConfirmationOpen}
        onAddPayment={handleAddPaymentFromSuperbillConfirmation}
        onCancel={handleCloseSuperbillWithoutPaymentConfirmation}
        onProceedWithoutPayment={handleSuperbillExportWithoutPayment}
      />
      <SendSuperbillModal
        visible={showSendSuperbillModal}
        onCancel={() => {
          setShowSendSuperbillModal(false)
        }}
        onSend={async () => {
          await sendSuperbillAndRefetchInvoices()
          setShowSendSuperbillModal(false)
        }}
        isSending={isSharingSuperbill}
      />
      <ResendSuperbillModal
        visible={showResendSuperbillModal}
        onCancel={() => {
          setShowResendSuperbillModal(false)
        }}
        onSend={async () => {
          await sendSuperbillAndRefetchInvoices()
          setShowResendSuperbillModal(false)
        }}
        isSending={isSharingSuperbill}
        sharedAt={invoiceToSendSuperbill?.superbill?.sharedAt}
      />
      <SendSuperbillWithoutPaymentModal
        visible={showSendSuperbillWithoutPaymentModal}
        onCancel={() => {
          setShowSendSuperbillWithoutPaymentModal(false)
        }}
        onAddPayment={() => {
          if (!invoiceToSendSuperbill) {
            return
          }
          setShowSendSuperbillWithoutPaymentModal(false)
          shouldShowSendSuperbillModalAfterPaymentRef.current = true
          handleOnNewPaymentClick(invoiceToSendSuperbill.uuid)
        }}
        onSend={async () => {
          await sendSuperbillAndRefetchInvoices()
          setShowSendSuperbillWithoutPaymentModal(false)
        }}
        isSending={isSharingSuperbill}
      />

      <div className={styles.billingTabContainer}>
        <Tabs
          tabBarExtraContent={buttons}
          defaultActiveKey={defaultTab()}
          onChange={onKeyChange as (key: string) => void}
        >
          <Tabs.TabPane tab="Outstanding invoices" key={ACTIVE_KEY.OUTSTANDING}>
            <InvoiceTable
              invoices={filteredInvoices}
              patientId={patientId}
              shouldFilterOutstanding={true}
              providerId={providerId}
              openAddPaymentModal={handleOnNewPaymentClick}
              openSuperbillExport={handleSuperbillExport}
              onStartSuperbillSend={handleStartSuperbillSend}
              type="Outstanding"
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="All invoices" key={ACTIVE_KEY.ALL_INVOICES}>
            <InvoiceTable
              invoices={invoices}
              patientId={patientId}
              shouldFilterOutstanding={false}
              providerId={providerId}
              openAddPaymentModal={handleOnNewPaymentClick}
              openSuperbillExport={handleSuperbillExport}
              type="All-invoices"
              onStartSuperbillSend={handleStartSuperbillSend}
            />
          </Tabs.TabPane>
          {claimFeatureFlag && (
            <Tabs.TabPane tab="Claims" key={ACTIVE_KEY.CLAIMS}>
              <ClaimsTable
                claims={claims}
                patientId={patientId}
                providerId={providerId}
              />
            </Tabs.TabPane>
          )}

          <Tabs.TabPane tab="Payments" key={ACTIVE_KEY.PAYMENTS}>
            <TransactionsTable
              payments={payments}
              refunds={refunds}
              patientId={patientId}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Cards" key={ACTIVE_KEY.CARDS}>
            <CardsTable patientId={patientId} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  )
}
