import { useMemo } from 'react'

import Sentry from '../../libs/sentry'
import { parseDateOrNull } from '../../libs/utils'
import { Allergy, DateISO8601 } from '../../shared-types'
import {
  determineNoteDate,
  getUsableDate,
  isBeforeOrOn,
} from '../../shared/Helpers/utils'

interface UseAllergiesHookReturnObject {
  allergiesWithValidStartDate: Allergy[]
  allergiesWithDateWarning: Allergy[]
}

/**
 * This hook compares the patient's clinical note date with their
 * allergies date to output an object with two filtered lists:
 *
 * 1. allergiesWithValidStartDate - allergies to display normally
 * 2. allergiesWithDateWarning - allergies to display with warning (no DiagnosisOnsetDate or DiagnosisDate)
 */
const useAllergiesInClinicNotes = (
  allergies?: Allergy[] | null,
  dateOfNote?: DateISO8601 | null
): UseAllergiesHookReturnObject => {
  return useMemo<UseAllergiesHookReturnObject>(() => {
    const filteredResults: UseAllergiesHookReturnObject = {
      allergiesWithValidStartDate: [],
      allergiesWithDateWarning: [],
    }

    if (!allergies || !allergies.length || !dateOfNote) {
      return filteredResults
    }

    const noteDate = determineNoteDate(dateOfNote)

    if (!noteDate) {
      Sentry.captureMessage(
        `[useAllergiesInClinicNotes] Invalid note date provided: ${noteDate}`
      )
      return filteredResults
    }

    allergies.forEach((allergy) => {
      const usableDate = getUsableDate([allergy.Onset, allergy.CreatedAt])

      if (!usableDate) {
        // No usable date of any kind
        return
      }

      if (!isBeforeOrOn(usableDate, noteDate)) {
        // The first usable date on allergy is after the note date
        return
      }

      if (!parseDateOrNull(allergy.Onset, true)) {
        // Use Creation date with a warning
        filteredResults.allergiesWithDateWarning.push(allergy)
        return
      }

      // Use the Onset date
      filteredResults.allergiesWithValidStartDate.push(allergy)
      return
    })

    return filteredResults
  }, [allergies, dateOfNote])
}

export default useAllergiesInClinicNotes
