import { addMinutes, isAfter, isBefore } from 'date-fns'
import { cloneDeep } from 'lodash'

import { TimeRange } from './AvailabilitySettings/AvailabilitySettings'

export const DEFAULT_TIMEZONE = 'America/Los_Angeles'

export const getDateAtHourAndMinute = (hour: number, minute: number) => {
  const date = new Date()
  date.setHours(hour)
  date.setMinutes(minute)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}

export const getDefaultStartTime = () => getDateAtHourAndMinute(6, 0)

export const isOverlapping = (
  startTimeA: Date,
  startTimeB: Date,
  endTimeA: Date,
  endTimeB: Date
) => {
  const maxStartTime = isAfter(startTimeA, startTimeB) ? startTimeA : startTimeB
  const minEndTime = isBefore(endTimeA, endTimeB) ? endTimeA : endTimeB
  return isBefore(maxStartTime, minEndTime)
}

export const validateTimerange = (timeRange: TimeRange): TimeRange => {
  const newTimeRange = cloneDeep(timeRange)

  if (newTimeRange.timeRange.length === 1) {
    newTimeRange.timeRange[0].hasValidationError = false
    return newTimeRange
  }

  //Reset validation errors each time
  newTimeRange.timeRange.forEach((_, idx) => {
    newTimeRange.timeRange[idx].hasValidationError = false
  })

  newTimeRange.timeRange.forEach(
    ({ startTimeHour, startTimeMinute, durationInMinutes }, idx) => {
      const startTime = getDateAtHourAndMinute(startTimeHour, startTimeMinute)
      const endTime = addMinutes(startTime, durationInMinutes)

      for (
        let otherTimeRangeIdx = idx + 1;
        otherTimeRangeIdx < newTimeRange.timeRange.length;
        otherTimeRangeIdx++
      ) {
        const otherTimeRange = newTimeRange.timeRange[otherTimeRangeIdx]
        const otherTimeRangeStartTime = getDateAtHourAndMinute(
          otherTimeRange.startTimeHour,
          otherTimeRange.startTimeMinute
        )
        const otherTimeRangeEndTime = addMinutes(
          otherTimeRangeStartTime,
          newTimeRange.timeRange[otherTimeRangeIdx].durationInMinutes
        )

        // Mark both entries with validation error if time range is intersecting
        if (
          isOverlapping(
            startTime,
            otherTimeRangeStartTime,
            endTime,
            otherTimeRangeEndTime
          )
        ) {
          newTimeRange.timeRange[otherTimeRangeIdx].hasValidationError = true
          newTimeRange.timeRange[idx].hasValidationError = true
        }
      }
    }
  )

  return newTimeRange
}
