import React from 'react'

import { FormikHelpers, FormikTouched } from 'formik'

import {
  convertCentsToDollars,
  convertCentsToDollarsNumber,
  convertDollarsToCents,
  formatNumberStringToUsdString,
} from '../../../../libs/billing'
import { PaymentMethod } from '../../../../shared-types'
import { Tooltip } from '../../../../stories/BaseComponents'
import { USDInput } from '../../../../stories/BaseComponents/EditableInputs'
import { getPaymentMethodDisplayContent } from '../Billing'
import { PaymentFormValues } from './AddPaymentForm'
import { ErrorSpan } from './Utilities'

import styles from '../Billing.module.scss'

export const PatientCreditInputs = ({
  values,
  errors,
  setFieldValue,
  paymentMethods,
  setTouched,
}: {
  values: PaymentFormValues
  errors: any
  setFieldValue: FormikHelpers<PaymentFormValues>['setFieldValue']
  setTouched: (input: FormikTouched<PaymentFormValues>) => void
  paymentMethods: PaymentMethod[]
}) => {
  return (
    <div className={styles.creditInputsContainer}>
      {Object.keys(values.creditUsage).map(
        (paymentMethodUuid: string, idx: number) => {
          const paymentMethod = paymentMethods.find(
            (pm) => pm.uuid === paymentMethodUuid
          )
          const totalCredit = values.creditUsage[paymentMethodUuid].totalCredit
          const creditUtilized =
            values.creditUsage[paymentMethodUuid]['creditUtilized']
          const testId = `apply-credit-input-${idx}`
          const paymentMethodName =
            getPaymentMethodDisplayContent(paymentMethod)

          const isErrored =
            errors.creditUsage &&
            errors.creditUsage[paymentMethodUuid]?.creditUtilized

          return totalCredit === 0 ? null : (
            <div className={styles.creditInput}>
              {paymentMethod && (
                <Tooltip placement="top" title={paymentMethodName}>
                  <span className={styles.creditInputPaymentMethod}>
                    {paymentMethodName}
                  </span>
                </Tooltip>
              )}
              <div className={styles.creditInputAmountContainer}>
                <div className={styles.creditInputAmountRow}>
                  <span className={styles.creditInputAmount}>
                    <USDInput
                      className={styles.creditUsdInput}
                      value={convertCentsToDollarsNumber(creditUtilized)}
                      onChange={(value: number | null) =>
                        setFieldValue(
                          `creditUsage.${paymentMethodUuid}.creditUtilized`,
                          convertDollarsToCents(value)
                        )
                      }
                      status={isErrored && 'error'}
                      onBlur={() =>
                        setTouched({
                          [`creditUsage.${paymentMethodUuid}.creditUtilized`]:
                            true,
                        })
                      }
                      testId={testId}
                    />
                  </span>
                  <span className={styles.creditInputTotal}>
                    {' '}
                    of{' '}
                    {formatNumberStringToUsdString(
                      convertCentsToDollars(totalCredit)
                    )}
                  </span>
                </div>
                {isErrored && (
                  <ErrorSpan
                    error={errors.creditUsage[paymentMethodUuid].creditUtilized}
                    testId="apply-credit-error-msg"
                  />
                )}
              </div>
            </div>
          )
        }
      )}
    </div>
  )
}
