import React from 'react'

import { Button, Result, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

import { TestId } from '../../shared-types'

import styles from './InfoPage.module.scss'

type InfoPageProps = {
  status: 'info' | 'error' | 'warning'
  title: string
  details?: string
  redirectLink?: string
  redirectButtonText?: string
} & TestId

export default function InfoPage({
  status,
  title,
  details,
  redirectLink,
  redirectButtonText = '',
  testId,
}: InfoPageProps) {
  const history = useHistory()
  const redirect = () => {
    history.push(redirectLink ?? '/')
  }

  return (
    <Result
      data-testid={testId}
      status={status}
      title={title}
      extra={
        <div className={styles.errorContent}>
          <Typography.Text className={styles.detailsText}>
            {details}
          </Typography.Text>
          {redirectLink && (
            <Button
              type="primary"
              key="info-page-home-button"
              onClick={redirect}
            >
              {redirectButtonText}
            </Button>
          )}
        </div>
      }
    />
  )
}
