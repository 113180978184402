// Import react
import React, { useState } from 'react'

import { faPrescriptionBottleAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Import components
import { Button, Form, Modal } from 'react-bootstrap'

// Import libs/other
import { updateActiveMedication } from '../../api/api-lib'
import { onError } from '../../libs/errorLib'
import { useFormFields } from '../../libs/hooksLib'

// Import styles
import './MedicationForms.scss'

const RefillMedication = (props) => {
  const [show, setShow] = useState(false)

  const [fields, handleFieldChange] = useFormFields({
    DosesRemainingEstimate: props.medicine.DosesRemainingEstimate,
  })

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  async function handleRefill(event) {
    event.preventDefault()

    try {
      // alert(fields.DosesRemainingEstimate)
      await updateActiveMedication({
        ActiveMedicationId: props.medicine.ActiveMedicationId,
        PatientId: props.medicine.PatientId,
        Administration: props.medicine.Administration,
        Dosage: props.medicine.Dosage,
        AdministrationIntervalInDays:
          props.medicine.AdministrationIntervalInDays,
        MedicationName: props.medicine.MedicationName,
        DosesRemainingEstimate: fields.DosesRemainingEstimate,
      })
      props.handleApiChange()
    } catch (e) {
      onError(e)
    } finally {
      setShow(false)
    }
  }

  return (
    <span>
      <Button
        style={{ display: 'contents' }}
        bsPrefix="button larger right"
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faPrescriptionBottleAlt} /> Refill
      </Button>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Refill for{' '}
            <span className="modal-header-blue">
              {props.medicine.MedicationName}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleRefill}>
          <Modal.Body>
            <Form.Group controlId="DosesRemainingEstimate">
              <Form.Label className="form-label">Doses remaining:</Form.Label>
              <Form.Control
                bsPrefix="form-input"
                onChange={handleFieldChange}
                placeholder="Must be a number"
                required
                type="text"
                value={fields.DosesRemainingEstimate}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button bsPrefix="button-block" type="submit">
              <i className="fa fa-plus-circle" />
              &nbsp; Add
            </Button>
            <Button bsPrefix="button-close" onClick={handleClose}>
              <i className="fa fa-times" />
              &nbsp; Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </span>
  )
}

export default RefillMedication
