// Import react
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { range } from 'lodash'
// Import libs/other
import moment from 'moment'
// Import components
import { Button, Col, Form, Modal } from 'react-bootstrap'
import { SingleDatePicker } from 'react-dates'

import Slider from '../../../src/stories/BaseComponents/Slider'
import { useScoreMutation } from '../../hooks/mutations'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import Input from '../../stories/BaseComponents/Input'
import Option from '../../stories/BaseComponents/Option'
import Select from '../../stories/BaseComponents/Select'
import LoaderButton from '../Buttons/LoaderButton'

// Import Styles
import './AddScoreModal.scss'

function generateScoreForSurveyType(answers, survey) {
  let score = 0

  Object.entries(answers).forEach(([stepId, answer]) => {
    const step = survey.Steps.find((s) => s.Id === Number(stepId))

    if (step.Type === 'SCALE') {
      score += Number(answer.score)
    } else if (step.Type === 'SELECT') {
      const option = step.Options.find(
        (opt) => opt.Id === Number(answer.optionId)
      )
      score +=
        survey.ScoreTypeEnum === 'Resilience'
          ? option.Score >= 3
            ? 1
            : 0
          : option.Score
    }
  })

  if (survey.ScoreTypeEnum === 'PEG') {
    score = Math.round(score / survey.Steps.length)
  }

  return score
}

const SelectInput = ({ step, index, existingAnswer, handleResponse }) => {
  const ref = useRef(null)
  return (
    <div className="question-answer-select-container" ref={ref}>
      <Select
        testId={`${step.Id}-select`}
        id={'selectQuestionNº' + (index + 1)}
        className="question-answer-select"
        getPopupContainer={() => ref.current}
        onChange={(optionId) => {
          const { Score } = step.Options.find((el) => el.Id == optionId)
          handleResponse(step.Id, step.Type, {
            score: Score,
            optionId,
            text: existingAnswer?.text,
          })
        }}
        value={existingAnswer?.optionId}
        wrapOptions
      >
        {step.Options.map((option) => {
          return (
            <Option key={option.Id} name={option.Title} value={option.Id}>
              <>
                {option.Score} - {option.Title}
              </>
            </Option>
          )
        })}
      </Select>
      {step.AdditionalInputLabel && (
        <div className="question-answer-additional-container">
          <Form.Label>{step.AdditionalInputLabel}</Form.Label>
          <Input
            data-testid={`${step.Id}-additional-input`}
            value={existingAnswer?.text}
            onChange={(e) => {
              handleResponse(step.Id, step.Type, {
                text: e.currentTarget.value,
                score: existingAnswer?.score,
                optionId: existingAnswer?.optionId,
              })
            }}
          />
        </div>
      )}
    </div>
  )
}

const ScaleInput = ({ step, existingAnswer, handleResponse }) => {
  return (
    <div data-testid={`${step.Id}-slider-container`}>
      <Slider
        min={parseInt(step.Properties.Min.Score)}
        max={parseInt(step.Properties.Max.Score)}
        step={1}
        dots
        marks={range(
          step.Properties.Min.Score,
          step.Properties.Max.Score + 1
        ).reduce((prev, curr) => ({ ...prev, [curr]: curr }), {})}
        defaultValue={5}
        onChange={(value) =>
          handleResponse(step.Id, step.Type, {
            score: value,
            text: existingAnswer?.text,
          })
        }
        value={existingAnswer?.score}
      />
      {step.AdditionalInputLabel && (
        <div className="question-answer-additional-container">
          <Form.Label>{step.AdditionalInputLabel}</Form.Label>
          <Input
            data-testid={`${step.Id}-additional-input`}
            value={existingAnswer?.text}
            onChange={(e) => {
              handleResponse(step.Id, step.Type, {
                text: e.currentTarget.value,
                score: existingAnswer?.score,
              })
            }}
          />
        </div>
      )}
    </div>
  )
}

const TextInput = ({ step, existingAnswer, handleResponse }) => {
  return (
    <div>
      <Input
        data-testid={`${step.Id}-input`}
        value={existingAnswer?.text}
        onChange={(e) => {
          handleResponse(step.Id, step.Type, { text: e.currentTarget.value })
        }}
      />
    </div>
  )
}

const ScoreInputField = ({ step, index, existingAnswer, handleResponse }) => {
  switch (step.Type) {
    case 'SELECT':
      return (
        <SelectInput
          step={step}
          existingAnswer={existingAnswer}
          index={index}
          handleResponse={handleResponse}
        />
      )
    case 'SCALE':
      return (
        <ScaleInput
          step={step}
          existingAnswer={existingAnswer}
          handleResponse={handleResponse}
        />
      )
    case 'TEXT':
      return (
        <TextInput
          step={step}
          existingAnswer={existingAnswer}
          handleResponse={handleResponse}
        />
      )
  }
}

/* Adds score manually to patient graphs */
function AddScoreForm(props) {
  const survey = props.survey
  const { mutate, isLoading } = useScoreMutation({
    onSuccess: () => {
      notification('Score successfully submitted.', 'success')
      props.handleApiChange()
      props.handleClose()
    },
    onError: (e) => {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    },
  })
  const [disabled, setDisabled] = useState(false)
  const [answers, setAnswers] = useState({})
  const [score, setScore] = useState(0)
  const PatientId = props.PatientId

  // resets survey according to survey type and modal show status
  useEffect(() => {
    if (!props.show || props.type !== '') {
      const newScore = props.type === 'MoodScore' ? 1 : 0
      setScore(newScore)
    }
    if (survey.ScoreTypeEnum === 'WHODAS') {
      setScore(36)
    }

    const initialAnswers = {}
    for (const step of survey.Steps) {
      switch (step.Type) {
        case 'SELECT':
          initialAnswers[step.Id] = {
            type: step.Type,
            optionId: step.Options[0].Id,
            score: step.Options[0].Score,
          }
          break
        case 'SCALE':
          initialAnswers[step.Id] = {
            type: step.Type,
            score: step.Properties.Min.Score,
          }
          break
        case 'TEXT':
          initialAnswers[step.Id] = {
            type: step.Type,
            text: '',
            score: 0,
          }
          break
      }
    }
    setScore(generateScoreForSurveyType(initialAnswers, survey))
    setAnswers(initialAnswers)
  }, [props.show, props.type])

  // changes disabled status for submit button according to score + survey type
  useEffect(() => {
    if (survey?.Steps.length) {
      setDisabled(false)
    } else {
      let isDisabled = score > 10
      if (props.type === 'MoodScore') {
        isDisabled = isDisabled || score < 1
      } else if (props.type === 'PainScore') {
        isDisabled = isDisabled || score < 0
      }
      // disable submit if score is out of range for mood or pain
      setDisabled(isDisabled)
    }
  }, [score, props.type, survey])

  // contains form to add score + answer questionnaire responses
  async function addScore(event) {
    event.preventDefault()
    const data = {
      Date: props.date.format('MM-DD-YYYY').toString(),
      PatientId: PatientId,
      ScoreTypeEnum: props.type,
      Score: score,
      Answers: Object.entries(answers).map(([step, answer]) => ({
        step: Number(step),
        ...answer,
      })),
    }

    mutate(data)
  }

  const handleResponse = useCallback(
    (step, type, answer) => {
      let answerProp = {}
      switch (type) {
        case 'SCALE':
          answerProp = {
            score: Number(answer.score),
            text: answer.text || null, // empty strings should be saved as null in the db
          }
          break
        case 'SELECT':
          answerProp = {
            optionId: Number(answer.optionId),
            score: Number(answer.score),
            text: answer.text || null, // empty strings should be saved as null in the db
          }
          break
        case 'TEXT':
          answerProp = { text: answer.text, score: 0 }
      }
      const newAnswers = { ...answers, [step]: { type, ...answerProp } }
      setAnswers(newAnswers)

      if (survey.Steps?.length) {
        const tally = generateScoreForSurveyType(newAnswers, survey)
        setScore(tally)
      }
    },
    [answers, survey.Steps]
  )

  const questionnaireForm = useMemo(() => {
    return (
      <Form.Row>
        {survey.Steps?.map((step, index) => {
          return (
            <Form.Group
              as={Col}
              md={11}
              lg={11}
              xl={11}
              controlId={step.Title}
              style={{ marginLeft: 14 }}
              key={step.Id}
            >
              <Form.Label style={{ marginBottom: 0, fontWeight: 400 }}>
                {step.Title}
              </Form.Label>
              <Form.Text>{step.Description}</Form.Text>
              <ScoreInputField
                step={step}
                index={index}
                existingAnswer={answers[step.Id]}
                handleResponse={handleResponse}
              />
            </Form.Group>
          )
        })}
      </Form.Row>
    )
  }, [survey.Steps, answers])

  return (
    <>
      <Form.Row>
        <Form.Group
          style={{ marginLeft: 10 }}
          as={Col}
          xs={4}
          sm={4}
          md={2}
          lg={2}
        >
          <span className="info">Score</span>
        </Form.Group>
        <Form.Group xs={2} sm={2} md={2} lg={1} xl={1} as={Col}>
          {survey?.Steps.length ? (
            <span id="totalScoreQuestionnaire">{score}</span>
          ) : (
            <Form.Control
              bsPrefix="form-input"
              onChange={(e) => setScore(parseInt(e.target.value))}
              required
              type="number"
              value={score}
            />
          )}
        </Form.Group>
      </Form.Row>
      {questionnaireForm}
      {disabled && (
        <Form.Row>
          <Col className="info" style={{ marginLeft: '9px' }}>
            For
            {props.type === 'PainScore'
              ? ' pain scores, please enter a number between 0 '
              : ' mood scores, please enter a number between 1 '}{' '}
            and 10 (inclusive)
          </Col>
        </Form.Row>
      )}
      {survey.ScoreTypeEnum === 'EPDS' ? (
        <div className={'disclaimer'}>
          "Detection of postnatal depression: Development of the 10-item
          Edinburgh Postnatal Depression Scale. British Journal of Psychiatry,
          June, 1987, vol. 150 by J.L. Cox, J.M. Holden, R. Segovsky."
        </div>
      ) : (
        <br />
      )}
      <Form.Row>
        <Col>
          <LoaderButton
            data-testid={'addScoreModal-submit-button'}
            isLoading={isLoading}
            className="button-block center"
            disabled={disabled}
            onClick={addScore}
            type="submit"
            textInside="Adding score"
          >
            + Add Score
          </LoaderButton>
        </Col>
        <Col>
          <Button
            data-testid={'addScoreModal-close-button'}
            onClick={props.handleClose}
            bsPrefix="button-close"
          >
            Close
          </Button>
        </Col>
      </Form.Row>
    </>
  )
}

// modal for add score -- holds date and type selectors
export default function AddScoreModal(props) {
  const [focused, setFocused] = useState(false) // for SingleDatePicker
  const [date, setDate] = useState(moment())
  const [type, setType] = useState('')
  const surveyData = props.surveyData

  const selectedSurvey = useMemo(
    () => surveyData.find((survey) => survey.ScoreTypeEnum === type),
    [surveyData, type]
  )

  const surveyOptions = useMemo(
    () =>
      surveyData
        .sort((a, b) => String(a.Name).localeCompare(b.Name))
        .map((survey) => (
          <option
            key={survey.ScoreTypeEnum}
            name={survey.Name}
            value={survey.ScoreTypeEnum}
          >
            {survey.Name}
          </option>
        )),
    [surveyData]
  )

  return (
    <Modal size="xl" show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Manual Score Entry</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Row>
            <Form.Group
              as={Col}
              xs={3}
              sm={3}
              md={1}
              lg={1}
              style={{
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              Type&nbsp;
            </Form.Group>
            <Form.Group xs={8} sm={8} md={4} lg={4} as={Col}>
              <Form.Control
                data-testid={'addScoreModal-surveyType-select'}
                id="selectSurveyTypeForEntry"
                bsPrefix="form-input"
                as="select"
                onChange={(e) => setType(e.target.value)}
                value={type}
              >
                <>
                  <option key="select" name="Select a survey" value="">
                    Select a survey
                  </option>
                  {surveyOptions}
                </>
              </Form.Control>
            </Form.Group>
            <Form.Group
              as={Col}
              xs={3}
              sm={3}
              md={1}
              lg={1}
              style={{
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              Date
            </Form.Group>
            <Form.Group xs={8} sm={8} md={4} lg={4} as={Col}>
              <SingleDatePicker
                date={date}
                onDateChange={(date) => {
                  const dateObj = moment(new Date(date))
                  setDate(dateObj.isValid() ? dateObj : moment())
                }}
                id="manual-entry-date"
                focused={focused}
                placeholder={moment().format('LL').toString()}
                onFocusChange={(f) => setFocused(f.focused)}
                numberOfMonths={1}
                displayFormat="MM/DD/YYYY"
                onClose={() => setFocused(false)}
                isOutsideRange={(day) => {
                  return !day.isBefore(moment())
                }}
              />
            </Form.Group>
          </Form.Row>

          {selectedSurvey && (
            <Form.Row>
              <Col>{selectedSurvey.Description}</Col>
            </Form.Row>
          )}
          {selectedSurvey && <hr />}
          {selectedSurvey && (
            <AddScoreForm
              handleApiChange={props.handleApiChange}
              survey={selectedSurvey}
              handleClose={props.handleClose}
              PatientId={props.PatientId}
              date={date}
              show={props.show}
              type={type}
            />
          )}
          {/* AddScoreForm and buttons below split because of a pesky lint error */}
          {type === '' && (
            <>
              <hr />
              <Form.Row>
                <Col>
                  <LoaderButton
                    data-testid={'addScoreModal-submit-button'}
                    className="button-block center"
                    disabled
                    type="submit"
                    textInside="Adding score"
                  >
                    + Add Score
                  </LoaderButton>
                </Col>
                <Col>
                  <Button
                    data-testid={'addScoreModal-close-button'}
                    onClick={props.handleClose}
                    bsPrefix="button-close"
                  >
                    Close
                  </Button>
                </Col>
              </Form.Row>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  )
}
