import React from 'react'

import { useMutation } from '@tanstack/react-query'
import { Modal } from 'antd'

import { connectProviderToAmd } from '../../api/api-lib'
import { notification } from '../../libs/notificationLib'
import { Button } from '../BaseComponents'
import { Provider } from '../types'

type CPBProps = {
  isAmdConnected: boolean
  amdProfileId?: string
  providerId: string
  onSuccess: (newAmdId: string) => void
}

const ConnectProviderButton = ({
  isAmdConnected,
  providerId,
  amdProfileId,
  onSuccess,
}: CPBProps) => {
  const { mutate: connectProvider, isLoading } = useMutation(
    connectProviderToAmd,
    {
      onError: () => {
        Modal.error({
          title: 'Provider not found',
          content:
            'Please confirm that this user exists in AdvancedMD and that their NPI has been added correctly in Account Settings. Otherwise, please check your settings in AdvancedMD and try again.',
        })
      },
      onSuccess: ({ amdProfileId }: Provider) => {
        notification('Provider connected.')
        if (amdProfileId) onSuccess(amdProfileId)
      },
    }
  )
  const connectItem = amdProfileId ? (
    <span className="connected">Connected</span>
  ) : (
    <Button
      type="link"
      loading={isLoading}
      onClick={() => connectProvider({ providerId })}
      disabled={!isAmdConnected}
    >
      Connect
    </Button>
  )

  return connectItem
}

export default ConnectProviderButton
