import React, { useCallback, useState } from 'react'

import {
  InfoCircleFilled,
  QrcodeOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { ButtonType } from 'antd/lib/button'
import {
  Badge,
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap'

import Modal from './../../../../../stories/BaseComponents/Modal'
import QRCode from './../../../../../stories/BaseComponents/QRCode'

interface AddVitalsProps {
  shortPublicId: string
  disabled: boolean
  bPPlacement: string
  savedVitalSigns: any
  setAddVitals: (addVital: boolean) => void
  handleSaveVitals: () => void
  handleSaveCaretakerVitals: () => void
  handleFieldChange: (obj: any) => void
}

export default function AddVitals(props: AddVitalsProps) {
  const [showQRModal, setShowQRModal] = useState(false)
  const isDisabled = props.disabled
  const handleOnChange = (event: React.BaseSyntheticEvent) => {
    props.handleFieldChange(event.target.value)
  }
  const handleSaveVitalsClick = () => {
    props.handleSaveVitals()
    props.setAddVitals(true)
  }

  const handleCaretakerVitalsCancel = useCallback(() => {
    setShowQRModal(false)
  }, [])

  const handleCaretakerVitalsOpen = useCallback(() => {
    setShowQRModal(true)
  }, [])

  const modalBody = (
    <>
      <p>
        Scan this QR code using your Caretaker® device to fill in the patient
        ID.
      </p>
      <div style={{ textAlign: 'center' }}>
        <QRCode value={props.shortPublicId} />
        <div style={{ color: 'grey', marginTop: 20 }}>
          Patient ID: {props.shortPublicId}
        </div>
      </div>
    </>
  )

  const cancelButtonProps = {
    type: 'primary' as ButtonType,
  }

  return (
    <>
      <Modal
        zIndex={2000}
        visible={showQRModal}
        title="Add Caretaker® vitals"
        onCancel={handleCaretakerVitalsCancel}
        closable={false}
        children={modalBody}
        hasOkButton={false}
        hasCloseButton={true}
        cancelButtonProps={cancelButtonProps}
        cancelText="Close"
      />
      {props.savedVitalSigns?.length !== 0 && (
        <>
          <Form.Group as={Col} xs={12} sm={5} md={4} lg={3} xl={2}>
            <Form.Label className="font-weight-bold">BP placement: </Form.Label>
          </Form.Group>
          <Form.Group
            as={Col}
            xs={12}
            sm={7}
            md={4}
            lg={4}
            xl={5}
            controlId="BpPlacement"
          >
            <Form.Control
              bsPrefix="form-input"
              disabled={props.disabled}
              value={props.bPPlacement}
              onChange={handleOnChange}
            />
          </Form.Group>
        </>
      )}
      {!isDisabled && (
        <>
          <Row style={{ justifyContent: 'center' }}>
            <Button
              bsPrefix="center plain-button info button-label"
              onClick={handleSaveVitalsClick}
            >
              + Add Vitals
            </Button>
          </Row>
          {props.shortPublicId && (
            <Row style={{ justifyContent: 'center' }}>
              <div className="caretaker-title">Caretaker®: </div>
              <span>
                <Button
                  bsPrefix="center plain-button info button-label"
                  onClick={() => {
                    props.handleSaveCaretakerVitals()
                  }}
                >
                  <SyncOutlined className="icon-vitals" />
                  Add Caretaker vitals
                  <Badge style={{ marginTop: '7px' }}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="caretaker-info" bsPrefix="tooltip-general">
                          Patient Id: {props.shortPublicId} <br />
                          Click the “Add Caretaker vitals” button after the
                          treatment is complete to automatically populate
                          vitals. Any vitals recorded via Caretaker for this
                          patient within the note date will populate over 10
                          data points calculated as 30 second averages.
                          <br />
                          Please contact sales@osmind.org to learn more about
                          Caretaker.
                        </Tooltip>
                      }
                    >
                      <InfoCircleFilled
                        className="antd-center-button"
                        style={{ color: 'grey' }}
                      />
                    </OverlayTrigger>
                  </Badge>
                </Button>
              </span>
              <span>
                <Button
                  bsPrefix="center plain-button info button-label"
                  onClick={handleCaretakerVitalsOpen}
                >
                  <QrcodeOutlined className="icon-vitals" />
                  Scan QR code
                </Button>
              </span>
            </Row>
          )}
        </>
      )}
    </>
  )
}
