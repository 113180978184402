import React, { CSSProperties } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  PageHeader as AntDPageHeader,
  PageHeaderProps as AntDPageHeaderProps,
  Typography,
} from 'antd'

import './_shared.scss'

const { Text, Link } = Typography

interface PageHeaderProps extends AntDPageHeaderProps {
  title: string
  description?: string
  descriptionStyle?: CSSProperties
  link?: {
    text: string
    url: string
  }
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  description,
  descriptionStyle,
  link,
  ...props
}) => {
  return (
    <div className="site-page-header-ghost-wrapper survey-text">
      <AntDPageHeader ghost={false} title={title} {...props}>
        <span>
          {description && <Text style={descriptionStyle}>{description}</Text>}
          {link && (
            <Link
              className="support-link"
              target="_blank"
              href={link.url}
              rel="noreferrer"
            >
              <QuestionCircleOutlined /> {link.text}
            </Link>
          )}
        </span>
      </AntDPageHeader>
    </div>
  )
}

export default PageHeader
