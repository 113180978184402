export enum AggregationMethodEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface Signature {
  Notes: string
  SignedBy: string
  CreatedAt?: string
}

/** This should be the same as the NoteTypes (src/shared-types.ts) */
export enum TreatmentListEnum {
  IV_KETAMINE = 'IV Ketamine',
  IM_KETAMINE = 'IM Ketamine',
  SPRAVATO = 'Spravato',
  KAP = 'KAP',
  MEDICAL_SOAP = 'Medical SOAP',
  THERAPY_SOAP = 'Therapy SOAP',
  PSYCHOTHERAPY_NOTE = 'Psychotherapy Note',
  CLINICAL_NOTE = 'Clinical Note',
  MEMO = 'Memo',
  EVALUATION_NOTE = 'Evaluation Note',
}

export type DateProps = {
  [key: string]: number
}

export type dateConstructorProps = {
  [key in AggregationMethodEnum]: DateProps
}

export type TreatmentAggregatesProps = {
  [key in TreatmentListEnum]: dateConstructorProps
}

export interface GraphPlotProp {
  category: TreatmentListEnum | string
  date: string
  value: number
}

export type GraphDataProps = {
  [key in AggregationMethodEnum]: GraphPlotProp[]
}

export enum ReportType {
  NOTES = 'notes',
  APPOINTMENTS = 'appointments',
  SURVEYS = 'surveys',
}

export * from './graphConfigs'
