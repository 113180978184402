import React from 'react'

import moment from 'moment'
import { Accordion, Card, Col, Container, Row } from 'react-bootstrap'

import './../../PatientProfile.scss'

export default function TreatmentsToDisplay(props) {
  return (
    <Container>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={props.index.toString()}
        id="treatmentsHeader"
        bsPrefix="patient-card"
      >
        <Container>
          <Row>
            {props.TreatmentType && (
              <Col bsPrefix="psych">{props.TreatmentType}</Col>
            )}
            {props.StartDate && (
              <Col bsPrefix="psych-date">
                {moment(new Date(props.StartDate)).format(props.DateFormat) +
                  (props.EndDate &&
                    ' to ' +
                      moment(new Date(props.EndDate)).format(props.DateFormat))}
              </Col>
            )}
            {props.ReasonForTreatment && (
              <Col bsPrefix="psych">{props.ReasonForTreatment}</Col>
            )}
          </Row>
        </Container>
      </Accordion.Toggle>

      <Accordion.Collapse
        style={{ marginTop: '6px', marginBottom: '8px' }}
        eventKey={props.index.toString()}
      >
        <Container>
          <Col>
            {props.NumberSessions && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">Number of sessions: </span>
                {props.NumberSessions}
              </Row>
            )}
            {props.TherapyType && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">Therapy type: </span>
                {props.TherapyType}
              </Row>
            )}
            {props.ReasonForTreatment && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">Reason for treatment: </span>
                {props.ReasonForTreatment}
              </Row>
            )}
            {props.TreatmentEfficacy && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">Treatment efficacy: </span>
                {props.TreatmentEfficacy}
              </Row>
            )}
            {props.AdditionalNotes && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">Additional Notes: </span>
                {props.AdditionalNotes}
              </Row>
            )}
          </Col>
        </Container>
      </Accordion.Collapse>
    </Container>
  )
}
