import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const HeadTraumaCodes: any = [
  {
    '82271004': {
      title: 'Head Trauma',
      description: 'Injury of head (disorder)',
      snomedcode: 62564004,
      icdcode: 'S0990X?',
      icddescription: 'Unspecified injury of head, episode of care unspecified',
    },
  },
  {
    '62564004': {
      title: 'ht.Concussion with Loss of Consciousness',
      description: 'Concussion with loss of consciousness (disorder)',
      snomedcode: 62564004,
      icdcode: 'S060X9?',
      icddescription:
        'Concussion with loss of consciousness of unspecified duration',
    },
  },
  {
    '62106007': {
      title: 'ht.Concussion with No Loss of Consciousness',
      description: 'Concussion with no loss of consciousness (disorder)',
      snomedcode: 62106007,
      icdcode: 'S060X0?',
      icddescription: 'Concussion without loss of consciousness',
    },
  },
  {
    '127295002': {
      title: 'ht.Traumatic Brain Injury (TBI)',
      description: 'Traumatic brain injury (disorder)',
      snomedcode: 127295002,
      icdcode: 'S069X0?',
      icddescription:
        'Unspecified intracranial injury without loss of consciousness',
    },
  },
]

export const HeadTraumaDropDownValues = constructPatientDemographicObject(
  HeadTraumaCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
