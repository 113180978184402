export const MODIFIERS_REGEX = /^[a-zA-Z0-9]{1,2}$/

export enum PatientDocumentType {
  INVOICE = 'INVOICE',
  SUPERBILL = 'SUPERBILL',
  INSURANCE = 'INSURANCE',
}

export type InvoiceComponentProps = {
  isLoading: boolean
  isEditing: boolean
  isSuperbillEnabled: boolean
}
