import { NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react'
import { Empty } from 'antd'

import {
  DynamicBlockTypes,
  dynamicBlockTypesSet,
} from '../../v2/notes/Components/Blocks/Core/Block/DynamicBlockTypes'
import { DragHandle } from '../../v2/notes/Components/Blocks/Core/DragHandle/DragHandle'
import { Table, Text } from '../BaseComponents'

import styles from './StubTableBlock.module.scss'

const emptyActiveMedicationsColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Dosage',
    dataIndex: 'dosage',
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
  },
  {
    title: 'Route',
    dataIndex: 'route',
  },
  {
    title: 'Start date',
    dataIndex: 'startDate',
  },
  {
    title: 'Side effects',
    dataIndex: 'sideEffects',
  },
  {
    title: 'Efficacy',
    dataIndex: 'efficacy',
  },
]

const emptyActiveAllergiesColumns = [
  {
    title: 'Allergen Name',
    dataIndex: 'name',
  },
  {
    title: 'Reaction',
    dataIndex: 'reaction',
  },
  {
    title: 'Severity',
    dataIndex: 'severity',
  },
  {
    title: 'Onset',
    dataIndex: 'onset',
  },
]

const emptyActiveDiagnosesColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Diagnosis Date',
    dataIndex: 'diagnosis_date',
  },
  {
    title: 'Onset date',
    dataIndex: 'diagnosis_onset_date',
  },
  {
    title: 'Comments',
    dataIndex: 'diagnosis_note_content',
  },
]

const getColumns = (tableType: DynamicBlockTypes) => {
  if (tableType === 'MEDICATIONS') {
    return emptyActiveMedicationsColumns
  }
  if (tableType === 'ALLERGIES') {
    return emptyActiveAllergiesColumns
  }
  return emptyActiveDiagnosesColumns
}

type EmptyBlockProps = {
  nodeViewProps: NodeViewRendererProps
}

const tableTypeToNameMapper: Record<DynamicBlockTypes, string> = {
  MEDICATIONS: 'medications',
  DIAGNOSIS: 'diagnoses',
  ALLERGIES: 'allergies',
}

export const StubTableBlock = ({ nodeViewProps }: EmptyBlockProps) => {
  const { id, type: tableType } = nodeViewProps.node.attrs as {
    id?: string
    type?: DynamicBlockTypes
  }
  if (!id || !tableType || !dynamicBlockTypesSet.has(tableType)) {
    throw new Error(
      `Invalid template block attributes: id=${id}, type=${tableType}`
    )
  }
  const columns = getColumns(tableType)
  return (
    <NodeViewWrapper className={styles.container}>
      <DragHandle noHeight />
      <Text className={styles.title}>
        Active {tableTypeToNameMapper[tableType]}
      </Text>
      <Table
        addClassNames={[
          'no-padding',
          'no-table-tools-section',
          'alternate-colors',
          styles.tableBlock,
        ]}
        columns={columns}
        dataSource={[]}
        loading={false}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description=""
              className={styles.empty}
            >
              <Text>
                Patient's active {tableTypeToNameMapper[tableType]} will be
                shown here when a note is generated.
              </Text>
            </Empty>
          ),
        }}
      />
    </NodeViewWrapper>
  )
}
