import React, { useMemo } from 'react'

import { useFormikContext } from 'formik'

import { PracticeDataType } from '../../../../hooks/usePatientProfile/shared-types'
import { Card } from '../../../../stories/BaseComponents'
import InlineField from '../InlineField'
import { sectionContainerBodyStyle, sectionContainerStyle } from '../constants'
import { BILLING } from '../field-constants'
import { useLocations, useNotes, useProviders } from '../hooks'
import { ClaimForm } from '../types'
import { getActivePopulatedFields, getNewValues } from './billing-helper'

import styles from './shared.module.scss'

export const testIds = {
  container: 'claim-billing',
}

type BillingProps = {
  practiceData: Omit<PracticeDataType, 'CreatedAt'>
  patientId: string
}
const Billing: React.FC<BillingProps> = ({ practiceData, patientId }) => {
  const {
    data: { providerOptions, providersById },
  } = useProviders()
  const {
    data: { locationOptions, locationsByLocationId },
  } = useLocations()
  const {
    data: { notesById },
  } = useNotes(patientId)

  const { values, setValues } = useFormikContext<ClaimForm>()
  const {
    billing: { type, taxType },
  } = values

  const activeFields = useMemo(
    () =>
      getActivePopulatedFields(type, taxType, locationOptions, providerOptions),
    [type, taxType, providerOptions, locationOptions]
  )

  const handleChange = (name: string, val: string | null) => {
    const newValues = getNewValues(
      values,
      name,
      val,
      providersById,
      locationsByLocationId,
      practiceData,
      notesById
    )
    setValues(newValues)
  }

  return (
    <Card
      testId={testIds.container}
      style={sectionContainerStyle}
      bodyStyle={sectionContainerBodyStyle}
    >
      <div className={styles.section}>
        <div className={styles.header}>
          <span className={styles.title}>{'Billing'}</span>
        </div>
        <div>
          <InlineField {...BILLING.TYPE} onChange={handleChange} />
          {activeFields.map((el) => (
            <InlineField {...el} key={el.name} onChange={handleChange} />
          ))}
        </div>
      </div>
    </Card>
  )
}

export default Billing
