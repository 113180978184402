import React from 'react'

import 'moment-timezone'
import { Col, Form } from 'react-bootstrap'
import 'react-dates/initialize'

import CustomTimePicker from '../../../../../../stories/BaseComponents/CustomTimePicker'
import AddOneVital from '../../Sections/AddOneVital'
import { generateSpravatoClassName } from './Spravato'

import 'react-dates/lib/css/_datepicker.css'

interface AddSpravatoTreatmentProps {
  disabled: boolean
  fields: any
  detail?: string
  saveStartSpravatoVitals: (editVitSign: any) => void
  handleTimeChange: (value: any) => void | undefined
  handleFieldChange: (obj: any) => void
}

export default function SpravatoTreatment(props: AddSpravatoTreatmentProps) {
  return (
    <>
      <div
        className="center info"
        style={{ fontWeight: 'bold', paddingBottom: '1em' }}
      >
        Baseline Vitals
      </div>
      <AddOneVital
        Time={props.fields.StartSpravatoVitals.Time}
        HR={props.fields.StartSpravatoVitals.HR}
        BP={props.fields.StartSpravatoVitals.BP}
        RR={props.fields.StartSpravatoVitals.RR}
        Comments={props.fields.StartSpravatoVitals.Comments}
        Temp={props.fields.StartSpravatoVitals.Temp}
        fields={props.fields}
        saveOneVital={props.saveStartSpravatoVitals}
        disabled={props.disabled}
        noteType={props.fields.NoteType}
        id="spravato-baseline-vital"
        requireBP
      />
      <Form.Row style={{ width: 'fit-content' }}>
        <Form.Group
          className={
            props.fields.SpravatoAdministrationRange === null
              ? generateSpravatoClassName()
              : ''
          }
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          controlId="SpravatoAdministrationRange"
        >
          <Form.Label className="font-weight-bold">
            Were vitals prior to Spravato administration in acceptable range:
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.SpravatoAdministrationRange)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="SpravatoAdministrationRangeYes"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoAdministrationRange: event.currentTarget.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={
              JSON.parse(props.fields.SpravatoAdministrationRange) === false
            }
            style={{ marginLeft: '10px' }}
            value="false"
            id="SpravatoAdministrationRangeNo"
            disabled={props.disabled}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoAdministrationRange: event.currentTarget.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <hr />
      <div className="subsection-header">Spravato administration:</div>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          controlId="SpravatoDose"
        >
          <span
            className={
              props.fields.SpravatoDose === ''
                ? `spravato-incomplete-adjusted`
                : ''
            }
          >
            <Form.Label className="font-weight-bold">
              Dose administered
            </Form.Label>
            <Form.Check
              bsPrefix="form-check"
              style={{ marginLeft: '10px' }}
              checked={props.fields.SpravatoDose === '56 mg'}
              value="56 mg"
              id="SpravatoDose56"
              disabled={props.disabled}
              onChange={(event) =>
                props.handleFieldChange({
                  ...props.fields,
                  SpravatoDose: event.currentTarget.value,
                })
              }
              type="radio"
              label="56 mg"
              inline
            />
            <Form.Check
              bsPrefix="form-check"
              style={{ marginLeft: '10px' }}
              checked={props.fields.SpravatoDose === '84 mg'}
              value="84 mg"
              id="SpravatoDose84"
              disabled={props.disabled}
              onChange={(event) =>
                props.handleFieldChange({
                  ...props.fields,
                  SpravatoDose: event.currentTarget.value,
                })
              }
              type="radio"
              label="84 mg"
              inline
            />
            <Form.Check
              bsPrefix="form-check"
              style={{ marginLeft: '10px' }}
              checked={props.fields.SpravatoDose === 'Other'}
              value="Other"
              id="SpravatoDoseOther"
              disabled={props.disabled}
              onChange={(event) =>
                props.handleFieldChange({
                  ...props.fields,
                  SpravatoDose: event.currentTarget.value,
                })
              }
              type="radio"
              label="Other (Type in)"
              inline
            />
            {props.fields.SpravatoDose === 'Other' ? (
              <Form.Control
                bsPrefix={
                  props.fields.SpravatoDoseOther === ''
                    ? generateSpravatoClassName(true)
                    : 'form-input'
                }
                value={props.fields.SpravatoDoseOther}
                disabled={props.disabled}
                onChange={(event) =>
                  props.handleFieldChange({
                    ...props.fields,
                    SpravatoDoseOther: event.target.value,
                  })
                }
              />
            ) : null}
          </span>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          controlId="LotNumber"
        >
          <Form.Label className="font-weight-bold">Lot Number</Form.Label>
          <Form.Control
            bsPrefix={'form-input'}
            value={props.fields.LotNumber}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                LotNumber: event.target.value,
              })
            }
            disabled={props.disabled}
            as="textarea"
            rows={1}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row style={{ marginBottom: '15px' }}>
        <Form.Group
          id="spravato-start-time"
          style={{ marginBottom: '15px' }}
          as={Col}
          xs={12}
          sm={12}
          lg={2}
          xl={4}
          controlId="SpravatoStartTime"
        >
          <Form.Label className="font-weight-bold">
            Time of first device administration
          </Form.Label>
          <br />
          <CustomTimePicker
            value={props.fields.SpravatoStartTime}
            onChange={(value: any) => {
              props.handleFieldChange({
                ...props.fields,
                SpravatoStartTime: value,
              })
            }}
            className={`time-picker ${
              props.fields.SpravatoStartTime === ''
                ? generateSpravatoClassName()
                : ''
            }`}
            disabled={props.disabled}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={12} md={4} lg={8}>
          <Form.Label className="font-weight-bold">
            Manufacturing Information
          </Form.Label>
          <Form.Control
            bsPrefix="form-input"
            value={props.fields.SpravatoManufacture}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoManufacture: event.target.value,
              })
            }
            disabled={props.disabled}
            as="textarea"
            rows={1}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
