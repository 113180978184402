import React, { useMemo, useState } from 'react'

import { Image } from 'antd'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Document, Page, pdfjs } from 'react-pdf'

import {
  saveApprovedJotform,
  updateJotformViewedStatus,
} from '../../api/api-lib'
import { reviewLabOrder } from '../../api/labs'
import { notification } from '../../libs/notificationLib'
import { Button as AntButton } from '../../stories/BaseComponents'
import LoaderButton from '../Buttons/LoaderButton'
import ConfirmationModal from './ConfirmationModal'

import styles from './PDFModal.module.scss'

// necessary for pdf viewer (react-pdf) to work
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.${pdfjs.version}.js`

// import icon

const ImportIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 2H19V4H5V2ZM12 6L18 12L16.59 13.41L13 9.83L13 22H11L11 9.83L7.41 13.41L6 12L12 6Z"
      fill="#4E7BEF"
    />
  </svg>
)

const ReviewIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 19H5V17H19V19ZM10 14.42L6 10.42L7.41 9.01L10 11.59L16.59 5L18 6.42L10 14.42Z"
      fill="#4E7CEF"
    />
  </svg>
)

export default function PDFModal(props) {
  const [page, flipPage] = useState(1)
  const [numberOfPages, setNumberOfPages] = useState(1)
  const [scale, setScale] = useState(1.0)
  const [isImportModal, showImportModal] = useState(false)
  const [loadingReviewButton, setLoadingReviewButton] = useState(false)
  const [loadingImportButton, setLoadingImportButton] = useState(false)
  const [loadingLabButton, setLoadingLabButton] = useState(false)
  const { activeSignatureRequest } = props

  function increaseScale() {
    const newScale = scale + 0.1
    setScale(newScale)
  }

  function decreaseScale() {
    const newScale = scale - 0.1
    setScale(newScale)
  }

  // receives and sets the number of pages in file
  function onDocumentLoadSuccess(e) {
    setNumberOfPages(e.numPages)
  }

  const handleReview = async () => {
    const newJotformId = activeSignatureRequest.RequestReference.replace(
      'JOTFORM#',
      ''
    )
    setLoadingReviewButton(true)
    await updateJotformViewedStatus({
      patientId: activeSignatureRequest.PatientId,
      jotformId: newJotformId,
    })
    setLoadingReviewButton(false)
    props.handleShow(false)
  }

  const handleImport = async () => {
    const newJotformId = activeSignatureRequest.RequestReference.replace(
      'JOTFORM#',
      ''
    )
    showImportModal(false)
    setLoadingImportButton(true)
    await saveApprovedJotform({
      patientId: activeSignatureRequest.PatientId,
      jotformId: newJotformId,
    })
    setLoadingImportButton(false)
    props.handleShow(false)
    notification(
      'Successfully imported the form data into the patient profile! Please refresh the page to see changes.',
      'success'
    )
  }

  const currentPageTitle = (
    <div>
      {props.fileContainer && (
        <>
          <span className="info">{props.fileContainer.title}</span>(Page {page}{' '}
          of {numberOfPages})
        </>
      )}
    </div>
  )

  // previous button on modal--disabled when at the start of the document
  const prev = (
    <>
      {page > 1 ? (
        <Button bsPrefix="button-block" onClick={() => flipPage(page - 1)}>
          <i className="fa fa-arrow-left" aria-hidden="true" />
        </Button>
      ) : (
        <>
          <Button bsPrefix="button-block" disabled>
            <i
              style={{ color: '#3e7ef7' }}
              className="fa fa-arrow-left"
              aria-hidden="true"
            />
          </Button>
        </>
      )}
    </>
  )

  // next button on modal--disabled when at the end of the document
  const next = (
    <>
      {page < numberOfPages ? (
        <Button
          id="nextPagePDFButton"
          bsPrefix="button-block"
          onClick={() => flipPage(page + 1)}
        >
          <i className="fa fa-arrow-right" aria-hidden="true" />
        </Button>
      ) : (
        <>
          <Button id="noNextPagePDFButton" bsPrefix="button-block" disabled>
            <i
              style={{ color: '#3e7ef7' }}
              className="fa fa-arrow-right"
              aria-hidden="true"
            />
          </Button>
        </>
      )}
    </>
  )

  const zoomOut = (
    <>
      {scale > 0.56 ? (
        <Button bsPrefix="button-block" onClick={decreaseScale}>
          <i className="fa fa-search-minus" aria-hidden="true" />
        </Button>
      ) : (
        <Button disabled bsPrefix="button-block">
          <i className="fa fa-search-minus" aria-hidden="true" />
        </Button>
      )}
    </>
  )

  const zoomIn = (
    <>
      {scale < 2 ? (
        <Button bsPrefix="button-block" onClick={increaseScale}>
          <i className="fa fa-search-plus" aria-hidden="true" />
        </Button>
      ) : (
        <Button disabled bsPrefix="button-block">
          <i className="fa fa-search-plus" aria-hidden="true" />
        </Button>
      )}
    </>
  )

  let file = props.fileContainer || null

  if (props.fileContainer?.file) {
    file = props.fileContainer.file
  }

  const modalBody = useMemo(
    () => (
      <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
        {file?.uri ? (
          <Image
            src={file.uri}
            className="image-preview-mask"
            preview={false}
          />
        ) : (
          <div style={{ maxWidth: '100%' }}>
            <Document
              file={file}
              onLoadSuccess={(e) => onDocumentLoadSuccess(e)}
            >
              <Page scale={scale} pageNumber={page} />
            </Document>
          </div>
        )}
      </Modal.Body>
    ),
    [file, file?.uri, scale, page]
  )

  // *~* MODAL TIME *~* includes a handy dandy PDF viewer + nav buttons
  const modal = (
    <Modal size="xl" show={props.show} onHide={() => props.handleShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{currentPageTitle}</Modal.Title>
      </Modal.Header>

      <ScrollContainer
        hideScrollbars={false}
        horizontal
        style={{ cursor: 'grab' }}
        className="scroll-container"
      >
        {modalBody}
      </ScrollContainer>
      {/* CONTROLS */}
      <Modal.Footer>
        <Container className="pdf-modal-footer">
          {!file?.uri && (
            <Row className="justify-content-center">
              <Col xs={2} sm={2}>
                {prev}
              </Col>
              {!props.isLabPdf && (
                <Col xs={3} sm={2}>
                  {zoomOut}
                </Col>
              )}
              {!props.isLabPdf && (
                <Col xs={3} sm={2}>
                  {zoomIn}
                </Col>
              )}
              <Col xs={2} sm={2}>
                {next}
              </Col>
              {props.download && (
                <Col>
                  <Button
                    bsPrefix="button"
                    onClick={() =>
                      props.download.save('attachment-preview.pdf')
                    }
                  >
                    <i className="fa fa-download" aria-hidden="true" />
                  </Button>
                </Col>
              )}
            </Row>
          )}
          <br />
          {activeSignatureRequest &&
            activeSignatureRequest.SignatureStatus !== 'imported' && (
              <Row className="justify-content-center mb-2">
                <Col md={10}>
                  <LoaderButton
                    className="button"
                    type="button"
                    isLoading={loadingImportButton}
                    textInside="Sending"
                    onClick={() => showImportModal(true)}
                  >
                    <ImportIcon />
                    Import
                  </LoaderButton>
                </Col>
              </Row>
            )}
          {activeSignatureRequest &&
            activeSignatureRequest.SignatureStatus === 'requires review' && (
              <Row className="justify-content-center mb-2">
                <Col md={10}>
                  <LoaderButton
                    className="button"
                    type="button"
                    isLoading={loadingReviewButton}
                    textInside="Reviewing"
                    onClick={handleReview}
                  >
                    <ReviewIcon />
                    Mark as Reviewed
                  </LoaderButton>
                </Col>
              </Row>
            )}
          {props.isLabPdf && props.labResult ? (
            <div class={styles.labsButtonsDiv}>
              {props.labResult.acknowledgedStatus === 'readyForReview' && (
                <AntButton
                  type="primary"
                  id={styles.reviewLabOrder}
                  loading={loadingLabButton}
                  onClick={async () => {
                    setLoadingLabButton(true)
                    await reviewLabOrder(props.labResult.key)
                    props.setLabsRefresh(true)
                    setLoadingLabButton(false)
                    props.handleShow(false)
                  }}
                >
                  Mark lab result as reviewed
                </AntButton>
              )}
              <AntButton onClick={() => props.handleShow(false)}>
                Close
              </AntButton>
            </div>
          ) : (
            <Row className="justify-content-center">
              <Col md={10}>
                <Button
                  id="closePDFModal"
                  bsPrefix="button-close"
                  onClick={() => props.handleShow(false)}
                >
                  Close
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </Modal.Footer>
    </Modal>
  )

  const renderConfirmationModal = (
    <ConfirmationModal
      title="Confirm import"
      body={
        <>
          <p>
            The patient-reported responses will be imported from the intake form
            to the patient profile for all patient profile fields that do not
            have existing data.{' '}
            <span className="font-weight-bold">
              Please review and edit imported fields for accuracy.
            </span>
          </p>
          <br />
          <p>
            Please note that information will not be imported for fields of the
            patient profile that already have information.
          </p>
        </>
      }
      size="lg"
      show={isImportModal}
      onCancel={() => showImportModal(false)}
      onConfirm={handleImport}
    />
  )

  return (
    <>
      {modal}
      {renderConfirmationModal}
    </>
  )
}
