import React, { useEffect, useState } from 'react'

import {
  CarryOutOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Modal } from 'antd'
import { FixedType } from 'rc-table/lib/interface'

import {
  deleteAppointmentType,
  getAppointmentSettings,
  getLocation,
  getTeammateData,
} from '../../api/api-lib'
import { getRooms } from '../../api/api-lib-typed'
import {
  LocationItem,
  RoomItem,
} from '../../containers/Authentication/Locations'
import { getTextToDisplay } from '../../containers/Scheduling/utils/providers-name-text-display'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import { Teammate } from '../../shared-types'
import {
  Button,
  Card,
  Space,
  Table,
  Tooltip,
} from '../../stories/BaseComponents'
import { FILTER_LOCAL_STORAGE_LOCATION } from '../../stories/Scheduling/constants'
import AppointmentSettingsModal from './AppointmentSettingsModal'

import styles from '../../containers/_shared.module.scss'
import './Scheduling.scss'

export type EventType = 'internal' | 'external'

export interface AppointmentType {
  id: string
  providerId: string
  name: string
  durationInMinutes?: number
  eventProviders?: string[]
  roomId?: string
  instructions?: string
  internalNotes?: string
  colorHex?: string
  isDeleted: boolean
  createdAt: string
  updatedAt: string
  isSelfSchedulable?: boolean
  selfSchedulingRecipientIds?: string[]
}

export interface AppointmentTypeResponse {
  appointmentTypes: AppointmentType[]
  isSelfSchedulingEnabled: boolean
}

export interface AppointmentTypeTableItem {
  id: string
  name: string
  durationInMinutes?: number | string
  eventProviders?: string[]
  instructions?: string
  internalNotes?: string
  roomId?: string
  roomName?: string
  colorHex?: string
  isSelfSchedulable?: boolean
  selfSchedulingRecipientIds?: string[]
}

function funcSort(item1: any, item2: any) {
  if (!item1 || !item2) return 0

  if (item1 > item2) return 1
  else if (item1 === item2) return 0
  return -1
}

export default function AppointmentSettings() {
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const [modalType, setModalType] = useState<string>('isAdd')
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>()
  const [rooms, setRooms] = useState<RoomItem[]>()
  const [locations, setLocations] = useState<LocationItem[]>()
  const [editAppointmentTypeItem, setEditAppointmentTypeItem] =
    useState<AppointmentTypeTableItem>()
  const [isTableLoading, setTableLoading] = useState<boolean>(false)
  const [teamData, setTeamData] = useState<Teammate[]>([])
  const [refresh, setRefresh] = useState<boolean>(false)
  const [isSelfSchedulingEnabled, setIsSelfSchedulingEnabled] =
    useState<boolean>(false)

  async function getAppointmentSettingsData() {
    try {
      setTableLoading(true)
      const [appointmentTypeData, locationInfo, roomList, allTeam] =
        await Promise.all([
          getAppointmentSettings() as Promise<AppointmentTypeResponse>,
          getLocation() as Promise<LocationItem[]>,
          getRooms() as Promise<RoomItem[]>,
          getTeammateData(),
        ])

      const { appointmentTypes, isSelfSchedulingEnabled } = appointmentTypeData
      const activeTeam = allTeam.filter(
        (user: Teammate) =>
          !user.isDeactivated && user.lastActive !== 'never_logged_in'
      )
      setAppointmentTypes(
        appointmentTypes.filter((apptType) => !apptType.isDeleted)
      )
      setIsSelfSchedulingEnabled(isSelfSchedulingEnabled)
      setRooms(roomList)
      setLocations([...locationInfo])
      setTeamData(activeTeam)
      setTableLoading(false)
    } catch (e) {
      console.error('Error when feching appointment settings', e)
    }
  }

  useEffect(() => {
    getAppointmentSettingsData()
  }, [refresh])

  function handleAddAppointmentType() {
    setModalType('isAdd')
    setShowAddModal(true)
  }

  function handleEditAppointmentType(
    appointmentType: AppointmentTypeTableItem
  ) {
    setModalType('isEdit')
    setEditAppointmentTypeItem(appointmentType)
    setShowAddModal(true)
  }

  async function handleDeleteAppointmentType(
    appointmentType: AppointmentTypeTableItem
  ) {
    try {
      await deleteAppointmentType(appointmentType.id)

      // remove this appointment type from selected scheduling filters
      const schedulingFilters = JSON.parse(
        localStorage.getItem(FILTER_LOCAL_STORAGE_LOCATION) || '{}'
      )
      if (schedulingFilters && schedulingFilters.appointmentTypesSelected) {
        const newApptFilters =
          schedulingFilters.appointmentTypesSelected.filter(
            (appt: string) => appt !== appointmentType.name
          )
        schedulingFilters.appointmentTypesSelected = newApptFilters
        localStorage.setItem(
          FILTER_LOCAL_STORAGE_LOCATION,
          JSON.stringify(schedulingFilters)
        )
      }

      notification('Successfully deleted an appointment type.', 'success')
      setRefresh(!refresh)
    } catch (e) {
      console.error('Error when deleting an appointment type', e)
      onError(new Error('Unable to delete appointment type'))
    }
  }

  function generateProviderTextTodisplay(
    appointmentType: AppointmentTypeTableItem
  ) {
    if (
      appointmentType?.eventProviders &&
      appointmentType.eventProviders.length > 0
    ) {
      const textProviderArray = appointmentType.eventProviders.map((evP) => {
        return getTextToDisplay(evP, teamData)
      })
      return <Space size="middle">{textProviderArray.join(', ')}</Space>
    } else {
      return <Space size="middle">{'-'.repeat(6)}</Space>
    }
  }

  function generateAppointmentColorCircle(
    appointmentType: AppointmentTypeTableItem
  ) {
    if (appointmentType.colorHex) {
      return (
        <div
          className="appt-type-color-circle"
          style={{ backgroundColor: appointmentType.colorHex }}
        />
      )
    }
  }

  function durationTodisplay(appointmentType: AppointmentTypeTableItem) {
    if (appointmentType.durationInMinutes) {
      const durationInMinutes =
        typeof appointmentType.durationInMinutes === 'string'
          ? parseInt(appointmentType.durationInMinutes)
          : appointmentType.durationInMinutes
      const hourValue = Math.floor(durationInMinutes / 60)
      const minuteValue = durationInMinutes % 60
      let durationValue
      if (hourValue !== 0) {
        if (minuteValue !== 0) {
          durationValue = `${hourValue}hr ${minuteValue}m`
        } else {
          durationValue = `${hourValue}hr`
        }
      } else {
        durationValue = `${minuteValue}m`
      }
      return (
        <Space size="middle" className="duration-space-item">
          {durationValue}
        </Space>
      )
    }
    return <Space size="middle" />
  }

  function handleDeleteButtonClick(appointmentType: AppointmentTypeTableItem) {
    Modal.confirm({
      title: 'Delete appointment type',
      content: 'Are you sure you want to delete this appointment type?',
      okText: 'Ok',
      centered: true,
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'default',
      },
      okButtonProps: {
        type: 'primary',
      },
      onOk: () => {
        handleDeleteAppointmentType(appointmentType)
      },
    })
  }

  const columns = [
    {
      dataIndex: 'colorHex',
      key: 'colorHex',
      render: (_text: any, record: AppointmentTypeTableItem) => {
        return generateAppointmentColorCircle(record)
      },
      width: 50,
      fixed: 'left' as FixedType,
    },
    {
      title: '',
      key: 'isSelfSchedulable',
      render: (text: any, record: AppointmentTypeTableItem) => {
        return record.isSelfSchedulable ? (
          <Tooltip
            title="Patients can book this appointment in the Osmind app"
            placement="left"
          >
            <CarryOutOutlined style={{ fontSize: 16 }} />
          </Tooltip>
        ) : null
      },
      fixed: 'left' as FixedType,
      width: 25,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: AppointmentTypeTableItem, b: AppointmentTypeTableItem) => {
          return funcSort(a.name, b.name)
        },
        multiple: 1,
      },
      width: 150,
      ellipsis: true,
      fixed: 'left' as FixedType,
    },
    {
      title: 'Duration',
      dataIndex: 'durationInMinutes',
      key: 'durationInMinutes',
      sorter: {
        compare: (a: AppointmentTypeTableItem, b: AppointmentTypeTableItem) => {
          return funcSort(a.durationInMinutes, b.durationInMinutes)
        },
        multiple: 2,
      },
      render: (text: any, record: AppointmentTypeTableItem) => {
        return durationTodisplay(record)
      },
      width: 150,
    },
    {
      title: 'Provider(s)',
      dataIndex: 'eventProviders',
      key: 'eventProviders',
      sorter: {
        compare: (a: AppointmentTypeTableItem, b: AppointmentTypeTableItem) => {
          return funcSort(a.eventProviders, b.eventProviders)
        },
        multiple: 4,
      },
      render: (text: any, record: AppointmentTypeTableItem) => {
        return generateProviderTextTodisplay(record)
      },
      width: 200,
    },
    {
      title: 'Facility / Room',
      dataIndex: 'roomName',
      key: 'roomName',
      sorter: {
        compare: (a: AppointmentTypeTableItem, b: AppointmentTypeTableItem) => {
          return funcSort(a.roomName, b.roomName)
        },
        multiple: 5,
      },
      width: 200,
    },
    {
      title: 'Description',
      dataIndex: 'instructions',
      key: 'instructions',
      sorter: {
        compare: (a: AppointmentTypeTableItem, b: AppointmentTypeTableItem) => {
          return funcSort(a.instructions, b.instructions)
        },
        multiple: 6,
      },
      ellipsis: true,
      width: 300,
    },
    {
      title: 'Internal Notes',
      dataIndex: 'internalNotes',
      key: 'internalNotes',
      sorter: {
        compare: (a: AppointmentTypeTableItem, b: AppointmentTypeTableItem) => {
          return funcSort(a.internalNotes, b.internalNotes)
        },
        multiple: 6,
      },
      ellipsis: true,
      width: 300,
    },
    {
      title: '',
      key: 'action',
      width: 230,
      fixed: 'right' as FixedType,
      render: (text: any, record: AppointmentTypeTableItem) => {
        return (
          <Space size="middle">
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => handleEditAppointmentType(record)}
              className="antd-center-button action-button-edit"
            >
              Edit
            </Button>

            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteButtonClick(record)}
              className="antd-center-button action-button-delete"
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  const data = React.useMemo(() => {
    const appointmentList: AppointmentTypeTableItem[] = (
      appointmentTypes || []
    ).map((apptType, index) => {
      let roomName = ''
      const room = rooms?.find((item) => item.roomId === apptType.roomId)
      if (room) {
        roomName = room.deleted ? `${room.roomName} (removed)` : room.roomName
      }
      return {
        key: `${index}`,
        id: apptType.id,
        name: apptType.name,
        durationInMinutes: apptType.durationInMinutes,
        eventProviders: apptType.eventProviders,
        roomId: apptType.roomId,
        roomName,
        instructions: apptType.instructions,
        internalNotes: apptType.internalNotes,
        colorHex: apptType.colorHex,
        isSelfSchedulable: apptType.isSelfSchedulable || false,
        selfSchedulingRecipientIds: apptType.selfSchedulingRecipientIds || [],
      }
    })

    return appointmentList
  }, [appointmentTypes, rooms])

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        <Card bordered={false}>
          <div className="headerWrapper headerWrapper_row">
            <span className="headerWrapper_title">Appointment types</span>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddAppointmentType}
              className="antd-center-button"
            >
              Add appointment type
            </Button>
          </div>

          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={isTableLoading}
            size="middle"
            scroll={{ x: '100vw' }}
          />
        </Card>

        <AppointmentSettingsModal
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
          appointmentItem={
            modalType === 'isAdd' ? undefined : editAppointmentTypeItem
          }
          appointmentTypes={appointmentTypes}
          rooms={rooms}
          locations={locations}
          teamData={teamData}
          refreshTable={() => setRefresh(!refresh)}
          isSelfSchedulingEnabled={isSelfSchedulingEnabled}
        />
      </div>
    </div>
  )
}
