import React, { useEffect, useState } from 'react'

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Button, Card, Col, Form, Row, Tab } from 'react-bootstrap'

import { isEmptyObject } from '../../../../../../libs/utils'
import { useClinicalNoteContext } from '../../../ClinicalNoteContext'
import { FieldType, Section } from './constants'

export default function PhysicalExam(props: any) {
  const { updateType, fields, setFields, findMostRecentEntry } =
    useClinicalNoteContext()

  const state = fields.PhysicalExam as any
  const setState = (
    key: string,
    value?: string | boolean | Record<string, string>
  ) => {
    return setFields({
      ...fields,
      PhysicalExam: {
        ...state,
        [key]: value,
      },
    })
  }

  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    setExpanded((isExpanded) => isExpanded || !isEmptyObject(state))
  }, [updateType, state])

  const mostRecentEntry = findMostRecentEntry('PhysicalExam')

  const renderText = (key: string, value: string | undefined) => {
    const text = value ?? ''
    return (
      <Form.Group key={key} as={Col} xs={12} sm={12} lg={4} xl={4}>
        <Form.Label style={{ margin: 0 }}> {key}</Form.Label>
        <Form.Control
          as="textarea"
          rows={2}
          key={`${key}`}
          style={{ fontSize: '14px' }}
          bsPrefix="form-input"
          value={text}
          disabled={props.disabled}
          onChange={(event) => setState(key, event.target.value)}
        />
      </Form.Group>
    )
  }

  const renderCheckbox = (key: string, value: any, { hasText }: any) => {
    const checked = value !== undefined
    return (
      <Form.Group key={key} as={Col} xs={12} sm={12} lg={4} xl={4}>
        <Form.Row key={key}>
          <Form.Check
            id={`checkbox-${key}`}
            type="checkbox"
            style={{ marginLeft: '5px' }}
            checked={checked}
            label={key}
            disabled={props.disabled}
            onChange={(event) =>
              setState(key, event.currentTarget.checked ? '' : undefined)
            }
          />

          {checked && hasText && (
            <Form.Control
              bsPrefix="form-input"
              placeholder="Additional notes"
              style={{ margin: '5px 10px' }}
              value={value}
              disabled={props.disabled}
              onChange={(event) => setState(key, event.target.value)}
            />
          )}
        </Form.Row>
      </Form.Group>
    )
  }

  return !expanded ? (
    <Row style={{ justifyContent: 'center' }}>
      <span>
        <Button
          bsPrefix="center plain-button info button-label"
          onClick={() => setExpanded(true)}
        >
          + Physical Examination
        </Button>
      </span>
    </Row>
  ) : (
    <Accordion defaultActiveKey="0" id="physicalExam">
      <Accordion.Toggle
        bsPrefix="accordion-header very-large accordion-toggle"
        as={Card.Header}
        eventKey="0"
      >
        <div
          className="alert-clinical-notes"
          style={{ cursor: 'pointer', marginBottom: 16, display: 'flex' }}
        >
          <span style={{ width: 'calc(100% - 150px)', paddingLeft: '90px' }}>
            Physical Examination
          </span>
          {mostRecentEntry && !props.disabled ? (
            <Button
              className="modal-save-button"
              style={{ width: '150px' }}
              onClick={() =>
                setFields({ ...fields, PhysicalExam: mostRecentEntry.value })
              }
            >
              Copy most recent-{' '}
              {new Date(mostRecentEntry.CreatedOn).toLocaleDateString()}
            </Button>
          ) : null}
          {!props.disabled && (
            <Button
              className="modal-save-button"
              style={{ width: '120px' }}
              onClick={() => {
                setFields({
                  ...fields,
                  PhysicalExam: {},
                })
                return setExpanded(false)
              }}
            >
              <FontAwesomeIcon
                icon={faTrash}
                style={{ marginLeft: '5px', marginRight: '5px', fontSize: 16 }}
              />
              Remove
            </Button>
          )}
        </div>
      </Accordion.Toggle>

      <Tab.Content
        style={{
          display: 'flex',
          flexFlow: 'column wrap',
          paddingTop: 16,
          maxHeight: 400,
          overflowX: 'scroll',
        }}
      >
        {Object.entries(Section).map(([key, config]) => {
          const value = state?.[key]

          let elem = null
          if (config.type === FieldType.TEXT) {
            elem = renderText(key, value)
          }
          if (config.type === FieldType.CHECKBOX) {
            elem = renderCheckbox(key, value, config)
          }

          if (config.break) {
            elem = (
              <>
                {elem}
                <div style={{ height: '100vh', width: 0 }} />
              </>
            )
          }

          return elem
        })}
      </Tab.Content>
    </Accordion>
  )
}
