import React, { useCallback, useMemo, useState } from 'react'

import { FormInstance } from 'antd'
import Form from 'antd/lib/form'
import { SelectValue } from 'antd/lib/select'

import {
  BirthSexDropDownValues,
  EthnicityTitleEnum,
  GenderIdentityDropDownValues,
  LegalRecordedSexDropDownValues,
  RaceTitleEnum,
  americanIndianSubRaceDropdownValues,
  asianSubRaceDropDownValues,
  blackOrAfricanAmericanSubRaceDropDownValues,
  emergencyContactRelationshipDropDownValues,
  ethnicityDropdownValues,
  hispanicLatinoSubEthnicityDropDownValues,
  nativeHawaiianSubRaceDropDownValues,
  raceDropdownValues,
  whiteSubRaceDropDownValues,
} from '../../../shared/Demographics'
import {
  DateInput,
  Divider,
  DynamicSubForm,
  Input,
  PhoneNumberInput,
  Radio,
  Select,
  Typography,
} from '../../BaseComponents'
import { SubFormProps } from '../../BaseComponents/DynamicSubForm'
import { detectBadPatientName } from '../../BaseComponents/helpers/validationRules'
import {
  email,
  normalizeText,
  optionalFreeText,
  required,
  requiredString,
} from '../validation-rules'
import Address from './PatientIntakeAddress'
import ProviderRolesMultiSelect from './ProviderRolesMultiSelect'
import { Header, MappedCodeSetOptions, mapCodesetToListView } from './helpers'
import {
  GeneralInformationQuestionKeys,
  HealthcareProviderSubForm,
} from './question-keys'

import styles from './_shared.module.scss'

// TODO: We need to convert the height feet and inches into inches and store
// that as a string until we change the schema to a number
interface Props {
  form: FormInstance
  reviewMode?: boolean
}

const { Title } = Typography
const PRONOUNS = ['she/her/hers', 'he/him/his', 'they/them/theirs'] // TODO: update to codeset, and update codeset as needed
const personalPronouns = PRONOUNS.map((label) => ({ label, value: label }))

// TODO: connect with codeset?
const phoneType = [
  { label: 'Mobile', value: 'mobile' },
  { label: 'Home', value: 'home' },
  { label: 'Work', value: 'work' },
]

const GeneralInformation = ({ form, reviewMode }: Props) => {
  const [showNameToUse, setShowNameToUse] = useState(false)
  const [showFormer, setShowFormer] = useState(false)

  const legalRecordedSex = useMemo(() => {
    return LegalRecordedSexDropDownValues.map(mapCodesetToListView)
  }, [])

  const birthSex = useMemo(() => {
    return BirthSexDropDownValues.map(mapCodesetToListView)
  }, [])

  const genderIdentity = useMemo(() => {
    return GenderIdentityDropDownValues.map(mapCodesetToListView)
  }, [])

  const ethnicity = useMemo(() => {
    return ethnicityDropdownValues.map(mapCodesetToListView)
  }, [])

  const specificEthnicity = useMemo(() => {
    return hispanicLatinoSubEthnicityDropDownValues.map(mapCodesetToListView)
  }, [])

  const race = useMemo(() => {
    return raceDropdownValues.map(mapCodesetToListView)
  }, [])

  const subRaces = useMemo(() => {
    return {
      [RaceTitleEnum.AMERICAN_INDIAN]:
        americanIndianSubRaceDropdownValues.map(mapCodesetToListView),
      [RaceTitleEnum.ASIAN]:
        asianSubRaceDropDownValues.map(mapCodesetToListView),
      [RaceTitleEnum.BLACK_OR_AFRICAN_AMERICAN]:
        blackOrAfricanAmericanSubRaceDropDownValues.map(mapCodesetToListView),
      [RaceTitleEnum.NATIVE_HAWAIIAN]:
        nativeHawaiianSubRaceDropDownValues.map(mapCodesetToListView),
      [RaceTitleEnum.WHITE]:
        whiteSubRaceDropDownValues.map(mapCodesetToListView),
      [RaceTitleEnum.UNKNOWN]: [],
      [RaceTitleEnum.NO_ANSWER]: [],
    }
  }, [])

  const emergencyRelationshipTypes = useMemo(() => {
    return emergencyContactRelationshipDropDownValues.map(mapCodesetToListView)
  }, [])

  const resetSpecificEthnicity = useCallback(
    (value: SelectValue) => {
      if (value !== EthnicityTitleEnum.HISPANIC_LATINO) {
        form.resetFields([GeneralInformationQuestionKeys.SPECIFIC_ETHNICITY])
      }
    },
    [form]
  )

  const resetRaceSubCategories = useCallback(
    () => form.resetFields([GeneralInformationQuestionKeys.RACE_SUBCATEGORY]),
    [form]
  )

  const validateName =
    (shouldIncludeDiacritics = false) =>
    () => ({
      validator(_: any, value: string) {
        return detectBadPatientName(value, shouldIncludeDiacritics)
      },
    })

  return (
    <>
      <Title level={2}>General Information</Title>
      <Divider />
      <Header
        title="Legal name"
        description="This is the name used on legal documents like insurance cards, passports and driver's licenses."
      />
      <Form.Item
        label="First name"
        name={GeneralInformationQuestionKeys.LEGAL_FIRST_NAME}
        rules={[
          requiredString('Please input legal first name'),
          validateName(),
        ]}
        normalize={normalizeText}
      >
        <Input disabled={reviewMode} size="large" />
      </Form.Item>
      <Form.Item
        label="Middle name"
        name={GeneralInformationQuestionKeys.LEGAL_MIDDLE_NAME}
        rules={[
          optionalFreeText('Please input valid middle name'),
          validateName(),
        ]}
        normalize={normalizeText}
      >
        <Input disabled={reviewMode} size="large" />
      </Form.Item>
      <Form.Item
        label="Last name"
        name={GeneralInformationQuestionKeys.LEGAL_LAST_NAME}
        rules={[requiredString('Please input legal last name'), validateName()]}
        normalize={normalizeText}
      >
        <Input
          disabled={reviewMode}
          size="large"
          onBlur={(props) =>
            (props.currentTarget.value = props.currentTarget.value.trim())
          }
        />
      </Form.Item>
      <Divider />
      <Form.Item label="Do you have a different name you'd like us to use?">
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          onChange={(event) => setShowNameToUse(event.target.value)}
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {(showNameToUse || reviewMode) && (
        <>
          <Header
            title="Name to use"
            description="This is the name you would like to be addressed by when people speak with you."
            shouldAddSpacing
          />
          <Form.Item
            label="First name"
            name={GeneralInformationQuestionKeys.FIRST_NAME_TO_USE}
            rules={[
              {
                ...optionalFreeText('Please input valid first name'),
                whitespace: false,
              },
              validateName(true),
            ]}
            normalize={normalizeText}
          >
            <Input disabled={reviewMode} size="large" />
          </Form.Item>
          <Form.Item
            label="Middle name"
            name={GeneralInformationQuestionKeys.MIDDLE_NAME_TO_USE}
            rules={[
              {
                ...optionalFreeText('Please input valid middle name'),
                whitespace: false,
              },
              validateName(true),
            ]}
            normalize={normalizeText}
          >
            <Input disabled={reviewMode} size="large" />
          </Form.Item>
          <Form.Item
            label="Last name"
            name={GeneralInformationQuestionKeys.LAST_NAME_TO_USE}
            rules={[
              {
                ...optionalFreeText('Please input valid last name'),
                whitespace: false,
              },
              validateName(true),
            ]}
            normalize={normalizeText}
          >
            <Input disabled={reviewMode} size="large" />
          </Form.Item>
        </>
      )}
      <Divider />
      <Form.Item label="Do you have a former name?">
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          onChange={(event) => setShowFormer(event.target.value)}
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {(showFormer || reviewMode) && (
        <>
          <Header
            title="Former name"
            description="This would include any prior legal names or names you were known by (e.g. maiden names or names on birth certificates). "
            shouldAddSpacing
          />
          <Form.Item
            label="First name"
            name={GeneralInformationQuestionKeys.FORMER_FIRST_NAME}
            rules={[
              {
                ...optionalFreeText('Please input valid first name'),
                whitespace: false,
              },
              validateName(true),
            ]}
            normalize={normalizeText}
          >
            <Input disabled={reviewMode} size="large" />
          </Form.Item>
          <Form.Item
            label="Middle name"
            name={GeneralInformationQuestionKeys.FORMER_MIDDLE_NAME}
            rules={[
              {
                ...optionalFreeText('Please input valid middle name'),
                whitespace: false,
              },
              validateName(true),
            ]}
            normalize={normalizeText}
          >
            <Input disabled={reviewMode} size="large" />
          </Form.Item>
          <Form.Item
            label="Last name"
            name={GeneralInformationQuestionKeys.FORMER_LAST_NAME}
            rules={[
              {
                ...optionalFreeText('Please input valid last name'),
                whitespace: false,
              },
              validateName(true),
            ]}
            normalize={normalizeText}
          >
            <Input disabled={reviewMode} size="large" />
          </Form.Item>
        </>
      )}
      <Divider />
      <Header
        title="Contact information"
        description="Please list where you currently live, including an apartment number if there is one. "
      />
      {/* would need to save under ADDRESS on submit */}
      <Address
        form={form}
        disabled={reviewMode}
        label="Street address"
        name={GeneralInformationQuestionKeys.ADDRESS}
        required
        size="large"
      />
      <PhoneNumberInput
        form={form}
        formItemProps={{ label: 'Phone number' }}
        disabled={reviewMode}
        name={GeneralInformationQuestionKeys.PHONE}
        inputProps={{ size: 'large' }}
        required
      />
      <Form.Item
        label="Phone type"
        name={GeneralInformationQuestionKeys.PHONE_TYPE}
        rules={[required('Please select your phone type')]}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          options={phoneType}
          optionType="button"
        />
      </Form.Item>
      <Divider />
      <Header title="Personal background" />
      <DateInput
        className={styles.dob}
        label="Date of birth"
        name={GeneralInformationQuestionKeys.DOB}
        required
        disabled={reviewMode}
        form={form}
      />
      <Form.Item
        label="Birth sex"
        name={GeneralInformationQuestionKeys.BIRTH_SEX}
        rules={[required('Please select one')]}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          options={birthSex}
          optionType="button"
        />
      </Form.Item>
      <Form.Item
        label="Legal sex"
        name={GeneralInformationQuestionKeys.LEGAL_SEX}
        rules={[required('Please select one')]}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          options={legalRecordedSex}
          optionType="button"
        />
      </Form.Item>
      <Form.Item
        label="Gender identity"
        name={GeneralInformationQuestionKeys.GENDER_IDENTITY}
      >
        <Select
          className={styles.fullWidth}
          disabled={reviewMode}
          size="large"
          placeholder="Select one"
          options={genderIdentity}
          allowClear
          showSearch
          wrapOptions
        />
        {/* We need to figure out unique codes for this
              Also "Other, please specify" is still any option here */}
      </Form.Item>
      <Form.Item
        label="Pronouns"
        name={GeneralInformationQuestionKeys.PRONOUNS}
      >
        <Select
          className={styles.fullWidth}
          disabled={reviewMode}
          placeholder="Select all that apply"
          allowClear
          showSearch
          size="large"
          options={personalPronouns}
          mode="multiple"
        />
      </Form.Item>
      <Form.Item
        label="Race"
        name={GeneralInformationQuestionKeys.RACE}
        rules={[required('Please select your race')]}
      >
        <Select
          className={styles.fullWidth}
          disabled={reviewMode}
          placeholder="Select all that apply"
          options={race}
          size="large"
          allowClear
          showSearch
          mode="multiple"
          onChange={resetRaceSubCategories}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues[GeneralInformationQuestionKeys.RACE] !==
          currentValues[GeneralInformationQuestionKeys.RACE]
        }
      >
        {({ getFieldValue }) => {
          const selectedRaces: RaceTitleEnum[] =
            getFieldValue(GeneralInformationQuestionKeys.RACE) ?? []

          const subRaceOptions = selectedRaces
            .reduce<MappedCodeSetOptions[]>((joinedSubRaces, raceCodeTitle) => {
              if (subRaces[raceCodeTitle]) {
                joinedSubRaces.push(...subRaces[raceCodeTitle])
              }
              return joinedSubRaces
            }, [])
            .sort((a, b) => a.label.localeCompare(b.label))

          return subRaceOptions.length ? (
            <Form.Item
              label="Race subcategory"
              name={GeneralInformationQuestionKeys.RACE_SUBCATEGORY}
            >
              <Select
                className={styles.fullWidth}
                disabled={reviewMode}
                placeholder="Select all that apply"
                options={subRaceOptions}
                size="large"
                mode="multiple"
                allowClear
                showSearch
              />
            </Form.Item>
          ) : null
        }}
      </Form.Item>
      <Form.Item
        label={'Ethnicity'}
        name={GeneralInformationQuestionKeys.ETHNICITY}
        rules={[required('Please select your ethnicity')]}
      >
        <Select
          className={styles.fullWidth}
          disabled={reviewMode}
          size="large"
          placeholder="Select one"
          options={ethnicity}
          onChange={resetSpecificEthnicity}
          allowClear
          showSearch
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues[GeneralInformationQuestionKeys.ETHNICITY] !==
          currentValues[GeneralInformationQuestionKeys.ETHNICITY]
        }
      >
        {({ getFieldValue }) =>
          getFieldValue(GeneralInformationQuestionKeys.ETHNICITY) ===
          EthnicityTitleEnum.HISPANIC_LATINO ? (
            <Form.Item
              label="Specific ethnicity"
              name={GeneralInformationQuestionKeys.SPECIFIC_ETHNICITY}
            >
              <Select
                className={styles.fullWidth}
                disabled={reviewMode}
                placeholder="Select all that apply"
                options={specificEthnicity}
                size="large"
                mode="multiple"
                allowClear
                showSearch
              />
            </Form.Item>
          ) : null
        }
      </Form.Item>
      <Divider />
      <Header title="Emergency contact" />
      <Form.Item
        label="Emergency contact's name"
        name={GeneralInformationQuestionKeys.EMERGENCY_CONTACT_NAME}
        rules={[optionalFreeText('Please input valid name')]}
        normalize={normalizeText}
      >
        <Input disabled={reviewMode} size="large" />
      </Form.Item>
      <Form.Item
        label="Emergency contact's relationship"
        name={GeneralInformationQuestionKeys.EMERGENCY_CONTACT_RELATIONSHIP}
      >
        <Select
          className={styles.fullWidth}
          disabled={reviewMode}
          size="large"
          options={emergencyRelationshipTypes}
          allowClear
          placeholder="Select one"
          showSearch
        />
      </Form.Item>
      <PhoneNumberInput
        disabled={reviewMode}
        form={form}
        name={GeneralInformationQuestionKeys.EMERGENCY_CONTACT_PHONE}
        formItemProps={{ label: `Emergency contact's phone` }}
        inputProps={{ size: 'large' }}
      />
      <Form.Item
        label="Emergency contact's email"
        name={GeneralInformationQuestionKeys.EMERGENCY_CONTACT_EMAIL}
        rules={[email('Invalid email address')]}
      >
        <Input disabled={reviewMode} size="large" type="email" />
      </Form.Item>
      <Divider />
      <Header
        title="Healthcare provider(s)"
        description="Please list any healthcare providers it could be important to coordinate with. It is ok if you do not have their full contact information. "
      />
      <DynamicSubForm
        addButtonText="Add a healthcare provider"
        reviewMode={reviewMode}
        buttonSize="large"
        name={GeneralInformationQuestionKeys.HEALTHCARE_PROVIDERS}
        subForm={({ restField, name, index }: SubFormProps) => (
          <>
            <Title level={5}>Healthcare provider #{index + 1}</Title>
            <Form.Item
              {...restField}
              label="Provider’s name"
              name={[name, HealthcareProviderSubForm.HEALTHCARE_PROVIDER_NAME]}
              rules={[optionalFreeText('Please input valid name')]}
              normalize={normalizeText}
            >
              <Input disabled={reviewMode} size="large" placeholder="Name" />
            </Form.Item>
            <ProviderRolesMultiSelect
              {...restField}
              label="Provider’s role"
              name={[
                name,
                HealthcareProviderSubForm.HEALTHCARE_PROVIDER_SPECIALTY,
              ]}
            />
            <Address
              form={form}
              disabled={reviewMode}
              label="Provider's address"
              labelUnit="Provider's unit or suite number"
              parentName={GeneralInformationQuestionKeys.HEALTHCARE_PROVIDERS}
              name={[
                name,
                HealthcareProviderSubForm.HEALTHCARE_PROVIDER_ADDRESS,
              ]}
              size="large"
            />
            <PhoneNumberInput
              form={form}
              disabled={reviewMode}
              listName={GeneralInformationQuestionKeys.HEALTHCARE_PROVIDERS}
              name={[name, HealthcareProviderSubForm.HEALTHCARE_PROVIDER_PHONE]}
              formItemProps={{ label: 'Provider’s phone' }}
              inputProps={{ size: 'large' }}
            />
            <Form.Item
              {...restField}
              label="Provider’s email"
              name={[name, HealthcareProviderSubForm.HEALTHCARE_PROVIDER_EMAIL]}
              rules={[email('Invalid email address')]}
            >
              <Input
                disabled={reviewMode}
                placeholder="Email"
                size="large"
                type="email"
              />
            </Form.Item>
          </>
        )}
      />
      <Divider />
    </>
  )
}

export default GeneralInformation
