import { useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment/moment'

import {
  Diagnosis,
  createRCopiaDiagnosis,
  editDiagnosis,
  editRCopiaDiagnosis,
} from '../../api/api-lib-typed'

export type UpdateDiagnosisParams = {
  icdCode: string
  icdDescription: string
  diagnosisDate: string
  diagnosisStatus: 'Active' | 'Inactive' | 'Resolved'
  onsetDate: string
  comments: string
}
type useEditDiagnosisProps = {
  patientId: string
}

/**
 * This hook will update our backend, as well as update or create Rcopia diagnosis
 * @param patientId
 */
export const useUpdateDiagnosis = ({ patientId }: useEditDiagnosisProps) => {
  const queryClient = useQueryClient()

  const {
    mutateAsync: update,
    isLoading,
    isSuccess,
  } = useMutation(
    async ({
      diagnosisId,
      patientRCopiaId,
      isAlreadyInRCopia,
      updatedDiagnosis,
    }: {
      diagnosisId: string
      isAlreadyInRCopia: boolean
      patientRCopiaId?: string
      updatedDiagnosis: Diagnosis
    }) => {
      const diagnosis = await editDiagnosis(
        diagnosisId,
        patientId,
        updatedDiagnosis
      )
      if (patientRCopiaId) {
        if (isAlreadyInRCopia) {
          await editRCopiaDiagnosis({
            ...updatedDiagnosis,
            PatientRCopiaId: patientRCopiaId,
          })
        } else {
          await createRCopiaDiagnosis({
            ...updatedDiagnosis,
            PatientRCopiaId: patientRCopiaId,
            DiagnosisId: diagnosisId,
          })
        }
      }

      queryClient.invalidateQueries(['DIAGNOSIS', diagnosisId, patientId])

      return diagnosis.DiagnosisId
    }
  )

  const serializeData = (
    dx: UpdateDiagnosisParams,
    patientId: string
  ): Diagnosis => {
    return {
      Diagnosis: dx.icdDescription,
      DiagnosisCode: dx.icdCode,
      DiagnosisDate: moment(dx.diagnosisDate),
      DiagnosisNoteContent: dx.comments,
      DiagnosisOnsetDate: moment(dx.onsetDate),
      DiagnosisOnsetDateFormat: 'MM/DD/YYYY',
      DiagnosisStatus: dx.diagnosisStatus,
      PatientId: patientId,
    }
  }

  const callUpdate = ({
    diagnosisId,
    dx,
    isAlreadyInRCopia,
    patientRCopiaId,
  }: {
    diagnosisId: string
    dx: UpdateDiagnosisParams
    isAlreadyInRCopia: boolean
    patientRCopiaId?: string
  }) => {
    const serializedDx = serializeData(dx, patientId)
    return update({
      isAlreadyInRCopia,
      diagnosisId: diagnosisId,
      patientRCopiaId: patientRCopiaId,
      updatedDiagnosis: serializedDx,
    })
  }

  return {
    updateDiagnosis: callUpdate,
    isLoading,
    isSuccess,
  }
}
