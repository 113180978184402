import React from 'react'

import { Col, Form } from 'react-bootstrap'

export default function Neurocognitive(props) {
  if (props.patientData.NoNeurocognitive) {
    return (
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Neurocognitive:
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="NoNeurocognitive"
            label="No relevant history in this section"
            checked={JSON.parse(props.patientData.NoNeurocognitive)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NoNeurocognitive: !props.patientData.NoNeurocognitive,
              })
            }}
          />
        </Form.Group>
      </Form.Row>
    )
  }
  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Neurocognitive:
          </Form.Label>
        </Form.Group>
        {props.patientData.Delirium.state === false &&
        props.patientData.MayorNeurocognitiveDisorder.state === false &&
        props.patientData.MinorNeurocognitiveDisorder.state === false &&
        props.patientData.OtherNeurocognitive.state === false &&
        props.patientData.NeurocognitiveAdditionalNotes === '' ? (
          <Form.Group as={Col} sm={12} md={12} lg={12}>
            <Form.Check
              type="checkbox"
              id="NoNeurocognitive"
              label="No relevant history in this section"
              checked={JSON.parse(props.patientData.NoNeurocognitive)}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoNeurocognitive: !props.patientData.NoNeurocognitive,
                })
              }}
            />
          </Form.Group>
        ) : (
          ''
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Diagnoses
          </Form.Label>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.Delirium.state ? 2 : 12}
          lg={props.patientData.Delirium.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="Delirium"
            label="Delirium"
            disabled={props.patientData.NoNeurocognitive}
            checked={JSON.parse(props.patientData.Delirium.state)}
            onChange={() => {
              props.setPatientData({
                ...props.patientData,
                Delirium: {
                  ...props.patientData.Delirium,
                  state: !props.patientData.Delirium.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.Delirium.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    Delirium: {
                      ...props.patientData.Delirium,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.Delirium.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.MayorNeurocognitiveDisorder.state ? 2 : 12}
          lg={props.patientData.MayorNeurocognitiveDisorder.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="MayorNeurocognitiveDisorder"
            label="Major neurocognitive disorder"
            disabled={props.patientData.NoNeurocognitive}
            checked={JSON.parse(
              props.patientData.MayorNeurocognitiveDisorder.state
            )}
            onChange={() => {
              props.setPatientData({
                ...props.patientData,
                MayorNeurocognitiveDisorder: {
                  ...props.patientData.MayorNeurocognitiveDisorder,
                  state: !props.patientData.MayorNeurocognitiveDisorder.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.MayorNeurocognitiveDisorder.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    MayorNeurocognitiveDisorder: {
                      ...props.patientData.MayorNeurocognitiveDisorder,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.MayorNeurocognitiveDisorder.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.MinorNeurocognitiveDisorder.state ? 2 : 12}
          lg={props.patientData.MinorNeurocognitiveDisorder.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="MinorNeurocognitiveDisorder"
            label="Minor neurocognitive disorder"
            disabled={props.patientData.NoNeurocognitive}
            checked={JSON.parse(
              props.patientData.MinorNeurocognitiveDisorder.state
            )}
            onChange={() => {
              props.setPatientData({
                ...props.patientData,
                MinorNeurocognitiveDisorder: {
                  ...props.patientData.MinorNeurocognitiveDisorder,
                  state: !props.patientData.MinorNeurocognitiveDisorder.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.MinorNeurocognitiveDisorder.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    MinorNeurocognitiveDisorder: {
                      ...props.patientData.MinorNeurocognitiveDisorder,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.MinorNeurocognitiveDisorder.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.OtherNeurocognitive.state ? 2 : 12}
          lg={props.patientData.OtherNeurocognitive.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="OtherNeurocognitive"
            label="Other"
            disabled={props.patientData.NoNeurocognitive}
            checked={JSON.parse(props.patientData.OtherNeurocognitive.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                OtherNeurocognitive: {
                  ...props.patientData.OtherNeurocognitive,
                  state: !props.patientData.OtherNeurocognitive.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OtherNeurocognitive.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    OtherNeurocognitive: {
                      ...props.patientData.OtherNeurocognitive,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.OtherNeurocognitive.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Symptoms
          </Form.Label>
        </Form.Group>
        <Form.Group
          style={{ marginTop: '0px' }}
          as={Col}
          sm={12}
          md={12}
          lg={12}
        >
          <Form.Check
            type="checkbox"
            id="Confusion"
            label="Confusion"
            disabled={props.patientData.NoNeurocognitive}
            checked={JSON.parse(
              props.patientData.NeurocognitiveSymptoms.Confusion
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NeurocognitiveSymptoms: {
                  ...props.patientData.NeurocognitiveSymptoms,
                  Confusion:
                    !props.patientData.NeurocognitiveSymptoms.Confusion,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="MemoryLoss"
            label="Memory loss"
            disabled={props.patientData.NoNeurocognitive}
            checked={JSON.parse(
              props.patientData.NeurocognitiveSymptoms.MemoryLoss
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NeurocognitiveSymptoms: {
                  ...props.patientData.NeurocognitiveSymptoms,
                  MemoryLoss:
                    !props.patientData.NeurocognitiveSymptoms.MemoryLoss,
                },
              })
            }}
          />
          <Form.Check
            type="checkbox"
            id="OtherNeurocognitiveSymptoms"
            label="Other"
            disabled={props.patientData.NoNeurocognitive}
            checked={JSON.parse(props.patientData.NeurocognitiveSymptoms.Other)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NeurocognitiveSymptoms: {
                  ...props.patientData.NeurocognitiveSymptoms,
                  Other: !props.patientData.NeurocognitiveSymptoms.Other,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.NeurocognitiveSymptoms.Other && (
          <Form.Group
            className="indent"
            style={{ marginTop: '0px' }}
            as={Col}
            sm={12}
            md={12}
            lg={12}
          >
            <Form.Control
              as="textarea"
              rows={1}
              bsPrefix="form-input"
              onChange={(e) => {
                props.setPatientData({
                  ...props.patientData,
                  NeurocognitiveSymptoms: {
                    ...props.patientData.NeurocognitiveSymptoms,
                    OtherText: e.target.value,
                  },
                })
              }}
              value={props.patientData.NeurocognitiveSymptoms.OtherText}
            />
          </Form.Group>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional notes
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            bsPrefix="form-input"
            disabled={props.patientData.NoNeurocognitive}
            onChange={(e) => {
              props.setPatientData({
                ...props.patientData,
                NeurocognitiveAdditionalNotes: e.target.value,
              })
            }}
            value={props.patientData.NeurocognitiveAdditionalNotes}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
