// Import react
import React from 'react'

// Import libs/other
import Moment from 'moment'
// Import components
import {
  Accordion,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap'

const MedicationResponses = (props) => {
  const adverseEffectPill = props.response.HasAdverseEvent ? (
    <div className="emphasis larger">
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={
          <Tooltip bsPrefix="tooltip-x">Adverse effects reported</Tooltip>
        }
      >
        <div>
          {props.response.MedicationName}
          &nbsp;
          <i className="fa fa-exclamation-triangle danger" aria-hidden="true" />
        </div>
      </OverlayTrigger>
    </div>
  ) : (
    <></>
  )
  const lateAdministrationPill = props.response.AdministeredLate ? (
    <div className="emphasis larger">
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={<Tooltip bsPrefix="tooltip-x">Late administration</Tooltip>}
      >
        <div>
          {props.response.MedicationName}
          &nbsp;
          <i className="fa fa-clock-o info" aria-hidden="true" />
        </div>
      </OverlayTrigger>
    </div>
  ) : (
    <></>
  )
  const missedAdministrationPill =
    !props.response.DidAdminister && props.response.DidRespond ? (
      <div className="emphasis larger">
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={
            <Tooltip bsPrefix="tooltip-x">Missed administration</Tooltip>
          }
        >
          <div>
            {props.response.MedicationName}
            &nbsp;
            <i className="fa fa-times" aria-hidden="true" />
          </div>
        </OverlayTrigger>
      </div>
    ) : (
      <></>
    )
  const missedResponsePill = !props.response.DidRespond ? (
    <div className="emphasis larger">
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={<Tooltip bsPrefix="tooltip-x">No patient response</Tooltip>}
      >
        <div>
          {props.response.MedicationName}
          &nbsp;
          <i className="fa fa-question-circle subtext" aria-hidden="true" />
        </div>
      </OverlayTrigger>
    </div>
  ) : (
    <></>
  )
  const badges = (
    <span>
      {adverseEffectPill} {lateAdministrationPill} {missedAdministrationPill}{' '}
      {missedResponsePill}
    </span>
  )
  const dueDateString = Moment(
    new Date(props.response.AdministrationDueDate)
  ).format('dddd, MMMM DD, YYYY')
  return (
    <Accordion bsPrefix="center">
      <Accordion.Toggle
        as={Card.Header}
        eventKey={props.index.toString()}
        id="header"
        bsPrefix="center patient-card"
      >
        <Container>
          <Row>
            <Col md={6} lg={4}>
              {badges}
            </Col>

            <Col md={6} lg={3} className="subtext larger">
              {props.response.Dosage} {props.response.Administration}
            </Col>

            <Col md={12} lg={5} className="subtext larger">
              Due: {dueDateString}
            </Col>
          </Row>
        </Container>
      </Accordion.Toggle>
      <Accordion.Collapse className="indent" eventKey={props.index.toString()}>
        <Container className="indent">
          <Row style={{ textAlign: 'left' }} className="marginal">
            <Col md={4} className="emphasis-light">
              Medication name:&nbsp;&nbsp;
            </Col>
            <Col md={8}>{props.response.MedicationName}</Col>
          </Row>
          <Row style={{ textAlign: 'left' }} className="marginal">
            <Col md={4} className="emphasis-light">
              Dosage:
            </Col>
            <Col md={8}>{props.response.Dosage}</Col>
          </Row>
          <Row style={{ textAlign: 'left' }} className="marginal">
            <Col md={4} className="emphasis-light">
              Administration:
            </Col>
            <Col md={8}>{props.response.Administration}</Col>
          </Row>
          <Row style={{ textAlign: 'left' }} className="marginal">
            <Col md={4} className="emphasis-light">
              Administration date:
            </Col>
            <Col md={8}>
              {props.response.DidRespond
                ? props.response.DidAdminister
                  ? !props.response.AdministeredLate
                    ? `${props.response.AdministrationDate} (on time)`
                    : `${props.response.AdministrationDate} (late)`
                  : 'Not administered.'
                : 'Not reported'}
            </Col>
          </Row>
          <Row style={{ textAlign: 'left' }} className="marginal">
            <Col md={4} className="emphasis-light">
              Administration due date:
            </Col>
            <Col md={8}>{props.response.AdministrationDueDate}</Col>
          </Row>
          <Row style={{ textAlign: 'left' }} className="marginal">
            <Col md={4} className="emphasis-light">
              Adverse Effect Report:
            </Col>
            <Col md={8}>
              {props.response.HasAdverseEvent
                ? props.response.AdverseEventText
                : 'No adverse effect reported.'}
            </Col>
          </Row>
        </Container>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default MedicationResponses
