import React from 'react'

import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined'
import TagOutlined from '@ant-design/icons/lib/icons/TagOutlined'
import { Popover } from 'antd'

import { useTagsData } from '../../hooks/useTags'
import { Space, Tag, Text, Tooltip } from '../BaseComponents'
import Button from '../BaseComponents/Button'
import Option from '../BaseComponents/Option'
import Select from '../BaseComponents/Select'
import { PatientTag } from './_types'

import styles from './_shared.module.scss'

interface AddTagPopoverProps {
  onToggle: (value: boolean) => void
  isOkayDisabled: boolean
  popoverOpen: boolean
  selectedTags: string[]
  tagsAlreadyAttached: PatientTag[]
  isLoading: boolean
  isCancelDisabled: boolean
  onPopoverClose: () => void
  onPopoverSubmit: () => void
  onTagSelect: (value: unknown) => void
  onTagDeselect: (value: unknown) => void
  onPopoverOpen: () => void
}

const AddTagPopover: React.FC<AddTagPopoverProps> = ({
  onToggle,
  isOkayDisabled,
  popoverOpen,
  selectedTags = [],
  tagsAlreadyAttached,
  isLoading,
  onPopoverClose,
  onPopoverSubmit,
  onTagSelect,
  onTagDeselect,
  onPopoverOpen,
  isCancelDisabled = false,
}) => {
  const { data: tagsData = [] } = useTagsData()

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }
    const isLongTag = label.length > 40
    const tagElem = (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        className={`${styles.tag} ${styles.tagBorder}`}
        closeIcon={<CloseOutlined className={styles.customCloseButton} />}
      >
        {isLongTag ? `${label.slice(0, 40)}...` : label}
      </Tag>
    )
    return isLongTag ? (
      <Tooltip title={label} key={label}>
        {tagElem}
      </Tooltip>
    ) : (
      tagElem
    )
  }

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onToggle(true)
    }

    if (event.key === 'Escape') {
      onToggle(false)
    }
  }

  const content = (
    <div className={styles.tagPopoverContent}>
      <Select
        className={styles.selectTags}
        mode="tags"
        placeholder="Select tags"
        defaultValue={selectedTags}
        value={selectedTags}
        dropdownMatchSelectWidth={false}
        onSelect={onTagSelect}
        onDeselect={onTagDeselect}
        dropdownAlign={{ overflow: { adjustY: 0 } }}
        tagRender={tagRender}
      >
        {tagsData.map((tag) => (
          <Option
            key={`${tag.clinicId}-${tag.label}`}
            name={tag.label}
            value={tag.label}
          >
            {tag.label}
          </Option>
        ))}
      </Select>
      <Text type="secondary">
        Select an existing tag or create a new one. Tags are limited to 100
        characters.
      </Text>
      <div className={styles.tagPopoverFooter}>
        <Button key="back" onClick={onPopoverClose} disabled={isCancelDisabled}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={onPopoverSubmit}
          disabled={isOkayDisabled}
          loading={isLoading}
        >
          Save
        </Button>
      </div>
    </div>
  )

  const title = tagsAlreadyAttached.length > 0 ? 'Edit Tags' : 'Add Tag'
  return (
    <Popover
      visible={popoverOpen}
      content={content}
      title={title}
      trigger="click"
      placement="bottom"
      onVisibleChange={onPopoverOpen}
    >
      <Tag
        tabIndex={0}
        onKeyDown={handleOnKeyDown}
        className={`${styles.tagPlus} ${styles.tagPlusOverride} ${
          popoverOpen ? styles.tagPlusActive : ''
        }`}
      >
        <Space size={4}>
          <TagOutlined />
          {title}
        </Space>
      </Tag>
    </Popover>
  )
}

export default AddTagPopover
