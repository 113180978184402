export enum CodeSystemOIDs {
  // https://phinvads.cdc.gov/vads/ViewValueSet.action?id=2C70C8B3-F43B-E311-A464-0017A477041A
  PHVSRaceCategoryCDCRef = '2.16.840.1.114222.4.11.7204',
  // https://phinvads.cdc.gov/vads/ViewCodeSystem.action?id=2.16.840.1.113883.6.238#
  PHRaceAndEthnicityCDC = '2.16.840.1.113883.6.238',
  // https://build.fhir.org/valueset-all-languages.html
  FHIRHl7ValueSetLanguages = '2.16.840.1.113883.4.642.3.21',
  // http://hl7.org/fhir/us/core/ValueSet-birthsex.html
  FHIRHL7ValueSetBirthSex = '2.16.840.1.113762.1.4.1021.24',
  // https://build.fhir.org/valueset-gender-identity.html
  FHIRHL7ValueSetGenderIdentity = '2.16.840.1.113883.4.642.3.972',
  // https://loinc.org/LL5144-2/
  LOINCPersonalPronouns = '1.3.6.1.4.1.12009.10.1.4011',
  // https://terminology.hl7.org/3.1.0/CodeSystem-v3-EntityNamePartQualifierR2.html
  HL7EntityNamePartQualifier = '2.16.840.1.113883.5.1122',
  // https://www.snomed.org/
  SNOMEDCT = '2.16.840.1.113883.6.96',
  // https://phinvads.cdc.gov/vads/SearchVocab.actionhttps://phinvads.cdc.gov/vads/ViewCodeSystem.action?id=2.16.840.1.114222.4.5.274
  PHPHINVSCDC = '2.16.840.1.114222.4.5.274',
  // Coding System that is defined by Osmind
  // TODO: Get Osmind OID to add here
  OSMINDCustomCodeSystem = 'OSMIND',
  // http://terminology.hl7.org/CodeSystem/v3-EducationLevel
  HL7EducationLevel = '2.16.840.1.113883.5.1077',
  // http://hl7.org/fhir/us/core/ValueSet/us-core-provider-role
  HL7ProviderRole = '10.138.1.2',
}
