import { useMemo } from 'react'

import { FixedType } from 'rc-table/lib/interface'

import {
  convertCentsToDollars,
  convertCentsToDollarsNumber,
  convertDollarsToCents,
  formatNumberStringToUsdString,
  getBillingCodeDisplayName,
} from '../../libs/billing'
import {
  DELETE_COLOR,
  DX_POINTER_LIMIT,
  MODS_LIMIT,
  PLACEHOLDER,
} from '../../libs/constants'
import { LineItem } from '../../shared-types'
import { Button, Option, Table } from '../BaseComponents'
import {
  EditableInput,
  EditableInputNumber,
  EditableSelect,
  USDInput,
} from '../BaseComponents/EditableInputs'
import { Diagnosis } from './Diagnoses'
import { InvoiceComponentProps, MODIFIERS_REGEX } from './constants'

import styles from './Invoice.module.scss'

export type LineItemUpdate = {
  lineItem: LineItem
  newData: Partial<LineItem>
}

type LineItemsProps = {
  lineItems: LineItem[]
  diagnoses?: Diagnosis[]
  onUpdate: (data: LineItemUpdate) => void
  onDelete: (lineItem: LineItem) => void
} & InvoiceComponentProps

const orderColumn = {
  dataIndex: 'displayOrder',
  key: 'displayOrder',
  title: '#',
  fixed: 'left' as FixedType,
  width: 50,
}

const nameColumn = {
  dataIndex: 'displayName',
  key: 'displayName',
  title: 'Billing code',
  fixed: 'left' as FixedType,
  width: 200,
}

export const LineItems = ({
  isSuperbillEnabled,
  isEditing,
  lineItems,
  diagnoses,
  onUpdate,
  onDelete,
}: LineItemsProps) => {
  const chargeColumns = [
    {
      dataIndex: 'units',
      key: 'units',
      title: 'Units',
      width: 100,
      render: (val: number, lineItem: LineItem) => (
        <EditableInputNumber
          testId="units"
          placeholder={PLACEHOLDER}
          label={Math.floor(val).toString()}
          value={Math.floor(val)}
          disabled={!isEditing}
          onChange={(val: number) => {
            onUpdate({
              lineItem,
              newData: { units: Math.abs(Math.floor(val)) },
            })
          }}
        />
      ),
    },
    {
      dataIndex: 'unitChargeAmountCents',
      key: 'unitChargeAmountCents',
      title: 'Unit charge',
      width: 150,
      render: (val: number, lineItem: LineItem) => (
        <USDInput
          testId="unitCharge"
          value={convertCentsToDollarsNumber(val)}
          disabled={!isEditing}
          onChange={(value: number | null) =>
            onUpdate({
              lineItem,
              newData: {
                unitChargeAmountCents: convertDollarsToCents(
                  Math.abs(Number(value))
                ),
              },
            })
          }
        />
      ),
    },
    {
      dataIndex: 'totalAdjustmentAmountCents',
      key: 'totalAdjustmentAmountCents',
      title: 'Discount',
      width: 150,
      render: (val: number, lineItem: LineItem) => (
        <USDInput
          testId="discount"
          value={convertCentsToDollarsNumber(val)}
          max={convertCentsToDollarsNumber(
            lineItem.units * lineItem.unitChargeAmountCents
          )}
          disabled={!isEditing}
          onChange={(value: number | null) =>
            onUpdate({
              lineItem,
              newData: {
                totalAdjustmentAmountCents: convertDollarsToCents(
                  Math.abs(Number(value))
                ),
              },
            })
          }
        />
      ),
    },
    {
      dataIndex: 'totalCharge',
      key: 'totalCharge',
      title: 'Amount',
      className: 'totalCharge',
      width: 100,
      render: formatNumberStringToUsdString,
    },
    ...(isEditing
      ? [
          {
            title: 'Actions',
            key: 'deleteAction',
            dataIndex: 'deleteAction',
            align: 'right' as const,
            width: 153,
          },
        ]
      : []),
  ]

  const modsColumn = {
    dataIndex: 'modifiers',
    key: 'modifiers',
    title: 'Mods',
    width: 100,
    render: (mods: string[], lineItem: LineItem) => {
      const modString = mods?.length
        ? mods.map((mod) => (mod ? mod : '0')).join(', ')
        : undefined
      return (
        <EditableInput
          value={modString}
          placeholder="0, 0, 0, 0"
          style={{ maxWidth: 150 }}
          disabled={!isEditing}
          validation={{
            validate: (input?: string) => {
              const mods = (input || '').split(',').map((i) => i?.trim())
              if (mods.length > MODS_LIMIT) return false
              if (
                mods.length &&
                !mods.every((mod) => {
                  if (!mod) return true
                  const matches = mod.match(MODIFIERS_REGEX)
                  return !!matches
                })
              ) {
                return false
              }

              return true
            },
            errorMessage:
              'Modifiers should be 2 digit alphanumeric characters. Each billing code can have up to 4 modifiers.',
          }}
          onChange={(value: string) => {
            const newMods = value?.length
              ? value.split(',').map((mod) => {
                  const trimmed = mod.trim()
                  return trimmed.length === 0 ? '0' : trimmed
                })
              : []
            onUpdate({
              lineItem,
              newData: {
                modifiers: newMods,
              },
            })
            return (newMods || []).join(', ')
          }}
        />
      )
    },
  }
  const dxPtrColumn = {
    dataIndex: 'dxPointers',
    key: 'dxPointers',
    title: 'Dx pointers',
    width: 100,
    render: (val: string[], lineItem: LineItem) => {
      return (
        <EditableSelect<number[]>
          placeholder={PLACEHOLDER}
          shouldHighlight={!val || !val.length}
          label={val?.length ? val.map((v) => v + 1).join(', ') : undefined}
          value={val?.length ? val : undefined}
          options={(diagnoses || []).map(({ id, order, code }) => {
            return (
              <Option key={id} value={order} role="dx-pointer">
                {order + 1} ({code})
              </Option>
            )
          })}
          disabled={!isEditing}
          onChange={(pointers = [] as number[]) => {
            const newPointers =
              pointers.length > DX_POINTER_LIMIT
                ? pointers.slice(0, DX_POINTER_LIMIT)
                : pointers
            onUpdate({
              lineItem: lineItem,
              newData: {
                dxPointers: newPointers.sort(),
              },
            })
          }}
          mode="multiple"
          limit={DX_POINTER_LIMIT}
        />
      )
    },
  }

  const columns = isSuperbillEnabled
    ? [orderColumn, nameColumn, modsColumn, dxPtrColumn, ...chargeColumns]
    : [orderColumn, nameColumn, ...chargeColumns]

  const getDeleteButtonContent = (lineItem: LineItem) => {
    return (
      isEditing && (
        <Button
          testId="delete-line-item-button"
          type="link"
          style={{ color: DELETE_COLOR, marginRight: '0.5rem', padding: 0 }}
          onClick={() => onDelete(lineItem)}
        >
          Delete
        </Button>
      )
    )
  }

  const dataSource = useMemo(
    () =>
      lineItems.map((lineItem, idx) => {
        const item = {
          displayName: getBillingCodeDisplayName(lineItem.billingCode),
          units: lineItem?.units,
          unitChargeAmountCents: lineItem?.unitChargeAmountCents,
          totalAdjustmentAmountCents: lineItem?.totalAdjustmentAmountCents,
          displayOrder: idx + 1,
          key: `${getBillingCodeDisplayName(lineItem.billingCode)}_${idx}`,
          totalCharge: convertCentsToDollars(
            Number(lineItem.units) * Number(lineItem.unitChargeAmountCents) -
              Number(lineItem.totalAdjustmentAmountCents)
          ),
          deleteAction: getDeleteButtonContent(lineItem),
          billingCode: lineItem.billingCode,
        }

        if (isSuperbillEnabled) {
          return {
            ...item,
            modifiers: lineItem.modifiers,
            dxPointers: lineItem.dxPointers,
          }
        }

        return item
      }),
    [isEditing, lineItems, diagnoses, isSuperbillEnabled]
  )

  const scroll = 750 + (isSuperbillEnabled ? 200 : 0) + (isEditing ? 153 : 0)

  return (
    <Table
      addClassNames={[
        'no-padding',
        'no-table-tools-section',
        styles.tableBorder,
        'line-items',
      ]}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      scroll={{ x: scroll }}
    />
  )
}
