import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { loadPatientClinicalNotes } from '../../api/notes'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'
import { ClinicalNote } from '../../shared-types'

export const usePatientClinicalNotes = (
  patientId: string,
  enabled = true
): UseQueryResult<ClinicalNote[]> => {
  return useQuery<ClinicalNote[]>(
    [patientDataTypes.PatientClinicalNotes, patientId],
    {
      queryFn: async () => {
        const data = await loadPatientClinicalNotes(patientId)
        return data
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  )
}
