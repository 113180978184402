import React, {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Input, Select, Space, Tag, Typography } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import cx from 'classnames'
import { CustomTagProps } from 'rc-select/lib/BaseSelect'

import { TestId } from '../../shared-types'
import {
  filterGroupedValueAndLabel,
  filterValueAndLabel,
} from './helpers/searchSelectInput'

import styles from './_shared.module.scss'
import './_shared.scss'

const { Option, OptGroup } = Select

export type OptionInput = {
  label: string
  value: string | number
  grouped?: boolean
  groupItems?: Record<any, any>[]
}
export type OptionOutput = string[]

type MultiSelectProps = {
  defaultValue?: string[]
  disabled?: boolean
  fieldLabel?: string
  options: OptionInput[]
  handleChange?: (output: OptionOutput) => Promise<void> | void
  placeholder?: string
  colorMap?: Map<any, any>
  value?: OptionOutput | undefined
  grouped?: boolean
  showSearch?: boolean
  showSliverColor?: boolean
  allowClear?: boolean
  className?: string
  maxTagCount?: number | 'responsive'
  showCustomAddOption?: boolean
  dropdownAlign?: any
  size?: SizeType
} & TestId

const DEFAULT_COLOR = 'default'

const MultiSelect: React.FC<MultiSelectProps> = ({
  defaultValue,
  disabled,
  fieldLabel,
  options,
  placeholder = '',
  colorMap = new Map(),
  value,
  grouped = false,
  showSearch = false,
  showSliverColor = false,
  allowClear = false,
  handleChange,
  className,
  maxTagCount,
  showCustomAddOption = false,
  dropdownAlign,
  size,
  testId,
}) => {
  const [items, setItems] = useState<OptionInput[]>(options ?? [])
  const [stagedOption, setStagedOption] = useState<string>('')
  useEffect(() => {
    setItems(options ?? [])
  }, [options])

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props
    const disableMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }

    let tagDisplay = label?.toString()
    // if the closing character is a paren, there is sub data in parens that
    // we do not need to show in the tag itself
    // eg - 'My Google Calendar (google@gmail.com)' -> 'My Google Calendar'
    if (tagDisplay && tagDisplay[tagDisplay.length - 1] === ')') {
      const opening = tagDisplay.indexOf('(')
      tagDisplay = tagDisplay.substr(0, opening)
    }

    const mappedColor =
      colorMap.get(value.toString() || label?.toString()) || DEFAULT_COLOR

    // If we're showing the sliver color ONLY, then we want the background to be the default color
    // otherwise, use the color we found in the colorMap
    const color = showSliverColor ? DEFAULT_COLOR : mappedColor
    return (
      <Tag
        className={cx('multiselect-tag', { 'only-sliver': showSliverColor })}
        color={color}
        onMouseDown={disableMouseDown}
        closable={closable}
        onClose={onClose}
        closeIcon={<CloseOutlined />}
      >
        {showSliverColor && (
          <div className="sliver-wrapper">
            <div className="sliver" style={{ backgroundColor: mappedColor }} />
          </div>
        )}
        <span className="tag-label">{tagDisplay}</span>
      </Tag>
    )
  }

  const onAddCustomChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStagedOption(event.target.value)
    },
    [stagedOption]
  )

  const addItem = useCallback(() => {
    if (!stagedOption) return
    const isIncludedAlready = items.find((item) => item.label === stagedOption)
    if (isIncludedAlready) return
    const newOption = {
      label: stagedOption,
      value: stagedOption,
      closeable: true,
    }
    setItems([...items, newOption])
    setStagedOption('')
  }, [items, stagedOption])

  const enterItem = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key) {
      event.preventDefault()
      event.stopPropagation()
    }

    addItem()
  }

  const addCustomInput = (
    <Space align="center" id={styles.multiSelectCustomInput}>
      <Input
        id="custom-input"
        placeholder="Add custom"
        onChange={onAddCustomChange}
        onPressEnter={enterItem}
        value={stagedOption}
      />
      <Button type="link" onClick={addItem}>
        <PlusOutlined /> Add
      </Button>
    </Space>
  )

  const customDropdown = useMemo(
    () =>
      showCustomAddOption
        ? (menu: ReactElement) => (
            <>
              {menu}
              {addCustomInput}
            </>
          )
        : undefined,
    [addCustomInput, showCustomAddOption, items]
  )

  return (
    <div className={className}>
      {fieldLabel && (
        <Typography.Text className="multiselect-label">
          {fieldLabel}
        </Typography.Text>
      )}
      {/* TODO: Refactor this base component to pass Option or OptionGroup as children */}
      {!grouped ? (
        <Select
          className="custom-multiselect"
          data-testid={testId}
          defaultValue={defaultValue}
          disabled={disabled}
          dropdownRender={customDropdown}
          value={value}
          mode="multiple"
          tagRender={tagRender}
          placeholder={placeholder}
          size={size}
          style={{ width: '100%' }}
          options={items}
          onChange={handleChange}
          showSearch={showSearch}
          filterOption={filterValueAndLabel}
          allowClear={allowClear}
          maxTagCount={maxTagCount}
          dropdownAlign={dropdownAlign}
        />
      ) : (
        <Select
          className="custom-multiselect"
          data-testid={testId}
          defaultValue={defaultValue}
          disabled={disabled}
          dropdownRender={customDropdown}
          value={value}
          mode="multiple"
          tagRender={tagRender}
          placeholder={placeholder}
          size={size}
          style={{ width: '100%' }}
          onChange={handleChange}
          showSearch={showSearch}
          filterOption={filterGroupedValueAndLabel}
          allowClear={allowClear}
          maxTagCount={maxTagCount}
          dropdownAlign={dropdownAlign}
        >
          {items.map((opt) => {
            if (opt.grouped) {
              return (
                <OptGroup
                  key={`multiselect-group-${opt.label}`}
                  label={opt.label}
                >
                  {opt.groupItems?.map((item) => (
                    <Option
                      key={`multiselect-${item.value}`}
                      value={item.value}
                      data-testid={
                        testId ? `${testId}-${item.label}` : undefined
                      }
                    >
                      {item.label}
                    </Option>
                  ))}
                </OptGroup>
              )
            } else {
              return (
                <Option
                  key={opt.value}
                  value={opt.value}
                  data-testid={testId ? `${testId}-${opt.label}` : undefined}
                >
                  {opt.label}
                </Option>
              )
            }
          })}
        </Select>
      )}
    </div>
  )
}

export default MultiSelect
