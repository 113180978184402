import React from 'react'

import moment from 'moment'
import 'moment-timezone'
import { Col, Form, Row, Table } from 'react-bootstrap'
import 'react-dates/initialize'

import 'react-dates/lib/css/_datepicker.css'

interface AddCGIProps {
  previousNotes: any[]
  disabled: boolean
  fields: any
  Medical?: any
  name?: any
  handleFieldChange: (obj: any) => void
}

const cGIImprovmentValues = [
  { value: 1, name: '1 = Very much improved' },
  { value: 2, name: '2 = Much improved' },
  { value: 3, name: '3 = Minimally improved' },
  { value: 4, name: '4 = No change' },
  { value: 5, name: '5 = Minimally worse' },
  { value: 6, name: '6 = Much worse' },
  { value: 7, name: '7 = Very much worse' },
]
const cGISeverityValues = [
  { value: 1, name: '1 = Normal' },
  { value: 2, name: '2 = Borderline mentally ill' },
  { value: 3, name: '3 = Mildly ill' },
  { value: 4, name: '4 = Moderately ill' },
  { value: 5, name: '5 = Markedly ill' },
  { value: 6, name: '6 = Severely ill' },
  { value: 7, name: '7 = Among the most extremely ill patients' },
]

export default function CGI(props: AddCGIProps) {
  function previousCGITable() {
    if (props.previousNotes.length === 0) {
      return (
        <Form.Text
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {' '}
          No previous notes to display
        </Form.Text>
      )
    }
    return (
      <Table
        style={{
          textAlign: 'center',
          marginTop: '20px',
          marginBottom: '30px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        responsive
      >
        <thead>
          <tr>
            <th
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                minWidth: '140px',
              }}
            >
              Date
            </th>
            <th
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                minWidth: '140px',
              }}
            >
              CGI-S
            </th>
            <th
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                minWidth: '96px',
              }}
            >
              CGI-I
            </th>
          </tr>
        </thead>
        <tbody>
          {props.previousNotes.slice(0, 5).map((pNote, index) => {
            return pNote.CGISeverity === '' ||
              pNote.CGIImprovment === '' ? null : (
              <tr key={index} style={{ textAlign: 'center' }}>
                <td>
                  <Form.Control
                    bsPrefix="form-input"
                    style={{ alignSelf: 'center', textAlign: 'center' }}
                    value={moment(pNote.CreatedOn).format('MM/DD/YYYY')}
                    as="textarea"
                    rows={1}
                    disabled={props.disabled}
                  />
                </td>
                <td>
                  <Form.Control
                    bsPrefix="form-input"
                    style={{ alignSelf: 'center', textAlign: 'center' }}
                    value={cGISeverityValues[pNote.CGISeverity - 1].name}
                    as="textarea"
                    rows={1}
                    disabled={props.disabled}
                  />
                </td>
                <td>
                  <Form.Control
                    bsPrefix="form-input"
                    style={{ alignSelf: 'center', textAlign: 'center' }}
                    value={cGIImprovmentValues[pNote.CGIImprovment - 1].name}
                    as="textarea"
                    rows={1}
                    disabled={props.disabled}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  return (
    <>
      {previousCGITable()}
      <hr />
      <Row>
        <Form.Group
          as={Col}
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Form.Label className="font-weight-bold">CGI-Severity:</Form.Label>
          <Form.Text className="font-weight-bold">
            Considering your total clinical experience with this particular
            population, how mentally ill is the patient at this time?
          </Form.Text>
          <Form.Control
            bsPrefix="form-input"
            as="select"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                CGISeverity: e.target.value,
              })
            }
            value={props.fields.CGISeverity}
          >
            <option key={0} value="">
              Select one
            </option>
            {cGISeverityValues.map((cgis, index) => {
              return (
                <option key={index + 1} value={cgis.value}>
                  {cgis.name}
                </option>
              )
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={9}
          lg={6}
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          className="marginDuringPrint"
        >
          <Form.Label className="font-weight-bold">
            CGI-S Guidelines:
          </Form.Label>
          <hr />
          <Form.Text className="font-weight-bold">
            1 = Normal—not at all ill, symptoms of disorder not present past
            seven days
          </Form.Text>
          <Form.Text className="font-weight-bold">
            2 = Borderline mentally ill—subtle or suspected pathology
          </Form.Text>
          <Form.Text className="font-weight-bold">
            3 = Mildly ill—clearly established symptoms with minimal, if any,
            distress or difficulty in social and occupational function
          </Form.Text>
          <Form.Text className="font-weight-bold">
            4 = Moderately ill—overt symptoms causing noticeable, but modest,
            functional impairment or distress; symptom level may warrant
            medication
          </Form.Text>
          <Form.Text className="font-weight-bold">
            5 = Markedly ill—intrusive symptoms that distinctly impair
            social/occupational function or cause intrusive levels of distress
          </Form.Text>
          <Form.Text className="font-weight-bold">
            6 = Severely ill—disruptive pathology, behavior and function are
            frequently influenced by symptoms, may require assistance from
            others
          </Form.Text>
          <Form.Text className="font-weight-bold">
            7 = Among the most extremely ill patients—pathology drastically
            interferes in many life functions; may be hospitalized
          </Form.Text>
        </Form.Group>
      </Row>
      <hr />
      <Row>
        <Form.Group
          as={Col}
          sm={12}
          md={6}
          lg={6}
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Form.Label className="font-weight-bold">CGI-Improvement</Form.Label>
          <Form.Text className="font-weight-bold">
            Compared to the patient's condition at admission to the project
            [prior to medication initiation], this patient's condition is:
          </Form.Text>
          <Form.Control
            bsPrefix="form-input"
            as="select"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                CGIImprovment: e.target.value,
              })
            }
            value={props.fields.CGIImprovment}
          >
            <option key={0} value="">
              Select one
            </option>
            {cGIImprovmentValues.map((cgii, index) => {
              return (
                <option key={index + 1} value={cgii.value}>
                  {cgii.name}
                </option>
              )
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={6}
          lg={6}
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          className="marginDuringPrint"
        >
          <Form.Label className="font-weight-bold">
            CGI-I Guidelines:
          </Form.Label>
          <hr />
          <Form.Text className="font-weight-bold">
            1 = Very much improved—nearly all better; good level of functioning;
            minimal symptoms; represents a very substantial change
          </Form.Text>
          <Form.Text className="font-weight-bold">
            2 = Much improved—notably better with significant reduction of
            symptoms; increase in the level of functioning but some symptoms
            remain
          </Form.Text>
          <Form.Text className="font-weight-bold">
            3 = Minimally improved—slightly better with little or no clinically
            meaningful reduction of symptoms. Represents very little change in
            basic clinical status, level of care, or functional capacity
          </Form.Text>
          <Form.Text className="font-weight-bold">
            4 = No change—symptoms remain essentially unchanged
          </Form.Text>
          <Form.Text className="font-weight-bold">
            5 = Minimally worse—slightly worse but may not be clinically
            meaningful; may represent very little change in basic clinical
            status or functional capacity
          </Form.Text>
          <Form.Text className="font-weight-bold">
            6 = Much worse—clinically significant increase in symptoms and
            diminished functioning
          </Form.Text>
          <Form.Text className="font-weight-bold">
            7 = Very much worse—severe exacerbation of symptoms and loss of
            functioning
          </Form.Text>
        </Form.Group>
      </Row>
      <hr />
      <Form.Group
        as={Col}
        sm={12}
        md={9}
        lg={6}
        style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}
        className="hideDuringPrint"
      >
        <a
          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2880930/"
          target="_blank"
          rel="noreferrer"
        >
          Open detailed description for CGI Improvement/Severity Guidelines
        </a>
      </Form.Group>
      <hr />
    </>
  )
}
