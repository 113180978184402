// TODO: is there something better to use? should this be used more globally, therefore moved?
import { logError } from '../errorLib'

export type SimpleLoggerMethods = 'info' | 'warn' | 'error'
export type SimpleLoggerType = {
  [K in SimpleLoggerMethods]: (errorMsg: string, ...rest: unknown[]) => void
}

export const simpleLogger: SimpleLoggerType = {
  info: console.info,
  warn: console.warn,
  // @ts-expect-error errorLib.js cannot be easily fully typed; errorInfo's type is not correctly inferrable
  error: logError,
}

/**
 * Prepends all logging messages with a consistent prefix
 * @param prefix - It will be automatically wrapped in brackets, e.g. `[${prefix}]`
 */
export const simpleLoggerWithPrefixFactory = (
  prefix: string
): SimpleLoggerType => ({
  info: (str, ...rest) => simpleLogger.info(`[${prefix}] ${str}`, ...rest),
  warn: (str, ...rest) => simpleLogger.warn(`[${prefix}] ${str}`, ...rest),
  error: (str, ...rest) => simpleLogger.error(`[${prefix}] ${str}`, ...rest),
})
