const KG = 'kg'

export const calculateTotalDose = (
  doseInMg?: string | number | null,
  _weight?: string | number | null,
  weightUnits = KG
) => {
  if (doseInMg && _weight) {
    const dose = Number(doseInMg)
    const weight = Number(_weight)
    return weightUnits === KG
      ? (dose * weight).toFixed(2)
      : (dose * (weight * 0.453592)).toFixed(2)
  }

  return null
}

export const calculateRelativeDose = (
  totalDoseInMg?: string | number | null,
  _weight?: string | number | null,
  weightUnits = KG
) => {
  if (totalDoseInMg && _weight) {
    const totalDose = Number(totalDoseInMg)
    const weight = Number(_weight)
    return weightUnits === KG
      ? (totalDose / weight).toFixed(2)
      : (totalDose / (weight * 0.453592)).toFixed(2)
  }

  return null
}
