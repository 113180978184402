import { useEffect, useState } from 'react'

import { Col, Form, FormInstance, Row, Typography } from 'antd'
import get from 'lodash/get'

import { HellosignTemplate } from '../../../components/Accordions/SharePatientProgress/attachmentLib'
import { ProviderHomePageData } from '../../../shared-types'
import { Checkbox, CheckboxGroup, Divider } from '../../BaseComponents'

import styles from '../_shared.module.scss'

const { Text, Title } = Typography

interface TemplateItem {
  id: string
  name: string
  twoWay: boolean
}

export enum CustomIntakeFormItems {
  formCustomOneWayIntakes = 'formCustomOneWayIntakes',
  formCustomTwoWayIntakes = 'formCustomTwoWayIntakes',
}

interface Props {
  providerData: Pick<ProviderHomePageData, 'hellosignTemplates'>
  form: FormInstance
}

export const NoCustomIntakeFormsMessage = {
  title: 'Custom forms',
  body: 'If you want to include custom forms, please configure them in the custom forms settings tab.',
}

export const OneWayCustomIntakeFormsMessage = {
  title: 'Custom forms - Patient input required',
  body: 'The following forms will be consolidated into a single form for the patient to sign.',
}

export const TwoWayCustomIntakeFormsMessage = {
  title: 'Custom forms - Patient and clinic input required',
  body: 'The following forms will be sent as individual emails for both the patient and your clinic to sign.',
}

export default function CustomIntakeCheckboxes({ form, providerData }: Props) {
  const [templateList, setTemplateList] = useState<TemplateItem[]>([])
  const [oneWayTemplatesIds, setOneWayTemplatesIds] = useState<string[]>([])
  const [twoWayTemplatesIds, setTwoWayTemplatesIds] = useState<string[]>([])

  useEffect(() => {
    const templates: HellosignTemplate[] = get(
      providerData,
      'hellosignTemplates',
      []
    )

    if (templates) {
      setTemplateList(
        templates.map((template) => {
          return {
            id: template.TemplateId,
            name: template.TemplateName,
            twoWay: template.SignatureOption === 'twoWay',
          }
        })
      )
    }
  }, [providerData])

  useEffect(() => {
    form.setFields([
      {
        name: CustomIntakeFormItems.formCustomOneWayIntakes,
        value: oneWayTemplatesIds,
      },
    ])
  }, [oneWayTemplatesIds])

  useEffect(() => {
    form.setFields([
      {
        name: CustomIntakeFormItems.formCustomTwoWayIntakes,
        value: twoWayTemplatesIds,
      },
    ])
  }, [twoWayTemplatesIds])

  if (!templateList || templateList.length === 0) {
    return (
      <>
        <Title level={5}>{NoCustomIntakeFormsMessage.title}</Title>
        <Text type="secondary">{NoCustomIntakeFormsMessage.body}</Text>
      </>
    )
  }

  const oneWayTemplates = templateList.filter((template) => !template.twoWay)
  const twoWayTemplates = templateList.filter((template) => template.twoWay)

  function RenderFormItem({
    templates,
    formItem,
  }: {
    templates: TemplateItem[]
    formItem: CustomIntakeFormItems
  }) {
    return (
      <Form.Item name={formItem}>
        <CheckboxGroup
          onChange={(checkedValue) => {
            if (formItem === CustomIntakeFormItems.formCustomOneWayIntakes) {
              setOneWayTemplatesIds(checkedValue as string[])
            } else if (
              formItem === CustomIntakeFormItems.formCustomTwoWayIntakes
            ) {
              setTwoWayTemplatesIds(checkedValue as string[])
            }
          }}
        >
          {templates.map((template, index) => {
            return (
              <Row
                key={`${
                  formItem === CustomIntakeFormItems.formCustomOneWayIntakes
                    ? 'one'
                    : 'two'
                }-way-intake-${index}`}
                className={styles.intakFormSectionItem}
              >
                <Col>
                  <Checkbox value={template.id}>
                    <Text>{template.name}</Text>
                  </Checkbox>
                </Col>
              </Row>
            )
          })}
        </CheckboxGroup>
      </Form.Item>
    )
  }

  return (
    <>
      {oneWayTemplates.length > 0 && (
        <>
          <Title level={5}>{OneWayCustomIntakeFormsMessage.title}</Title>
          <Text type="secondary">{OneWayCustomIntakeFormsMessage.body}</Text>
          <RenderFormItem
            templates={oneWayTemplates}
            formItem={CustomIntakeFormItems.formCustomOneWayIntakes}
          />
        </>
      )}
      <Divider className={styles.intakeSectionDivider} />
      {twoWayTemplates.length > 0 && (
        <>
          <Title level={5}>{TwoWayCustomIntakeFormsMessage.title}</Title>
          <Text type="secondary">{TwoWayCustomIntakeFormsMessage.body}</Text>
          <RenderFormItem
            templates={twoWayTemplates}
            formItem={CustomIntakeFormItems.formCustomTwoWayIntakes}
          />
        </>
      )}
    </>
  )
}
