import React from 'react'

import { Divider as AntDDivider, DividerProps as AntDDividerProps } from 'antd'

import { TestId } from '../../shared-types'

type DividerProps = AntDDividerProps & TestId

const Divider: React.FC<DividerProps> = ({ children, testId, ...props }) => {
  return (
    <AntDDivider data-testid={testId} {...props}>
      {children}
    </AntDDivider>
  )
}

export default Divider
