import React from 'react'

import 'moment-timezone'
import { Button, Col, Form, Row } from 'react-bootstrap'
import 'react-dates/initialize'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function ShowSavedAMClinicalNote(props) {
  return (
    <>
      <Row>
        <Form.Group as={Col} sm={12} lg={10}>
          <span className="patient-name">{props.index + 1}&nbsp;</span>:
        </Form.Group>
        <Form.Group as={Col} sm={12} lg={2}>
          <Button
            variant="outline-danger primary button-label"
            block
            size="sm"
            disabled={props.disabled}
            onClick={() => {
              props.deleteAM(props.index)
            }}
          >
            Delete
          </Button>
        </Form.Group>
      </Row>
      <Form.Group style={{ fontWeight: 'bold' }}>
        Medication name
        <Form.Control
          type="text"
          readOnly
          value={props.AdditionalMedicationName}
        />
      </Form.Group>
      <Form.Group style={{ fontWeight: 'bold' }}>
        Administration route
        <Form.Control
          type="text"
          readOnly
          value={props.AdditionalMedicationAdministrationRoute}
        />
      </Form.Group>
      {props.AdditionalMedicationAdministrationRoute ===
        'Other (please specify)' && (
        <Form.Group>
          <Form.Label style={{ fontWeight: 'bold' }}>
            If administration route was listed as other, please specify.
            <span className="danger">*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            readOnly
            value={props.AdditionalMedicationOtherAdministrationRoute}
          />
        </Form.Group>
      )}
      <Row>
        <Form.Group as={Col} sm={12} lg={4}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Total Dosage (mg)
          </Form.Label>
          <Form.Control
            type="number"
            readOnly
            value={props.AdditionalMedicationDosage}
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} lg={4}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Patient Weight (kg)
          </Form.Label>
          <Form.Control
            type="number"
            readOnly
            value={props.AdditionalMedicationPatientWeight}
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} lg={4}>
          <Form.Label style={{ fontWeight: 'bold' }}>Dose (mg/kg)</Form.Label>
          <Form.Control
            type="text"
            value={
              props.AdditionalMedicationPatientWeight &&
              props.AdditionalMedicationDosage
                ? (
                    props.AdditionalMedicationDosage /
                    props.AdditionalMedicationPatientWeight
                  ).toFixed(2)
                : '-'
            }
            readOnly
          />
        </Form.Group>
      </Row>
    </>
  )
}
