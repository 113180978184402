import React from 'react'

import {
  CheckCircleFilled,
  PlusCircleOutlined,
  StopOutlined,
  StopTwoTone,
} from '@ant-design/icons'
import { Tooltip } from 'antd'
import { ChromePicker, ColorResult } from 'react-color'

import { TestId } from '../../shared-types'

import './Colorpicker.scss'

type ColorPickerProps = {
  colors: string[]
  onChange: (colorHex: string) => void
  selectedColor: string
} & TestId

const ColorPicker: React.FC<ColorPickerProps> = ({
  colors,
  selectedColor,
  onChange,
  testId,
}) => {
  const colorList = [...colors]
  // if we just selected a custom color not in the default list, add it
  if (selectedColor && !colorList.includes(selectedColor)) {
    colorList.push(selectedColor)
  }

  function generateColorPickerCircle(colorHex: string) {
    if (colorHex === selectedColor) {
      return (
        <CheckCircleFilled
          data-testid={`colorPicker-option-${colorHex}`}
          className="circle-with-color color-option--selected"
          style={{ color: colorHex }}
        />
      )
    } else {
      return (
        <div
          data-testid={`colorPicker-option-${colorHex}`}
          className="circle-with-color color-option"
          onClick={() => onChange(colorHex)}
          style={{ backgroundColor: colorHex }}
        />
      )
    }
  }

  // render the color list between a 'no color selected' icon and a plus icon for adding a custom color
  return (
    <div className="color-selection-space">
      {selectedColor ? (
        <StopOutlined
          className="circle-without-color color-option"
          rotate={90}
          onClick={() => onChange('')}
        />
      ) : (
        // if there is no color selected, show the 'no color' option as two-toned
        // signifying that it is the selected option
        <StopTwoTone
          className="circle-without-color color-option"
          rotate={90}
          onClick={() => onChange('')}
        />
      )}
      {colorList.map((c) => generateColorPickerCircle(c))}
      {/* custom color picker that pops up when the plus icon is pressed */}
      <Tooltip
        trigger="click"
        placement="topLeft"
        title={
          <ChromePicker
            data-testid={testId}
            color={selectedColor}
            disableAlpha
            onChange={(color: ColorResult) => onChange(color.hex)}
          />
        }
      >
        <PlusCircleOutlined className="circle-add-color color-option" />
      </Tooltip>
    </div>
  )
}

export default ColorPicker
