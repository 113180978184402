import React, { useEffect, useMemo, useState } from 'react'

import { Modal, RadioChangeEvent } from 'antd'

import {
  deletePaymentMethod,
  updatePaymentMethod,
} from '../../../api/api-lib-typed'
import { notification } from '../../../libs/notificationLib'
import { PaymentMethod } from '../../../shared-types'
import { Button, Radio } from '../../../stories/BaseComponents'
import { MenuItem } from '../../../stories/BaseComponents/Menu'
import { getPaymentMethodDisplayContent } from './Billing'

import styles from './Billing.module.scss'

type DeleteCreditCardModalProps = {
  creditCard: PaymentMethod | null
  creditCards: PaymentMethod[]
  isButton?: boolean
  isDefault?: boolean
  onDelete: () => void
  patientId: string
  style?: Record<string, any>
}
export const DeleteCreditCardModal = ({
  creditCard,
  creditCards,
  onDelete,
  patientId,
  style,
  isButton = false,
  isDefault = false,
}: DeleteCreditCardModalProps) => {
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [newDefaultCard, setNewDefaultCard] = useState<string | null>(null)
  const cardNumbers = useMemo(
    () => `${creditCard?.cardIssuer} **** ${creditCard?.lastFour}`,
    [creditCard]
  )

  const leftCreditCards = useMemo(
    () => creditCards.filter((card) => card.uuid !== creditCard?.uuid),
    [creditCard, creditCards]
  )

  useEffect(() => {
    if (isDefault && leftCreditCards.length > 0) {
      setNewDefaultCard(leftCreditCards[0].uuid)
    }
  }, [isDefault, leftCreditCards])

  async function handleDelete() {
    setConfirmLoading(true)

    if (creditCard?.uuid) {
      try {
        await deletePaymentMethod(creditCard.uuid, patientId)
        notification(`${cardNumbers} deleted successfully.`, 'success')
        onDelete()
      } catch {
        notification('There was an error deleting card.', 'error')
      } finally {
        setConfirmLoading(false)
        setVisible(false)
      }
    }
  }

  async function handleChangeDefault() {
    if (newDefaultCard) {
      const paymentMethodData = {
        isDefault: true,
        patientId,
        uuid: newDefaultCard,
      }
      try {
        setConfirmLoading(true)
        await updatePaymentMethod(paymentMethodData)
        await handleDelete()
      } catch {
        setConfirmLoading(false)
        setVisible(true)
        notification('Something went wrong. Please try again.')
      }
    }
  }

  async function handleNewDefaultOnChange(event: RadioChangeEvent) {
    setNewDefaultCard(event.target.value)
  }

  return (
    <>
      {isDefault && leftCreditCards.length > 0 ? (
        <Modal
          title={`Delete ${cardNumbers}`}
          visible={visible}
          onOk={handleChangeDefault}
          okText="Delete and update"
          okButtonProps={{ type: 'primary' }}
          confirmLoading={confirmLoading}
          onCancel={() => setVisible(false)}
        >
          <div className={styles.warningMessage}>
            <span className={styles.title}>Delete, then update default</span>
            <span>
              To prevent disruptions to services paid for with this card, please
              update your default payment method below.
            </span>
          </div>
          <div className={styles.newDefaultOptions}>
            <span>Select new default payment method below</span>
            <Radio.Group
              data-testid="delete-credit-card-radio-group"
              className={styles.flexColumn}
              onChange={handleNewDefaultOnChange}
              value={newDefaultCard}
            >
              {leftCreditCards.map((card) => (
                <Radio
                  data-testid={`delete-credit-card-radio-${card.uuid}`}
                  className={styles.spaceTopAndBottom}
                  key={`option-${card.uuid}`}
                  value={card.uuid}
                >
                  {getPaymentMethodDisplayContent(card)}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </Modal>
      ) : (
        <Modal
          title={'Delete card'}
          visible={visible}
          onOk={handleDelete}
          okText="Delete"
          okButtonProps={{ danger: true, type: 'primary' }}
          confirmLoading={confirmLoading}
          onCancel={() => setVisible(false)}
        >
          <p>
            This card will no longer be usable after deleting from this account.
          </p>
        </Modal>
      )}
      {isButton ? (
        <Button
          data-testid="delete-credit-card-button"
          style={style}
          onClick={() => setVisible(true)}
          danger
        >
          Delete card
        </Button>
      ) : (
        <MenuItem
          data-testid="delete-credit-card-item"
          style={style}
          className={styles.menuDeleteButton}
          onClick={() => setVisible(true)}
        >
          Delete
        </MenuItem>
      )}
    </>
  )
}
