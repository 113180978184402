import { Extension } from '@tiptap/core'

import { CUSTOM_BLOCK_NAME_SET, CustomBlockNames } from '../../Blocks/types'

export const BlockDeletionExtension = Extension.create({
  name: 'blockDeletion',
  addKeyboardShortcuts() {
    return {
      Backspace: ({ editor }) => {
        let cursorStart = editor.state.selection.$from.pos
        let cursorEnd = editor.state.selection.$to.pos
        // Tiptap does not use an input/textarea element for their text editor,
        // and thus we cannot test this functionality. https://github.com/testing-library/user-event/issues/232
        /* istanbul ignore next */
        const isSelectionDelete = cursorStart !== cursorEnd

        // If selection delete, delete the selection presented by the editor.
        // If single deletion, delete the character before the cursor.
        cursorStart = isSelectionDelete ? cursorStart : cursorStart - 1

        let blockCount = 0
        let isDeletingCustomBlock = false
        if (!isSelectionDelete) {
          // Determine if we are deleting across blocks.
          editor.state.doc.nodesBetween(
            // The custom block will start at one character
            // prior to the current cursor position, which is
            // two characters prior to the start of the selection.
            cursorStart - 1,
            cursorEnd,
            (node) => {
              blockCount += 1
              if (
                CUSTOM_BLOCK_NAME_SET.has(node.type.name as CustomBlockNames)
              ) {
                isDeletingCustomBlock = true
              }
              // Never recurse into the blocks in the editor
              return false
            }
          )
        }
        // If we are deleting a custom block, we need to adjust the cursor positions to
        // only delete the custom block.
        if (isDeletingCustomBlock) {
          cursorStart -= 1
          cursorEnd -= 1
          // If we are not deleting a custom block, but we are deleting across blocks,
          // we need to adjust the cursor start position to delete such that
          // after deletion our text from the current block is concatenated into
          // the previous block.
        } else if (blockCount > 1) {
          cursorStart -= 1
        }
        editor.commands.deleteRange({
          from: cursorStart,
          to: cursorEnd,
        })

        return true
      },
    }
  },
})
