import { FormSectionKey } from '../../../api/intakeForms'

export const sampleForm = {
  id: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
  providerId: 'us-west-2:afadbc4b-adf0-44cc-9964-5775b779986c',
  patientId: 'us-west-2:e2e81f05-6ce1-444b-93bd-a21d2f44ba4c',
  title: 'Osmind Intake Form',
  sections: ['general_information'],
  lastInvitedAt: '2022-08-16T18:11:29.197Z',
  startedAt: '2022-08-16T18:12:09.182Z',
  completedAt: '2022-08-16T18:12:45.724Z',
  importedAt: '2022-08-16T18:12:51.286Z',
  reviewedAt: '2022-08-16T18:12:51.286Z',
  createdAt: '2022-08-16T18:11:29.197Z',
  createdBy: 'us-west-2:afadbc4b-adf0-44cc-9964-5775b779986c',
  updatedAt: '2022-08-16T18:12:51.287Z',
  updatedBy: 'us-west-2:afadbc4b-adf0-44cc-9964-5775b779986c',
  responses: [
    {
      formId: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'LEGAL_FIRST_NAME',
      answer: 'dgamklvmvlwk',
    },
    {
      formId: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'LEGAL_LAST_NAME',
      answer: 'sfgfgrvervre',
    },
    {
      formId: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'ADDRESS',
      answer: '345 California St, San Francisco, CA 94104, USA',
    },
    {
      formId: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'ADDRESS_OBJECT',
      answer: {
        City: 'San Francisco',
        State: 'California',
        Zipcode: '94104',
        Address1: '345 California St',
      },
    },
    {
      formId: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'PHONE',
      answer: '(500) 555-0006',
    },
    {
      formId: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'PHONE_TYPE',
      answer: 'mobile',
    },
    {
      formId: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'DOB',
      answer: '2022-08-16T18:12:26.340Z',
    },
    {
      formId: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'LEGAL_SEX',
      answer: 'M',
    },
    {
      formId: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'RACE',
      answer: [
        'Asian',
        'White',
        'Decline to Answer',
        'Black or African American',
      ],
    },
    {
      formId: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'ETHNICITY',
      answer: 'Hispanic or Latino',
    },
    {
      formId: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'RACE_SUBCATEGORY',
      answer: ['African American', 'Armenian', 'Assyrian'],
    },
    {
      formId: '88a7a3fa-ca07-4ec0-89a7-95ebf300998f',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'SPECIFIC_ETHNICITY',
      answer: ['Puerto Rican', 'Spaniard', 'Mexican'],
    },
  ],
}

export const bigSampleForm = {
  id: '755abb40-cb11-4b05-814e-706b2bcce91a',
  providerId: 'us-west-2:afadbc4b-adf0-44cc-9964-5775b779986c',
  patientId: 'us-west-2:d1d8d9e2-623d-4539-b785-8a5d529835a8',
  title: 'Osmind Intake Form',
  sections: [
    'general_information',
    'psychiatric_history',
    'insurance',
    'allergies_and_medications',
    'medical_history',
    'social_history',
    'other_information',
  ],
  lastInvitedAt: '2022-08-19T22:47:38.672Z',
  startedAt: '2022-08-19T22:48:14.388Z',
  completedAt: '2022-08-19T23:07:48.179Z',
  importedAt: null,
  reviewedAt: null,
  createdAt: '2022-08-19T22:47:38.672Z',
  createdBy: 'us-west-2:afadbc4b-adf0-44cc-9964-5775b779986c',
  updatedAt: '2022-08-19T23:07:48.213Z',
  updatedBy: 'us-west-2:afadbc4b-adf0-44cc-9964-5775b779986c',
  responses: [
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'LEGAL_FIRST_NAME',
      answer:
        'LONG NAME MY NAME IS SOOOOO LOOONG OH MY GOD LOOOOOOOOOOOONG AAAAAAAAAAAAAAAAA',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'LEGAL_LAST_NAME',
      answer:
        'LONG NAME MY NAME IS SOOOOO LOOONG OH MY GOD LOOOOOOOOOOOONG AAAAAAAAAAAAAAAAA',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'ADDRESS',
      answer: '3251 20th Ave, San Francisco, CA 94132, USA',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'ADDRESS_OBJECT',
      answer: {
        City: 'San Francisco',
        State: 'California',
        Zipcode: '94132',
        Address1: '3251 20th Ave',
      },
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'PHONE',
      answer: '(500) 555-0006',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'PHONE_TYPE',
      answer: 'mobile',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'DOB',
      answer: '2022-08-02T22:49:05.923Z',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'LEGAL_SEX',
      answer: 'M',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'GENDER_IDENTITY',
      answer: 'Cis male / Cis man',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'PRONOUNS',
      answer: ['he/him/his'],
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'RACE',
      answer: [
        'American Indian or Alaska Native',
        'Asian',
        'Black or African American',
        'White',
        'Native Hawaiian or Other Pacific Islander',
      ],
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'ETHNICITY',
      answer: 'Hispanic or Latino',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'EMERGENCY_CONTACT_NAME',
      answer: 'hello i am emergency contact',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'EMERGENCY_CONTACT_RELATIONSHIP',
      answer: 'Husband',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'EMERGENCY_CONTACT_PHONE',
      answer: '(500) 555-0006',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'EMERGENCY_CONTACT_EMAIL',
      answer: 'oiowejkwncjwaelkjwoekn2oinoknfwkl@oioawfkwkldfjl.com',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'HEALTHCARE_PROVIDERS',
      answer: [
        {
          HEALTHCARE_PROVIDER_NAME: 'mr. therapy horse',
          HEALTHCARE_PROVIDER_EMAIL:
            'laksfjwkefno2in222222ok3l2j3rlknfknwafklj@lkfa22222lnflkj.com',
          HEALTHCARE_PROVIDER_PHONE: '(500) 555-0006',
          HEALTHCARE_PROVIDER_ADDRESS:
            '3251 20th Ave, San Francisco, CA 94132, USA',
          HEALTHCARE_PROVIDER_SPECIALTY: ['Equine Therapist'],
          HEALTHCARE_PROVIDER_ADDRESS_OBJECT: {
            City: 'San Francisco',
            State: 'California',
            Zipcode: '94132',
            Address1: '3251 20th Ave',
            Address2: '280',
          },
          HEALTHCARE_PROVIDER_ADDRESS_UNIT_NUMBER: '280',
        },
        {
          HEALTHCARE_PROVIDER_NAME: 'mr. no specialty',
          HEALTHCARE_PROVIDER_EMAIL:
            'laksfjwkefno2in222222ok3l2j3rlknfknwafklj@lkfa22222lnflkj.com',
          HEALTHCARE_PROVIDER_PHONE: '(500) 555-0006',
          HEALTHCARE_PROVIDER_ADDRESS:
            '3251 20th Ave, San Francisco, CA 94132, USA',
          HEALTHCARE_PROVIDER_ADDRESS_OBJECT: {
            City: 'San Francisco',
            State: 'California',
            Zipcode: '94132',
            Address1: '3251 20th Ave',
            Address2: '280',
          },
          HEALTHCARE_PROVIDER_ADDRESS_UNIT_NUMBER: '280',
        },
        {
          HEALTHCARE_PROVIDER_EMAIL:
            'nfowaoi2j03i2jokj2ljl2kfjlkajwrlfkwj@jaflkwjflakwjel.com',
          HEALTHCARE_PROVIDER_PHONE: '(500) 555-0006',
          HEALTHCARE_PROVIDER_ADDRESS:
            '325 Mason St, San Francisco, CA 94102, USA',
          HEALTHCARE_PROVIDER_SPECIALTY: ['Counselor Addictions'],
          HEALTHCARE_PROVIDER_ADDRESS_OBJECT: {
            City: 'San Francisco',
            State: 'California',
            Zipcode: '94102',
            Address1: '325 Mason St',
            Address2: '560',
          },
          HEALTHCARE_PROVIDER_ADDRESS_UNIT_NUMBER: '560',
        },
      ],
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'insurance' as FormSectionKey,
      questionKey: 'INSURANCE_NAME',
      answer: 'insurance that i possess',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'insurance' as FormSectionKey,
      questionKey: 'INSURANCE_MEMBER_ID',
      answer: '32232323232',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'insurance' as FormSectionKey,
      questionKey: 'INSURANCE_GROUP_ID',
      answer: '232322',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'insurance' as FormSectionKey,
      questionKey: 'IS_PRIMARY_INSURANCE_HOLDER',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'allergies_and_medications' as FormSectionKey,
      questionKey: 'HAS_ALLERGIES',
      answer: true,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'allergies_and_medications' as FormSectionKey,
      questionKey: 'HAS_MEDICATIONS',
      answer: true,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'EXISTING_PSYCH_DATA',
      answer: [
        'Schizotypal Personality Disorder',
        'Generalized Anxiety Disorder',
        'Obsessive-Compulsive Disorder (OCD)',
        'Panic Disorder',
        'Phobias',
        'Anxiety',
        'Attention Deficit Hyperactivity Disorder (ADHD)',
        'Autistic Spectrum Disorder (ASD)',
        'Bipolar I',
        'Personality Disorder',
        'Eating Disorder',
      ],
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'EXISTING_PSYCH_DATA_OTHER',
      answer: 'i have pica and my favorite food is old VHS tapes',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_SUICIDAL_THOUGHTS',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_PREVIOUS_SUICIDAL_IDEATION',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_PREVIOUS_SUICIDAL_ATTEMPT',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_HISTORY_OF_SELF_HARM',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_PREVIOUS_KETAMINE_TREATMENT',
      answer: true,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_PREVIOUS_THERAPY_TREATMENT',
      answer: true,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_PREVIOUS_TMS_TREATMENT',
      answer: true,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_PREVIOUS_ECT_TREATMENT',
      answer: true,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'OTHER_MENTAL_HEALTH_SERVICES',
      answer:
        'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323fafhere is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_TAKEN_PSYCH_MEDS',
      answer: true,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'medical_history' as FormSectionKey,
      questionKey: 'CONDITIONS',
      answer: [
        'Erectile Dysfunction (Impotence)',
        'Lack or Loss of Sexual Desire',
        'Complex Regional Pain Syndrome (CRPS)',
        'Chronic Fatigue Syndrome',
        'Chronic Pain',
        'Insomnia',
        'High Blood Pressure',
        'Sexual Dysfunction',
      ],
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'medical_history' as FormSectionKey,
      questionKey: 'OTHER_CONDITIONS',
      answer:
        'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'medical_history' as FormSectionKey,
      questionKey: 'HEIGHT_FEET',
      answer: '5',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'medical_history' as FormSectionKey,
      questionKey: 'HEIGHT_INCHES',
      answer: '9',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'medical_history' as FormSectionKey,
      questionKey: 'WEIGHT',
      answer: '200',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'medical_history' as FormSectionKey,
      questionKey: 'IS_PREGNANT_OR_NURSING',
      answer: 'No',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'medical_history' as FormSectionKey,
      questionKey: 'IS_PLANNING_ON_BEING_PREGNANT',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'medical_history' as FormSectionKey,
      questionKey: 'HAS_PREVIOUS_SURGERIES',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'medical_history' as FormSectionKey,
      questionKey: 'HAS_PREVIOUS_HOSPITALIZATION',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'social_history' as FormSectionKey,
      questionKey: 'IS_USING_ALCOHOL',
      answer: true,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'social_history' as FormSectionKey,
      questionKey: 'SUBSTANCE_USE_HISTORY',
      answer: [
        'Cannabis (leaf, oil, resin)',
        'MDMA',
        'Methamphetamine/amphetamine',
        'Other prescription medicines not prescibed to the patient (e.g. pain meditcations, benzodiazepines, etc.)',
        'Other (synthetics, inhalants, over the counter, etc.)',
      ],
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'social_history' as FormSectionKey,
      questionKey: 'HIGHEST_EDUCATION_LEVEL',
      answer: 'Graduate degree',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'social_history' as FormSectionKey,
      questionKey: 'EMPLOYMENT_STATUS',
      answer: [
        'Attending school/college/other work or educational programming',
        'Employed (full-time)',
        'Self-employed',
        'Sporadically employed',
      ],
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'social_history' as FormSectionKey,
      questionKey: 'CURRENT_RELATIONSHIP_STATUS',
      answer: ['Not dating', 'Married', 'Engaged', 'Long-term partnership'],
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'social_history' as FormSectionKey,
      questionKey: 'HAS_SERVED_IN_MILITARY',
      answer: true,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'other_information' as FormSectionKey,
      questionKey: 'HEAR_ABOUT_CLINIC',
      answer:
        'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323fafhere is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323fafhere is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323fafhere is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323fafhere is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323fafhere is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'other_information' as FormSectionKey,
      questionKey: 'OTHER_IMPORTANT_INFO',
      answer:
        'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'FIRST_NAME_TO_USE',
      answer:
        'LONG NAME MY NAME IS SOOOOO LOOONG OH MY GOD LOOOOOOOOOOOONG AAAAAAAAAAAAAAAAA',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'MIDDLE_NAME_TO_USE',
      answer:
        'LONG NAME MY NAME IS SOOOOO LOOONG OH MY GOD LOOOOOOOOOOOONG AAAAAAAAAAAAAAAAA',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'LAST_NAME_TO_USE',
      answer:
        'LONG NAME MY NAME IS SOOOOO LOOONG OH MY GOD LOOOOOOOOOOOONG AAAAAAAAAAAAAAAAA',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'FORMER_FIRST_NAME',
      answer:
        'LONG NAME MY NAME IS SOOOOO LOOONG OH MY GOD LOOOOOOOOOOOONG AAAAAAAAAAAAAAAAA',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'FORMER_MIDDLE_NAME',
      answer:
        'LONG NAME MY NAME IS SOOOOO LOOONG OH MY GOD LOOOOOOOOOOOONG AAAAAAAAAAAAAAAAA',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'FORMER_LAST_NAME',
      answer:
        'LONG NAME MY NAME IS SOOOOO LOOONG OH MY GOD LOOOOOOOOOOOONG AAAAAAAAAAAAAAAAA',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'RACE_SUBCATEGORY',
      answer: [
        'Afghanistani',
        'Absentee Shawnee',
        'Abenaki',
        'African',
        'Ahtna',
        'Akiachak',
        'Akiak',
        'Akutan',
        'Alabama Coushatta',
        'Alabama Creek',
        'Alabama Quassarte',
        'Alakanuk',
        'Alamo Navajo',
        'Alanvik',
        'Alaska Indian',
        'Alaska Native',
        'Alaskan Athabascan',
        'Alatna',
        'Aleknagik',
        'Aleut',
        'Aleut Corporation',
        'Aleutian',
        'Aleutian Islander',
        'Alexander',
        'Algonquian',
        'Allakaket',
        'Allen Canyon',
        'Alpine',
        'Alsea',
        'Ambler',
        'American Indian',
        'Anaktuvuk',
        'Anaktuvuk Pass',
        'Angoon',
        'Anvik',
        'Apache',
        'Arab',
        'Arapaho',
        'Arctic',
      ],
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'general_information' as FormSectionKey,
      questionKey: 'SPECIFIC_ETHNICITY',
      answer: [
        'Spaniard',
        'Mexican',
        'Central American',
        'South American',
        'Latin American',
        'Puerto Rican',
        'Cuban',
        'Dominican',
        'Andalusian',
        'Asturian',
        'Castillian',
        'Catalonian',
      ],
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'insurance' as FormSectionKey,
      questionKey: 'PRIMARY_INSURED_RELATIONSHIP',
      answer: 'Parent',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'insurance' as FormSectionKey,
      questionKey: 'PRIMARY_INSURED_FIRST_NAME',
      answer: 'mom',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'insurance' as FormSectionKey,
      questionKey: 'PRIMARY_INSURED_LAST_NAME',
      answer: 'she is mom',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'insurance' as FormSectionKey,
      questionKey: 'PRIMARY_INSURED_DATE_OF_BIRTH',
      answer: '2022-07-05T22:52:13.315Z',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'insurance' as FormSectionKey,
      questionKey: 'PRIMARY_INSURED_LEGAL_SEX',
      answer: 'F',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'insurance' as FormSectionKey,
      questionKey: 'PRIMARY_INSURED_EMAIL',
      answer:
        'fksafnlk4n3o2j3oj2lj3lkrjlkjflkajflkaj@jflajflakjflkasjflkmecw.com',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'insurance' as FormSectionKey,
      questionKey: 'PRIMARY_INSURED_PHONE',
      answer: '(500) 555-0006',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'insurance' as FormSectionKey,
      questionKey: 'PRIMARY_INSURED_HAS_SAME_ADDRESS',
      answer: true,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'allergies_and_medications' as FormSectionKey,
      questionKey: 'ALLERGIES',
      answer: 'i am allergic to everything INCLUDING YOU',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'allergies_and_medications' as FormSectionKey,
      questionKey: 'CURRENT_MEDICATIONS',
      answer: [
        {
          DOSAGE: '121',
          MED_NAME: 'drugs',
          FREQUENCY: 'five times a day',
          REASONS_FOR_USE: 'i like drugs',
          FREQUENCY_OPTIONAL_DETAIL: 'as needed for pain',
        },
        {
          DOSAGE: '3132',
          MED_NAME: 'food',
          FREQUENCY_OPTIONAL_DETAIL: 'with meals',
        },
      ],
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HOSPITALIZATIONS',
      answer: [
        {
          END_OF_HOSPITALIZATION: '2022-08-02T22:54:24.084Z',
          START_OF_HOSPITALIZATION: '2022-08-02T22:54:22.285Z',
          LENGTH_OF_HOSPITALIZATION: 28,
          REASON_FOR_HOSPITALIZATION:
            'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
        },
        {
          START_OF_HOSPITALIZATION: '2022-08-02T22:54:22.285Z',
          LENGTH_OF_HOSPITALIZATION: 28,
        },
      ],
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'NUMBER_OF_KETAMINE_TREATMENTS',
      answer: 32,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'KETAMINE_TREATMENT_DETAILS',
      answer:
        'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'THERAPY_TREATMENT_DETAILS',
      answer:
        'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'NUMBER_OF_TMS_TREATMENTS',
      answer: 23,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'TMS_TREATMENT_DETAILS',
      answer:
        'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'NUMBER_OF_ECT_TREATMENTS',
      answer: 32,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'ECT_TREATMENT_DETAILS',
      answer:
        'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'PSYCH_MEDS',
      answer: {
        'Cymbalta (duloxetine)': {
          START_OF_MED: '2022-08-08T22:58:24.268Z',
          REASONS_FOR_USE:
            'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
          HAS_SIDE_EFFECTS: true,
          MAX_DOSE_PER_DAY: 23,
        },
        'Wellbutrin (bupropion)': {
          START_OF_MED: '2022-08-16T22:58:06.888Z',
          REASONS_FOR_USE:
            'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
          MAX_DOSE_PER_DAY: 23,
          IS_STILL_PRESCRIBED: true,
          SIDE_EFFECTS_DESCRIPTION:
            'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
        },
        'Mydayis (amphetamine aspartate / amphetamine sulfate / dextroamphetamine saccharate / dextroamphetamine sulfate)':
          {
            START_OF_MED: '2022-08-01T22:57:53.770Z',
            REASONS_FOR_USE:
              'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
            HAS_SIDE_EFFECTS: true,
            MAX_DOSE_PER_DAY: 23,
            SIDE_EFFECTS_DESCRIPTION:
              'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
          },
        'Adderall (amphetamine aspartate / amphetamine sulfate / dextroamphetamine saccharate / dextroamphetamine sulfate)':
          {
            START_OF_MED: '2022-08-01T22:57:30.702Z',
            END_OF_MED: '2022-08-01T22:57:53.770Z',
            REASONS_FOR_USE:
              'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf 42s',
            HAS_SIDE_EFFECTS: true,
            MAX_DOSE_PER_DAY: 23,
            IS_STILL_PRESCRIBED: true,
            SIDE_EFFECTS_DESCRIPTION:
              'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
          },
      },
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'social_history' as FormSectionKey,
      questionKey: 'HAS_COMBAT_EXPERIENCE',
      answer: true,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'other_information' as FormSectionKey,
      questionKey: 'REFERRING_CLINICIAN_NAME',
      answer:
        'here is a suuuuper long explanation! wow so long. so looooong aanaefow3noi2h39ihjkwanfkajwflja aanaefow3noi2h39ihjkwanfkajwflja 2fwefewaf waf32fwefasd as ff242. 2fwefewaf waf32fwefasd as f32323faf',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'other_information' as FormSectionKey,
      questionKey: 'REFERRING_CLINICIAN_PHONE',
      answer: '(500) 555-0006',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'other_information' as FormSectionKey,
      questionKey: 'REFERRING_CLINICIAN_EMAIL',
      answer: 'afjlj32jl2kklnwafkjaslkfjslkafn@lfkajflkanlkjaldkm.com',
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'other_information' as FormSectionKey,
      questionKey: 'REFERRING_CLINICIAN_SPECIALTY',
      answer: ['Equine Therapist', 'Cardiothoracic Surgeon'],
    },
  ],
}

/**
 * this is a sample form responses that have all the conditional fields,
 * the data for answers is present, but turned off because the original question
 * holds the `false`, indicating that they haven't. The tests toggle this value as
 * needed
 */
export const conditionalQuestionForm = {
  id: '755abb40-cb11-4b05-814e-706b2bcce91a',
  providerId: 'us-west-2:afadbc4b-adf0-44cc-9964-5775b779986c',
  patientId: 'us-west-2:d1d8d9e2-623d-4539-b785-8a5d529835a8',
  title: 'Osmind Intake Form',
  responses: [
    // allergies
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'allergies_and_medications' as FormSectionKey,
      questionKey: 'HAS_ALLERGIES',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'allergies_and_medications' as FormSectionKey,
      questionKey: 'ALLERGIES',
      answer: 'not sure of format here',
    },
    //meds
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'allergies_and_medications' as FormSectionKey,
      questionKey: 'HAS_MEDICATIONS',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'allergies_and_medications' as FormSectionKey,
      questionKey: 'CURRENT_MEDICATIONS',
      answer: [
        {
          DOSAGE: '1',
          MED_NAME: 'Medication Name 1',
          FREQUENCY: 'every 72 hours',
          REASONS_FOR_USE: 'testing',
          FREQUENCY_OPTIONAL_DETAIL: 'as directed',
        },
        {
          DOSAGE: '123',
          MED_NAME: 'Testing',
          FREQUENCY: 'three times a day',
          REASONS_FOR_USE: 'testing again',
          FREQUENCY_OPTIONAL_DETAIL: 'as directed',
        },
      ],
    },
    // ketamine treatment
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_PREVIOUS_KETAMINE_TREATMENT',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'NUMBER_OF_KETAMINE_TREATMENTS',
      answer: 32,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'KETAMINE_TREATMENT_DETAILS',
      answer: 'test',
    },
    //therapy
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_PREVIOUS_THERAPY_TREATMENT',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'THERAPY_TREATMENT_DETAILS',
      answer: 'test',
    },
    // tms treatment
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_PREVIOUS_TMS_TREATMENT',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'NUMBER_OF_TMS_TREATMENTS',
      answer: 23,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'TMS_TREATMENT_DETAILS',
      answer: 'test',
    },
    // ect treatment
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_PREVIOUS_ECT_TREATMENT',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'NUMBER_OF_ECT_TREATMENTS',
      answer: 32,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'ECT_TREATMENT_DETAILS',
      answer: 'test',
    },
    //psych meds
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'HAS_TAKEN_PSYCH_MEDS',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'psychiatric_history' as FormSectionKey,
      questionKey: 'PSYCH_MEDS',
      answer: {
        'Cymbalta (duloxetine)': {
          START_OF_MED: '2022-08-08T22:58:24.268Z',
          REASONS_FOR_USE: 'asdf',
          HAS_SIDE_EFFECTS: true,
          MAX_DOSE_PER_DAY: 23,
        },
        'Wellbutrin (bupropion)': {
          START_OF_MED: '2022-08-16T22:58:06.888Z',
          REASONS_FOR_USE: 'asdf',
          MAX_DOSE_PER_DAY: 23,
          IS_STILL_PRESCRIBED: true,
          SIDE_EFFECTS_DESCRIPTION: 'asdf',
        },
        'Mydayis (amphetamine aspartate / amphetamine sulfate / dextroamphetamine saccharate / dextroamphetamine sulfate)':
          {
            START_OF_MED: '2022-08-01T22:57:53.770Z',
            REASONS_FOR_USE: 'test',
            HAS_SIDE_EFFECTS: true,
            MAX_DOSE_PER_DAY: 23,
            SIDE_EFFECTS_DESCRIPTION: 'test',
          },
        'Adderall (amphetamine aspartate / amphetamine sulfate / dextroamphetamine saccharate / dextroamphetamine sulfate)':
          {
            START_OF_MED: '2022-08-01T22:57:30.702Z',
            END_OF_MED: '2022-08-01T22:57:53.770Z',
            REASONS_FOR_USE: 'test',
            HAS_SIDE_EFFECTS: true,
            MAX_DOSE_PER_DAY: 23,
            IS_STILL_PRESCRIBED: true,
            SIDE_EFFECTS_DESCRIPTION: 'test',
          },
      },
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'social_history' as FormSectionKey,
      questionKey: 'HAS_SERVED_IN_MILITARY',
      answer: false,
    },
    {
      formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
      formSection: 'social_history' as FormSectionKey,
      questionKey: 'HAS_COMBAT_EXPERIENCE',
      answer: true,
    },
  ],
}

export const hostpitalizationResponse = {
  formId: '755abb40-cb11-4b05-814e-706b2bcce91a',
  formSection: 'psychiatric_history' as FormSectionKey,
  questionKey: 'HOSPITALIZATIONS',
  answer: [
    {
      END_OF_HOSPITALIZATION: '2022-08-02T22:54:24.084Z',
      START_OF_HOSPITALIZATION: '2022-08-02T22:54:22.285Z',
      LENGTH_OF_HOSPITALIZATION: 28,
      REASON_FOR_HOSPITALIZATION: 'test',
    },
    {
      START_OF_HOSPITALIZATION: '2022-08-02T22:54:22.285Z',
      LENGTH_OF_HOSPITALIZATION: 28,
    },
  ],
}
