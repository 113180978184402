// Import react
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
// Import styles
import 'pretty-checkbox'
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
} from 'react-bootstrap'

// Import libs/other
import { emailMessage } from '../../../api/api-lib'
import { getEmailTemplate } from '../../../api/api-lib-typed'
import {
  PROVIDER_EMAIL_TEMPLATE_QUERY_KEY,
  getDefaultEmailBody,
} from '../../../containers/EmailTemplate/EmailTemplate'
// Import assets
import { RenderChart } from '../../../libs/chartsLib'
import { onError } from '../../../libs/errorLib'
import { useFormFields } from '../../../libs/hooksLib'
import { notification } from '../../../libs/notificationLib'
import {
  ClinicalNote,
  PatientSettingsData,
  ProviderAccountInfo,
  ProviderHomePageData,
} from '../../../shared-types'
// Import components
import LoaderButton from '../../Buttons/LoaderButton'
import PDFModal from '../../Other/PDFModal'
import ScoreCard from '../../Other/ScoreCard'
import {
  Charts,
  Graph,
  Patient,
  Score,
  generateAttachment,
} from './attachmentLib'

const REGEX_EMAIL =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

interface SharePatientProgressProps {
  homepageData: ProviderHomePageData
  patient: Patient
  provider: ProviderAccountInfo
  patientSettingsData: PatientSettingsData
  patientClinicalNotes?: ClinicalNote[]
}

interface SurveyInfo {
  id: string
  label: string
  [key: string]: unknown
}

function SharePatientProgress({
  homepageData,
  patient,
  provider,
  patientSettingsData,
  patientClinicalNotes,
}: SharePatientProgressProps) {
  const [show, setShow] = useState(false)
  const [previewFile, setPreviewFile] = useState<Blob | null>(null)
  const [loading, setLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const [sendCopy, setSendCopy] = useState(false)
  const [progressPercent, setProgressPercent] = useState(28)
  const [validated, setValidated] = useState(false)
  const { surveyData: surveys } = homepageData
  const { data: emailTemplate } = useQuery(
    [PROVIDER_EMAIL_TEMPLATE_QUERY_KEY],
    {
      queryFn: () => getEmailTemplate(),
      retry: false,
    }
  )

  const patientSurveyInfo = useMemo<{
    chartInfo: SurveyInfo[]
    singleSurveyInfo: SurveyInfo[]
  }>(() => {
    const chartInfo: SurveyInfo[] = []
    const singleSurveyInfo: SurveyInfo[] = []

    if (surveys) {
      surveys.forEach((survey) => {
        const { IsRecurring, Name, ScoreTypeEnum } = survey

        if (!Name || !ScoreTypeEnum) return

        const info = {
          id: ScoreTypeEnum,
          label: Name,
          ...survey,
        }

        if (IsRecurring) {
          chartInfo.push(info)
          return
        }

        singleSurveyInfo.push(info)
      })
    }

    return {
      chartInfo,
      singleSurveyInfo,
    }
  }, [surveys])

  const {
    DateOfBirth = null,
    PatientName = '',
    PatientProviderEmail = '',
  } = patient

  const [fields, handleFieldChange] = useFormFields({
    sender: '',
    recipient: '',
    recipientConfirm: '',
    subject: '',
    body: getDefaultEmailBody(emailTemplate),
    sendCopy: false,
    sendCopyRecipient: '',
    check: false,
  })

  // updates values for charts and email template
  useEffect(() => {
    handleFieldChange({
      target: { id: 'body', value: getDefaultEmailBody(emailTemplate) },
    })
  }, [emailTemplate])

  function validateForm(): boolean {
    return (
      fields.subject.length > 0 &&
      fields.body.length > 0 &&
      fields.recipient.length > 0 &&
      fields.recipientConfirm.length > 0 &&
      fields.recipient === fields.recipientConfirm &&
      REGEX_EMAIL.test(fields.recipient) &&
      (!sendCopy ||
        (sendCopy &&
          fields.sendCopyRecipient &&
          fields.sendCopyRecipient.length > 0 &&
          REGEX_EMAIL.test(fields.sendCopyRecipient)))
    )
  }

  const gatherCharts = useCallback<() => Charts | null>(() => {
    const graphs: Graph[] = []
    const scores: Score[] = []

    patientSurveyInfo.chartInfo.forEach((info) => {
      const { id, label } = info
      const element = document.getElementById(id)

      if (element) {
        const svgElement = element.getElementsByTagName('svg')
        if (svgElement) {
          const svg = new XMLSerializer().serializeToString(svgElement[0])
          graphs.push({ label, svg })
        }
      }
    })

    patientSurveyInfo.singleSurveyInfo.forEach((info) => {
      const { id, label } = info
      const element = document.getElementById(`${id}-score`)
      if (element) {
        const score = element.innerHTML
        scores.push({ label, score })
      }
    })

    return { graphs, scores }
  }, [patientSurveyInfo])

  // load preview and pdf file once charts are gathered
  async function loadFile(): Promise<Blob | null> {
    setLoading(true)
    const charts = gatherCharts()
    if (!charts) {
      return null
    }

    if (previewFile) {
      return previewFile
    }

    let response = null
    try {
      response = await generateAttachment(
        homepageData,
        DateOfBirth,
        PatientName,
        PatientProviderEmail,
        provider,
        charts
      )
    } catch (e) {
      onError(
        e,
        500,
        'Failed to send email. If this continues to fail, please notify suport@osmind.org'
      )
      console.error(e)
    }
    setLoading(false)
    return response
  }

  async function submit(base64: ArrayBuffer | string): Promise<void> {
    try {
      const name = PatientName.replace(' ', '_')

      const attachment = {
        fileName: `${name}_Progress.pdf`,
        contentType: 'application/pdf',
        content: base64,
      }

      const data = {
        recipient: fields.recipient,
        subject: fields.subject,
        body: fields.body,
        sendCopy: sendCopy ? fields.sendCopyRecipient : null,
        attachment: attachment,
      }

      await emailMessage(data)

      notification(
        'Successfully sent email! Please allow a few minutes for the recipient to receive it.',
        'success'
      )
    } catch (e) {
      onError(
        e,
        500,
        'Failed to send email. If this continues to fail, please notify suport@osmind.org'
      )
      console.error(e)
    }
  }

  function checkValidFields(event: React.FormEvent<HTMLFormElement>): void {
    const form = event.currentTarget
    if (!form.checkValidity()) {
      event.preventDefault()
      event.stopPropagation()
    }

    setValidated(true)
  }

  async function showPreview(): Promise<void> {
    const preview = await loadFile()
    setPreviewFile(preview)
    setShow(true)
  }

  async function readySubmit(
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault()
    checkValidFields(event)
    setShow(false)
    setProgressPercent(33)
    setLoadingButton(true)
    try {
      const response = await loadFile()
      if (!response) return
      const reader = new FileReader()
      let base64 = null
      reader.readAsDataURL(response)
      reader.onloadend = async function () {
        const result = reader.result

        if (!result) return

        base64 = result.slice(28)

        if (base64) {
          await submit(base64)
        }
      }
    } catch (e) {
      onError(
        e,
        500,
        'Failed to send email. If this continues to fail, please notify suport@osmind.org'
      )
      console.error(e)
    }
    setLoadingButton(false)
  }

  const patientScoresRendering = useMemo(() => {
    if (!patientSurveyInfo.singleSurveyInfo.length) {
      return null
    }

    return patientSurveyInfo.singleSurveyInfo.map((survey) => {
      const submissions = patientSettingsData.surveySubmissions[survey.id]

      return submissions?.length > 0 ? (
        <ScoreCard
          key={survey.id}
          type={survey.id}
          survey={survey}
          submissions={submissions}
        />
      ) : null
    })
  }, [patientSettingsData, patientSurveyInfo])

  const patientGraphsRendering = useMemo(() => {
    if (!patientSurveyInfo.singleSurveyInfo.length) {
      return null
    }

    return patientSurveyInfo.chartInfo.map((survey) => {
      const submissions = patientSettingsData.surveySubmissions[survey.id]

      return submissions ? (
        <RenderChart
          key={survey.id}
          type={survey.id}
          clinicalNotes={patientClinicalNotes}
          survey={survey}
          submissions={submissions}
          showChartControls={false}
        />
      ) : null
    })
  }, [patientSettingsData, patientSurveyInfo])

  const form = (
    <Container>
      <br />
      <Row>
        <Col>
          <p>
            Send an encrypted, HIPAA compliant email containing all of your
            patient&apos;s completed graphs and questionnaires to the
            patient&apos;s provider. Osmind will send the message with the
            patient&apos;s graphs attached as a PDF.
          </p>
          <p>
            Sent from <span className="emphasis-light">support@osmind.org</span>
          </p>
          <Form
            onChange={checkValidFields}
            onSubmit={readySubmit}
            validated={validated}
          >
            <Form.Row>
              <Form.Group as={Col} sm={12} md={6} lg={4} controlId="recipient">
                <Form.Label>
                  <span className="danger">*</span>&nbsp;To
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  placeholder="example@sample.org"
                  required
                  onChange={handleFieldChange}
                  value={fields.recipient}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                sm={12}
                md={6}
                lg={4}
                controlId="recipientConfirm"
              >
                <Form.Label>
                  <span className="danger">*</span>&nbsp;Confirm email
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  placeholder="example@sample.org"
                  required
                  onChange={handleFieldChange}
                  value={fields.recipientConfirm}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ color: '#3e7ef7' }}
                >
                  Ensure both emails are valid and matching
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md={6} lg={4} controlId="sendCopyRecipient">
                <Form.Label>
                  <Button
                    bsPrefix="plain-button"
                    onClick={() => setSendCopy(!sendCopy)}
                  >
                    {sendCopy ? (
                      <i className="danger fa fa-check" aria-hidden="true" />
                    ) : (
                      <i className="fa fa-square-o" aria-hidden="true" />
                    )}
                    &nbsp;Send an extra blind copy
                  </Button>
                </Form.Label>
                {sendCopy && (
                  <Form.Control
                    bsPrefix="form-input"
                    onChange={handleFieldChange}
                    placeholder="bcc@sample.org"
                    required={!sendCopy}
                    value={fields.sendCopyRecipient}
                  />
                )}
              </Form.Group>

              <Form.Group as={Col} md={6} lg={12} controlId="subject">
                <Form.Label>
                  <span className="danger">*</span>&nbsp;Subject
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  required
                  onChange={handleFieldChange}
                  value={fields.subject}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ color: '#3e7ef7' }}
                >
                  Make sure to fill out the subject line.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="body">
              <Form.Label>
                <span className="danger">*</span>
                &nbsp;Body&nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  style={{ fontSize: '14px' }}
                  href="/settings/email-template"
                  target="_blank"
                >
                  Edit this template in settings
                </a>
              </Form.Label>
              <Form.Control
                data-testid={'email-body'}
                as="textarea"
                bsPrefix="form-input"
                required
                rows={10}
                onChange={handleFieldChange}
                value={fields.body}
              />
            </Form.Group>
            <Form.Row className="justify-content-center">
              <Form.Group as={Col} md={12} lg={8}>
                <Button
                  className="view-attachment-button"
                  onClick={showPreview}
                >
                  <i className="fa fa-paperclip" aria-hidden="true" />
                  &nbsp; Click here to view attachment&nbsp;
                </Button>
                {previewFile && (
                  <PDFModal
                    fileContainer={previewFile}
                    show={show}
                    handleShow={setShow}
                  />
                )}
              </Form.Group>

              <Form.Group as={Col} md={12} lg={4} controlId="send">
                <LoaderButton
                  className="button"
                  isLoading={loadingButton}
                  isDisabled={!validateForm()}
                  type="submit"
                  textInside="Sending"
                >
                  Send
                </LoaderButton>
              </Form.Group>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          {(loading || loadingButton) && (
            <>
              Please wait (this may take a minute)...
              <ProgressBar animated now={progressPercent} />
            </>
          )}
        </Col>
      </Row>
      {/* We don't want these to show, they are only mounted so we can query the child elements and pass them as blobs to the output PDF */}
      <div style={{ visibility: 'hidden', height: 0 }}>
        {patientScoresRendering}
        {patientGraphsRendering}
      </div>
    </Container>
  )

  return (
    <Accordion>
      <Accordion.Toggle
        id="sendPatientProgress"
        bsPrefix="accordion-header very-large accordion-toggle"
        as={Card.Header}
        variant="link"
        eventKey="0"
      >
        <Col>
          <i
            style={{ fontSize: '26px' }}
            className="fa fa-share"
            aria-hidden="true"
          />
          &nbsp; Share Patient Progress
        </Col>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">{form}</Accordion.Collapse>
    </Accordion>
  )
}

export default SharePatientProgress
