import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ArrowRightOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { useHistory, useParams } from 'react-router-dom'

import {
  FormSectionKey,
  SectionNavigationMethod,
  getInsuranceCardUploadUrl,
  submitIntakeForm,
  trackIntakeFormNavigation,
  updateInsuranceCardKey,
} from '../../api/intakeForms'
import { useIntakeForm } from '../../hooks/usePatientIntakeForm'
import { notification } from '../../libs/notificationLib'
import { formatDate } from '../../libs/utils'
import { InfoPage, Progress, Skeleton } from '../BaseComponents'
import { StandardSkeletonRows } from '../BaseComponents/Skeleton'
import Steps from '../BaseComponents/Steps'
import { validateIntakeFormForSubmission } from './parse-intake-form'
import AllergiesAndMedication from './sections/AllergiesAndMedication'
import GeneralInformation from './sections/GeneralInformation'
import Insurance from './sections/Insurance'
import Introduction from './sections/Introduction'
import MedicalHistory from './sections/MedicalHistory'
import OtherInformation from './sections/OtherInformation'
import PsychiatricHistory from './sections/PsychiatricHistory'
import SocialHistory from './sections/SocialHistory'
import {
  AllergiesMedicationsQuestionKeys,
  GeneralInformationQuestionKeys,
  InsuranceQuestionKeys,
  MedHistoryNonPsychQuestionKeys,
  OtherInformationQuestionKeys,
  PsychiatricHistoryQuestionKeys,
  SocialHistoryQuestionKeys,
} from './sections/question-keys'

import styles from './sections/_shared.module.scss'

const FormStepFields = {
  [FormSectionKey.GENERAL_INFORMATION]: GeneralInformationQuestionKeys,
  [FormSectionKey.INSURANCE]: InsuranceQuestionKeys,
  [FormSectionKey.ALLERGIES_MEDICATIONS]: AllergiesMedicationsQuestionKeys,
  [FormSectionKey.PSYCHIATRIC_HISTORY]: PsychiatricHistoryQuestionKeys,
  [FormSectionKey.SOCIAL_HISTORY]: SocialHistoryQuestionKeys,
  [FormSectionKey.MEDICAL_HISTORY]: MedHistoryNonPsychQuestionKeys,
  [FormSectionKey.OTHER_INFORMATION]: OtherInformationQuestionKeys,
}

interface PatientIntakeProps {
  storybook?: boolean
  disableValidation?: boolean
}

const PatientIntake = ({
  storybook = false,
  disableValidation = false,
}: PatientIntakeProps) => {
  const { formId } = useParams<{ formId: string }>()
  const history = useHistory()
  const [form] = Form.useForm()
  const { error, loading, data: incompleteFormData } = useIntakeForm(formId)
  const [currentSection, setCurrentSection] = useState<FormSectionKey>(
    FormSectionKey.INTRODUCTION
  )
  const [isLoading, setIsLoading] = useState(false)
  const [completionPercent, setCompletionPercent] = useState(0)
  const intakeFormRef = useRef<null | HTMLDivElement>(null)

  const formSteps = useMemo(() => {
    const AvailableSections = [
      {
        sectionKey: FormSectionKey.INTRODUCTION,
        title: 'Introduction',
        content: <Introduction />,
        step: 1,
      },
      {
        sectionKey: FormSectionKey.GENERAL_INFORMATION,
        title: 'General Information',
        content: <GeneralInformation form={form} />,
        step: 2,
      },
      {
        sectionKey: FormSectionKey.INSURANCE,
        title: 'Insurance',
        content: <Insurance form={form} />,
        step: 3,
      },
      {
        sectionKey: FormSectionKey.ALLERGIES_MEDICATIONS,
        title: 'Allergies & Medications',
        content: <AllergiesAndMedication form={form} />,
        step: 4,
      },
      {
        sectionKey: FormSectionKey.PSYCHIATRIC_HISTORY,
        title: 'Psychiatric History',
        content: <PsychiatricHistory form={form} />,
        step: 5,
      },
      {
        sectionKey: FormSectionKey.MEDICAL_HISTORY,
        title: 'Medical History (Non-Psychiatric)',
        content: <MedicalHistory form={form} />,
        step: 6,
      },
      {
        sectionKey: FormSectionKey.SOCIAL_HISTORY,
        title: 'Social History',
        content: <SocialHistory form={form} />,
        step: 7,
      },
      {
        sectionKey: FormSectionKey.OTHER_INFORMATION,
        title: 'Other Information',
        content: <OtherInformation form={form} />,
        step: 8,
      },
    ]

    if (storybook) {
      return AvailableSections
    }

    if (!incompleteFormData) {
      return []
    }

    /* Keep ordering above to let frontend decide ordering of form sections, don't use the "sections" from db for ordering */
    return AvailableSections.filter(
      (availableSections) =>
        availableSections.sectionKey === FormSectionKey.INTRODUCTION ||
        incompleteFormData.sections.includes(availableSections.sectionKey)
    ).map((availableSections, index) => {
      return {
        ...availableSections,
        step: index + 1,
      }
    })
  }, [incompleteFormData, form, storybook])

  const proceedToNextStep = useCallback(
    async (nextSection: FormSectionKey) => {
      if (!incompleteFormData) {
        return
      }

      try {
        await trackIntakeFormNavigation(
          incompleteFormData.id,
          nextSection,
          SectionNavigationMethod.NEXT
        )
        if (intakeFormRef?.current) {
          intakeFormRef.current.scrollTo(0, 0)
        }
      } catch (err) {
        console.error(err)
      } finally {
        /** Still allow moving to next step if trackIntakeFormNavigation erred */
        setCurrentSection(nextSection)
      }
    },
    [intakeFormRef, incompleteFormData]
  )

  useEffect(() => {
    // Tracks progress of the form when form steps & current section change
    if (!formSteps.length) {
      return
    }
    const currentIndex = formSteps.findIndex(
      ({ sectionKey }) => sectionKey === currentSection
    )
    const currentPercent = (currentIndex / formSteps.length) * 100
    setCompletionPercent(currentPercent)
  }, [currentSection, formSteps])

  const validateCurrentStep = useCallback(async () => {
    try {
      setIsLoading(true)

      // Start from General Info if on intro screen
      if (currentSection === FormSectionKey.INTRODUCTION) {
        const generalSection = formSteps[1].sectionKey
        await proceedToNextStep(generalSection)
        return true
      }

      // Get current section & index of formStep
      const currentSectionFields = Object.values(FormStepFields[currentSection])
      const currentSectionIndex = formSteps.findIndex(
        ({ sectionKey }) => sectionKey === currentSection
      )

      // Bypass validation (dev mode only)
      if (disableValidation || storybook) {
        await proceedToNextStep(formSteps[currentSectionIndex + 1].sectionKey)
        return true
      }

      // Don't go to next step if form is untouched
      const fieldsTouched = form.isFieldsTouched()
      if (!fieldsTouched) {
        notification(
          'You must start on this section before proceeding to the next step.',
          'error'
        )
        return false
      }

      const updateCurrentSection = (parentName: string) => {
        let subForms = form.getFieldValue([parentName]) ?? []
        if (subForms.includes(undefined)) {
          subForms = subForms.filter((item: any) => typeof item !== 'undefined')
          form.setFields([{ name: parentName, value: subForms }])
        }
        subForms.forEach((subForm: any, index: number) => {
          if (!subForm || typeof subForm !== 'object') return
          Object.keys(subForm).forEach((key) => {
            currentSectionFields.push([parentName, index, key])
          })
        })
      }

      if (currentSection === FormSectionKey.GENERAL_INFORMATION) {
        updateCurrentSection(
          GeneralInformationQuestionKeys.HEALTHCARE_PROVIDERS
        )
      } else if (currentSection === FormSectionKey.ALLERGIES_MEDICATIONS) {
        updateCurrentSection(
          AllergiesMedicationsQuestionKeys.CURRENT_MEDICATIONS
        )
      } else if (currentSection === FormSectionKey.PSYCHIATRIC_HISTORY) {
        updateCurrentSection(PsychiatricHistoryQuestionKeys.HOSPITALIZATIONS)
      }

      // Validate only the fields in the current section
      await form.validateFields(currentSectionFields)
      await proceedToNextStep(formSteps[currentSectionIndex + 1].sectionKey)
      return true
    } catch (err) {
      notification(
        'Please address the errors on this step before proceeding to the next.',
        'error'
      )
      if (err.errorFields && err.errorFields[0]) {
        form.scrollToField(err.errorFields[0].name[0], { behavior: 'smooth' })
      }
      return false
    } finally {
      setIsLoading(false)
    }
  }, [form, currentSection, formSteps])

  const onBackClick = useCallback(async () => {
    if (!incompleteFormData) {
      return
    }

    let previousSection = currentSection
    try {
      const currentSectionIndex = formSteps.findIndex(
        ({ sectionKey }) => sectionKey === currentSection
      )
      if (currentSectionIndex > -1) {
        // Set current section to previous section
        previousSection = formSteps[currentSectionIndex - 1].sectionKey
        await trackIntakeFormNavigation(
          incompleteFormData.id,
          previousSection,
          SectionNavigationMethod.BACK
        )
      }
    } catch (err) {
      console.error(err)
    } finally {
      /** Still allow moving to next step if trackIntakeFormNavigation erred */
      setCurrentSection(previousSection)
    }
  }, [currentSection, formSteps, incompleteFormData])

  const nextStepButtonContent = useMemo(() => {
    const currentSectionIndex = formSteps.findIndex(
      ({ sectionKey }) => sectionKey === currentSection
    )
    let label = 'Next'
    const isAllergiesAndMeds =
      formSteps[currentSectionIndex + 1]?.sectionKey ===
      FormSectionKey.ALLERGIES_MEDICATIONS
    if (isAllergiesAndMeds) {
      label = 'Next: Allergies & Meds'
    } else if (formSteps[currentSectionIndex + 1]?.sectionKey) {
      label = `Next: ${formSteps[currentSectionIndex + 1]?.sectionKey
        .split('_')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')}`
    }
    return <div className={styles.nextButtonContent}>{label}</div>
  }, [formSteps, currentSection, isLoading])

  const updateInsuranceUrl = async (
    patientId: string,
    data: string,
    key: string
  ) => {
    try {
      const contentType = data.replace('data:', '').split(';')[0]
      const url = await getInsuranceCardUploadUrl(patientId, key, contentType)
      if (!url) return console.error('No url able to be created for form')
      const blob = await (await fetch(data))?.blob()
      await fetch(url, {
        method: 'PUT',
        headers: new Headers({ 'Content-Type': contentType }),
        body: blob,
      })
    } catch (e) {
      notification(
        'Error uploading insurance card image. Please try again.',
        'failure'
      )
      return console.error(e)
    }

    await updateInsuranceCardKey(patientId, key)
  }

  const submitInsuranceImages = useCallback(
    async (formData: any) => {
      const insuranceImageFront =
        formData[InsuranceQuestionKeys.INSURANCE_CARD_IMAGE_FRONT]
      const insuranceImageBack =
        formData[InsuranceQuestionKeys.INSURANCE_CARD_IMAGE_BACK]
      const patientId: string | undefined = incompleteFormData?.patientId

      if (!insuranceImageFront && !insuranceImageBack) return
      if (!patientId) return console.error('No patientId found for form')

      const date = formatDate({
        value: new Date(),
        format: 'yyyy-MM-dd_hhmm_aa',
        shouldLocalize: true,
      })

      if (insuranceImageFront) {
        const frontKeyName = `${date}#INSURANCE_CARD#FRONT`
        await updateInsuranceUrl(patientId, insuranceImageFront, frontKeyName)
      }

      if (insuranceImageBack) {
        const backKeyName = `${date}#INSURANCE_CARD#BACK`
        await updateInsuranceUrl(patientId, insuranceImageBack, backKeyName)
      }
    },
    [incompleteFormData]
  )

  const completeForm = useCallback(async () => {
    if (!incompleteFormData) return

    try {
      setIsLoading(true)
      await form.validateFields()
      const formData = form.getFieldsValue()
      // getPresigned URLS
      await submitInsuranceImages(formData)
      formData[InsuranceQuestionKeys.INSURANCE_CARD_IMAGE_FRONT] = undefined
      formData[InsuranceQuestionKeys.INSURANCE_CARD_IMAGE_BACK] = undefined
      const formResponses = validateIntakeFormForSubmission(formData)
      await submitIntakeForm({
        formId: incompleteFormData.id,
        responses: formResponses,
      })
      history.push('/form/success')
    } catch (err) {
      const errorMessage =
        err.response?.incompleteFormData?.error ??
        err.response?.data.error ??
        'Please address the errors on this step before submitting.'
      notification(errorMessage, 'error')

      if (err.errorFields && err.errorFields[0]) {
        form.scrollToField(err.errorFields[0].name[0], {
          behavior: 'smooth',
          block: 'center',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [incompleteFormData, form])

  if (loading) {
    return (
      <div className={styles.intakeFormClass}>
        <Skeleton paragraph={{ rows: StandardSkeletonRows.fullPage }} />
      </div>
    )
  }

  if (error && !storybook) {
    return (
      <InfoPage
        status="warning"
        title="Sorry there was a problem loading this page"
        details={error}
      />
    )
  }

  return (
    <div className={styles.intakeFormContainer} ref={intakeFormRef}>
      <Progress
        className={styles.progressBar}
        percent={completionPercent}
        showInfo={false}
        trailColor="white"
      />
      {formSteps.length ? (
        <Form
          id={styles.intakeForm}
          data-testid="form-success"
          layout="vertical"
          form={form}
        >
          <Steps
            footerStyling={{
              backClassName: styles.backButton,
              doneClassName: styles.doneButton,
              nextClassName: styles.nextButton,
            }}
            steps={formSteps}
            handleSubmit={completeForm}
            hideHeader
            validateBeforeNext={validateCurrentStep}
            onBackClick={onBackClick}
            isLoading={isLoading}
            nextButtonContent={nextStepButtonContent}
            nextButtonIcon={<ArrowRightOutlined className={styles.nextArrow} />}
            leftAlign={true}
            showBackButton
          />
          {completionPercent === 0 && (
            <div className={styles.disclaimer}>
              Your use of this form is subject to our{' '}
              <a href="https://www.osmind.org/terms-of-use" target="_blank">
                Terms of Use
              </a>{' '}
              and{' '}
              <a
                href="https://www.osmind.org/privacy-policy#:~:text=Privacy%20Policy"
                target="_blank"
              >
                Privacy Policy
              </a>
              . By clicking the button above and proceeding, you agree to these
              terms of use and policy.
            </div>
          )}
        </Form>
      ) : null}
    </div>
  )
}

export default PatientIntake
