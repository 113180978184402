// Import react
import React from 'react'

import { Redirect, Route } from 'react-router-dom'

// Import libs/other
import { useAppContext } from '../../libs/contextLib'

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&')

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i')
  const results = regex.exec(url)

  if (!results) {
    return null
  }
  if (!results[2]) {
    return ''
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export default function UnauthenticatedRoute({
  children,
  forceRedirect = true,
  ...rest
}) {
  const { isAuthenticated } = useAppContext()
  const redirect = querystring('redirect')
  return (
    <Route {...rest}>
      {isAuthenticated && forceRedirect ? (
        <Redirect to={redirect === '' || redirect === null ? '/' : redirect} />
      ) : (
        children
      )}
    </Route>
  )
}
