import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const sexAssignedAtBirthFHIR: PatientDemographicObject[] = [
  { F: { title: 'F' } },
  { M: { title: 'M' } },
  { UNK: { title: 'Unknown' } },
]

export const SexAssignedAtBirthDropDownValues =
  constructPatientDemographicObject(
    sexAssignedAtBirthFHIR,
    CodeSystemOIDs.OSMINDCustomCodeSystem,
    Categories.LEGAL_RECORD_SEX
  )
