// legal name validation
// currently just on patient invite--update me if used elsewhere
export const legalNameRegex = /^[0-9A-Za-z-'\s]*[0-9A-Za-z]+$/

// if you have some way of reliably breaking this up without breaking it, please--
// PLEASE--tell me your secrets :sob-face:
export const diacriticNameRegex =
  /^[0-9A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ-'\s]*[0-9A-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ]+$/ //new RegExp(`^[0-9A-z${diacriticRegex}-'\s]*[0-9A-z${diacriticRegex}]+/$`)

// billing NPI
export const npiRegex = /^\d{10}$/

/**
  password which has at least 8 characters, 1 Uppercase, 1 Lowercase, 1 Special character, 1 Number
*/
export const passwordRegex =
  '^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*!=]).*$'

/**
  US phone number: ie. (123) 456-7890
  the number is already formatted, and does not have leading 1 or 0
*/
export const phoneNumberRegex = /(\([2-9]{1}\d{2}\)\s\d{3}-\d{4})/

// EIN
export const einRegex = /^[0-9]\d?-\d{7}$/

// SSN

export const ssnRegExPersonalSettings =
  /^\d{3}-\d{2}-\d{4}$|^\*\*\*-\*\*-\d{4}$/

/**
 Ensures string has at least one none-whitespace character
 examples:
 INVALID: '   ', '  ', ' ', '', '                '
 VALID: '1acorn', '   two pine cones    ', '3walnut   ', '   4hickorynuts'
*/
export const noBlankSpaceOnlyRegex = /^\s{0,}\S{1,}.{0,}$/

// All characters that are not digits 0-9
export const nonNumericalRegex = /[^0-9]+/g

export const dobRegex = /^\d{2}\/\d{2}\/\d{4}$/
export const ssnRegex = /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/

// only applies to US zipcodes
export const zipcodeRegex = /^\d{5}(-\d{4})?$/

/**
  This applies to codesets that have prefix with the category.
  It only returns true if the period is in the front prefixed with a string
  Example:
    Cancer codeset will have "can.thyroid Cancer"
    ENT codeset will have "ent.thyroid Cancer"
*/
export const prefixedCodesetCategoryRegex = /^.{0,1}[a-zA-Z]+\./

/**
  Anything that isn't a number or a hyphen

  Example:
    Pass: (abcde)!!
    Fail: 500-555-0006
*/
export const noNumbersHyphensRegex = /[^\d|-]/g

/**
 * Date formats
 */
export const mmyyyyslash = /^((0[1-9])|(1[0-2]))\/(\d{4})$/ // mm/yyyy
export const mmyyyydash = /^((0[1-9])|(1[0-2]))-(\d{4})$/ // mm-yyyy

// Utility
/**
  This looks for underscores and is useful for applying on the
  question keys or various enum values
  Example:
    FIRST_NAME = "FIRST NAME"
*/
export const underscores = /_/g

// Email regEx
export const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// Ten digit phone number
export const tenDigitPhoneNumberRegEx = /^[0-9]{10}$/

// No symbols regex
export const nameRegEx = /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/
// Ten digit number
export const tenDigitNumberRegEx = /^[0-9]{10}$/
// Nine digit number
export const nineDigitNumberRegEx = /^[0-9]{9}$/
// Nine digit number with - (EIN number)
export const einRegEx = /^\d{2}-\d{7}$/
// Nine digit number with - in two different places (SSN number)
export const ssnRegEx = /^\d{2}-\d{3}-\d{4}$/
