import React from 'react'

import { Container, Form, Row } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'

import './../PatientProfile.scss'

export default function NeurocognitiveSaved(props) {
  const hasNoRelevantHistory =
    props.patientData.Delirium.state === false &&
    props.patientData.MayorNeurocognitiveDisorder.state === false &&
    props.patientData.MinorNeurocognitiveDisorder.state === false &&
    props.patientData.OtherNeurocognitive.state === false &&
    !props.patientData.NeurocognitiveAdditionalNotes &&
    props.patientData.NoNeurocognitive === true

  const noRecordedInfo =
    props.patientData.Delirium.state === false &&
    props.patientData.MayorNeurocognitiveDisorder.state === false &&
    props.patientData.MinorNeurocognitiveDisorder.state === false &&
    props.patientData.OtherNeurocognitive.state === false &&
    !props.patientData.NeurocognitiveAdditionalNotes &&
    props.patientData.NoNeurocognitive === false &&
    Object.values(props.patientData.NeurocognitiveSymptoms).some((v) => !!v) ===
      false

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Neurocognitive"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">Neurocognitive:</Form.Row>
      <Form.Group className="indent">
        {props.patientData.Delirium.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.Delirium.state)}
            />
            <span id="DeliriumShow">Delirium</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.Delirium.Notes}
          </Container>
        )}
        {props.patientData.MayorNeurocognitiveDisorder.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.MayorNeurocognitiveDisorder.state
              )}
            />
            <span id="MayorNeurocognitiveDisorderShow">
              Major neurocognitive disorder
            </span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.MayorNeurocognitiveDisorder.Notes}
          </Container>
        )}
        {props.patientData.MinorNeurocognitiveDisorder.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.MinorNeurocognitiveDisorder.state
              )}
            />
            <span id="MinorNeurocognitiveDisorderShow">
              Minor neurocognitive disorder
            </span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.MinorNeurocognitiveDisorder.Notes}
          </Container>
        )}
        {props.patientData.OtherNeurocognitive.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.OtherNeurocognitive.state)}
            />
            <span id="OtherNeurocognitiveShow">Other</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.OtherNeurocognitive.Notes}
          </Container>
        )}
        {props.patientData.NeurocognitiveSymptoms.Confusion && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.NeurocognitiveSymptoms.Confusion
              )}
            />
            <span id="ConfusionShow">Confusion</span>
            <br />
          </Container>
        )}
        {props.patientData.NeurocognitiveSymptoms.MemoryLoss && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.NeurocognitiveSymptoms.MemoryLoss
              )}
            />
            <span id="MemoryLossShow">Memory loss</span>
            <br />
          </Container>
        )}
        {props.patientData.NeurocognitiveSymptoms.Other && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.NeurocognitiveSymptoms.Other
              )}
            />
            <span id="OtherTextShow">
              {props.patientData.NeurocognitiveSymptoms.OtherText}
            </span>
            <br />
          </Container>
        )}
      </Form.Group>
      <Form.Group className="border-bottom indent">
        {props.patientData.NeurocognitiveAdditionalNotes && (
          <Container bsPrefix="psych">
            <Form.Row className="patient-dark">Additional Notes:</Form.Row>
            <Row bsPrefix="psych-sub">
              {props.patientData.NeurocognitiveAdditionalNotes}
            </Row>
          </Container>
        )}
      </Form.Group>
    </Form.Group>
  )
}
