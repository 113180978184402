import React, { useEffect } from 'react'

import { Select } from 'antd'

import { INVOICE_FORM_MODES } from '../../libs/constants'
import { formattedPhoneNumber } from '../../libs/utils'
import { Practice } from '../../shared-types'
import { Address, Card, RequiredLabel } from '../BaseComponents'
import { EditableSelect } from '../BaseComponents/EditableInputs'
import { InfoGrid, InfoRow } from '../BaseComponents/InfoGrid'
import { BillingIdentifiers, Location, Provider } from '../types'
import { InvoiceComponentProps } from './constants'

import styles from './Invoice.module.scss'

const { Option, OptGroup } = Select

type PracticeInformationProps = {
  practice?: Practice
  locations?: Location[]
  provider?: Provider
  selectedLocation?: Location
  onSelectLocation: (locationId: number) => void
  selectedEIN?: string
  onSelectEIN: (ein: string) => void
  selectedNPI?: string
  onSelectNPI: (npi: string) => void
  missingFields?: string[]
  mode: INVOICE_FORM_MODES
  setLocationDetails: (location: Location | null) => void
  location: Location | null
} & InvoiceComponentProps

export const PracticeInformation = ({
  isLoading,
  isEditing,
  mode,
  practice,
  locations = [],
  provider,
  selectedLocation,
  selectedEIN,
  onSelectEIN,
  selectedNPI,
  onSelectNPI,
  missingFields = [],
  onSelectLocation,
  isSuperbillEnabled,
  setLocationDetails,
  location,
}: PracticeInformationProps) => {
  const einAndNpiSources: { type: string; value?: BillingIdentifiers }[] = [
    { type: 'Practice', value: practice },
    { type: 'Location', value: selectedLocation },
  ]

  const einOptions = einAndNpiSources.filter((source) => source.value?.ein)
  const npiOptions = [
    ...einAndNpiSources,
    { type: 'Provider', value: provider },
  ].filter((source) => source.value?.billingNPI)

  useEffect(() => {
    const [defaultNpiSource] = npiOptions
    if (
      npiOptions.length === 1 &&
      defaultNpiSource.value?.billingNPI &&
      !selectedNPI
    ) {
      onSelectNPI(defaultNpiSource.value.billingNPI)
    }
  }, [npiOptions, selectedNPI])

  useEffect(() => {
    if (mode !== INVOICE_FORM_MODES.CREATE) {
      if (selectedLocation) {
        setLocationDetails(selectedLocation)
      }
    }
  }, [selectedLocation])

  /// If no phoneNumber is provided from location
  /// practice phone number is used instead.
  let phoneNumber = ''
  if (location) {
    if (location?.phoneNumber) {
      phoneNumber = location?.phoneNumber
    } else {
      phoneNumber = practice?.phoneNumber as string
    }
  }

  let rows: InfoRow[] = [
    { label: 'Practice:', content: practice?.name },
    {
      label: <RequiredLabel content="Location:" showAsterisk={isEditing} />,
      content: (
        <EditableSelect
          shouldHighlight={missingFields.includes('location')}
          value={location?.name}
          disabled={!isEditing}
          placeholder={isEditing ? 'Select location...' : ''}
          onChange={(locationId: number) => {
            if (locationId >= 0) {
              const _location = locations?.find(
                (location) => location.id === locationId
              )
              setLocationDetails(_location || null)
              onSelectLocation(locationId)
            }
          }}
          options={locations.map((location) => {
            return (
              <Option key={`location-${location.id}`} value={location.id || ''}>
                {location.name}
              </Option>
            )
          })}
        />
      ),
    },
    { label: 'Address:', content: <Address address={location} /> },
    {
      label: 'Phone:',
      content: formattedPhoneNumber(phoneNumber),
    },
  ]

  if (isSuperbillEnabled) {
    rows = rows.concat([
      {
        label: (
          <RequiredLabel content="Practice EIN:" showAsterisk={isEditing} />
        ),
        content: (
          <EditableSelect
            testId="ein-select"
            disabled={!isEditing}
            shouldHighlight={missingFields.includes('selectedEIN')}
            value={selectedEIN}
            placeholder={isEditing ? 'Add EIN...' : ''}
            onChange={(val: string) => onSelectEIN(val)}
            options={einOptions.map(
              (
                val: { type: string; value?: BillingIdentifiers },
                index: number
              ) => (
                <OptGroup key={`ein-${index}`} label={`${val.type} EIN`}>
                  <Option
                    key={val.value?.ein || `${val.type}-opt`}
                    value={val.value?.ein || ''}
                  >
                    {val.value?.ein}
                  </Option>
                </OptGroup>
              )
            )}
          />
        ),
      },
      {
        label: (
          <RequiredLabel content="Practice NPI:" showAsterisk={isEditing} />
        ),
        content: (
          <EditableSelect
            testId="npi-select"
            disabled={!isEditing}
            shouldHighlight={missingFields.includes('selectedNPI')}
            value={selectedNPI}
            placeholder={isEditing ? 'Add NPI...' : ''}
            onChange={(val: string) => val?.length && onSelectNPI(val)}
            options={npiOptions.map(
              (
                val: { type: string; value?: BillingIdentifiers },
                index: number
              ) => (
                <OptGroup key={`npi-${index}`} label={`${val.type} NPI`}>
                  <Option
                    key={val.value?.billingNPI || `${val.type}-opt`}
                    value={val.value?.billingNPI || ''}
                  >
                    {val.value?.billingNPI}
                  </Option>
                </OptGroup>
              )
            )}
          />
        ),
      },
    ])
  }

  return (
    <Card loading={isLoading} style={{ flexBasis: '33%' }}>
      <div className={styles.sectionHeader}>Practice information</div>

      <InfoGrid rows={rows} />
    </Card>
  )
}
