import { Select } from 'antd'

import {
  useCalendarTimezone,
  useTimezoneMutation,
} from '../../hooks/useCalendars'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import { Card, Row, Spinner, Text, Title } from '../BaseComponents'
import {
  LOCATION_TO_TIMEZONE_MAPPING,
  Location,
  TIMEZONE_OPTIONS,
  Timezone,
} from './constants'

import styles from '../../containers/_shared.module.scss'
import './Scheduling.scss'

const { Option } = Select

const TimezoneSettings = () => {
  const { data: clinicCalendarTimezone } = useCalendarTimezone()
  const { mutate: mutateTimezone, isLoading: isChangingTimezone } =
    useTimezoneMutation()

  const sectionHeaderText = { fontWeight: 600, fontSize: 18 }

  const loadTimezone = () => {
    let formattedTimezone = clinicCalendarTimezone as string
    for (const alt of Object.keys(LOCATION_TO_TIMEZONE_MAPPING)) {
      if (
        (formattedTimezone as Timezone) ===
        LOCATION_TO_TIMEZONE_MAPPING[alt as Location]
      ) {
        formattedTimezone = alt
      }
    }
    return formattedTimezone
  }

  const currentTimezone = loadTimezone()

  const changeTimezone = async (newTimezone: string) => {
    try {
      if (!newTimezone) {
        throw new Error('No new timezone found')
      }
      mutateTimezone({ timezone: newTimezone })
    } catch (e) {
      onError(new Error('Unable to update calendar timezone'))
      notification('Unable to update calendar timezone', 'failure')
    }
  }

  const timezoneOptions = () =>
    TIMEZONE_OPTIONS.map((zone, idx) => (
      <Option
        key={`${LOCATION_TO_TIMEZONE_MAPPING[zone as Location]}-${idx}`}
        value={LOCATION_TO_TIMEZONE_MAPPING[zone as Location]}
      >
        {zone}
      </Option>
    ))

  const loaderSpinner = (
    <Spinner
      fontSize={40}
      className="d-flex justify-content-center calendar-spinner scheduling-spinner"
      style={{ marginTop: 48 }}
    />
  )

  const calendarTitle = (
    <Title level={3} style={{ fontWeight: 600, marginBottom: '1em' }}>
      Practice timezone
    </Title>
  )

  const timezoneSelect = (
    <div className="sectionHeader">
      <Text style={sectionHeaderText}>Practice time zone</Text>
      <Select
        className="select-timezone"
        defaultValue={currentTimezone}
        onChange={(zone) => changeTimezone(zone)}
        loading={isChangingTimezone}
      >
        {timezoneOptions()}
      </Select>
      <div className="timezone-disclaimer">
        The selected time zone is applied to the entire practice, and not on a
        per-user basis.
      </div>
    </div>
  )

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        <Row className="timezone-settings-row" justify="start" wrap={false}>
          <Card bordered={false} className="timezone-settings-card">
            {!currentTimezone ? (
              loaderSpinner
            ) : (
              <>
                {calendarTitle}
                {timezoneSelect}
              </>
            )}
          </Card>
        </Row>
      </div>
    </div>
  )
}

export default TimezoneSettings
