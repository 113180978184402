import React, { useEffect, useState } from 'react'

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Nav,
  Row,
  Tab,
} from 'react-bootstrap'

import { capitalize, isEmptyObject } from '../../../../../../libs/utils'
import { useClinicalNoteContext } from '../../../ClinicalNoteContext'
import { Normal, Section, SectionFieldMap } from './constants'

enum Common {
  NONE = 'No known issues',
  OTHER = 'Other',
}

export default function ComprehensiveMedicalROS(props: any) {
  const { updateType, fields, setFields, findMostRecentEntry } =
    useClinicalNoteContext()
  const [section, setSection] = useState<keyof typeof Section>('Constitutional')

  const state = fields.ComprehensiveMedicalROS as any
  const setState = (
    section: keyof typeof Section,
    key: string,
    value?: string | boolean
  ) => {
    if (key === Common.NONE) {
      return setFields({
        ...fields,
        ComprehensiveMedicalROS: {
          ...state,
          [section]: value ? null : {},
        },
      })
    }

    return setFields({
      ...fields,
      ComprehensiveMedicalROS: {
        ...state,
        [section]: {
          ...state[section],
          [key]: value,
        },
      },
    })
  }
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    setExpanded((isExpanded) => isExpanded || !isEmptyObject(state))
  }, [updateType, state])

  const mostRecentEntry = findMostRecentEntry('ComprehensiveMedicalROS')

  const renderCheckbox = (
    key: string,
    label: string,
    value: string | undefined
  ) => {
    const checked = value !== undefined
    return (
      <Form.Group key={key} as={Col} xs={12} sm={12} lg={4} xl={4}>
        <Form.Row key={key}>
          <Form.Check
            id={`checkbox-${section}-${key}`}
            type="checkbox"
            checked={checked}
            label={capitalize(label)}
            disabled={props.disabled}
            onChange={(event) =>
              setState(
                section,
                key,
                event.currentTarget.checked ? '' : undefined
              )
            }
          />
          {checked && (
            <Form.Control
              bsPrefix="form-input"
              placeholder="Additional notes"
              value={value}
              disabled={props.disabled}
              onChange={(event) => setState(section, key, event.target.value)}
            />
          )}
        </Form.Row>
      </Form.Group>
    )
  }

  return !expanded ? (
    <Row style={{ justifyContent: 'center' }}>
      <span>
        <Button
          bsPrefix="center plain-button info button-label"
          onClick={() => setExpanded(true)}
        >
          + Comprehensive Medical ROS
        </Button>
      </span>
    </Row>
  ) : (
    <Accordion defaultActiveKey="0">
      <Accordion.Toggle
        bsPrefix="accordion-header very-large accordion-toggle"
        as={Card.Header}
        eventKey="0"
      >
        <div
          className="alert-clinical-notes"
          style={{ cursor: 'pointer', marginBottom: 16, display: 'flex' }}
        >
          <span style={{ width: 'calc(100% - 150px)', paddingLeft: '120px' }}>
            Comprehensive Medical ROS
          </span>
          {!props.disabled ? (
            <Button
              className="modal-save-button"
              style={{ width: '150px' }}
              onClick={() =>
                setFields({ ...fields, ComprehensiveMedicalROS: Normal })
              }
            >
              Mark all sections "No known issues"
            </Button>
          ) : null}
          {mostRecentEntry && !props.disabled ? (
            <Button
              className="modal-save-button"
              style={{ width: '150px' }}
              onClick={() =>
                setFields({
                  ...fields,
                  ComprehensiveMedicalROS: mostRecentEntry.value,
                })
              }
            >
              Copy most recent -{' '}
              {new Date(mostRecentEntry.CreatedOn).toLocaleDateString()}
            </Button>
          ) : null}
          {!props.disabled && (
            <Button
              className="modal-save-button"
              style={{ width: '120px' }}
              onClick={() => {
                setFields({
                  ...fields,
                  ComprehensiveMedicalROS: {},
                })
                return setExpanded(false)
              }}
            >
              <FontAwesomeIcon
                icon={faTrash}
                style={{ marginLeft: '5px', marginRight: '5px', fontSize: 16 }}
              />
              Remove
            </Button>
          )}
        </div>
      </Accordion.Toggle>

      <Tab.Container
        activeKey={section}
        onSelect={(s) => s && setSection(s as keyof typeof Section)}
      >
        <Row>
          <Col
            sm={3}
            style={{
              maxHeight: 400,
              overflowY: 'scroll',
              borderRight: '1px solid #eee',
            }}
          >
            <Nav
              variant="pills"
              className="flex-column"
              style={{ flexWrap: 'nowrap' }}
            >
              {Object.entries(Section).map(([key, label]) => (
                <Nav.Item key={key} style={{ margin: 0 }}>
                  <Nav.Link eventKey={key}>{label}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content
              style={{
                display: 'flex',
                flexFlow: 'column wrap',
                paddingTop: 16,
                maxHeight: 400,
                overflowX: 'scroll',
              }}
            >
              {section === 'Psychiatric' ? (
                <span>
                  {' '}
                  The psychiatric ROS is a separate section in the Osmind system{' '}
                </span>
              ) : (
                <>
                  {Object.entries(SectionFieldMap[Section[section]])
                    .concat([[Common.OTHER, Common.OTHER]])
                    .map(([key, label]) => {
                      const value = state?.[section]?.[key]
                      return renderCheckbox(key, label, value)
                    })}
                  <Form.Group as={Col} xs={12} sm={12} lg={4} xl={4}>
                    <Form.Row>
                      <Form.Check
                        className="green"
                        type="checkbox"
                        checked={state[section] === null}
                        label={Common.NONE}
                        id="NoneMedicalROS"
                        disabled={props.disabled}
                        onChange={(event) =>
                          setState(
                            section,
                            Common.NONE,
                            event.currentTarget.checked
                          )
                        }
                      />
                    </Form.Row>
                  </Form.Group>
                </>
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Accordion>
  )
}
