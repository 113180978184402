import { useMutation, useQueryClient } from '@tanstack/react-query'

import { updateNoteBlock } from '../../api'
import { getNoteBlockQueryKey } from './useGetNoteBlock'

export const useUpdateNoteBlock = () => {
  const queryClient = useQueryClient()
  const {
    mutateAsync: update,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(updateNoteBlock, {
    onSuccess: (_, opts) => {
      queryClient.invalidateQueries(
        getNoteBlockQueryKey(opts.noteUuid, opts.blockUuid)
      )
    },
  })

  return {
    updateNoteBlock: update,
    isLoading,
    isSuccess,
    isError,
  }
}
