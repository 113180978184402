import { useContext } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { cloneDeep } from 'lodash'

import {
  getPersonalSettings,
  getTeammateData,
  loadPatientInfo,
} from '../../api/api-lib'
import {
  createClaimFromPatientInfo,
  getClaimById,
  getPaymentInfo,
} from '../../api/insuranceClaims'
import { ChangePayersList, getChangePayersList } from '../../api/intakeForms'
import { getPatientInsurance } from '../../api/patients'
import { onError } from '../../libs/errorLib'
import { Teammate } from '../../shared-types'
import { UnreadMessageCountsContext } from '../../v2/messaging/UnreadMessageCounts'
import { UpdateSendbirdUserFn } from '../../v2/messaging/UnreadMessageCounts/types'
import { QueryKeys as usePatientInfoQueryKeys } from '../usePatientInfo'
import { updatePatientDemographicsHandler } from './handlers'
import {
  CoreIdentifiers,
  CoreIdentifiersMutation,
  DemographicsContactInfo,
  DemographicsContactInfoMutation,
  DemographicsContactInfoMutationParams,
  DemographicsSection,
  GetClaimInfo,
  GetPaymentRequest,
  GetTeammateRequest,
  PatientInfo,
  PatientProfileRequestObject,
  UpdateInsuranceRequest,
} from './shared-types'
import {
  adjustPatientBirthdate,
  mapDemographicsToCoreIdentifiers,
  mapPatientInfoToCoreIdentifiers,
  mapPatientInfoToDemographics,
} from './utils'

export enum QueryKeys {
  CORE_IDENTIFIERS = 'CORE_IDENTIFIERS',
  DEMOGRAPHICS_CONTACT_INFO = 'DEMOGRAPHICS_CONTACT_INFO',
  PATIENT_INSURANCE = 'PATIENT_INSURANCE',
  CHANGE_PAYERS = 'CHANGE_PAYERS',
  PATIENT_INSURANCE_CARDS = 'PATIENT_INSURANCE_CARDS',
  TEAMMATE_DATA = 'TEAMMATE_DATA',
  PATIENT_INSURANCE_PAYMENTS = 'PATIENT_INSURANCE_PAYMENTS',
  GET_CLAIM_INFO = 'GET_CLAIM_INFO',
}

const LOAD_ERROR_MSG = "There was a problem loading this patient's data."
const UPDATE_ERROR_MSG = "There was a problem saving this patient's data."

/** Client-side timezone correction for patient birthdate with UTC timestamps that may
 * offset the date to the next/previous day. This will only run on dates that have precise timestamps
 * not equal to "T00:00:00.000Z" */
export const correctPatientBirthdate = async (
  patientInfo: PatientInfo,
  updateSendbirdUser: UpdateSendbirdUserFn
): Promise<PatientInfo & { didBirthdateUpdate: boolean }> => {
  const patientBirthdate = patientInfo.DateOfBirth

  if (!patientBirthdate || patientBirthdate.includes('T00:00:00.000Z')) {
    return {
      ...patientInfo,
      didBirthdateUpdate: false,
    }
  }

  try {
    const correctedDateTime = adjustPatientBirthdate(patientBirthdate)
    const patientId = patientInfo.PatientId ?? ''
    const updatedDataMap: PatientInfo = {
      ...patientInfo,
      DateOfBirth: correctedDateTime,
    }

    await updatePatientDemographicsHandler(
      patientId,
      updatedDataMap,
      DemographicsSection.DateOfBirth,
      updateSendbirdUser,
      '',
      patientInfo
    )
    return {
      ...updatedDataMap,
      didBirthdateUpdate: true,
    }
  } catch (err) {
    console.error('Failed to correct patient birthdate')
    return {
      ...patientInfo,
      didBirthdateUpdate: false,
    }
  }
}

export const usePatientCoreIdentifiers = ({
  patientId,
}: PatientProfileRequestObject) => {
  const { updateSendbirdUser } = useContext(UnreadMessageCountsContext)
  const fetchFunction = async (): Promise<CoreIdentifiers> => {
    let response: CoreIdentifiers = {}
    let patientInfo: PatientInfo
    patientInfo = await loadPatientInfo(patientId) // TODO: Refactor this https://osmind.atlassian.net/browse/CC-2492
    patientInfo = await correctPatientBirthdate(patientInfo, updateSendbirdUser)
    response = mapPatientInfoToCoreIdentifiers(patientInfo)
    return response
  }
  const { data, error, isFetching, isError } = useQuery<CoreIdentifiers>(
    [QueryKeys.CORE_IDENTIFIERS, patientId],
    fetchFunction,
    {
      onError: (error) => onError(error, 500, LOAD_ERROR_MSG),
      retry: false,
      refetchOnWindowFocus: false,
    }
  )

  return {
    coreIdentifiers: data,
    error,
    isFetching,
    isError,
  }
}

export const useUpdatePatientCoreIdentifiersMutation = ({
  patientId,
}: PatientProfileRequestObject): CoreIdentifiersMutation => {
  const queryClient = useQueryClient()
  const { updateSendbirdUser } = useContext(UnreadMessageCountsContext)

  const fetchFunction = async ({ summary }: any): Promise<CoreIdentifiers> => {
    let response: CoreIdentifiers = {}
    const data: DemographicsContactInfo | undefined = queryClient.getQueryData([
      QueryKeys.DEMOGRAPHICS_CONTACT_INFO,
      patientId,
    ])
    const formData = { ...data, summary }
    await updatePatientDemographicsHandler(
      patientId,
      formData,
      DemographicsSection.summary,
      updateSendbirdUser,
      'Summary',
      data
    )
    response = queryClient.getQueryData([
      QueryKeys.CORE_IDENTIFIERS,
      patientId,
    ]) as CoreIdentifiers
    response.summary = summary
    return response
  }

  return useMutation(fetchFunction, {
    onSuccess: async (data) => {
      queryClient.setQueryData([QueryKeys.CORE_IDENTIFIERS, patientId], data)
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.CORE_IDENTIFIERS, patientId])
      queryClient.invalidateQueries([
        usePatientInfoQueryKeys.DEMOGRAPHICS,
        patientId,
      ])
    },
    onError: (error: Error) => {
      queryClient.fetchQuery([QueryKeys.CORE_IDENTIFIERS, patientId])
      return onError(error, 500, error?.message ?? UPDATE_ERROR_MSG)
    },
  })
}

export const useDemographicsContactInfo = ({
  patientId,
}: PatientProfileRequestObject) => {
  const fetchFunction = async (): Promise<DemographicsContactInfo> => {
    let patientInfo: PatientInfo = {}
    let response: DemographicsContactInfo = {}
    patientInfo = await loadPatientInfo(patientId) // // TODO: Refactor this https://osmind.atlassian.net/browse/CC-2492
    response = mapPatientInfoToDemographics(patientInfo)
    return response
  }
  const { data, error, isFetching, isError } = useQuery<PatientInfo>(
    [QueryKeys.DEMOGRAPHICS_CONTACT_INFO, patientId],
    fetchFunction,
    {
      onError: (error) => onError(error, 500, LOAD_ERROR_MSG),
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
  return {
    data,
    error,
    isFetching,
    isError,
  }
}

export const useDemographicsContactInfoMutation = ({
  patientId,
  updateSendbirdUser,
}: PatientProfileRequestObject & {
  updateSendbirdUser: UpdateSendbirdUserFn
}): DemographicsContactInfoMutation => {
  const queryClient = useQueryClient()

  const fetchFunction = async ({
    formData,
    patientInfo,
    newValueId,
    newValueLabel,
  }: DemographicsContactInfoMutationParams): Promise<DemographicsContactInfo> => {
    return await updatePatientDemographicsHandler(
      patientId,
      formData,
      newValueId,
      updateSendbirdUser,
      newValueLabel,
      patientInfo
    )
  }

  return useMutation(fetchFunction, {
    onSuccess: async (data) => {
      queryClient.setQueryData(
        [QueryKeys.DEMOGRAPHICS_CONTACT_INFO, patientId],
        data
      )
      const newCoreIdentifiersData = mapDemographicsToCoreIdentifiers(
        cloneDeep(data),
        queryClient.getQueryData([QueryKeys.CORE_IDENTIFIERS, patientId])
      )
      queryClient.setQueryData(
        [QueryKeys.CORE_IDENTIFIERS, patientId],
        newCoreIdentifiersData
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        QueryKeys.DEMOGRAPHICS_CONTACT_INFO,
        patientId,
      ])
      queryClient.invalidateQueries([
        usePatientInfoQueryKeys.DEMOGRAPHICS,
        patientId,
      ])
    },
    onError: (error: Error) => {
      queryClient.fetchQuery([QueryKeys.DEMOGRAPHICS_CONTACT_INFO, patientId])
      return onError(error, 500, error?.message ?? UPDATE_ERROR_MSG)
    },
  })
}

export const useInsuranceInfo = ({ patientId }: UpdateInsuranceRequest) => {
  const fetchFunction = async () => {
    let response: any = {}

    response = await getPatientInsurance(patientId)

    return response
  }

  const { data, error, isFetching, isError, refetch } = useQuery<any>(
    [QueryKeys.PATIENT_INSURANCE, patientId],
    fetchFunction,
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 300000,
    }
  )

  return {
    data,
    error,
    isFetching,
    isError,
    refetch,
  }
}

export const useClaimInfo = ({ patientId, noteId, claimId }: GetClaimInfo) => {
  const fetchFunction = async () => {
    if (claimId) {
      return await getClaimById(patientId, claimId)
    }
    return await createClaimFromPatientInfo({ patientId, noteId })
  }

  const { data, error, isFetching, isError, refetch } = useQuery(
    [QueryKeys.GET_CLAIM_INFO, claimId],
    fetchFunction,
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  )

  return {
    data,
    error,
    isFetching,
    isError,
    refetch,
  }
}
/*
  Regarding the 5min staleTime:
  Requested data is a large list with over 3000 items. This list is updated infrequently (once a quarter). It is relatively safe and more efficient to have a longer staleTime.
  At the same time, once it is updated in the back end, we want the user to see the new list soon-ish.
  For now, the team has determined that 5 minutes is a good middle ground.
*/
export const useChangePayers = () => {
  const fetchFunction = async (): Promise<ChangePayersList[]> => {
    return await getChangePayersList()
  }

  const { data, error, isFetching, isError, status } = useQuery(
    [QueryKeys.CHANGE_PAYERS],
    fetchFunction,
    {
      onError: (error) => onError(error, 500, LOAD_ERROR_MSG),
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 300_000, // 300s => 5min
    }
  )

  return {
    data,
    error,
    isFetching,
    isError,
    status,
  }
}

export const useTeammatePersonalSettings = ({
  providerId,
}: GetTeammateRequest) => {
  const fetchFunction = async (): Promise<Teammate[]> => {
    const [teamSettings, mainProviderSettings] = await Promise.all([
      getTeammateData(),
      getPersonalSettings(providerId),
    ])
    if (mainProviderSettings) {
      for (const t of teamSettings) {
        if (t.cognitoId === mainProviderSettings.ProviderId) {
          t.phone = mainProviderSettings.ProviderPhone
        } else {
          t.phone = t.phoneNumber
        }
      }
    }
    return teamSettings
  }

  const { data, error, isFetching, isError } = useQuery<any>(
    [QueryKeys.TEAMMATE_DATA],
    fetchFunction,
    {
      onError: (error) => onError(error, 500, LOAD_ERROR_MSG),
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 20000,
    }
  )

  return {
    data,
    error,
    isLoading: isFetching,
    isError,
  }
}

export const usePaymentsInfoForClaim = ({
  claimId,
  patientId,
  isEnabled = false,
}: GetPaymentRequest) => {
  const fetchFunction = async () => {
    let response: any = {}

    response = await getPaymentInfo(claimId)
    return response
  }

  const { data, error, isFetching, isError, refetch } = useQuery(
    [QueryKeys.PATIENT_INSURANCE_PAYMENTS, patientId, claimId],
    fetchFunction,
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 300000,
      enabled: isEnabled,
    }
  )

  return {
    data,
    error,
    isFetching,
    isError,
    refetch,
  }
}
