import { QUERY_PARAMS } from '../../../libs/constants'

export const clinicalNotesBaseUrl = '/patient/clinical-notes'

interface getClinicalNotesUrlInterface {
  isV2?: boolean
  saltAlert?: boolean
  noteId?: string
  urlParams: string
}

export const getClinicalNotesUrl = ({
  isV2,
  noteId,
  saltAlert,
  urlParams,
}: getClinicalNotesUrlInterface) => {
  const allSearchParams = new URLSearchParams(urlParams)
  if (saltAlert) {
    allSearchParams.set(QUERY_PARAMS.saltAlert, 'true')
  }
  const v2 = isV2 ? '/v2' : ''

  if (noteId) {
    return `${clinicalNotesBaseUrl}${v2}/${noteId}?${allSearchParams.toString()}`
  }

  return `${clinicalNotesBaseUrl}${v2}?${allSearchParams.toString()}`
}

export const getNoteTemplateSettingsUrl = () => {
  return `/settings/note-templates` as const
}

export const getNoteTemplateUrl = ({ templateId }: { templateId: string }) => {
  return `/settings/note-templates/${templateId}` as const
}
