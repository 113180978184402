import React from 'react'

import { Container, Form, Row } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'

import './../PatientProfile.scss'

export default function DepressiveSaved(props) {
  const hasNoRelevantHistory =
    props.patientData.MajorDepressiveDisorder.state === false &&
    props.patientData.TreatmentResistantDepression.state === false &&
    props.patientData.DepressionAnxiousDistress.state === false &&
    props.patientData.OtherDepressive.state === false &&
    !props.patientData.DepressiveAdditionalNotes &&
    props.patientData.NoRelevantDepressive === true

  const noRecordedInfo =
    props.patientData.MajorDepressiveDisorder.state === false &&
    props.patientData.TreatmentResistantDepression.state === false &&
    props.patientData.DepressionAnxiousDistress.state === false &&
    props.patientData.OtherDepressive.state === false &&
    !props.patientData.DepressiveAdditionalNotes &&
    props.patientData.NoRelevantDepressive === false

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Depressive"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">Depressive:</Form.Row>
      <Form.Group className="indent">
        {props.patientData.MajorDepressiveDisorder.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.MajorDepressiveDisorder.state
              )}
            />
            <span id="MajorDepressiveDisorderShow">
              Major depressive disorder
            </span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Number of Episodes:{' '}
            </span>
            {props.patientData.MajorDepressiveDisorder.NumberEpisodes}
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.MajorDepressiveDisorder.Notes}
          </Container>
        )}
        {props.patientData.TreatmentResistantDepression.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.TreatmentResistantDepression.state
              )}
            />
            <span id="TreatmentResistantDepressionShow">
              Treatment-resistant depression
            </span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Number of Episodes:{' '}
            </span>
            {props.patientData.TreatmentResistantDepression.NumberEpisodes}
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.TreatmentResistantDepression.Notes}
          </Container>
        )}
        {props.patientData.DepressionAnxiousDistress.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.DepressionAnxiousDistress.state
              )}
            />
            <span id="DepressionAnxiousDistressShow">
              Depression with anxious distress
            </span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Number of Episodes:{' '}
            </span>
            {props.patientData.DepressionAnxiousDistress.NumberEpisodes}
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.DepressionAnxiousDistress.Notes}
          </Container>
        )}
        {props.patientData.OtherDepressive.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.OtherDepressive.state)}
            />
            <span id="OtherDepressiveShow">Other</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.OtherDepressive.Notes}
          </Container>
        )}
      </Form.Group>
      <Form.Group className="border-bottom indent">
        {props.patientData.DepressiveAdditionalNotes && (
          <Container bsPrefix="psych">
            <Form.Row className="patient-dark">Additional Notes:</Form.Row>
            <Row bsPrefix="psych-sub">
              {props.patientData.DepressiveAdditionalNotes}
            </Row>
          </Container>
        )}
      </Form.Group>
    </Form.Group>
  )
}
