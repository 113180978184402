// Import react
import React, { useState } from 'react'

// Import components
import { Accordion, Card, Col, Container, Row, Table } from 'react-bootstrap'

import EditMedication from '../../components/Forms/EditMedication'
import RefillMedication from '../../components/Forms/RefillMedication'
import RetireAndMigrateToPastMedication from '../../components/Forms/RetireAndMigrateToPastMedication'

const ActiveMedsAccordion = (props) => {
  const [administrationIntervalString] = useState(
    props.medicine.AdministrationIntervalInDays
      ? props.medicine.AdministrationIntervalInDays === '1'
        ? 'Every day'
        : 'Every ' + props.medicine.AdministrationIntervalInDays + ' days'
      : ''
  )

  return (
    <Container style={{ padding: 0 }}>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={props.index.toString()}
        id="header"
        bsPrefix="patient-card"
      >
        <Container fluid>
          <Row>
            <Col sm={6} lg={12}>
              <Row>
                <Col sm={12} md={12} lg={2}>
                  <span className="emphasis info larger">
                    {props.medicine.MedicationName}
                  </span>
                </Col>
                <Col sm={12} md={6} lg={2}>
                  {props.medicine.Dosage || props.medicine.Administration ? (
                    <span>
                      <i className="fa fa-check-circle-o" aria-hidden="true" />
                      &nbsp;
                    </span>
                  ) : (
                    ''
                  )}
                  {props.medicine.Dosage ? (
                    <span className="emphasis-light larger">
                      {props.medicine.Dosage}
                      &nbsp;
                    </span>
                  ) : (
                    ''
                  )}
                  {!props.medicine.Administration ? (
                    ''
                  ) : props.medicine.Administration === 'Other (specify)' ? (
                    <span>
                      by&nbsp;
                      <span className="emphasis-light larger">
                        {props.medicine.AdministrationOther}
                      </span>
                    </span>
                  ) : (
                    <span>
                      by&nbsp;
                      <span className="emphasis-light larger">
                        {props.medicine.Administration}
                      </span>
                    </span>
                  )}
                </Col>
                <Col sm={12} md={6} lg={2} className="emphasis-light larger">
                  <span>
                    {props.medicine.Frecuency ? props.medicine.Frecuency : ''}
                  </span>
                </Col>
                <Col sm={12} md={12} lg={4}>
                  <span className="emphasis info larger">
                    Use medication adherence module:{' '}
                    {!props.medicine.DisallowMedicationAdherence ? 'Yes' : 'No'}
                  </span>
                </Col>
                <Col sm={12} md={12} lg={2}>
                  <span className="emphasis info larger">
                    Prescribed by you:{' '}
                    {props.medicine.PrescribedByProvider ? 'Yes' : 'No'}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={props.index.toString()}>
        <div>
          <Table hover bsPrefix="rounded" responsive>
            <tbody>
              <tr className="very-large">
                <td
                  style={{ width: '28vw' }}
                  className="patient emphasis-light"
                >
                  Medication:
                </td>
                <td className="patient emphasis-light">
                  {props.medicine.MedicationName} &nbsp;({' '}
                  {props.medicine.Administration !== 'Other (specify)'
                    ? props.medicine.Administration
                    : props.medicine.AdministrationOther}{' '}
                  )
                </td>
              </tr>

              <tr className="larger">
                <td className="patient emphasis-light">Dosage:</td>
                <td className="patient emphasis-light">
                  {props.medicine.Dosage} - {administrationIntervalString}
                </td>
              </tr>
              <tr>
                <td className="emphasis-light">Start date:</td>
                <td>
                  {props.medicine.ProviderStartDate
                    ? props.medicine.ProviderStartDate
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="emphasis-light">Frequency:</td>
                <td>{props.medicine.Frecuency}</td>
              </tr>

              <tr>
                <td className="emphasis-light">Reminder start date:</td>
                <td>{props.medicine.StartDate}</td>
              </tr>
              <tr>
                <td className="emphasis-light">Last administration date:</td>
                <td>{props.medicine.LastAdministrationDate}</td>
              </tr>
              <tr>
                <td className="emphasis-light">
                  Last Patient Estimate of Doses Remaining:
                </td>
                <td>{props.medicine.LastPatientEstimateOfDosesRemaining}</td>
              </tr>
              <tr>
                <td className="emphasis-light">Expected Doses Remaining:</td>
                <td>{props.medicine.DosesRemainingEstimate}</td>
              </tr>
              <tr>
                <td className="emphasis-light">
                  Total Administration Reminder Responses to Date:
                </td>
                <td>{props.medicine.AdministrationResponsesToDate}</td>
              </tr>
              <tr>
                <td className="emphasis-light">Prescribed by you:</td>
                <td>{props.medicine.PrescribedByProvider ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td className="emphasis-light">Side Effects:</td>
                <td>{props.medicine.SideEffects}</td>
              </tr>
              <tr>
                <td className="emphasis-light">
                  Is this medication for mental health or psychiatric
                  conditions:
                </td>
                <td>
                  {props.medicine.MentalPsychiatricConditions ? 'Yes' : 'No'}
                </td>
              </tr>
              {!props.medicine.MentalPsychiatricConditions ? (
                ''
              ) : (
                <tr>
                  <td className="emphasis-light">Reason for use:</td>
                  <td>{props.medicine.ReasonForUse}</td>
                </tr>
              )}
              {!props.medicine.MentalPsychiatricConditions ? (
                ''
              ) : (
                <tr>
                  <td className="emphasis-light">
                    Patient perspective of efficacy:
                  </td>
                  <td>{props.medicine.PatientPerspectiveEfficacy}</td>
                </tr>
              )}
              <tr>
                <td className="emphasis-light">Additional Instructions:</td>
                <td>{props.medicine.AdditionalInstructions}</td>
              </tr>
            </tbody>
          </Table>
          <Col
            sm={6}
            lg={4}
            style={{ marginLeft: 'auto', marginBottom: '20px' }}
          >
            <Row>
              <Col>
                <RefillMedication
                  medicine={props.medicine}
                  handleApiChange={props.handleApiChange}
                />
              </Col>
              <Col>
                <RetireAndMigrateToPastMedication
                  medicine={props.medicine}
                  handleApiChange={props.handleApiChange}
                />
              </Col>
              <Col>
                <EditMedication
                  medicine={props.medicine}
                  medicationType
                  handleApiChange={props.handleApiChange}
                />
              </Col>
            </Row>
          </Col>
        </div>
      </Accordion.Collapse>
    </Container>
  )
}

export default ActiveMedsAccordion
