import { Teammate } from '../../../shared-types'

export function generateProviderOptions(allTeam: Teammate[]) {
  if (allTeam.length === 0) {
    return []
  }
  const activeTeam = allTeam.filter((user: Teammate) => !user.isDeactivated)
  // Normalize the active providers list for react-select-search
  return activeTeam.map((p: Teammate) => {
    return {
      name: p.name
        ? p.credential
          ? p.credential + ' ' + p.name
          : p.name
        : p.email,
      label: p.name
        ? p.credential
          ? p.credential + ' ' + p.name
          : p.name
        : p.email,
      value: p.cognitoId,
    }
  })
}
