import React from 'react'

import moment from 'moment'
import { Accordion, Badge, Card, Col, Container, Row } from 'react-bootstrap'

import { EditDiagnosisButton } from '../../components/Buttons/EditDiagnosisButton'
import InfoTooltip from '../../components/Other/InfoTooltip'

function compareDiagnosisDates(a, b) {
  return moment(b.DiagnosisDate).valueOf() - moment(a.DiagnosisDate).valueOf()
}

export default function DiagnosesList(props) {
  const diagnosisOrderedByDate = props.diagnoses.sort(compareDiagnosisDates)

  function DiagnosesToDisplay(props) {
    const diagnosis = props.diagnosis
    return (
      <Container data-testid={`${props.status}-diagnosis-line-${props.index}`}>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={props.index.toString()}
          id="header"
          bsPrefix="patient-card"
        >
          <Container>
            <Row>
              {diagnosis.DiagnosisDate && (
                <Col
                  bsPrefix="diagnosis-date"
                  key={`Date${diagnosis.DiagnosisId}`}
                >
                  {moment(new Date(diagnosis.DiagnosisDate)).format('LL')}
                </Col>
              )}
              {diagnosis.DiagnosisCode && (
                <Col bsPrefix="diagnosis" key={`Code${diagnosis.DiagnosisId}`}>
                  {diagnosis.DiagnosisCode}
                </Col>
              )}
              <Col
                bsPrefix="diagnosis"
                key={`Diagnosis${diagnosis.DiagnosisId}`}
              >
                {diagnosis.Diagnosis}
                {props.editMedHistory ? (
                  <EditDiagnosisButton
                    status={props.status}
                    diagnosis={diagnosis}
                    handlePatientDataChange={props.handlePatientDataChange}
                    handleApiChange={props.handleApiChange}
                    patient={props.patient}
                  />
                ) : (
                  <></>
                )}
              </Col>

              {props.patient.PatientRCopiaId && !diagnosis.IsUploadedToRCopia && (
                <Col
                  bsPrefix="min-col"
                  key={`Uploaded${diagnosis.DiagnosisId}`}
                >
                  <InfoTooltip
                    label={
                      <Badge className="diagnosis-danger" pill>
                        !
                      </Badge>
                    }
                    style={{ fontSize: 15 }}
                    information="This diagnosis isn't currently synced within the eRx module. Please update with a valid ICD-10 code to resolve."
                  />
                </Col>
              )}
              {props.patient.PatientRCopiaId &&
                diagnosis.IsUpdatedInRCopia === false && (
                  <Col
                    bsPrefix="min-col"
                    key={`Updated${diagnosis.DiagnosisId}`}
                  >
                    <InfoTooltip
                      label={
                        <Badge className="diagnosis-warning" pill>
                          !
                        </Badge>
                      }
                      style={{ fontSize: 15 }}
                      information="This diagnosis may have discrepancies with what's shown in the eRx module, please try updating the diagnosis to resolve."
                    />
                  </Col>
                )}
            </Row>
          </Container>
        </Accordion.Toggle>

        <Accordion.Collapse
          style={{ marginTop: '6px', marginBottom: '8px' }}
          eventKey={props.index.toString()}
        >
          <Container>
            {diagnosis.DiagnosisStatus || diagnosis.DiagnosisOnsetDate ? (
              <Row as={Col}>
                {diagnosis.DiagnosisStatus && (
                  <Col
                    bsPrefix="diagnosis-sub"
                    key={`Status${diagnosis.DiagnosisId}`}
                  >
                    <span className="subtopic">Status: </span>
                    {diagnosis.DiagnosisStatus}
                  </Col>
                )}
                {diagnosis.DiagnosisOnsetDate && (
                  <Col
                    bsPrefix="diagnosis-sub"
                    key={`OnsetDate${diagnosis.DiagnosisId}`}
                  >
                    <span className="subtopic">Onset Date: </span>
                    {moment(new Date(diagnosis.DiagnosisOnsetDate)).format(
                      diagnosis.DiagnosisOnsetDateFormat
                    )}
                  </Col>
                )}
              </Row>
            ) : (
              <></>
            )}

            {diagnosis.DiagnosisNoteContent ? (
              <Row as={Col}>
                <Col
                  bsPrefix="diagnosis-sub"
                  key={`NoteContent${diagnosis.DiagnosisId}`}
                >
                  <span className="subtopic">Notes: </span>
                  {diagnosis.DiagnosisNoteContent}
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Container>
        </Accordion.Collapse>
      </Container>
    )
  }

  return diagnosisOrderedByDate ? (
    diagnosisOrderedByDate.map((diagnosis, index) => {
      return (
        <Accordion className="table-container" key={index}>
          <DiagnosesToDisplay
            status={props.status}
            type="active"
            index={index}
            editMedHistory={props.editMedHistory}
            handleApiChange={props.handleApiChange}
            handlePatientDataChange={props.handlePatientDataChange}
            diagnosis={diagnosis}
            patient={props.patient}
          />
        </Accordion>
      )
    })
  ) : (
    <div>No diagnoses</div>
  )
}
