import { differenceInMinutes, set } from 'date-fns'
import { View, Views, ViewsProps } from 'react-big-calendar'

import MonthNoWeekendView from '../CalendarViews/MontNoWeekendView'
import { WeekView, WorkWeekView } from '../CalendarViews/WeekView'
import { Views as AvailableCalendarViews, Timezone } from '../constants'

const mapCalendarViewToAvailableCalendarViews = {
  [AvailableCalendarViews.DAY]: Views.DAY,
  [AvailableCalendarViews.WEEK]: Views.WEEK,
  [AvailableCalendarViews.MONTH]: Views.MONTH,
  [AvailableCalendarViews.AGENDA]: Views.AGENDA,
}

export const getCurrentViewInformation = (
  view: AvailableCalendarViews,
  showWeekends?: boolean
) => {
  const className = view.toString()

  const availableReactBigCalendarViews: ViewsProps = {
    day: true,
    week: showWeekends ? WeekView : WorkWeekView,
    month: showWeekends ? true : MonthNoWeekendView,
    agenda: true,
  }

  const reactBigCalendarView: View =
    mapCalendarViewToAvailableCalendarViews[view]

  return {
    className,
    reactBigCalendarView,
    availableReactBigCalendarViews,
  }
}

export const getMinMaxTimeOfDay = (
  currentTimezone: Timezone,
  isDev?: boolean
) => {
  if (isDev) {
    return {
      minTime: undefined,
      maxTime: undefined,
    }
  }
  // For example, if the selected calendar timezone is CST and the OS timezone is EST, there is 1 hour difference.
  // So when setting min/max to the calendar, min/max should be 01:00:00.000/24:59:59.999 EST which are being calculated as Math.floor(differenceInMinutes(todayDate, timezoneDate) / 60).
  // And the reason why Math.floor is being used is the result of differenceInMinutes(todayDate, timezoneDate) is 59, not 60 in the above example.
  // This is because of the difference in milliseconds of todayDate and timezoneDate. So Math.floor should be used to get 60 instead of 59.
  const todayDate = new Date()
  const calendarTimezoneDate = new Date().toLocaleString('en-US', {
    timeZone: currentTimezone,
  })
  const timezoneDate = new Date(calendarTimezoneDate)
  return {
    minTime: set(new Date(), {
      hours: Math.floor(differenceInMinutes(todayDate, timezoneDate) / 60),
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    }),
    maxTime: set(new Date(), {
      hours: 23 + Math.floor(differenceInMinutes(todayDate, timezoneDate) / 60),
      minutes: 59,
      seconds: 59,
      milliseconds: 999,
    }),
  }
}
