import { useEffect, useState } from 'react'

import { compare as compareVersions } from 'compare-versions'

type UseAppVersionCheckParams = {
  /**
   * Flag for forcibily disabling any reloads.
   */
  versionReloadEnabled: boolean
  /**
   * The 'latest' version of the web app that's available.
   * This can be set via Launch Darkly flag, which creates a broadcast to our websocket
   * in order to ensure that clients always have the newest version of the app.
   */
  latestAppVersion: string
  /**
   * The current version of the web app.
   * This is the version within our globalConfig.json that increases after each release.
   */
  currentAppVersion: string
}

export const useAppVersionCheck = ({
  versionReloadEnabled,
  latestAppVersion,
  currentAppVersion,
}: UseAppVersionCheckParams): { versionChanged: boolean } => {
  const [versionChanged, setVersionChanged] = useState(false)

  // Log the app version on startup for debugging and troubleshooting.
  useEffect(() => {
    console.log('Current app version:', currentAppVersion)
  }, [currentAppVersion])

  useEffect(() => {
    try {
      if (
        versionReloadEnabled &&
        currentAppVersion !== '' &&
        latestAppVersion !== '' &&
        compareVersions(latestAppVersion, currentAppVersion, '>')
      ) {
        console.log(
          `App version change detected. current: ${currentAppVersion}, latest: ${latestAppVersion}`
        )
        setVersionChanged(true)
      }
    } catch (e) {
      console.error('There was an error comparing the versions', e)
    }
  }, [versionReloadEnabled, latestAppVersion, currentAppVersion])

  return {
    versionChanged,
  }
}
