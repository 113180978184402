import { Ref, useEffect, useRef, useState } from 'react'

import { SizeType } from 'antd/lib/config-provider/SizeContext'
// importing from antd bc otherwise refs won't work with the component (for now)
import Input, { InputProps } from 'antd/lib/input/Input'

import InlineEditBase, { focusRef } from './InlineEditBase'
import {
  ActiveComponentProps,
  BaseInterface,
  OnSave,
  Validator,
} from './shared-types'

import styles from './_shared.module.scss'

interface Props extends BaseInterface, InputProps {
  onSave?: OnSave<string | number>
  size?: SizeType
  value?: string | number
  validators?: Validator<string | number>[]
  isWrongValue?: boolean
  displayValue?: string
}
/**
  Editable inline Text
*/
const InlineText = ({
  placeholder,
  requireConfirmation = false,
  size,
  testId,
  value: initialValue,
  // inline edit base props below
  className,
  customActions,
  customDefault,
  id,
  noEmpty,
  onSave,
  padded,
  placeholderElement,
  validators,
  validateOnChange,
  scrollToField,
  autoFocus,
  disabled = false,
  isWrongValue,
  displayValue,
  // text input props below
  ...props
}: Props) => {
  const inlineEditBaseProps = {
    className,
    customActions,
    customDefault,
    id,
    noEmpty,
    onSave,
    padded,
    placeholderElement,
    validators,
    validateOnChange,
    scrollToField,
    isWrongValue,
    displayValue,
  }
  const textRef: Ref<any> = useRef(null)
  const textContainerRef: Ref<HTMLDivElement> = useRef(null)
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRendered(true)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const ActiveText = ({
    handleChange,
    hasError = false,
    toggleCancel,
    toggleFocus,
    toggleSave,
    value,
    hasValueChange,
  }: ActiveComponentProps) => {
    const isValidType = typeof value === 'string' || typeof value === 'number'

    return (
      <Input
        {...props}
        className={`${styles.activeText} ${!value && styles.noValue}`}
        defaultValue={initialValue}
        maxLength={500}
        onChange={handleChange}
        onFocus={toggleFocus}
        onKeyDown={(event) => event.key === 'Escape' && toggleCancel()}
        onPressEnter={() => (hasValueChange ? toggleSave() : toggleCancel())}
        placeholder={placeholder}
        ref={textRef}
        status={hasError ? 'error' : undefined}
        size={size}
        style={{ resize: 'vertical' }}
        data-testid={testId ? `text-${testId}` : undefined}
        value={isValidType && value ? value : undefined}
        disabled={disabled}
      />
    )
  }

  return (
    <div className={styles.refContainer} ref={textContainerRef}>
      <InlineEditBase
        {...inlineEditBaseProps}
        activeComponent={ActiveText}
        testId={testId}
        toggleActive={autoFocus}
        size={size}
        onActive={() =>
          focusRef(autoFocus, textContainerRef, textRef, rendered)
        }
        value={initialValue}
        placeholder={placeholder}
        showActions={requireConfirmation}
        disabled={disabled}
      />
    </div>
  )
}

export default InlineText
