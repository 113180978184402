import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

const asianSubRaceCDC: PatientDemographicObject[] = [
  { '2029-7': { title: 'Asian Indian' } },
  { '2030-5': { title: 'Bangladeshi' } },
  { '2031-3': { title: 'Bhutanese' } },
  { '2032-1': { title: 'Burmese' } },
  { '2033-9': { title: 'Cambodian' } },
  { '2034-7': { title: 'Chinese' } },
  { '2035-4': { title: 'Taiwanese' } },
  { '2036-2': { title: 'Filipino' } },
  { '2037-0': { title: 'Hmong' } },
  { '2038-8': { title: 'Indonesian' } },
  { '2039-6': { title: 'Japanese' } },
  { '2040-4': { title: 'Korean' } },
  { '2041-2': { title: 'Laotian' } },
  { '2042-0': { title: 'Malaysian' } },
  { '2043-8': { title: 'Okinawan' } },
  { '2044-6': { title: 'Pakistani' } },
  { '2045-3': { title: 'Sri Lankan' } },
  { '2046-1': { title: 'Thai' } },
  { '2047-9': { title: 'Vietnamese' } },
  { '2048-7': { title: 'Iwo Jiman' } },
  { '2049-5': { title: 'Maldivian' } },
  { '2050-3': { title: 'Nepalese' } },
  { '2051-1': { title: 'Singaporean' } },
  { '2052-9': { title: 'Madagascar' } },
]

export const asianSubRaceDropDownValues = constructPatientDemographicObject(
  asianSubRaceCDC,
  CodeSystemOIDs.PHVSRaceCategoryCDCRef,
  Categories.ASIAN_SUB_RACE
)
