import { Text } from '../../../../../../stories/BaseComponents'

import styles from './NoteV1HeaderText.module.scss'

export const NoteV1HeaderText = () => {
  return (
    <div>
      <Text className={styles.headerText}>Notes v1</Text>
    </div>
  )
}
