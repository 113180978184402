import { differenceInYears } from 'date-fns'

import { parseDateOrNull } from '../../../../libs/utils'
import { StoredFields } from '../types'

export function calculateBMI(props: StoredFields) {
  const { height = 0, weight = 0, heightUnit = 'in', weightUnit = 'lb' } = props

  if (!height || !weight) {
    return 0
  }

  const isHeightMetric = heightUnit === 'cm'
  let newHeight = height
  if (isHeightMetric) {
    newHeight = height / 2.54
  }

  const isWeightMetric = weightUnit === 'kg'
  let newWeight = weight
  if (isWeightMetric) {
    newWeight = weight * 2.20462262
  }

  const bmi = (703 * newWeight) / newHeight / newHeight

  return parseFloat(bmi.toFixed(2))
}

export function getFeetAndInches(inches: number) {
  const feet = Math.floor(inches / 12).toString()
  const remainingInches = inches % 12
  return `${feet}' ${remainingInches}"`
}

export function calculateAge(date: Date | null | undefined) {
  const parsed = parseDateOrNull(date)
  return parsed ? differenceInYears(new Date(), parsed) : ''
}
