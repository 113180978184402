import { KeyboardEvent } from 'react'

import {
  DatePicker,
  DatePickerProps,
  Form,
  FormInstance,
  FormItemProps,
} from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import moment from 'moment'

import { TestId } from '../../../shared-types'
import {
  dateRangeValidator,
  required as requiredRule,
} from '../../PatientIntake/validation-rules'

const VALID_SEPARATORS = new Set(['-', '/'])

/** Allow hyphen or slash as separator. */
const ALLOWED_FORMATS = ['MM-DD-YYYY', 'MM/DD/YYYY', 'MMDDYYYY']
const MMDDYYYY = ALLOWED_FORMATS[0]

const INVALID_DATE_ERROR = 'Enter a valid date.'

const nonDigits = /[^\d]/g
const nonDigitsOrHyphens = /[^0-9-]/g

/** Inserts hyphens in correct position for mm-dd-yyyy date string */
const formatDateInput = (value: string) => {
  const digits = value.replace(nonDigits, '')
  if (digits.length >= 4) {
    return `${digits.slice(0, 2)}-${digits.slice(2, 4)}-${digits.slice(4)}`
  } else if (digits.length >= 2) {
    return `${digits.slice(0, 2)}-${digits.slice(2)}`
  }
  return digits
}

/*
 * Assume if key length is 1, that it's a printable character unlike
 * backspace, which has key `backspace`. `metaKey` is true for things like
 * `cmd + v`.
 */
const isPrintableKey = (event: KeyboardEvent) =>
  !event.metaKey && !event.ctrlKey && event.key.length === 1

/** Strips characters other than digits and hyphens */
const cleanDateInput = (value: string) => value.replace(nonDigitsOrHyphens, '')

/**
 * Formats `value` based on which key was pressed in keydown event. Invalid
 * characters are ignored, hyphens auto inserted, padding auto added, etc.
 *
 * @param value the current input value
 * @param event the keyboard keydown event
 * @returns the new formatted input value
 */
export const autoFormat = (
  value = '',
  event: KeyboardEvent
): string | undefined => {
  switch (true) {
    // Max length, no more chars allowed
    case value.length === MMDDYYYY.length && isPrintableKey(event):
      event.preventDefault()
      return formatDateInput(value) // Guard against bad chars

    /** A number was pressed. */
    case !Number.isNaN(Number(event.key)):
      event.preventDefault() // we're adding manually
      return formatDateInput(value + event.key)

    /**
     * Allow user to type separator and auto-pad input for convenience.
     */
    case VALID_SEPARATORS.has(event.key):
      event.preventDefault() // we're adding manually
      if (value.length === 1) {
        // auto pad day
        return formatDateInput(value.padStart(2, '0'))
      } else if (value.length === 4) {
        // auto pad month
        return formatDateInput(`${value.slice(0, 3)}0${value.slice(3)}}`)
      }
      return formatDateInput(value) // Guard against bad chars

    /*
     * Assume if key length is 1, that it's a printable character unlike
     * backspace, which has key `backspace`.
     */
    case isPrintableKey(event):
      event.preventDefault() // Invalid chars. Don't allow
      return formatDateInput(value) // Guard against bad chars

    default:
      // Can't do regular format here or we could put back deleted hyphen, etc
      return cleanDateInput(value)
  }
}

export type DateInputProps = TestId & {
  form: FormInstance
  className?: string
  label: string
  name: NamePath
  disabled?: boolean
  required?: boolean
  allowFuture?: boolean
  showToday?: boolean
}

const DateInput = ({
  form,
  className,
  label,
  name,
  testId,
  required,
  disabled,
  allowFuture = false,
  showToday = false,
}: DateInputProps) => {
  const now = moment()
  const min = now.clone().subtract(120, 'years')

  // If future is allowed, no max, else today or yesterday based on showToday
  const max = allowFuture ? undefined : now.clone()
  if (!showToday) max?.subtract(1, 'day')

  // Don't allow date selection for dates outside range
  const isDateInRange = dateRangeValidator(min, max)
  const disabledDateChecker: DatePickerProps['disabledDate'] = (date) =>
    !isDateInRange(date)

  const rules: FormItemProps['rules'] = required
    ? [requiredRule(`Please input ${label}.`)]
    : []

  /*
   * This is a hack to get around limitations of antd `DatePicker`. What we'd
   * want to do instead of this is something like what `PhoneNumberInput` does
   * with `AutoFormatInput`, but antd `DatePicker` doesn't expose the input how
   * we would need, and also doesn't fire `onChange` when the user types.
   *
   * Because of this, we have to resort to `keyDown` listening. Even `keyUp`
   * would be better, but that's also not exposed by antd, so we have to hook
   * into `keyDown`. That means that in order to not show invalid characters,
   * we need to control append and disable default event propagation in some
   * cases.
   */
  const onKeyDown: DatePickerProps['onKeyDown'] = (event) => {
    // target is HTMLElement so cast to input
    const target = event.target as HTMLInputElement
    const formatted = autoFormat(target.value, event)
    if (formatted && target.value !== formatted) {
      target.value = formatted
    }

    // If complete date, try to set the field
    if (formatted?.length === MMDDYYYY.length) {
      const dt = moment(formatted, MMDDYYYY)
      const errors = []
      if (!dt.isValid() || !isDateInRange(dt)) {
        errors.push(INVALID_DATE_ERROR)
      }

      form.setFields([
        {
          name,
          touched: true,
          value: dt.isValid() && isDateInRange(dt) ? dt : undefined,
          errors,
        },
      ])
    }
  }

  return (
    <Form.Item data-testid={testId} label={label} name={name} rules={rules}>
      <DatePicker
        className={className}
        disabledDate={disabledDateChecker}
        format={ALLOWED_FORMATS}
        placeholder={MMDDYYYY}
        size="large"
        showToday={showToday}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
    </Form.Item>
  )
}

export default DateInput
