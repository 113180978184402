import { RangePickerProps } from 'antd/lib/date-picker'
import { Rule } from 'antd/lib/form'
import moment, { Moment } from 'moment'

export const CHARACTER_LIMIT = 2000

export const required = (message: string): Rule => ({
  required: true,
  message,
})

export const requiredString = (message: string): Rule => ({
  required: true,
  type: 'string',
  whitespace: true,
  message,
})

export const optionalFreeText = (message: string): Rule => ({
  required: false,
  type: 'string',
  message,
})

export const numberRange = (
  message: string,
  min?: number,
  max?: number
): Rule => ({
  min: min,
  max: max,
  type: 'integer',
  message,
})

export const numberDecimalRange = (
  message: string,
  min?: number,
  max?: number
): Rule => ({
  min: min,
  max: max,
  type: 'number',
  message,
})

export const email = (message: string): Rule => ({
  type: 'email',
  message,
})

/** @returns validator that checks date is in range (inclusive) */
export const dateRangeValidator = (min?: Moment, max?: Moment) => {
  return (dt: Moment): boolean => {
    return (!min || dt.isSameOrAfter(min)) && (!max || dt.isSameOrBefore(max))
  }
}

export const disabledDate: RangePickerProps['disabledDate'] = (
  current: Moment
): boolean => {
  // Can not select days after today or before 100 years ago
  return (
    current.isBefore(moment().subtract(100, 'year')) ||
    current.isAfter(moment())
  )
}

export const normalizeText = (value: string) => {
  return value?.trim().length === 0 ? value?.trim() : value
}
