import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { loadPatientMedications } from '../../api/patients'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'
import { PatientMedications } from '../../shared-types'

export const usePatientMedications = (
  patientId: string
): UseQueryResult<PatientMedications> => {
  return useQuery<PatientMedications>(
    [patientDataTypes.PatientMedications, patientId],
    {
      queryFn: async () => {
        const data = await loadPatientMedications(patientId)
        return data
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
