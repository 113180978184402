import { API } from 'aws-amplify'

import { nonNumericalRegex } from '../libs/regex'

export interface VerifiedPhoneInfo {
  valid: boolean
  body: string
}

type verifyPhoneNumber = (phoneNumber: string) => Promise<VerifiedPhoneInfo>

export const verifyPhoneNumber: verifyPhoneNumber = async (phoneNumber) => {
  const phoneNumberToTest = phoneNumber.replace(nonNumericalRegex, ``)

  if (phoneNumberToTest.length < 10) {
    return { valid: false, body: 'phoneNumber too Short' }
  }

  return await API.get(
    'core-api',
    `/verify-phone-number/${phoneNumberToTest}`,
    {}
  )
}
