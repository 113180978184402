import { FunctionComponent } from 'react'

import {
  TabPaneProps as AntdTabPaneProps,
  Tabs as AntdTabs,
  TabsProps as AntdTabsProps,
} from 'antd'

type TabPaneProps = {
  tab: AntdTabPaneProps['tab']
  key: string
  // INFO: Ideally, only the three props above should be
  // needed for prop definition of this component, however
  // antd cannot render custom TabPane children without
  // a prop spread: https://github.com/ant-design/ant-design/issues/5165#issuecomment-284679804
} & Omit<AntdTabPaneProps, 'tab' | 'key'>

type TabPaneComponent = FunctionComponent<TabPaneProps>

export const TabPane: TabPaneComponent = ({
  tab,
  children,
  key,
  ...restProps
}: TabPaneProps) => {
  return (
    <AntdTabs.TabPane tab={tab} key={key} {...restProps}>
      {children}
    </AntdTabs.TabPane>
  )
}

type TabsProps = React.PropsWithChildren & {
  tabBarExtraContent?: AntdTabsProps['tabBarExtraContent']
  color?: AntdTabsProps['color']
  defaultActiveKey?: AntdTabsProps['defaultActiveKey']
  onChange?: (key: string) => void
}

type TabsComponent = FunctionComponent<TabsProps> & {
  TabPane: TabPaneComponent
}

export const Tabs: TabsComponent = ({
  tabBarExtraContent,
  children,
  color,
  defaultActiveKey,
  onChange,
}: TabsProps) => {
  return (
    <AntdTabs
      color={color}
      tabBarExtraContent={tabBarExtraContent}
      defaultActiveKey={defaultActiveKey}
      onChange={onChange}
    >
      {children}
    </AntdTabs>
  )
}

Tabs.TabPane = TabPane
