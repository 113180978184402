import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const ChronicPainCodes: any = [
  {
    82423001: {
      title: 'Chronic Pain',
      description: 'Chronic pain (finding)',
      snomedcode: 82423001,
      icdcode: 'G894',
      icddescription: 'Chronic pain syndrome',
    },
  },
  {
    '134407002': {
      title: 'cp.Chronic Back Pain',
      description: 'Chronic back pain (finding)',
      snomedcode: 134407002,
      icdcode: 'M549',
      icddescription: 'Dorsalgia, unspecified',
    },
  },
  {
    '1121000119107': {
      title: 'cp.Chronic Neck Pain',
      description: 'Chronic neck pain (finding)',
      snomedcode: 1121000119107,
      icdcode: 'M542',
      icddescription: 'Cervicalgia',
    },
  },
  {
    '128200000': {
      title: 'cp.Complex Regional Pain Syndrome (CRPS)',
      description: 'Complex regional pain syndrome (disorder)',
      snomedcode: 128200000,
      icdcode: 'R69',
      icddescription: 'Illness, unspecified',
    },
  },
  {
    '203082005': {
      title: 'cp.Fibromyalgia',
      description: 'Fibromyalgia (disorder)',
      snomedcode: 203082005,
      icdcode: 'M797',
      icddescription: 'Fibromyalgia',
    },
  },
  {
    '247398009': {
      title: 'cp.Neuropathic Pain',
      description: 'Neuropathic pain (finding)',
      snomedcode: 247398009,
      icdcode: 'M792',
      icddescription: 'Neuralgia and Neuritis, unspecified',
    },
  },
  {
    '5771000119106': {
      title: 'cp.Phantom Limb Pain',
      description: 'Phantom limb syndrome with pain (disorder)',
      snomedcode: 5771000119106,
      icdcode: 'G546',
      icddescription: 'Phantom limb syndrome with pain',
    },
  },
  {
    '2177002': {
      title: 'cp.Postherpetic Neuralgia (Shingles)',
      description: 'Postherpetic neuralgia (disorder)',
      snomedcode: 2177002,
      icdcode: 'B0229',
      icddescription: 'Other postherpetic nervous system involvement',
    },
  },
  {
    '734947007': {
      title: 'cp.Reflex Sympathetic Dystrophy Syndrome (RSDS)',
      description: 'Complex regional pain syndrome type I (disorder)',
      snomedcode: 734947007,
      icdcode: 'G905',
      icddescription: 'Complex regional pain syndrome I',
    },
  },
  {
    '31681005': {
      title: 'cp.Trigeminal Neuralgia',
      description: 'Trigeminal neuralgia (disorder)',
      snomedcode: 31681005,
      icdcode: 'G500',
      icddescription: 'Trigeminal neuralgia',
    },
  },
]

export const ChronicPainDropDownValues = constructPatientDemographicObject(
  ChronicPainCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
