import React, { useState } from 'react'

import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { CardProps as AntDCardProps } from 'antd'

import { TestId } from '../../shared-types'
import { Card, HoverIcon, Text } from './index'

import styles from './_shared.module.scss'

type CollapsibleCardProps = TestId &
  AntDCardProps & {
    iconTestId?: string
    _testId?: string
    isDefaultOpened?: boolean
  }

export const CollapsibleCard = ({
  _testId,
  style = {},
  className = '',
  testId,
  title,
  children,
  iconTestId,
  isDefaultOpened = false,
  ...rest
}: CollapsibleCardProps) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpened)
  const collapseIcon = isOpen ? <DownOutlined /> : <RightOutlined />
  return (
    <Card data-testid={testId} className={className} style={style} {...rest}>
      <div className={styles.spaceBetween}>
        <Text header="h5">{title}</Text>
        <HoverIcon
          testId={iconTestId}
          icon={collapseIcon}
          onClick={() => setIsOpen((isOpen) => !isOpen)}
        />
      </div>
      {isOpen && <div style={{ paddingTop: '16px' }}>{children}</div>}
    </Card>
  )
}
