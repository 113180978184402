export enum ViewportEvents {
  VIEWPORT_INITIALIZED = 'Viewport Initialized', // Event when the viewport is initialized
  VIEWPORT_RESIZED = 'Viewport Resized', // Event when the viewport is resized
}

type ViewportEventProperties = {
  viewportWidth: number
  viewportHeight: number
}

export function trackViewportEvent(
  eventName: ViewportEvents,
  eventDetails: ViewportEventProperties
) {
  window?.freshpaint?.track(eventName, eventDetails)
}
