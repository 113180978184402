import React from 'react'

import cx from 'classnames'

import { Input, Skeleton } from '../../../../stories/BaseComponents'
import { TITLE_PLACEHOLDER } from '../../constants'
import { Note } from '../../types'

import styles from './Title.module.scss'

export type TitleProps = {
  noteTitle?: string
  onChange: (val: Partial<Note>) => void
  isLoading: boolean
  isSigned: boolean
}

export const Title = ({
  noteTitle,
  onChange,
  isLoading,
  isSigned,
}: TitleProps) => {
  return (
    <div className={cx(styles.titleWrapper, 'hide-on-print')}>
      {isLoading ? (
        <Skeleton
          title={{
            className: cx(styles.skeletonTitle, styles.skeletonBigTitle),
          }}
          paragraph={{
            rows: 0,
            className: styles.skeletonParagraph,
          }}
        />
      ) : isSigned ? (
        <div data-testid="note-title" className={styles.titleInputReadonly}>
          {noteTitle || TITLE_PLACEHOLDER}
        </div>
      ) : (
        <Input
          className={styles.titleInput}
          bordered={false}
          placeholder={TITLE_PLACEHOLDER}
          value={noteTitle}
          testId="note-title-input"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ title: event?.currentTarget?.value })
          }
        />
      )}
    </div>
  )
}
