import { API } from 'aws-amplify'

export async function getRCopiaNotificationsSSOUrl(landingPage: string) {
  const data: string = await API.get('erx', '/notificationsSSO', {
    queryStringParameters: {
      landingPage,
    },
  })
  return data
}
