import { useRef } from 'react'

import PatientSettings from '../Patient/PatientSettings'

import './SurveySettings.scss'

export default function SurveySettings({ surveyData, defaultSurveySettings }) {
  const showModalRef = useRef({
    showModal: null,
  })

  return (
    <>
      <PatientSettings
        surveyData={surveyData}
        defaultSurveySettings={defaultSurveySettings}
        showModalRef={showModalRef}
        isClinic={true}
      />
    </>
  )
}
