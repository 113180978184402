import React from 'react'

import { isBefore } from 'date-fns'
import moment from 'moment'
import 'moment-timezone'
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
import 'react-dates/initialize'

import { AutoSizingTextArea } from '../../../../stories/BaseComponents/TextAreaInput'
import AddVitals from './Sections/AddVitals'
import AssessmentPreparations from './Sections/AssessmentPreparations'
import Discharge from './Sections/Discharge'
import FacilityStaff from './Sections/FacilityStaff'
import ShowEntriesIM from './Tables/ShowEntriesIM'
import ShowSavedAM from './Tables/ShowSavedAM'
import ShowSavedVS from './Tables/ShowSavedVS'

import '../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function IMKetamine(props) {
  const isBeforeNewDoseCalculation = isBefore(
    new Date(props.createdOnDate),
    new Date('2023-04-25')
  )

  function handleShowEntries() {
    if (props.fields.SavedEntries && props.fields.SavedEntries.length !== 0) {
      return (
        <>
          {props.fields.SavedEntries.map((entry, index) => {
            return (
              <ShowEntriesIM
                key={index}
                index={index}
                Time={entry.Time}
                Dose={entry.Dose}
                TotalDose={entry.TotalDose}
                Location={entry.Location}
                Notes={entry.Notes}
                PatientWeight={props.fields.PatientWeight}
                KetamineUnits={props.fields.KetamineUnits}
                saveEditEntries={props.saveEditEntries}
                deleteEntries={props.deleteEntries}
                disabled={props.disabled}
                isBeforeNewDoseCalculation={isBeforeNewDoseCalculation}
              />
            )
          })}
        </>
      )
    }
  }

  function handleShowAdditionalMedication() {
    if (props.fields.SavedAdditionalMedications.length !== 0) {
      return (
        <>
          {props.fields.SavedAdditionalMedications.map((addMed, index) => {
            return (
              <ShowSavedAM
                key={index}
                index={index}
                deleteAM={props.deleteAM}
                AdditionalMedicationName={addMed.AdditionalMedicationName}
                AdditionalMedicationOther={addMed.AdditionalMedicationOther}
                AdditionalMedicationAdministrationRoute={
                  addMed.AdditionalMedicationAdministrationRoute
                }
                AdditionalMedicationDosage={addMed.AdditionalMedicationDosage}
                AdditionalMedicationOtherAdministrationRoute={
                  addMed.AdditionalMedicationOtherAdministrationRoute
                }
                AdditionalMedicationUnits={addMed.AdditionalMedicationUnits}
                AdditionalMedicationUnitsOther={
                  addMed.AdditionalMedicationUnitsOther
                }
                AdditionalMedicationTime={addMed.AdditionalMedicationTime}
                saveEditAdditionalMedication={
                  props.saveEditAdditionalMedication
                }
                disabled={props.disabled}
              />
            )
          })}
        </>
      )
    }
  }

  function handleShowSavedVitalsIMKetamine() {
    if (
      props.fields.SavedVitalSigns &&
      props.fields.SavedVitalSigns.length !== 0
    ) {
      return (
        <>
          {props.fields.SavedVitalSigns.map((vitals, index) => {
            return (
              <ShowSavedVS
                key={index}
                index={index}
                deleteVitals={props.deleteVitals}
                VitalSignsType={vitals.VitalSignsType}
                Time={vitals.Time}
                HR={vitals.HR}
                BP={vitals.BP}
                BpArm={vitals.BpArm}
                RR={vitals.RR}
                SPO2={vitals.SPO2}
                Comments={vitals.Comments}
                EKG={vitals.EKG}
                RASSscore={vitals.RASSscore}
                saveEditVitalSigns={props.saveEditVitalSigns}
                disabled={props.disabled}
                discharge
              />
            )
          })}
        </>
      )
    }
  }

  function saveNewAdditionalMedicationForIMKetamine() {
    props.setAddAdditionalMedication(false)
    const newAditionalMedication = [
      {
        AdditionalMedicationName: '',
        AdditionalMedicationOther: '',
        AdditionalMedicationAdministrationRoute: 'PO',
        AdditionalMedicationOtherAdministrationRoute: '',
        AdditionalMedicationDosage: '',
        AdditionalMedicationUnits: '',
        AdditionalMedicationUnitsOther: '',
        AdditionalMedicationTime: moment().format('HH:mm'),
      },
    ]
    props.handleFieldChange({
      ...props.fields,
      SavedAdditionalMedications:
        props.fields.SavedAdditionalMedications.concat(newAditionalMedication),
    })
  }
  return (
    <div style={{ position: 'relative' }}>
      {/* IM Ketamine Template */}
      <FacilityStaff
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        disabled={props.disabled}
      />
      <br />
      <AssessmentPreparations
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        pregnantStatus={props.pregnantStatus}
        detail={props.detail}
        disabled={props.disabled}
      />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        IM Ketamine
      </div>

      <div
        className="center info"
        style={{ fontWeight: 'bold', paddingTop: '1em', paddingBottom: '1em' }}
      >
        Ketamine Administration
      </div>
      <Table responsive>
        {props.fields.SavedEntries.length !== 0 || !props.detail ? (
          <thead>
            <tr>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '136px',
                }}
              >
                Time
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '150px',
                }}
              >
                {`Dose (${isBeforeNewDoseCalculation ? 'mg' : 'mg/kg'})`}
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '150px',
                }}
              >
                {`Total (${isBeforeNewDoseCalculation ? 'mg/kg' : 'mg'})`}
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '150px',
                }}
              >
                Location
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '300px',
                }}
              >
                Notes
              </th>
              {!props.disabled && (
                <th
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    minWidth: '140px',
                  }}
                >
                  &nbsp;
                </th>
              )}
            </tr>
          </thead>
        ) : (
          ''
        )}
        <tbody>{handleShowEntries()}</tbody>
      </Table>

      {props.disabled ? (
        props.fields.SavedEntries.length === 0 ? (
          ''
        ) : (
          <Row>
            <Form.Group as={Col} xs={6} sm={6} md={4} lg={4} xl={5}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Patient Weight:{' '}
              </Form.Label>
            </Form.Group>
            <Form.Group
              as={Col}
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={5}
              controlId="PatientWeight"
            >
              <Form.Control
                bsPrefix="form-input"
                type="number"
                value={props.fields.PatientWeight}
                disabled={props.disabled}
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    PatientWeight: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} sm={12} md={12} lg={12} xl={12}>
              <Form.Label style={{ fontWeight: 'bold' }}>Units: </Form.Label>
            </Form.Group>
            <Form.Group
              as={Col}
              xs={1}
              sm={1}
              lg={1}
              xl={1}
              controlId="KetamineUnits"
            >
              <Form.Control
                bsPrefix="form-input"
                as="select"
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    KetamineUnits: e.target.value,
                  })
                }
                disabled={props.disabled}
                value={props.fields.KetamineUnits}
              >
                <option key={1} name="lbs" value="lbs">
                  lbs
                </option>
                <option key={0} name="kg" value="kg">
                  kg
                </option>
              </Form.Control>
            </Form.Group>
          </Row>
        )
      ) : props.fields.SavedEntries.length > 0 ? (
        <>
          <Row>
            <Form.Group
              as={Col}
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={5}
              controlId="PatientWeight"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>
                Patient Weight:{' '}
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                type="number"
                value={props.fields.PatientWeight}
                disabled={props.disabled}
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    PatientWeight: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group
              as={Col}
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={5}
              controlId="KetamineUnits"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>Units: </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                as="select"
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    KetamineUnits: e.target.value,
                  })
                }
                disabled={props.disabled}
                value={props.fields.KetamineUnits}
              >
                <option key={1} name="lbs" value="lbs">
                  lbs
                </option>
                <option key={0} name="kg" value="kg">
                  kg
                </option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="center">
            <Form.Group
              as={Col}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginLeft: 'auto' }}
            >
              <Button
                bsPrefix="plain-button center info button-label"
                onClick={() => {
                  props.saveNewEntries()
                }}
              >
                + Add New Entry
              </Button>
            </Form.Group>
          </Row>
        </>
      ) : (
        <Row>
          <Form.Group
            as={Col}
            style={{ textAlign: 'center', marginLeft: 'auto' }}
          >
            <Button
              bsPrefix="plain-button center info button-label"
              onClick={() => {
                props.saveNewEntries()
              }}
            >
              + Add New Entry
            </Button>
          </Form.Group>
        </Row>
      )}
      <Form.Text
        className="center hideDuringPrint"
        style={{ fontWeight: 'bold', color: '#A9A9A9' }}
      >
        Small screens may need to scroll horizontally for a full view of
        medication and vitals.
      </Form.Text>
      <hr />
      <div
        className="center info"
        style={{ fontWeight: 'bold', paddingBottom: '1em' }}
      >
        Additional Medications
      </div>
      <Table responsive>
        {props.fields.SavedAdditionalMedications.length !== 0 ||
        !props.detail ? (
          <thead>
            <tr>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '336px',
                }}
              >
                Additional Medication Name
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '112px',
                }}
              >
                Total Dose
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '150px',
                }}
              >
                Units
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '224px',
                }}
              >
                Route
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '112px',
                }}
              >
                Time
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '140px',
                }}
              >
                &nbsp;
              </th>
            </tr>
          </thead>
        ) : (
          ''
        )}
        <tbody>{handleShowAdditionalMedication()}</tbody>
      </Table>

      {!props.disabled && (
        <Form.Row>
          <Button
            bsPrefix="center plain-button info button-label"
            onClick={() => {
              saveNewAdditionalMedicationForIMKetamine()
            }}
          >
            + Add Med
          </Button>
        </Form.Row>
      )}

      <Form.Text
        className="center hideDuringPrint"
        style={{ fontWeight: 'bold', color: '#A9A9A9', paddingBottom: '15px' }}
      >
        Small screens may need to scroll horizontally for a full view of
        medication and vitals.
      </Form.Text>

      <hr />
      <div
        className="center info"
        style={{ fontWeight: 'bold', paddingBottom: '1em' }}
      >
        Vitals
      </div>
      <Table responsive>
        {props.fields.SavedVitalSigns.length !== 0 || !props.detail ? (
          <thead>
            <tr>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '140px',
                }}
              >
                Time
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                HR
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                BP
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                SpO2
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                EKG
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                RR
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '96px',
                }}
              >
                RASS
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '360px',
                }}
              >
                Comments
              </th>
              {!props.disabled && (
                <th
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    minWidth: '140px',
                  }}
                >
                  &nbsp;
                </th>
              )}
            </tr>
          </thead>
        ) : (
          ''
        )}

        <tbody>{handleShowSavedVitalsIMKetamine()}</tbody>
      </Table>
      <AddVitals
        shortPublicId={props.patient.ShortPublicId}
        disabled={props.disabled}
        setAddVitals={props.setAddVitals}
        savedVitalSigns={props.fields.SavedVitalSigns}
        bPPlacement={props.fields.BpPlacement}
        handleSaveVitals={props.saveNewVitals}
        handleSaveCaretakerVitals={props.saveNewCaretakerVitals}
        handleFieldChange={(value) => {
          props.handleFieldChange({ ...props.fields, BpPlacement: value })
        }}
      />
      <Form.Text
        className="center hideDuringPrint"
        style={{ fontWeight: 'bold', color: '#A9A9A9' }}
      >
        Small screens may need to scroll horizontally for a full view of
        medication and vitals.
      </Form.Text>

      {(!props.disabled ||
        Object.keys(props.fields.SavedDischargeVitalSigns).length > 0) && (
        <hr className="style-one" />
      )}

      <Form.Row>
        <Form.Group
          as={Row}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          style={{ marginLeft: '15px' }}
          controlId="PatientEndorsment"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>
            Did the patient endorse any of the following: dissociation, an
            out-of-body experience, distortion of any of the 5 senses, a
            floating experience?
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.PatientEndorsment)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="PatientEndorsmentYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientEndorsment: e.target.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.PatientEndorsment)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="PatientEndorsmentNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientEndorsment: e.target.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>

      {JSON.parse(props.fields.PatientEndorsment) && (
        <Form.Row>
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            lg={12}
            xl={12}
            controlId="EndorsmentNotes"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Additional Notes
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.EndorsmentNotes}
              disabled={props.disabled}
              as={AutoSizingTextArea}
              minRows={1}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  EndorsmentNotes: e.target.value,
                })
              }
            />
          </Form.Group>
        </Form.Row>
      )}

      <hr className="style-one" />
      <Form.Row>
        <Form.Group as={Col} lg={12} controlId="TreatmentNotes">
          <Form.Label style={{ fontWeight: 'bold' }}>
            Treatment notes
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.TreatmentNotes}
            as={AutoSizingTextArea}
            minRows={3}
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                TreatmentNotes: e.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <br />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Assessment and Plan
      </div>
      <Form.Row style={{ marginTop: '20px' }}>
        <Form.Group as={Col} xs={12} sm={12} lg={12} controlId="TreatmentPlan">
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.TreatmentPlan}
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={3}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                TreatmentPlan: e.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Discharge
      </div>
      <Discharge
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        detail={props.detail}
        template="IM Ketamine"
        saveNewDischargeVitals={props.saveNewDischargeVitals}
        addDischargeVitals={props.addDischargeVitals}
        setAddDischargeVitals={props.setAddDischargeVitals}
        saveEditDischargeVitalSigns={props.saveEditDischargeVitalSigns}
        deleteVitals={props.deleteDischarge}
        disabled={props.disabled}
      />
    </div>
  )
}
