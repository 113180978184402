import { Col, Row } from '../../../BaseComponents'
import { EventCategory } from '../../types'

import './DayView.scss'

export type DayViewProps = {
  resource: Pick<EventCategory, 'label' | 'value' | 'subLabel'>
  currentProviderId: string
}

export const DayView = ({ resource, currentProviderId }: DayViewProps) => {
  const { label = '', subLabel = '', value } = resource
  return (
    <Col className="day-view-resource-header">
      <Row className="day-view-resource-header-title">
        <span className="day-view-header-label">
          {label}
          {currentProviderId === value && (
            <span className="day-view-header-sub-label">&nbsp;(You)</span>
          )}
          {subLabel && (
            <span className="day-view-header-sub-label">&nbsp;{subLabel}</span>
          )}
        </span>
      </Row>
    </Col>
  )
}
