import React, { useState } from 'react'

import { Modal } from 'antd'

import { deleteNoteTemplate } from '../../v2/notes/api'
import { generateNotification } from '../BaseComponents'
import { NotificationType } from '../BaseComponents/Notification'

interface NoteTemplateDeleteDialogProps {
  visible: boolean
  templateId: string
  templateName: string
  onCancel: () => void
  onDelete: () => void
}

export function NoteTemplateDeleteDialog({
  visible,
  templateId,
  templateName,
  onCancel,
  onDelete,
}: NoteTemplateDeleteDialogProps) {
  const [isDeleting, setIsDeleting] = useState(false)

  const handleOk = async () => {
    setIsDeleting(true)

    try {
      await deleteNoteTemplate(templateId)
      generateNotification(
        `Note template "${templateName}" deleted.`,
        NotificationType.SUCCESS
      )
      onDelete()
    } catch (e) {
      generateNotification(
        'Failed to delete note template. Try again.',
        NotificationType.ERROR
      )
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Modal
      title={`Delete "${templateName}"?`}
      visible={visible}
      closable={false}
      maskClosable={true}
      okText={'Delete'}
      okButtonProps={{ type: 'primary', danger: true, loading: isDeleting }}
      onOk={handleOk}
      onCancel={onCancel}
    >
      <span>
        Are you sure you want to delete the <b>{templateName}</b> note template?
        This action cannot be undone.
      </span>
    </Modal>
  )
}
