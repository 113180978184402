import React from 'react'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { ColumnType } from 'antd/lib/table/interface'

import { Button } from '../BaseComponents'
import Table, { GenericTableProps } from '../BaseComponents/Table'
import { CustomBillingData } from '../BaseComponents/tableData.types'

import './SettingsTableDisplay.scss'

interface CustomBillingTableProps extends GenericTableProps {
  dataSource: CustomBillingData[]
  isTableLoading?: boolean
  onDelete: (data: any) => void
  onEdit: (data: any) => void
}

const CustomBillingTable: React.FC<CustomBillingTableProps> = ({
  dataSource = [],
  isTableLoading = false,
  onEdit,
  onDelete,
}) => {
  // sorts ascending by code alphanumeric value
  dataSource = dataSource.sort(({ code: codeA = '' }, { code: codeB = '' }) =>
    codeA === codeB ? 0 : codeA > codeB ? 1 : -1
  )
  const columns: ColumnType<any>[] = [
    {
      dataIndex: 'code',
      fixed: 'left',
      key: 'code',
      title: 'Billing Code',
      width: 168,
    },
    {
      dataIndex: 'shortDescription',
      fixed: 'left',
      key: 'shortDescription',
      title: 'Short description',
    },
    {
      fixed: 'right',
      render: (data: CustomBillingData) => (
        <span>
          <Button className="survey-setting edit" onClick={() => onEdit(data)}>
            <EditOutlined />
            Edit
          </Button>
          <Button
            className="survey-setting delete"
            danger
            onClick={() => onDelete(data)}
          >
            <DeleteOutlined />
            Delete
          </Button>
        </span>
      ),
      title: ' ',
      width: 224,
    },
  ]
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      data-id="custom-billing-table"
      loading={isTableLoading}
    />
  )
}

export default CustomBillingTable
