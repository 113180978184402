// Goal of this component is to consistently render addresses
// across the app, regardless of their address field format
import React from 'react'

import { TestId } from '../../shared-types'

export type AddressType = {
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  zipcode?: string
  AddressLine1?: string
  AddressLine2?: string
  City?: string
  State?: string
  Zipcode?: string
  ZipCode?: string
}

type AddressProps = {
  address?: AddressType | null
} & TestId

const removeUndefinedOrEmpty = (list: (string | undefined)[]) =>
  list.map((i) => i?.trim()).filter((i) => i && i.length)

const Address = ({ address = {}, testId }: AddressProps) => {
  if (!address) return <div />
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    zipcode,
    AddressLine1,
    AddressLine2,
    City,
    State,
    Zipcode,
    ZipCode,
  } = address
  return (
    <div data-testid={testId}>
      <div>
        {removeUndefinedOrEmpty([
          addressLine1 || AddressLine1,
          addressLine2 || AddressLine2,
        ]).join(', ')}
      </div>
      <div>
        {removeUndefinedOrEmpty([city || City, state || State]).join(', ')}{' '}
        {(zipcode || Zipcode || ZipCode)?.trim()}
      </div>
    </div>
  )
}

export default Address
