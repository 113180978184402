import React from 'react'

import { WarningFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'

import styles from './_shared.module.scss'

interface AlertIndicatorProps {
  visible?: boolean
  loading?: boolean
  tooltip: string
  tooltipPlacement?: TooltipPlacement
  className?: string
}

const AlertIndicator: React.FC<AlertIndicatorProps> = ({
  visible = false,
  loading = true,
  tooltip,
  tooltipPlacement = 'rightTop',
  className,
}) => {
  return visible && !loading ? (
    <Tooltip
      color="#000000"
      placement={tooltipPlacement}
      title={tooltip}
      overlayClassName={styles.alertIndicator}
    >
      <WarningFilled className={className} style={{ color: '#FF4D4F' }} />
    </Tooltip>
  ) : null
}

export default AlertIndicator
