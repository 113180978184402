import { FontSizeOutlined } from '@ant-design/icons'
import { Editor } from '@tiptap/react'

import { ALLOWED_HEADINGS } from '../../../constants'
import { DropdownSelector } from './DropdownSelector'

const HEADING = 'heading'

export const HeadingDropdown = ({
  editor,
  isLoading,
}: {
  editor?: Editor
  isLoading: boolean
}) => {
  const items = ALLOWED_HEADINGS.map((level) => ({
    text: `Heading ${level}`,
    key: `h${level}`,
    testId: `notes-v2-heading-${level}`,
    onClick: () => editor?.chain().toggleHeading({ level }).focus().run(),
  }))

  // Add paragraph option at the start
  items.unshift({
    text: 'Normal text',
    key: 'paragraph',
    testId: 'notes-v2-paragraph',
    onClick: () => editor?.commands.setParagraph(),
  })

  const currentLevel = editor?.getAttributes(HEADING)?.level

  const label =
    editor?.isActive(HEADING) && currentLevel
      ? `Heading ${currentLevel}`
      : 'Normal text'

  return (
    <DropdownSelector
      icon={<FontSizeOutlined />}
      testId="notes-v2-font-size"
      label={label}
      items={items}
      isLoading={isLoading || !editor}
    />
  )
}
