import React, { Ref } from 'react'

import { Input } from 'antd'
import {
  TextAreaProps as AntDTextAreaProps,
  TextAreaRef,
} from 'antd/lib/input/TextArea'

import { TestId } from '../../shared-types'

const { TextArea } = Input

interface WithRefProps extends AntDTextAreaProps {
  ref?: Ref<TextAreaRef>
}

// .forwardRef is being used so that it can be used in the legacy bootstrap pages
export type InputProps = WithRefProps & TestId
const TextAreaInput = React.forwardRef<TextAreaRef, WithRefProps & TestId>(
  ({ testId, ...props }, ref) => {
    return <TextArea data-testid={testId} {...props} ref={ref} />
  }
)

export const AutoSizingTextArea = React.forwardRef<
  TextAreaRef,
  InputProps & { minRows?: number; maxRows?: number }
>((props, ref) => (
  <TextAreaInput
    {...props}
    ref={ref}
    autoSize={{ minRows: props.minRows || 3, maxRows: props.maxRows }}
  />
))

export default TextAreaInput
