import clsx from 'clsx'
import chunk from 'lodash/chunk'
import moment from 'moment'
import { NavigateAction, TimeGridProps } from 'react-big-calendar'
// @ts-ignore we need to use the MonthView from react-big-calendar, but it's not typed
import MonthView from 'react-big-calendar/lib/Month'

export interface MonthProps extends TimeGridProps {
  date: Date
  className?: string
  localizer?: any
}

class MonthNoWeekendView extends MonthView {
  props: MonthProps
  constructor(props: MonthProps) {
    super(props)
    this.props = props
  }

  static range = (date: Date, props: any) => {
    return MonthView.range(date, props)
  }

  static navigate = (date: Date, action: NavigateAction, props: any) => {
    return MonthView.navigate(date, action, props)
  }

  static title = (date: Date, props: any) => {
    return MonthView.title(date, props)
  }

  render() {
    const { date, localizer, className } = this.props
    const month = localizer
      ?.visibleDays(date, localizer)
      .filter((d: Date) => moment(d).isoWeekday() <= 5)
    const weeks = chunk(month, 5)

    return (
      <div
        className={clsx('rbc-month-view', className)}
        role="table"
        aria-label="Month View"
      >
        <div className="rbc-row rbc-month-header" role="row">
          {/*// @ts-ignore we extend from MonthView and have renderHeaders*/}
          {this.renderHeaders(weeks[0])}
        </div>
        {/*// @ts-ignore we extend from MonthView and have renderWeek*/}
        {weeks.map(this.renderWeek)}
        {/*// @ts-ignore we extend from MonthView and have renderOverlay*/}
        {this.props.popup && this.renderOverlay()}
      </div>
    )
  }
}
export default MonthNoWeekendView
