import React, { useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import { cloneDeep } from 'lodash'

import SkeletonLoadingTransition from '../../../components/Animation/SkeletonLoadingTransition'
import { useChangePayers } from '../../../hooks/usePatientProfile'
import { getInsurancePayerOptions } from '../../../libs/utils'
import Fields, { FieldGroupProps } from '../../../stories/PatientProfile/Fields'
import { companyToSubmitToComponent } from './constants'

import styles from './InsuranceClaim.module.scss'

export interface ClaimCreationProps {
  loading?: boolean
  testId?: string
  companyToSubmitTo: null | string | undefined
  setCompanyToSubmitTo: Function
  disabled?: boolean
}

const Loading = () => (
  <div className={styles.skeletonContainer}>
    <Skeleton
      active
      title={{ width: 85 }}
      paragraph={{
        rows: 3,
        width: '100%',
        className: styles.skeletonParagraph,
      }}
    />
  </div>
)

const CompanyToSubmit: React.FC<ClaimCreationProps> = ({
  testId,
  loading,
  companyToSubmitTo,
  setCompanyToSubmitTo,
  disabled = false,
}) => {
  const { data: changeList, isFetching: loadingChangeList } = useChangePayers()

  const [companyToSubmit, setCompanyToSubmit] = useState<FieldGroupProps[]>(
    companyToSubmitToComponent
  )

  useEffect(() => {
    if (loading || loadingChangeList || !changeList) return
    const changeOptions = getInsurancePayerOptions(changeList)
    const itemsCopy: any = cloneDeep(companyToSubmitToComponent)
    itemsCopy[0].items[0].value = companyToSubmitTo || null
    itemsCopy[0].items[0].options = changeOptions
    setCompanyToSubmit(itemsCopy)
  }, [loading, companyToSubmitTo, loadingChangeList])

  const handleSave = (
    newValue: any,
    _id: string,
    _groupName: string,
    _label?: string
  ) => {
    setCompanyToSubmitTo(newValue || null)
  }

  return (
    <SkeletonLoadingTransition
      isLoading={loading || loadingChangeList}
      skeletonComponent={<Loading />}
      loadedComponent={
        <div className={styles.topMargin}>
          <Fields
            testId={testId}
            items={companyToSubmit}
            compact={false}
            handleSave={handleSave}
            showTitle={false}
            isClaim
            disableAll={disabled}
          />
        </div>
      }
    />
  )
}

export default CompanyToSubmit
