import React from 'react'

import { Col, Form, Row } from 'react-bootstrap'

import { UnitEditFieldProps } from './types'

export default function UnitEditField(props: UnitEditFieldProps) {
  const {
    handleFieldChange,
    styling,
    templateField,
    units,
    unitValue = null,
    value,
  } = props

  const { columnSizes, id, label, placeholder = '' } = templateField

  const {
    colBsPrefix = '',
    colClassName = '',
    colStyle = {},
    editValueBsPrefix = '',
    editValueClassName = '',
    editValueStyle = {},
    editLabelBsPrefix = '',
    editLabelClassName = '',
    editLabelStyle = {},
  } = styling

  return (
    <Col
      bsPrefix={colBsPrefix}
      className={colClassName}
      style={colStyle}
      {...columnSizes}
    >
      <Row>
        <Col>
          <Form.Group controlId={id}>
            <Form.Label
              bsPrefix={editLabelBsPrefix}
              className={editLabelClassName}
              style={editLabelStyle}
            >
              {label}
            </Form.Label>
            <Form.Control
              bsPrefix={editValueBsPrefix}
              className={editValueClassName}
              style={editValueStyle}
              onChange={handleFieldChange}
              placeholder={placeholder}
              type="number"
              value={value}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label
              bsPrefix={editLabelBsPrefix}
              className={editLabelClassName}
              style={editLabelStyle}
            >
              Unit
            </Form.Label>
            {units &&
              units.map((unit, index) => {
                const radioId = `${label}-${unit}-${index}`
                const handleRadioChange = () =>
                  props.handleRadioChange(id, index)
                // if there's no unitValue given
                // then the first unit should be checked as a default
                const shouldCheckFirstAsDefault = !unitValue && !index
                return (
                  <Form.Check
                    bsPrefix="form-check"
                    inline
                    label={unit}
                    type="radio"
                    key={radioId}
                    id={radioId}
                    onChange={handleRadioChange}
                    checked={unitValue === unit || shouldCheckFirstAsDefault}
                  />
                )
              })}
          </Form.Group>
        </Col>
      </Row>
    </Col>
  )
}
