import { DateISO8601 } from '../../../shared-types'

type Notes = string

enum YesOrNo {
  YES = 'Yes',
  NO = 'No',
}

enum LivingSituation {
  Independent = 'Lives alone independently',
  Housemates = 'Lives with roommate(s)/housemate(s)',
  FamilyMembers = 'Lives with family member(s)',
  RomanticPartners = 'Lives with romantic partner(s)',
  Homeless = 'Homeless',
  GroupHome = 'Lives in a group home or other supported living situation',
  Other = 'Other [describe]',
}

enum CurrentOccupation {
  Student = 'Attending school/college/other work or educational programming',
  EmployedFullTime = 'Employed (full-time)',
  EmployedPartTime = 'Employed (part-time)',
  SporadicallyEmployed = 'Sporadically employed',
  Unemployed = 'Unemployed',
  OnDisability = 'On disability',
  Other = 'Other [describe]',
}

enum Degree {
  GraduateDegree = 'Graduate degree',
  CollegeDegree = 'College degree',
  HighSchoolDiploma = 'High school diploma',
  SomeHighSchool = 'Some high school',
  NoHighSchool = 'No high school',
  Other = 'Other [describe]',
}

export enum RelationalStatus {
  DATING = 'Dating',
  DIVORCED = 'Divorced',
  ENGAGED = 'Engaged',
  LONG_TERM_PARTNER = 'Long-term partnership',
  MARRIED = 'Married',
  NOT_DATING = 'Not dating',
  OTHER = 'Other [describe]',
  REMARRIED = 'Remarried',
  SEPARATED = 'Separated',
  SINGLE = 'Single',
  SINGLE_LEGACY = 'Single - never married',
  WIDOWED = 'Widowed',
}

export enum RelationshipStress {
  Connected = 'Connected',
  Isolated = 'Isolated',
  Stressful = 'Stressful',
  MildlyStressful = 'Mildly stressful',
  NotStressful = 'Not stressful',
  Other = 'Other [describe]',
}

enum ExerciseAmount {
  SignificantlyInadequate = 'Significantly inadequate',
  Inadequate = 'Inadequate',
  Appropriate = 'Appropriate',
  Excessive = 'Excessive',
  SignificantlyExcessive = 'Significantly excessive',
}

enum SexualOrientation {
  Straight = 'Straight or heterosexual',
  Gay = 'Lesbian, gay, or homosexual',
  Bisexual = 'Bisexual',
  Other = 'Other [describe]',
  Unsure = "Don't know",
  Declines = 'Patient declines to specify',
}

enum Diet {
  Healthy = 'Healthy',
  MildlyUnhealthy = 'Mildly unhealthy',
  SignificantlyUnhealthy = 'Significantly unhealthy',
}

export interface SocialHistoryNotesResponse {
  livingSituation?: Set<LivingSituation>
  livingSituationNotes?: Notes
  hasSafetyConcerns?: YesOrNo
  hasSafetyConcernsExplanation?: Notes
  hasAccessToWeapons?: YesOrNo
  hasAccessToWeaponsExplanation?: Notes
  currentOccupation?: CurrentOccupation
  currentOccupationOther?: Notes
  occupationNotes?: Notes
  educationNotes?: Notes
  hasLearningDisability?: YesOrNo
  hasLearningDisabilityExplanation?: Notes
  degree?: Degree
  degreeOther?: Notes
  relationalStatus?: RelationalStatus
  relationalStatusOther?: Notes
  familyRelationships?: RelationshipStress
  familyRelationshipsOther?: Notes
  socialRelationships?: RelationshipStress
  socialRelationshipsOther?: Notes
  exerciseNotes?: Notes
  exerciseAmount?: ExerciseAmount
  sexualActivityNotes?: Notes
  isSexuallyActive?: YesOrNo
  isSexuallyActiveExplanation?: Notes
  sexualOrientation?: SexualOrientation
  SexualOrientationOther?: Notes
  dietNotes?: Notes
  diet?: Diet
  spiritualityNotes?: Notes
  hasSpiritualReligiousBeliefs?: YesOrNo
  hasSpiritualReligiousBeliefsExplanation?: Notes
  hobbiesNotes?: Notes
  militaryServiceNotes?: Notes
  hasCombatExperience?: YesOrNo
  hasCombatExperienceExplanation?: Notes
  legalBackgroundNotes?: Notes
  hasLegalTroubles?: YesOrNo
  hasLegalTroublesExplanation?: Notes
  financesNotes?: Notes
  hasEconomicHardships?: YesOrNo
  hasEconomicHardshipsExplanation?: Notes
  additionalNotes?: Notes
  createdAt?: DateISO8601
}
