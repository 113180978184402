// Import react
import React, { useEffect, useState } from 'react'

// Import components
import {
  Badge,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap'

import MedicationResponses from './MedicationResponses'

const MedicationLogs = (props) => {
  const [responsesTabs, setResponsesTabs] = useState(<p />)
  const [totalAdverseMeds, setTotalAdverseMeds] = useState(0)
  const [totalLateMeds, setTotalLateMeds] = useState(0)
  const [totalMissedMeds, setTotalMissedMeds] = useState(0)
  const [totalNonResponseMeds, setTotalNonResponseMeds] = useState(0)

  let sumOfActiveMedResponses = 0
  for (let i = 0; i < props.activeMeds.length; i++) {
    sumOfActiveMedResponses += props.activeMeds[i].AdministrationResponsesToDate
  }
  const totalImportantMeds = props.responses.length

  useEffect(() => {
    let adverseCount = 0
    let lateCount = 0
    let MissedCount = 0
    let nonResponseCount = 0

    const counts = () => {
      for (let i = 0; i < totalImportantMeds; i++) {
        if (props.responses[i].HasAdverseEvent) {
          adverseCount += 1
        }
        if (props.responses[i].AdministeredLate) {
          lateCount += 1
        }
        if (
          !props.responses[i].DidAdminister &&
          props.responses[i].DidRespond
        ) {
          MissedCount += 1
        }
        if (!props.responses[i].DidRespond) {
          nonResponseCount += 1
        }
      }
    }
    const onLoad = () => {
      counts()
      setTotalAdverseMeds(adverseCount)
      setTotalLateMeds(lateCount)
      setTotalMissedMeds(MissedCount)
      setTotalNonResponseMeds(nonResponseCount)
      const medicationResponsesToShow = props.responses
      medicationResponsesToShow.sort(function compare(a, b) {
        const dateA = new Date(a.AdministrationDueDate)
        const dateB = new Date(b.AdministrationDueDate)
        return dateB - dateA
      })
      const responses = medicationResponsesToShow.map((response, index) => {
        return (
          <MedicationResponses key={index} index={index} response={response} />
        )
      })
      setResponsesTabs(responses)
    }
    onLoad()
  }, [totalImportantMeds, props.responses])

  return totalImportantMeds === 0 ? (
    <div>
      <br />
      <Card bsPrefix="summary-card">
        <h5>
          <Row style={{ marginLeft: '10px' }} className="emphasis-light subtle">
            &nbsp;Patient has responded to&nbsp;
            <span className="emphasis response-title">
              &nbsp;{sumOfActiveMedResponses}&nbsp;
            </span>
            medication reminders to date. No abnormal responses to report.
          </Row>
        </h5>
      </Card>
    </div>
  ) : (
    <Container>
      <br />
      {/* Patient response summarization */}
      <Row md={8} className="justify-content-center">
        <Col bsPrefix="very-large">
          <Row className="justify-content-center">
            <h4>
              <span className="info response-title">
                Patient response summary
              </span>
            </h4>
          </Row>
          <Row
            sm={12}
            md={8}
            style={{ textAlign: 'center', marginLeft: '5px' }}
            className="emphasis-light justify-content-center subtle"
          >
            Patient has responded to
            <span className="emphasis response-title">
              &nbsp;{sumOfActiveMedResponses}
            </span>
            &nbsp;medication reminders to date.
          </Row>
        </Col>
      </Row>

      <br />

      <Row className="justify-content-center">
        {/* Late medication card */}
        <Col xl={3} lg={6} md={6}>
          <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={
              totalLateMeds !== 1 ? (
                <Tooltip bsPrefix="tooltip-x" id="late">
                  There were {totalLateMeds} late medication administrations.
                </Tooltip>
              ) : (
                <Tooltip bsPrefix="tooltip-x" id="late">
                  There was one late medication administration.
                </Tooltip>
              )
            }
          >
            <Card bsPrefix="summary-card help info">
              <Row className="justify-content-center">
                <h2>
                  <b>{totalLateMeds}</b>
                </h2>
              </Row>

              <Row className="justify-content-center">
                <Badge pill bsPrefix="align-center info">
                  &nbsp;
                  <span>
                    &nbsp;
                    <i className="fa fa-clock-o fa-2x" aria-hidden="true" />
                    &nbsp; Administered Late
                  </span>
                </Badge>
              </Row>
            </Card>
          </OverlayTrigger>
        </Col>

        {/* Missed medication card */}
        <Col xl={3} lg={6} md={6}>
          <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={
              totalLateMeds !== 1 ? (
                <Tooltip bsPrefix="tooltip-x" id="missed">
                  There were {totalMissedMeds} missed medication
                  administrations.
                </Tooltip>
              ) : (
                <Tooltip bsPrefix="tooltip-x" id="missed">
                  There was one missed medication administration.
                </Tooltip>
              )
            }
          >
            <Card bsPrefix="summary-card help">
              <Row className="justify-content-center">
                <h2>
                  <b>{totalMissedMeds}</b>
                </h2>
              </Row>

              <Row className="justify-content-center">
                <Badge pill bsPrefix="align-center emphasis">
                  &nbsp;
                  <span>
                    &nbsp;
                    <i className="fa fa-times fa-2x" aria-hidden="true" />
                    &nbsp; Did Not Administer
                  </span>
                </Badge>
              </Row>
            </Card>
          </OverlayTrigger>
        </Col>

        {/* Adverse effect card */}
        <Col xl={3} lg={6} md={6}>
          <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={
              totalAdverseMeds !== 1 ? (
                <Tooltip bsPrefix="tooltip-x" id="adverse">
                  There were {totalAdverseMeds} adverse effects reported from
                  the administration of the medication.
                </Tooltip>
              ) : (
                <Tooltip bsPrefix="tooltip-x" id="adverse">
                  There was one adverse effect reported from the administration
                  of the medication.
                </Tooltip>
              )
            }
          >
            <Card bsPrefix="summary-card help danger">
              <Row className="justify-content-center">
                <h2>
                  <b>{totalAdverseMeds}</b>
                </h2>
              </Row>

              <Row className="justify-content-center">
                <Badge pill bsPrefix="align-center danger">
                  &nbsp;
                  <span>
                    &nbsp;
                    <i
                      className="fa fa-exclamation-triangle fa-2x"
                      aria-hidden="true"
                    />
                    &nbsp; Adverse Effect
                  </span>
                </Badge>
              </Row>
            </Card>
          </OverlayTrigger>
        </Col>

        {/* Missed Responses Catf */}
        <Col xl={3} lg={6} md={6}>
          <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={
              totalAdverseMeds !== 1 ? (
                <Tooltip bsPrefix="tooltip-x" id="nonreponse">
                  There were {totalNonResponseMeds} medication reminders that
                  expired without a response from the patient.
                </Tooltip>
              ) : (
                <Tooltip bsPrefix="tooltip-x" id="nonresponse">
                  There was one medication reminder that expired without a
                  response from the patient.
                </Tooltip>
              )
            }
          >
            <Card bsPrefix="summary-card help subtext">
              <Row className="justify-content-center">
                <h2>
                  <b>{totalNonResponseMeds}</b>
                </h2>
              </Row>

              <Row className="justify-content-center">
                <Badge pill bsPrefix="align-center emphasis subtext">
                  &nbsp;
                  <span>
                    &nbsp;
                    <i
                      className="fa fa-question-circle fa-2x subtext"
                      aria-hidden="true"
                    />
                    &nbsp; Not Reported
                  </span>
                </Badge>
              </Row>
            </Card>
          </OverlayTrigger>
        </Col>
      </Row>

      <Row className="table-container">
        <Col className="summary-card">{responsesTabs}</Col>
      </Row>
    </Container>
  )
}

export default MedicationLogs
