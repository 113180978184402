import Focus from '@tiptap/extension-focus'
import Highlight from '@tiptap/extension-highlight'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import { Content, EditorEvents, Extensions, useEditor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'

import {
  ALLOWED_HEADINGS,
  HISTORY_DEPTH,
  NOTE_EDITOR_PLACEHOLDER_TEXT,
} from '../../constants'

const SharedExtensions = [
  StarterKit.configure({
    heading: { levels: ALLOWED_HEADINGS },
    history: { depth: HISTORY_DEPTH },
    bulletList: { keepMarks: true, keepAttributes: true },
  }),
  Placeholder.configure({
    placeholder: NOTE_EDITOR_PLACEHOLDER_TEXT,
  }),
  Highlight.configure({ multicolor: true }),
  Underline,
  Focus,
]

interface useRichTextEditorProps {
  extraExtensions?: Extensions
  content: Content
  isEditable: boolean
  onBlur?: (props: EditorEvents['blur']) => void
  onUpdate?: (props: EditorEvents['update']) => void
}

export const useRichTextEditor = ({
  extraExtensions,
  content,
  isEditable,
  onBlur,
  onUpdate,
}: useRichTextEditorProps) => {
  const editor = useEditor(
    {
      extensions: [
        ...SharedExtensions,
        ...(extraExtensions ? extraExtensions : []),
      ],
      editorProps: {
        attributes: {
          'data-testid': 'v2-editor-content',
        },
      },

      content,
      editable: isEditable,
      ...(onBlur && { onBlur: onBlur }),
      ...(onUpdate && { onUpdate: onUpdate }),
    },
    [isEditable]
  )

  return {
    editor,
  }
}
