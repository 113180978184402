import { Result } from 'antd'

import OsmindLogo from '../images/OsmindLogo'

export default function MaintenancePage() {
  return (
    <div>
      <div>
        <title>Osmind</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Osmind Health Tracking App" />
        <link rel="icon" href="/favicon.ico" />
      </div>

      <main>
        <Result
          icon={<OsmindLogo width="245.32px" height="60px" />}
          title="We’re offline for maintenance."
          subTitle={
            <text>
              Hi there! Osmind is undergoing maintenance right now.
              <br />
              Please check back later. Thank you for your patience.
            </text>
          }
        />
      </main>
    </div>
  )
}
