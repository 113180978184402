import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Editor } from '@tiptap/core'
import { Transaction } from '@tiptap/pm/state'
import { AnyExtension, JSONContent } from '@tiptap/react'
import { differenceInYears } from 'date-fns'
import { useHistory, useLocation } from 'react-router-dom'

import { removeDiagnosisNoteBlock } from '../../../../api/patients'
import { QUERY_PARAMS } from '../../../../libs/constants'
import { notification } from '../../../../libs/notificationLib'
import { formatDate } from '../../../../libs/utils'
import {
  ClinicDataType,
  Invoice,
  Location,
  Teammate,
} from '../../../../shared-types'
import Alert from '../../../../stories/BaseComponents/Alert'
import Button from '../../../../stories/BaseComponents/Button'
import ImageWithFallback from '../../../../stories/BaseComponents/ImageWithFallback'
import { useBlocks } from '../../hooks'
import { useCreateNoteBlock } from '../../hooks/blocks/useCreateNoteBlock'
import { useRichTextEditor } from '../../hooks/editor/useRichTextEditor'
import { Block, Note, SignatureRequest } from '../../types'
import {
  getNameInitials,
  getNoteAppointmentDateDisplay,
  getNoteTimeDisplay,
} from '../../utils'
import { DynamicBlock } from '../Blocks/Core/Block/DynamicBlock'
import { CreateDynamicBlockOptions } from '../Blocks/Core/Block/DynamicBlockTypes'
import { BlockWrapper } from '../Blocks/Core/BlockWrapper'
import { CoreNode } from '../Blocks/Core/CoreNode'
import { BlockConfig, CUSTOM_BLOCK_NAME_SET } from '../Blocks/types'
import { NoteNavigationBar } from '../NavigationBar/NoteNavigationBar'
import { NoteCoreData } from './CoreData/NoteCoreData'
import {
  EditorContent,
  didEditorContentChange,
} from './EditorContent/EditorContent'
import { NoteEditorToolbar } from './EditorToolbar/NoteEditorToolbar'
import { insertBlockInEditor } from './EditorToolbar/utils'
import { BlockDeletionExtension } from './Plugins/BlockDeletionExtension'
import { TrailingNode } from './Plugins/TrailingNode'
import { SignatureHeader } from './SignatureHeader'
import { Signatures } from './Signatures'
import { Title } from './Title'

import './RichTextEditor.global.scss'
import styles from './RichTextEditor.module.scss'

const getDiagnosisBlocks = ({ content }: JSONContent) => {
  return (
    content?.filter(
      (block: Partial<Block>) => block?.type === 'ActiveDiagnoses'
    )?.length || 0
  )
}

export type RichTextEditorProps = {
  healthGorillaUserName: string
  note: Note
  invoice: Invoice | undefined
  clinicData: ClinicDataType | undefined
  clinicLogoUrl: string | undefined
  isLoading: boolean
  isSaving: boolean
  saveError: string | null
  onChange: (content: Partial<Note>) => void
  teamOptions: Array<Teammate>
  locationOptions: Array<Location>
  onDelete: () => Promise<void>
  onCopyToNewNote: (patientId: string, sourceNoteId: string) => void
  pendingChanges?: Partial<Note>
  onSign: (body: SignatureRequest) => Promise<Partial<Note>>
  onAddAddendum: (text: string) => Promise<Partial<Note>>
  onNavigateAway: () => void
}

// TODO: move component to it's own file (without breaking dependent CSS styling/modules).
// https://osmind.atlassian.net/browse/CC-2769
export const PrintableHeader = ({
  note,
  locationName,
  providerName,
}: {
  note: Note
  locationName: string
  providerName: string
}) => {
  return (
    <div className={styles.containerHeader}>
      <table className="header-table">
        <tr>
          <th>Patient name</th>
          <td>{note.patientName}</td>
        </tr>
        <tr>
          <th>Date of birth</th>
          <td>
            {note.patient.DateOfBirth ? (
              <>
                {formatDate({
                  format: 'MM/dd/yyyy',
                  value: note.patient.DateOfBirth,
                })}
                {note.appointmentDate &&
                  ` (Age at visit: ${differenceInYears(
                    new Date(note.appointmentDate),
                    new Date(note.patient.DateOfBirth)
                  )} years)`}
              </>
            ) : (
              'N/A'
            )}
          </td>
        </tr>
        <tr>
          <th>Date of visit</th>
          <td>{getNoteAppointmentDateDisplay(note.appointmentDate)}</td>
        </tr>
        <tr>
          <th>Start time</th>
          <td>
            {note.startTime
              ? getNoteTimeDisplay({
                  time: note.startTime,
                  timezone: note.timezone ?? null,
                })
              : 'N/A'}
          </td>
        </tr>
        {note.locationId ? (
          <tr>
            <th>Location</th>
            <td>{locationName}</td>
          </tr>
        ) : (
          ''
        )}
        {note?.renderingProviderId ? (
          <tr>
            <th>Rendering provider</th>
            <td>{providerName}</td>
          </tr>
        ) : (
          ''
        )}
        <tr>
          <th>Note title</th>
          <td>{note.title}</td>
        </tr>
      </table>
      {note?.firstSignedAt && (
        <SignatureHeader
          signatures={note?.signatures ?? []}
          printMode={true}
          addendums={note?.addendums ?? []}
          timezone={note.timezone ?? null}
        />
      )}
    </div>
  )
}

export const RichTextEditor = ({
  healthGorillaUserName,
  note,
  invoice,
  clinicData,
  clinicLogoUrl,
  isLoading,
  isSaving,
  saveError,
  onChange,
  pendingChanges,
  teamOptions,
  locationOptions,
  onDelete,
  onCopyToNewNote,
  onSign,
  onAddAddendum,
  onNavigateAway,
}: RichTextEditorProps) => {
  const location = useLocation()
  const history = useHistory()

  const isSigned = !!note?.firstSignedAt
  const [hasDiagnosisBlock, setHasDiagnosisBlock] = useState<boolean>(
    (note.content && getDiagnosisBlocks(note.content) > 0) || false
  )

  //signatures
  const signatureContainerRef: RefObject<HTMLDivElement> = useRef(null)
  const addendumContainerRef: RefObject<HTMLDivElement> = useRef(null)
  //content
  const scrollableContainerRef: RefObject<HTMLTableElement> = useRef(null)

  const [pdfTitle, setPdfTitle] = useState<string>('')

  useEffect(() => {
    const {
      firstName,
      firstNameToUse,
      middleName,
      middleNameToUse,
      lastName,
      lastNameToUse,
    } = note.patient

    const patientName = `${firstNameToUse || firstName} ${
      middleNameToUse || middleName
    } ${lastNameToUse || lastName}`

    const title = `${getNameInitials(patientName)} ${
      note?.title ?? ''
    } ${getNoteAppointmentDateDisplay(note.appointmentDate)}`

    setPdfTitle(title)
  }, [note])

  const selectedRenderingProvider = teamOptions?.find(
    (teammate) => teammate.cognitoId === note?.renderingProviderId
  )

  const selectedLocation = locationOptions.find(
    (location) => location.LocationId === note?.locationId
  )

  const triggerUpdate = ({
    editor,
    transaction,
  }: {
    editor: Editor
    transaction: Transaction
  }) => {
    if (!didEditorContentChange(transaction)) {
      return
    }

    const newValue = editor.getJSON()

    // Check if we are removing diagnosisBlock; if so, call removeDiagnosisBlock api
    const diagnosisBlockCount = getDiagnosisBlocks(newValue)

    if (hasDiagnosisBlock && diagnosisBlockCount === 0) {
      removeDiagnosisNoteBlock(note.uuid)
    }
    setHasDiagnosisBlock(diagnosisBlockCount > 0)
    const currentCustomBlocks =
      newValue.content?.reduce<Block[]>((acc, block) => {
        if (
          block.type && // Cast to satisfy TS set type check
          CUSTOM_BLOCK_NAME_SET.has(block.type as BlockConfig['name'])
        ) {
          acc.push({
            type: block.type,
            blockId: block.attrs?.id,
          })
        }

        return acc
      }, []) ?? []

    onChange({ content: newValue, blocks: currentCustomBlocks })
  }

  const blocks = useBlocks()
  const blockExtensions = blocks.map((config) =>
    CoreNode({
      BlockComponent: BlockWrapper(config, isSigned),
      name: config.name,
      tag: config.tag,
    })
  ) as AnyExtension[]

  const DynamicBlockExtension = DynamicBlock({
    note,
  })

  const { editor } = useRichTextEditor({
    extraExtensions: [
      TrailingNode,
      DynamicBlockExtension,
      BlockDeletionExtension,
      ...blockExtensions,
    ],
    content: note.content,
    isEditable: !isLoading && !isSigned,
    onBlur: triggerUpdate,
    onUpdate: triggerUpdate,
  })

  const { createNewNoteBlock } = useCreateNoteBlock()

  const handleCreateNewBlock = async (opts: CreateDynamicBlockOptions) => {
    try {
      const noteBlock = await createNewNoteBlock({
        noteUuid: opts.noteUuid,
        blockConfig: opts.blockConfig,
      })
      insertBlockInEditor(
        editor ?? undefined,
        `<dynamic-block id="${noteBlock.uuid}"></dynamic-block>`
      )
      return
    } catch (error) {
      console.error(error)
      notification(
        'There was a problem creating the note block. Please contact your Osmind administrator.',
        'error'
      )
    }
  }

  const scrollToSignatures = () => {
    signatureContainerRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const scrollToAddendums = () => {
    addendumContainerRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
  const clinicAddress = useMemo(() => {
    const addressParts = [
      clinicData?.ProviderPracticeAddress,
      clinicData?.ProviderPracticeAddressExtn,
      clinicData?.ProviderPracticeCity,
      clinicData?.ProviderPracticeState,
      clinicData?.ProviderPracticeZipcode,
    ]
    return addressParts.filter((p) => !!p).join(', ')
  }, [clinicData])

  const showSaltAlert = useMemo(() => {
    return (
      new URLSearchParams(location.search).get(QUERY_PARAMS.saltAlert) ===
      'true'
    )
  }, [location.search])

  const onSaltAck = useCallback(() => {
    const newSearchParams = new URLSearchParams(location.search)
    newSearchParams.delete(QUERY_PARAMS.saltAlert)
    history.replace({
      pathname: location.pathname,
      search: newSearchParams.toString(),
    })
  }, [location])

  const hasUpdateTriggered =
    (pendingChanges && Object.keys(pendingChanges).length > 0) || false

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topToolbars} id="editor-nav">
        <NoteNavigationBar
          healthGorillaUserName={healthGorillaUserName}
          note={note}
          invoice={invoice}
          saveError={saveError}
          isLoading={isLoading}
          isSaving={isSaving}
          onDelete={onDelete}
          canDownload={!!clinicData && !isLoading}
          onSign={onSign}
          onAddAddendum={onAddAddendum}
          onCopy={() => onCopyToNewNote(note.patientId, note.uuid)}
          isNoteEmpty={editor?.isEmpty ?? true}
          hasPendingChanges={hasUpdateTriggered}
          pdfTitle={pdfTitle}
          onNavigateAway={onNavigateAway}
        />
        {showSaltAlert && (
          <Alert
            testId="alert-note-copied-from"
            message="This note was copied from a previous note."
            description="Please review all the information to ensure accuracy. Note that medications, diagnoses, and allergies will update to reflect the current patient chart."
            type="warning"
            showIcon
            action={
              <Button type="ghost" onClick={onSaltAck}>
                Acknowledge
              </Button>
            }
          />
        )}
        {!isSigned && (
          <NoteEditorToolbar
            editor={editor ?? undefined}
            isLoading={isLoading}
            isNoteSaving={isSaving || hasUpdateTriggered}
            patientId={note.patientId}
            noteId={note.uuid}
            onAddDynamicBlock={handleCreateNewBlock}
            onChange={onChange}
          />
        )}
      </div>
      <table
        id="note-content"
        ref={scrollableContainerRef}
        className={styles.contentContainer}
      >
        <tbody className={styles.editorWrapper}>
          <div className="print-only pdf-header">
            <div>
              <ImageWithFallback
                className="logo"
                alt=""
                src={clinicLogoUrl}
                title=""
                fallback={<span></span>}
              />
            </div>
            <div>
              <span className="title">{clinicData?.PracticeName}</span>
              &nbsp;
              <span className="address">{clinicAddress}</span>
            </div>
            <PrintableHeader
              note={note}
              locationName={selectedLocation?.LocationName ?? ''}
              providerName={selectedRenderingProvider?.name ?? ''}
            />
          </div>
          <div className={styles.contentHeader}>
            <Title
              noteTitle={note?.title}
              onChange={onChange}
              isLoading={isLoading}
              isSigned={isSigned}
            />
            <NoteCoreData
              note={note}
              onChange={onChange}
              teamOptions={teamOptions}
              locationOptions={locationOptions}
              isLoading={isLoading}
              isSigned={isSigned}
            />
            {isSigned && (
              <div className="hide-on-print">
                <SignatureHeader
                  signatures={note?.signatures ?? []}
                  scrollToSignatures={scrollToSignatures}
                  scrollToAddendums={scrollToAddendums}
                  addendums={note?.addendums ?? []}
                  timezone={note.timezone ?? null}
                />
              </div>
            )}
          </div>
          <div className={styles.contentWrapper}>
            {isSigned && editor?.isEmpty && !isLoading && (
              <div className={styles.emptyReadOnlyText}>This note is empty</div>
            )}
            <EditorContent isLoading={isLoading} editor={editor} />
          </div>
          {isSigned && (
            <div style={{ width: '100%' }}>
              <div className="hide-on-print">
                <Signatures
                  signatures={note?.signatures ?? []}
                  addendums={note?.addendums ?? []}
                  signatureContainerRef={signatureContainerRef}
                  addendumContainerRef={addendumContainerRef}
                  timezone={note.timezone ?? null}
                />
              </div>
              <div className="print-only">
                <Signatures
                  signatures={note?.signatures ?? []}
                  addendums={note?.addendums ?? []}
                  printMode={true}
                  timezone={note.timezone ?? null}
                />
              </div>
            </div>
          )}
        </tbody>
        <tfoot className="print-only" style={{ height: '46px' }} />
      </table>
      <div className="print-only pdf-footer">
        <div>
          <p>
            {note.patientName}
            {note.patient.DateOfBirth ? (
              <>
                {' | ' +
                  formatDate({
                    format: 'MM/dd/yyyy',
                    value: note.patient.DateOfBirth,
                  })}
              </>
            ) : (
              ''
            )}
            {' | Visit: ' + getNoteAppointmentDateDisplay(note.appointmentDate)}
          </p>
        </div>
        <div>
          <img
            src="/osmind-logo.png"
            alt=""
            style={{
              width: '45px',
              height: 'auto',
              overflow: 'visible',
              filter: 'grayscale(100%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}
