import { ColumnType } from 'antd/lib/table'
import { omit } from 'lodash'

import { ActiveMedicationHistory } from '../../../../../../../shared-types'
import { renderDate, renderValue } from '../../../elements'
import { sortDates, sortNumbers } from '../../../utilities'

import styles from '../../../styles.module.scss'

export type ActiveMedicationsData = {
  id: string
  name: string
  dosage?: string
  frequency?: string
  route?: string
  startDate?: string
  lastFilled?: string
  stopDate?: string
  quantity?: number
  refills?: number
  sideEffects?: string
  efficacy?: string
}

export const serializeMedicationData = (
  medicationData: ActiveMedicationHistory[] | undefined,
  isDrFirst: boolean
): ActiveMedicationsData[] => {
  return (medicationData || []).map((medication) => {
    const frequency = isDrFirst
      ? [medication.DoseTiming, medication.other].filter((a) => a).join(' ')
      : medication.Frecuency

    const dosage = isDrFirst
      ? [medication.Dose, medication.DoseUnit].filter((a) => a).join(' ')
      : medication.Dosage
    return {
      id: medication.ActiveMedicationId,
      name: medication.MedicationName,
      dosage: dosage || undefined,
      frequency: frequency || undefined,
      route: medication.Administration || undefined,
      startDate:
        medication.StartDate || medication.ProviderStartDate || undefined,
      lastFilled: medication.FillDate ?? undefined,
      stopDate: medication.StopDate ?? undefined,
      quantity: medication.quantity ?? undefined,
      refills: medication.refills ?? undefined,
      sideEffects: medication.SideEffects ?? undefined,
      efficacy: medication.PatientPerspectiveEfficacy ?? undefined,
    }
  })
}

export type MedicationColumn = ColumnType<ActiveMedicationsData> & {
  sorter?: (a: ActiveMedicationsData, b: ActiveMedicationsData) => number
}

export const MedicationBlockColumns: MedicationColumn[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a: ActiveMedicationsData, b: ActiveMedicationsData) =>
      a.name?.localeCompare(b.name),
    className: styles['primary-column'],
  },
  {
    title: 'Dosage',
    dataIndex: 'dosage',
    render: renderValue,
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
    render: renderValue,
  },
  {
    title: 'Route',
    dataIndex: 'route',
    render: renderValue,
  },
]

export const MedicationDrFirstColumns: MedicationColumn[] = [
  {
    title: 'Start date',
    dataIndex: 'startDate',
    render: renderDate,
    sorter: (a: ActiveMedicationsData, b: ActiveMedicationsData) =>
      sortDates<ActiveMedicationsData>(a, b, 'startDate'),
  },
  {
    title: 'Last filled',
    dataIndex: 'lastFilled',
    render: renderDate,
    sorter: (a: ActiveMedicationsData, b: ActiveMedicationsData) =>
      sortDates<ActiveMedicationsData>(a, b, 'lastFilled'),
  },
  {
    title: 'Stop date',
    dataIndex: 'stopDate',
    render: renderDate,
    sorter: (a: ActiveMedicationsData, b: ActiveMedicationsData) =>
      sortDates<ActiveMedicationsData>(a, b, 'stopDate'),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    render: renderValue,
    sorter: (a: ActiveMedicationsData, b: ActiveMedicationsData) =>
      sortNumbers<ActiveMedicationsData>(a, b, 'quantity'),
  },
  {
    title: 'Refills',
    dataIndex: 'refills',
    render: renderValue,
    sorter: (a: ActiveMedicationsData, b: ActiveMedicationsData) =>
      sortNumbers<ActiveMedicationsData>(a, b, 'refills'),
  },
]

export const MedicationOsmindColumns: MedicationColumn[] = [
  {
    title: 'Start date',
    dataIndex: 'startDate',
    render: renderDate,
  },
  {
    title: 'Side effects',
    dataIndex: 'sideEffects',
    render: renderValue,
  },
  {
    title: 'Efficacy',
    dataIndex: 'efficacy',
    render: renderValue,
  },
]

export const getColumns = ({
  isDrFirst,
  isSigned,
}: {
  isDrFirst: boolean
  isSigned: boolean
}): MedicationColumn[] => {
  return [
    ...MedicationBlockColumns,
    ...(isDrFirst ? MedicationDrFirstColumns : MedicationOsmindColumns),
  ].map((col) => {
    if (!isSigned) {
      return col
    }
    return omit(col, 'sorter')
  })
}
