import React, { useEffect, useMemo, useState } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js'
import { Modal } from 'antd'

import { createPaymentMethod } from '../../../api/api-lib'
import { updatePaymentMethod } from '../../../api/api-lib-typed'
import {
  AmexIcon,
  DiscoverIcon,
  MastercardIcon,
  VisaIcon,
} from '../../../images/Icons/PaymentMethodIcons'
import { isExpiredCard } from '../../../libs/billing'
import {
  EXTERNAL_SERVICES,
  PAYMENT_METHOD_TYPES,
} from '../../../libs/constants'
import { notification } from '../../../libs/notificationLib'
import { handleStripeError } from '../../../libs/utils'
import { PaymentMethod } from '../../../shared-types'
import { validateZipCode } from '../../../shared/Helpers/utils'
import {
  Button,
  Input,
  Spinner,
  Tooltip,
} from '../../../stories/BaseComponents'
import { DeleteCreditCardModal } from './DeleteCreditCardModal'

import styles from './Billing.module.scss'

interface AddCreditCardModal {
  isVisible: boolean
  onCloseModal: () => void
  handleNewCreditCardCreated: () => void
  patientId: string
  oldCreditCardValues: PaymentMethod | null
  creditCards?: PaymentMethod[]
}

export default function AddCreditCardModal({
  isVisible,
  onCloseModal,
  handleNewCreditCardCreated,
  patientId,
  oldCreditCardValues,
  creditCards,
}: AddCreditCardModal) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [cardNickname, setCardNickname] = useState<string | null>(null)
  const [zipcode, setZipcode] = useState('')
  const [cardholderName, setCardholderName] = useState('')
  const [isDefaultCard, setIsDefaultCard] = useState(false)

  enum requiredFields {
    cardNumber = 'cardNumber',
    expiryDate = 'expiryDate',
    cvc = 'cvc',
    cardholderName = 'cardholderName',
    zipcode = 'zipcode',
  }

  const errorMessages = useMemo(
    () => ({
      [requiredFields.cardNumber]: 'Please enter a valid card number.',
      [requiredFields.expiryDate]: 'Please enter a valid expiry date.',
      [requiredFields.cvc]: 'Please enter a valid CVC.',
      [requiredFields.cardholderName]: 'Please enter a cardholder name.',
      [requiredFields.zipcode]: 'Please enter a valid zipcode.',
    }),
    []
  )

  const [cardNumberErrorMsg, setCardNumberErrorMsg] = useState<string | null>(
    null
  )
  const [expiryDateErrorMsg, setExpiryDateErrorMsg] = useState<string | null>(
    null
  )
  const [cvcErrorMsg, setCvcErrorMsg] = useState<string | null>(null)
  const [cardholderError, setCardholderError] = useState(false)
  const [zipcodeError, setZipcodeError] = useState(false)

  const [lockErrors, setLockErrors] = useState({
    [requiredFields.cardNumber]: true,
    [requiredFields.expiryDate]: true,
    [requiredFields.cvc]: true,
    [requiredFields.cardholderName]: true,
    [requiredFields.zipcode]: true,
  })

  // If it is first card, set as default
  const isFirstCard = useMemo(() => !creditCards?.length, [creditCards])
  const everyOtherCardisExpired = useMemo(
    () => Boolean(creditCards?.length && creditCards?.every(isExpiredCard)),
    [creditCards]
  )
  const isDefaultTooltipMessage = useMemo(
    () =>
      !everyOtherCardisExpired
        ? 'This card will be used as the default payment method. You can always change the default.'
        : "This patient's other credit cards have expired, so this one will be set as the default",
    [everyOtherCardisExpired]
  )
  const isEditingMode = useMemo(
    () => Boolean(oldCreditCardValues),
    [oldCreditCardValues]
  )
  const isDefaultDisabled = useMemo(
    () => oldCreditCardValues?.isDefault || everyOtherCardisExpired,
    [oldCreditCardValues]
  )
  const editTitle = useMemo(
    () =>
      `${oldCreditCardValues?.cardIssuer} **** ${oldCreditCardValues?.lastFour}`,
    [oldCreditCardValues]
  )
  const expiryDate = useMemo(
    () =>
      `${oldCreditCardValues?.expMonth}/${oldCreditCardValues?.expYear
        ?.toString()
        .slice(2, 4)}`,
    [oldCreditCardValues]
  )

  const submitButtonContent = isSubmitting ? (
    <span>
      Save <Spinner fontSize={20} />
    </span>
  ) : (
    <span>Save</span>
  )

  const stripe = useStripe()
  const elements = useElements()

  const handleUpdateLockErrors = (key: requiredFields, value: boolean) => {
    setLockErrors({ ...lockErrors, [key]: value })
  }

  const handleCleanForm = () => {
    setIsSubmitting(false)
    setCardNickname(null)
    setZipcode('')
    setCardholderName('')
    setIsDefaultCard(everyOtherCardisExpired)

    // Stripe
    const cardNumberElement = elements?.getElement('cardNumber')
    cardNumberElement?.clear()
    const cardExpiryElement = elements?.getElement('cardExpiry')
    cardExpiryElement?.clear()
    const cardCvcElement = elements?.getElement('cardCvc')
    cardCvcElement?.clear()

    // Initialize render errors
    setCardNumberErrorMsg(null)
    setExpiryDateErrorMsg(null)
    setCvcErrorMsg(null)
    setCardholderError(false)
    setZipcodeError(false)

    // Initialize Errors to prevent submit
    setLockErrors({
      [requiredFields.cardNumber]: true,
      [requiredFields.expiryDate]: true,
      [requiredFields.cvc]: true,
      [requiredFields.cardholderName]: true,
      [requiredFields.zipcode]: true,
    })
  }

  const handleCloseForm = () => {
    handleCleanForm()
    onCloseModal()
  }

  const handleInitForm = () => {
    setCardNickname(oldCreditCardValues?.nickname || null)
    let isZipcodeError = true
    let isCardholderNameError = true
    if (oldCreditCardValues?.zipCode) {
      setZipcode(oldCreditCardValues?.zipCode)
      setZipcodeError(false)
      isZipcodeError = false
    }
    if (oldCreditCardValues?.cardholderName) {
      setCardholderName(oldCreditCardValues?.cardholderName)
      setCardholderError(false)
      isCardholderNameError = false
    }

    setIsDefaultCard(
      oldCreditCardValues?.isDefault || everyOtherCardisExpired || false
    )

    setCvcErrorMsg(null)
    setCardNumberErrorMsg(null)
    setExpiryDateErrorMsg(null)
    // Initialize Errors to prevent submit
    setLockErrors({
      [requiredFields.cardNumber]: false,
      [requiredFields.expiryDate]: false,
      [requiredFields.cvc]: false,
      [requiredFields.cardholderName]: isCardholderNameError,
      [requiredFields.zipcode]: isZipcodeError,
    })
  }

  useEffect(() => {
    if (isVisible && !isEditingMode) {
      handleCleanForm()
    }
  }, [isVisible])

  useEffect(() => {
    setIsDefaultCard(everyOtherCardisExpired)
  }, [everyOtherCardisExpired])

  useEffect(() => {
    if (isEditingMode) {
      handleInitForm()
    }
  }, [isEditingMode])

  const handleZipcodeChange = (e: any) => {
    e.preventDefault()
    const newZipcode = e.target.value

    const isError =
      (zipcode.length > newZipcode.length ||
        newZipcode.length > 4 ||
        zipcodeError) &&
      !validateZipCode(newZipcode)
    setZipcodeError(isError)
    handleUpdateLockErrors(requiredFields.zipcode, !validateZipCode(newZipcode))
    setZipcode(newZipcode)
  }

  const handleZipcodeOnBlurValidation = (newZipcode: string) => {
    const isError = !validateZipCode(newZipcode)
    setZipcodeError(isError)
    handleUpdateLockErrors(requiredFields.zipcode, isError)
  }

  const handleCardholderNameOnBlurValidation = (newCardholderName: string) => {
    const isError =
      (cardholderName && !newCardholderName) || newCardholderName === ''
    setCardholderError(isError)
    handleUpdateLockErrors(requiredFields.cardholderName, isError)
  }

  const handleCardholderNameChange = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    e.preventDefault()
    const newCardholderName = e.target.value
    handleCardholderNameOnBlurValidation(newCardholderName)
    setCardholderName(newCardholderName)
  }

  const handleStripeFieldError = (
    e:
      | StripeCardNumberElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | StripeCardExpiryElementChangeEvent,
    fieldName: requiredFields,
    setError: (value: React.SetStateAction<string | null>) => void
  ) => {
    let error = null
    // This condition is made to avoid a problem with StripeCardNumberElementChangeEvent that refused to clean after close modal.
    if (
      e.empty &&
      (Object.keys(e).includes('brand')
        ? (e as StripeCardNumberElementChangeEvent).brand
        : true)
    ) {
      error = errorMessages[fieldName]
    } else if (e.error) {
      error = e.error.message
    }
    setError(error)
    handleUpdateLockErrors(requiredFields[fieldName], Boolean(error))
  }

  const handleCardNumberChange = (e: StripeCardNumberElementChangeEvent) => {
    handleStripeFieldError(e, requiredFields.cardNumber, setCardNumberErrorMsg)
  }

  const handleCvcChange = (e: StripeCardCvcElementChangeEvent) => {
    handleStripeFieldError(e, requiredFields.cvc, setCvcErrorMsg)
  }

  const handleExpiryDateChange = (e: StripeCardExpiryElementChangeEvent) => {
    handleStripeFieldError(e, requiredFields.expiryDate, setExpiryDateErrorMsg)
  }

  const handleCreditCardCreate = async () => {
    setIsSubmitting(true)
    const cardElement = elements?.getElement(CardNumberElement)

    if (!stripe || !cardElement) {
      notification('Unable to add card at this time.')
      setIsSubmitting(false)
      return
    }

    const { error, paymentMethod: stripeResponse } =
      await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardholderName,
          address: { postal_code: zipcode },
        },
      })

    if (error) {
      const errorMessage = handleStripeError(
        error,
        'There was an error adding the card. Please check card details and try again.'
      )
      notification(errorMessage)
      setIsSubmitting(false)
      return
    }

    const cardDetails = stripeResponse?.card
    const lastFour = cardDetails?.last4
    const cardIssuer = cardDetails?.brand
    const expMonth = cardDetails?.exp_month
    const expYear = cardDetails?.exp_year
    const externalServiceId = stripeResponse?.id
    const externalService = EXTERNAL_SERVICES.STRIPE
    const paymentMethodData = {
      lastFour,
      cardIssuer,
      expMonth,
      expYear,
      externalServiceId,
      externalService,
      nickname: cardNickname,
      type: PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD,
      patientId,
      cardholderName,
      zipCode: zipcode,
      isDefault: isFirstCard || isDefaultCard,
    }

    try {
      await createPaymentMethod(paymentMethodData)
      handleNewCreditCardCreated()
      setIsSubmitting(false)
      notification(`${cardIssuer} **** ${lastFour} added.`, 'success')
      handleCloseForm()
    } catch (err) {
      const errorMessage = handleStripeError(
        err,
        'Something went wrong. Please try again.'
      )
      notification(errorMessage)
      setIsSubmitting(false)
    }
  }

  const handleCreditCardUpdate = async () => {
    setIsSubmitting(true)
    if (oldCreditCardValues?.uuid) {
      const paymentMethodData = {
        cardholderName,
        cardNickname,
        isDefault: isDefaultCard,
        patientId,
        uuid: oldCreditCardValues.uuid,
        zipCode: zipcode,
      }
      try {
        await updatePaymentMethod(paymentMethodData)
        handleNewCreditCardCreated()
        setIsSubmitting(false)
        notification(`${editTitle} successfully edited.`, 'success')
        handleCloseForm()
      } catch (err) {
        const errorMessage = handleStripeError(
          err,
          'Something went wrong. Please try again.'
        )
        setIsSubmitting(false)
        notification(errorMessage)
      }
    } else {
      setIsSubmitting(false)
      notification('Something went wrong. Please try again.')
    }
  }

  const okAction = isEditingMode
    ? handleCreditCardUpdate
    : handleCreditCardCreate

  const handleNicknameChange = (e: any) => {
    e.preventDefault()
    setCardNickname(e.target.value)
  }

  const stripeStyles = {
    base: {
      '::placeholder': {
        color: 'lightgrey',
      },
    },
  }

  return (
    <Modal
      visible={isVisible}
      title={isEditingMode ? `Edit ${editTitle}` : 'Add credit card'}
      onCancel={handleCloseForm}
      onOk={okAction}
      closable={!isSubmitting}
      maskClosable={!isSubmitting}
      footer={[
        isEditingMode && (
          <DeleteCreditCardModal
            key="delete-credit-card-modal"
            isButton
            creditCard={oldCreditCardValues}
            creditCards={creditCards || []}
            isDefault={isDefaultDisabled}
            onDelete={handleNewCreditCardCreated}
            patientId={patientId}
            style={{ float: 'left' }}
          />
        ),
        <Button
          key="cancel-credit-card-modal"
          disabled={isSubmitting}
          onClick={handleCloseForm}
        >
          Cancel
        </Button>,
        <Button
          key="ok-credit-card-modal"
          disabled={
            isSubmitting || Object.values(lockErrors).some((field) => field)
          }
          onClick={okAction}
        >
          {submitButtonContent}
        </Button>,
      ]}
    >
      <div className={styles.iconRow}>
        <span className={styles.creditCardIcon}>
          <VisaIcon height="32px" />
        </span>
        <span className={styles.creditCardIcon}>
          <MastercardIcon height="32px" />
        </span>
        <span className={styles.creditCardIcon}>
          <AmexIcon height="32px" />
        </span>
        <span className={styles.creditCardIcon}>
          <DiscoverIcon height="32px" />
        </span>
      </div>
      <div className={styles.creditCardFieldRow}>
        <div className={styles.cardNameContainer}>
          <div className={styles.fieldLabel}>
            Card nickname <span className={styles.helpText}>(optional)</span>
          </div>
          <Input
            testId="card-nickname-input"
            value={cardNickname || ''}
            placeholder="e.g., Super special card"
            onChange={handleNicknameChange}
          />
        </div>
      </div>

      <div className={styles.creditCardFieldRow}>
        <div
          className={`${styles.cardNumberContainer} ${
            cardNumberErrorMsg ? styles.errorContainer : ''
          }`}
        >
          <div className={styles.fieldLabel}>
            <span className={styles.requiredFieldMarking}>*</span> Card number
          </div>
          {!isEditingMode ? (
            <CardNumberElement
              data-testid="card-number-input-add"
              className={styles.stripeField}
              onChange={handleCardNumberChange}
              options={{ disabled: isEditingMode, style: stripeStyles }}
            />
          ) : (
            <Input
              testId="card-number-input-edit"
              disabled
              placeholder="1234 1234 1234 1234"
              value={`**** **** **** ${oldCreditCardValues?.lastFour}`}
            />
          )}
          {cardNumberErrorMsg && (
            <div className={styles.errorMessage}>{cardNumberErrorMsg}</div>
          )}
        </div>
      </div>

      <div className={styles.creditCardFieldRow}>
        <div
          className={`${styles.expiryDateContainer} ${
            expiryDateErrorMsg ? styles.errorContainer : ''
          }`}
        >
          <div className={styles.fieldLabel}>
            <span className={styles.requiredFieldMarking}>*</span> Expiration
            date (MM/YY)
          </div>
          {!isEditingMode ? (
            <CardExpiryElement
              className={styles.stripeField}
              onChange={handleExpiryDateChange}
              options={{ disabled: isEditingMode, style: stripeStyles }}
            />
          ) : (
            <Input
              testId="card-expiry-date-input-edit"
              disabled
              placeholder="MM/YY"
              value={expiryDate}
            />
          )}
          {expiryDateErrorMsg && (
            <div className={styles.errorMessage}>{expiryDateErrorMsg}</div>
          )}
        </div>

        <div
          className={`${styles.cvcContainer} ${
            cvcErrorMsg ? styles.errorContainer : ''
          }`}
        >
          <div className={styles.fieldLabel}>
            <span className={styles.requiredFieldMarking}>*</span>Security code
            <Tooltip title="3 or 4 digit code on the front or back of your card.">
              <QuestionCircleOutlined style={{ color: '#434343' }} />
            </Tooltip>
          </div>
          {!isEditingMode ? (
            <CardCvcElement
              className={styles.stripeField}
              onChange={handleCvcChange}
              options={{ disabled: isEditingMode, style: stripeStyles }}
            />
          ) : (
            <Input
              testId="card-security-code-input-edit"
              disabled
              placeholder="CSV"
              value="***"
            />
          )}
          {cvcErrorMsg && (
            <div className={styles.errorMessage}>{cvcErrorMsg}</div>
          )}
        </div>
      </div>

      <div className={styles.creditCardFieldRow}>
        <div
          className={`${styles.cardholderContainer} ${
            cardholderError ? styles.errorContainer : ''
          }`}
        >
          <div className={styles.fieldLabel}>
            <span className={styles.requiredFieldMarking}>*</span> Name on card
          </div>
          <Input
            testId="card-cardholder-name-input"
            value={cardholderName}
            placeholder="Name on card"
            onChange={handleCardholderNameChange}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleCardholderNameOnBlurValidation(e.target.value)
            }
          />
          {cardholderError && (
            <div className={styles.errorMessage}>
              {errorMessages.cardholderName}
            </div>
          )}
        </div>

        <div
          className={`${styles.zipcodeContainer} ${
            zipcodeError && styles.errorContainer
          }`}
        >
          <div className={styles.fieldLabel}>
            <span className={styles.requiredFieldMarking}>*</span> Billing zip
            code
            <Tooltip title="Enter the ZIP code for your card's billing address.">
              <QuestionCircleOutlined style={{ color: '#434343' }} />
            </Tooltip>
          </div>
          <Input
            testId="card-billing-zipcode-input"
            placeholder="Zip code"
            value={zipcode}
            onChange={handleZipcodeChange}
            onBlur={() => handleZipcodeOnBlurValidation(zipcode)}
          />
          {zipcodeError && (
            <div className={styles.errorMessage}>{errorMessages.zipcode}</div>
          )}
        </div>
      </div>
      {!isFirstCard ? (
        <div className={styles.creditCardFieldRow}>
          <div className={styles.isDefaultCheckbox}>
            <Input
              testId="card-is-default-input"
              onChange={({ target }) => setIsDefaultCard(target.checked)}
              checked={isDefaultCard}
              disabled={isDefaultDisabled}
              type="checkbox"
            />
            <div
              className={`${styles.fieldLabel} ${
                isDefaultDisabled ? 'disabled' : ''
              }`}
            >
              Set as default payment method
            </div>
            <Tooltip title={isDefaultTooltipMessage}>
              <QuestionCircleOutlined style={{ color: '#434343' }} />
            </Tooltip>
          </div>
        </div>
      ) : null}
    </Modal>
  )
}
