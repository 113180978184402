import { DateISO8601 } from '../../../shared-types'

export enum YesNoMaybeNA {
  YES = 'Yes',
  NO = 'No',
  MAYBE = 'Not known',
  NA = 'N/A',
}

export enum MedicalIssue {
  // Autoimmune
  AUTOIMMUNE = 'Autoimmune, including arthritis', // legacy
  AUTOIMMUNE_NEW = 'Autoimmune Disorders (Including Arthritis)', // new
  LUPUS = 'Lupus',
  MULTIPLE_SCLEROSIS = 'Multiple Sclerosis (MS)',
  MYASTHENIA_GRAVIS = 'Myasthenia Gravis',
  NARCOLEPSY = 'Narcolepsy',
  OSTEO_ARTHRITIS = 'Osteoarthritis',
  RHEUM_ARTHRITIS = 'Rheumatoid Arthritis (RA)',

  // Cancer
  CANCER = 'Cancer',
  CANCER_ANAL_CANAL = 'Anal Cancer',
  CANCER_BILE_DUCT = 'Bile Duct Cancer (Including Cholangiocarcinoma)',
  CANCER_BLADDER = 'Bladder Cancer',
  CANCER_BONE = 'Bone Cancer',
  CANCER_BRAIN = 'Brain Cancer',
  CANCER_BREAST = 'Breast Cancer',
  CANCER_CERVICAL = 'Cervical Cancer',
  CANCER_COLON = 'Colon Cancer (Large Bowel)',
  CANCER_EYE = 'Eye Cancer',
  CANCER_GALLBLADDER = 'Gallbladder Cancer',
  CANCER_INTESTINAL = 'Intestinal Cancer (Small Bowel)',
  CANCER_KIDNEY = 'Kidney Cancer',
  CANCER_LARYNGEAL = 'Laryngeal Cancer (Throat Cancer)',
  CANCER_LEUKEMIA = 'Leukemia',
  CANCER_LIVER = 'Liver Cancer',
  CANCER_LUNG = 'Lung Cancer',
  CANCER_LYMPHOMA = 'Lymphoma',
  CANCER_MOUTH = 'Mouth Cancer',
  CANCER_NASOPHARYNGEAL = 'Nasopharyngeal Cancer',
  CANCER_OVARIAN = 'Ovarian Cancer',
  CANCER_PANCREATIC = 'Pancreatic Cancer',
  CANCER_PENILE = 'Penile Cancer',
  CANCER_PROSTATE = 'Prostate Cancer',
  CANCER_RECTAL = 'Rectal Cancer',
  CANCER_STOMACH = 'Stomach Cancer',
  CANCER_TESTICULAR = 'Testicular Cancer',
  CANCER_THYROID = 'Thyroid Cancer',
  CANCER_UTERINE = 'Uterine Cancer',
  CANCER_VAGINAL = 'Vaginal Cancer',
  CANCER_VULVAR = 'Vulvar Cancer',

  // Chronic Pain
  CHRONIC_PAIN = 'Chronic pain, CRPS, or any pain disorder', // legacy
  CHRONIC_PAIN_NEW = 'Chronic Pain', // new
  CHRONIC_PAIN_BACK = 'Chronic Back Pain',
  CHRONIC_PAIN_NECK = 'Chronic Neck Pain',
  CHRONIC_PAIN_CRPS = 'Complex Regional Pain Syndrome (CRPS)',
  CHRONIC_PAIN_FIBROMYALGIA = 'Fibromyalgia',
  CHRONIC_PAIN_NEUROPATHIC = 'Neuropathic Pain',
  CHRONIC_PAIN_PHANTOM_LIMB = 'Phantom Limb Pain',
  CHRONIC_PAIN_SHINGLES = 'Postherpetic Neuralgia (Shingles)',
  CHRONIC_PAIN_RSDS = 'Reflex Sympathetic Dystrophy Syndrome (RSDS)',
  CHRONIC_PAIN_TRIGEMINAL_NEURALGIA = 'Trigeminal Neuralgia',

  // Diabetes
  DIABETES = 'Diabetes', // legacy
  DIABETES_NEW = 'Endocrine Disorder (Including Diabetes and Thyroid)', // new
  DIABETES_TYPE_1 = 'Diabetes Type 1',
  DIABETES_TYPE_2 = 'Diabetes Type 2',
  DIABETES_HYPERGLYCEMIA = 'Hyperglycemia',
  DIABETES_GOITER = 'Goiter (Thyroid Enlargement)',
  DIABETES_GRAVES = 'Graves Disease',
  DIABETES_HYPOTHYROID = 'Hypothyroidism',
  DIABETES_HYPERTHYROID = 'Hyperthyroidism',

  // Ear, nose, throat
  EAR_NOSE_THROAT = 'Ear, Nose, or Throat Disorder',
  ALLERGIC_RHINITIS = 'Allergic Rhinitis',
  HEARING_LOSS = 'Hearing Loss',
  NASAL_OBSTRUCTION = 'Nasal obstruction',

  // Eye disorders
  EYE_DISORDER = 'Eye Disorder',
  BLIND = 'Blind or Low Vision',
  CATARACT = 'Cataract',
  GLAUCOMA = 'Glaucoma',

  // Gastrointestinal
  GID = 'Gastrointestinal Disorder',
  CROHNS = "Crohn's Disease",
  DIVERTICULITIS = 'Diverticulitis',
  DYSPHAGIA = 'Dysphagia (Swallowing Problems)',
  GERD = 'Gastro-Esophageal Reflux Disease (GERD)',
  HIATAL_HERNIA = 'Hiatal Hernia',
  IBS = 'Irritable Bowel Syndrome (IBS)',
  PANCREATITIS = 'Pancreatitis',
  UCLER = 'Stomach Ulcer',
  ULCERATIVE_COLITIS = 'Ulcerative Colitis',

  // General conditions
  FATIGUE = 'Chronic Fatigue Syndrome',
  INSOMNIA = 'Insomnia',
  OBESITY = 'Obesity',
  WEIGHT_GAIN = 'Unintentional Weight Gain',
  WEIGHT_LOSS = 'Unintentional Weight Loss',
  HIGH_BLOOD_PRESSURE = 'High Blood Pressure',

  // Genitourinary
  GENITOURINARY = 'Genitourinary Disorder',
  CYSTITIS = 'Cystitis',
  ENDOMETRIOSIS = 'Endometriosis',
  FIBROIDS = 'Fibroids',
  ENLARGED_PROSTATE = 'Prostate Enlargement (BPH)',

  // Headaches
  HEADACHES_MIGRAINES = 'Severe headaches or migraines', // legacy
  HEADACHES_NEW = 'Headaches (Including Migraines)', // new
  HEADACHES_POST_TRAUMATIC = 'Chronic Post Traumatic Headache',
  HEADACHES_CLUSTER = 'Cluster Headache',
  MIGRAINE = 'Migraine', // new
  HEADACHES_TENSION = 'Tension Headache',

  // Head trauma
  HEAD_TRAUMA = 'Head trauma, concussion, or head injuries',
  HEAD_TRAUMA_NEW = 'Head Trauma',
  CONCUSSION_UNCONSCIOUS = 'Concussion with Loss of Consciousness',
  CONCUSSION_CONSCIOUS = 'Concussion with No Loss of Consciousness',
  TBI = 'Traumatic Brain Injury (TBI)',

  // Heart
  HEART_PROBLEMS = 'Heart problems',
  HEART_CONDITIONS = 'Heart Condition',
  CARDIAC_ARRHYTHMIA = 'Cardiac Arrhythmia',
  CON_HEART_FAILER = 'Congestive Heart Failure',
  CORONARY_ARTERY = 'Coronary Artery Disease',
  HEART_ATTACK = 'Myocardial Infarction (Heart Attack)',

  // Kidney
  KIDNEY_DISEASE = 'Kidney Disease',
  KIDNEY_CHRONIC = 'Chronic Kidney Disease',
  KIDNEY_STONE = 'Kidney Stone',

  // Liver
  LIVER_DISEASE = 'Liver Disease (Including Gallbladder)',
  LIVER_ALCOHOL = 'Alcohol-Related Liver Disease',
  CHOLECYSTITIS = 'Cholecystitis',
  CIRRHOSIS = 'Cirrhosis',
  GALLSTONES = 'Gallstones',
  NAFLD = 'Non-Alcoholic Fatty Liver Disease (NAFLD)',
  HEPATITIS = 'Viral Hepatitis',

  // Lung
  LUNG_DISORDER = 'Lung/Respiratory Disorder',
  ASTHMA = 'Asthma',
  BRONCHITIS = 'Chronic Bronchitis',
  COPD = 'Chronic Obstructive Pulmonary Disease (COPD)',
  EMPHYSEMA = 'Emphysema',
  SLEEP_APNEA = 'Obstructive Sleep Apnea',
  DYSPNEA = 'Shortness of Breath/Dyspnea',

  // Seizures
  EPILEPSY_SEIZURES = 'Epilepsy/seizures', // legacy
  SEIZURE = 'Seizures', // new
  EPILEPSY = 'Epilepsy', // new

  // Sex
  SEX_DYSFUNCTION = 'Sexual Dysfunction',
  IMPOTENCE = 'Erectile Dysfunction (Impotence)',
  FEMALE_AROUSAL_DISORDER = 'Female Sexual Arousal Disorder',
  SEXUAL_DESIRE_LOSS = 'Lack or Loss of Sexual Desire',

  // Skin
  SKIN_DISORDER = 'Skin Disorders',
  DERMATITIS = 'Contact Dermatitis',
  ECZEMA = 'Eczema',
  EDS = 'Ehlers Danlos Syndrome (EDS)',
  PSORIASIS = 'Psoriasis',

  // Stroke
  STROKE = 'Stroke',
  STROKE_CEREBROVASCULAR = 'Cerebrovascular Disease (Including Stroke)', // new
  STROKE_CLOT = 'Cerebral Embolism (Clot-Type Stroke)',
  STROKE_HEMORRHAGE = 'Intracranial Hemorrhage (Bleeding-Type Stroke)',
  STROKE_TIA = 'Transient Ischemic Attack (TIA)',

  // Other legacy
  THYROID = 'Thyroid',
  OTHER = 'Other [describe]',
}

type Notes = string

interface DateField {
  date?: string
  startDate?: string
  endDate?: string
  format: string
}

interface Hospitalization {
  reason: Notes
  when: Notes
}

interface Pregnancy {
  resultOfPregnancy: Notes
  year: DateField
}

interface Surgery {
  additionalNotes: Notes
  complications: Notes
  name: Notes
  reason: Notes
  year: DateField
}

export interface ConditionDetails {
  comments: Notes
  year: DateField | string
}

interface Description {
  description: Notes
}

type Condition = {
  autoimmune: ConditionDetails
  cancer: ConditionDetails
  chronicPain: ConditionDetails
  diabetes: ConditionDetails
  epilepsySeizures: ConditionDetails
  headachesMigraines: ConditionDetails
  heartProblems: ConditionDetails
  headTrauma: ConditionDetails
  highBloodPressure: ConditionDetails
  stroke: ConditionDetails
  thyroid: ConditionDetails
  other: Description[]
  [medicalIssue: string]: ConditionDetails | Description[]
}

export interface MedicalSurgicalResponse {
  medicalConditions: MedicalIssue[]
  medicalConditionsDetails: Condition
  hasHadNoRelevantMedHistory: boolean
  isPregnantOrNursing: YesNoMaybeNA
  previousPregnancies: Pregnancy[]
  pregnancyNotes: Notes
  hasNoPregnancyHistory: boolean
  surgicalHistory: Surgery[]
  hasHadNoSurgeries: boolean
  hospitalizations: Hospitalization[]
  hasHadNoHospitalizations: boolean
  isInCareFacility: boolean
  isInCareFacilityExplanation: Notes
  hasImplantedDevices: boolean
  hasImplantedDevicesExplanation: Notes
  additionalNotes: Notes
  createdAt: DateISO8601
}
