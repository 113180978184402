import React from 'react'

import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

import { ReactComponent } from './SidebarOutlined.svg'

export default function SidebarOutlined(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon {...props} component={ReactComponent} />
}
