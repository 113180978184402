import React, { CSSProperties } from 'react'

import { differenceInMinutes, format, isSameDay, isValid } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { DateHeaderProps, Event, HeaderProps } from 'react-big-calendar'

import { isSameHourAndMinutes } from '../../../libs/utils'
import { Badge, Divider, Typography } from '../../BaseComponents'
import { DEFAULT_EVENT_COLOR } from '../constants'
import { convertHexToRGBA } from '../helpers'

import './MonthView.scss'

interface MonthEventProps {
  colorMap: Map<string, string>
  event: Event
  title: string
  timezone: string
}

export const MonthHeader: React.ComponentType<HeaderProps> = ({ label }) => {
  return (
    <div className="month-view-header-container">
      <Typography.Text>{label}</Typography.Text>
    </div>
  )
}

export const MonthDate: React.ComponentType<DateHeaderProps> = ({
  label,
  date,
}) => {
  const sameDay = isSameDay(date, new Date())

  return (
    <div className="date-container">
      <Divider
        orientation="center"
        className={'date-separator ' + (sameDay ? 'active' : '')}
      />
      <Typography.Text>{label}</Typography.Text>
    </div>
  )
}

export const MonthEvent: React.ComponentType<MonthEventProps> = ({
  colorMap,
  event,
  title,
  timezone,
}) => {
  const { allDay, end = '', resource, start = '' } = event

  const { AppointmentType: appointmentType } = resource

  const hasAppointmentColor = Boolean(colorMap.get(appointmentType))
  const sliverColor = hasAppointmentColor
    ? colorMap.get(appointmentType)
    : DEFAULT_EVENT_COLOR
  // TO DO devise a way to pass event color (from provider, room or just event itself)
  const backgroundColor = convertHexToRGBA(
    sliverColor || DEFAULT_EVENT_COLOR,
    0.1
  )
  const hoverColor = convertHexToRGBA(sliverColor || DEFAULT_EVENT_COLOR, 0.15)

  const endTime = new Date(end)
  const startTime = new Date(start)

  const duration = differenceInMinutes(endTime, startTime)
  const isSameTime = isSameHourAndMinutes(startTime, endTime)
  const isDefinedAllDay = allDay || duration === 0
  const isMultiDay = duration >= 1440

  const startTimeFormatted = format(
    utcToZonedTime(start || new Date(), timezone),
    "hh:mmaaaaa'm'"
  )

  if (isDefinedAllDay || isMultiDay) {
    const sliver = sliverColor && (
      <span
        className="sliver-event"
        style={{
          backgroundColor: sliverColor,
          marginRight: '1px',
          width: '28px',
        }}
      />
    )

    let timespan = <span />

    if (isValid(endTime) && !isDefinedAllDay && !isSameTime) {
      const endTimeFormatted = format(
        utcToZonedTime(end, timezone),
        "hh:mmaaaaa'm'"
      )

      timespan = (
        <Typography.Text className="event-text">
          {` ${startTimeFormatted} - ${endTimeFormatted}`}
        </Typography.Text>
      )
    }

    const monthAllDayStyle = {
      '--event-color': backgroundColor,
      '--hover-color': hoverColor,
    } as CSSProperties

    return (
      <span className="month-allday" style={monthAllDayStyle}>
        {sliver}
        <Typography.Text className="event-text event-title">
          {title}
        </Typography.Text>
        {timespan}
      </span>
    )
  }

  return (
    <span className="event-content">
      <Badge className="event-badge" color={backgroundColor} />
      <Typography.Text className="event-text">
        {startTimeFormatted}
      </Typography.Text>
      <Typography.Text className="event-text event-title">
        {title}
      </Typography.Text>
    </span>
  )
}
