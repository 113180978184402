import React, { useState } from 'react'

interface ImageWithFallbackProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallback?: React.ReactNode // Component to show when the image fails to load
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  fallback,
  alt,
  ...props
}) => {
  const [hasError, setHasError] = useState(false)

  const handleError = () => {
    setHasError(true)
  }

  if (hasError) {
    return fallback ? (
      <>{fallback}</>
    ) : (
      <span>Image not available</span> // Default empty state
    )
  }

  return <img src={src} alt={alt} onError={handleError} {...props} />
}

export default ImageWithFallback
