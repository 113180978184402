import { globalConfig } from '../config/config'
import { notification } from './notificationLib'
import Sentry, { supportedEnvs as sentrySupportedEnvs } from './sentry'

export function logError(error, errorInfo = null, tags = null) {
  const config = globalConfig.get()
  if (config.ENV === 'test') {
    console.warn(
      'Sentry is not supported in test environment. You should probably mock Sentry.'
    )
    return
  } else if (!sentrySupportedEnvs.includes(config.ENV)) {
    console.warn(`Sentry is not supported in ${config.ENV} environment`)
    return
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo)
    tags && scope.setTags(tags)
    Sentry.captureException(error)
  })
}

export function onError(error, statusCode, userError) {
  let errorInfo = {}
  let message = error.toString()

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error
    message = error.message
    error = new Error(message)
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url
  }

  logError(error, errorInfo)

  console.error(`🚫Error: ${error}`, error)

  const userErrorMessage =
    userError ||
    `An error has occured. Please report this error to your administrator. ${error}`

  notification(userErrorMessage, 'error')
}
