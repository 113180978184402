import { DiagnosisHistory } from '../../../../../../../shared-types'
import { Table } from '../../../../../../../stories/BaseComponents'
import { getColumns, serializeRowData } from './DiagnosesBlockTypes'

import styles from '../../../styles.module.scss'

export const DiagnosesBlockTable = ({
  blockData,
  isSigned,
  patientName,
  isLoading,
  onRowEdit,
}: {
  blockData: DiagnosisHistory[] | undefined
  isSigned: boolean
  isDrFirst?: boolean
  patientName: string
  isLoading: boolean
  onRowEdit?: (diagnosisId: string | null) => void
}) => {
  const columns = getColumns({ isSigned, onRowEdit })
  const data = serializeRowData(blockData)

  return (
    <Table
      addClassNames={[
        'no-padding',
        'no-table-tools-section',
        'alternate-colors',
        styles.tableBlock,
      ]}
      rowKey={'key'}
      columns={columns}
      dataSource={data}
      loading={isLoading}
      pagination={false}
      locale={{
        emptyText: isLoading ? (
          'Loading'
        ) : (
          <div className={styles['empty-state']}>
            {patientName} has no active diagnoses recorded
          </div>
        ),
      }}
    />
  )
}
