import {
  DateFormat,
  FieldStyling,
  GridLayout,
  TemplateFields,
} from '../../../components/Forms/FormMachine/types'

export const styling: FieldStyling = {
  editColClassName: 'col-bottom-edit',
  colClassName: 'bottom-line',
  editLabelClassName: 'field-label-edit',
  editValueClassName: 'field-value-edit',
  labelClassName: 'col-squished field-label',
  valueClassName: 'field-value',
}

const noBottomBorder = {
  ...styling,
  editColStyle: { borderBottom: 'none' },
  colStyle: { borderBottom: 'none' },
}

export const gridLayout: GridLayout = [
  { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
]

const fullWidth = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }
const halfWidth = { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }

export const template: TemplateFields = [
  {
    addFieldLabel: 'Add new',
    columnSizes: fullWidth,
    customStyling: noBottomBorder,
    fieldType: 'multiple',
    id: 'immunizations',
    label: 'Immunizations',
    multipleComponent: [
      {
        columnSizes: halfWidth,
        customStyling: noBottomBorder,
        fieldType: 'text',
        label: 'Name',
        id: 'name',
      },
      {
        columnSizes: halfWidth,
        dateType: DateFormat.MONTH_DAY_YEAR,
        customStyling: noBottomBorder,
        fieldType: 'datepicker',
        label: 'Date',
        id: 'date',
      },
      {
        columnSizes: fullWidth,
        customStyling: noBottomBorder,
        fieldType: 'text',
        label: 'Notes',
        id: 'notes',
      },
    ],
  },
  {
    columnSizes: gridLayout[0],
    fieldType: 'textarea',
    id: 'additionalNotes',
    label: 'Additional notes',
    placeholder: 'Any additional notes',
  },
]
