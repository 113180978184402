import { Link } from 'react-router-dom'

import styles from './NavTableItem.module.scss'

export type NavTableItemProps = {
  isNavigationAllowed: boolean
  linkText: string
  url: string
}

export const NavTableItem = ({
  isNavigationAllowed,
  linkText,
  url,
}: NavTableItemProps) => {
  if (!isNavigationAllowed) {
    return <p className={styles.textContainer}>{linkText}</p>
  }
  return (
    <Link to={url} className={styles.link}>
      {linkText}
    </Link>
  )
}
