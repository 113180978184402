import React from 'react'

import { FormInstance } from 'antd'
import Form from 'antd/lib/form'

import {
  Col,
  Divider,
  Input,
  Radio,
  Row,
  TextArea,
  Typography,
} from '../../../BaseComponents'
import {
  CHARACTER_LIMIT,
  normalizeText,
  numberDecimalRange,
  numberRange,
  optionalFreeText,
  required,
} from '../../validation-rules'
import { Header } from '../helpers'
import { MedHistoryNonPsychQuestionKeys } from '../question-keys'
import ConditionsList from './ConditionsList'

import styles from '../_shared.module.scss'

interface Props {
  form: FormInstance
  reviewMode?: boolean
}

const { Title, Text } = Typography
const pregnantOrNursingOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'Unknown', value: 'Unknown' },
]

const MedicalHistory = ({ form, reviewMode = false }: Props) => {
  return (
    <>
      <Title level={2}>Medical History</Title>
      <Divider />
      <Header title="Medical conditions" />
      <Form.Item
        label="Do you have, or have you ever had, any of the following issues/conditions/diagnoses?"
        name={MedHistoryNonPsychQuestionKeys.CONDITIONS}
      >
        <ConditionsList reviewMode={reviewMode} form={form} />
      </Form.Item>
      <Form.Item
        name={MedHistoryNonPsychQuestionKeys.OTHER_CONDITIONS}
        label="Please list any other non-psychiatric medical conditions you have been diagnosed with and provide any additional pertinent details about any diagnoses selected above."
        rules={[
          optionalFreeText(
            'Please input valid issues, conditions, or diagnoses'
          ),
        ]}
        normalize={normalizeText}
      >
        <TextArea
          disabled={reviewMode}
          maxLength={CHARACTER_LIMIT}
          showCount
          size="large"
          rows={4}
        />
      </Form.Item>
      <Divider />
      <Header title="Additional questions" />
      <Row gutter={16}>
        <Col xs={12} md={6}>
          <Form.Item
            label="Height"
            name={MedHistoryNonPsychQuestionKeys.HEIGHT_FEET}
            rules={[numberRange('Invalid number for feet', 0, 8)]}
            normalize={(value) => Number(value)}
          >
            <Input type="number" min={0} max={8} />
          </Form.Item>
          <Text type="secondary">Feet</Text>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            label=" "
            id={styles.heightInput}
            name={MedHistoryNonPsychQuestionKeys.HEIGHT_INCHES}
            rules={[numberDecimalRange('Invalid number for inches', 0, 11.9)]}
            normalize={(value) => Number(value)}
          >
            <Input type="number" min={0} max={11.9} title="" />
          </Form.Item>
          <Text type="secondary">Inches</Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={12} md={6}>
          <Form.Item
            label="Weight"
            name={MedHistoryNonPsychQuestionKeys.WEIGHT}
            rules={[numberDecimalRange('Invalid number for weight', 0)]}
            normalize={(value) => Number(value)}
          >
            <Input type="number" min={0} title="" />
          </Form.Item>
          <Text type="secondary">Pounds</Text>
        </Col>
      </Row>
      <Form.Item
        label="Are you pregnant or nursing?"
        name={MedHistoryNonPsychQuestionKeys.IS_PREGNANT_OR_NURSING}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          options={pregnantOrNursingOptions}
          optionType="button"
        />
      </Form.Item>
      <Form.Item
        label="Are you planning to get pregnant in the near future?"
        name={MedHistoryNonPsychQuestionKeys.IS_PLANNING_ON_BEING_PREGNANT}
      >
        <Radio.Group buttonStyle="solid" disabled={reviewMode} size="large">
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Have you ever had any surgeries?"
        name={MedHistoryNonPsychQuestionKeys.HAS_PREVIOUS_SURGERIES}
        rules={[required('Please select an option.')]}
      >
        <Radio.Group buttonStyle="solid" disabled={reviewMode} size="large">
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Have you ever been hospitalized, besides for any surgeries or mental health reasons mentioned in the section above?"
        name={MedHistoryNonPsychQuestionKeys.HAS_PREVIOUS_HOSPITALIZATION}
        rules={[required('Please select an option.')]}
      >
        <Radio.Group buttonStyle="solid" disabled={reviewMode} size="large">
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Divider />
    </>
  )
}

export default MedicalHistory
