import { Modal as AntDModal } from 'antd'

interface ConfirmModalProps {
  title: string
  content: string
  okText: string
  cancelText: string
  handleOk: Function
  cancelButtonProps?: Object
  closable?: boolean
  maskClosable?: boolean
}

function ConfirmModal({
  title,
  content,
  okText,
  cancelText,
  handleOk,
  cancelButtonProps = {},
  closable = true,
  maskClosable = true,
}: ConfirmModalProps) {
  AntDModal.confirm({
    title,
    content,
    okText,
    cancelText,
    cancelButtonProps: {
      type: 'default',
      ...cancelButtonProps,
    },
    okButtonProps: {
      type: 'primary',
    },
    onOk: () => handleOk(),
    closable: closable,
    maskClosable: maskClosable,
  })
}

export default ConfirmModal
