import React from 'react'

import { Drawer as AntDDrawer, DrawerProps as AntDDrawerProps } from 'antd'

import { TestId } from '../../shared-types'

type DrawerProps = AntDDrawerProps & TestId

const Drawer: React.FC<DrawerProps> = ({ children, testId, ...props }) => {
  return (
    <AntDDrawer data-testid={testId} {...props}>
      {children}
    </AntDDrawer>
  )
}

export default Drawer
