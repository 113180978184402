export type SuperbillEventName = 'create' | 'replace' | 'pdf' | 'share'
export type SuperbillEventProperties = {
  invoiceId: string
  superbillId?: number
  patientId?: string
}

export const trackSuperbillEvent = (
  eventName: SuperbillEventName,
  eventProperties: SuperbillEventProperties
) => {
  window?.freshpaint?.track(eventName, eventProperties)
}
