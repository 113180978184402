import React, { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Skeleton } from 'antd'
import { cloneDeep } from 'lodash'

import { getPOSCodes } from '../../../api/api-lib'
import SkeletonLoadingTransition from '../../../components/Animation/SkeletonLoadingTransition'
import { AppointmentInfo } from '../../../hooks/usePatientProfile/shared-types'
import { Location } from '../../../shared-types'
import Fields, { FieldGroupProps } from '../../../stories/PatientProfile/Fields'
import { NoteDetail } from '../../../v2/notes/types'
import {
  handleValueUpdateAppointmentInfo,
  populateAppointmentInfo,
} from './InsuranceClaim-helper'
import { appointmentComponent } from './constants'

import styles from './InsuranceClaim.module.scss'

export interface PatientConditionRelationProps {
  testId?: string
  appointmentInfo: AppointmentInfo
  setAppointmentInfo: Function
  loading: boolean
  notes?: NoteDetail[]
  locations?: Location[]
  handleNoteChange: Function
  disabled: boolean
}

const Loading = () => (
  <div className={styles.skeletonContainer}>
    <Skeleton
      active
      title={{ width: 85 }}
      paragraph={{
        rows: 3,
        width: '100%',
        className: styles.skeletonParagraph,
      }}
    />
  </div>
)

interface OptionsType {
  label: string
  value?: string
  disabled?: boolean
}

const AppointmentInformation: React.FC<PatientConditionRelationProps> = ({
  testId,
  appointmentInfo,
  setAppointmentInfo,
  notes = [],
  locations = [],
  loading,
  handleNoteChange,
  disabled = false,
}) => {
  const [renderingProvider, setRenderingProvider] =
    useState<FieldGroupProps[]>(appointmentComponent)

  const { data: posCodes, isLoading: posLoading } = useQuery(
    ['POSCodes'],
    getPOSCodes
  )

  useEffect(() => {
    const itemsCopy: any = cloneDeep(appointmentComponent)
    if (!loading && !posLoading) {
      const posOptions = posCodes.map((p: any) => {
        return {
          label: p.name,
          value: p.code,
        }
      })
      itemsCopy[0].items[6].options = posOptions
      const notesOptions = notes.map((n) => {
        return {
          // use || to catch v1 notes that default titles to empty strings
          label: n.title || 'No Title',
          value: n.noteId,
        }
      })
      itemsCopy[0].items[0].options = notesOptions
      const locationOptions: OptionsType[] = []
      locations?.forEach((l) => {
        locationOptions.push({
          label: l.Name ? l.Name : 'Location',
          value: l.LocationId,
        })
      })
      itemsCopy[0].items[4].options = locationOptions
      if (appointmentInfo) {
        populateAppointmentInfo(itemsCopy, appointmentInfo)
      }
      setRenderingProvider(itemsCopy)
    }
  }, [appointmentInfo, notes, loading, posLoading, posCodes])

  const handleSave = (
    newValue: any,
    id: string,
    _groupName: string,
    _label?: string
  ) => {
    const newVal: any =
      typeof newValue === 'string' ? newValue.trim() : newValue
    const copyAppointmentInfo = cloneDeep(appointmentInfo)
    const shouldSave = handleValueUpdateAppointmentInfo(
      newVal,
      id,
      copyAppointmentInfo,
      notes,
      locations,
      handleNoteChange
    )
    if (shouldSave) {
      setAppointmentInfo(copyAppointmentInfo)
    }
  }

  return (
    <SkeletonLoadingTransition
      isLoading={loading || posLoading}
      skeletonComponent={<Loading />}
      loadedComponent={
        <div className={styles.topMargin}>
          <Fields
            testId={testId}
            items={renderingProvider}
            compact={false}
            handleSave={handleSave}
            isClaim
            disableAll={disabled}
          />
        </div>
      }
    />
  )
}

export default AppointmentInformation
