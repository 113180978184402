import * as React from 'react'

import {
  calculateClaimAndPatientOutstanding,
  convertCentsToDollars,
  formatNumberStringToUsdString,
} from '../../../../libs/billing'
import { InsuranceClaim, Invoice } from '../../../../shared-types'

import styles from '../Billing.module.scss'

export const ErrorSpan = ({
  error,
  testId,
}: {
  error: string
  testId?: string
}) => (
  <div className={styles.errorMessage} data-testid={testId}>
    {error}
  </div>
)

export const InvoiceOption = ({ invoice }: { invoice?: Invoice }) => (
  <>
    <span>Invoice #{invoice?.invoiceNumber} - </span>
    <span className={styles.billableEntityBalance}>
      {formatNumberStringToUsdString(
        convertCentsToDollars(invoice?.amountCentsDue || 0)
      )}
    </span>
  </>
)

export const ClaimOption = ({
  claim,
  claimPatientOutstanding,
  testId,
}: {
  claim: InsuranceClaim
  claimPatientOutstanding: number
  testId?: string
}) => (
  <div data-testid={testId}>
    <span>Claim #{claim?.patientControlNumber} - </span>
    <span className={styles.billableEntityBalance}>
      {claim.patientPaidAmountCents === 0 && claimPatientOutstanding === 0
        ? 'Pending'
        : formatNumberStringToUsdString(
            convertCentsToDollars(
              calculateClaimAndPatientOutstanding(claim)?.patientOutstanding ||
                0
            )
          )}
    </span>
  </div>
)
