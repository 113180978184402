import React, { useEffect, useState } from 'react'

import 'moment-timezone'
import { Button, Col, Form, Row } from 'react-bootstrap'
import 'react-dates/initialize'

import { convertTime12to24 } from '../../../../../shared/Helpers/utils'
import CustomTimePicker from '../../../../../stories/BaseComponents/CustomTimePicker'
import { AutoSizingTextArea } from '../../../../../stories/BaseComponents/TextAreaInput'
import { calculateRelativeDose, calculateTotalDose } from '../calculations'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function ShowEntriesIM(props) {
  const [time, setTime] = useState(props.Time || '')
  const [dose, setDose] = useState(props.Dose || '')
  const [totalDose, setTotalDose] = useState(props.TotalDose || '')
  const [location, setLocation] = useState(props.Location || '')
  const [notes, setNotes] = useState(props.Notes)

  useEffect(() => {
    setTime(props.Time || '')
    setDose(props.Dose || '')
    setTotalDose(props.TotalDose || '')
    setLocation(props.Location || '')
    setNotes(props.Notes || '')
  }, [props.Time, props.Dose, props.TotalDose, props.Location, props.Notes])

  useEffect(() => {
    const fields = {
      Time: time,
      Dose: dose,
      TotalDose: totalDose,
      Location: location,
      Notes: notes,
    }
    props.saveEditEntries(props.index, fields)
  }, [time, dose, totalDose, location, notes])

  return (
    <>
      <tr>
        <td>
          <CustomTimePicker
            value={time}
            onChange={(value) => {
              setTime(convertTime12to24(value))
            }}
            disabled={props.disabled}
          />
        </td>
        <td>
          {!totalDose ? (
            <Form.Control
              bsPrefix="form-input"
              type="number"
              step=".01"
              disabled={props.disabled}
              value={dose}
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(e) => setDose(e.target.value)}
            />
          ) : (
            <Form.Control
              bsPrefix="form-input"
              disabled
              value={
                props.PatientWeight
                  ? props.isBeforeNewDoseCalculation
                    ? calculateTotalDose(
                        totalDose,
                        props.PatientWeight,
                        props.KetamineUnits
                      )
                    : calculateRelativeDose(
                        totalDose,
                        props.PatientWeight,
                        props.KetamineUnits
                      )
                  : ''
              }
            />
          )}
        </td>
        <td>
          {!dose ? (
            <Form.Control
              bsPrefix="form-input"
              type="number"
              step=".01"
              disabled={props.disabled}
              value={totalDose}
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(e) => setTotalDose(e.target.value)}
            />
          ) : (
            <Form.Control
              bsPrefix="form-input"
              disabled
              value={
                props.PatientWeight
                  ? props.isBeforeNewDoseCalculation
                    ? calculateRelativeDose(
                        dose,
                        props.PatientWeight,
                        props.KetamineUnits
                      )
                    : calculateTotalDose(
                        dose,
                        props.PatientWeight,
                        props.KetamineUnits
                      )
                  : ''
              }
            />
          )}
        </td>
        <td>
          <Form.Control
            bsPrefix="form-input"
            style={{ alignSelf: 'center', textAlign: 'center' }}
            value={location}
            disabled={props.disabled}
            onChange={(e) => setLocation(e.target.value)}
          />
        </td>
        <td>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            style={{ alignSelf: 'center', textAlign: 'center' }}
            value={notes}
            as={AutoSizingTextArea}
            minRows={1}
            disabled={props.disabled}
            onChange={(e) => setNotes(e.target.value)}
          />
        </td>
        {!props.disabled && (
          <td className="center">
            <Row>
              <Col>
                <Button
                  bsPrefix="center plain-button info button-label"
                  onClick={() => {
                    props.deleteEntries(props.index)
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true" />
                  &nbsp;Delete
                </Button>
              </Col>
            </Row>
          </td>
        )}
      </tr>
    </>
  )
}
