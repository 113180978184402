import React from 'react'

import generateCalendar, {
  CalendarMode,
  CalendarProps,
} from 'antd/lib/calendar/generateCalendar'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'

import { TestId } from '../../shared-types'

import './_shared.scss'

const Calendar = generateCalendar<Date>(dateFnsGenerateConfig)

export type HeaderRenderFunction = (config: {
  value: Date
  type: CalendarMode
  onChange: (date: Date) => void
  onTypeChange: (type: CalendarMode) => void
}) => React.ReactNode

type MiniCalendarProps = {
  selectedDate?: Date
  onChange: (date: Date) => void
} & TestId

const MiniCalendar: React.FC<MiniCalendarProps> = ({
  onChange,
  selectedDate,
  testId,
}) => {
  const MiniCalendarProps: CalendarProps<Date> = {
    fullscreen: false,
    value: selectedDate,
    onChange,
  }

  return (
    <div className="mini-calendar-container">
      <Calendar data-testid={testId} {...MiniCalendarProps} />
    </div>
  )
}

export default MiniCalendar
