import React from 'react'

import 'moment-timezone'
import { Col, Form } from 'react-bootstrap'
import 'react-dates/initialize'

import { AutoSizingTextArea } from '../../../../../stories/BaseComponents/TextAreaInput'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function KAPSideEffects(props) {
  return (
    <div>
      <Form.Row>
        <Form.Group as={Col} xs={12} sm={12} lg={12} xl={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Did patient experience any of the following ketamine-related side
            effects?
          </Form.Label>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRAnxiety"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Anxiety</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRAnxiety)}
            value="true"
            id="KRAnxietyYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRAnxiety: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRAnxiety)}
            value="false"
            id="KRAnxietyNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRAnxiety: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRAnxiety) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRAnxietyText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Anxiety comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.KRAnxietyText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRAnxietyText: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        )}

        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRVisualChanges"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Visual Changes</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRVisualChanges)}
            value="true"
            id="KRVisualChangesYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRVisualChanges: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRVisualChanges)}
            value="false"
            id="KRVisualChangesNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRVisualChanges: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRVisualChanges) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRVisualChangesText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Visual changes comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.KRVisualChangesText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRVisualChangesText: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        )}

        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRDizziness"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Dizziness</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRDizziness)}
            value="true"
            id="KRDizzinessYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRDizziness: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRDizziness)}
            value="false"
            id="KRDizzinessNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRDizziness: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRDizziness) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRDizzinessText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Dizziness comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-input"
              value={props.fields.KRDizzinessText}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRDizzinessText: e.target.value,
                })
              }
              as="textarea"
              disabled={props.disabled}
              rows="1"
            />
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRSedation"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Sedation</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRSedation)}
            value="true"
            id="KRSedationYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRSedation: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRSedation)}
            value="false"
            id="KRSedationNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRSedation: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRSedation) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRSedationText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Sedation comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.KRSedationText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRSedationText: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        )}

        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRNausea"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Nausea</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRNausea)}
            value="true"
            id="KRNauseaYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRNausea: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRNausea)}
            value="false"
            id="KRNauseaNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRNausea: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRNausea) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRNauseaText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Nausea comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.KRNauseaText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRNauseaText: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        )}

        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRVomiting"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Vomiting</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRVomiting)}
            value="true"
            id="KRVomitingYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRVomiting: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRVomiting)}
            value="false"
            id="KRVomitingNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRVomiting: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRVomiting) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRVomitingText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Vomiting comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.KRVomitingText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRVomitingText: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRFlushing"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Flushing</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRFlushing)}
            value="true"
            id="KRFlushingYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRFlushing: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRFlushing)}
            value="false"
            id="KRFlushingNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRFlushing: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRFlushing) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRFlushingText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Flushing comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-input"
              value={props.fields.KRFlushingText}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRFlushingText: e.target.value,
                })
              }
              as="textarea"
              disabled={props.disabled}
              rows="1"
            />
          </Form.Group>
        )}

        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRNumbness"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Numbness</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRNumbness)}
            value="true"
            id="KRNumbnessYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRNumbness: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRNumbness)}
            value="false"
            id="KRNumbnessNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRNumbness: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRNumbness) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRNumbnessText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Numbness comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.KRNumbnessText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRNumbnessText: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        )}

        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRPalpitations"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Palpitations</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRPalpitations)}
            value="true"
            id="KRPalpitationsYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRPalpitations: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRPalpitations)}
            value="false"
            id="KRPalpitationsNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRPalpitations: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRPalpitations) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRPalpitationsText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Palpitations comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.KRPalpitationsText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRPalpitationsText: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          s={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRSalivation"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Salivation</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRSalivation)}
            value="true"
            id="KRSalivationYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRSalivation: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRSalivation)}
            value="false"
            id="KRSalivationNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRSalivation: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRSalivation) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRSalivationText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Salivation comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.KRSalivationText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRSalivationText: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        )}

        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRDryMouth"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Dry Mouth</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRDryMouth)}
            value="true"
            id="KRDryMouthYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRDryMouth: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRDryMouth)}
            value="false"
            id="KRDryMouthNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRDryMouth: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRDryMouth) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRDryMouthText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Dry mouth comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.KRDryMouthText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRDryMouthText: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRDissociation"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Dissociation</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRDissociation)}
            value="true"
            id="KRDissociationYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRDissociation: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRDissociation)}
            value="false"
            id="KRDissociationNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRDissociation: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRDissociation) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRDissociationText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Dissociation comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.KRDissociationText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRDissociationText: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KRMoodElevation"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Mood elevation</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KRMoodElevation)}
            value="true"
            id="KRMoodElevationYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRMoodElevation: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KRMoodElevation)}
            value="false"
            id="KRMoodElevationNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KRMoodElevation: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KRMoodElevation) && (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KRMoodElevationText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Mood elevation comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.KRMoodElevationText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KRMoodElevationText: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        )}

        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          controlId="KROther"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Other</Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.KROther)}
            value="true"
            id="KROtherYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KROther: e.target.value,
              })
            }
            type="radio"
            label="Yes"
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.KROther)}
            value="false"
            id="KROtherNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KROther: e.target.value,
              })
            }
            type="radio"
            label="No"
          />
        </Form.Group>
        {JSON.parse(props.fields.KROther) ? (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            controlId="KROtherText"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Other comments
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.KROtherText}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KROtherText: e.target.value,
                })
              }
              as={AutoSizingTextArea}
              minRows={1}
            />
          </Form.Group>
        ) : (
          <></>
        )}
      </Form.Row>
    </div>
  )
}
