import React from 'react'

import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'

import {
  mapToOptionGroups,
  useBillingCodeDropdownOptions,
} from '../../containers/Billing/useBillingCodeDropdownOptions'
import {
  BareBillingCode,
  useBillingCodes,
} from '../../hooks/queries/useBillingCodes'
import { useBillingTemplates } from '../../hooks/queries/useBillingTemplates'
import {
  BillingTemplate,
  LineItem,
  PaymentAttempt,
  RefundAttempt,
} from '../../shared-types'
import { Button, Card, Option, Select, Text, Tooltip } from '../BaseComponents'
import { ChargeBreakdown } from './ChargeBreakdown'
import { Diagnosis } from './Diagnoses'
import { LineItems } from './InvoiceLineItems'
import { InvoiceComponentProps } from './constants'

import styles from './Invoice.module.scss'
import './LineItemsContainer.scss'

const BILLING_CODE_TOOLTIP =
  'For E/M codes, Modifier 25 is commonly used. If you include another service (e.g. ketamine infusion/injection) on the same date, this modifier should be included. It indicates a significant, separately identifiable preventive or other E/M service that was provided on the same date.'

type LineItemsContainerProps = {
  invoiceUuid: string
  selectedTemplateId?: number
  lineItems: LineItem[]
  payments: PaymentAttempt[]
  refunds: RefundAttempt[]
  diagnoses: Diagnosis[]
  onLineItemUpdate: any
  onLineItemDelete: any
  onTemplateChange: (billingTemplate?: BillingTemplate) => void
  onBillingCodeAdd: (bc: BareBillingCode) => void
} & InvoiceComponentProps

export const LineItemsContainer = ({
  invoiceUuid,
  isEditing,
  isLoading,
  isSuperbillEnabled,
  payments,
  refunds,
  lineItems,
  diagnoses,
  onLineItemUpdate,
  onLineItemDelete,
  onTemplateChange,
  onBillingCodeAdd,
  selectedTemplateId,
}: LineItemsContainerProps) => {
  const [selectedBillingCode, setSelectedBillingCode] = React.useState<
    BareBillingCode | undefined
  >(undefined)
  const [billingCodeSearch, setBillingCodeSearch] = React.useState('')
  const [billingTemplateSearch, setBillingTemplateSearch] = React.useState<
    string | undefined
  >('')

  const {
    templates: billingTemplates,
    templatesById,
    isLoading: billingTemplatesLoading,
  } = useBillingTemplates(billingTemplateSearch)

  const {
    codes: billingCodes,
    codesById,
    isLoading: isLoadingBCs,
  } = useBillingCodes(billingCodeSearch)

  const billingCodeOptions = useBillingCodeDropdownOptions(billingCodes)

  const handleBillingTemplateSelect = (value?: unknown): void => {
    if (value) {
      onTemplateChange(templatesById[Number(value)])
    }
  }

  const billingCodeSelectHandler = (value?: number) => {
    if (value) {
      const selectedBillingCode = codesById[value]
      setSelectedBillingCode(selectedBillingCode)
    } else {
      setSelectedBillingCode(undefined)
    }
  }

  const addBillingCodeHandler = () =>
    selectedBillingCode && onBillingCodeAdd(selectedBillingCode)

  const billingTemplateOptions: JSX.Element[] = (billingTemplates || []).map(
    (template: BillingTemplate) => (
      <Option value={template.id} key={template.id}>
        {template.name}
      </Option>
    )
  )

  const tooltipContent = (
    <div>
      Osmind uses CPT© American Medical Association, HCPCS - Centers for
      Medicare & Medicaid Services.
    </div>
  )

  const headerContent = (
    <div style={{ paddingBottom: 16 }}>
      <Text header="h5">
        Procedures{' '}
        <Tooltip title={tooltipContent}>
          <InfoCircleOutlined />
        </Tooltip>
      </Text>
    </div>
  )

  return (
    <Card loading={isLoading} testId="invoice-procedures-card">
      {headerContent}

      {isEditing && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            columnGap: 15,
            justifyContent: 'space-between',
          }}
        >
          <div className={styles.halfWidth}>
            <div className={styles.subheader}>Billing template</div>
            <div className="flex" style={{ paddingBottom: 16 }}>
              <Select
                loading={billingTemplatesLoading}
                style={{ width: '100%' }}
                value={selectedTemplateId}
                disabled={!isEditing}
                showSearch
                searchValue={billingTemplateSearch}
                filterOption={false}
                onSearch={setBillingTemplateSearch}
                onSelect={handleBillingTemplateSelect}
                placeholder="Select a billing template"
              >
                {billingTemplateOptions.map((option: JSX.Element) => option)}
              </Select>
            </div>
          </div>
          <div className={styles.halfWidth}>
            <div className={styles.subheader}>
              Add billing code
              <Tooltip
                overlayClassName="billing-code-tooltip"
                placement="top"
                title={BILLING_CODE_TOOLTIP}
              >
                <InfoCircleOutlined
                  width={14}
                  style={{ cursor: 'pointer', marginLeft: 5 }}
                />
              </Tooltip>
            </div>
            <div className={styles.selectBillingCode}>
              <Select
                className="add-billing-code__input"
                loading={isLoadingBCs}
                disabled={!isEditing}
                showSearch
                placeholder="Search for billing code"
                style={{ marginRight: 16, width: '85%' }}
                searchValue={billingCodeSearch}
                filterOption={false}
                onSearch={setBillingCodeSearch}
                onSelect={(value: unknown) =>
                  billingCodeSelectHandler(value ? Number(value) : undefined)
                }
              >
                {mapToOptionGroups(billingCodeOptions)}
              </Select>
              <Button
                testId="add-billing-code-button"
                icon={<PlusOutlined />}
                type="default"
                color="#262626"
                disabled={!isEditing}
                onClick={addBillingCodeHandler}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
      )}

      <LineItems
        isSuperbillEnabled={isSuperbillEnabled}
        isLoading={isLoading}
        isEditing={isEditing}
        lineItems={lineItems}
        diagnoses={diagnoses}
        onDelete={onLineItemDelete}
        onUpdate={onLineItemUpdate}
      />
      <ChargeBreakdown
        invoiceUuid={invoiceUuid}
        isEditing={isEditing}
        payments={payments}
        refunds={refunds}
        lineItems={lineItems}
      />
    </Card>
  )
}
