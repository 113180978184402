import React from 'react'

import { logError } from '../../libs/errorLib'

import styles from './ErrorBoundary.module.scss'

// this must be a class component, not a functional component, for access to componentDidCatch
export default class ErrorBoundary extends React.Component<React.PropsWithChildren> {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // @ts-expect-error errorLib.js cannot be easily fully typed; errorInfo's type is not correctly inferrable
    logError(error, errorInfo, { 'app.showed_error_boundary': 'yes' })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.wrapper}>
          <h3>Sorry there was a problem loading this page</h3>
        </div>
      )
    }
    return this.props.children
  }
}
