export enum FieldType {
  CHECKBOX = 'checkbox',
  TEXT = 'text',
  GROUP = 'group',
}

export enum Appearance {
  Dress = 'Dress',
  GroomedHygiene = 'Groomed/Hygiene',
  Weight = 'Weight',
  GeneralAppearance = 'General appearance',
  Notes = 'Notes',
}

export enum Behavior {
  CooperativeOrPleasant = 'Cooperative/pleasant',
  Uncooperative = 'Uncooperative',
  Guarded = 'Guarded',
  HostileOrBelligerent = 'Hostile/belligerent',
  Evasive = 'Evasive',
  Withdrawn = 'Withdrawn',
  Passive = 'Passive',
  Suspicious = 'Suspicious',
  Seductive = 'Seductive',
  EyeContact = 'Eye contact',
  Notes = 'Notes',
}

export enum Psychomotor {
  Normal = 'Normal movement, position, posture',
  Agitation = 'psychomotor agitation',
  Retardation = 'psychomotor retardation',
  Catatonic = 'catatonic',
  AbnormalGait = 'Abnormal gait',
  Tics = 'tics',
  Tremors = 'tremors',
  Pacing = 'pacing',
  ScratchingOrPicking = 'scratching/picking',
  Notes = 'Notes',
}

export enum Speech {
  Normal = 'Normal rate, rhythm, volume',
  Volume = 'Volume',
  Rate = 'Rate',
  Intelligibility = 'Intelligibility',
  Quality = 'Quality',
  Quantity = 'Quantity',
  Notes = 'Notes',
}
export enum Mood {
  Euthymic = 'Euthymic or normal mood',
  Euphoric = 'Euphoric',
  Happy = 'Happy',
  Fearful = 'Fearful',
  Irritable = 'Irritable',
  Angry = 'Angry',
  Sad = 'Sad',
  Helpless = 'Helpless',
  Anxious = 'Anxious',
  Notes = 'Notes',
}
export enum Affect {
  Normal = 'Normal range',
  MoodCongruent = 'Mood congruent',
  Incongruous = 'Incongruous',
  Depressed = 'Depressed',
  RestrictedRange = 'Restricted range',
  Blunted = 'Blunted',
  Flat = 'Flat',
  Fixed = 'Fixed',
  Labile = 'Labile',
  Anxious = 'Anxious',
  Irritable = 'Irritable',
  Notes = 'Notes',
}
export enum ThoughtProcess {
  Coherent = 'Coherent, linear, logical, appropriate',
  Tangential = 'Tangential',
  Circumstantial = 'Circumstantial',
  Derailment = 'Derailment',
  Blocking = 'Blocking',
  Perseveration = 'Perseveration',
  FlightOfIdeas = 'Flight of ideas',
  LooseAssociations = 'Loose associations',
  Incoherent = 'Incoherent',
  Egocentric = 'Egocentric',
  Evasive = 'Evasive',
  Racing = 'Racing',
  Notes = 'Notes',
}
export enum ThoughtContentAndPerception {
  Unremarkable = 'Unremarkable, no suicidal or homicidal ideation',
  SI = 'SI',
  HI = 'HI',
  Hallucinations = 'Hallucinations',
  Paranoia = 'Paranoia',
  Delusions = 'Delusions ',
  Illusions = 'Illusions',
  ObsessionsCompulsions = 'Obsessions, compulsions',
  Ruminations = 'Ruminations',
  Phobias = 'Phobias',
  Notes = 'Notes',
}
export enum InsightAndJudgement {
  Insight = 'Insight',
  Judgment = 'Judgment',
  Notes = 'Notes',
}
export enum Cognition {
  Alert = 'Alert',
  Oriented = 'Oriented to person, place, time, situation',
  NormalConcentration = 'Normal concentration',
  FollowsConversation = 'Follows conversation',
  NotAOx4 = 'Not A&O x 4',
  ImpairedCognition = 'Impaired cognition',
  Distracted = 'Distracted',
  Drowsy = 'Drowsy',
  Memory = 'Memory',
  Notes = 'Notes',
}

export enum Section {
  Appearance = 'Appearance',
  Behavior = 'Behavior',
  Psychomotor = 'Psychomotor',
  Speech = 'Speech',
  Mood = 'Mood',
  Affect = 'Affect',
  ThoughtProcess = 'Thought process',
  ThoughtContentAndPerception = 'Thought content and perceptions',
  InsightAndJudgement = 'Insight and judgment',
  Cognition = 'Cognition',
}

interface GroupField {
  type: FieldType.GROUP
  options: Record<string, string>
  color?: Record<string, string>
  hasText?: boolean
  nested?: boolean
}

interface TextField {
  type: FieldType.TEXT
  description?: string
}

interface CheckboxField {
  type: FieldType.CHECKBOX
  hasText?: boolean
  color?: string
}

type Field = GroupField | TextField | CheckboxField

export const SectionFieldMap: Record<Section, Record<string, Field>> = {
  [Section.Appearance]: {
    [Appearance.Dress]: {
      type: FieldType.GROUP,
      options: {
        Appropriate: 'Appropriate',
        NotAppropriate: 'Not appropriate',
      },
      color: { Appropriate: 'green' },
    },
    [Appearance.GroomedHygiene]: {
      type: FieldType.GROUP,
      options: {
        Appropriate: 'Appropriate',
        NotAppropriate: 'Not appropriate',
      },
      color: { Appropriate: 'green' },
      hasText: true,
    },
    [Appearance.Weight]: {
      type: FieldType.GROUP,
      options: {
        WNL: 'WNL, adequately nourished',
        Underweight: 'Underweight or frail looking',
        Overweight: 'Overweight',
      },
      color: { WNL: 'green' },
    },
    [Appearance.GeneralAppearance]: {
      type: FieldType.GROUP,
      options: {
        Tattoos: 'Tattoos',
        Scars: 'Prominent scars',
        FacialExpressions: 'Notable facial expressions',
      },
    },
    [Appearance.Notes]: {
      type: FieldType.TEXT,
      description:
        'Age, race/ethnicity, position, posture, dress, grooming, hygiene, facial expression, other features',
    },
  },
  [Section.Behavior]: {
    [Behavior.CooperativeOrPleasant]: {
      type: FieldType.CHECKBOX,
      color: 'green',
    },
    [Behavior.Uncooperative]: { type: FieldType.CHECKBOX },
    [Behavior.Guarded]: { type: FieldType.CHECKBOX },
    [Behavior.HostileOrBelligerent]: { type: FieldType.CHECKBOX },
    [Behavior.Evasive]: { type: FieldType.CHECKBOX },
    [Behavior.Withdrawn]: { type: FieldType.CHECKBOX },
    [Behavior.Passive]: { type: FieldType.CHECKBOX },
    [Behavior.Suspicious]: { type: FieldType.CHECKBOX },
    [Behavior.Seductive]: { type: FieldType.CHECKBOX },
    [Behavior.EyeContact]: {
      type: FieldType.GROUP,
      options: { Normal: 'Normal', Fair: 'Fair', Minimal: 'Minimal' },
      color: { Normal: 'green' },
    },
    [Behavior.Notes]: {
      type: FieldType.TEXT,
      description:
        'Eye contact, manner and attitude, cooperativeness, alertness, mannerisms, posturing',
    },
  },
  [Section.Psychomotor]: {
    [Psychomotor.Normal]: { type: FieldType.CHECKBOX, color: 'green' },
    [Psychomotor.Agitation]: { type: FieldType.CHECKBOX },
    [Psychomotor.Retardation]: { type: FieldType.CHECKBOX },
    [Psychomotor.Catatonic]: { type: FieldType.CHECKBOX },
    [Psychomotor.AbnormalGait]: { type: FieldType.CHECKBOX },
    [Psychomotor.Tics]: { type: FieldType.CHECKBOX },
    [Psychomotor.Tremors]: { type: FieldType.CHECKBOX },
    [Psychomotor.Pacing]: { type: FieldType.CHECKBOX },
    [Psychomotor.ScratchingOrPicking]: { type: FieldType.CHECKBOX },
    [Psychomotor.Notes]: {
      type: FieldType.TEXT,
      description: 'Psychomotor retardation or agitation, tremor, ataxia',
    },
  },
  [Section.Speech]: {
    [Speech.Normal]: { type: FieldType.CHECKBOX, color: 'green' },
    [Speech.Volume]: {
      type: FieldType.GROUP,
      options: { Soft: 'Soft', Loud: 'Loud' },
    },
    [Speech.Rate]: {
      type: FieldType.GROUP,
      options: { Pressured: 'Pressured', Rapid: 'Rapid', Slow: 'Slow' },
    },
    [Speech.Intelligibility]: {
      type: FieldType.GROUP,
      options: {
        Slurred: 'Slurred',
        Incoherent: 'Incoherent',
        Stuttering: 'Stuttering',
        IncreasedLatency: 'Increased latency of response',
      },
    },
    [Speech.Quality]: {
      type: FieldType.GROUP,
      options: { Monotone: 'Monotone' },
    },
    [Speech.Quantity]: {
      type: FieldType.GROUP,
      options: { Verbose: 'Verbose', Laconic: 'Laconic', Mute: 'Mute' },
    },
    [Speech.Notes]: {
      type: FieldType.TEXT,
      description: 'Rate, rhythm, volume, amount, articulation, spontaneity',
    },
  },
  [Section.Mood]: {
    [Mood.Euthymic]: { type: FieldType.CHECKBOX, color: 'green' },
    [Mood.Euphoric]: { type: FieldType.CHECKBOX },
    [Mood.Happy]: { type: FieldType.CHECKBOX },
    [Mood.Fearful]: { type: FieldType.CHECKBOX },
    [Mood.Irritable]: { type: FieldType.CHECKBOX },
    [Mood.Angry]: { type: FieldType.CHECKBOX },
    [Mood.Sad]: { type: FieldType.CHECKBOX },
    [Mood.Helpless]: { type: FieldType.CHECKBOX },
    [Mood.Anxious]: { type: FieldType.CHECKBOX },
    [Mood.Notes]: {
      type: FieldType.TEXT,
      description: "Usually in patient's own words",
    },
  },
  [Section.Affect]: {
    [Affect.Normal]: { type: FieldType.CHECKBOX, color: 'green' },
    [Affect.MoodCongruent]: { type: FieldType.CHECKBOX, color: 'green' },
    [Affect.Incongruous]: { type: FieldType.CHECKBOX },
    [Affect.Depressed]: { type: FieldType.CHECKBOX },
    [Affect.RestrictedRange]: { type: FieldType.CHECKBOX },
    [Affect.Blunted]: { type: FieldType.CHECKBOX },
    [Affect.Flat]: { type: FieldType.CHECKBOX },
    [Affect.Fixed]: { type: FieldType.CHECKBOX },
    [Affect.Labile]: { type: FieldType.CHECKBOX },
    [Affect.Anxious]: { type: FieldType.CHECKBOX },
    [Affect.Irritable]: { type: FieldType.CHECKBOX },
    [Affect.Notes]: {
      type: FieldType.TEXT,
      description: 'range, stability, intensity, appropriateness /congruence',
    },
  },
  [Section.ThoughtProcess]: {
    [ThoughtProcess.Coherent]: { type: FieldType.CHECKBOX, color: 'green' },
    [ThoughtProcess.Tangential]: { type: FieldType.CHECKBOX },
    [ThoughtProcess.Circumstantial]: { type: FieldType.CHECKBOX },
    [ThoughtProcess.Derailment]: { type: FieldType.CHECKBOX },
    [ThoughtProcess.Blocking]: { type: FieldType.CHECKBOX },
    [ThoughtProcess.Perseveration]: { type: FieldType.CHECKBOX },
    [ThoughtProcess.FlightOfIdeas]: { type: FieldType.CHECKBOX },
    [ThoughtProcess.LooseAssociations]: { type: FieldType.CHECKBOX },
    [ThoughtProcess.Incoherent]: { type: FieldType.CHECKBOX },
    [ThoughtProcess.Egocentric]: { type: FieldType.CHECKBOX },
    [ThoughtProcess.Evasive]: { type: FieldType.CHECKBOX },
    [ThoughtProcess.Racing]: { type: FieldType.CHECKBOX },
    [ThoughtProcess.Notes]: {
      type: FieldType.TEXT,
      description:
        'linearity, circumstantial, derailment, thought blocking, tangentiality, perseveration, logicality, flight of ideas, looseness of associations, neologisms, clanging',
    },
  },
  [Section.ThoughtContentAndPerception]: {
    [ThoughtContentAndPerception.Unremarkable]: {
      type: FieldType.CHECKBOX,
      color: 'green',
    },
    [ThoughtContentAndPerception.SI]: {
      type: FieldType.GROUP,
      options: { Intent: 'Intent', Plan: 'Plan', Means: 'Means' },
      nested: true,
      hasText: true,
    },
    [ThoughtContentAndPerception.HI]: {
      type: FieldType.GROUP,
      options: { Intent: 'Intent', Plan: 'Plan', Means: 'Means' },
      nested: true,
      hasText: true,
    },
    [ThoughtContentAndPerception.Hallucinations]: {
      type: FieldType.GROUP,
      options: {
        Auditory: 'Auditory',
        Visual: 'Visual',
        Gustatory: 'Gustatory',
        Olfactory: 'Olfactory',
        Tactile: 'Tactile',
      },
      nested: true,
      hasText: true,
    },
    [ThoughtContentAndPerception.Paranoia]: {
      type: FieldType.CHECKBOX,
      hasText: true,
    },
    [ThoughtContentAndPerception.Delusions]: {
      type: FieldType.CHECKBOX,
      hasText: true,
    },
    [ThoughtContentAndPerception.Illusions]: {
      type: FieldType.CHECKBOX,
      hasText: true,
    },
    [ThoughtContentAndPerception.ObsessionsCompulsions]: {
      type: FieldType.CHECKBOX,
      hasText: true,
    },
    [ThoughtContentAndPerception.Ruminations]: {
      type: FieldType.CHECKBOX,
      hasText: true,
    },
    [ThoughtContentAndPerception.Phobias]: {
      type: FieldType.CHECKBOX,
      hasText: true,
    },
    [ThoughtContentAndPerception.Notes]: {
      type: FieldType.TEXT,
      description: '',
    },
  },
  [Section.InsightAndJudgement]: {
    [InsightAndJudgement.Insight]: {
      type: FieldType.GROUP,
      options: { Good: 'Good', Fair: 'Fair', Poor: 'Poor' },
      color: { Good: 'green' },
    },
    [InsightAndJudgement.Judgment]: {
      type: FieldType.GROUP,
      options: { Good: 'Good', Fair: 'Fair', Poor: 'Poor' },
      color: { Good: 'green' },
    },
    [InsightAndJudgement.Notes]: { type: FieldType.TEXT, description: '' },
  },
  [Section.Cognition]: {
    [Cognition.Alert]: { type: FieldType.CHECKBOX, color: 'green' },
    [Cognition.Oriented]: { type: FieldType.CHECKBOX, color: 'green' },
    [Cognition.NormalConcentration]: {
      type: FieldType.CHECKBOX,
      color: 'green',
    },
    [Cognition.FollowsConversation]: {
      type: FieldType.CHECKBOX,
      color: 'green',
    },
    [Cognition.NotAOx4]: { type: FieldType.CHECKBOX, hasText: true },
    [Cognition.ImpairedCognition]: { type: FieldType.CHECKBOX, hasText: true },
    [Cognition.Distracted]: { type: FieldType.CHECKBOX },
    [Cognition.Drowsy]: { type: FieldType.CHECKBOX },
    [Cognition.Memory]: {
      type: FieldType.GROUP,
      options: {
        NoDeficits: 'No apparent deficits',
        ImpairedImmediate: 'Impaired immediate recall',
        ImpairedShortTerm: 'Impaired short-term recall',
        ImpairedLongTerm: 'Impaired long-term recall',
      },
      color: { NoDeficits: 'green' },
    },
    [Cognition.Notes]: {
      type: FieldType.TEXT,
      description:
        'attentiveness and distractibility, orientation, memory, language, visuospatial skills, executive functioning',
    },
  },
}

export const Normal = Object.fromEntries(
  Object.entries(Section).map(([key, section]) => [
    key,
    Object.fromEntries(
      Object.entries(SectionFieldMap[section])
        .map(([field, config]) => {
          if (config.type === FieldType.CHECKBOX && config.color === 'green') {
            return [field, '']
          }
          if (config.type === FieldType.GROUP) {
            const keys = Object.keys(config.options).filter(
              (key) => config.color?.[key] === 'green'
            )
            if (keys.length) {
              return [field, Object.fromEntries(keys.map((key) => [key, '']))]
            }
          }
          return []
        })
        .filter((value) => value.length > 0)
    ),
  ])
)
