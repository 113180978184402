import React from 'react'

import { Container, Form, Row } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'

import './../PatientProfile.scss'

export default function NeurodevelopmentalSaved(props) {
  const hasNoRelevantHistory =
    props.patientData.AttentionDeficit.state === false &&
    props.patientData.LearningDisorders.state === false &&
    props.patientData.Autism.state === false &&
    props.patientData.OtherNeurodevelopmental.state === false &&
    !props.patientData.NeurodevelopmentalAdditionalNotes &&
    props.patientData.NoNeurodevelopmental === true

  const noRecordedInfo =
    props.patientData.AttentionDeficit.state === false &&
    props.patientData.LearningDisorders.state === false &&
    props.patientData.Autism.state === false &&
    props.patientData.OtherNeurodevelopmental.state === false &&
    !props.patientData.NeurodevelopmentalAdditionalNotes &&
    props.patientData.NoNeurodevelopmental === false

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Neurodevelopmental"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">Neurodevelopmental:</Form.Row>
      <Form.Group className="indent">
        {props.patientData.AttentionDeficit.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.AttentionDeficit.state)}
            />
            <span id="AttentionDeficitShow">Attention deficit disorder</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.AttentionDeficit.Notes}
          </Container>
        )}
        {props.patientData.LearningDisorders.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.LearningDisorders.state)}
            />
            <span id="LearningDisordersShow">Learning disorders</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.LearningDisorders.Notes}
          </Container>
        )}
        {props.patientData.Autism.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.Autism.state)}
            />
            <span id="AutismShow">Autism spectrum disorder</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.Autism.Notes}
          </Container>
        )}
        {props.patientData.OtherNeurodevelopmental.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(
                props.patientData.OtherNeurodevelopmental.state
              )}
            />
            <span id="OtherNeurodevelopmentalShow">Other</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.OtherNeurodevelopmental.Notes}
          </Container>
        )}
      </Form.Group>
      <Form.Group className="border-bottom indent">
        {props.patientData.NeurodevelopmentalAdditionalNotes && (
          <Container bsPrefix="psych">
            <Form.Row className="patient-dark">Additional Notes:</Form.Row>
            <Row bsPrefix="psych-sub">
              {props.patientData.NeurodevelopmentalAdditionalNotes}
            </Row>
          </Container>
        )}
      </Form.Group>
    </Form.Group>
  )
}
