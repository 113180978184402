import React, { useMemo } from 'react'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'

import { Button, Table, Typography } from '../BaseComponents'
import { SurveySettingsData } from '../BaseComponents/tableData.types'
import { thresholdComparison } from './_constants'
import { AllSurveySettings } from './_types'

import './_shared.scss'

interface SurveySettingsTableProps {
  dataSource: SurveySettingsData[]
  onEditClick: (data: AllSurveySettings) => void
  onDeleteClick: (data: AllSurveySettings) => void
}

const SurveySettingsTable: React.FC<SurveySettingsTableProps> = ({
  dataSource,
  onEditClick,
  onDeleteClick,
}) => {
  const settingsColumns: ColumnsType<SurveySettingsData> = useMemo(
    () => [
      {
        title: 'Survey name',
        dataIndex: 'name',
        key: 'name',
        width: '35%',
        render: (_text, { name, description }) => (
          <>
            <Typography.Text>{name}</Typography.Text>
            <Typography.Text className="description">
              {description}
            </Typography.Text>
          </>
        ),
      },
      {
        title: 'Send to patients',
        dataIndex: 'sendingEnabled',
        key: 'sendingEnabled',
        render: (_text, { sendingEnabled }) => (sendingEnabled ? 'Yes' : 'No'),
      },
      {
        title: 'Send interval',
        dataIndex: 'sendInterval',
        key: 'sendInterval',
        render: (_text, { isRecurring, sendInterval }) =>
          isRecurring
            ? `${sendInterval} day` + (sendInterval > 1 ? 's' : '')
            : 'One-time',
      },
      {
        title: 'Score threshold alerts',
        dataIndex: 'alertThreshold',
        key: 'alertThreshold',
        render: (
          _text,
          { alertingEnabled, alertThreshold, comparisonOperator }
        ) => {
          if (!alertingEnabled) {
            return (
              <Typography.Text className="disabled">Alerts off</Typography.Text>
            )
          } else {
            return (
              <Typography.Text>
                {`${alertThreshold} or ${thresholdComparison(
                  comparisonOperator
                )}`}
              </Typography.Text>
            )
          }
        },
      },
      {
        render: (data) => (
          <div>
            <Button
              className="survey-setting edit"
              onClick={() => onEditClick(data)}
            >
              <EditOutlined />
              Edit
            </Button>
            <Button
              className="survey-setting delete"
              danger
              onClick={() => onDeleteClick(data)}
            >
              <DeleteOutlined />
              Delete
            </Button>
          </div>
        ),
      },
    ],
    [onDeleteClick, onEditClick]
  )

  return (
    <Table
      className="component-container"
      columns={settingsColumns}
      dataSource={dataSource}
      size="middle"
    />
  )
}

export default SurveySettingsTable
