import React from 'react'

import { Card as AntDCard, CardProps as AntDCardProps } from 'antd'

import { TestId } from '../../shared-types'

type CardProps = AntDCardProps & TestId

const Card: React.FC<CardProps> = ({ children, testId, ...props }) => {
  return (
    <AntDCard data-testid={testId} {...props}>
      {children}
    </AntDCard>
  )
}

export default Card
