import { ReactNode, useEffect, useState } from 'react'

import moment from 'moment'
import {
  Col,
  Container,
  Dropdown,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'react-bootstrap'

import { ClinicalNote } from '../../../shared-types'
import ClinicalNoteTab from './ClinicalNoteTab'

interface ClinicalNoteProps {
  clinicalNotes: ClinicalNote[]
}

export default function ClinicalNotes(props: ClinicalNoteProps) {
  const [cNotesSort, setCNotesSort] = useState('newest')
  const [notesList, setNotesList] = useState<ReactNode>('')

  function compare(list: ClinicalNote[], type: string) {
    const oldest = moment(new Date('1828-12-28'))
    const newest = moment()
    switch (type) {
      case 'newest':
        list.sort(function compare(a, b) {
          const dateA = a.CreatedOn ? moment(new Date(a.CreatedOn)) : oldest
          const dateB = b.CreatedOn ? moment(new Date(b.CreatedOn)) : oldest
          return dateA.isBefore(dateB) ? 1 : -1
        })
        break
      case 'oldest':
        list.sort(function compare(a, b) {
          const dateA = a.CreatedOn ? moment(new Date(a.CreatedOn)) : newest
          const dateB = b.CreatedOn ? moment(new Date(b.CreatedOn)) : newest
          return dateA.isAfter(dateB) ? 1 : -1
        })
        break
      case 'treatment-first':
        list.sort(function compare(a, b) {
          const x = a.ReceivedTreatment ? 1 : -1
          const y = b.ReceivedTreatment ? 1 : -1
          if (x === y) {
            const dateA = a.CreatedOn ? moment(new Date(a.CreatedOn)) : oldest
            const dateB = b.CreatedOn ? moment(new Date(b.CreatedOn)) : oldest
            return dateA.isBefore(dateB) ? 1 : -1
          } else {
            return x < y ? 1 : -1
          }
        })
        break
      case 'psychotherapy-first':
        list.sort(function compare(a, b) {
          const x = a.IsTreatmentConcurrentPsychotherapy ? 1 : -1
          const y = b.IsTreatmentConcurrentPsychotherapy ? 1 : -1
          if (x === y) {
            const dateA = a.CreatedOn ? moment(new Date(a.CreatedOn)) : oldest
            const dateB = b.CreatedOn ? moment(new Date(b.CreatedOn)) : oldest
            return dateA.isBefore(dateB) ? 1 : -1
          } else {
            return x < y ? 1 : -1
          }
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (!props.clinicalNotes) {
      return
    }
    const notes = props.clinicalNotes
    compare(notes, cNotesSort)
    setNotesList(
      notes.length === 0 ? (
        <h6>No clinical notes found for this patient.</h6>
      ) : (
        notes.map((note) => {
          return <ClinicalNoteTab key={note.NoteId} note={note} />
        })
      )
    )
  }, [props.clinicalNotes, cNotesSort])

  const cNotesNavSort = (
    <Nav>
      <Dropdown as={NavItem} className={'autosave-sort-dropdown'}>
        <Dropdown.Toggle
          as={NavLink}
          bsPrefix="nav-items"
          style={{ fontSize: 20, verticalAlign: 'sub' }}
        >
          <i className="fa fa-sort" aria-hidden="true" />
          &nbsp;&nbsp;Sort
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix="dropdown-menu-simple">
          <Dropdown.Item
            bsPrefix="nav-items"
            onClick={() => {
              setCNotesSort('newest')
            }}
          >
            <div className="align-left">
              <i className="fa fa-clock-o" aria-hidden="true" />
              &nbsp;Newest
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            bsPrefix="nav-items"
            onClick={() => {
              setCNotesSort('oldest')
            }}
          >
            <div className="align-left">
              <i className="fa fa-clock-o" aria-hidden="true" />
              &nbsp;Oldest
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            bsPrefix="nav-items"
            onClick={() => {
              setCNotesSort('treatment-first')
            }}
          >
            <div className="align-left">
              <i className="fa fa-check-circle-o" aria-hidden="true" />
              &nbsp; Received Treatment
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            bsPrefix="nav-items"
            onClick={() => {
              setCNotesSort('psychotherapy-first')
            }}
          >
            <div className="align-left">
              <i className="fa fa-check-circle-o" aria-hidden="true" />
              &nbsp; Received Psychotherapy
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  )

  return (
    <Container data-testid="note-list-container">
      <Row data-testid="note-list-sort">
        <Col className={'remove-padding'}>{cNotesNavSort}</Col>
      </Row>
      <Row data-testid="note-list-content">
        <Col bsPrefix="accordion center">{notesList}</Col>
      </Row>
    </Container>
  )
}
