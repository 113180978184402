import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

enum RaceCodesEnum {
  AMERICAN_INDIAN = '1002-5',
  ASIAN = '2028-9',
  BLACK_OR_AFRICAN_AMERICAN = '2054-5',
  NATIVE_HAWAIIAN = '2076-8',
  WHITE = '2076-8',
  UNKNOWN = 'UNK',
  NO_ANSWER = 'PHC1175',
}

export enum RaceTitleEnum {
  AMERICAN_INDIAN = 'American Indian or Alaska Native',
  ASIAN = 'Asian',
  BLACK_OR_AFRICAN_AMERICAN = 'Black or African American',
  NATIVE_HAWAIIAN = 'Native Hawaiian or Other Pacific Islander',
  WHITE = 'White',
  UNKNOWN = 'Unknown',
  NO_ANSWER = 'Decline to Answer',
}

const raceCDC: PatientDemographicObject[] = [
  {
    [RaceCodesEnum.AMERICAN_INDIAN]: {
      title: RaceTitleEnum.AMERICAN_INDIAN,
      description: '',
      category: Categories.AMERICAN_INDIAN_SUB_RACE,
    },
  },
  {
    [RaceCodesEnum.ASIAN]: {
      title: RaceTitleEnum.ASIAN,
      description: '...',
      category: Categories.ASIAN_SUB_RACE,
    },
  },
  {
    [RaceCodesEnum.BLACK_OR_AFRICAN_AMERICAN]: {
      title: RaceTitleEnum.BLACK_OR_AFRICAN_AMERICAN,
      category: Categories.BLACK_OR_AFRICAN_AMERICAN_SUB_RACE,
    },
  },
  {
    [RaceCodesEnum.NATIVE_HAWAIIAN]: {
      title: RaceTitleEnum.NATIVE_HAWAIIAN,
      description: '...',
      category: Categories.NATIVE_HAWAIIAN_SUB_RACE,
    },
  },
  {
    [RaceCodesEnum.WHITE]: {
      title: RaceTitleEnum.WHITE,
      category: Categories.WHITE_SUB_RACE,
    },
  },
  { [RaceCodesEnum.UNKNOWN]: { title: RaceTitleEnum.UNKNOWN } },
  { [RaceCodesEnum.NO_ANSWER]: { title: RaceTitleEnum.NO_ANSWER } },
]

export const raceToRaceDetail = Object.fromEntries(
  raceCDC.map((r) => {
    const obj = Object.values(r)[0]
    return [obj.title, { title: obj.title, category: obj.category }]
  })
)

export const raceDropdownValues = constructPatientDemographicObject(
  raceCDC,
  CodeSystemOIDs.PHVSRaceCategoryCDCRef,
  Categories.RACE,
  true
)
