import { Text } from '../../../../../../stories/BaseComponents'

import styles from './NoteV2HeaderText.module.scss'

export const NoteV2HeaderText = () => {
  return (
    <div className={styles.container}>
      <Text className={styles.headerText}>Notes v2</Text>
      <Text className={styles.betaLogo}>BETA</Text>
    </div>
  )
}
