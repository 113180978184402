import React from 'react'

import { Form } from 'react-bootstrap'

import { AutoSizingTextArea } from '../../../../stories/BaseComponents/TextAreaInput'

import '../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function Memo(props) {
  return (
    <div>
      <Form.Group controlId="ClinicalNoteContent">
        <Form.Label style={{ fontWeight: 'bold' }}>Memo</Form.Label>
        <Form.Control
          bsPrefix="form-control"
          className="field-value-edit"
          value={props.fields.ClinicalNoteContent}
          onChange={(e) =>
            props.handleFieldChange({
              ...props.fields,
              ClinicalNoteContent: e.target.value,
            })
          }
          disabled={props.disabled}
          as={AutoSizingTextArea}
          minRows={3}
        />
      </Form.Group>
    </div>
  )
}
