import {
  FieldStyling,
  GridLayout,
  TemplateFields,
} from '../../../components/Forms/FormMachine/types'
import { Conditions } from './types'

const conditionList = Object.values(Conditions)

export const styling = {
  editLabelClassName: 'field-label-edit',
  editValueClassName: 'field-value-edit',
  labelClassName: 'col-squished field-label',
  valueClassName: 'field-value',
  colSize: { xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
  formRowStyle: {
    margin: 2,
  },
} as FieldStyling

export const gridLayout = [
  { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
] as GridLayout

export const conditionsTemplate: TemplateFields[] = [
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'motherConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'grandmotherMaternalConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'grandfatherMaternalConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'auntMaternalConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'uncleMaternalConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'cousinMaternalConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'fatherConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'grandmotherPaternalConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'grandfatherPaternalConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'auntPaternalConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'unclePaternalConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'cousinPaternalConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'sisterConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'brotherConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'daughterConditions',
      options: conditionList,
      label: '',
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'checkbox',
      id: 'sonConditions',
      options: conditionList,
      label: '',
    },
  ],
]

export const commentTemplate: TemplateFields[] = [
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'motherPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'grandmotherMaternalPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'grandfatherMaternalPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'auntMaternalPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'uncleMaternalPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'cousinMaternalPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'fatherPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'grandmotherPaternalPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'grandfatherPaternalPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'auntPaternalPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'unclePaternalPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'cousinPaternalPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'sisterPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'brotherPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'daughterPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
  [
    {
      columnSizes: gridLayout[0],
      fieldType: 'textarea',
      id: 'sonPsychNotes',
      placeholder: 'Add comments',
      label: '',
      size: 4,
    },
  ],
]

export const additionalInfoTemplate = [
  {
    columnSizes: gridLayout[0],
    fieldType: 'textarea',
    label: 'Additional Information',
    placeholder: 'Add additional information',
    id: 'additionalNotes',
  },
] as TemplateFields

export const familyInfoTemplate = [
  {
    columnSizes: gridLayout[0],
    fieldType: 'text',
    label: 'Family members',
    placeholder: 'Add family member information',
    id: 'familyMemberNotes',
  },
] as TemplateFields
