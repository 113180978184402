import React, { useEffect, useState } from 'react'

import { Form, FormItemProps, Select } from 'antd'

import { getPOSCodes } from '../../../api/api-lib'

const { Option } = Select

interface POSCode {
  code: string
  name: string
  description?: string
}

interface POSProps {
  formItemProps?: FormItemProps
  name: string
}

const PlaceOfServiceInput: React.FC<POSProps> = ({
  formItemProps = {
    label: 'Place of Service (POS)',
    rules: [
      {
        type: 'string',
        whitespace: true,
      },
    ],
  },
  name = 'placeOfService',
}) => {
  const [codes, setCodes] = useState<POSCode[]>([])

  useEffect(() => {
    const loadPOSCodes = async () => {
      try {
        const posCodes = await getPOSCodes()
        if (Array.isArray(posCodes)) {
          setCodes(posCodes)
        }
      } catch (e) {
        console.error(e)
      }
    }

    loadPOSCodes()
  }, [])

  return (
    <Form.Item {...formItemProps} name={name}>
      <Select
        filterOption={(input, option) => {
          let children = (option?.children ?? '') as string
          // todo: 'children' is deprecated--will need to be updated
          // BUT can't find a valid replacement yet
          children = children.toLowerCase()
          return children.indexOf(input.toLowerCase()) >= 0
        }}
        filterSort={(optionA, optionB) => {
          let childrenA = (optionA?.children ?? '') as string
          let childrenB = (optionB?.children ?? '') as string
          // todo: 'children' is deprecated--will need to be updated
          // BUT can't find a valid replacement yet
          childrenA = childrenA.toLowerCase()
          childrenB = childrenB.toLowerCase()
          return childrenA.localeCompare(childrenB)
        }}
        showSearch
        optionFilterProp="children"
      >
        {codes.map(({ name, code }) => {
          return (
            <Option key={`pos-option-${code}`} value={code}>
              {name}
            </Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default PlaceOfServiceInput
