import { KeyboardEvent } from 'react'

export const getLengthWithTypeCheck = (value?: string | number) =>
  value && typeof value === 'string' ? value?.length : value || 0

export const checkIfCtrlOrCmdKeyIsPressed = (
  event: KeyboardEvent<HTMLElement>
) => {
  const isMac = window.navigator.userAgent.indexOf('Mac') !== -1
  const isCmdKey = isMac && event.metaKey
  const isCtrlKey = !isMac && event.ctrlKey
  return isCmdKey || isCtrlKey
}
