import { useMutation } from '@tanstack/react-query'

import { CreateNoteTemplateRequest, createNoteTemplate } from '../../api'

export const useCreateNoteTemplate = () => {
  const {
    mutateAsync: create,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(createNoteTemplate, {})

  return {
    createNoteTemplate: (body: CreateNoteTemplateRequest) => {
      return create(body)
    },
    isLoading,
    isSuccess,
    isError,
  }
}
