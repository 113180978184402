import React from 'react'

import { FormInstance, FormItemProps, InputProps } from 'antd'

import { npiRegex } from '../../../libs/regex'
import AutoFormatInput from '../AutoFormatInput'

interface NPIInput {
  form: FormInstance
  formItemProps?: FormItemProps
  inputProps?: InputProps
  name: string
  required?: boolean
}

export const formatNPI = (input: string) => {
  if (!input || !input.length) {
    return input
  }
  let clean = input.replace(/\D/g, '')
  if (clean.length > 10) {
    clean = clean.substring(0, 10)
  }

  return clean
}

const NPIInput: React.FC<NPIInput> = ({
  form,
  formItemProps = {},
  inputProps = {},
  name = 'npi',
  required = false,
}) => {
  const formItems: FormItemProps = {
    label: formItemProps.label || 'NPI',
    rules: formItemProps.rules || [
      {
        message: 'Enter a valid 10-digit code',
        min: 10,
        pattern: npiRegex,
        transform: formatNPI,
        type: 'string',
        validateTrigger: 'onBlur',
        required,
        whitespace: true,
      },
    ],
  }

  return (
    <AutoFormatInput
      form={form}
      formatInput={formatNPI}
      formItemProps={formItems}
      inputProps={inputProps}
      name={name}
    />
  )
}

export default NPIInput
