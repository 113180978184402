import { booleanToString } from './helpers'

/**
 * For some questions will return empty placeholder
 * @param questionKey
 * @param answer
 */
export const getEmptyStateOrAnswer = (
  questionKey: string,
  answer: boolean
): string => {
  if (answer) {
    return booleanToString(answer)
  }

  switch (questionKey) {
    case 'HAS_ALLERGIES':
      return 'No allergies reported'
    case 'HAS_MEDICATIONS':
      return 'No medications reported'
    case 'HAS_TAKEN_PSYCH_MEDS':
      return 'No psychiatric medications reported'
    default:
      return booleanToString(answer)
  }
}
