import { useMutation } from '@tanstack/react-query'

import { finalizeSuperbill } from '../../api/api-lib'
import { trackSuperbillEvent } from '../../libs/freshpaint/superbillEvents'
import { notification } from '../../libs/notificationLib'
import { Invoice, Superbill } from '../../shared-types'
import ExportPdfConfirmationModal from './ExportPdfConfirmationModal'

export interface ExportSuperbillPdfConfirmationModalProps {
  invoice: Invoice | null
  isVisible: boolean
  onCancel: () => void
  onExportStarted: () => void
  onExportFinished: (superbill: Superbill) => void
}

export default function ExportSuperbillPdfConfirmationModal({
  invoice,
  isVisible,
  onCancel,
  onExportStarted,
  onExportFinished,
}: ExportSuperbillPdfConfirmationModalProps) {
  const { mutate: exportSuperbillToPdf, isLoading: isExportingSuperbillToPdf } =
    useMutation(finalizeSuperbill, {
      onSuccess: (superbill: Superbill) => {
        trackSuperbillEvent('pdf', { invoiceId: invoice?.uuid || '' })
        notification("Superbill successfully saved to patient's documents.")
        onExportFinished(superbill)
      },
      onError: () =>
        notification(
          'There was an error saving the superbill as a PDF.',
          'failure'
        ),
    })

  const handleExportSuperbillToPdf = () => {
    if (!invoice?.superbill?.id) return

    exportSuperbillToPdf({
      id: invoice.superbill.id,
      submitToAmd: false,
    })

    onExportStarted()
  }

  return (
    <ExportPdfConfirmationModal
      isVisible={isVisible}
      onCancel={onCancel}
      okButtonProps={{ loading: isExportingSuperbillToPdf }}
      onOk={handleExportSuperbillToPdf}
      cancelButtonProps={{ disabled: isExportingSuperbillToPdf }}
      closable={!isExportingSuperbillToPdf}
      maskClosable={!isExportingSuperbillToPdf}
      fileType="superbill"
    />
  )
}
