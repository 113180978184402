import { useEffect, useState } from 'react'

import { loadStripe } from '@stripe/stripe-js'

import { useStripePublicKey } from '../../../hooks/useBillingInfo'

const useStripe = () => {
  const [stripePromise, setStripePromise] = useState<any>(null)

  const {
    isLoading: stripePublicKeyIsLoading,
    isError: stripePublicKeyIsError,
    data: stripePublicKeyData,
  } = useStripePublicKey()

  useEffect(() => {
    if (!stripePublicKeyData) {
      return
    }

    const stripePromise = loadStripe(stripePublicKeyData.key)
    setStripePromise(stripePromise)
  }, [stripePublicKeyData])

  return { stripePublicKeyIsLoading, stripePublicKeyIsError, stripePromise }
}

export default useStripe
