import React, { useCallback, useMemo, useRef, useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { Dropdown } from 'antd'
import { useHistory } from 'react-router-dom'

import { finalizeSuperbill, getAmdConnection } from '../../api/api-lib'
import { shareSuperbill } from '../../api/billing'
import PaymentModals from '../../containers/Patient/Billing/PaymentModals'
import { useStripeAccountStatus } from '../../hooks/useBillingInfo'
import { INVOICE_FORM_MODES } from '../../libs/constants'
import { useFeatureFlags } from '../../libs/featureFlags'
import { trackSuperbillEvent } from '../../libs/freshpaint/superbillEvents'
import { notification } from '../../libs/notificationLib'
import { Invoice } from '../../shared-types'
import { Button, Spinner, Tooltip } from '../BaseComponents'
import { DeleteInvoiceModal } from './DeleteInvoiceModal'
import {
  DISCARD_INVOICE_MODAL_CONFIG,
  DiscardInvoiceModal,
} from './DiscardInvoiceModal'
import ExportSuperbillPdfConfirmationModal from './ExportSuperbillPdfConfirmationModal'
import ResendSuperbillModal from './ResendSuperbillModal'
import SendSuperbillModal from './SendSuperbillModal'
import SendSuperbillWithoutPaymentModal from './SendSuperbillWithoutPaymentModal'
import SuperbillWithoutPaymentConfirmationModal from './SuperbillWithoutPaymentConfirmationModal'

import styles from './Invoice.module.scss'

type ButtonsProps = {
  primaryProviderId: string
  invoice: Invoice
  invoiceNumber: number
  amountCentsDue: number
  isMissingFields: boolean
  isMissingLineItems: boolean
  isEmptyLineItems: boolean
  isCreating: boolean
  isUpdating: boolean
  isAnythingLoading: boolean
  isExporting: boolean
  patientId: string
  mode: INVOICE_FORM_MODES
  onCreate: () => Promise<string | null>
  onUpdate: () => Promise<void>
  onSwitchToEditMode?: () => void
  onExport: () => void
  refetchInvoice: () => void
}

const Buttons = ({
  primaryProviderId,
  invoice,
  invoiceNumber,
  amountCentsDue,
  isMissingFields,
  isMissingLineItems,
  isCreating,
  isUpdating,
  isAnythingLoading,
  isExporting,
  patientId,
  mode,
  onCreate,
  onUpdate,
  onSwitchToEditMode,
  onExport,
  refetchInvoice,
  isEmptyLineItems,
}: ButtonsProps) => {
  const history = useHistory()

  const { advancedmd, enableProviderSuperbillSend } = useFeatureFlags()
  const { data: amdSettings } = useQuery(
    ['amd-settings'],
    () => getAmdConnection(),
    { enabled: advancedmd }
  )

  const showAmdButton = advancedmd && amdSettings && !!amdSettings.username
  const alreadyExportedSuperbill = !!invoice?.superbill?.pdfS3Location
  const alreadySentToAmd = showAmdButton && !!invoice?.superbill?.amdVisitId

  const alreadyExportedSuperbillTooltip =
    alreadyExportedSuperbill &&
    'FYI: This superbill has already been generated.'
  const amdTooltip =
    showAmdButton &&
    alreadySentToAmd &&
    'FYI: This bill has already been sent to AdvancedMD.'

  const isInvoicePaid = amountCentsDue === 0

  const { mutate: sendToAmd, isLoading: isSendingToAmd } = useMutation(
    finalizeSuperbill,
    {
      onSuccess: () => {
        notification('Bill successfully sent to AdvancedMD.', 'success')
        invoice.superbill &&
          history.push(
            `/patient/clinical-notes?patientId=${invoice.patientId}&providerId=${primaryProviderId}`
          )
      },
      onError: (err: string) => notification(err.toString(), 'failure'),
    }
  )

  const { mutateAsync: doShareSuperbill, isLoading: isSharingSuperbill } =
    useMutation(shareSuperbill)

  const [isExportingSuperbillToPdf, setIsExportingSuperbillToPdf] =
    useState(false)
  const [showUnpaidModal, setShowUnpaidModal] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const [
    showExportSuperbillModalAfterPayment,
    setShowExportSuperbillModalAfterPayment,
  ] = useState(false)

  const [isSavingAndPaying, setIsSavingAndPaying] = useState(false)

  const [invoiceUuid, setinvoiceUuid] = useState(invoice ? invoice.uuid : null)

  const [showSendSuperbillModal, setShowSendSuperbillModal] = useState(false)
  const [
    showSendSuperbillWithoutPaymentModal,
    setShowSendSuperbillWithoutPaymentModal,
  ] = useState(false)
  const [showResendSuperbillModal, setShowResendSuperbillModal] =
    useState(false)
  // "useRef" is used instead of "useState" because we only need this value to persist, but do no need its change to trigger rerendering.
  const shouldShowSendSuperbillModalAfterPaymentRef = useRef(false)

  const {
    isLoading: stripeAccountStatusIsLoading,
    data: stripeAccountStatusData,
  } = useStripeAccountStatus()

  const getTooltipContent = () => {
    const verb = mode === INVOICE_FORM_MODES.CREATE ? 'create' : 'update'
    if (isMissingFields) {
      return `Cannot ${verb} invoice until all required fields are entered.`
    } else if (isMissingLineItems) {
      return `Cannot ${verb} invoice without any billing codes.`
    } else if (isEmptyLineItems) {
      return `Cannot ${verb} invoice until all line items have 1 or more units.`
    } else {
      return null
    }
  }

  const createAndShowPaymentsModal = async () => {
    setIsSavingAndPaying(true)
    const uuid = await onCreate()
    setinvoiceUuid(uuid)
    setShowPaymentModal(true)
    setIsSavingAndPaying(false)
  }

  const updateAndShowPaymentsModal = async () => {
    setIsSavingAndPaying(true)
    await onUpdate()
    setShowPaymentModal(true)
    setIsSavingAndPaying(false)
  }

  const handleOpenAddPaymentModal = () => {
    setShowPaymentModal(true)
    setIsSavingAndPaying(true)
  }

  const stopLoadingButton = useCallback(
    () => setTimeout(() => setIsSavingAndPaying(false), 500),
    []
  )

  // start the "send superbill to patient" flow
  const handleStartSuperbillSend = () => {
    const alreadySharedSuperbill = !!invoice?.superbill?.sharedBy
    if (alreadySharedSuperbill) {
      setShowResendSuperbillModal(true)
    } else if (!isInvoicePaid) {
      setShowSendSuperbillWithoutPaymentModal(true)
    } else {
      setShowSendSuperbillModal(true)
    }
  }
  // finalize the "send superbill to patient" flow
  const sendSuperbillAndRefetchInvoice = async () => {
    if (!invoice?.superbill?.id) return

    trackSuperbillEvent('share', {
      invoiceId: invoice.uuid,
      superbillId: invoice.superbill.id,
      patientId: invoice.patientId,
    })

    try {
      await doShareSuperbill({
        invoiceId: invoice.uuid,
        superbillId: invoice.superbill.id,
      })
      notification('Superbill successfully sent to patient.', 'success')
      refetchInvoice()
    } catch (e) {
      notification('Failed to send superbill to patient', 'failure')
    }
  }

  const areButtonsDisabled =
    isAnythingLoading ||
    isMissingFields ||
    isMissingLineItems ||
    isEmptyLineItems ||
    stripeAccountStatusIsLoading ||
    isCreating ||
    isUpdating ||
    isSavingAndPaying ||
    isSendingToAmd ||
    isExportingSuperbillToPdf

  const exportSuperbillAsPdfButton = (
    <li
      onClick={() =>
        isInvoicePaid ? setShowExportModal(true) : setShowUnpaidModal(true)
      }
    >
      Export superbill as PDF
    </li>
  )

  const sendSuperbillButton = (
    <li
      onClick={handleStartSuperbillSend}
      onKeyDown={({ key }) => {
        if (key === 'Enter') {
          handleStartSuperbillSend()
        }
      }}
    >
      Send superbill to patient
    </li>
  )

  const sendToAmdButton = (
    <li
      onClick={() =>
        invoice?.superbill?.id &&
        sendToAmd({
          id: invoice.superbill.id,
          submitToAmd: true,
        })
      }
    >
      Send to Advanced MD
    </li>
  )

  const amdButton = useMemo(() => {
    if (!showAmdButton) {
      return null
    }

    return amdTooltip ? (
      <Tooltip placement="bottom" title={amdTooltip}>
        {sendToAmdButton}
      </Tooltip>
    ) : (
      sendToAmdButton
    )
  }, [invoice, showAmdButton, amdTooltip])

  const overlay = (
    <ul className={styles.dropdownMenu}>
      <li onClick={onSwitchToEditMode}>Edit invoice</li>
      <li onClick={onExport}>Export invoice as PDF</li>

      {invoice?.superbill?.id && (
        <>
          {amdButton}

          {alreadyExportedSuperbillTooltip ? (
            <Tooltip placement="bottom" title={alreadyExportedSuperbillTooltip}>
              {exportSuperbillAsPdfButton}
            </Tooltip>
          ) : (
            exportSuperbillAsPdfButton
          )}

          {enableProviderSuperbillSend && sendSuperbillButton}
        </>
      )}

      {invoice && (
        <li>
          <DeleteInvoiceModal
            invoiceUuid={invoice.uuid}
            invoiceNumber={invoiceNumber}
            patientId={invoice.patientId}
            onDelete={() => {
              /* do nothing */
            }}
          />
        </li>
      )}
    </ul>
  )

  const modals = (
    <>
      <SuperbillWithoutPaymentConfirmationModal
        isVisible={showUnpaidModal}
        onAddPayment={() => {
          setShowUnpaidModal(false)
          setShowPaymentModal(true)
          setShowExportSuperbillModalAfterPayment(true)
        }}
        onCancel={() => {
          setShowUnpaidModal(false)
        }}
        onProceedWithoutPayment={() => {
          setShowUnpaidModal(false)
          setShowExportModal(true)
        }}
      />
      <ExportSuperbillPdfConfirmationModal
        invoice={invoice}
        isVisible={showExportModal}
        onCancel={() => {
          setShowExportModal(false)
          setShowExportSuperbillModalAfterPayment(false)
        }}
        onExportStarted={() => {
          setIsExportingSuperbillToPdf(true)
          setShowExportSuperbillModalAfterPayment(false)
        }}
        onExportFinished={(superbill) => {
          setIsExportingSuperbillToPdf(false)
          superbill.id &&
            history.push(
              `/patient/documents?patientId=${superbill.invoice.patientId}&documentReference=${superbill.clinicalNoteId}`
            )
        }}
      />
      <SendSuperbillModal
        visible={showSendSuperbillModal}
        onCancel={() => {
          setShowSendSuperbillModal(false)
        }}
        onSend={async () => {
          await sendSuperbillAndRefetchInvoice()
          setShowSendSuperbillModal(false)
        }}
        isSending={isSharingSuperbill}
      />
      <ResendSuperbillModal
        visible={showResendSuperbillModal}
        onCancel={() => {
          setShowResendSuperbillModal(false)
        }}
        onSend={async () => {
          await sendSuperbillAndRefetchInvoice()
          setShowResendSuperbillModal(false)
        }}
        isSending={isSharingSuperbill}
        sharedAt={invoice?.superbill?.sharedAt}
      />
      <SendSuperbillWithoutPaymentModal
        visible={showSendSuperbillWithoutPaymentModal}
        onCancel={() => {
          setShowSendSuperbillWithoutPaymentModal(false)
        }}
        onAddPayment={() => {
          setShowPaymentModal(true)
          setShowSendSuperbillWithoutPaymentModal(false)
          shouldShowSendSuperbillModalAfterPaymentRef.current = true
        }}
        onSend={async () => {
          await sendSuperbillAndRefetchInvoice()
          setShowSendSuperbillWithoutPaymentModal(false)
        }}
        isSending={isSharingSuperbill}
      />
    </>
  )

  let buttons = null
  switch (mode) {
    case INVOICE_FORM_MODES.CREATE:
      buttons = (
        <Tooltip placement="bottomRight" title={getTooltipContent()}>
          <div>
            <DiscardInvoiceModal
              patientId={patientId}
              providerId={primaryProviderId}
              invoiceNumber={invoiceNumber}
              mode={mode}
              config={DISCARD_INVOICE_MODAL_CONFIG.DISCARD_BUTTON}
              isDiscardDisabled={isCreating || isUpdating || isExporting}
            />
            <Button
              type={'primary'}
              disabled={areButtonsDisabled}
              loading={isCreating && !isSavingAndPaying}
              onClick={onCreate}
              style={{ marginLeft: 10 }}
            >
              <span>Save invoice</span>
            </Button>
            {!isInvoicePaid && (
              <Button
                type={'primary'}
                disabled={areButtonsDisabled}
                loading={isSavingAndPaying}
                onClick={createAndShowPaymentsModal}
                style={{ marginLeft: 10 }}
              >
                {' '}
                <span>Save invoice and pay</span>
              </Button>
            )}
          </div>
        </Tooltip>
      )
      break
    case INVOICE_FORM_MODES.EDIT:
      buttons = (
        <Tooltip placement="bottomRight" title={getTooltipContent()}>
          <div>
            <DiscardInvoiceModal
              patientId={patientId}
              providerId={primaryProviderId}
              invoiceNumber={invoiceNumber}
              mode={mode}
              config={DISCARD_INVOICE_MODAL_CONFIG.DISCARD_BUTTON}
              isDiscardDisabled={isCreating || isUpdating || isExporting}
            />
            <Button
              type={'primary'}
              disabled={areButtonsDisabled}
              loading={isUpdating && !isSavingAndPaying}
              onClick={onUpdate}
              style={{ marginLeft: 10 }}
            >
              {' '}
              <span>Save invoice</span>
            </Button>
            {!isInvoicePaid && (
              <Button
                type={'primary'}
                disabled={areButtonsDisabled}
                loading={isSavingAndPaying}
                onClick={updateAndShowPaymentsModal}
                style={{ marginLeft: 10 }}
              >
                {' '}
                <span>Save invoice and pay</span>
              </Button>
            )}
          </div>
        </Tooltip>
      )
      break
    case INVOICE_FORM_MODES.VIEW:
      buttons = (
        <div>
          {isExporting ? (
            <div style={{ padding: '1em 0em', display: 'contents' }}>
              <Spinner fontSize={24} />
              &nbsp;&nbsp; Loading
            </div>
          ) : (
            <Dropdown disabled={!invoice} overlay={overlay}>
              <Button>Actions</Button>
            </Dropdown>
          )}
          <Button
            testId="pay-invoice-button"
            type={'primary'}
            disabled={isInvoicePaid}
            loading={isAnythingLoading || isSavingAndPaying}
            onClick={handleOpenAddPaymentModal}
            style={{ marginLeft: 10 }}
          >
            Pay Invoice
          </Button>

          <PaymentModals
            patientId={patientId}
            isOpen={showPaymentModal}
            onClose={() => {
              setShowPaymentModal(false)
              showExportSuperbillModalAfterPayment && setShowExportModal(true)
              if (shouldShowSendSuperbillModalAfterPaymentRef.current) {
                setShowSendSuperbillModal(true)
                shouldShowSendSuperbillModalAfterPaymentRef.current = false
              }
            }}
            stripeAccountStatus={stripeAccountStatusData?.status}
            preSelectedInvoiceUuid={invoice ? invoice.uuid : invoiceUuid}
            stopModalLoading={isSavingAndPaying ? stopLoadingButton : null}
            fromClaim={false}
            fromGlobalView={false}
          />
        </div>
      )
      break
  }

  return (
    <>
      {buttons}
      {modals}
    </>
  )
}

export default Buttons
