import React, { useState } from 'react'

import moment from 'moment'
import 'moment-timezone'
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  ToggleButton,
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import { Link } from 'react-router-dom'

import ShowSuicideAttempts from './Tables/ShowSuicideAttempts'

import './Tables/DatePicker.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dates/lib/css/_datepicker.css'

const FREQUENCY = [
  'Constantly',
  'Daily',
  'Between daily and weekly',
  'Weekly',
  'Between weekly and monthly',
  'Monthly',
  'Less frequent than monthly',
  'Rarely',
]

const dateFormats = [
  { format: 'MM/DD/YYYY' },
  { format: 'MM/YYYY' },
  { format: 'YYYY' },
]

export default function SuicidalityDanger(props) {
  const { patientId = '', providerId = '' } = props

  const [isDatefocused, setIsDateFocused] = useState(false)

  function showSavedAttempts() {
    if (props.patientData.SuicideAttempts.length !== 0) {
      return (
        <>
          {props.patientData.SuicideAttempts.map((SA, index) => {
            return (
              <ShowSuicideAttempts
                key={index}
                index={index}
                deleteAttempt={props.deleteAttempt}
                Description={SA.description}
                StartDate={SA.startDate}
                EndDate={SA.endDate}
                DateFormat={SA.dateFormat}
                disabled={props.disabled}
                saveAttempt={props.saveAttempt}
                suicide
              />
            )
          })}
        </>
      )
    }
  }

  function showSelfHarm() {
    if (props.patientData.SelfHarm.length !== 0) {
      return (
        <>
          {props.patientData.SelfHarm.map((SA, index) => {
            return (
              <ShowSuicideAttempts
                key={index}
                index={index}
                deleteAttempt={props.deleteSelfHarm}
                Description={SA.description}
                StartDate={SA.startDate}
                EndDate={SA.endDate}
                disabled={props.disabled}
                saveAttempt={props.saveSelfHarm}
                selfHarm
              />
            )
          })}
        </>
      )
    }
  }

  function handleViolenceStartDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        const newOnsetDate = date.toDate()
        props.setPatientData({
          ...props.patientData,
          ViolenceToOthers: {
            ...props.patientData.ViolenceToOthers,
            StartDate: newOnsetDate,
          },
        })
      } else {
        props.setPatientData({
          ...props.patientData,
          ViolenceToOthers: {
            ...props.patientData.ViolenceToOthers,
            StartDate: date,
          },
        })
      }
    } else {
      props.setPatientData({
        ...props.patientData,
        ViolenceToOthers: {
          ...props.patientData.ViolenceToOthers,
          StartDate: '',
        },
      })
    }
  }
  function handleViolenceEndDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        const newOnsetDate = date.toDate()
        props.setPatientData({
          ...props.patientData,
          ViolenceToOthers: {
            ...props.patientData.ViolenceToOthers,
            EndDate: newOnsetDate,
          },
        })
      } else {
        props.setPatientData({
          ...props.patientData,
          ViolenceToOthers: {
            ...props.patientData.ViolenceToOthers,
            EndDate: date,
          },
        })
      }
    } else {
      props.setPatientData({
        ...props.patientData,
        ViolenceToOthers: {
          ...props.patientData.ViolenceToOthers,
          EndDate: '',
        },
      })
    }
  }
  function handlePSIStartDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        const newOnsetDate = date.toDate()
        props.setPatientData({
          ...props.patientData,
          PreviousSuicidalIdeation: {
            ...props.patientData.PreviousSuicidalIdeation,
            StartDate: newOnsetDate,
          },
        })
      } else {
        props.setPatientData({
          ...props.patientData,
          PreviousSuicidalIdeation: {
            ...props.patientData.PreviousSuicidalIdeation,
            StartDate: date,
          },
        })
      }
    } else {
      props.setPatientData({
        ...props.patientData,
        PreviousSuicidalIdeation: {
          ...props.patientData.PreviousSuicidalIdeation,
          StartDate: '',
        },
      })
    }
  }
  function handlePSIEndDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        const newOnsetDate = date.toDate()
        props.setPatientData({
          ...props.patientData,
          PreviousSuicidalIdeation: {
            ...props.patientData.PreviousSuicidalIdeation,
            EndDate: newOnsetDate,
          },
        })
      } else {
        props.setPatientData({
          ...props.patientData,
          PreviousSuicidalIdeation: {
            ...props.patientData.PreviousSuicidalIdeation,
            EndDate: date,
          },
        })
      }
    } else {
      props.setPatientData({
        ...props.patientData,
        PreviousSuicidalIdeation: {
          ...props.patientData.PreviousSuicidalIdeation,
          EndDate: '',
        },
      })
    }
  }

  function handleSIStartDate(date) {
    if (date) {
      if (moment.isMoment(date)) {
        const newOnsetDate = date.toDate()
        props.setPatientData({
          ...props.patientData,
          SuicidalIdeation: {
            ...props.patientData.SuicidalIdeation,
            Date: newOnsetDate,
          },
        })
      } else {
        props.setPatientData({
          ...props.patientData,
          SuicidalIdeation: {
            ...props.patientData.SuicidalIdeation,
            Date: date,
          },
        })
      }
    } else {
      props.setPatientData({
        ...props.patientData,
        SuicidalIdeation: { ...props.patientData.SuicidalIdeation, Date: '' },
      })
    }
  }

  if (props.patientData.NoSuicideDanger) {
    return (
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Suicidality/danger to self/others:
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="NoSuicideDanger"
            label="No relevant history in this section"
            checked={!!props.patientData.NoSuicideDanger}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NoSuicideDanger: !props.patientData.NoSuicideDanger,
              })
            }}
          />
        </Form.Group>
      </Form.Row>
    )
  }

  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Suicidality/danger to self/others:
          </Form.Label>
        </Form.Group>
        {props.patientData.SuicideAttempts.length === 0 &&
        props.patientData.SuicidalIdeation.state === false &&
        props.patientData.PreviousSuicidalIdeation.state === false &&
        props.patientData.SelfHarm.length === 0 &&
        props.patientData.ViolenceToOthers.state === false &&
        !props.patientData.SuicideAdditionalNotes ? (
          <Form.Group as={Col} sm={12} md={12} lg={12}>
            <Form.Check
              type="checkbox"
              id="NoSuicideDanger"
              label="No relevant history in this section"
              checked={!!props.patientData.NoSuicideDanger}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoSuicideDanger: !props.patientData.NoSuicideDanger,
                })
              }}
            />
          </Form.Group>
        ) : (
          ''
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginLeft: 'auto' }}
        >
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Suicide Attempts
          </Form.Label>
          {showSavedAttempts()}
          {!props.patientData.NoSuicideDanger && (
            <Button
              bsPrefix="plain-button center info button-label"
              disabled={props.patientData.NoSuicideDanger}
              onClick={() => {
                props.saveNewSuicideAttempt()
              }}
            >
              + Add New Suicide Attempt
            </Button>
          )}
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Suicidal Ideation
          </Form.Label>
          <Form.Check
            type="checkbox"
            id="Suicidal Ideation"
            disabled={props.patientData.NoSuicideDanger}
            label="Current Suicidal ideation"
            checked={!!props.patientData.SuicidalIdeation.state}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                SuicidalIdeation: {
                  ...props.patientData.SuicidalIdeation,
                  state: !props.patientData.SuicidalIdeation.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.SuicidalIdeation.state && (
          <>
            <Form.Group
              style={{ marginRight: 24 }}
              as={Col}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={4}
            >
              <Row style={{ width: 'min-content' }}>
                <Col>
                  <Form.Label style={{ fontWeight: 'bold' }}>Start</Form.Label>
                </Col>
                <Col>
                  <ButtonGroup style={{ marginLeft: -14 }} toggle size="sm">
                    {dateFormats.map((btn, idx) => (
                      <ToggleButton
                        className="date-format-selector"
                        variant="primary"
                        key={idx}
                        type="radio"
                        name="radio"
                        value={btn.format}
                        style={{ fontSize: '10px' }}
                        checked={
                          props.patientData.SuicidalIdeation.DateFormat ===
                          btn.format
                        }
                        onChange={(e) =>
                          props.setPatientData({
                            ...props.patientData,
                            SuicidalIdeation: {
                              ...props.patientData.SuicidalIdeation,
                              DateFormat: e.currentTarget.value,
                            },
                          })
                        }
                      >
                        {btn.format}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </Col>
              </Row>

              <Form.Group>
                {props.patientData.SuicidalIdeation.DateFormat ===
                  'MM/DD/YYYY' && (
                  <SingleDatePicker
                    date={
                      props.patientData.SuicidalIdeation.Date
                        ? moment(props.patientData.SuicidalIdeation.Date)
                        : moment()
                    }
                    onDateChange={(date) => handleSIStartDate(date)}
                    id="mmddyyyy-date"
                    focused={isDatefocused}
                    onFocusChange={(f) => setIsDateFocused(f.focused)}
                    onClose={() => setIsDateFocused(false)}
                    placeholder={props.patientData.SuicidalIdeation.DateFormat}
                    numberOfMonths={1}
                    displayFormat="MM/DD/YYYY"
                  />
                )}
                {props.patientData.SuicidalIdeation.DateFormat ===
                  'MM/YYYY' && (
                  <DatePicker
                    className="date-input"
                    selected={
                      !props.patientData.SuicidalIdeation.Date
                        ? ''
                        : new Date(props.patientData.SuicidalIdeation.Date)
                    }
                    placeholderText={
                      props.patientData.SuicidalIdeation.DateFormat
                    }
                    onChange={(date) => handleSIStartDate(date)}
                    maxDate={new Date()}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                )}
                {props.patientData.SuicidalIdeation.DateFormat === 'YYYY' && (
                  <DatePicker
                    className="date-input"
                    selected={
                      !props.patientData.SuicidalIdeation.Date
                        ? ''
                        : new Date(props.patientData.SuicidalIdeation.Date)
                    }
                    placeholderText={
                      props.patientData.SuicidalIdeation.DateFormat
                    }
                    onChange={(date) => handleSIStartDate(date)}
                    maxDate={new Date()}
                    dateFormat="yyyy"
                    showYearPicker
                  />
                )}
              </Form.Group>
            </Form.Group>
            <Form.Group as={Col} sm={12} md={4} lg={4}>
              <Form.Label style={{ fontWeight: 'bold' }}>Frequency</Form.Label>
              <Form.Control
                bsPrefix="form-input"
                as="select"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    SuicidalIdeation: {
                      ...props.patientData.SuicidalIdeation,
                      Frequency: e.target.value,
                    },
                  })
                }}
                value={props.patientData.SuicidalIdeation.Frequency}
              >
                <option key={0} name="Select one" value="">
                  Select one
                </option>
                {FREQUENCY.map((type, index) => (
                  <option key={index + 1} id={type} name={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} sm={12} md={8} lg={8}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Plan/timing/notes
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    SuicidalIdeation: {
                      ...props.patientData.SuicidalIdeation,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.SuicidalIdeation.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="Previous Suicidal Ideation"
            label="Previous Suicidal ideation"
            disabled={props.patientData.NoSuicideDanger}
            checked={JSON.parse(
              props.patientData.PreviousSuicidalIdeation.state
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                PreviousSuicidalIdeation: {
                  ...props.patientData.PreviousSuicidalIdeation,
                  state: !props.patientData.PreviousSuicidalIdeation.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.PreviousSuicidalIdeation.state && (
          <>
            <Form.Group
              style={{ marginRight: 24 }}
              as={Col}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={4}
            >
              <Row style={{ width: 'min-content' }}>
                <Col>
                  <Form.Label style={{ fontWeight: 'bold' }}>Start</Form.Label>
                </Col>
                <Col>
                  <ButtonGroup style={{ marginLeft: -14 }} toggle size="sm">
                    {dateFormats.map((btn, idx) => (
                      <ToggleButton
                        className="date-format-selector"
                        variant="primary"
                        key={idx}
                        type="radio"
                        name="radio"
                        value={btn.format}
                        style={{ fontSize: '10px' }}
                        checked={
                          props.patientData.PreviousSuicidalIdeation
                            .DateFormat === btn.format
                        }
                        onChange={(e) =>
                          props.setPatientData({
                            ...props.patientData,
                            PreviousSuicidalIdeation: {
                              ...props.patientData.PreviousSuicidalIdeation,
                              DateFormat: e.currentTarget.value,
                            },
                          })
                        }
                      >
                        {btn.format}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </Col>
              </Row>
              <Form.Group>
                {props.patientData.PreviousSuicidalIdeation.DateFormat ===
                  'MM/DD/YYYY' && (
                  <SingleDatePicker
                    date={
                      props.patientData.PreviousSuicidalIdeation.StartDate
                        ? moment(
                            props.patientData.PreviousSuicidalIdeation.StartDate
                          )
                        : moment()
                    }
                    onDateChange={(date) => handlePSIStartDate(date)}
                    id="mmddyyyy-date"
                    focused={isDatefocused}
                    onFocusChange={(f) => setIsDateFocused(f.focused)}
                    onClose={() => setIsDateFocused(false)}
                    placeholder={
                      props.patientData.PreviousSuicidalIdeation.DateFormat
                    }
                    numberOfMonths={1}
                    displayFormat="MM/DD/YYYY"
                    isOutsideRange={() => false}
                  />
                )}
                {props.patientData.PreviousSuicidalIdeation.DateFormat ===
                  'MM/YYYY' && (
                  <DatePicker
                    className="date-input"
                    selected={
                      props.patientData.PreviousSuicidalIdeation.StartDate ===
                      ''
                        ? ''
                        : new Date(
                            props.patientData.PreviousSuicidalIdeation.StartDate
                          )
                    }
                    placeholderText={
                      props.patientData.PreviousSuicidalIdeation.DateFormat
                    }
                    onChange={(date) => handlePSIStartDate(date)}
                    maxDate={new Date()}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                )}
                {props.patientData.PreviousSuicidalIdeation.DateFormat ===
                  'YYYY' && (
                  <DatePicker
                    className="date-input"
                    selected={
                      props.patientData.PreviousSuicidalIdeation.StartDate ===
                      ''
                        ? ''
                        : new Date(
                            props.patientData.PreviousSuicidalIdeation.StartDate
                          )
                    }
                    placeholderText={
                      props.patientData.PreviousSuicidalIdeation.DateFormat
                    }
                    onChange={(date) => handlePSIStartDate(date)}
                    maxDate={new Date()}
                    dateFormat="yyyy"
                    showYearPicker
                  />
                )}
              </Form.Group>
            </Form.Group>
            <Form.Group
              style={{ marginRight: 24 }}
              as={Col}
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={4}
            >
              <Form.Label className="patient">End</Form.Label>
              {props.patientData.PreviousSuicidalIdeation.DateFormat ===
                'MM/DD/YYYY' && (
                <SingleDatePicker
                  date={
                    props.patientData.PreviousSuicidalIdeation.EndDate
                      ? moment(
                          props.patientData.PreviousSuicidalIdeation.EndDate
                        )
                      : moment()
                  }
                  onDateChange={(date) => handlePSIEndDate(date)}
                  id="mmddyyyy-date"
                  focused={isDatefocused}
                  onFocusChange={(f) => setIsDateFocused(f.focused)}
                  onClose={() => setIsDateFocused(false)}
                  placeholder={
                    props.patientData.PreviousSuicidalIdeation.DateFormat
                  }
                  numberOfMonths={1}
                  displayFormat="MM/DD/YYYY"
                  isOutsideRange={() => false}
                />
              )}
              {props.patientData.PreviousSuicidalIdeation.DateFormat ===
                'MM/YYYY' && (
                <DatePicker
                  className="date-input"
                  selected={
                    !props.patientData.PreviousSuicidalIdeation.EndDate
                      ? ''
                      : new Date(
                          props.patientData.PreviousSuicidalIdeation.EndDate
                        )
                  }
                  placeholderText={
                    props.patientData.PreviousSuicidalIdeation.DateFormat
                  }
                  onChange={(date) => handlePSIEndDate(date)}
                  maxDate={new Date()}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              )}

              {props.patientData.PreviousSuicidalIdeation.DateFormat ===
                'YYYY' && (
                <DatePicker
                  className="date-input"
                  selected={
                    !props.patientData.PreviousSuicidalIdeation.EndDate
                      ? ''
                      : new Date(
                          props.patientData.PreviousSuicidalIdeation.EndDate
                        )
                  }
                  placeholderText={
                    props.patientData.PreviousSuicidalIdeation.DateFormat
                  }
                  onChange={(date) => handlePSIEndDate(date)}
                  maxDate={new Date()}
                  dateFormat="yyyy"
                  showYearPicker
                />
              )}
            </Form.Group>
            <Form.Group as={Col} sm={12} md={3} lg={3}>
              <Form.Label style={{ fontWeight: 'bold' }}>Frequency</Form.Label>
              <Form.Control
                bsPrefix="form-input"
                as="select"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    PreviousSuicidalIdeation: {
                      ...props.patientData.PreviousSuicidalIdeation,
                      Frequency: e.target.value,
                    },
                  })
                }}
                value={props.patientData.PreviousSuicidalIdeation.Frequency}
              >
                <option key={0} name="Select one" value="">
                  Select one
                </option>
                {FREQUENCY.map((type, index) => (
                  <option key={index + 1} id={type} name={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} sm={12} md={8} lg={8}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Plan/timing/notes
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    PreviousSuicidalIdeation: {
                      ...props.patientData.PreviousSuicidalIdeation,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.PreviousSuicidalIdeation.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginLeft: 'auto' }}
        >
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Self Harm
          </Form.Label>
          {showSelfHarm()}
          {!props.patientData.NoSuicideDanger && (
            <Button
              bsPrefix="plain-button center info button-label"
              disabled={props.patientData.NoSuicideDanger}
              onClick={() => {
                props.saveNewSelfHarm()
              }}
            >
              + Add history of self harm
            </Button>
          )}
        </Form.Group>
        <hr />
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Violence to others
          </Form.Label>
          <Form.Check
            type="checkbox"
            id="Violence to others"
            label="Violence to others"
            disabled={props.patientData.NoSuicideDanger}
            checked={JSON.parse(props.patientData.ViolenceToOthers.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                ViolenceToOthers: {
                  ...props.patientData.ViolenceToOthers,
                  state: !props.patientData.ViolenceToOthers.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.ViolenceToOthers.state && (
          <>
            <Form.Group as={Col} xs={6} sm={6} md={3} lg={3} xl={3}>
              <Row style={{ width: 'min-content' }}>
                <Col>
                  <Form.Label style={{ fontWeight: 'bold' }}>Start</Form.Label>
                </Col>
                <Col>
                  <ButtonGroup style={{ marginLeft: -44 }} toggle size="sm">
                    {dateFormats.map((btn, idx) => (
                      <ToggleButton
                        className="date-format-selector"
                        variant="primary"
                        key={idx}
                        type="radio"
                        name="radio"
                        value={btn.format}
                        style={{ fontSize: '10px' }}
                        checked={
                          props.patientData.ViolenceToOthers.DateFormat ===
                          btn.format
                        }
                        onChange={(e) =>
                          props.setPatientData({
                            ...props.patientData,
                            ViolenceToOthers: {
                              ...props.patientData.ViolenceToOthers,
                              DateFormat: e.currentTarget.value,
                            },
                          })
                        }
                      >
                        {btn.format}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </Col>
              </Row>

              <Form.Group>
                {props.patientData.ViolenceToOthers.DateFormat ===
                  'MM/YYYY' && (
                  <DatePicker
                    className="date-input"
                    selected={
                      !props.patientData.ViolenceToOthers.StartDate
                        ? ''
                        : new Date(props.patientData.ViolenceToOthers.StartDate)
                    }
                    placeholderText={
                      props.patientData.ViolenceToOthers.DateFormat
                    }
                    onChange={(date) => handleViolenceStartDate(date)}
                    maxDate={new Date()}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                )}

                {props.patientData.ViolenceToOthers.DateFormat === 'YYYY' && (
                  <DatePicker
                    className="date-input"
                    selected={
                      !props.patientData.ViolenceToOthers.StartDate
                        ? ''
                        : new Date(props.patientData.ViolenceToOthers.StartDate)
                    }
                    placeholderText={
                      props.patientData.ViolenceToOthers.DateFormat
                    }
                    onChange={(date) => handleViolenceStartDate(date)}
                    maxDate={new Date()}
                    dateFormat="yyyy"
                    showYearPicker
                  />
                )}
              </Form.Group>
            </Form.Group>
            <Form.Group as={Col} xs={6} sm={6} md={3} lg={3} xl={3}>
              <Form.Label className="patient">End</Form.Label>
              {props.patientData.ViolenceToOthers.DateFormat === 'MM/YYYY' && (
                <DatePicker
                  className="date-input"
                  selected={
                    !props.patientData.ViolenceToOthers.EndDate
                      ? ''
                      : new Date(props.patientData.ViolenceToOthers.EndDate)
                  }
                  placeholderText={
                    props.patientData.ViolenceToOthers.DateFormat
                  }
                  onChange={(date) => handleViolenceEndDate(date)}
                  maxDate={new Date()}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              )}

              {props.patientData.ViolenceToOthers.DateFormat === 'YYYY' && (
                <DatePicker
                  className="date-input"
                  selected={
                    !props.patientData.ViolenceToOthers.EndDate
                      ? ''
                      : new Date(props.patientData.ViolenceToOthers.EndDate)
                  }
                  placeholderText={
                    props.patientData.ViolenceToOthers.DateFormat
                  }
                  onChange={(date) => handleViolenceEndDate(date)}
                  maxDate={new Date()}
                  dateFormat="yyyy"
                  showYearPicker
                />
              )}
            </Form.Group>
            <Form.Group as={Col} sm={12} md={6} lg={6}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    ViolenceToOthers: {
                      ...props.patientData.ViolenceToOthers,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.ViolenceToOthers.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          style={{ textAlign: 'center', marginLeft: 'auto' }}
        >
          <Link
            to={{
              pathname: `/patient/progress?patientId=${patientId}&providerId=${providerId}`,
            }}
            target="_blank"
            style={{ marginLeft: 'auto', marginRight: '7px' }}
          >
            <Button variant="primary">
              See SI/DTS/DTO questionnaire scores
            </Button>
          </Link>
          <Link
            to={{
              pathname: `/patient/settings?patientId=${patientId}&providerId=${providerId}`,
            }}
            target="_blank"
            style={{ marginLeft: '7px', marginRight: 'auto' }}
          >
            <Button variant="primary">Administer questionnaires</Button>
          </Link>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional notes
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            bsPrefix="form-input"
            disabled={props.patientData.NoSuicideDanger}
            onChange={(e) => {
              props.setPatientData({
                ...props.patientData,
                SuicideAdditionalNotes: e.target.value,
              })
            }}
            value={props.patientData.SuicideAdditionalNotes}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
