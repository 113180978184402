// Import react
import React, { useState } from 'react'

// Import components
import { Button } from 'react-bootstrap'

// Import libs/other
import EditAllergy from '../Forms/EditAllergy'

const EditAllergyButton = (props) => {
  const [show, setShow] = useState(false)

  function handleShow() {
    setShow(!show)
  }

  return (
    <>
      <Button id={props.tagName} bsPrefix="button-edit" onClick={handleShow}>
        <i className="fa fa-pencil" aria-hidden="true" />
      </Button>
      {show && (
        <EditAllergy
          allergy={props.allergy}
          patient={props.patient}
          handleAllergyDataChange={props.handleAllergyDataChange}
          handleApiChange={props.handleApiChange}
          handleShow={handleShow}
        />
      )}
    </>
  )
}

export default EditAllergyButton
