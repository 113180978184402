import React from 'react'

import { useMutation } from '@tanstack/react-query'

import { updateClaimMemo } from '../../../api/insuranceClaims'
import { logErrorAndNotify } from '../../../libs/notificationLib'
import {
  Button,
  CollapsibleCard,
  TextArea,
} from '../../../stories/BaseComponents'

import styles from './ClaimMemo.module.scss'

export interface ClaimMemoProps {
  initialValue: string
  value: string
  onChange: (val: string) => void
  onSave: (val: string) => void
  disabled?: boolean
  claimId: string
  patientId: string
}

const ClaimMemo = ({
  initialValue,
  value,
  onChange,
  onSave,
  disabled = false,
  claimId,
  patientId,
}: ClaimMemoProps) => {
  const { mutate: doUpdateClaimMemo, isLoading } = useMutation(updateClaimMemo)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value)
  }

  const handleSave = () => {
    doUpdateClaimMemo(
      {
        claimId,
        patientId,
        body: { claimMemo: value || null },
      },
      {
        onError: (error: any) => {
          logErrorAndNotify('There was an error saving the memo', error)
        },
        onSuccess: () => {
          onSave(value)
        },
      }
    )
  }

  const handleCancel = () => {
    onChange(initialValue)
  }

  const isValueSame = initialValue === value
  return (
    <CollapsibleCard
      title="Claim memo"
      isDefaultOpened
      _testId="claim-memo"
      iconTestId="claim-memo-action"
    >
      <TextArea
        className={styles.input}
        placeholder="Add notes"
        value={value}
        onChange={handleChange}
        disabled={disabled || isLoading}
        data-testid={'claim-memo-input'}
      />
      <div className={styles.btnsContainer}>
        <Button
          type="default"
          onClick={handleCancel}
          testId="claim-memo-cancel-button"
          disabled={disabled || isLoading || isValueSame}
          id="claim-memo-cancel-button"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSave}
          testId="claim-memo-save-button"
          loading={isLoading}
          disabled={disabled || isLoading || isValueSame}
        >
          Save
        </Button>
      </div>
    </CollapsibleCard>
  )
}

export default ClaimMemo
