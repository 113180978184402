import { CoreNode } from '../../v2/notes/Components/Blocks/Core/CoreNode'
import { CUSTOM_TEMPLATE_BLOCK } from '../../v2/notes/Components/Templates/types'
import { StubTableBlock } from './StubTableBlock'

export const TemplateBlockNode = () =>
  CoreNode({
    name: CUSTOM_TEMPLATE_BLOCK,
    BlockComponent: (props) => <StubTableBlock nodeViewProps={props} />,
    tag: 'template-block',
    addedAttributes: ['id', 'type'],
  })
