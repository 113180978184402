import axios from 'axios'

import { NdcOption } from './NationalDrugCodeSelect'

type ActiveIngredient = {
  name: string
  strength: string
}

type Packaging = {
  package_ndc: string
  description: string
  marketing_start_date: string
  sample: boolean
}

type OpenFDA = {
  manufacturer_name: string[]
  rxcui: string[]
  spl_set_id: string[]
  is_original_packager: boolean[]
  unii: string[]
  upc?: string[]
  nui?: string[]
  pharm_class_cs?: string[]
  pharm_class_epc?: string[]
}

export type Product = {
  product_ndc: string
  generic_name: string
  labeler_name: string
  dea_schedule?: string
  brand_name: string
  active_ingredients: ActiveIngredient[]
  finished: boolean
  packaging: Packaging[]
  listing_expiration_date: string
  openfda: OpenFDA
  marketing_category: string
  dosage_form: string
  spl_id: string
  product_type: string
  route: string[]
  marketing_start_date: string
  product_id: string
  application_number: string
  brand_name_base: string
  pharm_class?: string[]
}

const NDC_SEARCH_URL = 'https://api.fda.gov/drug/ndc.json?search='

/**
 * Searches for National Drug Code (NDC) options based on a search term.
 * This search utilizes the OpenFDA API to find NDC options.
 * More information can be found at https://open.fda.gov/apis/drug/ndc/.
 *
 * Here is a link to the searchable fields https://open.fda.gov/apis/drug/ndc/searchable-fields/
 * Searches on packaging.package_ndc, brand_name, and packaging.description`
 * @param {string} term - The search term to use for finding NDC options.
 * @returns {Promise<NdcOption[] | null>} - A promise that resolves to an array of NDC options.
 * Each option is an object with a `label` and `value` property.
 * The `label` is a string that includes the brand name, description, and NDC of the product.
 * The `value` is the package NDC of the product.
 */
export const search = async (term: string): Promise<NdcOption[] | null> => {
  if (!term) return null
  const url =
    NDC_SEARCH_URL +
    `brand_name:${term}*+packaging.package_ndc:${encodeURIComponent(
      term
    )}*+packaging.description:${term}*&limit=20`

  let res
  try {
    res = await axios.get(url.toString())
  } catch (e) {
    console.info(`Unable to get response from FDA API ${e}`)
    return []
  }

  const ndcOptions: NdcOption[] =
    res?.data?.results?.flatMap((opt: Product) =>
      opt.packaging.map((optPackage: Packaging) => ({
        label: `${opt.brand_name}, ${optPackage.package_ndc}, ${optPackage.description}`,
        value: optPackage.package_ndc,
      }))
    ) || []

  return ndcOptions
}
