import React, { useEffect, useState } from 'react'

import 'moment-timezone'
import { Button, Col, Form, Row } from 'react-bootstrap'
import 'react-dates/initialize'

import { convertTime12to24 } from '../../../../../shared/Helpers/utils'
import CustomTimePicker from '../../../../../stories/BaseComponents/CustomTimePicker'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

// NOTE: If the name is not specified, then the value is used as the name in the dropdown
const medicationAgent = [
  { name: 'Benadryl (diphenhydramine)', value: 'Benadryl' },
  { name: 'Brevibloc (esmolol)', value: 'Esmolol' },
  { value: 'Cardene (nicardipine)' },
  { name: 'Catapres (clonidine)', value: 'Clonidine' },
  { value: 'Chlormag (magnesium chloride)' },
  { name: 'Decadron (dexamethasone)', value: 'Decadron' },
  { value: 'Diprivan (propofol)' },
  { value: 'Hydralazine' },
  { name: 'Inapsine (droperidol)', value: 'Droperidol' },
  { value: 'Ketamine' },
  { value: 'Labetalol' },
  { value: 'Lidocaine' },
  { name: 'Lopressor (metoprolol)', value: 'Metoprolol' },
  { name: 'Magnesium Sulfate', value: 'MgSO4' },
  { value: 'Meclizine' },
  { name: 'Neurontin (gabapentin)', value: 'Gabapentin' },
  { value: 'Nitroglycerin' },
  { name: 'Phenergan (promethazine)', value: 'Phenergan' },
  { name: 'Pitocin (oxytocin)', value: 'Pitocin' },
  { value: 'Reglan (metoclopramide)' },
  { value: 'Scopolamine' },
  { name: 'Toradol (ketorolac)', value: 'Toradol' },
  { name: 'Tylenol (acetaminophen)', value: 'Tylenol' },
  { name: 'Versed (midazolam)', value: 'Versed' },
  { name: 'Zofran (ondansetron)', value: 'Zofran' },
  { name: '0.9% Sodium Chloride', value: '0.9% Normal Saline' },
  { value: 'Other (type in)' },
]

const medicationAgentRoute = [
  'IV infusion',
  'IV push',
  'PO',
  'IM',
  'Patch',
  'Other (type)',
]

const medicationUnits = ['mg', 'g', 'Other (units)']

// TODO: need to do loading for this component
export default function ShowSavedAM(props) {
  const [additionalMedicationName, setAdditionalMedicationName] = useState(
    props.AdditionalMedicationName || ''
  )
  const [additionalMedicationOther, setAdditionalMedicationOther] = useState(
    props.AdditionalMedicationOther || ''
  )
  const [
    additionalMedicationAdministrationRoute,
    setAdditionalMedicationAdministrationRoute,
  ] = useState(props.AdditionalMedicationAdministrationRoute || '')
  const [additionalMedicationDosage, setAdditionalMedicationDosage] = useState(
    props.AdditionalMedicationDosage || ''
  )
  const [
    additionalMedicationOtherAdministrationRoute,
    setAdditionalMedicationOtherAdministrationRoute,
  ] = useState(props.AdditionalMedicationOtherAdministrationRoute || '')
  const [additionalMedicationUnits, setAdditionalMedicationUnits] = useState(
    props.AdditionalMedicationUnits || ''
  )
  const [additionalMedicationUnitsOther, setAdditionalMedicationUnitsOther] =
    useState(props.AdditionalMedicationUnitsOther || '')
  const [additionalMedicationTime, setAdditionalMedicationTime] = useState(
    props.AdditionalMedicationTime || ''
  )

  useEffect(() => {
    setAdditionalMedicationName(props.AdditionalMedicationName || '')
    setAdditionalMedicationOther(props.AdditionalMedicationOther || '')
    setAdditionalMedicationAdministrationRoute(
      props.AdditionalMedicationAdministrationRoute || ''
    )
    setAdditionalMedicationDosage(props.AdditionalMedicationDosage || '')
    setAdditionalMedicationOtherAdministrationRoute(
      props.AdditionalMedicationOtherAdministrationRoute || ''
    )
    setAdditionalMedicationUnits(props.AdditionalMedicationUnits || '')
    setAdditionalMedicationUnitsOther(
      props.AdditionalMedicationUnitsOther || ''
    )
    setAdditionalMedicationTime(props.AdditionalMedicationTime || '')
  }, [
    props.AdditionalMedicationName,
    props.AdditionalMedicationAdministrationRoute,
    props.AdditionalMedicationOther,
    props.AdditionalMedicationDosage,
    props.AdditionalMedicationOtherAdministrationRoute,
    props.AdditionalMedicationUnits,
    props.AdditionalMedicationUnitsOther,
    props.AdditionalMedicationTime,
  ])

  useEffect(() => {
    const fields = {
      AdditionalMedicationName: additionalMedicationName,
      AdditionalMedicationOther: additionalMedicationOther,
      AdditionalMedicationAdministrationRoute:
        additionalMedicationAdministrationRoute,
      AdditionalMedicationDosage: additionalMedicationDosage,
      AdditionalMedicationOtherAdministrationRoute:
        additionalMedicationOtherAdministrationRoute,
      AdditionalMedicationUnits: additionalMedicationUnits,
      AdditionalMedicationUnitsOther: additionalMedicationUnitsOther,
      AdditionalMedicationTime: additionalMedicationTime,
    }
    props.saveEditAdditionalMedication(props.index, fields)
  }, [
    additionalMedicationName,
    additionalMedicationOther,
    additionalMedicationAdministrationRoute,
    additionalMedicationDosage,
    additionalMedicationOtherAdministrationRoute,
    additionalMedicationUnits,
    additionalMedicationUnitsOther,
    additionalMedicationTime,
  ])

  return (
    <>
      <tr style={{ width: '800px' }}>
        <td>
          {additionalMedicationName !== 'Other (type in)' ? (
            <Form.Control
              bsPrefix="form-input"
              style={{ alignSelf: 'center', textAlign: 'center' }}
              as="select"
              onChange={(e) => setAdditionalMedicationName(e.target.value)}
              value={additionalMedicationName}
              disabled={props.disabled}
            >
              <option key={0} name="Select" value="">
                Select one
              </option>
              {medicationAgent.map((medType, idx) => {
                const name = medType.name || medType.value
                return (
                  <option
                    key={`med-agent-${idx}`}
                    name={name}
                    value={medType.value}
                  >
                    {name}
                  </option>
                )
              })}
            </Form.Control>
          ) : (
            <Form.Control
              bsPrefix="form-input"
              style={{ alignSelf: 'center', textAlign: 'center' }}
              value={additionalMedicationOther}
              onChange={(e) => setAdditionalMedicationOther(e.target.value)}
              disabled={props.disabled}
            />
          )}
        </td>
        <td>
          <Form.Control
            bsPrefix="form-input"
            style={{ alignSelf: 'center', textAlign: 'center' }}
            type="number"
            step=".01"
            value={additionalMedicationDosage}
            onWheel={(event) => event.currentTarget.blur()}
            onChange={(e) => setAdditionalMedicationDosage(e.target.value)}
            disabled={props.disabled}
          />
        </td>
        <td>
          {additionalMedicationUnits !== 'Other (units)' ? (
            <Form.Control
              bsPrefix="form-input"
              style={{ alignSelf: 'center', textAlign: 'center' }}
              as="select"
              onChange={(e) => setAdditionalMedicationUnits(e.target.value)}
              value={additionalMedicationUnits}
              disabled={props.disabled}
            >
              <option key={0} name="select" value="">
                Select one
              </option>
              {medicationUnits.map((unit, index) => (
                <option key={index + 1} name={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </Form.Control>
          ) : (
            <Form.Control
              bsPrefix="form-input"
              style={{ alignSelf: 'center', textAlign: 'center' }}
              value={additionalMedicationUnitsOther}
              onChange={(e) =>
                setAdditionalMedicationUnitsOther(e.target.value)
              }
              disabled={props.disabled}
            />
          )}
        </td>
        <td>
          {additionalMedicationAdministrationRoute !== 'Other (type)' ? (
            <Form.Control
              bsPrefix="form-input"
              style={{
                alignSelf: 'center',
                textAlign: 'center',
                width: '224px',
              }}
              as="select"
              onChange={(e) =>
                setAdditionalMedicationAdministrationRoute(e.target.value)
              }
              value={additionalMedicationAdministrationRoute}
              disabled={props.disabled}
            >
              <option key={0} name="select" value="">
                Select one
              </option>
              {medicationAgentRoute.map((route, index) => (
                <option key={index + 1} name={route} value={route}>
                  {route}
                </option>
              ))}
            </Form.Control>
          ) : (
            <Form.Control
              bsPrefix="form-input"
              style={{
                alignSelf: 'center',
                textAlign: 'center',
                width: '224px',
              }}
              value={additionalMedicationOtherAdministrationRoute}
              onChange={(e) =>
                setAdditionalMedicationOtherAdministrationRoute(e.target.value)
              }
              disabled={props.disabled}
            />
          )}
        </td>
        <td>
          <CustomTimePicker
            value={additionalMedicationTime}
            onChange={(value) => {
              setAdditionalMedicationTime(convertTime12to24(value))
            }}
            disabled={props.disabled}
          />
        </td>
        <td>
          <Row>
            <Col>
              {!props.disabled && (
                <Button
                  bsPrefix="center plain-button info button-label"
                  onClick={() => {
                    props.deleteAM(props.index)
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true" />
                  &nbsp;Remove
                </Button>
              )}
            </Col>
          </Row>
        </td>
      </tr>
    </>
  )
}
