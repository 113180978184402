import React, { useMemo } from 'react'

import { Dropdown as AntDDropdown, Menu as AntDMenu } from 'antd'

import { TestId } from '../../shared-types'
import Divider from './Divider'
import Menu, { MenuItem } from './Menu'

import styles from './Dropdown.module.scss'

type MenuClickEventHandler = ({ key }: { key: string }) => void

export type MenuItemType = {
  key: string
  danger?: boolean
  disabled?: boolean
  text: React.ReactNode
  type?: string
  onClick?: MenuClickEventHandler
  children?: {
    key: string
    text: React.ReactNode
    type?: string
    onClick?: MenuClickEventHandler
  }[]
} & TestId

type DropdownProps = Partial<React.ComponentProps<typeof AntDDropdown>> & {
  items: MenuItemType[]
  onAddClick?: MenuClickEventHandler
} & TestId

const renderMenu = (
  menuItem: MenuItemType[],
  onAddClick?: MenuClickEventHandler
) => {
  return (
    <Menu onClick={(info) => onAddClick && onAddClick(info)}>
      {menuItem.map((item) => {
        if (item.type === 'divider') {
          return <Divider className={styles.divider} key={item.key} />
        }
        if (item.children) {
          return (
            <AntDMenu.SubMenu
              key={item.key}
              title={item.text}
              className={styles.subMenu}
              popupClassName={styles.popupMenu}
            >
              {item.children.map(({ key, text, onClick, type }) => {
                if (type === 'divider') {
                  return <Divider key={key} className={styles.divider} />
                }
                return (
                  <MenuItem key={key} onClick={(e) => onClick?.(e)}>
                    {text}
                  </MenuItem>
                )
              })}
            </AntDMenu.SubMenu>
          )
        }
        return (
          <MenuItem
            danger={item.danger}
            key={item.key}
            disabled={item.disabled}
            onClick={(e) => item.onClick && item.onClick(e)}
            data-testid={item.testId}
          >
            {item.text}
          </MenuItem>
        )
      })}
    </Menu>
  )
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  items,
  testId,
  ...props
}) => {
  const menu = useMemo(() => renderMenu(items, props.onAddClick), [items])
  return (
    <AntDDropdown data-testid={testId} {...props} overlay={menu}>
      {children}
    </AntDDropdown>
  )
}
export default Dropdown
