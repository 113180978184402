export interface S3Config {
  REGION: string
  BUCKET: string
}

export interface CognitoConfig {
  REGION: string
  USER_POOL_ID: string
  APP_CLIENT_ID: string
  IDENTITY_POOL_ID: string
}

export interface DynamicConfig {
  apiUrl: string
  ENV: 'local' | 'dev' | 'test' | 'staging' | 'prod' | 'blue' | 'green' | string
  s3: S3Config
  GOOGLE_API_KEY: string
  apiGateway: {
    REGION: string
    API_GATEWAY_URL: string
  }
  cognito: CognitoConfig
  hellosign: {
    CLIENT_ID: string
  }
  sendbird: {
    APP_ID: string
  }
  sentry: {
    dsn: string
  }
  datadog: {
    applicationId: string
    clientToken: string
    site: string
    service: string
  }
  isProd: boolean
  version: string
  smartyStreets: {
    key: string
  }
}

// Consider to move this to AWS SSM
export const getS3Config = (awsProfile: string): S3Config => {
  const S3Config: Record<string, S3Config> = {
    default: {
      REGION: 'us-west-2',
      BUCKET: 'osmind-notes-staging',
    },
    'osmind-dev': {
      REGION: 'us-west-2',
      BUCKET: 'osmind-notes-dev',
    },
  }
  return S3Config[awsProfile] || S3Config['default']
}

// Consider to move this to AWS SSM
export const getCognitoConfig = (awsProfile: string): CognitoConfig => {
  const cognitoConfig: Record<string, CognitoConfig> = {
    default: {
      REGION: 'us-west-2',
      USER_POOL_ID: 'us-west-2_pQRaDsUPB',
      APP_CLIENT_ID: '7a0km9dnsv0sc801rgjlimdndt',
      IDENTITY_POOL_ID: 'us-west-2:a46488f8-e071-4ff1-aaaf-5b2f9d1f3672',
    },
    'osmind-dev': {
      REGION: 'us-west-2',
      USER_POOL_ID: 'us-west-2_56rZIHPZY',
      APP_CLIENT_ID: '3crgi12915rsqnv31lhbllmvhc',
      IDENTITY_POOL_ID: 'us-west-2:a66b7f3e-b0b0-4b54-bd8c-dafe45aeb999',
    },
  }
  return cognitoConfig[awsProfile] || cognitoConfig['default']
}

// Used only locally
export const defaultConfig: DynamicConfig = {
  apiUrl: process.env.REACT_APP_URL || 'http://localhost:4000',
  ENV: process.env.REACT_APP_STAGE || 'local',
  s3: getS3Config(process.env.REACT_APP_AWS_PROFILE || 'default'),
  GOOGLE_API_KEY: 'AIzaSyDs3WPcCo4KHugHUm7b6E9nSxyUiIYI1OQ',
  apiGateway: {
    REGION: 'us-west-2',
    API_GATEWAY_URL:
      process.env.REACT_APP_URL || 'http://localhost:4000/legacy',
  },
  cognito: getCognitoConfig(process.env.REACT_APP_AWS_PROFILE || 'default'),
  hellosign: {
    CLIENT_ID: 'e2086339339709e60772804809aa8d0f',
  },
  sentry: {
    dsn: 'https://e543a904a9c54ed9b643d924a691f2cd@o421968.ingest.sentry.io/5342694',
  },
  datadog: {
    applicationId: 'e540ccb5-2a46-4f74-b738-c38708160b0c',
    clientToken: 'pubcc08dadeea38b34fee9689627d517e5b',
    site: 'datadoghq.com',
    service: 'osmind-provider-portal',
  },
  sendbird: {
    APP_ID: '9E3C7CFC-5795-455B-A9A6-99890C561923',
  },
  isProd: false,
  version: '',
  smartyStreets: {
    key: process.env.REACT_APP_SMARTY_STREETS_KEY ?? '172648316708510167',
  },
}

export class GlobalConfig {
  config: DynamicConfig = defaultConfig
  notDefinedYet = true

  public get(): DynamicConfig {
    if (this.notDefinedYet) {
      throw new Error(
        'Global config has not been defined yet. Be sure to call the getter only after the config has been downloaded and set. Probable cause is accessing globalConfig in static context.'
      )
    } else {
      return this.config
    }
  }

  public set(value: DynamicConfig): void {
    if (this.notDefinedYet) {
      this.config = value
      this.notDefinedYet = false
    } else {
      throw new Error(
        'Global config has already been defined and now has been called second time. This is probably not intended.'
      )
    }
  }

  // specifically for test purposes
  public reset(): void {
    this.notDefinedYet = true
  }
}

export const globalConfig = new GlobalConfig()

export const globalConfigUrl = '/globalConfig.json'
