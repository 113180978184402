import React, { createContext, useCallback, useEffect } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { ConnectionState } from '@sendbird/chat'
import ConnectionHandler from '@sendbird/uikit-react/handlers/ConnectionHandler'
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext'
import { v4 as uuidv4 } from 'uuid'

import { useFeatureFlags } from '../../libs/featureFlags'
import { SENDBIRD_SDK_OR_USER_ID_NOT_DEFINED } from '../../libs/sendbird/constants'
import { DATADOG_ACTION } from '../datadog/datadogActions'
import { useInactiveWindowTab } from '../inactiveWindowTab/useInactiveWindowTab'

/**
 * SENDBIRD CONNECTION LOGIC AND LINKS
 * - Sendbird Connection FAQ: https://community.sendbird.com/t/sendbird-connection-faq/451#:~:text=SendBird%20SDK%20internal%20reconnection%20logic
 * - Sendbird 20s WS timeout: https://community.sendbird.com/t/react-uikit-channel-component-doesnt-seem-to-realize-reconnection-status/8651/5
 * - Sendbird Foreground/Background state: https://sendbird.com/docs/chat/sdk/v4/javascript/push-notifications/overview-push-notifications
 */

/**
 * the properties mirror what's available in SendbirdSDK where possible
 */
export type SendbirdConnectionHandlerContext = {
  connectionState: ConnectionState
  isWindowTabActive: boolean
}

const initialState: SendbirdConnectionHandlerContext = {
  /**
   * assume connection on load
   * - this could be wrong but will be updated as the connection is attempted
   * */
  connectionState: ConnectionState.CONNECTING,
  isWindowTabActive: true,
}

export const SendbirdConnectionHandlerContext =
  createContext<SendbirdConnectionHandlerContext>(initialState)

const useSendbirdConnectionHandlerContext =
  (): SendbirdConnectionHandlerContext => {
    const {
      enableSendbirdConnectionManagement,
      enableSendbirdConnectionManagementLogs,
    } = useFeatureFlags()

    const store = useSendbirdStateContext()
    const sdk = store?.stores?.sdkStore?.sdk

    /**
     * disconnect sendbird callback
     * - no action if FF is not turned on
     */
    const disconnectSendbird = useCallback(() => {
      if (!enableSendbirdConnectionManagement) {
        return
      }

      if (enableSendbirdConnectionManagementLogs) {
        datadogRum.addAction(
          DATADOG_ACTION.OsmindPatientMessaging
            .SendbirdWSConnectionForceDisconnecting,
          {
            sendbirdUserId:
              sdk?.currentUser?.userId ?? SENDBIRD_SDK_OR_USER_ID_NOT_DEFINED,
          }
        )
      }
      /**
       * Let sendbird manage the WS connection state as part of "backgroundState"
       * - Alternative: `sdk?.disconnectWebSocket`
       *
       * If the SDK is not defined when moving this to background state,
       *  then there's nothing left to do, so no need to log about this
       */
      sdk?.setBackgroundState()
    }, [sdk])

    /**
     * (re)connect sendbird callback
     * - no action if FF is not turned on
     */
    const connectSendbird = useCallback(() => {
      if (!enableSendbirdConnectionManagement) {
        return
      }

      /**
       * Since we only did a WS disconnect, `sdk?.reconnect` will reconnect as needed
       */
      if (sdk?.connectionState !== ConnectionState.OPEN) {
        if (sdk) {
          if (enableSendbirdConnectionManagementLogs) {
            datadogRum.addAction(
              DATADOG_ACTION.OsmindPatientMessaging
                .SendbirdWSConnectionReconnecting,
              {
                sendbirdUserId:
                  sdk?.currentUser?.userId ??
                  SENDBIRD_SDK_OR_USER_ID_NOT_DEFINED,
              }
            )
          }
          /**
           * Let sendbird manage the WS connection state as part of "backgroundState"
           * - Alternative: `sdk?.reconnect()`
           */
          sdk.setForegroundState()
        } else if (!sdk && enableSendbirdConnectionManagementLogs) {
          // if SDK is not defined, log and then do nothing else
          /**
           * This is very hard to test functionally
           * - Requires mocking Sendbird SDK and then updating that from being defined to not defined
           */
          datadogRum.addAction(
            DATADOG_ACTION.OsmindPatientMessaging
              .SendbirdWSConnectionReconnectFailed,
            {
              sendbirdUserId: SENDBIRD_SDK_OR_USER_ID_NOT_DEFINED,
            }
          )
        }
      }
    }, [sdk])

    /**
     * PRIMARY ACTION (side effect): disconnect/connect based on window/tab active/inactive status
     */
    const { isActive: isWindowTabActive } = useInactiveWindowTab({
      onInactive: disconnectSendbird,
      onActive: connectSendbird,
    })

    useEffect(() => {
      if (!enableSendbirdConnectionManagement) {
        return
      }

      const uniqueHandlerId = uuidv4()

      try {
        const handler = new ConnectionHandler({
          /**
           * These two methods aren't used
           * - Identifying them here to prevent confusion if the Sendbird SDK is researched
           * - onConnected never fired on initial app load
           *     (likely due to always losing a race condition at init)
           * - onDisconnected should never be called
           *     since we're just closing the WS connection not logging out (disconnecting) from Sendbird
           */
          // onConnected() {},
          // onDisconnected() {},
          /**
           * This one would fire in our use case, but we don't have a use for it currently
           */
          // onReconnectStarted() {},
          /**
           * on successful reconnect, log to DD
           */
          onReconnectSucceeded: () => {
            if (enableSendbirdConnectionManagementLogs) {
              datadogRum.addAction(
                DATADOG_ACTION.OsmindPatientMessaging
                  .SendbirdWSConnectionReconnected,
                {
                  sendbirdUserId:
                    sdk?.currentUser?.userId ??
                    SENDBIRD_SDK_OR_USER_ID_NOT_DEFINED,
                }
              )
            }
          },
          /**
           * on failed reconnect, show notification and log to dd
           * - we could call `sdk?.reconnect()`, but it seems superfluous since Sendbird already tries 5 times to reconnect
           */
          onReconnectFailed: () => {
            if (enableSendbirdConnectionManagementLogs) {
              datadogRum.addAction(
                DATADOG_ACTION.OsmindPatientMessaging
                  .SendbirdWSConnectionReconnectFailed,
                {
                  sendbirdUserId:
                    sdk?.currentUser?.userId ??
                    SENDBIRD_SDK_OR_USER_ID_NOT_DEFINED,
                }
              )
            }
          },
        })

        if (typeof sdk?.addConnectionHandler === 'function') {
          sdk?.addConnectionHandler(uniqueHandlerId, handler)
        } else if (
          typeof sdk?.addConnectionHandler !== 'function' &&
          enableSendbirdConnectionManagementLogs
        ) {
          datadogRum.addAction(
            DATADOG_ACTION.OsmindPatientMessaging
              .SendbirdWSConnectionManagementSetupFailed,
            {
              sendbirdUserId:
                sdk?.currentUser?.userId ?? SENDBIRD_SDK_OR_USER_ID_NOT_DEFINED,
            }
          )
        }
      } catch {
        if (enableSendbirdConnectionManagementLogs) {
          datadogRum.addAction(
            DATADOG_ACTION.OsmindPatientMessaging
              .SendbirdWSConnectionManagementSetupFailed,
            {
              sendbirdUserId:
                sdk?.currentUser?.userId ?? SENDBIRD_SDK_OR_USER_ID_NOT_DEFINED,
            }
          )
        }
      }

      // Clean up the connection handler when the component unmounts
      return () => {
        try {
          sdk?.removeConnectionHandler(uniqueHandlerId)
        } catch {
          // this should resolve itself on next render
        }
      }
    }, [sdk])

    return {
      isWindowTabActive,
      connectionState: sdk?.connectionState,
    }
  }

export const SendbirdConnectionHandlerProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const connectionState = useSendbirdConnectionHandlerContext()

  return (
    <SendbirdConnectionHandlerContext.Provider value={connectionState}>
      {children}
    </SendbirdConnectionHandlerContext.Provider>
  )
}
