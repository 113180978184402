// Import react
import { useState } from 'react'

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState)

  return [
    fields,
    function (
      event,
      fieldChange = true,
      intervalChange = false,
      min = null,
      max = 100000
    ) {
      if (!fieldChange) {
        setValues({
          ...event,
        })
        // handles Form.Check field changes that aren't using events (in new/edit Medication forms)
      } else if (!event) {
        setValues({ ...fieldChange })
      } else if (intervalChange) {
        // setting no upper bound
        if (max > 10000 && event.target.value >= min) {
          setValues({
            ...fields,
            [event.target.id]: event.target.value,
          })
        } else if (event.target.value >= 0 && event.target.value <= max) {
          // lower and upper bound set to 0 and max respectively
          setValues({
            ...fields,
            [event.target.id]: event.target.value,
          })
        }
      } else if (event.target.type === 'checkbox') {
        setValues({
          ...fields,
          [event.target.id]: event.target.checked,
        })
      } else if (event.target.role === 'switch') {
        setValues({
          ...fields,
          [event.target.id]: event.target.checked,
        })
      } else if (event.target.type === 'radio') {
        if (event.target.value !== 'true' && event.target.value !== 'false') {
          setValues({
            ...fields,
            [event.target.id]: event.target.value,
          })
        } else {
          setValues({
            ...fields,
            [event.target.id]: JSON.parse(event.target.value),
          })
        }
      } else {
        setValues({
          ...fields,
          [event.target.id]: event.target.value,
        })
      }
    },
  ]
}
