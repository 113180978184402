import { convertTime12to24 } from '../../../libs/utils'

// default times from start to end of 24 hour day
enum DefaultHours {
  START = 0,
  END = 24,
}

export const generateTimeOptions = (
  minuteStep: number,
  startHour?: number,
  endHour?: number,
  extraOption?: string
) => {
  const start = startHour || DefaultHours.START
  const end = endHour || DefaultHours.END
  const times: string[] = []
  for (let i = start; i < end; i++) {
    let hour = i
    let amOrPm = 'am'

    if (i >= 12) {
      amOrPm = 'pm'
    }

    if (i > 12) {
      hour = hour - 12
    }

    const steps = 60 / minuteStep
    if (!Number.isInteger(steps)) {
      throw new Error('minuteStep must be divisible by 60')
    }
    for (let j = 0; j <= 60 - minuteStep; j += minuteStep) {
      let min = j.toString()
      if (min.length === 1) {
        min = `0${min}`
      }
      times.push(`${hour === 0 ? 12 : hour}:${min}${amOrPm}`)
    }
  }
  if (extraOption && !times.includes(extraOption)) {
    times.push(extraOption)
  }
  function sortTimes(a: string, b: string) {
    const timeA = new Date('2023-01-01 ' + convertTime12to24(a))
    const timeB = new Date('2023-01-01 ' + convertTime12to24(b))

    return timeA.getTime() - timeB.getTime()
  }
  const options = times.sort(sortTimes).map((time) => ({
    key: time,
    value: time,
    style: { marginTop: 4 },
  }))
  // AntD workaround to show last value in list
  options.push({
    key: 'dummy',
    value: '',
    style: { marginTop: 4 },
  })
  return options
}
