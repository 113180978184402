import { JSX, useState } from 'react'

import { RedoOutlined } from '@ant-design/icons'
import cx from 'classnames'

import { Typography } from '../../../../../../../stories/BaseComponents'
import { RefreshConfirmationModal } from '../../../../RefreshConfirmationModal'
import { DynamicBlockTypes } from '../../../Core/Block/DynamicBlockTypes'

import styles from './TableHeader.module.scss'

const blockTypeToRefreshText: Record<DynamicBlockTypes, string> = {
  DIAGNOSIS: 'diagnoses',
  MEDICATIONS: 'medications',
  ALLERGIES: 'allergies',
} as const

export const DynamicBlockHeader = ({
  blockUuid,
  buttons,
  isSigned,
  onBlockRefresh,
  title,
  blockType,
}: {
  title: string
  isSigned: boolean
  buttons: JSX.Element[]
  onBlockRefresh: () => void
  blockType: DynamicBlockTypes
  blockUuid: string
}): JSX.Element | null => {
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false)
  const { Link } = Typography

  const onRefreshClick = () => {
    setIsRefreshModalOpen(true)
  }

  const onConfirmRefresh = () => {
    onBlockRefresh()
    setIsRefreshModalOpen(false)
  }

  const onCancelRefresh = () => {
    setIsRefreshModalOpen(false)
  }
  return (
    <div className={styles.container}>
      <RefreshConfirmationModal
        modalType={blockType}
        isVisible={isRefreshModalOpen}
        onCancel={onCancelRefresh}
        onConfirm={onConfirmRefresh}
      />
      <h2 className={styles.label + ' block-header'}>{title}</h2>
      {!isSigned && buttons && (
        <div className={cx('block-buttons', styles.blockButtons)}>
          <Link
            role="button"
            tabIndex={0}
            data-testid={`${blockUuid}_refresh`}
            onClick={onRefreshClick}
          >
            <RedoOutlined /> Refresh {blockTypeToRefreshText[blockType]}
          </Link>
          {buttons}
        </div>
      )}
    </div>
  )
}
