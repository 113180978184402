import { Teammate } from '../../../shared-types'

export function getTextToDisplay(provId: string, activeTeam: Teammate[]) {
  for (const member of activeTeam) {
    if (member.cognitoId === provId) {
      return member.name
        ? member.credential
          ? member.credential + ' ' + member.name
          : member.name
        : member.email
    }
  }
  return ''
}
