import React, { useMemo } from 'react'

import cx from 'classnames'
import { sumBy } from 'lodash'

import { centsToUsdString } from '../../../libs/billing'
import {
  BillableEntityPayment,
  LineItem,
  PaymentAttempt,
  PaymentStatus,
  RefundAttempt,
} from '../../../shared-types'
import { Divider } from '../../BaseComponents'
import { Row } from './Row'
import {
  Transaction,
  TransactionCollapse,
  TransactionType,
  totalTransactions,
} from './TransactionCollapse'

import styles from '../Invoice.module.scss'

const GUTTER_WIDTH = 24
const ANT_TABLE_PADDING = 8
const DEFAULT_TOTAL_OFFSET = '70%'
const SECTION_WIDTH = 450

/**
 * Transforms a list of transactions into a list of payments/refunds.
 *
 * The amountCents property is calculated by reducing over the billableEntityPayments of the transaction. If a
 * billableEntityPayment matches the provided billableEntityUuid, its amountCents is added to the total. Otherwise, the
 * total remains the same.
 *
 * Note: Payments or refunds that don't match the invoice or claim are still returned, but with a 0 amountCents.
 *
 * After mapping over the transactions, the function filters out any transactions that were not successful.
 *
 * @param {Array<Transaction>} transactions - The list of transactions (refund or payment) to transform.
 * @param {string} billableEntityUuid - The UUID of the billable entity to match against.
 * @param {TransactionType} type - The type of the transactions (either Payment or Refund).
 * @returns {Array<{ status: PaymentStatus, createdAt: string | Date, amountCents: number, paymentMethod: PaymentMethod }>} The transformed list of payments/refunds.
 */
export const normalizeTransactions = (
  billableEntityUuid: string,
  transactions: PaymentAttempt[] | RefundAttempt[],
  type: TransactionType
): Transaction[] => {
  return transactions
    .map((transaction) => {
      const paymentAttempt = (
        type === TransactionType.REFUND
          ? (transaction as RefundAttempt).paymentAttempt
          : transaction
      ) as PaymentAttempt

      const computeAmountCentsReduce = (
        acc: number,
        billableEntityPayment: BillableEntityPayment
      ) => {
        if (
          billableEntityPayment.invoiceUuid === billableEntityUuid ||
          billableEntityPayment.insuranceClaimUuid === billableEntityUuid
        ) {
          return acc + billableEntityPayment.amountCents
        }
        return acc
      }

      const amountCents = (transaction.billableEntityPayments || []).reduce(
        computeAmountCentsReduce,
        0
      )

      return {
        status: paymentAttempt.status,
        createdAt: transaction.createdAt,
        amountCents,
        paymentMethod: paymentAttempt.paymentMethod,
      }
    })
    .filter((transaction) => transaction.status === PaymentStatus.SUCCESSFUL)
}

export const ChargeBreakdown = ({
  isEditing,
  payments,
  refunds,
  lineItems,
  invoiceUuid,
}: {
  isEditing: boolean
  payments: PaymentAttempt[]
  refunds: RefundAttempt[]
  lineItems: LineItem[]
  invoiceUuid: string
}) => {
  const [leftOffset, setLeftOffset] = React.useState(
    DEFAULT_TOTAL_OFFSET as string | number
  )

  // This useEffect lines up the total tally with the Total Charges column:
  //   1. when the page loads
  //   2. whenever the lineitems change because the table will auto-size
  React.useEffect(() => {
    const handleResize = () => {
      const totalChargeColumn = document.querySelectorAll('.totalCharge')[0]
      const totalChargePosition = totalChargeColumn?.getClientRects()[0]

      const lineItemsTable = document.querySelectorAll('.line-items')[0]
      const tablePosition = lineItemsTable?.getClientRects()[0]

      const getOffset = () => {
        if (tablePosition && totalChargePosition) {
          const totalChargePositionLeftOffset = totalChargePosition.left
          const tableWidth = tablePosition.width

          const offset =
            totalChargePositionLeftOffset -
            SECTION_WIDTH -
            2 * GUTTER_WIDTH +
            ANT_TABLE_PADDING +
            40
          const maxOffset = tableWidth - SECTION_WIDTH

          return Math.min(offset, maxOffset)
        } else {
          return '70%'
        }
      }

      const offset = getOffset()

      setLeftOffset(offset)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [lineItems, isEditing])

  const subtotal = useMemo(() => {
    return sumBy(lineItems, (lineItem) => {
      return lineItem.units * lineItem.unitChargeAmountCents
    })
  }, [lineItems])

  const discountsApplied = useMemo(() => {
    return sumBy(lineItems, (lineItem) => {
      return lineItem.totalAdjustmentAmountCents
    })
  }, [lineItems])

  const normalizedPayments = useMemo(
    () =>
      normalizeTransactions(
        invoiceUuid,
        payments,
        TransactionType.PAYMENT
      ).filter((payment) => payment.amountCents !== 0),
    [payments]
  )
  const normalizedRefunds = useMemo(
    () =>
      normalizeTransactions(
        invoiceUuid,
        refunds,
        TransactionType.REFUND
      ).filter((payment) => payment.amountCents !== 0),
    [refunds]
  )

  const amountDue = useMemo(() => {
    return (
      subtotal -
      discountsApplied -
      totalTransactions(normalizedPayments) +
      totalTransactions(normalizedRefunds)
    )
  }, [lineItems, normalizedPayments, normalizedRefunds])

  return (
    <div
      className={styles.totalContainer}
      style={{ width: SECTION_WIDTH, marginLeft: leftOffset }}
    >
      <Row
        rowClassName={styles.infoRow}
        label="Subtotal:"
        value={centsToUsdString(subtotal)}
        testId="subtotal-id"
      />

      {discountsApplied > 0 ? (
        <Row
          rowClassName={cx([styles.discount, styles.infoRow])}
          label="Total discounts applied:"
          value={`(-${centsToUsdString(discountsApplied)})`}
          testId="discounts-id"
        />
      ) : null}

      <Divider dashed />

      <Row
        rowClassName={cx(styles.infoRow)}
        label="Total:"
        value={centsToUsdString(subtotal - discountsApplied)}
        testId="total-id"
      />

      <TransactionCollapse
        transactions={normalizedPayments}
        type={TransactionType.PAYMENT}
      />
      <TransactionCollapse
        transactions={normalizedRefunds}
        type={TransactionType.REFUND}
      />

      <Divider />

      <Row
        rowClassName={styles.infoRow}
        rowStyle={{ height: 32 }}
        label="Balance due:"
        testId="balace-due-id"
        labelStyle={{ height: 32, alignItems: 'center' }}
        value={
          <span className={styles.balanceDueAmount}>
            {amountDue < 0 ? '--' : centsToUsdString(amountDue)}
          </span>
        }
      />

      {amountDue < 0 && (
        <span className={styles.overpaymentMessage}>
          {centsToUsdString(amountDue * -1)} will be returned to patient credit.
        </span>
      )}
    </div>
  )
}
