import React, { useState } from 'react'

import { Col, Container, Modal, Row } from 'react-bootstrap'

import { getRCopiaSSOUrl, syncRCopiaMeds } from '../../api/api-lib'
import LoaderButton from '../../components/Buttons/LoaderButton'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'

import './PatientProfile.scss'

export default function RCopiaMedsDisplay(props) {
  const [isLoadingRCopia, setIsLoadingRCopia] = useState(false)
  const [RCopiaUrl, setRCopiaUrl] = useState('')
  const [showRCopia, setShowRCopia] = useState(false)

  async function handleEditMedications() {
    // if this patient is not onboarded for erx, this component shouldn't be rendered, throw an error as extra layer of precaution
    // otherwise, bring up patients RCopia profile for provider to make edits there
    if (!props.patient.PatientRCopiaId) {
      throw new Error(
        `PatientId ${props.patient.PatientId} is not onboarded for eRx`
      )
    } else {
      setIsLoadingRCopia(true)
      notification(
        `Medications should be updated within the eRx module. Please wait while we pull up ${props.patient.PatientName}'s profile.`,
        'success'
      )
      const ssoUrl = await getRCopiaSSOUrl(props.patient.PatientRCopiaId)
      setRCopiaUrl(ssoUrl)
      setShowRCopia(true)
      setIsLoadingRCopia(false)
    }
  }

  async function handleHideRCopia() {
    try {
      setShowRCopia(false)
      notification(
        'Please wait a moment to see the medication changes made in the eRx module reflected in Osmind.',
        'success'
      )
      const data = {
        PatientRCopiaId: props.patient.PatientRCopiaId,
        PatientId: props.patient.PatientId,
        LastMedicationUpdateTimestamp:
          props.patient.LastMedicationUpdateTimestamp || '',
      }
      await syncRCopiaMeds(data)
      props.handleApiChange()
    } catch (error) {
      onError(
        error,
        500,
        'There was an internal error pulling updates from the eRx module into Osmind. Please inform your administrator.'
      )
    }
  }
  function createMedicalDisplays(medArray) {
    return (
      medArray &&
      medArray.map((med, index) => {
        return (
          <Container bsPrefix="medications-list" key={med.ActiveMedicationId}>
            <Row key={med.ActiveMedicationId + index}>
              <Col
                bsPrefix="medication"
                key={med.ActiveMedicationId + med.MedicationName}
              >
                {med.MedicationName}
              </Col>
            </Row>
            {med.Form && (
              <Row>
                <Col
                  bsPrefix="medication-sub"
                  key={med.ActiveMedicationId + med.Form}
                >
                  <span className="subtopic">Form: </span>
                  {med.Form}
                </Col>
              </Row>
            )}
            {med.Administration && (
              <Row>
                <Col
                  bsPrefix="medication-sub"
                  key={med.ActiveMedicationId + med.Administration}
                >
                  <span className="subtopic">Administration: </span>
                  {med.Administration}
                </Col>
              </Row>
            )}
            {med.Dosage && (
              <Row>
                <Col
                  bsPrefix="medication-sub"
                  key={med.ActiveMedicationId + med.Dosage}
                >
                  <span className="subtopic">Dosage: </span>
                  {med.Dosage}
                </Col>
              </Row>
            )}
            {(med.Dose || med.DoseUnit || med.DoseTiming) && (
              <Row>
                <Col
                  bsPrefix="medication-sub"
                  key={med.ActiveMedicationId + med.Dose}
                >
                  <span className="subtopic">Dose: </span>
                  {`${med.Dose} ${med.DoseUnit} ${med.DoseTiming}`}
                </Col>
              </Row>
            )}
            {med.StartDate && (
              <Row>
                <Col
                  bsPrefix="medication-sub"
                  key={med.ActiveMedicationId + med.StartDate}
                >
                  <span className="subtopic">Start Date: </span>
                  {med.StartDate}
                </Col>
              </Row>
            )}
            {med.StopDate && (
              <Row>
                <Col
                  bsPrefix="medication-sub"
                  key={med.ActiveMedicationId + med.StopDate}
                >
                  <span className="subtopic">Stop Date: </span>
                  {med.StopDate}
                </Col>
              </Row>
            )}
            {med.FillDate && (
              <Row>
                <Col
                  bsPrefix="medication-sub"
                  key={med.ActiveMedicationId + med.FillDate}
                >
                  <span className="subtopic">Fill Date: </span>
                  {med.FillDate}
                </Col>
              </Row>
            )}
          </Container>
        )
      })
    )
  }

  const medicationDisplay = createMedicalDisplays(props.activeMeds)

  return (
    <>
      {medicationDisplay}
      <br />
      {/* EDIT BUTTON */}
      <Row>
        <Col>
          <LoaderButton
            bsPrefix="button-block icon-button"
            onClick={async () => await handleEditMedications()}
            isLoading={isLoadingRCopia}
          >
            <i className="fa fa-pencil-square-o" aria-hidden="true" />
            &nbsp; Edit medications
          </LoaderButton>
          <br />
        </Col>
      </Row>
      <Modal
        size="xl"
        show={showRCopia}
        onHide={async () => await handleHideRCopia()}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <iframe
            src={RCopiaUrl}
            style={{ height: 975, width: '100%', marginTop: 25 }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
