import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const AnxietyCodes: any = [
  {
    '197480006': {
      title: 'Anxiety',
      description: 'Anxiety disorder (disorder)',
      snomedcode: 197480006,
      icdcode: 'F419',
      icddescription: 'Anxiety disorder, unspecified',
    },
  },
  {
    '21897009': {
      title: 'anx.Generalized Anxiety Disorder',
      description: 'Generalized anxiety disorder (disorder)',
      snomedcode: 21897009,
      icdcode: 'F411',
      icddescription: 'Generalized anxiety disorder',
    },
  },
  {
    '191736004': {
      title: 'anx.Obsessive-Compulsive Disorder (OCD)',
      description: 'Obsessive-compulsive disorder (disorder)',
      snomedcode: 191736004,
      icdcode: 'F422',
      icddescription: 'Mixed obsessional thoughts and acts',
    },
  },
  {
    '371631005': {
      title: 'anx.Panic Disorder',
      description: 'Panic disorder (disorder)',
      snomedcode: 371631005,
      icdcode: 'F410',
      icddescription: 'Panic disorder [episodic paroxysmal anxiety]',
    },
  },
  {
    '386810004': {
      title: 'anx.Phobias',
      description: 'Phobic disorder (disorder)',
      snomedcode: 386810004,
      icdcode: 'F409',
      icddescription: 'Phobic anxiety disorder, unspecified',
    },
  },
]

export const AnxietyDropDownValues = constructPatientDemographicObject(
  AnxietyCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.PYSCH_CONDITIONS
)
