import React, { useEffect, useMemo, useState } from 'react'

import { useStripe } from '@stripe/react-stripe-js'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Modal } from 'antd'
import { Formik } from 'formik'

import {
  applyPayment,
  createPayment,
  getPaymentLockStatus,
  setPaymentLockStatus,
  updatePaymentIntent,
} from '../../../api/api-lib'
import { QueryKeys as BillingQueryKeys } from '../../../hooks/useBillingInfo'
import { QueryKeys as InsuranceClaimsQueryKeys } from '../../../hooks/useInsuranceClaims'
import { QueryKeys as PatientProfileQueryKeys } from '../../../hooks/usePatientProfile'
import {
  calculateAvailableCredit,
  calculatedOutstandingBalanceBillableEntity,
  pollForPaymentStatus,
} from '../../../libs/billing'
import {
  MINIMUM_INPUT_NUMBER_VALUE,
  PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS,
  PAYMENT_FORM_OPTIONS,
  PAYMENT_METHOD_STATUSES,
  PAYMENT_METHOD_TYPES,
  PAYMENT_TYPE,
  STRIPE_ACCOUNT_STATUSES,
} from '../../../libs/constants'
import { notification } from '../../../libs/notificationLib'
import { handleStripeError } from '../../../libs/utils'
import {
  InsuranceClaim,
  Invoice,
  PaymentAttempt,
  PaymentMethod,
  PaymentStatus,
} from '../../../shared-types'
import { paymentDataLockedModal } from '../../../shared/Payments/PaymentLockModal'
import Spinner from '../../../stories/BaseComponents/Spinner'
import AddCreditCardModal from './AddCreditCardModal'
import { AddPaymentForm, PaymentFormValues } from './PaymentForm/AddPaymentForm'
import { AddPaymentTypeForm } from './PaymentForm/AddPaymentTypeForm'
import { validatePaymentForm } from './PaymentForm/validation'
import {
  getDefaultCreditUsageByPaymentMethod,
  usePaymentAttribution,
} from './payment-attribution'

interface AddPaymentModalProps {
  isLoading: boolean
  isVisible: boolean
  invoices: Invoice[]
  claims: InsuranceClaim[]
  claimFeatureFlag: boolean
  stripeCreditCards: PaymentMethod[]
  onCloseModal: () => void
  paymentMethods: PaymentMethod[]
  patientId: string
  payments: PaymentAttempt[]
  paymentIntentSecret: string | null
  paymentIntentId: string | null
  stripeAccountStatus: STRIPE_ACCOUNT_STATUSES
  preSelectedInvoiceUuid?: string | null
  preSelectedClaimUuid?: string | null
  resetPaymentIntent: () => void
  fromClaim: boolean
  fromGlobalView: boolean
}

export default function AddPaymentModal({
  isLoading,
  isVisible,
  invoices,
  claims,
  claimFeatureFlag,
  stripeCreditCards,
  onCloseModal,
  paymentMethods,
  patientId,
  payments,
  paymentIntentId,
  paymentIntentSecret,
  stripeAccountStatus,
  preSelectedInvoiceUuid,
  preSelectedClaimUuid,
  resetPaymentIntent,
  fromClaim,
  fromGlobalView,
}: AddPaymentModalProps) {
  const stripePaymentsEnabled = useMemo(
    () =>
      !!paymentIntentSecret &&
      stripeAccountStatus === STRIPE_ACCOUNT_STATUSES.SETUP_COMPLETE,
    [paymentIntentSecret, stripeAccountStatus]
  )

  const queryClient = useQueryClient()
  const handleNewCreditCardCreated = () => {
    queryClient.invalidateQueries([BillingQueryKeys.PAYMENT_METHODS])
  }

  const refreshPaymentData = () => {
    queryClient.invalidateQueries([BillingQueryKeys.INVOICES])
    queryClient.invalidateQueries([
      InsuranceClaimsQueryKeys.INSURANCE_CLAIMS,
      patientId,
    ])
    queryClient.invalidateQueries([BillingQueryKeys.INVOICE])
    queryClient.invalidateQueries([BillingQueryKeys.TRANSACTIONS])
    queryClient.invalidateQueries([BillingQueryKeys.PAYMENT_METHODS])
    queryClient.invalidateQueries([
      PatientProfileQueryKeys.PATIENT_INSURANCE_PAYMENTS,
    ])
  }

  const stripe = useStripe()

  const [isAddCreditCardModalOpen, setIsAddCreditCardModalOpen] =
    useState(false)

  /**
   * Determines the payment type based on the provided conditions.
   *
   * @param {boolean} hasInvoiceOrClaim - Indicates if the user has an invoice or claim.
   * @returns {string|null} Returns 'INVOICE' if the claim feature flag is off, the user has no invoices or claims, or none of the other conditions are met. Returns 'CLAIM' if the function is called from a claim. Returns null if the function is called from a global view.
   */
  const getPaymentType = (hasInvoiceOrClaim: boolean) => {
    // Type is always invoice if claim feature flag is off or user has no invoices or claims
    if (!claimFeatureFlag || !hasInvoiceOrClaim) {
      return PAYMENT_TYPE.INVOICE
    } else if (fromGlobalView) {
      return null
    } else if (fromClaim) {
      return PAYMENT_TYPE.CLAIM
    } else {
      return PAYMENT_TYPE.INVOICE
    }
  }

  function getDefaultSelectedPaymentOption(
    invoices: Invoice[],
    claims: InsuranceClaim[],
    preSelectedClaimUuid?: string | null,
    preSelectedInvoiceUuid?: string | null
  ): PAYMENT_FORM_OPTIONS {
    if (preSelectedClaimUuid || preSelectedInvoiceUuid) {
      return PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL
    } else if (
      (invoices.length > 0 && getPaymentType(true) === PAYMENT_TYPE.INVOICE) ||
      (claims.length > 0 && getPaymentType(true) === PAYMENT_TYPE.CLAIM)
    ) {
      return PAYMENT_FORM_OPTIONS.PAY_OUTSTANDING_BALANCE
    } else {
      return PAYMENT_FORM_OPTIONS.ADD_PATIENT_CREDIT
    }
  }

  const defaultSelectedPaymentOption = useMemo(() => {
    return getDefaultSelectedPaymentOption(
      invoices,
      claims,
      preSelectedClaimUuid,
      preSelectedInvoiceUuid
    )
  }, [invoices, claims, preSelectedClaimUuid, preSelectedInvoiceUuid])

  const defaultPaymentMethod = useMemo(() => {
    if (
      stripeAccountStatus === STRIPE_ACCOUNT_STATUSES.SETUP_COMPLETE &&
      paymentIntentSecret
    ) {
      return (
        stripeCreditCards.find((card) => card.isDefault) ||
        stripeCreditCards[0] ||
        null
      )
    } else {
      const cashPaymentMethod = paymentMethods.find(
        (paymentMethod) => paymentMethod.type === PAYMENT_METHOD_TYPES.CASH
      )

      return cashPaymentMethod ?? null
    }
  }, [
    stripeAccountStatus,
    paymentIntentSecret,
    stripeCreditCards,
    paymentMethods,
  ])

  useEffect(() => {
    stripeCreditCards.sort((a, b) => Number(b.isDefault) - Number(a.isDefault))
  }, [stripeCreditCards])

  const defaultPaymentMethodType = stripePaymentsEnabled
    ? PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD
    : PAYMENT_METHOD_TYPES.CASH

  const { mutateAsync: applyPaymentMutate } = useMutation(applyPayment, {
    onSuccess: () => {
      notification('Payment successful.', 'success')
    },
    onError: (err) => {
      console.error('Error applying payment', err)
      notification(
        'Payment created successfully, but unable to apply to selected invoice(s).'
      )
    },
    onSettled: () => {
      onCloseModal()
      refreshPaymentData()
    },
  })

  const { buildBillableEntityApplicationData: buildInvoiceApplicationData } =
    usePaymentAttribution({
      billableEntities: invoices,
    })

  const { buildBillableEntityApplicationData: buildClaimApplicationData } =
    usePaymentAttribution({
      billableEntities: claims,
    })

  const handlePaymentApplication = async (
    {
      paymentOption,
      selectedInvoice,
      selectedClaim,
      customAmount,
      paymentType,
      creditUsage,
    }: PaymentFormValues,
    paymentAttemptUuid?: string
  ) => {
    if (paymentOption === PAYMENT_FORM_OPTIONS.ADD_PATIENT_CREDIT) {
      notification('Credit successfully added!')
      onCloseModal()
      refreshPaymentData()
      return
    }

    if (
      paymentType === PAYMENT_TYPE.INVOICE &&
      paymentOption === PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL &&
      !selectedInvoice
    ) {
      notification(
        'Charge was successful, but unable to apply payment. No invoice is selected.'
      )
      refreshPaymentData()
      onCloseModal()
      return
    }

    if (
      paymentType === PAYMENT_TYPE.CLAIM &&
      paymentOption === PAYMENT_FORM_OPTIONS.PAY_INDIVIDUAL &&
      !selectedClaim
    ) {
      notification(
        'Charge was successful, but unable to apply payment. No claim is selected.'
      )
      refreshPaymentData()
      onCloseModal()
      return
    }

    let billableEntityApplication
    if (paymentType === PAYMENT_TYPE.CLAIM) {
      billableEntityApplication = buildClaimApplicationData({
        selectedBillableEntity: selectedClaim,
        customAmount,
        paymentOption,
        paymentType: PAYMENT_TYPE.CLAIM,
      })
    } else {
      billableEntityApplication = buildInvoiceApplicationData({
        selectedBillableEntity: selectedInvoice,
        customAmount,
        paymentOption,
        paymentType: PAYMENT_TYPE.INVOICE,
      })
    }

    const creditApplications = [] as unknown[]

    Object.keys(creditUsage).forEach((paymentMethodUuid: string) => {
      const { creditUtilized } = creditUsage[paymentMethodUuid]
      console.log(`Credit for ${paymentMethodUuid}:`, creditUsage)
      if (creditUtilized > 0) {
        creditApplications.push({
          paymentMethodUuid,
          amountCents: creditUtilized,
        })
      }
    })

    await applyPaymentMutate({
      [`${paymentType}Applications`]: billableEntityApplication,
      paymentAttemptUuid,
      creditApplications,
      patientId,
    })
  }

  const handleAsStripeCharge = async (values: PaymentFormValues) => {
    const finalCharge =
      values.totalChargeAmountCents - values.totalAppliedCredit
    if (finalCharge === 0) {
      await handlePaymentApplication(values)
      return
    }

    // this shouldn't happen -- we disable the submit button if the PAYMENT_METHOD_TYPE is Stripe and no Stripe card is selected
    // but typescript doesn't know that
    if (!values.stripeCreditCard) {
      notification('No credit card selected.')
      return
    }

    if (!paymentIntentSecret) {
      notification(
        'Necessary Stripe information is not loaded. Cannot proceed.'
      )
      return
    }

    const paymentData = {
      patientId: patientId,
      status: PAYMENT_METHOD_STATUSES.PENDING,
      memo: values.memo,
      totalAmountCents: finalCharge,
      paymentMethodUuid: values.paymentMethod?.uuid,
      externalServiceId: paymentIntentId,
    }

    // create a PENDING paymentAttempt on our BE
    let paymentAttemptResponse: any
    try {
      paymentAttemptResponse = await createPayment(paymentData)
    } catch (err) {
      const errorMessage = handleStripeError(
        err,
        'An unknown error occurred. No charge was made. Please try again.'
      )
      notification(errorMessage)
      return
    }

    const { paymentAttemptUuid } = paymentAttemptResponse

    try {
      await updatePaymentIntent(paymentIntentId, {
        amountCents: finalCharge,
        stripePaymentMethodId: values.stripeCreditCard?.externalServiceId,
        patientId,
      })
    } catch (err) {
      const errorMessage = handleStripeError(
        err,
        'An unknown error occurred. No charge was made. Please try again.'
      )
      notification(errorMessage)
      resetPaymentIntent()
      return
    }

    try {
      await stripe?.confirmCardPayment(paymentIntentSecret, {
        setup_future_usage: 'off_session',
      })

      const resolvedStatus = await pollForPaymentStatus(paymentAttemptUuid, 0)

      // we hit this only if polling 10 times doesn't get us a finalized paymentAttempt
      if (resolvedStatus === PaymentStatus.FAILED) {
        notification(
          'Payment failed. Charge was not made. Please check details and try again.'
        )
      } else if (resolvedStatus === PaymentStatus.SUCCESSFUL) {
        await handlePaymentApplication(values, paymentAttemptUuid)
      } else {
        notification('Something went wrong. Please contact Osmind support.')
      }
    } catch (err) {
      const errorMessage = handleStripeError(err, 'An unknown error occurred.')
      notification(errorMessage)
    } finally {
      resetPaymentIntent()
    }
  }

  const handleAsNonStripeCharge = async (values: PaymentFormValues) => {
    const chargeAmountCents =
      values.totalChargeAmountCents - values.totalAppliedCredit
    if (chargeAmountCents === 0) {
      await handlePaymentApplication(values)
      return
    }

    // for payments not processed externally, we just create the right stuff on our BE
    const paymentData = {
      patientId: patientId,
      status: PAYMENT_METHOD_STATUSES.SUCCESSFUL,
      memo: values.memo,
      totalAmountCents: chargeAmountCents,
      paymentMethodUuid: values.paymentMethod?.uuid,
    }

    try {
      const paymentResponse = await createPayment(paymentData)
      console.log('payment response', paymentResponse)
      await handlePaymentApplication(values, paymentResponse.paymentAttemptUuid)
    } catch (err) {
      console.error('Error creating payment', err)
      const errorMessage = handleStripeError(
        err,
        'There was an error creating the payment. You can retry again safely.'
      )
      notification(errorMessage)
    }
  }

  const handlePaymentSubmit = async (values: PaymentFormValues) => {
    const lockStatusResponse = await getPaymentLockStatus(patientId)
    if (lockStatusResponse.isLocked) {
      paymentDataLockedModal()
      return
    }

    await setPaymentLockStatus(
      patientId,
      true,
      'AddPaymentModal handlePaymentSubmit start'
    )

    if (values.paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD) {
      await handleAsStripeCharge(values)
    } else {
      await handleAsNonStripeCharge(values)
    }

    await setPaymentLockStatus(
      patientId,
      false,
      'AddPaymentModal handlePaymentSubmit complete'
    )
  }

  const hasInvoiceOrClaim = claims.length + invoices.length > 0

  const totalPatientCreditAmountCents = calculateAvailableCredit(payments)
  const initialValues = useMemo(
    () => ({
      paymentOption: defaultSelectedPaymentOption,
      paymentMethodType: defaultPaymentMethodType,
      paymentMethod: defaultPaymentMethod,
      invoiceUuid: preSelectedInvoiceUuid ?? null,
      claimUuid: preSelectedClaimUuid ?? null,
      paymentType: getPaymentType(hasInvoiceOrClaim),
      billableEntitySubOption:
        PAYMENT_FORM_BILLABLE_ENTITY_SUBOPTIONS.OUTSTANDING_BALANCE,
      selectedInvoice: invoices.length > 0 ? invoices[0] : undefined,
      selectedClaim: claims.length > 0 ? claims[0] : undefined,
      customAmount: invoices.length > 0 ? invoices[0].amountCentsDue : 0,
      patientCreditAmount: MINIMUM_INPUT_NUMBER_VALUE,
      memo: '',
      creditUsage: getDefaultCreditUsageByPaymentMethod(
        paymentMethods,
        payments
      ),
      isApplyingCredit: totalPatientCreditAmountCents > 0,
      stripeCreditCard:
        defaultPaymentMethod?.type === PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD
          ? defaultPaymentMethod
          : null,
      totalAppliedCredit: 0,
      totalChargeAmountCents: calculatedOutstandingBalanceBillableEntity(
        fromClaim ? claims : invoices
      ),
    }),
    [
      defaultSelectedPaymentOption,
      defaultPaymentMethodType,
      defaultPaymentMethod,
      preSelectedInvoiceUuid,
      preSelectedClaimUuid,
      invoices,
      paymentMethods,
      payments,
    ]
  )

  return (
    <Formik
      initialValues={initialValues}
      validate={validatePaymentForm}
      onSubmit={async (values, { resetForm }) => {
        await handlePaymentSubmit(values)
        const newFormState = { values: initialValues }
        resetForm(newFormState)
      }}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        submitForm,
        isSubmitting,
        handleReset,
        ...formik
      }) => {
        const showStripeDropdown =
          values.paymentMethodType ===
            PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD && paymentIntentSecret
        return (
          <Modal
            destroyOnClose
            visible={isVisible}
            title={<div style={{ fontSize: '16px' }}>New payment</div>}
            onCancel={() => {
              onCloseModal()
              handleReset()
            }}
            okText={'Submit'}
            onOk={submitForm}
            okButtonProps={{
              disabled:
                isLoading ||
                isSubmitting ||
                !values.paymentType ||
                Object.keys(errors).length > 0,
              loading: isSubmitting,
            }}
            cancelButtonProps={{ disabled: isSubmitting }}
            closable={!isSubmitting}
            maskClosable={!isSubmitting}
            bodyStyle={{ padding: '16px 24px' }}
          >
            {stripePaymentsEnabled && showStripeDropdown && (
              <AddCreditCardModal
                isVisible={stripePaymentsEnabled && isAddCreditCardModalOpen}
                onCloseModal={() => setIsAddCreditCardModalOpen(false)}
                handleNewCreditCardCreated={handleNewCreditCardCreated}
                patientId={patientId}
                oldCreditCardValues={null}
                creditCards={stripeCreditCards}
              />
            )}
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {claimFeatureFlag && hasInvoiceOrClaim && (
                  <AddPaymentTypeForm
                    claims={claims}
                    invoices={invoices}
                    values={values}
                    handleChange={handleChange}
                    fromClaim={fromClaim}
                    fromGlobalView={fromGlobalView}
                    {...formik}
                  />
                )}
                {values.paymentType && (
                  <AddPaymentForm
                    invoices={invoices}
                    claims={claims}
                    stripeCreditCards={stripeCreditCards}
                    preSelectedInvoiceUuid={preSelectedInvoiceUuid}
                    preSelectedClaimUuid={preSelectedClaimUuid}
                    payments={payments}
                    stripeAccountStatus={stripeAccountStatus}
                    paymentIntentSecret={paymentIntentSecret}
                    stripePaymentsEnabled={stripePaymentsEnabled}
                    isLoading={isLoading}
                    paymentMethods={paymentMethods}
                    showAddCreditCardModal={() =>
                      setIsAddCreditCardModalOpen(true)
                    }
                    totalPatientCreditAmountCents={
                      totalPatientCreditAmountCents
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    {...formik}
                  />
                )}
              </>
            )}
          </Modal>
        )
      }}
    </Formik>
  )
}
