import { API } from 'aws-amplify'

import { PatientTag, Tag } from '../stories/Patients/_types'

type getTagsRequest = () => Promise<Tag[]>
type getPatientTagsRequest = () => Promise<PatientTag[]>
type createTagRequest = (createTagObj: { label: string }) => Promise<Tag>
type CreatePatientTag = (createPatientTagObj: {
  patientId: string
  tagIds: number[]
}) => Promise<PatientTag[]>
type DeletePatientTag = (deletePatientTagObj: {
  patientId: string
  tagId: number
}) => Promise<PatientTag>
type BulkUpdatePatientTag = (bulkUpdatePatientTagObj: {
  patientId: string
  tagIdsToAdd: number[]
  tagIdsToRemove: number[]
}) => Promise<PatientTag[]>

/* Tags - [get many, create one]*/
export const createTag: createTagRequest = async ({ label }) => {
  return API.post('core-api', '/tags', { body: { label } })
}

export const getTags: getTagsRequest = async () => {
  return API.get('core-api', '/tags', {})
}

/**** Patient Tags [get many, create many, remove one, bulk update] ****/
export const createPatientTags: CreatePatientTag = async ({
  // not referenced anywhere?
  patientId,
  tagIds,
}) => {
  return API.post('core-api', '/patient-tags', { body: { patientId, tagIds } })
}

export const getPatientTags: getPatientTagsRequest = async () => {
  // not referenced anywhere?
  return API.get('core-api', '/patient-tags', {})
}

export const deletePatientTag: DeletePatientTag = async ({
  // still referenced!
  patientId,
  tagId,
}) => {
  return API.del(
    'core-api',
    `/patient-tags?patientId=${patientId}&tagId=${tagId}`,
    {}
  )
}

export const bulkUpdatePatientTag: BulkUpdatePatientTag = async ({
  // still referenced!
  patientId,
  tagIdsToAdd,
  tagIdsToRemove,
}) => {
  return API.post('core-api', '/patient-tags/bulk', {
    body: { patientId, tagIdsToAdd, tagIdsToRemove },
  })
}
