export enum patientDataTypes {
  ProviderSidePatientData = 'PROVIDER_SIDE_PATIENT_DATA',
  PatientImageUrlData = 'IMAGE_URL_DATA',
  PatientData = 'PATIENT_DATA',
  PatientMessages = 'PATIENT_MESSAGES',
  PatientClinicalNotes = 'PATIENT_CLINICAL_NOTES',
  PatientDocuments = 'PATIENT_DOCUMENTS',
  PatientMedications = 'PATIENT_MEDICATIONS',
  PatientJournalNotes = 'PATIENT_JOURNAL_NOTES',
  PatientSettings = 'PATIENT_SETTINGS',
  PatientDiagnoses = 'PATIENT_DIAGNOSES',
  PatientAllergies = 'PATIENT_ALLERGIES',
}
