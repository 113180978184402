import React from 'react'

import { Button, Col, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Anxiety(props) {
  const { patientId = '', providerId = '' } = props

  if (props.patientData.NoPTSDTrauma) {
    return (
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            PTSD and trauma:
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="NoPTSDTrauma"
            label="No relevant history in this section"
            checked={JSON.parse(props.patientData.NoPTSDTrauma)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NoPTSDTrauma: !props.patientData.NoPTSDTrauma,
              })
            }}
          />
        </Form.Group>
      </Form.Row>
    )
  }
  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            PTSD and trauma:
          </Form.Label>
        </Form.Group>
        {props.patientData.PTSD.state === false &&
        props.patientData.TraumaAbuseHistory.state === false &&
        props.patientData.OtherPTSD.state === false &&
        props.patientData.PTSDTraumaAdditionalNotes === '' ? (
          <Form.Group as={Col} sm={12} md={12} lg={12}>
            <Form.Check
              type="checkbox"
              id="NoPTSDTrauma"
              label="No relevant history in this section"
              checked={JSON.parse(props.patientData.NoPTSDTrauma)}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoPTSDTrauma: !props.patientData.NoPTSDTrauma,
                })
              }}
            />
          </Form.Group>
        ) : (
          ''
        )}
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="TraumaAbuseHistory"
            label="History of trauma or abuse"
            disabled={props.patientData.NoPTSDTrauma}
            checked={JSON.parse(props.patientData.TraumaAbuseHistory.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                TraumaAbuseHistory: {
                  ...props.patientData.TraumaAbuseHistory,
                  state: !props.patientData.TraumaAbuseHistory.state,
                },
              })
            }}
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Diagnoses
          </Form.Label>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.PTSD.state ? 2 : 12}
          lg={props.patientData.PTSD.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="PTSD"
            label="PTSD"
            disabled={props.patientData.NoPTSDTrauma}
            checked={JSON.parse(props.patientData.PTSD.state)}
            onChange={() => {
              props.setPatientData({
                ...props.patientData,
                PTSD: {
                  ...props.patientData.PTSD,
                  state: !props.patientData.PTSD.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.PTSD.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    PTSD: { ...props.patientData.PTSD, Notes: e.target.value },
                  })
                }}
                value={props.patientData.PTSD.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.OtherPTSD.state ? 2 : 12}
          lg={props.patientData.OtherPTSD.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="OtherPTSD"
            label="Other"
            disabled={props.patientData.NoPTSDTrauma}
            checked={JSON.parse(props.patientData.OtherPTSD.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                OtherPTSD: {
                  ...props.patientData.OtherPTSD,
                  state: !props.patientData.OtherPTSD.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OtherPTSD.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    OtherPTSD: {
                      ...props.patientData.OtherPTSD,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.OtherPTSD.Notes}
              />
            </Form.Group>
          </>
        )}
        {props.patientData.TraumaAbuseHistory.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    TraumaAbuseHistory: {
                      ...props.patientData.TraumaAbuseHistory,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.TraumaAbuseHistory.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          style={{ textAlign: 'center', marginLeft: 'auto' }}
        >
          <Link
            to={{
              pathname: `/patient/progress?patientId=${patientId}&providerId=${providerId}`,
            }}
            target="_blank"
            style={{ marginLeft: 'auto', marginRight: '7px' }}
          >
            <Button variant="primary">
              See PTSD/trauma questionnaire scores
            </Button>
          </Link>
          <Link
            to={{
              pathname: `/patient/settings?patientId=${patientId}&providerId=${providerId}`,
            }}
            target="_blank"
            style={{ marginLeft: '7px', marginRight: 'auto' }}
          >
            <Button variant="primary">Administer questionnaires</Button>
          </Link>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional notes
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            bsPrefix="form-input"
            disabled={props.patientData.NoPTSDTrauma}
            onChange={(e) => {
              props.setPatientData({
                ...props.patientData,
                PTSDTraumaAdditionalNotes: e.target.value,
              })
            }}
            value={props.patientData.PTSDTraumaAdditionalNotes}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
