import { CodeSystemOIDs } from './CodeSystems'
import {
  Categories,
  PatientDemographicObject,
  constructPatientDemographicObject,
} from './types'

export enum EmergencyContactRelationshipEnum {
  mother = 'mother',
  father = 'father',
  brother = 'brother',
  sister = 'sister',
  husband = 'husband',
  wife = 'wife',
  boyfriend = 'boyfriend',
  girlfriend = 'girlfriend',
  partner = 'partner',
  son = 'son',
  daughter = 'daughter',
  friend = 'friend',
  other = 'other',
}

const emergencyContactRelationship: PatientDemographicObject[] = [
  { [EmergencyContactRelationshipEnum.mother]: { title: 'Mother' } },
  { [EmergencyContactRelationshipEnum.father]: { title: 'Father' } },
  { [EmergencyContactRelationshipEnum.brother]: { title: 'Brother' } },
  { [EmergencyContactRelationshipEnum.sister]: { title: 'Sister' } },
  { [EmergencyContactRelationshipEnum.husband]: { title: 'Husband' } },
  { [EmergencyContactRelationshipEnum.wife]: { title: 'Wife' } },
  { [EmergencyContactRelationshipEnum.boyfriend]: { title: 'Boyfriend' } },
  { [EmergencyContactRelationshipEnum.girlfriend]: { title: 'Girlfriend' } },
  { [EmergencyContactRelationshipEnum.partner]: { title: 'Partner' } },
  { [EmergencyContactRelationshipEnum.son]: { title: 'Son' } },
  { [EmergencyContactRelationshipEnum.daughter]: { title: 'Daughter' } },
  { [EmergencyContactRelationshipEnum.friend]: { title: 'Friend' } },
  { [EmergencyContactRelationshipEnum.other]: { title: 'Other' } },
]

export const emergencyContactRelationshipDropDownValues =
  constructPatientDemographicObject(
    emergencyContactRelationship,
    CodeSystemOIDs.OSMINDCustomCodeSystem,
    Categories.EMERGENCY_CONTACT_RELATIONSHIP,
    true
  )

export enum InsuranceHolderRelationshipEnum {
  Child = 'child,',
  Spouse = 'spouse,',
  Parent = 'parent,',
  Grandparent = 'grandparent,',
  Sibling = 'sibling,',
  Partner = 'partner,',
  Other = 'other,',
}

const insuranceHolderRelationships: PatientDemographicObject[] = [
  { [InsuranceHolderRelationshipEnum.Child]: { title: 'Child' } },
  { [InsuranceHolderRelationshipEnum.Spouse]: { title: 'Spouse' } },
  { [InsuranceHolderRelationshipEnum.Parent]: { title: 'Parent' } },
  {
    [InsuranceHolderRelationshipEnum.Grandparent]: {
      title: 'Grandparent',
    },
  },
  { [InsuranceHolderRelationshipEnum.Sibling]: { title: 'Sibling' } },
  { [InsuranceHolderRelationshipEnum.Partner]: { title: 'Partner' } },
  { [InsuranceHolderRelationshipEnum.Other]: { title: 'Other' } },
]

export const insuranceHolderRelationshipDropDownValues =
  constructPatientDemographicObject(
    insuranceHolderRelationships,
    CodeSystemOIDs.OSMINDCustomCodeSystem,
    Categories.INSURANCE_HOLDER_RELATIONSHIP,
    true // sorts options in alphabetical order
  )
