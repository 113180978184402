import Icon, { WarningFilled } from '@ant-design/icons'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { ReactComponent as ExternalLink } from '../../../images/Icons/ExternalLink.svg'
import { AcceptedAppointmentParams } from '../../../libs/freshpaint/selfSchedulingEvents'
import {
  formattedPhoneNumberWithoutCountryCode,
  getProviderNames,
} from '../../../libs/utils'
import { Appointment } from '../../../shared-types'
import {
  getPatientUrl,
  transformDurationToHoursAndMinutes,
} from '../../../shared/Helpers/utils'
import { Button, Link, Tooltip } from '../../../stories/BaseComponents'

import styles from './AppointmentRequestCard.module.scss'

export type AppointmentCardRequestProps = {
  providerId: string
  appointment: Appointment<false>
  onAccept(
    appointmentId: string,
    trackingData: Omit<AcceptedAppointmentParams, 'eventName' | 'clinicId'>
  ): void
  openDeclineModal(
    appointment: Pick<Appointment<false>, 'id' | 'appointmentType' | 'patient'>
  ): void
  isAccepting: boolean
  onNavigationChange(dateSelected: Date): void
  currentTimezone: string
}

export function AppointmentRequestCard({
  onNavigationChange,
  openDeclineModal,
  providerId = '',
  appointment: {
    id,
    patient: {
      firstName,
      lastName,
      PatientEmail,
      PhoneNumber,
      SuicidalThoughts,
      PatientId,
      CareTeam,
    },
    appointmentType: {
      name: appointmentTypeName,
      room,
      durationInMinutes,
      id: apptTypeId,
      eventProviders,
    },
    requestedStartTime,
    providers,
  },
  onAccept,
  isAccepting,
  currentTimezone,
}: AppointmentCardRequestProps) {
  const handleAccept = () => {
    onAccept(id, {
      apptTypeId,
      apptTypeName: appointmentTypeName,
      patientId: PatientId,
      providerId,
      requestedApptTime: requestedStartTime,
    })
  }
  return (
    <div className={styles.apptRequestCard} data-testid={`request-card-${id}`}>
      <header>
        <Link
          to={getPatientUrl(PatientId, providerId)}
          className={styles.apptRequestPatientName}
        >
          {firstName} {lastName}{' '}
          <Icon color={'#1890FF'} component={ExternalLink} />
        </Link>
        <Tooltip
          placement="left"
          title={
            'Patient reported thoughts of suicide or self-harm on their last PHQ-9, BDI-II, C-SSR, and/or DSM-XC survey(s).'
          }
        >
          {SuicidalThoughts && <WarningFilled className={styles.alertColor} />}
        </Tooltip>
      </header>
      <span className={styles.apptRequestApptType}>{appointmentTypeName}</span>
      <div className={styles.apptRequestPatientInfo}>
        <span>
          {PatientEmail}
          {PhoneNumber
            ? ` • ${formattedPhoneNumberWithoutCountryCode(PhoneNumber)}`
            : ''}
        </span>
      </div>
      <span className={styles.apptRequestTime}>
        {format(
          utcToZonedTime(new Date(requestedStartTime), currentTimezone),
          'EEE, MMM d, yyyy - hh:mm aa'
        )}{' '}
        {`(${transformDurationToHoursAndMinutes(durationInMinutes)})`}
      </span>
      {/* TODO: (you) is missing, but I need providers ids to do it */}
      <span>{getProviderNames(providers)}</span>
      {room && (
        <span>
          {room.location.name} • {room.roomName}
        </span>
      )}
      <div className={styles.apptRequestFooter}>
        <Button
          className={styles.apptRequestJumpToButton}
          type="link"
          onClick={() => {
            onNavigationChange(new Date(requestedStartTime))
          }}
          disabled={isAccepting}
        >
          Jump to
        </Button>
        <div className={styles.apptRequestButtons}>
          <Button
            onClick={() => {
              openDeclineModal({
                id,
                appointmentType: {
                  durationInMinutes,
                  eventProviders,
                  id: apptTypeId,
                  name: appointmentTypeName,
                  room,
                },
                patient: {
                  CareTeam,
                  PatientEmail,
                  firstName,
                  lastName,
                  PatientId,
                  PhoneNumber,
                  SuicidalThoughts,
                },
              })
            }}
            disabled={isAccepting}
          >
            Decline
          </Button>
          <Button type="primary" onClick={handleAccept} loading={isAccepting}>
            Accept
          </Button>
        </div>
      </div>
    </div>
  )
}
