import React from 'react'

import {
  Badge,
  Button,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Depressive(props) {
  const { patientId = '', providerId = '' } = props

  function DepressionAnxiousDistressLabel() {
    return (
      <>
        <span>Depression with anxious distress</span>
        <Badge className="danger">
          <span>
            {' '}
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip bsPrefix="tooltip-x">
                  <Form.Label className="indentsubtext">
                    To meet the criteria for the “with anxious distress”
                    specifier, patients must present with two of the following
                    symptoms:
                  </Form.Label>
                  <Form.Text className="indentsubtext">
                    (a) feeling keyed up or tense
                  </Form.Text>
                  <Form.Text className="indentsubtext">
                    (b) feeling unusually restless
                  </Form.Text>
                  <Form.Text className="indentsubtext">
                    (c) difficulty concentrating because of worry
                  </Form.Text>
                  <Form.Text className="indentsubtext">
                    (d) fear that something awful may happen
                  </Form.Text>
                  <Form.Text className="indentsubtext">
                    (e) feeling of potential loss of control
                  </Form.Text>
                </Tooltip>
              }
            >
              <i className="fa fa-exclamation-circle" />
            </OverlayTrigger>
          </span>
        </Badge>
      </>
    )
  }

  if (props.patientData.NoRelevantDepressive) {
    return (
      <Form.Row className="border-bottom indent">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Depressive:
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Check
            type="checkbox"
            id="NoRelevantDepressive"
            label="No relevant history in this section"
            checked={!!props.patientData.NoRelevantDepressive}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                NoRelevantDepressive: !props.patientData.NoRelevantDepressive,
              })
            }}
          />
        </Form.Group>
      </Form.Row>
    )
  }

  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Depressive:
          </Form.Label>
        </Form.Group>
        {props.patientData.MajorDepressiveDisorder.state === false &&
        props.patientData.TreatmentResistantDepression.state === false &&
        props.patientData.DepressionAnxiousDistress.state === false &&
        props.patientData.OtherDepressive.state === false &&
        props.patientData.DepressiveAdditionalNotes === '' ? (
          <Form.Group as={Col} sm={12} md={12} lg={12}>
            <Form.Check
              type="checkbox"
              id="NoRelevantDepressive"
              label="No relevant history in this section"
              checked={!!props.patientData.NoRelevantDepressive}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoRelevantDepressive: !props.patientData.NoRelevantDepressive,
                })
              }}
            />
          </Form.Group>
        ) : (
          ''
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.MajorDepressiveDisorder.state ? 2 : 12}
          lg={props.patientData.MajorDepressiveDisorder.state ? 2 : 12}
        >
          <Form.Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Diagnosis
          </Form.Label>
          <Form.Check
            type="checkbox"
            id="MajorDepressiveDisorder"
            label="Major depressive disorder"
            disabled={props.patientData.NoRelevantDepressive}
            checked={JSON.parse(
              props.patientData.MajorDepressiveDisorder.state
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                MajorDepressiveDisorder: {
                  ...props.patientData.MajorDepressiveDisorder,
                  state: !props.patientData.MajorDepressiveDisorder.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.MajorDepressiveDisorder.state && (
          <>
            <Form.Group as={Col} sm={12} md={2} lg={2}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Number of episodes
              </Form.Label>
              <Form.Control
                className="text-area"
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    MajorDepressiveDisorder: {
                      ...props.patientData.MajorDepressiveDisorder,
                      NumberEpisodes: e.target.value,
                    },
                  })
                }}
                type="number"
                value={props.patientData.MajorDepressiveDisorder.NumberEpisodes}
              />
            </Form.Group>
            <Form.Group as={Col} sm={12} md={8} lg={8}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    MajorDepressiveDisorder: {
                      ...props.patientData.MajorDepressiveDisorder,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.MajorDepressiveDisorder.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.TreatmentResistantDepression.state ? 2 : 12}
          lg={props.patientData.TreatmentResistantDepression.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="Treatment-resistantDepression"
            label="Treatment-resistant depression"
            disabled={props.patientData.NoRelevantDepressive}
            checked={JSON.parse(
              props.patientData.TreatmentResistantDepression.state
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                TreatmentResistantDepression: {
                  ...props.patientData.TreatmentResistantDepression,
                  state: !props.patientData.TreatmentResistantDepression.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.TreatmentResistantDepression.state && (
          <>
            <Form.Group as={Col} sm={12} md={2} lg={2}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Number of episodes
              </Form.Label>
              <Form.Control
                className="text-area"
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    TreatmentResistantDepression: {
                      ...props.patientData.TreatmentResistantDepression,
                      NumberEpisodes: e.target.value,
                    },
                  })
                }}
                type="number"
                value={
                  props.patientData.TreatmentResistantDepression.NumberEpisodes
                }
              />
            </Form.Group>
            <Form.Group as={Col} sm={12} md={8} lg={8}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    TreatmentResistantDepression: {
                      ...props.patientData.TreatmentResistantDepression,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.TreatmentResistantDepression.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.DepressionAnxiousDistress.state ? 2 : 12}
          lg={props.patientData.DepressionAnxiousDistress.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="DepressionAnxiousDistress"
            label={DepressionAnxiousDistressLabel()}
            disabled={props.patientData.NoRelevantDepressive}
            checked={JSON.parse(
              props.patientData.DepressionAnxiousDistress.state
            )}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                DepressionAnxiousDistress: {
                  ...props.patientData.DepressionAnxiousDistress,
                  state: !props.patientData.DepressionAnxiousDistress.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.DepressionAnxiousDistress.state && (
          <>
            <Form.Group as={Col} sm={12} md={2} lg={2}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Number of episodes
              </Form.Label>
              <Form.Control
                className="text-area"
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    DepressionAnxiousDistress: {
                      ...props.patientData.DepressionAnxiousDistress,
                      NumberEpisodes: e.target.value,
                    },
                  })
                }}
                type="number"
                value={
                  props.patientData.DepressionAnxiousDistress.NumberEpisodes
                }
              />
            </Form.Group>
            <Form.Group as={Col} sm={12} md={8} lg={8}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    DepressionAnxiousDistress: {
                      ...props.patientData.DepressionAnxiousDistress,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.DepressionAnxiousDistress.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={props.patientData.OtherDepressive.state ? 2 : 12}
          lg={props.patientData.OtherDepressive.state ? 2 : 12}
        >
          <Form.Check
            type="checkbox"
            id="OtherDepressive"
            label="Other"
            disabled={props.patientData.NoRelevantDepressive}
            checked={JSON.parse(props.patientData.OtherDepressive.state)}
            onClick={() => {
              props.setPatientData({
                ...props.patientData,
                OtherDepressive: {
                  ...props.patientData.OtherDepressive,
                  state: !props.patientData.OtherDepressive.state,
                },
              })
            }}
          />
        </Form.Group>
        {props.patientData.OtherDepressive.state && (
          <>
            <Form.Group as={Col} sm={12} md={10} lg={10}>
              <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                bsPrefix="form-input"
                onChange={(e) => {
                  props.setPatientData({
                    ...props.patientData,
                    OtherDepressive: {
                      ...props.patientData.OtherDepressive,
                      Notes: e.target.value,
                    },
                  })
                }}
                value={props.patientData.OtherDepressive.Notes}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          as={Col}
          sm={12}
          md={12}
          lg={12}
          style={{ textAlign: 'center', marginLeft: 'auto' }}
        >
          <Link
            to={{
              pathname: `/patient/progress?patientId=${patientId}&providerId=${providerId}`,
            }}
            target="_blank"
            style={{ marginLeft: 'auto', marginRight: '7px' }}
          >
            <Button variant="primary">
              See depression questionnaire scores
            </Button>
          </Link>
          <Link
            to={{
              pathname: `/patient/settings?patientId=${patientId}&providerId=${providerId}`,
            }}
            target="_blank"
            style={{ marginLeft: '7px', marginRight: 'auto' }}
          >
            <Button variant="primary">Administer questionnaires</Button>
          </Link>
        </Form.Group>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Additional notes
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            bsPrefix="form-input"
            disabled={props.patientData.NoRelevantDepressive}
            onChange={(e) => {
              props.setPatientData({
                ...props.patientData,
                DepressiveAdditionalNotes: e.target.value,
              })
            }}
            value={props.patientData.DepressiveAdditionalNotes}
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
