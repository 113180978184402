import { Button, Modal } from '../BaseComponents'

export interface SuperbillWithoutPaymentConfirmationModalProps {
  onAddPayment: () => void
  onCancel: () => void
  onProceedWithoutPayment: () => void
  isVisible: boolean
}

export default function SuperbillWithoutPaymentConfirmationModal({
  onAddPayment,
  onCancel,
  onProceedWithoutPayment,
  isVisible,
}: SuperbillWithoutPaymentConfirmationModalProps) {
  return (
    <Modal
      title="Create Superbill without payment?"
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button key="proceed" onClick={onProceedWithoutPayment}>
          Proceed without payment
        </Button>,
        <Button key="add-payment" type="primary" onClick={onAddPayment}>
          Add payment
        </Button>,
      ]}
    >
      <p>Are you sure you want to create a superbill without payment?</p>
    </Modal>
  )
}
