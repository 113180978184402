import HelloSign from 'hellosign-embedded'

import { globalConfig } from '../config/config'

interface HellosignWindowOptions {
  allowCancel?: boolean
  container?: HTMLElement
}

export function openWindow(
  url: string,
  options: HellosignWindowOptions = {},
  handleClose?: () => void
): void {
  const config = globalConfig.get()
  const params = {
    clientId: config.hellosign.CLIENT_ID,
    skipDomainVerification: true,
  }
  const client = new HelloSign({
    clientId: config.hellosign.CLIENT_ID,
  })

  client.open(url, Object.assign({}, params, options))

  /**
   * finish is only emitted after edit (not on cancel)
   * - this is hard to test:
   *   see note in the spec above 'Open modal, with editing, then Close and Confirm close'
   */
  client.on('finish', () => {
    if (handleClose) {
      handleClose()
    }
    client.off('finish')
  })
}

export function openWindowWithoutCancel(
  url: string,
  container?: HTMLElement,
  handleClose?: () => void
): void {
  return openWindow(url, { allowCancel: false, container }, handleClose)
}

export const openHellosignWindow = async (
  claimUrl: string,
  afterFinish?: Function,
  container?: HTMLElement
): Promise<void> => {
  return new Promise((resolve, _reject) => {
    // container param (the second param) should be undefined since hellosign-embedded
    // container option only accepts HTMLElement or undefined.
    openWindowWithoutCancel(claimUrl, container, () => {
      afterFinish && afterFinish()
      return resolve()
    })
  })
}
