import React, { useCallback, useEffect, useMemo, useState } from 'react'

import dayjs from 'dayjs'
// Switch from dayjs to date-fns
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { ButtonGroup, Col, Form, Row, ToggleButton } from 'react-bootstrap'
import DatePicker from 'react-datepicker'

import { parseDateOrNull } from '../../../../libs/utils'
import { DateField, DateFormat, UniqueDatePickerID } from '../types'
import { DatePickerEditFieldProps } from './types'

import '../../DatePicker.scss'

type ValidDateFromString = Array<Date | null> | Date | null

function generateToggleLabel(id: string) {
  switch (id) {
    case DateFormat.YEAR:
      return 'YYYY - YYYY'
    case DateFormat.MONTH_YEAR:
      return 'MM/YYYY'
    case DateFormat.MONTH_DAY_YEAR:
      return 'MM/DD/YYYY'
  }
  return 'YYYY'
}

function generateFormat(id: string) {
  let format = 'YYYY'
  switch (id) {
    case DateFormat.MONTH_DAY_YEAR:
      format = 'MM/DD/YYYY'
      break
    case DateFormat.MONTH_YEAR:
      format = 'MM/YYYY'
  }
  return format
}

function generateValidDateFromString(fields: DateField): ValidDateFromString {
  if (!fields) {
    return null
  }
  dayjs.extend(customParseFormat)
  let dayjsDate = null

  const type = fields.format
  const format = generateFormat(type)
  const isValid = (date: Date) => {
    return dayjs(date).isValid()
  }

  const isRange = type && type.includes('Range')
  if (isRange) {
    let startDate = null
    let endDate = null
    if (fields.startDate) {
      const start = dayjs(fields.startDate, format).toDate()
      if (isValid(start)) startDate = new Date(start)
    }
    if (fields.endDate) {
      const end = dayjs(fields.endDate, format).toDate()
      if (isValid(end)) endDate = new Date(end)
    }

    return [startDate, endDate]
  } else if (format === 'YYYY') {
    dayjsDate = dayjs(fields.date)
  } else {
    dayjsDate = dayjs(fields.date, format)
  }

  const newDate = new Date(dayjsDate.toDate())
  return isValid(newDate) ? newDate : null
}

function reformatDate(format: string, date: Date): string {
  // if there is no newDate (because a user might remove a patient's DOB), set date as empty string instead of this returning 'Invalid Date'
  if (!date) return ''
  dayjs.extend(customParseFormat)
  const dateFormat = generateFormat(format)

  return dayjs(date).format(dateFormat).toString()
}

export default function DatePickerEditField(props: DatePickerEditFieldProps) {
  const { templateField, handleFieldChange, styling, value } = props

  const {
    columnSizes,
    dateType = DateFormat.MONTH_DAY_YEAR,
    id,
    label,
    toggleDateType = null,
  } = templateField

  const {
    editColBsPrefix = '',
    editColClassName = '',
    editColStyle = {},
    editLabelBsPrefix = '',
    editLabelClassName = '',
    editLabelStyle = {},
  } = styling

  const hasFormat = value && typeof value === 'object' && 'format' in value
  const initialFormat = (hasFormat && value?.format) || dateType
  const [fields, setFields] = useState<any>(value)
  const [currentFormat, setCurrentFormat] = useState<DateFormat>(initialFormat)
  const [shouldUpdateFormat, setShouldUpdateFormat] = useState(false)
  const isRange = currentFormat.includes('Range')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [checkedDate, setCheckedDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  useEffect(() => {
    const isObject = fields && typeof fields === 'object'
    const isDateField =
      isObject &&
      ('format' in fields || 'startDate' in fields || 'endDate' in fields)
    const generatedDate = isDateField
      ? generateValidDateFromString(fields)
      : parseDateOrNull(fields)

    if (!Array.isArray(generatedDate)) return setCheckedDate(generatedDate)

    const [start, end] = generatedDate
    setStartDate(start)
    setEndDate(end)
  }, [fields])

  function handleChange(newDate: Date, position = '') {
    const formattedNewDate = reformatDate(currentFormat, newDate)
    const handleChangeWithoutFormat = (
      givenDate: Omit<DateField, 'format'>
    ): void => {
      handleFieldChange({
        target: {
          id: id,
          value: {
            ...givenDate,
            format: currentFormat,
          },
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>)
      setShouldUpdateFormat(false)
    }

    const isStartDate = position === 'startDate'
    if (!isStartDate && position !== 'endDate') {
      return handleChangeWithoutFormat({ date: formattedNewDate })
    }

    const formattedCurrentDate = reformatDate(currentFormat, new Date())
    if (typeof fields !== 'object') {
      const endDate = isStartDate ? formattedCurrentDate : formattedNewDate
      return handleChangeWithoutFormat({ startDate: formattedNewDate, endDate })
    }

    if (isStartDate) {
      const { endDate = formattedCurrentDate } = fields
      return handleChangeWithoutFormat({ startDate: formattedNewDate, endDate })
    }

    const { startDate = formattedNewDate } = fields
    handleChangeWithoutFormat({ startDate, endDate: formattedNewDate })
  }

  useEffect(() => {
    setFields(value)
  }, [value])

  useEffect(() => {
    if (!shouldUpdateFormat) {
      return
    }
    // below updates corresponding values on storedFields when
    // currentFormat is changed
    const oldFormat = fields.format
    if (!oldFormat) {
      return
    }
    const dateCopy = generateValidDateFromString(fields) as ValidDateFromString
    if (!dateCopy) {
      return
    }

    const isOldFormatRange = oldFormat.includes('Range')
    const isNewFormatRange = Array.isArray(dateCopy) // determines format by result
    const castedCopy = dateCopy as Date[] // only applicable is above is true
    const isRangeToSingle = !isRange && isOldFormatRange
    const isSingleToRange = isRange && !isOldFormatRange
    const isSingleToSingle = !isRange && !isOldFormatRange
    const isRangeToRange = isRange && isOldFormatRange

    if (isRangeToSingle && isNewFormatRange && castedCopy[0]) {
      const startDate = castedCopy[0] as Date
      handleChange(startDate)
    } else if (isSingleToRange && !isNewFormatRange) {
      handleChange(dateCopy as Date, 'startDate')
    } else if (isSingleToSingle && !isNewFormatRange) {
      handleChange(dateCopy as Date)
    } else if (isRangeToRange && isNewFormatRange) {
      const [startDate, endDate] = castedCopy
      if (startDate) {
        handleChange(startDate, 'startDate')
      }
      if (endDate) {
        handleChange(endDate, 'startDate')
      }
    }
  }, [shouldUpdateFormat])

  function updateFormat(type: DateFormat) {
    setCurrentFormat(type)
    setShouldUpdateFormat(true)
  }

  const startDateLabel = (
    <Form.Label
      bsPrefix={editLabelBsPrefix}
      className={editLabelClassName}
      style={editLabelStyle}
    >
      {label || 'Start'} &nbsp;
      {Array.isArray(toggleDateType) && (
        <ButtonGroup toggle size="sm">
          {toggleDateType.map((type, index) => (
            <ToggleButton
              className="date-format-selector"
              variant="primary"
              key={index}
              type="radio"
              name="radio"
              value={type}
              style={{ fontSize: '12px' }}
              checked={currentFormat === type}
              onChange={() => updateFormat(type)}
            >
              {generateToggleLabel(type)}
            </ToggleButton>
          ))}
        </ButtonGroup>
      )}
    </Form.Label>
  )

  const endDateLabel = (
    <Form.Label
      bsPrefix={editLabelBsPrefix}
      className={editLabelClassName}
      style={editLabelStyle}
    >
      End
    </Form.Label>
  )

  const validateBirthdate = useCallback(
    (date: string, min: Date, max: Date) => {
      if (!date) {
        setErrorMessage(null)
        return
      }

      const invalidDate = isNaN(Date.parse(date))
      const outsideRange = new Date(date) < min || new Date(date) > max

      if (!errorMessage && (invalidDate || outsideRange)) {
        setErrorMessage('Invalid date of birth entered.')
      } else {
        setErrorMessage(null)
      }
    },
    [errorMessage]
  )

  const datePicker = useMemo(() => {
    const now = new Date()
    const birthdateMin = new Date(now.setFullYear(now.getFullYear() - 110))
    const birthdateMax = new Date()

    if (id === UniqueDatePickerID.BIRTHDATE) {
      return (
        <DatePicker
          id="mm/dd/yyyy-dateOfBirth"
          className="date-input"
          selected={checkedDate}
          placeholderText="MM/DD/YYYY"
          onChange={(date) => handleChange(date as Date)}
          dateFormat="MM/dd/yyyy"
          minDate={birthdateMin}
          maxDate={birthdateMax}
          onChangeRaw={(event) =>
            validateBirthdate(event.target.value, birthdateMin, birthdateMax)
          }
        />
      )
    }

    switch (currentFormat) {
      case DateFormat.YEAR_RANGE:
        return (
          <Row>
            <Col>
              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  {startDateLabel}
                </Col>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <DatePicker
                    className="date-input"
                    selected={startDate}
                    placeholderText="YYYY"
                    onChange={(start: Date) => handleChange(start, 'startDate')}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={endDate || now}
                    dateFormat="yyyy"
                    showYearPicker
                    minDate={now}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  {endDateLabel}
                </Col>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <DatePicker
                    className="date-input"
                    selected={endDate}
                    placeholderText="YYYY"
                    onChange={(end: Date) => handleChange(end, 'endDate')}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={now}
                    dateFormat="yyyy"
                    showYearPicker
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )
      case DateFormat.YEAR:
        return (
          <DatePicker
            id="yyyy-dateinput"
            className="date-input"
            selected={checkedDate}
            placeholderText="YYYY"
            onChange={(date) => handleChange(date as Date)}
            dateFormat="yyyy"
            showYearPicker
          />
        )
      case DateFormat.MONTH_YEAR:
        return (
          <DatePicker
            id="mm/yyyy-dateinput"
            className="date-input"
            selected={checkedDate}
            placeholderText="MM/YYYY"
            onChange={(date) => handleChange(date as Date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
        )
      default:
        return (
          <DatePicker
            id="mm/dd/yyyy-dateinput"
            className="date-input"
            selected={checkedDate}
            placeholderText="MM/DD/YYYY"
            onChange={(date) => handleChange(date as Date)}
            dateFormat="MM/dd/yyyy"
          />
        )
    }
  }, [startDate, endDate, checkedDate, props])

  return (
    <Form.Group
      as={Col}
      bsPrefix={editColBsPrefix}
      className={editColClassName}
      controlId={id}
      style={editColStyle}
      {...columnSizes}
    >
      {!isRange && startDateLabel}
      <Row>
        <Col>{datePicker}</Col>
      </Row>
      <p className="datepicker-error-text">{errorMessage}</p>
    </Form.Group>
  )
}
