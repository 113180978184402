import { useEffect, useState } from 'react'

import FilterTags from '../BaseComponents/FilterTags'
import Table, { useToolsWithTableHook } from '../BaseComponents/Table'
import LabsResultsTableConstructor, {
  LabsResultsTableProps,
} from './LabsResultsTableConstructor'

export default function LabsResultsTable({
  results,
  showPatients,
  isLoading,
  onDocuments,
  viewResultPDF,
  providerId,
  refresh,
  noToolsSection = true,
}: LabsResultsTableProps) {
  const [data, setData] = useState<any[]>([])
  useEffect(() => {
    const { columns } = LabsResultsTableConstructor({
      results,
      viewResultPDF,
      showPatients,
      providerId,
    })
    setData(columns)
  }, [results, refresh])

  const { activeFilters, tableChangeHandler } = useToolsWithTableHook(results)

  return (
    <Table
      noToolsSection={noToolsSection}
      loading={isLoading}
      columns={data}
      dataSource={results}
      pageSize={onDocuments ? 5 : 50}
      onChange={tableChangeHandler}
    >
      <FilterTags activeFilters={activeFilters} closable={false} />
    </Table>
  )
}
