import React from 'react'

import MultiSelect from '../MultiSelect'
import { Credentials } from './Credentials'

interface CredentialsSelectProps {
  defaultValue?: string[]
  handleChange?: (output: string[]) => Promise<void> | void
  placeholder?: string
}

const CredentialsMultiSelect: React.FC<CredentialsSelectProps> = ({
  defaultValue = [],
  handleChange,
  placeholder,
}) => {
  const options = Object.values(Credentials).map((credential) => {
    return { label: credential, value: credential }
  })
  return (
    <MultiSelect
      defaultValue={defaultValue}
      handleChange={handleChange}
      options={options}
      placeholder={placeholder}
      showSearch
    />
  )
}

export default CredentialsMultiSelect
