import { ReactNode } from 'react'

import { cloneDeep } from 'lodash'

import { Teammate } from '../../shared-types'
import { GoogleCalendarCheckbox } from '../../stories/Scheduling/SchedulingPage/Filters/GoogleCalendarCheckbox'
import { FILTER_LOCAL_STORAGE_LOCATION } from '../../stories/Scheduling/constants'
import {
  CalendarEventPrivacy,
  UserCalendarAccount,
} from '../../stories/Scheduling/types'
import { LocalStorageFilterData } from './useSchedulingFilters'

export const getLocalStorageFilterData = () => {
  const filterData = JSON.parse(
    localStorage.getItem(FILTER_LOCAL_STORAGE_LOCATION) || '{}'
  ) as LocalStorageFilterData
  return filterData
}

//TODO: This should be done in a hook
export const getLocalStorageFilterPropertyData = (
  property: keyof LocalStorageFilterData
): string[] => {
  const filterData = getLocalStorageFilterData()
  if (!Object.keys(filterData)) {
    return []
  }
  return filterData[property] ?? []
}

export const setLocalStorageFilterPropertyData = (
  property: keyof LocalStorageFilterData,
  data: string[]
) => {
  const localStorageData = getLocalStorageFilterData()
  const newLocalStorageData = cloneDeep(localStorageData)
  newLocalStorageData[property] = [...data]
  localStorage.setItem(
    FILTER_LOCAL_STORAGE_LOCATION,
    JSON.stringify(newLocalStorageData)
  )
}

export const toSet = (values: string[]) => {
  const set = new Set<string>()
  values.forEach((val) => set.add(val))
  return set
}

export type CalendarCheckbox = {
  [accountName: string]: {
    calendarName: string | ReactNode
    calendarId: string
    isPrimaryCalendar: boolean
  }[]
}

type GoogleCalendarInformation = {
  calendarId: string
  calendarName: string
  accountName: string
  isPrimaryCalendar: false
  googleAccountName: string
}

type OsmindCalendarInformation = {
  calendarId: string
  calendarName: string
  accountName: string
  isPrimaryCalendar: true
  googleAccountName?: never
}

export const populateCheckboxes = (
  checkboxes: CalendarCheckbox,
  calendarInformation: GoogleCalendarInformation | OsmindCalendarInformation
) => {
  const { accountName, isPrimaryCalendar, calendarId, calendarName } =
    calendarInformation
  // Account already has checkboxes associated with them,
  // meaning this information is related to a google calendar.
  if (checkboxes[accountName] && !isPrimaryCalendar) {
    const { googleAccountName } = calendarInformation
    const currentOsmindCheckboxes = cloneDeep(checkboxes[accountName])
    checkboxes[accountName] = [
      ...currentOsmindCheckboxes,
      {
        calendarId,
        calendarName: (
          <GoogleCalendarCheckbox
            calendarName={calendarName}
            googleAccountName={googleAccountName}
          />
        ),
        isPrimaryCalendar,
      },
    ]

    return checkboxes
  }

  checkboxes[accountName] = [
    {
      calendarId,
      calendarName,
      isPrimaryCalendar,
    },
  ]

  return checkboxes
}

export const generateUniqueGoogleCalendarId = (
  calendarId: string,
  accountName: string
) => {
  return `${calendarId}_${accountName}`
}

const OSMIND_CALENDAR_ACCOUNT_NAME = 'Osmind'

export const getCalendarFilters = (
  teammates: Teammate[],
  teammateCalendars: UserCalendarAccount[],
  providerId: string
) => {
  if (!teammates) {
    return { personalCalendarCheckboxes: {}, teammateCalendarCheckboxes: {} }
  }

  const activeTeammateMap = new Map<string, string>()

  let personalCalendarCheckboxes: CalendarCheckbox = {}
  let teammateCalendarCheckboxes: CalendarCheckbox = {}

  teammates.forEach(({ name, email, cognitoId }) => {
    const accountName = name || email

    if (cognitoId === providerId) {
      activeTeammateMap.set(cognitoId, OSMIND_CALENDAR_ACCOUNT_NAME)
      personalCalendarCheckboxes = populateCheckboxes(
        personalCalendarCheckboxes,
        {
          accountName: OSMIND_CALENDAR_ACCOUNT_NAME,
          calendarId: cognitoId,
          calendarName: `${name || email}`,
          isPrimaryCalendar: true,
        }
      )
      return
    }

    activeTeammateMap.set(cognitoId, accountName)

    teammateCalendarCheckboxes = populateCheckboxes(
      teammateCalendarCheckboxes,
      {
        accountName,
        calendarId: cognitoId,
        calendarName: `${name || email}`,
        isPrimaryCalendar: true,
      }
    )
  })

  teammateCalendars.forEach(({ calendars, internalId, accountName }) => {
    const currentAccountName = activeTeammateMap.get(internalId)
    if (currentAccountName) {
      calendars.forEach(({ calendarName, eventPrivacy, calendarId }) => {
        if (
          eventPrivacy &&
          [
            CalendarEventPrivacy.INTEGRATED,
            CalendarEventPrivacy.INTEGRATED_WITH_DETAILS,
          ].includes(eventPrivacy)
        ) {
          return
        }

        const uniqueGoogleCalendarId = generateUniqueGoogleCalendarId(
          calendarId,
          accountName
        )

        // Google calendars are associated with current user
        if (internalId === providerId) {
          personalCalendarCheckboxes = populateCheckboxes(
            personalCalendarCheckboxes,
            {
              accountName: OSMIND_CALENDAR_ACCOUNT_NAME,
              calendarId: uniqueGoogleCalendarId,
              calendarName,
              isPrimaryCalendar: false,
              googleAccountName: accountName,
            }
          )
          return
        }
        teammateCalendarCheckboxes = populateCheckboxes(
          teammateCalendarCheckboxes,
          {
            accountName: currentAccountName,
            calendarId: uniqueGoogleCalendarId,
            calendarName,
            isPrimaryCalendar: false,
            googleAccountName: accountName,
          }
        )
      })
    }
  })

  return { personalCalendarCheckboxes, teammateCalendarCheckboxes }
}

export const getTeammateGoogleCalendarIds = (
  teammateCalendars: UserCalendarAccount[]
) => {
  return teammateCalendars
    .map(({ accountName, calendars }) =>
      calendars.map(({ calendarId }) =>
        generateUniqueGoogleCalendarId(calendarId, accountName)
      )
    )
    .flat()
}
