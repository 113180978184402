import React from 'react'

import CSVExportButton from '../BaseComponents/CSVExportButton'
import FilterTags from '../BaseComponents/FilterTags'
import Table, { useToolsWithTableHook } from '../BaseComponents/Table'
import { ReportTableProps } from '../BaseComponents/tableData.types'

const ReportTable: React.FC<ReportTableProps> = ({
  columns,
  dataSource,
  loading,
  csvHeaders,
  tableName,
}) => {
  const {
    activeFilters,
    // downloadableCSVData,
    tableChangeHandler,
  } = useToolsWithTableHook(dataSource)

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      onChange={tableChangeHandler}
      loading={loading}
    >
      <FilterTags activeFilters={activeFilters} closable={false} />
      <CSVExportButton
        loading={loading}
        tableName={tableName}
        data={dataSource}
        headers={csvHeaders}
      />
    </Table>
  )
}

export default ReportTable
