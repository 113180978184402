import React from 'react'

import { Container, Form, Row } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'

import './../PatientProfile.scss'

export default function PsychoticSaved(props) {
  const hasNoRelevantHistory =
    props.patientData.Schizophrenia.state === false &&
    props.patientData.PsychoticOther.state === false &&
    !props.patientData.PsychoticAdditionalNotes &&
    props.patientData.NoPsychotic === true

  const noRecordedInfo =
    props.patientData.Schizophrenia.state === false &&
    props.patientData.PsychoticOther.state === false &&
    !props.patientData.PsychoticAdditionalNotes &&
    props.patientData.NoPsychotic === false

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Psychotic"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">Psychotic:</Form.Row>
      <Form.Group className="indent">
        {props.patientData.Schizophrenia.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.Schizophrenia.state)}
            />
            <span id="SchizophreniaShow">Schizophrenia</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.Schizophrenia.Notes}
          </Container>
        )}
        {props.patientData.PsychoticOther.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.PsychoticOther.state)}
            />
            <span id="PsychoticOtherShow">Other</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.PsychoticOther.Notes}
          </Container>
        )}
        <Container bsPrefix="psych">
          {props.patientData.PsychoticSymptoms.AuditoryHallucinations && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(
                  props.patientData.PsychoticSymptoms.AuditoryHallucinations
                )}
              />
              <span id="AuditoryHallucinationsShow">
                Auditory hallucinations
              </span>
              <br />
            </>
          )}
          {props.patientData.PsychoticSymptoms.VisualHallucinations && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(
                  props.patientData.PsychoticSymptoms.VisualHallucinations
                )}
              />
              <span id="VisualHallucinationsShow">Visual hallucinations</span>
              <br />
            </>
          )}
          {props.patientData.PsychoticSymptoms.SuspiciousThoughts && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(
                  props.patientData.PsychoticSymptoms.SuspiciousThoughts
                )}
              />
              <span id="SuspiciousThoughtsShow">
                Strong suspicious thoughts
              </span>
              <br />
            </>
          )}
          {props.patientData.PsychoticSymptoms.Paranoia && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(
                  props.patientData.PsychoticSymptoms.Paranoia
                )}
              />
              <span id="ParanoiaShow">Strong feelings of paranoia</span>
              <br />
            </>
          )}
          {props.patientData.PsychoticSymptoms.IdeasReference && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(
                  props.patientData.PsychoticSymptoms.IdeasReference
                )}
              />
              <span id="IdeasReferenceShow">Ideas of reference</span>
              <br />
            </>
          )}
          {props.patientData.PsychoticSymptoms.ThoughtBroadcasting && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(
                  props.patientData.PsychoticSymptoms.ThoughtBroadcasting
                )}
              />
              <span id="ThoughtBroadcastingShow">Thought broadcasting</span>
              <br />
            </>
          )}
          {props.patientData.PsychoticSymptoms.ThoughtInsertion && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(
                  props.patientData.PsychoticSymptoms.ThoughtInsertion
                )}
              />
              <span id="ThoughtInsertionShow">Thought insertion</span>
              <br />
            </>
          )}
          {props.patientData.PsychoticSymptoms.BeliefsReality && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(
                  props.patientData.PsychoticSymptoms.BeliefsReality
                )}
              />
              <span id="BeliefsRealityShow">
                Beliefs out of touch with reality
              </span>
              <br />
            </>
          )}
          {props.patientData.PsychoticSymptoms.Other && (
            <>
              <Form.Check
                className="psych-checkbox"
                type="checkbox"
                checked={JSON.parse(props.patientData.PsychoticSymptoms.Other)}
              />
              <span id="OtherTextShow">
                {props.patientData.PsychoticSymptoms.OtherText}
              </span>
              <br />
            </>
          )}
        </Container>
      </Form.Group>
      <Form.Group className="border-bottom indent">
        {props.patientData.PsychoticAdditionalNotes && (
          <Container bsPrefix="psych">
            <Form.Row className="patient-dark">Additional Notes:</Form.Row>
            <Row bsPrefix="psych-sub">
              {props.patientData.PsychoticAdditionalNotes}
            </Row>
          </Container>
        )}
      </Form.Group>
    </Form.Group>
  )
}
