import validator from 'validator'

import { detectBadPatientName } from '../../stories/BaseComponents/helpers/validationRules'
import { DemographicsContactInfo, DemographicsSection } from './shared-types'

/** Validation function for all the fields in the demographics/contact info section of the side-panel */
export const validateDemographics = async (
  newValueId: string,
  formData: DemographicsContactInfo
): Promise<true | void> => {
  const formField = newValueId.trim() as DemographicsSection

  if (
    [
      DemographicsSection.firstName,
      DemographicsSection.middleName,
      DemographicsSection.lastName,
    ].includes(formField)
  ) {
    const nameValue = formData[formField] as string // always a string for these values
    await detectBadPatientName(nameValue, false)
  } else if (
    [
      DemographicsSection.firstNameToUse,
      DemographicsSection.middleNameToUse,
      DemographicsSection.lastNameToUse,
      DemographicsSection.formerFirstName,
      DemographicsSection.formerMiddleName,
      DemographicsSection.formerLastName,
    ].includes(formField)
  ) {
    const nameValue = formData[formField] as string // always a string for these values
    await detectBadPatientName(nameValue, true)
  }

  if (formField === DemographicsSection.firstName && !formData[formField]) {
    // First name required
    throw new Error(`Legal first name is required and cannot be empty.`)
  }

  if (formField === DemographicsSection.lastName && !formData[formField]) {
    throw new Error('Legal last name is required and cannot be empty.')
  }

  if (
    formField === DemographicsSection.email &&
    !validator.isEmail(formData?.email ?? '')
  ) {
    throw new Error('Please enter a valid email.')
  }
}
