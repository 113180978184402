import React, { useMemo } from 'react'

import { Form as AntDForm, FormItemProps } from 'antd'
import { FormProps } from 'antd/lib/form/Form'
import { FieldData } from 'rc-field-form/lib/interface'

import { detectBadCSVValues } from './helpers/validationRules'

type FieldNameTypes = string | number | (string | number)[]

export interface BaseFormFields {
  name: FieldNameTypes
  value?: any
  touched?: boolean
  validating?: boolean
  errors?: string[]
}
export interface ExtendedFormFields extends BaseFormFields {
  /* This is used to pass props to each field's Form.Item */
  formItemProps?: FormItemProps
  /* This is used to render a form element */
  render: () => React.ReactNode
}
export type OutputFieldData = FieldData

export interface FormContainerProps extends FormProps {
  /* Appended data in fields */
  fields: ExtendedFormFields[]
}
export default function FormContainer({
  fields,
  ...formProps
}: FormContainerProps) {
  const baseFormFields = useMemo(() => {
    return fields.map(
      ({ render: _render, formItemProps: _formItemProps, ...baseFieldProps }) =>
        baseFieldProps
    )
  }, [fields])

  const initialFormData = useMemo(() => {
    return baseFormFields.map(({ name, value }) => {
      return { [name as string]: value }
    })
  }, [baseFormFields])

  return (
    <AntDForm
      {...formProps}
      initialValues={initialFormData}
      fields={baseFormFields}
    >
      {fields.map((field, i) => (
        <AntDForm.Item
          {...field.formItemProps}
          key={`${i}-${field.name}`}
          name={field.name}
          rules={[
            {
              validator: detectBadCSVValues,
            },
          ]}
        >
          {field.render()}
        </AntDForm.Item>
      ))}
    </AntDForm>
  )
}
