import React from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { LDClient, LDEvaluationDetail } from 'launchdarkly-js-client-sdk'
import {
  LDFlagSet,
  camelCaseKeys,
  useFlags,
  withLDProvider,
} from 'launchdarkly-react-client-sdk'

import { ProviderAccountInfo } from '../shared-types'
import { logFlags } from './freshpaint'

enum AppEnvironments {
  TEST = 'test',
  LOCAL = 'local',
  DEV = 'dev',
  STAGE = 'staging',
  PROD = 'prod',
  QA = 'qa',
}

const getCurrentAppEnvironment = (): AppEnvironments => {
  const stage = process.env.REACT_APP_STAGE
  if (stage && stage.endsWith('-dev')) {
    return AppEnvironments.DEV
  }

  return (stage ?? AppEnvironments.LOCAL) as AppEnvironments
}

const CurrentEnv = getCurrentAppEnvironment()

const LaunchDarklyClientIDs: Record<AppEnvironments, string> = {
  [AppEnvironments.TEST]: 'fake-key', // Test environments should not use the SDK
  [AppEnvironments.LOCAL]: '6185bbba14265214b6238b40',
  [AppEnvironments.DEV]: '6244da5694fa1b157e9f5d6d',
  [AppEnvironments.STAGE]: '6185bba9ee53b6149a94089c',
  [AppEnvironments.PROD]: '61840e0f4d4ecd6364618b6c',
  [AppEnvironments.QA]: '62ded984d192011114d7ce9b',
} as const

export interface FlagTypes {
  /* Note: Launch Darkly converts flag keys from snake_case & kebab-case to camelCase */
  community: boolean
  maintenanceMode: boolean
  datadogFrontendMonitoring: boolean
  datadogMonitoringSampleRate: number
  datadogMonitoringSessionReplaySampleRate: number
  datadogSessionReplays: boolean
  labs: boolean
  versionReloadEnabled: boolean
  advancedmd: boolean
  claims: boolean
  claimCreationV2: boolean
  hasEnabledCustomNotes: boolean
  /**
   * Are custom note templates enabled?
   */
  enableNoteTemplates: boolean
  /**
   * Used to control autosave rate for notes and note templates.
   * Poorly named, since it's not limited to v1 notes.
   * e.g. 2 seconds means a note will be autosaved every 2 seconds.
   *
   * This is a feature flag to allow dynamically controlling the autosave
   * cadence to reduce server load if necessary by reducing the save frequency.
   */
  autosaveDebounceNotev1Seconds: number
  schedulingNotificationsRefetchInterval: number
  unsignedSpravatoNotificationsRefetchInterval: number
  enableProviderSuperbillSend: boolean
  enableSendbirdConnectionManagement: boolean
  enableSendbirdConnectionManagementLogs: boolean
  appVersion: string
}

interface FlagUserDataType {
  kind: string
  name: string
  email: string
  userId: string
  clinicId: string
  createdAt?: string
}

interface IdentifyUserMethodProps {
  client: LDClient
  user: FlagUserDataType
}

/* This is used in the event the flag service is down */
export const BackupFlags: FlagTypes = {
  community: false,
  maintenanceMode: false,
  datadogFrontendMonitoring: process.env.NODE_ENV === 'production',
  datadogMonitoringSampleRate: 100,
  datadogMonitoringSessionReplaySampleRate: 0,
  datadogSessionReplays: process.env.NODE_ENV === 'production',
  labs: false,
  versionReloadEnabled: false,
  advancedmd: false,
  claims: false,
  claimCreationV2: false,
  hasEnabledCustomNotes: false,
  enableNoteTemplates: false,
  autosaveDebounceNotev1Seconds: 2,
  schedulingNotificationsRefetchInterval: 60 * 1000,
  unsignedSpravatoNotificationsRefetchInterval: 60 * 1000,
  enableProviderSuperbillSend: true,
  enableSendbirdConnectionManagement: true,
  enableSendbirdConnectionManagementLogs: false,
  appVersion: '',
}

export const shouldSkipLaunchDarklyCalls = (): boolean => {
  return [AppEnvironments.TEST, AppEnvironments.LOCAL].includes(CurrentEnv)
}

/**
 * NOTE: Automated test accounts are going to be identified
 * with the 'osmoTestContext' key. Non automated test accounts
 * are going to be identified in Launch Darkly with their Cognito ID.
 *
 * We are using 'qaprovider' string to identify test users instead of 'isTestAccount'
 * boolean because we have hardcoded flags for hardcoded test accounts.
 *
 * @param user object with custom metadata
 * @returns the key to be used in Launch Darkly to identify the user
 */
export const getLaunchDarklyKey = (user: FlagUserDataType): string => {
  return user.email && user.email.includes('qaprovider')
    ? 'osmoTestContext'
    : user.userId
}

export const FEATURE_FLAG_CACHE_KEY = 'launchDarkly_lastKnownFlags'

/**
 * Internal function that fetches fallback flags for passing to LaunchDarkly provider.
 * Specifying fallbacks to withLDProvider also limits the flags that the LD client will subscribe to.
 * So if there are none cached for this user session then this will return undefined so that LD pulls all flags
 *
 * @returns {FlagTypes}
 */
const getCachedFallbackFlags = (): FlagTypes | undefined => {
  const serializedCachedKeys = localStorage.getItem(FEATURE_FLAG_CACHE_KEY)
  if (!serializedCachedKeys) {
    return
  }

  try {
    const cachedKeys: Partial<FlagTypes> = JSON.parse(serializedCachedKeys)
    return { ...BackupFlags, ...cachedKeys }
  } catch (err) {
    return
  }
}

export const FeatureFlagProvider = (
  auth: ProviderAccountInfo | null,
  AppProvider: React.ComponentType
) => {
  let anonymousTemplateUser
  if (AppEnvironments.PROD === CurrentEnv) {
    anonymousTemplateUser = {
      name: 'Lost Found',
      email: 'lostandfound@osmind.org',
      key: 'us-west-2:040233e6-b541-484e-ac4c-8f2be9557559',
      anonymous: true,
    }
  } else {
    anonymousTemplateUser = {
      name: 'Osmind Test',
      email: 'testing@osmind.org',
      key: 'us-west-2:afadbc4b-adf0-44cc-9964-5775b779986c',
      anonymous: true,
    }
  }

  // initialize with the authed user if one is found via aws-amplify. This payload will not include the user name
  // so we still need to call identify later just to get the full context -- but this should get us most of the way there at first render
  const context = auth
    ? {
        kind: 'user',
        key: auth.id,
        email: auth.attributes.email,
        userId: auth.id,
        clinicId: auth.attributes.main_provider_id ?? auth.id,
      }
    : {
        /* This is a stubbed user (shared key) for all anonymous users */
        kind: 'user',
        key: anonymousTemplateUser.key,
      }

  const fallbackFlags = auth ? getCachedFallbackFlags() : undefined

  /* withLDProvider will call LaunchDarkly when the returned AppProvider is rendered. */
  return withLDProvider({
    clientSideID: LaunchDarklyClientIDs[CurrentEnv],
    context,
    flags: fallbackFlags,
    options: {
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key: string, detail: LDEvaluationDetail) => {
            /* There's no simple way to unit test this third-party library interaction */
            /* istanbul ignore next */
            datadogRum.addFeatureFlagEvaluation(key, detail.value)
          },
        },
      ],
    },
  })(AppProvider)
}

const cacheLatestFeatureFlags = (featureFlags: LDFlagSet) => {
  // flags coming from the launchDarkly client (not react sdk) are not camel-cased. for sanity's sake, convert them asap
  const camelCasedFlags = camelCaseKeys(featureFlags)
  localStorage.setItem(FEATURE_FLAG_CACHE_KEY, JSON.stringify(camelCasedFlags))
}

export const identifyFlagUser = ({ client, user }: IdentifyUserMethodProps) => {
  if (shouldSkipLaunchDarklyCalls()) {
    return
  }

  client.identify(
    { ...user, key: getLaunchDarklyKey(user) },
    undefined, // not using hash/secure mode
    (err, featureFlags) => {
      if (err) {
        console.error(err)
      } else if (featureFlags) {
        logFlags(user.userId, featureFlags)
        cacheLatestFeatureFlags(featureFlags)
        // register new listener
        client.on('change', () => {
          const currentContext = client.getContext()
          if (currentContext.key !== user.userId) {
            return
          }
          // we could be clever and apply the diff from the callback here
          // but it's just as cheap and easier/dumber to get LD to give us the current state of all flags
          const allFlags = client.allFlags()
          cacheLatestFeatureFlags(allFlags)
        })
      }
    }
  )
}

export const useFeatureFlags = (): FlagTypes => {
  if (shouldSkipLaunchDarklyCalls()) {
    // @ts-ignore
    const DevFlags = require('../config/featureFlags.config.json') // eslint-disable-line
    return DevFlags
  }

  try {
    return (useFlags as () => FlagTypes)()
  } catch (err) {
    console.error(
      `Using backup flags because the flag service failed with: ${err}`
    )
    const fallbackFlags = getCachedFallbackFlags() ?? BackupFlags
    return fallbackFlags
  }
}
