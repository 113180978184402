import React from 'react'

import moment from 'moment'
import 'moment-timezone'
import { Button, Col, Form, ListGroup, Row } from 'react-bootstrap'
import 'react-dates/initialize'
import { Link } from 'react-router-dom'

import useQueryString from '../../../../../hooks/useQueryString'
import { notification } from '../../../../../libs/notificationLib'
import { AutoSizingTextArea } from '../../../../../stories/BaseComponents/TextAreaInput'
import CGI from './CGI'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function AssesmentProblems(props) {
  const query = useQueryString()
  const patientId = query.get('patientId')
  const providerId = query.get('providerId')
  function diffDiagnosisFromPreviousNotes() {
    if (props.previousNotes.length === 0) {
      notification(
        'There are no previous SOAP notes to import from.',
        'failure'
      )
      return
    }
    props.handleFieldChange({
      ...props.fields,
      DifferentialDiagnosis: props.previousNotes[0].DifferentialDiagnosis,
    })
  }

  return (
    <>
      {props.Medical ? (
        <>
          <Form.Group
            as={Col}
            sm={12}
            md={6}
            lg={4}
            style={{
              textAlign: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Diagnoses / ICD codes:
            </Form.Label>
            <ListGroup className="form-list-group">
              {props.Diagnosis.map((actMeds, i) => {
                return (
                  <ListGroup.Item
                    key={'diagnosis-list-' + i}
                    className="disabled"
                  >
                    <span className="info">{actMeds.Diagnosis}</span>
                    <span>{': ' + (actMeds.DiagnosisCode || 'N/C')}</span>
                    <span>
                      {' (' +
                        (moment(actMeds.DiagnosisDate).isValid()
                          ? moment(actMeds.DiagnosisDate).format('MM/DD/YYYY')
                          : 'N/A') +
                        ')'}
                    </span>
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </Form.Group>
          <Form.Group>
            <Form.Text
              className="center hideDuringPrint"
              style={{ fontWeight: 'bold', color: '#A9A9A9' }}
            >
              <span style={{ fontSize: '13px' }}>
                Diagnoses are automatically copied from the Patient Profile. Go
                to the Patient Profile to edit.
              </span>
            </Form.Text>
          </Form.Group>
          <Row>
            <Link
              to={{
                pathname: `/patient/profile?patientId=${patientId}&providerId=${providerId}`,
              }}
              target="_blank"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '10px',
                marginTop: '10px',
              }}
            >
              <Button variant="primary">Update Diagnosis List</Button>
            </Link>
          </Row>
          <hr />
          <Row>
            <Form.Group as={Col} sm={12} md={12} lg={12}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Differential Diagnosis:
              </Form.Label>
              <Form.Control
                bsPrefix="form-control"
                className="field-value-edit"
                value={props.fields.DifferentialDiagnosis}
                disabled={props.disabled}
                as={AutoSizingTextArea}
                minRows={1}
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    DifferentialDiagnosis: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Row>
          <Row>
            <Button
              variant="outline-primary"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '10px',
              }}
              disabled={props.disabled}
              onClick={() => {
                diffDiagnosisFromPreviousNotes()
              }}
            >
              Import from last SOAP note
            </Button>
          </Row>
          <CGI
            fields={props.fields}
            handleFieldChange={props.handleFieldChange}
            Diagnosis={props.Diagnosis}
            previousNotes={props.previousNotes}
            disabled={props.disabled}
            Medical
          />
          <Row>
            <Form.Group as={Col} sm={12} md={12} lg={12}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Additional Notes
              </Form.Label>
              <Form.Control
                bsPrefix="form-control"
                className="field-value-edit"
                value={props.fields.TreatmentNotes}
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    TreatmentNotes: e.target.value,
                  })
                }
                disabled={props.disabled}
                as={AutoSizingTextArea}
                minRows={1}
              />
            </Form.Group>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Link
              to={{
                pathname: `/patient/profile?patientId=${patientId}&providerId=${providerId}`,
              }}
              target="_blank"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '10px',
                marginTop: '10px',
              }}
            >
              <Button variant="primary">Update Diagnosis List</Button>
            </Link>
          </Row>
          <Row>
            <Form.Group as={Col} sm={12} md={12} lg={12}>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Assessment and interpretations:
              </Form.Label>
              <Form.Control
                bsPrefix="form-control"
                className="field-value-edit"
                value={props.fields.TreatmentNotes}
                onChange={(e) =>
                  props.handleFieldChange({
                    ...props.fields,
                    TreatmentNotes: e.target.value,
                  })
                }
                disabled={props.disabled}
                as={AutoSizingTextArea}
                minRows={1}
              />
            </Form.Group>
          </Row>
        </>
      )}
    </>
  )
}
