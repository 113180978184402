import {
  FieldStyling,
  TemplateFields,
} from '../../../components/Forms/FormMachine/types'
import { Relationship } from './types'

const relationshipChoices = Object.values(Relationship)

export const styling: FieldStyling = {
  editColClassName: 'col-bottom-edit',
  colClassName: 'bottom-line',
  editLabelClassName: 'field-label-edit',
  editValueClassName: 'field-value-edit',
  labelClassName: 'col-squished field-label',
  valueClassName: 'field-value',
}

const noBottomBorder = {
  ...styling,
  editColStyle: { borderBottom: 'none' },
  colStyle: { borderBottom: 'none' },
}

const shiftLeft = {
  ...styling,
  editColStyle: {
    borderBottom: 'none',
    marginLeft: -15,
    marginRight: 15,
    marginTop: 30,
  },
  colStyle: {
    borderBottom: 'none',
    marginLeft: -15,
    marginRight: 15,
    marginTop: 30,
  },
}

const shiftRightAndNoBottomBorder = {
  ...styling,
  editColStyle: { marginLeft: 15, borderBottom: 'none' },
  colStyle: { marginLeft: 15, borderBottom: 'none' },
}

const shiftLeftAndNoBottomBorder = {
  ...styling,
  editColStyle: { marginLeft: -15, borderBottom: 'none' },
  colStyle: { marginLeft: -15, borderBottom: 'none' },
}

const centerOptionsAndNoBottomBorder = {
  ...styling,
  editColStyle: { borderBottom: 'none' },
  colStyle: { borderBottom: 'none' },
  editValueStyle: { marginLeft: 28 },
}

const fullWidth = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }
const halfWidth = { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }
const quarterWidth = { xs: 12, sm: 4, md: 4, lg: 4, xl: 4 } // the names should
const thirdWidth = { xs: 12, sm: 4, md: 3, lg: 3, xl: 3 } // be switched lmao
const fifthWidth = { xs: 12, sm: 5, md: 5, lg: 5, xl: 5 }
const seventhWidth = { xs: 12, sm: 7, md: 7, lg: 7, xl: 7 }

export const templateNames: TemplateFields = [
  {
    columnSizes: quarterWidth,
    customStyling: shiftLeft,
    fieldType: 'text',
    label: 'Legal first name',
    placeholder: '',
    id: 'firstName',
  },
  {
    columnSizes: quarterWidth,
    customStyling: shiftLeft,
    fieldType: 'text',
    label: 'Legal middle name',
    placeholder: '',
    id: 'middleName',
  },
  {
    columnSizes: quarterWidth,
    customStyling: shiftLeft,
    fieldType: 'text',
    label: 'Legal last name',
    placeholder: '',
    id: 'lastName',
  },
]

export const template: TemplateFields = [
  {
    columnSizes: quarterWidth,
    customStyling: noBottomBorder,
    fieldType: 'text',
    label: 'Address Line 1',
    placeholder: '',
    id: 'addressLine1',
  },
  {
    columnSizes: quarterWidth,
    customStyling: noBottomBorder,
    fieldType: 'text',
    label: 'Address Line 2',
    placeholder: '',
    id: 'addressLine2',
  },
  {
    columnSizes: quarterWidth,
    customStyling: noBottomBorder,
    fieldType: 'text',
    label: 'City',
    placeholder: '',
    id: 'city',
  },
  {
    columnSizes: quarterWidth,
    fieldType: 'text',
    label: 'State',
    placeholder: '',
    id: 'state',
  },
  {
    columnSizes: quarterWidth,
    fieldType: 'text',
    label: 'Zip code',
    placeholder: '',
    id: 'zipCode',
  },
  {
    columnSizes: quarterWidth,
    fieldType: 'text',
    label: 'Email',
    placeholder: '',
    id: 'email',
  },
  {
    columnSizes: quarterWidth,
    fieldType: 'tel',
    label: 'Cell phone',
    placeholder: '',
    id: 'cellPhone',
  },
  {
    columnSizes: quarterWidth,
    fieldType: 'tel',
    label: 'Home phone',
    placeholder: '',
    id: 'homePhone',
  },
  {
    columnSizes: quarterWidth,
    fieldType: 'tel',
    label: 'Work phone',
    placeholder: '',
    id: 'workPhone',
  },
  {
    addFieldLabel: 'Add new Emergency Contact',
    columnSizes: fullWidth,
    customStyling: noBottomBorder,
    fieldType: 'multiple',
    id: 'emergencyContacts',
    label: 'Emergency Contact',
    multipleComponent: [
      {
        columnSizes: quarterWidth,
        customStyling: noBottomBorder,
        fieldType: 'text',
        label: 'Name',
        id: 'emergencyName',
      },
      {
        columnSizes: quarterWidth,
        customStyling: noBottomBorder,
        fieldType: 'tel',
        label: 'Phone',
        id: 'emergencyPhone',
      },
      {
        columnSizes: quarterWidth,
        customStyling: noBottomBorder,
        fieldType: 'text',
        label: 'Email',
        id: 'emergencyEmail',
      },
      {
        conditionalComponent: {
          columnSizes: fullWidth,
          customStyling: noBottomBorder,
          fieldType: 'text',
          id: 'emergencyRelationshipOther',
          label: 'Other relationship',
        },
        columnSizes: halfWidth,
        conditionalOption: 'Other',
        customStyling: noBottomBorder,
        fieldType: 'dropdown',
        id: 'emergencyRelationship',
        label: 'Relationship',
        options: relationshipChoices,
        size: 1,
      },
      {
        columnSizes: halfWidth,
        customStyling: centerOptionsAndNoBottomBorder,
        defaultOption: 'No',
        fieldType: 'yesNo',
        id: 'emergencyAuthorization',
        label: 'Provided authorization to discuss treatment:',
      },
    ],
  },
  // Care Coordination
  {
    columnSizes: fullWidth,
    fieldType: 'label',
    label: 'Care Coordination: ',
    id: 'careLabel',
  },
  {
    columnSizes: fullWidth,
    fieldType: 'label',
    label: 'Family physician / PCP',
    id: 'familyPhysicianLabel',
  },
  {
    columnSizes: thirdWidth,
    customStyling: shiftRightAndNoBottomBorder,
    fieldType: 'text',
    label: 'Name',
    id: 'primaryProviderName',
  },
  {
    columnSizes: thirdWidth,
    customStyling: shiftLeftAndNoBottomBorder,
    fieldType: 'tel',
    label: 'Phone',
    id: 'primaryProviderPhone',
  },
  {
    columnSizes: thirdWidth,
    customStyling: noBottomBorder,
    fieldType: 'text',
    label: 'Email',
    id: 'primaryProviderEmail',
  },
  {
    columnSizes: thirdWidth,
    customStyling: noBottomBorder,
    fieldType: 'text',
    label: 'City',
    id: 'primaryProviderCity',
  },
  {
    columnSizes: fifthWidth,
    customStyling: {
      ...styling,
      editValueStyle: { marginLeft: 28 },
    },
    defaultOption: 'No',
    fieldType: 'yesNo',
    id: 'primaryProviderAuth',
    label: 'Provided authorization to discuss treatment:',
  },
  {
    columnSizes: seventhWidth,
    fieldType: 'textarea',
    label: 'Notes',
    id: 'primaryProviderNotes',
  },
  {
    addFieldLabel: 'Add new Mental health provider',
    columnSizes: fullWidth,
    customStyling: noBottomBorder,
    fieldType: 'multiple',
    id: 'mentalHealthcareProviders',
    label: 'Mental health provider(s)',
    multipleComponent: [
      {
        columnSizes: thirdWidth,
        customStyling: noBottomBorder,
        fieldType: 'text',
        label: 'Name',
        id: 'mentalHealthProviderName',
      },
      {
        columnSizes: thirdWidth,
        customStyling: noBottomBorder,
        fieldType: 'tel',
        label: 'Phone',
        id: 'mentalHealthProviderPhone',
      },
      {
        columnSizes: thirdWidth,
        customStyling: noBottomBorder,
        fieldType: 'text',
        label: 'Email',
        id: 'mentalHealthProviderEmail',
      },
      {
        columnSizes: thirdWidth,
        customStyling: noBottomBorder,
        fieldType: 'text',
        label: 'City',
        id: 'mentalHealthProviderCity',
      },
      {
        columnSizes: fifthWidth,
        customStyling: centerOptionsAndNoBottomBorder,
        defaultOption: 'No',
        fieldType: 'yesNo',
        id: 'mentalHealthProviderAuth',
        label: 'Provided authorization to discuss treatment:',
      },
      {
        columnSizes: seventhWidth,
        customStyling: noBottomBorder,
        fieldType: 'textarea',
        label: 'Notes',
        id: 'mentalHealthProviderNotes',
      },
    ],
  },
  {
    addFieldLabel: 'Add new Other healthcare provider',
    columnSizes: fullWidth,
    customStyling: noBottomBorder,
    fieldType: 'multiple',
    id: 'otherHealthcareProviders',
    label: 'Other Healthcare provider(s)',
    multipleComponent: [
      {
        columnSizes: thirdWidth,
        customStyling: noBottomBorder,
        fieldType: 'text',
        label: 'Name',
        id: 'otherHealthProviderName',
      },
      {
        columnSizes: thirdWidth,
        customStyling: noBottomBorder,
        fieldType: 'tel',
        label: 'Phone',
        id: 'otherHealthProviderPhone',
      },
      {
        columnSizes: thirdWidth,
        customStyling: noBottomBorder,
        fieldType: 'text',
        label: 'Email',
        id: 'otherHealthProviderEmail',
      },
      {
        columnSizes: thirdWidth,
        customStyling: noBottomBorder,
        fieldType: 'text',
        label: 'City',
        id: 'otherHealthProviderCity',
      },
      {
        columnSizes: fifthWidth,
        customStyling: centerOptionsAndNoBottomBorder,
        defaultOption: 'No',
        fieldType: 'yesNo',
        id: 'otherHealthProviderAuth',
        label: 'Provided authorization to discuss treatment:',
      },
      {
        columnSizes: seventhWidth,
        customStyling: noBottomBorder,
        fieldType: 'textarea',
        label: 'Notes',
        id: 'otherHealthProviderNotes',
      },
    ],
  },
  {
    columnSizes: fullWidth,
    customStyling: noBottomBorder,
    fieldType: 'text',
    label: 'Preferred pharmacy',
    id: 'preferredPharmacy',
  },
]
