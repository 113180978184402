import React, { PropsWithChildren } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'

import { Card, Text, Tooltip } from '../../../stories/BaseComponents'

type ProceduresContainerProps = PropsWithChildren & {
  isLoading?: boolean
}

const ProceduresContainer: React.FC<ProceduresContainerProps> = ({
  isLoading,
  children,
}) => {
  return (
    <Card loading={isLoading} testId="claim-procedures" bordered={false}>
      <div style={{ paddingBottom: 16 }}>
        <Text header="h5">
          {`Procedures `}
          <Tooltip
            title={`Osmind uses CPT© American Medical Association, HCPCS - Centers for Medicare & Medicaid Services.`}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </Text>
      </div>
      {children}
    </Card>
  )
}

export default ProceduresContainer
