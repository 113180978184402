import React, { useCallback, useEffect, useState } from 'react'

import cx from 'classnames'
import { endOfMonth, startOfDay, startOfMonth, subMonths } from 'date-fns'

import { getReportLabsResults, saveViewOrder } from '../../api/labs'
import PDFModal from '../../components/Other/PDFModal'
import { useReadyForReviewLabs } from '../../hooks/useReadyToReviewLabs'
import { getS3FilePrivate } from '../../libs/awsLib'
import LabsResultsTable from '../../stories/Labs/LabsResultsTable'
import { LabsResultData } from '../../stories/Labs/_types'
import DatePicker, {
  NonNullableDateChangeHandler,
} from '../BaseComponents/Datepicker'
import Header from '../BaseComponents/Header'
import Metrics, { MetricObject } from '../BaseComponents/Metrics'
import { METRICS_HEADER_TOTAL_LABS } from './constants'

import '../../components/Other/ConfirmationMessage.scss'
import styles from '../../containers/_shared.module.scss'
import '../ReportPages.scss'

interface ReportLabsType {
  providerId: string
}

const header = {
  description: 'Explore your practice’s labs.',
  link: {
    url: 'https://support.osmind.org/en/articles/5665898-reports',
    text: 'Learn more.',
  },
  metrics: [{ title: METRICS_HEADER_TOTAL_LABS, dataSource: 0 }],
  title: 'Labs',
}

const ReportLabs: React.FC<ReportLabsType> = ({ providerId }) => {
  const refetchIsReadyforReviewLab = useReadyForReviewLabs().refetch
  const now = new Date()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [dateRangeValues, setDateRange] = useState<{
    startDate: Date
    endDate: Date
  }>({ startDate: subMonths(startOfMonth(now), 5), endDate: endOfMonth(now) })
  const [tableData, setTableData] = useState<LabsResultData[]>([])
  const [metrics, setMetrics] = useState<MetricObject[]>(header.metrics)
  const [activeResult, setActiveLabResults] = useState()
  const [refetch, setRefetch] = useState(false)
  const [file, setFile] = useState(null)
  const [show, setShow] = useState(false)
  const [refresh, setRefresh] = useState(false)

  async function downloadFileFromS3(record: any) {
    const result = await getS3FilePrivate(
      record.labsOrderDocumentReference,
      providerId,
      {
        ResponseCacheControl: 'max-age=0',
      }
    )
    const File: any = {}
    File.file = { data: result }
    setFile(File)
  }

  function setLabToViewed(record: any) {
    const results: any = tableData
    for (const r of results) {
      if (r.key === record.key) {
        r.viewed = new Date().toISOString()
      }
    }
    setTableData(results)
    refetchIsReadyforReviewLab()
    setRefresh(!refresh)
  }

  async function putViewedOrder(record: any) {
    try {
      await saveViewOrder(record.key)
      setLabToViewed(record)
    } catch (e) {
      return
    }
  }

  const fetchData = useCallback(async () => {
    setTableData([])
    setIsLoading(true)
    try {
      const { startDate, endDate } = dateRangeValues
      /// Using startOfDay to avoid next day error due to timezones.
      const data: any = await getReportLabsResults(
        startOfDay(startDate).toISOString(),
        startOfDay(endDate).toISOString()
      )
      setMetrics([{ title: 'Total Labs', dataSource: data.total }])
      setTableData(data.labs)
    } catch (err) {
      console.error(err)
    }
    setIsLoading(false)
  }, [dateRangeValues, fetch])

  // This will run whenever dateRangeValues change
  useEffect(() => {
    // here I should fetch the data
    fetchData()
  }, [dateRangeValues, fetchData, refetch])

  const handleDateChange: NonNullableDateChangeHandler = useCallback(
    (startDate, endDate) => {
      setDateRange({ startDate, endDate })
    },
    []
  )

  function viewResultPDF(record: any) {
    setActiveLabResults(record)
    setShow(true)
    downloadFileFromS3(record)
    if (providerId === record.orderingClinicianId && !record.viewed) {
      putViewedOrder(record)
    }
  }

  function labRefresh() {
    setRefetch(!refetch)
  }

  return (
    <div key={'labs'} className={cx(['report-page', styles.scroll])}>
      <Header
        description={header.description}
        link={header.link}
        title={header.title}
      >
        <DatePicker
          initialValues={dateRangeValues}
          onDateChange={handleDateChange}
        />
        <Metrics metrics={metrics} loading={isLoading} />
      </Header>
      <PDFModal
        fileContainer={file}
        show={show}
        handleShow={setShow}
        isLabPdf={true}
        labResult={activeResult}
        setLabsRefresh={labRefresh}
      />
      <LabsResultsTable
        results={tableData}
        isLoading={isLoading}
        viewResultPDF={viewResultPDF}
        providerId={providerId}
        refresh={refresh}
        showPatients={true}
        noToolsSection={false}
      />
    </div>
  )
}

export default ReportLabs
