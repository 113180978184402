import React from 'react'

import styles from './NoAppointmentRequestCard.module.scss'

export function NoAppointmentRequestCard() {
  return (
    <div className={styles.noApptRequestCard}>
      <h5>No appointment requests</h5>
      <main>
        There are no appointment requests. As soon as someone requests to
        schedule a time, it will show up here.
      </main>
    </div>
  )
}
