import React, { useMemo } from 'react'

import Icon from '@ant-design/icons'
import { Layout } from 'antd'

import Menu from '../../components/Menu/Menu'
import { Option } from '../../components/Menu/types'
import { getNoteTemplateSettingsUrl } from '../../containers/Patient/ClinicalNotes/utils'
import { useReadyForReviewLabs } from '../../hooks/useReadyToReviewLabs'
import { useUnsignedSpravatoNotifications } from '../../hooks/useUnsignedSpravatoNotifications'
import { ReactComponent as ExternalLink } from '../../images/Icons/ExternalLink.svg'
import { useFeatureFlags } from '../../libs/featureFlags'
import { Badge } from '../BaseComponents'

import themeStyles from '../../_theme.module.scss'
import './Navigation.scss'

const { Sider } = Layout

export enum RoutesWithSidebar {
  SETTINGS = 'settings',
  REPORTS = 'reports',
}

interface NavigationSideBarProps {
  isAuthenticated?: boolean
  title?: string
  path: RoutesWithSidebar
  healthGorillaUserName: string | undefined
}

export default function NavigationSideBar({
  path,
  healthGorillaUserName,
}: NavigationSideBarProps) {
  const { totalUnreadSpravatoNotes } = useUnsignedSpravatoNotifications()
  const hasLabsReportReview = useReadyForReviewLabs().data
  const { advancedmd, enableNoteTemplates } = useFeatureFlags()
  const ReportsSidebarItems = useMemo(() => {
    const sideBarTabs: Option[] = [
      {
        title: 'REPORTS',
        className: 'ant-main-sidebar-menu-title',
        shouldDisable: true,
      },
      {
        icon: 'FileOutlined',
        link: `/${RoutesWithSidebar.REPORTS}/notes`,
        title: 'Notes',
        rightIcon: {
          icon: () => (
            <Badge
              data-testid={'notes-badge'}
              count={totalUnreadSpravatoNotes}
              style={{ backgroundColor: themeStyles.primary6 }}
            />
          ),
        },
      },
      {
        icon: 'CarryOutOutlined',
        link: `/${RoutesWithSidebar.REPORTS}/appointments`,
        title: 'Appointments',
      },
      {
        icon: 'UnorderedListOutlined',
        link: `/${RoutesWithSidebar.REPORTS}/surveys`,
        title: 'Surveys',
      },
    ]
    if (healthGorillaUserName) {
      sideBarTabs.push({
        icon: 'ExperimentOutlined',
        link: `/${RoutesWithSidebar.REPORTS}/labs`,
        title: 'Labs',
      })
    }
    sideBarTabs.push({
      icon: 'PieChartOutlined',
      link: 'https://analytics.osmind.org/',
      title: 'Analytics',
      isExternalLink: true,
      rightIcon: {
        icon: (className?: string) => (
          <Icon component={ExternalLink} className={className} />
        ),
        showOnHover: true,
      },
    })
    return sideBarTabs
  }, [healthGorillaUserName, hasLabsReportReview, totalUnreadSpravatoNotes])

  const SettingsSidebarItems = useMemo(() => {
    const billingSubmenu: Option[] = [
      {
        // for whatever reason, the first item in a submenu
        // won't be clickable (but the rest will!)
        // adding a dummy item to take one for the team
        className: 'hide-item',
      },
      {
        link: `/${RoutesWithSidebar.SETTINGS}/billing-templates`,
        title: 'Billing templates',
        key: 'billing-settings:billing-templates',
      },
      {
        link: `/${RoutesWithSidebar.SETTINGS}/billing-custom`,
        title: 'Custom billing codes',
        key: 'billing-settings:billing-custom',
      },
    ]

    billingSubmenu.push({
      link: `/${RoutesWithSidebar.SETTINGS}/payments`,
      title: 'Payment processing',
      key: 'billing-settings:payments',
    })

    if (advancedmd) {
      billingSubmenu.push({
        link: `/${RoutesWithSidebar.SETTINGS}/advanced-md`,
        title: 'AdvancedMD Integration',
        key: 'billing-settings:advanced-md',
      })
    }

    const schedulingSubmenu: Option[] = [
      {
        // for whatever reason, the first item in a submenu
        // won't be clickable (but the rest will!)
        // adding a dummy item to take one for the team
        className: 'hide-item',
      },
      {
        link: `/${RoutesWithSidebar.SETTINGS}/scheduling`,
        title: 'Connected accounts',
        key: 'scheduling-settings:connected-accounts',
      },
      {
        link: `/${RoutesWithSidebar.SETTINGS}/timezone`,
        title: 'Practice timezone',
        key: 'scheduling-settings:timezone',
      },
      {
        link: `/${RoutesWithSidebar.SETTINGS}/appointment-settings`,
        title: 'Appointment types',
        key: 'scheduling-settings:appointment-types',
      },
    ]

    const sideBarTabs: Option[] = [
      {
        title: 'MY SETTINGS',
        className: 'ant-main-sidebar-menu-title',
        shouldDisable: true,
      },
      {
        icon: 'IdcardOutlined',
        link: `/${RoutesWithSidebar.SETTINGS}/personal`,
        title: 'My account',
      },
      {
        icon: 'BookOutlined',
        link: `/${RoutesWithSidebar.SETTINGS}/availability`,
        title: 'My availability',
      },
      {
        icon: 'LockOutlined',
        link: `/${RoutesWithSidebar.SETTINGS}/password`,
        title: 'Change password',
      },
      {
        title: 'PRACTICE SETTINGS',
        className: 'ant-main-sidebar-menu-title',
        shouldDisable: true,
      },
      {
        icon: 'HomeOutlined',
        link: `/${RoutesWithSidebar.SETTINGS}/practice-settings`,
        title: 'Practice details',
      },
      {
        icon: 'LocationOutlined',
        link: `/${RoutesWithSidebar.SETTINGS}/location`,
        title: 'Locations',
      },
      {
        icon: 'CalendarOutlined',
        link: `/${RoutesWithSidebar.SETTINGS}/appointment-settings`,
        title: 'Calendar',
        subMenu: schedulingSubmenu,
      },
      {
        icon: 'TeamOutlined',
        link: `/${RoutesWithSidebar.SETTINGS}/team`,
        title: 'Team management',
      },
      ...(enableNoteTemplates
        ? [
            {
              icon: 'FileTextOutlined',
              link: getNoteTemplateSettingsUrl(),
              title: 'Note templates',
            },
          ]
        : []),
      {
        icon: 'DollarOutlined',
        title: 'Billing settings',
        key: 'billing-settings',
        subMenu: billingSubmenu,
      },
      {
        icon: 'UserAddOutlined',
        link: `/${RoutesWithSidebar.SETTINGS}/intake`,
        title: 'Custom forms',
      },
      {
        icon: 'MailOutlined',
        link: `/${RoutesWithSidebar.SETTINGS}/email-template`,
        title: 'Email template',
      },
      {
        icon: 'BellOutlined',
        link: `/${RoutesWithSidebar.SETTINGS}/email-notifications`,
        title: 'Notifications',
      },
      {
        icon: 'BarsOutlined',
        link: `/${RoutesWithSidebar.SETTINGS}/survey`,
        title: 'Surveys',
      },
    ]
    return sideBarTabs
  }, [advancedmd])

  const SidebarMenuItems = {
    [RoutesWithSidebar.SETTINGS]: SettingsSidebarItems,
    [RoutesWithSidebar.REPORTS]: ReportsSidebarItems,
  }

  const sideBarItems = SidebarMenuItems[path]

  return (
    sideBarItems && (
      <div className="settings-sidebar">
        <Sider trigger={null} id="ant-main-sidebar" theme="light" width={256}>
          <Menu
            key="settings-sidebar-collapsible"
            id="ant-main-sidebar-menu"
            mode="inline"
            options={sideBarItems}
          />
        </Sider>
      </div>
    )
  )
}
