import React, { useState } from 'react'

import { Button, Modal, ModalProps, Row, Typography } from 'antd'
import ReactQRCode from 'react-qr-code'

export interface QRCodeProps {
  value: string
  modalTrigger: React.ReactNode
  modalDetailsText?: string
  modalFooterText?: string
  modalProps?: ModalProps
  onModalOpen?(): void
}

const QRCode: React.FC<QRCodeProps> = ({
  value,
  modalTrigger,
  modalDetailsText = null,
  modalFooterText = null,
  modalProps,
  onModalOpen = null,
}) => {
  const [qrCodeModalVisible, setQrCodeModalVisible] = useState<boolean>(false)

  const showModal = () => {
    setQrCodeModalVisible(true)
    if (onModalOpen) onModalOpen()
  }

  const hideModal = () => {
    setQrCodeModalVisible(false)
  }

  return (
    <>
      <Button type="link" onClick={showModal} style={{ padding: '5px' }}>
        {modalTrigger}
      </Button>
      <Modal
        {...modalProps}
        visible={qrCodeModalVisible}
        onOk={hideModal}
        onCancel={hideModal}
        cancelButtonProps={{ hidden: true }}
      >
        <Row justify="center">
          <Typography.Text>{modalDetailsText}</Typography.Text>
        </Row>
        <br />
        <Row justify="center">
          <ReactQRCode value={value} />
        </Row>
        {modalFooterText && (
          <Row justify="center" style={{ margin: '10px auto' }}>
            <Typography.Text>{modalFooterText}</Typography.Text>
          </Row>
        )}
      </Modal>
    </>
  )
}

export default QRCode
