import * as React from 'react'

import GoogleCalendarIcon from './google_calendar_icon.svg'

const GoogleLogo = () => {
  return (
    <img src={GoogleCalendarIcon} style={{ borderRadius: 0, height: 36 }} />
  )
}

export default GoogleLogo
