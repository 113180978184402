import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { addDays } from 'date-fns'

import { getAppointments } from '../../api/api-lib-typed'
import { useFeatureFlags } from '../../libs/featureFlags'
import { Appointment, AppointmentStatus } from '../../shared-types'

export const getTotalNotifications = (
  canSeeAllPatients: boolean,
  appointments: Appointment<true>[]
) => {
  const pendingAppointments = appointments.filter(
    ({ status }) => status === 'PENDING'
  )

  if (!canSeeAllPatients) {
    return pendingAppointments.filter(
      ({ providerIsOnCareTeam }) => providerIsOnCareTeam
    ).length
  }
  return pendingAppointments.length
}

export const useSchedulingNotificationsRefetchInterval = (): number => {
  const { schedulingNotificationsRefetchInterval } = useFeatureFlags()

  // Provide a floor for this in case of a bad configuration.
  return Math.max(schedulingNotificationsRefetchInterval ?? 0, 5000)
}

type useSchedulingNotificationsProps = {
  patientId?: string
  appointmentStatuses?: AppointmentStatus[]
}
export const useSchedulingNotifications = ({
  appointmentStatuses,
  patientId,
}: useSchedulingNotificationsProps) => {
  const schedulingNotificationsRefetchInterval =
    useSchedulingNotificationsRefetchInterval()
  const {
    data: appointments,
    isLoading,
    isError,
  } = useQuery(
    ['appointmentList'],
    () => {
      return getAppointments<true>({
        startDate: new Date().toISOString(),
        endDate: addDays(new Date(), 120).toISOString(),
        includeDetails: false,
        statuses: appointmentStatuses,
        patientId,
      })
    },
    {
      refetchInterval: schedulingNotificationsRefetchInterval,
    }
  )

  const totalNotifications = useMemo(() => {
    if (isLoading || isError || !appointments) {
      return 0
    }
    return getTotalNotifications(
      appointments.canSeeAllPatients,
      appointments.appointments
    )
  }, [appointments, isLoading, isError])

  return {
    totalNotifications,
  }
}
