import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { loadPatientJournalNotes } from '../../api/patients'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'
import { JournalNote } from '../../shared-types'

export const usePatientJournalNotes = (
  patientId: string
): UseQueryResult<JournalNote[]> => {
  return useQuery<JournalNote[]>(
    [patientDataTypes.PatientJournalNotes, patientId],
    {
      queryFn: async () => {
        const data = await loadPatientJournalNotes(patientId)
        return data
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
