import React from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Col, Row, Typography } from 'antd'

import { TestId } from '../../shared-types'
import Box from './Box'

const { Title, Text, Link } = Typography

type ReportHeaderProps = React.PropsWithChildren & {
  title: string
  description: string
  link: {
    text: string
    url: string
  }
} & TestId

const ReportHeader: React.FC<ReportHeaderProps> = ({
  title,
  children,
  description,
  link,
  testId,
}) => {
  return (
    <Box testId={testId}>
      <Row className="header">
        <Col span={12} md={14}>
          <Title level={3} className="margin-bottom header-title">
            {title}
          </Title>
          <span className="small-text">
            <Text>{description}</Text>
            <Link
              className="support-link"
              target="_blank"
              href={link.url}
              rel="noreferrer"
            >
              <QuestionCircleOutlined /> {link.text}
            </Link>
          </span>
        </Col>
        <Col span={12} md={10}>
          {' '}
          {children}{' '}
        </Col>
      </Row>
    </Box>
  )
}

export default ReportHeader
