import { useMutation, useQueryClient } from '@tanstack/react-query'

import { UpdateNoteTemplateRequest, updateNoteTemplate } from '../../api'
import { getNoteTemplateQueryKey } from './useGetNoteTemplate'

export const useUpdateNoteTemplate = () => {
  const queryClient = useQueryClient()
  const {
    mutateAsync: update,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(
    async ({
      templateId,
      body,
    }: {
      templateId: string
      body: UpdateNoteTemplateRequest
    }) => await updateNoteTemplate(templateId, body),
    {
      onSuccess: (noteTemplate) => {
        queryClient.invalidateQueries(
          getNoteTemplateQueryKey(noteTemplate.uuid)
        )
      },
    }
  )

  return {
    updateNoteTemplate: update,
    isLoading,
    isSuccess,
    isError,
  }
}
