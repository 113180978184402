import { ReactElement } from 'react'

import Icon from '@ant-design/icons'
import cx from 'classnames'

import { Button } from '../../../../../../stories/BaseComponents'

import styles from './BlockHeader.module.scss'

export const BlockHeader = ({
  label,
  onActionClick,
  actionIcon,
  actionLabel,
  options,
  isSigned,
}: {
  label: string
  onActionClick?: ({ options }: { options?: Record<string, any> }) => void
  actionIcon?: ReactElement
  actionLabel?: ({
    options,
  }: {
    options?: Record<string, any>
  }) => string | ReactElement
  options?: Record<string, any>
  isSigned: boolean
}) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.label + ' block-header'}>{label}</h2>
      {!isSigned && (
        <div className={cx('block-buttons', styles.blockButtons)}>
          {onActionClick && actionLabel && (
            <Button
              icon={actionIcon ? <Icon component={() => actionIcon} /> : null}
              onClick={() => onActionClick({ options })}
            >
              {actionLabel({ options })}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
