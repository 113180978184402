import React, { useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import { cloneDeep } from 'lodash'

import SkeletonLoadingTransition from '../../../components/Animation/SkeletonLoadingTransition'
import { PatientInfoType } from '../../../hooks/usePatientProfile/shared-types'
import Fields, { FieldGroupProps } from '../../../stories/PatientProfile/Fields'
import {
  handlePatientInfoUpdate,
  populatePatientInfo,
} from './InsuranceClaim-helper'
import { patientInformationComponent } from './constants'

import styles from './InsuranceClaim.module.scss'

export interface PatientConditionRelationProps {
  testId?: string
  patientInfo: PatientInfoType
  setPatientInfo: Function
  disabled?: boolean
}

const Loading = () => (
  <div className={styles.skeletonContainer}>
    <Skeleton
      active
      title={{ width: 85 }}
      paragraph={{
        rows: 3,
        width: '100%',
        className: styles.skeletonParagraph,
      }}
    />
  </div>
)

const PatientInformation: React.FC<PatientConditionRelationProps> = ({
  testId,
  patientInfo,
  setPatientInfo,
  disabled = false,
}) => {
  const [patientCR, setPatientCR] = useState<FieldGroupProps[]>(
    patientInformationComponent
  )
  useEffect(() => {
    const itemsCopy = cloneDeep(patientInformationComponent)
    if (!patientInfo) return setPatientCR(itemsCopy)
    populatePatientInfo(itemsCopy, patientInfo)
    setPatientCR(itemsCopy)
  }, [patientInfo])

  const handleSave = (
    newValue: any,
    id: string,
    _groupName: string,
    _label?: string
  ) => {
    const newVal: any =
      typeof newValue === 'string' ? newValue.trim() : newValue
    const copyPatientInfo = cloneDeep(patientInfo)
    const shouldSave = handlePatientInfoUpdate(newVal, id, copyPatientInfo)
    if (shouldSave) {
      setPatientInfo(copyPatientInfo)
    }
  }

  return (
    <SkeletonLoadingTransition
      isLoading={false}
      skeletonComponent={<Loading />}
      loadedComponent={
        <Fields
          testId={testId}
          items={patientCR}
          compact={false}
          handleSave={handleSave}
          isClaim
          disableAll={disabled}
        />
      }
    />
  )
}

export default PatientInformation
