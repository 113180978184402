import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { IntakeForm, listIntakeForms } from '../../api/intakeForms'

export enum PatientIntakeFormQueryKeys {
  ALL_INTAKE_FORMS_FOR_PATIENT = 'ALL_INTAKE_FORMS_FOR_PATIENT',
  PATIENT_LATEST_COMPLETED_INTAKE = 'PATIENT_LATEST_COMPLETED_INTAKE',
}

export const useListPatientIntakeForms = (
  patientId: string
): UseQueryResult<IntakeForm[]> => {
  return useQuery<IntakeForm[]>(
    [PatientIntakeFormQueryKeys.ALL_INTAKE_FORMS_FOR_PATIENT, patientId],
    {
      queryFn: () => listIntakeForms(patientId),
      retry: false,
    }
  )
}
