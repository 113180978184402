import {
  InsurancePrimary,
  InsurancePrimaryHolder,
  InsuranceSecondary,
  InsuranceSecondaryHolder,
} from '../../hooks/usePatientProfile/shared-types'
import { FieldGroupProps, Item } from './Fields'

export enum InsuranceHolderRelationshipEnum {
  Self = 'Self',
  Child = 'Child',
  Spouse = 'Spouse',
  Parent = 'Parent',
  Grandparent = 'Grandparent',
  Sibling = 'Sibling',
  Partner = 'Partner',
  Other = 'Other',
}

export const InsuranceHolderRelationshipOptions = [
  {
    value: InsuranceHolderRelationshipEnum.Self,
    label: InsuranceHolderRelationshipEnum.Self,
  },
  {
    value: InsuranceHolderRelationshipEnum.Child,
    label: InsuranceHolderRelationshipEnum.Child,
  },
  {
    value: InsuranceHolderRelationshipEnum.Spouse,
    label: InsuranceHolderRelationshipEnum.Spouse,
  },
  {
    value: InsuranceHolderRelationshipEnum.Parent,
    label: InsuranceHolderRelationshipEnum.Parent,
  },
  {
    value: InsuranceHolderRelationshipEnum.Grandparent,
    label: InsuranceHolderRelationshipEnum.Grandparent,
  },
  {
    value: InsuranceHolderRelationshipEnum.Sibling,
    label: InsuranceHolderRelationshipEnum.Sibling,
  },
  {
    value: InsuranceHolderRelationshipEnum.Partner,
    label: InsuranceHolderRelationshipEnum.Partner,
  },
  {
    value: InsuranceHolderRelationshipEnum.Other,
    label: InsuranceHolderRelationshipEnum.Other,
  },
]

export const InsuranceSubscriberItems: Item[] = [
  {
    id: InsurancePrimaryHolder.primaryRelationship,
    label: 'Relationship to subscriber',
    placeHolder: 'Add',
    requireConfirmation: true,
    allowClear: true,
    scrollToField: true,
    value: undefined,
    type: 'select',
    options: InsuranceHolderRelationshipOptions,
  },
  {
    id: InsurancePrimaryHolder.primaryFirstName,
    label: 'First name',
    placeHolder: 'Add',
    requireConfirmation: true,
    scrollToField: true,
    value: '',
    type: 'text',
  },
  {
    id: InsurancePrimaryHolder.primaryLastName,
    label: 'Last name',
    placeHolder: 'Add',
    requireConfirmation: true,
    scrollToField: true,
    value: '',
    type: 'text',
  },
  {
    id: InsurancePrimaryHolder.dateOfBirth,
    label: 'Date of birth',
    placeHolder: 'Add',
    format: ['MM-dd-yyyy', 'MM/dd/yyyy', 'MMM dd yyyy'],
    scrollToField: true,
    requireConfirmation: true,
    value: null,
    type: 'dateInput',
  },
  {
    id: InsurancePrimaryHolder.legalSex,
    label: 'Legal sex per plan',
    placeHolder: 'Add',
    requireConfirmation: true,
    scrollToField: true,
    value: '',
    type: 'radio',
    options: [
      {
        value: 'F',
        label: 'F',
      },
      {
        value: 'M',
        label: 'M',
      },
      {
        value: 'X',
        label: 'X',
      },
      {
        value: 'Unknown',
        label: 'Unknown',
      },
    ],
  },
  {
    id: InsurancePrimaryHolder.email,
    label: 'Email',
    placeHolder: 'Add',
    requireConfirmation: true,
    scrollToField: true,
    value: '',
    type: 'text',
  },
  {
    id: InsurancePrimaryHolder.phoneNumber,
    label: 'Phone',
    placeHolder: 'Add',
    requireConfirmation: true,
    scrollToField: true,
    value: '',
    type: 'text',
  },
  {
    id: InsurancePrimaryHolder.sameAddressAsPatient,
    label: 'Same address as patient?',
    placeHolder: 'Add',
    requireConfirmation: true,
    scrollToField: true,
    value: 'Yes',
    type: 'radio',
    options: [
      {
        value: 'Yes',
        label: 'Yes',
      },
      {
        value: 'No',
        label: 'No',
      },
    ],
  },
  {
    id: InsurancePrimaryHolder.address,
    label: 'Address',
    placeHolder: 'Add',
    requireConfirmation: true,
    scrollToField: true,
    value: '',
    type: 'googleAddress',
  },
  {
    id: InsurancePrimaryHolder.unit,
    label: 'Unit',
    placeHolder: 'Add',
    requireConfirmation: true,
    scrollToField: true,
    value: '',
    type: 'text',
  },
]

export const primaryInsuranceComponents: FieldGroupProps[] = [
  {
    groupName: 'Insurance info',
    items: [
      {
        id: InsurancePrimary.insuranceName,
        label: 'Company name',
        placeHolder: 'Add',
        requireConfirmation: true,
        allowClear: true,
        scrollToField: true,
        value: undefined,
        type: 'select',
        options: [],
      },
      {
        id: InsurancePrimary.insuranceMemberId,
        label: 'Member ID',
        placeHolder: 'Add',
        requireConfirmation: true,
        scrollToField: true,
        value: '',
        type: 'text',
      },
      {
        id: InsurancePrimary.insuranceGroupId,
        label: 'Group ID',
        placeHolder: 'Add',
        requireConfirmation: true,
        scrollToField: true,
        value: '',
        type: 'text',
      },
      {
        id: InsurancePrimary.informationRelease,
        label: 'Information release',
        placeHolder: 'Add',
        requireConfirmation: true,
        scrollToField: true,
        value: null,
        type: 'radio',
        options: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          },
        ],
      },
      {
        id: InsurancePrimary.paymentAutorization,
        label: 'Payment authorization',
        placeHolder: 'Add',
        requireConfirmation: true,
        scrollToField: true,
        value: null,
        type: 'radio',
        options: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          },
        ],
      },
    ],
  },
  {
    groupName: 'Insurance subscriber info',
    items: InsuranceSubscriberItems,
  },
]

export const secondaryInsuranceComponents: FieldGroupProps[] = [
  {
    groupName: 'Secondary insurance',
    items: [
      {
        id: InsuranceSecondary.secondaryInsuranceName,
        label: 'Company name',
        placeHolder: 'Add',
        requireConfirmation: true,
        allowClear: true,
        scrollToField: true,
        value: undefined,
        type: 'select',
        options: [],
      },
      {
        id: InsuranceSecondary.secondaryInsuranceMemberId,
        label: 'Member ID',
        placeHolder: 'Add',
        requireConfirmation: true,
        scrollToField: true,
        value: '',
        type: 'text',
      },
      {
        id: InsuranceSecondary.secondaryInsuranceGroupId,
        label: 'Group ID',
        placeHolder: 'Add',
        requireConfirmation: true,
        scrollToField: true,
        value: '',
        type: 'text',
      },
      {
        id: InsuranceSecondary.secondaryInformationRelease,
        label: 'Information release',
        placeHolder: 'Add',
        requireConfirmation: true,
        scrollToField: true,
        value: null,
        type: 'radio',
        options: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          },
        ],
      },
      {
        id: InsuranceSecondary.secondaryPaymentAuthorization,
        label: 'Payment authorization',
        placeHolder: 'Add',
        requireConfirmation: true,
        scrollToField: true,
        value: null,
        type: 'radio',
        options: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          },
        ],
      },
      {
        id: InsuranceSecondaryHolder.secondaryRelationship,
        label: 'Relationship to subscriber',
        placeHolder: 'Add',
        requireConfirmation: true,
        allowClear: true,
        scrollToField: true,
        value: null,
        type: 'select',
        options: InsuranceHolderRelationshipOptions,
      },
    ],
  },
  {
    groupName: 'Secondary insurance subscriber info',
    items: [
      {
        id: InsuranceSecondaryHolder.secondaryFirstName,
        label: 'First name',
        placeHolder: 'Add',
        requireConfirmation: true,
        scrollToField: true,
        value: '',
        type: 'text',
      },
      {
        id: InsuranceSecondaryHolder.secondaryLastName,
        label: 'Last name',
        placeHolder: 'Add',
        requireConfirmation: true,
        scrollToField: true,
        value: '',
        type: 'text',
      },
      {
        id: InsuranceSecondaryHolder.secondaryDateOfBirth,
        label: 'Date of birth',
        placeHolder: 'Add',
        requireConfirmation: true,
        scrollToField: true,
        value: '',
        type: 'dateInput',
      },
    ],
  },
]

export const insuranceEmptyObject = {
  patientId: '',
  patientInsuranceId: null,
  isPrimaryInsurance: false,
  insuranceName: null,
  insuranceMemberId: null,
  insuranceGroupId: null,
  insuranceCompany: null,
  insuranceCompanyAddress1: null,
  insuranceCompanyAddress2: null,
  insuranceCompanyCity: null,
  insuranceCompanyState: null,
  insuranceCompanyZipCode: null,
  insuranceCardBack: null,
  insuranceCardFront: null,
  isPrimaryInsuranceHolder: null,
  hasInsuranceCardSaved: null,
  deletedAt: null,
  isPaymentAuthorized: null,
  isInformationReleaseAuthorized: null,
}

export const insuranceHolderEmptyObject = {
  address1: null,
  address2: null,
  zipCode: null,
  city: null,
  state: null,
  country: null,
  dateOfBirth: null,
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  relationship: null,
  legalSex: null,
  deletedAt: null,
  hasSameAddressAsPatient: null,
}
