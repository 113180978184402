import React, { MouseEvent, useCallback, useMemo } from 'react'

import { Modal as AntDModal, ModalProps as AntDModalProps } from 'antd'

import './Modal.scss'

export type ModalProps = AntDModalProps & {
  hasOkButton?: boolean
  hasCloseButton?: boolean
  isOkayDisabled?: boolean
}

export const toggleConfirmModal = AntDModal.confirm

const hidden = {
  style: {
    display: 'none',
  },
} as const

const Modal: React.FC<ModalProps> = ({
  zIndex,
  visible,
  confirmLoading,
  title,
  onOk,
  onCancel,
  closable,
  children,
  className,
  isOkayDisabled = false,
  hasOkButton = true,
  hasCloseButton = true,
  okButtonProps,
  cancelButtonProps,
  ...props
}) => {
  // TODO: This still needs to be updated to not derive
  // variables from passed props. We should restrict
  // the amount of passed props to the modal itself,
  // such that we know the exact props being passed
  // to both the cancel and ok buttons. We would
  // then be able to directly pass those props to the
  // AntD modal, and can remove this logic.
  const usedOkButtonProps = useMemo(() => {
    if (!hasOkButton) {
      return hidden
    }
    return { ...okButtonProps, disabled: isOkayDisabled }
  }, [okButtonProps, isOkayDisabled, hasOkButton])

  const usedCancelButtonProps = useMemo(() => {
    if (!hasCloseButton) {
      return hidden
    }
    return { ...cancelButtonProps }
  }, [cancelButtonProps, hasCloseButton])

  const handleCancel = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (onCancel) {
        onCancel(e)
      }
    },
    [onCancel]
  )

  const handleOk = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (onOk) {
        onOk(e)
      }
    },
    [onOk]
  )

  return (
    <AntDModal
      className={className}
      zIndex={zIndex ?? 1000}
      closable={closable}
      title={title}
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      cancelButtonProps={usedCancelButtonProps}
      okButtonProps={usedOkButtonProps}
      onCancel={handleCancel}
      {...props}
    >
      {children}
    </AntDModal>
  )
}

export default Modal
