import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const EarsNoseThroatCodes: any = [
  {
    232208008: {
      title: 'Ear, Nose, or Throat Disorder',
      snomedcode: 232208008,
      description: 'Ear, nose and throat disorder (disorder)',
      icdcode: 'R69',
      icddescription: 'Illness, unspecified',
    },
  },
  {
    61582004: {
      title: 'een.Allergic Rhinitis',
      description: 'Allergic rhinitis (disorder)',
      snomedcode: 61582004,
      icdcode: 'J309',
      icddescription: 'Allergic rhinitis, unspecified',
    },
  },
  {
    15188001: {
      title: 'een.Hearing Loss',
      description: 'Hearing loss (disorder)',
      snomedcode: 15188001,
      icdcode: 'H9190',
      icddescription: 'Unspecified hearing loss, unspecified ear',
    },
  },
  {
    232209000: {
      title: 'een.Nasal obstruction',
      description: 'Nasal obstruction (disorder)',
      snomedcode: 232209000,
      icdcode: 'J3489',
      icddescription: 'Other specified disorders of nose and nasal sinuses',
    },
  },
]

export const EarsNoseThroatDropDownValues = constructPatientDemographicObject(
  EarsNoseThroatCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
