import React from 'react'

import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const ErxOutlinedSVG = () => (
  <svg
    viewBox="0 0 22 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.39209 9.99976H5.7793V3.65845H8.53467C10.0947 3.65845 10.9341 4.44067 10.9341 5.75024V5.75903C10.9341 6.53247 10.543 7.23999 9.85742 7.54321L11.123 9.99976H9.3125L8.22705 7.78052H7.39209V9.99976ZM7.39209 4.87573V6.67749H8.35449C8.93457 6.67749 9.29053 6.33032 9.29053 5.78101V5.77222C9.29053 5.23608 8.92578 4.87573 8.3457 4.87573H7.39209Z"
    />
    <path d="M13.3555 9.99976H11.7383L13.2017 7.59595L11.7295 5.10864H13.5005L14.2168 6.63354H14.3003L15.021 5.10864H16.7041L15.2275 7.53003L16.7085 9.99976H15.0122L14.2256 8.50122H14.1377L13.3555 9.99976Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 0.281128H20.75C21.1648 0.281128 21.5 0.616284 21.5 1.03113V12.6561C21.5 13.071 21.1648 13.4061 20.75 13.4061H11.8438V16.0311H15.875C16.0813 16.0311 16.25 16.1999 16.25 16.4061V17.5311C16.25 17.6343 16.1656 17.7186 16.0625 17.7186H5.9375C5.83437 17.7186 5.75 17.6343 5.75 17.5311V16.4061C5.75 16.1999 5.91875 16.0311 6.125 16.0311H10.1562V13.4061H1.25C0.835156 13.4061 0.5 13.071 0.5 12.6561V1.03113C0.5 0.616284 0.835156 0.281128 1.25 0.281128ZM2.1875 11.7186H19.8125V1.96863H2.1875V11.7186Z"
    />
    <path d="M5.7793 9.99976H7.39209V7.78052H8.22705L9.3125 9.99976H11.123L9.85742 7.54321C10.543 7.23999 10.9341 6.53247 10.9341 5.75903V5.75024C10.9341 4.44067 10.0947 3.65845 8.53467 3.65845H5.7793V9.99976ZM7.39209 6.67749V4.87573H8.3457C8.92578 4.87573 9.29053 5.23608 9.29053 5.77222V5.78101C9.29053 6.33032 8.93457 6.67749 8.35449 6.67749H7.39209Z" />
    <path d="M11.7383 9.99976H13.3555L14.1377 8.50122H14.2256L15.0122 9.99976H16.7085L15.2275 7.53003L16.7041 5.10864H15.021L14.3003 6.63354H14.2168L13.5005 5.10864H11.7295L13.2017 7.59595L11.7383 9.99976Z" />
  </svg>
)

export default function ERxOutlined(props: Partial<CustomIconComponentProps>) {
  return <Icon component={ErxOutlinedSVG} {...props} />
}
