import React from 'react'

import { Col, Form } from 'react-bootstrap'

import { AutoSizingTextArea } from '../../../../stories/BaseComponents/TextAreaInput'
import { DefaultEditFieldProps } from './types'

export default function DefaultEditField(props: DefaultEditFieldProps) {
  const { templateField, handleFieldChange, styling, value } = props

  const {
    columnSizes,
    fieldType = 'text',
    id,
    label,
    placeholder = '',
    size,
  } = templateField

  const {
    editColBsPrefix = '',
    editColClassName = '',
    editColStyle = {},
    editValueBsPrefix = '',
    editValueClassName = '',
    editValueStyle = {},
    editLabelBsPrefix = '',
    editLabelClassName = '',
    editLabelStyle = {},
  } = styling

  const textAreaProps = {
    ...(fieldType === 'textarea' && {
      rows: size,
      as: AutoSizingTextArea,
      minRows: 1,
    }),
  }

  return (
    <Form.Group
      as={Col}
      bsPrefix={editColBsPrefix}
      className={editColClassName}
      controlId={id}
      style={editColStyle}
      {...columnSizes}
    >
      <Form.Label
        bsPrefix={editLabelBsPrefix}
        className={editLabelClassName}
        style={editLabelStyle}
      >
        {label}
      </Form.Label>
      <Form.Control
        bsPrefix={editValueBsPrefix}
        className={editValueClassName}
        onChange={handleFieldChange}
        placeholder={placeholder}
        style={editValueStyle}
        type={fieldType}
        value={value}
        {...textAreaProps}
      />
    </Form.Group>
  )
}
