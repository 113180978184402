import React from 'react'

import { ConfigProvider } from 'antd'
import { ConfigProviderProps } from 'antd/lib/config-provider'

const AntDConfigProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const GlobalStyleConfigs: ConfigProviderProps = {
    /* Falls back to document.body (default behavior) for elements that don't have an
     HTMLElement as a trigger (like the modal) */
    getPopupContainer: (trigger) => trigger?.parentElement || document.body,
  }

  return <ConfigProvider {...GlobalStyleConfigs}>{children}</ConfigProvider>
}

export default AntDConfigProvider
