import React from 'react'

interface SVGProps {
  width: number | string
  height: number | string
  color: string
}

const UndoIcon: React.FC<SVGProps> = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 17"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88017 0.238745C5.80206 0.160645 5.67542 0.160654 5.59732 0.238764L0.523352 5.31344C0.445307 5.39149 0.445253 5.51802 0.523231 5.59614L5.59693 10.6792C5.67497 10.7574 5.8016 10.7575 5.87978 10.6795L6.79279 9.76817C6.87096 9.69014 6.87108 9.5635 6.79305 9.48532L3.61509 6.30149L15.8256 6.29795C16.7879 6.29809 17.5784 7.08867 17.5784 8.05092V12.7074C17.5784 13.6698 16.7877 14.4604 15.8254 14.4604H7.92127C7.81081 14.4604 7.72127 14.55 7.72127 14.6604V15.9504C7.72127 16.0609 7.81081 16.1504 7.92127 16.1504H15.8254C17.7211 16.1504 19.2684 14.6031 19.2684 12.7074V8.05092C19.2684 6.15523 17.7211 4.60795 15.8254 4.60795L3.61506 4.61149L6.79242 1.43369C6.87052 1.35558 6.87051 1.22895 6.7924 1.15085L5.88017 0.238745Z"
        fill={color}
      />
    </svg>
  )
}

export default UndoIcon
