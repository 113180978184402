import { Typography } from 'antd'

import { usePatientDemographics } from '../../../hooks/usePatientInfo'
import { Skeleton } from '../../BaseComponents'
import LabNewOrderMissingFieldsForm from './LabNewOrderMissingFieldsForm'

import styles from './LabsNewOrderMissingFields.module.scss'

const { Text } = Typography

export interface LabsNewOrderMissingFieldsProps {
  patientId: string
  isLoading: boolean
  savePatientInfo: (newPatientInfo: any) => void
}

export default function LabsNewOrderMissingFields({
  isLoading,
  patientId,
  savePatientInfo,
}: LabsNewOrderMissingFieldsProps) {
  const {
    isLoading: patientDataIsLoading,
    isError: patientDataIsError,
    data: patientData,
  } = usePatientDemographics(patientId ?? '')

  function missingFieldsFormGenerator() {
    return (
      <LabNewOrderMissingFieldsForm
        isLoading={isLoading}
        patientData={patientData}
        savePatientInfo={savePatientInfo}
      />
    )
  }
  if (patientDataIsError) {
    //TODO We need to return to a previus screen or show and error
    return <div />
  }
  return (
    <div className={styles.missingValuesContainer}>
      <Text className={styles.missingValuesDescription}>
        Before we can order a new lab, we need some information from you. Please
        fill out the required fields below.
      </Text>
      {patientDataIsLoading ? <Skeleton /> : missingFieldsFormGenerator()}
    </div>
  )
}
