import 'moment-timezone'
import { Button, Col, Form, Table } from 'react-bootstrap'
import 'react-dates/initialize'

import { generateSpravatoClassName } from './Spravato'

import 'react-dates/lib/css/_datepicker.css'

interface PatientSedationProps {
  disabled: boolean
  fields: any
  detail?: boolean
  eventType: string
  entriesType: string
  showMeds: () => React.ReactElement | undefined
  saveMeds: () => void
  handleFieldChange: (obj: any) => void
}

export default function PatientSedation(props: PatientSedationProps) {
  const areThereEntries =
    props.fields[props.entriesType].length !== 0 || !props.detail
  let sedationOrDissociation = null
  let medicationType = ''
  if (props.eventType === 'SpravatoSedation') {
    sedationOrDissociation = 'sedation'
    medicationType = 'SavedAdditionalMedicationsSedation'
  } else if (props.eventType === 'SpravatoDissociation') {
    sedationOrDissociation = 'dissociation'
    medicationType = 'SavedAdditionalMedicationsDissociation'
  }

  function handleOnChange(eventType: string, value: any) {
    props.fields[eventType] = value
    props.handleFieldChange({ ...props.fields })
  }
  return (
    <>
      <Form.Row style={{ width: 'fit-content' }}>
        <Form.Group
          className={
            props.fields[props.eventType] === null
              ? generateSpravatoClassName()
              : ''
          }
          as={Col}
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          controlId={props.eventType}
        >
          {sedationOrDissociation && (
            <Form.Label className="font-weight-bold">
              Did the patient experience {sedationOrDissociation}?
            </Form.Label>
          )}
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields[props.eventType])}
            style={{ marginLeft: '10px' }}
            value="true"
            id={`${sedationOrDissociation}Yes`}
            disabled={props.disabled}
            onChange={(event) =>
              handleOnChange(props.eventType, event.currentTarget.value)
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields[props.eventType]) === false}
            style={{ marginLeft: '10px' }}
            value="false"
            id={`${sedationOrDissociation}No`}
            disabled={props.disabled}
            onChange={(event) =>
              handleOnChange(props.eventType, event.currentTarget.value)
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      {JSON.parse(props.fields[props.eventType]) && (
        <>
          <div style={{ marginLeft: '20px' }}>
            <Form.Row>
              <Form.Group as={Col} xs={12} sm={12} lg={12} xl={12}>
                <Form.Label className="font-weight-bold">
                  Onset of symptoms from start of administration?
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row
              className={
                props.fields[`${props.eventType}Onset`] === ''
                  ? generateSpravatoClassName()
                  : ''
              }
            >
              <Form.Group
                as={Col}
                xs={12}
                sm={6}
                lg={4}
                xl={2}
                controlId={`${props.eventType}Onset`}
              >
                <Form.Check
                  bsPrefix="form-check"
                  checked={
                    props.fields[`${props.eventType}Onset`] === '1-29 min'
                  }
                  value="1-29 min"
                  id={`${sedationOrDissociation}1-29`}
                  disabled={props.disabled}
                  onChange={(event) =>
                    handleOnChange(
                      `${props.eventType}Onset`,
                      event.currentTarget.value
                    )
                  }
                  type="radio"
                  label="1-29 min"
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} lg={4} xl={2}>
                <Form.Check
                  bsPrefix="form-check"
                  checked={
                    props.fields[`${props.eventType}Onset`] === '30-59 min'
                  }
                  value="30-59 min"
                  id={`${sedationOrDissociation}30-59`}
                  disabled={props.disabled}
                  onChange={(event) =>
                    handleOnChange(
                      `${props.eventType}Onset`,
                      event.currentTarget.value
                    )
                  }
                  type="radio"
                  label="30-59 min"
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} lg={4} xl={2}>
                <Form.Check
                  bsPrefix="form-check"
                  checked={
                    props.fields[`${props.eventType}Onset`] === '60-89 min'
                  }
                  value="60-89 min"
                  id={`${sedationOrDissociation}60-89`}
                  disabled={props.disabled}
                  onChange={(event) =>
                    handleOnChange(
                      `${props.eventType}Onset`,
                      event.currentTarget.value
                    )
                  }
                  type="radio"
                  label="60-89 min"
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} lg={4} xl={2}>
                <Form.Check
                  bsPrefix="form-check"
                  checked={
                    props.fields[`${props.eventType}Onset`] === '90-120 min'
                  }
                  value="90-120 min"
                  id={`${sedationOrDissociation}90-120`}
                  disabled={props.disabled}
                  onChange={(event) =>
                    handleOnChange(
                      `${props.eventType}Onset`,
                      event.currentTarget.value
                    )
                  }
                  type="radio"
                  label="90-120 min"
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} lg={4} xl={2}>
                <Form.Check
                  bsPrefix="form-check"
                  checked={
                    props.fields[`${props.eventType}Onset`] === '>120 min'
                  }
                  value=">120 min"
                  id={`${sedationOrDissociation}>120`}
                  disabled={props.disabled}
                  onChange={(event) =>
                    handleOnChange(
                      `${props.eventType}Onset`,
                      event.currentTarget.value
                    )
                  }
                  type="radio"
                  label=">120 min"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row style={{ marginTop: '10px' }}>
              <Form.Group
                as={Col}
                xs={12}
                sm={12}
                lg={12}
                xl={12}
                controlId={`${props.eventType}Resolution`}
              >
                <span
                  className={
                    props.fields[`${props.eventType}Resolution`] === null
                      ? 'spravato-incomplete-adjusted '
                      : ''
                  }
                >
                  <Form.Label className="font-weight-bold">
                    Resolution of symptoms within 2 hours?
                  </Form.Label>
                  <Form.Check
                    bsPrefix="form-check"
                    checked={JSON.parse(
                      props.fields[`${props.eventType}Resolution`]
                    )}
                    style={{ marginLeft: '10px' }}
                    value="true"
                    id={`${sedationOrDissociation}ResolutionYes`}
                    disabled={props.disabled}
                    onChange={(event) =>
                      handleOnChange(
                        `${props.eventType}Resolution`,
                        event.currentTarget.value
                      )
                    }
                    type="radio"
                    label="Yes"
                    inline
                  />
                  <Form.Check
                    bsPrefix="form-check"
                    checked={
                      JSON.parse(
                        props.fields[`${props.eventType}Resolution`]
                      ) === false
                    }
                    style={{ marginLeft: '10px' }}
                    value="false"
                    id={`${sedationOrDissociation}ResolutionNo`}
                    disabled={props.disabled}
                    onChange={(event) =>
                      handleOnChange(
                        `${props.eventType}Resolution`,
                        event.currentTarget.value
                      )
                    }
                    type="radio"
                    label="No"
                    inline
                  />
                </span>
              </Form.Group>
            </Form.Row>
            <Form.Row style={{ marginTop: '10px' }}>
              <Form.Group
                as={Col}
                xs={12}
                sm={12}
                lg={12}
                xl={12}
                controlId={`${props.eventType}ResolutionTime`}
              >
                <Form.Label className="font-weight-bold">
                  Specify total time to resolution (minutes):
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={6} sm={6} md={6} lg={6} xl={6}>
                <Form.Control
                  bsPrefix={
                    props.fields[`${props.eventType}ResolutionTime`] === ''
                      ? generateSpravatoClassName(true)
                      : 'form-input'
                  }
                  type="number"
                  onWheel={(event: React.WheelEvent<HTMLInputElement>) =>
                    event.currentTarget.blur()
                  }
                  value={props.fields[`${props.eventType}ResolutionTime`]}
                  disabled={props.disabled}
                  onChange={(event) =>
                    handleOnChange(
                      `${props.eventType}ResolutionTime`,
                      event.currentTarget.value
                    )
                  }
                />
              </Form.Group>
            </Form.Row>
            <Form.Row style={{ width: 'fit-content' }}>
              <Form.Group
                className={
                  props.fields[`${props.eventType}Medication`] === null
                    ? generateSpravatoClassName()
                    : ''
                }
                as={Col}
                xs={12}
                sm={12}
                lg={12}
                xl={12}
                controlId={`${props.eventType}Medication`}
              >
                {sedationOrDissociation && (
                  <Form.Label className="font-weight-bold">
                    Medication(s) given for {sedationOrDissociation}?
                  </Form.Label>
                )}
                <Form.Check
                  bsPrefix="form-check"
                  checked={JSON.parse(
                    props.fields[`${props.eventType}Medication`]
                  )}
                  style={{ marginLeft: '10px' }}
                  value="true"
                  id={`${sedationOrDissociation}MedicationYes`}
                  disabled={props.disabled}
                  onChange={(event) => {
                    handleOnChange(
                      `${props.eventType}Medication`,
                      event.currentTarget.value
                    )
                    !props.fields[medicationType]?.length && props.saveMeds()
                  }}
                  type="radio"
                  label="Yes"
                  inline
                />
                <Form.Check
                  bsPrefix="form-check"
                  checked={
                    JSON.parse(props.fields[`${props.eventType}Medication`]) ===
                    false
                  }
                  style={{ marginLeft: '10px' }}
                  value="false"
                  id={`${sedationOrDissociation}MedicationNo`}
                  disabled={props.disabled}
                  onChange={(event) =>
                    handleOnChange(
                      `${props.eventType}Medication`,
                      event.currentTarget.value
                    )
                  }
                  type="radio"
                  label="No"
                  inline
                />
              </Form.Group>
            </Form.Row>
            {JSON.parse(props.fields[`${props.eventType}Medication`]) && (
              <>
                <Table responsive>
                  {areThereEntries && (
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            minWidth: '136px',
                          }}
                        >
                          Medication Name
                        </th>
                        <th
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            minWidth: '96px',
                          }}
                        >
                          Dose
                        </th>
                        <th
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            minWidth: '96px',
                          }}
                        >
                          Time
                        </th>
                        <th
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            minWidth: '150px',
                          }}
                        >
                          Comments
                        </th>
                        <th
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            minWidth: '140px',
                          }}
                        >
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>{props.showMeds()}</tbody>
                </Table>
                {!props.disabled && (
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      style={{ textAlign: 'center', marginLeft: 'auto' }}
                    >
                      <Button
                        bsPrefix="plain-button center info button-label"
                        onClick={() => {
                          props.saveMeds()
                        }}
                      >
                        + Add Medication
                      </Button>
                    </Form.Group>
                  </Form.Row>
                )}
              </>
            )}
          </div>
        </>
      )}
      {JSON.parse(props.fields[props.eventType]) && <hr />}
    </>
  )
}
