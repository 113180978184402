import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const CancerCodes: any = [
  {
    '363346000': {
      title: 'Cancer',
      description: 'Malignant neoplastic disease (disorder)',
      snomedcode: 363346000,
      icdcode: 'C801',
      icddescription: 'Malignant (primary) neoplasm, unspecified',
    },
  },
  {
    '363352004': {
      title: 'can.Anal Cancer',
      description: 'Malignant tumor of anal canal (disorder)',
      snomedcode: 363352004,
      icdcode: 'C211',
      icddescription: 'Malignant neoplasm of anal canal',
    },
  },
  {
    '93763008': {
      title: 'can.Bile Duct Cancer (Including Cholangiocarcinoma)',
      description: 'Primary malignant neoplasm of common bile duct (disorder)',
      snomedcode: 93763008,
      icdcode: 'R69',
      icddescription: 'Illness, unspecified',
    },
  },
  {
    '399326009': {
      title: 'can.Bladder Cancer',
      description: 'Malignant tumor of urinary bladder (disorder)',
      snomedcode: 399326009,
      icdcode: 'C679',
      icddescription: 'Malignant neoplasm of bladder, unspecified',
    },
  },
  {
    '428281000': {
      title: 'can.Bone Cancer',
      description: 'Malignant neoplasm of bone (disorder)',
      snomedcode: 428281000,
      icdcode: 'C419',
      icddescription:
        'Malignant neoplasm of bone and articular cartilage, unspecified',
    },
  },
  {
    '428061005': {
      title: 'can.Brain Cancer',
      description: 'Malignant neoplasm of brain (disorder)',
      snomedcode: 428061005,
      icdcode: 'C719',
      icddescription: 'Malignant neoplasm of brain, unspecified',
    },
  },
  {
    '254837009': {
      title: 'can.Breast Cancer',
      description: 'Malignant neoplasm of breast (disorder)',
      snomedcode: 254837009,
      icdcode: 'C50919',
      icddescription:
        'Malignant neoplasm of unspecified site of unspecified female breast',
    },
  },
  {
    '363354003': {
      title: 'can.Cervical Cancer',
      description: 'Malignant tumor of cervix (disorder)',
      snomedcode: 363354003,
      icdcode: 'C539',
      icddescription: 'Malignant neoplasm of cervix uteri, unspecified',
    },
  },
  {
    '363406005': {
      title: 'can.Colon Cancer (Large Bowel)',
      description: 'Malignant neoplasm of colon (disorder)',
      snomedcode: 363406005,
      icdcode: 'C189',
      icddescription: 'Malignant neoplasm of colon, unspecified',
    },
  },
  {
    '363461003': {
      title: 'can.Eye Cancer',
      description: 'Malignant tumor of eye (disorder)',
      snomedcode: 363461003,
      icdcode: 'C6990',
      icddescription:
        'Malignant neoplasm of unspecified site of unspecified eye',
    },
  },
  {
    '363353009': {
      title: 'can.Gallbladder Cancer',
      description: 'Malignant tumor of gallbladder (disorder)',
      snomedcode: 363353009,
      icdcode: 'C23',
      icddescription: 'Malignant neoplasm of gallbladder',
    },
  },
  {
    '363508008': {
      title: 'can.Intestinal Cancer (Small Bowel)',
      description: 'Malignant tumor of intestine (disorder)',
      snomedcode: 363508008,
      icdcode: 'C260',
      icddescription:
        'Malignant neoplasm of intestinal tract, part unspecified',
    },
  },
  {
    '363518003': {
      title: 'can.Kidney Cancer',
      description: 'Malignant tumor of kidney (disorder)',
      snomedcode: 363518003,
      icdcode: 'C649',
      icddescription:
        'Malignant neoplasm of unspecified kidney, except renal pelvis',
    },
  },
  {
    '371995001': {
      title: 'can.Laryngeal Cancer (Throat Cancer)',
      description: 'Primary malignant neoplasm of larynx (disorder)',
      snomedcode: 371995001,
      icdcode: 'C329',
      icddescription: 'Malignant neoplasm of larynx, unspecified',
    },
  },
  {
    '93143009': {
      title: 'can.Leukemia',
      description: 'Leukemia, disease (disorder)',
      snomedcode: 93143009,
      icdcode: 'C9590',
      icddescription: 'Leukemia, unspecified not having achieved remission',
    },
  },
  {
    '93870000': {
      title: 'can.Liver Cancer',
      description: 'Malignant neoplasm of liver (disorder)',
      snomedcode: 93870000,
      icdcode: 'C229',
      icddescription:
        'Malignant neoplasm of liver, not specified as primary or secondary',
    },
  },
  {
    '93880001': {
      title: 'can.Lung Cancer',
      description: 'Primary malignant neoplasm of lung (disorder)',
      snomedcode: 93880001,
      icdcode: 'C3490',
      icddescription:
        'Malignant neoplasm of unspecified part of unspecified bronchus or lung',
    },
  },
  {
    '118600007': {
      title: 'can.Lymphoma',
      description: 'Malignant lymphoma (disorder)',
      snomedcode: 118600007,
      icdcode: 'R69',
      icddescription: 'Illness, unspecified',
    },
  },
  {
    '363505006': {
      title: 'can.Mouth Cancer',
      description: 'Malignant tumor of oral cavity (disorder)',
      snomedcode: 363505006,
      icdcode: 'C069',
      icddescription: 'Malignant neoplasm of mouth, unspecified',
    },
  },
  {
    '187692001': {
      title: 'can.Nasopharyngeal Cancer',
      description: 'Malignant tumor of nasopharynx (disorder)',
      snomedcode: 187692001,
      icdcode: 'C119',
      icddescription: 'Malignant neoplasm of nasopharynx, unspecified',
    },
  },
  {
    '363443007': {
      title: 'can.Ovarian Cancer',
      description: 'Malignant tumor of ovary (disorder)',
      snomedcode: 363443007,
      icdcode: 'C569',
      icddescription: 'Malignant neoplasm of unspecified ovary',
    },
  },
  {
    '363418001': {
      title: 'can.Pancreatic Cancer',
      description: 'Malignant tumor of pancreas (disorder)',
      snomedcode: 363418001,
      icdcode: 'C259',
      icddescription: 'Malignant neoplasm of pancreas, unspecified',
    },
  },
  {
    '363516004': {
      title: 'can.Penile Cancer',
      description: 'Malignant tumor of penis (disorder)',
      snomedcode: 363516004,
      icdcode: 'C609',
      icddescription: 'Malignant neoplasm of penis, unspecified',
    },
  },
  {
    '399068003': {
      title: 'can.Prostate Cancer',
      description: 'Malignant tumor of prostate (disorder)',
      snomedcode: 399068003,
      icdcode: 'C61',
      icddescription: 'Malignant neoplasm of prostate',
    },
  },
  {
    '363351006': {
      title: 'can.Rectal Cancer',
      description: 'Malignant tumor of rectum (disorder)',
      snomedcode: 363351006,
      icdcode: 'C20',
      icddescription: 'Malignant neoplasm of rectum',
    },
  },
  {
    '363349007': {
      title: 'can.Stomach Cancer',
      description: 'Malignant tumor of stomach (disorder)',
      snomedcode: 363349007,
      icdcode: 'C169',
      icddescription: 'Malignant neoplasm of stomach, unspecified',
    },
  },
  {
    '363449006': {
      title: 'can.Testicular Cancer',
      description: 'Malignant tumor of testis (disorder)',
      snomedcode: 363449006,
      icdcode: 'C6290',
      icddescription:
        'Malignant neoplasm of unspecified testis, unspecified whether descended or undescended',
    },
  },
  {
    '363478007': {
      title: 'can.Thyroid Cancer',
      description: 'Malignant tumor of thyroid gland (disorder)',
      snomedcode: 363478007,
      icdcode: 'C73',
      icddescription: 'Malignant neoplasm of thyroid gland',
    },
  },
  {
    '371973000': {
      title: 'can.Uterine Cancer',
      description: 'Malignant neoplasm of uterus (disorder)',
      snomedcode: 371973000,
      icdcode: 'C55',
      icddescription: 'Malignant neoplasm of uterus, part unspecified',
    },
  },
  {
    '188209005': {
      title: 'can.Vaginal Cancer',
      description: 'Malignant neoplasm of vaginal vault (disorder)',
      snomedcode: 188209005,
      icdcode: 'C52',
      icddescription: 'Malignant neoplasm of vagina',
    },
  },
  {
    '94143002': {
      title: 'can.Vulvar Cancer',
      description: 'Primary malignant neoplasm of vulva (disorder)',
      snomedcode: 94143002,
      icdcode: 'C519',
      icddescription: 'Malignant neoplasm of vulva, unspecified',
    },
  },
]

export const CancerDropDownValues = constructPatientDemographicObject(
  CancerCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
