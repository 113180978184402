import {
  ConfigProps,
  NotificationType,
  generateNotification,
} from '../stories/BaseComponents/Notification'

export function notification(
  content: string,
  type?: string, // note! notifications default to type 'info' if no type is given
  config?: ConfigProps
): void {
  const acceptedTypes = Object.values(NotificationType).map((value) =>
    value.toString()
  )
  const isTypeValid = type ? acceptedTypes.includes(type.toLowerCase()) : false

  if (type === 'failure') {
    type = NotificationType.ERROR
  } else if (!isTypeValid) {
    type = NotificationType.INFO
  }

  const notificationType = type as NotificationType

  generateNotification(content, notificationType, config)
}

export const errorNotification = (content: string, config?: ConfigProps) => {
  notification(content, NotificationType.ERROR, config)
}

export const logErrorAndNotify = (content: string, error: Error) => {
  console.error(content, error)
  notification(content, 'failure')
}
