import React from 'react'

import { Typography } from 'antd'
import generatePicker from 'antd/lib/date-picker/generatePicker'
import { endOfMonth, startOfMonth, subMonths } from 'date-fns'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import { RangeValue } from 'rc-picker/lib/interface'

import { TestId } from '../../shared-types'

import './Datepicker.scss'

const { RangePicker } = generatePicker<Date>(dateFnsGenerateConfig)
const { Text } = Typography

type DatePickerChangeEventHandler = (dates: RangeValue<Date>) => void
export type NonNullableDateChangeHandler = (
  startDate: Date,
  endDate: Date
) => void

type DatePickerProps = {
  initialValues?: { startDate: Date; endDate: Date }
  onDateChange: NonNullableDateChangeHandler
} & TestId

const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  initialValues,
  testId,
}) => {
  const currentDate = new Date()
  const dateChangeHandler: DatePickerChangeEventHandler = (dates) => {
    if (dates == null || dates[0] == null || dates[1] == null) {
      /* Improve on this */
      alert(
        'Invalid date range selected. Please choose a start and end date range.'
      )
      return
    }

    onDateChange(dates[0], dates[1])
  }

  const defaultDateRanage = initialValues
    ? ([initialValues.startDate, initialValues.endDate] as [Date, Date])
    : undefined

  return (
    <div className="datepicker-column margin-bottom">
      <Text className="datepicker-label small-text">Date range</Text>
      <RangePicker
        data-testid={testId}
        getPopupContainer={(trigger) => trigger?.parentElement || document.body}
        onChange={dateChangeHandler}
        defaultValue={defaultDateRanage}
        ranges={{
          /* We need to keep an eye out for these ranges because of edge cases that arise from
          variation in the patient/provider timezones, daylight savings, and the backend UTC time */
          Today: [currentDate, currentDate],
          'This month': [startOfMonth(currentDate), endOfMonth(currentDate)],
          'Last 3 months': [
            startOfMonth(subMonths(currentDate, 2)),
            endOfMonth(currentDate),
          ],
        }}
      />
    </div>
  )
}

export default DatePicker
