enum GeneralInformationQuestionKeys {
  LEGAL_FIRST_NAME = 'LEGAL_FIRST_NAME',
  LEGAL_MIDDLE_NAME = 'LEGAL_MIDDLE_NAME',
  LEGAL_LAST_NAME = 'LEGAL_LAST_NAME',
  FIRST_NAME_TO_USE = 'FIRST_NAME_TO_USE',
  MIDDLE_NAME_TO_USE = 'MIDDLE_NAME_TO_USE',
  LAST_NAME_TO_USE = 'LAST_NAME_TO_USE',
  FORMER_FIRST_NAME = 'FORMER_FIRST_NAME',
  FORMER_MIDDLE_NAME = 'FORMER_MIDDLE_NAME',
  FORMER_LAST_NAME = 'FORMER_LAST_NAME',
  ADDRESS = 'ADDRESS',
  ADDRESS_OBJECT = 'ADDRESS_OBJECT',
  ADDRESS_UNIT_NUMBER = 'ADDRESS_UNIT_NUMBER',
  PHONE = 'PHONE',
  PHONE_TYPE = 'PHONE_TYPE',
  DOB = 'DOB', // or DATE_OF_BIRTH??
  LEGAL_SEX = 'LEGAL_SEX',
  BIRTH_SEX = 'BIRTH_SEX',
  GENDER_IDENTITY = 'GENDER_IDENTITY',
  PRONOUNS = 'PRONOUNS',
  RACE = 'RACE',
  RACE_SUBCATEGORY = 'RACE_SUBCATEGORY',
  ETHNICITY = 'ETHNICITY',
  SPECIFIC_ETHNICITY = 'SPECIFIC_ETHNICITY',
  EMERGENCY_CONTACT_NAME = 'EMERGENCY_CONTACT_NAME',
  EMERGENCY_CONTACT_RELATIONSHIP = 'EMERGENCY_CONTACT_RELATIONSHIP',
  EMERGENCY_CONTACT_PHONE = 'EMERGENCY_CONTACT_PHONE',
  EMERGENCY_CONTACT_EMAIL = 'EMERGENCY_CONTACT_EMAIL',
  // EMERGENCY_CONTACT_HIPAA_AUTHORIZED = 'EMERGENCY_CONTACT_HIPAA_AUTHORIZED',
  HEALTHCARE_PROVIDERS = 'HEALTHCARE_PROVIDERS',
  // EXTERNAL_PROVIDER_SPECIALTY = 'EXTERNAL_PROVIDER_SPECIALTY',
  // EXTERNAL_PROVIDER_ADDRESS = 'EXTERNAL_PROVIDER_ADDRESS',
  // EXTERNAL_PROVIDER_PHONE = 'EXTERNAL_PROVIDER_PHONE',
  // EXTERNAL_PROVIDER_EMAIL = 'EXTERNAL_PROVIDER_EMAIL',
  // EXTERNAL_PROVIDER_HIPAA_AUTHORIZED = 'EXTERNAL_PROVIDER_HIPAA_AUTHORIZED',
}

/** These healthcare provider fields nested as objects in the HEALTHCARE_PROVIDERS array */
export enum HealthcareProviderSubForm {
  HEALTHCARE_PROVIDER_NAME = 'HEALTHCARE_PROVIDER_NAME',
  HEALTHCARE_PROVIDER_SPECIALTY = 'HEALTHCARE_PROVIDER_SPECIALTY',
  HEALTHCARE_PROVIDER_ADDRESS = 'HEALTHCARE_PROVIDER_ADDRESS',
  HEALTHCARE_PROVIDER_ADDRESS_OBJECT = 'HEALTHCARE_PROVIDER_ADDRESS_OBJECT',
  HEALTHCARE_PROVIDER_ADDRESS_UNIT_NUMBER = 'HEALTHCARE_PROVIDER_ADDRESS_UNIT_NUMBER',
  HEALTHCARE_PROVIDER_PHONE = 'HEALTHCARE_PROVIDER_PHONE',
  HEALTHCARE_PROVIDER_EMAIL = 'HEALTHCARE_PROVIDER_EMAIL',
}

enum InsuranceQuestionKeys {
  INSURANCE_NAME = 'INSURANCE_NAME',
  INSURANCE_MEMBER_ID = 'INSURANCE_MEMBER_ID',
  INSURANCE_GROUP_ID = 'INSURANCE_GROUP_ID',
  IS_PRIMARY_INSURANCE_HOLDER = 'IS_PRIMARY_INSURANCE_HOLDER',
  PRIMARY_INSURED_HAS_SAME_ADDRESS = 'PRIMARY_INSURED_HAS_SAME_ADDRESS',
  PRIMARY_INSURED_RELATIONSHIP = 'PRIMARY_INSURED_RELATIONSHIP',
  PRIMARY_INSURED_FIRST_NAME = 'PRIMARY_INSURED_FIRST_NAME',
  PRIMARY_INSURED_LAST_NAME = 'PRIMARY_INSURED_LAST_NAME',
  PRIMARY_INSURED_DATE_OF_BIRTH = 'PRIMARY_INSURED_DATE_OF_BIRTH',
  PRIMARY_INSURED_LEGAL_SEX = 'PRIMARY_INSURED_LEGAL_SEX',
  PRIMARY_INSURED_EMAIL = 'PRIMARY_INSURED_EMAIL',
  PRIMARY_INSURED_PHONE = 'PRIMARY_INSURED_PHONE',
  PRIMARY_INSURED_ADDRESS = 'PRIMARY_INSURED_ADDRESS',
  PRIMARY_INSURED_ADDRESS_UNIT_NUMBER = 'PRIMARY_INSURED_ADDRESS_UNIT_NUMBER',
  PRIMARY_INSURED_ADDRESS_OBJECT = 'PRIMARY_INSURED_ADDRESS_OBJECT',
  HAS_SECONDARY_INSURANCE = 'HAS_SECONDARY_INSURANCE',
  SECONDARY_INSURANCE_NAME = 'SECONDARY_INSURANCE_NAME',
  SECONDARY_INSURANCE_MEMBER_ID = 'SECONDARY_INSURANCE_MEMBER_ID',
  SECONDARY_INSURANCE_GROUP_ID = 'SECONDARY_INSURANCE_GROUP_ID',
  IS_SECONDARY_INSURANCE_SUBSCRIBER = 'IS_SECONDARY_INSURANCE_SUBSCRIBER',
  SECONDARY_INSURANCE_SUBSCRIBER_RELATIONSHIP = 'SECONDARY_INSURANCE_SUBSCRIBER_RELATIONSHIP',
  SECONDARY_INSURANCE_SUBSCRIBER_FIRST_NAME = 'SECONDARY_INSURANCE_SUBSCRIBER_FIRST_NAME',
  SECONDARY_INSURANCE_SUBSCRIBER_LAST_NAME = 'SECONDARY_INSURANCE_SUBSCRIBER_LAST_NAME',
  SECONDARY_INSURANCE_SUBSCRIBER_DATE_OF_BIRTH = 'SECONDARY_INSURANCE_SUBSCRIBER_DATE_OF_BIRTH',
  IS_PRIMARY_INFORMATION_RELEASE_AUTHORIZED = 'IS_PRIMARY_INFORMATION_RELEASE_AUTHORIZED',
  IS_PRIMARY_PAYMENT_AUTHORIZED = 'IS_PRIMARY_PAYMENT_AUTHORIZED',
  IS_SECONDARY_INFORMATION_RELEASE_AUTHORIZED = 'IS_SECONDARY_INFORMATION_RELEASE_AUTHORIZED',
  IS_SECONDARY_PAYMENT_AUTHORIZED = 'IS_SECONDARY_PAYMENT_AUTHORIZED',
  INSURANCE_CARD_IMAGE_FRONT = 'INSURANCE_CARD_IMAGE_FRONT',
  INSURANCE_CARD_IMAGE_BACK = 'INSURANCE_CARD_IMAGE_BACK',
}

enum AllergiesMedicationsQuestionKeys {
  HAS_ALLERGIES = 'HAS_ALLERGIES',
  HAS_MEDICATIONS = 'HAS_MEDICATIONS',
  ALLERGIES = 'ALLERGIES',
  CURRENT_MEDICATIONS = 'CURRENT_MEDICATIONS',
}

enum MedicationsSubFormQuestionKeys {
  MED_NAME = 'MED_NAME',
  DOSAGE = 'DOSAGE',
  FREQUENCY = 'FREQUENCY',
  FREQUENCY_OPTIONAL_DETAIL = 'FREQUENCY_OPTIONAL_DETAIL',
  REASONS_FOR_USE = 'REASONS_FOR_USE',
}

enum PsychiatricHistoryQuestionKeys {
  EXISTING_PSYCH_DATA = 'EXISTING_PSYCH_DATA',
  EXISTING_PSYCH_DATA_OTHER = 'EXISTING_PSYCH_DATA_OTHER',
  HAS_SUICIDAL_THOUGHTS = 'HAS_SUICIDAL_THOUGHTS',
  HAS_PREVIOUS_SUICIDAL_IDEATION = 'HAS_PREVIOUS_SUICIDAL_IDEATION',
  HAS_PREVIOUS_SUICIDAL_ATTEMPT = 'HAS_PREVIOUS_SUICIDAL_ATTEMPT',
  HAS_HISTORY_OF_SELF_HARM = 'HAS_HISTORY_OF_SELF_HARM',
  HAS_PREVIOUS_KETAMINE_TREATMENT = 'HAS_PREVIOUS_KETAMINE_TREATMENT',
  NUMBER_OF_KETAMINE_TREATMENTS = 'NUMBER_OF_KETAMINE_TREATMENTS',
  KETAMINE_TREATMENT_DETAILS = 'KETAMINE_TREATMENT_DETAILS',
  HAS_PREVIOUS_THERAPY_TREATMENT = 'HAS_PREVIOUS_THERAPY_TREATMENT',
  THERAPY_TREATMENT_DETAILS = 'THERAPY_TREATMENT_DETAILS',
  HAS_PREVIOUS_TMS_TREATMENT = 'HAS_PREVIOUS_TMS_TREATMENT',
  NUMBER_OF_TMS_TREATMENTS = 'NUMBER_OF_TMS_TREATMENTS',
  TMS_TREATMENT_DETAILS = 'TMS_TREATMENT_DETAILS',
  HAS_PREVIOUS_ECT_TREATMENT = 'HAS_PREVIOUS_ECT_TREATMENT',
  NUMBER_OF_ECT_TREATMENTS = 'NUMBER_OF_ECT_TREATMENTS',
  ECT_TREATMENT_DETAILS = 'ECT_TREATMENT_DETAILS',
  OTHER_MENTAL_HEALTH_SERVICES = 'OTHER_MENTAL_HEALTH_SERVICES',
  HOSPITALIZATIONS = 'HOSPITALIZATIONS',
  HAS_TAKEN_PSYCH_MEDS = 'HAS_TAKEN_PSYCH_MEDS',
  PSYCH_MEDS = 'PSYCH_MEDS',
}

enum PsychHistoryMedsQuestionKeys { // subform to psych history
  IS_STILL_PRESCRIBED = 'IS_STILL_PRESCRIBED',
  START_OF_MED = 'START_OF_MED',
  END_OF_MED = 'END_OF_MED',
  MAX_DOSE_PER_DAY = 'MAX_DOSE_PER_DAY',
  REASONS_FOR_USE = 'REASONS_FOR_USE',
  REASON_FOR_DISCONTINUATION = 'REASON_FOR_DISCONTINUATION',
  HAS_SIDE_EFFECTS = 'HAS_SIDE_EFFECTS',
  SIDE_EFFECTS_DESCRIPTION = 'SIDE_EFFECTS_DESCRIPTION',
}

enum PsychHistoryHospQuestionKeys { // subform to psych history
  REASON_FOR_HOSPITALIZATION = 'REASON_FOR_HOSPITALIZATION',
  START_OF_HOSPITALIZATION = 'START_OF_HOSPITALIZATION',
  END_OF_HOSPITALIZATION = 'END_OF_HOSPITALIZATION',
  LENGTH_OF_HOSPITALIZATION = 'LENGTH_OF_HOSPITALIZATION',
}

enum MedHistoryNonPsychQuestionKeys {
  CONDITIONS = 'CONDITIONS',
  OTHER_CONDITIONS = 'OTHER_CONDITIONS',
  HEIGHT_FEET = 'HEIGHT_FEET',
  HEIGHT_INCHES = 'HEIGHT_INCHES',
  WEIGHT = 'WEIGHT',
  IS_PREGNANT_OR_NURSING = 'IS_PREGNANT_OR_NURSING',
  IS_PLANNING_ON_BEING_PREGNANT = 'IS_PLANNING_ON_BEING_PREGNANT',
  HAS_PREVIOUS_SURGERIES = 'HAS_PREVIOUS_SURGERIES',
  HAS_PREVIOUS_HOSPITALIZATION = 'HAS_PREVIOUS_HOSPITALIZATION',
}

enum SocialHistoryQuestionKeys {
  SUBSTANCE_USE_HISTORY = 'SUBSTANCE_USE_HISTORY',
  IS_USING_ALCOHOL = 'IS_USING_ALCOHOL',
  HIGHEST_EDUCATION_LEVEL = 'HIGHEST_EDUCATION_LEVEL',
  EMPLOYMENT_STATUS = 'EMPLOYMENT_STATUS',
  CURRENT_RELATIONSHIP_STATUS = 'CURRENT_RELATIONSHIP_STATUS',
  HAS_SERVED_IN_MILITARY = 'HAS_SERVED_IN_MILITARY',
  HAS_COMBAT_EXPERIENCE = 'HAS_COMBAT_EXPERIENCE',
}

enum OtherInformationQuestionKeys {
  HEAR_ABOUT_CLINIC = 'HEAR_ABOUT_CLINIC',
  REFERRING_CLINICIAN_NAME = 'REFERRING_CLINICIAN_NAME',
  REFERRING_CLINICIAN_PHONE = 'REFERRING_CLINICIAN_PHONE',
  REFERRING_CLINICIAN_EMAIL = 'REFERRING_CLINICIAN_EMAIL',
  REFERRING_CLINICIAN_SPECIALTY = 'REFERRING_CLINICIAN_SPECIALTY',
  OTHER_IMPORTANT_INFO = 'OTHER_IMPORTANT_INFO',
}

export {
  GeneralInformationQuestionKeys,
  InsuranceQuestionKeys,
  AllergiesMedicationsQuestionKeys,
  MedicationsSubFormQuestionKeys,
  PsychiatricHistoryQuestionKeys,
  PsychHistoryMedsQuestionKeys,
  PsychHistoryHospQuestionKeys,
  MedHistoryNonPsychQuestionKeys,
  SocialHistoryQuestionKeys,
  OtherInformationQuestionKeys,
}
