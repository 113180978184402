import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const HeartCodes: any = [
  {
    56265001: {
      title: 'Heart Condition',
      description: 'Heart disease (disorder)',
      snomedcode: 362969004,
      icdcode: 'I519',
      icddescription: 'Heart disease, unspecified',
    },
  },
  {
    '698247007': {
      title: 'hrt.Cardiac Arrhythmia',
      description: 'Cardiac arrhythmia (disorder)',
      snomedcode: 698247007,
      icdcode: 'I499',
      icddescription: 'Cardiac arrhythmia, unspecified',
    },
  },
  {
    '42343007': {
      title: 'hrt.Congestive Heart Failure',
      description: 'Congestive heart failure (disorder)',
      snomedcode: 42343007,
      icdcode: 'I50.9',
      icddescription: 'Heart failure, unspecified',
    },
  },
  {
    '53741008': {
      title: 'hrt.Coronary Artery Disease',
      description: 'Coronary arteriosclerosis (disorder)',
      snomedcode: 53741008,
      icdcode: 'I2510',
      icddescription:
        'Atherosclerotic heart disease of native coronary artery without angina pectoris',
    },
  },
  {
    '22298006': {
      title: 'hrt.Myocardial Infarction (Heart Attack)',
      description: 'Myocardial infarction (disorder)',
      snomedcode: 22298006,
      icdcode: 'I252',
      icddescription: 'Old myocardial infarction',
    },
  },
]

export const HeartDropDownValues = constructPatientDemographicObject(
  HeartCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
