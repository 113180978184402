import { useMemo, useState } from 'react'

import { Elements } from '@stripe/react-stripe-js'
import { useQueryClient } from '@tanstack/react-query'
import { Dropdown, Table } from 'antd'
import { FixedType } from 'rc-table/lib/interface'

import { updatePaymentMethod } from '../../../api/api-lib-typed'
import {
  QueryKeys as BillingQueryKeys,
  usePaymentMethods,
} from '../../../hooks/useBillingInfo'
import { isExpiredCard } from '../../../libs/billing'
import { PAYMENT_METHOD_TYPES } from '../../../libs/constants'
import { PaymentMethod } from '../../../shared-types'
import { Button, Tag, Tooltip } from '../../../stories/BaseComponents'
import AddCreditCardModal from './AddCreditCardModal'
import { getPaymentMethodDisplayContent } from './Billing'
import CardsTableOptions from './CardsTableOptions'
import ErrorView from './ErrorView'
import useStripe from './useStripe'

import styles from './Billing.module.scss'

interface CardsTableProps {
  patientId: string
}

export default function CardsTable({ patientId }: CardsTableProps) {
  const [isAddCreditCardModalOpen, setIsAddCreditCardModalOpen] =
    useState(false)
  const [oldCreditCardValues, setOldCreditCardValues] =
    useState<null | PaymentMethod>(null)
  const {
    data: paymentMethods,
    isInitialLoading: paymentMethodsIsLoading,
    isError: paymentMethodsIsError,
  } = usePaymentMethods(patientId)

  const stripePaymentMethods: PaymentMethod[] = useMemo(() => {
    return paymentMethods
      ?.filter(
        (paymentMethod: PaymentMethod) =>
          paymentMethod.type === PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD &&
          !paymentMethod.deletedAt
      )
      .sort(
        (a: PaymentMethod, b: PaymentMethod) =>
          Number(b.isDefault) - Number(a.isDefault)
      )
  }, [paymentMethods])
  const columns = [
    {
      title: 'Card',
      dataIndex: 'card',
      key: 'card',
      fixed: 'left' as FixedType,
      width: 250,
    },
    {
      title: 'Expiration date',
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      width: 250,
    },
    {
      title: 'Name on card',
      dataIndex: 'name',
      key: 'name',
      width: 250,
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      key: 'nickname',
      width: 250,
    },
    {
      title: 'Manage card',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right' as FixedType,
      width: 170,
    },
  ]

  const queryClient = useQueryClient()
  const { stripePromise, stripePublicKeyIsError, stripePublicKeyIsLoading } =
    useStripe()

  const handleEditCreditCard = (creditCard: PaymentMethod) => {
    setOldCreditCardValues(creditCard)
    setIsAddCreditCardModalOpen(true)
  }

  const handleModalClose = () => {
    setIsAddCreditCardModalOpen(false)
    setOldCreditCardValues(null)
  }

  const refreshPaymentMethods = () => {
    handleModalClose()
    queryClient.invalidateQueries([BillingQueryKeys.PAYMENT_METHODS])
  }

  const handleChangeDefault = async (uuid: string) => {
    const paymentMethodData = {
      isDefault: true,
      patientId,
      uuid,
    }
    await updatePaymentMethod(paymentMethodData)
    refreshPaymentMethods()
  }

  const handleDisplayExpiryDate = (card: PaymentMethod) => {
    const { expYear, expMonth } = card
    if (!expYear || !expMonth) {
      return ''
    }
    if (isExpiredCard(card)) {
      return (
        <span className={styles.expired}>
          Expired on {expMonth}/{expYear}
        </span>
      )
    } else {
      return (
        <span>
          {expMonth}/{expYear}
        </span>
      )
    }
  }

  const formatTransactionsAsDataSource = useMemo(
    () =>
      stripePaymentMethods?.map((creditCard, idx) => ({
        key: `transaction_${idx}`,
        card: (
          <span>
            {getPaymentMethodDisplayContent(creditCard)}{' '}
            {creditCard.isDefault ? (
              <Tooltip
                getPopupContainer={() =>
                  document.getElementById(
                    'cardTableDropdownArea'
                  ) as HTMLElement
                }
                title="This card will be used as the default payment method. You can always change the default."
              >
                <Tag className={styles.defaultTag}>Default</Tag>
              </Tooltip>
            ) : null}
          </span>
        ),
        expiredAt: handleDisplayExpiryDate(creditCard),
        name: <span>{creditCard.cardholderName}</span>,
        nickname: <span>{creditCard.nickname}</span>,
        actions: (
          <Dropdown
            getPopupContainer={() =>
              document.getElementById('cardTableDropdownArea') as HTMLElement
            }
            overlayClassName={styles.dropdownMenu}
            overlay={CardsTableOptions({
              creditCard,
              handleChangeDefault,
              handleEditCreditCard,
              patientId,
              refreshPaymentMethods,
              stripePaymentMethods,
            })}
          >
            <a
              data-testid={`credit-card-actions-${creditCard.uuid}`}
              className={styles.paymentActionLink}
            >
              Actions{' '}
              <i className="fa fa-angle-down fa-lg" aria-hidden="true"></i>
            </a>
          </Dropdown>
        ),
      })),
    [stripePaymentMethods]
  )

  if (paymentMethodsIsLoading || stripePublicKeyIsLoading) {
    return <span />
  }

  if (paymentMethodsIsError || stripePublicKeyIsError) {
    return <ErrorView />
  }

  return (
    <>
      <Elements stripe={stripePromise}>
        <AddCreditCardModal
          creditCards={stripePaymentMethods}
          handleNewCreditCardCreated={refreshPaymentMethods}
          isVisible={isAddCreditCardModalOpen}
          oldCreditCardValues={oldCreditCardValues}
          onCloseModal={handleModalClose}
          patientId={patientId}
        />
      </Elements>
      <Button
        className={styles.addNewCard__button}
        onClick={() => setIsAddCreditCardModalOpen(true)}
      >
        + Add new card
      </Button>
      <Table
        size="middle"
        scroll={{ x: 1170 }}
        columns={columns}
        dataSource={formatTransactionsAsDataSource}
      />
      {/* This element is used to place dropdown and 
          avoid table positions issues.  */}
      <div style={{ position: 'relative' }} id="cardTableDropdownArea" />
    </>
  )
}
