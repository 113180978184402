import { CalendarTwoTone } from '@ant-design/icons'
import { Typography } from 'antd'

interface DateWarningIconProps {
  text?: string
  iconPaddingLeft: string
  iconPaddingRight: string
}

export default function DateWarningIcon({
  text,
  iconPaddingLeft,
  iconPaddingRight,
}: DateWarningIconProps) {
  return (
    <span
      style={{
        color: '#D46B08',
        paddingLeft: iconPaddingLeft,
        fontSize: '12px',
        zIndex: 100,
      }}
    >
      <CalendarTwoTone
        twoToneColor="#D46B08"
        style={{ paddingRight: iconPaddingRight }}
      />
      <Typography.Text
        style={{
          color: '#D46B08',
          fontSize: '11px',
          fontWeight: 500,
        }}
      >
        {text ?? null}
      </Typography.Text>
    </span>
  )
}
