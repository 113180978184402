import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import {
  getHealthGorillaAccessToken,
  getLabsResults,
  saveViewOrder,
} from '../../api/labs'
import PDFModal from '../../components/Other/PDFModal'
import useQueryString from '../../hooks/useQueryString'
import { useReadyForReviewLabs } from '../../hooks/useReadyToReviewLabs'
import { getS3FilePrivate } from '../../libs/awsLib'
import { InfoPage } from '../../stories/BaseComponents'
import LabsNewOrder from '../../stories/Labs/LabsNewOrder/LabsNewOrder'
import LabsResultsTable from '../../stories/Labs/LabsResultsTable'
import { PatientHeader } from './PatientHeader'

import sharedStyles from '../_shared.module.scss'
import styles from './Labs.module.scss'

const ErrorView = (
  <InfoPage
    status="warning"
    title="Sorry there was a problem loading this page"
    details="Oops something went wrong. Please contact your Osmind representative if this issue persists."
    redirectButtonText="Return to Patient List"
    redirectLink="/"
  />
)

const ErrorViewLabs = (
  <InfoPage
    status="warning"
    title="Something went wrong"
    details="We had a problem connecting with our labs partner. Please contact your Osmind representative if this issue persists."
    redirectButtonText="Retry"
    redirectLink="/"
  />
)
export default function Labs({
  healthGorillaUserName,
}: {
  healthGorillaUserName: string
}) {
  const {
    data: healthGorillaAccessToken,
    isLoading: healthGorillaAccessTokenLoading,
    isError: healthGorillaAccessTokenError,
  } = useQuery(
    ['healthGorillaAccessToken'],
    getHealthGorillaAccessToken, // 5 minutes
    { staleTime: 60000 * 5 }
  )
  const refetchIsReadyforReviewLab = useReadyForReviewLabs().refetch

  const query = useQueryString()
  const patientId = query.get('patientId')
  const providerId = query.get('providerId')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [show, setShow] = useState(false)
  const [file, setFile] = useState(null)
  const [labResultError, setLabResultError] = useState(false)
  const [labResults, setLabResults] = useState([])
  const [activeResult, setActiveLabResults] = useState()
  const [refresh, setRefresh] = useState(false)
  const [refetch, setRefetch] = useState(false)

  async function downloadFileFromS3(record: any) {
    const data = await getS3FilePrivate(
      record.labsOrderDocumentReference,
      providerId,
      {
        ResponseCacheControl: 'max-age=0',
      }
    )
    const File: any = {}
    File.file = { data: data }
    setFile(File)
  }

  function labRefresh() {
    setRefetch(!refetch)
  }

  // Receives message from the iFrame to refresh the table
  window.onmessage = (event) => {
    if (event.data === 'loading') {
      setIsLoading(true)
    }
    if (event.data === 'new lab results') {
      labRefresh()
    }
  }

  function setLabToViewed(record: any) {
    const results: any = labResults
    for (const r of results) {
      if (r.key === record.key) {
        r.viewed = new Date().toISOString()
      }
    }
    setLabResults(results)
    refetchIsReadyforReviewLab()
    setRefresh(!refresh)
  }

  async function putViewedOrder(record: any) {
    try {
      await saveViewOrder(record.key)
      setLabToViewed(record)
    } catch (e) {
      return
    }
  }

  function viewResultPDF(record: any) {
    setActiveLabResults(record)
    setShow(true)
    downloadFileFromS3(record)
    if (providerId === record.orderingClinicianId && !record.viewed) {
      putViewedOrder(record)
    }
  }

  async function fetchLabResults() {
    const pId = patientId
    if (!pId) {
      throw new Error('No PatientId in url')
    }
    const results = await getLabsResults(patientId)
    if (!results || !results.length) {
      setLabResults([])
      return
    }
    setLabResults(results)
  }

  useEffect(() => {
    setIsLoading(true)
    const fetchData = async () => {
      await fetchLabResults()
      setRefresh(!refresh)
    }
    fetchData().catch(() => {
      setLabResultError(true)
    })
    setIsLoading(false)
  }, [refetch])

  if (healthGorillaAccessTokenError) {
    return ErrorViewLabs
  }

  if (!patientId || !providerId || labResultError || !healthGorillaUserName) {
    return ErrorView
  }

  return (
    <div className={sharedStyles.scroll}>
      <PatientHeader
        providerId={providerId}
        patientId={patientId}
        healthGorillaUserName={healthGorillaUserName}
      />
      <div className={styles.patientLabsContainer}>
        <div className={styles.orderNewLabContainer}>
          <LabsNewOrder
            patientId={patientId}
            accessToken={healthGorillaAccessToken}
            loadingAccessToken={healthGorillaAccessTokenLoading}
            ErrorView={ErrorViewLabs}
          />
        </div>
        <PDFModal
          fileContainer={file}
          show={show}
          handleShow={setShow}
          isLabPdf={true}
          labResult={activeResult}
          setLabsRefresh={labRefresh}
        />
        <LabsResultsTable
          results={labResults}
          isLoading={isLoading}
          viewResultPDF={viewResultPDF}
          providerId={providerId}
          refresh={refresh}
        />
      </div>
    </div>
  )
}
