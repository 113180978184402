import { MutationOptions, useMutation } from '@tanstack/react-query'

import { sendFeatureEnablementEmail } from '../../api/api-lib'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'

export default function useSendFeatureEnablementEmail(
  feature: string,
  options: Omit<MutationOptions, 'mutationFn' | 'onSuccess' | 'onError'> = {}
) {
  const registrationRequestMutator = useMutation({
    ...options,
    mutationFn: () => sendFeatureEnablementEmail({ feature }),
    onSuccess: () => {
      notification(`Request sent.`, 'success')
    },
    onError: (error: unknown) => {
      onError(
        error,
        500,
        'Request failed, please reach out to support directly.'
      )
    },
  })

  return registrationRequestMutator
}
