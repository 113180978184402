import { useQuery } from '@tanstack/react-query'

import { fetchBillingCodeDxMapping } from '../../api/billing'

export type BillingCodeToDxPointers = {
  [key: number]: {
    billingCodeId: number
    billingCodeCode: string
    billingCodeOwner: string
    billingCodeVersion: string
    latestDxCodes: string[]
  }
}

export const useBillingCodeDxMapping = (
  patientId: string,
  options?: {
    enabled?: boolean
    onSuccess?: (data: BillingCodeToDxPointers) => void
  }
) => {
  const { data, isInitialLoading, isLoading, error, isFetched } =
    useQuery<BillingCodeToDxPointers>(
      ['billingCodeToDxPointers', patientId],
      () => fetchBillingCodeDxMapping(patientId),
      {
        enabled: options?.enabled ?? true,
        onSuccess: options?.onSuccess,
      }
    )

  return {
    mapping: data || {},
    isLoading,
    isInitialLoading,
    error,
    isFetched,
  }
}
