import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react'

import { isArray } from 'lodash'
import { BaseSelectRef } from 'rc-select'
import ClickAwayListener from 'react-click-away-listener'
import { animated, useSpring } from 'react-spring'

import Button from '../Button'
import { Message } from '../Message'
import Text from '../Text'
import * as FieldTypes from './shared-types'

import styles from './_shared.module.scss'

export const focusRef = (
  autoFocus: boolean | undefined,
  containerRef: RefObject<HTMLDivElement>,
  ref?: RefObject<HTMLInputElement | BaseSelectRef>,
  rendered?: boolean,
  focusFunction?: () => void
) => {
  if (!ref?.current) return
  if (!autoFocus) {
    ref.current?.focus()
    focusFunction && focusFunction()
    return
  }
  setTimeout(() => {
    containerRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, 200)

  if (rendered) {
    setTimeout(() => {
      focusFunction && focusFunction()
      ref?.current?.focus()
      return
    }, 200)
  } else {
    setTimeout(() => {
      focusFunction && focusFunction()
      ref?.current?.focus()
    }, 700)
  }
}

/**
  Base component for inline editable components
*/
function InlineEditBase({
  activeComponent,
  autoSave = false,
  className: givenClassName = '',
  customActions,
  onActive,
  onCancel,
  customDefault,
  scrollToField = false,
  id,
  noEmpty = false,
  onSave,
  fieldWidth = undefined,
  padded = false,
  inactiveCursor = '',
  placeholder = '',
  placeholderElement = '',
  size = 'middle',
  showActions = true,
  testId,
  validators = [],
  validateOnChange = false,
  value: savedValue = '',
  toggleActive = false,
  disabled = false,
  isWrongValue = false,
  allowClear = false,
  displayValue,
}: FieldTypes.InlineEditProps) {
  const containerRef: RefObject<HTMLDivElement> = useRef(null)
  const actionsRef: React.Ref<HTMLDivElement> = useRef(null)
  // ----------USE(FUL) STATES-----------------------------------
  const [isActive, setIsActive] = useState<boolean>(false)
  /**
   * NOTE: `draftValue` __might__ be better initialized at `savedValue` than `''`\
   * - but without enough tests and too many impressions, it's not worth making this risky change
   */
  const [draftValue, setDraftValue] = useState<FieldTypes.Value>('')
  const [value, setValue] = useState<FieldTypes.Value>(savedValue)
  const [hasError, setHasError] = useState<boolean>(false)
  const [hasValueChange, setHasValueChange] = useState<boolean>(false)
  const [toggleScroll, setToggleScroll] = useState<boolean>(false)
  const [cursor, setCursor] = useState('')

  useEffect(() => {
    setDraftValue('')
    setValue('')
    return () => {
      setDraftValue('')
      setValue('')
    }
  }, [])

  useEffect(() => {
    if (toggleScroll && actionsRef && actionsRef.current) {
      actionsRef.current.scrollIntoView({ block: 'nearest' })
    }
  }, [toggleScroll, actionsRef])

  useEffect(() => {
    if (disabled) {
      setCursor('not-allowed')
    } else if (inactiveCursor && Boolean(inactiveCursor)) {
      setCursor(inactiveCursor)
    } else {
      setCursor('')
    }
  }, [inactiveCursor, disabled])

  /*
    sync internal values with the "savedValue" passed in via props,
    otherwise "value" and "draftValue" can be outdated when "savedValue" is changed, but not through direct user interactions.
    example of user flow that exposes this issue:
      1. user enters "abc" in an InlineEditText field,
      2. a separate event leads us to clear this field, by setting the "savedValue" to ""
      3. the "draftValue" remains to be "abc" without this "useEffect"
      4. when user focuses on the field, they see "abc" instead of "" because that is the value of "draftValue"
  */
  useEffect(() => {
    setValue(savedValue)
    setDraftValue(savedValue)
  }, [savedValue])

  const shouldShowActions = isActive && hasValueChange && showActions

  // ----------HELPFUL FUNCTIONS---------------------------------
  const resetValue = () => setValue(savedValue)

  const toggleClose = () => setIsActive(false)
  const toggleCancel = () => {
    resetValue()
    toggleClose()
    onCancel && onCancel()
    containerRef?.current?.focus()
  }

  const toggleFocus = () => {
    setValue(draftValue)
  }

  const toggleEdit = async () => {
    if (!disabled) {
      await setIsActive(true)
      if (!draftValue) {
        await setHasValueChange(false)
      }
      toggleFocus()
      onActive && onActive()
    }
  }

  useEffect(() => {
    if (toggleActive) {
      toggleEdit()
    }
  }, [toggleActive])

  const validate = (value: any) => {
    const issues = validators.filter(({ onError, rule }) => {
      const isInvalid = !rule(value)
      if (onError && isInvalid) onError()
      return isInvalid
    })
    const hasIssues = (issues?.length || -1) > 0
    const shouldNotBeEmptyButIs = noEmpty && !value
    if (shouldNotBeEmptyButIs) {
      const message = id ? `${id} cannot be empty` : `This cannot be empty.`
      Message.error(message)
    }
    const errorStatus = hasIssues || shouldNotBeEmptyButIs
    setHasError(errorStatus)
    return !errorStatus // returns true if input is valid
  }

  // ----------MAIN FUNCTIONS------------------------------------
  async function toggleSave(newValue?: any) {
    let valueToSave = ''
    if (allowClear) {
      valueToSave = newValue || ''
    } else {
      valueToSave = newValue || value || ''
    }
    if (typeof valueToSave === 'string') {
      valueToSave = valueToSave.trim()
      setValue(valueToSave)
    }

    const isValid = validate(valueToSave)
    if (!isValid) return

    try {
      if (onSave) await onSave(valueToSave, id)
    } catch (e) {
      console.error(e)
    } finally {
      toggleClose()
      containerRef?.current?.focus()
    }
  }

  const toggleBlur = () => {
    if (!isActive) return
    if (!showActions && hasValueChange) {
      toggleSave()
    }
    setDraftValue(value)
    toggleClose()
  }

  const handleChange = async (event: any) => {
    let newValue: any
    if (
      typeof event === 'string' ||
      isArray(event) ||
      event === null ||
      event?.Address1 ||
      event === undefined
    ) {
      newValue = event
    } else {
      newValue = event?.target?.value || event?.target?.checked
    }
    if (validateOnChange) validate(newValue)
    await setValue(newValue)
    await setHasValueChange(newValue !== savedValue)
    if (autoSave && newValue !== savedValue) {
      toggleSave(newValue)
    }
  }

  const overlayStyle = useSpring({
    opacity: shouldShowActions ? 1 : 0,
    y: shouldShowActions ? 0 : -8,
    config: {
      tension: 280,
      friction: 20,
    },
  })

  // ----------DEFAULT COMPONENT(S)-------------------------------
  const inactiveDefault = useMemo(() => {
    if (isActive) return null
    if (customDefault) return <div onClick={toggleEdit}>{customDefault}</div>
    const sizeParsed = size !== 'large' && size !== 'small' ? 'base' : size
    const sizeClassName = styles[sizeParsed] || ''
    let className = savedValue ? styles.withValue : styles.placeholder
    className += ` ${sizeClassName}`

    return (
      <animated.div
        style={{ width: '100%', maxWidth: fieldWidth, overflow: 'hidden' }}
        className={styles[`textBox${sizeParsed}`]}
        data-testid={testId ? `displayed-value-${testId}` : undefined}
        onClick={toggleEdit}
      >
        <Text style={{ cursor: cursor }} className={className}>
          {displayValue ?? (savedValue?.toString() || placeholder)}
        </Text>
      </animated.div>
    )
  }, [
    customDefault,
    isActive,
    padded,
    placeholder,
    placeholderElement,
    savedValue,
    size,
    disabled,
    displayValue,
  ])

  const actionsDefault = useMemo(() => {
    const shouldToggle = !shouldShowActions && toggleScroll && scrollToField
    if (shouldToggle) setToggleScroll(false)
    if (!shouldShowActions) return null
    if (customActions) return customActions({ toggleCancel, toggleSave })

    const cancelClassName = `${styles.actions} ${styles.actionsCancel}`
    const saveClassName = `${styles.actions} ${styles.actionsSave}`
    const cancelTestId = testId ? `close-btn-${testId}` : undefined
    const saveTestId = testId ? `check-btn-${testId}` : undefined
    if (!toggleScroll && scrollToField) {
      setToggleScroll(true)
    }

    return (
      <animated.div
        style={overlayStyle}
        ref={actionsRef}
        className={styles.actionsBox}
      >
        <Button
          className={cancelClassName}
          testId={cancelTestId}
          onClick={() => toggleCancel()}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className={saveClassName}
          testId={saveTestId}
          onClick={() => toggleSave(value)}
        >
          Save
        </Button>
      </animated.div>
    )
  }, [
    customActions,
    hasValueChange,
    scrollToField,
    showActions,
    toggleCancel,
    toggleSave,
    value,
  ])

  const className = useMemo(() => {
    let newClassName = styles.container
    if (givenClassName) newClassName += ` ${givenClassName}`
    if (isActive) return newClassName
    if (padded) {
      newClassName += ` ${
        size !== 'small' ? styles.padded : styles.paddedSmall
      }`
    }
    newClassName += ` ${styles.isNotActive}`
    if (isWrongValue) {
      newClassName += ` ${styles.redBorder}`
    }
    return newClassName
  }, [givenClassName, isActive, padded, isWrongValue])

  /**
   * We use ClickAwayListener instead of the input's onBlur event
   * because the latter fires when clicking the action buttons outside
   * the input, which we do not want.
   */
  return (
    <ClickAwayListener onClickAway={toggleBlur}>
      <div
        style={{ width: '100%', maxWidth: fieldWidth, cursor: cursor }}
        className={className}
        data-testid={testId}
        id={id}
        ref={containerRef}
        tabIndex={0}
        onFocus={() => {
          setTimeout(() => {
            containerRef.current?.scrollIntoView?.({
              behavior: 'smooth',
              block: 'center',
            })
          }, 100)
        }}
        onKeyDown={({ key }) => {
          if (key === 'Enter' && !isActive) {
            toggleEdit()
          }
          if (key === 'Escape') {
            toggleCancel()
          }
        }}
      >
        {isActive &&
          activeComponent({
            handleChange,
            hasError,
            toggleCancel,
            toggleBlur,
            toggleFocus,
            toggleSave,
            value,
            hasValueChange,
          })}
        {inactiveDefault}
        {actionsDefault}
      </div>
    </ClickAwayListener>
  )
}

export default InlineEditBase
