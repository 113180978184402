// This component should call the smarty street hook and render
// the options from the antd select component and on change should call the onChange function
import React, { useState } from 'react'

import { blue } from '@ant-design/colors'
import { AutoComplete } from 'antd'

import { globalConfig } from '../../config/config'
import {
  Address,
  useSmartyStreetsAutoComplete,
} from '../../hooks/useSmartyStreetsAutoComplete'

type Props = {
  onChange: (address: string) => void
  onSelect: (address: string) => void
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void
  toggleFocus: () => void
  defaultValue?: string
}

const SmartyStreetAddressInput: React.FC<Props> = ({
  onSelect,
  onChange,
  toggleFocus,
  onKeyDown,
  defaultValue = '',
}) => {
  const config = globalConfig.get()
  const [inputValue, setInputValue] = useState(defaultValue)
  const [selected, setSelected] = useState<string>('')
  const { suggestions, isLoading, error, remove, invalidate } =
    useSmartyStreetsAutoComplete({
      key: config.smartyStreets.key,
      selected: selected,
      query: inputValue,
      debounceTime: selected ? 0 : 1250,
    })
  const [isOpen, setIsOpen] = useState<boolean>(false)

  if (error) {
    invalidate()
    remove()
    console.error(error)
  }
  function formatSuggestion(suggestion: Address) {
    return `${suggestion.street_line}${
      suggestion.secondary ? ' ' + suggestion.secondary : ''
    }, ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`
  }

  function formatSelectedSuggestion(suggestion: Address) {
    return `${suggestion.street_line}${
      suggestion.secondary ? ' ' + suggestion.secondary : ''
    } (${suggestion.entries}) ${suggestion.city} ${suggestion.state} ${
      suggestion.zipcode
    }`
  }

  const handleInputChange = (value: string) => {
    setIsOpen(Boolean(value))
    setInputValue(value)
    onChange(value)
    if (selected) setSelected('')
  }

  const handleSelect = (value: string) => {
    const suggestion = JSON.parse(value) as Address
    const { entries } = suggestion
    const formattedSuggestion = formatSuggestion(suggestion)
    const formattedSelectedSuggestion = formatSelectedSuggestion(suggestion)
    const newIsOpen = entries <= 1 ? false : isOpen
    setSelected(formattedSelectedSuggestion)
    setInputValue(formattedSuggestion)
    setIsOpen(newIsOpen)
    onSelect(value)
  }

  return (
    <AutoComplete
      value={inputValue}
      autoFocus
      allowClear
      id="address-input"
      placeholder="Enter an address"
      notFoundContent={isLoading ? ' Loading Results...' : 'No results found'}
      onChange={handleInputChange}
      onSelect={handleSelect}
      open={isOpen}
      style={{ minWidth: '100%' }}
      onFocus={toggleFocus}
      onKeyDown={onKeyDown}
      options={suggestions?.map((suggestion, index) => ({
        id: `suggestion-${index}`,
        key: suggestion.street_line + index.toString(),
        value: JSON.stringify(suggestion),
        label: (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{formatSuggestion(suggestion)}</div>
            <div style={{ color: blue.primary, fontSize: '12px' }}>
              {suggestion?.entries > 1 ? `＋(${suggestion.entries}) more` : ''}
            </div>
          </div>
        ),
      }))}
      dropdownMatchSelectWidth={false}
    />
  )
}

export default SmartyStreetAddressInput
