import { useState } from 'react'

import { Typography } from 'antd'

import {
  getHealthGorillaURL,
  saveMissingDemographicData,
} from '../../../api/labs'
import { notification } from '../../../libs/notificationLib'
import { Card, Skeleton } from '../../BaseComponents'
import { HGMissingValuesJSON } from '../_types'
import LabsNewOrderMissingFields from './LabsNewOrderMissingFields'

import styles from './LabsNewOrder.module.scss'

const { Title } = Typography

interface LabsNewOrderProps {
  patientId: string
  accessToken: string
  loadingAccessToken: boolean
  ErrorView: JSX.Element
}

export default function LabsNewOrder({
  patientId,
  accessToken,
  loadingAccessToken,
  ErrorView,
}: LabsNewOrderProps) {
  const [showiFrame, setShowiFrame] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [healthGorillaUrl, setHealthGorillaUrl] = useState<string>('')
  useState<HGMissingValuesJSON | null>(null)

  async function orderNewLab(sendInsurance?: 'yes' | 'no') {
    if (patientId && accessToken) {
      try {
        const url = await getHealthGorillaURL(
          patientId,
          accessToken,
          sendInsurance || 'no'
        )
        setHealthGorillaUrl(url)
        setShowiFrame(true)
      } catch (e) {
        notification(
          'Something went wrong sending a new lab order. Please try again',
          'error'
        )
        setShowiFrame(false)
      }
    }
    setIsLoading(false)
  }

  if (!loadingAccessToken && accessToken === undefined) {
    return ErrorView
  }

  if (loadingAccessToken) {
    return <Skeleton />
  }

  return (
    <Card bordered={false}>
      <div className={styles.cardContainer}>
        <Title level={4}>Order new lab</Title>
        {!showiFrame ? (
          <LabsNewOrderMissingFields
            patientId={patientId}
            isLoading={isLoading}
            savePatientInfo={async (values: any) => {
              setIsLoading(true)
              try {
                await saveMissingDemographicData(patientId, values)
              } catch (e) {
                notification(
                  'Something went wrong saving patient information. Please try again.',
                  'error'
                )
                return
              }
              await orderNewLab(values.BillToInsurance)
            }}
          />
        ) : (
          showiFrame &&
          healthGorillaUrl && (
            <div id={styles.hgiFrame}>
              <iframe
                id={styles.iFrameHealthGorilla}
                title="iFrame Health Gorilla"
                width="100%"
                height="100%"
                frameBorder="0"
                src={healthGorillaUrl}
              />
            </div>
          )
        )}
      </div>
    </Card>
  )
}
