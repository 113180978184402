import React from 'react'

import { Modal as AntDModal } from 'antd'

import { TestId } from '../../shared-types'
import Button, { ButtonProps } from './Button'

import './ModalBasic.scss'

export type ModalBasicProps = {
  visible?: boolean
  title?: string
  content?: string
  children?: React.ReactNode
  hasCancel?: boolean
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void
  cancelButtonLabel?: string | React.ReactNode
  cancelButtonProps?: ButtonProps
  hasConfirm?: boolean
  onConfirm?: (e: React.MouseEvent<HTMLElement>) => void
  confirmButtonLabel?: string | React.ReactNode
  confirmButtonProps?: ButtonProps
  hasSecondaryAction?: boolean
  onSecondaryAction?: (e: React.MouseEvent<HTMLElement>) => void
  secondaryActionButtonLabel?: string | React.ReactNode
  secondaryActionButtonProps?: ButtonProps
  zIndex?: number
  width?: number
  contentTestId?: string
} & TestId

export const defaultProps: ModalBasicProps = {
  visible: false,
  hasCancel: true,
  cancelButtonLabel: 'Cancel',
  hasConfirm: true,
  confirmButtonLabel: 'Save',
  hasSecondaryAction: false,
  secondaryActionButtonLabel: "Don't save",
  zIndex: 1000,
  width: 400,
}

const ModalBasic: React.FC<ModalBasicProps> = (props) => {
  const {
    visible,
    title,
    content,
    children,
    hasCancel,
    onCancel,
    cancelButtonLabel,
    cancelButtonProps,
    hasConfirm,
    onConfirm,
    confirmButtonLabel,
    confirmButtonProps,
    hasSecondaryAction,
    onSecondaryAction,
    secondaryActionButtonLabel,
    secondaryActionButtonProps,
    zIndex,
    width,
    testId,
    contentTestId,
  } = { ...defaultProps, ...props }

  const renderContent = () => {
    if (content) {
      return <p>{content}</p>
    } else {
      return children
    }
  }
  const renderActionButtons = () => {
    const left = []
    const right = []
    if (hasCancel) {
      left.push(
        <Button
          key="cancel"
          type="default"
          onClick={onCancel}
          {...cancelButtonProps}
        >
          {cancelButtonLabel}
        </Button>
      )
    }
    if (hasSecondaryAction) {
      right.push(
        <Button
          key="secondary"
          type="default"
          danger
          onClick={onSecondaryAction}
          {...secondaryActionButtonProps}
        >
          {secondaryActionButtonLabel}
        </Button>
      )
    }
    if (hasConfirm) {
      right.push(
        <Button
          key="confirm"
          type="primary"
          onClick={onConfirm}
          {...confirmButtonProps}
        >
          {confirmButtonLabel}
        </Button>
      )
    }
    return (
      <div className={'buttons-container'}>
        <div>{left}</div>
        <div>{right}</div>
      </div>
    )
  }

  return (
    <AntDModal
      visible={visible}
      title={title}
      onCancel={onCancel}
      zIndex={zIndex}
      width={width}
      data-testid={testId}
      closable={false}
      maskClosable={false}
      footer={renderActionButtons()}
      className="osmo-modal-basic"
    >
      <div data-testid={contentTestId}>{renderContent()}</div>
    </AntDModal>
  )
}

export default ModalBasic
