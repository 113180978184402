import React from 'react'

import { UploadOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import type { UploadFile } from 'antd/es/upload/interface'
import { RcFile } from 'antd/lib/upload'

import { deleteS3FilePrivate, putS3FilePrivate } from '../../libs/awsLib'
import { onError } from '../../libs/errorLib'
import { Button, Text, Upload } from '../BaseComponents'
import {
  dataURLtoFile,
  getBase64,
  validateImageFileSize,
} from '../BaseComponents/helpers/imageUploadHelpers'

import styles from './ProfilePhoto.module.scss'

export interface ProfilePhotoUploadProps {
  setIsImageLoading: (newValue: boolean) => void
  hasImage: boolean
  isPopoverVisible: boolean
  setIsPopoverVisible: (newValue: boolean) => void
  imageUrl: string
  setImageUrl: (newValue: string) => void
  providerId: string
  patientName?: string
}

const ProfilePhotoUpload: React.FC<ProfilePhotoUploadProps> = ({
  setIsImageLoading,
  hasImage,
  isPopoverVisible,
  setIsPopoverVisible,
  setImageUrl,
  providerId,
  patientName,
}) => {
  /* resets which image to upload - use "preview" (cropped) version, not original */
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as RcFile)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  /* validate image size and save to S3 */
  const handleChange = async (file: RcFile) => {
    try {
      if (validateImageFileSize(file)) {
        setIsImageLoading(true)
        const filename = patientName?.split(' ').join('') || ''
        const img = new Image()

        img.onload = async () => {
          //Resize image to 200 x 200 px
          const canvas = document.createElement('canvas')
          const max_size = 200
          let width = img.width
          let height = img.height
          if (width > height) {
            if (width > max_size) {
              height *= max_size / width
              width = max_size
            }
          } else {
            if (height > max_size) {
              width *= max_size / height
              height = max_size
            }
          }
          canvas.width = width
          canvas.height = height
          canvas.getContext('2d')?.drawImage(img, 0, 0, width, height)
          const dataUrl = canvas.toDataURL('image/jpeg')
          //get new resized file
          const file = dataURLtoFile(dataUrl, `${filename}.jpg`)
          const arrayBuffer = await file?.arrayBuffer()
          //Upload file to S3
          await putS3FilePrivate(arrayBuffer, filename, providerId)

          //Set new image to profile
          getBase64(file as RcFile, (url: string) => {
            setImageUrl(url)
          })
          setIsImageLoading(false)
        }

        img.src = URL.createObjectURL(file) // is the data URL because called with readAsDataURL
      }
      setIsPopoverVisible(!isPopoverVisible)
    } catch (error) {
      console.error('Error submitting patient image in patient profile', error)
      onError(
        error,
        500,
        "Failed to update the patient's image. Please inform your administrator."
      )
    }
  }

  /* delete from s3 */
  const handleRemoveImages = async () => {
    const filename = patientName?.split(' ').join('') || ''
    try {
      await deleteS3FilePrivate(filename, providerId)
      setImageUrl('')
      setIsPopoverVisible(!isPopoverVisible)
    } catch (error) {
      console.error('Error deleting patient image in patient profile', error)
      onError(
        error,
        500,
        "Failed to delete the patient's image. Please inform your administrator."
      )
    }
  }

  return (
    <div style={{ minWidth: 300 }}>
      <Text className={styles.profilePhotoUploadText}>
        File must be a JPG or PNG
      </Text>
      <br />
      {/* @ts-expect-error antd4's ImgCrop's props do not extend React 18's React.PropsWithChildren */}
      <ImgCrop beforeCrop={validateImageFileSize}>
        <Upload
          className={styles.uploadFileButton}
          showUploadList={false}
          beforeUpload={handleChange}
          onPreview={onPreview}
          data-testid="profile-photo-upload"
        >
          <Button block icon={<UploadOutlined />}>
            Upload a file
          </Button>
        </Upload>
      </ImgCrop>
      {hasImage && (
        <Button
          danger
          block
          className={styles.removeButton}
          onClick={handleRemoveImages}
        >
          Remove photo
        </Button>
      )}
    </div>
  )
}

export default ProfilePhotoUpload
