import React from 'react'

import { FormInstance, FormItemProps, InputProps } from 'antd'

import { extractNumbersFromString } from '../../../containers/Patient/Claims/InsuranceClaim-helper'
import { einRegex } from '../../../libs/regex'
import AutoFormatInput from '../AutoFormatInput'

interface EINInput {
  form: FormInstance
  formItemProps?: FormItemProps
  inputProps?: InputProps
  name: string
}

export const formatEIN = (input: string) => {
  if (!input || !input.length) {
    return input
  }
  let clean = input.replace(/\D/g, '')
  if (clean.length > 9) {
    clean = clean.substring(0, 9)
  }

  if (clean.length > 2) {
    return clean.replace(/(\d{2})(\d)/, '$1-$2')
  } else {
    // limit to 10 numbers
    return clean
  }
}

export const formatSSN = (input: string) => {
  if (input.includes('***')) return input.substring(0, 11)
  if (!input || !input.length) {
    return input
  }
  let clean = extractNumbersFromString(input) || ''
  if (!clean) return clean
  if (clean.length > 9) {
    clean = clean.substring(0, 9)
  }

  if (clean.length > 5) {
    return [
      clean.slice(0, 3),
      '-',
      clean.slice(3, 5),
      '-',
      clean.slice(5),
    ].join('')
  } else if (clean.length > 3) {
    return [clean.slice(0, 3), '-', clean.slice(3, 5)].join('')
  } else {
    // limit to 11 numbers
    return clean
  }
}

const EINInput: React.FC<EINInput> = ({
  form,
  formItemProps = {},
  inputProps = {},
  name = 'ein',
}) => {
  const formItems: FormItemProps = {
    label: formItemProps.label || 'EIN',
    rules: formItemProps.rules || [
      {
        message: 'Enter a valid 9-digit code',
        min: 10,
        pattern: einRegex,
        transform: formatEIN,
        type: 'string',
        validateTrigger: 'onChange',
        whitespace: true,
      },
    ],
  }
  return (
    <AutoFormatInput
      form={form}
      formatInput={formItemProps.label === 'SSN' ? formatSSN : formatEIN}
      formItemProps={formItems}
      inputProps={inputProps}
      name={name}
      testId={name}
    />
  )
}

export default EINInput
