import { ReactNode } from 'react'

import cx from 'classnames'

import { LoadingSkeleton } from './LoadingSkeleton'
import { Row } from './Row'

import styles from './CoreData.module.scss'

type CoreDataField = {
  /**
   * Define the type of the field.
   * 'blank' fields will be rendered as empty blocks.
   * 'readonly' fields will be rendered with the specified readonlyValue.
   * 'dynamic' fields will be rendered with the specified dynamicValue ReactNode.
   */
  type: 'blank' | 'readonly' | 'dynamic'
  readonlyValue?: string
  dynamicValue?: ReactNode
}

export type CoreDataFields = {
  patient: CoreDataField
  dateOfVisit: CoreDataField
  startTime: CoreDataField
  location: CoreDataField
  renderingProvider: CoreDataField
  billingType?: CoreDataField
}

interface CoreDataProps {
  isLoading: boolean
  fields: CoreDataFields
}

export const CoreData = ({ isLoading, fields }: CoreDataProps) => {
  const renderRow = (
    field: CoreDataField,
    labelName: string,
    fieldName: string
  ) => (
    <Row
      label={labelName}
      content={
        isLoading || field.type === 'blank' ? (
          <LoadingSkeleton />
        ) : (
          <>
            {field.type === 'readonly' && (
              <div className={styles.nonEditableField}>
                {field.readonlyValue}
              </div>
            )}
            {field.type === 'dynamic' && field.dynamicValue}
          </>
        )
      }
      testIdPrefix={fieldName}
    />
  )

  return (
    <div className={cx(styles.container, 'hide-on-print')}>
      <div className={styles.tableWrapper}>
        {renderRow(fields.patient, 'Patient name', 'patientName')}
        {renderRow(fields.dateOfVisit, 'Date of visit', 'appointmentDate')}
        {renderRow(fields.startTime, 'Treatment start time', 'startTime')}
        {renderRow(fields.location, 'Location', 'location')}
        {renderRow(
          fields.renderingProvider,
          'Rendering provider',
          'renderingProvider'
        )}
        {fields.billingType &&
          renderRow(fields.billingType, 'Billing type', 'billingType')}
      </div>
    </div>
  )
}
