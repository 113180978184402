import React from 'react'

import EyeOutlined from '@ant-design/icons/lib/icons/EyeOutlined'
import { Popover, PopoverProps } from 'antd'

import Button from './Button'

import styles from './Popover.module.scss'

// base antd popover without added styling
export const AntDPopover: React.FC<PopoverProps> = (props) => {
  return <Popover {...props} />
}

const PopoverComponent: React.FC<PopoverProps> = ({
  content,
  title,
  placement,
}) => {
  return (
    <Popover content={content} title={title} placement={placement}>
      <div className={styles.popoverArea}>
        <Button className={styles.popoverButton} type="link">
          {' '}
          <EyeOutlined /> View details
        </Button>
      </div>
    </Popover>
  )
}

export default PopoverComponent
