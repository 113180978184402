import { isEqual } from 'date-fns'

import { DayOfWeek } from '../../../../api/api-lib-typed'
import { getDateAtHourAndMinute } from '../helpers'
import { TimeRange } from './AvailabilitySettings'

export const getInitialAvailability = () => {
  const availability: Record<DayOfWeek, TimeRange> = {
    SUN: {
      providerAvailabilityDayId: '',
      timeRange: [],
      isUnavailable: true,
    },
    MON: {
      providerAvailabilityDayId: '',
      timeRange: [],
      isUnavailable: true,
    },
    TUE: {
      providerAvailabilityDayId: '',
      timeRange: [],
      isUnavailable: true,
    },
    WED: {
      providerAvailabilityDayId: '',
      timeRange: [],
      isUnavailable: true,
    },
    THUR: {
      providerAvailabilityDayId: '',
      timeRange: [],
      isUnavailable: true,
    },
    FRI: {
      providerAvailabilityDayId: '',
      timeRange: [],
      isUnavailable: true,
    },
    SAT: {
      providerAvailabilityDayId: '',
      timeRange: [],
      isUnavailable: true,
    },
  }

  return availability
}

export const areTimeRangesEqual = (
  { timeRange: timeRangeA }: Pick<TimeRange, 'timeRange'>,
  { timeRange: timeRangeB }: Pick<TimeRange, 'timeRange'>
) => {
  if (timeRangeA.length !== timeRangeB.length) {
    return false
  }

  let areEqual = true
  for (let idx = 0; idx <= timeRangeA.length - 1; idx++) {
    const tA = timeRangeA[idx]
    const tB = timeRangeB[idx]

    if (
      tA.hasValidationError !== tB.hasValidationError ||
      !isEqual(
        getDateAtHourAndMinute(tA.startTimeHour, tA.startTimeMinute),
        getDateAtHourAndMinute(tB.startTimeHour, tB.startTimeMinute)
      ) ||
      tA.durationInMinutes !== tB.durationInMinutes
    ) {
      areEqual = false
      break
    }
  }

  return areEqual
}

export const areAvailabilitiesDifferent = (
  initialAvailability: Record<DayOfWeek, TimeRange>,
  currentAvailability: Record<DayOfWeek, TimeRange>
) => {
  if (
    Object.keys(initialAvailability).length !==
    Object.keys(currentAvailability).length
  ) {
    return true
  }

  const daysOfWeek = Object.keys(initialAvailability)
  let areDifferent = false
  for (const day of daysOfWeek) {
    const initialAvailabilityDay = initialAvailability[day as DayOfWeek]
    const currentAvailabilityDay = currentAvailability[day as DayOfWeek] as
      | TimeRange
      | undefined

    if (
      initialAvailabilityDay.isUnavailable !==
        currentAvailabilityDay?.isUnavailable ||
      initialAvailabilityDay.providerAvailabilityDayId !==
        currentAvailabilityDay?.providerAvailabilityDayId
    ) {
      areDifferent = true
      break
    }

    if (
      !currentAvailabilityDay.isUnavailable &&
      !areTimeRangesEqual(initialAvailabilityDay, currentAvailabilityDay)
    ) {
      areDifferent = true
      break
    }
  }

  return areDifferent
}
