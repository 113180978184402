import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const AutoimmuneCodes: any = [
  {
    '85828009': {
      title: 'Autoimmune Disorders (Including Arthritis)',
      description: 'Autoimmune disease (disorder)',
      snomedcode: 85828009,
      icdcode: 'M359',
      icddescription: 'Systemic involvement of connective tissue, unspecified',
    },
  },
  {
    '200936003': {
      title: 'auto.Lupus',
      description: 'Lupus erythematosus (disorder)',
      snomedcode: 200936003,
      icdcode: 'M329',
      icddescription: 'Systemic lupus erythematosus, unspecified',
    },
  },
  {
    '24700007': {
      title: 'auto.Multiple Sclerosis (MS)',
      description: 'Multiple sclerosis (disorder)',
      snomedcode: 24700007,
      icdcode: 'G35',
      icddescription: 'Multiple Sclerosis',
    },
  },
  {
    '91637004': {
      title: 'auto.Myasthenia Gravis',
      description: 'Myasthenia gravis (disorder)',
      snomedcode: 91637004,
      icdcode: 'G7000',
      icddescription: 'Myasthenia gravis without (acute) exacerbation',
    },
  },
  {
    '60380001': {
      title: 'auto.Narcolepsy',
      description: 'Narcolepsy (disorder)',
      snomedcode: 60380001,
      icdcode: 'G47419',
      icddescription: 'Narcolepsy without cataplexy',
    },
  },
  {
    '396275006': {
      title: 'auto.Osteoarthritis',
      description: 'Osteoarthritis (disorder)',
      snomedcode: 396275006,
      icdcode: 'M1990',
      icddescription: 'Unspecified osteoarthritis, unspecified site',
    },
  },
  {
    '69896004': {
      title: 'auto.Rheumatoid Arthritis (RA)',
      description: 'Rheumatoid arthritis (disorder)',
      snomedcode: 69896004,
      icdcode: 'M069',
      icddescription: 'Rheumatoid Arthritis, unspecified',
    },
  },
]

export const AutoimmuneDropDownValues = constructPatientDemographicObject(
  AutoimmuneCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
