import { Badge } from '../../../../stories/BaseComponents'

import styles from './NavigationBarBadge.module.scss'

const getBadgeText = ({
  isLoading,
  isSaving,
  hasError,
}: {
  isLoading: boolean
  isSaving: boolean
  hasError: boolean
}) => {
  if (isLoading) return 'Loading'
  if (isSaving) return 'Saving...'
  if (hasError) return 'Error saving'
  return 'Saved'
}

const getBadgeColor = ({
  isLoading,
  isSaving,
  hasError,
}: {
  isLoading: boolean
  isSaving: boolean
  hasError: boolean
}) => {
  if (isLoading || isSaving) return 'gray'
  if (hasError) return 'red'
  return 'green'
}

export type NavigationBarBadgeProps = {
  isLoading: boolean
  isSaving: boolean
  hasError: boolean
}

export const NavigationBarBadge = ({
  isLoading,
  isSaving,
  hasError,
}: NavigationBarBadgeProps) => {
  const badgeColor = getBadgeColor({
    isLoading,
    isSaving,
    hasError,
  })
  const badgeText = getBadgeText({
    isLoading,
    isSaving,
    hasError,
  })

  return (
    <Badge
      data-testid={'NavigationBarBadge'}
      className={isLoading ? styles.addSkeletonMargin : styles.nowrap}
      color={badgeColor}
      text={badgeText}
    />
  )
}
