import { format } from 'date-fns-tz'

import { DateISO8601 } from '../../shared-types'
import { getAgeWithMonths } from '../../shared/Helpers/utils'
import {
  CoreIdentifiers,
  DemographicsContactInfo,
  PatientInfo,
} from './shared-types'

export const generateCoreIdDisplayName = ({
  firstName,
  lastName,
  middleName,
  firstNameToUse,
  lastNameToUse,
  middleNameToUse,
}: {
  firstName: string
  lastName: string
  middleName: string
  firstNameToUse?: string
  lastNameToUse?: string
  middleNameToUse?: string
}) => {
  const middleNameToUseText = middleNameToUse
    ? ` ${middleNameToUse.charAt(0)}.`
    : ''
  const lastNameToUseText = lastNameToUse ? ` ${lastNameToUse}` : ''

  return `${firstName} ${
    middleName ? `${middleName.charAt(0)}.` : ''
  } ${lastName} ${
    firstNameToUse || middleNameToUseText !== '' || lastNameToUseText !== ''
      ? `(${firstNameToUse || ''}${middleNameToUseText}${lastNameToUseText})`
      : ''
  }`
}

export const normalizedAndFormatDate = (date: string, dateFormat: string) => {
  const splittedDate: string =
    new Date(date).toISOString().substring(0, 10) || ''
  const doo = new Date(splittedDate)
  const normalizedDate = new Date(
    doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)
  )
  return format(normalizedDate, dateFormat)
}

export const mapDemographicsToCoreIdentifiers = (
  demographicsData: DemographicsContactInfo,
  prevCoreIdentifiersData?: CoreIdentifiers
): CoreIdentifiers => {
  const DateOfBirth =
    demographicsData?.DateOfBirth &&
    normalizedAndFormatDate(demographicsData?.DateOfBirth, 'M-d-yyyy')
  const coreIdentifiers: CoreIdentifiers = {
    ...prevCoreIdentifiersData,
    patientFirstName: demographicsData.firstName,
    patientLastName: demographicsData.lastName,
    patientNameToUse: demographicsData.firstNameToUse,
    displayName: generateCoreIdDisplayName({
      firstName: demographicsData?.firstName || '',
      lastName: demographicsData?.lastName || '',
      middleName: demographicsData?.middleName || '',
      firstNameToUse: demographicsData?.firstNameToUse || '',
      lastNameToUse: demographicsData?.lastNameToUse || '',
      middleNameToUse: demographicsData?.middleNameToUse || '',
    }),
    birthOfDate: DateOfBirth,
    age: getAgeWithMonths(demographicsData.DateOfBirth || ''),
    pronouns: demographicsData.pronouns,
    genderIdentity: demographicsData.genderIdentity || '',
  }
  return coreIdentifiers
}

export const mapPatientInfoToDemographics = (
  patientInfo: PatientInfo
): DemographicsContactInfo => {
  const DateOfBirth =
    patientInfo?.DateOfBirth &&
    normalizedAndFormatDate(patientInfo?.DateOfBirth, 'MM/dd/yyyy')
  const demographics: PatientInfo = {
    firstName: patientInfo?.firstName || '',
    middleName: patientInfo?.middleName || '',
    lastName: patientInfo?.lastName || '',
    firstNameToUse: patientInfo?.firstNameToUse || '',
    middleNameToUse: patientInfo?.middleNameToUse || '',
    lastNameToUse: patientInfo?.lastNameToUse || '',
    formerFirstName: patientInfo?.formerFirstName || '',
    formerMiddleName: patientInfo?.formerMiddleName || '',
    formerLastName: patientInfo?.formerLastName || '',
    DateOfBirth,
    birthSex: patientInfo?.birthSex || '',
    legalSex: patientInfo?.legalSex || undefined,
    pronouns: patientInfo?.pronouns || undefined,
    genderIdentity: patientInfo?.genderIdentity || '',
    race: patientInfo?.race || undefined,
    raceSubcategory: patientInfo.raceSubcategory || undefined,
    ethnicity: patientInfo?.ethnicity || '',
    specificEthnicity: patientInfo?.specificEthnicity || undefined,
    notes: patientInfo?.notes || '',
    addressLine1: patientInfo?.AddressLine1 || '',
    addressLine2: patientInfo?.AddressLine2 || '',
    city: patientInfo?.City || '',
    state: patientInfo?.State || '',
    zipCode: patientInfo?.zipCode || '',
    cellPhone: patientInfo?.cellPhone || '',
    homePhone: patientInfo?.homePhone || '',
    workPhone: patientInfo?.workPhone || '',
    ChannelUrl: patientInfo?.ChannelUrl || '',
    PatientRCopiaId: patientInfo?.PatientRCopiaId || '',
    PublicId: patientInfo?.PublicId || '',
    email: patientInfo?.email || '',
    CognitoId: patientInfo?.CognitoId || '',
  }
  return demographics
}

export const mapPatientInfoToCoreIdentifiers = (
  patientInfo: PatientInfo
): CoreIdentifiers => {
  const DateOfBirth =
    patientInfo?.DateOfBirth &&
    normalizedAndFormatDate(patientInfo?.DateOfBirth, 'M-d-yyyy')
  const coreIdentifiers: CoreIdentifiers = {
    summary: patientInfo?.summary || '',
    patientFirstName: patientInfo?.firstName || '',
    patientLastName: patientInfo?.lastName || '',
    patientName: patientInfo?.PatientName || '',
    patientNameToUse: patientInfo?.firstNameToUse || '',
    displayName: generateCoreIdDisplayName({
      firstName: patientInfo?.firstName || '',
      lastName: patientInfo?.lastName || '',
      middleName: patientInfo?.middleName || '',
      firstNameToUse: patientInfo?.firstNameToUse || '',
      lastNameToUse: patientInfo?.lastNameToUse || '',
      middleNameToUse: patientInfo?.middleNameToUse || '',
    }),
    birthOfDate: DateOfBirth,
    age: getAgeWithMonths(patientInfo.DateOfBirth || ''),
    suicidalIntention: Boolean(patientInfo.HasRecentSuicidalThoughts),
    pronouns:
      (patientInfo.pronouns &&
        patientInfo.pronouns.length > 0 &&
        patientInfo.pronouns) ||
      undefined,
    genderIdentity: patientInfo.genderIdentity || undefined,
    tags: patientInfo.patientTags,
  }
  return coreIdentifiers
}

export const adjustPatientBirthdate = (patientBirthdate: DateISO8601) => {
  const dateWithoutTime = format(new Date(patientBirthdate), 'yyyy-MM-dd')
  return `${dateWithoutTime}T00:00:00.000Z`
}
