import MonthView, { MonthProps } from './MonthNoWeekendView'

//We need to cast MonthView as unknown as React.FC<MonthProps> because of a type error from react-big-calendar MonthView
const MonthViewComponent = MonthView as unknown as React.FC<MonthProps>

function MyCustomView(props: MonthProps) {
  return <MonthViewComponent {...props} />
}

MyCustomView.range = MonthView.range
MyCustomView.navigate = MonthView.navigate
MyCustomView.title = MonthView.title

export default MyCustomView
