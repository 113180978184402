import React, { useMemo } from 'react'

import { Col, Row } from 'antd'
import { sumBy } from 'lodash'

import { centsToUsdString } from '../../../libs/billing'
import { LineItem } from '../../../shared-types'

import styles from '../../../stories/Invoice/Invoice.module.scss'

const GUTTER_WIDTH = 24
const ANT_TABLE_PADDING = 8
const DEFAULT_TOTAL_OFFSET = '70%'
const SECTION_WIDTH = 450

export const ChargeBreakdown = ({
  isEditing,
  lineItems,
  testId,
}: {
  isEditing: boolean
  lineItems: LineItem[]
  testId?: string
}) => {
  const [leftOffset, setLeftOffset] = React.useState(
    DEFAULT_TOTAL_OFFSET as string | number
  )

  // This useEffect lines up the total tally with the Total Charges column:
  //   1. when the page loads
  //   2. whenever the lineitems change because the table will auto-size
  React.useEffect(() => {
    const handleResize = () => {
      const totalChargeColumn = document.querySelectorAll('.totalCharge')[0]
      const totalChargePosition = totalChargeColumn?.getClientRects()[0]

      const lineItemsTable = document.querySelectorAll('.line-items')[0]
      const tablePosition = lineItemsTable?.getClientRects()[0]

      const getOffset = () => {
        if (tablePosition && totalChargePosition) {
          const totalChargePositionLeftOffset = totalChargePosition.left
          const tableWidth = tablePosition.width

          const offset =
            totalChargePositionLeftOffset -
            SECTION_WIDTH -
            2 * GUTTER_WIDTH +
            ANT_TABLE_PADDING +
            40
          const maxOffset = tableWidth - SECTION_WIDTH

          return Math.min(offset, maxOffset)
        } else {
          return '70%'
        }
      }

      const offset = getOffset()

      setLeftOffset(offset)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [lineItems, isEditing])

  const totalAmount = useMemo(() => {
    return sumBy(lineItems, (lineItem) => {
      return lineItem.units * lineItem.unitChargeAmountCents
    })
  }, [lineItems])

  return (
    <div
      className={styles.billToInsurance}
      style={{ width: SECTION_WIDTH, marginLeft: leftOffset }}
      data-testid={testId}
    >
      <Row className={styles.proceduresTotalRow}>
        <Col className={styles.proceduresTotalColBold}>Bill to insurance</Col>
        <Col className={styles.proceduresTotalCol}>
          {centsToUsdString(totalAmount)}
        </Col>
      </Row>
    </div>
  )
}
