import { useCallback, useEffect, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'

import { syncRCopiaAllergies, syncRCopiaMeds } from '../../api/api-lib'
import { usePatientAllergies } from '../../hooks/usePatientAllergies'
import { usePatientDiagnoses } from '../../hooks/usePatientDiagnoses'
import { usePatientDemographics } from '../../hooks/usePatientInfo'
import { useProviderSidePatientData } from '../../hooks/useProviderSidePatientData'
import useQueryString from '../../hooks/useQueryString'
import { InfoPage, Skeleton } from '../../stories/BaseComponents'
import { StandardSkeletonRows } from '../../stories/BaseComponents/Skeleton'
import Allergies from './Allergies'
import DevelopmentalHistory from './DevelopmentalHistory/DevelopmentalHistory'
import Diagnoses from './Diagnoses'
import FamilyHistory from './FamilyHistory/FamilyHistory'
import General from './General/General'
import Immunizations from './Immunizations/Immunizations'
import MedicalSurgicalHistory from './MedicalSurgicalHistory/MedicalSurgicalHistory'
import OtherNotes from './OtherNotes/OtherNotes'
import { PatientHeader } from './PatientHeader'
import PhysicalNotes from './PhysicalNotes/PhysicalNotes'
import PsychiatricHistory from './PsychiatricHistory'
import SocialHistoryNotes from './SocialHistoryNotes/SocialHistoryNotes'
import { patientDataTypes } from './patient-data-types'

import styles from '../_shared.module.scss'
import './Patient.scss'

const ErrorView = (
  <InfoPage
    status="warning"
    title="Sorry there was a problem loading this page"
    details="Oops something went wrong. Please contact your Osmind representative if this issue persists."
    redirectButtonText="Return to Patient List"
    redirectLink="/"
  />
)

export default function PatientProfile({
  healthGorillaUserName,
}: {
  healthGorillaUserName: string
}) {
  const queryClient = useQueryClient()
  const query = useQueryString()
  const patientId = query.get('patientId')
  const providerId = query.get('providerId')
  const [openAllergies, setOpenAllergies] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const param = queryParams.get('patientProfile')

    if (param === 'allergies') {
      setOpenAllergies(true)
      // remove from query params; only used to focus on allergy section, redirected from Notes
      queryParams.delete('patientProfile')
      history.replace({
        search: queryParams.toString(),
      })
    }
  }, [])

  const {
    isLoading: patientIsLoading,
    isError: patientIsError,
    data: patientData,
  } = usePatientDemographics(patientId ?? '')

  const {
    isLoading: providerSidePatientIsLoading,
    isError: providerSidePatientIsError,
    data: providerSidePatientData,
  } = useProviderSidePatientData(patientId ?? '')

  const {
    isLoading: diagnosesIsLoading,
    isError: diagnosesIsError,
    data: diagnosesData,
  } = usePatientDiagnoses(patientId ?? '')

  const {
    isLoading: allergiesIsLoading,
    isError: allergiesIsError,
    data: allergiesData,
  } = usePatientAllergies(patientId ?? '')

  const handleApiChange = useCallback(
    async (queryName: string[]) => {
      queryClient.invalidateQueries(queryName)
    },
    [queryClient]
  )

  const updateProviderSidePatientData = useCallback(() => {
    queryClient.invalidateQueries([
      patientDataTypes.ProviderSidePatientData,
      patientId,
    ])
  }, [queryClient])

  const refreshPatientAllergies = useCallback(() => {
    queryClient.invalidateQueries([
      patientDataTypes.PatientAllergies,
      patientId,
    ])
  }, [queryClient])

  const refreshPatientMedications = useCallback(() => {
    queryClient.invalidateQueries([
      patientDataTypes.PatientMedications,
      patientId,
    ])
  }, [queryClient])

  useEffect(() => {
    async function downloadRCopiaData() {
      try {
        const data = {
          PatientRCopiaId: patientData.PatientRCopiaId,
          PatientId: patientData.PatientId,
        }
        await syncRCopiaAllergies({
          ...data,
          LastAllergyUpdateTimestamp:
            patientData.LastAllergyUpdateTimestamp || '',
        })
        await syncRCopiaMeds({
          ...data,
          LastMedicationUpdateTimestamp:
            patientData.LastMedicationUpdateTimestamp || '',
        })
        updateProviderSidePatientData()
      } catch (error) {
        console.error(
          `Download data calls to RCopia failed for PatientRCopiaId ${patientData.PatientRCopiaId} with error - `
        )
      }
    }
    // if this patient is onboarded for erx, download their meds and allergies from RCopia to sync with Osmind if updates were made since last call
    if (patientData?.PatientRCopiaId) {
      downloadRCopiaData()
    }
  }, [patientData])

  const queriesLoading =
    patientIsLoading ||
    providerSidePatientIsLoading ||
    diagnosesIsLoading ||
    allergiesIsLoading

  const noQueryData = !providerId || !providerSidePatientData

  // if an error occurs show error
  // TODO: implement our error page
  if (
    patientIsError ||
    providerSidePatientIsError ||
    diagnosesIsError ||
    allergiesIsError ||
    !patientId ||
    !providerId
  ) {
    return ErrorView
  }

  return (
    <div className={styles.scroll}>
      <PatientHeader
        providerId={providerId}
        patientId={patientId}
        healthGorillaUserName={healthGorillaUserName}
      />
      <div className={'patientProfileContainer'}>
        {queriesLoading || noQueryData ? (
          <Skeleton paragraph={{ rows: StandardSkeletonRows.fullPage }} />
        ) : (
          <>
            <General
              patient={patientData}
              providerId={providerId}
              handleApiChange={handleApiChange}
              response={patientData}
              patientId={patientData?.PatientId}
            />
            <Diagnoses
              Patient={patientData}
              Diagnoses={diagnosesData || []}
              handleApiChange={handleApiChange}
            />
            <div id="patient-profile-allergies" className="allergy-scroll">
              <Allergies
                Patient={patientData}
                Allergies={allergiesData}
                handleApiChange={() => {
                  refreshPatientAllergies()
                  refreshPatientMedications()
                }}
                openAllergies={openAllergies}
              />
            </div>
            <PsychiatricHistory
              Patient={patientData}
              ProviderId={providerId}
              PsychHistory={providerSidePatientData.PsychiatricHistory || {}}
              handleApiChange={handleApiChange}
            />
            <MedicalSurgicalHistory
              patientId={patientData.PatientId}
              handleApiChange={handleApiChange}
              response={providerSidePatientData.MedicalSurgicalNotes || {}}
            />
            <PhysicalNotes
              patientId={patientData.PatientId}
              handleApiChange={handleApiChange}
              response={providerSidePatientData.PhysicalNotes || {}}
            />
            <FamilyHistory
              handleApiChange={handleApiChange}
              response={providerSidePatientData.FamilyHistory || {}}
              patientId={patientData.PatientId}
            />
            <SocialHistoryNotes
              patientId={patientData.PatientId}
              handleApiChange={handleApiChange}
              response={providerSidePatientData.SocialHistoryNotes || {}}
            />
            <DevelopmentalHistory
              handleApiChange={handleApiChange}
              response={providerSidePatientData.DevelopmentalHistory || {}}
              patientId={patientData.PatientId}
            />
            <Immunizations
              handleApiChange={handleApiChange}
              response={providerSidePatientData.Immunizations || {}}
              patientId={patientData.PatientId}
            />
            <OtherNotes
              handleApiChange={handleApiChange}
              otherNotes={providerSidePatientData.OtherNotes || {}}
              patientId={patientData.PatientId}
            />
          </>
        )}
      </div>
    </div>
  )
}
