import React, { useEffect } from 'react'

import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import { v4 } from 'uuid'

import { getBillingCodeDisplayName } from '../../../libs/billing'
import { LineItem } from '../../../shared-types'
import {
  Button,
  Dropdown,
  Input,
  InputNumber,
  Table,
  Tooltip,
} from '../../../stories/BaseComponents'
import { MenuItemType } from '../../../stories/BaseComponents/Dropdown'
import { NdcIdQualifier, NdcItem } from '../ClaimsV2/types'
import NationalDrugCodeSelect, { NdcOption } from './NationalDrugCodeSelect'
import { NDC_ID_QUALIFIER_OPTIONS, NDC_UNIT_TYPE_OPTIONS } from './constants'

import styles from './NationalDrugCode.module.scss'

export const testIds = {
  container: 'claim-national-drug-code',
  table: 'claim-national-drug-code-table',
  row: 'national-drug-code-table-row',
  billingCodeOption: 'national-drug-code-billing-code-option',
  idQualifierOption: 'national-drug-code-id-qualifier-option',
  unitTypeOption: 'national-drug-code-unit-type-option',
  addButton: 'national-drug-code-add-button',
}

export type NationalDrugCodeProps = {
  lineItems: LineItem[]
  ndcItems: NdcItem[]
  onNdcItemsChange: (items: NdcItem[]) => void
  isEditingDisabled?: boolean
}

export const INPUT_NAMES = {
  billingCodeId: 'billingCodeId',
  idQualifier: 'idQualifier',
  ndc: 'ndc',
  numberOfUnits: 'numberOfUnits',
  unitType: 'unitType',
  prescriptionNumber: 'prescriptionNumber',
}

export const POPUP_CONTAINER_ID = 'NDCTablePopUpContainer'

const NUMBER_OF_UNITS_MAX_DECIMAL_PLACES = 3

type DropDownOption = {
  label: string
  value: string | number
  disabled?: boolean
}
const getDropdownMenu = (
  options: DropDownOption[],
  onClick: (selectedValue: string | number) => void,
  testIdPrefix: string
): MenuItemType[] =>
  options.map(({ label, value, disabled }) => ({
    text: label,
    key: `${value}`,
    testId: `${testIdPrefix}-${value}`,
    onClick: () => onClick(value),
    disabled,
  }))

export const getNewNdcItem = (): NdcItem => {
  return {
    key: v4(),
    billingCodeId: null,
    idQualifier: NdcIdQualifier.NDC_IN_5_4_2_FORMAT,
    ndc: null,
    ndcLabel: null,
    numberOfUnits: 1,
    unitType: null,
    prescriptionNumber: null,
  }
}

const trimNumberDecimalPlaces = (num: number, decimalPlaces: number) => {
  const multiplier = Math.pow(10, decimalPlaces)
  return Math.floor((num ?? 0) * multiplier) / multiplier
}

const Label: React.FC<{ text: string; required?: boolean }> = ({
  text,
  required = false,
}) => (
  <>
    {text}
    {required ? <span className={styles.requiredSymbol}>{'*'}</span> : null}
  </>
)
const InCellDropdown: React.FC<{
  label: React.ReactNode
  menuItems: MenuItemType[]
  disabled?: boolean
  style?: React.CSSProperties
  testId: string
}> = ({ label, menuItems, disabled, style, testId }) => (
  <Dropdown
    items={menuItems}
    placement="bottomLeft"
    trigger={['click']}
    getPopupContainer={() =>
      document.getElementById(POPUP_CONTAINER_ID) as HTMLElement
    }
    disabled={disabled}
  >
    <div className={styles.dropDownButton} style={style} data-testid={testId}>
      <span>{label}</span>
      <DownOutlined style={{ fontSize: 12, verticalAlign: 'middle' }} />
    </div>
  </Dropdown>
)

const NationalDrugCode: React.FC<NationalDrugCodeProps> = ({
  lineItems,
  ndcItems,
  onNdcItemsChange,
  isEditingDisabled = false,
}) => {
  const selectedBillingCodeIds = ndcItems
    .map((el) => el.billingCodeId)
    .filter((id) => id)

  const handleDelete = (item: NdcItem) => {
    onNdcItemsChange(ndcItems.filter((el) => el.key !== item.key))
  }

  const handleAdd = () => {
    onNdcItemsChange([...ndcItems, getNewNdcItem()])
  }

  const handleUpdate = (
    inputName: string,
    inputValue: string | number,
    itemKey: string
  ) => {
    const newNdcItems = ndcItems.map((el) => {
      if (el.key === itemKey) {
        return { ...el, [inputName]: inputValue }
      } else {
        return el
      }
    })

    onNdcItemsChange(newNdcItems)
  }

  const handleUpdateIdQualifier = (newIdQualifier: string, item: NdcItem) => {
    const isIdQualifierChanged = newIdQualifier !== item.idQualifier
    if (isIdQualifierChanged) {
      const newNdc = null
      const newNdcItems = ndcItems.map((el) => {
        if (el.key === item.key) {
          return {
            ...el,
            [INPUT_NAMES.idQualifier]: newIdQualifier,
            [INPUT_NAMES.ndc]: newNdc,
            ndcLabel: null,
          }
        } else {
          return el
        }
      })

      onNdcItemsChange(newNdcItems)
    }
  }

  const handleUpdateNdcSelect = (newOption: NdcOption, itemKey: string) => {
    const { label: newNdcLabel, value: newNdcValue } = newOption
    const newNdcItems = ndcItems.map((el) => {
      if (el.key === itemKey) {
        return { ...el, [INPUT_NAMES.ndc]: newNdcValue, ndcLabel: newNdcLabel }
      } else {
        return el
      }
    })

    onNdcItemsChange(newNdcItems)
  }

  // reset billing code for NDC if the billing code is deleted
  useEffect(() => {
    const availableBillingCodeIds = lineItems.map((el) => el.billingCode.id)
    const newNdcItems = ndcItems.map((el) => {
      if (
        el.billingCodeId &&
        !availableBillingCodeIds.includes(el.billingCodeId)
      ) {
        return {
          ...el,
          billingCodeId: null,
        }
      }
      return el
    })
    onNdcItemsChange(newNdcItems)
  }, [lineItems])

  const columns: ColumnsType<NdcItem> = [
    {
      title: <Label text="Linked billing code" required />,
      dataIndex: INPUT_NAMES.billingCodeId,
      fixed: 'left',
      width: 242,
      render: (value: string, item: NdcItem) => {
        const handleSelect = (selectedValue: string | number) => {
          handleUpdate(INPUT_NAMES.billingCodeId, selectedValue, item.key)
        }
        const billingCodeOptions = lineItems.map((el) => {
          const billingCodeId = el.billingCode.id as number
          const option: DropDownOption = {
            label: getBillingCodeDisplayName(el.billingCode),
            value: billingCodeId,
          }
          if (
            selectedBillingCodeIds.includes(billingCodeId) &&
            billingCodeId !== item.billingCodeId
          ) {
            option.disabled = true
          }
          return option
        })
        const menuItems = getDropdownMenu(
          billingCodeOptions,
          handleSelect,
          `${item.key}-${testIds.billingCodeOption}`
        )
        const displayText =
          menuItems.find((el: MenuItemType) => el.key === `${value}`)?.text ??
          'Select'
        return billingCodeOptions.length === 0 ? (
          <Tooltip
            title={
              'Please add the billing code to the claim that you plan to attach this National Drug code to'
            }
            placement="right"
            getPopupContainer={() =>
              document.getElementById(POPUP_CONTAINER_ID) as HTMLElement
            }
          >
            <div
              style={{ minWidth: 120 }}
              data-testid={`${testIds.row}-${item.key}-${INPUT_NAMES.billingCodeId}-info`}
            >
              <span style={{ marginRight: 4 }}>Add a billing code</span>
              <InfoCircleOutlined style={{ fontSize: '12px' }} />
            </div>
          </Tooltip>
        ) : (
          <InCellDropdown
            menuItems={menuItems}
            label={displayText}
            disabled={isEditingDisabled}
            style={{ minWidth: 120 }}
            testId={`${testIds.row}-${item.key}-${INPUT_NAMES.billingCodeId}`}
          />
        )
      },
    },
    {
      title: <Label text="ID Qualifier" required />,
      dataIndex: INPUT_NAMES.idQualifier,
      width: 242,
      render: (value: string, item: NdcItem) => {
        const handleSelect = (selectedValue: string | number) => {
          handleUpdateIdQualifier(selectedValue as string, item)
        }
        const menuItems = getDropdownMenu(
          NDC_ID_QUALIFIER_OPTIONS,
          handleSelect,
          `${item.key}-${testIds.idQualifierOption}`
        )
        const displayText =
          menuItems.find((el: MenuItemType) => el.key === value)?.text ??
          'Select'
        return (
          <InCellDropdown
            menuItems={menuItems}
            label={displayText}
            disabled={isEditingDisabled}
            style={{ minWidth: 150 }}
            testId={`${testIds.row}-${item.key}-${INPUT_NAMES.idQualifier}`}
          />
        )
      },
    },
    {
      title: <Label text="NDC code number" required />,
      dataIndex: INPUT_NAMES.ndc,
      width: 242,
      render: (value: string, item: NdcItem) => {
        const isSearchable =
          item.idQualifier === NdcIdQualifier.NDC_IN_5_4_2_FORMAT
        return isSearchable ? (
          <NationalDrugCodeSelect
            value={value}
            initialLabel={item.ndcLabel}
            onChange={(newOption) => {
              handleUpdateNdcSelect(newOption, item.key)
            }}
            disabled={isEditingDisabled}
            popupContainerId={POPUP_CONTAINER_ID}
            style={{ width: 220 }}
            testId={`${testIds.row}-${item.key}-${INPUT_NAMES.ndc}-select`}
          />
        ) : (
          <Input
            value={value}
            onChange={(e) => {
              handleUpdate(INPUT_NAMES.ndc, e.target.value, item.key)
            }}
            placeholder="Enter drug code"
            disabled={isEditingDisabled}
            style={{ width: 220 }}
            suffix={
              <Tooltip
                title={'Enter drug code manually'}
                placement="right"
                getPopupContainer={() =>
                  document.getElementById(POPUP_CONTAINER_ID) as HTMLElement
                }
              >
                <InfoCircleOutlined
                  style={{
                    fontSize: '12px',
                    color: 'rgba(0, 0, 0, 0.25)',
                  }}
                />
              </Tooltip>
            }
            testId={`${testIds.row}-${item.key}-${INPUT_NAMES.ndc}-input`}
          />
        )
      },
    },
    {
      title: <Label text="Number of units" required />,
      dataIndex: INPUT_NAMES.numberOfUnits,
      width: 120,
      render: (value: number, item: NdcItem) => (
        <InputNumber
          value={value}
          onChange={(newVal) => {
            const trimmedVal = trimNumberDecimalPlaces(
              (newVal as number | null) ?? 0,
              NUMBER_OF_UNITS_MAX_DECIMAL_PLACES
            )
            handleUpdate(INPUT_NAMES.numberOfUnits, trimmedVal, item.key)
          }}
          min={1 / Math.pow(10, NUMBER_OF_UNITS_MAX_DECIMAL_PLACES)}
          disabled={isEditingDisabled}
          testId={`${testIds.row}-${item.key}-${INPUT_NAMES.numberOfUnits}`}
        />
      ),
    },
    {
      title: <Label text="Unit type" required />,
      dataIndex: INPUT_NAMES.unitType,
      width: 140,
      render: (value: string, item: NdcItem) => {
        const handleSelect = (selectedValue: string | number) =>
          handleUpdate(INPUT_NAMES.unitType, selectedValue, item.key)
        const menuItems = getDropdownMenu(
          NDC_UNIT_TYPE_OPTIONS,
          handleSelect,
          `${item.key}-${testIds.unitTypeOption}`
        )
        const displayText =
          menuItems.find((el: MenuItemType) => el.key === value)?.text ??
          'Select'
        return (
          <InCellDropdown
            menuItems={menuItems}
            label={displayText}
            disabled={isEditingDisabled}
            style={{ minWidth: 108 }}
            testId={`${testIds.row}-${item.key}-${INPUT_NAMES.unitType}`}
          />
        )
      },
    },
    {
      title: <Label text="Prescription number" />,
      dataIndex: INPUT_NAMES.prescriptionNumber,
      width: 140,
      render: (value: string, item: NdcItem) => (
        <Input
          value={value}
          onChange={(e) =>
            handleUpdate(
              INPUT_NAMES.prescriptionNumber,
              e.target.value,
              item.key
            )
          }
          placeholder="Enter prescription number"
          disabled={isEditingDisabled}
          testId={`${testIds.row}-${item.key}-${INPUT_NAMES.prescriptionNumber}`}
        />
      ),
    },
    {
      title: <Label text="Actions" />,
      dataIndex: 'actions',
      width: 84,
      render: (_value, item: NdcItem) => (
        <Button
          testId={`${testIds.row}-${item.key}-delete-button`}
          type="link"
          danger
          onClick={() => handleDelete(item)}
          style={{ marginLeft: -16 }}
          disabled={isEditingDisabled}
        >
          Delete
        </Button>
      ),
    },
  ]

  return (
    <div className={styles.container} data-testid={testIds.container}>
      {ndcItems?.length ? (
        <>
          <div className={styles.tableHeader}>
            <span>National Drug Code</span>
          </div>
          <Table
            showBoxContainer={false}
            testId={testIds.table}
            dataSource={ndcItems}
            pagination={false}
            columns={columns}
            noToolsSection
            scroll={{ x: true }}
          />
        </>
      ) : null}
      <Button
        type="link"
        onClick={handleAdd}
        className={styles.addButton}
        disabled={isEditingDisabled}
        testId={testIds.addButton}
      >
        + Add a National Drug Code
      </Button>
      <div id={POPUP_CONTAINER_ID} />
    </div>
  )
}

export default NationalDrugCode
