import React, { useEffect, useMemo, useRef, useState } from 'react'

// importing from antd bc otherwise refs won't work with the component (for now)
import { Select } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { BaseSelectRef } from 'rc-select'
import { useHistory } from 'react-router-dom'

import Spinner from '../Spinner'
import InlineEditBase, { focusRef } from './InlineEditBase'
import { ActiveComponentProps, BaseInterface } from './shared-types'

import styles from './_shared.module.scss'

export interface Option {
  value: string
  label: string
  options?: Array<Option>
}

export interface Props extends BaseInterface {
  rows?: number
  size?: SizeType
  mode?: 'multiple' | 'tags'
  allowClear?: boolean
  showSearch?: boolean
  filterSort?: (optionA: Option, optionB: Option) => number
  options: Array<Option>
  value?: string | Array<string>
  dropdownWidth?: number
  isWrongValue?: boolean
  isFetching?: boolean
}

/**
  Editable inline select
*/
const InlineEditSelect = ({
  placeholder,
  requireConfirmation = false,
  mode,
  size,
  options,
  testId,
  fieldWidth,
  dropdownWidth,
  showSearch = true,
  value: initialValue,
  filterSort,
  autoFocus,
  allowClear,
  isWrongValue = false,
  isFetching,
  ...props
}: Props) => {
  const history = useHistory()
  const selectRef: React.Ref<BaseSelectRef> = useRef(null)
  const selectContainerRef: React.Ref<HTMLDivElement> = useRef(null)
  const [selectValue, setSelectValue] = useState<string | Array<string>>('')
  const [rendered, setRendered] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRendered(true)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    setSelectValue(initialValue ?? '')
  }, [initialValue, isWrongValue])

  const ActiveSelect = useMemo(
    () =>
      ({
        handleChange,
        value,
        toggleSave,
        toggleBlur,
      }: ActiveComponentProps) => {
        return (
          <Select
            className={`${styles.activeSelect} ${!value && styles.noValue}`}
            dropdownClassName="inline-active-select"
            style={{ resize: 'vertical' }}
            ref={selectRef}
            showSearch={showSearch}
            autoFocus
            allowClear={allowClear}
            size={size}
            dropdownMatchSelectWidth={false}
            placement="topLeft"
            filterSort={filterSort}
            dropdownStyle={{ width: dropdownWidth, minWidth: dropdownWidth }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            data-testid={`inline-dropdown-${testId}`}
            showAction={['focus']}
            open={open}
            value={selectValue}
            onInputKeyDown={(e) => {
              if (e.key === 'Escape' && open) {
                setOpen(false)
                return
              }
              if (e.key === 'Escape' && !open) {
                toggleBlur && toggleBlur()
                return
              }
              if (
                (e.key === 'Enter' && e.ctrlKey) ||
                (e.key === 'Enter' && e.metaKey)
              ) {
                if (open) {
                  const prevValue = selectValue
                  setTimeout(() => {
                    setSelectValue(prevValue)
                    handleChange(prevValue)
                  }, 200)
                }
                toggleSave()
                return
              }
              if (!open && !e.ctrlKey && e.key !== 'Tab' && !e.metaKey) {
                setOpen(true)
              }
            }}
            onClick={() => {
              if (!open) {
                setOpen(true)
              }
            }}
            onChange={(e) => {
              setSelectValue(e)
              if (handleChange) handleChange(e)
              if (selectRef && selectRef.current && requireConfirmation) {
                selectRef.current.blur()
                setOpen(false)
              } else {
                selectRef?.current?.focus()
              }
            }}
            mode={mode}
            options={options}
            notFoundContent={
              isFetching ? (
                <div className={styles.spinnerContainer}>
                  <Spinner fontSize={24} />
                </div>
              ) : undefined
            }
          />
        )
      },
    [JSON.stringify(options), open, selectValue, mode, isFetching]
  )

  const getFormattedOptions = () => {
    if (!options[0]?.options) {
      return options
    }
    let tempOpts: Array<Option> = []
    options.forEach((e) => {
      tempOpts = [...tempOpts, ...(e.options ?? [])]
    })
    return tempOpts
  }

  const getValue = (): string | null => {
    if (!initialValue) return ''
    if (mode !== 'multiple') {
      return options.filter((e) => e.value === initialValue)[0]?.label
    }
    if (
      typeof initialValue === 'string' ||
      initialValue instanceof String ||
      !initialValue
    )
      return ''

    const filtered = getFormattedOptions().filter(
      (r) => initialValue.findIndex((e: string) => e === r.value) >= 0
    )
    if (filtered.length === 1) return filtered[0].label

    const mappedValues = initialValue.map((e: string) => {
      return getFormattedOptions().filter(({ value }) => value === e)[0]?.label
    })

    // hit when all tags are manually deselected
    if (mappedValues.length < 1) return null

    return mappedValues.join(', ').trim()?.toString()
  }

  return (
    <div className={styles.refContainer} ref={selectContainerRef}>
      <InlineEditBase
        {...props}
        activeComponent={(props) => <ActiveSelect {...props} />}
        value={getValue()}
        toggleActive={autoFocus}
        size={size}
        onCancel={() =>
          initialValue ? setSelectValue(initialValue) : setSelectValue([])
        }
        fieldWidth={fieldWidth}
        onActive={() =>
          focusRef(autoFocus, selectContainerRef, selectRef, rendered, () => {
            setOpen(true)
            const searchParams = new URLSearchParams(location.search)
            if (searchParams.toString().indexOf('field') !== -1) {
              history.push({
                search: location.search.replace(
                  searchParams
                    .toString()
                    .slice(searchParams.toString().indexOf('field') - 1),
                  ''
                ),
              })
            }
          })
        }
        autoSave={!requireConfirmation && mode !== 'multiple'}
        testId={testId}
        placeholder={placeholder}
        showActions={requireConfirmation}
        isWrongValue={isWrongValue}
        allowClear={allowClear}
      />
    </div>
  )
}

export default InlineEditSelect
