import React from 'react'

import { Button, Col, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import ShowHospitalizations from './Tables/ShowHospitalizations'

export default function Hospitalizations(props) {
  const { patientId = '', providerId = '' } = props

  function showSavedHospitalizations() {
    if (props.patientData.PreviousHospitalizations.length !== 0) {
      return (
        <>
          {props.patientData.PreviousHospitalizations.map((H, index) => {
            return (
              <ShowHospitalizations
                key={index}
                index={index}
                deleteHospitalization={props.deleteHospitalization}
                ReasonForHospitalization={H.reasonForHospitalization}
                LenghtOfHospitalization={H.lenghtOfHospitalization}
                StartDate={H.startDate}
                EndDate={H.endDate}
                HospitalName={H.hospitalName}
                AdditionalNotes={H.additionalNotes}
                DateFormat={H.dateFormat}
                disabled={props.disabled}
                saveHospitalization={props.saveHospitalization}
              />
            )
          })}
        </>
      )
    }
  }

  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Hospitalizations for mental health reasons:
          </Form.Label>
          {showSavedHospitalizations()}
          {props.patientData.PreviousHospitalizations.length === 0 && (
            <Form.Check
              type="checkbox"
              id="noHospitalizationCheckbox"
              label="No relevant previous treatments"
              checked={!!props.patientData.NoPreviousHospitalizations}
              onClick={() => {
                props.setPatientData({
                  ...props.patientData,
                  NoPreviousHospitalizations:
                    !props.patientData.NoPreviousHospitalizations,
                })
              }}
            />
          )}
        </Form.Group>
        {!props.patientData.NoPreviousHospitalizations && (
          <>
            <Form.Group
              as={Col}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginLeft: 'auto' }}
            >
              <Button
                bsPrefix="plain-button center info button-label"
                onClick={() => {
                  props.saveNewHospitalization()
                }}
              >
                + Add New Hospitalization
              </Button>
            </Form.Group>
            <hr />
            <Link
              to={{
                pathname: `/patient/medication?patientId=${patientId}&providerId=${providerId}`,
              }}
              target="_blank"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '10px',
              }}
            >
              <Button variant="primary">See more/edit Medications list</Button>
            </Link>
            <Form.Group as={Col} sm={12} md={12} lg={12} xl={12}>
              <Form.Text
                bsPrefix="center"
                style={{ color: '#A9A9A9', marginBottom: '10px' }}
              >
                Use the medication list to log all past and present medications
              </Form.Text>
            </Form.Group>
          </>
        )}
      </Form.Row>
    </>
  )
}
