import React, { useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import { cloneDeep } from 'lodash'

import SkeletonLoadingTransition from '../../../components/Animation/SkeletonLoadingTransition'
import { ReferringProviderType } from '../../../hooks/usePatientProfile/shared-types'
import Fields, { FieldGroupProps } from '../../../stories/PatientProfile/Fields'
import {
  handleRefferingProvUpdate,
  populateReferringProviderInfo,
} from './InsuranceClaim-helper'
import { referringProviderComponent } from './constants'

import styles from './InsuranceClaim.module.scss'

export interface PatientConditionRelationProps {
  testId?: string
  referringProviderInfo: ReferringProviderType
  setReferringProviderInfo: Function
  includeReferringProvider: boolean
  setIncludeReferringProvider: Function
  disabled?: boolean
}

const Loading = () => (
  <div className={styles.skeletonContainer}>
    <Skeleton
      active
      title={{ width: 85 }}
      paragraph={{
        rows: 3,
        width: '100%',
        className: styles.skeletonParagraph,
      }}
    />
  </div>
)

const referringProviderSection: React.FC<PatientConditionRelationProps> = ({
  testId,
  referringProviderInfo,
  setReferringProviderInfo,
  includeReferringProvider,
  setIncludeReferringProvider,
  disabled = false,
}) => {
  const [referringProvider, setReferringProvider] = useState<FieldGroupProps[]>(
    referringProviderComponent
  )

  useEffect(() => {
    const itemsCopy = cloneDeep(referringProviderComponent)
    if (referringProviderInfo) {
      populateReferringProviderInfo(itemsCopy, referringProviderInfo)
    }
    setReferringProvider(itemsCopy)
  }, [referringProviderInfo])

  const handleSave = (
    newValue: any,
    id: string,
    _groupName: string,
    _label?: string
  ) => {
    const newVal: any =
      typeof newValue === 'string' ? newValue.trim() : newValue
    const copyreferringProviderInfo = cloneDeep(referringProviderInfo)
    const shouldSave = handleRefferingProvUpdate(
      newVal,
      id,
      copyreferringProviderInfo
    )
    if (shouldSave) {
      setReferringProviderInfo(copyreferringProviderInfo)
    }
  }

  return (
    <SkeletonLoadingTransition
      isLoading={false}
      skeletonComponent={<Loading />}
      loadedComponent={
        <div className={styles.topMargin}>
          <Fields
            testId={testId}
            items={referringProvider}
            compact={false}
            handleSave={handleSave}
            isIncluded={includeReferringProvider}
            setIsIncluded={setIncludeReferringProvider}
            includedText="Include patient’s referring provider"
            isClaim
            disableAll={disabled}
          />
        </div>
      }
    />
  )
}

export default referringProviderSection
