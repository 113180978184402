import { ComponentType, FC, HTMLAttributes, useMemo } from 'react'

import { addHours, addMinutes, differenceInMinutes, set } from 'date-fns'
import { cloneDeep } from 'lodash'
import {
  Calendar,
  CalendarProps,
  DateLocalizer,
  SlotInfo,
  View,
  Views,
  stringOrDate,
} from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import {
  LocationItem,
  RoomItem,
} from '../../../containers/Authentication/Locations'
import { useShowWeekends } from '../../../hooks/useShowWeekends/useShowWeekends'
import { notification } from '../../../libs/notificationLib'
import { Event } from '../../../shared-types'
import {
  AgendaDate,
  AgendaEvent,
  AgendaTime,
} from '../CalendarViews/AgendaCustomViews'
import TimeSlotWrapper from '../CalendarViews/Components/TimeSlotWrapper'
import { DayView } from '../CalendarViews/DayView'
import {
  MonthDate,
  MonthEvent,
  MonthHeader,
} from '../CalendarViews/MonthCustomViews'
import WeekViewHeader from '../CalendarViews/WeekCustomViews'
import { PatientOption, TopToolbar } from '../TopToolbar'
import { Views as AvailableCalendarViews, Timezone } from '../constants'
import { EventCategory } from '../types'
import {
  getCurrentViewInformation,
  getMinMaxTimeOfDay,
} from './CustomCalendar.helpers'
import { EventCell } from './EventCell'

import '../CalendarViews/AgendaView.scss'
import '../CalendarViews/MonthView.scss'
import '../CalendarViews/WeekView.scss'
import '../Scheduling.scss'

interface Settings {
  startHour: number
  endHour: number
}

interface HandleSelectValues {
  // TODO: lib react-big-calendar consumes stringOrDate; Osmind API consumes DateISO8601 string. Can we use real Date objects, and only
  // TODO: convert to string just before an API call or a localized UI view?
  start: Date
  end: Date
}

export interface CustomCalendarProps {
  agendaLength?: number
  colorMap: Map<string, string>
  currentDate: Date
  defaultView?: AvailableCalendarViews
  currentView: AvailableCalendarViews
  localizer: DateLocalizer & { segmentOffset?: number } // adding this notation, because of a type error from react-big-calendar
  locations: LocationItem[]
  events: Event[]
  eventCategories?: EventCategory[] // would contain cognitoIds + rooms queried for
  settings?: Settings
  patientList: Map<string, string>
  patientOptions: PatientOption[]
  currentPatient: string
  isDev?: boolean
  rooms?: RoomItem[]
  setCurrentView: (view: View) => void
  handleSelectEvent: (event: Event) => void
  onSelectSlot: ({ start, end }: HandleSelectValues) => void
  handleNavigate: (selectedDate: Date) => Promise<void> | void
  handleTooltip?: (event: Event) => string
  eventPropGetter?: (
    event: Event,
    start: stringOrDate,
    end: stringOrDate
  ) => HTMLAttributes<HTMLDivElement>
  handlePatientChange: (patientId: string) => void
  onCreateEventClick: () => void
  onNotificationClick: () => void
  currentProviderId: string
  currentTimezone: Timezone
  onClearPatient: () => void
}

const MINIMUM_EVENT_TIME = 15
const getEventEndTime = (startTime: Date, initialEndTime: Date) => {
  const eventTime = differenceInMinutes(initialEndTime, startTime)
  if (eventTime >= MINIMUM_EVENT_TIME) {
    return initialEndTime
  }
  return addMinutes(startTime, MINIMUM_EVENT_TIME)
}

const DnDCalendar = withDragAndDrop(
  Calendar as ComponentType<CalendarProps<object, object>>
)

export const CustomCalendar: FC<CustomCalendarProps> = ({
  agendaLength,
  colorMap = new Map(),
  currentDate,
  defaultView,
  currentView,
  isDev = false,
  localizer,
  events,
  eventCategories,
  locations = [],
  rooms = [],
  settings,
  patientList,
  patientOptions,
  currentPatient,
  onClearPatient,
  setCurrentView,
  handleSelectEvent,
  onSelectSlot,
  handleNavigate,
  handleTooltip,
  eventPropGetter,
  handlePatientChange,
  onCreateEventClick,
  onNotificationClick,
  currentTimezone,
  currentProviderId,
}) => {
  /// When segmentedOffset is different than 0, events are not shown on saturdays.
  localizer.segmentOffset = 0

  const { showWeekends, toggleShowWeekends } = useShowWeekends()
  const { className, reactBigCalendarView, availableReactBigCalendarViews } =
    getCurrentViewInformation(currentView, showWeekends)

  const hours = useMemo(() => {
    return (settings?.startHour ? settings.startHour : 6) - 1
  }, [settings])

  const scrollToTime = set(new Date(), { hours, minutes: 50 })

  const { minTime, maxTime } = getMinMaxTimeOfDay(currentTimezone)

  // react-big-calendar forces this component definition
  // because it's types are incorrect
  const AgendaDateCell = (args: unknown) => (
    <AgendaDate currentDate={currentDate} day={(args as { day: Date }).day} />
  )

  const handleSelectSlot = (slot: SlotInfo) => {
    const startTime = new Date(slot.slots[0])
    const initialEndTime = new Date(slot.slots[slot.slots.length - 1])

    if (reactBigCalendarView === Views.MONTH) {
      // For timezone purposes, add 6 hours to start of date
      // when in month view to avoid date shifts when scheduling new event
      const newStart = addHours(startTime, 6)
      const newEndTime = addHours(initialEndTime, 6)
      onSelectSlot({
        ...slot,
        start: newStart,
        end: getEventEndTime(newStart, newEndTime),
      })
      return
    }
    onSelectSlot({
      ...slot,
      start: startTime,
      end: getEventEndTime(startTime, initialEndTime),
    })
  }

  const handleEventResize = (args: unknown) => {
    const { event, start, end } = args as {
      event: Event
      start: stringOrDate
      end: stringOrDate
    }

    const clonedEvent = cloneDeep(event)
    clonedEvent.start = new Date(start)
    clonedEvent.end = getEventEndTime(new Date(start), new Date(end))
    handleSelectEvent(clonedEvent)
  }

  // React-big-calendar types are incorrect
  const handleDraggedEvent = (args: unknown) => {
    const { end, event, resourceId, start } = args as {
      event: Event
      start: Date
      end: Date
      resourceId: string | null
    }

    if (currentView === Views.DAY && resourceId !== event.resourceId) {
      notification(
        'Open the event details to change the provider or room',
        'error'
      )
      return
    }

    const clonedEvent = cloneDeep(event)
    clonedEvent.start = new Date(start)
    clonedEvent.end = new Date(end)
    clonedEvent.resourceId = resourceId ?? undefined
    handleSelectEvent(clonedEvent)
  }

  return (
    <>
      <TopToolbar
        onCreateEventClick={onCreateEventClick}
        onPatientChange={handlePatientChange}
        onViewChange={setCurrentView}
        patients={patientOptions}
        currentDate={currentDate}
        currentView={currentView}
        currentPatient={currentPatient}
        currentTimezone={currentTimezone}
        onNotificationClick={onNotificationClick}
        onNavigationChange={handleNavigate}
        showWeekends={showWeekends}
        toggleShowWeekends={toggleShowWeekends}
        onClearPatient={onClearPatient}
      />
      <DnDCalendar
        selectable="ignoreEvents"
        className={`calendar scheduling-big-calendar ${className}-view-calendar`}
        timeslots={4}
        step={15}
        defaultView={defaultView}
        length={agendaLength}
        view={reactBigCalendarView}
        onView={setCurrentView}
        date={currentDate}
        onNavigate={handleNavigate}
        localizer={localizer}
        events={events}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        dayLayoutAlgorithm="no-overlap"
        scrollToTime={scrollToTime}
        // This prop is not in the react-big-calendar types
        // but does exist and enables loading the calendar
        // to the time set by scrollToTime
        enableAutoScroll
        views={availableReactBigCalendarViews}
        min={minTime}
        max={maxTime}
        tooltipAccessor={handleTooltip}
        onEventResize={handleEventResize}
        onEventDrop={handleDraggedEvent}
        components={{
          toolbar: () => <></>,
          event: ({ event }) => (
            <EventCell
              event={event}
              colorMap={colorMap}
              isDev={isDev}
              patientList={patientList}
              rooms={rooms}
              locations={locations}
              reactBigCalendarView={reactBigCalendarView}
              currentTimezone={currentTimezone}
            />
          ),
          agenda: {
            date: AgendaDateCell,
            time: AgendaTime as ComponentType,
            event: AgendaEvent,
          },
          month: {
            header: MonthHeader,
            dateHeader: MonthDate,
            event: ({ event, title }) => (
              <MonthEvent
                colorMap={colorMap}
                event={event}
                title={title}
                timezone={currentTimezone}
              />
            ),
          },
          week: {
            header: WeekViewHeader,
          },
          work_week: {
            header: WeekViewHeader,
          },
          // @ts-ignore: react-big-calendar types are incorrect
          // here, value does exist
          timeSlotWrapper: ({ value }: { value: Date }) => (
            <TimeSlotWrapper value={value} timezone={currentTimezone} />
          ),
          resourceHeader: ({ resource }) => (
            <DayView
              currentProviderId={currentProviderId}
              resource={resource as EventCategory}
            />
          ),
        }}
        eventPropGetter={eventPropGetter}
        resources={eventCategories}
        resourceIdAccessor={(event: unknown) => {
          return (event as { value: string }).value
        }}
        resourceTitleAccessor={(event: unknown) =>
          (event as { label: string }).label
        }
      />
    </>
  )
}
