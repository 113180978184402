import { IntakeForm } from '../../../../../../api/intakeForms'
import { Button, Spinner } from '../../../../../../stories/BaseComponents'
import ReviewPatientIntake from '../../../../../../stories/PatientIntake/ReviewPatientIntake'

import styles from './IntakeRowActions.module.scss'

type IntakeRowActionsProps = {
  newIntakeForm: IntakeForm
  resendingForm: boolean
  onResendIntakeInvite: ({ intakeFormId }: { intakeFormId: string }) => void
}

export const IntakeRowActions = ({
  newIntakeForm,
  onResendIntakeInvite,
  resendingForm,
}: IntakeRowActionsProps) => {
  const handleResendIntakeInvite = () => {
    onResendIntakeInvite({ intakeFormId: newIntakeForm.id })
  }
  if (newIntakeForm.importedAt) {
    return (
      <ReviewPatientIntake
        formId={newIntakeForm.id}
        toggleButtonText="View Form"
      />
    )
  }

  if (newIntakeForm.reviewedAt) {
    return (
      <ReviewPatientIntake
        formId={newIntakeForm.id}
        toggleButtonText="Import Form"
      />
    )
  }
  if (newIntakeForm.completedAt) {
    return (
      <ReviewPatientIntake
        formId={newIntakeForm.id}
        toggleButtonText="Review and Import"
      />
    )
  }

  if (newIntakeForm.startedAt) {
    if (resendingForm) {
      return <Spinner />
    }
    return (
      <Button
        type="default"
        className={styles.btnPrimary}
        onClick={handleResendIntakeInvite}
      >
        Resend Form
      </Button>
    )
  }

  if (newIntakeForm.lastInvitedAt) {
    if (resendingForm) {
      return <Spinner />
    }

    return (
      <Button
        type="default"
        className={styles.btnPrimary}
        onClick={handleResendIntakeInvite}
      >
        Resend Form
      </Button>
    )
  }

  return null
}
