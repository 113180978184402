import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { isBefore } from 'date-fns'
import 'moment-timezone'

import {
  createNewClinicalNote,
  editClinicalNote,
  getLocation,
  markForcedEndEditComplete,
  markNoteAsNotBeingEdited,
  saveNewSignatures,
} from '../../api/api-lib'
import { getNoteById } from '../../api/notes'
import { onError } from '../../libs/errorLib'
import { NotesEvents, trackNotesEvent } from '../../libs/freshpaint/notesEvents'
import { notification } from '../../libs/notificationLib'
import Sentry from '../../libs/sentry'
import {
  ClinicalNote,
  LegacyNoteTypes,
  Location,
  NoteTypes,
  TypeLocation,
} from '../../shared-types'
import useAutoSaveNoteV1 from '../useAutoSaveNoteV1'
import { useRooms } from '../useBillingInfo'
import {
  Entries,
  Medications,
  NoteType,
  Signature,
  UpdateType,
  getFields,
} from './utils'

export default function useClinicalNoteFields({
  updateType,
  patient,
  patientId,
  patientPhysicalNotes,
  note,
  clinicData,
  previousNotes,
  inViewMode,
  disableNewNoteButton,
  setIsGeneratingREMS,
  saltFormattingFinalized,
}: any) {
  const { data: rooms } = useRooms()
  const { data: locations } = useQuery<Location[]>(['locations'], getLocation)

  const defaultFields = useMemo(() => {
    const prePopulateFields = note
    return getFields(
      updateType,
      patientId,
      prePopulateFields,
      clinicData,
      patientPhysicalNotes
    )
  }, [clinicData, note, patient, updateType])

  const [fields, setFields] = useState(defaultFields)
  const [isSaving, setIsSaving] = useState(false)
  const [showUnsignedAndInvalid, setShowUnsignedAndInvalid] =
    useState<boolean>(false)
  const [showSignedAndValid, setShowSignedAndValid] = useState<boolean>(false)
  const [showSignedAndInvalid, setShowSignedAndInvalid] =
    useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)

  const noteId = note?.NoteId

  const {
    isSaving: isSavingAutosave,
    lastSavedTimestamp,
    auditLogs,
    isNoteCreated,
    autosaveFailed,
    saveQueue,
    hasAnyPendingChanges,
    setDataToAutosave,
  } = useAutoSaveNoteV1(fields, noteId)

  const resetFields = useCallback(() => {
    return setFields(defaultFields)
  }, [defaultFields])

  useEffect(() => {
    if (saltFormattingFinalized) {
      return setFields(defaultFields)
    }
  }, [saltFormattingFinalized])

  const findMostRecentEntry = useCallback(
    (
      key: keyof ClinicalNote
    ): null | {
      value: Record<string, unknown>
      CreatedOn: string
    } => {
      // Legacy code updated to make more sense/be more explicit, this should grab the most recent note and return the CreatedOnDate and value of the specified 'key'
      if (!previousNotes || !previousNotes.length) {
        return null
      }

      let lastestNoteDate = new Date(-8640000000000000) // date counter
      let objectToReturn = null
      previousNotes.forEach((note: Record<string, any>) => {
        if (
          !note || // bad note data
          !note[key] || // undefined object
          (typeof note[key] === 'object' && !Object.values(note[key]).length) || // empty object
          isBefore(new Date(note.CreatedOnDate), lastestNoteDate) // not latest noted
        ) {
          return
        }

        objectToReturn = {
          value: note[key],
          CreatedOn: note.CreatedOn,
        }
        lastestNoteDate = new Date(note.CreatedOnDate)
      })

      return objectToReturn
    },
    [previousNotes]
  )

  const refetchOnEdit = useCallback(
    async (patientId: string, noteId: string) => {
      try {
        const upToDateNote = await getNoteById(patientId, noteId)
        const newFields = getFields(
          updateType,
          patient.PatientId,
          upToDateNote,
          clinicData
        )
        if (upToDateNote.Signatures?.length) {
          notification(
            'This note has already been signed, and does not allow further editing.',
            'failure'
          )
          setFields(newFields)
        } else {
          setFields({
            ...newFields,
            NewSignature: { Notes: '', SignedBy: '', SignedByProviderId: '' },
          })
        }
        return upToDateNote
      } catch (err) {
        console.error('Failed to refetch note with err:', err)
      }
    },
    [patient]
  )

  const updatedNoteAddress = useCallback(
    (roomId: string) => {
      const clinicDefault = {
        SpravatoProviderPhone: clinicData?.ProviderPhone || '',
        SpravatoClinicAddress1: clinicData?.ProviderPracticeAddress || '',
        SpravatoClinicAddress2: clinicData?.ProviderPracticeAddressExtn || '',
        SpravatoClinicCity: clinicData?.ProviderPracticeCity || '',
        SpravatoClinicState: clinicData?.ProviderPracticeState || '',
        SpravatoClinicZip: clinicData?.ProviderPracticeZipcode || '',
      }

      if (!rooms || !roomId || !locations) {
        return clinicDefault
      }

      const room = rooms.find((r) => r.roomId === roomId)
      if (!room) {
        return clinicDefault
      }

      const location = locations.find((l) => l.LocationId === room.locationId)
      // If the location is not found, or the location is not of type Physical,
      // then we want to set the address to the clinic default
      if (!location || location.Type !== TypeLocation.PHYSICAL) {
        return clinicDefault
      }

      const { AddressLine1, AddressLine2, City, State, Zipcode, PhoneNumber } =
        location

      return {
        SpravatoProviderPhone: PhoneNumber,
        SpravatoClinicAddress1: AddressLine1,
        SpravatoClinicAddress2: AddressLine2 || '',
        SpravatoClinicCity: City,
        SpravatoClinicState: State,
        SpravatoClinicZip: Zipcode,
      }
    },
    [rooms, locations, clinicData]
  )

  const handleEdit = useCallback(async () => {
    try {
      if (fields.Signatures?.length) {
        notification(
          'This note has already been signed, and does not allow further editing.',
          'failure'
        )
      } else {
        if (fields.SavedAdditionalMedications === null) {
          fields.SavedAdditionalMedications = []
        }
        await editClinicalNote({
          ...fields,
          NoteId: noteId,
          ReceivedTreatment:
            fields.NoteType === LegacyNoteTypes.IV_KETAMINE_INFUSION ||
            fields.NoteType === NoteTypes.IV_KETAMINE ||
            fields.NoteType === NoteTypes.IM_KETAMINE ||
            fields.NoteType === NoteTypes.KAP ||
            (fields.NoteType === NoteTypes.CLINICAL_NOTE &&
              fields.ReceivedTreatment),
        })

        // TODO: Condense the saveNewSignature call into the editClinicalNote endpoint
        //       to save on http requests.
        if (fields.NewSignature.SignedBy !== '') {
          await saveNewSignatures({
            Signatures: {
              Notes: fields.NewSignature.Notes,
              SignedBy: fields.NewSignature.SignedBy,
              SignedByProviderId: fields.NewSignature.SignedByProviderId,
            },
            PatientId: fields.PatientId,
            NoteId: noteId,
          })
          setFields((fields) => ({
            ...fields,
            NewSignature: { Notes: '', SignedBy: '', SignedByProviderId: '' },
          }))
        }

        notification(`Changes saved`, 'success')
      }
    } catch (error) {
      throw new Error('Failed to edit this note')
    }
  }, [fields, note?.NoteId, patient])

  const handleNew = useCallback(async () => {
    const { NoteId } = await createNewClinicalNote({
      ...fields,
      ReceivedTreatment:
        fields.NoteType === LegacyNoteTypes.IV_KETAMINE_INFUSION ||
        fields.NoteType === NoteTypes.IV_KETAMINE ||
        fields.NoteType === NoteTypes.IM_KETAMINE ||
        fields.NoteType === NoteTypes.KAP ||
        fields.NoteType === NoteTypes.SPRAVATO ||
        (fields.NoteType === NoteTypes.CLINICAL_NOTE &&
          fields.ReceivedTreatment),
      // Disables new note submission on first click
      ...(!fields.Signatures?.length &&
        fields.NewSignature.SignedBy !== '' && {
          Signatures: (fields.Signatures as Signature[]).concat({
            ...fields.NewSignature,
            CreatedAt: new Date().toISOString(),
          }),
        }),
    })

    notification(`${fields.Title ?? 'No title'} saved`, 'success')
    return NoteId
  }, [fields, patient])

  /**
   * Note submission function that takes
   * @param resetFieldsOnSuccess: boolean -- Trigger the resetFields function (legacy behavior that was preserved but problematic from note creation page)
   *
   * @returns boolean: -- True indicates nav-away is safe, False indicates shouldn't nav-away
   */
  const handleSave = useCallback<
    (
      resetFieldsOnSuccess?: boolean
    ) => Promise<{ shouldNavAway: boolean; NoteId?: string }>
  >(
    async (resetFieldsOnSuccess = true) => {
      try {
        setIsSaving(true)
        // disable the ability to make a new clinical note while one is saving until the sorting of the notes completes in NewClinicalNoteButton
        disableNewNoteButton?.() // not always available

        if (updateType === UpdateType.EDIT) {
          await handleEdit()
          trackNotesEvent(NotesEvents.SAVED_EDITED_NOTE, {
            patientId: patient.PatientId,
            noteId: note.NoteId,
            noteType: note.NoteType,
          })
          await markNoteAsNotBeingEdited({
            PatientId: note.PatientId,
            NoteId: note.NoteId,
            UpdateEnum: 'notBeingEdited',
          })
          setIsGeneratingREMS(false)
          if (
            fields.NoteType === NoteTypes.SPRAVATO &&
            fields.NewSignature.SignedBy === ''
          ) {
            setShowUnsignedAndInvalid(true)
          }
        }

        if (updateType === UpdateType.NEW) {
          const NoteId = await handleNew()
          trackNotesEvent(NotesEvents.SAVED_NEW_NOTE, {
            patientId: patient.PatientId,
            noteId: note.NoteId,
            noteType: fields.NoteType,
          })
          if (resetFieldsOnSuccess) resetFields()
          if (
            fields.NoteType === NoteTypes.SPRAVATO &&
            fields.NewSignature.SignedBy === ''
          ) {
            setShowUnsignedAndInvalid(true)
          }
          return {
            NoteId,
            shouldNavAway: fields.NoteType !== NoteTypes.SPRAVATO,
          }
        }

        setIsSaving(false)

        // Could nav away after submission for any note except Spravato bc it requires post-submission processing
        return { shouldNavAway: fields.NoteType !== NoteTypes.SPRAVATO }
      } catch (e) {
        setIsSaving(false)
        const errorMsg =
          'There was an internal error processing your request. Please inform your administrator.'
        onError(e, 500)
        Sentry.captureException(e)
        throw new Error(errorMsg)
      }
    },
    [note, updateType, fields, isValid, handleEdit, handleNew]
  )

  const validateTimeGreaterThan2Hours = (): boolean => {
    if (fields.SpravatoEndTime === null || fields.SpravatoStartTime === null) {
      return false
    }

    const endTime =
      Number(fields.SpravatoEndTime.split(':')[0]) * 60 +
      Number(fields.SpravatoEndTime.split(':')[1])
    const startTime =
      Number(fields.SpravatoStartTime.split(':')[0]) * 60 +
      Number(fields.SpravatoStartTime.split(':')[1])
    return endTime - startTime >= 120
  }

  const validateSpravato = (
    type: string | null,
    onset: string,
    resolution: string | null,
    resolutionTime: string,
    spravatoMedication: string | null,
    additionalMedications: Medications[]
  ): boolean => {
    if (type === null) {
      return false
    }

    const isSedationOrDissociationFieldsInvalid =
      String(type) === 'true' &&
      (onset === '' || resolution === null || resolutionTime === '')
    const isMedicationInvalid =
      String(type) === 'true' && spravatoMedication === null
    const isMedicationFieldsInvalid =
      String(type) === 'true' &&
      String(spravatoMedication) === 'true' &&
      additionalMedications.some((m) => m.Dose === '' || m.Name === '')

    if (isSedationOrDissociationFieldsInvalid) {
      return false
    }

    if (isMedicationInvalid) {
      return false
    }

    if (isMedicationFieldsInvalid) {
      return false
    }

    return true
  }

  const validateAdverseEvent = (
    event: string | null,
    entries: Entries[]
  ): boolean => {
    if (event === null) {
      return false
    }
    const isEntryInvalid =
      String(event) === 'true' &&
      entries &&
      entries.some(
        (e) =>
          e.Date === null ||
          e.Description === '' ||
          e.Resolution === '' ||
          e.Type === ''
      )

    if (isEntryInvalid) {
      return false
    }

    return true
  }

  const validateAdverseDuringAndBetweenEvents = (): boolean => {
    return (
      validateAdverseEvent(
        fields.SpravatoAdverseEventDuring,
        fields.SavedAdditionalEntries
      ) &&
      validateAdverseEvent(
        fields.SpravatoAdverseEventBetween,
        fields.SavedEntries
      )
    )
  }

  const validateSedationAndDissociation = (): boolean => {
    return (
      validateSpravato(
        fields.SpravatoSedation,
        fields.SpravatoSedationOnset,
        fields.SpravatoSedationResolution,
        fields.SpravatoSedationResolutionTime,
        fields.SpravatoSedationMedication,
        fields.SavedAdditionalMedicationsSedation
      ) &&
      validateSpravato(
        fields.SpravatoDissociation,
        fields.SpravatoDissociationOnset,
        fields.SpravatoDissociationResolution,
        fields.SpravatoDissociationResolutionTime,
        fields.SpravatoDissociationMedication,
        fields.SavedAdditionalMedicationsDissociation
      )
    )
  }

  // function to save any edits that are in progress when a provider is forced out of editing, without marking note as note being edited since the editor switched
  const handleForcedSave = useCallback(async () => {
    try {
      setIsSaving(true)
      await handleEdit()
      // after saving the providers edits from being forced off, mark the process of forcing the other provider off editing as complete
      await markForcedEndEditComplete({
        PatientId: note.PatientId,
        NoteId: note.NoteId,
        SwitchOn: true,
        UpdateEnum: 'forcedEndEditComplete',
      })
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
      Sentry.captureException(e)
    } finally {
      setIsSaving(false)
    }
  }, [note, fields])

  const validateSpravatoFields = () => {
    const nullCheck: Array<keyof NoteType> = [
      'Benzodiazepines',
      'NonBenzodiazepine',
      'Psychostimulants',
      'MAOIs',
      'SpravatoAdverseEventDuring',
      'SpravatoAdverseEventBetween',
      'SpravatoSessionRange',
      'SpravatoAdministrationRange',
    ]
    const emptyStringCheck: Array<keyof NoteType> = [
      'SpravatoProviderFirstName',
      'SpravatoProviderLastName',
      'SpravatoProviderPhone',
      'SpravatoProviderEmail',
      'SpravatoClinicAddress1',
      'SpravatoClinicCity',
      'SpravatoClinicState',
      'SpravatoClinicZip',
      'SessionMonitoringTime',
      'SpravatoEndTime',
      'SpravatoStartTime',
    ]

    const validateNull = nullCheck.every((key) => fields[key] !== null)
    const validateEmptyString = emptyStringCheck.every(
      (key) => fields[key] !== ''
    )
    const isValidated =
      patient.firstName &&
      patient.lastName &&
      patient.DateOfBirth &&
      patient.legalSex &&
      fields.RenderingProvider &&
      fields.NoteDate &&
      (fields.SpravatoDose === '56 mg' ||
        fields.SpravatoDose === '84 mg' ||
        (fields.SpravatoDose === 'Other' && fields.SpravatoDoseOther !== '')) &&
      fields.StartSpravatoVitals.BP.includes('/') &&
      fields.FortyMinSpravatoVitals.BP.includes('/') &&
      fields.EndSpravatoVitals.BP.includes('/') &&
      validateNull &&
      validateEmptyString &&
      validateTimeGreaterThan2Hours() &&
      validateSedationAndDissociation() &&
      validateAdverseDuringAndBetweenEvents()
    setIsValid(isValidated)
  }

  useEffect(() => {
    if (updateType === UpdateType.NEW && patientPhysicalNotes?.weight) {
      setFields((fields) => ({
        ...fields,
        PatientWeight: String(patientPhysicalNotes.weight),
        AdditionalMedicationPatientWeight: String(patientPhysicalNotes.weight),
        // weight unit is recorded as 'lb' in db but KetamineUnit value expects 'lbs'
        KetamineUnits: patientPhysicalNotes.weightUnit === 'kg' ? 'kg' : 'lbs',
      }))
    }
  }, [patientPhysicalNotes, updateType])

  useEffect(() => {
    if (!inViewMode) {
      // This links autosave to the fields state to queue up changes to autosave whenever fields change
      setDataToAutosave(fields)
    }

    if (fields.NoteType === NoteTypes.SPRAVATO && !fields.Signatures?.length) {
      validateSpravatoFields()
    } else {
      setIsValid(Boolean(fields.NoteType))
    }
  }, [fields])

  const setFieldsWithDependencies = useCallback(
    (newFields: NoteType) => {
      let updatedAddress = {}
      if (newFields.Rooms !== fields.Rooms) {
        updatedAddress = updatedNoteAddress(newFields.Rooms)
      }

      setFields({ ...newFields, ...updatedAddress })
    },
    [fields, updatedNoteAddress]
  )

  return {
    updateType,
    fields,
    setFields: setFieldsWithDependencies,
    resetFields,
    isSaving,
    handleSave,
    handleForcedSave,
    findMostRecentEntry,
    showUnsignedAndInvalid,
    setShowUnsignedAndInvalid,
    isValid,
    showSignedAndValid,
    setShowSignedAndValid,
    showSignedAndInvalid,
    setShowSignedAndInvalid,
    refetchOnEdit,
    isSavingAutosave,
    hasAnyPendingChanges,
    lastSavedTimestamp,
    auditLogs,
    isNoteCreated,
    autosaveFailed,
    saveQueue,
  }
}

export { UpdateType }
