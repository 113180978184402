export enum ModalActions {
  CREATE = 'CREATE',
  VIEW = 'VIEW',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  NONE = 'NONE',
}

export enum CalendarNavigationOptions {
  BACK = 'Back',
  TODAY = 'Today',
  NEXT = 'Next',
}

export enum ResourceType {
  PROVIDER = 'PROVIDER',
  ROOM = 'ROOM',
  APPOINTMENT_TYPE = 'APPOINTMENT_TYPE',
  UNASSIGNED = 'UNASSIGNED',
}

export enum Views {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
  AGENDA = 'agenda', // not an interval
}

export interface OldColors {
  [value: string]: '#FA541C' | '#722ED1' | '#1890FF' | '#F5222D' | '#D4B106'
}

export const DEFAULT_COLOR_PALETTE_PROVIDERS = [
  '#91D5FF',
  '#FFADD2',
  '#B7EB8F',
  '#D3ADF7',
  '#FFD666',
  '#D3F261',
  '#FFEC3D',
  '#FFC069',
  '#ADC6FF',
  '#FFBB96',
  '#87E8DE',
  '#FFA39E',
  '#BAE7FF',
  '#FFCCC7',
  '#FFE7BA',
  '#D9F7BE',
  '#FFFB8F',
  '#D6E4FF',
  '#EAFF8F',
  '#FFD8BF',
  '#B5F5EC',
  '#FFE58F',
  '#EFDBFF',
  '#FFD6E7',
]

/* To spread usage of colors to avoid rooms and providers clashing in color */
export const DEFAULT_COLOR_PALETTE_ROOMS = [
  ...DEFAULT_COLOR_PALETTE_PROVIDERS,
].reverse()
export const DEFAULT_GREY = '#F5F5F5'
export const DEFAULT_DARK = '#262626'

export const UNASSIGNED_CATEGORY = {
  color: DEFAULT_GREY,
  label: 'Unassigned',
  value: 'unassigned',
}

export const FILTER_LOCAL_STORAGE_LOCATION = 'scheduleFilters'

export const DEFAULT_APPT_TYPE_COLORS: string[] = [
  '#FADB14',
  '#FA8C16',
  '#F5222D',
  '#CF1322',
  '#A0D911',
  '#52C41A',
  '#5CDBD3',
  '#13C2C2',
  '#08979C',
  '#69C0FF',
  '#1890FF',
  '#0050B3',
  '#B37FEB',
  '#722ED1',
  '#F759AB',
]

export const DEFAULT_EVENT_COLOR = '#434343'

export type Location =
  | 'US/Alaska'
  | 'US/Arizona'
  | 'US/Central'
  | 'US/Eastern'
  | 'US/Hawaii'
  | 'US/Mountain'
  | 'US/Pacific'
export type Timezone =
  | 'America/Anchorage'
  | 'America/Phoenix'
  | 'America/Chicago'
  | 'America/New_York'
  | 'Pacific/Honolulu'
  | 'America/Denver'
  | 'America/Los_Angeles'

export const TIMEZONE_OPTIONS = [
  'US/Alaska',
  'US/Arizona',
  'US/Central',
  'US/Eastern',
  'US/Hawaii',
  'US/Mountain',
  'US/Pacific',
]

export const LOCATION_TO_TIMEZONE_MAPPING: Record<Location, Timezone> = {
  'US/Alaska': 'America/Anchorage',
  'US/Arizona': 'America/Phoenix',
  'US/Central': 'America/Chicago',
  'US/Eastern': 'America/New_York',
  'US/Hawaii': 'Pacific/Honolulu',
  'US/Mountain': 'America/Denver',
  'US/Pacific': 'America/Los_Angeles',
}

export const TIMEZONE_TO_LOCATION_MAPPING: Record<Timezone, Location> = {
  'America/Anchorage': 'US/Alaska',
  'America/Chicago': 'US/Central',
  'America/Denver': 'US/Mountain',
  'America/Los_Angeles': 'US/Pacific',
  'America/New_York': 'US/Eastern',
  'America/Phoenix': 'US/Arizona',
  'Pacific/Honolulu': 'US/Hawaii',
}
