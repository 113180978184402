import moment from 'moment'

import {
  deleteDiagnosis,
  deleteRCopiaDiagnosis,
} from '../../../../../../../api/api-lib-typed'
import { DiagnosisModal } from '../../../../../../../components/Forms/DiagnosisModal'
import { useGetDiagnosis } from '../../../../../../../hooks/diagnoses/useGetDiagnosis'
import {
  UpdateDiagnosisParams,
  useUpdateDiagnosis,
} from '../../../../../../../hooks/diagnoses/useUpdateDiagnosis'
import { usePatientDemographics } from '../../../../../../../hooks/usePatientInfo'

export const EditDiagnosisModal = ({
  patientId,
  diagnosisId,
  setDiagnosisId,
  onUpdate,
}: {
  patientId?: string
  diagnosisId: string | null
  setDiagnosisId: (val: string | null) => void
  onUpdate: () => Promise<void>
}) => {
  const { data: diagnosis, isLoading } = useGetDiagnosis({
    patientId: patientId,
    diagnosisId: diagnosisId ?? undefined,
  })

  const handleCancelClick = () => {
    setDiagnosisId(null)
  }

  const { data: patientData, isLoading: isPatientLoading } =
    usePatientDemographics(patientId)

  const { updateDiagnosis } = useUpdateDiagnosis({
    patientId: patientData.PatientId,
  })

  const isAnyLoading = isLoading || isPatientLoading

  const handleEditDiagnosis = async (
    editedDiagnosis: UpdateDiagnosisParams
  ) => {
    if (!diagnosis || !patientData) return
    await updateDiagnosis({
      dx: editedDiagnosis,
      isAlreadyInRCopia: diagnosis.IsUploaded ?? false,
      patientRCopiaId: patientData.PatientRCopiaId,
      diagnosisId: diagnosis.DiagnosisId,
    })
    setDiagnosisId(null)
    await onUpdate()
  }

  const handleDeleteDiagnosis = async () => {
    if (!diagnosis) return

    await deleteDiagnosis(diagnosis.DiagnosisId, patientData.PatientId)
    if (patientData.PatientRCopiaId) {
      await deleteRCopiaDiagnosis({
        DiagnosisId: diagnosis.DiagnosisId,
        PatientRCopiaId: patientData.PatientRCopiaId,
        Diagnosis: {
          Diagnosis: diagnosis.Diagnosis,
          DiagnosisCode: diagnosis.DiagnosisCode,
          DiagnosisDate: moment(diagnosis.DiagnosisDate),
          DiagnosisNoteContent: diagnosis.DiagnosisNoteContent,
          DiagnosisOnsetDate: moment(diagnosis.DiagnosisOnsetDate),
          DiagnosisOnsetDateFormat: 'MM/DD/YYYY',
          DiagnosisStatus: diagnosis.DiagnosisStatus,
          PatientId: patientData.PatientId,
        },
        DiagnosisCode: diagnosis.DiagnosisCode,
        PatientId: patientData.PatientId,
      })
    }

    setDiagnosisId(null)
    await onUpdate()
  }

  return (
    <>
      {diagnosis && !isAnyLoading ? (
        <DiagnosisModal
          isVisible={!!diagnosisId}
          onCancel={handleCancelClick}
          title="Edit diagnosis"
          initialIcdCode={{
            code: diagnosis.DiagnosisCode,
            description: diagnosis.Diagnosis,
          }}
          initialDiagnosisDate={diagnosis.DiagnosisDate}
          initialComments={diagnosis.DiagnosisNoteContent}
          initialDiagnosisStatus={
            diagnosis.DiagnosisStatus as 'Active' | 'Inactive' | 'Resolved'
          }
          initialOnsetDate={diagnosis.DiagnosisOnsetDate}
          onSaveDiagnosis={handleEditDiagnosis}
          onDeleteDiagnosis={handleDeleteDiagnosis}
        />
      ) : null}
    </>
  )
}
