import { useState } from 'react'

import moment from 'moment'

import {
  deleteDiagnosis,
  deleteRCopiaDiagnosis,
} from '../../../api/api-lib-typed'
import { patientDataTypes } from '../../../containers/Patient/patient-data-types'
import {
  UpdateDiagnosisParams,
  useUpdateDiagnosis,
} from '../../../hooks/diagnoses/useUpdateDiagnosis'
import { Button } from '../../../stories/BaseComponents'
import { DiagnosisModal } from '../../Forms/DiagnosisModal'

export const EditDiagnosisButton = ({
  diagnosis,
  handleApiChange,
  handlePatientDataChange,
  patient,
  status,
}: {
  diagnosis: {
    DiagnosisId: string
    Diagnosis: string
    DiagnosisCode: string
    DiagnosisDate: string
    DiagnosisNoteContent: string
    DiagnosisOnsetDate: string
    DiagnosisStatus: 'Active' | 'Inactive' | 'Resolved'
    IsUploaded: boolean
  }
  patient: {
    PatientId: string
    PatientRCopiaId?: string
  }
  handleApiChange: (args: [string, string]) => void
  handlePatientDataChange: () => void
  status: string
}) => {
  const [show, setShow] = useState(false)

  const { updateDiagnosis } = useUpdateDiagnosis({
    patientId: patient.PatientId,
  })

  function toggleShow() {
    setShow((curr) => !curr)
  }

  const handleCancelClick = () => {
    setShow(false)
  }

  const handleSubmitEditDiagnosis = async (
    editedDiagnosis: UpdateDiagnosisParams
  ) => {
    if (!patient) return
    await updateDiagnosis({
      diagnosisId: diagnosis.DiagnosisId,
      patientRCopiaId: patient.PatientRCopiaId,
      isAlreadyInRCopia: diagnosis.IsUploaded,
      dx: editedDiagnosis,
    })

    handlePatientDataChange()
    setShow(false)
  }

  const handleDeleteDiagnosis = async () => {
    await deleteDiagnosis(diagnosis.DiagnosisId, patient.PatientId)
    if (patient?.PatientRCopiaId) {
      await deleteRCopiaDiagnosis({
        DiagnosisId: diagnosis.DiagnosisId,
        PatientRCopiaId: patient.PatientRCopiaId,
        Diagnosis: {
          Diagnosis: diagnosis.Diagnosis,
          DiagnosisCode: diagnosis.DiagnosisCode,
          DiagnosisDate: moment(diagnosis.DiagnosisDate),
          DiagnosisNoteContent: diagnosis.DiagnosisNoteContent,
          DiagnosisOnsetDate: moment(diagnosis.DiagnosisOnsetDate),
          DiagnosisOnsetDateFormat: 'MM/DD/YYYY',
          DiagnosisStatus: diagnosis.DiagnosisStatus,
          PatientId: patient.PatientId,
        },
        DiagnosisCode: diagnosis.DiagnosisCode,
        PatientId: patient.PatientId,
      })
    }
    handleApiChange([
      patientDataTypes.PatientDiagnoses,
      patient.PatientId ?? '',
    ])
    handleApiChange([
      patientDataTypes.ProviderSidePatientData,
      patient.PatientId ?? '',
    ])
    setShow(false)
  }

  return (
    <>
      <Button
        id={'editDiagnosisButton' + status}
        onClick={toggleShow}
        type="text"
        style={{ backgroundColor: 'transparent' }}
      >
        <i className="fa fa-pencil" aria-hidden="true">
          {' '}
        </i>
      </Button>
      <DiagnosisModal
        isVisible={show}
        onCancel={handleCancelClick}
        title="Edit diagnosis"
        initialIcdCode={{
          code: diagnosis.DiagnosisCode,
          description: diagnosis.Diagnosis,
        }}
        initialDiagnosisDate={diagnosis.DiagnosisDate}
        initialComments={diagnosis.DiagnosisNoteContent}
        initialDiagnosisStatus={diagnosis.DiagnosisStatus}
        initialOnsetDate={diagnosis.DiagnosisOnsetDate}
        onDeleteDiagnosis={handleDeleteDiagnosis}
        onSaveDiagnosis={handleSubmitEditDiagnosis}
      />
    </>
  )
}
