import { format } from 'date-fns'

import { printPatientProgress } from '../../../api/api-lib'
import { formatDate } from '../../../libs/utils'
import { ProviderHomePageData } from '../../../shared-types'

export interface Graph {
  label: string
  svg: string
}

export interface Score {
  label: string
  score: string
}

export interface Charts {
  scores: Score[]
  graphs: Graph[]
}

interface Email {
  email: string
}

interface EmailTemplate {
  EmailTemplate: string
}

export interface HellosignObject {
  PracticeName?: string
  ProviderEmail?: string
}

export interface QuestionnaireStep {
  Type: string
  Title: string
  Description: string
  Options: {
    Title: string
    Score: number
  }[]
}

export interface SurveyData {
  Id: number
  Name: string
  IsLatest: boolean
  IsPatientReported: boolean
  IsRecurring: boolean
  ScoreTypeEnum: string
  Steps: QuestionnaireStep[]
  UpdatedAt: object
  Version: number
}

export interface HellosignTemplate {
  EditUrlTimeSubmitted: string
  ProviderId: string
  SignatureOption: string
  TemplateEditUrl: string
  TemplateId: string
  TemplateName: string
}

export interface HomepageData {
  emailData: EmailTemplate[]
  hellosign: HellosignObject[]
  ProviderEmail?: string
  providerId: string
  surveyData?: SurveyData[]
  hellosignTemplates?: HellosignTemplate[]
}

export interface Patient {
  DateOfBirth?: Date | null
  PatientName?: string
  PatientProviderEmail?: string
}

export interface Provider {
  attributes?: Email
}

export async function generateAttachment(
  homepageData: ProviderHomePageData,
  dateOfBirth: Date | null,
  patientName: string,
  patientProviderEmail: string,
  provider: Provider,
  charts: Charts | null
): Promise<Blob | null> {
  const patientDOB = formatDate({
    textWhenInvalid: 'N/A',
    value: dateOfBirth,
  })

  const isHSArray = Array.isArray(homepageData?.hellosign)
  const hsData = isHSArray ? homepageData.hellosign[0] : {}
  /*
  looks for email from provider in three places
  ---first gets email from current user
  ---if not, gets email from the homepage data api call
  ---and if still not available, then gets it from patient info
  */
  let providerEmail = 'N/A'
  if (typeof provider?.attributes?.email === 'string') {
    providerEmail = provider.attributes?.email
  } else if (typeof hsData?.ProviderEmail === 'string') {
    providerEmail = hsData?.ProviderEmail
  } else if (typeof patientProviderEmail === 'string') {
    providerEmail = patientProviderEmail
  }

  const practiceName = hsData?.PracticeName || 'N/A'

  const data = {
    dateOfReport: format(new Date(), 'MM/dd/yyyy'),
    providerInformation: { practiceName, providerEmail },
    patientInformation: { patientName, patientDOB },
    charts,
  }

  const response = await printPatientProgress(data)
  const base64Response = await fetch(
    `data:application/pdf;base64,${response.body}`
  )
  const blob = await base64Response.blob()
  return blob
}
