import { useState } from 'react'

import { DeleteOutlined, DownOutlined } from '@ant-design/icons'

import { Button, Dropdown, Space } from '../BaseComponents'
import { NoteTemplateCreateOrEditDialog } from './NoteTemplateCreateOrEditDialog'
import { NoteTemplateDeleteDialog } from './NoteTemplateDeleteDialog'

import styles from './NoteTemplateTableRowActions.module.scss'

interface NoteTemplateTableRowActionsProps {
  templateId: string
  templateName: string
  templateDescription?: string
  onTemplateNameEdited: () => void
  onTemplateEdited: () => void
  onTemplateDeleted: () => void
}

export function NoteTemplateTableRowActions({
  templateId,
  templateName,
  templateDescription,
  onTemplateNameEdited,
  onTemplateEdited,
  onTemplateDeleted,
}: NoteTemplateTableRowActionsProps) {
  const [isEditTemplateNameModalOpen, setIsEditTemplateNameModalOpen] =
    useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  return (
    <Space size="small" data-testid={`NoteTemplateRow--${templateId}`}>
      <Dropdown
        testId={`NoteTemplateRow--EditDropdown--${templateId}`}
        trigger={['click']}
        items={[
          {
            text: 'Edit Template',
            key: `NoteTemplateRow--EditDropdown--EditTemplate--${templateId}`,
            onClick: () => onTemplateEdited(),
          },
          {
            text: 'Edit Name',
            key: `NoteTemplateRow--EditDropdown--EditName--${templateId}`,
            onClick: () => setIsEditTemplateNameModalOpen(true),
          },
        ]}
      >
        <span
          className={styles.dropdownEditButton}
          data-testid={`NoteTemplateRow--EditDropdown--Inner--${templateId}`}
        >
          Edit <DownOutlined />
        </span>
      </Dropdown>
      <Button
        type="text"
        danger
        data-testid={`NoteTemplateRow--Delete--${templateId}`}
        icon={<DeleteOutlined />}
        onClick={() => setIsDeleteModalOpen(true)}
      >
        Delete
      </Button>

      <NoteTemplateCreateOrEditDialog
        mode={'edit'}
        visible={isEditTemplateNameModalOpen}
        onTemplateUpdated={() => {
          setIsEditTemplateNameModalOpen(false)
          onTemplateNameEdited()
        }}
        onCancel={() => setIsEditTemplateNameModalOpen(false)}
        templateId={templateId}
        initialName={templateName}
        initialDescription={templateDescription}
      />

      <NoteTemplateDeleteDialog
        visible={isDeleteModalOpen}
        templateId={templateId}
        templateName={templateName}
        onCancel={() => setIsDeleteModalOpen(false)}
        onDelete={() => {
          setIsDeleteModalOpen(false)
          onTemplateDeleted()
        }}
      />
    </Space>
  )
}
