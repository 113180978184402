import { PatientTag } from '../../hooks/usePatientProfile/shared-types'
import { isPatientActive } from '../../libs/utils'
import { DateISO8601 } from '../../shared-types'
import { getNameToDisplay } from '../../shared/Helpers/utils'
import { CareTeam, PatientTableData } from './_types'

type SendbirdMessageCounts = Map<
  string,
  { count: number; markedUnread: boolean }
>

export type PatientResponse = {
  PatientId: string
  PublicId: string
  firstName: string
  middleName?: string | null
  lastName: string
  HasRecentSuicidalThoughts?: boolean
  ChannelUrl: string
  RequiresSystemIntakeFormReview?: boolean | null
  LastMoodScore: string | null
  LastMoodScoreDate: DateISO8601 | null
  LastPHQScore: string | null
  LastPHQScoreDate: DateISO8601 | null
  patientTags: PatientTag[]
  IsActive: boolean
  primaryProvider: string
  CareTeam: CareTeam[]
  ClinicId: string
}

export const buildPatientTableData = (
  patients: PatientResponse[] = [],
  messageCounts?: SendbirdMessageCounts
): PatientTableData[] =>
  patients.map((patient, i: number) => {
    return {
      key: `${patient.PatientId}-${i}`,
      publicId: patient.PublicId,
      patientId: patient.PatientId,
      name: getNameToDisplay({
        firstName: patient.firstName,
        middleName: patient.middleName ?? undefined,
        lastName: patient.lastName,
      }),
      firstLast: `${patient.firstName} ${patient.lastName}`,
      fullName: `${patient.firstName} ${
        patient.middleName ? `${patient.middleName} ` : ''
      }${patient.lastName}`,
      isSuicidal: patient.HasRecentSuicidalThoughts ?? false,
      // setting to 0 as a default value for our loading state
      unreadMessageCount:
        messageCounts?.get(patient.ChannelUrl)?.count ||
        (messageCounts?.get(patient.ChannelUrl)?.markedUnread ? 1 : 0),
      needReview: patient.RequiresSystemIntakeFormReview
        ? patient.RequiresSystemIntakeFormReview
        : false,
      moodScore: patient.LastMoodScore ? Number(patient.LastMoodScore) : null,
      moodScoreDate: patient.LastMoodScoreDate
        ? new Date(patient.LastMoodScoreDate)
        : null,
      phqScore: patient.LastPHQScore ? Number(patient.LastPHQScore) : null,
      phqScoreDate: patient.LastPHQScoreDate
        ? new Date(patient.LastPHQScoreDate)
        : null,
      tags: patient.patientTags || [],
      isActive: isPatientActive(patient.IsActive),
      primaryProvider: patient.primaryProvider,
      careTeam: patient.CareTeam,
      clinicId: patient.ClinicId,
    }
  })
