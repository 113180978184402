import { Divider } from '../../BaseComponents'
import { IntroSectionHeader, ParagraphBlock } from './helpers'

const Introduction = () => {
  return (
    <>
      <IntroSectionHeader sectionTitle="Patient Intake Form" />
      <ParagraphBlock
        shouldAddSpacing={true}
        paragraphText="Please fill out this form as part of the intake process. The information we collect through this form helps us gain a detailed understanding of your health and a holistic understanding of you as a person. This is critical for us to provide you with personalized, evidence-based, and high-quality care."
      />
      <ParagraphBlock
        shouldAddSpacing={true}
        paragraphText="All information you enter into this form will be kept strictly confidential and private. This electronic form is HIPAA-compliant and the information is stored in a HIPAA-compliant, encrypted database."
      />
      <Divider />
      <IntroSectionHeader levelSize={4} sectionTitle="Before you begin" />
      <ParagraphBlock
        shouldAddSpacing={true}
        paragraphText="For security and privacy reasons, your progress will not be saved, so you should plan to fill this out in a single session. Hitting the “Back” button in your browser will also require you to restart the form."
      />
      <ParagraphBlock
        shouldAddSpacing={true}
        paragraphText="Please be sure to have information about your medical history on hand."
      />
      <ParagraphBlock
        shouldAddSpacing={true}
        paragraphText="Thank you for taking your time to fill out this form accurately and in detail."
      />
      <Divider />
    </>
  )
}

export default Introduction
