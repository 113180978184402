import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { UploadChangeParam, UploadListType } from 'antd/es/upload/interface'
import classNames from 'classnames'

import { TestId } from '../../../shared-types'
import { getBase64, validateImageFileSize } from '../helpers/imageUploadHelpers'

import './ImageUpload.scss'

export type ImageUploadProps = {
  cropAspectRatio?: number
  className?: string
  imageAlt?: string
  isLoading: boolean
  listType?: UploadListType
  name?: string
  quality?: number
  removeButtonClassName?: string
  setLoading?: Function
  croppedImageBase64: string
  shouldKeepFileType?: boolean
  setOriginalImageBase64?: Function
  setCroppedImageBase64: Function
  showRemoveButton?: boolean
  uploadedClassName?: string
} & TestId

export type ImageUploadHandler = {
  imageChanged: boolean
  imageRemoved: boolean
}

const ImageUploadComponent: ForwardRefRenderFunction<
  ImageUploadHandler,
  ImageUploadProps
> = (
  {
    className,
    cropAspectRatio = 1,
    croppedImageBase64,
    listType = 'picture-card',
    imageAlt = 'avatar',
    isLoading,
    name = 'avatar',
    quality = 0.4,
    removeButtonClassName = 'remove-image-button',
    setLoading,
    shouldKeepFileType = false,
    setOriginalImageBase64,
    setCroppedImageBase64,
    showRemoveButton,
    testId,
    uploadedClassName = 'uploaded-image',
  },
  ref
) => {
  const [imageChanged, setImageChanged] = useState(false)
  const [imageRemoved, setImageRemoved] = useState(false)

  useImperativeHandle(ref, () => ({
    imageChanged,
    imageRemoved,
  }))

  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status === 'uploading') return setLoading?.(true)
    if (info.file.status !== 'done' || !info.file.originFileObj) return
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (imageUrl: string) => {
      if (typeof imageUrl !== 'string') return
      const base64Image = shouldKeepFileType ? imageUrl : imageUrl.split(',')[1]
      setLoading?.(false)
      setCroppedImageBase64(base64Image)
      setImageChanged(true)
      setImageRemoved(false)
    })
  }

  const uploadButton = useMemo(
    () => (
      <div>
        {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    ),
    [isLoading]
  )

  const handleBeforeCrop = (file: File) => {
    getBase64(file, (imageUrl: string) => {
      if (
        typeof imageUrl !== 'string' ||
        typeof setOriginalImageBase64 === 'undefined'
      )
        return
      const base64Image = shouldKeepFileType ? imageUrl : imageUrl.split(',')[1]
      setLoading?.(false)
      setOriginalImageBase64(base64Image)
    })
    return true
  }

  const dummyRequest = ({ _file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const handleRemoveImages = () => {
    setOriginalImageBase64?.('')
    setCroppedImageBase64('')
    setImageChanged(true)
    setImageRemoved(true)
  }

  return (
    <>
      {/* @ts-expect-error antd4's ImgCrop's props do not extend React 18's React.PropsWithChildren */}
      <ImgCrop
        aspect={cropAspectRatio}
        rotate
        beforeCrop={handleBeforeCrop}
        quality={quality}
      >
        <Upload
          data-testid={testId}
          listType={listType}
          name={name}
          className={
            className
              ? classNames({
                  [className]: true,
                  [`${className}-filled`]: !!croppedImageBase64,
                })
              : classNames({
                  'avatar-empty-uploader': !croppedImageBase64,
                  'avatar-image': !!croppedImageBase64,
                })
          }
          showUploadList={false}
          beforeUpload={validateImageFileSize}
          onChange={handleChange}
          customRequest={dummyRequest}
        >
          {croppedImageBase64 ? (
            <img
              src={
                shouldKeepFileType
                  ? croppedImageBase64.replace('image/jpeg', 'image/png')
                  : `data:image/png;base64, ${croppedImageBase64}`
              }
              alt={imageAlt}
              className={uploadedClassName}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </ImgCrop>
      {showRemoveButton !== true && (
        <Button
          className={removeButtonClassName}
          onClick={handleRemoveImages}
          data-testid={testId && `${testId}-remove`}
        >
          Remove
        </Button>
      )}
    </>
  )
}

export const ImageUpload = forwardRef<ImageUploadHandler, ImageUploadProps>(
  ImageUploadComponent
)
