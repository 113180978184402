import React from 'react'

export interface ColumnSizes {
  xs?: number | 'auto'
  sm?: number | 'auto'
  md?: number | 'auto'
  lg?: number | 'auto'
  xl?: number | 'auto'
}

export type GridLayout = Array<ColumnSizes>

export type StoredField = any
export interface StoredFields {
  [key: string]: StoredField
}
export interface DateField {
  date?: string
  startDate?: string
  endDate?: string
  format: DateFormat
}

export enum CheckForm {
  SWITCH = 'switch',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export enum FieldTypes {
  CHECKBOX = 'checkbox',
  CONDITIONAL = 'conditional',
  CUSTOM = 'custom',
  DATEPICKER = 'datepicker',
  MULTIPLE = 'multiple',
  RADIO = 'radio',
  SWITCH = 'switch',
  TEXT = 'text',
  UNITS = 'units',
  YESNO = 'yesNo',
}

export enum DateFormat {
  MONTH_DAY_YEAR = 'monthDayYear',
  MONTH_YEAR = 'monthYear',
  YEAR = 'year',
  YEAR_RANGE = 'yearRange',
}

export enum UniqueDatePickerID {
  BIRTHDATE = 'DateOfBirth',
}

export interface ConditionalOption {
  label?: string
  option: string
  conditionalComponent: TemplateField | TemplateFields | null
}

export interface TemplateField {
  addFieldLabel?: string // only applies to multiple fields
  columnSizes: ColumnSizes
  conditionalComponent?: TemplateField | TemplateFields
  conditionalOption?: string
  conditionalOptions?: ConditionalOption[]
  customStyling?: CustomStyling
  dateType?: DateFormat
  defaultOption?: string
  fieldType?: string
  hideConditional?: boolean
  id: string
  label: string
  multipleComponent?: TemplateFields
  placeholder?: string
  options?: string[]
  size?: number
  toggleDateType?: DateFormat[]
  units?: Array<Unit>
}

export type TemplateFields = TemplateField[]
export type CustomStyling = FieldStyling
export type EmptyFunction = () => void
export type FormInput = (event: React.ChangeEvent<HTMLInputElement>) => void
export type RadioInput = (id: string, key: number) => void
export type CheckInput = (id: string, key: number, checked?: boolean) => void
export type Unit = string
export type MultipleInput = (multipleField: object | number, id: string) => void

export interface GenerateFieldProps {
  handleCheckChange?: CheckInput
  handleFieldChange?: FormInput
  handleMultipleSubmit?: MultipleInput
  isEditing: boolean
  storedFields: StoredFields
  styling: FieldStyling
}

export interface FormMachineProps {
  handleCheckChange?: CheckInput
  handleFieldChange: FormInput
  handleMultipleSubmit?: MultipleInput
  isEditing: boolean
  storedFields: StoredFields
  styling: FieldStyling
  template: TemplateFields
}

export interface FieldStyling {
  colClassName?: string
  colBsPrefix?: string
  colSize?: ColumnSizes
  colStyle?: object
  editColClassName?: string
  editColBsPrefix?: string
  editColStyle?: object
  editValueClassName?: string
  editValueBsPrefix?: string
  editValueStyle?: object
  editLabelClassName?: string
  editLabelBsPrefix?: string
  editLabelStyle?: object
  formRowBsPrefix?: string
  formRowClassName?: string
  formRowStyle?: object
  labelClassName?: string
  labelBsPrefix?: string
  labelStyle?: object
  isLabelOnSameLine?: boolean
  rowClassName?: string
  rowBsPrefix?: string
  rowStyle?: object
  valueClassName?: string
  valueBsPrefix?: string
  valueStyle?: object
}
