import React, { useEffect, useMemo, useState } from 'react'

import { FormInstance } from 'antd'
import { isEqual } from 'date-fns'
import { format } from 'date-fns-tz'

import {
  createScheduledEvent,
  deleteScheduledEvent,
  updateScheduledEvent,
} from '../../../api/api-lib'
import { AppointmentType } from '../../../components/Scheduling/AppointmentSettings'
import {
  LocationItem,
  RoomItem,
} from '../../../containers/Authentication/Locations'
import { onError } from '../../../libs/errorLib'
import { convertTime12to24 } from '../../../libs/utils'
import { Event, Patient, ScheduledEvent, Teammate } from '../../../shared-types'
import { Button, Modal } from '../../BaseComponents'
import Form, { FormField, SelectOptions } from '../../BaseComponents/Form'
import ConfirmationModal, {
  ChangeEventActionType,
  ConfirmationTypes,
} from '../ConfirmationModal'
import { GoogleEventModal } from '../GoogleEventModal'
import { ModalActions } from '../constants'
import { formatTime, generateProviderOptions } from '../helpers'
import {
  DateParams,
  Days,
  FrequencyType,
  ResourceType,
  WEEKDAYS,
  areRecurrencesEqual,
  buildAppointmentTypeValue,
  correctSingleDateTimeZone,
  correctTimezone,
  generateDateParams,
  generateFrequencyOptions,
  generateIntervalLabelAfter,
  generateMonthlyFrequencyOptions,
  getAppointmentTypeId,
  getFrequencyValue,
  getHiddenFields,
  getResourceType,
  getZonedTimes,
  isStartTimeInRecurrence,
  parseRecurrence,
} from './EventModal.helpers'
import { Footer } from './Footer'

// TODO: Localize CSS to this component
import '../Scheduling.scss'

const mapFrequencyTypeToRecurrenceFrequency = {
  [FrequencyType.DAILY]: 'days',
  [FrequencyType.WEEKLY]: 'weeks',
  [FrequencyType.WEEK_DAY]: 'weeks',
  [FrequencyType.MONTHLY]: 'months',
  [FrequencyType.YEARLY]: 'years',
} as const

const ALLDAYS = [Days.SU, ...WEEKDAYS, Days.SA]

export const INITIAL_PATIENT_ID_SELECTED = 'Select a patient'

interface EventModalProps {
  isOpen: boolean
  closeModal: (action: ModalActions) => void
  event: Event
  patients: Patient[]
  rooms: RoomItem[]
  locations: LocationItem[]
  appointmentTypes: AppointmentType[]
  teamData: Teammate[]
  timezone: string
  dateSelected: Date
  resourceId: string | undefined
  patientIdSelected: string
}

export enum Mode {
  EDIT = 'Edit',
  CREATE = 'Create',
}

const EVENT_NAME_FIELD = 'eventName'
const APPOINTMENT_TYPE_FIELD = 'appointmentType'
const PATIENT_ID_FIELD = 'patientId'
const EVENT_PROVIDERS_FIELD = 'eventProviders'
export const START_DAY_FIELD = 'startDay'
export const START_HOUR_FIELD = 'startHour'
export const END_DAY_FIELD = 'endDay'
export const END_HOUR_FIELD = 'endHour'
const IS_REPEAT_FIELD = 'isRepeat'
const FREQUENCY_FIELD = 'frequency'
const INTERVAL_FIELD = 'interval'
const MONTHLY_FREQUENCY_FIELD = 'monthlyFrequency'
const DAYS_OF_WEEK_FIELD = 'daysOfWeek'
const ENDS_FIELD = 'ends'
const NEVER_SELECT_FIELD = 'never'
const RECURRENCE_END_DATE_SELECT_FIELD = 'recurrenceEndDate'
const NUM_OCCURRENCES_SELECT_FIELD = 'numOccurrences'
const ROOM_FIELD = 'roomId'
const PATIENT_INSTRUCTIONS_FIELD = 'instructions'
const APPOINT_NOTES_FIELD = 'internalNotes'

export const EventModal: React.FC<EventModalProps> = ({
  isOpen,
  patients,
  locations,
  rooms,
  appointmentTypes,
  closeModal,
  event,
  teamData,
  dateSelected,
  timezone,
  resourceId, // ie. cognitoId of selected provider, roomId of selected room, etc
  patientIdSelected,
}) => {
  const [formValues, setFormValues] = useState<Record<string, any>>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const [showConfirmUpdate, setShowConfirmUpdate] = useState<boolean>(false)
  const [isThisEventUpdateVisible, setIsThisEventUpdateVisible] = useState(true)
  const [
    isThisAndFollowingEventUpdateVisible,
    setIsThisAndFollowingEventUpdateVisible,
  ] = useState(true)
  const [changeEventActionType, setChangeEventActionType] =
    useState<ChangeEventActionType>(ChangeEventActionType.THIS_EVENT)
  const [showConfirmDiscard, setShowConfirmDiscard] = useState<boolean>(false)
  const [showConfirmNotify, setShowConfirmNotify] = useState<boolean>(false)
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [hasManuallySetEventName, setHasManuallySetEventName] =
    useState<boolean>(false)

  const showUpdateAllEvents = useMemo(() => {
    if (event.start && formValues.startDay) {
      return isEqual(
        formatTime(event.start, timezone, !event.end).day,
        formValues.startDay
      )
    }
    return true
  }, [event.start, formValues.startDay])

  const { frequency, daysOfWeek, recurrenceEndDate, date, on, interval } =
    parseRecurrence(event.resource?.recurrence)

  const defaultFrequency = getFrequencyValue(frequency, daysOfWeek)
  const defaultMonthlyFrequency =
    defaultFrequency === FrequencyType.MONTHLY
      ? on
        ? JSON.stringify({
            ordinal: on.ordinal,
            day: on.day,
          })
        : JSON.stringify({ date })
      : ''
  const defaultInterval = interval || 1

  const [hiddenFields, setHiddenFields] = useState<{
    [index: string]: boolean
  }>(getHiddenFields(!!event.resource?.recurrence, defaultFrequency))
  const [intervalLabelAfter, setIntervalLabelAfer] = useState<string>(
    generateIntervalLabelAfter(defaultFrequency)
  )
  const [dateParams, setDateParams] = useState<DateParams>({
    dayOfWeek: Days.SU.toString(),
    dayOfMonth: 1,
    ordinal: 1,
  })

  // if theres an OsmindEventId attached to an event click, it is a preexisting event versus a new onw
  const mode = event.resource?.OsmindEventId ? Mode.EDIT : Mode.CREATE

  const toggleHiddenFields = (toggledFields: { [index: string]: boolean }) => {
    setHiddenFields({ ...hiddenFields, ...toggledFields })
  }

  const isHidden = (field: string) => {
    return !!hiddenFields[field]
  }

  const generatePatientOptions = () => {
    if (!patients) {
      return []
    }

    const patientsList: SelectOptions[] = patients.map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })
    const firstOption = { name: 'None', value: 'none', addLine: true }

    /// Due to antd bug, select dropdown is not showing last element.
    /// It cannot be fixed easily with css because it manually translates scroll
    /// to show options. Best approach is to add a ghost last value in order to show
    /// latest element.
    const patientsOptionsList = [firstOption, ...patientsList]

    if (patientsOptionsList.length > 8) {
      patientsOptionsList.push({ name: '', value: -1 })
    }

    return patientsOptionsList
  }

  const generateLocationRoomOptions = () => {
    if (!locations) {
      return []
    }

    const locationsAndRooms = []
    const filteredLocations = locations.filter((l) => !l.Deleted)
    for (const location of filteredLocations) {
      const filteredRooms = rooms?.filter(
        (r) => r.locationId === location.LocationId && !r.deleted
      )
      let roomsInLocation = []
      if (!filteredRooms || filteredRooms.length === 0) {
        roomsInLocation = [{ value: '', name: '' }]
      } else {
        roomsInLocation = filteredRooms.map((r) => ({
          name: r.roomName,
          value: r.roomId,
        }))
      }
      const selectGroup = {
        name: location.LocationName,
        value: location.LocationId,
        grouped: true,
        groupItems: roomsInLocation,
        disableItem: true,
      }
      locationsAndRooms.push(selectGroup)
    }

    return locationsAndRooms
  }

  const appointmentTypeOptions = useMemo(() => {
    if (!appointmentTypes) {
      return []
    }

    const appointmentTypesList = appointmentTypes.reduce<
      { name: string; value: string }[]
    >((acc, appt) => {
      if (!appt.isDeleted) {
        acc.push({
          name: appt.name,
          // Appointment type names are not unique, must store id
          // with name to ensure correct id is posted to BE
          value: buildAppointmentTypeValue(appt.id, appt.name),
        })
      }
      return acc
    }, [])

    if (appointmentTypesList.length > 10) {
      appointmentTypesList.push({ name: '', value: '-1' })
    }
    return appointmentTypesList
  }, [appointmentTypes])

  const appointmentTypePrefillOptions = useMemo(() => {
    if (!appointmentTypes) {
      return []
    }

    // these objects instruct the form what to prepopulate if a specific appointment type is selected
    return appointmentTypes.map((appt) => {
      let roomId = appt.roomId
      if (roomId) {
        const roomItem = rooms.find((r) => r.roomId === roomId)
        // dont let appt types with deleted rooms attached to them update the event with deleted room
        if (roomItem?.deleted) {
          roomId = ''
        }
      }
      return {
        value: buildAppointmentTypeValue(appt.id, appt.name),
        appointmentType: appt.name,
        instructions: appt.instructions,
        internalNotes: appt.internalNotes,
        roomId,
        duration: appt.durationInMinutes,
        eventProviders: appt.eventProviders || [],
      }
    })
  }, [appointmentTypes])

  const handleCancel = () => {
    setShowConfirmDiscard(false)
    closeModal(ModalActions.NONE)
  }

  const buildRecurrence = (
    frequency: FrequencyType,
    interval: number,
    monthlyFrequency: string,
    daysOfWeek: Days,
    ends: string,
    recurrenceEndDate: string,
    numOccurrences: number
  ) => {
    let params
    switch (frequency) {
      case FrequencyType.DAILY: {
        params = {
          frequency: 'days',
        }
        break
      }
      case FrequencyType.WEEK_DAY:
      case FrequencyType.WEEKLY: {
        params = {
          daysOfWeek,
          frequency: 'weeks',
        }
        break
      }
      case FrequencyType.MONTHLY: {
        const { date, day, ordinal } = JSON.parse(monthlyFrequency)

        params = {
          frequency: 'months',
          ...((date && { date }) || {}),
          ...((ordinal &&
            day && {
              on: {
                ordinal,
                day,
              },
            }) ||
            {}),
        }
        break
      }
      case FrequencyType.YEARLY: {
        params = {
          frequency: 'years',
        }
        break
      }
      default: {
        params = {}
        break
      }
    }

    return {
      timezone,
      interval,
      ...params,
      end: {
        ...((ends === NEVER_SELECT_FIELD && { never: true }) || {}),
        ...((ends === RECURRENCE_END_DATE_SELECT_FIELD && {
          on: recurrenceEndDate,
        }) ||
          {}),
        ...((ends === NUM_OCCURRENCES_SELECT_FIELD && {
          after: numOccurrences,
        }) ||
          {}),
      },
    }
  }

  const getEventParams = (
    values: any = undefined,
    notifyPatient = false,
    overrideChangeEventActionType: ChangeEventActionType | undefined = undefined
  ) => {
    const {
      eventName: uneditedEventName,
      startHour,
      endHour,
      roomId,
      patientId: patientIdValue,
      instructions,
      appointmentType: apptType,
      eventProviders,
      internalNotes,
      startDay,
      endDay,
      frequency,
      interval,
      isRepeat,
      monthlyFrequency,
      daysOfWeek,
      ends,
      recurrenceEndDate,
      numOccurrences,
    } = values ? values : formValues

    // first and last spaces removed in event name
    const eventName = uneditedEventName.replace(
      /^\s{1,}|(?=\s{1,}$)\s{1,}/g,
      ''
    )
    const patientId =
      !patientIdValue ||
      patientIdValue === 'none' ||
      patientIdValue === INITIAL_PATIENT_ID_SELECTED
        ? ''
        : patientIdValue
    const { startTime, endTime } = correctTimezone({
      startDay,
      endDay,
      startHour,
      endHour,
      timezone,
    })
    const recurrenceEndTime = correctSingleDateTimeZone(
      recurrenceEndDate,
      startHour,
      timezone
    )
    // if the user is saving the form with a deleted room or appt type, be sure to save the value as the roomId
    // or correct appt type name, not the user friendly display string we initialized the form to be
    const room = roomId?.includes('(removed)')
      ? event.resource?.Room || ''
      : roomId
    const appointmentType = apptType?.includes('(removed)')
      ? event.resource?.AppointmentType || ''
      : apptType

    return {
      eventName,
      startTime,
      endTime,
      roomId: room,
      patientId,
      instructions,
      appointmentType,
      internalNotes,
      eventProviders,
      notifyPatient,
      ...((isRepeat && {
        recurrence: buildRecurrence(
          frequency,
          interval,
          monthlyFrequency,
          daysOfWeek,
          ends,
          recurrenceEndTime,
          numOccurrences
        ),
      }) ||
        {}),
      changeEventActionType: overrideChangeEventActionType
        ? overrideChangeEventActionType
        : changeEventActionType,
    }
  }

  const handleSubmit = async (
    values: any = undefined,
    notifyPatient = false,
    overrideChangeEventActionType: ChangeEventActionType | undefined = undefined
  ) => {
    setShowConfirmNotify(false)
    setIsSubmitting(true)
    try {
      let newEvent = getEventParams(
        values,
        notifyPatient,
        overrideChangeEventActionType
      )
      if (newEvent?.appointmentType) {
        const appointmentTypeId = getAppointmentTypeId(
          appointmentTypeOptions,
          newEvent
        )

        if (appointmentTypeId) {
          newEvent.appointmentTypeId = appointmentTypeId
        }
      }

      // Add event details id for internal scheduler to
      // differentiate THIS_EVENT updates on a isMainEvent=false event
      newEvent = { ...newEvent, eventDetailsId: event.resource?.eventDetailsId }

      if (mode === Mode.CREATE) {
        await createScheduledEvent(newEvent)
        closeModal(ModalActions.CREATE)
        setIsSubmitting(false)
      } else if (mode === Mode.EDIT) {
        await updateScheduledEvent(event.resource?.OsmindEventId, newEvent)

        closeModal(ModalActions.UPDATE)
        setIsSubmitting(false)
      }
    } catch (e) {
      console.error(e)
      onError(
        new Error(
          `Unable to ${mode === Mode.CREATE ? 'create' : 'update'} event`
        )
      )
      return closeModal(ModalActions.NONE)
    }
    setFormValues({})
  }

  const onSubmit = async (values: any) => {
    // Values are passed as form values of the modal,
    // meaning the appointmentTypeId is not passed as a
    // form value to this function. Reset to a value
    // that the handleSubmit parsing logic expects.
    if (values?.appointmentType) {
      const appointmentTypeId = getAppointmentTypeId(
        appointmentTypeOptions,
        values
      )

      if (appointmentTypeId) {
        values.appointmentTypeId = appointmentTypeId
      }
    }

    //TODO: Remove this coupling when drawer is introduced
    if (values?.patientId === INITIAL_PATIENT_ID_SELECTED) {
      values.patientId = 'none'
    }

    setFormValues(values)
    if (mode === Mode.EDIT) {
      if (event.resource?.recurrence) {
        if (!values.isRepeat) {
          if (values.patientId && values.patientId !== 'none') {
            setChangeEventActionType(ChangeEventActionType.ALL_EVENTS)
            setShowConfirmNotify(true)
          } else {
            await handleSubmit(
              formValues,
              false,
              ChangeEventActionType.ALL_EVENTS
            )
          }
        } else {
          const monthlyFrequency = values.monthlyFrequency
            ? JSON.parse(values.monthlyFrequency)
            : undefined
          const newRecurrence = {
            frequency:
              mapFrequencyTypeToRecurrenceFrequency[
                values.frequency as FrequencyType
              ],
            daysOfWeek:
              values.daysOfWeek &&
              mapFrequencyTypeToRecurrenceFrequency[
                values.frequency as FrequencyType
              ] === 'weeks'
                ? values.daysOfWeek
                : [],
            recurrenceEndDate:
              values.recurrenceEndDate &&
              values.ends === RECURRENCE_END_DATE_SELECT_FIELD
                ? (values.recurrenceEndDate as Date).toISOString()
                : undefined,
            date: monthlyFrequency?.date ? monthlyFrequency.date : undefined,
            on:
              monthlyFrequency?.day || monthlyFrequency?.ordinal
                ? {
                    ...(monthlyFrequency.day && {
                      day: monthlyFrequency.day,
                    }),
                    ...(monthlyFrequency.ordinal && {
                      ordinal: monthlyFrequency.ordinal,
                    }),
                  }
                : undefined,
            interval: values.interval,
            after:
              values.ends === NUM_OCCURRENCES_SELECT_FIELD
                ? formValues[NUM_OCCURRENCES_SELECT_FIELD]
                : undefined,
            never: values.ends === NEVER_SELECT_FIELD,
          }
          const isNoLongerRecurring =
            event.resource?.recurrence && !values.isRepeat
          const isThisEventAvailable =
            !isNoLongerRecurring &&
            areRecurrencesEqual(newRecurrence, event.resource?.recurrence)
          setIsThisEventUpdateVisible(isThisEventAvailable)
          setChangeEventActionType(
            isThisEventAvailable
              ? ChangeEventActionType.THIS_EVENT
              : ChangeEventActionType.THIS_AND_FOLLOWING_EVENT
          )
          const startDayOfWeek =
            ALLDAYS[(formValues[START_DAY_FIELD] as Date).getDay()]

          setIsThisAndFollowingEventUpdateVisible(
            isHidden(DAYS_OF_WEEK_FIELD) ||
              isStartTimeInRecurrence(
                formValues[DAYS_OF_WEEK_FIELD],
                startDayOfWeek
              )
          )
          setShowConfirmUpdate(true)
        }
      } else {
        if (values.patientId && values.patientId !== 'none') {
          setShowConfirmNotify(true)
        } else {
          await handleSubmit(values, false)
        }
      }
    } else if (values.patientId && values.patientId !== 'none') {
      setShowConfirmNotify(true)
    } else {
      await handleSubmit(values, false)
    }
  }

  const handleUpdate = async () => {
    setShowConfirmUpdate(false)
    if (formValues.patientId && formValues.patientId !== 'none') {
      setShowConfirmNotify(true)
    } else {
      await handleSubmit(formValues, false)
    }
  }

  const handleDelete = async () => {
    setShowConfirmDelete(false)
    if (!event?.resource?.OsmindEventId) {
      return onError(
        new Error('Unable to find eventId so we cannot delete item')
      )
    }

    setIsDeleting(true)

    try {
      await deleteScheduledEvent(
        event.resource.OsmindEventId,
        changeEventActionType,
        event.start?.toISOString(),
        event.resource.eventDetailsId
      )
      closeModal(ModalActions.DELETE)
    } catch (e) {
      console.error(e)
      onError(new Error('Unable to delete event'))
      closeModal(ModalActions.NONE)
    }
    setIsDeleting(false)
  }

  const getInitialRecurrenceValues = (resource?: ScheduledEvent) => {
    if (resource?.recurrence) {
      const {
        interval,
        frequency,
        date,
        on,
        daysOfWeek,
        end: { never, on: endsOn, after },
      } = JSON.parse(resource.recurrence)
      const endsValue = never
        ? NEVER_SELECT_FIELD
        : endsOn
        ? RECURRENCE_END_DATE_SELECT_FIELD
        : NUM_OCCURRENCES_SELECT_FIELD

      return {
        [IS_REPEAT_FIELD]: true,
        [INTERVAL_FIELD]: interval || 1,
        [FREQUENCY_FIELD]: getFrequencyValue(frequency, daysOfWeek),
        [ENDS_FIELD]: endsValue,
        ...((endsValue === RECURRENCE_END_DATE_SELECT_FIELD && {
          [RECURRENCE_END_DATE_SELECT_FIELD]: endsOn,
        }) ||
          {}),
        [NUM_OCCURRENCES_SELECT_FIELD]:
          endsValue === NUM_OCCURRENCES_SELECT_FIELD ? after : 1,
        ...(((date || on) && {
          [MONTHLY_FREQUENCY_FIELD]: on
            ? JSON.stringify({
                ordinal: on.ordinal,
                day: on.day,
              })
            : JSON.stringify({ date }),
        }) ||
          {}),
        ...((daysOfWeek && { [DAYS_OF_WEEK_FIELD]: daysOfWeek }) || {}),
      }
    } else {
      return {
        [IS_REPEAT_FIELD]: false,
        [ENDS_FIELD]: NEVER_SELECT_FIELD,
        [INTERVAL_FIELD]: 1,
        [NUM_OCCURRENCES_SELECT_FIELD]: 1,
        [FREQUENCY_FIELD]: getFrequencyValue(frequency, daysOfWeek),
      }
    }
  }

  // if a time slot is selected, use that as the start date
  // if a time slot has not been selected and the user is coming from the 'Create Event' button,
  // use the date selected on the minicalendar as the start date
  const oneHourLater = new Date(dateSelected)
  oneHourLater.setHours(dateSelected.getHours() + 1)
  const eventStart = event.start ? event.start : dateSelected
  const eventEnd = event.end ? event.end : oneHourLater
  const { startHour, endHour, startDay, endDay } = getZonedTimes(
    eventStart,
    eventEnd,
    timezone,
    !event.end
  )

  useEffect(() => {
    setDateParams(generateDateParams(startDay))
  }, [])

  const resourceType = getResourceType(resourceId)
  // if there is a resourceId from calendar select slot, autopopulate
  // the event modal with that room or provider resource
  const selectedRoomCal = resourceType === ResourceType.ROOM ? resourceId : ''
  const selectedProviderCal =
    resourceType === ResourceType.PROVIDER && resourceId ? [resourceId] : []
  let roomDisplay = event.resource?.Room ? event.resource?.Room : undefined
  if (roomDisplay && rooms) {
    const roomItem = rooms.find((r) => r.roomId === roomDisplay)
    // if this roomId has been deleted, it won't be included in the list of dropdown options and therefore will
    // display to the user as a ulid so we should update the display to be identifiable to the user
    if (roomItem?.deleted) {
      const locationItem = locations?.find(
        (l) => l.LocationId === roomItem.locationId
      )
      roomDisplay = `${locationItem?.LocationName} - ${roomItem.roomName} (removed)`
    }
  }

  const fields: FormField[] = [
    {
      label: 'Patient',
      name: PATIENT_ID_FIELD,
      type: 'select',
      defaultValue:
        event.resource?.OsmindPatientId || patientIdSelected || 'none',
      selectOptions: generatePatientOptions(),
      showSearch: true,
      allowClear: true,
      onChange: (value: any, form: FormInstance) => {
        if (!formValues.appointmentType || hasManuallySetEventName) {
          return
        }

        const patient = patients.find((p) => p.id === value)

        if (!patient) {
          return
        }

        form.setFieldsValue({
          eventName: `${patient.name} - ${formValues.appointmentType}`,
        })
      },
    },
    {
      label: 'Appointment type',
      name: APPOINTMENT_TYPE_FIELD,
      type: 'select',
      selectOptions: appointmentTypeOptions,
      placeholder: 'Select appointment type',
      prefillValues: appointmentTypePrefillOptions,
      defaultValue: event.resource?.AppointmentType,
      showSearch: true,
      allowClear: true,
      onChange: (val: any, form: FormInstance) => {
        if (!formValues.patientId || hasManuallySetEventName) {
          return
        }

        const appointmentType = appointmentTypeOptions.find(
          (option) => option.value === val
        )
        const patient = patients.find((p) => p.id === formValues.patientId)
        if (!appointmentType || !patient) {
          return
        }

        form.setFieldsValue({
          eventName: `${patient.name} - ${appointmentType.name}`,
        })
      },
    },
    {
      label: 'Event title',
      name: EVENT_NAME_FIELD,
      pattern: /^\s{0,}\S{1,}.{0,}$/,
      type: 'input',
      required: true,
      validationMessage: 'Event title is a required field.',
      className: 'top-ant-form-item',
      onChange: (val: any, _form: FormInstance) => {
        setHasManuallySetEventName(!!val.length)
      },
    },
    {
      label: 'Provider(s)',
      name: EVENT_PROVIDERS_FIELD,
      type: 'multiSelect',
      placeholder: 'Select provider(s)',
      multiSelectOptions: generateProviderOptions(
        teamData,
        selectedProviderCal
      ),
    },
    {
      label: 'Start time',
      name: START_DAY_FIELD,
      type: 'datepicker',
      defaultValue: startDay,
      className: 'max-width-input-field',
      sharedRow: true,
      rowmates: [
        {
          label: ' ',
          name: START_HOUR_FIELD,
          type: 'timeselector',
          minuteStep: 15,
          dependencies: [START_DAY_FIELD, END_DAY_FIELD, END_HOUR_FIELD],
        },
      ],
      onChange: (value: Date) => {
        setDateParams(generateDateParams(value))
      },
      dependencies: [START_HOUR_FIELD, END_HOUR_FIELD, END_DAY_FIELD],
    },
    {
      label: 'End time',
      name: END_DAY_FIELD,
      type: 'datepicker',
      defaultValue: endDay,
      className: 'max-width-input-field',
      sharedRow: true,
      dependencies: [START_DAY_FIELD, START_HOUR_FIELD, END_HOUR_FIELD],
      rowmates: [
        {
          label: ' ',
          name: END_HOUR_FIELD,
          type: 'timeselector',
          minuteStep: 15,
          validator: (form: FormInstance, value: any) => {
            const startHour = form.getFieldValue(START_HOUR_FIELD)
            if (!startHour)
              return Promise.reject(
                new Error('Start time for event is required')
              )

            const startDay = format(
              form.getFieldValue(START_DAY_FIELD),
              'MM/dd/yyyy'
            )
            const endDay = format(
              form.getFieldValue(END_DAY_FIELD),
              'MM/dd/yyyy'
            )

            const start = convertTime12to24(startHour)
            const end = convertTime12to24(value)
            const startTime = new Date(`${startDay} ${start}`)
            const endTime = new Date(`${endDay} ${end}`)
            if (startTime < endTime) return Promise.resolve()
            return Promise.reject(
              new Error('End time must be after start time.')
            )
          },
          dependencies: [START_DAY_FIELD, START_HOUR_FIELD, END_DAY_FIELD],
        },
      ],
    },
    {
      label: 'Repeat event',
      name: IS_REPEAT_FIELD,
      type: 'checkbox',
      hideLabel: true,
      defaultChecked: !!event.resource?.recurrence,
      onChange: (value: boolean, form: FormInstance) => {
        if (value) {
          const frequency = form.getFieldValue(FREQUENCY_FIELD)
          toggleHiddenFields(getHiddenFields(value, frequency))
        } else {
          toggleHiddenFields({
            frequency: true,
            interval: true,
            ends: true,
            daysOfWeek: true,
            monthlyFrequency: true,
          })
        }
      },
    },
    {
      label: 'Repeat',
      name: FREQUENCY_FIELD,
      type: 'select',
      selectOptions: generateFrequencyOptions(),
      showSearch: false,
      hideLabel: true,
      defaultValue: defaultFrequency,
      onChange: (value: string, form: FormInstance) => {
        let daysOfWeek: string[] = []
        switch (value as keyof FrequencyType) {
          case FrequencyType.WEEK_DAY:
            daysOfWeek = WEEKDAYS
            toggleHiddenFields({
              daysOfWeek: false,
              monthlyFrequency: true,
            })
            break
          case FrequencyType.WEEKLY:
            daysOfWeek = [dateParams.dayOfWeek]
            toggleHiddenFields({
              daysOfWeek: false,
              monthlyFrequency: true,
            })
            break
          case FrequencyType.MONTHLY:
            daysOfWeek = [dateParams.dayOfWeek]
            toggleHiddenFields({
              daysOfWeek: true,
              monthlyFrequency: false,
            })
            break
          default:
            toggleHiddenFields({
              daysOfWeek: true,
              monthlyFrequency: true,
            })
        }
        form.setFieldsValue({
          daysOfWeek,
          monthlyFrequency: defaultMonthlyFrequency,
        })
        setIntervalLabelAfer(generateIntervalLabelAfter(value))
      },
      hidden: isHidden(FREQUENCY_FIELD),
      required: true,
      validationMessage: 'Frequency is a required field.',
    },
    {
      label: 'Every',
      name: INTERVAL_FIELD,
      type: 'numberinput',
      min: 1,
      defaultValue: defaultInterval,
      addonAfter: intervalLabelAfter,
      hidden: isHidden(INTERVAL_FIELD),
      required: true,
      validationMessage: 'Interval is a required field.',
    },
    {
      label: 'Monthly frequency',
      hideLabel: true,
      type: 'select',
      defaultValue: defaultMonthlyFrequency,
      selectOptions: generateMonthlyFrequencyOptions(dateParams),
      name: MONTHLY_FREQUENCY_FIELD,
      hidden: isHidden(MONTHLY_FREQUENCY_FIELD),
      required: true,
      validationMessage: 'Monthly frequency is a required field.',
    },
    {
      label: 'On',
      name: DAYS_OF_WEEK_FIELD,
      type: 'checkboxgroup',
      checkboxOptions: [
        { label: 'S', value: Days.SU },
        { label: 'M', value: Days.MO },
        { label: 'T', value: Days.TU },
        { label: 'W', value: Days.WE },
        { label: 'T', value: Days.TH },
        { label: 'F', value: Days.FR },
        { label: 'S', value: Days.SA },
      ],
      hidden: isHidden(DAYS_OF_WEEK_FIELD),
      onChange: (value: Days[], form: FormInstance) => {
        const frequency = getFrequencyValue('weeks', value)
        form.setFieldsValue({ frequency })
      },
      required: true,
      validator: (form: FormInstance, selectedWeeklyStartDays: Days[]) => {
        if (mode === Mode.EDIT || isHidden(DAYS_OF_WEEK_FIELD)) {
          return Promise.resolve()
        }
        const startDayOfEvent =
          ALLDAYS[form.getFieldValue(START_DAY_FIELD).getDay()]
        if (
          !isStartTimeInRecurrence(selectedWeeklyStartDays, startDayOfEvent)
        ) {
          return Promise.reject(
            new Error(
              `Your start date (${startDayOfEvent}) is not included in your recurrence. Please update your start time or select ${startDayOfEvent}`
            )
          )
        }
        return Promise.resolve()
      },
      validationMessage: 'Day of week is a required field.',
    },
    {
      label: 'Ends',
      name: ENDS_FIELD,
      type: 'radioGroup',
      defaultValue: NEVER_SELECT_FIELD,
      groupOptions: [
        { name: 'Never ends', value: NEVER_SELECT_FIELD },
        {
          name: 'On',
          value: RECURRENCE_END_DATE_SELECT_FIELD,
          nestedInput: {
            label: 'Recurrence End Date',
            hideLabel: true,
            name: RECURRENCE_END_DATE_SELECT_FIELD,
            defaultValue: recurrenceEndDate
              ? formatTime(new Date(recurrenceEndDate), timezone, !event.end)
                  .day
              : new Date(
                  startDay.getFullYear(),
                  startDay.getMonth(),
                  startDay.getDate() + 1
                ),
            type: 'datepicker',
            className: 'ant-row nested-date-picker',
            dependencies: [ENDS_FIELD, START_DAY_FIELD],

            dynamicRequired: (form: FormInstance) =>
              form.getFieldValue(ENDS_FIELD) ===
              RECURRENCE_END_DATE_SELECT_FIELD,
            validator: (form: FormInstance, value: Date) => {
              if (
                form.getFieldValue(ENDS_FIELD) !==
                RECURRENCE_END_DATE_SELECT_FIELD
              )
                return Promise.resolve()
              if (value > form.getFieldValue(START_DAY_FIELD))
                return Promise.resolve()
              return Promise.reject(
                new Error('Recurrence end date must be after start date.')
              )
            },
            validationMessage:
              'Recurrence end date is required when Ends By is selected',
          },
        },
        {
          name: 'After',
          value: NUM_OCCURRENCES_SELECT_FIELD,
          nestedInput: {
            label: 'Num Occurrences',
            hideLabel: true,
            name: NUM_OCCURRENCES_SELECT_FIELD,
            type: 'numberinput',
            defaultValue: 1,
            min: 1,
            addonAfter: 'appointment(s)',
            className: 'ant-row nested-input-number',
            dependencies: [ENDS_FIELD],
            dynamicRequired: (form: FormInstance) =>
              form.getFieldValue(ENDS_FIELD) === NUM_OCCURRENCES_SELECT_FIELD,
            validationMessage:
              'Number of occurrences is required when Ends After is selected',
          },
        },
      ],
      className: 'radio-group-with-nested',
      hidden: isHidden(ENDS_FIELD),
      required: true,
      validationMessage: 'Ends on is a required field.',
    },
    {
      label: 'Room',
      name: ROOM_FIELD,
      type: 'select',
      useTitleProp: true,
      showSearch: true,
      selectOptions: generateLocationRoomOptions(),
      placeholder: 'Select room',
      allowClear: true,
      defaultValue: roomDisplay,
    },

    {
      label: 'Patient instructions',
      name: PATIENT_INSTRUCTIONS_FIELD,
      type: 'textArea',
    },
    {
      label: 'Appointment notes (internal)',
      name: APPOINT_NOTES_FIELD,
      type: 'textArea',
    },
  ]

  let apptTypeDisplay = event.resource?.AppointmentType || ''
  if (apptTypeDisplay && appointmentTypes) {
    const apptTypeItem = appointmentTypes.find(
      (appt) => appt.name === apptTypeDisplay
    )
    // if this appt type has been deleted, it won't be included in the list of dropdown options
    // and we want to signify to the user that it has been removed
    if (apptTypeItem === undefined || apptTypeItem.isDeleted) {
      apptTypeDisplay += ' (removed)'
    }
  }

  // default to no patientId initially selected,
  // if we are editing an event use the patientId tied to that scheduled event,
  // otherwise use the patientId that is being searched for if one exists
  let initialPatientId = 'none'
  if (event.resource?.OsmindPatientId) {
    initialPatientId = event.resource?.OsmindPatientId
  } else if (patientIdSelected) {
    initialPatientId = patientIdSelected
  }

  const initialValues = {
    eventName: event.resource?.EventName,
    appointmentType: apptTypeDisplay,
    patientId: initialPatientId,
    // if we are opening an existing event, use the providers listed on the event or
    // an empty array if none exist, otherwise set the events providers to the
    // providers calendar the user just clicked on (is empty if opening from a room calendar)
    eventProviders: event.resource
      ? event.resource?.EventProviders || []
      : selectedProviderCal,
    instructions: event.resource?.Instructions,
    internalNotes: event.resource?.InternalNotes,
    roomId: roomDisplay || selectedRoomCal,
    startDay,
    endDay,
    startHour,
    endHour,
    ...getInitialRecurrenceValues(event.resource),
  }

  // extract a zoom host link from the appointments internal notes and open it in a new tab
  const openZoomLink = () => {
    if (!initialValues.internalNotes) return
    let zoomLink
    const zoomLinkFirstIndex = initialValues.internalNotes.indexOf(
      'https://us02web.zoom.us/s/'
    )
    const zoomLinkSpaceIndex = initialValues.internalNotes // zoom link will terminate either in newline, space, or at the end of the string
      .substring(zoomLinkFirstIndex)
      .indexOf(' ')
    const zoomLinkNewlineIndex = initialValues.internalNotes
      .substring(zoomLinkFirstIndex)
      .indexOf('\n')
    if (zoomLinkSpaceIndex === -1 && zoomLinkNewlineIndex === -1) {
      // if neither newline or space appear after the link, use to the end of the string
      zoomLink = initialValues.internalNotes.substring(zoomLinkFirstIndex)
    } else if (zoomLinkSpaceIndex === -1 && zoomLinkNewlineIndex !== -1) {
      // use newline as the end of the string
      zoomLink = initialValues.internalNotes.substring(
        zoomLinkFirstIndex,
        zoomLinkNewlineIndex - zoomLinkFirstIndex
      )
    } else if (zoomLinkSpaceIndex !== -1 && zoomLinkNewlineIndex === -1) {
      // use space as the end of the string
      zoomLink = initialValues.internalNotes.substring(
        zoomLinkFirstIndex,
        zoomLinkSpaceIndex - zoomLinkFirstIndex
      )
    } else {
      // use whichever comes first
      zoomLink =
        zoomLinkSpaceIndex < zoomLinkNewlineIndex
          ? initialValues.internalNotes.substring(
              zoomLinkFirstIndex,
              zoomLinkSpaceIndex - zoomLinkFirstIndex
            )
          : initialValues.internalNotes.substring(
              zoomLinkFirstIndex,
              zoomLinkNewlineIndex - zoomLinkFirstIndex
            )
    }
    window.open(zoomLink)
  }

  return (
    <>
      {event.resource?.IsGoogleEvent ? (
        <GoogleEventModal
          isOpen={isOpen}
          onClose={handleCancel}
          event={event.resource}
        />
      ) : (
        <>
          <Modal
            visible={isOpen}
            onCancel={
              hasChanges ? () => setShowConfirmDiscard(true) : handleCancel
            }
            footer={
              <Footer
                hasChanges={hasChanges}
                isDeleting={isDeleting}
                isSubmitting={isSubmitting}
                mode={mode}
                onCancel={handleCancel}
                onConfirmDelete={() => setShowConfirmDelete(true)}
                onShowConfirmDiscard={() => setShowConfirmDiscard(true)}
              />
            }
            title={`${mode} event`}
            closable={true}
          >
            <Form
              fields={fields}
              layout="vertical"
              handleSubmit={onSubmit}
              formName="eventModal"
              size="middle"
              initialValues={initialValues}
              setHasChanges={setHasChanges}
              onChangesSaveValue={(form: FormInstance) =>
                setFormValues(form.getFieldsValue())
              }
              key="eventModal-form"
            />
            {initialValues?.internalNotes?.includes(
              'https://us02web.zoom.us/s/'
            ) && (
              <Button onClick={() => openZoomLink()} style={{ marginTop: 10 }}>
                {' '}
                Join Zoom Personal Meeting Room
              </Button>
            )}
          </Modal>
          <ConfirmationModal
            confirmationType={
              event.resource?.recurrence
                ? ConfirmationTypes.RECURRING
                : ConfirmationTypes.DELETE
            }
            visible={showConfirmDelete}
            onOk={handleDelete}
            onCancel={() => setShowConfirmDelete(false)}
            onRadioChange={(action) => setChangeEventActionType(action)}
            action="Delete"
            centered
          />
          <ConfirmationModal
            allEventsAction={showUpdateAllEvents}
            isThisEventUpdateVisible={isThisEventUpdateVisible}
            isThisAndFollowingEventUpdateVisible={
              isThisAndFollowingEventUpdateVisible
            }
            confirmationType={ConfirmationTypes.RECURRING}
            visible={showConfirmUpdate}
            onOk={handleUpdate}
            onCancel={() => setShowConfirmUpdate(false)}
            onRadioChange={(action) => setChangeEventActionType(action)}
            action="Update"
            centered
          />
          <ConfirmationModal
            confirmationType={ConfirmationTypes.DISCARD}
            visible={showConfirmDiscard}
            onOk={handleCancel}
            onCancel={() => setShowConfirmDiscard(false)}
            centered
          />
          <ConfirmationModal
            confirmationType={ConfirmationTypes.NOTIFY}
            visible={showConfirmNotify}
            onSend={() => handleSubmit(undefined, true)}
            onDontSend={() => handleSubmit(undefined, false)}
            onCancel={() => setShowConfirmNotify(false)}
            centered
            mode={mode}
          />
        </>
      )}
    </>
  )
}
