import React from 'react'

import { Menu as AntDMenu } from 'antd'

import { TestId } from '../../shared-types'

export const MenuItem = AntDMenu.Item
export type MenuProps = React.ComponentProps<typeof AntDMenu> & TestId

const Menu: React.FC<MenuProps> = ({ children, testId, ...props }) => {
  return (
    <AntDMenu data-testid={testId} {...props}>
      {children}
    </AntDMenu>
  )
}

export default Menu
