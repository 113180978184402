import { getPaymentLockStatus, setPaymentLockStatus } from '../api/api-lib'
import { paymentDataLockedModal } from '../shared/Payments/PaymentLockModal'

class PaymentLockManager {
  async withLock<T>(
    patientId: string,
    originPoint: string,
    operation: () => Promise<T>
  ): Promise<T> {
    try {
      await this.acquireLock(patientId, originPoint)
      return await operation()
    } catch (error) {
      console.error('Error performing operation:', error)
      throw error
    } finally {
      await this.releaseLock(patientId, originPoint)
    }
  }

  private async getLockStatus(
    patientId: string
  ): Promise<{ isLocked: boolean }> {
    try {
      return await getPaymentLockStatus(patientId)
    } catch (error) {
      console.error('Error fetching lock status:', error)
      throw new Error('Unable to determine lock status. Please try again.')
    }
  }

  private async setLockStatus(
    patientId: string,
    lockStatus: boolean,
    originPoint: string
  ): Promise<void> {
    try {
      await setPaymentLockStatus(patientId, lockStatus, originPoint)
    } catch (error) {
      console.error('Error setting lock status:', error)
      throw new Error('Unable to set lock status. Please try again.')
    }
  }

  private async acquireLock(patientId: string, originPoint: string) {
    const lockStatus = await this.getLockStatus(patientId)

    if (lockStatus.isLocked) {
      paymentDataLockedModal()
      throw new Error('Payment data is locked. Please try again later.')
    }

    await this.setLockStatus(patientId, true, originPoint)
  }

  private async releaseLock(patientId: string, originPoint: string) {
    try {
      await this.setLockStatus(patientId, false, originPoint)
    } catch (error) {
      console.error('Error releasing lock:', error)
      throw new Error('Unable to release lock. Please try again.')
    }
  }
}

export default new PaymentLockManager()
