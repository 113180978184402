import React, { useEffect, useRef, useState } from 'react'

import { RightOutlined } from '@ant-design/icons'
import { Affix } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'

import { Skeleton, Title } from '../BaseComponents'

import styles from './NavigatorHeader.module.scss'

interface NavigatorHeaderProps {
  loading?: boolean
  title: string
  hasDetail?: boolean
  profilePanelRoute: string
  container: HTMLDivElement | null
}

const NavigatorHeader: React.FC<NavigatorHeaderProps> = ({
  loading = true,
  title,
  hasDetail = true,
  profilePanelRoute,
  container,
}) => {
  const [tabbable, setTabbable] = useState(true)
  const history = useHistory()
  const location = useLocation()
  const ref = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    history.push({
      search: location.search.replace(
        'profilePanel=overview',
        `profilePanel=${profilePanelRoute}`
      ),
    })
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleClick()
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const param = searchParams.get('profilePanel')

    if (ref?.current) {
      if (param !== 'overview') {
        setTabbable(false)
        ref.current.blur()
        return
      }

      setTabbable(true)
    }
  }, [location])

  return loading ? (
    <div className={styles.loadingContainer}>
      <Skeleton
        paragraph={{
          rows: 0,
        }}
        title={{
          width: '100%',
          className: styles.skeletonTitle,
        }}
        active
      />
    </div>
  ) : (
    <Affix target={() => container}>
      <div
        tabIndex={tabbable ? 0 : -1}
        className={styles.container}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        ref={ref}
      >
        <Title level={5} className={styles.title}>
          {title}
        </Title>
        {hasDetail && <RightOutlined className={styles.icon} />}
      </div>
    </Affix>
  )
}

export default NavigatorHeader
