import React, { useEffect, useState } from 'react'

import _isEqual from 'lodash/isEqual'

import {
  submitEditPhysicalNotesRequest,
  submitNewPhysicalNotesRequest,
} from '../../../api/api-lib'
import GenericAccordion from '../../../components/Accordions/GenericAccordion'
import PatientProfileButtons from '../../../components/Buttons/PatientProfileButtons'
import FormMachine from '../../../components/Forms/FormMachine/FormMachine'
import { onError } from '../../../libs/errorLib'
import { useFormFields } from '../../../libs/hooksLib'
import { notification } from '../../../libs/notificationLib'
import { PhysicalNotesResponse } from '../../../shared-types'
import { patientDataTypes } from '../patient-data-types'
import { styling, template } from './template'

import '../PatientProfile.scss'

interface PhysicalNotesProps {
  handleApiChange: (queryName: string[]) => void
  response: PhysicalNotesResponse
  patientId: string
}

export default function PhysicalNotes(props: PhysicalNotesProps) {
  const [copy, setCopy] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [storedFields, handleFieldChange] = useFormFields({
    ...props.response,
  })

  function copyStoredFields() {
    setCopy(storedFields)
  }
  function switchToEdit() {
    copyStoredFields()
    setIsEditing(true)
  }

  function switchToSave() {
    setIsEditing(false)
  }
  function checkIfSaveButtonShouldBeDisabled() {
    const haveNotesNotChanged = _isEqual(storedFields, copy)
    setIsDisabled(haveNotesNotChanged)
  }

  function cancelEditing() {
    handleFieldChange(copy, false)
    switchToSave()
  }

  useEffect(() => {
    copyStoredFields()
  }, [])

  useEffect(() => {
    checkIfSaveButtonShouldBeDisabled()
  }, [storedFields])

  function handleRadioChange(id: string, key: number) {
    const field = template.find((field) => field.id === id)
    if (typeof field !== 'undefined' && typeof field.units !== 'undefined') {
      const units = field.units
      const event = {
        target: {
          id: `${id}Unit`,
          type: 'radio',
          value: units[key],
        },
      }
      handleFieldChange(event)
    }
  }

  async function handleSubmit(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    try {
      setIsLoading(true)
      const data = {
        height: parseFloat(storedFields.height) || '',
        heightUnit: storedFields.heightUnit || '',
        weight: parseFloat(storedFields.weight) || '',
        // default weightUnit to pounds since that's what the form defaults to unless they actively press kg
        weightUnit: storedFields.weightUnit || 'lb',
        hip: parseFloat(storedFields.hip) || '',
        hipUnit: storedFields.hipUnit || '',
        additionalNotes: storedFields.additionalNotes || '',
        patientId: props.patientId,
      } as PhysicalNotesResponse
      if (props.response?.createdAt) {
        await submitEditPhysicalNotesRequest(data)
      } else {
        await submitNewPhysicalNotesRequest(data)
      }
      props.handleApiChange([
        patientDataTypes.ProviderSidePatientData,
        props.patientId,
      ])
      notification(
        'You have successfully updated the Physical subsection on the patient.',
        'success'
      )
      copyStoredFields()
      setIsLoading(false)
      setIsDisabled(true)
      switchToSave()
    } catch (error) {
      console.error(
        'Error submitting information on Physical subsection of Patient Profile',
        error
      )
      onError(
        error,
        500,
        "Failed to update the patient's physical subsection information. Please inform your administrator."
      )
      setIsLoading(false)
    }
  }

  return (
    <GenericAccordion title="Physical">
      <FormMachine
        storedFields={storedFields}
        template={template}
        handleFieldChange={handleFieldChange}
        handleCheckChange={handleRadioChange}
        isEditing={isEditing}
        styling={styling}
      />
      <PatientProfileButtons
        cancelEditing={cancelEditing}
        isDisabled={isDisabled}
        isEditing={isEditing}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        switchToEdit={switchToEdit}
      />
    </GenericAccordion>
  )
}
