import { ReactNode } from 'react'

import styles from './Row.module.scss'

type RowProps = {
  label: string
  content: string | ReactNode
  testIdPrefix: string
}

export const Row = ({ label, content, testIdPrefix }: RowProps) => {
  return (
    <>
      <div data-testid={`${testIdPrefix}-label`} className={styles.label}>
        {label}
      </div>
      <div data-testid={`${testIdPrefix}-content`} className={styles.content}>
        {content}
      </div>
    </>
  )
}
