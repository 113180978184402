import React from 'react'

import { Avatar as AntDAvatar, AvatarProps as AntDAvatarProps } from 'antd'

import { TestId } from '../../shared-types'

type AvatarProps = AntDAvatarProps & TestId

const Avatar: React.FC<AvatarProps> = (props) => {
  return <AntDAvatar {...props} />
}

export default Avatar
