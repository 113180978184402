import { useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'

import {
  Diagnosis,
  createNewDiagnosis,
  createRCopiaDiagnosis,
} from '../../api/api-lib-typed'
import { DiagnosisOptions } from '../../components/Forms/DiagnosisModal/DiagnosisModal'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'

type useCreateDiagnosisProps = {
  patientId: string
}

export const useAddDiagnosis = ({ patientId }: useCreateDiagnosisProps) => {
  const queryClient = useQueryClient()

  const {
    mutateAsync: create,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(
    async ({
      patientId,
      rCopiaId,
      data,
    }: {
      patientId: string
      data: Diagnosis
      rCopiaId?: string
    }) => {
      const diagnosis = await createNewDiagnosis(patientId, data)
      if (rCopiaId) {
        await createRCopiaDiagnosis({
          ...data,
          PatientRCopiaId: rCopiaId,
          DiagnosisId: diagnosis.DiagnosisId,
        })
      }

      queryClient.invalidateQueries([
        patientDataTypes.PatientDiagnoses,
        patientId,
      ])
    }
  )

  const callCreate = ({
    dx,
    rCopiaId,
  }: {
    dx: DiagnosisOptions
    rCopiaId?: string
  }) => {
    return create({
      patientId,
      rCopiaId,
      data: {
        Diagnosis: dx.icdDescription,
        DiagnosisCode: dx.icdCode,
        DiagnosisDate: moment(dx.diagnosisDate), // what is this moment
        DiagnosisNoteContent: dx.comments,
        DiagnosisOnsetDate: moment(dx.onsetDate),
        DiagnosisOnsetDateFormat: 'MM/DD/YYYY',
        DiagnosisStatus: dx.diagnosisStatus,
        PatientId: patientId,
      },
    })
  }

  return {
    addDiagnosis: callCreate,
    isLoading,
    isSuccess,
    isError,
  }
}
