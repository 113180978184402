import React, { useCallback, useState } from 'react'

import { Select } from 'antd'

import { prettifyCamelCase } from '../../libs/utils'
import Box from '../BaseComponents/Box'
import LineGraph from '../BaseComponents/LineGraph'
import { DataTypes } from './ReportPageHelpers'
import {
  ActiveGraphFilter,
  AggregationMethodEnum,
  GraphDataProps,
  GraphDropdownSelectOptions,
  GraphFilterOptions,
  GraphFilterValue,
} from './types'

import '../ReportPages.scss'

const { Option } = Select
interface ReportGraphProps {
  classes?: string
  dataSource: GraphDataProps
  loading: boolean
  filters: GraphFilterOptions
  activeFilters: ActiveGraphFilter
  onFilterChange: (newFilter: ActiveGraphFilter) => void
  dataBatchOptions: GraphDropdownSelectOptions
  onDataBatchChange: (selectedBatchKey: keyof DataTypes) => void
  currentDataBatch: keyof DataTypes
}

const ReportGraph: React.FC<ReportGraphProps> = ({
  dataSource,
  loading,
  filters,
  onFilterChange,
  activeFilters,
  dataBatchOptions,
  onDataBatchChange,
  currentDataBatch,
}) => {
  const [agreggationMethod, setAggreggationMethod] =
    useState<AggregationMethodEnum>(AggregationMethodEnum.MONTH)
  const lineColors = [
    '#5B8FF9',
    '#6DC8EC',
    '#5D7092',
    '#F6BD16',
    'green',
    'red',
    '#967459',
    '#f19cbb',
    '#e16b0e',
  ]

  const handleAggregationChange = useCallback(
    (value: AggregationMethodEnum) => {
      setAggreggationMethod(value)
    },
    []
  )
  const handleFilterChange = useCallback(
    (filterKey: string, value: GraphFilterValue | null) => {
      onFilterChange({ [filterKey]: value ?? undefined })
    },
    []
  )

  if (!dataSource) {
    return null
  }
  return (
    <Box>
      <div className="graph-select-container">
        <Select
          data-testid="reportgraph-aggregation-select"
          defaultValue={agreggationMethod}
          className="graph-select"
          onChange={handleAggregationChange}
          dropdownMatchSelectWidth={false}
        >
          <Option value={AggregationMethodEnum.DAY}>Day</Option>
          <Option value={AggregationMethodEnum.WEEK}>Week</Option>
          <Option value={AggregationMethodEnum.MONTH}>Month</Option>
        </Select>
        {dataBatchOptions.length > 0 && (
          <Select
            data-testid="reportgraph-batch-select"
            defaultValue={currentDataBatch}
            className="graph-select"
            onChange={onDataBatchChange}
            dropdownMatchSelectWidth={false}
            options={dataBatchOptions}
          />
        )}
        {Object.keys(filters).map((filterKey) => (
          <Select
            data-testid={`reportgraph-filter-select-${filterKey}`}
            style={{ minWidth: filters[filterKey].minWidth }}
            value={activeFilters[filterKey]}
            key={filterKey}
            className="graph-select"
            placeholder={
              filters[filterKey].filterName ||
              prettifyCamelCase(filterKey, 'FIRST_WORD')
            }
            options={filters[filterKey].options}
            onSelect={(value: any) => handleFilterChange(filterKey, value)}
            onClear={() => handleFilterChange(filterKey, null)}
            allowClear
          />
        ))}
      </div>
      <LineGraph
        data={dataSource[agreggationMethod]}
        colors={lineColors}
        loading={loading}
      />
    </Box>
  )
}

export default ReportGraph
