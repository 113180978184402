import { useCallback, useEffect, useMemo, useState } from 'react'

import { Col, Form, FormInstance, Row, Switch, Typography } from 'antd'
import { SwitchChangeEventHandler } from 'antd/lib/switch'
import { isEqual } from 'lodash'

import { FormSectionKey } from '../../../api/intakeForms'
import {
  FormSectionLocalStorage,
  useLocalStorage,
} from '../../../hooks/useLocalStorage'
import { Checkbox, CheckboxGroup, Modal } from '../../BaseComponents'
import {
  INTAKE_FORM_SECTIONS,
  getFormSectionComponent,
} from '../../PatientIntake/sections/helpers'

import styles from '../_shared.module.scss'

const { Link, Text, Title } = Typography

interface FormSection {
  section: FormSectionKey
  title: string
}

interface DefaultIntakeCheckboxesProps {
  form: FormInstance
  hasIntakeForm?: boolean
}

export enum DefaultIntakeFormItems {
  formSections = 'formSections',
  formSectionsSwitch = 'formSectionsSwitch',
}

const DefaultIntakeSections = [FormSectionKey.GENERAL_INFORMATION]

const DefaultIntakeCheckboxes = ({
  form,
  hasIntakeForm = false,
}: DefaultIntakeCheckboxesProps) => {
  const [currentPreview, setCurrentPreview] = useState<FormSection | null>(null)

  // useLocalStorage to determine whether provider wants to send intake forms in general
  const [intakeFormSwitch, setIntakeFormSwitch] = useLocalStorage(
    FormSectionLocalStorage.INTAKE_FORM_SWITCH,
    false
  )

  // if user already has an Osmind intake form (i.e. patient already exists and this form
  // is in Documents section), override the useLocalStorage intakeFormSwitch value
  const [hasIntakeFormOverride, setHasIntakeFormOverride] = useState(
    !hasIntakeForm
  )

  const [intakeSections, setIntakeSections] = useLocalStorage<FormSectionKey[]>(
    FormSectionLocalStorage.INTAKE_SECTIONS,
    DefaultIntakeSections
  )

  // used in parent component SendIntakeForms to determine if "Send Forms" button should be disabled
  useEffect(() => {
    const isSwitchChecked = intakeFormSwitch && hasIntakeFormOverride
    // Form in use updates as either intakeFormSwitch or hasIntakeFormOverride updates
    // (whether from localstorage or whenever the switch is checked)
    form.setFields([
      {
        name: DefaultIntakeFormItems.formSectionsSwitch,
        value: isSwitchChecked,
      },
    ])
  }, [intakeFormSwitch, hasIntakeFormOverride])

  useEffect(() => {
    const currentFormFields = form.getFieldsValue()
    if (
      !isEqual(
        currentFormFields[DefaultIntakeFormItems.formSections],
        intakeSections
      )
    ) {
      form.setFields(
        // Form in use updates as either intakeSections updates
        // (whether from localstorage or whenever the sections are selected/deselected)
        [{ name: DefaultIntakeFormItems.formSections, value: intakeSections }]
      )
    }
  }, [intakeSections, form.getFieldsValue()])

  useEffect(() => {
    setHasIntakeFormOverride(!hasIntakeForm)
  }, [hasIntakeForm])

  const previewModal = useMemo(() => {
    if (!currentPreview) return null
    const { section, title } = currentPreview
    const modalForm = getFormSectionComponent(section, form)

    return (
      <Modal
        footer={null}
        onCancel={() => setCurrentPreview(null)}
        style={{ marginTop: '80px' }}
        title={`${title} preview`}
        visible={!!currentPreview}
        zIndex={1120}
      >
        {modalForm}
      </Modal>
    )
  }, [currentPreview])

  const handleChange = (change: (string | number | boolean)[]) =>
    setIntakeSections(change)

  const onSwitchClick: SwitchChangeEventHandler = useCallback(
    (checked: boolean) => {
      // Update localstorage 'INTAKE_FORM_SWITCH'
      setIntakeFormSwitch(checked)
      // Reset override value for hasIntakeFormOverride
      setHasIntakeFormOverride(checked)
    },
    [form]
  )

  return (
    <>
      <Title level={5}>Intake form</Title>
      <Form.Item
        name={DefaultIntakeFormItems.formSectionsSwitch}
        initialValue={intakeFormSwitch && hasIntakeFormOverride}
      >
        <Switch
          checked={intakeFormSwitch && hasIntakeFormOverride}
          onChange={onSwitchClick}
          data-testid="send-intake-form-button"
        />
        <Text className={styles.switchText}>Send Osmind intake form</Text>
      </Form.Item>
      {intakeFormSwitch && hasIntakeFormOverride && (
        <Form.Item name={DefaultIntakeFormItems.formSections}>
          {previewModal}
          <CheckboxGroup defaultValue={intakeSections} onChange={handleChange}>
            {INTAKE_FORM_SECTIONS.map(({ section, title }, i) => {
              const isGeneral = section === FormSectionKey.GENERAL_INFORMATION
              return (
                <Row
                  key={`${section}-${i}`}
                  className={styles.intakFormSectionItem}
                >
                  <Col>
                    <Checkbox disabled={isGeneral} value={section}>
                      <Text>{title}</Text>
                    </Checkbox>
                    <Link onClick={() => setCurrentPreview({ section, title })}>
                      View fields
                    </Link>
                  </Col>
                  <Col xs={24} className={styles.subtextGeneralInfo}>
                    {isGeneral && (
                      <Text type="secondary">
                        This section is required to capture basic demographics
                        and contact details.
                      </Text>
                    )}
                  </Col>
                </Row>
              )
            })}
          </CheckboxGroup>
        </Form.Item>
      )}
    </>
  )
}

export default DefaultIntakeCheckboxes
