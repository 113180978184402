import { HTMLAttributes } from 'react'

import {
  differenceInMinutes,
  getHours,
  isAfter,
  isBefore,
  setHours,
  setMinutes,
} from 'date-fns'
import { toDate } from 'date-fns-tz'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { View, stringOrDate } from 'react-big-calendar'

import { DEFAULT_TIMEZONE } from '../../../containers/Provider/availability/helpers'
import { getTextToDisplay } from '../../../containers/Scheduling/utils/providers-name-text-display'
import {
  DELETED_APPT_TYPE,
  NO_APPT_TYPE,
} from '../../../hooks/useSchedulingFilters/useSchedulingFilters'
import { generateUniqueGoogleCalendarId } from '../../../hooks/useSchedulingFilters/useSchedulingFilters.helpers'
import { isSameHourAndMinutes } from '../../../libs/utils'
import {
  DateISO8601,
  Event,
  ScheduledEvent,
  Teammate,
  YYYYMMDDDateString,
} from '../../../shared-types'
import { ResourceType, Timezone, Views } from '../constants'
import {
  CalendarEventPrivacy,
  EventCategory,
  UserCalendarAccount,
} from '../types'

export const getAllGoogleCalendarIds = (
  teammateCalendars?: UserCalendarAccount[]
) => {
  const allGoogleCalendarsId = teammateCalendars
    ?.map((account) =>
      account.calendars
        .filter((props) => {
          const { eventPrivacy } = props

          return (
            eventPrivacy &&
            ![
              CalendarEventPrivacy.INTEGRATED,
              CalendarEventPrivacy.INTEGRATED_WITH_DETAILS,
            ].includes(eventPrivacy)
          )
        })
        .map((calendar) => {
          return calendar.externalCalendarId.toString()
        })
    )
    .reduce(
      (calendarIdsA, calendarIdsB) => [...calendarIdsA, ...calendarIdsB],
      []
    )
  return allGoogleCalendarsId || []
}

export const hasPatientSelected = (
  selectedPatientId: string,
  initialSelectedPatientId: string
): boolean => {
  return (
    Boolean(selectedPatientId) && selectedPatientId !== initialSelectedPatientId
  )
}

export const MIN_ALL_DAY_EVENT_LENGTH = 1425
export const DAY_IN_MINUTES = 1440

/**
 * Takes a day (as a string) and gets the start of that day, based on a timezone
 * - Returned value will be a point in time that can be converted to any other timezone, if needed
 * @param {YYYYMMDDDateString} ymdDate - 'yyyy-MM-dd', e.g. '2024-01-31'
 * @param {string} [timezone="America/Los_Angeles"] - Defaults to `DEFAULT_TIMEZONE`, which is "America/Los_Angeles"`
 * @returns {Date}
 */
export const startOfDayForTz = (
  ymdDate: YYYYMMDDDateString,
  /**
   * For this to return the proper "start of day based on a timezeon", the date needs to be
   * - `YYYY-MM-DD` in date format, as recognizable by the underlying `DateFNS.toDate`
   *
   * A string representing dateTime will pass typechecking, but will not return the expected result
   *
   * We considered these options before aborting them:
   * - Regex assertion for YYY-MM-DD format and throwing an error if it failed
   *   - This would result in adding conditional logic to handle the error case for every method call
   * - Use `DateFns.startOfDay`
   *   - This doesn't take TZ as a parameter, so it won't work as needed
   */
  timezone: string = DEFAULT_TIMEZONE
): Date => {
  return toDate(ymdDate, { timeZone: timezone })
}

// ensure that events outputted are in a form react-big-calendar expects
export const convertToRBCEvent = (
  event: ScheduledEvent,
  providerTimezone?: Timezone
): Event => {
  const { IsAllDayEvent, EventName: title, eventId } = event

  /**
   * These props are from a union type and conditionally exist
   *   therefore, access them based on that condition
   */
  let start = IsAllDayEvent
    ? startOfDayForTz(event.StartDate, providerTimezone)
    : toDate(event.StartTime, { timeZone: providerTimezone })
  let end = IsAllDayEvent
    ? startOfDayForTz(event.EndDate, providerTimezone)
    : toDate(event.EndTime, { timeZone: providerTimezone })

  // checks if in all-day event
  const isSameTime = Boolean(isSameHourAndMinutes(end, start))
  /**
   * If either
   * - the UTC hours is 0 (midnight) - Osmind "all day" events since our server response for all Day events is based in UTC start/end of day
   * - the local (browser) hours is 0 -
   * HOW DOES THIS ADDRESS PROVIDER TZ, if different from Browser TZ?
   */
  const isStartMidnight = start.getUTCHours() === 0 || getHours(start) === 0
  const isBothMidnight = isSameTime && isStartMidnight

  const eventLengthInMinutes = Math.abs(differenceInMinutes(end, start))
  // 1415 minutes is equivalent to 23 hours and 45 minutes~
  // if an event is within 15 minutes of 24 hours (or longer),
  // then make it all-day, to account for how single all-day events are
  // currently created
  const allDay =
    IsAllDayEvent ||
    (eventLengthInMinutes !== 0 &&
      (eventLengthInMinutes >= MIN_ALL_DAY_EVENT_LENGTH ||
        !eventLengthInMinutes))

  if (isBothMidnight) {
    if (start.getUTCHours() === 0) {
      start = setMinutes(setHours(start, 0), 0)
      end = setMinutes(setHours(end, 0), 0)
    }
  }
  return {
    allDay,
    showTimespan: !IsAllDayEvent && !isBothMidnight,
    title,
    start,
    end,
    resource: event,
    eventId,
  }
}

type MappingPatientData = {
  teammatesSelected: Set<string>
  roomsSelected: Set<string>
  appointmentTypesSelected: Set<string>
  isUnassignedSelected: boolean
  patientIdSelected: string
  appointmentTypesOptionsSet: Set<string>
}

export const checkIfEventIsSelected = (
  event: ScheduledEvent,
  {
    appointmentTypesSelected,
    teammatesSelected,
    isUnassignedSelected,
    roomsSelected,
    appointmentTypesOptionsSet,
  }: Pick<
    MappingPatientData,
    | 'teammatesSelected'
    | 'appointmentTypesSelected'
    | 'roomsSelected'
    | 'isUnassignedSelected'
    | 'appointmentTypesOptionsSet'
  >
) => {
  const {
    EventProviders,
    Room,
    AppointmentTypeId,
    IsGoogleEvent,
    GoogleCalendarId,
    GoogleCalendarAccountName,
  } = event

  const isUnassigned = !EventProviders.length && isUnassignedSelected

  let correspondingSelectedProviders = EventProviders.filter((providerId) =>
    teammatesSelected.has(providerId)
  )

  if (
    GoogleCalendarId &&
    GoogleCalendarAccountName &&
    teammatesSelected.has(
      generateUniqueGoogleCalendarId(
        GoogleCalendarId,
        GoogleCalendarAccountName
      )
    )
  ) {
    const uniqueCalendarId = generateUniqueGoogleCalendarId(
      GoogleCalendarId,
      GoogleCalendarAccountName
    )
    correspondingSelectedProviders = [
      ...correspondingSelectedProviders,
      uniqueCalendarId,
    ]
  }

  const correspondingSelectedRooms =
    Room && roomsSelected.has(Room) ? [Room] : []

  const appointmentTypeIsSelected =
    Boolean(AppointmentTypeId) &&
    appointmentTypesSelected.has(AppointmentTypeId)
  const appointmentDoesNotHaveType =
    appointmentTypesSelected.has(NO_APPT_TYPE) && AppointmentTypeId === ''
  const appointmentIsGoogleEvent =
    appointmentTypesSelected.has(NO_APPT_TYPE) && IsGoogleEvent
  const appointmentIsDeleted =
    Boolean(AppointmentTypeId) &&
    !appointmentTypesOptionsSet.has(AppointmentTypeId) &&
    appointmentTypesSelected.has(DELETED_APPT_TYPE)

  const passesAppointmentTypeFilter =
    appointmentTypeIsSelected ||
    appointmentDoesNotHaveType ||
    appointmentIsGoogleEvent ||
    appointmentIsDeleted

  return {
    isUnassigned,
    correspondingSelectedProviders,
    correspondingSelectedRooms,
    passesAppointmentTypeFilter,
  }
}

export const convertToRBCEventWrapper = (
  event: ScheduledEvent,
  index: number,
  checkedEvents: ReturnType<typeof checkIfEventIsSelected>[],
  providerTimezone?: Timezone
) => {
  const rbcEvent = convertToRBCEvent(event, providerTimezone)
  const mappedEvents: Event[] = []

  const {
    isUnassigned,
    correspondingSelectedProviders,
    correspondingSelectedRooms,
    passesAppointmentTypeFilter,
  } = checkedEvents[index]

  if (!passesAppointmentTypeFilter) return

  if (isUnassigned) {
    rbcEvent.resourceId = 'unassigned'
    mappedEvents.push(rbcEvent)
  }

  const addEventToList = (value: string) => {
    const rbcEventCopy = cloneDeep(rbcEvent)
    rbcEventCopy.resourceId = value
    mappedEvents.push(rbcEventCopy)
  }

  correspondingSelectedProviders.forEach((value) => addEventToList(value))
  correspondingSelectedRooms.forEach((value) => addEventToList(value))

  return mappedEvents
}

// transforms API ScheduledEvent to BigCal Event
export const mapEventsToCalendar = (
  rawEvents: Record<string, ScheduledEvent>,
  {
    appointmentTypesSelected,
    patientIdSelected,
    roomsSelected,
    teammatesSelected,
    isUnassignedSelected,
    appointmentTypesOptionsSet,
  }: MappingPatientData,
  initialPatientIdSelected: string,
  providerTimezone?: Timezone
): Event[] => {
  const filterByPatient = (event: ScheduledEvent) =>
    event.OsmindPatientId === patientIdSelected

  const rawEventArray = Object.values(rawEvents)

  const events = hasPatientSelected(patientIdSelected, initialPatientIdSelected)
    ? rawEventArray.filter(filterByPatient)
    : rawEventArray

  const checkedEvents = events.map((e) =>
    checkIfEventIsSelected(e, {
      appointmentTypesSelected,
      roomsSelected,
      teammatesSelected,
      isUnassignedSelected,
      appointmentTypesOptionsSet,
    })
  )

  const mappedEvents = events.reduce<Event[][]>((acc, e, idx) => {
    const event = convertToRBCEventWrapper(
      e,
      idx,
      checkedEvents,
      providerTimezone
    )
    if (event) {
      acc.push(event)
    }
    return acc
  }, [])

  return mappedEvents.flat()
}

export const getProvidersNames = (
  provArray: string[],
  teammates: Teammate[]
) => {
  const providerNames = []
  for (const p of provArray) {
    providerNames.push(getTextToDisplay(p, teammates))
  }
  return providerNames
}

export const generateCalendarOptions = (
  teammateCalendars?: UserCalendarAccount[]
) => {
  const calendarOptions: EventCategory[] = []
  if (teammateCalendars) {
    for (const calendarAccount of teammateCalendars) {
      for (const cal of calendarAccount.calendars) {
        const value = generateUniqueGoogleCalendarId(
          cal.calendarId,
          calendarAccount.accountName
        )
        const type = ResourceType.PROVIDER
        const label = cal.calendarName
        const subLabel = calendarAccount.accountName

        calendarOptions.push({ label, subLabel, value, type })
      }
    }
  }
  return calendarOptions
}

export const handleTooltip = (
  event: Event,
  teammates: Teammate[],
  patientList: Map<string, string>
): string => {
  const {
    OsmindPatientId: patientId,
    AppointmentType: appointmentType,
    Providers: providers,
    EventProviders: eventProviders,
    Facility: facility,
  } = event.resource as ScheduledEvent

  const title = event.title || ''
  let provNameList: string[] = []
  if (eventProviders && eventProviders.length) {
    provNameList = getProvidersNames(eventProviders, teammates)
  }
  const patientName = patientList.get(patientId)
  const textProviders = provNameList.length
    ? provNameList.join(' - ')
    : providers
  const fields = [patientName, title, appointmentType, textProviders, facility]
  const dayTitle = fields.filter(Boolean).join(' - ')

  return patientName ? dayTitle : title
}

export const handleEventPropGetter = (
  event: Event,
  start: stringOrDate,
  end: stringOrDate,
  originEvents: Record<string, ScheduledEvent>,
  viewSelected: Views
): HTMLAttributes<HTMLDivElement> => {
  const eventItem = Object.values(originEvents).filter(
    (item: ScheduledEvent) =>
      item.EventName === event.title &&
      (item.IsAllDayEvent
        ? moment(item.StartDate).isSame(start) &&
          moment(item.EndDate).isSame(end)
        : moment(item.StartTime).isSame(start) &&
          moment(item.EndTime).isSame(end))
  )
  if (
    eventItem.length !== 0 &&
    viewSelected !== Views.AGENDA &&
    eventItem[0].ColorHex
  ) {
    // Agenda is plain black on white, for printing use case.
    return {
      style: { border: 'none' },
    }
  }

  return {}
}

export const getEndOfNextMonthDate = (date: DateISO8601 | Date | undefined) => {
  return moment(date).add(1, 'month').endOf('month')
}

// gets a date range relative to a given date or today
export const getFetchedDateRanges = (date: DateISO8601 | Date | undefined) => {
  return {
    start: moment(date).subtract(1, 'month').startOf('month').toISOString(),
    end: getEndOfNextMonthDate(date).toISOString(),
  }
}

const viewToInterval: Record<View, 'day' | 'month' | 'week'> = {
  agenda: 'month',
  day: 'day',
  month: 'month',
  week: 'week',
  work_week: 'week',
} as const

export const getDateBoundariesOfView = (
  dateSelected: Date,
  viewSelected: View
) => {
  // these are currently based on browser TZ, not on provider TZ
  const endDate = moment(dateSelected)
    .endOf(viewToInterval[viewSelected])
    .toDate()
  const startDate = moment(dateSelected)
    .startOf(viewToInterval[viewSelected])
    .toDate()
  return { startDate, endDate }
}

export const getAreDateRangesMoved = ({
  currentMaxFetchedEnd,
  currentMinFetchedStart,
  newEnd,
  newStart,
}: {
  currentMinFetchedStart: DateISO8601
  newStart: DateISO8601
  currentMaxFetchedEnd: DateISO8601
  newEnd: DateISO8601
}) => {
  const isStartDateMovedBack = isAfter(
    new Date(currentMinFetchedStart),
    new Date(newStart)
  )
  const isEndDateMovedForward = isBefore(
    new Date(currentMaxFetchedEnd),
    new Date(newEnd)
  )
  return { isStartDateMovedBack, isEndDateMovedForward }
}
