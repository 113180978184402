import React from 'react'

import { InputNumber as AntDInputNumber } from 'antd'
import { InputNumberProps as AntDInputNumberProps } from 'antd/lib/input-number'

import { TestId } from '../../shared-types'

type InputNumberProps = AntDInputNumberProps & TestId

const InputNumber: React.FC<InputNumberProps> = ({ testId, ...props }) => {
  return <AntDInputNumber data-testid={testId} {...props} />
}

export default InputNumber
