// Import react
import React, { useState } from 'react'

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Import libs/other
import { deleteAllergy } from '../../api/api-lib'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import LoaderButton from '../Buttons/LoaderButton'

const DeleteAllergy = (props) => {
  const [isDeleting, setIsDeleting] = useState(false)

  async function handleDeleteAllergy(event) {
    event.preventDefault()
    setIsDeleting(true)
    try {
      await deleteAllergy(props.allergyId, props.patientId)
      props.handleApiChange([
        patientDataTypes.PatientAllergies,
        props.patientId,
      ])
      props.handleApiChange([
        patientDataTypes.ProviderSidePatientData,
        props.patientId,
      ])
      notification(
        'You have successfully deleted an allergy. Please wait a moment for the allergies list to update.',
        'success'
      )
    } catch (error) {
      onError(
        error,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    } finally {
      setIsDeleting(false)
      props.handleClose()
    }
  }

  return (
    <LoaderButton
      id="deleteEditAllergy"
      onClick={handleDeleteAllergy}
      isLoading={isDeleting}
      className="button-label button-block center button-delete"
      textInside="Deleting allergy"
    >
      <div className="button-label">
        <FontAwesomeIcon icon={faTrashAlt} /> Delete
      </div>
    </LoaderButton>
  )
}

export default DeleteAllergy
