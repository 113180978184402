import { API } from 'aws-amplify'

import { ReportType } from '../stories/Reports/types'

export interface AppointmentItem {
  discriminator: ReportType.APPOINTMENTS
  colorHex: string
  createdAt: string
  endTime: string
  eventName: string
  eventProviders: string[] | null[]
  eventType: string
  facility: string
  internalNotes: string
  location: string
  osmindEventId: string
  osmindPatientId: string
  osmindProviderId: string
  patientFirstName: string
  patientMiddleName?: string
  patientLastName: string
  patientPublicId?: string
  startTime: string
  type: string
  patientId: string
  providerId: string
}

export interface AppointmentsResponse {
  appointments: AppointmentItem[]
  total: number
}

type getAppointmentsByDateRange = (
  start: string,
  end: string
) => Promise<AppointmentsResponse>

const mapAppointmentItems = ({
  appointments,
  ...aggregations
}: AppointmentsResponse) => {
  const mappedNotes = appointments.map(({ type, ...appointment }) => {
    const appointmentType = type ? type : 'None'
    const mappedNote = {
      ...appointment,
      type: appointmentType,
      discriminator: ReportType.APPOINTMENTS,
    } as AppointmentItem
    return mappedNote
  })

  return { appointments: mappedNotes, ...aggregations }
}

export const getAppointmentsByDateRange: getAppointmentsByDateRange = async (
  start,
  end
) => {
  return mapAppointmentItems(
    await API.get(
      'scheduling',
      `/appointments/report?start=${start}&end=${end}`,
      {}
    )
  )
}
