import React, { useState } from 'react'

import { Card } from 'react-bootstrap'

import { sendFeatureEnablementEmail } from '../../api/api-lib'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import LoaderButton from '../Buttons/LoaderButton'

import styles from '../../containers/_shared.module.scss'

interface DisabledFeatureMessageProps {
  feature: string
  isTestPatient?: boolean
  onModalClose?: () => void
}

export default function DisabledFeatureMessage(
  props: DisabledFeatureMessageProps
) {
  const [isLoading, setIsLoading] = useState(false)
  const featureCapitalized =
    props.feature.charAt(0).toUpperCase() + props.feature.slice(1)
  let message = `If you would like to enable ${props.feature}, please click the button below to contact the Osmind team to enable the feature for you (may require an upgrade).`
  if (props.feature === 'e-prescribing') {
    message =
      'To access e-prescribing, electronic prescribing of controlled substances (EPCS), prescription drug monitoring programs (PDMP), powerful medication and allergy interaction checkers, real-time prescription benefit eligibility checkers, and prior authorization automation, please click the button below to contact the Osmind team to enable the feature for you (may require an upgrade).'
  } else if (props.feature === 'secure messaging') {
    message =
      'To access HIPAA-compliant secure messaging with your patients, please click the button below to contact the Osmind team to enable the feature for you (may require an upgrade).'
  }

  async function sendEnableEmail() {
    setIsLoading(true)
    try {
      if (props.feature !== 'e-prescribing') {
        await sendFeatureEnablementEmail({ feature: props.feature })
      }
      notification(
        `You have successfully notified our team to enable ${props.feature} for your clinic. We will be in touch via email.`,
        'success'
      )
      // if this message is being displayed in a modal, close the modal upon successful sending of feature enablement email
      if (props.onModalClose) props.onModalClose()
    } catch (error) {
      onError(
        error,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
    setIsLoading(false)
  }
  return (
    <div className={styles.scroll}>
      <Card className="text-center" style={{ border: 'none' }}>
        <Card.Header
          as="h3"
          style={{ borderBottom: 'none' }}
          className="feature-disabled-header"
        >
          <b>Feature disabled</b>
        </Card.Header>
        <Card.Body>
          <Card.Title style={{ fontSize: 18 }}>
            {featureCapitalized} is currently disabled for{' '}
            {props.isTestPatient ? 'this patient' : 'your clinic'}.
          </Card.Title>
          <Card.Text>{message}</Card.Text>
          <LoaderButton
            style={{ width: '40%', marginTop: 25 }}
            isDisabled={false}
            isLoading={isLoading}
            onClick={sendEnableEmail}
            textInside="Sending request"
          >
            Send request to enable {props.feature}
          </LoaderButton>
        </Card.Body>
      </Card>
    </div>
  )
}
