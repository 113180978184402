import React from 'react'

import {
  Radio as AntDRadio,
  RadioGroupProps as AntDRadioGroupProps,
  RadioProps as AntDRadioProps,
} from 'antd'

const { Button: AntDRadioButton, Group: AntDRadioGroup } = AntDRadio

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RadioProps extends AntDRadioProps {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RadioGroupProps extends AntDRadioGroupProps {}

export const Radio = (props: RadioProps) => <AntDRadio {...props} />
const RadioButton = (props: RadioProps) => <AntDRadioButton {...props} />
const RadioGroup = (props: RadioGroupProps) => <AntDRadioGroup {...props} />

Radio.Group = RadioGroup
Radio.Button = RadioButton
