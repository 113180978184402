import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const SeizureCodes: any = [
  {
    128613002: {
      title: 'Seizures',
      description: 'Seizure disorder (disorder)',
      snomedcode: 128613002,
      icdcode: 'R569',
      icddescription: 'Unspecified convulsions',
    },
  },
  {
    '84757009': {
      title: 'sz.Epilepsy',
      description: 'Epilepsy (disorder)',
      snomedcode: 84757009,
      icdcode: 'G40909',
      icddescription:
        'Epilepsy, unspecified, not intractable, without status epilepticus',
    },
  },
]

export const SeizuresDropDownValues = constructPatientDemographicObject(
  SeizureCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
