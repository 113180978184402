import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { loadPatientInfo } from '../../api/api-lib'

export enum QueryKeys {
  DEMOGRAPHICS = 'DEMOGRAPHICS',
}

// TODO should return PatientInfo type here
export const usePatientDemographics = (
  patientId: string | undefined | null
): UseQueryResult<any> => {
  return useQuery<any[]>([QueryKeys.DEMOGRAPHICS, patientId], {
    queryFn: async () => loadPatientInfo(patientId),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!patientId,
  })
}
