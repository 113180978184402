import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { getDiagnosisSnapshot } from '../../api/patients'
import { parseDateOrNull } from '../../libs/utils'
import { DiagnosisHistory } from '../../shared-types'

export enum DiagnosisHistoryQueryKeys {
  DiagnosisSnapshot = 'DIAGNOSIS_HISTORY_SNAPSHOT',
}

export interface DiagnosisSnapshotHook {
  activeDiagnoses: DiagnosisHistory[]
  inactiveDiagnoses: DiagnosisHistory[]
  resolvedDiagnoses: DiagnosisHistory[]
  patientReportedDiagnoses: DiagnosisHistory[]
}

export const useDiagnosisSnapshot = (
  patientId: string,
  startTime: Date,
  endTime?: Date | null
): UseQueryResult<DiagnosisSnapshotHook> => {
  return useQuery<DiagnosisSnapshotHook>(
    [
      DiagnosisHistoryQueryKeys.DiagnosisSnapshot,
      patientId,
      startTime,
      endTime,
    ],
    {
      queryFn: async () => {
        const diagnosisSnapshot = await getDiagnosisSnapshot(patientId, endTime)

        const activeDiagnoses: DiagnosisHistory[] = []
        const inactiveDiagnoses: DiagnosisHistory[] = []
        const resolvedDiagnoses: DiagnosisHistory[] = []
        const patientReportedDiagnoses: DiagnosisHistory[] = []

        diagnosisSnapshot.forEach((diagnosis: DiagnosisHistory) => {
          const createdAt = parseDateOrNull(
            diagnosis.CreatedOn ?? diagnosis.CreatedAt,
            true
          )
          const lastUpdatedAt = parseDateOrNull(
            diagnosis.DiagnosisHistoryCreatedAt,
            true
          )

          // invalid diagnosis data
          if (!createdAt || !lastUpdatedAt) {
            return
          }

          // diagnosis created after endTime should not be included (need this check for backfilled records)
          if (endTime && createdAt > endTime) {
            return
          }

          // include all active diagnoses
          if (diagnosis.DiagnosisStatus === 'Active') {
            activeDiagnoses.push(diagnosis)
            return
          }

          // include (non-empty) patient-reported diagnoses
          if (diagnosis.isPatientReported && diagnosis.Diagnosis) {
            patientReportedDiagnoses.push(diagnosis)
            return
          }

          // only include inactive if updated after startTime
          if (
            diagnosis.DiagnosisStatus === 'Inactive' &&
            lastUpdatedAt >= startTime
          ) {
            inactiveDiagnoses.push(diagnosis)
            return
          }

          // only include resolved if updated after startTime
          if (
            diagnosis.DiagnosisStatus === 'Resolved' &&
            lastUpdatedAt >= startTime
          ) {
            resolvedDiagnoses.push(diagnosis)
            return
          }
        })

        return {
          activeDiagnoses,
          inactiveDiagnoses,
          resolvedDiagnoses,
          patientReportedDiagnoses,
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
