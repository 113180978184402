import { useEffect, useRef, useState } from 'react'

import { Form } from 'antd'

import {
  deletePracticeLogo,
  updatePracticeLogo,
  updatePracticeSettings,
} from '../../../api/api-lib'
import { s3DownloadPracticeLogos } from '../../../libs/awsLib'
import { onError } from '../../../libs/errorLib'
import { notification } from '../../../libs/notificationLib'
import { npiRegex } from '../../../libs/regex'
import {
  Button,
  Card,
  ImageUpload,
  Input,
} from '../../../stories/BaseComponents'
import AddressAutocomplete, {
  Address,
} from '../../../stories/BaseComponents/AddressAutocomplete'
import { ImageUploadHandler } from '../../../stories/BaseComponents/ImageUpload'
import EINInput from '../../../stories/BaseComponents/SpecialInputFields/EINInput'
import PhoneNumberInput from '../../../stories/BaseComponents/SpecialInputFields/PhoneNumberInput'
import { getBase64FromUrl } from './PracticeSettings.helpers'

import styles from '../../_shared.module.scss'
import './PracticeSettings.scss'

interface FormValues {
  PracticeName: string
  PracticePhone: string
  AutocompleteAddress: Address
  EIN: string
  BillingNPI: string
}

interface Props {
  providerData: any
  updateProviderData: Function
}

export function PracticeSettings(props: Props) {
  const imageUploadRef = useRef<ImageUploadHandler>(null)
  const { providerData, updateProviderData } = props
  const [form] = Form.useForm()
  const [isSaveImageLoading, setSaveImageLoading] = useState<boolean>(false)
  const [originalImageBase64, setOriginalImageBase64] = useState<string>('')
  const [croppedImageBase64, setCroppedImageBase64] = useState<string>('')
  // toggle for 'loading' mode on the button below
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function loadImage() {
    const croppedUrl = (await s3DownloadPracticeLogos(
      `${providerData.providerId}/practiceLogo`
    )) as string
    const uncroppedUrl = (await s3DownloadPracticeLogos(
      `${providerData.providerId}/practiceUncroppedLogo`
    )) as string

    if (croppedUrl) {
      const croppedRes = await getBase64FromUrl(croppedUrl)
      setCroppedImageBase64(
        croppedRes.replace(/^data:image\/(png|jpg);base64,/, '')
      )
    }

    if (uncroppedUrl) {
      const originRes = await getBase64FromUrl(uncroppedUrl)
      setOriginalImageBase64(
        originRes.replace(/^data:image\/(png|jpg);base64,/, '')
      )
    }
  }

  useEffect(() => {
    const clinicData = providerData?.clinicData || {}
    const values: FormValues = {
      PracticeName: clinicData.PracticeName ?? '',
      PracticePhone: clinicData.ProviderPhone ?? '',
      AutocompleteAddress: {
        Address1: clinicData.ProviderPracticeAddress ?? '',
        Address2: clinicData.ProviderPracticeAddressExtn ?? '',
        City: clinicData.ProviderPracticeCity ?? '',
        State: clinicData.ProviderPracticeState ?? '',
        Zipcode: clinicData.ProviderPracticeZipcode ?? '',
      },
      BillingNPI: clinicData.ProviderPracticeBillingNPI || '',
      EIN: clinicData.ProviderPracticeEIN || '',
    }
    form.setFieldsValue(values)

    loadImage()
  }, [])

  const update = (values: FormValues) => {
    const updateItem = {
      name: values.PracticeName,
      phoneNumber: values.PracticePhone?.replace(/\D/g, ''),
      addressLine1: values.AutocompleteAddress.Address1,
      addressLine2: values.AutocompleteAddress.Address2,
      city: values.AutocompleteAddress.City,
      state: values.AutocompleteAddress.State,
      zipcode: values.AutocompleteAddress.Zipcode,
      billingNPI: values.BillingNPI,
      ein: values.EIN,
    }
    return updatePracticeSettings(updateItem)
  }

  const updateImage = async (originalUrl: string, croppedUrl: string) => {
    const updateItem = {
      croppedLogo: croppedUrl !== '' ? croppedUrl : croppedImageBase64,
      uncroppedLogo: originalUrl !== '' ? originalUrl : originalImageBase64,
    }
    await updatePracticeLogo(updateItem)
    if (originalUrl) {
      setOriginalImageBase64(originalUrl)
    }
    if (croppedUrl) {
      setCroppedImageBase64(croppedUrl)
    }
  }

  const handleUploadImage = async () => {
    if (!imageUploadRef.current?.imageChanged) {
      return true
    }

    const imageRemoved = imageUploadRef.current.imageRemoved
    let imageError: string | undefined
    let logError: any | undefined

    try {
      if (imageRemoved) {
        await deletePracticeLogo()
      } else {
        await updateImage(originalImageBase64, croppedImageBase64)
      }
    } catch (e) {
      logError = e
      imageError = `Practice details were updated, but we were unable to ${
        imageRemoved ? 'delete' : 'update'
      } the logo.`
    }

    if (imageError) {
      onError(logError, '', imageError)
      return false
    }
    return true
  }

  const handleSubmit = async (values: FormValues) => {
    setIsLoading(true)
    try {
      await update(values)
    } catch (e) {
      console.error(e)
      onError(new Error('Unable to update a practice settings'))
      setIsLoading(false)
      return
    }

    const didUploadImage = await handleUploadImage()
    if (didUploadImage) {
      notification('Practice settings updated.')
    }

    setIsLoading(false)
    updateProviderData()
  }

  const handleAddressChange = async (values: Address) => {
    const updateItem = form.getFieldsValue() as FormValues
    if (updateItem.AutocompleteAddress) {
      updateItem.AutocompleteAddress.Address1 = values?.Address1 ?? ''
      updateItem.AutocompleteAddress.Address2 = values?.Address2 ?? ''
      updateItem.AutocompleteAddress.City = values?.City ?? ''
      updateItem.AutocompleteAddress.State = values?.State ?? ''
      updateItem.AutocompleteAddress.Zipcode = values?.Zipcode ?? ''
      form.setFieldsValue(updateItem)
    }
  }

  const columnSpan = {
    xs: 24,
    sm: 24,
    md: 20,
    lg: 15,
    xl: 8,
    xxl: 8,
  }

  const formValues = form.getFieldsValue()

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        <Card bordered={false}>
          <div className="headerWrapper headerWrapper_title">
            Practice details
          </div>
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            labelCol={columnSpan}
            wrapperCol={columnSpan}
            validateTrigger="onChange"
          >
            <Form.Item
              id="PracticeName"
              name="PracticeName"
              label="Practice Name"
              rules={[
                {
                  required: true,
                  message: 'Please input Practice Name',
                  type: 'string',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Practice Name" />
            </Form.Item>
            <Form.Item
              id="PracticeLogoImage"
              name="PracticeLogoImage"
              label="Practice Logo (5MB or smaller)"
            >
              <ImageUpload
                ref={imageUploadRef}
                isLoading={isSaveImageLoading}
                setLoading={setSaveImageLoading}
                croppedImageBase64={croppedImageBase64}
                setCroppedImageBase64={setCroppedImageBase64}
                setOriginalImageBase64={setOriginalImageBase64}
              />
            </Form.Item>
            <PhoneNumberInput form={form} name="PracticePhone" />
            <Form.Item label={''} name="AutocompleteAddress">
              <AddressAutocomplete
                value={formValues.AutocompleteAddress}
                onChange={handleAddressChange}
              />
            </Form.Item>
            <Form.Item
              name="BillingNPI"
              label="Billing NPI"
              id="BillingNPI"
              rules={[
                {
                  message: 'Enter a 10-digit code',
                  pattern: npiRegex,
                  type: 'string',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="0000000000" />
            </Form.Item>
            <EINInput form={form} name="EIN" />
            <Form.Item id="submit">
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  )
}
