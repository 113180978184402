import React, { useState } from 'react'

import _ from 'lodash'
import _isEqual from 'lodash/isEqual'

import {
  submitEditMedicalSurgicalHistoryRequest,
  submitNewMedicalSurgicalHistoryRequest,
} from '../../../api/api-lib'
import GenericAccordion from '../../../components/Accordions/GenericAccordion'
import PatientProfileButtons from '../../../components/Buttons/PatientProfileButtons'
import FormMachine from '../../../components/Forms/FormMachine/FormMachine'
import {
  TemplateField,
  TemplateFields,
} from '../../../components/Forms/FormMachine/types'
import { onError } from '../../../libs/errorLib'
import { useFormFields } from '../../../libs/hooksLib'
import { notification } from '../../../libs/notificationLib'
import { patientDataTypes } from '../patient-data-types'
import { styling, template } from './template'
import {
  ConditionDetails,
  MedicalIssue,
  MedicalSurgicalResponse,
} from './types'

import '../PatientProfile.scss'

interface MedicalSurgicalHistoryProps {
  handleApiChange: (queryName: string[]) => void
  response: MedicalSurgicalResponse
  patientId: string
}

export default function MedicalSurgicalHistory(
  props: MedicalSurgicalHistoryProps
) {
  const response = props.response
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const details = props.response.medicalConditionsDetails

  function parseFromBoolean(value: boolean | null | undefined, isYesNo = true) {
    if (isYesNo) {
      if (typeof value !== 'boolean') {
        return ''
      }
      return value ? 'Yes' : 'No'
    } else {
      return value ? ['No relevant history in this section'] : []
    }
  }

  const medicalSurgicalFields = {
    ...response,
    medicalConditions: (response.medicalConditions ?? []).map((medCondition) =>
      // This resolves an issue with any legacy 'High blood pressure' values that need to match with new 'High Blood Pressure' med condition
      medCondition.toLowerCase() ===
      MedicalIssue.HIGH_BLOOD_PRESSURE.toLowerCase()
        ? MedicalIssue.HIGH_BLOOD_PRESSURE
        : medCondition
    ),
    hasHadNoRelevantMedHistory: parseFromBoolean(
      response.hasHadNoRelevantMedHistory,
      false
    ),
    hasNoPregnancyHistory: parseFromBoolean(
      response.hasNoPregnancyHistory,
      false
    ),
    hasHadNoSurgeries: parseFromBoolean(response.hasHadNoSurgeries, false),
    hasHadNoHospitalizations: parseFromBoolean(
      response.hasHadNoHospitalizations,
      false
    ),
    isInCareFacility: parseFromBoolean(response.isInCareFacility),
    hasImplantedDevices: parseFromBoolean(response.hasImplantedDevices),
    autoimmuneComments: details?.autoimmune?.comments,
    autoimmuneYear: details?.autoimmune?.year,
    cancerComments: details?.cancer?.comments,
    cancerYear: details?.cancer?.year,
    chronicPainComments: details?.chronicPain?.comments,
    chronicPainYear: details?.chronicPain?.year,
    diabetesComments: details?.diabetes?.comments,
    diabetesYear: details?.diabetes?.year,
    epilepsySeizuresComments: details?.epilepsySeizures?.comments,
    epilepsySeizuresYear: details?.epilepsySeizures?.year,
    headTraumaComments: details?.headTrauma?.comments,
    headTraumaYear: details?.headTrauma?.year,
    heartProblemsComments: details?.heartProblems?.comments,
    heartProblemsYear: details?.heartProblems?.year,
    highBloodPressureComments: details?.highBloodPressure?.comments,
    highBloodPressureYear: details?.highBloodPressure?.year,
    headachesMigrainesComments: details?.headachesMigraines?.comments,
    headachesMigrainesYear: details?.headachesMigraines?.year,
    strokeComments: details?.stroke?.comments,
    strokeYear: details?.stroke?.year,
    thyroidComments: details?.thyroid?.comments,
    thyroidYear: details?.thyroid?.year,
    // This is temporary logic to support displaying new medical issues
    ...Object.values(MedicalIssue)
      .filter((issue) => issue !== MedicalIssue.OTHER)
      .reduce<{ [key: string]: unknown }>((outputMap, issue) => {
        outputMap[`${_.camelCase(issue)}Comments`] = details
          ? (details[_.camelCase(issue)] as ConditionDetails)?.comments ?? ''
          : ''
        outputMap[`${_.camelCase(issue)}Year`] = details
          ? (details[_.camelCase(issue)] as ConditionDetails)?.year ?? ''
          : ''
        return outputMap
      }, {}),
    otherConditions: details?.other,
  }
  const [storedFields, handleFieldChange] = useFormFields(medicalSurgicalFields)

  function switchToEdit() {
    setIsEditing(true)
  }

  function switchToSave() {
    setIsEditing(false)
  }

  function cancelEditing() {
    handleFieldChange(false, medicalSurgicalFields)
    switchToSave()
  }

  function saveAsBoolean(value: string | string[] | undefined) {
    if (value === '') {
      return null
    }
    if (typeof value === 'string') {
      return value === 'Yes'
    } else if (Array.isArray(value)) {
      return value.length > 0
    }
    return false
  }

  function findById(
    object: TemplateField | TemplateFields | boolean,
    id: string
  ): TemplateField | boolean {
    if (!Array.isArray(object) && typeof object === 'object') {
      const field = object.id === id ? object : false
      return field
    } else if (Array.isArray(object)) {
      const hasFoundId = object.find(
        (field: TemplateField) => field.id === id
      ) as unknown as TemplateField
      if (hasFoundId) {
        return hasFoundId
      } else {
        let foundObject = false as unknown as TemplateField | boolean
        object.forEach((field: TemplateField) => {
          if (field.conditionalComponent) {
            foundObject =
              foundObject || findById(field.conditionalComponent, id)
          }
        })
        if (typeof foundObject === 'object') return foundObject
      }
    }

    return false
  }

  function handleCheckChange(id: string, key: number, isChecked = false) {
    const field = findById(template, id)
    if (typeof field === 'object') {
      const type = field.fieldType
      const event = { target: {} }
      if (field.options) {
        const options = field.options
        if (type === 'radio' || type === 'yesNo') {
          event.target = {
            id: id,
            type: 'radio',
            value: options[key],
          }
        } else if (type === 'checkbox') {
          let selectedOptions = storedFields[id] || []
          if (!isChecked) {
            selectedOptions.push(options[key])
          } else {
            selectedOptions = selectedOptions.filter(
              (choice: string) => choice !== options[key]
            )
          }
          event.target = {
            id: id,
            type: 'checkbox',
            checked: selectedOptions,
          }
        }
      }
      handleFieldChange(event)
    }
  }

  async function handleSubmit(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    try {
      setIsLoading(true)
      const data = {
        patientId: props.patientId,
        medicalConditions: storedFields.medicalConditions || [],
        medicalConditionsDetails: {
          autoimmune: {
            comments: storedFields.autoimmuneComments || '',
            year: storedFields.autoimmuneYear || '',
          },
          cancer: {
            comments: storedFields.cancerComments || '',
            year: storedFields.cancerYear || '',
          },
          chronicPain: {
            comments: storedFields.chronicPainComments || '',
            year: storedFields.chronicPainYear || '',
          },
          diabetes: {
            comments: storedFields.diabetesComments || '',
            year: storedFields.diabetesYear || '',
          },
          epilepsySeizures: {
            comments: storedFields.epilepsySeizuresComments || '',
            year: storedFields.epilepsySeizuresYear || '',
          },
          heartProblems: {
            comments: storedFields.heartProblemsComments || '',
            year: storedFields.heartProblemsYear || '',
          },
          headachesMigraines: {
            comments: storedFields.headachesMigrainesComments || '',
            year: storedFields.headachesMigrainesYear || '',
          },
          headTrauma: {
            comments: storedFields.headTraumaComments || '',
            year: storedFields.headTraumaYear || '',
          },
          highBloodPressure: {
            comments: storedFields.highBloodPressureComments || '',
            year: storedFields.highBloodPressureYear || '',
          },
          stroke: {
            comments: storedFields.strokeComments || '',
            year: storedFields.strokeYear || '',
          },
          thyroid: {
            comments: storedFields.thyroidComments || '',
            year: storedFields.thyroidYear || '',
          },
          // This is temporary logic to support updating new medical issues
          ...Object.values(MedicalIssue)
            .filter((issue) => issue !== MedicalIssue.OTHER)
            .reduce<{ [key: string]: ConditionDetails }>((outputMap, issue) => {
              outputMap[_.camelCase(issue)] = {
                comments: storedFields[`${_.camelCase(issue)}Comments`] ?? '',
                year: storedFields[`${_.camelCase(issue)}Year`] ?? '',
              }
              return outputMap
            }, {}),
          other: storedFields.otherConditions || '',
        },
        hasHadNoRelevantMedHistory: saveAsBoolean(
          storedFields.hasHadNoRelevantMedHistory
        ),
        isPregnantOrNursing: storedFields.isPregnantOrNursing || '',
        previousPregnancies: storedFields.previousPregnancies || '',
        pregnancyNotes: storedFields.pregnancyNotes || '',
        hasNoPregnancyHistory: saveAsBoolean(
          storedFields.hasNoPregnancyHistory
        ),
        surgicalHistory: storedFields.surgicalHistory || '',
        hasHadNoSurgeries: saveAsBoolean(storedFields.hasHadNoSurgeries),
        hospitalizations: storedFields.hospitalizations || '',
        hasHadNoHospitalizations: saveAsBoolean(
          storedFields.hasHadNoHospitalizations
        ),
        isInCareFacility: saveAsBoolean(storedFields.isInCareFacility),
        isInCareFacilityExplanation:
          storedFields.isInCareFacilityExplanation || '',
        hasImplantedDevices: saveAsBoolean(storedFields.hasImplantedDevices),
        hasImplantedDevicesExplanation:
          storedFields.hasImplantedDevicesExplanation || '',
        additionalNotes: storedFields.additionalNotes || '',
      }
      if (response.createdAt) {
        await submitEditMedicalSurgicalHistoryRequest(data)
      } else {
        await submitNewMedicalSurgicalHistoryRequest(data)
      }
      props.handleApiChange([
        patientDataTypes.ProviderSidePatientData,
        props.patientId,
      ])
      notification(
        'You have successfully updated the Medical Surgical History subsection on the patient.',
        'success'
      )
      setIsLoading(false)
      switchToSave()
    } catch (error) {
      console.error(
        'Error submitting information on Medical Surgical History subsection of Patient Profile',
        error
      )
      onError(
        error,
        500,
        "Failed to update the patient's additonal information. Please inform your administrator."
      )
      setIsLoading(false)
    }
  }

  function handleMultipleSubmit(field: object | number, id: string) {
    let newValue = []
    if (typeof field !== 'object') {
      // DELETE field
      storedFields[id].splice(field, 1)
      newValue = storedFields[id]
    } else if (id !== 'otherConditions') {
      // ADD field - default
      newValue = [field]
      if (storedFields[id]) {
        newValue = [...storedFields[id], field]
      }
    } else {
      // ADD field - new 'other' medical condition
      // initialize the object with an empty description
      // so it matches backend validation
      const newField = { description: '' }
      newValue = [newField]
      if (storedFields[id]) {
        newValue = [...storedFields[id], newField]
      }
    }
    handleFieldChange({
      target: {
        id: id,
        value: newValue,
      },
    })
  }

  return (
    <GenericAccordion
      testId="patient-profile-medical-history"
      title="Medical and Surgical History"
    >
      <FormMachine
        storedFields={storedFields}
        template={template}
        handleCheckChange={handleCheckChange}
        handleFieldChange={handleFieldChange}
        handleMultipleSubmit={handleMultipleSubmit}
        isEditing={isEditing}
        styling={styling}
      />
      <PatientProfileButtons
        cancelEditing={cancelEditing}
        isDisabled={false}
        isEditing={isEditing}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        switchToEdit={switchToEdit}
      />
    </GenericAccordion>
  )
}
