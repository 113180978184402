import { Modal } from 'antd'

export const paymentDataLockedModal = () => {
  Modal.info({
    title: 'This action is locked',
    content: (
      <p>
        Another user is working on this patient's billing. This action is
        unavailable until they are done.
      </p>
    ),
    onOk() {
      return null
    },
  })
}
