import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { loadPatientMedicationHistory } from '../../api/patients'
import { ActiveMedicationHistory } from '../../shared-types'

export enum QueryKeys {
  ACTIVE_MED_HISTORY = 'ACTIVE_MED_HISTORY',
}

type QueryData = ActiveMedicationHistory[]

interface UsePatientMedicationHistoryQueryParams {
  patientId: string
  startDate?: Date | string | null
  endDate?: Date | string | null
}

/**
 * Gets a list of ActiveMedicationHistory records in a given time span.
 * Should return only the latest history record per ActiveMedicationId if multiple are available within the timeframe.
 *
 * @param patientId **string** - Patient's id associated with history records
 * @param startDate **Date | string | undefined** - Filters history records with creation dates that are greater-than-or-equal to this date.  If *undefined*, will pull up records since the beginning.
 * @param endDate **Date | string | undefined** - Filters history records with creation dates that are less-than-or-equal to this date. If *undefined*, will pull up recreds up to current date.
 * @param enabled
 */
export const usePatientMedicationHistory = ({
  patientId,
  startDate,
  endDate,
  enabled = true,
}: {
  patientId: string
  startDate?: Date | string | null
  endDate?: Date | string | null
  enabled?: boolean
}): UseQueryResult<ActiveMedicationHistory[]> => {
  return useQuery<
    QueryData,
    unknown,
    QueryData,
    [QueryKeys.ACTIVE_MED_HISTORY, UsePatientMedicationHistoryQueryParams]
  >([QueryKeys.ACTIVE_MED_HISTORY, { patientId, startDate, endDate }], {
    queryFn: async ({
      queryKey: [_key, { patientId, startDate, endDate }],
    }) => {
      if (!endDate) return Promise.resolve([]) // Prevents unnecessary API call
      return loadPatientMedicationHistory(patientId, startDate, endDate)
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled,
  })
}
