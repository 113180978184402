import { useMemo } from 'react'

import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'

import { formatDate, formattedPhoneNumber } from '../../libs/utils'
import { PascalPatient } from '../../shared-types'
import {
  Address,
  Card,
  Divider,
  InfoGrid,
  Switch,
  Text,
  Tooltip,
} from '../BaseComponents'
import { InvoiceComponentProps } from './constants'
import { getLocaleDateDisplay, getLocaleTimeDisplay } from './utils'

import styles from './Invoice.module.scss'

export type BillingInformationProps = {
  patient?: PascalPatient
  isSuperbillLocked: boolean
  isSuperbillChecked: boolean
  isManualInNetworkClaimsChecked: boolean
  isManualInNetworkClaimsEnabled: boolean
  onSuperbillToggle: (checked: boolean) => void
  onManualInNetworkClaimsToggle: (checked: boolean) => void
  superbillSharedAt?: string | null
  superbillSharedBy?: string | null
} & InvoiceComponentProps

export const BillingInformation = ({
  patient,
  isEditing,
  isLoading,
  isSuperbillEnabled,
  isSuperbillLocked,
  isSuperbillChecked,
  onSuperbillToggle,
  isManualInNetworkClaimsChecked,
  isManualInNetworkClaimsEnabled,
  onManualInNetworkClaimsToggle,
  superbillSharedAt,
  superbillSharedBy,
}: BillingInformationProps) => {
  const phoneNumber = formattedPhoneNumber(patient?.PhoneNumber)
  const dateOfBirth = useMemo(
    () => formatDate({ value: patient?.DateOfBirth }),
    [patient]
  )

  const superbillToggle = (
    <Switch
      className={styles.superbillToggle}
      checked={isSuperbillEnabled}
      disabled={
        isSuperbillLocked || !isEditing || isManualInNetworkClaimsChecked
      }
      onToggle={() => onSuperbillToggle(!isSuperbillEnabled)}
      label="Include Superbill"
    />
  )

  const superbillSharedInfo = superbillSharedBy ? (
    <p
      className={styles.superbillSharedInfoMessage}
      data-testid={'superbill-shared-info'}
    >
      <CheckCircleOutlined
        style={{ fontSize: 16, color: '#52c41a', marginRight: 8 }}
      />
      {superbillSharedAt
        ? `Last shared with patient on ${getLocaleDateDisplay(
            superbillSharedAt
          )} at ${getLocaleTimeDisplay(superbillSharedAt)}.`
        : `This superbill has been previously shared with the patient.`}
    </p>
  ) : null

  const manualInNetworkClaimsToggle = (
    <Switch
      className={styles.superbillToggle}
      checked={isManualInNetworkClaimsChecked}
      disabled={!isEditing || isSuperbillChecked || isSuperbillLocked}
      onToggle={() =>
        onManualInNetworkClaimsToggle(!isManualInNetworkClaimsChecked)
      }
      label="Submit In-Network insurance claim"
    />
  )

  const superbillLockedTooltip = isManualInNetworkClaimsChecked
    ? 'An In-Network claim has been generated for this invoice and cannot be turned off. This invoice cannot be changed to be a Superbill'
    : 'A Superbill has already been generated for this invoice and can not be turned off.'

  const inNetworkClaimLockedTooltip = isManualInNetworkClaimsChecked
    ? 'An In-Network claim has been generated for this invoice and cannot be turned off. This invoice cannot be changed to be a Superbill'
    : 'A superbill has already been generated for this invoice. You may not create both an out-of-network and in-network claim for the same invoice.'

  const superbillToggleWithTooltip = isSuperbillLocked ? (
    <Text style={{ display: 'flex', alignItems: 'center' }}>
      {superbillToggle}
      <Tooltip placement="top" title={superbillLockedTooltip}>
        <InfoCircleOutlined style={{ marginLeft: '5px' }} />
      </Tooltip>
    </Text>
  ) : (
    superbillToggle
  )

  const inNetworkToggleWithTooltip = isSuperbillLocked ? (
    <Text style={{ display: 'flex', alignItems: 'center' }}>
      {manualInNetworkClaimsToggle}
      <Tooltip placement="top" title={inNetworkClaimLockedTooltip}>
        <InfoCircleOutlined style={{ marginLeft: '5px' }} />
      </Tooltip>
    </Text>
  ) : (
    manualInNetworkClaimsToggle
  )

  return (
    <Card loading={isLoading} className={styles.billingInfo}>
      <div className={styles.sectionHeader}>Billing information</div>
      <InfoGrid
        rows={[
          {
            label: 'Patient name:',
            content: `${patient?.PatientFirstName} ${patient?.PatientLastName}`,
          },
          { label: 'Date of birth:', content: dateOfBirth },
          { label: 'Address:', content: <Address address={patient} /> },
          { label: 'Phone number:', content: phoneNumber },
        ]}
      />

      <Divider />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {superbillToggleWithTooltip}
        {superbillSharedInfo}
        {isManualInNetworkClaimsEnabled && inNetworkToggleWithTooltip}
      </div>
    </Card>
  )
}
