/**
 * Custom hook for SmartyStreets Autocomplete.
 * @returns {object} Contains state and methods to manage SmartyStreets autocomplete.
 */
import { useEffect, useState } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useDebounce } from '../useDebounce'

export interface Address {
  street_line: string
  secondary: string
  city: string
  state: string
  zipcode: string
  entries: number
}

export interface Suggestions {
  suggestions: Address[]
}

type useSmartyStreetsAutoCompleteProps = {
  query: string
  selected?: string
  key: string
  debounceTime?: number
  maxResults?: number
}

export function useSmartyStreetsAutoComplete({
  query,
  key,
  selected = '',
  debounceTime = 1250,
  maxResults = 10,
}: useSmartyStreetsAutoCompleteProps) {
  const [suggestions, setSuggestions] = useState<Address[]>([])
  const searchTerm = useDebounce(query, debounceTime)
  const { isLoading, error, data, refetch, remove } = useQuery<Suggestions>(
    ['autocomplete', searchTerm],
    () =>
      fetch(
        `https://us-autocomplete-pro.api.smartystreets.com/lookup?search=${encodeURIComponent(
          query
        ).replace(/%20/g, '+')}&selected=${encodeURIComponent(selected).replace(
          /%20/g,
          '+'
        )}&max_results=${maxResults}&key=${key}&source=all`,
        {
          headers: {
            Referer: 'osmind.org',
          },
        }
      ).then((res) => res.json()),
    {
      enabled: !!searchTerm,
      refetchOnWindowFocus: false,
      cacheTime: 86400000, //24h
      staleTime: Infinity, // never re-fetch unless user manually does it
    }
  )

  if (error) console.error(error)

  useEffect(() => {
    if (data) {
      setSuggestions(data.suggestions)
    } else {
      setSuggestions([])
    }
  }, [data])

  const queryClient = useQueryClient()

  const invalidate = () => {
    queryClient.invalidateQueries(['autocomplete', searchTerm])
  }

  return {
    isLoading,
    error,
    suggestions,
    refetch,
    remove,
    invalidate,
  }
}
