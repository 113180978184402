import { useQuery } from '@tanstack/react-query'

import { fetchNoteBlock } from '../../api'
import { NOTE_BLOCK_QUERY_KEY } from '../../constants'

export const getNoteBlockQueryKey = (
  noteUuid: string | undefined,
  blockUuid: string | undefined
) => {
  return [NOTE_BLOCK_QUERY_KEY, noteUuid, blockUuid]
}

export const useGetNoteBlock = (
  noteUuid: string | undefined,
  blockUuid: string | undefined
) => {
  const queryKey = getNoteBlockQueryKey(noteUuid, blockUuid)
  return useQuery(
    queryKey,
    () => {
      if (!noteUuid || !blockUuid) {
        return undefined
      }
      return fetchNoteBlock({ noteUuid, blockUuid })
    },
    {
      // there is no need to re-fetch every time the window loses focus,
      // it results in weird UX and puts unnecessary load on the server,
      // since the data here is static until the block config is updated
      refetchOnWindowFocus: false,
      enabled: !!(noteUuid && blockUuid),
    }
  )
}
