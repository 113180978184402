import React from 'react'

import 'moment-timezone'
import { Button, Col, Form, Table } from 'react-bootstrap'
import 'react-dates/initialize'

import CustomTimePicker from '../../../../stories/BaseComponents/CustomTimePicker'
import { AutoSizingTextArea } from '../../../../stories/BaseComponents/TextAreaInput'
import AddVitals from './Sections/AddVitals'
import AssessmentPreparations from './Sections/AssessmentPreparations'
import Discharge from './Sections/Discharge'
import FacilityStaff from './Sections/FacilityStaff'
import ShowSavedAM from './Tables/ShowSavedAM'
import ShowSavedVS from './Tables/ShowSavedVS'

import '../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function IVKetamine(props) {
  function handleShowAdditionalMedication() {
    if (props.fields.SavedAdditionalMedications.length !== 0) {
      return (
        <>
          {props.fields.SavedAdditionalMedications.map((addMed, index) => {
            return (
              <ShowSavedAM
                key={index}
                index={index}
                deleteAM={props.deleteAM}
                AdditionalMedicationName={addMed.AdditionalMedicationName}
                AdditionalMedicationOther={addMed.AdditionalMedicationOther}
                AdditionalMedicationAdministrationRoute={
                  addMed.AdditionalMedicationAdministrationRoute
                }
                AdditionalMedicationDosage={addMed.AdditionalMedicationDosage}
                AdditionalMedicationOtherAdministrationRoute={
                  addMed.AdditionalMedicationOtherAdministrationRoute
                }
                AdditionalMedicationUnits={addMed.AdditionalMedicationUnits}
                AdditionalMedicationUnitsOther={
                  addMed.AdditionalMedicationUnitsOther
                }
                AdditionalMedicationTime={addMed.AdditionalMedicationTime}
                saveEditAdditionalMedication={
                  props.saveEditAdditionalMedication
                }
                disabled={props.disabled}
              />
            )
          })}
        </>
      )
    }
  }

  function handleShowSavedVitals() {
    if (
      props.fields.SavedVitalSigns &&
      props.fields.SavedVitalSigns.length !== 0
    ) {
      return (
        <>
          {props.fields.SavedVitalSigns.map((vitals, index) => {
            return (
              <ShowSavedVS
                key={index}
                index={index}
                deleteVitals={props.deleteVitals}
                VitalSignsType={vitals.VitalSignsType}
                Time={vitals.Time}
                HR={vitals.HR}
                BP={vitals.BP}
                BpArm={vitals.BpArm}
                RR={vitals.RR}
                SPO2={vitals.SPO2}
                Comments={vitals.Comments}
                EKG={vitals.EKG}
                RASSscore={vitals.RASSscore}
                saveEditVitalSigns={props.saveEditVitalSigns}
                disabled={props.disabled}
              />
            )
          })}
        </>
      )
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <FacilityStaff
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        disabled={props.disabled}
      />
      <br />
      <AssessmentPreparations
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        pregnantStatus={props.pregnantStatus}
        detail={props.detail}
        disabled={props.disabled}
      />
      <br />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        IV
      </div>
      <br />
      <Form.Row>
        <Form.Group as={Col} sm={6} md={3} lg={3} controlId="startTime">
          <Form.Label style={{ fontWeight: 'bold' }}>
            IV Start (HH:mm)
          </Form.Label>
          <br />
          <CustomTimePicker
            value={props.fields.InfusionStartTime}
            onChange={(value) => {
              props.handleFieldChange({
                ...props.fields,
                InfusionStartTime: value,
              })
            }}
            disabled={props.disabled}
          />
        </Form.Group>
        <Form.Group as={Col} sm={6} md={3} lg={2} controlId="IVType">
          <Form.Label style={{ fontWeight: 'bold' }}>IV (Type)</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="select"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                IVType: e.target.value,
              })
            }
            value={props.fields.IVType}
          >
            <option key={0} name="Select One" value="">
              Select one
            </option>
            <option key={1} name="Infusion" value="Infusion">
              Infusion
            </option>
            <option key={2} name="Push" value="Push">
              Push
            </option>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} sm={6} md={3} lg={2} controlId="Catheter">
          <Form.Label style={{ fontWeight: 'bold' }}>Catheter</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="select"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                Catheter: e.target.value,
              })
            }
            value={props.fields.Catheter}
          >
            <option key={0} name="Select One" value="">
              Select one
            </option>
            {props.catheter.map((catheterType, index) => (
              <option key={index + 1} name={catheterType} value={catheterType}>
                {catheterType}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {props.fields.Catheter === 'Other (type in)' ? (
          <Form.Group as={Col} sm={6} md={2} lg={1} controlId="CatheterOther">
            <Form.Label style={{ fontWeight: 'bold' }}>Other</Form.Label>
            <Form.Control
              bsPrefix="form-input"
              value={props.fields.CatheterOther}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  CatheterOther: e.target.value,
                })
              }
            />
          </Form.Group>
        ) : (
          <></>
        )}
        <Form.Group as={Col} sm={3} md={2} lg={1} controlId="NumberOfAttempts">
          <Form.Label style={{ fontWeight: 'bold' }}>Attempts</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            type="number"
            min={1}
            disabled={props.disabled}
            onWheel={(event) => event.currentTarget.blur()}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                NumberOfAttempts: e.target.value,
              })
            }
            value={props.fields.NumberOfAttempts}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          xs={6}
          sm={6}
          md={4}
          lg={3}
          controlId="InfusionLocation"
        >
          <Form.Label style={{ fontWeight: 'bold' }}>Location</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            value={props.fields.InfusionLocation}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                InfusionLocation: e.target.value,
              })
            }
            disabled={props.disabled}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12} sm={12} md={12} lg={12}>
          <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            placeholder={
              props.detail
                ? ''
                : 'Aseptic technique, no complications. IV secured and free flowing, etc.'
            }
            value={props.fields.IVNotes}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                IVNotes: e.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={3}
          />
        </Form.Group>
      </Form.Row>
      <hr />
      <Form.Row>
        <Form.Group as={Col} sm={12} md={6} controlId="IVNotes">
          <Form.Label style={{ fontWeight: 'bold' }}>
            Ketamine Administration
          </Form.Label>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={6} md={3} lg={2} controlId="PatientWeight">
          <Form.Label style={{ fontWeight: 'bold' }}>Patient Weight</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            type="number"
            step=".01"
            value={props.fields.PatientWeight}
            disabled={props.disabled}
            onWheel={(event) => event.currentTarget.blur()}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientWeight: e.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} sm={6} md={2} lg={1} controlId="KetamineUnits">
          <Form.Label style={{ fontWeight: 'bold' }}>Units</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            as="select"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KetamineUnits: e.target.value,
              })
            }
            value={props.fields.KetamineUnits}
          >
            <option key={1} name="lbs" value="lbs">
              lbs
            </option>
            <option key={0} name="kg" value="kg">
              kg
            </option>
          </Form.Control>
        </Form.Group>
        {!props.fields.KetamineTotalDose ? (
          <Form.Group as={Col} sm={6} md={3} lg={2} controlId="KetamineDose">
            <Form.Label style={{ fontWeight: 'bold' }}>Dose (mg/kg)</Form.Label>
            <Form.Control
              bsPrefix="form-input"
              type="number"
              step=".01"
              disabled={props.disabled}
              value={props.fields.KetamineDose}
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KetamineDose: e.target.value,
                })
              }
            />
          </Form.Group>
        ) : (
          <Form.Group as={Col} sm={6} md={3} lg={2}>
            <Form.Label style={{ fontWeight: 'bold' }}>Dose (mg/kg)</Form.Label>
            <Form.Control
              bsPrefix="form-input"
              disabled
              value={
                props.fields.PatientWeight
                  ? props.fields.KetamineUnits === 'kg'
                    ? (
                        props.fields.KetamineTotalDose /
                        props.fields.PatientWeight
                      ).toFixed(2)
                    : (
                        props.fields.KetamineTotalDose /
                        (props.fields.PatientWeight * 0.453592)
                      ).toFixed(2)
                  : ''
              }
            />
          </Form.Group>
        )}

        {!props.fields.KetamineDose ? (
          <Form.Group
            as={Col}
            sm={6}
            md={3}
            lg={2}
            controlId="KetamineTotalDose"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>Total (mg)</Form.Label>
            <Form.Control
              bsPrefix="form-input"
              type="number"
              step=".01"
              disabled={props.disabled}
              value={props.fields.KetamineTotalDose}
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  KetamineTotalDose: e.target.value,
                })
              }
            />
          </Form.Group>
        ) : (
          <Form.Group as={Col} sm={6} md={3} lg={2}>
            <Form.Label style={{ fontWeight: 'bold' }}>Total (mg)</Form.Label>
            <Form.Control
              bsPrefix="form-input"
              disabled
              value={
                props.fields.KetamineUnits === 'kg'
                  ? (
                      props.fields.KetamineDose * props.fields.PatientWeight
                    ).toFixed(2)
                  : (
                      props.fields.KetamineDose *
                      (props.fields.PatientWeight * 0.453592)
                    ).toFixed(2)
              }
            />
          </Form.Group>
        )}
        <Form.Group as={Col} sm={12} md={12} lg={5} controlId="KetamineNotes">
          <Form.Label style={{ fontWeight: 'bold' }}>Notes</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            placeholder={
              props.detail
                ? ''
                : 'E.g. ketamine vial/lot number and expiration date, infusion rate, etc.'
            }
            value={props.fields.KetamineNotes}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                KetamineNotes: e.target.value,
              })
            }
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={2}
          />
        </Form.Group>
      </Form.Row>
      <Table style={{ marginTop: '20px', marginBottom: '30px' }} responsive>
        {props.fields.SavedAdditionalMedications.length !== 0 ||
        !props.detail ? (
          <thead>
            <tr>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '336px',
                }}
              >
                Additional Medication Name
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '112px',
                }}
              >
                Total Dose
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '150px',
                }}
              >
                Units
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '200px',
                }}
              >
                Route
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '112px',
                }}
              >
                Time
              </th>
              <th
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  minWidth: '140px',
                }}
              >
                &nbsp;
              </th>
            </tr>
          </thead>
        ) : (
          <></>
        )}
        <tbody>{handleShowAdditionalMedication()}</tbody>
      </Table>

      {props.disabled ? (
        ''
      ) : (
        <Button
          bsPrefix="center plain-button info button-label"
          onClick={() => {
            props.saveNewAdditionalMedication()
          }}
        >
          + Add Med
        </Button>
      )}

      <Form.Row style={{ paddingBottom: '30px' }}>
        <Form.Text
          className="center hideDuringPrint"
          style={{ fontWeight: 'bold', color: '#A9A9A9' }}
        >
          Small screens may need to scroll horizontally for a full view of
          medication and vitals.
        </Form.Text>
      </Form.Row>

      <Table style={{ marginTop: '20px', marginBottom: '30px' }} responsive>
        {props.fields.SavedVitalSigns.length !== 0 || !props.detail ? (
          <thead>
            <tr>
              <th
                style={{
                  padding: '0.2rem',
                  fontWeight: 'bold',
                  minWidth: '105px',
                }}
              >
                Type (Infusion)
              </th>
              <th
                style={{
                  padding: '0.2rem',
                  fontWeight: 'bold',
                  minWidth: '120px',
                }}
              >
                Time
              </th>
              <th
                style={{
                  padding: '0.2rem',
                  fontWeight: 'bold',
                  minWidth: '68px',
                }}
              >
                HR
              </th>
              <th
                style={{
                  padding: '0.2rem',
                  fontWeight: 'bold',
                  minWidth: '85px',
                }}
              >
                BP
              </th>
              <th
                style={{
                  padding: '0.2rem',
                  fontWeight: 'bold',
                  minWidth: '68px',
                }}
              >
                SpO2
              </th>
              <th
                style={{
                  padding: '0.2rem',
                  fontWeight: 'bold',
                  minWidth: '80px',
                }}
              >
                EKG
              </th>
              <th
                style={{
                  padding: '0.2rem',
                  fontWeight: 'bold',
                  minWidth: '55px',
                }}
              >
                RR
              </th>
              <th
                style={{
                  padding: '0.2rem',
                  fontWeight: 'bold',
                  minWidth: '55px',
                }}
              >
                RASS
              </th>
              <th
                style={{
                  padding: '0.2rem',
                  fontWeight: 'bold',
                  minWidth: '336px',
                }}
              >
                Comments
              </th>
              <th
                style={{
                  padding: '0.2rem',
                  fontWeight: 'bold',
                  minWidth: '112px',
                }}
              >
                &nbsp;
              </th>
            </tr>
          </thead>
        ) : (
          <></>
        )}
        <tbody>{handleShowSavedVitals()}</tbody>
      </Table>
      <AddVitals
        shortPublicId={props.patient.ShortPublicId}
        disabled={props.disabled}
        setAddVitals={props.setAddVitals}
        savedVitalSigns={props.fields.SavedVitalSigns}
        bPPlacement={props.fields.BpPlacement}
        handleSaveVitals={props.saveNewVitals}
        handleSaveCaretakerVitals={props.saveNewCaretakerVitals}
        handleFieldChange={(value) => {
          props.handleFieldChange({ ...props.fields, BpPlacement: value })
        }}
      />
      <Form.Row>
        <Col className="center">
          <Form.Text
            style={{
              fontWeight: 'bold',
              color: '#A9A9A9',
              paddingBottom: '20px',
            }}
            className="hideDuringPrint"
          >
            Small screens may need to scroll horizontally for a full view of
            medication and vitals.
          </Form.Text>
        </Col>
      </Form.Row>
      <hr />
      <Form.Row>
        <Form.Group as={Col} lg={12} controlId="PatientEndorsment">
          <Form.Label style={{ fontWeight: 'bold' }}>
            Did the patient endorse any of the following: dissociation, an
            out-of-body experience, distortion of any of the 5 senses, a
            floating experience?
          </Form.Label>
          <Form.Check
            bsPrefix="form-check"
            checked={JSON.parse(props.fields.PatientEndorsment)}
            style={{ marginLeft: '10px' }}
            value="true"
            id="PatientEndorsmentYes"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientEndorsment: e.target.value,
              })
            }
            type="radio"
            label="Yes"
            inline
          />
          <Form.Check
            bsPrefix="form-check"
            checked={!JSON.parse(props.fields.PatientEndorsment)}
            style={{ marginLeft: '10px' }}
            value="false"
            id="PatientEndorsmentNo"
            disabled={props.disabled}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                PatientEndorsment: e.target.value,
              })
            }
            type="radio"
            label="No"
            inline
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        {JSON.parse(props.fields.PatientEndorsment) ? (
          <Form.Group
            as={Col}
            xs={12}
            sm={12}
            lg={12}
            xl={12}
            controlId="EndorsmentNotes"
          >
            <Form.Label style={{ fontWeight: 'bold' }}>
              Additional Notes
            </Form.Label>
            <Form.Control
              bsPrefix="form-control"
              className="field-value-edit"
              value={props.fields.EndorsmentNotes}
              as={AutoSizingTextArea}
              minRows={1}
              disabled={props.disabled}
              onChange={(e) =>
                props.handleFieldChange({
                  ...props.fields,
                  EndorsmentNotes: e.target.value,
                })
              }
            />
          </Form.Group>
        ) : (
          <></>
        )}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12} sm={12} lg={2} xl={2} controlId="endTime">
          <Form.Label style={{ fontWeight: 'bold' }}>IV End (HH:mm)</Form.Label>
          <br />
          <CustomTimePicker
            value={props.fields.InfusionEndTime}
            onChange={(value) => {
              props.handleFieldChange({
                ...props.fields,
                InfusionEndTime: value,
              })
            }}
            disabled={props.disabled}
          />
        </Form.Group>
        <Form.Group as={Col} lg={12} controlId="TreatmentNotes">
          <Form.Label style={{ fontWeight: 'bold' }}>
            Treatment notes
          </Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.TreatmentNotes}
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={3}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                TreatmentNotes: e.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <br />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Assessment and Plan
      </div>
      <Form.Row style={{ marginTop: '20px' }}>
        <Form.Group as={Col} xs={12} sm={12} lg={12} controlId="TreatmentPlan">
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            value={props.fields.TreatmentPlan}
            disabled={props.disabled}
            as={AutoSizingTextArea}
            minRows={3}
            onChange={(e) =>
              props.handleFieldChange({
                ...props.fields,
                TreatmentPlan: e.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <br />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Discharge
      </div>
      <br />
      <Discharge
        fields={props.fields}
        handleFieldChange={props.handleFieldChange}
        detail={props.detail}
        template="IV Ketamine"
        saveNewDischargeVitals={props.saveNewDischargeVitals}
        addDischargeVitals={props.addDischargeVitals}
        setAddDischargeVitals={props.setAddDischargeVitals}
        saveEditDischargeVitalSigns={props.saveEditDischargeVitalSigns}
        deleteVitals={props.deleteDischarge}
        disabled={props.disabled}
      />
    </div>
  )
}
