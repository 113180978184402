import { useQuery } from '@tanstack/react-query'

import { getNoteTemplate } from '../../api'
import { NOTE_TEMPLATES_QUERY_KEY } from '../../constants'

export const getNoteTemplateQueryKey = (templateId: string) => {
  return [NOTE_TEMPLATES_QUERY_KEY, templateId]
}

export const useGetNoteTemplate = (templateId: string) => {
  const queryKey = getNoteTemplateQueryKey(templateId)
  return useQuery(queryKey, () => {
    return getNoteTemplate(templateId)
  })
}
