import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { fetchDiagnoses } from '../../api/patients'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'
import { Diagnosis, DiagnosisHistory } from '../../shared-types'

export const normalizePatientDxs = (
  dxs: Diagnosis[] | DiagnosisHistory[],
  includeStatus = false
) =>
  dxs
    .filter((d) => d.DiagnosisCode)
    .map((diagnosis, i) => {
      const normalizedDiagnosis: any = {
        diagnosisId: diagnosis.DiagnosisId,
        code: diagnosis.DiagnosisCode,
        description: diagnosis.Diagnosis,
        order: i,
        isDeleted: false,
      }

      if (includeStatus) {
        normalizedDiagnosis.status = diagnosis.DiagnosisStatus || 'Active'
      }

      return normalizedDiagnosis
    })

export const usePatientDiagnoses = (
  patientId: string
): UseQueryResult<Diagnosis[]> => {
  return useQuery<Diagnosis[]>([patientDataTypes.PatientDiagnoses, patientId], {
    queryFn: async () => {
      const data = await fetchDiagnoses(patientId)
      return data
    },
    retry: false,
    refetchOnWindowFocus: false,
  })
}
