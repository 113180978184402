import { format, isAfter, isBefore, isValid, parse } from 'date-fns'
import get from 'lodash/get'
import { Rule, RuleType } from 'rc-field-form/lib/interface'
import { useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { ChangePayersList } from '../api/intakeForms'
import { PaymentAttempt, RefundAttempt } from '../shared-types'

export function capitalize(str: string): string {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function isEmptyObject(obj: any): boolean {
  return !obj || Object.values(obj).every((value) => value === undefined)
}

export function getProviderInitials(providerName: string | undefined) {
  let intials: string | null = null
  const providerNames = providerName?.split(' ')

  if (providerNames?.length && providerName !== 'Secondary Account (N/A)') {
    const letters = [providerNames[0][0]] // we know that names will at least be ['<letter>']
    if (providerNames.length > 1) {
      letters.push(providerNames[providerNames.length - 1][0])
    }
    intials = letters.join('')
  }
  return intials
}

//Function to check if array contains one of the values of other array
export const arrayHasValues = (arr: any[], valuesToCheck: any[]) => {
  return arr.filter((item) => valuesToCheck.includes(item)).length > 0
}

export function noop() {
  // does no action, returns undefined. satisfies readability concerns of @typescript-eslint/no-empty-function
}

export const sleep = (milliseconds: number) => {
  const date = Date.now()
  let currentDate = null
  do {
    currentDate = Date.now()
  } while (currentDate - date < milliseconds)
}

export const asyncSleep = async (milliseconds: number) => {
  await new Promise((resolve) => setTimeout(resolve, milliseconds))
}

/**
 *
 * @param input Input text
 * @returns A string in 12-hour format (e.g. 1:00pm) or undefined if the input is invalid
 */
export const convertFreeTextTo12HourFormat = (
  input: string,
  minHourToUseAm = 9
): string | undefined => {
  //Use pm on string if the input ends with p or pm
  let userInputsAMPM = false
  let ampm = 'am'
  if (input.toLowerCase().endsWith('a') || input.toLowerCase().endsWith('am')) {
    userInputsAMPM = true
  }
  if (input.toLowerCase().endsWith('p') || input.toLowerCase().endsWith('pm')) {
    userInputsAMPM = true
    ampm = 'pm'
  }

  // Remove any non-digit characters
  input = input.replace(/\D/g, '')

  //If input is less than 4 characters, add leading zeros and/or trailing zeros
  if (input.length < 4) {
    //Only add leading zero
    if (!(input.length === 2 && parseInt(input.charAt(0)) < 3)) {
      input = input.padStart(input.length + 1, '0')
    }

    //Add trailing zeros
    input = input.padEnd(4, '0')
  }

  if (input.length >= 4) {
    let hours = parseInt(input.substring(0, 2))
    let minutes = parseInt(input.substring(2, 4))
    if (hours >= 24) {
      return undefined
    }

    // Handle special cases
    if (hours === 0) {
      hours = 12 // 12:xxam is midnight
    } else if (hours > 12) {
      ampm = 'pm'
      hours -= 12 // Convert to 12-hour format
    } else if (!userInputsAMPM && hours < minHourToUseAm) {
      // If user did not input am/pm, assume it's pm if it's before minTime
      ampm = 'pm'
    }

    if (minutes > 59) {
      minutes = parseInt(input.substring(2, 3))
    }

    return `${hours}:${minutes.toString().padStart(2, '0')}${ampm}`
  }

  return undefined
}

export function convertTime12to24(time12h: string) {
  const time = time12h.slice(0, time12h.length - 2)
  const modifier = time12h.slice(time12h.length - 2, time12h.length)

  let hours = time.split(':')[0]
  const minutes = time.split(':')[1]

  if (hours === '12') {
    hours = '00'
  }

  if (modifier?.toLowerCase() === 'pm') {
    hours = (parseInt(hours, 10) + 12).toString()
  }

  return `${hours}:${minutes}`
}

/*
  toCamelCase("EquipmentClass name");
  toCamelCase("Equipment className");
  toCamelCase("equipment class name");
  toCamelCase("Equipment Class Name");
  all output "equipmentClassName"
*/
export const toCamelCase = (str: string): string => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export const mapObjectToCamelCase = (object: {}) => {
  const mappedObject: { [index: string]: unknown } = {}
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      mappedObject[toCamelCase(key)] = mapObjectToCamelCase(value)
    } else {
      mappedObject[toCamelCase(key)] = value
    }
  })
  return mappedObject
}

export const prettifyCamelCase = (
  originalWord: string,
  capitalize?: 'FIRST_WORD' | 'ALL_WORDS'
): string => {
  // camelCase => Camel Case (capitalize === 'ALL_WORDS')
  // camelCase => Camel case (capitalize === 'FIRST_WORD')
  // camelCase => camel case (default)
  const wordArray = originalWord.match(/[A-Za-z][a-z]*/g) || []

  let outputWordArray: string[]

  switch (capitalize) {
    case 'ALL_WORDS':
      outputWordArray = wordArray.map(
        (singleWord) =>
          singleWord.charAt(0).toUpperCase() + singleWord.substring(1)
      )
      break
    case 'FIRST_WORD':
      outputWordArray = wordArray.map((singleWord, i) => {
        if (i === 0) {
          return singleWord.charAt(0).toUpperCase() + singleWord.substring(1)
        } else {
          return singleWord.toLowerCase()
        }
      })
      break
    default:
      outputWordArray = wordArray.map((singleWord) => singleWord.toLowerCase())
      break
  }

  return outputWordArray.join(' ')
}

export const generateComponentKey = (componentName: string) => {
  return `${componentName}-${uuidv4()}`
}

// TODO: CLIN-246, CLIN-245
// PatientId is a cognito ID, which should be kept totally private/internal.
// We should switch our endpoints to use publicId instead, AND we shouldn't use local storage to nav between patients
// export const persistLastViewedPatient = (patientId: string) => {
//   localStorage.setItem('lastViewedPatientId', JSON.stringify(patientId))
// }

export function useQuery() {
  const { search } = useLocation()
  return new URLSearchParams(search)
}

export function roundTwoDecimals(num: string | number) {
  if (!num) return 0
  const float = typeof num === 'string' ? parseFloat(num) : num
  return Math.round(float * 1e2) / 1e2
}

export const sortAlphabetically = (a: string, b: string): number => {
  const aUppercase = a.toUpperCase()
  const bUppercase = b.toUpperCase()
  if (aUppercase > bUppercase) return 1
  else if (aUppercase === bUppercase) return 0
  return -1
}

type FormatToUsdOptions = {
  excludeDollarSymbol?: boolean
  defaultZero?: boolean
}

// formats a number with commas to use just for display to the user (isn't currently used to override the field value)
// eg: 12345.12345 -> '12,345.12345'
export function formatNumberStringToUsdString(
  value: string | number | undefined,
  options?: FormatToUsdOptions
): string {
  if (!value)
    return options?.defaultZero
      ? `${!options?.excludeDollarSymbol && '$'}0.00`
      : ''
  let newString = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(value))

  newString = options?.excludeDollarSymbol
    ? newString.replace('$', '')
    : newString

  return newString
}

export function isPatientActive(
  isActiveValue: boolean | undefined | null
): boolean {
  return isActiveValue !== false
}

export function isSameHourAndMinutes(
  startTime: Date,
  endTime: Date
): boolean | null {
  if (!isValid(startTime) || !isValid(endTime)) return null
  return (
    endTime.getUTCHours() === startTime.getUTCHours() &&
    endTime.getUTCMinutes() === startTime.getUTCMinutes()
  )
}

export const rulesConstructor = ({
  message = '',
  required = true,
  type = 'string' as RuleType,
  whitespace = true,
}): Rule => ({ required, message, type, whitespace })

export function compare(
  list: any[],
  type: 'newest' | 'oldest',
  timekey: string
) {
  const oldest = new Date('1828-12-28')
  const newest = new Date()
  if (type === 'newest') {
    list.sort((a, b) => {
      const dateA = a[timekey] ? new Date(a[timekey]) : oldest
      const dateB = b[timekey] ? new Date(b[timekey]) : oldest
      return isBefore(dateA, dateB) ? 1 : -1
    })
    return list
  }
  list.sort((a, b) => {
    const dateA = a[timekey] ? new Date(a[timekey]) : newest
    const dateB = b[timekey] ? new Date(b[timekey]) : newest
    return isAfter(dateA, dateB) ? 1 : -1
  })
  return list
}

export const getTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

// basically parseInt, but returns null instead of NaN and can in anything
export const parseIntOrNull = (value: any = '') => {
  const isNumber = typeof value === 'number'
  if (isNumber && !isNaN(value)) return value
  if (typeof value !== 'string') return null

  const parsed = parseInt(value)
  if (isNaN(parsed)) return null
  return parsed
}

export const changeToUTC = (dateOrNull: Date | null): Date | null => {
  if (!dateOrNull) return null
  return new Date(dateOrNull.toUTCString().slice(0, -4))
}

// WARNING: won't localize the date automatically! needs to be explicitly set
// - defaults to UTC time
export const parseDateOrNull = (
  value: any = '',
  shouldLocalize = false,
  givenFormat: string | undefined = undefined
): Date | null => {
  if (!value) return null

  if (typeof value === 'string') {
    const newDate = !givenFormat
      ? new Date(value)
      : parse(value, givenFormat, new Date())
    const checkedDate = isValid(newDate) ? newDate : null
    return shouldLocalize ? checkedDate : changeToUTC(checkedDate)
  }

  if (isValid(value)) return shouldLocalize ? value : changeToUTC(value)

  if (typeof value !== 'object') return null

  if (!('format' in value)) return null
  if (value.startDate && value.endDate) {
    return value // can't handle ranges right now, so just returning as is
  } else if (value.date) {
    return parseDateOrNull(value.date)
  }

  return null
}

// WARNING: won't localize the date automatically! needs to be explicitly set
// - defaults to UTC time
export const formatDate = ({
  format: dateFormat = 'MM/dd/yyyy',
  textWhenInvalid = '', // shows up instead when value given cannot be parsed
  shouldLocalize = false, // set to true here if you want to localize
  value = '' as any,
}): string => {
  const dateOrNull = parseDateOrNull(value, shouldLocalize)
  if (!dateOrNull) return textWhenInvalid

  return format(dateOrNull, dateFormat)
}

export const formattedPhoneNumber = (rawPhoneNumber?: string) => {
  const phoneNumber = rawPhoneNumber?.match(/\d/g)?.join('')
  return phoneNumber
    ? '(' +
        phoneNumber.substring(0, 3) +
        ') ' +
        phoneNumber.substring(3, 6) +
        '-' +
        phoneNumber.substring(6)
    : ''
}

export const formattedPhoneNumberWithoutCountryCode = (phoneNumber: string) => {
  const digits = phoneNumber.match(/\d/g)
  if (!digits) {
    return ''
  }
  const lastTenDigits = digits.slice(-10).join('')

  const group1 = lastTenDigits.substring(0, 3)
  const group2 = lastTenDigits.substring(3, 6)
  const group3 = lastTenDigits.substring(6, 10)

  const formattedNumber = `(${group1}) ${group2}-${group3}`

  return formattedNumber
}
export const formatLastFirstName = (firstName?: string, lastName?: string) =>
  [lastName, firstName].filter((val) => val).join(', ')

// super naïve, can only compare at top level attributes
// could be typed better
export const sortBy = (
  a: Record<string, any>,
  b: Record<string, any>,
  field: string
) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0)

export const getCreatedAtContent = (timestamp: string) => {
  const createdAt = new Date(timestamp)
  return format(new Date(createdAt), 'MM/dd/Y @ hh:mm aa')
}

export const handlePaymentsSort = (
  a: PaymentAttempt | RefundAttempt,
  b: PaymentAttempt | RefundAttempt
) => (a.createdAt > b.createdAt ? -1 : 1)

export const getMissingFields = <T extends object>(
  assessedObject: T,
  fields: string[],
  { requirePopulatedArrays = false }
): string[] => {
  if (!assessedObject) return fields
  if (!fields.length) return []

  const obj = assessedObject as { [K in keyof typeof fields]: unknown }

  const missingList = fields.filter((field) => {
    const value = get(obj, field)
    if (!value) return true
    if (Array.isArray(value) && requirePopulatedArrays && !value.length) {
      return true
    }
    return false
  })

  return missingList
}

export const uppercaseFirstLetter = (str: string) => {
  const changedCase = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  return changedCase.replace(/_/g, ' ')
}

export const isValidUrl = (urlString: any) => {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

export const checkIfIsMobile = (): boolean => {
  const isSmall = Math.min(window.screen.width, window.screen.height) < 768
  const mobileList = [
    'webos',
    'ios',
    'android',
    'iphone',
    'ipad',
    'kindle',
    'opera mini',
    'mobile',
    'tablet',
  ]
  // list/approach mainly from:
  // https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
  // https://www.tutorialspoint.com/javascript-how-to-detect-if-a-website-is-open-on-a-mobile-or-a-desktop
  const includesMobile = mobileList.includes(navigator.userAgent.toLowerCase())
  return isSmall || includesMobile
}

/**
 * Returns true if the text contains a Credit Card Number inside
 *
 * @remarks
 * WARNING Do not use this function to validate a CC Number, this is only to check if a CC Number exists inside a Text
 *
 * @param text - Text that we want to check
 * @returns True if the Text contains a CC Number, otherwise false
 */
export const containsACreditCardNumber = (text?: string) => {
  if (!text) {
    return false
  }
  const cardTypesRgex = {
    Visa: /4[0-9]{12}(?:[0-9]{3})?/,
    VisaMasterCard: /(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})/,
    Mastercard:
      /(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))/,
    Amex: /3[47][0-9]{13}/,
    Discover:
      /65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})/,
    DinnersClub: /3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    JBC: /(?:2131|1800|35\d{3})\d{11}/,
    UnionPay: /(62[0-9]{14,17})/,
  }
  const creditCardRegex = new RegExp(
    `(${Object.values(cardTypesRgex)
      .map((regex) => regex.source)
      .join(')|(')})`,
    'gmi'
  )
  return creditCardRegex.test(text.replace(/\D/gim, ''))
}

export const handleStripeError = (
  err: any,
  defaultMessage = 'Unknown error ocurred. Please contact Osmind engineering.'
): string => {
  console.error(err)
  if (typeof err === 'string') {
    return err
  }
  if (err.response) {
    const { message, error } = err.response.data
    return message || error || defaultMessage
  }
  return err.message
}

export const convertBlobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })

export const getProviderNames = (
  providers: {
    ProviderFirstName: string | null
    ProviderLastName: string | null
    ProviderCredential: string | null
    ProviderSuffix: string | null
  }[]
) =>
  providers
    .map((provider) => {
      const { ProviderFirstName, ProviderLastName, ProviderSuffix } = provider
      return `${ProviderFirstName} ${ProviderLastName}${
        ProviderSuffix ? `, ${ProviderSuffix}` : ''
      }`
    })
    .join(', ')
    .trimEnd()

export const queryParamsToQueryStringParams = (
  params?: Record<string, any> | null
): Record<string, string> => {
  const serializedParams: Record<string, string> = {}

  if (!params) {
    return serializedParams
  }

  for (const key in params) {
    if (params[key] !== undefined && params[key] !== null) {
      serializedParams[key] = String(params[key])
    }
  }

  return serializedParams
}

// temporary solution to show correct id to user, before we make DB changes
export const getInsurancePayerOptions = (payers?: ChangePayersList[]) =>
  payers?.map(({ id, organizationName, tradingPartnerServiceId }) => {
    // Demo payer does not have external id
    if (id) {
      return {
        value: organizationName,
        label: organizationName.replace(tradingPartnerServiceId, id),
      }
    } else {
      return {
        value: organizationName,
        label: organizationName.replace(` (${tradingPartnerServiceId})`, ''),
      }
    }
  }) ?? []
