import z from 'zod'

export const PhoneNumber = z
  .string()
  .regex(/^\d{10}$/, { message: 'Please enter a 10-digit number.' })
export const Ein = z
  .string()
  .regex(/^\d{9}$/, { message: 'Please enter a 9-digit number.' })
export const Npi = z
  .string()
  .regex(/^\d{10}$/, { message: 'Please enter a 10-digit number.' })
export const Ssn = z
  .string()
  .regex(/^\d{9}$/, { message: 'Please enter a 9-digit number.' })
