import { API } from 'aws-amplify'

// TODO: type this file and figure out responses

export async function createNewTemplateUrl(data: any) {
  const requestData = Object.assign({}, data, { updateEnum: 'create' })
  const response = await API.post('api', '/legacy-api/v1/hellosign', {
    body: requestData,
  })
  return response
}

export async function getTemplateEditUrl(data: any) {
  const requestData = Object.assign({}, data, { updateEnum: 'edit' })
  const response = await API.post('api', '/legacy-api/v1/hellosign', {
    body: requestData,
  })
  return response
}

export async function deleteTemplate(data: any) {
  const requestData = Object.assign({}, data, { updateEnum: 'delete' })
  return API.post('api', '/legacy-api/v1/hellosign', {
    body: requestData,
  })
}

export function changeTemplateName(data: any) {
  const requestData = Object.assign({}, data, { updateEnum: 'update' })
  return API.post('api', '/legacy-api/v1/hellosign', { body: requestData })
}

// Provider can choose to call this when inviting a new patient into his platform.
export async function getClaimURL(data: any) {
  const requestData = Object.assign({}, data, { updateEnum: 'claim' })
  const response = await API.post('api', '/legacy-api/v1/hellosign', {
    body: requestData,
  })
  return response
}
