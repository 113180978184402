import { useEffect } from 'react'

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  WarningTwoTone,
} from '@ant-design/icons'

import { saveSuccessfulOrder } from '../../api/labs'
import useQueryString from '../../hooks/useQueryString'
import { notification } from '../../libs/notificationLib'
import { Button } from '../../stories/BaseComponents'

import styles from './LabOrderFinalStatus.module.scss'

export default function LabOrderFinalStatus() {
  const query = useQueryString()
  const orderId = query.get('orderId')
  const responseCode = query.get('responseCode')
  const responseMessage = query.get('responseMessage')
  const state = query.get('state')

  const saveOrderBody = {
    responseCode: responseCode,
    responseMessage: responseMessage,
    orderId: orderId,
  }

  const LabOrderStatus = ({
    submitText,
    statusText,
    icon,
  }: {
    submitText: string
    statusText: string
    icon: JSX.Element
  }) => {
    return (
      <div id={styles.labFinalContainer}>
        <div id={styles.statusFinalContent}>
          <div>{icon}</div>
          <div id={styles.orderSubmitText}>{submitText}</div>
          <div id={styles.finalText}>{statusText}</div>
          <Button
            type="primary"
            id="startNewLabOrder"
            // Create new order
            // Refresh the parent page
            onClick={() => window.parent.location.reload()}
          >
            Start new lab order
          </Button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    const saveOrder = async () => {
      if (orderId && state) {
        window?.top?.postMessage('loading', '*')
        await saveSuccessfulOrder(state, saveOrderBody)
        window?.top?.postMessage('new lab results', '*')
      } else {
        throw new Error('error saving order no orderId or state')
      }
    }
    if (responseCode === 'success') {
      try {
        saveOrder()
      } catch {
        notification(
          'An error has occurred saving your order. Please contact Osmind for additional troubleshooting',
          'error'
        )
      }
    }
  }, [])

  if (responseCode === 'success') {
    return (
      <LabOrderStatus
        submitText={'Lab order submitted'}
        statusText={
          'Your lab has successfully been ordered. You may check back here for updates.'
        }
        icon={<CheckCircleTwoTone twoToneColor="#389E0D" />}
      />
    )
  } else if (responseCode === 'canceled') {
    return (
      <LabOrderStatus
        submitText={'Lab order cancelled'}
        statusText={'Your lab has successfully been cancelled.'}
        icon={<CloseCircleTwoTone twoToneColor="#F5222D" />}
      />
    )
  } else {
    return (
      <LabOrderStatus
        submitText={'Something went wrong'}
        statusText={'We had a problem while trying to order your lab.'}
        icon={<WarningTwoTone twoToneColor="#F5222D" />}
      />
    )
  }
}
