import React from 'react'

import { Statistic } from 'antd'

import { TestId } from '../../shared-types'

import './_shared.scss'

export interface MetricObject {
  readonly title: string
  readonly dataSource: number
}

export type MetricProps = {
  metrics: MetricObject[]
  loading: boolean
} & TestId

const Metrics: React.FC<MetricProps> = ({ metrics = [], loading, testId }) => {
  const MetricList = metrics.map((metric, i) => {
    const classTitle =
      metrics.length === i + 1 ? 'metric-item' : 'metric-item-margin'
    return (
      <Statistic
        key={i}
        className={classTitle}
        loading={loading}
        title={metric.title}
        value={metric.dataSource}
      />
    )
  })

  return (
    <div className="metrics-column" data-testid={testId}>
      {MetricList}
    </div>
  )
}

export default Metrics
