import { useMemo, useState } from 'react'

import { DownOutlined, UpOutlined } from '@ant-design/icons'

import { Button, Link, Skeleton } from '../../../../BaseComponents'
import { AppointmentInfoProps } from '../AppointmentInfo'

import styles from './AppointmentInfoContent.module.scss'

export const getClinicianText = (clinicians?: string[]) => {
  if (!clinicians || !clinicians.length) {
    return ''
  }

  if (clinicians.length === 2) {
    return clinicians.join(' & ')
  }

  return clinicians.join(', ').trim()
}

const MAX_UNEXPANDED_LENGTH = 2

export const AppointmentInfoContent = ({
  title,
  appointmentInfo,
  isLoading,
}: AppointmentInfoProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const infoToDisplay = useMemo(() => {
    if (!appointmentInfo) {
      return []
    }
    if (!isExpanded) {
      return appointmentInfo.slice(0, MAX_UNEXPANDED_LENGTH)
    }
    return appointmentInfo
  }, [isExpanded, appointmentInfo])

  const todayButtonText = useMemo(
    () =>
      isExpanded
        ? 'Show less'
        : `+ ${(appointmentInfo?.length || 0) - MAX_UNEXPANDED_LENGTH} More`,
    [isExpanded, appointmentInfo]
  )

  if (isLoading) {
    return (
      <Skeleton
        title={{
          className: styles.skeletonTitle,
        }}
        paragraph={{
          rows: 0,
          className: styles.skeletonParagraph,
        }}
      />
    )
  }
  if (!appointmentInfo && title.toLowerCase() === 'next') {
    return (
      <Link to="/scheduling" className={styles.emptyContent}>
        Schedule Next Appointment
      </Link>
    )
  }
  if (!appointmentInfo || !appointmentInfo.length) {
    return <p className={styles.emptyContent}>--</p>
  }
  return (
    <div className={styles.container}>
      {infoToDisplay.map(
        ({ date, endTime, startTime, clinicians, room }, idx) => {
          const clinicianText = getClinicianText(clinicians)
          return (
            <div
              className={styles.appointmentInfoContainer}
              key={`${title}_appointmentInfoContent_${idx}`}
            >
              <p className={styles.appointmentTime}>
                <span className={styles.appointmentDate}>{date}</span>
                <span> &#x2022; </span>
                <span>
                  {startTime}-{endTime}
                </span>
              </p>
              <p className={styles.appointmentText}>
                <span>{clinicianText}</span>
                {clinicianText.length && room ? (
                  <span className={styles.roomBullet}> &#x2022; </span>
                ) : null}
                {room ? <span className={styles.roomText}>{room}</span> : null}
              </p>
            </div>
          )
        }
      )}
      {title === 'Today' && appointmentInfo.length > MAX_UNEXPANDED_LENGTH && (
        <Button
          className={styles.expandButton}
          type="link"
          onClick={() => setIsExpanded((currExpanded) => !currExpanded)}
        >
          {todayButtonText}{' '}
          {isExpanded ? (
            <UpOutlined className={styles.icon} />
          ) : (
            <DownOutlined className={styles.icon} />
          )}
        </Button>
      )}
    </div>
  )
}
