import React from 'react'

import moment from 'moment'
import { Accordion, Card, Col, Container, Row } from 'react-bootstrap'

import './../../PatientProfile.scss'

export default function TreatmentsToDisplay(props) {
  return (
    <Container>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={props.index.toString()}
        id="treatmentsHeader"
        bsPrefix="patient-card"
      >
        <Container>
          <Row>
            {props.ReasonForHospitalization && (
              <Col bsPrefix="psych">{props.ReasonForHospitalization}</Col>
            )}
            {props.StartDate && (
              <Col bsPrefix="psych-date">
                {moment(new Date(props.StartDate)).format(props.DateFormat) +
                  (props.EndDate &&
                    ' to ' +
                      moment(new Date(props.EndDate)).format(props.DateFormat))}
              </Col>
            )}
            {props.HospitalName && (
              <Col bsPrefix="psych">{props.HospitalName}</Col>
            )}
            {props.LenghtOfHospitalization && (
              <Col bsPrefix="psych">
                {'length: ' + props.LenghtOfHospitalization + ' days'}
              </Col>
            )}
          </Row>
        </Container>
      </Accordion.Toggle>

      <Accordion.Collapse
        style={{ marginTop: '6px', marginBottom: '8px' }}
        eventKey={props.index.toString()}
      >
        <Container>
          <Col>
            {props.LenghtOfHospitalization && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">
                  Hospitalization length (days):{' '}
                </span>
                {props.LenghtOfHospitalization}
              </Row>
            )}
            {props.AdditionalNotes && (
              <Row bsPrefix="psych-sub">
                <span className="subtopic">Additional Notes: </span>
                {props.AdditionalNotes}
              </Row>
            )}
          </Col>
        </Container>
      </Accordion.Collapse>
    </Container>
  )
}
