import { useCallback, useEffect, useState } from 'react'

import _ from 'lodash'
import { Button, Modal, Spinner } from 'react-bootstrap'

import {
  markForcedEndEditComplete,
  markNoteAsBeingEdited,
} from '../../../api/api-lib'
import { notification } from '../../../libs/notificationLib'
import { ClinicalNote } from '../../../shared-types'

interface NoteIsBeingEditedAlertProps {
  note: ClinicalNote | null
  isBeingEditedBy: string
  anotherProviderIsEditing: boolean
  setAnotherProviderIsEditing: (flag: boolean) => void
  providerEmail: string
  setInViewMode: (inViewMode: boolean) => void
  forcedEndEditComplete: boolean
  handleApiChange: () => void
  refetchNote: (
    patientId: string,
    noteId: string
  ) => Promise<ClinicalNote | undefined>
}

export default function NoteIsBeingEditedAlert(
  props: NoteIsBeingEditedAlertProps
) {
  const [isForcing, setIsForcing] = useState(false)
  const [prevBeingEditedBy, setPrevIsBeingEditedBy] = useState('')

  useEffect(() => {
    // Resolves potential race condition between the modal closing, markNoteAsBeingEdited and the refetchNote that occurs between two frontends using timeouts
    if (!prevBeingEditedBy && props.isBeingEditedBy !== props.providerEmail) {
      setPrevIsBeingEditedBy(props.isBeingEditedBy)
    }
  }, [props.providerEmail, props.isBeingEditedBy])

  const closeModal = () => {
    props.setAnotherProviderIsEditing?.(false)
  }

  const finishForcedEndEdit = useCallback(async () => {
    try {
      if (!isForcing || !props.note) {
        return
      }

      const { PatientId, NoteId } = props.note

      await markForcedEndEditComplete({
        PatientId,
        NoteId,
        SwitchOn: false,
        UpdateEnum: 'forcedEndEditComplete',
      })
      setTimeout(async () => {
        // Need to give some time for other provider's changes to save after they are forced out so that we can see those changes when we refetch the note for this provider
        setIsForcing(false)
        await props.refetchNote(PatientId, NoteId)
        closeModal()
        props.setInViewMode(false)
        notification(
          `You have successfully ended ${props.isBeingEditedBy}'s editing session and started your own. Any changes they made were auto saved and will be reflected here.`,
          'success'
        )
      }, 5000)
    } catch (err) {
      console.error(`Error trying to turn off forcedEndEditComplete: ${err}`)
    }
  }, [props, isForcing])

  const forceOffOtherProvider = async () => {
    try {
      // if they continue to editing, switch which provider is currently editing the note so ClinicalNoteTab can register that it's no longer the other provider
      if (!props.providerEmail || !props.note) {
        return
      }

      setIsForcing(true)
      await markNoteAsBeingEdited({
        ProviderEmail: props.providerEmail,
        PatientId: props.note.PatientId,
        NoteId: props.note.NoteId,
        UpdateEnum: 'beingEdited',
      })

      // in case there was a bug and there is false state indicating forced off is turned "off" or left "off" (which happens from force closing tabs,
      // windows or computer crashing) check if the force off is still incomplete in 30 seconds to stop loading indicator
      if (!props.forcedEndEditComplete) {
        setTimeout(finishForcedEndEdit, 30000)
      }
    } catch (err) {
      console.error(`Error trying to turn off forceOffOtherProvider: ${err}`)
    }
  }

  useEffect(() => {
    // if forcedEndEdit is ON and this current provider is the one trying to edit
    if (
      props.anotherProviderIsEditing &&
      props.isBeingEditedBy &&
      props.providerEmail &&
      props.isBeingEditedBy === props.providerEmail
    ) {
      finishForcedEndEdit()
    }
  }, [
    props.anotherProviderIsEditing,
    props.isBeingEditedBy,
    props.providerEmail,
  ])

  return (
    <Modal
      show={props.anotherProviderIsEditing}
      aria-labelledby="contained-modal-title-vcenter"
      className="delete-modal vertical-align-center front-modal"
      backdropClassName="backdrop"
      centered
      size="lg"
      onHide={_.noop}
    >
      <Modal.Header className="modal-full-header">
        <Modal.Title id="contained-modal-title-vcenter">
          <i
            className="fa fa-exclamation-circle fa-lg delete-icon"
            aria-hidden="true"
          />
          &nbsp; This note is currently being edited!&nbsp;
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          This note is currently being edited by {prevBeingEditedBy}. Clicking
          Continue will allow you to take over editing. Please be aware that if{' '}
          {prevBeingEditedBy} is actively editing the note, the latest 30
          seconds of their changes may not be saved during the hand-off.
        </p>
      </Modal.Body>
      <Modal.Footer>
        {!isForcing ? (
          <>
            <Button
              onClick={closeModal}
              variant="secondary"
              className="cancel-btn"
            >
              Cancel
            </Button>
            <Button className="danger-btn" onClick={forceOffOtherProvider}>
              Continue to editing
            </Button>
          </>
        ) : (
          <>
            <Spinner
              animation="border"
              variant="danger"
              className="loader-button-spinner"
            />
            <div className="text-muted">This may take up to 30 seconds.</div>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}
