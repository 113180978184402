import { useQuery } from '@tanstack/react-query'

import { getAllPatientNotes } from '../api'
import { NOTE_QUERY_KEY } from '../constants'

export const notePatientQueryKey = (patientId: string | null) => [
  NOTE_QUERY_KEY,
  'patient',
  patientId,
]

export const useNotes = (patientId: string | null, enabled = false) => {
  const { data, isInitialLoading, isSuccess, isError, refetch } = useQuery(
    notePatientQueryKey(patientId),
    () => patientId && getAllPatientNotes(patientId),
    { enabled: !!patientId && enabled }
  )

  return { notes: data, isInitialLoading, isSuccess, isError, refetch }
}
