import { useMemo, useState } from 'react'

import { Form, FormInstance } from 'antd'

import * as PsychConditions from '../../../../shared/PsychConditions'
import {
  PsychHistoryCodeset,
  adhdCodeset,
  antiAnxietyCodeset,
  antipsychoticsCodeset,
  glutamateModCodeset,
  maoiCodeset,
  miscMedCodeset,
  ndriCodeset,
  otherAntidepressants,
  otherAugCodeset,
  sedativeCodeset,
  snriCodeset,
  ssriCodeset,
  stabilizersCodeset,
  substanceUseCodeset,
  tcaCodeset,
} from '../../../../shared/PsychiatricMedications'
import {
  CheckboxGroup,
  Col,
  DateInput,
  Divider,
  DynamicSubForm,
  Input,
  InputNumber,
  Radio,
  Row,
  TextArea,
  Typography,
} from '../../../BaseComponents'
import {
  CHARACTER_LIMIT,
  normalizeText,
  numberDecimalRange,
  numberRange,
  optionalFreeText,
  required,
} from '../../validation-rules'
import CheckboxesWithForm from '../CheckboxesWithForm'
import { Header } from '../helpers'
import ConditionsList from '../helpers/ConditionsList'
import {
  PsychHistoryHospQuestionKeys,
  PsychHistoryMedsQuestionKeys,
  PsychiatricHistoryQuestionKeys,
} from '../question-keys'

import styles from '../_shared.module.scss'

interface Props {
  form: FormInstance
  reviewMode?: boolean
}

const { Text, Title } = Typography

function PsychMedSubForm({
  label = '',
  disabled,
  form,
  value = '',
  reviewMode = false,
}: {
  disabled?: boolean
  form: FormInstance
  label?: string
  value?: string
  reviewMode?: boolean
}) {
  const [hasSideEffects, setHasSideEffects] = useState(false)
  const [isStillPrescribed, setIsStillPrescribed] = useState(false)
  const names = [PsychiatricHistoryQuestionKeys.PSYCH_MEDS, value]
  const genericLabel = (
    label.includes('(') ? label.substring(0, label.indexOf('(')) : label
  ).trim()
  return (
    <>
      <Title level={5}>{genericLabel}</Title>
      <Form.Item
        label={`Are you still currently prescribed ${genericLabel}?`}
        name={[...names, PsychHistoryMedsQuestionKeys.IS_STILL_PRESCRIBED]}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={disabled}
          size="large"
          onChange={(event) => setIsStillPrescribed(event.target.value)}
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <DateInput
        name={[...names, PsychHistoryMedsQuestionKeys.START_OF_MED]}
        label={`Approximately when did you start taking ${genericLabel}?`}
        form={form}
        className={styles.dob}
      />
      {(!isStillPrescribed || reviewMode) && (
        <DateInput
          name={[...names, PsychHistoryMedsQuestionKeys.END_OF_MED]}
          label={`Approximately when did you stop taking ${genericLabel}?`}
          form={form}
          className={styles.dob}
        />
      )}
      <Form.Item
        name={[...names, PsychHistoryMedsQuestionKeys.MAX_DOSE_PER_DAY]}
        label="What is/was the maximum dose per day?"
        rules={[numberDecimalRange('Please input valid dosage', 0)]}
      >
        <InputNumber disabled={disabled} size="large" />
      </Form.Item>
      <Text type="secondary">milligrams</Text>
      <Form.Item
        name={[...names, PsychHistoryMedsQuestionKeys.REASONS_FOR_USE]}
        label={`Why were/are you taking ${genericLabel}? How effective is/was it?`}
        rules={[optionalFreeText('Please input valid reason')]}
        normalize={normalizeText}
      >
        <TextArea
          disabled={reviewMode}
          maxLength={CHARACTER_LIMIT}
          showCount
          size="large"
        />
      </Form.Item>
      <Form.Item
        label={`Are/were there any side effects as a result of taking ${genericLabel}?`}
        name={[...names, PsychHistoryMedsQuestionKeys.HAS_SIDE_EFFECTS]}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          onChange={(event) => setHasSideEffects(event.target.value)}
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {(hasSideEffects || reviewMode) && (
        <Form.Item
          name={[
            ...names,
            PsychHistoryMedsQuestionKeys.SIDE_EFFECTS_DESCRIPTION,
          ]}
          label="Please describe the side effects"
          rules={[optionalFreeText('Please input valid side effects')]}
          normalize={normalizeText}
        >
          <TextArea
            disabled={reviewMode}
            maxLength={CHARACTER_LIMIT}
            showCount
            size="large"
          />
        </Form.Item>
      )}
      {(!isStillPrescribed || reviewMode) && (
        <Form.Item
          name={[
            ...names,
            PsychHistoryMedsQuestionKeys.REASON_FOR_DISCONTINUATION,
          ]}
          label={`Why did you stop taking ${genericLabel}?`}
          rules={[optionalFreeText('Please input valid reason')]}
          normalize={normalizeText}
        >
          <TextArea
            disabled={reviewMode}
            maxLength={CHARACTER_LIMIT}
            showCount
            size="large"
          />
        </Form.Item>
      )}
    </>
  )
}

const PsychiatricHistory = ({ form, reviewMode = false }: Props) => {
  const [showHospitalization, setShowHospitalization] = useState(false)
  const [hadKetamine, setShowHadKetamine] = useState(false)
  const [hadTherapy, setShowHadTherapy] = useState(false)
  const [hadTMS, setShowHadTMS] = useState(false)
  const [hadECT, setShowHadECT] = useState(false)
  const [hadMeds, setHadMeds] = useState(false)

  const mapValues = (codesets: PsychHistoryCodeset[]) =>
    codesets.map((codeset) => {
      const [_key, entry] = Object.entries(codeset)[0]
      return {
        label: entry.title,
        // questionKey: key, <== Switch to this when codesets ready
        questionKey: entry.title,
        value: entry.title,
      }
    })

  const medicationList = useMemo(() => {
    return [
      { title: 'ADHD Medications', options: mapValues(adhdCodeset) },
      { title: 'Anti-anxiety', options: mapValues(antiAnxietyCodeset) },
      { title: 'Antipsychotics', options: mapValues(antipsychoticsCodeset) },
      {
        title: 'Glutamate Modulators',
        options: mapValues(glutamateModCodeset),
      },
      { title: 'MAOIs', options: mapValues(maoiCodeset) },
      { title: 'Miscellaneous', options: mapValues(miscMedCodeset) },
      { title: 'Mood Stabilizers', options: mapValues(stabilizersCodeset) },
      { title: 'NDRIs', options: mapValues(ndriCodeset) },
      {
        title: 'Other antidepressants',
        options: mapValues(otherAntidepressants),
      },
      {
        title: 'Other augmentation agents',
        options: mapValues(otherAugCodeset),
      },
      { title: 'Sedatives', options: mapValues(sedativeCodeset) },
      { title: 'SNRIs', options: mapValues(snriCodeset) },
      { title: 'SSRIs', options: mapValues(ssriCodeset) },
      { title: 'TCAs', options: mapValues(tcaCodeset) },
      { title: 'Substance use', options: mapValues(substanceUseCodeset) },
    ]
  }, [])

  return (
    <>
      <Title level={2}>Psychiatric History</Title>
      <Divider />
      <Title level={4}>Background</Title>
      <Form.Item
        label="Have you ever been diagnosed by a healthcare professional with any of the following psychiatric conditions? Mark all that apply, including past diagnoses."
        name={PsychiatricHistoryQuestionKeys.EXISTING_PSYCH_DATA}
      >
        <ConditionsList
          reviewMode={reviewMode}
          form={form}
          conditionsToShow={PsychConditions}
          questionKeysName={PsychiatricHistoryQuestionKeys.EXISTING_PSYCH_DATA}
        />
      </Form.Item>
      <Form.Item
        label="Are there other psychiatric conditions that you have been diagnosed with or are concerned you might have? Please elaborate on these and any of the above diagnoses."
        name={PsychiatricHistoryQuestionKeys.EXISTING_PSYCH_DATA_OTHER}
        rules={[optionalFreeText('Please input valid conditions')]}
        normalize={normalizeText}
      >
        <TextArea
          disabled={reviewMode}
          maxLength={CHARACTER_LIMIT}
          showCount
          size="large"
        />
      </Form.Item>
      <Form.Item
        label="Do you currently have suicidal thoughts or feel that you don't want to live?"
        name={PsychiatricHistoryQuestionKeys.HAS_SUICIDAL_THOUGHTS}
      >
        <Radio.Group buttonStyle="solid" disabled={reviewMode} size="large">
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="In the past, have you ever had suicidal feelings or thoughts, or felt like you didn't want to live?"
        name={PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_SUICIDAL_IDEATION}
      >
        <Radio.Group buttonStyle="solid" disabled={reviewMode} size="large">
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Have you ever made a suicide attempt before?"
        name={PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_SUICIDAL_ATTEMPT}
      >
        <Radio.Group buttonStyle="solid" disabled={reviewMode} size="large">
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Do you have a history of self-harm?"
        name={PsychiatricHistoryQuestionKeys.HAS_HISTORY_OF_SELF_HARM}
      >
        <Radio.Group buttonStyle="solid" disabled={reviewMode} size="large">
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Have you ever been hospitalized for reasons related to your mental health?">
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          onChange={(event) => setShowHospitalization(event.target.value)}
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
        {(showHospitalization || reviewMode) && (
          <DynamicSubForm
            addButtonText="Add a hospitalization"
            reviewMode={reviewMode}
            buttonSize="large"
            name={PsychiatricHistoryQuestionKeys.HOSPITALIZATIONS}
            subForm={({ name = '', restField = {}, index = 0 }) => (
              <>
                <Title level={5}>Hospitalization #{index + 1}</Title>
                <Form.Item
                  {...restField}
                  name={[
                    name,
                    PsychHistoryHospQuestionKeys.REASON_FOR_HOSPITALIZATION,
                  ]}
                  label="Reason for hospitalization"
                  rules={[
                    optionalFreeText(
                      'Please input valid hospitalization reason'
                    ),
                  ]}
                  normalize={normalizeText}
                >
                  <TextArea
                    disabled={reviewMode}
                    maxLength={CHARACTER_LIMIT}
                    showCount
                    size="large"
                  />
                </Form.Item>
                <DateInput
                  {...restField}
                  name={[
                    name,
                    PsychHistoryHospQuestionKeys.START_OF_HOSPITALIZATION,
                  ]}
                  label="Approximate hospitalization start date"
                  form={form}
                  className={styles.dob}
                />
                <DateInput
                  {...restField}
                  name={[
                    name,
                    PsychHistoryHospQuestionKeys.END_OF_HOSPITALIZATION,
                  ]}
                  label="Approximate hospitalization end date"
                  form={form}
                  className={styles.dob}
                />
                <Form.Item
                  {...restField}
                  name={[
                    name,
                    PsychHistoryHospQuestionKeys.LENGTH_OF_HOSPITALIZATION,
                  ]}
                  label="Approximate length of hospitalization"
                  rules={[
                    numberDecimalRange(
                      'Please input valid length of hospitalization',
                      0
                    ),
                  ]}
                >
                  <InputNumber disabled={reviewMode} size="large" />
                </Form.Item>
                <Text type="secondary">Days</Text>
                <br />
              </>
            )}
          />
        )}
      </Form.Item>
      <Divider />
      <Header
        title="Past mental health treatment experience"
        description="Please provide as much detail as possible regarding prior mental health treatments and therapy you have received."
      />
      <Form.Item
        label="Have you ever received ketamine treatment for mental health concerns or for chronic pain?"
        name={PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_KETAMINE_TREATMENT}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          onChange={(event) => setShowHadKetamine(event.target.value)}
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {hadKetamine && (
        <>
          <Row className={styles.psychHistoryLabel}>
            <Text>Number of past ketamine treatment sessions</Text>
          </Row>
          <Row>
            <Col xs={8} md={6}>
              <Form.Item
                name={
                  PsychiatricHistoryQuestionKeys.NUMBER_OF_KETAMINE_TREATMENTS
                }
                rules={[numberRange('Invalid number', 0)]}
                normalize={(value) => Number(value)}
              >
                <Input type="number" min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Was the ketamine treatment effective? Please provide details around when, where and why you received ketamine and what form(s) of ketamine you have received."
            name={PsychiatricHistoryQuestionKeys.KETAMINE_TREATMENT_DETAILS}
            normalize={normalizeText}
          >
            <TextArea
              disabled={reviewMode}
              maxLength={CHARACTER_LIMIT}
              showCount
              size="large"
              rows={2}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        label="Have you ever attended therapy for mental health concerns?"
        name={PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_THERAPY_TREATMENT}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          onChange={(event) => setShowHadTherapy(event.target.value)}
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {hadTherapy && (
        <Form.Item
          label="Please describe the type(s) of therapy and any other pertinent details about how it went, who it was with, when it was and how long the course of each therapy was."
          name={PsychiatricHistoryQuestionKeys.THERAPY_TREATMENT_DETAILS}
          normalize={normalizeText}
        >
          <TextArea
            disabled={reviewMode}
            maxLength={CHARACTER_LIMIT}
            showCount
            size="large"
            rows={2}
          />
        </Form.Item>
      )}
      <Form.Item
        label="Have you ever received Transcranial Magnetic Stimulation (TMS)?"
        name={PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_TMS_TREATMENT}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          onChange={(event) => setShowHadTMS(event.target.value)}
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {hadTMS && (
        <>
          <Row className={styles.psychHistoryLabel}>
            <Text>How many courses of TMS?</Text>
          </Row>
          <Row>
            <Col xs={8} md={6}>
              <Form.Item
                name={PsychiatricHistoryQuestionKeys.NUMBER_OF_TMS_TREATMENTS}
                rules={[numberRange('Invalid number', 0)]}
                normalize={(value) => Number(value)}
              >
                <Input type="number" min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Please describe the type(s) of TMS, what it was for and when and where it was. Include any pertinent details about how effective TMS was and about the experience(s)."
            name={PsychiatricHistoryQuestionKeys.TMS_TREATMENT_DETAILS}
            normalize={normalizeText}
          >
            <TextArea
              disabled={reviewMode}
              maxLength={CHARACTER_LIMIT}
              showCount
              size="large"
              rows={2}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        label="Have you ever received Electroconvulsive Therapy (ECT)?"
        name={PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_ECT_TREATMENT}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          onChange={(event) => setShowHadECT(event.target.value)}
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {hadECT && (
        <>
          <Row className={styles.psychHistoryLabel}>
            <Text>How many sessions of ECT?</Text>
          </Row>
          <Row>
            <Col xs={8} md={6}>
              <Form.Item
                name={PsychiatricHistoryQuestionKeys.NUMBER_OF_ECT_TREATMENTS}
                rules={[numberRange('Invalid number', 0)]}
                normalize={(value) => Number(value)}
              >
                <Input type="number" min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Please describe the type(s) of ECT, what it was for and when and where it was. Include any pertinent details about how effective ECT was and about the experience(s)."
            name={PsychiatricHistoryQuestionKeys.ECT_TREATMENT_DETAILS}
            normalize={normalizeText}
          >
            <TextArea
              disabled={reviewMode}
              maxLength={CHARACTER_LIMIT}
              showCount
              size="large"
              rows={2}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        label="Please list any other types of mental health services you have received, when you received them and how effective they were. This could include services such as residential treatment, day or intensive programs, self-help groups, outpatient psychiatric treatment or participation in psychiatric clinical trials."
        name={PsychiatricHistoryQuestionKeys.OTHER_MENTAL_HEALTH_SERVICES}
      >
        <TextArea
          disabled={reviewMode}
          maxLength={CHARACTER_LIMIT}
          showCount
          size="large"
          rows={4}
        />
      </Form.Item>
      <Divider />
      <Header title="Psychiatric medications" />
      <Form.Item
        label="Have you ever been prescribed medications for mental health concerns?"
        name={PsychiatricHistoryQuestionKeys.HAS_TAKEN_PSYCH_MEDS}
        rules={[required('Please select an option.')]}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          onChange={(event) => setHadMeds(event.target.value)}
          size="large"
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {hadMeds && (
        <>
          <br />
          <Text>
            Which of the following medications have you been prescribed in the
            past or are you currently taking?
          </Text>
          <CheckboxGroup name={PsychiatricHistoryQuestionKeys.PSYCH_MEDS}>
            {medicationList.map(({ title, options }, index) => {
              return (
                <div key={index} className={styles.checkboxPadding}>
                  <Title level={5}>{title}</Title>
                  <CheckboxesWithForm
                    disabled={reviewMode}
                    checkboxGroupName={
                      PsychiatricHistoryQuestionKeys.PSYCH_MEDS
                    }
                    options={options}
                    subForm={(props) => (
                      <PsychMedSubForm
                        reviewMode={reviewMode}
                        form={form}
                        {...props}
                      />
                    )}
                  />
                </div>
              )
            })}
          </CheckboxGroup>
        </>
      )}
      <Divider />
    </>
  )
}

export default PsychiatricHistory
