export const Icon = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        id="drag-background"
        x="0.5"
        width="15"
        height="20"
        rx="1.66667"
        fill="white"
      />
      <circle cx="5.49967" cy="4.99992" r="1.66667" fill="#8C8C8C" />
      <circle cx="10.4997" cy="4.99992" r="1.66667" fill="#8C8C8C" />
      <circle cx="5.49967" cy="9.99992" r="1.66667" fill="#8C8C8C" />
      <circle cx="10.4997" cy="9.99992" r="1.66667" fill="#8C8C8C" />
      <circle cx="5.49967" cy="14.9999" r="1.66667" fill="#8C8C8C" />
      <circle cx="10.4997" cy="14.9999" r="1.66667" fill="#8C8C8C" />
    </svg>
  )
}
