import React from 'react'

import { ModalBasic } from '../BaseComponents'

export type SendSuperbillModalProps = {
  visible: boolean
  onCancel: () => void
  onSend: () => void
  isSending: boolean
}
const SendSuperbillModal: React.FC<SendSuperbillModalProps> = ({
  visible,
  onCancel,
  onSend,
  isSending,
}) => {
  return (
    <ModalBasic
      visible={visible}
      title={'Send superbill to patient'}
      content={'The superbill is ready to be sent to the patient.'}
      onCancel={onCancel}
      cancelButtonProps={{
        disabled: isSending,
        testId: 'cancel-send-superbill-button',
      }}
      onConfirm={onSend}
      confirmButtonLabel={'Send superbill'}
      confirmButtonProps={{
        loading: isSending,
        disabled: isSending,
        testId: 'send-superbill-button',
      }}
      contentTestId={'send-superbill-to-patient-modal'}
    />
  )
}

export default SendSuperbillModal
