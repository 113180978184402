import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { addMinutes, differenceInMinutes } from 'date-fns'

import { DayOfWeek } from '../../../../api/api-lib-typed'
import {
  Button,
  Checkbox,
  Text,
  TimeSelector,
} from '../../../../stories/BaseComponents'
import { TimeRange } from '../AvailabilitySettings/AvailabilitySettings'
import { getDateAtHourAndMinute, validateTimerange } from '../helpers'
import { CopyDropdown } from './CopyDropdown'

import sharedStyles from '../../../_shared.module.scss'
import styles from './DayAvailability.module.scss'

export type DayAvailabilityProps = {
  dayOfWeek: DayOfWeek
  label: string
  availability: TimeRange
  setAvailability: (availability: TimeRange) => void
  onAddTimeslot(timeslot: DayOfWeek): void
  onDeleteTimeslot(timeslot: DayOfWeek, timeslotIdx: number): void
  onToggleAvailability(dayOfWeek: DayOfWeek): void
  onCopyApply(currentDayOfWeek: DayOfWeek, daysToApplyCopy: DayOfWeek[]): void
}

export const DayAvailability = ({
  dayOfWeek,
  label,
  availability,
  setAvailability,
  onAddTimeslot,
  onDeleteTimeslot,
  onToggleAvailability,
  onCopyApply,
}: DayAvailabilityProps) => {
  const updateAvailability = (
    index: number,
    key: 'startTime' | 'endTime',
    value: string
  ) => {
    const timeSplit = value.split(':')

    const minutes = Number(timeSplit[1].replace(/[p|a]m/, ''))
    const hour = Number(timeSplit[0])
    const isPm = timeSplit[1].includes('pm')
    const hours =
      isPm && hour !== 12 ? 12 + hour : !isPm && hour === 12 ? 0 : hour
    const time = getDateAtHourAndMinute(hours, minutes)

    if (key === 'startTime') {
      const currentTimeRange = availability.timeRange[index]
      const startTime = getDateAtHourAndMinute(
        currentTimeRange.startTimeHour,
        currentTimeRange.startTimeMinute
      )
      availability.timeRange[index].durationInMinutes =
        availability.timeRange[index].durationInMinutes +
        differenceInMinutes(startTime, time)

      availability.timeRange[index].startTimeHour = hours
      availability.timeRange[index].startTimeMinute = minutes
    } else {
      const currentTimeRange = availability.timeRange[index]
      const startTime = getDateAtHourAndMinute(
        currentTimeRange.startTimeHour,
        currentTimeRange.startTimeMinute
      )
      availability.timeRange[index].durationInMinutes = differenceInMinutes(
        time,
        startTime
      )
    }
    setAvailability(validateTimerange(availability))
  }

  const handleAddTimeslot = () => {
    onAddTimeslot(dayOfWeek)
  }

  const handleDeleteTimeslot = (timeslotIdx: number) => () => {
    onDeleteTimeslot(dayOfWeek, timeslotIdx)
  }

  const handleToggleAvailability = () => {
    onToggleAvailability(dayOfWeek)
  }

  return (
    <div
      className={styles.container}
      data-testid={`availability-container-${dayOfWeek}`}
    >
      <div className={styles.checkboxContainer}>
        <Checkbox
          testId={`${dayOfWeek}-availability-checkbox`}
          checked={!availability.isUnavailable}
          onChange={handleToggleAvailability}
        />
        <p
          style={{ alignSelf: 'center', justifyContent: 'center' }}
          className={styles.label}
        >
          {label}
        </p>
      </div>
      <div
        className={cx(styles.timepickerContainer, {
          [styles.columnContainer]:
            availability.timeRange.length > 1 && !availability.isUnavailable,
        })}
      >
        {!availability.isUnavailable ? (
          availability.timeRange.map(
            (
              {
                durationInMinutes,
                startTimeHour,
                startTimeMinute,
                hasValidationError,
              },
              idx
            ) => {
              const formatTime = (date: Date) => {
                const hours = date.getHours()
                const mins = date.getMinutes()
                return `${hours % 12 === 0 ? 12 : hours % 12}:${
                  mins < 10 ? `0${mins}` : mins
                }${+hours >= 12 ? 'pm' : 'am'}`
              }
              const selectedStartTime = formatTime(
                getDateAtHourAndMinute(startTimeHour, startTimeMinute)
              )
              const selectedEndTime = formatTime(
                addMinutes(
                  getDateAtHourAndMinute(startTimeHour, startTimeMinute),
                  durationInMinutes
                )
              )
              return (
                <div key={`${startTimeHour}_${startTimeMinute}_${idx}`}>
                  <div
                    className={cx(styles.availabilityTimeSelectorRow, {
                      [styles.additional]: idx > 0,
                    })}
                  >
                    <TimeSelector
                      testId={`${dayOfWeek}-start-time-${idx}`}
                      minuteStep={15}
                      className={styles.select}
                      value={selectedStartTime}
                      allowClear={false}
                      status={hasValidationError ? 'error' : undefined}
                      onSelect={(value: string) => {
                        updateAvailability(idx, 'startTime', value)
                      }}
                    />
                    <span className={sharedStyles.hyphen} />
                    <TimeSelector
                      testId={`${dayOfWeek}-end-time-${idx}`}
                      minuteStep={15}
                      className={styles.select}
                      value={selectedEndTime}
                      allowClear={false}
                      status={
                        durationInMinutes <= 0 || hasValidationError
                          ? 'error'
                          : undefined
                      }
                      onSelect={(value: string) => {
                        updateAvailability(idx, 'endTime', value)
                      }}
                    />
                    <Button
                      className={styles.button}
                      onClick={handleDeleteTimeslot(idx)}
                      type="text"
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                  {durationInMinutes <= 0 && (
                    <p className={styles.errorText}>
                      Choose an end time later than start time.
                    </p>
                  )}
                  {hasValidationError && (
                    <p className={styles.errorText}>
                      Times overlap with another set of times.
                    </p>
                  )}
                </div>
              )
            }
          )
        ) : (
          <Text className={styles.unavailableText}>Unavailable</Text>
        )}
      </div>
      <div className={styles.actionButtons}>
        <Button
          className={styles.button}
          onClick={handleAddTimeslot}
          type="text"
        >
          <PlusOutlined />
        </Button>
        <CopyDropdown currentDayOfWeek={dayOfWeek} onCopyApply={onCopyApply} />
      </div>
    </div>
  )
}
