import { useEffect } from 'react'

type HotkeyItemOptions = {
  preventDefault?: boolean
}

export type HotkeyItem = [
  string,
  (event: KeyboardEvent) => void,
  HotkeyItemOptions?
]

export type KeyboardModifiers = {
  alt: boolean
  ctrl: boolean
  meta: boolean
  mod: boolean
  shift: boolean
}

export type Hotkey = KeyboardModifiers & {
  key?: string
}

type CheckHotkeyMatch = (event: KeyboardEvent) => boolean

export function parseHotkey(hotkey: string): Hotkey {
  const keys = hotkey
    .toLowerCase()
    .split('+')
    .map((part) => part.trim())

  const modifiers: KeyboardModifiers = {
    alt: keys.includes('alt'),
    ctrl: keys.includes('ctrl'),
    meta: keys.includes('meta'),
    mod: keys.includes('mod'),
    shift: keys.includes('shift'),
  }

  const key = keys.find(
    (key) => !['alt', 'ctrl', 'meta', 'shift', 'mod'].includes(key)
  )

  return { ...modifiers, key }
}

export function isExactHotkey(hotkey: Hotkey, event: KeyboardEvent): boolean {
  const { alt, ctrl, meta, mod, shift, key } = hotkey
  const { altKey, ctrlKey, metaKey, shiftKey, key: pressedKey, code } = event

  if (alt !== altKey) {
    return false
  }

  if (mod) {
    if (!ctrlKey && !metaKey) {
      return false
    }
  } else if (ctrl !== ctrlKey || meta !== metaKey) {
    return false
  }

  if (shift !== shiftKey) {
    return false
  }

  if (key) {
    const normalizedPressedKey = pressedKey.toLowerCase()
    const normalizedKey = key.toLowerCase()
    const normalizedCode = code.replace('Key', '').toLowerCase()

    if (
      normalizedPressedKey === normalizedKey ||
      normalizedCode === normalizedKey
    ) {
      return true
    }
  }

  return false
}

export function getHotkeyMatcher(hotkey: string): CheckHotkeyMatch {
  return (event) => isExactHotkey(parseHotkey(hotkey), event)
}

export function shouldFireEvent(
  event: KeyboardEvent,
  tagsToIgnore: string[],
  triggerOnContentEditable = false
) {
  if (event.target instanceof HTMLElement) {
    if (triggerOnContentEditable) {
      return !tagsToIgnore.includes(event.target.tagName)
    }

    return (
      !event.target.isContentEditable &&
      !tagsToIgnore.includes(event.target.tagName)
    )
  }

  return true
}

/**
 * Custom hook that allows registering keyboard shortcuts and executing corresponding handlers.
 *
 * @param hotkeys - An array of hotkey items, where each item consists of a hotkey string, a handler function, and optional options.
 * @param tagsToIgnore - An array of HTML tag names to ignore when checking if the keyboard event should be triggered. Defaults to ['INPUT', 'TEXTAREA', 'SELECT'].
 * @param triggerOnContentEditable - A boolean indicating whether the keyboard event should be triggered when the focus is on a content editable element. Defaults to false.
 *
 * @example
 * ```tsx
 *  useKeyboardShortcut([
 *     ['mod+J', () => console.log('Toggle color scheme')],
 *     ['ctrl+K', () => console.log('Trigger search')],
 *     ['alt+mod+shift+X', () => console.log('Rick roll')],
 *   ]);
 * ```
 *
 * @example
 * ```tsx
 * useKeyboardShortcut(
 *  [
 *   ['mod+p', () => console.log('Calling PDF'), { preventDefault: true }],
 *  ],
 *  [],
 *  true
 * )
 * ```
 */
export function useKeyboardShortcut(
  hotkeys: HotkeyItem[],
  tagsToIgnore: string[] = ['INPUT', 'TEXTAREA', 'SELECT'],
  triggerOnContentEditable = false
) {
  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      hotkeys.forEach(
        ([hotkey, handler, options = { preventDefault: true }]) => {
          if (
            getHotkeyMatcher(hotkey)(event) &&
            shouldFireEvent(event, tagsToIgnore, triggerOnContentEditable)
          ) {
            if (options.preventDefault) {
              event.preventDefault()
            }
            handler(event)
          }
        }
      )
    }

    document.documentElement.addEventListener('keydown', keydownListener)
    return () =>
      document.documentElement.removeEventListener('keydown', keydownListener)
  }, [hotkeys])
}

export default useKeyboardShortcut
