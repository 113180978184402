/*
  NOTE: Not actual an codeset here, only enums are needed for these fields
*/
enum Frequency {
  SINGLE_DOSE = 'SINGLE_DOSE',
  ONCE_A_DAY = 'ONCE_A_DAY',
  TWICE_A_DAY = 'TWICE_A_DAY',
  THREE_TIMES_A_DAY = 'THREE_TIMES_A_DAY',
  FOUR_TIMES_A_DAY = 'FOUR_TIMES_A_DAY',
  FIVE_TIMES_A_DAY = 'FIVE_TIMES_A_DAY',
  SIX_TIMES_A_DAY = 'SIX_TIMES_A_DAY',
  EVERY_MORNING = 'EVERY_MORNING',
  EVERY_EVENING = 'EVERY_EVENING',
  EVERY_NIGHT = 'EVERY_NIGHT',
  AT_BEDTIME = 'AT_BEDTIME',
  EVERY_OTHER_DAY = 'EVERY_OTHER_DAY',
  EVERY_THREE_DAYS = 'EVERY_THREE_DAYS',
  EVERY_HOUR = 'EVERY_HOUR',
  EVERY_TWO_HOURS = 'EVERY_TWO_HOURS',
  EVERY_TWO_HOURS_WHILE_AWAKE = 'EVERY_TWO_HOURS_WHILE_AWAKE',
  EVERY_THREE_HOURS = 'EVERY_THREE_HOURS',
  EVERY_THREE_HOURS_WHILE_AWAKE = 'EVERY_THREE_HOURS_WHILE_AWAKE',
  EVERY_FOUR_HOURS = 'EVERY_FOUR_HOURS',
  EVERY_FOUR_HOURS_WHILE_AWAKE = 'EVERY_FOUR_HOURS_WHILE_AWAKE',
  EVERY_SIX_HOURS = 'EVERY_SIX_HOURS',
  EVERY_SIX_TO_EIGHT_HOURS = 'EVERY_SIX_TO_EIGHT_HOURS',
  EVERY_EIGHT_HOURS = 'EVERY_EIGHT_HOURS',
  EVERY_TWELVE_HOURS = 'EVERY_TWELVE_HOURS',
  EVERY_24_HOURS = 'EVERY_24_HOURS',
  EVERY_48_HOURS = 'EVERY_48_HOURS',
  EVERY_72_HOURS = 'EVERY_72_HOURS',
  EVERY_THREE_TO_FOUR_HOURS = 'EVERY_THREE_TO_FOUR_HOURS',
  EVERY_THREE_TO_FOUR_HOURS_WHILE_AWAKE = 'EVERY_THREE_TO_FOUR_HOURS_WHILE_AWAKE',
  EVERY_FOUR_TO_SIX_HOURS = 'EVERY_FOUR_TO_SIX_HOURS',
  EVERY_FOUR_TO_SIX_HOURS_WHILE_AWAKE = 'EVERY_FOUR_TO_SIX_HOURS_WHILE_AWAKE',
  EVERY_EIGHT_TO_TWELVE_HOURS = 'EVERY_EIGHT_TO_TWELVE_HOURS',
  ONCE_A_WEEK = 'ONCE_A_WEEK',
  TWICE_A_WEEK = 'TWICE_A_WEEK',
  THREE_TIMES_A_WEEK = 'THREE_TIMES_A_WEEK',
  ONCE_EVERY_TWO_WEEKS = 'ONCE_EVERY_TWO_WEEKS',
  EVERY_TWO_WEEKS = 'EVERY_TWO_WEEKS',
  EVERY_FOUR_WEEKS = 'EVRY_FOUR_WEEKS',
  ONCE_A_MONTH = 'ONCE_A_MONTH',
  EVERY_THREE_MONTHS = 'EVERY_THREE_MONTHS',
  AS_DIRECTED = 'AS_DIRECTED',
}

enum FrequencyOptionalDetail {
  AS_NEEDED = 'AS_NEEDED',
  AS_NEEDED_FOR_PAIN = 'AS_NEEDED_FOR_PAIN',
  AS_DIRECTED = 'AS_DIRECTED',
  WITH_MEALS = 'WITH_MEALS',
  BETWEEN_MEALS = 'BETWEEN_MEALS',
  AFTER_MEALS = 'AFTER_MEALS',
  ONE_HOUR_BEFORE_MEALS = 'ONE_HOUR_BEFORE_MEALS',
  WITH_A_GLASS_OF_WATER = 'WITH_A_GLASS_OF_WATER',
  BEFORE_EXERCISE = 'BEFORE_EXERCISE',
}

export { Frequency, FrequencyOptionalDetail }
