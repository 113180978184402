import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const LiverCodes: any = [
  {
    235856003: {
      title: 'Liver Disease (Including Gallbladder)',
      snomedcode: 235856003,
      description: 'Disorder of liver (disorder)',
      icdcode: 'K769',
      icddescription: 'Liver Disease, unspecified',
    },
  },
  {
    41309000: {
      title: 'ld.Alcohol-Related Liver Disease',
      description: 'Alcoholic liver damage (disorder)',
      snomedcode: 41309000,
      icdcode: 'K709',
      icddescription: 'Alcoholic liver disease, unspecified',
    },
  },
  {
    76581006: {
      title: 'ld.Cholecystitis',
      description: 'Cholecystitis (disorder)',
      snomedcode: 76581006,
      icdcode: 'K819',
      icddescription: 'Cholecystitis, unspecified',
    },
  },
  {
    19943007: {
      title: 'ld.Cirrhosis',
      description: 'Cirrhosis of liver (disorder)',
      snomedcode: 19943007,
      icdcode: 'K7460',
      icddescription: 'Unspecified Cirrhosis of Liver',
    },
  },
  {
    235919008: {
      title: 'ld.Gallstones',
      description: 'Gallbladder calculus (disorder)',
      snomedcode: 235919008,
      icdcode: 'K8020',
      icddescription:
        'Calculus of gallbladder without cholecystitis without obstruction',
    },
  },
  {
    197315008: {
      title: 'ld.Non-Alcoholic Fatty Liver Disease (NAFLD)',
      description: 'Non-alcoholic fatty liver (disorder)',
      snomedcode: 197315008,
      icdcode: 'K760',
      icddescription: 'Fatty (change of) liver, not elsewhere classified',
    },
  },
  {
    3738000: {
      title: 'ld.Viral Hepatitis',
      description: 'Viral hepatitis (disorder)',
      snomedcode: 3738000,
      icdcode: 'B199',
      icddescription: 'Unspecified viral hepatitis without hepatic coma',
    },
  },
]

export const LiverDropDownValues = constructPatientDemographicObject(
  LiverCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
