import React from 'react'

import 'moment-timezone'
import { Col, Form } from 'react-bootstrap'
import 'react-dates/initialize'

import { AutoSizingTextArea } from '../../../../../../stories/BaseComponents/TextAreaInput'
import { generateSpravatoClassName } from './Spravato'

import 'react-dates/lib/css/_datepicker.css'

interface AddHealthcareProps {
  disabled: boolean
  fields: any
  handleFieldChange: (obj: any) => void
}

export default function SpravatoHealthcareSettings(props: AddHealthcareProps) {
  return (
    <>
      <Form.Row>
        <Form.Group as={Col} md={6} controlId="SpravatoProviderFirstName">
          <Form.Label className="font-weight-bold">
            First Name (Spravato REMS-trained provider on-site throughout)
          </Form.Label>
          <Form.Control
            bsPrefix={
              props.fields.SpravatoProviderFirstName === ''
                ? generateSpravatoClassName(true)
                : 'form-input'
            }
            type="text"
            disabled={props.disabled}
            value={props.fields.SpravatoProviderFirstName}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoProviderFirstName: event.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} md={6} controlId="SpravatoProviderLastName">
          <Form.Label className="font-weight-bold">
            Last Name (Spravato REMS-trained provider on-site throughout)
          </Form.Label>
          <Form.Control
            bsPrefix={
              props.fields.SpravatoProviderLastName === ''
                ? generateSpravatoClassName(true)
                : 'form-input'
            }
            type="text"
            disabled={props.disabled}
            value={props.fields.SpravatoProviderLastName}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoProviderLastName: event.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md={5} controlId="SpravatoClinicName">
          <Form.Label className="font-weight-bold">Clinic Name</Form.Label>
          <Form.Control
            bsPrefix={
              props.fields.SpravatoClinicName === ''
                ? generateSpravatoClassName(true)
                : 'form-input'
            }
            type="text"
            disabled={props.disabled}
            value={props.fields.SpravatoClinicName}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoClinicName: event.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} md={3} controlId="SpravatoProviderPhone">
          <Form.Label className="font-weight-bold">Phone Number</Form.Label>
          <Form.Control
            bsPrefix={
              props.fields.SpravatoProviderPhone === ''
                ? generateSpravatoClassName(true)
                : 'form-input'
            }
            type="text"
            disabled={props.disabled}
            value={props.fields.SpravatoProviderPhone || ''}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoProviderPhone: event.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} md={4} controlId="SpravatoProviderEmail">
          <Form.Label className="font-weight-bold">Email</Form.Label>
          <Form.Control
            bsPrefix={
              props.fields.SpravatoProviderEmail === ''
                ? generateSpravatoClassName(true)
                : 'form-input'
            }
            type="text"
            disabled={props.disabled}
            value={props.fields.SpravatoProviderEmail}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoProviderEmail: event.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md={6} controlId="SpravatoClinicAddress1">
          <Form.Label className="font-weight-bold">Address 1</Form.Label>
          <Form.Control
            bsPrefix={
              props.fields.SpravatoClinicAddress1 === ''
                ? generateSpravatoClassName(true)
                : 'form-input'
            }
            type="text"
            disabled={props.disabled}
            value={props.fields.SpravatoClinicAddress1 || ''}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoClinicAddress1: event.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} md={6} controlId="SpravatoClinicAddress2">
          <Form.Label className="font-weight-bold">Address 2</Form.Label>
          <Form.Control
            bsPrefix="form-input"
            type="text"
            disabled={props.disabled}
            value={props.fields.SpravatoClinicAddress2 || ''}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoClinicAddress2: event.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md={5} controlId="SpravatoClinicCity">
          <Form.Label className="font-weight-bold">City</Form.Label>
          <Form.Control
            bsPrefix={
              props.fields.SpravatoClinicCity === ''
                ? generateSpravatoClassName(true)
                : 'form-input'
            }
            type="text"
            disabled={props.disabled}
            value={props.fields.SpravatoClinicCity || ''}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoClinicCity: event.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="SpravatoClinicState">
          <Form.Label className="font-weight-bold">State</Form.Label>
          <Form.Control
            bsPrefix={
              props.fields.SpravatoClinicState === ''
                ? generateSpravatoClassName(true)
                : 'form-input'
            }
            type="text"
            disabled={props.disabled}
            value={props.fields.SpravatoClinicState || ''}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoClinicState: event.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="SpravatoClinicZip">
          <Form.Label className="font-weight-bold">Zip</Form.Label>
          <Form.Control
            bsPrefix={
              props.fields.SpravatoClinicZip === ''
                ? generateSpravatoClassName(true)
                : 'form-input'
            }
            type="text"
            disabled={props.disabled}
            value={props.fields.SpravatoClinicZip || ''}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoClinicZip: event.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md={12} controlId="SpravatoClinicAdditionalNotes">
          <Form.Label className="font-weight-bold">Additional Notes</Form.Label>
          <Form.Control
            bsPrefix="form-control"
            className="field-value-edit"
            type="text"
            as={AutoSizingTextArea}
            minRows={1}
            disabled={props.disabled}
            value={props.fields.SpravatoClinicAdditionalNotes}
            onChange={(event) =>
              props.handleFieldChange({
                ...props.fields,
                SpravatoClinicAdditionalNotes: event.target.value,
              })
            }
          />
        </Form.Group>
      </Form.Row>
    </>
  )
}
