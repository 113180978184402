import React, { useState } from 'react'

import { Table as AntDTable, TableProps as AntDTableProps } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface'

import Box from './Box'
import { FilterObject, updateFilters } from './FilterTags'
import { AllTableDataTypes } from './tableData.types'

import './_shared.scss'

export type TableOnChangeHandler = (
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<any> | SorterResult<any>[],
  extra: TableCurrentDataSource<AllTableDataTypes>
) => void

const DEFAULT_PAGE_SIZE = 10

export interface GenericTableProps extends AntDTableProps<AllTableDataTypes> {
  dataSource: AllTableDataTypes[]
  columns?: ColumnsType<any> | undefined
  onChange?: TableOnChangeHandler
  children?: React.ReactNode
  size?: SizeType
  noToolsSection?: boolean
  pageSize?: number
  addClassNames?: string[]
  /**
   * Show the surrounding box container from Table.
   * Can be hidden if the parent component is already embedding the table in another
   * container like tabs.
   */
  showBoxContainer?: boolean
  testId?: string
}

export const useToolsWithTableHook = (_dataSource: AllTableDataTypes[]) => {
  /*
    Custom Table hook that manages child component states (e.g. CSV export, filter tags)
    and subscribes them to the table's changes
  */
  // const [downloadableCSVData, setDownloadableCSVData] = useState<AllTableDataTypes[]>(dataSource)
  const [activeFilters, setActiveFilters] = useState<FilterObject[]>([])
  // const [activeSort, setActiveSort] = useState<SorterResult<any>> ({})

  const tableChangeHandler: TableOnChangeHandler = (
    _pagination,
    filters,
    sorter,
    event
  ) => {
    if (event.action === 'filter') {
      const currentFiltersOn = updateFilters(filters)
      setActiveFilters(currentFiltersOn)
    }
  }

  return {
    // downloadableCSVData,
    activeFilters,
    tableChangeHandler,
  }
}

const customPagination = {
  size: 'default',
  className: 'ant-table-pagination ant-table-pagination-right margin-override',
  defaultPageSize: DEFAULT_PAGE_SIZE,
} as TablePaginationConfig

const Table: React.FC<GenericTableProps> = ({
  children,
  columns,
  dataSource,
  loading,
  onChange = () => null,
  size = 'middle',
  pagination = customPagination,
  pageSize = DEFAULT_PAGE_SIZE,
  noToolsSection = false,
  addClassNames = [],
  showBoxContainer = true,
  testId,
  ...props
}) => {
  customPagination.defaultPageSize = pageSize

  const tableContent = (
    <>
      {!noToolsSection && <div className="table-tools-section">{children}</div>}
      <AntDTable
        data-testid={testId}
        {...props}
        dataSource={dataSource}
        columns={columns}
        onChange={onChange}
        size={size}
        pagination={pagination}
        loading={loading}
      />
    </>
  )

  if (showBoxContainer) {
    return <Box addClassNames={addClassNames}>{tableContent}</Box>
  }

  return tableContent
}

export default Table
