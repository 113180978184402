import { format, parseISO } from 'date-fns'
import moment from 'moment'

import { INTAKE_REVIEW_FORM_MAP } from '../sections/helpers'
import {
  AllergiesMedicationsQuestionKeys,
  GeneralInformationQuestionKeys,
  HealthcareProviderSubForm,
  MedHistoryNonPsychQuestionKeys,
  MedicationsSubFormQuestionKeys,
  OtherInformationQuestionKeys,
  PsychHistoryHospQuestionKeys,
  PsychHistoryMedsQuestionKeys,
  PsychiatricHistoryQuestionKeys,
} from '../sections/question-keys'
import {
  conditionallyRenderedFields,
  emptyIntakeResponseDefaults,
  subSectionItemKeys,
  unpopulatedSubFormItems,
} from './constants'
import { createKeyValueNode, createListItemNode } from './createV2Node'

/** Adds a hyphen as a bullet character */
export const bulletCharacter = (indented = false): string =>
  indented ? '   - ' : '- '

/**
 * Conditional: checks question's answers should be displayed as a bulleted list
 */
export const isBulletList = (questionKey: string): boolean =>
  (
    [
      MedHistoryNonPsychQuestionKeys.CONDITIONS,
      PsychiatricHistoryQuestionKeys.EXISTING_PSYCH_DATA,
    ] as string[]
  ).includes(questionKey)

/**
 * Conditional: checks question's answers should be displayed as a bulleted list
 */
export const isSubformItem = (questionKey: string): boolean =>
  (
    [
      GeneralInformationQuestionKeys.HEALTHCARE_PROVIDERS,
      PsychiatricHistoryQuestionKeys.HOSPITALIZATIONS,
    ] as string[]
  ).includes(questionKey)

/**
 * Conditional: checks if a question is nested in a subform item and should be ignored since it'll be rendered within the isSubformItem
 */
export const isNestedSubformItem = (questionKey: string): boolean =>
  (
    [
      ...Object.keys(HealthcareProviderSubForm),
      ...Object.keys(MedicationsSubFormQuestionKeys),
      ...Object.keys(PsychHistoryMedsQuestionKeys),
      ...Object.keys(PsychHistoryHospQuestionKeys),
    ] as string[]
  ).includes(questionKey)

/**
 * Conditional: checks for medications in array form
 */
export const isMedItem = (questionKey: string): boolean =>
  (
    [
      AllergiesMedicationsQuestionKeys.CURRENT_MEDICATIONS,
      PsychiatricHistoryQuestionKeys.PSYCH_MEDS,
    ] as string[]
  ).includes(questionKey)

/**
 * Conditional: checks an address is an address object from Places API with added unit number
 */
export const isViewableAddress = (questionKey: string): boolean =>
  questionKey.includes('ADDRESS_OBJECT')

/**
 * Conditional: checks form item is an incomplete address item (stringified address & unit numbers difficult to parse separately)
 */
export const isHiddenAddressItem = (questionKey: string): boolean =>
  (questionKey.includes('ADDRESS') || questionKey.includes('UNIT_NUMBER')) &&
  !isViewableAddress(questionKey)

/**
 * Conditional: checks that a question should not be displayed to provider which includes
 *   1. Addresses that pass the `isHiddenAddressItem` check
 *   2. Specific form items that should not be in the eval note
 */
export const isQuestionToIgnore = (questionKey: string): boolean =>
  isHiddenAddressItem(questionKey) ||
  (
    [
      GeneralInformationQuestionKeys.FORMER_FIRST_NAME,
      GeneralInformationQuestionKeys.FORMER_MIDDLE_NAME,
      GeneralInformationQuestionKeys.FORMER_LAST_NAME,
      GeneralInformationQuestionKeys.ADDRESS,
      GeneralInformationQuestionKeys.ADDRESS_UNIT_NUMBER,
      GeneralInformationQuestionKeys.ADDRESS_OBJECT,
      GeneralInformationQuestionKeys.PHONE,
      GeneralInformationQuestionKeys.PHONE_TYPE,
      GeneralInformationQuestionKeys.EMERGENCY_CONTACT_PHONE,
      GeneralInformationQuestionKeys.EMERGENCY_CONTACT_EMAIL,
      HealthcareProviderSubForm.HEALTHCARE_PROVIDER_ADDRESS,
      HealthcareProviderSubForm.HEALTHCARE_PROVIDER_ADDRESS_UNIT_NUMBER,
      HealthcareProviderSubForm.HEALTHCARE_PROVIDER_ADDRESS_OBJECT,
      HealthcareProviderSubForm.HEALTHCARE_PROVIDER_PHONE,
      HealthcareProviderSubForm.HEALTHCARE_PROVIDER_EMAIL,
      OtherInformationQuestionKeys.HEAR_ABOUT_CLINIC,
      OtherInformationQuestionKeys.REFERRING_CLINICIAN_PHONE,
      OtherInformationQuestionKeys.REFERRING_CLINICIAN_EMAIL,
    ] as string[]
  ).includes(questionKey)

/**
 * Conditional: checks if a form item should be followed with a double-space
 */
export const isFollowedByDoubleSpace = (questionKey: string): boolean =>
  ([MedHistoryNonPsychQuestionKeys.OTHER_CONDITIONS] as string[]).includes(
    questionKey
  )

/**
 * Conditional: checks if a form item should be preceded with a double-space
 */
export const isPrecededByDoubleSpace = (questionKey: string): boolean =>
  (
    [
      PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_KETAMINE_TREATMENT,
      PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_THERAPY_TREATMENT,
      PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_TMS_TREATMENT,
      PsychiatricHistoryQuestionKeys.HAS_PREVIOUS_ECT_TREATMENT,
      PsychiatricHistoryQuestionKeys.OTHER_MENTAL_HEALTH_SERVICES,
      PsychiatricHistoryQuestionKeys.OTHER_MENTAL_HEALTH_SERVICES,
    ] as string[]
  ).includes(questionKey)

/**
 * Conditional: checks if a form item's answer is null/undefined or an empty string
 */
export const isEmptyValue = (answer: unknown) =>
  answer == undefined || answer === ''

/**
 * Callback for an array map to iterate through a list of words, and check if the last word is plural. If it
 * is and it could easily be modified, then turn the plural word singular
 */
export const pluralWordsToSingular = (
  word: string,
  i: number,
  allWords: string[]
) => {
  const isPluralWord =
    i + 1 === allWords.length && // only last word
    word.slice(-1) === 's' && // check if last letter of last word ends in "s"
    !['es', 'ss'].includes(word.substring(word.length - 2)) // use a lib if we need to convert more advanced plural words

  return (word = isPluralWord ? word.replace(/.$/, '') : word)
}

/**
 * Converts boolean to Yes/No
 * - true => 'Yes'
 * - false => 'No'
 */
export const booleanToString = (answer: boolean): string => {
  return `${answer ? 'Yes' : 'No'}`
}

/**
 * Converts the address object into a string
 */
export const addressObjectToString = (answer: unknown): string => {
  try {
    const addressObject = answer as {
      Address1?: string
      Address2?: string
      City?: string
      State?: string
      Zipcode?: string
    }
    if (!answer || typeof answer !== 'object') {
      return ''
    }

    return `Address: ${
      [
        addressObject.Address1,
        addressObject.Address2,
        addressObject.City,
        addressObject.State,
      ]
        .filter(Boolean)
        .join(', ') + ` ${addressObject.Zipcode}`
    }\n`
  } catch (err) {
    console.error('addressObjectToString failed with', err)
    return ''
  }
}

/**
 * Converts a nested object (form subsection) into a string
 */
export const subSectionObjectReducer = (
  outputString: string,
  [questionKey, value]: [string, unknown]
): string => {
  try {
    const questionLabel = INTAKE_REVIEW_FORM_MAP[questionKey]?.title

    if (isQuestionToIgnore(questionKey)) {
      // Undefined, null or '' values, hidden questions or missing question titles
      return outputString
    }

    // Use this if we ever want to display an address nested in a form sub section
    // if (isViewableAddress(questionKey)) {
    //   // Address objects to readable string
    //   return outputString + `   ${addressObjectToString(value)}`
    // }

    if (!questionLabel) {
      console.warn(
        `INTAKE_REVIEW_FORM array is missing question title for ${questionKey}`
      )
      return outputString
    }

    if (Array.isArray(value)) {
      // Array of strings
      return (outputString = outputString.replace(
        questionKey,
        value.join(', ')
      ))
    } else if (typeof value === 'string' && moment(value, true).isValid()) {
      // Dates
      return (outputString = outputString.replace(
        questionKey,
        format(parseISO(value), 'P')
      ))
    } else if (typeof value === 'boolean') {
      // Booleans
      return (outputString = outputString.replace(
        questionKey,
        booleanToString(value)
      ))
    } else if (typeof value === 'string') {
      // strings
      return (outputString = outputString.replace(questionKey, value ?? ''))
    } else if (typeof value === 'number') {
      // numbers
      return (outputString = outputString.replace(
        questionKey,
        value.toString()
      ))
    }

    return outputString
  } catch (err) {
    console.error(`subSectionObjectReducer failed with`, err)
    return ''
  }
}

/**
 * Output a string structured as bullet list
 * ```
 * Header
 *   - Item 1
 *   - Item 2
 *   - ...
 * ```
 */
export const outputBulletList = (header: string, answer: unknown): string => {
  try {
    if (!Array.isArray(answer) || !answer.length) {
      return ''
    }

    return answer.reduce<string>((outputString, val) => {
      if (isEmptyValue(val)) {
        return outputString
      }

      if (typeof val === 'string' || typeof val === 'number') {
        outputString += bulletCharacter(true)
        outputString += val.toString()
        return `${outputString}\n`
      }

      return outputString
    }, `${header}\n`)
  } catch (err) {
    console.error(`outputBulletList failed with`, err)
    return ''
  }
}

/**
 * Output a string with items listed with their number
 * ```
 * Header
 *
 * - Item #1
 *    Answer1
 *    Answer2
 *
 * - Item #2
 *    Answer1
 *    Answer2
 * ...
 * ```
 */
export const outputSubSectionList = (
  header: string,
  answer: unknown,
  questionKey: string
) => {
  try {
    const subSectionHeader = `\n${header}\n\n`

    if (!answer) {
      // Answer doesn't exist, return a template to be filled out
      return subSectionHeader + emptyIntakeResponseDefaults[questionKey] ?? ''
    }

    if (!Array.isArray(answer)) {
      return ''
    }

    return answer.reduce<string>((outputSubSection, answerObject, index) => {
      if (!answerObject) {
        return outputSubSection
      }

      const subHeading = header.split(' ').map(pluralWordsToSingular).join(' ')

      // Start with a template unpopulatedSubFormItems and replace the question key for each item
      // with the actual answer value
      let subFormList = Object.entries(answerObject).reduce<string>(
        subSectionObjectReducer,
        `${bulletCharacter(false)}${subHeading} #${index + 1}\n` +
          (unpopulatedSubFormItems[questionKey] ?? '')
      )

      // If a question did not have an answer, a question key remains which should be replaced with an empty string
      const subFormItemKeys = subSectionItemKeys[questionKey] ?? []
      subFormItemKeys.forEach((subSectionKey) => {
        subFormList = subFormList.replace(subSectionKey, '')
      })

      if (subFormList.length) {
        outputSubSection += `${subFormList}\n`
      }
      return outputSubSection
    }, subSectionHeader)
  } catch (err) {
    console.error('outputSubSectionList failed with', err)
    return ''
  }
}

/**
 * Outputs the following string format from a medications array:
 *
 * ```
 * Header
 *
 * - Medication A
 *   Property1: Answer1
 *   Property2: Answer2
 *
 * - Medication B
 *   Property1: Answer1
 *   Property2: Answer2
 * ...
 * ```
 */
export const outputMedicationList = (
  answer: unknown,
  questionKey: string
): string => {
  try {
    const medsListIsObject =
      typeof answer === 'object' && answer && !Array.isArray(answer)
    const medListIsArray = Array.isArray(answer) && answer.length

    if (!answer || (medsListIsObject && Object.keys(answer).length === 0)) {
      // Answer doesn't exist
      return emptyIntakeResponseDefaults[questionKey] ?? ''
    }

    if (!medsListIsObject && !medListIsArray) {
      return ''
    }

    let medList = []

    if (medListIsArray) {
      medList = answer
    }
    if (medsListIsObject) {
      medList = Object.entries(answer)
    }

    if (!medList.length) {
      return ''
    }

    return medList.reduce<string>((outputSection, medication) => {
      let details

      if (medListIsArray) {
        details = medication
      } else if (medsListIsObject) {
        details = {
          MED_NAME: medication[0],
          ...medication[1],
        }
      }

      if (!details || typeof details !== 'object') {
        return outputSection
      }

      // Start with a template unpopulatedSubFormItems and replace the question key for each item with the actual answer value
      let medDetails = Object.entries(details).reduce<string>(
        subSectionObjectReducer,
        unpopulatedSubFormItems[questionKey] ?? ''
      )

      // If a question did not have an answer, a question key remains which should be replaced with an empty string
      const subFormItemKeys = subSectionItemKeys[questionKey] ?? []
      subFormItemKeys.forEach((subSectionKey) => {
        if (subSectionKey === MedicationsSubFormQuestionKeys.FREQUENCY) {
          // this needs to be done first, otherwise 'FREQUENCY' will override
          // 'FREQUENCY_OPTIONAL_DETAIL' and leave it as '_OPTIONAL_DETAIL' :,)
          medDetails = medDetails.replace(
            MedicationsSubFormQuestionKeys.FREQUENCY_OPTIONAL_DETAIL,
            ''
          )
        }
        medDetails = medDetails.replace(subSectionKey, '')
      })

      const hiddenEndOfMed =
        conditionallyRenderedFields[PsychHistoryMedsQuestionKeys.END_OF_MED]
      medDetails = medDetails.replace(hiddenEndOfMed, '')

      if (medDetails.length) {
        outputSection += medDetails
      }

      return outputSection
    }, '')
  } catch (err) {
    console.error('outputMedicationList failed with', err)
    return ''
  }
}

/*
 * Output editor contents for bullet list
 */
export const updateBulletList = (header: string, answer: unknown): Object[] => {
  try {
    if (!Array.isArray(answer)) {
      return []
    }

    return answer.reduce<Object[]>((contents, val) => {
      if (isEmptyValue(val)) {
        return contents
      }
      if (typeof val === 'string' || typeof val === 'number') {
        createListItemNode(contents, val.toString())
      }
      return contents
    }, [])
  } catch (err) {
    console.error(`updateBulletList failed with`, err)
    return []
  }
}

/*
 * Output editor contents for subsection list
 */
export const updateSubSectionList = (
  header: string,
  answer: unknown,
  questionKey: string
): Object[] => {
  try {
    if (!answer || !Array.isArray(answer)) {
      return []
    }
    const subHeading = header.split(' ').map(pluralWordsToSingular).join(' ')
    const updateContents = answer.reduce<Object[]>(
      (contents, answerObject, index) => {
        let subFormList = Object.entries(answerObject).reduce<string>(
          subSectionObjectReducer,
          unpopulatedSubFormItems[questionKey] ?? ''
        )
        const subFormItemKeys = subSectionItemKeys[questionKey] ?? []
        subFormItemKeys.forEach((subSectionKey) => {
          subFormList = subFormList.replace(subSectionKey, '')
        })
        const subFormContents = subFormList
          .split('\n')
          .slice(0, -1)
          .map((str) => {
            const node = {
              type: 'listItem',
              content: [],
            }
            createKeyValueNode(node.content, str.trim())
            return node
          })
        createListItemNode(
          contents,
          `${subHeading} #${index + 1}`,
          subFormContents
        )
        return contents
      },
      []
    )
    return updateContents
  } catch (err) {
    console.error('updateSubSectionList failed with', err)
    return []
  }
}
/*
 * Output editor contents for medication list
 */
export const updateMedicationList = (
  answer: unknown,
  questionKey: string
): Object[] => {
  try {
    const medsListIsObject =
      typeof answer === 'object' && answer && !Array.isArray(answer)
    const medListIsArray = Array.isArray(answer) && answer.length

    if (!answer || (medsListIsObject && Object.keys(answer).length === 0)) {
      // Answer doesn't exist
      return []
    }

    if (!medsListIsObject && !medListIsArray) {
      return []
    }

    let medList = []

    if (medListIsArray) {
      medList = answer
    }
    if (medsListIsObject) {
      medList = Object.entries(answer)
    }

    if (!medList.length) {
      return []
    }

    return medList.reduce<Object[]>((contents, medication) => {
      let details

      if (medListIsArray) {
        details = medication
      } else if (medsListIsObject) {
        details = {
          MED_NAME: medication[0],
          ...medication[1],
        }
      }

      if (!details || typeof details !== 'object') {
        return contents
      }

      // Start with a template unpopulatedSubFormItems and replace the question key for each item with the actual answer value
      let medDetails = Object.entries(details).reduce<string>(
        subSectionObjectReducer,
        unpopulatedSubFormItems[questionKey] ?? ''
      )

      // If a question did not have an answer, a question key remains which should be replaced with an empty string
      const subFormItemKeys = subSectionItemKeys[questionKey] ?? []
      subFormItemKeys.forEach((subSectionKey) => {
        if (subSectionKey === MedicationsSubFormQuestionKeys.FREQUENCY) {
          // this needs to be done first, otherwise 'FREQUENCY' will override
          // 'FREQUENCY_OPTIONAL_DETAIL' and leave it as '_OPTIONAL_DETAIL' :,)
          medDetails = medDetails.replace(
            MedicationsSubFormQuestionKeys.FREQUENCY_OPTIONAL_DETAIL,
            ''
          )
        }
        medDetails = medDetails.replace(subSectionKey, '')
      })

      const hiddenEndOfMed =
        conditionallyRenderedFields[PsychHistoryMedsQuestionKeys.END_OF_MED]
      medDetails = medDetails.replace(hiddenEndOfMed, '')

      const list = medDetails.split('\n').slice(1)
      const listContent = medDetails
        .split('\n')
        .slice(2, -1)
        .map((str) => {
          const node = {
            type: 'listItem',
            content: [],
          }
          createKeyValueNode(node.content, str.trim())
          return node
        })
      createListItemNode(contents, `${list[0].replace('- ', '')}`, listContent)
      return contents
    }, [])
  } catch (err) {
    console.error('updateMedicationList failed with', err)
    return []
  }
}
