// Import react
import React, { useState } from 'react'

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Import libs/other
import { API } from 'aws-amplify'
// Import components
import { Button, Col, Form, Modal } from 'react-bootstrap'

import { onError } from '../../libs/errorLib'
import { useFormFields } from '../../libs/hooksLib'
import { notification } from '../../libs/notificationLib'

// Import styles
import './MedicationForms.scss'

const RetireAndMigrateToPastMedication = (props) => {
  const [show, setShow] = useState(false)
  const [medicationId] = useState(
    props.medicine.ActiveMedicationId || props.medicine.PastMedicationId
  )
  const [patientId] = useState(props.medicine.PatientId)

  const [providerEndDate, setProviderEndDate] = useState('')

  const [fields, handleFieldChange] = useFormFields({
    ReasonDiscontinued: '',
  })

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  function putRetireActiveMed(data) {
    data.UpdateEnum = 'RetireActive'
    return API.post('api', '/meds', {
      body: data,
    })
  }

  function putNewPastMedication(data) {
    data.UpdateEnum = 'NewPast'
    return API.post('api', '/meds', {
      body: data,
    })
  }

  async function handleRetire(event) {
    event.preventDefault()

    try {
      await putRetireActiveMed({
        ActiveMedicationId: medicationId,
        PatientId: patientId,
      })
      await putNewPastMedication({
        PatientId: patientId,
        Administration: props.medicine.Administration,
        DateRange: `${props.medicine.StartDate.toString()} - `,
        Dosage: props.medicine.Dosage,
        AdministrationIntervalInDays:
          props.medicine.AdministrationIntervalInDays,
        MedicationName: props.medicine.MedicationName,
        PrescribedByProvider: props.medicine.PrescribedByProvider,
        AdministrationOther: props.medicine.AdministrationOther,
        SideEffects: props.medicine.SideEffects,
        MentalPsychiatricConditions: props.medicine.MentalPsychiatricConditions,
        ReasonForUse: props.medicine.ReasonForUse,
        PatientPerspectiveEfficacy: props.medicine.PatientPerspectiveEfficacy,
        ReasonDiscontinued: fields.ReasonDiscontinued,
        Comments: props.medicine.Comments,
        Frecuency: props.medicine.Frecuency,
        ProviderEndDate: providerEndDate,
        ProviderStartDate: props.medicine.ProviderStartDate,
        originDate: props.medicine.originDate,
      })

      props.handleApiChange()

      notification(
        `You have successfully retired ${props.medicine.MedicationName} from active to past medication.`,
        'success'
      )
      setShow(false)
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
  }

  return (
    <span>
      <Button
        style={{ display: 'contents' }}
        bsPrefix="button-two larger right"
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faTrash} />
        Retire
      </Button>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Retire{' '}
            <span className="modal-header-blue">
              {props.medicine.MedicationName}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label
                  className="form-label"
                  style={{ fontWeight: 'bold' }}
                >
                  End Date:
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  onChange={(e) => setProviderEndDate(e.target.value)}
                  placeholder="MM/YYYY"
                  value={providerEndDate}
                  type="text"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="ReasonDiscontinued">
                <Form.Label
                  className="form-label"
                  style={{ fontWeight: 'bold' }}
                >
                  Reason discontinued:
                </Form.Label>
                <Form.Control
                  bsPrefix="form-input"
                  onChange={handleFieldChange}
                  as="textarea"
                  rows={1}
                  value={fields.ReasonDiscontinued}
                />
              </Form.Group>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button bsPrefix="button-block" onClick={handleRetire}>
              <i className="fa fa-minus-circle" />
              &nbsp; Retire
            </Button>
            <Button bsPrefix="button-close" onClick={handleClose}>
              <i className="fa fa-times" />
              &nbsp; Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </span>
  )
}

export default RetireAndMigrateToPastMedication
