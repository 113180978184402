import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const StrokeCodes: any = [
  {
    '230690007': {
      title: 'Cerebrovascular Disease (Including Stroke)',
      description: 'Cerebrovascular accident (disorder)',
      snomedcode: 230690007,
      icdcode: 'I639',
      icddescription: 'Cerebral infarction, unspecified',
    },
  },
  {
    '75543006': {
      title: 'cvd.Cerebral Embolism (Clot-Type Stroke)',
      description: 'Cerebral embolism (disorder)',
      snomedcode: 75543006,
      icdcode: 'I669',
      icddescription: 'Occlusion and stenosis of unspecified cerebral artery',
    },
  },
  {
    '1386000': {
      title: 'cvd.Intracranial Hemorrhage (Bleeding-Type Stroke)',
      description: 'Intracranial hemorrhage (disorder)',
      snomedcode: 1386000,
      icdcode: 'I629',
      icddescription: 'Nontraumatic intracranial hemorrhage, unspecified',
    },
  },
  {
    '230690007': {
      title: 'cvd.Stroke',
      description: 'Cerebrovascular accident (disorder)',
      snomedcode: 230690007,
      icdcode: 'I639',
      icddescription: 'Cerebral infarction, unspecified',
    },
  },
  {
    '266257000': {
      title: 'cvd.Transient Ischemic Attack (TIA)',
      description: 'Transient ischemic attack (disorder)',
      snomedcode: 266257000,
      icdcode: 'G459',
      icddescription: 'Transient cerebral ischemic attack, unspecified',
    },
  },
]

export const StrokeDropDownValues = constructPatientDemographicObject(
  StrokeCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
