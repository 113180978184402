import React from 'react'

import { Form } from 'react-bootstrap'

import { NoteTypes, UserFacingNoteTypes } from '../../../../../shared-types'

interface NoteTypeSelectProps {
  value?: string
  disabled?: boolean
  handleFieldChange: (obj: any) => void
}

export default function NoteTypeSelect(props: NoteTypeSelectProps) {
  const handleOnChange = (event: React.BaseSyntheticEvent) => {
    props.handleFieldChange(event.target.value)
  }

  return (
    <>
      <Form.Label style={{ fontWeight: 'bold' }}>Note Type</Form.Label>
      <Form.Control
        disabled={Boolean(props.disabled)}
        bsPrefix="form-input"
        as="select"
        onChange={handleOnChange}
        value={props.value || ''}
      >
        <option key={0} disabled value="">
          Select Note Type
        </option>
        {Object.values(NoteTypes)
          .filter((noteType) => noteType !== NoteTypes.NOTE_V2)
          .map((type, index) => {
            // Change the name of clinical note here to display Simple Clinical Note
            const name = UserFacingNoteTypes[type]
            return (
              <option key={index + 1} value={type}>
                {name}
              </option>
            )
          })}
      </Form.Control>
    </>
  )
}
