import { Badge } from 'antd'
import cx from 'classnames'

import styles from './HeaderItem.module.scss'

export type HeaderItemProps = {
  label: string
  count: number
}
export const HeaderItem = ({ label, count }: HeaderItemProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.headerItemContainer}>{label}</p>
      <Badge className={cx(styles.countContainer, 'responsiveCount')}>
        <span className={styles.countValue}>{count > 99 ? '99+' : count}</span>
      </Badge>
    </div>
  )
}
