import React, { useEffect, useState } from 'react'

import { Form, FormInstance, Input } from 'antd'

import { underscores } from '../../../libs/regex'
import {
  Frequency,
  FrequencyOptionalDetail,
} from '../../../shared/AllergiesMedications'
import {
  Divider,
  DynamicSubForm,
  Radio,
  Select,
  TextArea,
  Typography,
} from '../../BaseComponents'
import {
  CHARACTER_LIMIT,
  normalizeText,
  optionalFreeText,
  required,
  requiredString,
} from '../validation-rules'
import { Header } from './helpers'
import {
  AllergiesMedicationsQuestionKeys,
  MedicationsSubFormQuestionKeys,
} from './question-keys'

import styles from './_shared.module.scss'

interface Props {
  form: FormInstance
  reviewMode?: boolean
}

const { Text, Title } = Typography

const AllergiesAndMedication = ({ form, reviewMode }: Props) => {
  const [hasAllergies, setHasAllergies] = useState(false)
  const [isTakingMedication, setIsTakingMedication] = useState(false)

  useEffect(() => {
    if (isTakingMedication) return
    form.setFields([
      {
        name: AllergiesMedicationsQuestionKeys.CURRENT_MEDICATIONS,
        value: [],
      },
    ])
  }, [isTakingMedication])

  const FrequencyValues = Object.keys(Frequency).map((option) => {
    const prettyValue = option
      .toString()
      .toLowerCase()
      .replace(underscores, ' ')
    return { label: prettyValue, value: prettyValue }
  })
  const OptionalFrequencyValues = Object.keys(FrequencyOptionalDetail).map(
    (option) => {
      const prettyValue = option
        .toString()
        .toLowerCase()
        .replace(underscores, ' ')
      return { label: prettyValue, value: prettyValue }
    }
  )

  return (
    <>
      <Title level={2}>Allergies & Medications</Title>
      <Divider />
      <Header title="Allergies" />
      <Form.Item
        label="Do you have any allergies?"
        name={AllergiesMedicationsQuestionKeys.HAS_ALLERGIES}
        rules={[required('Please select an option.')]}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          size="large"
          onChange={(event) => setHasAllergies(event.target.value)}
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {(hasAllergies || reviewMode) && (
        <Form.Item
          label="Please list any medications, foods or other substances that you have had an allergic reaction to. Describe if this is a current or past allergy, what the reaction is and the severity of the reaction."
          name={AllergiesMedicationsQuestionKeys.ALLERGIES}
          required={hasAllergies}
          rules={
            hasAllergies
              ? [
                  requiredString(
                    'Please provide information about your allergies'
                  ),
                ]
              : [
                  optionalFreeText(
                    'Please provide information about your allergies'
                  ),
                ]
          }
          normalize={normalizeText}
        >
          <TextArea
            disabled={reviewMode}
            maxLength={CHARACTER_LIMIT}
            showCount
            size="large"
            rows={4}
          />
        </Form.Item>
      )}
      <Divider />
      <Header
        title="Medications"
        description='Please include any medications you are currently taking for any reason, including prescribed and over-the counter medications and supplements. Note that "dosage" refers to how much you take at one time, not the total daily dose if taken more than once per day.'
      />
      <Form.Item
        label="Are you currently taking any medications?"
        name={AllergiesMedicationsQuestionKeys.HAS_MEDICATIONS}
        rules={[required('Please select an option.')]}
      >
        <Radio.Group
          buttonStyle="solid"
          disabled={reviewMode}
          onChange={(event) => setIsTakingMedication(event.target.value)}
          size="large"
        >
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {(isTakingMedication || reviewMode) && (
        <DynamicSubForm
          addButtonText="Add a medication"
          reviewMode={reviewMode}
          buttonSize="large"
          name={AllergiesMedicationsQuestionKeys.CURRENT_MEDICATIONS}
          initialValues={[
            {
              [MedicationsSubFormQuestionKeys.MED_NAME]: null,
              [MedicationsSubFormQuestionKeys.DOSAGE]: null,
              [MedicationsSubFormQuestionKeys.FREQUENCY]: null,
              [MedicationsSubFormQuestionKeys.FREQUENCY_OPTIONAL_DETAIL]: null,
              [MedicationsSubFormQuestionKeys.REASONS_FOR_USE]: null,
            },
          ]}
          subForm={({ name = '', restField = {}, index = 0 }) => (
            <>
              <Title level={5}>Medication #{index + 1}</Title>
              <Form.Item
                {...restField}
                name={[name, MedicationsSubFormQuestionKeys.MED_NAME]}
                label="Medication name"
                rules={[requiredString('Please provide the medication name')]}
                normalize={normalizeText}
              >
                <Input disabled={reviewMode} size="large" />
              </Form.Item>
              <Text type="secondary">Generic or brand name</Text>
              <Form.Item
                {...restField}
                name={[name, MedicationsSubFormQuestionKeys.DOSAGE]}
                label="Dosage with units"
                rules={[optionalFreeText('Please input valid dosage')]}
                normalize={normalizeText}
              >
                <Input
                  disabled={reviewMode}
                  size="large"
                  className={styles.shortenedInput}
                />
              </Form.Item>
              <Text type="secondary">e.g. 10 mg</Text>
              <Form.Item
                {...restField}
                name={[name, MedicationsSubFormQuestionKeys.FREQUENCY]}
                label="Frequency"
                rules={[required('Please select an option.')]}
              >
                <Select
                  disabled={reviewMode}
                  options={FrequencyValues}
                  size="large"
                  showSearch
                  placeholder="Select one"
                  allowClear
                />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[
                  name,
                  MedicationsSubFormQuestionKeys.FREQUENCY_OPTIONAL_DETAIL,
                ]}
                label="Additional frequency detail"
              >
                <Select
                  disabled={reviewMode}
                  options={OptionalFrequencyValues}
                  size="large"
                  showSearch
                  placeholder="Select one"
                  allowClear
                />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, MedicationsSubFormQuestionKeys.REASONS_FOR_USE]}
                label="Reason(s) for taking this medication"
                rules={[
                  requiredString(
                    'Please provide details for taking this medication'
                  ),
                ]}
                normalize={normalizeText}
              >
                <TextArea
                  disabled={reviewMode}
                  maxLength={CHARACTER_LIMIT}
                  showCount
                  size="large"
                />
              </Form.Item>
            </>
          )}
        />
      )}
      <Divider />
    </>
  )
}

export default AllergiesAndMedication
