import React, { useState } from 'react'

import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { Link } from 'react-router-dom'

import { useReadyForReviewLabs } from '../../hooks/useReadyToReviewLabs'
import { useSchedulingNotifications } from '../../hooks/useSchedulingNotifications'
import { useUnsignedSpravatoNotifications } from '../../hooks/useUnsignedSpravatoNotifications'
import { Badge, Menu } from '../../stories/BaseComponents'
import { generateKey, getItemClassName } from './Menu.helpers'
import { MenuIcon } from './MenuIcon'
import { getIcon } from './MenuIcon/MenuIcon'
import { MenuProps, Option } from './types'

import './Menu.scss'

export function hasLeftIndicator({
  icon,
  title,
  hasLabsReportReview,
  totalSchedulingNotifications,
  totalUnreadSpravatoNotes,
}: {
  icon: string
  title: string | undefined
  hasLabsReportReview: boolean | undefined
  totalSchedulingNotifications: number
  totalUnreadSpravatoNotes: number
}): boolean {
  return (
    (hasLabsReportReview && icon === 'ExperimentOutlined') ||
    (totalSchedulingNotifications > 0 && title === 'Schedule') ||
    (totalUnreadSpravatoNotes > 0 && title === 'Reports')
  )
}

export default function GenericMenu({
  className = '',
  id = '',
  isVisible = true,
  mode = 'vertical',
  onClick,
  options = [],
  theme = 'light',
  selectedItem,
}: MenuProps) {
  const hasLabsReportReview = useReadyForReviewLabs().data
  const { totalNotifications: totalSchedulingNotifications } =
    useSchedulingNotifications({
      appointmentStatuses: ['PENDING'],
    })
  const { totalUnreadSpravatoNotes } = useUnsignedSpravatoNotifications()
  const [current, setCurrent] = useState<string>(selectedItem?.key || '')

  const generateMenuItems = ({
    className = '',
    hasNotification = false,
    icon = '',
    id = '',
    isDivider = false,
    isSelected = false,
    key,
    link = '',
    notificationCount = undefined,
    onClick,
    shouldDisable = false,
    title,
    isExternalLink = false,
    rightIcon = undefined,
  }: Option) => {
    // not in a separate component due to styling issues

    let content: React.ReactElement | string = title || ''
    // If the route is patients, we want to reload the page everytime the
    // user navigates so that we can pick up new chart information.
    // TODO: Have better state management

    content = link ? <Link to={link}>{title}</Link> : <span>{title}</span>

    if (isExternalLink) {
      content = (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      )
    }

    if (rightIcon) {
      content = (
        <div className="ant-menu-item-right-icon-container">
          {content}
          {rightIcon.icon(
            rightIcon.showOnHover ? 'ant-menu-item-right-icon' : undefined
          )}
        </div>
      )
    }

    let menuItemContent = content
    if (hasNotification || notificationCount) {
      menuItemContent = (
        <Badge
          className="ant-menu-item-notification"
          count={notificationCount}
          dot={!notificationCount}
        >
          {content}
        </Badge>
      )
    }

    key = key ?? generateKey({ id, mode, title, isExternalLink })
    const menuItem: ItemType = {
      icon: (
        <MenuIcon
          icon={getIcon(icon)}
          hasTopLeftIndicator={
            (hasLabsReportReview && icon === 'ExperimentOutlined') || false
          }
          hasLeftIndicator={hasLeftIndicator({
            icon,
            title,
            hasLabsReportReview,
            totalSchedulingNotifications,
            totalUnreadSpravatoNotes,
          })}
        />
      ),
      key: key,
      className: getItemClassName({ className, isSelected, link }),
      onClick: onClick,
      disabled: shouldDisable,
      label: menuItemContent,
    }

    return isDivider
      ? {
          type: 'divider',
          key: key,
        }
      : menuItem
  }

  function onNavBarChange(e: any) {
    if (e.key.includes('-is-external-link')) {
      return
    }
    setCurrent(e.key)
  }

  const generateMenu = (options: Array<Option>) => {
    return options.map((option: Option) => {
      const { icon = '', id = '', isSelected, key, subMenu, title } = option
      if (subMenu) {
        return {
          label: <span>{title}</span>,
          key: key ?? generateKey({ id, mode, title }),
          className: getItemClassName({ className, isSelected }),
          icon: (
            <MenuIcon
              icon={getIcon(icon)}
              hasTopLeftIndicator={
                (hasLabsReportReview && icon === 'ExperimentOutlined') || false
              }
              hasLeftIndicator={
                (hasLabsReportReview && icon === 'LineChartOutlined') ||
                (totalSchedulingNotifications > 0 && title === 'Schedule')
              }
            />
          ),
          title,
          children: subMenu.map(generateMenuItems),
        }
      }
      return generateMenuItems(option)
    })
  }

  return (
    <Menu
      hidden={!isVisible}
      className={`${className} ant-menu-dropdown-${mode}`}
      defaultSelectedKeys={[]}
      selectedKeys={[current]}
      id={id}
      mode={mode}
      onClick={onClick}
      onSelect={onNavBarChange}
      theme={theme}
      items={generateMenu(options)}
    />
  )
}
