import { datadogRum } from '@datadog/browser-rum'
import '@sendbird/chat/groupChannel'
// eslint-disable-next-line import/no-unresolved
import { SBUEventHandlers } from '@sendbird/uikit-react/types/lib/types'

import { updateChannelStatus } from '../../api/api-lib'
import { DATADOG_ACTION } from '../../v2/datadog/datadogActions'
import Sentry from '../sentry'

/**
 * Capture Sendbird connection errors
 */
export const getSendbirdProviderEventHandlers = (
  /**
   * NOTE: sendbirdUserId references ClinicID aka ProviderPrimaryID
   */
  sendbirdUserId: string
) =>
  ({
    connection: {
      onFailed: (e) => {
        if (!sendbirdUserId) {
          return
        }

        console.error('Received error when initializing sendbird', e)
        const regexForWsConnections = new RegExp(
          /Sendbird.*Too many Websocket connections/i
        )
        if (regexForWsConnections.exec(e.toString()) !== null) {
          /**
           * This is very similar to SendbirdInitializationError
           * - However, we need to be able to have a clear DD Monitor for this case
           * - Therefore we need a separate action
           *   since we can't easily filter DD Monitors on Custom Action properties
           *
           * Note that we are intentionally logging these Actions without gating on the FF
           * - this is to ensure that we capture these logs for all providers
           * - potential cost: too many DD Action logs leads to increased DD cost
           */
          datadogRum.addAction(
            DATADOG_ACTION.OsmindPatientMessaging
              .SendbirdWSConnectionLimitExceeded,
            {
              sendbirdUserId,
              error: e,
            }
          )
        }

        /**
         * Note that we are intentionally logging these Actions without gating on the FF
         * - this is to ensure that we capture these logs for all providers
         * - potential cost: too many DD Action logs leads to increased DD cost
         */
        datadogRum.addAction(
          DATADOG_ACTION.OsmindPatientMessaging.SendbirdInitializationError,
          {
            sendbirdUserId,
            error: e,
          }
        )
        Sentry.captureException(e, {
          tags: { sendbirdUser: sendbirdUserId },
        })
      },
    },
  } as SBUEventHandlers)

export type UnreadMessages = {
  count: number
  markedUnread: boolean
}

export async function persistMessagesAsUnread(channelUrl: string) {
  await updateChannelStatus(channelUrl, true)
  /**
   * Note that we are intentionally logging these Actions without gating on the FF
   * - this is to ensure that we capture these logs for all providers
   * - potential cost: too many DD Action logs leads to increased DD cost
   */
  datadogRum.addAction(
    DATADOG_ACTION.OsmindPatientMessaging.SetMessagesAsUnread,
    {
      channelUrl,
    }
  )
}

export async function persistMessagesAsRead(channelUrl: string) {
  await updateChannelStatus(channelUrl, false)
  /**
   * Note that we are intentionally logging these Actions without gating on the FF
   * - this is to ensure that we capture these logs for all providers
   * - potential cost: too many DD Action logs leads to increased DD cost
   */
  datadogRum.addAction(
    DATADOG_ACTION.OsmindPatientMessaging.SetMessagesAsRead,
    {
      channelUrl,
    }
  )
}
