import React, { useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import { cloneDeep } from 'lodash'

import SkeletonLoadingTransition from '../../../components/Animation/SkeletonLoadingTransition'
import { PatientConditionRelation } from '../../../hooks/usePatientProfile/shared-types'
import Fields, { FieldGroupProps } from '../../../stories/PatientProfile/Fields'
import {
  handlePatientConditionRelationUpdate,
  populatePatientconditionRelationInfo,
} from './InsuranceClaim-helper'
import { patientConditionRelationComponent } from './constants'

import styles from './InsuranceClaim.module.scss'

export interface PatientConditionRelationProps {
  testId?: string
  patientConditionRelation: PatientConditionRelation
  setPatientConditionRelation: Function
  disabled?: boolean
}

const Loading = () => (
  <div className={styles.skeletonContainer}>
    <Skeleton
      active
      title={{ width: 85 }}
      paragraph={{
        rows: 3,
        width: '100%',
        className: styles.skeletonParagraph,
      }}
    />
  </div>
)

const PatientConditionRelationWithClaim: React.FC<
  PatientConditionRelationProps
> = ({
  testId,
  patientConditionRelation,
  setPatientConditionRelation,
  disabled = false,
}) => {
  const [patientCR, setPatientCR] = useState<FieldGroupProps[]>(
    patientConditionRelationComponent
  )

  useEffect(() => {
    const itemsCopy = cloneDeep(patientConditionRelationComponent)
    if (!patientConditionRelation) return setPatientCR(itemsCopy)
    if (!patientConditionRelation.autoAccident) {
      const tempComponent = cloneDeep(itemsCopy[0].items[3])
      const firstPart = itemsCopy[0].items.slice(0, 2)
      itemsCopy[0].items = firstPart.concat(tempComponent)
    }
    populatePatientconditionRelationInfo(itemsCopy, patientConditionRelation)
    setPatientCR(itemsCopy)
  }, [patientConditionRelation])

  const handleSave = (
    newVal: any,
    id: string,
    _groupName: string,
    _label?: string
  ) => {
    const copypatientConditionRelation = cloneDeep(patientConditionRelation)
    handlePatientConditionRelationUpdate(
      newVal,
      id,
      copypatientConditionRelation
    )
    setPatientConditionRelation(copypatientConditionRelation)
  }

  return (
    <SkeletonLoadingTransition
      isLoading={false}
      skeletonComponent={<Loading />}
      loadedComponent={
        <div className={styles.topMargin}>
          <Fields
            testId={testId}
            items={patientCR}
            compact={false}
            handleSave={handleSave}
            isClaim
            disableAll={disabled}
          />
        </div>
      }
    />
  )
}

export default PatientConditionRelationWithClaim
