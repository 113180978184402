import React from 'react'

import { Button, Col, Form, Row } from 'react-bootstrap'

import LoaderButton from './LoaderButton'

interface ButtonsProps {
  cancelEditing: () => void
  isDisabled: boolean
  isEditing: boolean
  isLoading: boolean
  handleSubmit: (event: React.MouseEvent<HTMLElement>) => void
  switchToEdit: () => void
}

export default function PatientProfileButtons(props: ButtonsProps) {
  const {
    isDisabled = false,
    isEditing = false,
    isLoading = false,
    handleSubmit,
    cancelEditing,
    switchToEdit,
  } = props

  const saveButtons = (
    <>
      <Form.Group sm={12} md={5} lg={{ span: 4, offset: 2 }} as={Col}>
        <LoaderButton
          id="saveEditDemograficsProfile"
          bsPrefix="icon-button"
          isDisabled={isDisabled}
          isLoading={isLoading}
          loadingText="Saving"
          onClick={handleSubmit}
          data-testid="profile-save-general-info-button"
        >
          <i className="fa fa-floppy-o" aria-hidden="true" />
          &nbsp; Save notes
        </LoaderButton>
      </Form.Group>
      <Form.Group sm={12} md={5} as={Col}>
        <Button
          id="cancelEditDemograficsProfile"
          bsPrefix="button-close icon-button"
          onClick={cancelEditing}
        >
          <i className="fa fa-times" aria-hidden="true" />
          &nbsp; Cancel
        </Button>
      </Form.Group>
    </>
  )

  const editButtons = (
    <Col id="editDemograficsProfile">
      <Button
        bsPrefix="button-block icon-button"
        data-testid="profile-edit-general-info-button"
        onClick={switchToEdit}
      >
        <i className="fa fa-pencil-square-o" aria-hidden="true" />
        &nbsp; Edit record
      </Button>
    </Col>
  )

  return (
    <Row style={{ marginBottom: '14px' }}>
      {isEditing ? <>{saveButtons}</> : <>{editButtons}</>}
    </Row>
  )
}
