import {
  calculateAvailableCredit,
  calculatedOutstandingBalanceBillableEntity,
  convertCentsToDollars,
  filterSuccessful,
  formatNumberStringToUsdString,
} from '../../../libs/billing'
import { Invoice, PaymentAttempt, RefundAttempt } from '../../../shared-types'

import styles from './Billing.module.scss'

interface BillingSummaryCardsProps {
  payments: PaymentAttempt[]
  refunds: RefundAttempt[]
  invoices: Invoice[]
}

export default function BillingSummaryCards(props: BillingSummaryCardsProps) {
  const calculateTotalCollected = (payments: PaymentAttempt[]) => {
    let totalCollectedAmountCents = 0
    payments.filter(filterSuccessful).forEach((payment) => {
      totalCollectedAmountCents += payment.totalAmountCents

      if (payment.refundAttempts) {
        payment.refundAttempts
          .filter(filterSuccessful)
          .forEach((refund: RefundAttempt) => {
            totalCollectedAmountCents -= refund.totalAmountCents
          })
      }
    })

    return totalCollectedAmountCents
  }

  const getLastPaymentDate = (payments: PaymentAttempt[]) => {
    const successfulPayments = payments
      .filter(filterSuccessful)
      .sort((a, b) => {
        if (!a.updatedAt || !b.updatedAt) {
          return 0
        }

        if (a.updatedAt > b.updatedAt) {
          return -1
        } else if (a.updatedAt < b.updatedAt) {
          return 1
        } else {
          return 0
        }
      })

    return successfulPayments.length && successfulPayments[0].updatedAt
      ? new Date(successfulPayments[0].updatedAt)
      : null
  }

  const lastPaymentDate = getLastPaymentDate(props.payments)
  const mostRecentPaymentContent =
    lastPaymentDate === null
      ? '--'
      : lastPaymentDate.toLocaleDateString('en-us', {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
        })
  return (
    <div className={styles.summaryCardContainer}>
      <div className={styles.summaryCard}>
        <div className={styles.summaryCardDescription}>
          Available patient credit
        </div>
        <h4>
          {formatNumberStringToUsdString(
            convertCentsToDollars(calculateAvailableCredit(props.payments))
          )}
        </h4>
      </div>

      <div className={styles.summaryCard}>
        <div className={styles.summaryCardDescription}>
          Total outstanding - Invoices
        </div>
        <h4>
          {formatNumberStringToUsdString(
            convertCentsToDollars(
              calculatedOutstandingBalanceBillableEntity(props.invoices)
            )
          )}
        </h4>
      </div>

      <div className={styles.summaryCard}>
        <div className={styles.summaryCardDescription}>
          Total collected payment
        </div>
        <h4>
          {formatNumberStringToUsdString(
            convertCentsToDollars(calculateTotalCollected(props.payments))
          )}
        </h4>
      </div>

      <div className={styles.summaryCard}>
        <div className={styles.summaryCardDescription}>Last payment date</div>
        <h4>{mostRecentPaymentContent}</h4>
      </div>
    </div>
  )
}
