import { compareAsc } from 'date-fns'

function sortDates<T>(a: T, b: T, key: keyof T) {
  if (!a[key]) {
    return -1
  }
  if (!b[key]) {
    return 1
  }

  const dateA = new Date(a[key] as unknown as string | number | Date)
  const dateB = new Date(b[key] as unknown as string | number | Date)

  return compareAsc(dateA, dateB)
}

function sortNumbers<T>(a: T, b: T, key: keyof T) {
  if (!a[key]) {
    return -1
  }
  if (!b[key]) {
    return 1
  }

  return Number(a[key]) - Number(b[key])
}

export { sortDates, sortNumbers }
