import React, { useEffect } from 'react'

import { useFormikContext } from 'formik'

import { ChangePayersList } from '../../../../api/intakeForms'
import { Card } from '../../../../stories/BaseComponents'
import InlineField from '../InlineField'
import SectionToggle from '../SectionToggle'
import {
  DEFAULT_INSURANCE_TYPE_CODE,
  sectionContainerBodyStyle,
  sectionContainerStyle,
} from '../constants'
import { SECONDARY_INSURANCE } from '../field-constants'
import { ClaimForm, Option } from '../types'
import {
  getClaimFilingCodeForPayer,
  isMedicare,
  isSubscriberSelf,
} from '../utils'

import styles from './shared.module.scss'

const { TOGGLE, INSURANCE, SUBSCRIBER } = SECONDARY_INSURANCE

export const testIds = {
  container: 'claim-secondary-insurance',
}

type SecondaryInsuranceProps = {
  payers: {
    payerOptions: Option[]
    payersByName: { [index: string]: ChangePayersList }
  }
}

const SecondaryInsurance: React.FC<SecondaryInsuranceProps> = ({
  payers: { payerOptions, payersByName },
}) => {
  // current form values and touched status
  const { values, touched, setFieldValue, setValues } =
    useFormikContext<ClaimForm>()
  const {
    secondaryInsurance: {
      isIncluded,
      name,
      claimFilingCode,
      subscriberRelationship,
    },
  } = values
  const nameTouched = !!touched.secondaryInsurance?.name
  const subscriberRelationshipTouched =
    !!touched.secondaryInsurance?.subscriberRelationship
  const claimFilingCodeTouched = !!touched.secondaryInsurance?.claimFilingCode

  // dependent fields logic
  // autofill claimFilingCode and insuranceTypeCode when user selects a different insurance
  useEffect(() => {
    if (!nameTouched) {
      return
    }
    const newClaimFilingCode =
      name && payersByName
        ? getClaimFilingCodeForPayer(payersByName, name)
        : null
    setFieldValue('secondaryInsurance.claimFilingCode', newClaimFilingCode)

    const newInsuranceTypeCode = isMedicare(newClaimFilingCode)
      ? DEFAULT_INSURANCE_TYPE_CODE
      : null
    setFieldValue('secondaryInsurance.insuranceTypeCode', newInsuranceTypeCode)
  }, [name, nameTouched, payersByName])

  // autofill insuranceTypeCode when claimFilingCode changes
  useEffect(() => {
    if (!claimFilingCodeTouched) {
      return
    }
    const newInsuranceTypeCode = isMedicare(claimFilingCode)
      ? DEFAULT_INSURANCE_TYPE_CODE
      : null
    setFieldValue('secondaryInsurance.insuranceTypeCode', newInsuranceTypeCode)
  }, [claimFilingCode, claimFilingCodeTouched])

  // clear subscriber fields if patient is the subscriber
  useEffect(() => {
    if (!subscriberRelationshipTouched) {
      return
    }
    if (isSubscriberSelf(subscriberRelationship)) {
      const newValues: ClaimForm = {
        ...values,
        secondaryInsurance: {
          ...values.secondaryInsurance,
          subscriberFirstName: null,
          subscriberLastName: null,
          subscriberDateOfBirth: null,
        },
      }
      setValues(newValues)
    }
  }, [subscriberRelationship, subscriberRelationshipTouched])

  // rendering functions
  const renderInsuranceFields = () =>
    INSURANCE.map((item) => {
      if (item.name === 'secondaryInsurance.name') {
        return <InlineField {...item} key={item.name} options={payerOptions} />
      }
      if (
        item.name === 'secondaryInsurance.insuranceTypeCode' &&
        !isMedicare(claimFilingCode)
      ) {
        return null
      }
      return <InlineField {...item} key={item.name} />
    })

  const renderSubscriberFields = () =>
    SUBSCRIBER.map((item) => <InlineField {...item} key={item.name} />)

  // show/hide logic
  const isShowSubscriberSection =
    subscriberRelationship && !isSubscriberSelf(subscriberRelationship)

  return (
    <Card
      testId={testIds.container}
      style={sectionContainerStyle}
      bodyStyle={sectionContainerBodyStyle}
    >
      <div className={styles.section}>
        <div className={styles.header}>
          <span className={styles.title}>{'Secondary insurance'}</span>
        </div>
        <div>
          <SectionToggle {...TOGGLE} />
          {isIncluded && (
            <>
              {renderInsuranceFields()}
              {isShowSubscriberSection && renderSubscriberFields()}
            </>
          )}
        </div>
      </div>
    </Card>
  )
}

export default SecondaryInsurance
