import React from 'react'

import {
  Collapse as AntDCollapse,
  CollapsePanelProps as AntDCollapsePanelProps,
} from 'antd'

import { TestId } from '../../shared-types'

const { Panel: AntDPanel } = AntDCollapse

type CollapsePanelProps = AntDCollapsePanelProps & TestId

const CollapsePanel: React.FC<CollapsePanelProps> = (props) => (
  <AntDPanel {...props} />
)
export default CollapsePanel
