import { Tooltip } from '../../../../../../stories/BaseComponents'

export const MAX_TEMPLATE_NAME_LENGTH = 60
export const TemplateMenuItem = ({ name }: { name: string }) => {
  if (name.length < MAX_TEMPLATE_NAME_LENGTH) {
    return <>{name}</>
  }

  return (
    <Tooltip title={name} placement="left">
      {name.slice(0, MAX_TEMPLATE_NAME_LENGTH - 3)}...
    </Tooltip>
  )
}
