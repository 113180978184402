import React from 'react'

import { Tooltip as AntDTooltip, TooltipProps as AntDTooltipProps } from 'antd'

type TooltipProps = {} & AntDTooltipProps

const Tooltip = ({ children, ...props }: TooltipProps) => {
  return (
    <AntDTooltip color={'#000000'} {...props}>
      {children}
    </AntDTooltip>
  )
}

export default Tooltip
