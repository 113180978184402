import { useMemo } from 'react'

import { Editor } from '@tiptap/core'
import { Transaction } from '@tiptap/pm/state'
import { useHistory, useParams } from 'react-router-dom'
import { v4 } from 'uuid'

import { getNoteTemplateSettingsUrl } from '../../containers/Patient/ClinicalNotes/utils'
import { NavigationBar } from '../../v2/notes/Components/NavigationBar/NavigationBar'
import { CoreData } from '../../v2/notes/Components/RichTextEditor/CoreData/CoreData'
import {
  EditorContent,
  didEditorContentChange,
} from '../../v2/notes/Components/RichTextEditor/EditorContent/EditorContent'
import { DropdownSelectorItem } from '../../v2/notes/Components/RichTextEditor/EditorToolbar/DropdownSelector'
import { EditorToolbar } from '../../v2/notes/Components/RichTextEditor/EditorToolbar/EditorToolbar'
import { insertBlockInEditor } from '../../v2/notes/Components/RichTextEditor/EditorToolbar/utils'
import { BlockDeletionExtension } from '../../v2/notes/Components/RichTextEditor/Plugins/BlockDeletionExtension'
import { TrailingNode } from '../../v2/notes/Components/RichTextEditor/Plugins/TrailingNode'
import { TITLE_PLACEHOLDER_NAVIGATION } from '../../v2/notes/constants'
import { useRichTextEditor } from '../../v2/notes/hooks/editor/useRichTextEditor'
import { useGetNoteTemplate } from '../../v2/notes/hooks/templates/useGetNoteTemplate'
import { TemplateBlockNode } from './TemplateBlockNode'
import { useNoteTemplateAutosave } from './hooks/useNoteTemplateAutosave'

import styles from './NoteTemplateEditorPage.module.scss'

export function NoteTemplateEditorPage() {
  const { templateId } = useParams() as { templateId: string }
  const history = useHistory()

  const { data, isLoading } = useGetNoteTemplate(templateId)
  const { isSaving, hasPendingChanges, hasError, onChange, forceSave } =
    useNoteTemplateAutosave(templateId)

  const handleClickBack = () => {
    history.push(getNoteTemplateSettingsUrl())
  }

  const handleNavigateAway = async () => {
    await forceSave()
  }

  const triggerUpdate = ({
    editor,
    transaction,
  }: {
    editor: Editor
    transaction: Transaction
  }) => {
    if (didEditorContentChange(transaction)) {
      onChange(editor.getJSON())
    }
  }

  const { editor } = useRichTextEditor({
    extraExtensions: [
      TemplateBlockNode(),
      BlockDeletionExtension,
      TrailingNode,
    ],
    content: data && data.content ? data.content : '',
    isEditable: !isLoading,
    onBlur: triggerUpdate,
    onUpdate: triggerUpdate,
  })

  const insertMenuItems: DropdownSelectorItem[] = useMemo(() => {
    if (!editor) {
      return []
    }
    return [
      {
        key: 'active-medications',
        onClick: () => {
          const id = v4()
          insertBlockInEditor(
            editor,
            `<template-block type='MEDICATIONS' id='${id}'></template-block>`
          )
        },
        text: 'Active medications',
      },
      {
        key: 'active-allergies',
        onClick: () => {
          const id = v4()
          insertBlockInEditor(
            editor,
            `<template-block type='ALLERGIES' id='${id}'></template-block>`
          )
        },
        text: 'Active allergies',
      },
      {
        key: 'active-diagnoses',
        onClick: () => {
          const id = v4()
          insertBlockInEditor(
            editor,
            `<template-block type='DIAGNOSIS' id='${id}'></template-block>`
          )
        },
        text: 'Active diagnoses',
      },
    ]
  }, [editor])

  return (
    <div className={styles.mainContainer}>
      <div id="editor-nav" className={styles.topToolbars}>
        <NavigationBar
          title={data?.name ?? TITLE_PLACEHOLDER_NAVIGATION}
          hasError={hasError}
          isLoading={isLoading}
          isSaving={isSaving || hasPendingChanges}
          onBackClicked={handleClickBack}
          backButtonText={'Note Templates'}
          onNavigateAway={handleNavigateAway}
        />
        <EditorToolbar
          editor={editor ?? undefined}
          isLoading={isLoading}
          insertMenuItems={insertMenuItems}
        />
      </div>
      <div id="note-content" className={styles.contentContainer}>
        <div className={styles.editorWrapper}>
          <div className={styles.contentHeader}>
            <CoreData
              isLoading={isLoading}
              fields={{
                patient: {
                  type: 'blank',
                },
                dateOfVisit: {
                  type: 'blank',
                },
                startTime: {
                  type: 'blank',
                },
                location: {
                  type: 'blank',
                },
                renderingProvider: {
                  type: 'blank',
                },
              }}
            />
          </div>
          <div className={styles.contentWrapper}>
            <EditorContent isLoading={isLoading} editor={editor} />
          </div>
        </div>
      </div>
    </div>
  )
}
