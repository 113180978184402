import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { format, utcToZonedTime } from 'date-fns-tz'

import { getPatientSidePanelEvents } from '../../../api/api-lib-typed'
import { usePrevious } from '../../../hooks/usePrevious'
import { PatientSidePanelEvents, SidePanelEvent } from '../../../shared-types'
import { AppointmentInfo, AppointmentInfoProps } from './AppointmentInfo'

import styles from './Appointments.module.scss'

type AppointmentsProps = {
  patientId: string
}

const getAppointmentInfo = (
  event: SidePanelEvent
): NonNullable<AppointmentInfoProps['appointmentInfo']>[number] => {
  const zonedStartTime = utcToZonedTime(event.startTime, event.practiceTimeZone)
  const zonedEndTime = utcToZonedTime(event.endTime, event.practiceTimeZone)
  return {
    date: format(zonedStartTime, 'LLL d, y'),
    endTime: format(zonedEndTime, 'h:mmaaaaa', {
      timeZone: event.practiceTimeZone,
    }),
    startTime: format(zonedStartTime, 'h:mmaaaaa', {
      timeZone: event.practiceTimeZone,
    }),
    clinicians: event.eventProviders,
    room: event.roomName,
  }
}

const getScheduledEvents = (
  events?: PatientSidePanelEvents
): {
  next: Omit<AppointmentInfoProps, 'isLoading'>
  today: Omit<AppointmentInfoProps, 'isLoading'>
  last: Omit<AppointmentInfoProps, 'isLoading'>
} => {
  if (!events) {
    return {
      last: {
        title: 'Last',
      },
      next: {
        title: 'Next',
      },
      today: {
        title: 'Today',
      },
    }
  }

  return {
    last: {
      title: 'Last',
      appointmentInfo: events.lastAppointment
        ? [getAppointmentInfo(events.lastAppointment)]
        : undefined,
    },
    next: {
      title: 'Next',
      appointmentInfo: events.nextAppointment
        ? [getAppointmentInfo(events.nextAppointment)]
        : undefined,
    },
    today: {
      title: 'Today',
      appointmentInfo: events.todayAppointment?.map((today) =>
        getAppointmentInfo(today)
      ),
    },
  }
}
export const Appointments = ({ patientId }: AppointmentsProps) => {
  const { data, isLoading, isFetching } = useQuery(
    ['patientScheduledEvents'],
    () => getPatientSidePanelEvents(patientId, new Date())
  )
  const previousPatientId = usePrevious(patientId)
  const { last, next, today } = useMemo(() => getScheduledEvents(data), [data])
  const isUpdating =
    isLoading || (previousPatientId !== patientId && isFetching)

  return (
    <div className={styles.container}>
      <h5 className={styles.header}>Appointments</h5>
      <div className={styles.appointmentInfoContainer}>
        <AppointmentInfo
          title={today.title}
          appointmentInfo={today.appointmentInfo}
          isLoading={isUpdating}
        />
        <AppointmentInfo
          title={next.title}
          appointmentInfo={next.appointmentInfo}
          isLoading={isUpdating}
        />
        <AppointmentInfo
          title={last.title}
          appointmentInfo={last.appointmentInfo}
          isLoading={isUpdating}
        />
      </div>
    </div>
  )
}
