import { useState } from 'react'

import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'

import { TestId } from '../../shared-types'
import { EditableInput, EditableInputProps } from './EditableInputs'

import './ConfirmOrExitInput.scss'

type ConfirmOrExitInput = Omit<EditableInputProps<any>, 'onChange'> & {
  onConfirm: (data: string) => Promise<void>
  value?: string
} & TestId

const ConfirmOrExitInput = ({
  placeholder,
  onConfirm,
  value = '',
  validation,
  testId,
}: ConfirmOrExitInput) => {
  const [original, setOriginal] = useState<string>(value)
  const [hoverCheck, setHoverCheck] = useState<boolean>(false)
  const [hoverClose, setHoverClose] = useState<boolean>(false)
  const [shouldClose, setShouldClose] = useState<boolean[]>([false])
  const [current, setCurrent] = useState<string>(value)

  const closeEditing = () => setShouldClose([...shouldClose, true])

  const toggleConfirm = async () => {
    await onConfirm(current)
    setOriginal(current)
    closeEditing()
  }

  const toggleClose = () => {
    setCurrent(original)
    closeEditing()
  }

  const confirmOrExitChangeName = (
    <span className="confirm-exit-input">
      <CheckCircleTwoTone
        twoToneColor={hoverCheck ? '#02B628' : '#52C41A'}
        className="confirm-exit-input-check-button"
        onMouseEnter={() => setHoverCheck(true)}
        onMouseLeave={() => setHoverCheck(false)}
        onClick={toggleConfirm}
      />
      <CloseCircleTwoTone
        twoToneColor={hoverClose ? '#B60202' : '#FC4141'}
        onMouseEnter={() => setHoverClose(true)}
        onMouseLeave={() => setHoverClose(false)}
        onClick={toggleClose}
      />
    </span>
  )

  return (
    <EditableInput
      testId={testId}
      closeEditing={shouldClose}
      onChange={setCurrent}
      placeholder={placeholder}
      value={value}
      validation={validation}
      suffix={confirmOrExitChangeName}
    />
  )
}

export default ConfirmOrExitInput
