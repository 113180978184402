import React, { useCallback, useState } from 'react'

import { Form, FormControlProps } from 'react-bootstrap'

import { detectBadCSVValuesInReactBootstrap } from './helpers/validationRules'

// import { FormItemProps } from 'antd/lib/form/Form'
interface LegacyFormItemProps extends FormControlProps {
  setIsFormValid: any
}
export default function LegacyFormItem(formItemProps: LegacyFormItemProps) {
  const [isInputValid, setIsInputValid] = useState<boolean>(true)
  const { setIsFormValid, onChange, ...filteredFormItemProps } = formItemProps
  const handleValidationFieldChange = useCallback(
    (inputvalue: any) => {
      if (detectBadCSVValuesInReactBootstrap(inputvalue.target.value)) {
        setIsInputValid(false)
        setIsFormValid(false)
      } else if (!isInputValid) {
        setIsInputValid(true)
        setIsFormValid(true)
      }
      if (onChange !== undefined) {
        onChange(inputvalue)
      }
    },
    [onChange, isInputValid]
  )

  return (
    <>
      <Form.Control
        {...filteredFormItemProps}
        onChange={handleValidationFieldChange}
        isInvalid={!isInputValid}
      />
      <Form.Control.Feedback type="invalid">
        Cannot start with @, -, +, or =
      </Form.Control.Feedback>
    </>
  )
}
