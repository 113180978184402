import React, { useMemo } from 'react'

import { usePatientDemographics } from '../../hooks/usePatientInfo'
import { PatientInfo } from '../../hooks/usePatientProfile/shared-types'
import { usePatientSidePanelState } from '../../hooks/usePatientSidePanelState'
import useQueryString from '../../hooks/useQueryString'
import { ProviderHomePageData } from '../../shared-types'
import LeftSidebarContainer from '../BaseComponents/LeftSidebar'
import ContactInfo from '../PatientProfile/ContactInfo'
import CoreIdentifiers from '../PatientProfile/CoreIdentifiers'
import Demographics from '../PatientProfile/Demographics'
import Insurance from '../PatientProfile/Insurance'
import Navigator, { Element } from '../PatientProfile/Navigator'
import { Appointments } from './Appointments'

import './PatientProfilePanel.scss'
import './_shared.scss'

interface PatientProfilePanelProps {
  providerData?: ProviderHomePageData
}

export interface FormValues {
  firstName: string
  middleName: string
  lastName: string
  suffix: string
  firstNameToUse: string
  middleNameToUse: string
  lastNameToUse: string
  firstFormerName: string
  middleFormerName: string
  lastFormerName: string
  dob: string
  ssn: string
  legalSex: string
  birthSex: string
  genderIdentity: string
  genderPronouns: string
  ethnicity: string
  specificEthnicity: string
  race: string
  raceDetail: string
  languages: string
}

const PatientProfilePanel: React.FC<PatientProfilePanelProps> = ({
  providerData,
}) => {
  const queryParams = useQueryString()
  const patientId = queryParams.get('patientId')
  const { collapsed, collapseButtonRef, handleCollapseClick } =
    usePatientSidePanelState(providerData?.providerId)
  const { data: patientInfo } = usePatientDemographics(patientId)

  if (!patientId) {
    throw new Error(
      'There should be a patientId when using the patient profile panel'
    )
  }
  const routes: Element[] = useMemo(
    () => [
      {
        title: 'Contact',
        profilePanelRoute: '',
        // updating the hook to return PatientInfo touches a ton of downstream dependencies.
        // so we just cast here
        content: (
          <div className="sectionWrapper">
            <h5 className="sectionHeading">Contact</h5>
            <ContactInfo patientInfo={patientInfo as PatientInfo | undefined} />
          </div>
        ),
        hasDetail: false,
      },
      {
        title: 'Appointments',
        profilePanelRoute: '',
        content: <Appointments patientId={patientId} />,
        hasDetail: false,
      },
      {
        title: 'Demographics',
        profilePanelRoute: 'demographics',
        content: <Demographics testId="demographics" loading={false} />,
        hasDetail: true,
      },
      {
        title: 'Insurance',
        profilePanelRoute: 'insurance',
        content: <Insurance testId="insurance" loading={false} />,
        hasDetail: true,
      },
    ],
    [patientId, patientInfo]
  )

  return (
    <LeftSidebarContainer
      handleCollapseClick={() => handleCollapseClick(patientId)}
      collapseButtonRef={collapseButtonRef}
      collapsed={collapsed}
      testId={'patient-sidebar'}
      tooltip={'profile panel'}
    >
      <CoreIdentifiers collapsed={collapsed} testId="core-identifiers" />
      <Navigator hidden={collapsed} elements={routes} />
    </LeftSidebarContainer>
  )
}
export default PatientProfilePanel
