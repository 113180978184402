import { MouseEventHandler } from 'react'

import { Button, Skeleton } from '../BaseComponents'
import LogoCard from './LogoCard'

export interface InvalidInviteInfo {
  title: string
  body: string
  buttonData?: {
    buttonTitle: string
    buttonAction: MouseEventHandler<HTMLElement>
  }
}

interface SignupErrorCardProps extends InvalidInviteInfo {
  isLoading: boolean
}

export default function SignupErrorCard({
  isLoading,
  title,
  body,
  buttonData,
}: SignupErrorCardProps) {
  if (isLoading) {
    return (
      <LogoCard id="signup-error-card">
        <Skeleton active />
      </LogoCard>
    )
  }
  return (
    <LogoCard id="signup-error-card" title={title}>
      {body}
      {buttonData && (
        <Button
          onClick={buttonData.buttonAction}
          type="primary"
          id="signup-error-card-button"
        >
          {buttonData.buttonTitle}
        </Button>
      )}
    </LogoCard>
  )
}
