import { useQuery } from '@tanstack/react-query'

import { GetNoteDetailsQueryParams, getAllPatientNoteDetails } from '../api'
import { NOTE_DETAILS_QUERY_KEY } from '../constants'
import { NoteDetail } from '../types'

export const notePatientQueryKey = (patientId: string | null) => [
  NOTE_DETAILS_QUERY_KEY,
  'patient',
  patientId,
]

function maybeParseDate(dateString?: string | null) {
  if (dateString) {
    return new Date(dateString)
  }
  return null
}

export const useNoteDetails = (
  patientId: string | null,
  queryParams: Omit<GetNoteDetailsQueryParams, 'patientId'> = {},
  reactQueryOptions: { enabled?: boolean; keepPreviousData?: boolean } = {}
) => {
  const queryOptionEnabled = reactQueryOptions.enabled ?? true
  return useQuery(
    notePatientQueryKey(patientId),
    async () => {
      if (!patientId) {
        return
      }
      const result = await getAllPatientNoteDetails({
        ...queryParams,
        patientId,
      })

      return {
        ...result,
        data: result.data.map<NoteDetail>((detail) => {
          return {
            ...detail,
            deletedAt: maybeParseDate(detail.deletedAt),
            startTime: maybeParseDate(detail.startTime),
            endTime: maybeParseDate(detail.endTime),
            dischargeTime: maybeParseDate(detail.dischargeTime),
            appointmentDate: maybeParseDate(detail.appointmentDate),
            firstSignedAt: maybeParseDate(detail.appointmentDate),
          }
        }),
      }
    },
    { ...reactQueryOptions, enabled: !!patientId && queryOptionEnabled }
  )
}
