import { LocationWithRoomInfo } from '../../../../hooks/useSchedulingData/useSchedulingData'
import { CalendarCheckbox } from '../../../../hooks/useSchedulingFilters/useSchedulingFilters.helpers'

export const sortAlphabetically = (a: string, b: string) => {
  return a.localeCompare(b, 'en', { numeric: true })
}

export const sortTrueFirst = (a: boolean, b: boolean) => {
  if (a === b) {
    return 0
  }

  if (a) {
    return -1
  }

  return 1
}

export const areAllTeammateCalendarsSelected = (
  teammatesSelected: Set<string>,
  teammateCalendarCheckboxes: CalendarCheckbox
) => {
  return Object.values(teammateCalendarCheckboxes).every((calendars) =>
    calendars.every((calendar) => teammatesSelected.has(calendar.calendarId))
  )
}

export const getTotalNumberOfRoomsFromLocations = (
  locationsWithRoomInformation: LocationWithRoomInfo
) => {
  return Object.values(locationsWithRoomInformation).reduce(
    (acc, { rooms }) => acc + rooms.length,
    0
  )
}

export const isSelectAllState = ({
  selectedCount,
  totalCount,
}: {
  selectedCount: number
  totalCount: number
}) => {
  return selectedCount < totalCount
}
