import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { uniqBy } from 'lodash'
import { useHistory } from 'react-router-dom'

import {
  createInvoice,
  generateInvoicePDF,
  getLocation,
  getPOSCodes,
  getPaymentLockStatus,
  getTeammateData,
  setPaymentLockStatus,
  updateInvoice,
} from '../../api/api-lib'
import { BareBillingCode } from '../../hooks/queries/useBillingCodes'
import {
  QueryKeys as BillingQueryKeys,
  useInvoice,
  useNewInvoiceFormData,
  useRooms,
  useTransactions,
} from '../../hooks/useBillingInfo'
import {
  BillingCodeToDxPointers,
  useBillingCodeDxMapping,
} from '../../hooks/useBillingInfo/useBillingCodeDxMapping'
import {
  normalizePatientDxs,
  usePatientDiagnoses,
} from '../../hooks/usePatientDiagnoses'
import useQueryString from '../../hooks/useQueryString'
import {
  convertCentsToDollars,
  formatNumberStringToUsdString,
  getBillingCodeDisplayName,
  invoiceUrl,
  markDxAsDeletedAndUpdatePointers,
} from '../../libs/billing'
import { INVOICE_FORM_MODES } from '../../libs/constants'
import { useFeatureFlags } from '../../libs/featureFlags'
import { notification } from '../../libs/notificationLib'
import {
  formatDate,
  getMissingFields,
  mapObjectToCamelCase,
} from '../../libs/utils'
import {
  BillableEntityPayment,
  BillingTemplate,
  InNetworkClaimStatus,
  Invoice,
  LineItem,
  Location,
  PaymentAttempt,
  PlaceOfServiceCode,
} from '../../shared-types'
import { paymentDataLockedModal } from '../../shared/Payments/PaymentLockModal'
import { useNoteDetails } from '../../v2/notes/hooks/useNoteDetails'
import { Card, Tag } from '../BaseComponents'
import { Provider } from '../types'
import { BillableEntityPayments } from './BillableEntityPayments'
import { BillingInformation } from './BillingInformation'
import { Diagnoses, Diagnosis } from './Diagnoses'
import {
  DISCARD_INVOICE_MODAL_CONFIG,
  DiscardInvoiceModal,
} from './DiscardInvoiceModal'
import ExportPdfConfirmationModal from './ExportPdfConfirmationModal'
import { InvoiceAppointmentInformation } from './InvoiceAppointmentInformation'
import Buttons from './InvoiceButtons'
import { LineItemUpdate } from './InvoiceLineItems'
import { LineItemsContainer } from './LineItemsContainer'
import { NoteInformation } from './NoteInformation'
import { PracticeInformation } from './PracticeInformation'

import styles from './Invoice.module.scss'

type CreateOrUpdateInvoice = {
  uuid?: string
  patientId: string
  providerId?: string
  /**
   * @deprecated use clinicalNoteIdV2 instead
   */
  clinicalNoteId?: string
  clinicalNoteIdV2?: string
  locationId?: number
  invoiceNumber: number
  posCode?: string
  posVersion?: string
  notes?: string
  billableEntityLineItems: LineItem[]
  appointmentDate?: Date
  inNetworkClaimStatus?: string
  superbill?: {
    id?: number
    diagnoses: Diagnosis[]
    includeSignature: boolean
    selectedEIN?: string
    selectedNPI?: string
  }
}

export const routeParameters = {
  patientId: 'patientId',
  invoiceUuid: 'invoiceUuid',
  noteId: 'noteId',
  createSuperbill: 'createSuperbill',
  claimId: 'claimId',
} as const

export const InvoiceForm = ({
  primaryProviderId,
  userId: providerId,
}: {
  primaryProviderId: string
  userId: string
}) => {
  const history = useHistory()
  const query = useQueryString()
  const { hasEnabledCustomNotes } = useFeatureFlags()
  const patientId = query.get(routeParameters.patientId) ?? ''
  const invoiceUuid = query.get(routeParameters.invoiceUuid) || undefined
  const shouldCreateSuperbill =
    Boolean(query.get(routeParameters.createSuperbill)) || false
  const noteId = query.get(routeParameters.noteId) || undefined
  const [lineItems, setLineItems] = useState<LineItem[]>([])
  const [mode, setMode] = useState(
    invoiceUuid ? INVOICE_FORM_MODES.VIEW : INVOICE_FORM_MODES.CREATE
  )

  const isEditing = mode !== INVOICE_FORM_MODES.VIEW

  const [noteTextContent, setNoteTextContent] = useState<string | undefined>('')
  const [isLoadingFinished, setIsLoadingFinished] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(null)
  const [newInvoiceUuid, setNewInvoiceUuid] = useState<string | null>(null)
  const [amountCentsDue, setAmountCentsDue] = useState(0)
  const [selectedNoteId, setSelectedNoteId] = useState<string | undefined>(
    noteId
  )
  const [selectedPOS, setSelectedPOS] = useState<PlaceOfServiceCode | null>(
    null
  )
  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >(undefined)
  const [selectedProvider, setSelectedProvider] = useState<
    Provider | undefined
  >(undefined)
  const [appointmentDate, setAppointmentDate] = useState<Date | undefined>(
    undefined
  )
  const [isExportConfirmationOpen, setIsExportConfirmationOpen] =
    useState(false)
  const [isSuperbillEnabled, setIsSuperbillEnabled] = useState(false)
  const [isSuperbillChecked, setIsSuperbillChecked] = useState(false)
  const [isManualInNetworkClaimsChecked, setIsManualInNetworkClaimsChecked] =
    useState(false)
  const [locationDetails, setLocationDetails] = useState<Location | null>(null)

  const { isLoading: transactionsIsLoading, data: transactionData } =
    useTransactions({ patientId, invoiceUuid })

  // If navigated to page to create a superbill,
  // place form in edit mode and toggle superbill on.
  useEffect(() => {
    if (
      mode === INVOICE_FORM_MODES.CREATE &&
      shouldCreateSuperbill &&
      patientId &&
      !invoiceUuid
    ) {
      setIsSuperbillEnabled(true)
      setIsSuperbillChecked(true)
    }
    if (shouldCreateSuperbill && patientId && invoiceUuid) {
      setMode(INVOICE_FORM_MODES.EDIT)
      setIsSuperbillEnabled(true)
      setIsSuperbillChecked(true)
    }
  }, [shouldCreateSuperbill, patientId, invoiceUuid, mode])

  const {
    data: existingInvoice,
    isInitialLoading: isInvoiceLoading,
    isError: isInvoiceError,
    isFetched: isInvoiceFetched,
    refetch: refetchInvoice,
  } = useInvoice({
    invoiceUuid: newInvoiceUuid ?? invoiceUuid,
    noteId: selectedNoteId,
    patientId,
    v2NotesEnabled: hasEnabledCustomNotes,
    onSuccess: ({ uuid }) => {
      history.push(`invoice?patientId=${patientId}&invoiceUuid=${uuid}`)
    },
  })

  useEffect(() => {
    if (existingInvoice && existingInvoice.invoice.deletedAt) {
      history.push(
        `patient/billing?patientId=${existingInvoice.invoice.patientId}&providerId=${existingInvoice.invoice.providerId}`
      )
    }
  }, [existingInvoice])

  const {
    data: invoiceFormData,
    isInitialLoading: isInvoiceFormLoading,
    isError: isInvoiceFormError,
    isFetched: isInvoiceFormFetched,
  } = useNewInvoiceFormData(patientId, hasEnabledCustomNotes)

  const {
    mapping: billingCodeToDxMapping,
    isInitialLoading: isBillingCodeDxMappingLoading,
    isFetched: isBillingCodeDxMappingFetched,
  } = useBillingCodeDxMapping(patientId)

  const {
    data: rooms,
    isLoading: isRoomsLoading,
    isFetched: isRoomsFetched,
  } = useRooms()

  const {
    data: patientNotes,
    isInitialLoading: isNotesLoading,
    refetch: refetchPatientNotes,
  } = useNoteDetails(patientId, {}, { keepPreviousData: true })

  const data = { ...invoiceFormData, ...existingInvoice }

  const isLoading =
    (isInvoiceLoading && !isInvoiceFetched) ||
    (isInvoiceFormLoading && !isInvoiceFormFetched) ||
    (isRoomsLoading && !isRoomsFetched) ||
    isNotesLoading

  const isError = isInvoiceError || isInvoiceFormError

  const {
    data: locations,
    isLoading: areLocationsLoading,
    isFetched: areLocationsFetched,
  } = useQuery<Location[]>(['locations'], async () => {
    const data = await getLocation()
    return (data || []).map(mapObjectToCamelCase)
  })
  const {
    data: providers,
    isLoading: areProvidersLoading,
    isFetched: areProvidersFetched,
  } = useQuery(['teammates'], getTeammateData)
  const {
    data: posCodes,
    isLoading: arePOSCodesLoading,
    isFetched: arePOSCodesFetched,
  } = useQuery<PlaceOfServiceCode[]>(['posCodes'], getPOSCodes)

  const hasSuperbill = !!data.invoice?.superbill?.id

  const [diagnoses, setDiagnoses] = useState<Diagnosis[]>([])
  const findDefaultDxPointers = useCallback(
    (dxList: Diagnosis[], billingCodeId?: number): number[] => {
      const activeDxs = dxList.filter((dx) => !dx.isDeleted)

      if (!billingCodeId) {
        return activeDxs.length === 0 ? [] : [0]
      }

      const recentPointers = billingCodeToDxMapping as BillingCodeToDxPointers
      const defaultDxPtrs = (recentPointers[billingCodeId]?.latestDxCodes || [])
        .map((dxCode) => {
          return activeDxs.find((dx) => dx.code === dxCode)?.order
        })
        .filter((x) => x !== undefined)

      // PRAC-342: default to the first dx if no recent dxs are found AND there is at least one dx on the record
      if (defaultDxPtrs.length === 0 && activeDxs.length) {
        return [0]
      }

      return defaultDxPtrs as number[]
    },
    [billingCodeToDxMapping]
  )

  const {
    data: patientDiagnoses,
    isLoading: areDxsLoading,
    isFetched: areDxsFetched,
  } = usePatientDiagnoses(patientId)

  useEffect(() => {
    if (patientDiagnoses) setDiagnoses(normalizePatientDxs(patientDiagnoses))
  }, [patientDiagnoses])

  useEffect(() => {
    setDiagnoses(
      hasSuperbill && data.invoice?.superbill?.diagnoses
        ? data.invoice?.superbill?.diagnoses.map((d: Diagnosis) => ({
            id: d.id,
            code: d.code,
            description: d.description,
            order: d.order,
            isDeleted: d.isDeleted,
          }))
        : normalizePatientDxs(patientDiagnoses ?? [])
    )
  }, [data.invoice, hasSuperbill])

  const resetDiagnoses = () => {
    const normalizedDxs = normalizePatientDxs(
      patientDiagnoses ?? []
    ) as Diagnosis[]

    const dxCodeSet = new Set([
      ...diagnoses.reduce<string[]>((list, dx) => {
        if (!dx.isDeleted) list.push(dx.code)
        return list
      }, []),
      ...normalizedDxs.map((dx) => dx.code),
    ])

    const orderedList = Array.from(dxCodeSet)
      .reduce<Diagnosis[]>((list, dxCode, order) => {
        const dx = diagnoses.find((d) => d.code === dxCode)
        if (dx) {
          if (dx.isDeleted) {
            dx.order = order
            dx.isDeleted = false
          }
          list.push(dx)
          return list
        }

        const normalizedDx = normalizedDxs.find((d) => d.code === dxCode)
        if (normalizedDx) {
          normalizedDx.order = order
          list.push(normalizedDx)
          return list
        }

        return list
      }, [])
      .sort((dxA, dxB) => dxA.order - dxB.order)

    setDiagnoses(orderedList)

    if (lineItems.length) {
      const newLineItems = Array.from(lineItems).map((lineItem) => ({
        ...lineItem,
        dxPointers: findDefaultDxPointers(orderedList, lineItem.billingCode.id),
      }))
      setLineItems(newLineItems)
    }
  }

  const activeDiagnoses = useMemo(
    () =>
      diagnoses
        .filter((dx) => !dx.isDeleted)
        .map((dx, i) => ({ ...dx, order: i })),
    [diagnoses]
  )

  const handleDxDelete = (dxCode: string) => {
    const { newDiagnoses, newLineItems } = markDxAsDeletedAndUpdatePointers(
      diagnoses,
      lineItems,
      dxCode
    )

    setDiagnoses(newDiagnoses)
    setLineItems(newLineItems)
  }

  const [includeSignature, setIncludeSignature] = useState(false)

  const [selectedEIN, setSelectedEIN] = useState('')
  const [selectedNPI, setSelectedNPI] = useState('')

  const getAmountCentsPaid = (transactionData: any): number => {
    let totalAmountCents = 0
    transactionData?.payments?.forEach((payment: PaymentAttempt) => {
      const invoicePayments = payment.billableEntityPayments
      invoicePayments.forEach((invoicePayment: BillableEntityPayment) => {
        if (invoicePayment.invoiceUuid === data?.invoice?.uuid) {
          totalAmountCents += invoicePayment.amountCents
        }
      })
    })
    return totalAmountCents
  }

  const getAmountCentsRefunded = (transactionData: any): number => {
    let totalAmountCents = 0
    transactionData?.refunds?.forEach((refund: any) => {
      const invoiceRefunds = refund?.invoicePayments
      invoiceRefunds?.forEach((invoiceRefund: any) => {
        if (invoiceRefund.invoiceUuid === data?.invoice?.uuid) {
          totalAmountCents += invoiceRefund?.amountCents
        }
      })
    })
    return totalAmountCents
  }

  const noteInfo = useMemo(() => {
    if (!patientNotes || !selectedNoteId) {
      return null
    }
    return patientNotes.data.find((n) => n.noteId === selectedNoteId) ?? null
  }, [patientNotes, selectedNoteId])

  const isApptDataLoading =
    (areLocationsLoading && !areLocationsFetched) ||
    (arePOSCodesLoading && !arePOSCodesFetched) ||
    (areProvidersLoading && !areProvidersFetched) ||
    isNotesLoading ||
    (areDxsLoading && !areDxsFetched) ||
    (isBillingCodeDxMappingLoading && !isBillingCodeDxMappingFetched) ||
    isLoading

  const handlePOSSelect = (posCodeData: any) => {
    const newPOSCode = posCodes?.find(
      (posCode: PlaceOfServiceCode) =>
        posCode.code === posCodeData.code &&
        posCode.version === posCodeData.version
    )
    setSelectedPOS(newPOSCode || null)
  }

  const handleProviderSelect = (
    providerId: string | null | undefined
  ): Provider => {
    const newProvider = providers.find(
      (provider: Provider) => provider.cognitoId === providerId
    )
    newProvider && setSelectedProvider(newProvider)
    return newProvider
  }

  const setLocation = (newLocation: Location, provider?: Provider) => {
    setSelectedLocation(newLocation)
    if (newLocation?.pos) {
      const newPOSCode = posCodes?.find(
        (posCode: PlaceOfServiceCode) => posCode.code === newLocation.pos
      )
      setSelectedPOS(newPOSCode || null)
    }

    setSelectedEIN(newLocation?.ein || data?.practice?.ein)
    setSelectedNPI(
      newLocation?.billingNPI ||
        data?.practice?.billingNPI ||
        provider?.billingNPI
    )
  }

  const handleLocationSelect = (locationId: number, provider?: Provider) => {
    const newLocation = (locations || []).find(
      (location: Location) => location.id === locationId
    )

    newLocation && setLocation(newLocation, provider)
  }
  useEffect(() => {
    if (!isLoadingFinished || !rooms || !providers) return

    if (!noteInfo) {
      setAppointmentDate(undefined)
      return
    }
    setAppointmentDate(noteInfo.appointmentDate ?? undefined)

    const provider = handleProviderSelect(noteInfo.renderingProviderId)
    const noteLocation = locations?.find(
      (location) => location.uuid === noteInfo.locationId
    )
    if (!noteLocation) return

    setLocationDetails(noteLocation)
    setLocation(noteLocation, provider)
  }, [noteInfo, rooms, locations, providers, isLoadingFinished])

  const availableNotes = useMemo(() => {
    const notes = (patientNotes?.data ?? []).filter(
      // only allow selecting notes without invoices, and, if custom notes is off, v1 notes
      (i) => !i.invoiceId && (hasEnabledCustomNotes || i.version === '1')
    )
    if (noteInfo) {
      notes.push(noteInfo)
    }
    return uniqBy(notes, ({ noteId }) => noteId)
  }, [patientNotes, noteInfo, hasEnabledCustomNotes])

  const calculateTotalAmountCents = () => {
    if (!lineItems) {
      return 0
    }

    let totalAmountCents = 0
    lineItems.forEach((lineItem: LineItem) => {
      totalAmountCents +=
        lineItem.units * lineItem.unitChargeAmountCents -
        lineItem.totalAdjustmentAmountCents
    })

    return totalAmountCents
  }

  useEffect(() => {
    const totalAmountCents = calculateTotalAmountCents()
    const amountPaidWithoutRefunds = getAmountCentsPaid(transactionData)
    const amountRefunded = getAmountCentsRefunded(transactionData)
    const due = totalAmountCents - amountPaidWithoutRefunds + amountRefunded
    due > 0 ? setAmountCentsDue(due) : setAmountCentsDue(0)
  }, [lineItems, transactionData])

  useEffect(() => {
    if (!isApptDataLoading) {
      setIsLoadingFinished(true)
    }
  }, [isApptDataLoading])

  useEffect(() => {
    if (!data.invoice) {
      return
    }

    if (mode !== INVOICE_FORM_MODES.CREATE && !isError && isLoadingFinished) {
      const {
        clinicalNoteId,
        clinicalNoteIdV2,
        amountCentsDue,
        notes,
        providerId,
        posCode,
        posVersion,
        billableEntityLineItems,
        createdAt,
        superbill,
        inNetworkClaimStatus,
      } = data.invoice as Invoice
      const savedNoteId = clinicalNoteIdV2 ?? clinicalNoteId
      if (savedNoteId && !selectedNoteId) {
        setSelectedNoteId(savedNoteId)
      }
      setAmountCentsDue(amountCentsDue)
      setInvoiceDate(new Date(createdAt))
      setNoteTextContent(notes)
      if (data.location) {
        setLocation(data.location)
      }
      if (providers) {
        handleProviderSelect(providerId)
      }
      if (posCodes) {
        handlePOSSelect({ code: posCode, version: posVersion })
      }
      setLineItems(billableEntityLineItems || [])
      if (superbill) {
        setSelectedEIN(superbill.selectedEIN)
        setSelectedNPI(superbill.selectedNPI)
        setIncludeSignature(Boolean(superbill?.includeSignature))
        setIsSuperbillEnabled(Boolean(superbill.id))
        if (inNetworkClaimStatus) {
          setIsManualInNetworkClaimsChecked(true)
        } else {
          setIsSuperbillChecked(true)
        }
      }
    }
  }, [isLoadingFinished, data.invoice])

  const getFormattedInvoiceDate = () => {
    if (isLoading || !isLoadingFinished) {
      return '--'
    }

    let date = new Date()
    if (mode !== INVOICE_FORM_MODES.CREATE) {
      date = (invoiceDate as Date) ?? new Date(data?.invoice?.createdOn)
    }
    return formatDate({
      value: date,
      textWhenInvalid: '--',
      shouldLocalize: true,
    })
  }

  const invoiceNumber = useMemo(() => {
    if (!data || !isLoadingFinished) return null

    if (mode === INVOICE_FORM_MODES.CREATE) {
      return data.invoiceNumber
    } else {
      return data.invoice?.invoiceNumber ?? data.invoiceNumber
    }
  }, [mode, data, isLoadingFinished])

  const addNewLineItem = ({
    code,
    version,
    ownerId,
    shortDescription,
    id,
  }: BareBillingCode) => {
    const isAlreadyOnBill = lineItems?.find(
      (item) => item?.billingCode?.code === code
    )

    if (isAlreadyOnBill) {
      notification(
        'That code is already on the invoice. Try increasing the unit count instead.'
      )
    } else {
      const newLineItems = Array.from(lineItems)
      newLineItems.push({
        billingCode: {
          code: code,
          version: version,
          ownerId: ownerId,
          description: shortDescription ?? '',
          id: id,
        },
        units: 1,
        unitChargeAmountCents: 0,
        totalAdjustmentAmountCents: 0,
        dxPointers: isSuperbillEnabled
          ? findDefaultDxPointers(diagnoses, id)
          : [],
      })

      setLineItems(newLineItems)
    }
  }

  const queryClient = useQueryClient()

  const prunedLineItems = useMemo(() => {
    return lineItems.map((item) => {
      delete item?.billingTemplateId
      delete item?.id

      if (!isSuperbillEnabled) {
        delete item?.dxPointers
      }
      return item
    })
  }, [lineItems, isSuperbillEnabled])

  async function handleInvoiceCreate(): Promise<string | null> {
    setIsCreating(true)
    const lockStatusResponse = await getPaymentLockStatus(patientId)
    if (lockStatusResponse.isLocked) {
      paymentDataLockedModal()
      setIsCreating(false)
      return null
    }

    await setPaymentLockStatus(
      patientId,
      true,
      'InvoiceForm handleInvoiceCreate start'
    )

    const createInvoiceData: CreateOrUpdateInvoice = {
      patientId,
      providerId: selectedProvider?.cognitoId,
      // clinicalNoteId has an FK constraint so only set if it's a v1 note
      clinicalNoteId: noteInfo?.version === '1' ? selectedNoteId : undefined,
      clinicalNoteIdV2: selectedNoteId,
      locationId: selectedLocation?.id,
      invoiceNumber,
      posCode: selectedPOS?.code,
      posVersion: selectedPOS?.version,
      notes: noteTextContent || '',
      billableEntityLineItems: prunedLineItems,
      appointmentDate,
    }

    if (
      invoiceFormData?.practice?.isManualInNetworkClaimsEnabled &&
      !invoiceFormData?.invoice?.inNetworkClaimStatus &&
      isManualInNetworkClaimsChecked
    ) {
      createInvoiceData.inNetworkClaimStatus =
        InNetworkClaimStatus.SUBMISSION_REQUIRED
    }

    if (isSuperbillEnabled) {
      createInvoiceData.superbill = {
        diagnoses: diagnoses.filter((d) => !d.isDeleted),
        includeSignature,
        selectedEIN,
        selectedNPI,
      }
    }

    try {
      const data = await createInvoice(createInvoiceData, {
        v2Notes: hasEnabledCustomNotes,
      })
      await setPaymentLockStatus(
        patientId,
        false,
        'InvoiceForm handleCreate success'
      )
      notification('Invoice created successfully.', 'success')
      refetchPatientNotes()
      setNewInvoiceUuid(data.uuid)
      setInvoiceDate(new Date())
      queryClient.invalidateQueries([BillingQueryKeys.INVOICE])
      setMode(INVOICE_FORM_MODES.VIEW)
      setIsCreating(false)
      return data.uuid
    } catch (e) {
      await setPaymentLockStatus(
        patientId,
        false,
        'InvoiceForm handleCreate failure'
      )
      notification('There was an error creating the invoice.', 'error')
      setIsCreating(false)
      return null
    }
  }

  const handleInvoiceUpdate = async (): Promise<void> => {
    setIsUpdating(true)
    const lockStatusResponse = await getPaymentLockStatus(patientId)
    if (lockStatusResponse.isLocked) {
      paymentDataLockedModal()
      setIsUpdating(false)
      return
    }

    await setPaymentLockStatus(
      patientId,
      true,
      'InvoiceForm handleInvoiceUpdate start'
    )

    const updateInvoiceData: CreateOrUpdateInvoice = {
      uuid: data?.invoice?.uuid,
      billableEntityLineItems: prunedLineItems,
      providerId: selectedProvider?.cognitoId,
      patientId: patientId,
      invoiceNumber,
      posCode: selectedPOS?.code,
      posVersion: selectedPOS?.version,
      // clinicalNoteId has an FK constraint so only set if it's a v1 note
      clinicalNoteId: noteInfo?.version === '1' ? selectedNoteId : undefined,
      clinicalNoteIdV2: selectedNoteId,
      locationId: selectedLocation?.id,
      notes: noteTextContent || '',
      appointmentDate,
      superbill: undefined,
    }

    //Only update if the inNetworkClaimStatus is null and the flag is enabled
    if (
      invoiceFormData?.practice?.isManualInNetworkClaimsEnabled &&
      !invoiceFormData?.invoice?.inNetworkClaimStatus &&
      isManualInNetworkClaimsChecked
    ) {
      updateInvoiceData.inNetworkClaimStatus =
        InNetworkClaimStatus.SUBMISSION_REQUIRED
    }

    if (isSuperbillEnabled) {
      updateInvoiceData.superbill = {
        id: data?.invoice?.superbill?.id,
        diagnoses,
        includeSignature,
        selectedEIN,
        selectedNPI,
      }
    }

    try {
      await updateInvoice(updateInvoiceData)
      await setPaymentLockStatus(
        patientId,
        false,
        'InvoiceForm handleInvoiceUpdate success'
      )
      notification('Invoice saved.', 'success')
      setMode(INVOICE_FORM_MODES.VIEW)
      queryClient.invalidateQueries([BillingQueryKeys.TRANSACTIONS])
      setIsUpdating(false)
      refetchInvoice()
      refetchPatientNotes()
    } catch (e) {
      await setPaymentLockStatus(
        patientId,
        false,
        'InvoiceForm handleInvoiceUpdate failure'
      )
      notification('There was an error updating the invoice.', 'error')
      setIsUpdating(false)
      refetchInvoice()
    }
  }

  const handleNoteContentChange = (e: any) => {
    setNoteTextContent(e?.target?.value)
  }

  const handleLineItemUpdate = (data: LineItemUpdate) => {
    const newItems = Array.from(lineItems)
    const index = newItems.findIndex(
      (item) => item.billingCode.id === data.lineItem.billingCode.id
    )

    if (index >= 0) {
      newItems[index] = {
        ...newItems[index],
        ...data.newData,
      }
    }

    setLineItems(newItems)
  }

  const handleLineItemDelete = (lineItem: LineItem) => {
    const newLineItems = [] as LineItem[]
    lineItems.forEach((item: LineItem) => {
      if (
        getBillingCodeDisplayName(item.billingCode) !==
        getBillingCodeDisplayName(lineItem.billingCode)
      ) {
        newLineItems.push(item)
      }
    })
    setLineItems(newLineItems)
  }

  const handleNoteSelect = (noteId: string) => {
    setSelectedNoteId(noteId)
  }

  const handleBillingTemplateSelect = (billingTemplate?: BillingTemplate) => {
    const newLineItems = [] as LineItem[]
    billingTemplate?.billingTemplateCodes?.forEach((templateCode: any) => {
      const lineItem: LineItem = {
        units: templateCode.unit,
        unitChargeAmountCents: templateCode.unitChargeAmountCents,
        totalAdjustmentAmountCents: templateCode.discountAmountCents,
        billingCode: {
          code: templateCode.billingCode.code,
          version: templateCode.billingCode.version,
          ownerId: templateCode.billingCode.ownerId,
          description: templateCode.billingCode.shortDescription,
          id: templateCode.billingCode.id,
        },
        billingTemplateId: billingTemplate.id,
        modifiers: templateCode.modifiers,
        billingTemplateCodeId: templateCode.id,
        dxPointers: isSuperbillEnabled
          ? findDefaultDxPointers(diagnoses, templateCode.billingCode.id)
          : [],
      }
      newLineItems.push(lineItem)
    })

    lineItems.forEach((lineItem: LineItem) => {
      if (!lineItem.billingTemplateId) {
        newLineItems.push(lineItem)
      }
    })

    setLineItems(newLineItems)
  }

  const handleSuperbillToggle = (newValue: boolean) => {
    if (!isSuperbillEnabled && newValue && lineItems.length) {
      const newLineItems = Array.from(lineItems).map((lineItem) => ({
        ...lineItem,
        dxPointers: findDefaultDxPointers(diagnoses, lineItem.billingCode.id),
      }))
      setLineItems(newLineItems)
    }
    setIsSuperbillEnabled(newValue)
  }

  const handleManualInNetworkClaimsChecked = (newValue: boolean) => {
    handleSuperbillToggle(newValue)
    setIsManualInNetworkClaimsChecked(newValue)
  }

  const handleSuperbillChecked = (newValue: boolean) => {
    handleSuperbillToggle(newValue)
    setIsSuperbillChecked(newValue)
  }

  const handleExportInvoice = async () => {
    setIsUpdating(true)
    try {
      await generateInvoicePDF(data.invoice.uuid)
      setIsUpdating(false)
      history.push(
        invoiceUrl({
          invoice: data.invoice,
          providerId,
          documentReference: data.invoice.uuid,
        })
      )
    } catch (error) {
      console.error(error)
      setIsUpdating(false)
    }
  }

  const invoicePayments = useMemo(() => {
    if (
      mode === INVOICE_FORM_MODES.CREATE ||
      data?.invoice?.uuid === undefined
    ) {
      return []
    }

    const payments = (transactionData?.payments || [])
      .map((p: PaymentAttempt) => ({
        ...p,
        invoicePayments: p.billableEntityPayments.filter(
          (ip) => ip.invoiceUuid === data?.invoice?.uuid
        ),
      }))
      .filter((p: PaymentAttempt) => p.billableEntityPayments.length > 0)
    return payments
  }, [transactionData, data])

  const invoiceRefunds = useMemo(() => {
    if (
      mode === INVOICE_FORM_MODES.CREATE ||
      data?.invoice?.uuid === undefined
    ) {
      return []
    }

    const refunds = (transactionData?.refunds || [])
      .map((p: any) => ({
        ...p,
        invoicePayments: (
          p.billableEntityPayments as BillableEntityPayment[]
        ).filter((payment) => payment.invoiceUuid === data?.invoice?.uuid),
      }))
      .filter((p: any) => p.billableEntityPayments.length > 0)
    return refunds
  }, [transactionData, data])

  const requiredBaseFieldPaths = ['location', 'providerId', 'lineItems']

  const requiredSuperbillFieldPaths = useMemo(
    () => [
      'noteId',
      'diagnoses',
      'selectedEIN',
      'selectedNPI',
      ...Array.from({ length: lineItems.length }).map(
        (_, i) => `lineItems[${i}].dxPointers`
      ),
    ],
    [lineItems.length]
  )

  const allRequiredFields = useMemo(() => {
    const fields = isSuperbillEnabled
      ? [...requiredBaseFieldPaths, ...requiredSuperbillFieldPaths]
      : requiredBaseFieldPaths
    if (isSuperbillEnabled || appointmentDate) {
      fields.push('pos')
    }
    return fields
  }, [isSuperbillEnabled, requiredSuperbillFieldPaths, appointmentDate])

  const tentativePayload = {
    pos: selectedPOS,
    location: selectedLocation,
    providerId,
    noteId: selectedNoteId,
    lineItems,
    selectedEIN,
    selectedNPI,
    diagnoses,
  }

  const missingFields = useMemo(
    () =>
      getMissingFields(tentativePayload, allRequiredFields, {
        requirePopulatedArrays: true,
      }),
    [tentativePayload, allRequiredFields]
  )

  const isMissingFields = missingFields.length > 0
  const isMissingLineItems = !lineItems || lineItems.length === 0

  /// Billing line items cannot be less or equal 0.
  const isEmptyLineItems = useMemo(
    () => lineItems.some((lineItem) => lineItem.units <= 0),
    [lineItems]
  )

  const getInvoiceStatusContent = () => {
    const invoice: Invoice | undefined = data.invoice
    if (mode === INVOICE_FORM_MODES.CREATE || !invoice) {
      return <Tag color="red">Unpaid</Tag>
    }

    if (!invoice) {
      return null
    }

    if (invoice.amountCentsDue === 0) {
      return <Tag color="green">Paid</Tag>
    } else if (invoice.amountCentsDue === invoice.totalAmountCents) {
      return <Tag color="red">Unpaid</Tag>
    } else {
      return <Tag color="gold">Partially paid</Tag>
    }
  }

  const getFormattedAmountCentsDue = () => {
    if (mode === INVOICE_FORM_MODES.VIEW && !data.invoice) return '--'
    if (amountCentsDue !== 0 && !amountCentsDue) return '--'

    return formatNumberStringToUsdString(
      convertCentsToDollars(
        mode !== INVOICE_FORM_MODES.CREATE && mode !== INVOICE_FORM_MODES.EDIT
          ? data.invoice?.amountCentsDue
          : amountCentsDue
      )
    )
  }

  const isAnythingLoading = isCreating || isUpdating || isApptDataLoading

  return (
    <>
      <ExportPdfConfirmationModal
        isVisible={isExportConfirmationOpen}
        onCancel={() => {
          setIsExportConfirmationOpen(false)
        }}
        okButtonProps={{ loading: isAnythingLoading }}
        onOk={handleExportInvoice}
        cancelButtonProps={{ disabled: isAnythingLoading }}
        closable={!isAnythingLoading}
        maskClosable={!isAnythingLoading}
        fileType="invoice"
      />
      <div className={styles.invoice}>
        <Card className={styles.mainHeaderRow}>
          <div className={styles.spaceBetween}>
            <div className={styles.mainHeader}>
              <DiscardInvoiceModal
                mode={mode}
                invoiceNumber={invoiceNumber}
                patientId={patientId ?? ''}
                providerId={providerId ?? ''}
                config={DISCARD_INVOICE_MODAL_CONFIG.BACK_ARROW}
                isDiscardDisabled={isCreating || isUpdating}
              />{' '}
              <span>Invoice {invoiceNumber ? `#${invoiceNumber}` : ''}</span>
            </div>
            <div className={styles.mainHeaderActions}>
              <Buttons
                primaryProviderId={primaryProviderId}
                invoice={data.invoice}
                invoiceNumber={invoiceNumber}
                amountCentsDue={amountCentsDue}
                patientId={patientId}
                isMissingFields={isMissingFields}
                isMissingLineItems={isMissingLineItems}
                isEmptyLineItems={isEmptyLineItems}
                onCreate={handleInvoiceCreate}
                onUpdate={handleInvoiceUpdate}
                mode={mode}
                isCreating={isCreating}
                isUpdating={isUpdating}
                isExporting={isUpdating}
                isAnythingLoading={isLoading}
                onSwitchToEditMode={() => {
                  setMode(INVOICE_FORM_MODES.EDIT)
                }}
                onExport={() => setIsExportConfirmationOpen(true)}
                refetchInvoice={refetchInvoice}
              />
            </div>
          </div>
          <div className={styles.invoiceTopBarInfo}>
            <div className={styles.topBarField}>
              <span className={styles.topBarFieldName}>Status</span>
              <span className={styles.topBarStatusFieldValue}>
                {getInvoiceStatusContent()}
              </span>
            </div>

            <div className={styles.topBarField}>
              <span className={styles.topBarFieldName}>Total amount due</span>
              <span className={styles.topBarFieldValue}>
                {getFormattedAmountCentsDue()}
              </span>
            </div>

            <div className={styles.topBarField}>
              <span className={styles.topBarFieldName}>Invoice Date</span>
              <span className={styles.topBarFieldValue}>
                {getFormattedInvoiceDate()}
              </span>
            </div>
          </div>
        </Card>

        <div
          data-testid="patientAndAppointment"
          className={styles.patientAndAppointment}
        >
          <BillingInformation
            patient={data?.patient}
            isLoading={isLoading || !data?.patient}
            isEditing={isEditing}
            isSuperbillEnabled={isSuperbillChecked}
            isSuperbillLocked={hasSuperbill}
            isSuperbillChecked={isSuperbillChecked}
            onSuperbillToggle={handleSuperbillChecked}
            isManualInNetworkClaimsChecked={isManualInNetworkClaimsChecked}
            onManualInNetworkClaimsToggle={handleManualInNetworkClaimsChecked}
            isManualInNetworkClaimsEnabled={
              invoiceFormData?.practice?.isManualInNetworkClaimsEnabled
            }
            superbillSharedAt={data?.invoice?.superbill?.sharedAt}
            superbillSharedBy={data?.invoice?.superbill?.sharedBy}
          />

          <PracticeInformation
            isLoading={isApptDataLoading}
            isEditing={isEditing}
            practice={data?.practice}
            provider={selectedProvider}
            locations={locations}
            selectedLocation={selectedLocation}
            selectedEIN={selectedEIN}
            onSelectEIN={setSelectedEIN}
            selectedNPI={selectedNPI}
            onSelectNPI={setSelectedNPI}
            mode={mode}
            missingFields={missingFields}
            onSelectLocation={handleLocationSelect}
            isSuperbillEnabled={isSuperbillEnabled}
            setLocationDetails={setLocationDetails}
            location={locationDetails}
          />
          <InvoiceAppointmentInformation
            isLoading={isApptDataLoading}
            isEditing={isEditing}
            providers={providers}
            posCodes={posCodes}
            appointmentDate={appointmentDate ?? data?.invoice?.appointmentDate}
            missingFields={missingFields}
            selectedProvider={selectedProvider}
            pos={selectedPOS}
            isSuperbillEnabled={isSuperbillEnabled}
            includeSignature={includeSignature}
            onSelectPOS={handlePOSSelect}
            onSelectProvider={handleProviderSelect}
            onSelectIncludeSignature={setIncludeSignature}
            notes={availableNotes}
            onNoteSelect={handleNoteSelect}
            selectedNoteId={selectedNoteId}
            hasSuperbill={hasSuperbill}
            patientId={patientId}
            providerId={existingInvoice?.invoice?.providerId}
          />
        </div>

        {isSuperbillEnabled && (
          <Diagnoses
            isLoading={isLoading}
            isEditing={isEditing}
            isSuperbillEnabled={isSuperbillEnabled}
            diagnoses={activeDiagnoses}
            onDelete={handleDxDelete}
            onReset={resetDiagnoses}
          />
        )}

        <NoteInformation
          isLoading={isLoading}
          textContent={noteTextContent}
          onTextContentChange={handleNoteContentChange}
          isEditing={isEditing}
          isSuperbillEnabled={isSuperbillEnabled}
        />

        <LineItemsContainer
          invoiceUuid={data?.invoice?.uuid}
          isEditing={isEditing}
          isLoading={isLoading}
          isSuperbillEnabled={isSuperbillEnabled}
          lineItems={lineItems}
          payments={invoicePayments}
          refunds={invoiceRefunds}
          diagnoses={activeDiagnoses}
          onLineItemUpdate={handleLineItemUpdate}
          onLineItemDelete={handleLineItemDelete}
          selectedTemplateId={data?.invoice?.billingTemplate?.id}
          onTemplateChange={(billingTemplate?: BillingTemplate) =>
            handleBillingTemplateSelect(billingTemplate)
          }
          onBillingCodeAdd={(newCode: BareBillingCode) => {
            addNewLineItem(newCode)
          }}
        />

        {mode !== INVOICE_FORM_MODES.CREATE && (
          <div>
            <BillableEntityPayments
              isLoading={isLoading || transactionsIsLoading}
              payments={invoicePayments}
              invoiceNumber={invoiceNumber}
              invoiceUuid={invoiceUuid ?? ''}
              patientId={patientId}
              invoice={data?.invoice}
            />
          </div>
        )}
      </div>
    </>
  )
}
