import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Form, Modal } from 'antd'
import { isEqual } from 'lodash'

import {
  addBillingTemplateCode,
  updateBillingTemplateCode,
} from '../../api/api-lib'
import { useBillingCodes } from '../../hooks/queries/useBillingCodes'
import { convertDollarsToCents } from '../../libs/billing'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import {
  BillingTemplateCodeTableItem,
  BillingTemplateTableItem,
} from '../../shared-types'
import {
  Col,
  Input,
  InputNumber,
  Modal as OsmindModal,
  Row,
  Select,
  USDInput,
} from '../../stories/BaseComponents'
import {
  mapToOptionGroups,
  useBillingCodeDropdownOptions,
} from './useBillingCodeDropdownOptions'

interface Props {
  show: boolean
  fetchTemplates: () => void
  onHide: () => void
  editBillingTemplateCodeItem: BillingTemplateCodeTableItem | undefined
  billingTemplateItem: BillingTemplateTableItem
  existingBillingCodeIds: number[]
}

interface FormValues {
  billingCode: number | undefined
  discount: string | number
  mod1: string
  mod2: string
  mod3: string
  mod4: string
  unit: number | undefined
  unitCharge: string | number
}

interface BillingTemplateCodeRequest {
  id: number
  modifiers: string[]
  unit: number
  unitChargeAmountCents: number
  discountAmountCents: number
  templateId: string
  billingTemplateCodeId?: string
}

enum Mode {
  EDIT = 'isEdit',
  ADD = 'isAdd',
}

const BillingTemplateCodeModal: React.FC<Props> = ({
  show,
  onHide,
  editBillingTemplateCodeItem,
  fetchTemplates,
  billingTemplateItem,
  existingBillingCodeIds,
}) => {
  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState<boolean>(false)
  const mode = useMemo(
    () => (editBillingTemplateCodeItem ? Mode.EDIT : Mode.ADD),
    [editBillingTemplateCodeItem]
  )
  const billingCodeJson = JSON.parse(
    editBillingTemplateCodeItem?.billingCodeValue ?? '{}'
  )
  const [billingCodeSearch, setBillingCodeSearch] = React.useState('')
  const {
    codes: billingCodes,
    count: numberBillingCodes,
    isLoading: isSearchingCodes,
  } = useBillingCodes(billingCodeSearch)

  const codeOptions = useBillingCodeDropdownOptions(billingCodes)

  const initialValues: FormValues = {
    // have to explicitly set billingCode to undefined (if missing) so antD recognizes to show the placeholder
    // which is "Search for billing codes" when there is no billingCode currently selected
    billingCode: billingCodeJson?.code
      ? JSON.parse(editBillingTemplateCodeItem?.billingCodeValue || '{}')?.id
      : undefined,
    discount: editBillingTemplateCodeItem?.discountAmountCents
      ? editBillingTemplateCodeItem.discountAmountCents / 100
      : '',
    mod1: editBillingTemplateCodeItem?.modifiers[0] ?? '',
    mod2: editBillingTemplateCodeItem?.modifiers[1] ?? '',
    mod3: editBillingTemplateCodeItem?.modifiers[2] ?? '',
    mod4: editBillingTemplateCodeItem?.modifiers[3] ?? '',
    unit: editBillingTemplateCodeItem?.unit ?? 1,
    unitCharge: editBillingTemplateCodeItem?.unitChargeAmountCents
      ? editBillingTemplateCodeItem.unitChargeAmountCents / 100
      : '',
  }

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [show])

  const codeIdRef = React.useRef(editBillingTemplateCodeItem?.codeId)

  useEffect(() => {
    codeIdRef.current = editBillingTemplateCodeItem?.codeId
  }, [editBillingTemplateCodeItem])

  const checkFormChanged = () => {
    const values = {
      ...initialValues,
      ...form.getFieldsValue(),
    }
    return !isEqual(values, initialValues)
  }

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (!values.billingCode) {
        notification('Please select a billing code.', 'error')
        return
      }

      try {
        setLoading(true)
        const data: BillingTemplateCodeRequest = {
          id: values.billingCode,
          modifiers: [values.mod1, values.mod2, values.mod3, values.mod4],
          unit: values.unit || 1,
          unitChargeAmountCents: convertDollarsToCents(values.unitCharge),
          discountAmountCents: convertDollarsToCents(values.discount),
          templateId: billingTemplateItem?.templateId,
        }
        if (mode === Mode.ADD) {
          await addBillingTemplateCode(data)
          notification('Successfully added a billing template code.', 'success')
        } else {
          data.billingTemplateCodeId = codeIdRef.current
          await updateBillingTemplateCode(data)
          notification(
            'Successfully edited a billing template code.',
            'success'
          )
        }
        onHide()
        fetchTemplates()
      } catch (e) {
        console.error(e)
        onError(new Error('Unable to add a billing template code'))
        onHide()
      }
      setLoading(false)
    },
    [mode]
  )

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values)
      })
      .catch((info) => {
        console.log('Validate Failed: ', info)
      })
  }

  const handleCancel = () => {
    if (!checkFormChanged()) {
      setLoading(false)
      onHide()
      return
    }

    Modal.confirm({
      title: 'Unsaved changes',
      content:
        'Are you sure you want to close this window? Any unsaved changes will be lost.',
      okText: 'Ok',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'default',
      },
      okButtonProps: {
        type: 'primary',
        loading: isLoading,
      },
      onOk: () => {
        setLoading(false)
        onHide()
      },
    })
  }

  const updateCodeOptions = (input: string) => {
    setBillingCodeSearch(input)
  }

  useEffect(() => {
    if (editBillingTemplateCodeItem) {
      setBillingCodeSearch(editBillingTemplateCodeItem.billingCodeCode)
    }
  }, [show])

  const modValidation = () => ({
    validator(_: any, value: string) {
      if (value.length > 2) {
        return Promise.reject(
          new Error('Modifier cannot be greater than 2 characters.')
        )
      }
      return Promise.resolve()
    },
  })

  const duplicateCodeValidation = () => ({
    validator(_: any, value: number) {
      if (
        mode === Mode.ADD &&
        existingBillingCodeIds &&
        existingBillingCodeIds.includes(value)
      ) {
        return Promise.reject(
          new Error('Billing code already exists on template')
        )
      }

      return Promise.resolve()
    },
  })

  return (
    <OsmindModal
      confirmLoading={isLoading}
      closable={true}
      title={`${mode === Mode.ADD ? 'Add' : 'Edit'} billing code`}
      visible={show}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        onFinish={handleOk}
        scrollToFirstError
        layout="vertical"
      >
        {/* while waiting to search the db for the billing code attached to this edit item,
          show a disabled, loading select item */}
        {isSearchingCodes && numberBillingCodes === 0 ? (
          <Form.Item
            className="top-ant-form-item"
            label="Billing code"
            required
          >
            <Select disabled loading />
          </Form.Item>
        ) : (
          <Form.Item
            className="top-ant-form-item"
            name="billingCode"
            label="Billing code"
            rules={[
              {
                required: true,
                message: 'Please select a billing code.',
              },
              duplicateCodeValidation,
            ]}
          >
            <Select
              placeholder="Search for billing codes"
              showSearch={true}
              allowClear={true}
              onSearch={updateCodeOptions}
              loading={isSearchingCodes}
              listHeight={240}
            >
              {mapToOptionGroups(codeOptions)}
            </Select>
          </Form.Item>
        )}
        <Row gutter={20} data-testid="modifiers">
          <Col span={6}>
            <Form.Item label="Modifiers" name="mod1" rules={[modValidation]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label=" " name="mod2" rules={[modValidation]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label=" " name="mod3" rules={[modValidation]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label=" " name="mod4" rules={[modValidation]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Unit" name="unit">
              <InputNumber
                min={1}
                step={1}
                precision={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Unit charge" name="unitCharge">
              <USDInput testId="unit-charge-input" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Discount" name="discount">
          <USDInput testId="discount-input" />
        </Form.Item>
      </Form>
    </OsmindModal>
  )
}

export default BillingTemplateCodeModal
