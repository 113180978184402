import React from 'react'

import { Accordion, Container, Form, Row } from 'react-bootstrap'

import NoRelevantHistory from './NoRelevantHistory'
import SubstanceToDisplay from './PsychContent/SubstanceToDisplay'

import './../PatientProfile.scss'

export default function SubstanceRelatedAndAddictiveSaved(props) {
  const hasNoRelevantHistory =
    props.patientData.SUD.state === false &&
    props.patientData.Alcoholism.state === false &&
    props.patientData.OUD.state === false &&
    props.patientData.OtherSubstance.state === false &&
    props.patientData.NoSubstanceAddiction === true &&
    !props.patientData.Alcohol.state &&
    !props.patientData.Tobacco.state &&
    !props.patientData.Cannabis.state &&
    !props.patientData.Cocaine.state &&
    !props.patientData.Heroin.state &&
    !props.patientData.MethAmphetamines.state &&
    !props.patientData.MDMA.state &&
    !props.patientData.Psilocybin.state &&
    !props.patientData.OtherPsychedelics.state &&
    !props.patientData.Stimulants.state &&
    !props.patientData.Sedatives.state &&
    !props.patientData.OtherPrescription.state &&
    !props.patientData.SubstanceAdditionalNotes &&
    !props.patientData.OtherAbuseSubstance.state

  const noRecordedInfo =
    props.patientData.SUD.state === false &&
    props.patientData.Alcoholism.state === false &&
    props.patientData.OUD.state === false &&
    props.patientData.OtherSubstance.state === false &&
    props.patientData.NoSubstanceAddiction === false &&
    !props.patientData.Alcohol.state &&
    !props.patientData.Tobacco.state &&
    !props.patientData.Cannabis.state &&
    !props.patientData.Cocaine.state &&
    !props.patientData.Heroin.state &&
    !props.patientData.MethAmphetamines.state &&
    !props.patientData.MDMA.state &&
    !props.patientData.Psilocybin.state &&
    !props.patientData.OtherPsychedelics.state &&
    !props.patientData.Stimulants.state &&
    !props.patientData.Sedatives.state &&
    !props.patientData.OtherPrescription.state &&
    !props.patientData.SubstanceAdditionalNotes &&
    !props.patientData.OtherAbuseSubstance.state

  if (hasNoRelevantHistory || noRecordedInfo) {
    return (
      <NoRelevantHistory
        title="Substance-related and addictive"
        noRecordedInfo={noRecordedInfo}
        hasNoRelevantHistory={hasNoRelevantHistory}
      />
    )
  }

  return (
    <Form.Group className="indent">
      <Form.Row className="patient topic indent">
        Substance-related and addictive:
      </Form.Row>
      <Form.Group className="indent">
        {props.patientData.SUD.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.SUD.state)}
            />
            <span id="SUDShow">SUD</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.SUD.Notes}
          </Container>
        )}
        {props.patientData.Alcoholism.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.Alcoholism.state)}
            />
            <span id="AlcoholismShow">Other</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.Alcoholism.Notes}
          </Container>
        )}
        {props.patientData.OUD.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.OUD.state)}
            />
            <span id="OUDShow">OUD</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.OUD.Notes}
          </Container>
        )}
        {props.patientData.OtherSubstance.state && (
          <Container bsPrefix="psych">
            <Form.Check
              className="psych-checkbox"
              type="checkbox"
              checked={JSON.parse(props.patientData.OtherSubstance.state)}
            />
            <span id="OtherSubstanceShow">Other</span>
            <span className="subtopic" style={{ marginLeft: '25px' }}>
              Notes:{' '}
            </span>
            {props.patientData.OtherSubstance.Notes}
          </Container>
        )}
      </Form.Group>
      <Form.Group className="indent">
        <Container bsPrefix="psych">
          <Form.Row className="patient-dark">Substance use:</Form.Row>
        </Container>
        {props.patientData.Alcohol.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={props.patientData.Alcohol.currentPastUse}
              DrinksPerWeek={props.patientData.Alcohol.drinksPerWeek}
              BingeDrink={props.patientData.Alcohol.bingeDrink}
              DateRange={props.patientData.Alcohol.dateRange}
              Frequency={props.patientData.Alcohol.frequency}
              Quantity={props.patientData.Alcohol.quantity}
              Route={props.patientData.Alcohol.route}
              CAGE={props.patientData.Alcohol.cAGE}
              Notes={props.patientData.Alcohol.notes}
              Substance="Alcohol"
              SubstanceText="Alcohol"
            />
          </Accordion>
        )}
        {props.patientData.Tobacco.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={props.patientData.Tobacco.currentPastUse}
              DrinksPerWeek={props.patientData.Tobacco.drinksPerWeek}
              BingeDrink={props.patientData.Tobacco.bingeDrink}
              DateRange={props.patientData.Tobacco.dateRange}
              Frequency={props.patientData.Tobacco.frequency}
              Quantity={props.patientData.Tobacco.quantity}
              Route={props.patientData.Tobacco.route}
              CAGE={props.patientData.Tobacco.cAGE}
              Notes={props.patientData.Tobacco.notes}
              Substance="Tobacco"
              SubstanceText="Tobacco"
            />
          </Accordion>
        )}
        {props.patientData.Cannabis.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={props.patientData.Cannabis.currentPastUse}
              DrinksPerWeek={props.patientData.Cannabis.drinksPerWeek}
              BingeDrink={props.patientData.Cannabis.bingeDrink}
              DateRange={props.patientData.Cannabis.dateRange}
              Frequency={props.patientData.Cannabis.frequency}
              Quantity={props.patientData.Cannabis.quantity}
              Route={props.patientData.Cannabis.route}
              CAGE={props.patientData.Cannabis.cAGE}
              Notes={props.patientData.Cannabis.notes}
              Substance="Cannabis"
              SubstanceText="Cannabis"
            />
          </Accordion>
        )}
        {props.patientData.Cocaine.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={props.patientData.Cocaine.currentPastUse}
              DrinksPerWeek={props.patientData.Cocaine.drinksPerWeek}
              BingeDrink={props.patientData.Cocaine.bingeDrink}
              DateRange={props.patientData.Cocaine.dateRange}
              Frequency={props.patientData.Cocaine.frequency}
              Quantity={props.patientData.Cocaine.quantity}
              Route={props.patientData.Cocaine.route}
              CAGE={props.patientData.Cocaine.cAGE}
              Notes={props.patientData.Cocaine.notes}
              Substance="Cocaine"
              SubstanceText="Cocaine"
            />
          </Accordion>
        )}
        {props.patientData.Heroin.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={props.patientData.Heroin.currentPastUse}
              DrinksPerWeek={props.patientData.Heroin.drinksPerWeek}
              BingeDrink={props.patientData.Heroin.bingeDrink}
              DateRange={props.patientData.Heroin.dateRange}
              Frequency={props.patientData.Heroin.frequency}
              Quantity={props.patientData.Heroin.quantity}
              Route={props.patientData.Heroin.route}
              CAGE={props.patientData.Heroin.cAGE}
              Notes={props.patientData.Heroin.notes}
              Substance="Heroin"
              SubstanceText="Heroin"
            />
          </Accordion>
        )}
        {props.patientData.MethAmphetamines.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={props.patientData.MethAmphetamines.currentPastUse}
              DrinksPerWeek={props.patientData.MethAmphetamines.drinksPerWeek}
              BingeDrink={props.patientData.MethAmphetamines.bingeDrink}
              DateRange={props.patientData.MethAmphetamines.dateRange}
              Frequency={props.patientData.MethAmphetamines.frequency}
              Quantity={props.patientData.MethAmphetamines.quantity}
              Route={props.patientData.MethAmphetamines.route}
              CAGE={props.patientData.MethAmphetamines.cAGE}
              Notes={props.patientData.MethAmphetamines.notes}
              Substance="MethAmphetamines"
              SubstanceText="Meth/Amphetamines"
            />
          </Accordion>
        )}
        {props.patientData.MDMA.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={props.patientData.MDMA.currentPastUse}
              DrinksPerWeek={props.patientData.MDMA.drinksPerWeek}
              BingeDrink={props.patientData.MDMA.bingeDrink}
              DateRange={props.patientData.MDMA.dateRange}
              Frequency={props.patientData.MDMA.frequency}
              Quantity={props.patientData.MDMA.quantity}
              Route={props.patientData.MDMA.route}
              CAGE={props.patientData.MDMA.cAGE}
              Notes={props.patientData.MDMA.notes}
              Substance="MDMA"
              SubstanceText="MDMA"
            />
          </Accordion>
        )}
        {props.patientData.Psilocybin.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={props.patientData.Psilocybin.currentPastUse}
              DrinksPerWeek={props.patientData.Psilocybin.drinksPerWeek}
              BingeDrink={props.patientData.Psilocybin.bingeDrink}
              DateRange={props.patientData.Psilocybin.dateRange}
              Frequency={props.patientData.Psilocybin.frequency}
              Quantity={props.patientData.Psilocybin.quantity}
              Route={props.patientData.Psilocybin.route}
              CAGE={props.patientData.Psilocybin.cAGE}
              Notes={props.patientData.Psilocybin.notes}
              Substance="Psilocybin"
              SubstanceText="Psilocybin"
            />
          </Accordion>
        )}
        {props.patientData.OtherPsychedelics.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={
                props.patientData.OtherPsychedelics.currentPastUse
              }
              DrinksPerWeek={props.patientData.OtherPsychedelics.drinksPerWeek}
              BingeDrink={props.patientData.OtherPsychedelics.bingeDrink}
              DateRange={props.patientData.OtherPsychedelics.dateRange}
              Frequency={props.patientData.OtherPsychedelics.frequency}
              Quantity={props.patientData.OtherPsychedelics.quantity}
              Route={props.patientData.OtherPsychedelics.route}
              CAGE={props.patientData.OtherPsychedelics.cAGE}
              Notes={props.patientData.OtherPsychedelics.notes}
              Substance="OtherPsychedelics"
              SubstanceText="Other psychedelics and hallucinogens (PCP, LSD, DMT, etc)"
            />
          </Accordion>
        )}
        {props.patientData.Stimulants.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={props.patientData.Stimulants.currentPastUse}
              DrinksPerWeek={props.patientData.Stimulants.drinksPerWeek}
              BingeDrink={props.patientData.Stimulants.bingeDrink}
              DateRange={props.patientData.Stimulants.dateRange}
              Frequency={props.patientData.Stimulants.frequency}
              Quantity={props.patientData.Stimulants.quantity}
              Route={props.patientData.Stimulants.route}
              CAGE={props.patientData.Stimulants.cAGE}
              Notes={props.patientData.Stimulants.notes}
              Substance="Stimulants"
              SubstanceText="Stimulants (Adderall, Ritalin, Ephedrine, etc)"
            />
          </Accordion>
        )}
        {props.patientData.Sedatives.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={props.patientData.Sedatives.currentPastUse}
              DrinksPerWeek={props.patientData.Sedatives.drinksPerWeek}
              BingeDrink={props.patientData.Sedatives.bingeDrink}
              DateRange={props.patientData.Sedatives.dateRange}
              Frequency={props.patientData.Sedatives.frequency}
              Quantity={props.patientData.Sedatives.quantity}
              Route={props.patientData.Sedatives.route}
              CAGE={props.patientData.Sedatives.cAGE}
              Notes={props.patientData.Sedatives.notes}
              Substance="Sedatives"
              SubstanceText="Sedatives or tranquilizers (sleeping pills, Valium, etc)"
            />
          </Accordion>
        )}
        {props.patientData.OtherPrescription.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={
                props.patientData.OtherPrescription.currentPastUse
              }
              DrinksPerWeek={props.patientData.OtherPrescription.drinksPerWeek}
              BingeDrink={props.patientData.OtherPrescription.bingeDrink}
              DateRange={props.patientData.OtherPrescription.dateRange}
              Frequency={props.patientData.OtherPrescription.frequency}
              Quantity={props.patientData.OtherPrescription.quantity}
              Route={props.patientData.OtherPrescription.route}
              CAGE={props.patientData.OtherPrescription.cAGE}
              Notes={props.patientData.OtherPrescription.notes}
              Substance="OtherPrescription"
              SubstanceText="Other prescription medications that were not prescribed to the patient (e.g. pain medications, benzodiazepines, etc)"
            />
          </Accordion>
        )}
        {props.patientData.OtherAbuseSubstance.state && (
          <Accordion className="table-container">
            <SubstanceToDisplay
              type="active"
              CurrentPastUse={
                props.patientData.OtherAbuseSubstance.currentPastUse
              }
              DrinksPerWeek={
                props.patientData.OtherAbuseSubstance.drinksPerWeek
              }
              BingeDrink={props.patientData.OtherAbuseSubstance.bingeDrink}
              DateRange={props.patientData.OtherAbuseSubstance.dateRange}
              Frequency={props.patientData.OtherAbuseSubstance.frequency}
              Quantity={props.patientData.OtherAbuseSubstance.quantity}
              Route={props.patientData.OtherAbuseSubstance.route}
              CAGE={props.patientData.OtherAbuseSubstance.cAGE}
              Notes={props.patientData.OtherAbuseSubstance.notes}
              Substance="OtherPrescription"
              SubstanceText="Other (synthetics, inhalants, over the counter, etc)"
            />
          </Accordion>
        )}
        {!props.patientData.Alcohol.state &&
          !props.patientData.Tobacco.state &&
          !props.patientData.Cannabis.state &&
          !props.patientData.Cocaine.state &&
          !props.patientData.Heroin.state &&
          !props.patientData.MethAmphetamines.state &&
          !props.patientData.MDMA.state &&
          !props.patientData.Psilocybin.state &&
          !props.patientData.OtherPsychedelics.state &&
          !props.patientData.Stimulants.state &&
          !props.patientData.Sedatives.state &&
          !props.patientData.OtherPrescription.state &&
          !props.patientData.OtherAbuseSubstance.state && (
            <Container bsPrefix="psych">
              <Row bsPrefix="psych-sub">No substances listed</Row>
            </Container>
          )}
      </Form.Group>
      <Form.Group className="border-bottom indent">
        {props.patientData.SubstanceAdditionalNotes && (
          <Container bsPrefix="psych">
            <Form.Row className="patient-dark">Additional Notes:</Form.Row>
            <Row bsPrefix="psych-sub">
              {props.patientData.SubstanceAdditionalNotes}
            </Row>
          </Container>
        )}
      </Form.Group>
    </Form.Group>
  )
}
