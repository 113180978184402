import { Option, Sex, TAX_TYPE } from './types'

export const LEGAL_SEX_OPTIONS: Option[] = [
  {
    value: Sex.F,
    label: 'F',
  },
  {
    value: Sex.M,
    label: 'M',
  },
  {
    value: Sex.X,
    label: 'X',
  },
  {
    value: Sex.Unknown,
    label: 'Unknown',
  },
]

export const CLAIM_FILING_CODES = {
  BLUE_CROSS_BLUE_SHIELD: 'BL',
  DISABILITY: 'DS',
  COMMERCIAL_HMO: 'CI',
  MEDICARE_A: 'MA',
  MEDICARE_B: 'MB',
  MEDICAID: 'MC',
  OTHER_NON_FEDERAL_PROGRAM: '11',
  OTHER_FEDERAL_PROGRAM: 'OF',
  TRICARE_CHAMPUS: 'CH',
  VETERANS_AFFAIRS_PLAN: 'VA',
  WORKERS_COMPENSATION: 'WC',
  MUTUALLY_DEFINED: 'ZZ',
}

// map Payers.claim_insurance_type to a subset of the claimFilingCode
export const CLAIM_INSURANCE_TYPE_TO_FILING_CODE: { [index: string]: string } =
  {
    'Commercial/HMO': CLAIM_FILING_CODES.COMMERCIAL_HMO,
    Medicare: CLAIM_FILING_CODES.MEDICARE_B,
    'Blue Cross/Blue Shield': CLAIM_FILING_CODES.BLUE_CROSS_BLUE_SHIELD,
    'Workers Compensation': CLAIM_FILING_CODES.WORKERS_COMPENSATION,
    Medicaid: CLAIM_FILING_CODES.MEDICAID,
    'TRICARE/CHAMPUS': CLAIM_FILING_CODES.TRICARE_CHAMPUS,
  }

export const CLAIM_FILING_CODE_OPTIONS: Option[] = [
  {
    label: 'Blue Cross/Blue Shield',
    value: CLAIM_FILING_CODES.BLUE_CROSS_BLUE_SHIELD,
  },
  {
    label: 'Disability',
    value: CLAIM_FILING_CODES.DISABILITY,
  },
  {
    label: 'Commercial/HMO',
    value: CLAIM_FILING_CODES.COMMERCIAL_HMO,
  },
  {
    label: 'Medicare Part A',
    value: CLAIM_FILING_CODES.MEDICARE_A,
  },
  {
    label: 'Medicare Part B',
    value: CLAIM_FILING_CODES.MEDICARE_B,
  },
  {
    label: 'Medicaid',
    value: CLAIM_FILING_CODES.MEDICAID,
  },
  {
    label: 'Other non-federal program',
    value: CLAIM_FILING_CODES.OTHER_NON_FEDERAL_PROGRAM,
  },
  {
    label: 'Other federal program',
    value: CLAIM_FILING_CODES.OTHER_FEDERAL_PROGRAM,
  },
  {
    label: 'Tricare/Champus',
    value: CLAIM_FILING_CODES.TRICARE_CHAMPUS,
  },
  {
    label: 'Veterans Affairs Plan',
    value: CLAIM_FILING_CODES.VETERANS_AFFAIRS_PLAN,
  },
  {
    label: "Worker's Compensation Health Claim",
    value: CLAIM_FILING_CODES.WORKERS_COMPENSATION,
  },
  {
    label: 'Mutually Defined',
    value: CLAIM_FILING_CODES.MUTUALLY_DEFINED,
  },
]

export const MEDICARE_CLAIM_FILING_CODES = [
  CLAIM_FILING_CODES.MEDICARE_A,
  CLAIM_FILING_CODES.MEDICARE_B,
]
// insurance type code is only needed if secondary insurance is medicare
export const DEFAULT_INSURANCE_TYPE_CODE = '12'
export const INSURANCE_TYPE_CODE_OPTIONS: Option[] = [
  {
    label: `'12' Medicare Secondary Working Aged Beneficiary or Spouse with Employer Group Health Plan`,
    value: '12',
  },
  {
    label: `'13' Medicare Secondary End-Stage Renal Disease Beneficiary in the Mandated Coordination Period`,
    value: '13',
  },
  {
    label: `'14' Medicare Secondary, No-fault Insurance including Auto is Primary`,
    value: '14',
  },
  {
    label: `'15' Medicare Secondary Worker's Compensation`,
    value: '15',
  },
  {
    label: `'16' Medicare Secondary Public Health Service (PHS) or Other Federal Agency`,
    value: '16',
  },
  {
    label: `'41' Medicare Secondary Black Lung`,
    value: '41',
  },
  {
    label: `'42' Medicare Secondary Veteran's Administration`,
    value: '42',
  },
  {
    label: `'43' Medicare Secondary Disabled Beneficiary Under Age 65 with Large Group Health Plan (LGHP)`,
    value: '43',
  },
  {
    label: `'47' Medicare Secondary, Other Liability Insurance is Primary`,
    value: '47',
  },
]

export const BOOL_OPTIONS: Option[] = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
]

export const RELATIONSHIP = {
  SELF: 'Self',
  CHILD: 'Child',
  SPOUSE: 'Spouse',
  PARENT: 'Parent',
  GRANDPARENT: 'Grandparent',
  SIBLING: 'Sibling',
  PARTNER: 'Partner',
  OTHER: 'Other',
}

export const RELATIONSHIP_OPTIONS: Option[] = [
  {
    value: RELATIONSHIP.SELF,
    label: 'Self',
  },
  {
    value: RELATIONSHIP.CHILD,
    label: 'Child',
  },
  {
    value: RELATIONSHIP.GRANDPARENT,
    label: 'Grandparent',
  },
  {
    value: RELATIONSHIP.PARENT,
    label: 'Parent',
  },
  {
    value: RELATIONSHIP.PARTNER,
    label: 'Partner',
  },
  {
    value: RELATIONSHIP.SIBLING,
    label: 'Sibling',
  },
  {
    value: RELATIONSHIP.SPOUSE,
    label: 'Spouse',
  },
  {
    value: RELATIONSHIP.OTHER,
    label: 'Other',
  },
]

export const TAX_TYPE_OPTIONS: Option[] = [
  {
    value: TAX_TYPE.EIN,
    label: 'EIN',
  },
  {
    value: TAX_TYPE.SSN,
    label: 'SSN',
  },
]

export const BILLING_TYPE_OPTIONS: Option[] = [
  {
    value: 'Provider',
    label: 'Provider',
  },
  {
    value: 'Practice',
    label: 'Practice',
  },
]

export const STATE_OPTIONS: Option[] = [
  {
    label: 'AL',
    value: 'AL',
  },
  {
    label: 'AK',
    value: 'AK',
  },
  {
    label: 'AS',
    value: 'AS',
  },
  {
    label: 'AZ',
    value: 'AZ',
  },
  {
    label: 'AR',
    value: 'AR',
  },
  {
    label: 'CA',
    value: 'CA',
  },
  {
    label: 'CO',
    value: 'CO',
  },
  {
    label: 'CT',
    value: 'CT',
  },
  {
    label: 'DE',
    value: 'DE',
  },
  {
    label: 'DC',
    value: 'DC',
  },
  {
    label: 'FM',
    value: 'FM',
  },
  {
    label: 'FL',
    value: 'FL',
  },
  {
    label: 'GA',
    value: 'GA',
  },
  {
    label: 'GU',
    value: 'GU',
  },
  {
    label: 'HI',
    value: 'HI',
  },
  {
    label: 'ID',
    value: 'ID',
  },
  {
    label: 'IL',
    value: 'IL',
  },
  {
    label: 'IN',
    value: 'IN',
  },
  {
    label: 'IA',
    value: 'IA',
  },
  {
    label: 'KS',
    value: 'KS',
  },
  {
    label: 'KY',
    value: 'KY',
  },
  {
    label: 'LA',
    value: 'LA',
  },
  {
    label: 'ME',
    value: 'ME',
  },
  {
    label: 'MH',
    value: 'MH',
  },
  {
    label: 'MD',
    value: 'MD',
  },
  {
    label: 'MA',
    value: 'MA',
  },
  {
    label: 'MI',
    value: 'MI',
  },
  {
    label: 'MN',
    value: 'MN',
  },
  {
    label: 'MS',
    value: 'MS',
  },
  {
    label: 'MO',
    value: 'MO',
  },
  {
    label: 'MT',
    value: 'MT',
  },
  {
    label: 'NE',
    value: 'NE',
  },
  {
    label: 'NV',
    value: 'NV',
  },
  {
    label: 'NH',
    value: 'NH',
  },
  {
    label: 'NJ',
    value: 'NJ',
  },
  {
    label: 'NM',
    value: 'NM',
  },
  {
    label: 'NY',
    value: 'NY',
  },
  {
    label: 'NC',
    value: 'NC',
  },
  {
    label: 'ND',
    value: 'ND',
  },
  {
    label: 'MP',
    value: 'MP',
  },
  {
    label: 'OH',
    value: 'OH',
  },
  {
    label: 'OK',
    value: 'OK',
  },
  {
    label: 'OR',
    value: 'OR',
  },
  {
    label: 'PW',
    value: 'PW',
  },
  {
    label: 'PA',
    value: 'PA',
  },
  {
    label: 'PR',
    value: 'PR',
  },
  {
    label: 'RI',
    value: 'RI',
  },
  {
    label: 'SC',
    value: 'SC',
  },
  {
    label: 'SD',
    value: 'SD',
  },
  {
    label: 'TN',
    value: 'TN',
  },
  {
    label: 'TX',
    value: 'TX',
  },
  {
    label: 'UT',
    value: 'UT',
  },
  {
    label: 'VT',
    value: 'VT',
  },
  {
    label: 'VI',
    value: 'VI',
  },
  {
    label: 'VA',
    value: 'VA',
  },
  {
    label: 'WA',
    value: 'WA',
  },
  {
    label: 'WV',
    value: 'WV',
  },
  {
    label: 'WI',
    value: 'WI',
  },
  {
    label: 'WY',
    value: 'WY',
  },
]

/*
  "order" is a required integer in InsuranceClaimDiagnosis modal.
  It does not make sense for an excluded diagnosis, setting it to -1 to differentiate from included diagnosis.
*/
export const EXCLUDED_DIAGNOSIS_ORDER = -1

export const sectionContainerBodyStyle = { paddingBottom: 0 }
export const sectionContainerStyle = { marginTop: 24 }
