import { useQuery } from '@tanstack/react-query'

import { fetchNote } from '../api'
import { NOTE_QUERY_KEY } from '../constants'

export interface UseNoteDataQueryParams {
  includeInsuranceClaims?: boolean
}

export const getNoteQueryKey = (
  noteId: string | undefined,
  queryParams: UseNoteDataQueryParams = {}
) => {
  return [NOTE_QUERY_KEY, noteId, queryParams]
}

export const useNoteData = (
  noteId: string | undefined,
  queryParams: UseNoteDataQueryParams = {}
) => {
  const queryKey = getNoteQueryKey(noteId, queryParams)
  return useQuery(queryKey, () => noteId && fetchNote(noteId, queryParams), {
    enabled: !!noteId,
  })
}
