// Import react
import React, { useEffect, useState } from 'react'

// Import components/containers
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap'

// Import libs/others
import {
  submitEditPsychHistoryRequest,
  submitNewPsychHistoryRequest,
} from '../../api/api-lib'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import Anxiety from './PsychiatricSubsections/Anxiety'
import AnxietySaved from './PsychiatricSubsections/AnxietySaved.jsx'
import DangerToSelfSaved from './PsychiatricSubsections/DangerToSelfSaved.jsx'
import Depressive from './PsychiatricSubsections/Depressive.jsx'
import DepressiveSaved from './PsychiatricSubsections/DepressiveSaved.jsx'
import FeedingEating from './PsychiatricSubsections/FeedingEating.jsx'
import FeedingEatingSaved from './PsychiatricSubsections/FeedingEatingSaved.jsx'
import HospitalizationSaved from './PsychiatricSubsections/HospitalizationSaved.jsx'
import Hospitalizations from './PsychiatricSubsections/Hospitalizations'
import ManicHypomanicBipolar from './PsychiatricSubsections/ManicHypomanicBipolar'
import ManicSaved from './PsychiatricSubsections/ManicSaved.jsx'
import Neurocognitive from './PsychiatricSubsections/Neurocognitive.jsx'
import NeurocognitiveSaved from './PsychiatricSubsections/NeurocognitiveSaved.jsx'
import Neurodevelopmental from './PsychiatricSubsections/Neurodevelopmental.jsx'
import NeurodevelopmentalSaved from './PsychiatricSubsections/NeurodevelopmentalSaved.jsx'
import ObsessiveCompulsive from './PsychiatricSubsections/ObsessiveCompulsive.jsx'
import ObsessiveCompulsiveSaved from './PsychiatricSubsections/ObsessiveCompulsiveSaved.jsx'
import OtherConditions from './PsychiatricSubsections/OtherConditions.jsx'
import OtherConditionsSaved from './PsychiatricSubsections/OtherConditionsSaved.jsx'
import PTSDTrauma from './PsychiatricSubsections/PTSDTrauma.jsx'
import PTSDTraumaSaved from './PsychiatricSubsections/PTSDTraumaSaved.jsx'
import Psychotic from './PsychiatricSubsections/Psychotic.jsx'
import PsychoticSaved from './PsychiatricSubsections/PsychoticSaved.jsx'
import SubstanceAddictive from './PsychiatricSubsections/SubstanceAddictive.jsx'
import SubstanceRelatedAndAddictiveSaved from './PsychiatricSubsections/SubstanceRelatedAndAddictiveSaved.jsx'
import SuicidalityDanger from './PsychiatricSubsections/SuicidalityDanger'
import Treatments from './PsychiatricSubsections/Treatments'
import TreatmentsSaved from './PsychiatricSubsections/TreatmentsSaved.jsx'
import { patientDataTypes } from './patient-data-types'

// Import styles
import './PatientProfile.scss'

export default function PsychiatricHistory(props) {
  const substanceUseModel = {
    state: false,
    currentPastUse: '',
    bingeDrink: '',
    drinksPerWeek: '',
    dateRange: [{ startDate: '', endDate: '', dateFormat: 'MM/YYYY' }],
    frequency: '',
    quantity: '',
    route: '',
    cAGE: {
      state: false,
      CuttingDown: false,
      AnnoyedCriticism: false,
      UseGuilt: false,
      MorningUse: false,
    },
    notes: '',
  }
  const notesModel = { state: false, Notes: '' }
  const countingModel = { state: false, NumberEpisodes: 0, Notes: '' }
  const SIModel = {
    state: false,
    Frequency: '',
    Notes: '',
    Date: '',
    DateFormat: 'MM/YYYY',
  }
  const PSIModel = {
    state: false,
    Frequency: '',
    Notes: '',
    StartDate: '',
    EndDate: '',
    DateFormat: 'MM/YYYY',
  }
  const VOModel = {
    state: false,
    Description: '',
    StartDate: '',
    EndDate: '',
    DateFormat: 'MM/YYYY',
  }
  // left column size of the table (11-block grid system)
  const [editPsychiatricHistory, setEditPsychiatricHistory] = useState(false)
  const [shouldEdit, setShouldEdit] = useState(
    props.PsychHistory?.CreatedAt || false
  )
  const [patientData, setPatientData] = useState({
    PatientId: props.Patient.PatientId,
    Treatments: props.PsychHistory?.Treatments
      ? props.PsychHistory.Treatments.treatments
      : [],
    NoPreviousTreatments: props.PsychHistory?.Treatments
      ? props.PsychHistory.Treatments.noPreviousTreatments
      : false,
    NoPreviousHospitalizations: props.PsychHistory?.PreviousHospitalizations
      ? props.PsychHistory.PreviousHospitalizations.noPreviousHospitalizations
      : false,
    PreviousHospitalizations: props.PsychHistory?.PreviousHospitalizations
      ? props.PsychHistory.PreviousHospitalizations.hospitalizations
      : [],
    MajorDepressiveDisorder: props.PsychHistory?.Depressive
      ? props.PsychHistory.Depressive.majorDepressiveDisorder
      : countingModel,
    TreatmentResistantDepression: props.PsychHistory?.Depressive
      ? props.PsychHistory.Depressive.treatmentResistantDepression
      : countingModel,
    DepressionAnxiousDistress: props.PsychHistory?.Depressive
      ? props.PsychHistory.Depressive.depressionAnxiousDistress
      : countingModel,
    OtherDepressive: props.PsychHistory?.Depressive
      ? props.PsychHistory.Depressive.otherDepressive
      : notesModel,
    NoRelevantDepressive: props.PsychHistory?.Depressive
      ? props.PsychHistory.Depressive.noRelevantDepressive
      : false,
    DepressiveAdditionalNotes: props.PsychHistory?.Depressive
      ? props.PsychHistory.Depressive.depressiveAdditionalNotes
      : '',
    SuicideAttempts: props.PsychHistory?.DangerToSelf
      ? props.PsychHistory.DangerToSelf.suicideAttempts
      : [],
    SuicidalIdeation: props.PsychHistory?.DangerToSelf
      ? props.PsychHistory.DangerToSelf.suicidalIdeation
      : SIModel,
    PreviousSuicidalIdeation: props.PsychHistory?.DangerToSelf
      ? props.PsychHistory.DangerToSelf.previousSuicidalIdeation
      : PSIModel,
    SelfHarm: props.PsychHistory?.DangerToSelf
      ? props.PsychHistory.DangerToSelf.selfHarm
      : [],
    ViolenceToOthers: props.PsychHistory?.DangerToSelf
      ? props.PsychHistory.DangerToSelf.violenceToOthers
      : VOModel,
    NoSuicideDanger: props.PsychHistory?.DangerToSelf
      ? props.PsychHistory.DangerToSelf.noSuicideDanger
      : false,
    SuicideAdditionalNotes: props.PsychHistory?.DangerToSelf
      ? props.PsychHistory.DangerToSelf.suicideAdditionalNotes
      : '',
    BipolarI: props.PsychHistory?.Manic
      ? props.PsychHistory.Manic.bipolarI
      : countingModel,
    BipolarII: props.PsychHistory?.Manic
      ? props.PsychHistory.Manic.bipolarII
      : countingModel,
    OtherManic: props.PsychHistory?.Manic
      ? props.PsychHistory.Manic.otherManic
      : notesModel,
    IrritableMood: props.PsychHistory?.Manic
      ? props.PsychHistory.Manic.irritableMood
      : {
          state: false,
          IncreasedImpulsivity: false,
          TalkingFaster: false,
          MoreEnergy: false,
          ExcessiveConfidence: false,
          FasterThoughts: false,
          IncreasedAgitation: false,
          IncreasedDistractibility: false,
          ChangeMedication: false,
          Other: false,
          OtherText: '',
        },
    NoManicBipolar: props.PsychHistory?.Manic
      ? props.PsychHistory.Manic.noManicBipolar
      : false,
    ManicAdditionalNotes: props.PsychHistory?.Manic
      ? props.PsychHistory.Manic.manicAdditionalNotes
      : '',
    GeneralizedAnxietyDisorder: props.PsychHistory?.Anxiety
      ? props.PsychHistory.Anxiety.generalizedAnxietyDisorder
      : notesModel,
    PanicDisorder: props.PsychHistory?.Anxiety
      ? props.PsychHistory.Anxiety.panicDisorder
      : notesModel,
    Phobias: props.PsychHistory?.Anxiety
      ? props.PsychHistory.Anxiety.phobias
      : notesModel,
    OtherAnxiety: props.PsychHistory?.Anxiety
      ? props.PsychHistory.Anxiety.otherAnxiety
      : notesModel,
    AnxietySymptoms: props.PsychHistory?.Anxiety
      ? props.PsychHistory.Anxiety.anxietySymptoms
      : {
          state: false,
          MuscleTension: false,
          SleepDisruption: false,
          PanicAttacks: false,
          SocialAnxiety: false,
          CurrentStressEvents: false,
          Other: false,
          OtherText: '',
        },
    NoAnxiety: props.PsychHistory?.Anxiety
      ? props.PsychHistory.Anxiety.noAnxiety
      : false,
    AnxietyAdditionalNotes: props.PsychHistory?.Anxiety
      ? props.PsychHistory.Anxiety.anxietyAdditionalNotes
      : '',
    PTSD: props.PsychHistory?.PTSDAndTrauma
      ? props.PsychHistory.PTSDAndTrauma.pTSD
      : notesModel,
    OtherPTSD: props.PsychHistory?.PTSDAndTrauma
      ? props.PsychHistory.PTSDAndTrauma.otherPTSD
      : notesModel,
    TraumaAbuseHistory: props.PsychHistory?.PTSDAndTrauma
      ? props.PsychHistory.PTSDAndTrauma.traumaAbuseHistory
      : notesModel,
    NoPTSDTrauma: props.PsychHistory?.PTSDAndTrauma
      ? props.PsychHistory.PTSDAndTrauma.noPTSDTrauma
      : false,
    PTSDTraumaAdditionalNotes: props.PsychHistory?.PTSDAndTrauma
      ? props.PsychHistory.PTSDAndTrauma.pTSDTraumaAdditionalNotes
      : '',
    OCD: props.PsychHistory?.ObsessiveCompulsive
      ? props.PsychHistory.ObsessiveCompulsive.oCD
      : notesModel,
    OtherObsessiveCompulsive: props.PsychHistory?.ObsessiveCompulsive
      ? props.PsychHistory.ObsessiveCompulsive.otherObsessiveCompulsive
      : notesModel,
    UnwantedThoughts: props.PsychHistory?.ObsessiveCompulsive
      ? props.PsychHistory.ObsessiveCompulsive.unwantedThoughts
      : notesModel,
    NoObsessiveCompulsive: props.PsychHistory?.ObsessiveCompulsive
      ? props.PsychHistory.ObsessiveCompulsive.noObsessiveCompulsive
      : false,
    ObsessiveCompulsiveAdditionalNotes: props.PsychHistory?.ObsessiveCompulsive
      ? props.PsychHistory.ObsessiveCompulsive
          .obsessiveCompulsiveAdditionalNotes
      : '',
    SUD: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.sUD
      : notesModel,
    Alcoholism: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.alcoholism
      : notesModel,
    OUD: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.oUD
      : notesModel,
    OtherSubstance: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.otherSubstance
      : notesModel,
    NoSubstanceAddiction: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.noSubstanceAddiction
      : false,
    Alcohol: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.alcohol
      : substanceUseModel,
    Tobacco: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.tobacco
      : substanceUseModel,
    Cannabis: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.cannabis
      : substanceUseModel,
    Cocaine: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.cocaine
      : substanceUseModel,
    Heroin: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.heroin
      : substanceUseModel,
    MethAmphetamines: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.methAmphetamines
      : substanceUseModel,
    MDMA: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.mDMA
      : substanceUseModel,
    Psilocybin: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.psilocybin
      : substanceUseModel,
    OtherPsychedelics: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.otherPsychedelics
      : substanceUseModel,
    Stimulants: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.stimulants
      : substanceUseModel,
    Sedatives: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.sedatives
      : substanceUseModel,
    OtherPrescription: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.otherPrescription
      : substanceUseModel,
    OtherAbuseSubstance: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.otherAbuseSubstance
      : substanceUseModel,
    SubstanceAdditionalNotes: props.PsychHistory?.SubstanceRelatedAndAddictive
      ? props.PsychHistory.SubstanceRelatedAndAddictive.substanceAdditionalNotes
      : '',
    Schizophrenia: props.PsychHistory?.Pyschotic
      ? props.PsychHistory.Pyschotic.schizophrenia
      : notesModel,
    PsychoticOther: props.PsychHistory?.Pyschotic
      ? props.PsychHistory.Pyschotic.psychoticOther
      : notesModel,
    PsychoticSymptoms: props.PsychHistory?.Pyschotic
      ? props.PsychHistory.Pyschotic.psychoticSymptoms
      : {
          state: false,
          AuditoryHallucinations: false,
          VisualHallucinations: false,
          SuspiciousThoughts: false,
          Paranoia: false,
          IdeasReference: false,
          ThoughtBroadcasting: false,
          ThoughtInsertion: false,
          BeliefsReality: false,
          Other: false,
          OtherText: '',
        },
    NoPsychotic: props.PsychHistory?.Pyschotic
      ? props.PsychHistory.Pyschotic.noPsychotic
      : false,
    PsychoticAdditionalNotes: props.PsychHistory?.Pyschotic
      ? props.PsychHistory.Pyschotic.psychoticAdditionalNotes
      : '',
    Anorexia: props.PsychHistory?.FeedingAndEating
      ? props.PsychHistory.FeedingAndEating.anorexia
      : notesModel,
    Bulimia: props.PsychHistory?.FeedingAndEating
      ? props.PsychHistory.FeedingAndEating.bulimia
      : notesModel,
    BingeEatingDisorder: props.PsychHistory?.FeedingAndEating
      ? props.PsychHistory.FeedingAndEating.bingeEatingDisorder
      : notesModel,
    OtherEating: props.PsychHistory?.FeedingAndEating
      ? props.PsychHistory.FeedingAndEating.otherEating
      : notesModel,
    EatingSymptoms: props.PsychHistory?.FeedingAndEating
      ? props.PsychHistory.FeedingAndEating.eatingSymptoms
      : {
          state: false,
          RestrictingFood: false,
          Purging: false,
          BodyImage: false,
          ExcessiveEating: false,
          Other: false,
          OtherText: '',
        },
    NoEatingDisorder: props.PsychHistory?.FeedingAndEating
      ? props.PsychHistory.FeedingAndEating.noEatingDisorder
      : false,
    EatingAdditionalNotes: props.PsychHistory?.FeedingAndEating
      ? props.PsychHistory.FeedingAndEating.eatingAdditionalNotes
      : '',
    AttentionDeficit: props.PsychHistory?.Neurodevelopmental
      ? props.PsychHistory.Neurodevelopmental.attentionDeficit
      : notesModel,
    LearningDisorders: props.PsychHistory?.Neurodevelopmental
      ? props.PsychHistory.Neurodevelopmental.learningDisorders
      : notesModel,
    Autism: props.PsychHistory?.Neurodevelopmental
      ? props.PsychHistory.Neurodevelopmental.autism
      : notesModel,
    OtherNeurodevelopmental: props.PsychHistory?.Neurodevelopmental
      ? props.PsychHistory.Neurodevelopmental.otherNeurodevelopmental
      : notesModel,
    NoNeurodevelopmental: props.PsychHistory?.Neurodevelopmental
      ? props.PsychHistory.Neurodevelopmental.noNeurodevelopmental
      : false,
    NeurodevelopmentalAdditionalNotes: props.PsychHistory?.Neurodevelopmental
      ? props.PsychHistory.Neurodevelopmental.neurodevelopmentalAdditionalNotes
      : '',
    Delirium: props.PsychHistory?.Neurocognitive
      ? props.PsychHistory.Neurocognitive.delirium
      : notesModel,
    MayorNeurocognitiveDisorder: props.PsychHistory?.Neurocognitive
      ? props.PsychHistory.Neurocognitive.mayorNeurocognitiveDisorder
      : notesModel,
    MinorNeurocognitiveDisorder: props.PsychHistory?.Neurocognitive
      ? props.PsychHistory.Neurocognitive.minorNeurocognitiveDisorder
      : notesModel,
    OtherNeurocognitive: props.PsychHistory?.Neurocognitive
      ? props.PsychHistory.Neurocognitive.otherNeurocognitive
      : notesModel,
    NeurocognitiveSymptoms: props.PsychHistory?.Neurocognitive
      ? props.PsychHistory.Neurocognitive.neurocognitiveSymptoms
      : {
          state: false,
          Confusion: false,
          MemoryLoss: false,
          Other: false,
          OtherText: '',
        },
    NoNeurocognitive: props.PsychHistory?.Neurocognitive
      ? props.PsychHistory.Neurocognitive.noNeurocognitive
      : false,
    NeurocognitiveAdditionalNotes: props.PsychHistory?.Neurocognitive
      ? props.PsychHistory.Neurocognitive.neurocognitiveAdditionalNotes
      : '',

    NoOtherConditions: props.PsychHistory?.OtherConditions
      ? props.PsychHistory.OtherConditions.noOtherConditions
      : false,
    OtherConditionsAdditionalNotes: props.PsychHistory?.OtherConditions
      ? props.PsychHistory.OtherConditions.otherConditionsAdditionalNotes
      : '',

    CreatedBy: props.PsychHistory?.CreatedBy
      ? props.PsychHistory.CreatedBy
      : '',
    CreatedAt: props.PsychHistory?.CreatedAt
      ? props.PsychHistory.CreatedAt
      : '',
    UpdateHistory: props.PsychHistory?.UpdateHistory
      ? props.PsychHistory.UpdateHistory
      : [],
  })

  const [patientDataCopy, setPatientDataCopy] = useState(patientData)

  useEffect(() => {
    setPatientDataCopy(patientData)
  }, [editPsychiatricHistory])

  function revert() {
    setPatientData(patientDataCopy)
  }

  async function handleSubmit(_event) {
    setEditPsychiatricHistory(false)
    try {
      if (shouldEdit) {
        await submitEditPsychHistoryRequest(patientData)
      } else {
        await submitNewPsychHistoryRequest(patientData)
        setShouldEdit(true)
      }
      props.handleApiChange(patientDataTypes.ProviderSidePatientData)
      notification(
        "You have successfully updated the patient's psychiatric history.",
        'success'
      )
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    }
  }

  function saveNewTreatment() {
    const newTreatment = [
      {
        treatmentType: '',
        therapyType: '',
        tumberSessions: '',
        startDate: '',
        endDate: '',
        reasonForTreatment: '',
        treatmentEfficacy: '',
        nameOfClinician: '',
        additionalNotes: '',
        dateformat: 'MM/YYYY',
      },
    ]
    setPatientData({
      ...patientData,
      Treatments: patientData.Treatments.concat(newTreatment),
    })
  }

  function saveNewHospitalization() {
    const newHospitalization = [
      {
        reasonForHospitalization: '',
        lenghtOfHospitalization: '',
        startDate: '',
        endDate: '',
        hospitalName: '',
        additionalNotes: '',
        dateformat: 'MM/YYYY',
      },
    ]
    setPatientData({
      ...patientData,
      PreviousHospitalizations:
        patientData.PreviousHospitalizations.concat(newHospitalization),
    })
  }

  function saveNewSuicideAttempt() {
    const newAttempt = [
      {
        description: '',
        startDate: '',
        endDate: '',
        dateformat: 'MM/YYYY',
      },
    ]
    setPatientData({
      ...patientData,
      SuicideAttempts: patientData.SuicideAttempts.concat(newAttempt),
    })
  }

  function saveNewSelfHarm() {
    const newAttempt = [
      {
        description: '',
        startDate: '',
        endDate: '',
        dateformat: 'MM/YYYY',
      },
    ]
    setPatientData({
      ...patientData,
      SelfHarm: patientData.SelfHarm.concat(newAttempt),
    })
  }

  function saveTreatment(treatment, index) {
    const addTreatment = patientData.Treatments.slice()
    addTreatment[index] = treatment
    setPatientData({ ...patientData, Treatments: addTreatment })
  }

  function saveHospitalization(data, index) {
    const addHospitalization = patientData.PreviousHospitalizations.slice()
    addHospitalization[index] = data
    setPatientData({
      ...patientData,
      PreviousHospitalizations: addHospitalization,
    })
  }

  function saveAttempt(attempt, index) {
    const addAttempt = patientData.SuicideAttempts.slice()
    addAttempt[index] = attempt
    setPatientData({ ...patientData, SuicideAttempts: addAttempt })
  }

  function saveSelfHarm(attempt, index) {
    const addAttempt = patientData.SelfHarm.slice()
    addAttempt[index] = attempt
    setPatientData({ ...patientData, SelfHarm: addAttempt })
  }

  function deleteTreatment(index) {
    const array = patientData.Treatments.slice()
    array.splice(index, 1)
    setPatientData({ ...patientData, Treatments: array })
  }

  function deleteAttempt(index) {
    const array = patientData.SuicideAttempts.slice()
    array.splice(index, 1)
    setPatientData({ ...patientData, SuicideAttempts: array })
  }

  function deleteHospitalization(index) {
    const array = patientData.PreviousHospitalizations.slice()
    array.splice(index, 1)
    setPatientData({ ...patientData, PreviousHospitalizations: array })
  }

  function deleteSelfHarm(index) {
    const array = patientData.SelfHarm.slice()
    array.splice(index, 1)
    setPatientData({ ...patientData, SelfHarm: array })
  }

  // patient notes already stored
  const savedTable = (
    <Container>
      <TreatmentsSaved patientData={patientData} />
      <HospitalizationSaved patientData={patientData} />
      <DepressiveSaved patientData={patientData} />
      <DangerToSelfSaved patientData={patientData} />
      <ManicSaved patientData={patientData} />
      <AnxietySaved patientData={patientData} />
      <PTSDTraumaSaved patientData={patientData} />
      <ObsessiveCompulsiveSaved patientData={patientData} />
      <SubstanceRelatedAndAddictiveSaved patientData={patientData} />
      <PsychoticSaved patientData={patientData} />
      <FeedingEatingSaved patientData={patientData} />
      <NeurodevelopmentalSaved patientData={patientData} />
      <NeurocognitiveSaved patientData={patientData} />
      <OtherConditionsSaved patientData={patientData} />
      {/* EDIT BUTTON */}
      <Row>
        <Col>
          <Button
            bsPrefix="button-block icon-button"
            onClick={() => setEditPsychiatricHistory(true)}
          >
            <i className="fa fa-pencil-square-o" aria-hidden="true" />
            &nbsp; Edit record
          </Button>
          <br />
        </Col>
      </Row>
    </Container>
  )

  // brace yourself, there be forms here
  const editableTable = (
    <Form
      as={Container}
      noValidate
      onSubmit={handleSubmit}
      style={{ padding: '0px' }}
    >
      <Treatments
        patientData={patientData}
        setPatientData={setPatientData}
        disabled={!editPsychiatricHistory}
        saveNewTreatment={saveNewTreatment}
        saveTreatment={saveTreatment}
        deleteTreatment={deleteTreatment}
      />
      <Hospitalizations
        patientId={props.Patient.PatientId}
        providerId={props.ProviderId}
        patientData={patientData}
        setPatientData={setPatientData}
        disabled={!editPsychiatricHistory}
        saveNewHospitalization={saveNewHospitalization}
        saveHospitalization={saveHospitalization}
        deleteHospitalization={deleteHospitalization}
      />
      <Depressive
        patientId={props.Patient.PatientId}
        providerId={props.ProviderId}
        patientData={patientData}
        setPatientData={setPatientData}
      />
      <SuicidalityDanger
        patientId={props.Patient.PatientId}
        providerId={props.ProviderId}
        patientData={patientData}
        setPatientData={setPatientData}
        saveNewSuicideAttempt={saveNewSuicideAttempt}
        saveAttempt={saveAttempt}
        deleteAttempt={deleteAttempt}
        saveNewSelfHarm={saveNewSelfHarm}
        saveSelfHarm={saveSelfHarm}
        deleteSelfHarm={deleteSelfHarm}
      />
      <ManicHypomanicBipolar
        patientId={props.Patient.PatientId}
        providerId={props.ProviderId}
        patientData={patientData}
        setPatientData={setPatientData}
      />
      <Anxiety
        patientId={props.Patient.PatientId}
        providerId={props.ProviderId}
        patientData={patientData}
        setPatientData={setPatientData}
      />
      <PTSDTrauma
        patientId={props.Patient.PatientId}
        providerId={props.ProviderId}
        patientData={patientData}
        setPatientData={setPatientData}
      />
      <ObsessiveCompulsive
        patientData={patientData}
        setPatientData={setPatientData}
      />
      <SubstanceAddictive
        patientData={patientData}
        setPatientData={setPatientData}
      />
      <Psychotic patientData={patientData} setPatientData={setPatientData} />
      <FeedingEating
        patientData={patientData}
        setPatientData={setPatientData}
      />
      <Neurodevelopmental
        patientData={patientData}
        setPatientData={setPatientData}
      />
      <Neurocognitive
        patientData={patientData}
        setPatientData={setPatientData}
      />
      <OtherConditions
        patientData={patientData}
        setPatientData={setPatientData}
      />
      {/* SAVE BUTTON */}
      <Form.Row className="justify-contents-center">
        <Form.Group sm={12} md={5} lg={{ span: 4, offset: 2 }} as={Col}>
          <Button
            bsPrefix="button-block icon-button"
            onClick={() => {
              setEditPsychiatricHistory(false)
              handleSubmit()
            }}
          >
            <i className="fa fa-floppy-o" aria-hidden="true" />
            &nbsp; Save record
          </Button>
        </Form.Group>
        <Form.Group sm={12} md={5} as={Col}>
          <Button
            bsPrefix="button-close icon-button"
            onClick={() => {
              revert()
              setEditPsychiatricHistory(false)
            }}
          >
            <i className="fa fa-times" aria-hidden="true" />
            &nbsp; Cancel
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  )

  const psychiatricHistory = (
    <Accordion defaultActiveKey="0">
      <Accordion.Toggle
        bsPrefix="accordion-header very-large accordion-toggle"
        as={Card.Header}
        eventKey="1"
      >
        <div style={{ cursor: 'pointer' }}>Psychiatric History</div>
      </Accordion.Toggle>

      {!editPsychiatricHistory ? (
        <Accordion.Collapse eventKey="1">{savedTable}</Accordion.Collapse>
      ) : (
        <Accordion.Collapse eventKey="1">{editableTable}</Accordion.Collapse>
      )}
    </Accordion>
  )

  return <>{psychiatricHistory}</>
}
