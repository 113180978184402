import React, { useState } from 'react'

import { MailFilled, PlusOutlined } from '@ant-design/icons'
import { Divider } from 'antd'
import cx from 'classnames'
import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { View } from 'react-big-calendar'

import { useSchedulingNotifications } from '../../../hooks/useSchedulingNotifications'
import {
  Button,
  Col,
  Option,
  Row,
  Select,
  Switch,
  Text,
} from '../../BaseComponents'
import NavigateButtons from '../NavigateButtons'
import { Timezone, Views } from '../constants'
import { convertOffsetToTimezomeAbbreviation } from '../helpers'

import styles from './TopToolbar.module.scss'

export interface PatientOption {
  name: string
  value: string
}

interface TopToolbarProps {
  patients: PatientOption[]
  currentDate: Date
  currentView: Views
  currentPatient?: string
  onViewChange: (view: View) => void
  onPatientChange: (patientId: string) => void
  onCreateEventClick: () => void
  onNotificationClick: () => void
  currentTimezone: Timezone
  onNavigationChange: (selectedDate: Date) => void
  toggleShowWeekends: () => void
  showWeekends?: boolean
  onClearPatient: () => void
}

export const TopToolbar: React.FC<TopToolbarProps> = ({
  patients,
  currentDate,
  currentView,
  currentPatient,
  onViewChange,
  onPatientChange,
  onCreateEventClick,
  onNotificationClick,
  currentTimezone,
  onNavigationChange,
  toggleShowWeekends,
  showWeekends,
  onClearPatient,
}) => {
  const { totalNotifications } = useSchedulingNotifications({
    appointmentStatuses: ['PENDING'],
  })
  const [showSelectView, setShowSelectView] = useState(false)
  const DateFormat = currentView === Views.DAY ? 'MMMM dd, yyyy' : 'MMMM yyyy'

  const viewOptions = () =>
    Object.values(Views).map((viewName: string) => (
      <Option key={viewName} value={viewName}>
        {`${viewName[0].toUpperCase()}${viewName.slice(1)}`}
      </Option>
    ))

  const patientOptions = () =>
    patients.map((patient) => (
      <Option key={patient.value} value={patient.value}>
        {patient.name}
      </Option>
    ))

  return (
    <Row className={styles['toolbar-box']}>
      <Col className={styles['toolbar-date-navigation']}>
        <div className={styles['toolbar-date-timezone']}>
          <Text header="h3" className={styles['toolbar-title']}>
            {format(currentDate, DateFormat)}
          </Text>
          <Text
            header="h5"
            type="secondary"
            className={styles['toolbar-timezone']}
          >
            {convertOffsetToTimezomeAbbreviation(
              formatInTimeZone(new Date(), currentTimezone, 'zzz'),
              currentTimezone
            )}
          </Text>
        </div>
        <NavigateButtons
          currentView={currentView}
          selectedDate={currentDate}
          onNavigationChange={onNavigationChange}
        />
      </Col>
      <Col className={styles['toolbar-menu-items']}>
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => {
            const children = (option?.children ?? '') as string
            return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
          placeholder="Search for a patient"
          defaultValue={currentPatient?.length ? currentPatient : undefined}
          className={cx(styles.selectPatient, styles.toolbarMargin)}
          onSelect={onPatientChange as (value: unknown) => void}
          value={currentPatient?.length ? currentPatient : undefined}
          onClear={onClearPatient}
        >
          {patientOptions()}
        </Select>
        <Select
          defaultValue={currentView}
          value={currentView}
          className={styles.selectView}
          onSelect={onViewChange as (value: unknown) => void}
          open={showSelectView}
          onDropdownVisibleChange={setShowSelectView}
          dropdownRender={(menu) => {
            return (
              <div data-testid="scheduling_viewSelectDropdown">
                {menu}
                <Divider className={styles.selectViewDivider} />
                <Switch
                  size="small"
                  className={styles.selectViewToggle}
                  checked={showWeekends}
                  onToggle={() => {
                    toggleShowWeekends()
                    setShowSelectView(false)
                  }}
                  leftLabel
                  label="Show Weekends"
                />
              </div>
            )
          }}
        >
          {viewOptions()}
        </Select>
        <Button
          type="default"
          className={cx(styles.toolbarMargin)}
          onClick={onCreateEventClick}
        >
          <PlusOutlined /> Event
        </Button>
        <Button
          onClick={onNotificationClick}
          className={cx(styles.toolbarMargin, {
            [styles.hasNotificationButton]: totalNotifications > 0,
          })}
        >
          <MailFilled /> {totalNotifications}
        </Button>
      </Col>
    </Row>
  )
}
