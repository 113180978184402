import React, { useMemo } from 'react'

import { Elements } from '@stripe/react-stripe-js'

import {
  useInvoices,
  usePaymentMethods,
  useTransactions,
} from '../../../hooks/useBillingInfo'
import { useGetClaimsByPatientId } from '../../../hooks/useInsuranceClaims'
import {
  PAYMENT_METHOD_TYPES,
  STRIPE_ACCOUNT_STATUSES,
} from '../../../libs/constants'
import { useFeatureFlags } from '../../../libs/featureFlags'
import {
  CLAIM_STATUS,
  InsuranceClaim,
  Invoice,
  PaymentMethod,
} from '../../../shared-types'
import AddPaymentModal from './AddPaymentModal'
import ErrorView from './ErrorView'
import useCreatePaymentIntentWithSideEffects from './useCreatePaymentIntentWithSideEffects'
import useStripe from './useStripe'

const PaymentModals = ({
  patientId,
  isOpen,
  onClose,
  stripeAccountStatus,
  preSelectedInvoiceUuid,
  preSelectedClaimUuid,
  stopModalLoading,
  fromClaim,
  fromGlobalView,
}: {
  patientId: string
  isOpen: boolean
  onClose: () => void
  stripeAccountStatus: STRIPE_ACCOUNT_STATUSES
  preSelectedInvoiceUuid?: string | null
  preSelectedClaimUuid?: string | null
  stopModalLoading: null | (() => void)
  fromClaim: boolean
  fromGlobalView: boolean
}) => {
  const { claims: claimFeatureFlag } = useFeatureFlags()
  const {
    isInitialLoading: paymentMethodsIsLoading,
    isError: paymentMethodsIsError,
    data: paymentMethods,
  } = usePaymentMethods(patientId)

  const {
    isInitialLoading: invoiceDataIsLoading,
    isError: invoiceDataIsError,
    data: invoices,
  } = useInvoices(patientId)

  const {
    isLoading: claimsDataIsLoading,
    isError: claimsDataIsError,
    data: claims,
  } = useGetClaimsByPatientId(patientId, claimFeatureFlag)

  const {
    isLoading: transactionsIsLoading,
    isError: transactionsIsError,
    data: transactions,
  } = useTransactions({ patientId })

  const stripePaymentMethods = useMemo(() => {
    return (paymentMethods || []).filter(
      (paymentMethod: PaymentMethod) =>
        paymentMethod.type === PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD &&
        !paymentMethod.deletedAt
    )
  }, [paymentMethods])

  const outstandingInvoices = useMemo(() => {
    return (invoices || []).filter(
      (invoice: Invoice) => invoice.amountCentsDue > 0
    )
  }, [invoices])

  const statusesToFilterOut = [
    CLAIM_STATUS.DRAFT,
    CLAIM_STATUS.REJECTED,
    CLAIM_STATUS.CANCELED,
    CLAIM_STATUS.DENIED,
    CLAIM_STATUS.CHANGE_ERROR,
  ]

  const submittedClaims = useMemo(() => {
    return (claims ?? []).filter(
      (claim: InsuranceClaim) =>
        claim.claimStatus && !statusesToFilterOut.includes(claim.claimStatus)
    )
  }, [claims])

  const isLoadInvoicesError = invoiceDataIsError || paymentMethodsIsError
  const isLoadingInvoices = invoiceDataIsLoading || paymentMethodsIsLoading

  const isLoadingClaimsError = claimFeatureFlag ? claimsDataIsError : false
  const isLoadingClaims = claimFeatureFlag && claimsDataIsLoading

  const isLoading =
    isLoadingInvoices || isLoadingClaims || transactionsIsLoading

  const isLoadError =
    isLoadInvoicesError || isLoadingClaimsError || transactionsIsError

  const { stripePromise, stripePublicKeyIsError, stripePublicKeyIsLoading } =
    useStripe()

  const {
    paymentIntentClientSecret,
    paymentIntentId,
    paymentIntentLoading,
    resetPaymentIntent,
  } = useCreatePaymentIntentWithSideEffects({
    patientId,
    invoices,
    isOpen,
  })

  if (isLoading || stripePublicKeyIsLoading || paymentIntentLoading) {
    return <span />
  }

  if (isLoadError || stripePublicKeyIsError) {
    return <ErrorView />
  }

  if (
    stopModalLoading &&
    !isLoading &&
    !stripePublicKeyIsLoading &&
    !paymentIntentLoading
  ) {
    stopModalLoading()
  }

  // Guard against passing in invalid options to Stripe to avoid errors.
  const stripeOptions = paymentIntentClientSecret?.length
    ? { clientSecret: paymentIntentClientSecret }
    : {}

  return (
    <div data-testid="payments-modals">
      <Elements stripe={stripePromise} options={stripeOptions}>
        <AddPaymentModal
          isVisible={isOpen}
          isLoading={
            isLoading || stripePublicKeyIsLoading || paymentIntentLoading
          }
          invoices={outstandingInvoices}
          claims={submittedClaims}
          claimFeatureFlag={claimFeatureFlag}
          stripeCreditCards={stripePaymentMethods}
          onCloseModal={() => onClose()}
          paymentMethods={paymentMethods}
          patientId={patientId}
          payments={transactions.payments}
          paymentIntentSecret={paymentIntentClientSecret}
          paymentIntentId={paymentIntentId}
          stripeAccountStatus={stripeAccountStatus}
          preSelectedInvoiceUuid={preSelectedInvoiceUuid}
          preSelectedClaimUuid={preSelectedClaimUuid}
          resetPaymentIntent={resetPaymentIntent}
          fromClaim={fromClaim}
          fromGlobalView={fromGlobalView}
        />
      </Elements>
    </div>
  )
}

export default PaymentModals
