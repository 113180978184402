import { useLocalStorage } from '../useLocalStorage'

export const SHOW_WEEKENDS_KEY = 'SHOW_WEEKENDS_KEY'
export const useShowWeekends = () => {
  const [showWeekends, setShowWeekends] = useLocalStorage<boolean>(
    SHOW_WEEKENDS_KEY,
    true
  )
  const toggleShowWeekends = () => {
    setShowWeekends((currShowWeekends: boolean) => !currShowWeekends)
  }
  return { showWeekends, toggleShowWeekends }
}
