import React, { useState } from 'react'

import { Modal } from 'antd'
import { useHistory } from 'react-router-dom'

import {
  deleteInvoice,
  getPaymentLockStatus,
  setPaymentLockStatus,
} from '../../api/api-lib'
import { notification } from '../../libs/notificationLib'
import { paymentDataLockedModal } from '../../shared/Payments/PaymentLockModal'

import styles from './Invoice.module.scss'

type DeleteInvoiceModalProps = {
  invoiceUuid: string
  invoiceNumber: number
  patientId: string
  onDelete: (uuid: string) => void
}
export const DeleteInvoiceModal = ({
  invoiceUuid,
  invoiceNumber,
  patientId,
  onDelete,
}: DeleteInvoiceModalProps) => {
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const history = useHistory()
  async function handleOk() {
    setConfirmLoading(true)
    const lockStatusResponse = await getPaymentLockStatus(patientId)
    if (lockStatusResponse.isLocked) {
      paymentDataLockedModal()
      return
    }

    await setPaymentLockStatus(
      patientId,
      true,
      'DeleteInvoiceModal handleOk start'
    )

    deleteInvoice(invoiceUuid)
      .then(async () => {
        await setPaymentLockStatus(
          patientId,
          false,
          'DeleteInvoiceModal handleOk success'
        )
        notification('Invoice deleted successfully.', 'success')
        history.push(`/patient/billing?patientId=${patientId}`)
        invoiceUuid && onDelete(invoiceUuid)
      })
      .catch(async () => {
        await setPaymentLockStatus(
          patientId,
          false,
          'DeleteInvoiceModal handleOk error'
        )
        notification('There was an error creating the invoice.', 'error')
      })
      .finally(() => {
        setConfirmLoading(false)
        setVisible(false)
      })
  }

  return (
    <>
      <span
        className={styles.deleteInvoiceButton}
        onClick={() => setVisible(true)}
      >
        Delete
      </span>
      <Modal
        title={`Delete invoice #${invoiceNumber}?`}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
      >
        <p>
          Are you sure you want to delete this invoice? All payments made
          towards this invoice will be applied as patient credit. This action
          can't be undone.
        </p>
      </Modal>
    </>
  )
}
