import { Auth, Cache } from 'aws-amplify'

import Sentry from '../../libs/sentry'
import {
  CognitoUserInfoFromStorage,
  ProviderAccountInfo,
} from '../../shared-types'

export const CACHE_KEY = 'PROVIDER_ID'

interface GetCognitoUserOptions {
  logFailure: boolean
}

/**
 * This is a custom hook that can be used globally to access Cognito user info in a more optimized way by
 * using local storage & Amplify cache instead of calling Cognito directly
 */
const getCognitoUser = async (
  { logFailure }: GetCognitoUserOptions = { logFailure: true }
): Promise<ProviderAccountInfo> => {
  try {
    let cognitoId = Cache.getItem(CACHE_KEY) ?? undefined
    const cognitoUserFromStorage: CognitoUserInfoFromStorage =
      await Auth.currentAuthenticatedUser()

    if (!cognitoId) {
      // Fall back to Auth.currentUserInfo only if no provider id in cache
      const userInfoFromCognito = (await Auth.currentUserInfo()) ?? {}

      // currentUserInfo() returns an empty object instead of throwing an error
      if (!Object.keys(userInfoFromCognito).length) {
        throw new Error('Failed to get user info from Cognito.')
      }
      cognitoId = userInfoFromCognito?.id ?? ''
      // Cache the provider id to reduce calling Auth.currentUserInfo for it
      await Cache.setItem(CACHE_KEY, cognitoId)
    }
    return {
      // Output the same payload as Auth.currentUserInfo
      id: cognitoId,
      username: cognitoUserFromStorage?.username,
      attributes: {
        email: cognitoUserFromStorage?.attributes?.email,
        email_verified: cognitoUserFromStorage?.attributes?.email_verified,
        sub: cognitoUserFromStorage?.attributes?.sub,
        main_provider_id:
          cognitoUserFromStorage?.attributes?.['custom:main_provider_id'],
      },
    }
  } catch (err) {
    if (logFailure) {
      Sentry.captureException(err)
    }
    throw new Error(`Authentication error: ${err.message ?? err}`)
  }
}

export default getCognitoUser
