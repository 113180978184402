import { StoredFields } from '../../../components/Forms/FormMachine/types'

export enum PhoneType {
  CELL = 'cellPhone',
  WORK = 'workPhone',
  HOME = 'homePhone',
  PRIMARY_INSURED = 'primaryInsuredPhone',
  PRIMARY_PROVIDER = 'primaryProviderPhone',
  MENTAL_HEALTH_PROVIDER = 'mentalHealthProviderPhone',
  OTHER_HEALTH_PROVIDER = 'otherHealthProviderPhone',
}

export const phoneTypes: {
  count: (store: StoredFields) => number
  getStoredNumber: (store: StoredFields, index: number) => string
  setStoredNumber: (
    store: StoredFields,
    phoneNumber: string,
    index: number
  ) => void
  getName?: (store: StoredFields, index: number) => string
  getPhoneTypeForError: (name?: string) => string
  required?: boolean
}[] = [
  {
    count: () => 1,
    getStoredNumber: (store) => store[PhoneType.CELL],
    setStoredNumber: (store, phoneNumber) =>
      (store[PhoneType.CELL] = phoneNumber),
    getPhoneTypeForError: () => 'cell',
    required: true,
  },
  {
    count: () => 1,
    getStoredNumber: (store) => store[PhoneType.WORK],
    setStoredNumber: (store, phoneNumber) =>
      (store[PhoneType.WORK] = phoneNumber),
    getPhoneTypeForError: () => 'work',
  },
  {
    count: () => 1,
    getStoredNumber: (store) => store[PhoneType.HOME],
    setStoredNumber: (store, phoneNumber) =>
      (store[PhoneType.HOME] = phoneNumber),
    getPhoneTypeForError: () => 'home',
  },
  {
    count: () => 1,
    getStoredNumber: (store) => store[PhoneType.PRIMARY_INSURED],
    setStoredNumber: (store, phoneNumber) =>
      (store[PhoneType.PRIMARY_INSURED] = phoneNumber),
    getPhoneTypeForError: () => 'primary insured',
  },
  {
    count: () => 1,
    getStoredNumber: (store) => store[PhoneType.PRIMARY_PROVIDER],
    setStoredNumber: (store, phoneNumber) =>
      (store[PhoneType.PRIMARY_PROVIDER] = phoneNumber),
    getPhoneTypeForError: () => 'primary provider',
  },
  {
    count: (store) =>
      store.mentalHealthcareProviders
        ? store.mentalHealthcareProviders.length
        : 0,
    getStoredNumber: (store, index) =>
      store.mentalHealthcareProviders[index][PhoneType.MENTAL_HEALTH_PROVIDER],
    setStoredNumber: (store, phoneNumber, index) =>
      (store.mentalHealthcareProviders[index][
        PhoneType.MENTAL_HEALTH_PROVIDER
      ] = phoneNumber),
    getName: (store, index) =>
      store.mentalHealthcareProviders[index].mentalHealthProviderName,
    getPhoneTypeForError: (name) => `mental healthcare provider ${name}`,
  },
  {
    count: (store) =>
      store.otherHealthcareProviders
        ? store.otherHealthcareProviders.length
        : 0,
    getStoredNumber: (store, index) =>
      store.otherHealthcareProviders[index][PhoneType.OTHER_HEALTH_PROVIDER],
    setStoredNumber: (store, phoneNumber, index) =>
      (store.otherHealthcareProviders[index][PhoneType.OTHER_HEALTH_PROVIDER] =
        phoneNumber),
    getName: (store, index) =>
      store.otherHealthcareProviders[index].otherHealthProviderName,
    getPhoneTypeForError: (name) => `other healthcare provider ${name}`,
  },
]
