import { FilePdfTwoTone } from '@ant-design/icons'

import { Modal } from '../BaseComponents'

interface ExportPdfConfirmationModalProps {
  fileType: string
  onOk: () => void
  okButtonProps: object
  onCancel: () => void
  cancelButtonProps: object
  closable: boolean
  maskClosable: boolean
  isVisible: boolean
}

export default function ExportPdfConfirmationModal(
  props: ExportPdfConfirmationModalProps
) {
  const {
    fileType,
    onOk,
    okButtonProps,
    onCancel,
    cancelButtonProps,
    closable,
    maskClosable,
    isVisible,
  } = props
  return (
    <Modal
      title="Export as PDF"
      visible={isVisible}
      okText="Export PDF"
      okButtonProps={okButtonProps}
      onOk={onOk}
      onCancel={onCancel}
      cancelButtonProps={cancelButtonProps}
      closable={closable}
      maskClosable={maskClosable}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <FilePdfTwoTone style={{ fontSize: 33 }} />
        <div style={{ paddingTop: '20px' }}>
          {`Your ${fileType} is ready to be exported as a PDF.`}
        </div>
      </div>
    </Modal>
  )
}
