import { StatusCategory } from '../../../shared-types'
import { NdcIdQualifier, UnitMeasurementType } from '../ClaimsV2/types'

export const InsurancePrimary = {
  insuranceName: 'insuranceName',
  insuranceMemberId: 'insuranceMemberId',
  insuranceGroupId: 'insuranceGroupId',
  informationRelease: 'informationRelease',
  paymentAutorization: 'paymentAutorization',
  claimFilingCode: 'claimFilingCode',
}

export const InsurancePrimaryHolder = {
  primaryFirstName: 'primaryFirstName',
  primaryLastName: 'primaryLastName',
  dateOfBirth: 'dateOfBirth',
  legalSex: 'legalSex',
  email: 'email',
  phoneNumber: 'phoneNumber',
  sameAddressAsPatient: 'sameAddressAsPatient',
  address: 'address',
  unit: 'unit',
  primaryRelationship: 'primaryRelationship',
}

export const InsuranceSecondary = {
  secondaryInsuranceName: 'secondaryInsuranceName',
  secondaryInsuranceMemberId: 'secondaryInsuranceMemberId',
  secondaryInsuranceGroupId: 'secondaryInsuranceGroupId',
  secondaryInformationRelease: 'secondaryInformationRelease',
  secondaryPaymentAuthorization: 'secondaryPaymentAuthorization',
  secondaryClaimFillingCode: 'secondaryClaimFillingCode',
  secondaryInsuranceTypeCode: 'secondaryInsuranceTypeCode',
}

export const InsuranceSecondaryHolder = {
  secondaryFirstName: 'secondaryFirstName',
  secondaryLastName: 'secondaryLastName',
  secondaryRelationship: 'secondaryRelationship',
  secondaryDateOfBirth: 'secondaryDateOfBirth',
}

export const InsuranceHolderRelationshipEnum = {
  Self: 'Self',
  Child: 'Child',
  Spouse: 'Spouse',
  Parent: 'Parent',
  Grandparent: 'Grandparent',
  Sibling: 'Sibling',
  Partner: 'Partner',
  Other: 'Other',
}

export const claimFilingChangeOptions = [
  {
    label: 'Commercial/HMO',
    value: 'CI',
  },
  {
    label: 'TRICARE/CHAMPUS',
    value: 'CH',
  },
  {
    label: 'Blue Cross/Blue Shield',
    value: 'BL',
  },
  {
    label: 'Workers Compensation',
    value: 'WC',
  },
  {
    label: 'Medicaid',
    value: 'MC',
  },
  {
    label: 'Medicare',
    value: 'MB',
  },
]

export const claimFilingCodeOptions = [
  {
    label: 'Blue Cross / Blue Shield',
    value: 'BL',
  },
  {
    label: 'Disability',
    value: 'DS',
  },
  {
    label: 'Commercial / HMO',
    value: 'CI',
  },
  {
    label: 'Medicare Part A',
    value: 'MA',
  },
  {
    label: 'Medicare Part B',
    value: 'MB',
  },
  {
    label: 'Medicaid',
    value: 'MC',
  },
  {
    label: 'Other non - federal program',
    value: '11',
  },
  {
    label: 'Other federal program',
    value: 'OF',
  },
  {
    label: 'Tricare / Champus',
    value: 'CH',
  },
  {
    label: 'Veterans Affairs Plan',
    value: 'VA',
  },
  {
    label: "Worker's Compensation Health Claim",
    value: 'WC',
  },
  {
    label: 'Mutually Defined',
    value: 'ZZ',
  },
]

export const MEDICARE_CLAIM_FILING_CODES = ['MA', 'MB']
export const DEFAULT_MEDICARE_INSURANCE_TYPE_CODE = '12'
export const insuranceTypeCodeOptions = [
  {
    label: `'12' Medicare Secondary Working Aged Beneficiary or Spouse with Employer Group Health Plan`,
    value: '12',
  },
  {
    label: `'13' Medicare Secondary End-Stage Renal Disease Beneficiary in the Mandated Coordination Period`,
    value: '13',
  },
  {
    label: `'14' Medicare Secondary, No-fault Insurance including Auto is Primary`,
    value: '14',
  },
  {
    label: `'15' Medicare Secondary Worker's Compensation`,
    value: '15',
  },
  {
    label: `'16' Medicare Secondary Public Health Service (PHS) or Other Federal Agency`,
    value: '16',
  },
  {
    label: `'41' Medicare Secondary Black Lung`,
    value: '41',
  },
  {
    label: `'42' Medicare Secondary Veteran's Administration`,
    value: '42',
  },
  {
    label: `'43' Medicare Secondary Disabled Beneficiary Under Age 65 with Large Group Health Plan (LGHP)`,
    value: '43',
  },
  {
    label: `'47' Medicare Secondary, Other Liability Insurance is Primary`,
    value: '47',
  },
]

export const InsuranceHolderRelationshipOptions = [
  {
    value: InsuranceHolderRelationshipEnum.Self,
    label: InsuranceHolderRelationshipEnum.Self,
    disabled: false,
  },
  {
    value: InsuranceHolderRelationshipEnum.Child,
    label: InsuranceHolderRelationshipEnum.Child,
    disabled: false,
  },
  {
    value: InsuranceHolderRelationshipEnum.Spouse,
    label: InsuranceHolderRelationshipEnum.Spouse,
    disabled: false,
  },
  {
    value: InsuranceHolderRelationshipEnum.Parent,
    label: InsuranceHolderRelationshipEnum.Parent,
    disabled: false,
  },
  {
    value: InsuranceHolderRelationshipEnum.Grandparent,
    label: InsuranceHolderRelationshipEnum.Grandparent,
    disabled: false,
  },
  {
    value: InsuranceHolderRelationshipEnum.Sibling,
    label: InsuranceHolderRelationshipEnum.Sibling,
    disabled: false,
  },
  {
    value: InsuranceHolderRelationshipEnum.Partner,
    label: InsuranceHolderRelationshipEnum.Partner,
    disabled: false,
  },
  {
    value: InsuranceHolderRelationshipEnum.Other,
    label: InsuranceHolderRelationshipEnum.Other,
    disabled: false,
  },
]

export const InsuranceSubscriberItems = [
  {
    id: InsurancePrimaryHolder.primaryFirstName,
    label: 'First name',
    placeHolder: 'Add',
    requireConfirmation: false,
    scrollToField: false,
    value: '',
    type: 'text',
    isRequired: true,
  },
  {
    id: InsurancePrimaryHolder.primaryLastName,
    label: 'Last name',
    placeHolder: 'Add',
    requireConfirmation: false,
    scrollToField: false,
    value: '',
    type: 'text',
    isRequired: true,
  },
  {
    id: InsurancePrimaryHolder.dateOfBirth,
    label: 'Date of birth',
    placeHolder: 'Select',
    requireConfirmation: false,
    scrollToField: false,
    value: null,
    type: 'date',
    isRequired: true,
  },
  {
    id: InsurancePrimaryHolder.legalSex,
    label: 'Legal sex per plan',
    placeHolder: 'Select',
    requireConfirmation: false,
    allowClear: true,
    scrollToField: false,
    value: '',
    type: 'select',
    options: [
      {
        value: 'F',
        label: 'F',
      },
      {
        value: 'M',
        label: 'M',
      },
      {
        value: 'X',
        label: 'X',
      },
      {
        value: 'Unknown',
        label: 'Unknown',
      },
    ],
  },
  {
    id: InsurancePrimaryHolder.email,
    label: 'Email',
    placeHolder: 'Add',
    requireConfirmation: false,
    scrollToField: false,
    value: '',
    type: 'text',
  },
  {
    id: InsurancePrimaryHolder.phoneNumber,
    label: 'Phone',
    placeHolder: 'Add',
    requireConfirmation: false,
    scrollToField: false,
    value: '',
    type: 'text',
  },
  {
    id: InsurancePrimaryHolder.sameAddressAsPatient,
    label: 'Same address as patient?',
    placeHolder: 'Select',
    allowClear: true,
    requireConfirmation: false,
    scrollToField: false,
    value: 'Yes',
    type: 'select',
    options: [
      {
        value: 'Yes',
        label: 'Yes',
      },
      {
        value: 'No',
        label: 'No',
      },
    ],
  },
  {
    id: InsurancePrimaryHolder.address,
    label: 'Address 1',
    placeHolder: 'Select',
    requireConfirmation: false,
    scrollToField: false,
    value: '',
    type: 'smartyAddress',
  },
  {
    id: InsurancePrimaryHolder.unit,
    label: 'Address 2',
    placeHolder: 'Add',
    requireConfirmation: false,
    scrollToField: false,
    value: '',
    type: 'text',
  },
]

export const primaryInsuranceComponents = [
  {
    groupName: 'Primary insurance',
    items: [
      {
        id: InsurancePrimary.insuranceName,
        label: 'Company name',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        options: [],
        isRequired: true,
      },
      {
        id: InsurancePrimary.claimFilingCode,
        label: 'Claim filing code',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        options: claimFilingCodeOptions,
        isRequired: true,
      },
      {
        id: InsurancePrimary.insuranceMemberId,
        label: 'Member ID',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: '',
        type: 'text',
        isRequired: true,
      },
      {
        id: InsurancePrimary.insuranceGroupId,
        label: 'Group ID',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: '',
        type: 'text',
      },
      {
        id: InsurancePrimary.informationRelease,
        label: 'Information release',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: null,
        type: 'select',
        isRequired: true,
        options: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          },
        ],
      },
      {
        id: InsurancePrimary.paymentAutorization,
        label: 'Payment authorization',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: null,
        type: 'select',
        isRequired: true,
        options: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          },
        ],
      },
      {
        id: InsurancePrimaryHolder.primaryRelationship,
        label: 'Relationship to subscriber',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        isRequired: true,
        options: InsuranceHolderRelationshipOptions,
      },
    ],
  },
  {
    groupName: 'Primary insurance subscriber info',
    items: InsuranceSubscriberItems,
  },
]

export const secondaryInsuranceComponents = [
  {
    groupName: 'Secondary insurance',
    items: [
      {
        id: InsuranceSecondary.secondaryInsuranceName,
        label: 'Company name',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        options: [],
        isRequired: true,
      },
      {
        id: InsuranceSecondary.secondaryClaimFillingCode,
        label: 'Claim filing code',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        options: claimFilingCodeOptions,
        isRequired: true,
      },
      {
        id: InsuranceSecondary.secondaryInsuranceTypeCode,
        label: 'Medicare secondary insurance type code',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        options: insuranceTypeCodeOptions,
        isRequired: true,
        dropdownWidth: 400,
      },
      {
        id: InsuranceSecondary.secondaryInsuranceMemberId,
        label: 'Member ID',
        placeHolder: 'Select',
        requireConfirmation: false,
        scrollToField: false,
        value: '',
        type: 'text',
        isRequired: true,
      },
      {
        id: InsuranceSecondary.secondaryInsuranceGroupId,
        label: 'Group ID',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: '',
        type: 'text',
      },
      {
        id: InsuranceSecondary.secondaryInformationRelease,
        label: 'Information release',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: null,
        type: 'select',
        isRequired: true,
        options: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          },
        ],
      },
      {
        id: InsuranceSecondary.secondaryPaymentAuthorization,
        label: 'Payment authorization',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: null,
        type: 'select',
        isRequired: true,
        options: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          },
        ],
      },
      {
        id: InsuranceSecondaryHolder.secondaryRelationship,
        label: 'Relationship to subscriber',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: null,
        type: 'select',
        isRequired: true,
        options: InsuranceHolderRelationshipOptions,
      },
      {
        id: InsuranceSecondaryHolder.secondaryFirstName,
        label: 'First name',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: '',
        type: 'text',
        isRequired: true,
      },
      {
        id: InsuranceSecondaryHolder.secondaryLastName,
        label: 'Last name',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: '',
        type: 'text',
        isRequired: true,
      },
      {
        id: InsuranceSecondaryHolder.secondaryDateOfBirth,
        label: 'Date of birth',
        placeHolder: 'Select',
        requireConfirmation: false,
        scrollToField: false,
        allowClear: true,
        value: null,
        type: 'date',
        isRequired: true,
      },
    ],
  },
]

export const insuranceEmptyObject = {
  patientId: '',
  isPrimaryInsurance: false,
  insuranceName: null,
  insuranceMemberId: null,
  insuranceGroupId: null,
  insuranceCompany: null,
  insuranceCompanyAddress1: null,
  insuranceCompanyAddress2: null,
  insuranceCompanyCity: null,
  insuranceCompanyState: null,
  insuranceCompanyZipCode: null,
  insuranceCardBack: null,
  insuranceCardFront: null,
  isPrimaryInsuranceHolder: null,
  hasInsuranceCardSaved: null,
  deletedAt: null,
  isPaymentAuthorized: null,
  isInformationReleaseAuthorized: null,
}

export const insuranceHolderEmptyObject = {
  address1: null,
  address2: null,
  zipCode: null,
  city: null,
  state: null,
  country: null,
  dateOfBirth: null,
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  relationship: null,
  legalSex: null,
  deletedAt: null,
}

export const patientConditionCauses = {
  employmentAccident: null,
  autoAccident: null,
  accidentState: null,
  otherAccident: null,
}

export const patientCondition = {
  employmentAccident: 'employmentAccident',
  autoAccident: 'autoAccident',
  accidentState: 'accidentState',
  otherAccident: 'otherAccident',
}

export const statesOptionsArray = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

export const patientConditionRelationComponent = [
  {
    groupName: 'Is the patient’s condition related to...',
    items: [
      {
        id: patientCondition.employmentAccident,
        label: 'Employment?',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: null,
        type: 'select',
        options: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          },
        ],
      },
      {
        id: patientCondition.autoAccident,
        label: 'Auto accident?',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: null,
        type: 'select',
        options: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          },
        ],
      },
      {
        id: patientCondition.accidentState,
        label: 'Auto accident state',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        options: statesOptionsArray.map((sO) => {
          return {
            value: sO,
            label: sO,
          }
        }),
      },
      {
        id: patientCondition.otherAccident,
        label: 'Other accident?',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: null,
        type: 'select',
        options: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          },
        ],
      },
    ],
  },
]

export const patientInfoEmptyObject = {
  firstName: null,
  lastName: null,
  address1: null,
  address2: null,
  zipCode: null,
  city: null,
  state: null,
  country: null,
  dateOfBirth: null,
  legalSex: null,
}

export const patientInformation = {
  firstName: 'firstName',
  lastName: 'lastName',
  address: 'address',
  unit: 'unit',
  dateOfBirth: 'dateOfBirth',
  legalSex: 'legalSex',
}

export const patientInformationComponent = [
  {
    groupName: 'Patient information',
    items: [
      {
        id: patientInformation.firstName,
        label: 'First name',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: '',
        type: 'text',
        isRequired: true,
      },
      {
        id: patientInformation.lastName,
        label: 'Last name',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: '',
        type: 'text',
        isRequired: true,
      },
      {
        id: patientInformation.dateOfBirth,
        label: 'Date of birth',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        allowClear: true,
        value: null,
        type: 'date',
        isRequired: true,
      },
      {
        id: patientInformation.address,
        label: 'Address 1',
        placeHolder: 'Select',
        requireConfirmation: false,
        scrollToField: false,
        value: '',
        type: 'smartyAddress',
        isRequired: true,
      },
      {
        id: patientInformation.unit,
        label: 'Address 2',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: '',
        type: 'text',
      },
      {
        id: patientInformation.legalSex,
        label: 'Legal sex',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: null,
        type: 'select',
        isRequired: true,
        options: [
          {
            value: 'F',
            label: 'F',
          },
          {
            value: 'M',
            label: 'M',
          },
          {
            value: 'X',
            label: 'X',
          },
          {
            value: 'Unknown',
            label: 'Unknown',
          },
        ],
      },
    ],
  },
]

export const companyToSubmitToComponent = [
  {
    groupName: 'Primary insurance',
    items: [
      {
        id: 'claimToSubmitTo',
        label: 'Company name',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        options: [],
        isRequired: true,
      },
    ],
  },
]

export const referringProviderEmptyObject = {
  firstName: null,
  lastName: null,
  providerEin: null,
  providerNpi: null,
}

export const referringProviderArgs = {
  firstName: 'firstName',
  lastName: 'lastName',
  providerEin: 'providerEin',
  providerNpi: 'providerNpi',
}

export const referringProviderComponent = [
  {
    groupName: 'Referring provider',
    items: [
      {
        id: referringProviderArgs.firstName,
        label: 'First name',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
      },
      {
        id: referringProviderArgs.lastName,
        label: 'Last name',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
      },
      {
        id: referringProviderArgs.providerEin,
        label: 'EIN',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        isRequired: true,
        type: 'text',
      },
      {
        id: referringProviderArgs.providerNpi,
        label: 'NPI',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        isRequired: true,
        type: 'text',
      },
    ],
  },
]

export const supervisingProviderEmptyObject = {
  providerId: null,
  providerEin: null,
  providerNpi: null,
}

export const supervisingProviderArgs = {
  providerId: 'providerId',
  providerEin: 'providerEin',
  providerNpi: 'providerNpi',
}

export const renderingProviderEmptyObject = {
  providerId: null,
  providerEin: null,
  providerNpi: null,
  providerSsn: null,
  providerTaxonomyCode: null,
  taxType: null,
  providerSSNfirstFive: null,
  providerSSNlastFour: null,
}

export const renderingProviderArgs = {
  providerId: 'providerId',
  providerEin: 'providerEin',
  providerNpi: 'providerNpi',
  providerSsn: 'providerSsn',
  taxType: 'taxType',
  taxonomyCode: 'taxonomyCode',
}

export const renderingProviderComponent = [
  {
    groupName: 'Rendering provider',
    items: [
      {
        id: renderingProviderArgs.providerId,
        label: 'Name',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        isRequired: true,
        options: [],
      },
      {
        id: renderingProviderArgs.taxType,
        label: 'Tax ID Type',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        isRequired: true,
        options: [
          {
            value: 'EIN',
            label: 'EIN',
          },
          {
            value: 'SSN',
            label: 'SSN',
          },
        ],
      },
      {
        id: renderingProviderArgs.providerSsn,
        label: 'SSN',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
        isRequired: true,
      },
      {
        id: renderingProviderArgs.providerEin,
        label: 'EIN',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
        isRequired: true,
      },
      {
        id: renderingProviderArgs.providerNpi,
        label: 'NPI',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
        isRequired: true,
      },
      {
        id: renderingProviderArgs.taxonomyCode,
        label: 'Taxonomy code',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
        isRequired: false,
      },
    ],
  },
]

export const supervisingProviderComponent = [
  {
    groupName: 'Supervising provider',
    items: [
      {
        id: supervisingProviderArgs.providerId,
        label: 'Name',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        isRequired: true,
        options: [],
      },
      {
        id: supervisingProviderArgs.providerEin,
        label: 'EIN',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
        isRequired: true,
      },
      {
        id: supervisingProviderArgs.providerNpi,
        label: 'NPI',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
        isRequired: true,
      },
    ],
  },
]

export const billingInfoEmptyObject = {
  type: null,
  name: null,
  locationId: null,
  address1: null,
  locationName: null,
  address2: null,
  zipCode: null,
  city: null,
  state: null,
  country: null,
  phone: null,
  EIN: null,
  NPI: null,
  SSN: null,
  taxType: null,
  SSNfirstFive: null,
  SSNlastFour: null,
  taxonomyCode: null,
}

export const billingInfoArgs = {
  type: 'type',
  name: 'name',
  locationId: 'locationId',
  locationName: 'locationName',
  address1: 'address1',
  address2: 'address2',
  phone: 'phone',
  taxType: 'taxType',
  SSN: 'SSN',
  EIN: 'EIN',
  NPI: 'NPI',
  taxonomyCode: 'taxonomyCode',
}

export const billingProviderCopmponent = {
  id: billingInfoArgs.name,
  label: 'Name',
  placeHolder: 'Select',
  requireConfirmation: false,
  allowClear: true,
  scrollToField: false,
  value: undefined,
  type: 'select',
  options: [],
}

export const billingProviderTaxonomyCodeComponent = {
  id: billingInfoArgs.taxonomyCode,
  label: 'Taxonomy code',
  placeHolder: 'Add',
  requireConfirmation: false,
  scrollToField: false,
  value: '',
  type: 'text',
  isRequired: false,
}

export const billingInfoComponents = [
  {
    groupName: 'Billing',
    items: [
      {
        id: billingInfoArgs.type,
        label: 'Type',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        isRequired: true,
        options: [
          {
            value: 'Provider',
            label: 'Provider',
          },
          {
            value: 'Practice',
            label: 'Practice',
          },
        ],
      },
      {
        id: billingInfoArgs.name,
        label: 'Practice',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        isRequired: true,
        type: 'text',
      },
      {
        id: billingInfoArgs.locationId,
        label: 'Location',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        isRequired: true,
        options: [],
      },
      {
        id: billingInfoArgs.address1,
        label: 'Address 1',
        placeHolder: 'Select',
        requireConfirmation: false,
        scrollToField: false,
        value: '',
        type: 'smartyAddress',
        isRequired: true,
      },
      {
        id: billingInfoArgs.address2,
        label: 'Unit',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
      },
      {
        id: billingInfoArgs.phone,
        label: 'Phone',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        isRequired: true,
        value: '',
        type: 'text',
      },
      {
        id: billingInfoArgs.taxType,
        label: 'Tax ID Type',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        isRequired: true,
        options: [
          {
            value: 'EIN',
            label: 'EIN',
          },
          {
            value: 'SSN',
            label: 'SSN',
          },
        ],
      },
      {
        id: billingInfoArgs.SSN,
        label: 'SSN',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
        isRequired: true,
      },
      {
        id: billingInfoArgs.EIN,
        label: 'EIN',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
        isRequired: true,
      },
      {
        id: billingInfoArgs.NPI,
        label: 'NPI',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
        isRequired: true,
      },
    ],
  },
]

export const appointmentInfoEmptyObject = {
  noteId: null,
  noteIdV2: null,
  startDate: null,
  endDate: null,
  practice: null,
  locationId: null,
  address1: null,
  address2: null,
  state: null,
  city: null,
  country: null,
  zipCode: null,
  posCode: null,
  practiceNpi: null,
  isSigned: false,
  signedDate: null,
}

export const appointmentArgs = {
  noteIdV2: 'noteIdV2',
  startDate: 'startDate',
  endDate: 'endDate',
  practice: 'practice',
  locationId: 'locationId',
  address: 'address',
  posCode: 'posCode',
  practiceNpi: 'practiceNpi',
}

export const appointmentComponent = [
  {
    groupName: 'Appointment information',
    items: [
      {
        id: appointmentArgs.noteIdV2,
        label: 'Chart note',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        options: [],
        isRequired: true,
      },
      {
        id: appointmentArgs.startDate,
        label: 'Start date',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'date',
        isRequired: true,
        // prevent the datepicker from trying to shift this date to UTC
        isLocal: true,
      },
      {
        id: appointmentArgs.endDate,
        label: 'End date',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'date',
        // prevent the datepicker from trying to shift this date to UTC
        isLocal: true,
      },
      {
        id: appointmentArgs.practice,
        label: 'Practice',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: '',
        type: 'text',
        isRequired: true,
      },
      {
        id: appointmentArgs.locationId,
        label: 'Location',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        isRequired: true,
        options: [],
      },
      {
        id: appointmentArgs.practiceNpi,
        label: 'NPI',
        placeHolder: 'Add',
        requireConfirmation: false,
        scrollToField: false,
        value: null,
        type: 'text',
      },
      {
        id: appointmentArgs.posCode,
        label: 'POS Code',
        placeHolder: 'Select',
        requireConfirmation: false,
        allowClear: true,
        scrollToField: false,
        value: undefined,
        type: 'select',
        options: [],
        isRequired: true,
      },
      {
        id: appointmentArgs.address,
        label: 'Address 1',
        placeHolder: 'Select',
        requireConfirmation: false,
        scrollToField: false,
        isRequired: true,
        value: '',
        type: 'smartyAddress',
      },
    ],
  },
]

export const claimInsuranceEmptyObject = {
  patientId: '',
  isPrimaryInsurance: false,
  patientInsuranceId: null,
  insuranceName: null,
  insuranceMemberId: null,
  insuranceGroupId: null,
  insuranceCompany: null,
  insuranceCompanyAddress1: null,
  insuranceCompanyAddress2: null,
  insuranceCompanyCity: null,
  insuranceCompanyState: null,
  insuranceCompanyZipCode: null,
  insuranceCardBack: null,
  insuranceCardFront: null,
  isPrimaryInsuranceHolder: null,
  hasInsuranceCardSaved: null,
  deletedAt: null,
  isPaymentAuthorized: null,
  isInformationReleaseAuthorized: null,
  claimFilingCode: null,
}

export const claimDetailsEmptyObject = {
  type: '',
  patientControlNumber: '',
  created: '',
  submittedStatus: '',
  status: '',
  claimStatusUpdate: {
    displayMessage: '',
    statusCategory: StatusCategory.DRAFT,
  },
}

export const NDC_ID_QUALIFIER_OPTIONS = [
  {
    label: 'National Drug Code in 5-4-2 format',
    value: NdcIdQualifier.NDC_IN_5_4_2_FORMAT,
  },
  {
    label: 'EAN/UCC - 13',
    value: NdcIdQualifier.EAN_UCC_13,
  },
  {
    label: 'EAN/UCC - 8',
    value: NdcIdQualifier.EAN_UCC_8,
  },
  {
    label: 'Health Industry Bar Code Supplier Labeling',
    value: NdcIdQualifier.HEALTH_INDUSTRY_BAR_CODE_SUPPLIER_LABELING,
  },
  {
    label: 'Customer Order Number',
    value: NdcIdQualifier.CUSTOMER_ORDER_NUMBER,
  },
  {
    label: 'GTIN-14',
    value: NdcIdQualifier.GTIN_14,
  },
  {
    label: 'UCC-12',
    value: NdcIdQualifier.UCC_12,
  },
]

export const NDC_UNIT_TYPE_OPTIONS = [
  {
    label: 'International Unit',
    value: UnitMeasurementType.INTERNATIONAL_UNIT,
  },
  {
    label: 'Gram',
    value: UnitMeasurementType.GRAM,
  },
  {
    label: 'Milligram',
    value: UnitMeasurementType.MILLIGRAM,
  },
  {
    label: 'Milliliter',
    value: UnitMeasurementType.MILLILITER,
  },
  {
    label: 'Unit',
    value: UnitMeasurementType.UNIT,
  },
]
