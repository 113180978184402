/*global google*/
import React, { useCallback, useRef, useState } from 'react'

import { Form, FormInstance, Input } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { NamePath } from 'rc-field-form/lib/interface'
import { usePlacesWidget } from 'react-google-autocomplete'

import { globalConfig } from '../../../config/config'
import LocationOutlined from '../../../images/Icons/LocationOutlined'
import { rulesConstructor } from '../../../libs/utils'

import styles from './_shared.module.scss'

interface AutocompleteProps {
  form: FormInstance
  disabled?: boolean
  label?: React.ReactNode
  labelUnit?: React.ReactNode
  name?: NamePath
  parentName?: string
  required?: boolean
  showAddress2?: boolean
  size?: SizeType
}

const ERROR_MESSAGE = 'Please input and select an address'
/**
  address autocomplete modified to take in form prop
**/
const Address: React.FC<AutocompleteProps> = ({
  form,
  disabled,
  label = 'Address',
  labelUnit = 'Unit or suite number',
  name = 'address',
  required = false,
  parentName = '',
  showAddress2 = true,
  size,
}) => {
  const config = globalConfig.get()
  const isNameArray = parentName && Array.isArray(name)
  const [addressValue, setAddressValue] = useState('')
  const ADDRESS_OBJECT: NamePath = isNameArray
    ? [parentName, name[0], `${name[1]}_OBJECT`]
    : `${name}_OBJECT`
  const UNIT_OBJECT: NamePath = isNameArray
    ? [parentName, name[0], `${name[1]}_UNIT_NUMBER`]
    : `${name}_UNIT_NUMBER`
  const addressFieldRef = useRef(null)

  const { ref: antRef } = usePlacesWidget({
    apiKey: config.GOOGLE_API_KEY,
    options: {
      componentRestrictions: { country: 'us' },
      types: ['address'],
    },
    onPlaceSelected: async (place: google.maps.places.PlaceResult) => {
      if (Object.keys(place).length === 0 || !place?.address_components) return

      const addressComponents = {
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        Zipcode: '',
      }

      let address1 = ''
      let postcode = ''

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const componentType = component.types[0]
        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`
            break
          }
          case 'subpremise': {
            addressComponents.Address2 = component.long_name
            break
          }
          case 'route': {
            address1 += component.short_name
            addressComponents.Address1 = address1
            break
          }
          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`
            addressComponents.Zipcode = postcode
            break
          }
          case 'locality':
            addressComponents.City = component.long_name
            break
          case 'administrative_area_level_1': {
            addressComponents.State = component.long_name
            break
          }
        }
      }

      await form.setFields([
        {
          name: isNameArray ? [parentName, ...name] : name,
          value: place.formatted_address ?? '',
        },
        {
          name: ADDRESS_OBJECT,
          value: addressComponents,
        },
      ])

      form.validateFields([isNameArray ? [parentName, ...name] : name])
    },
  })

  const handleAddress2Change = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const addressUnitNumber = event.target.value ?? ''
      const addressComponents = form.getFieldValue(ADDRESS_OBJECT)
      form.setFields([
        {
          name: ADDRESS_OBJECT,
          value: {
            ...addressComponents,
            Address2: addressUnitNumber,
          },
        },
        {
          name: UNIT_OBJECT,
          value: addressUnitNumber,
        },
      ])
    },
    [form]
  )

  const addressValidator = () => {
    if (!addressValue) {
      return Promise.resolve()
    }
    if (
      form.getFieldValue(ADDRESS_OBJECT) &&
      form.getFieldValue(isNameArray ? [parentName, ...name] : name) !==
        addressValue
    ) {
      return Promise.resolve()
    }
    return Promise.reject(ERROR_MESSAGE)
  }

  const handleAddressInput = (value: string) => {
    setAddressValue(value)
    if (!value) {
      form.setFields([
        {
          name: isNameArray ? [parentName, ...name] : name,
          value: undefined,
        },
        {
          name: ADDRESS_OBJECT,
          value: undefined,
        },
      ])
    }
  }

  return (
    <>
      <Form.Item
        id="address-autocomplete-one-line"
        name={name}
        label={label}
        rules={[
          rulesConstructor({
            message: ERROR_MESSAGE,
            required,
          }),
          {
            message: ERROR_MESSAGE,
            validator: addressValidator,
          },
        ]}
      >
        <Input
          disabled={disabled}
          onSubmit={(event) => event.preventDefault()}
          placeholder="Search by address"
          onChange={(e) => handleAddressInput(e.target.value)}
          prefix={<LocationOutlined />}
          ref={(c) => {
            //@ts-ignore
            addressFieldRef.current = c
            //@ts-ignore
            if (c) antRef.current = c.input
          }}
          size={size}
        />
      </Form.Item>
      {/* This is used to occupy a namespace on the form instance for the address as a structured object.
      It does not displayed on the UI but is required to output the address separated into components */}
      <Form.Item style={{ display: 'none' }} name={ADDRESS_OBJECT}>
        <></>
      </Form.Item>
      {showAddress2 && (
        <Form.Item
          id="unit-or-suite-number"
          name={UNIT_OBJECT}
          label={labelUnit}
        >
          <Input
            className={styles.intakeAddress2}
            disabled={disabled}
            onSubmit={(event) => event.preventDefault()}
            onChange={handleAddress2Change}
            size={size}
          />
        </Form.Item>
      )}
    </>
  )
}

export default Address
