import React, { useEffect, useState } from 'react'

import _isEqual from 'lodash/isEqual'

import {
  submitEditDevelopmentalHistoryRequest,
  submitNewDevelopmentalHistoryRequest,
} from '../../../api/api-lib'
import GenericAccordion from '../../../components/Accordions/GenericAccordion'
import PatientProfileButtons from '../../../components/Buttons/PatientProfileButtons'
import FormMachine from '../../../components/Forms/FormMachine/FormMachine'
import { onError } from '../../../libs/errorLib'
import { useFormFields } from '../../../libs/hooksLib'
import { notification } from '../../../libs/notificationLib'
import { DevelopmentalHistoryResponse } from '../../../shared-types'
import { patientDataTypes } from '../patient-data-types'
import { styling, template } from './template'

import '../PatientProfile.scss'

interface DevelopmentalHistoryResponseProps {
  handleApiChange: (queryName: string[]) => void
  response: DevelopmentalHistoryResponse
  patientId: string
}

export default function DevelopmentalHistory(
  props: DevelopmentalHistoryResponseProps
) {
  const [copy, setCopy] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [storedFields, handleFieldChange] = useFormFields({
    ...props.response,
  })

  const switchToSave = () => {
    setIsEditing(false)
  }

  const checkIfSaveButtonShouldBeDisabled = () => {
    const haveNotesNotChanged = _isEqual(storedFields, copy)
    setIsDisabled(haveNotesNotChanged)
  }

  const copyStoredFields = () => {
    setCopy(storedFields)
  }

  useEffect(() => {
    copyStoredFields()
  }, [])

  useEffect(() => {
    checkIfSaveButtonShouldBeDisabled()
  }, [storedFields])

  const cancelEditing = () => {
    handleFieldChange(copy, false)
    switchToSave()
  }

  const switchToEdit = () => {
    copyStoredFields()
    setIsEditing(true)
  }

  async function handleSubmit(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    try {
      setIsLoading(true)
      const data = {
        developmentalHistoryNotes: storedFields.developmentalHistoryNotes || '',
        patientId: props.patientId,
      }
      if (props.response?.createdAt) {
        await submitEditDevelopmentalHistoryRequest(data)
      } else {
        await submitNewDevelopmentalHistoryRequest(data)
      }
      await props.handleApiChange([
        patientDataTypes.ProviderSidePatientData,
        props.patientId,
      ])
      notification(
        'You have successfully updated the Developmental History subsection on the patient.',
        'success'
      )
      copyStoredFields()
      setIsLoading(false)
      setIsDisabled(true)
      switchToSave()
    } catch (error) {
      console.error(
        'Error submitting information on Developmental History subsection of Patient Profile',
        error
      )
      onError(
        error,
        500,
        "Failed to update the patient's additional information. Please inform your administrator."
      )
      setIsLoading(false)
    }
  }

  return (
    <GenericAccordion title="Developmental History">
      <FormMachine
        storedFields={storedFields}
        template={template}
        handleFieldChange={handleFieldChange}
        isEditing={isEditing}
        styling={styling}
      />
      <PatientProfileButtons
        cancelEditing={cancelEditing}
        isDisabled={isDisabled}
        isEditing={isEditing}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        switchToEdit={switchToEdit}
      />
    </GenericAccordion>
  )
}
