import { Fragment } from 'react'

import { Button, Divider, Form } from 'antd'
import { addHours, addMinutes, differenceInMinutes } from 'date-fns'
import { cloneDeep } from 'lodash'

import { DayOfWeek } from '../../../../../api/api-lib-typed'
import { Text } from '../../../../../stories/BaseComponents'
import { DayAvailability } from '../../DayAvailability/DayAvailability'
import {
  getDateAtHourAndMinute,
  getDefaultStartTime,
  validateTimerange,
} from '../../helpers'
import { DAYS_OF_WEEK, TimeRange } from '../AvailabilitySettings'

import styles from './AvailabilitySettingsForm.module.scss'

const DEFAULT_DURATION_IN_MINUTES = 60

export const getNewAvailabilityHour = ({
  timeRange,
}: Pick<TimeRange, 'timeRange'>) => {
  if (!timeRange.length) {
    return {
      newStartTime: getDefaultStartTime(),
      newDuration: DEFAULT_DURATION_IN_MINUTES,
    }
  }
  const { startTimeHour, startTimeMinute, durationInMinutes } =
    timeRange[timeRange.length - 1]
  const startTime = getDateAtHourAndMinute(startTimeHour, startTimeMinute)
  const lastEndTime = addMinutes(startTime, durationInMinutes)
  const newStartTimeDate = new Date()
  const newStartTime = addHours(lastEndTime, 1)
  newStartTimeDate.setHours(newStartTime.getHours())
  newStartTimeDate.setMinutes(newStartTime.getMinutes())
  newStartTimeDate.setSeconds(0)
  newStartTimeDate.setMilliseconds(0)

  const midnight = new Date(newStartTimeDate.getTime())
  midnight.setHours(24)
  midnight.setMinutes(0)
  const minsTilMidnight = differenceInMinutes(midnight, newStartTimeDate)

  return {
    newStartTime: newStartTimeDate,
    newDuration:
      minsTilMidnight < DEFAULT_DURATION_IN_MINUTES
        ? minsTilMidnight
        : DEFAULT_DURATION_IN_MINUTES,
  }
}

export type SettingsPersonalFormProps = {
  availability: Record<DayOfWeek, TimeRange>
  updateAvailability: (newAvailability: Record<DayOfWeek, TimeRange>) => void
  isSubmitting: boolean
  onSubmit(): void
  isSaveDisabled: boolean
  practiceTimezone: string
}

export const AvailabilitySettingsForm = ({
  availability,
  updateAvailability,
  isSubmitting,
  onSubmit,
  isSaveDisabled,
}: SettingsPersonalFormProps) => {
  const [form] = Form.useForm()

  const setAvailability = (key: string, newAvailability: TimeRange) => {
    updateAvailability({ ...cloneDeep(availability), [key]: newAvailability })
  }

  const handleAddTimeslot = (dayOfWeek: DayOfWeek) => {
    const oldDayAvailability = availability[dayOfWeek]
    const { newDuration, newStartTime } =
      getNewAvailabilityHour(oldDayAvailability)
    const newDayAvailability: TimeRange = {
      providerAvailabilityDayId: oldDayAvailability.providerAvailabilityDayId,
      isUnavailable: false,
      timeRange: [
        ...oldDayAvailability.timeRange,
        {
          startTimeHour: newStartTime.getHours(),
          startTimeMinute: newStartTime.getMinutes(),
          durationInMinutes: newDuration,
          hasValidationError: false,
        },
      ],
    }
    const newAvailability = {
      ...cloneDeep(availability),
      [dayOfWeek]: validateTimerange(newDayAvailability),
    }
    updateAvailability(newAvailability)
  }

  const handleDeleteTimeslot = (dayOfWeek: DayOfWeek, timeslotIdx: number) => {
    const oldDayAvailability = availability[dayOfWeek]
    const newTimeRange = oldDayAvailability.timeRange.filter(
      (_, idx) => idx !== timeslotIdx
    )
    const newDayAvailability: TimeRange = {
      providerAvailabilityDayId: oldDayAvailability.providerAvailabilityDayId,
      timeRange: [...newTimeRange],
      isUnavailable: newTimeRange.length ? false : true,
    }
    updateAvailability({
      ...cloneDeep(availability),
      [dayOfWeek]: validateTimerange(newDayAvailability),
    })
  }

  const handleToggleAvailability = (dayOfWeek: DayOfWeek) => {
    const oldDayAvailability = cloneDeep(availability[dayOfWeek])

    //If no timeslots exist in previous time range, set default time slot
    if (
      oldDayAvailability.isUnavailable &&
      !oldDayAvailability.timeRange.length
    ) {
      const newStartTime = getDefaultStartTime()
      oldDayAvailability.timeRange = [
        ...oldDayAvailability.timeRange,
        {
          durationInMinutes: DEFAULT_DURATION_IN_MINUTES,
          hasValidationError: false,
          startTimeHour: newStartTime.getHours(),
          startTimeMinute: newStartTime.getMinutes(),
        },
      ]
    }

    oldDayAvailability.isUnavailable = !oldDayAvailability.isUnavailable

    updateAvailability({
      ...cloneDeep(availability),
      [dayOfWeek]: oldDayAvailability,
    })
  }

  const handleCopyApply = (
    currentDayOfWeek: DayOfWeek,
    daysToApplyCopy: DayOfWeek[]
  ) => {
    const { isUnavailable, timeRange } = availability[currentDayOfWeek]
    const updatedAvailabilities = daysToApplyCopy.reduce<
      Record<DayOfWeek, TimeRange>
    >((acc, day) => {
      acc[day] = {
        isUnavailable,
        timeRange: timeRange.map((time) => cloneDeep(time)),
        providerAvailabilityDayId: acc[day].providerAvailabilityDayId,
      }
      return acc
    }, cloneDeep(availability))

    updateAvailability({
      ...updatedAvailabilities,
    })
  }

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={availability}
      onFinish={onSubmit}
    >
      <Form.Item style={{ paddingBottom: 16 }}>
        <Text header="h5">Set your available weekly hours</Text>
      </Form.Item>

      {DAYS_OF_WEEK.map(({ label, key }) => (
        <Fragment key={`${label}_${key}`}>
          <DayAvailability
            dayOfWeek={key}
            label={label}
            availability={availability[key]}
            setAvailability={(availability) =>
              setAvailability(key, availability)
            }
            onAddTimeslot={handleAddTimeslot}
            onDeleteTimeslot={handleDeleteTimeslot}
            onToggleAvailability={handleToggleAvailability}
            onCopyApply={handleCopyApply}
          />
          {key !== 'SAT' && <Divider className={styles.divider} />}
        </Fragment>
      ))}
      <Form.Item id="submit" className={styles.saveButton}>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
          disabled={isSaveDisabled}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
