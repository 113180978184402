import {
  ActiveMedicationHistory,
  AllergyHistory,
  DiagnosisHistory,
} from '../../../../../../shared-types'
import { Note } from '../../../../types'

export type DynamicBlockTypes =
  | typeof DYNAMIC_MEDICATIONS_BLOCK
  | typeof DYNAMIC_ALLERGIES_BLOCK
  | typeof DYNAMIC_DIAGNOSIS_BLOCK

export type DynamicBlockOptions =
  | MedicationsBlockOptions
  | AllergiesBlockOptions
  | DiagnosisBlockOptions

export type DynamicBlockDataTypes =
  | MedicationsBlockData
  | AllergiesBlockData
  | DiagnosisBlockData

export type DynamicNoteBlock = {
  uuid: string
  noteId: number
  blockType: DynamicBlockTypes
  renderVersion: string
  options: DynamicBlockOptions
  data: DynamicBlockDataTypes
  deletedAt: Date | null
  updatedAt: Date | null
  createdAt: Date
}

export type CreateDynamicBlockOptions = {
  noteUuid: string
  blockConfig: Pick<DynamicNoteBlock, 'blockType' | 'options'>
}

export type UpdateDynamicBlockOptions = {
  noteUuid: string
  blockUuid: string
  blockConfig: Pick<DynamicNoteBlock, 'options'>
}

export type DynamicBlockRenderOptions = {
  block: DynamicNoteBlock
  note: Note
  onBlockUpdate: (params: UpdateDynamicBlockOptions) => Promise<void>
  isRefreshing: boolean
}

/**
 * Medication Block Types
 */
export const DYNAMIC_MEDICATIONS_BLOCK = 'MEDICATIONS'
export type MedicationsBlockData = ActiveMedicationHistory[] | undefined
export type MedicationsBlockOptions = {
  limit: number
}
export type MedicationBlockRenderOptions = {
  isDrFirstEnabled: boolean
  isDrFirst: boolean
  urlParams: string
}

/**
 * Allergy Block Types
 */
export const DYNAMIC_ALLERGIES_BLOCK = 'ALLERGIES'
export type AllergiesBlockData = AllergyHistory[] | undefined
export type AllergiesBlockOptions = {
  limit: number
}

/**
 * Diagnoses Block Types
 */
export const DYNAMIC_DIAGNOSIS_BLOCK = 'DIAGNOSIS'
export type DiagnosisBlockData = DiagnosisHistory[] | undefined
export type DiagnosisBlockOptions = {
  limit: number
}

export const dynamicBlockTypesSet = new Set<DynamicBlockTypes>([
  DYNAMIC_MEDICATIONS_BLOCK,
  DYNAMIC_ALLERGIES_BLOCK,
  DYNAMIC_DIAGNOSIS_BLOCK,
])
