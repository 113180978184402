/* Standard 24px-padding container (based off mockups) */
import React from 'react'

import { TestId } from '../../shared-types'

import './_shared.scss'

type BoxProps = {
  children: React.ReactNode
  addClassNames?: string[]
} & TestId

const Box: React.FC<BoxProps> = ({ children, addClassNames = [], testId }) => {
  const boxClassNames = `component-container ${addClassNames.join(' ')}`

  return (
    <div className={boxClassNames} data-testid={testId}>
      {children}
    </div>
  )
}

export default Box
