import React from 'react'

import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom'

import { TestId } from '../../shared-types'

type LinkProps = {
  to: string
} & ReactRouterLinkProps &
  TestId

const Link: React.FC<LinkProps> = ({ children, testId, ...props }) => {
  return (
    <ReactRouterLink data-testid={testId} {...props}>
      {children}
    </ReactRouterLink>
  )
}

export default Link
