import React from 'react'

import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Form, FormItemProps } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

import Button from './Button'

import styles from './dynamicSubForm.module.scss'

export interface SubFormProps {
  index: number
  key?: number
  name: string | number
  restField?: FormItemProps
}

interface DynamicSubFormProps {
  addButtonText?: string
  reviewMode?: boolean
  buttonSize?: SizeType
  name?: string
  subForm: (param: SubFormProps) => React.ReactElement<any>
  initialValues?: any[]
}

const DynamicSubForm = ({
  addButtonText = 'Add field',
  reviewMode,
  buttonSize,
  name = 'subForm',
  subForm,
  initialValues = [],
}: DynamicSubFormProps) => {
  return (
    <Form.List name={name} initialValue={initialValues}>
      {(fields, { add, remove }, { errors }) => {
        return (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div className={styles.defaultBlueBackground} key={key}>
                {subForm({ key, name, restField, index })}
                {!reviewMode && (
                  <Button
                    className={styles.removeButton}
                    type="ghost"
                    onClick={() => remove(name)}
                    icon={<DeleteOutlined />}
                    size={buttonSize}
                  >
                    Remove
                  </Button>
                )}
              </div>
            ))}
            {!reviewMode && (
              <Form.Item className={styles.addButton}>
                <Button
                  type="dashed"
                  onClick={() => {
                    if (Array.isArray(initialValues) && initialValues[0]) {
                      return add(initialValues[0])
                    }
                    add()
                  }}
                  icon={<PlusCircleOutlined />}
                  size={buttonSize}
                >
                  {addButtonText}
                </Button>
              </Form.Item>
            )}
            <Form.ErrorList errors={errors} />
          </>
        )
      }}
    </Form.List>
  )
}

export default DynamicSubForm
