import React from 'react'

import moment from 'moment'
import { Col, Form, Row } from 'react-bootstrap'

import { NoteV1AuditLog } from '../../../../../shared-types'

import '../../NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

interface AuditTrailProps {
  createdOnDate?: string
  createdOn?: string
  createdBy?: string
  editedOnDateBy?: NoteV1AuditLog
}

export default function AuditTrail({
  createdOnDate,
  createdOn,
  createdBy,
  editedOnDateBy,
}: AuditTrailProps) {
  return (
    <div>
      <br />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        History
      </div>
      <br />
      {createdOnDate || createdOn ? (
        <Row>
          <Form.Group
            as={Col}
            style={{ textAlign: 'center', marginLeft: 'auto' }}
          >
            <Form.Text
              data-testid="creation-log"
              className="center"
              style={{ fontWeight: 'bold', fontSize: '15px' }}
            >
              Created on{' '}
              {createdOnDate
                ? moment(createdOnDate).format('LLLL')
                : moment(createdOn).format('LL')}{' '}
              {createdBy ? ' by ' : ''} {createdBy ?? ''}
            </Form.Text>
          </Form.Group>
        </Row>
      ) : null}
      {(editedOnDateBy ?? []).map((obj, index) => (
        <Row key={index}>
          <Form.Group
            as={Col}
            style={{ textAlign: 'center', marginLeft: 'auto' }}
          >
            <Form.Text
              data-testid="edit-log"
              className="center"
              style={{ fontWeight: 'bold', fontSize: '15px' }}
            >
              Edited on {moment(obj.Date).format('LLLL')} by {obj.EditBy}
            </Form.Text>
          </Form.Group>
          <hr />
        </Row>
      ))}
    </div>
  )
}
