import { Typography } from 'antd'
import { LinkProps } from 'antd/lib/typography/Link'
import { ParagraphProps } from 'antd/lib/typography/Paragraph'
import { TextProps } from 'antd/lib/typography/Text'
import { TitleProps } from 'antd/lib/typography/Title'

const { Link, Paragraph, Text, Title } = Typography

export default {
  Link: (props: LinkProps) => <Link {...props} />,
  Paragraph: (props: ParagraphProps) => <Paragraph {...props} />,
  Text: (props: TextProps) => <Text {...props} />,
  Title: (props: TitleProps) => <Title {...props} />,
}
