import { CodeSystemOIDs } from '../Demographics/CodeSystems'
import {
  Categories,
  constructPatientDemographicObject,
} from '../Demographics/types'

const SkinCodes: any = [
  {
    95320005: {
      title: 'Skin Disorders',
      snomedcode: 95320005,
      description: 'Disorder of skin (disorder)',
      icdcode: 'L989',
      icddescription:
        'Disorder of the skin and subcutaneous tissue, unspecified',
    },
  },
  {
    40275004: {
      title: 'skd.Contact Dermatitis',
      description: 'Contact dermatitis (disorder)',
      snomedcode: 40275004,
      icdcode: 'L259',
      icddescription: 'Unspecified contact dermatitis, unspecified cause',
    },
  },
  {
    43116000: {
      title: 'skd.Eczema',
      description: 'Eczema (disorder)',
      snomedcode: 43116000,
      icdcode: 'L209',
      icddescription: 'Atopic dermatitis, unspecified',
    },
  },
  {
    398114001: {
      title: 'skd.Ehlers Danlos Syndrome (EDS)',
      description: 'Ehlers-Danlos syndrome (disorder)',
      snomedcode: 398114001,
      icdcode: 'Q7960',
      icddescription: 'Ehlers-Danlos syndrome, unspecified',
    },
  },
  {
    9014002: {
      title: 'skd.Psoriasis',
      description: 'Psoriasis (disorder)',
      snomedcode: 9014002,
      icdcode: 'L409',
      icddescription: 'Psoriasis, unspecified',
    },
  },
]

export const SkinDropDownValues = constructPatientDemographicObject(
  SkinCodes,
  CodeSystemOIDs.OSMINDCustomCodeSystem,
  Categories.MEDICAL_CONDITIONS
)
