import { useEffect, useState } from 'react'

import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import {
  finalizeStripeOnboarding,
  getStripeAccountStatus,
  getStripeLoginLink,
} from '../../api/api-lib'
import osmindLogo from '../../images/osmind_logo.png'
import { notification } from '../../libs/notificationLib'
import { Button, Spinner } from '../../stories/BaseComponents'
import { STRIPE_ACCOUNT_STATUSES } from './stripe-constants'

import styles from './PaymentsOnboardingComplete.module.scss'

export default function PaymentsOnboardingComplete() {
  const [isLoading, setIsLoading] = useState(true)
  const [stripeFinalizationError, setStripeFinalizationError] = useState(false)
  const [stripeAccountStatus, setStripeAccountStatus] = useState(null)
  const [stripeTitle, setStripeTitle] = useState('')
  const [stripeMessage, setStripeMessage] = useState('')
  const queryParams = queryString.parse(window.location.search)
  const [stripeCode] = useState(queryParams.code)
  const [isFetchingStripeLink, setIsFetchingStripeLink] = useState(false)
  const history = useHistory()

  const finalizeOnboarding = async () => {
    try {
      await finalizeStripeOnboarding(queryParams.code)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      if (err?.error !== 'Stripe account already exists on provider') {
        setStripeFinalizationError(true)
        notification(
          'There was a problem finalizing your Stripe setup. Please contact support.'
        )
      }
    }
  }

  const redirectToStripeLogin = async () => {
    setIsFetchingStripeLink(true)
    getStripeLoginLink()
      .then((resp) => {
        setIsFetchingStripeLink(false)
        window.open(resp.redirectUrl)
      })
      .catch(() => {
        setIsFetchingStripeLink(false)
        notification(
          'Error redirecting to Stripe login. Please retry or contact support if the problem persists.'
        )
      })
  }

  useEffect(() => {
    finalizeOnboarding().then(() => {
      getStripeAccountStatus().then((resp) => {
        if (resp?.status) {
          switch (resp.status) {
            case STRIPE_ACCOUNT_STATUSES.setupComplete:
              setStripeTitle("You're connected to Stripe")
              setStripeMessage("Now you're all set to accept online payments.")
              break
            case STRIPE_ACCOUNT_STATUSES.setupIncomplete:
              setStripeTitle('Account setup pending')
              setStripeMessage(
                "Stripe may take a few minutes to finish verifying your account. If it's still incomplete, log back into Stripe to finish your signup."
              )
              break
            default:
              setStripeTitle('Unable to determine Stripe onboarding status')
          }
        } else {
          console.log('No Stripe status')
        }
        setStripeAccountStatus(resp.status)
      })
    })
  }, [stripeCode])

  return (
    <>
      {isLoading || stripeAccountStatus === null ? (
        <Spinner />
      ) : (
        <div id={styles.background}>
          <div id={styles.card}>
            <img id={styles.logo} src={osmindLogo}></img>
            <h5 id={styles.title}> {stripeTitle}</h5>
            <h6 id={styles.message}> {stripeMessage}</h6>
            <Button
              id={styles.stripeButton}
              onClick={redirectToStripeLogin}
              disabled={stripeFinalizationError}
              loading={isFetchingStripeLink}
            >
              <span> </span>
              <span>Go to Stripe</span>
            </Button>
            <Button
              id={styles.paymentsButton}
              onClick={() => history.replace('/settings/payments')}
              disabled={stripeFinalizationError || isFetchingStripeLink}
            >
              View Payments
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
