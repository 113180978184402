import { globalConfig } from '../../../config/config'
import { blockConfig } from '../Components/Blocks/config'
import { BlockConfig, BlockPermissions } from '../Components/Blocks/types'

export const useBlocks = (isForEditor = true) => {
  const { isProd } = globalConfig.get()

  return blockConfig.reduce((list, config) => {
    const { permissions } = config

    if (permissions) {
      if (permissions === BlockPermissions.PREPROD && isProd) {
        return list
      }

      if (permissions === BlockPermissions.FEATURE_FLAG && !isForEditor) {
        // implement me if we ever want to limit blocks to feature flags
        return list
      }
    }

    list.push(config)
    return list
  }, [] as BlockConfig[])
}
