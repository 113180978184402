import React from 'react'

import { GroupChannelHandler } from '@sendbird/chat/groupChannel'
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors'
// eslint-disable-next-line import/no-unresolved
import { SendbirdChatType } from '@sendbird/uikit-react/types/lib/types'
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext'

import { updateSendbirdChannelName } from '../../../api/api-lib'
import {
  IncrementMessageCountForPatientFn,
  UnreadMessageCountsMap,
  UpdateSendbirdUserFn,
} from './types'
import { useAllSendbirdChannelsForUserId } from './useAllSendbirdChannelsForUserId'

/**
 * TODOs
 * [] Move Sendbird specific functionality over to the `/libs/sendbird.ts` file
 */

export type UnreadMessageCountsError = { toString: () => string }

export type UnreadMessageCountsContext = {
  unreadMessageCounts: UnreadMessageCountsMap
  /**
   * Values and naming are in alignment with ReactQuery definitions
   */
  isFetching: boolean
  /**
   * Values and naming are in alignment with ReactQuery definitions
   */
  isLoading: boolean
  isError: boolean
  /**
   * Values and naming are in alignment with ReactQuery definitions
   * - ReactQuery defines this as `unknown` but also references `message` as a prop
   */
  error?: UnreadMessageCountsError
  markAsUnreadForPatient: (patientChannelurl: string) => Promise<void>
  markAsReadForPatient: (patientChannelurl: string) => Promise<void>
  updateSendbirdUser: UpdateSendbirdUserFn
}

const initialState: UnreadMessageCountsContext = {
  unreadMessageCounts: new Map(),
  /**
   * Values and naming are in alignment with ReactQuery definitions
   */
  isFetching: false,
  /**
   * Values and naming are in alignment with ReactQuery definitions
   */
  isLoading: false,
  isError: false,
  error: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  markAsUnreadForPatient: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  markAsReadForPatient: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateSendbirdUser: async () => {},
}

export const UnreadMessageCountsContext =
  React.createContext<UnreadMessageCountsContext>(initialState)

export const useSetupSendbirdGroupChannelHandler = ({
  sendbirdSdk,
  incrementMessageCountForPatient,
}: {
  sendbirdSdk: SendbirdChatType
  incrementMessageCountForPatient: IncrementMessageCountForPatientFn
}) => {
  React.useEffect(() => {
    const groupChannelHandler: GroupChannelHandler = new GroupChannelHandler({
      onMessageReceived: (channel, message) => {
        return incrementMessageCountForPatient(channel.url, {
          messageCustomType: message.customType,
        })
      },
    })

    if (sendbirdSdk?.groupChannel) {
      sendbirdSdk.groupChannel.addGroupChannelHandler(
        'newMessagesHandlerHome',
        groupChannelHandler
      )
    }

    /**
     * 💀 DO NOT ADD A DESTRUCTOR 💀
     * - it will freeze the app
     *
     * Steps to repro
     * 1. Open app to Messages
     * 2. Navigate away
     * 3. Wait ~1 minute
     * 4. Navigate back
     * 5. Send a patient message to that chat instance
     * 6. Notice message isn't received and app starts hanging
     */
  }, [sendbirdSdk, incrementMessageCountForPatient])
}

export const UnreadMessageCountsProvider: React.FC<
  React.PropsWithChildren<{
    isSecureMessagingEnabled: boolean
    /**
     * IMPORTANT: This is mapped from the ClinicID/PrimaryProviderID
     */
    sendbirdUserId: string
  }>
> = ({ isSecureMessagingEnabled, sendbirdUserId, children }) => {
  const globalStore = useSendbirdStateContext()
  const sendbirdSdk = sendbirdSelectors.getSdk(globalStore)

  const { _incrementMessageCountForPatient, ...contextValue } =
    useAllSendbirdChannelsForUserId({
      sendbirdSdk,
      isSecureMessagingEnabled,
      sendbirdUserId,
    })

  useSetupSendbirdGroupChannelHandler({
    sendbirdSdk,
    incrementMessageCountForPatient: _incrementMessageCountForPatient,
  })

  const updateSendbirdUser: UpdateSendbirdUserFn = React.useMemo(
    () =>
      async ({ patientId, patientName, channelUrl }) => {
        try {
          await sendbirdSdk.connect(patientId)
          await sendbirdSdk.updateCurrentUserInfo({
            nickname: patientName,
          })
          await updateSendbirdChannelName(channelUrl, patientName)
        } catch (err) {
          console.error('Failed to update Sendbird user', err)
        }
      },
    [sendbirdSdk]
  )

  const exposedContextValue = React.useMemo(
    () => ({ ...contextValue, updateSendbirdUser }),
    [contextValue, updateSendbirdUser]
  )

  // using type casting to override the `error` type so we can use the `.toString` method on it
  return (
    <UnreadMessageCountsContext.Provider
      value={exposedContextValue as UnreadMessageCountsContext}
    >
      {children}
    </UnreadMessageCountsContext.Provider>
  )
}
