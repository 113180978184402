// Import React
import React, { useState } from 'react'

import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Modal } from 'antd'
import { ColumnsType } from 'antd/es/table'

import {
  addBillingTemplate,
  deleteBillingTemplate,
  deleteBillingTemplateCode,
  getBillingTemplates,
  updateBillingTemplateName,
} from '../../api/api-lib'
import { formatNumberStringToUsdString } from '../../libs/billing'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import { sortAlphabetically } from '../../libs/utils'
import {
  BillingTemplate,
  BillingTemplateCode,
  BillingTemplateCodeTableItem,
  BillingTemplateTableItem,
} from '../../shared-types'
import {
  Button,
  Card,
  Divider,
  EditableInput,
  Space,
  Table,
  TableAvatar,
} from '../../stories/BaseComponents'
import BillingTemplateCodeModal from './BillingTemplateCodeModal'

import '../../stories/BillingTemplates/SettingsTableDisplay.scss'
import '../Authentication/Locations.scss'
import styles from '../_shared.module.scss'

export default function BillingTemplates() {
  const [showAddBillingTemplateCodeModal, setShowAddBillingTemplateCodeModal] =
    useState<boolean>(false)
  const [isEditingBillingTemplateCode, setIsEditingBillingTemplateCode] =
    useState<boolean>(false)
  const [isCreatingNewTemplate, setIsCreatingNewTemplate] =
    useState<boolean>(false)
  const [billingTemplateDisplayItem, setBillingTemplateDisplayItem] = useState<
    BillingTemplateTableItem | undefined
  >(undefined)
  const [editBillingTemplateCodeItem, setEditBillingTemplateCodeItem] =
    useState<BillingTemplateCodeTableItem>()
  const [isChangingName, setIsChangingName] = useState<boolean>(false)

  async function getTemplates() {
    try {
      const billingTemplates: BillingTemplate[] = await getBillingTemplates()
      return billingTemplates
    } catch (e) {
      console.error('Error when getting billing templates', e)
      onError(new Error('Unable to get billing templates'))
    }
  }

  const {
    data: billingTemplates,
    isLoading: isTableLoading,
    refetch: refetchTemplates,
  } = useQuery(['billingTemplates'], getTemplates)

  const onSuccess = async (actionText: string) => {
    notification(`Successfully ${actionText} a billing template.`, 'success')
    refetchTemplates()
  }

  const onApiError = (error: Error | string) => {
    console.error(error)
    onError(error)
  }

  async function handleCreateBillingTemplate() {
    setIsCreatingNewTemplate(true)
    const newTemplate: BillingTemplate = await addBillingTemplate({
      name: 'New billing template',
    })
    const templateDisplayItem: BillingTemplateTableItem = {
      key: newTemplate.uuid,
      templateId: newTemplate.uuid,
      templateName: newTemplate.name,
    }
    setBillingTemplateDisplayItem(templateDisplayItem)
    setIsCreatingNewTemplate(false)
  }

  const { mutate: createTemplate } = useMutation(handleCreateBillingTemplate, {
    onSuccess: async () => await onSuccess('added'),
    onError: onApiError,
  })

  const { mutate: deleteTemplate } = useMutation(deleteBillingTemplate, {
    onSuccess: async () => await onSuccess('deleted'),
    onError: onApiError,
  })

  function handleAddBillingTemplateCode() {
    setIsEditingBillingTemplateCode(false)
    setShowAddBillingTemplateCodeModal(true)
  }

  function handleEditBillingTemplateCode(
    codeItem: BillingTemplateCodeTableItem
  ) {
    setIsEditingBillingTemplateCode(true)
    setEditBillingTemplateCodeItem(codeItem)
    setShowAddBillingTemplateCodeModal(true)
  }

  async function handleEditTemplateName(newTemplateName?: string) {
    if (
      newTemplateName &&
      newTemplateName.trim() !== '' &&
      billingTemplateDisplayItem
    ) {
      try {
        setIsChangingName(true)
        const data = {
          name: newTemplateName,
          templateId: billingTemplateDisplayItem.templateId,
        }
        await updateBillingTemplateName(data)
        billingTemplateDisplayItem.templateName = newTemplateName
        refetchTemplates()
      } catch (e) {
        console.error('Error when updating a billing template name', e)
        onError(new Error('Unable to update billing template name'))
      }
      setIsChangingName(false)
    }
  }

  async function handleDeleteBillingTemplateCode(id: string) {
    try {
      await deleteBillingTemplateCode(id)
      notification('Successfully deleted a billing template code.', 'success')
      refetchTemplates()
    } catch (e) {
      console.error('Error when deleting a billing template code', e)
      onError(new Error('Unable to delete billing template code'))
    }
  }

  function handleDeleteBillingTemplateClick(templateId: string) {
    Modal.confirm({
      title: 'Delete billing template',
      content:
        'Are you sure you want to delete this billing template and all its associated billing template codes?',
      okText: 'Ok',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'default',
      },
      okButtonProps: {
        type: 'primary',
      },
      onOk: () => {
        deleteTemplate(templateId)
      },
    })
  }

  function handleDeleteBillingTemplateCodeClick(codeId: string) {
    Modal.confirm({
      title: 'Delete billing template code',
      content: 'Are you sure you want to delete this billing template code?',
      okText: 'Ok',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'default',
      },
      okButtonProps: {
        type: 'primary',
      },
      onOk: () => {
        handleDeleteBillingTemplateCode(codeId)
      },
    })
  }

  const billingTemplateColumns: ColumnsType<BillingTemplateTableItem> = [
    {
      dataIndex: 'templateName',
      key: 'templateName',
      render: (_value, record) => {
        const billingTemplate = billingTemplates?.find(
          (template) => template.uuid === record.templateId
        )
        const codeCount = billingTemplate
          ? billingTemplate.billingTemplateCodes?.length
          : 0

        return (
          <TableAvatar
            id={record.templateId}
            icon={<UnorderedListOutlined />}
            heading={record.templateName}
            subheading={`(${codeCount} codes)`}
          />
        )
      },
    },
    {
      title: '',
      key: 'action',
      render: (text: any, record: BillingTemplateTableItem) => {
        return (
          <Space size="small" className="tableButtonContainer">
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={(event) => {
                event.stopPropagation()
                handleDeleteBillingTemplateClick(record.templateId)
              }}
              className="antd-center-button action-button-delete"
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  const billingTemplateCodeColumns = [
    {
      title: 'Billing code',
      dataIndex: 'codeAndDescription',
      key: 'codeAndDescription',
    },
    {
      title: 'Modifiers',
      dataIndex: 'modifiersDisplay',
      key: 'modifiersDisplay',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: 'Unit charge',
      dataIndex: 'unitChargeDisplay',
      key: 'unitChargeDisplay',
    },
    {
      title: 'Discount',
      dataIndex: 'discountDisplay',
      key: 'discountDisplay',
    },
    {
      title: 'Total charge',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: '',
      key: 'action',
      align: 'right' as const,
      render: (text: any, record: BillingTemplateCodeTableItem) => {
        return (
          <Space size="middle" className="tableButtonContainer">
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => handleEditBillingTemplateCode(record)}
              className="antd-center-button action-button-edit"
            >
              Edit
            </Button>

            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() =>
                handleDeleteBillingTemplateCodeClick(record.codeId)
              }
              className="antd-center-button action-button-delete"
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  const billingTemplateData = React.useMemo(() => {
    const sortedTemplates = billingTemplates?.sort((a, b) => {
      return sortAlphabetically(a.name, b.name)
    })
    const templateList: BillingTemplateTableItem[] = (
      sortedTemplates || []
    ).map((template, index) => {
      return {
        key: `${index}`,
        templateId: template.uuid,
        templateName: template.name,
      }
    })

    return templateList
  }, [billingTemplates])

  const getTotalCharge = (code: BillingTemplateCode) => {
    const total =
      code.unit * (code.unitChargeAmountCents / 100) -
      code.discountAmountCents / 100
    if (total < 0) {
      return '0'
    }
    return total.toFixed(2)
  }

  const billingTemplateCodesData = React.useMemo(() => {
    const codesList: BillingTemplateCodeTableItem[] = []
    if (!billingTemplateDisplayItem) {
      return []
    }
    const billingTemplate = billingTemplates?.find(
      (t) => t.uuid === billingTemplateDisplayItem.templateId
    )
    const billingTemplateCodes = billingTemplate?.billingTemplateCodes || []

    // sort the billing template codes by code so that the always display in the same order
    billingTemplateCodes.sort((a, b) => {
      return sortAlphabetically(a.billingCode.code, b.billingCode.code)
    })

    for (const code of billingTemplateCodes) {
      const billingCodeData = {
        code: code.billingCode.code || '',
        version: code.billingCode.version || '',
        description: code.billingCode.description || '',
        shortDescription: code.billingCode.shortDescription || '',
        ownerId: code.billingCode.ownerId || '',
        id: code.billingCode.id || '',
      }
      codesList.push({
        key: `${code.billingCode?.code}`,
        codeId: code.uuid,
        modifiers: code.modifiers,
        modifiersDisplay: code.modifiers.length
          ? `${code.modifiers[0] || 0}, ${code.modifiers[1] || 0}, ${
              code.modifiers[2] || 0
            }, ${code.modifiers[3] || 0}`
          : '',
        unit: code.unit,
        unitChargeAmountCents: code.unitChargeAmountCents,
        unitChargeDisplay: formatNumberStringToUsdString(
          (code.unitChargeAmountCents / 100).toFixed(2)
        ),
        discountAmountCents: code.discountAmountCents,
        discountDisplay: formatNumberStringToUsdString(
          (code.discountAmountCents / 100).toFixed(2)
        ),
        codeAndDescription: `${code.billingCode.code} ${code.billingCode.shortDescription}`,
        billingCodeValue: JSON.stringify(billingCodeData),
        billingCodeCode: code.billingCode.code,
        total: formatNumberStringToUsdString(getTotalCharge(code)),
        billingCodeId: code.billingCodeId,
      })
    }

    return codesList
  }, [billingTemplateDisplayItem, billingTemplates])

  const allTemplates = (
    <Card bordered={false}>
      <div className="headerWrapper headerWrapper_row">
        <span className="headerWrapper_title">Billing templates</span>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => createTemplate()}
          loading={isCreatingNewTemplate}
          className="antd-center-button"
        >
          Add billing template
        </Button>
      </div>
      <Divider className="settings-table-divider" />
      <Table
        className="settings-table"
        columns={billingTemplateColumns}
        dataSource={billingTemplateData}
        pagination={false}
        loading={isTableLoading}
        size="middle"
        showHeader={false}
        onRow={(template) => ({
          onClick: () => {
            setBillingTemplateDisplayItem(template as BillingTemplateTableItem)
          },
        })}
      />
    </Card>
  )

  const singleTemplate = billingTemplateDisplayItem && (
    <>
      <Card bordered={false}>
        <div className="headerWrapper headerWrapper_row">
          <span className="headerWrapper_title billing-template-title-wrapper">
            <ArrowLeftOutlined
              style={{ marginRight: 8 }}
              onClick={() => {
                setBillingTemplateDisplayItem(undefined)
              }}
            />
            <span className="billing-template-table-header">
              <EditableInput
                value={billingTemplateDisplayItem.templateName}
                onChange={handleEditTemplateName}
                isLoading={isChangingName}
              />
            </span>
          </span>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddBillingTemplateCode}
            className="antd-center-button"
          >
            Add billing code
          </Button>
        </div>
        <Table
          columns={billingTemplateCodeColumns}
          dataSource={billingTemplateCodesData}
          pagination={false}
          loading={isTableLoading}
          size="middle"
        />
      </Card>
      <BillingTemplateCodeModal
        show={showAddBillingTemplateCodeModal}
        fetchTemplates={refetchTemplates}
        onHide={() => setShowAddBillingTemplateCodeModal(false)}
        editBillingTemplateCodeItem={
          isEditingBillingTemplateCode ? editBillingTemplateCodeItem : undefined
        }
        billingTemplateItem={billingTemplateDisplayItem}
        existingBillingCodeIds={billingTemplateCodesData.map(
          (codeData) => codeData.billingCodeId
        )}
      />
    </>
  )

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        {!billingTemplateDisplayItem ? (
          <>{allTemplates}</>
        ) : (
          <>{singleTemplate}</>
        )}
      </div>
    </div>
  )
}
